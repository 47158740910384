import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { buildtoastNotification } from '../../../../../../../../ToastNotification/controller/ToastNotificationController';
import { getAllRoles, getRoleReportMapping, updateRoleReportMapping } from '../../../../../view/report/service/KagamiReportService';
import { MapReportsModel } from '../Model/MapReportsModel';
let kagamiMapReportsModel:MapReportsModel;
export const setAllRolesAndMapReportsData = (mapReportsModel: MapReportsModel) => {
  getAllRolesAndMapReportsData().then((response:any)=>{
    kagamiMapReportsModel=mapReportsModel;
    mapReportsModel.dbRolesList = response[0].data.respObject;
    mapReportsModel.dbRoleReportMapping = response[1].data.respObject;
    makeMapReports(mapReportsModel);
    mapReportsModel.state.setDataLoaded(true);
  }).catch((err:any)=>{
    console.log(err);
  })
}

export const getAllRolesAndMapReportsData = () => {
  return Promise.all([getAllRoles(), getRoleReportMapping()]);
}

export const makeMapReports = (mapReportsModel: MapReportsModel) => {
  let reportsMappingList: any = [];
  mapReportsModel.dbRolesList.forEach((role: any) => {
    if (role !== undefined) {
      let mappedReportList =  filter(mapReportsModel.dbRoleReportMapping, { roleId: role });
      let mappedReports =  map(mappedReportList, 'reportId').map((reportId: string) => {
        if (reportId !== undefined) {
          let mappedReportsName =  find(mapReportsModel.dbReportList, { id: reportId });
          if (mappedReportsName !== undefined) {
            return { reportName: mappedReportsName.name, reportId: reportId };
          }
        }
      });
      let temp = {
        roleId: role,
        reportsMapping:  compact(mappedReports)
      };
      reportsMappingList.push(temp);
    }
  });
  mapReportsModel.roleIdAndMappedReportData = reportsMappingList;
  
};


export const mapReportsOnSave = (mapReportsModel: MapReportsModel,setDisable:any) => {
  updateRoleReportMapping(mapReportsModel.allSelectedData)
    .then((response: any) => {
      if (response.status === 200) {
        let count=mapReportsModel.allSelectedData.length
         //showing success alert
        // showToastAlert('SUCCESS', 0)
        buildtoastNotification('success', 'Save Successfull' ,0)
        while(count!==0){
          mapReportsModel.allSelectedData.pop();
          count--;
         }
         setDisable(true);
      }
    })
    .catch((error: any) => {
      alert(error);
    });
};


export const enableAndDisableSaveButton=()=> {
  if (kagamiMapReportsModel) {
    if (kagamiMapReportsModel.allSelectedData.length) {
      kagamiMapReportsModel.setDisableSave(false);
    } else {
      kagamiMapReportsModel.setDisableSave(true);
    }
  }
}