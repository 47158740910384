import { createState } from "@hookstate/core";
import { KagamiEmbeddedFormPresentationModel } from "../../../model/KagamiEmbeddedFormPresentationModel";
import { getFormRecords } from "../../../service/FormService";
import { KagamiEmbeddedAccordionPresentationModel } from "../builder/embedded-accordion-presentation/model/KagamiEmbeddedAccordionPresentationModel";
import { KagamiEmbeddedTabsPresentationModel } from "../builder/embedded-tabs-presentation/model/KagamiEmbeddedTabsPresentationModel";
import { KagamiEmbeddedPresentationModel } from "../model/KagamiEmbeddedPresentationModel";

export function buildEmbedAccordionModel(embedFormPresentationModel : KagamiEmbeddedFormPresentationModel){
   let embedAccordionModel : KagamiEmbeddedAccordionPresentationModel = new KagamiEmbeddedAccordionPresentationModel(
       embedFormPresentationModel.onStartData,
       embedFormPresentationModel.mainEntityId,
       embedFormPresentationModel.formEntityId,
       embedFormPresentationModel.formHeader,
       embedFormPresentationModel.processName,
       embedFormPresentationModel.processStepName,
       embedFormPresentationModel.uiTemplate,
       embedFormPresentationModel.initiallyVisible,
       embedFormPresentationModel.initiallyReadOnly,
       embedFormPresentationModel.isDynamicForm
   );
   embedAccordionModel.presentationId = embedFormPresentationModel.presentationId;
   embedAccordionModel.presentation = embedFormPresentationModel.presentation;
  embedAccordionModel.mandatory = embedFormPresentationModel.mandatory;
  embedAccordionModel.visible = embedFormPresentationModel.visible;
  embedAccordionModel.readonly = embedFormPresentationModel.readonly;
  embedAccordionModel.isListPresentation = embedFormPresentationModel.isListPresentation;
  embedAccordionModel.onloadRequired = embedFormPresentationModel.onloadRequired;
  embedAccordionModel.onLoadData = getFormRecords(embedFormPresentationModel);
  embedAccordionModel.onCancelRequired = embedFormPresentationModel.onCancelRequired;
  embedAccordionModel.createState = createState(embedAccordionModel);
  embedAccordionModel.presentationPolicy = embedFormPresentationModel.presentationPolicy;
  embedAccordionModel.businessPolicy = embedFormPresentationModel.businessPolicy;
   return embedAccordionModel;
}

export function buildEmbedTabsModel(embedPresentationModel : KagamiEmbeddedPresentationModel){
    let visibleEmbedModels : any[] = embedPresentationModel.embeddedFormModels.filter((model : KagamiEmbeddedFormPresentationModel) => {
        model.embedViewId =  model.isNested ?  'ParentNestedEmbedState' : 'ParentEmbedState';
        return model.visible === true});
    let embedTabsModel : KagamiEmbeddedTabsPresentationModel = new KagamiEmbeddedTabsPresentationModel(0,visibleEmbedModels,embedPresentationModel.key);
    if(  embedPresentationModel.embeddedFormModels[0]['isNested']) embedTabsModel.isNestedEmbedModel = true  ;
    return embedTabsModel;

}