import React from 'react';
import { createState } from '@hookstate/core'
import { KagamiListFilterModel, KagamiListFilterModelProps } from '../../../model/KagamiListFilterModel'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { buildListPresentationFilters } from '../../ListFilterBuilder';
import SearchIcon from '@mui/icons-material/Search';
import {  Checkbox, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField  } from '@mui/material';  

export function buildListFilter(kagamiListFilterModel: KagamiListFilterModel){   
    
    return <KagamiListFilterWidget key = 'list_filters' kagamiListFilterModel={kagamiListFilterModel}/>
}



export function KagamiListFilterWidget(props :KagamiListFilterModelProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
 
  return (  


    <>
       <IconButton 
       onClick={handleClick}>
        <FilterAltOutlinedIcon   style={{border:"1px solid #ccc", borderRadius:'3px'}} />
       </IconButton>
        <Popover  
      
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div>
        <BuildMenu kagamiListFilterModel = {props.kagamiListFilterModel} />
       
      </div>
    </Popover>
   </>
  )
}
function buildMenuComps(presentationFiltersObj :any) {
  let presentationFilters = presentationFiltersObj[0]
    return  presentationFilters.map((item:any) => {
        let label =  Object.keys(item)[0];
        let values:any = Object.values(item)[0];
        return (
                <>
                <ListItem style={{textTransform: 'capitalize', fontSize:'16px', color: '#A4A1A1', padding: '0px 20px 5px 20px', margin: 0}}> = {label}</ListItem>
                {values ?
                values
                .map((x:string) => {
                return (
                <>
                
                <ListItem style = {{padding: '0px 20px 5px 14px', margin: 0}}>
                
                  <ListItemButton   dense style = {{padding: '5px', margin: 0}}>
                <ListItemIcon style={{minWidth: '0px'}}>
                  <Checkbox
                    edge="start"
                    size='small'
                    //  checked={checked.indexOf(validateItemSelected(item) ) !== -1}
                    tabIndex={-1}
                    disableRipple
                    //  inputProps={{ 'aria-labelledby': labelId }}
                   style = {{padding: 0, margin: 0}}/>
                </ListItemIcon> 
                {/* check if the item is in timestamp format as there is no controltype comming in filter data */}
                <ListItemText  primary={ x} style = {{color: '#33475b', padding: 0, margin: 0, paddingLeft: '10px'}}/>
                </ListItemButton>
                  </ListItem>
                
                </>
                )
                }) : <></>}
                </>
        ) 
    })
}
 function BuildMenu(props : any) {
    let presentationFiltersObj = buildListPresentationFilters(props.kagamiListFilterModel);


    let presentationComps = buildMenuComps(presentationFiltersObj)
   
    return <>
    <div style={{padding: '4px'}}>
    <TextField
                  placeholder="Search..." size="small" 
                //   value={searchProcessName}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                        sx={{
                          color:(theme) => theme.palette.secondary.main
                        }}
                        ></SearchIcon>
                      </InputAdornment>
                    )
                  }}
                //   onChange={(e: any) =>   kagamiPresentationFilterModel.state.SetSearchProcessName(e.target.value)}
                style={{width: '100%'}}/> 


                <List>
                    {presentationComps}
                </List>


 
        </div>
    </>
 }

