import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { buildDashboardSection } from '../builder/DashboardBuilder';
import {
  DashboardGridProps,
  DashboardModelProps,
  DashboardType,
  KagamiDashboardModel,
  useKagamiDashboardState
} from '../model/KagamiDashboardModel';
import { getDashboardData, setDashboardLayoutData } from '../service/DashboardService';
import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import _ from 'lodash';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { getIdForLayoutPage } from '../controller/KagamiDashboardController';

const useStyles = makeStyles(KagamiDashboardStyle);
export const highPriorityWidget = 'HIGH PRIORITY';

let kagamiDashboardModel: KagamiDashboardModel;

export function getDashboardModel() {
  return kagamiDashboardModel;
}

export function setDashboardModel(dashboard: KagamiDashboardModel) {
  kagamiDashboardModel = dashboard;
}

export const buildDashboardComponent = (dashboardModel: KagamiDashboardModel): JSX.Element => {
  return <KagamiDashboard key={'kagami-dashboard'} dashboardModel={dashboardModel} />;
};

export const KagamiDashboard = (props: DashboardModelProps) => {
  const dashboardModel = props.dashboardModel;
  const classes = useStyles();
  dashboardModel.state = useKagamiDashboardState(createState(dashboardModel));
  setDashboardModel(dashboardModel);

  useEffect(() => {
    getDashboardData(dashboardModel);
  }, []);
   return dashboardModel.state.isDataLoaded ? <div className={classes.dashboard_grid_layout}>{buildDashboardSection(dashboardModel)}</div> : <div></div>
};

function setPriorityAsFirst (components:any[], dashboardModel: KagamiDashboardModel){

  let index = components.findIndex((comp: any) => {
    return comp['key'].toLowerCase() === highPriorityWidget.toLowerCase();
  });
  console.log(index);
  if(index > -1){
    let higherPriority:any = components[index];
    components = [...components.slice(0, index), ...components.slice(index + 1)];
    components.unshift(higherPriority);
  }
  return components;
}

export const DashboardGrid = (props: DashboardGridProps) => {
  const classes = useStyles();
  const dashboardModel = props.dashboardModel;
  const components = setPriorityAsFirst(_.flattenDepth(props?.component), dashboardModel) ?? [];

  let layouts: any;
  
  if (props.dashboardModel.dashboardType === DashboardType.NOTIFICATIONS) {
    if (CommonUtils.isNotEmpty(props.dashboardModel.notificationLayouts)) {
      if (isAllComponentsLayoutAvailable(components, props.dashboardModel.notificationLayouts)) {
        layouts = props.dashboardModel.notificationLayouts;
      } else {
        layouts = getLayoutForExtraComponent(components, props.dashboardModel.notificationLayouts);
      }
    } else {
      layouts = {
        lg: getNotificationLayout('lg', components),
        md: getNotificationLayout('md', components),
        sm: getNotificationLayout('sm', components),
        xs: getNotificationLayout('xs', components),
        xxs: getNotificationLayout('xxs', components)
      };
    }
  } else if (props.dashboardModel.dashboardType === DashboardType.REPORTS) {
    if (CommonUtils.isNotEmpty(props.dashboardModel.reportLayouts)) {
      if (isAllComponentsLayoutAvailable(components, props.dashboardModel.reportLayouts)) {
        layouts = props.dashboardModel.reportLayouts;
      } else {
        console.log('length does not match add new layout for new reports');
        layouts = getLayoutForExtraComponent(components, props.dashboardModel.reportLayouts);
      }
    } else {
      layouts = {
        lg: getReportLayout('lg', components),
        md: getReportLayout('md', components),
        sm: getReportLayout('sm', components),
        xs: getReportLayout('xs', components),
        xxs: getReportLayout('xxs', components)
      };
    }
  }

  const onLayoutChange = _.debounce((currentLayout: Layout[], allLayouts: Layouts) => {
    for (const [key, values] of Object.entries(allLayouts)) {
      if (JSON.stringify(values) === JSON.stringify(currentLayout)) {
        dashboardModel.currentScreenSize = key;
      }
    }
  }, 0);

  const onDragAndResizeStop = _.debounce((layout: Layout[]) => {
    if (
      dashboardModel.dashboardType === DashboardType.NOTIFICATIONS &&
      JSON.stringify(props.dashboardModel.notificationLayouts[dashboardModel.currentScreenSize]) !==
        JSON.stringify(layout)
    ) {
      props.dashboardModel.notificationLayouts[dashboardModel.currentScreenSize] = layout;
      const payloadData: any = {
        pageName: 'NotificationLayout',
        reqData: JSON.stringify(props.dashboardModel.notificationLayouts)
      };
      if (getIdForLayoutPage('NotificationLayout')) payloadData['id'] = getIdForLayoutPage('NotificationLayout');
      setDashboardLayoutData(payloadData, props.dashboardModel);
    } else if (
      dashboardModel.dashboardType === DashboardType.REPORTS &&
      JSON.stringify(props.dashboardModel.reportLayouts[dashboardModel.currentScreenSize]) !== JSON.stringify(layout)
    ) {
      props.dashboardModel.reportLayouts[dashboardModel.currentScreenSize] = layout;
      const payloadData: any = {
        pageName: 'ReportLayout',
        reqData: JSON.stringify(props.dashboardModel.reportLayouts)
      };
      if (getIdForLayoutPage('ReportLayout')) payloadData['id'] = getIdForLayoutPage('ReportLayout');
      setDashboardLayoutData(payloadData, props.dashboardModel);
    }
  }, 500);

  const ResponsiveGridLayout = WidthProvider(Responsive);
  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      isDraggable
      isResizable
      resizeHandles = {['se', 'sw', 'ne', 'nw', 's', 'w', 'e', 'n']}
      onDragStop={onDragAndResizeStop}
      onResizeStop={onDragAndResizeStop}
      onLayoutChange={onLayoutChange}
      autoSize={true}
      draggableHandle=".drag-handle"
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
    >
      {components.map((component: any, index: any) => {
        if (dashboardModel.dashboardType === DashboardType.NOTIFICATIONS) {
          const indexKey = component.key ?? `notification-${index + 1}`;
          return (
            <div key={indexKey} className={`grid-component ${component?.key?.toLowerCase() == highPriorityWidget?.toLowerCase() ? 'dashboard-highpriority-widget' :'' }`}>
              {component}
            </div>
          );
        } else if (dashboardModel.dashboardType === DashboardType.REPORTS) {
          const indexKey = component.key ?? `report-${index + 1}`;
          return (
            <div key={indexKey} className={'grid-component'}>
              {component}
            </div>
          );
        } else {
          return <div>{component}</div>;
        }
      })}
    </ResponsiveGridLayout>
  );
};

function isAllComponentsLayoutAvailable(components: any[], layouts: any) {
  return components.every((comp: any) => {
    return Object.values(layouts).every((layouts: any) => {
      if (layouts.find((layout: any) => layout.i === comp.key) !== undefined) {
        return true;
      } else {
        return false;
      }
    });
  });
}

function getLayoutForExtraComponent(components: any[], layouts: any) {
  let _layout: any = {};
  console.log('length does not match add new layout for new notification');
  for (const [key, _louts] of Object.entries(layouts)) {
    let yaxis_peak: number;
    let yaxis_peak_height: number;
    let countOfNewComp: number = 0;
    _layout[key] = components.map((comp: any, index: number) => {
      if (Array.isArray(_louts)) {
        if (index == 0) {
          yaxis_peak = _louts
            .map((_ll: any) => _ll.y)
            .sort((y1, y2) => y1 - y2)
            .reverse()[0];
          yaxis_peak_height = _louts
            .filter((_ll: any) => _ll.y == yaxis_peak)
            .map((__ll: any) => __ll.h)
            .sort((h1, h2) => h1 - h2)
            .reverse()[0];
        }
        let comp_layout = _louts.find((_ll: any) => {
          return _ll.i === comp.key;
        });
        if (comp_layout !== undefined) {
          return comp_layout;
        } else {
          countOfNewComp += 1;
          return createNewLayout(key, comp, countOfNewComp, yaxis_peak, yaxis_peak_height);
        }
      }
    });
  }
  return _layout;
}

function getNotificationLayout(screenSize: any, components: any[]) {
  let _layout: { w: number; h: number; x: number; y: number; i: string }[] = [];
  if (screenSize == 'lg') {
    _layout = components.map((component: any, index: number) => {
      if((component.key).toLowerCase() == highPriorityWidget.toLowerCase()) { // if((component.key).toLowerCase() == "higher priority")
        return {
          w: 4,
          h: 3,
          x: index % 3 != 0 ? (index % 3 != 1 ? 8 : 4) : 0,
          y: 0,
          i: component.key ?? `notification-${index + 1}`,
          static: true
        };
      }else {
        return {
          w: 4,
          h: 3,
          x: index % 3 != 0 ? (index % 3 != 1 ? 8 : 4) : 0,
          y: 0,
          i: component.key ?? `notification-${index + 1}`
        };
      }
    });
  } else if (screenSize == 'md') {
    _layout = components.map((component: any, index: number) => {
      if((component.key).toLowerCase() == highPriorityWidget.toLowerCase()) {
        return { w: 5, h: 3, x: index % 2 == 0 ? 0 : 5, y: 0, i: component.key ?? `notification-${index + 1}`, static: true };
       }else {
        return { w: 5, h: 3, x: index % 2 == 0 ? 0 : 5, y: 0, i: component.key ?? `notification-${index + 1}` };
       }
    });
  } else if (screenSize == 'sm') {
    _layout = components.map((component: any, index: number) => {
      if((component.key).toLowerCase() == highPriorityWidget.toLowerCase()) {
        return { w: 6, h: 3, x: index % 2 == 0 ? 0 : 6, y: 0, i: component.key ?? `notification-${index + 1}`, static: true };
       }else {
        return { w: 6, h: 3, x: index % 2 == 0 ? 0 : 6, y: 0, i: component.key ?? `notification-${index + 1}` };
       }
    });
  } else if (screenSize == 'xs') {
    _layout = components.map((component: any, index: number) => {
      if((component.key).toLowerCase() == highPriorityWidget.toLowerCase()) {
        return { w: 4, h: 3, x: index % 2 == 0 ? 0 : 4, y: 0, i: component.key ?? `notification-${index + 1}`, static: true };
       }else {
        return { w: 4, h: 3, x: index % 2 == 0 ? 0 : 4, y: 0, i: component.key ?? `notification-${index + 1}` };
       }
    });
  } else if (screenSize == 'xxs') {
    _layout = components.map((component: any, index: number) => {
      if((component.key).toLowerCase() == highPriorityWidget.toLowerCase()) {
        return { w: 2, h: 3, x: index % 2 == 0 ? 0 : 2, y: 0, i: component.key ?? `notification-${index + 1}`, static: true };
       }else {
        return { w: 2, h: 3, x: index % 2 == 0 ? 0 : 2, y: 0, i: component.key ?? `notification-${index + 1}` };
       }
    });
  }
  return _layout;
}

function getReportLayout(screenSize: any, components: any[]) {
  const _layout: { w: number; h: number; x: number; y: number; i: string }[] = [];
  for (let index = 0; index < components.length; index++) {
    if (screenSize == 'lg') {
      _layout.push({
        w: 4,
        h: 3,
        x: index % 3 != 0 ? (index % 3 != 1 ? 8 : 4) : 0,
        y: 0,
        i: components[index].key ?? `report-${index + 1}`
      });
    } else if (screenSize == 'md') {
      _layout.push({ w: 5, h: 3, x: index % 2 == 0 ? 0 : 5, y: 0, i: components[index].key ?? `report-${index + 1}` });
    } else if (screenSize == 'sm') {
      _layout.push({ w: 6, h: 3, x: index % 2 == 0 ? 0 : 6, y: 0, i: components[index].key ?? `report-${index + 1}` });
    } else if (screenSize == 'xs') {
      _layout.push({ w: 4, h: 3, x: index % 2 == 0 ? 0 : 4, y: 0, i: components[index].key ?? `report-${index + 1}` });
    } else if (screenSize == 'xxs') {
      _layout.push({ w: 2, h: 3, x: index % 2 == 0 ? 0 : 2, y: 0, i: components[index].key ?? `report-${index + 1}` });
    }
  }
  return _layout;
}

function createNewLayout(
  screenSize: string,
  comp: any,
  countOfNewComp: number,
  yaxis_peak: number,
  yaxis_peak_height: number
) {
  // const _layout: { w: number; h: number; x: number; y: number; i: string }[] = [];
  if (screenSize == 'lg') {
    return {
      w: 4,
      h: 3,
      x: countOfNewComp % 3 != 0 ? (countOfNewComp % 3 != 1 ? 8 : 4) : 0,
      y: getYaxis(screenSize, countOfNewComp, yaxis_peak, yaxis_peak_height),
      i: comp.key ?? `report-${countOfNewComp + 1}`
    };
  } else if (screenSize == 'md') {
    return {
      w: 5,
      h: 3,
      x: countOfNewComp % 2 == 0 ? 0 : 5,
      y: getYaxis(screenSize, countOfNewComp, yaxis_peak, yaxis_peak_height),
      i: comp.key ?? `report-${countOfNewComp + 1}`
    };
  } else if (screenSize == 'sm') {
    return {
      w: 6,
      h: 3,
      x: countOfNewComp % 2 == 0 ? 0 : 6,
      y: getYaxis(screenSize, countOfNewComp, yaxis_peak, yaxis_peak_height),
      i: comp.key ?? `report-${countOfNewComp + 1}`
    };
  } else if (screenSize == 'xs') {
    return {
      w: 4,
      h: 3,
      x: countOfNewComp % 2 == 0 ? 0 : 4,
      y: getYaxis(screenSize, countOfNewComp, yaxis_peak, yaxis_peak_height),
      i: comp.key ?? `report-${countOfNewComp + 1}`
    };
  } else if (screenSize == 'xxs') {
    return {
      w: 2,
      h: 3,
      x: countOfNewComp % 2 == 0 ? 0 : 2,
      y: getYaxis(screenSize, countOfNewComp, yaxis_peak, yaxis_peak_height),
      i: comp.key ?? `report-${countOfNewComp + 1}`
    };
  }
}

function getYaxis(screenSize: string, countOfNewComp: number, yaxis_peak: number, yaxis_peak_height: number) {
  if (screenSize == 'lg') {
    return yaxis_peak + yaxis_peak_height * (Math.trunc(countOfNewComp / 4) + 1);
  } else if (screenSize == 'md') {
    return yaxis_peak + yaxis_peak_height * (Math.trunc(countOfNewComp / 3) + 1);
  } else if (screenSize == 'sm') {
    return yaxis_peak + yaxis_peak_height * (Math.trunc(countOfNewComp / 7) + 1);
  } else if (screenSize == 'xs') {
    return yaxis_peak + yaxis_peak_height * (Math.trunc(countOfNewComp / 5) + 1);
  } else if (screenSize == 'xxs') {
    return yaxis_peak + yaxis_peak_height * (Math.trunc(countOfNewComp / 3) + 1);
  }
}
