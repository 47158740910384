import { createState } from "@hookstate/core";
import { buildProcessPageHeaderName } from "../../ProcessPageBuildler";
import { kagamiProcessHeaderModel, kagamiProcessHeaderModelProps, useProcessHeaderState } from "../model/KagamiProcessHeaderModel";
import { getFormModel } from "../../form/controller/KagamiFormController";

export function buildProcessHeaderComponent(kagamiProcessHeaderModel: kagamiProcessHeaderModel) {
    return <KagamiProcessHeader key = 'Process Header' kagamiProcessHeaderModel = {kagamiProcessHeaderModel}/>
}

const KagamiProcessHeader = (props: kagamiProcessHeaderModelProps) => {
    props.kagamiProcessHeaderModel.state = useProcessHeaderState(createState(props.kagamiProcessHeaderModel))
    return (
       <div  className = 'ListHeader_name' >
        {props.kagamiProcessHeaderModel.state.headerVisible && buildProcessPageHeaderName(getFormModel())}
       </div>
    )
}

