import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const reportLandingPageBodyContent = (theme: Theme) =>
  createStyles({
    reportBodyContent: {
      minHeight: '85vh',
      overflow: 'hidden'
    },
    reportLandingPage: {
      display: 'grid',
      // gridTemplateColumns: 'repeat(8, 1fr)',
      gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
      gridTemplateRows: '1fr',
      gridGap: theme.spacing(2),
      alignItems: 'start',
      width: '100%',
      padding: theme.spacing(1),
      background: theme.palette.background.defaultBlue
    }
  });
