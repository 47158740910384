import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { createState } from '@hookstate/core';
import { makeStyles } from '@mui/styles';
import {
  ReportModalCustomButtonProps,
  ReportModalModel,
  ReportModalModelProps,
  useReportModalCustomButton,
  useReportModalState
} from '../model/ReportModalModel';
import { ReportModalStyle } from '../style/ReportModalStyle';
import CloseIcon from '@mui/icons-material/Close';
import { translateToLocalistaion } from '../../../../process/service/PresentationService';

const useStyles = makeStyles(ReportModalStyle);

export const buildReportModalComponent = (reportModalModel: ReportModalModel) => {
  return <ReportModal reportModalModel={reportModalModel} />;
};

export function ReportModal(props: ReportModalModelProps) {
  const classes = useStyles();
  const reportModalModel = props.reportModalModel;
  let translations = props?.reportModalModel?.translations;
  const reportModalState = useReportModalState(createState(reportModalModel));
  reportModalModel.state = reportModalState;

  const onCancel = () => {
    reportModalState.setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={reportModalModel.maxWidth}
      open={reportModalState.open}
      // sx={{height:`${reportModalModel.height}vh`}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes.headerContent}>
        <DialogTitle id={reportModalModel.title} className="report-title">
          {/* {reportModalModel.title} */}
          {translateToLocalistaion(translations, reportModalModel.title)}
        </DialogTitle>
        <CloseIcon className="close-button" onClick={onCancel} />
      </div>

      <DialogContent className={classes.bodyContent}>
        {reportModalModel.content !== undefined ? <Box>{reportModalModel.content}</Box> : <></>}
      </DialogContent>

      <DialogActions className={classes.footerContent}>
        {reportModalModel.defaultCancelButton && (
          <Button className={classes.cancelButton} onClick={onCancel}>
            {translateToLocalistaion(translations, 'Cancel')}
          </Button>
        )}
        {reportModalModel.listOfButton.map((btnConfig: any, index: number) => {
          return (
            <CustomReportModalButton
              key={`custom-report-modal-button-${index}-${btnConfig?.buttonName ?? ''}`}
              reportModalCustomButton={btnConfig}
            />
          );
        })}
      </DialogActions>
    </Dialog>
  );
}

const CustomReportModalButton = (props: ReportModalCustomButtonProps) => {
  const classes = useStyles();
  let data = {Submit: "Soumettre"}
  let translations = data;
  const reportModalCustomButton = props.reportModalCustomButton;
  const reportModalCustomButtonState = useReportModalCustomButton(createState(reportModalCustomButton));
  reportModalCustomButton.state = reportModalCustomButtonState;

  return (
    <>
      <Button
        disabled={reportModalCustomButtonState.disabledButton}
        variant="contained"
        className={`${classes.eachButton} ${reportModalCustomButton.buttonClassName}`}
        onClick={() => reportModalCustomButton.clickFunction()}
      >
        {/* {reportModalCustomButton.buttonName} */}
        {translateToLocalistaion(translations, reportModalCustomButton.buttonName)}
      </Button>
    </>
  );
};
