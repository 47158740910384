import { createState } from '@hookstate/core';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button/Button';
import React, { useEffect } from 'react';
import {
  MapReportsModel,
  MapReportsModelProps,
  useMapReportsViewState
} from '../Model/MapReportsModel';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiMapReportsView } from '../builder/ui/MapReportsView';
import { setAllRolesAndMapReportsData, mapReportsOnSave } from '../service/MapReportsService';
import { MapReportsViewModel } from '../builder/model/MapReportsViewModel';
import { KagamiReportHeaderModelStyle } from '../../ReportHeader/styles/ReportHeaderStyles';
import { translateToLocalistaion } from '../../../../../../process/service/PresentationService';

export const buildMapReportsComponent = (mapReportsModel: MapReportsModel,translations:any) => {
  return <MapReportView key={Math.random()} mapReportsModel={mapReportsModel} translations={translations}/>;
};

export const MapReportView = (props: MapReportsModelProps) => {
  const classes = makeStyles(KagamiReportHeaderModelStyle)();
  const [disable, setDisable] = React.useState(true);
  props.mapReportsModel.setDisableSave = setDisable;



  return (
    <>
      <div className={classes.root}>
        <table>
          <thead>
          <p className={classes.ReportsHeaderName}>{translateToLocalistaion(props.translations, "Manage Roles")}</p>
            <tr>
              <th>{translateToLocalistaion(props.translations, "Roles")}</th>
              <th>{translateToLocalistaion(props.translations, "Map Reports")}</th>
              <th>{translateToLocalistaion(props.translations, "Mapped Reports")}</th>
            </tr>
          </thead>
          <tbody>
          <LoadMapReportsData mapReportsModel={props.mapReportsModel} translations={props.translations}/>
          </tbody>
        </table>
        <Button
          variant="contained"
          color="success"
          style={{ position: 'fixed', bottom: '0px', right: '57px', width: '125px' }}
          disabled={disable}
          onClick={(event: any) => {
            mapReportsOnSave(props.mapReportsModel,setDisable);
          }}
        >
          {translateToLocalistaion(props.translations, "Save")}
        </Button>
      </div>
    </>
  );
};

const LoadMapReportsData = (props:MapReportsModelProps)=>{
  let getMapReportsData:any=[]
  const mapReportsModel = props.mapReportsModel;
  const mapReportState = useMapReportsViewState(createState(mapReportsModel));
  mapReportsModel.state = mapReportState;

  useEffect(() => {
    setAllRolesAndMapReportsData(mapReportsModel);
  }, []);
  if(!mapReportState.isDataLoaded){
    return <CircularProgress sx={{ marginTop: '4%', marginLeft: '140%' }} color="secondary" />;
  }else{
     getMapReportsData =  mapReportsModel.roleIdAndMappedReportData.map((rowData: any, index:number) => {
      let mapReportsViewModel = new MapReportsViewModel();
      mapReportsViewModel.name = rowData.roleId;
      mapReportsViewModel.options = mapReportsModel.dbReportList.map((ele: any) => ({reportName : ele.name, reportId : ele.id}));
      mapReportsViewModel.selectedData = rowData.reportsMapping;
      mapReportsViewModel.allSelectedData = mapReportsModel.allSelectedData;
      // mapReportsViewModel.roleIdAndMappedReportData = mapReportsModel.roleIdAndMappedReportData;
      return <KagamiMapReportsView key={`map-reports-${index}`} mapReportsViewModel={mapReportsViewModel} translations={props.translations} />;
  }
    )};
  return <>{getMapReportsData}</>
}
