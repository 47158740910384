import React from "react";
import { createState } from "@hookstate/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  KagamiModalModel,
  ModalModelProps,
  useKagamiModalModelState,
} from "../model/KagamiModalModel";
import { KagamiModalStyle } from "../style/KagamiModal";
import ClearIcon from "@mui/icons-material/Clear";
import {
  handleModalOnClose,
  handleModalOnOk,
} from "../controller/KagamiModalController";
import { VisibleConstants } from "../../../application/constants/VisibeConstants";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";
import Draggable from "react-draggable";
import { getActiveModel } from "../../../application/builder/home/builder/body/builder/process/controller/KagamiProcessController";
import { translateToLocalistaion } from "../../../application/builder/home/builder/body/builder/process/service/PresentationService";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Zoom
      timeout={{ appear: 1000, enter: 7000, exit: 7000 }}
      mountOnEnter
      unmountOnExit
      ref={ref}
      {...props}
    />
  );
});

export function builModal(kagamiModalModel: KagamiModalModel) {
  if (kagamiModalModel.isPickItem) {
    return (
      <KagamiModal
        key={kagamiModalModel.key}
        kagamiModalModel={kagamiModalModel}
      ></KagamiModal>
    );
  } else {
    return <KagamiModal kagamiModalModel={kagamiModalModel}></KagamiModal>;
  }
}

function PaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const useStyles = makeStyles(KagamiModalStyle);
const KagamiModal = (props: ModalModelProps) => {
  let classes = useStyles();
  let modalState: any = useKagamiModalModelState(
    createState(props.kagamiModalModel),
    props.kagamiModalModel.isPickItem
  );

  let translations: any;
  let activeModel = getActiveModel();
  translations =
    activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties
      ?.translation;

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    handleModalOnClose(props.kagamiModalModel);
  };
  props.kagamiModalModel.state = modalState;
  return (
    <Dialog
      fullWidth={true}
      scroll={"paper"}
      maxWidth={props.kagamiModalModel.modalWidth}
      open={modalState.isOpen}
      onKeyDown={(event) => {
        console.log("nothing");

        if (props.kagamiModalModel.isInfo) {
          handleClose(event, "keydown");
        }
      }}
      aria-labelledby="draggable-dialog-title"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      classes={{
        root: classes.modalRoot,
        paper: classes.modalPaper,
        scrollPaper: props.kagamiModalModel.modalOnTop
          ? classes.topScrollPaper
          : undefined,
        paperFullWidth: classes.paperFullWidth,
      }}
      TransitionComponent={Transition}
      aria-describedby="classic-modal-slide-description"
    >
      <IconButton
        className={classes.closeModalBtn}
        size="small"
        onClick={() => handleModalOnClose(props.kagamiModalModel)}
      >
        <ClearIcon fontSize="medium"></ClearIcon>
      </IconButton>

      <DialogTitle
        className={classes.modalHeader}
        style={{ cursor: "move" }}
        id={props.kagamiModalModel.isdragable ? "draggable-dialog-title" : ""}
      >
        {props.kagamiModalModel.title === "Error"
          ? "Validations Failed..."
          : props.kagamiModalModel.title}
      </DialogTitle>

      <DialogContent
        style={{
          textAlign: props.kagamiModalModel.error == true ? "start" : "center",
        }}
        className={
          checkActions(props.kagamiModalModel)
            ? `${classes.modalWithoutActions} ${classes.modalBody}`
            : classes.modalBody
        }
      >
        {props.kagamiModalModel.content}
      </DialogContent>
      <DialogActions
        style={{
          borderTop: checkActions(props.kagamiModalModel)
            ? "none"
            : "0.5px solid #ccc",
        }}
        className={classes.modalFooter}
      >
        <BuildModalActions kagamiModalModel={props.kagamiModalModel} />
      </DialogActions>
    </Dialog>
  );
};

const BuildModalActions = (props: any) => {
  const modalState = props.kagamiModalModel.state;
  let classes = useStyles();

  let translations: any;
  let activeModel = getActiveModel();
  translations =
    activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties
      ?.translation;

  return (
    <>
      <>
        {checkActions(props.kagamiModalModel) ? (
          <></>
        ) : (
          <div className={classes.model_buttons}>
            <Button
              color="secondary"
              variant="contained"
              disabled={modalState.buttonVisiblity}
              onClick={() => handleModalOnOk(props.kagamiModalModel)}
              size="small"
              style={{ width: "96px", height: "30.75px" }}
              className={classes.model_button}
            >
              {translateToLocalistaion(translations, VisibleConstants.okLabel)}
            </Button>
          </div>
        )}
      </>
    </>
  );
};

const checkActions = (kagamiModalModel: KagamiModalModel) => {
  if (
    kagamiModalModel.isModalDialogTemplate ||
    kagamiModalModel.isBulkUploadSave ||
    kagamiModalModel.isBulkUploadSaveActive ||
    kagamiModalModel.isVendorListButtonDisable ||
    kagamiModalModel.isImpersonate ||
    kagamiModalModel.isPickItem
  ) {
    return true;
  } else {
    return false;
  }
};
