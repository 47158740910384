import { createTheme, adaptV4Theme } from '@mui/material/styles';


import getColorPalette from '../theme/colors/KagamiColors';
const font = "'Lexend deca', sans-serif";




export const kagamiAppTheme = createTheme(adaptV4Theme({
  palette: getColorPalette(),
  typography: {
    fontFamily: font,
  },
  
  overrides: {    
    MuiOutlinedInput: {
    root: {
       
        // '&.Mui-error.Mui-focused  fieldset':{
        //  boxShadow: '0 0 3px 2px rgb(198 40 40 / 25%)',
        //  // borderColor:'rgb(198 ,40 ,40 ) !important',
        // },  
        // '&.Mui-focused fieldset': {  
        //   borderWidth:'0.2px !important', 
        //   // boxShadow: '0 0 3px 2px rgb(0 51 102 / 25%)',
        //   boxShadow:' rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
        // },
     
    },
    input: { 
      fontSize: '0.9rem',
      paddingTop: '0',
      paddingBottom: '0'
      },
    notchedOutline : {
      // borderWidth:'0.2px', 
      // boxShadow: '0 0 3px 2px rgb(0 51 102 / 25%)',
    }, 
     
  },
   
  MuiSelect:{
    select:{
      fontSize: '0.9rem',
    }
  },

  
    MuiInputLabel:{ 
      root: {
        // color: '#4cc8817',
        // color: '#B3B3B3',
        lineHeight:'1.2em',
        textTransform: 'capitalize',
        // padding: '0',
        fontSize: '14px',
        // fontSize: '0.8rem'
      },
      shrink: {
        // color: '#13344d',
        lineHeight:'1.45em',
        textTransform: 'capitalize',
        fontSize:'1rem'
      }
    },
 
    MuiFormControl: {
      root: {
        minHeight: '35px', 
        maxHeight: 'fit-content'
      },
    },
    // MuiOutlinedInput :{
    //   input: { 
    //   paddingTop: '0',
    //   paddingBottom: '0'
    //   }
    // },
    MuiInputBase: {
      root: {
        minHeight: '35px',  
        maxHeight: '90px'
      },
      // input: { 
      //   paddingTop: '0',
      //   paddingBottom: '0'
      //   }
    },
    MuiFormLabel: {
      root: {
        //bigger label text
        wordBreak:'break-word',
        whiteSpace:'pre-line',

      },
      asterisk: {
        color: '#f05252',
        '&$error': {
          color: '#f05252 !important'
        },
      }
    },
    MuiPaper:{
      root:{
        // backgroundColor:'#f5f8fa',
        '& .MuiCalendarPicker-root': {
          '& .MuiTypography-caption ': {
            color: '#05263d',
            fontWeight: 'bold'
          },
        },
        "&::-webkit-scrollbar":{
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb' :{
            backgroundColor: 'rgba(0,0,0,.4)',
        },
        '&::-webkit-scrollbar-track' :{
            background: 'hsla(0,0,0,.2)',
        },
      },
    }
  },
  
  // overrides:{
  //   MuiOutlinedInput:{
  //     root:{
  //       height:'40px',
  //       borderRadius:'3px',
  //       borderBottom:'0.5px solid #CCC'
  //     },
  //     input:{
  //       color:'#666',
  //       fontSize:'14px'
  //     }
  //   }, 
  //   MuiInputBase:{
  //     input:{
  //       color:'#666'
  //     }
  //   } ,
  //   MuiPaper:{
  //     root:{
  //       width:'100%'
  //     }
  //   },
    
   
  // }
}));
