import { State, useState } from '@hookstate/core';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { KagamiListModel } from '../../../model/KagamiListModel';
import { KagamiModalModel } from '../../../../../../../../../../../../components/modal/model/KagamiModalModel';

export class KagamiCardViewModel extends KagamiListModel {
  kagamiModalModel: KagamiModalModel;
  constructor(processName: string, processStepName: string, mainEntityId: string, presentation: any) {
    super(processName, processStepName, null, mainEntityId);
    this.state = null;
    this.presentation = presentation;
    this.kagamiModalModel = new KagamiModalModel();
  }
}

export interface KagamiCardViewModelProps {
  kagamiCardViewModel: KagamiCardViewModel;
}
export const wrapKagamiCardViewModelState = (state: State<KagamiCardViewModel>) => {
  let listState = state;

  return {
    getListLoaded() {
      return listState.isListLoaded.get();
    },
    setListLoaded(value: boolean) {
      listState.isListLoaded.set(value);
    }
  };
};

export const useCardViewState = (state: State<KagamiCardViewModel>) => {
  const cardViewState = wrapKagamiCardViewModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.CARD_VIEW_STATE, cardViewState);
  return cardViewState;
};
