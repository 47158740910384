import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import { getFormModel } from "../../../form/controller/KagamiFormController";
import { useKagamiControlModelState } from "../../model/kagamiControlModel";
import { handleCheckBoxOnChange } from "../controller/kagamiCheckboxController";
import {
  CheckboxFieldProps,
  KagamiCheckboxModel,
} from "../model/kagamiCheckboxModal";
import { KagamiCheckBoxStyle } from "../style/KagamiCheckBoxStyle";
import FormLabel from "@mui/material/FormLabel";
import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";

export const buildCheckboxComponent = (
  kagamiCheckboxModel: KagamiCheckboxModel
) => {
  return (
    <KagamiCheckboxField
      kagamiCheckboxModel={kagamiCheckboxModel}
    ></KagamiCheckboxField>
  );
};

const useStyles = makeStyles(KagamiCheckBoxStyle);

const KagamiCheckboxField = (props: CheckboxFieldProps) => {
  const classes = useStyles();
  let fieldState = useKagamiControlModelState(
    props.kagamiCheckboxModel.createState
  );
  props.kagamiCheckboxModel.state = fieldState;
  const uiSettingsClassName = props.kagamiCheckboxModel.getControlWidth(
    props.kagamiCheckboxModel.uiSettings
  );
  const fontProperties = props.kagamiCheckboxModel.getFontStyling(
    props.kagamiCheckboxModel.uiSettings
  );

  getFormModel().formControlStates[
    props.kagamiCheckboxModel.controlIdentifier
  ] = fieldState;

  if (fieldState.isVisible === false) {
    return null;
  } else {
    return props.kagamiCheckboxModel.controlType === "boolean" &&
      props.kagamiCheckboxModel.readonly ? (
      <div className={`${uiSettingsClassName} ${classes.root}`}>
        <div className={classes.readOnlyField_cont}>
          <FormLabel
            component="span"
            className={classes.controlLabel}
            style={{
              color: fontProperties.labelFontColor,
              fontWeight: fontProperties.labelFontBold,
              fontStyle: fontProperties.labelFontItalic,
              textDecoration: fontProperties.labelFontUnderline,
            }}
          >
            {" "}
            {props.kagamiCheckboxModel.controlDisplayName}
          </FormLabel>
          <div>
            {CommonUtils.isNotEmpty(fieldState.controlValue)
              ? props.kagamiCheckboxModel.controlValue.toString()
              : "false"}
          </div>
        </div>
      </div>
    ) : (
      <div className={`${uiSettingsClassName} ${classes.root}`}>
        <FormControl
          required={fieldState.isMandatory}
          // error={error}
          id={props.kagamiCheckboxModel.controlIdentifier}
          className={
            "field " +
            (fieldState.isReadOnly ? "readOnlyField" : "defaultField")
          }
          // variant="standard"
        >
          <FormLabel
            component="div"
            style={{
              color: fontProperties.labelFontColor,
              fontWeight: fontProperties.labelFontBold,
              fontStyle: fontProperties.labelFontItalic,
              textDecoration: fontProperties.labelFontUnderline,
            }}
          >
            {props.kagamiCheckboxModel.controlDisplayName}
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              checked={fieldState.controlValue}
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  sx={{
                    color: "0.8px solid #000",
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
              }
              onKeyPress={(event: any) => {
                if (event.code === "Enter") {
                  handleCheckBoxOnChange(
                    props.kagamiCheckboxModel,
                    !props.kagamiCheckboxModel.controlValue
                  );
                }
              }}
              label=""
              onChange={(e, checked) =>
                handleCheckBoxOnChange(props.kagamiCheckboxModel, checked)
              }
            />
          </FormGroup>
          {fieldState.errorMessage ? (
            <span style={{ color: "#f05252", fontSize: "12px" }}>
              {fieldState.errorMessage}
            </span>
          ) : null}
        </FormControl>
      </div>
    );
  }
};

export default KagamiCheckboxField;
