import { KagamiApplicationModel, useApplicationState } from '../model/KagamiApplicationModel';
import { RenderLogin, RenderPage } from '../builder/KagamiApplicationBuilder';
import { createState } from '@hookstate/core';
import { KagamiApplicationController } from '../controller/KagamiApplicationController';
import { KagamiHomeModel } from '../../pages/static/home/model/KagamiHomeModel';

export const BuildApplication = (appModel: KagamiApplicationModel) => {
  let appState = useApplicationState(createState(appModel));
  appModel.state = appState;
  KagamiApplicationController.applicationModel = appModel;
  if (appState.isUserLoggedIn) {
    let kagamiPageModel: KagamiHomeModel = new KagamiHomeModel();
    return RenderPage(kagamiPageModel);
  } else {
   // let loginModel = KagamiApplicationController.getAppplicationModel().loginModel;
    //return RenderLogin(loginModel);
  }
};
