import { State, useState } from '@hookstate/core';
import { KagamiNotificationModel } from './KagamiNotificationModel';

export class KagamiHeaderNotificationModel extends KagamiNotificationModel {
  open: boolean;
  constructor(title: any) {
    super(title);
    this.title = title;
    this.open = false;
  }
}

export const wrapKagamiHeaderNotificationModelState = (state: State<KagamiHeaderNotificationModel>) => {
  let notificationState = state;

  return {
    get isOpen() {
      return notificationState.open.get();
    },

    setOpen(isOpen: boolean) {
      notificationState.open.set(isOpen);
    },

    get content() {
      return notificationState.content.get();
    },

    setContent(content: any) {
      notificationState.content.set(content);
    },
    get selectTab() {
      return notificationState.selectTab.get();
    },

    setselectTab(selectTabName: any) {
      notificationState.selectTab.set(selectTabName);
    }
  };
};
export const useKagamiHeaderNotificationModelState = (state: State<KagamiHeaderNotificationModel>) =>
  wrapKagamiHeaderNotificationModelState(useState(state));
