import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiNumberStyle = (theme: Theme) =>
  createStyles({
    root: {
      '& input': {
        fontSize:'0.9rem',
      },
      '& textarea':{
        fontSize:theme.typography.caption.fontSize

      },
      
      // '& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
      //   fontSize: theme.typography.caption.fontSize,
      //   lineHeight: '0.7rem'
      // },
      // '& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
      //   fontSize:'0.9rem',
      //   lineHeight: '1.3rem'
      // }
    },
    visuallyHidden: {
      position: 'absolute',
      width: theme.spacing(0),
      height: theme.spacing(0),
      margin: `${theme.spacing(0)} !important`,
      border: theme.spacing(0),
      padding: theme.spacing(0),
      clipPath: 'inset(100%)',
      clip: 'rect(0 0 0 0)',
      overflow: 'hidden'
    },
    controlLabel : {
      '&.MuiFormLabel-root ': {
        fontSize: '13px',
        // color: theme.palette.info.dark,
      }, 
    } ,
    readOnlyField: {
      margin: '0',
      fontSize: '14px',
      color: theme.palette.primary.dark,
      // marginLeft: theme.spacing(-0.5)
    },
    toWordsStyle: {
      margin: '0',
      fontSize: theme.spacing(1.25),
      color: theme.palette.primary.dark,
    },
    errMessage:{
      margin: '0',
      fontSize: '14px',
      color: theme.palette.error.main,
    },
    readOnlyField_cont: {
      // paddingLeft: theme.spacing(1.6),
      // marginTop: theme.spacing(-1.375)
    }
  });

 