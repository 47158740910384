import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
export const KagamiCrossTabStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      float: 'left',
      alignContent: 'center',
      margin: theme.spacing(2),
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      alignItems: 'flex-strech',
      marginTop: theme.spacing(4),
      borderRadius: theme.spacing(1)
    },
  });
