import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiLoginThreeStyle = (theme: Theme) =>
  createStyles({
    root: {
      background: '#b6c5cf',
      width: '100vw',
      height: '100vh',
      minWidth: '100vw',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiInputBase-root': {   
        '&.Mui-error.Mui-focused  fieldset':{
          boxShadow: '0 0 3px 2px rgb(198 40 40 / 25%) !important',
          // borderColor:'rgb(198 ,40 ,40 ) !important',
         },  
         '&.Mui-focused fieldset': {  
           borderWidth:'1px !important', 
           // boxShadow: '0 0 3px 2px rgb(0 51 102 / 25%)',
           boxShadow:' rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important',
         },
         
      },

      '& .MuiFormLabel-root' :{
        fontSize:'16px',
        marginBottom:'5px',
      },
      '& .MuiOutlinedInput-input': {
        fontSize:'16px'
      },
      '& .MuiOutlinedInput-root': {
        minHeight:'40px',
      }
    },
    content_container: {
      width: '85%',
      height: '85%',
      borderRadius: theme.spacing(2),
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        maxWidth: '1190px',
        maxHeight: '620px',
      },
      [theme.breakpoints.down('md')]: {
        maxHeight:'500px',
        paddingTop:'30px'

      },
    },
    main_heading: {
      fontSize: '2vw'
    },
    intro_Tagline: {
      fontSize: '1vw',
      padding: '8px 80px',
    },
   
    intro_img: {
      // height: 'auto',
      // width: 'auto'
     height: '50%',
      // width: 'auto',
    },
    brandLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    image_part: {
      width: '50%',
      height: '100%',
      borderRadius:'16px 0 0 16px',
      display: 'flex',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor: '#eaf4f8',


      [theme.breakpoints.down('md')]: {
        display:'none',
      },
    },
    form_part: {
      width: '50%',
      height: '100%',
      display:'flex',
      alignItems:'center',
      flexDirection: 'column',
      position:'relative',
      justifyContent:'center',
      [theme.breakpoints.down('md')]: {
        width:'100%',
        paddingLeft: '5vw',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0vw',
      },

    },
    logo_img: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: '4vh',
      width: 'auto'
    },
    login_card_cont: {
      width:'325px',
      height:'500px',
      [theme.breakpoints.down('sm')]:{
        width: '100%',
        padding: '0px 20px'
      },
    },
    login_card: {
      // padding: '2vw',
      // paddingTop: '0.5vw',
      
    },
    login_form: {
      
      display: 'flex',
      
      flexDirection: 'column',
      // gap: theme.spacing(1.5),
      // height: '50%',
      // width:'50%',

         },
    errMsg: {
      color: theme.palette.error.main,
      height:'50px',
      fontSize:'0.9rem',
    },
    forgot: {
       display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      '& a':  {
        color:theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]:{
          fontSize: '12px',
        } 
      }
      ,
      '& a:hover':{
        textDecoration:'underline', 
      }
    }, 
    company_logo: {
      textAlign: 'center',
      height:'75px',
      '& img': {
        // height:'7vh'
      }
    },
    formPart: {
      // height:'270px',
      width:'100%',
      display:'flex',
      flexDirection:'column',
      gap:'20px',
      justifyContent:'center',
      // marginBottom:'55px'


    },
    footer: {
      margin:`${ theme.spacing(1)} 0`,
      marginTop:'auto',
      textAlign: 'center',
      
    position: 'absolute',
    bottom: 0,
    width: 'fit-content',
    right: '8px',
    '& span' : {
      width:'max-content',
      [theme.breakpoints.down('sm')]:{
        fontSize: '11px',
      },
    },
    [theme.breakpoints.down('sm')]:{
      left: '50%',
      bottom: '0%',
      transform: 'translate(-50%, 0%)',
    }
    },
    company_logo_size: {
      // maxWidth: '30%',
      // maxHeight: '30%',
      width: 'auto',
      maxHeight:' 65px',
      maxWidth: '230px',
    },
    loginBtn: {
      width:'200px',
      height:'35px',
      marginLeft:' 62.5px',
      marginRight:' 62.5px',
      marginTop:'30px',
      [theme.breakpoints.down('sm')]: {
        margin:'10px auto 0px',
        width:'150px',
        height:'30px',
      },
    },
  });
