import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import intersectionBy from 'lodash/intersectionBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
export const getEntityConsumedFromPresentationRule = function (presentationRule:any) {
    var prefix =  get(presentationRule,"source.parent.entityPrefix") ?  get(presentationRule,"source.parent.entityPrefix") : '';
    if( get(presentationRule,"source.parent.entityId")){
        return prefix +  get(presentationRule,"source.parent.entityId");
    }else{
        return prefix;
    }
};

export const getDispPresentationRuleConfig = function (presentationRule:any) {
    let dispPresentationRules:any = {};
    if (presentationRule.source && presentationRule.source.parent) {
        let displayAttributes =  map(presentationRule.source.parent.for_attr, function (attr) {
            return {
                name: attr.name,
                type: attr.type
            }
        });
        if(displayAttributes[0] == undefined){
            displayAttributes.shift();
        }
        let index= findIndex(displayAttributes,function(obj){
            return obj.name==="id"
        });
        if(index!==-1){
            displayAttributes.shift();
        }
        dispPresentationRules.$$displayAttributes = displayAttributes;
        if(presentationRule.uiSettings && presentationRule.uiSettings.visibleAttributes){
            let temp= map(presentationRule.uiSettings.visibleAttributes,function(str){return {name:str }});
            dispPresentationRules.$$visibleAttributes =  intersectionBy(displayAttributes,temp,'name');
            // if( isEmpty(presentationRule.$$visibleAttributes)){
            if( isEmpty(presentationRule?.uiSettings?.visibleAttributes)){
                dispPresentationRules.$$visibleAttributes = displayAttributes;
            }
        }else{
            dispPresentationRules.$$visibleAttributes = displayAttributes;
        }
    } else {
        if(presentationRule.uiSettings && presentationRule.uiSettings.visibleAttributes){
            let temp= map(presentationRule.uiSettings.visibleAttributes,function(str){return {name:str }});
            dispPresentationRules.$$visibleAttributes = temp;
        }
    }
    return dispPresentationRules;
};
export const isPreviewColumn = (type: any,value:any) => {
    if(type == 'event'){
        return value?.column?.colDef?.cellRendererParams?.attributePresentation?.htmlControl ==  'file'||
         value?.column?.colDef?.cellRendererParams?.attributePresentation?.htmlControl == "multiselect" ||
         value?.column?.colDef?.cellRendererParams?.attributePresentation?.htmlControl == "customactivitylog" ||
         value?.column?.colDef?.cellRendererParams?.attributePresentation?.htmlControl == "comments"
    }
    return false;
}