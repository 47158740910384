import { createState } from '@hookstate/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VisibleConstants } from '../../../../../../../../../../../constants/VisibeConstants';
import {
  TriggerAlertModalModel,
  TriggerAlertModalModelProps,
  useTriggerAlertModalState
} from '../model/TriggerAlertModalModel';
import { TriggerAlertStyle } from '../style/TriggerAlertStyle';
import { handleTrigger } from '../controller/TriggerAlertModalController';
import { getActiveModel } from '../../../../../controller/KagamiProcessController';
import { translateToLocalistaion } from '../../../../../service/PresentationService';

const useStyles = makeStyles(TriggerAlertStyle);

export const buildTriggerAlertModalComponent = (triggerAlertModalModel: TriggerAlertModalModel) => {
  return <TriggerAlertModal key={triggerAlertModalModel.key} triggerAlertModalModel={triggerAlertModalModel} />;
};

const TriggerAlertModal = (props: TriggerAlertModalModelProps) => {

  const classes = useStyles();
  const triggerAlertModalModel = props.triggerAlertModalModel;
  const triggerAlertModalState = useTriggerAlertModalState(createState(triggerAlertModalModel));
  triggerAlertModalModel.state = triggerAlertModalState;
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;

  return (
    <Dialog
      fullWidth={true}
      scroll={'paper'}
      open={triggerAlertModalState.isOpen}
      classes={{
        root: classes.modalRoot,
        // paper: classes.modal,
        paperFullWidth: classes.paperFullWidth
      }}
      aria-labelledby={props.triggerAlertModalModel.alertName}
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle className={classes.modalHeader} color="secondary" id={props.triggerAlertModalModel.alertName}>
        <div></div>
       {/* <>{props.triggerAlertModalModel.alertName}</>  */}
       <>{translateToLocalistaion(translations, props.triggerAlertModalModel.alertName)}</> 
        {/* <IconButton
        size='small'
        onClick={() => triggerAlertModalState.setOpen(false)}>
          <CloseIcon fontSize='small'/>
        </IconButton> */}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContentRoot
        }}
        className={classes.modalBody}
      >
        {/* {props.triggerAlertModalModel.alertMessage} */}
        {translateToLocalistaion(translations, props.triggerAlertModalModel.alertMessage)}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <div className={classes.model_button}>
          <Button onClick={() => handleTrigger(true)}  style={{ color : '#fff'  }}>
          {translateToLocalistaion(translations, 'Yes')}
          </Button>
        </div>
        <div className={classes.model_buttons}>
          <Button variant="outlined" onClick={() => handleTrigger(false)} color='error'>
            {/* {VisibleConstants.cancelLabel} */}
          {translateToLocalistaion(translations, VisibleConstants.cancelLabel)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
