import { createState, useState } from '@hookstate/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, Button, ButtonGroup, Menu, MenuItem, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AgGridReact } from 'ag-grid-react';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { downloadExcel, downloadPDF, onDetailedExcelDownload } from '../../ledgerAccounts/service/LedgerExportService';
import { buildLedgerDetailsModal } from '../../modal/controller/ledgerDetailsModalController';
import { handleOnLedgerClick } from '../controller/SubLedgerReportController';
import { SubLedgerReportModel, SubLedgerReportModelProps, useSubLedgerReportModelState } from '../model/SubLedgerReportModel';
import { getData, handledColumnsBasedOnProjects, makeFooterRowData, makeLedgerData, makeReport, onChangeAccount, onChangeControlAccount, onChangeDependedValue, onChangeState } from '../service/SubLedgerReportService';

export const BuildSubLedgerReportView = (subLedgerReportModel: SubLedgerReportModel) => {
  return (
    <SubLedgerReportView key={'sub-ledger-report'} subLedgerReportModel={subLedgerReportModel}></SubLedgerReportView>
  );
};


const SubLedgerReportView = (props: SubLedgerReportModelProps) => {
  //Open
  const [startDateCalendar, setStartDateCalendar] = React.useState(false);
  const [endDateCalendar, setEndDateCalendar] = React.useState(false);
  // Date
  let startDateYear=new Date();
  startDateYear.setDate(1);
  startDateYear.setMonth(0);
  let yearStartDate = new Date(startDateYear).toISOString().substring(0, 10);
  let todayDate = new Date().toISOString().substring(0, 10);
  const [startDate, setStartDate] = React.useState(yearStartDate);
  const [endDate, setEndDate] = React.useState(todayDate);
  const [controlAccountType, setControlAccountType] = React.useState('');
  const [controlAccountValue, setControlAccountValue] = React.useState([]);
  const [controlAccountSubValue, setControlAccountSubValue] = React.useState('');
  const [selectstate,setSelectState]=React.useState(false);
  const [selectproject, setSelectProject] = React.useState(false)
  const [controlAccount,setControlAccount] = React.useState(false);
  //Excel 
  const [excelAnchorEl, setExcelAnchorEl] = React.useState<null | HTMLElement>(null);
  const excelOpen = Boolean(excelAnchorEl);
  const excelHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExcelAnchorEl(event.currentTarget);
  };
  
  const onLedgerDetails = (row:any) => {
    let ledgerInputs:any = {
      invoiceNo:row.data.docno,
      transType:row.data.transtype,
      reportType: inputReportConfig.reportConfigSummary.type,
      currency: undefined
    };

    handleOnLedgerClick(ledgerInputs,row);
  }
  
  const onDetailedExcel = () => {
    onDetailedExcelDownload(inputReportConfig);
  };

  const excelHandleClose = () => {
    setExcelAnchorEl(null);
  };
  const onDownloadExcel = (index: any) => {
    setExcelAnchorEl(null);
    downloadExcel( index, props.subLedgerReportModel);
  };

  const onDownloadPdf = (type:any,index:any) => {
    if(type == 'potrait') {
      setPrintPotraitAnchorEl(null);
    }else if(type == 'landscape') {
      setPrintLandScapeAnchorEl(null);
    }
    downloadPDF(type,index,props.subLedgerReportModel);
  };

  //Print Potrait 
  const [printPotraitAnchorEl, setPrintPotraitAnchorEl] = React.useState<null | HTMLElement>(null);
  const printPotraitOpen = Boolean(printPotraitAnchorEl);
  const printPotraitHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPrintPotraitAnchorEl(event.currentTarget);
  };
  const printPotraitHandleClose = () => {
    setPrintPotraitAnchorEl(null);
  };
  

  //Print Land Scape 
  const [printLandScapeAnchorEl, setPrintLandScapeAnchorEl] = React.useState<null | HTMLElement>(null);
  const printLandScapeOpen = Boolean(printLandScapeAnchorEl);
  const printLandScapeHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPrintLandScapeAnchorEl(event.currentTarget);
  };
  const printLandScapeHandleClose = () => {
    setPrintLandScapeAnchorEl(null);
  };


  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader:false,
    reportInfo: {}
  });
  let subLedgerReportModel = props.subLedgerReportModel;
  let subLedgerChartState = useSubLedgerReportModelState(createState(subLedgerReportModel));
  subLedgerReportModel.state = subLedgerChartState;
  let staticFilterData :any;
  if (subLedgerReportModel?.reportData?.staticFilterData) {
    staticFilterData = subLedgerReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo:any ={}
  if(subLedgerReportModel?.reportData?.gridReportInfo) {
    gridReportInfo  = subLedgerReportModel.reportData['gridReportInfo'];
  }

  let inputReportConfig= (props.subLedgerReportModel.reportConfig);
  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(subLedgerReportModel, inputReportConfig);
    let ledgerData = await getData(subLedgerReportModel,inputReportConfig);
    let rowData = await makeLedgerData(subLedgerReportModel,ledgerData);
    let footerRowData = await makeFooterRowData(subLedgerReportModel,ledgerData);
    handledColumnsBasedOnProjects(reportInfo,ledgerData);
    reportInfo['pinnedBottomRowData']= footerRowData;
    reportInfo['rowData']= rowData;
    reportInfo['dbLedgerData'] = ledgerData;
    const obj = Object.assign({}, reportInfo);
    // props.subLedgerReportModel.reportConfig
    props.subLedgerReportModel.reportData['gridReportInfo'] = obj;
    // runReportConfig.reportInfo.set(obj);
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  const handleDependedChange = async (value: any) => {
    let staticData = await onChangeDependedValue(value, subLedgerReportModel);
    setControlAccountType(value);
    let controlAccountData: any = [];
    let accountResult: any = [];
    if (staticData?.data) {
      controlAccountData = cloneDeep(staticData.data.controlAccount);
      accountResult = cloneDeep(staticData.data.accountResult);
    }
    staticFilterData['controlAccount'] = controlAccountData;
    staticFilterData['accountResult'] = accountResult;
    setControlAccountValue([]);
    setControlAccountSubValue('');
    runReportConfig.loadFilterData.set(true);
  }
  const handleControlAccountChange = async (value: any) => {
    let staticData = await onChangeControlAccount(value, subLedgerReportModel, inputReportConfig['classifier1']);
    let accountResult: any = [];
    if (staticData?.data) {
      accountResult = cloneDeep(staticData.data.accountResult);
    }
    staticFilterData['accountResult'] = accountResult;
    runReportConfig.loadFilterData.set(true);
  }
  const handleAccountChange = async (value: any) => {
    let gstData = await onChangeAccount(value, subLedgerReportModel);
    staticFilterData['gst'] = gstData;
    runReportConfig.loadFilterData.set(true);
  }

  const handleStateChange =  async (value:any) => {
    let  projectPlan =[];  
      if(value && value.length > 0 ){
        projectPlan  = await  onChangeState(value,subLedgerReportModel);
      }else {
        projectPlan = staticFilterData['allProjects'];
      }
      staticFilterData['projectPlan'] = projectPlan;
      runReportConfig.loadFilterData.set(true);
  }
  const handleStaticFilters = (value:any,type:any) => {
    if(type == 'startDate' || type =='endDate'){
      if(  !inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {};
      }
      inputReportConfig['leftPanel'][type] =value;
    }else if(type == 'state' || type =='projectPlan' || type =='controlAccount' ){
      inputReportConfig[type] =value && value.length > 0 ? value.join(","):[] ;
    }else {
      inputReportConfig[type] = value;
    }

    
  }
  return (
    <div className="Report-MuiFormControl-root">

      {buildLedgerDetailsModal(props.subLedgerReportModel.modelPopup)}

      {/* fields  */}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {/* Sub Organization */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ledgerSubOrganization"
            options={staticFilterData  &&  staticFilterData.suborganization||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Sub Organization"
                placeholder="Select Sub Organization"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters([newValue],'subOrganization');
            }}

          />
        </Grid>
        <Grid item xs={9}>
        </Grid>
          {/* Dependet values */}
          <Grid item xs={12}>
          <FormControl>
            <RadioGroup row name="row-radio-buttons-group"
             onChange={(event:any,newValue:any|null) => {
              handleDependedChange(newValue);
              handleStaticFilters(newValue,'classifier1');
              }}>
              <FormControlLabel value="employee" control={<Radio />} label="Employee" />
              <FormControlLabel value="customer" control={<Radio />} label="Customer" />
              <FormControlLabel value="vendor" control={<Radio />} label="Vendor" />
              <FormControlLabel value="bank" control={<Radio />} label="Bank" />
              <FormControlLabel value="project" control={<Radio />} label="Project" />
            </RadioGroup>
          </FormControl>
          </Grid>

          {/* Control account  */}
          {staticFilterData?.controlAccount && staticFilterData.controlAccount.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="controlAccount"
            value={controlAccountValue}
            multiple
            options={staticFilterData  &&  staticFilterData.controlAccount ||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Control Account"
                placeholder={controlAccount === false?"Select Control Account":''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              setControlAccountValue(newValue)   // setting state to visible data after select
              handleControlAccountChange(newValue);
              handleStaticFilters(newValue,'classifier2');
              if(newValue.length>0){setControlAccount(true)}
              else{
                setSelectState(false)
              }
            }}
          />
        </Grid>
        }
        {/* Account value */}
        {staticFilterData?.accountResult && staticFilterData.accountResult.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ledgerAccountValue"
            value={controlAccountSubValue}
            options={staticFilterData  &&  staticFilterData.accountResult||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                required
                {...params}
                label={controlAccountType}
                placeholder="Select Value"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              setControlAccountSubValue(newValue);   // setting state to visible data after select
              handleAccountChange(newValue);
              handleStaticFilters(newValue,'selectedValue');
            }}
          />
        </Grid>
        }
        
        {/* Currency */}
        {staticFilterData?.currency && staticFilterData.currency.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ledgerCurrency"
            options={staticFilterData  &&  staticFilterData.currency||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                required
                {...params}
                label="Currency List "
                placeholder="Select Currency"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'selectedCurrency');
            }}
          />
          </Grid>  
          }
          {/* GST */}
          {runReportConfig.loadFilterData.get() && staticFilterData?.gst && staticFilterData.gst.length > 0 && 
          <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ledgerAccount"
            options={staticFilterData  &&  staticFilterData.gst||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="GST"
                placeholder="Select GST"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </Grid>
          }
        {/* State */}
        {runReportConfig.loadFilterData.get() && staticFilterData?.state && staticFilterData.state.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
           size="small"
            multiple
            id="ledgerState"
            options={staticFilterData  &&  staticFilterData.state||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="State"
                placeholder={selectstate === false?"Select State":''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStateChange(newValue);
              handleStaticFilters(newValue,'state');
              if(newValue.length>0){setSelectState(true)}
              else{
                setSelectState(false)
              }
            }}
          />
        </Grid>
          }
        {/* Project */}
        {runReportConfig.loadFilterData.get() && staticFilterData?.projectPlan && staticFilterData.projectPlan.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            multiple
            id="ledgerProject"
            options={staticFilterData  &&  staticFilterData.projectPlan||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Project"
                placeholder={selectproject === false?"Select Project":''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'projectPlan');
              if(newValue.length>0){setSelectProject(true)}
              else{
                setSelectProject(false)
              }
            }}
          />
        </Grid>
          }
         {/* From Date */}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month','day']}
              open={startDateCalendar}
              onOpen={() => setStartDateCalendar(true)}
              onClose={() => setStartDateCalendar(false)}
              label="From Date"
              value={startDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setStartDate(newDate);
                  let val = newDate+" 00:00:00";
                  handleStaticFilters(val,'startDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                    required
                    fullWidth
                    className='full-width'
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setStartDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
         {/* To Date */}
         <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            views={['year', 'month','day']}
              open={endDateCalendar}
              onOpen={() => setEndDateCalendar(true)}
              onClose={() => setEndDateCalendar(false)}
              label="To Date"
              value={endDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setEndDate(newDate);
                  let val = newDate+" 23:59:59";
                  handleStaticFilters(val,'endDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                    required 
                    fullWidth
                    className='full-width'
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setEndDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>

      </Grid>
        {/* fields end */}
      {/* Run */}
      <Grid container style={{ margin: '10px',textAlign:'right'}} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        <Grid item xs={12}>
        <ButtonGroup>
          {/* excel Donwloader  */}
          
            <div>
          <Button  style={{ margin: '2px',textAlign:'center'}} variant="contained" onClick={() => { runReport() }}>
            Run
          </Button>
          </div>
          <div>
            <Button
              style={{ margin: '2px',textAlign:'center'}}
              id="excel-button"
              variant="contained"
              onClick={excelHandleClick}
             >
              Excel
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="excel-menu"
              anchorEl={excelAnchorEl}
              open={excelOpen}
              onClose={excelHandleClose}
              >
              <MenuItem onClick={() => onDownloadExcel(0)}>With Narration</MenuItem>
              <MenuItem onClick={() => onDownloadExcel(1)}>Without Narration</MenuItem>
              <MenuItem onClick={() => onDownloadExcel(2)}>With Narration & Closing Balance</MenuItem>
              <MenuItem onClick={() => onDownloadExcel(3)}>Without Narration & Closing Balance</MenuItem>
            </Menu>
          </div>
          <Button  style={{ margin: '2px',textAlign:'center'}} variant="contained" onClick={() => { onDetailedExcel() }}>
            Detailed Excel
          </Button>
          {/* print potrait */}
            <div>
              <Button
                style={{ margin: '2px',textAlign:'center'}}
                id="printPotrait-button"
                variant="contained"
                onClick={printPotraitHandleClick}
              >
                Print Potrait
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="printPotrait-menu"
                anchorEl={printPotraitAnchorEl}
                open={printPotraitOpen}
                onClose={printPotraitHandleClose}
              >
                <MenuItem onClick={() => onDownloadPdf('potrait',0)}>With Narration</MenuItem>
                <MenuItem onClick={() => onDownloadPdf('potrait',1)}>Without Narration</MenuItem>
                <MenuItem onClick={() => onDownloadPdf('potrait',2)}>With Narration & Closing Balance</MenuItem>
                <MenuItem onClick={() => onDownloadPdf('potrait',3)}>Without Narration & Closing Balance</MenuItem>
              </Menu>
            </div>
          {/*  print LandScape */}
            <div>
              <Button
                style={{ margin: '2px',textAlign:'center'}}
                id="printLandScape-button"
                variant="contained"
                onClick={printLandScapeHandleClick}
              >
                Print LandScape
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="printLandScape-menu"
                anchorEl={printLandScapeAnchorEl}
                open={printLandScapeOpen}
                onClose={printLandScapeHandleClose}
                 >
                <MenuItem onClick={() => onDownloadPdf('landscape',0)}>With Narration</MenuItem>
                <MenuItem onClick={() => onDownloadPdf('landscape',1)}>Without Narration</MenuItem>
                <MenuItem onClick={() => onDownloadPdf('landscape',2)}>With Narration & Closing Balance</MenuItem>
                <MenuItem onClick={() => onDownloadPdf('landscape',3)}>Without Narration & Closing Balance</MenuItem>
              </Menu>
            </div>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '10px',textAlign:'center'}}  >
        <Grid item xs={12}>
        {runReportConfig.showLoader.get()  && 
              <CircularProgress color="secondary" />
        }
        </Grid>
      </Grid>
      {runReportConfig.loadReport.get()  && 
      <div className="ag-theme-alpine kgm-report-aggrid kgm-report-hwm dashboard-report" >
        <AgGridReact 
          columnDefs={gridReportInfo.columnDefs}
          defaultColDef={gridReportInfo.defaultColDef}
          detailCellRendererParams = {gridReportInfo.detailCellRendererParams}
          detailRowHeight = {gridReportInfo.detailRowHeight}
          onRowClicked={onLedgerDetails}
          // getRowHeight = {gridReportInfo.getRowHeight}
          // getRowStyle = {gridReportInfo.getRowStyle}
          groupIncludeTotalFooter = {gridReportInfo.groupIncludeTotalFooter}
          icons = {gridReportInfo.icons}
          masterDetail = {gridReportInfo.masterDetail}
          // pagination = {gridReportInfo.pagination}
          // paginationPageSize = {gridReportInfo.paginationPageSize}
          rowHeight =   {gridReportInfo.rowHeight}
          rowData={gridReportInfo.rowData}
          // onFirstDataRendered = {gridReportInfo.onFirstDataRendered}
          pinnedBottomRowData = {gridReportInfo.pinnedBottomRowData}
          suppressAggFuncInHeader={gridReportInfo.suppressAggFuncInHeader}
         ></AgGridReact>
        </div>
        }
      </div>
  );
};
