 


import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox'; 
import { Autocomplete, InputAdornment, TextField, Typography } from '@mui/material';  
import SearchIcon from '@mui/icons-material/Search';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { searchRecord } from '../../../../../service/kagamiListService'; 
import { GetListFilterListProps,KagamiPresentationFilterModel, usePresentationFiltersState } from '../model/KagamiPresentationFilterModel';
import { KagamiListFilterModel, useListFiltersState } from '../../../model/KagamiListFilterModel';
import { useClearFilterButtonModelState } from '../../clearFilterButton/model/ClearFiltersModel';
import { KagamiStateManager } from '../../../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../../../constants/SystemConstants';
import { isNullishCoalesce } from 'typescript';
import { createState } from '@hookstate/core';
import { KagamiListModel } from '../../../../../model/KagamiListModel';
import makeStyles from '@mui/styles/makeStyles';

import {kagamiPresentationFilterstyle} from "../styles/KagamiPresentationFilterStyle"
import moment from 'moment';
import { getKagamiListFilterModel } from '../../../controller/KagamiListFilterController';

export const getListFiltersList = (kagamiListModel:KagamiListModel,kagamiPresentationFilterModel: KagamiPresentationFilterModel,kagamiListFilterModel:KagamiListFilterModel ) => {
  // let clearModel:DataFilterModel = new DataFilterModel()
  return <GetFilterDataList kagamiListModel={kagamiListModel} kagamiPresentationFilterModel={kagamiPresentationFilterModel} kagamiListFilterModel= {kagamiListFilterModel} />
}

const GetFilterDataList = (props: GetListFilterListProps) => { 
    const {kagamiListModel,kagamiPresentationFilterModel, kagamiListFilterModel} = props
    const classes = makeStyles(kagamiPresentationFilterstyle)();
    
    //states
    kagamiPresentationFilterModel.state = usePresentationFiltersState(createState(kagamiPresentationFilterModel))
    const listFilterAppliedState = KagamiStateManager.getStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE);
   
   
    let searchProcessName =  kagamiPresentationFilterModel.state.searchProcessName
    let checked =  kagamiPresentationFilterModel.state.checkedList
    React.useEffect(() => {
  
      //the filterItems that should be built checked on popover open 
      if(CommonUtils.isNotEmpty(searchObj) && searchObj.hasOwnProperty(attributeKeyStatus) ){
  
        kagamiPresentationFilterModel.state.SetCheckedList(searchObj[attributeKeyStatus]['like'])  
      } 
    }, []) 
    

   const filtersData: any = kagamiPresentationFilterModel.filterDataForSearchableAttributes;
   let attributeKeyStatus: string = kagamiPresentationFilterModel.attributeName;
   
   
   
   let listModelPresentationId = kagamiListModel.presentation.presentationId;
   let entityId = kagamiListModel.presentation.entityId 
   let searchObj = kagamiListFilterModel.searchObj 
 
  
    const handleToggle = (value: string) => () => {
      const currentIndex = checked.indexOf(value); 
      const newChecked = [...checked];
  
    //condition to check if this attributekey exists in search obj
  
    
    
    
    if (attributeKeyStatus in searchObj ) {
      console.log('This key exist.'); 
  
      if(currentIndex === -1){
        newChecked.push(value); 
        searchObj[attributeKeyStatus]['like'].push(value) 
       
      }
      else {
        newChecked.splice(currentIndex, 1);  
        searchObj[attributeKeyStatus]['like'].splice(currentIndex, 1) 
       
      }
    } else {
        console.log('This key does not exist.');
        searchObj[attributeKeyStatus]={}
        searchObj[attributeKeyStatus]['like'] = [];
        searchObj[attributeKeyStatus]['between'] = [];
        if (currentIndex === -1 ) {
          newChecked.push(value);  
          searchObj[attributeKeyStatus]['like'].push(value) 
       
        }  else {
          newChecked.splice(currentIndex, 1);  
          searchObj[attributeKeyStatus]['like'].splice(currentIndex, 1) 
         
        }
    }

    
    searchObj[attributeKeyStatus]['like'] = newChecked

    kagamiPresentationFilterModel.state.SetCheckedList(newChecked)
   
    if(newChecked.length > 0) {
      let listFiltersModel = getKagamiListFilterModel();
      if(listFiltersModel){ 
        listFiltersModel.presentationFiltersApplied.push(kagamiPresentationFilterModel);
      }
    }
     const allkeys = Object.keys(searchObj)
     let falsyArray:any[] = [];
     if (allkeys.length > 0 ){
      for(let item of allkeys){
        searchObj[item]['like'].length > 0 ? falsyArray.push('true') : falsyArray.push('false')
      }
      falsyArray
     }

   
 CommonUtils.isNotEmpty(falsyArray) && falsyArray.includes('true') 
 ? listFilterAppliedState.SetIsEnabled(true) : listFilterAppliedState.SetIsEnabled(false);
      searchRecord(kagamiListModel,listModelPresentationId,null,null, searchObj);    
  
    };
   let validateItemSelected = (item : any) => moment(item, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()  ? moment(item).format('DD-MM-YYYY'): item 
   
    return (
      <div className= {classes.filtersListBody}>
            <div>
              <TextField
                  placeholder="Search..." size="small" 
                  value={searchProcessName}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                        sx={{
                          color:(theme) => theme.palette.secondary.main
                        }}
                        ></SearchIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={(e: any) =>   kagamiPresentationFilterModel.state.SetSearchProcessName(e.target.value)}
                /> 
            </div>
        <List className={classes.listItems}>
   
          {CommonUtils.isNotEmpty(filtersData) && 
          
          filtersData
                    .filter((item: any) => validateItemSelected(item).toString().match(new RegExp(searchProcessName, "i")))
                    .map((item: any) => { 
                      
             const labelId = `checkbox-list-label-${item}`;
             if(CommonUtils.isNotEmpty(item)){
               return (
                <ListItem
                  key={item} 
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(validateItemSelected(item))} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        size='small'
                        checked={checked.indexOf(validateItemSelected(item) ) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon> 
                  {/* check if the item is in timestamp format as there is no controltype comming in filter data */}
                    <ListItemText className={classes.listItem} id={labelId} primary={validateItemSelected(item)} />
                  </ListItemButton>
                </ListItem>
              );
    
             } else {
              return <ListItem >
                <Typography variant='caption' color="action">
                NO OPTIONS
                </Typography>
              </ListItem>
             }
             
          })}
  
        </List> 
      </div>
    );
  };
  