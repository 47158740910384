import { logout } from '../../../application/builder/login/controller/KagamiLoginController';
import { KagamiErrorModalModel } from '../model/KagamiErrorModalModel';
import { builModal } from '../ui/KagamiErrorModal';

export function buildErrorModal(modalModel: KagamiErrorModalModel) {
  return builModal(modalModel);
}

export function handleModalOnOk(modalModel: KagamiErrorModalModel) {
  if (modalModel.isTriggerModal && modalModel.embeddedFormPresentationModel !== null) {
    modalModel.handleOnOk(modalModel.embeddedFormPresentationModel);
  } else if (modalModel.handleOnOk) {
    modalModel.state.setOpen(false);
    modalModel.handleOnOk();
  } else {
    modalModel.state.setOpen(false);
    if (modalModel.isSessionInvalid) logout();
  }
}

export function handleErrorModalOnClose(modalModel: KagamiErrorModalModel) {
  modalModel.state.setOpen(false);
  if (modalModel.isTriggerModal && modalModel.embeddedFormPresentationModel !== null) {
    modalModel.handleOnClose(modalModel.embeddedFormPresentationModel);
  } else if (modalModel.handleOnClose) {
    modalModel.handleOnClose();
  }
}
