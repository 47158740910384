import { Button, Typography } from '@mui/material';
import React from 'react';
import { buildModal } from '../../../../../../../../../../components/modal/controller/KagamiModalController';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { getActiveModel } from '../../../controller/KagamiProcessController';
import { nonFieldButtonOnClickCall } from '../../../service/PolicyExecutionService';
import IconButton from '@mui/material/IconButton';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { KagamiListModel } from '../../list/model/KagamiListModel';
import { getNonFieldProcessName, updateInFocusMap } from '../service/FormService';
import { getAttributePresentations, getNonFieldPresentationTriggers } from '../../../service/PresentationService';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { getFormModel } from '../controller/KagamiFormController';
import _ from 'lodash';

let pickItemListModel : KagamiListModel

export function setPickItemListModel(listModel : KagamiListModel){
  pickItemListModel = listModel;
}

export function getPickItemListModel(){
  return pickItemListModel;
}
 
export const BuildNonFieldPresentations = (props: any) => {
  let isActionColumn : boolean = props.isActionColumn ? props.isActionColumn : false;
  let key: any;
  let value: any;
  let toreturn: any[] = [];
  let isListDetail : boolean= CommonUtils.isNotEmpty(props.isListDetail) ? props.isListDetail : false
  let presentationId = props.kagamiGridModel.presentation.presentationId;
  const attributePresentations: any = getAttributePresentations(props.kagamiGridModel.presentation);
let headerPresentations:any[] = [];
let paragraphPresentations:any[] = [];


  if(!isListDetail){
    if (attributePresentations) {
      for ([key, value] of Object.entries(attributePresentations)) {
        if (key.includes('NonFieldPresentation')) {
          if(isActionColumn){
            if(value['type'] === 'BUTTON'){
              toreturn.push(createNonFieldPresentation(key, value, presentationId, props.kagamiGridModel.mainEntityId, props.isPickAttributeButtonVisible,isListDetail,props.embedData));
            }
          }
          else{
            if(value['type'] === 'HEADER') {
              headerPresentations.push(
                createNonFieldPresentation(key, value, presentationId, props.kagamiGridModel.mainEntityId, props.isPickAttributeButtonVisible,isListDetail)
             );
              
            }
            else if(value['type'] ==='PARAGRAPH' ){
              paragraphPresentations.push( 
                  createNonFieldPresentation(key, value, presentationId, props.kagamiGridModel.mainEntityId, props.isPickAttributeButtonVisible,isListDetail)
              )
            }
            else {
              toreturn.push(createNonFieldPresentation(key, value, presentationId, props.kagamiGridModel.mainEntityId, props.isPickAttributeButtonVisible,isListDetail))
            }
            
          }
        }
      }
    }
  }
  const headerComps = CommonUtils.isNotEmpty(headerPresentations) 
  ? <Typography key={"way-to-header" + Math.random()} variant="subtitle2" style={{ textAlign: 'left', color: '#000' }}> 
  
  {/* {headerPresentations.reverse()} */}
  {headerPresentations}
   </Typography> : <></>
  const paraComps = CommonUtils.isNotEmpty(paragraphPresentations) ?  <Typography key={"way-to-paragraph" + Math.random()} variant="caption" color="action">
    {paragraphPresentations.reverse()}
    </Typography> :<></>
  return <> {[headerComps, paraComps,...toreturn]} </>;
};


export function createNonFieldPresentation(
  fieldName: any, 
  attributePresentation: any, 
  presentationId: any,
  mainEntityId : string, 
  isPickAttributeButtonVisible: boolean,
  isListDetail : boolean = false,
  embedData?:any
  ) {
  let nonFieldPresentation = attributePresentation;
  let controlType = nonFieldPresentation['type'];
  let metaData = nonFieldPresentation['metaData'];
  let content = metaData['content'];
  let visible = nonFieldPresentation['visible'];
  let showNonFieldPresentation = getNonFieldPresentationTriggers(metaData,controlType)
  let widget: any[] = [];

  const activemodel = getActiveModel()
  if (showNonFieldPresentation) {
    switch (controlType) {
      case 'PARAGRAPH':
        widget.push(<>{content}</>);
        break;
      case 'HEADER':
        widget.push( <> {content} </>)
        break;
      case 'LINE_BREAK':
        if (!isPickAttributeButtonVisible) {
          widget.push(
            <div key={"way-to-linebreak" + Math.random()} >
              <br />
            </div>
          );
        }
        break;
      case 'BUTTON':
        // if(content==='pickitem'||content==='pickitem'||content==="PickItem" || content==="Pick Item"){
        let processName = getNonFieldProcessName(nonFieldPresentation)
        let formModel = getFormModel()
        if (isPickAttributeButtonVisible) {
          let modalModel: KagamiModalModel = new KagamiModalModel();
          modalModel.isPickItem = true;
          modalModel.parentEntityId = mainEntityId
          //if(isPickAttributeButtonVisible){
          if (content === 'pickitem' || content === 'pickItem' || content === "PickItem" || content === "Pick Item") {
            widget.push(
              <React.Fragment key={"way-to-pickitem" + Math.random()} >
                
                {buildModal(modalModel)}
                <IconButton
                  // className={classes.root}
                  style={{ minWidth: '0' }}
                  title="pick item"
                  onClick={ _.debounce(() =>{
                  {CommonUtils.isNotEmpty(embedData) && updateInFocusMap(modalModel.parentEntityId,embedData.id,formModel)}
                    nonFieldButtonOnClickCall(activemodel, modalModel, fieldName, presentationId)
                  },800)
                }
                >
                  <LocalOfferOutlinedIcon color='primary' fontSize="small" />
                </IconButton>
              </React.Fragment>
            )
          }
          else {
            widget.push(
              // <div className={fieldWidth}>

              <React.Fragment key={"way-to-button" + Math.random()} >
              
                {buildModal(modalModel)}
                <Button
                  // style={{ margin: '0.2rem', width: "fit-content", padding: "0.2rem", height: '33px', marginTop: '6px' }}
                  style={{ width: "fit-content" ,  margin: '0.2rem', padding: "0.2rem",}}

                  variant="contained" size='small'
                  onClick={ _.debounce(() => {
                       {CommonUtils.isNotEmpty(embedData) && updateInFocusMap(modalModel.parentEntityId,embedData.id,formModel)}
                    nonFieldButtonOnClickCall(activemodel, modalModel, fieldName, presentationId, processName)
                  },800)
                }
                //color="primary"
                // onClick={() =>
                //   console.log('aksldjl')
                //   // nonFieldButtonOnClickCall(getActiveModel(), fieldName, presentationId)
                // }
                >
                  {' '}
                  {content}{' '}
                </Button>
              </React.Fragment>

              // </div>
            );
          }
        }

        break;
    }
  }
  return <>{widget} </>
}

export const NonFieldButtonModalDialog = (props: any) => {
  let modalModel: KagamiModalModel = props.modalModel;
  return buildModal(modalModel);

}
