import { ReportTextFilterModel } from "../model/TextFilter";
import { buildTextFilterComponent } from "../ui/TextFilter";

export const buildTextFilter = (reportTextFilterModel: ReportTextFilterModel, translationWords?: any) => {
    return buildTextFilterComponent(reportTextFilterModel, translationWords);
}

export const setTextFieldData = (child: any, filterModel: any) => {
    if (child !== null) {
      if (Array.isArray(child)) {
        filterModel.reportFilterModel.selectedValue.set(filterModel.filterName, child.join(','));
      } else {
        filterModel.reportFilterModel.selectedValue.set(filterModel.filterName, child);
      }
    } else {
      filterModel.reportFilterModel.selectedValue.set(filterModel.filterName, 'NOPOP');
    }
  };