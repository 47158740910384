import * as React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
//--------------------------------------------------------------------------------
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Divider from '@mui/material/Divider';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
//--------------------------------------------------------------------------------
import IconButton from '@mui/material/IconButton';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomReportPopoverStyle } from '../style/CustomReportPopoverStyle';
import { makeStyles } from '@mui/styles';
import { SystemConstants } from '../../../../../../../../../../../../constants/SystemConstants';
import { ReportLandingPageModel, ReportLandingPageModelProps } from '../../../../../model/ReportsLandingPageModel';
import { PinnedReportsModel } from '../../../../ReportTabHeader/PinnedReports/Model/PinnedReportsModel';
import { buildPinnedReports } from '../../../../ReportTabHeader/PinnedReports/controller/PinnedReportsController';
import { MapReportsModel } from '../../../../ReportTabHeader/MapReports/Model/MapReportsModel';
import { buildMapReports } from '../../../../ReportTabHeader/MapReports/controller/MapReportsController';
import { MapRolesModel } from '../../../../ReportTabHeader/MapRoles/model/MapRolesModel';
import { buildMapRoles } from '../../../../ReportTabHeader/MapRoles/controller/MapRolesController';
import { ReportSchedulerModel } from '../../../../ReportTabHeader/ScheduleReports/model/ScheduleReportsModel';
import { buildReportScheduler } from '../../../../ReportTabHeader/ScheduleReports/controller/ScheduleReportsController';
import { translateToLocalistaion } from '../../../../../../../process/service/PresentationService';
// import addUser from '../../../../../../../../../../../../../assets/images/custom-icons/noun-add-user.svg';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export const buildReportCustomizationForAdminRoleComponent = (reportLandingPageModel:ReportLandingPageModel) => {
  return <CustomizedMenus reportLandingPageModel={reportLandingPageModel}/>;
};

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
);

const useStyles = makeStyles(CustomReportPopoverStyle);

export const CustomizedMenus = (props: ReportLandingPageModelProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePinnedReport = (event: any) => {
      let pinnedReportsModel = new PinnedReportsModel();
      pinnedReportsModel.dbReportList = props.reportLandingPageModel.reportList ?? [];
      props.reportLandingPageModel.content = buildPinnedReports(pinnedReportsModel, props.reportLandingPageModel.translations);
      props.reportLandingPageModel.isContentLoaded = true;
      props.reportLandingPageModel.fromHeader = true;
      props.reportLandingPageModel.state.setDataLoaded(true);
    setAnchorEl(null);
};

  const handleMapReports = (event: any) => {
      let mapReportsModel = new MapReportsModel();
      mapReportsModel.dbReportList = props.reportLandingPageModel.reportList;
      props.reportLandingPageModel.content = buildMapReports(mapReportsModel,props.reportLandingPageModel.translations);
      props.reportLandingPageModel.isContentLoaded = true;
      props.reportLandingPageModel.fromHeader = true;
      props.reportLandingPageModel.state.setDataLoaded(true);
    setAnchorEl(null);
};

  const handleMapRolesPage = (event: any) => {
      let mapReportModel = new MapRolesModel();
      mapReportModel.dbReportList = props.reportLandingPageModel.reportList;
      props.reportLandingPageModel.content = buildMapRoles(mapReportModel,props.reportLandingPageModel.translations);
      props.reportLandingPageModel.isContentLoaded = true;
      props.reportLandingPageModel.fromHeader = true;
      props.reportLandingPageModel.state.setDataLoaded(true);
    setAnchorEl(null);
};

  const handleScheduleReport = (event: any) => {
      let reportSchedulerModel = new ReportSchedulerModel();
      reportSchedulerModel.reportList = props.reportLandingPageModel.reportList;
      props.reportLandingPageModel.content = buildReportScheduler(reportSchedulerModel,props.reportLandingPageModel.translations);
      props.reportLandingPageModel.isContentLoaded = true;
      props.reportLandingPageModel.fromHeader = true;
      props.reportLandingPageModel.state.setDataLoaded(true);
    setAnchorEl(null);
};

  const handleViewPage = (event: any) => {
    props.reportLandingPageModel.isContentLoaded = false;
    props.reportLandingPageModel.fromHeader = false;
    props.reportLandingPageModel.state.setDataLoaded(true);
    setAnchorEl(null);
};

  return (
    <>
      <IconButton className={classes.adminPanel} size="small" onClick={handleClick}>
        <SettingsOutlinedIcon fontSize="small"/>
        <ExpandMoreIcon fontSize="small" />
      </IconButton>
      <StyledMenu
        className={classes.root}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handlePinnedReport} disableRipple>
        <PushPinOutlinedIcon />
          {/* {SystemConstants.PINNED_REPORTS} */}
          {translateToLocalistaion(props.reportLandingPageModel.translations, SystemConstants.PINNED_REPORTS)}
        </MenuItem>
        <MenuItem onClick={handleMapRolesPage} disableRipple>
        <PersonAddAltOutlinedIcon />
          {/* {SystemConstants.MAP_ROLES} */}
          {translateToLocalistaion(props.reportLandingPageModel.translations, SystemConstants.MAP_ROLES)}
        </MenuItem>
        <MenuItem onClick={handleMapReports} disableRipple>
        <PersonAddAltOutlinedIcon />
          {/* {SystemConstants.MAP_REPORTS} */}
          {translateToLocalistaion(props.reportLandingPageModel.translations, SystemConstants.MAP_REPORTS)}
        </MenuItem>
        <MenuItem onClick={handleScheduleReport} disableRipple>
        <AccessTimeOutlinedIcon />
          {/* {SystemConstants.SCHEDULE_REPORTS} */}
          {translateToLocalistaion(props.reportLandingPageModel.translations, SystemConstants.SCHEDULE_REPORTS)}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleViewPage} disableRipple>
        <VisibilityOutlinedIcon />
          {/* {SystemConstants.VIEW_REPORTS} */}
          {translateToLocalistaion(props.reportLandingPageModel.translations, SystemConstants.VIEW_REPORTS)}
        </MenuItem>
      </StyledMenu>
    </>
  );
};
