import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiEmbeddedFormStyle = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(0.5),
      paddingLeft:theme.spacing(0.5),
      paddingRight:theme.spacing(0.5),
      marginTop: theme.spacing(1),
      // padding: `${theme.spacing(0.3)} ${theme.spacing(0.5)}`,
      '& .css-1udso76-MuiPaper-root-MuiAccordion-root.Mui-disabled':{
        backgroundColor: '#ffffff',
      }
    },
    accordion: {
      marginTop: theme.spacing(0.5),
      //border: '1px solid',
      borderColor: theme.palette.grey[400],
      borderRadius: '1rem',
      backgroundColor: theme.palette.background.default,
      paddingLeft:'12px',
      '&.Mui-expanded' :{
        // margin: theme.spacing(0.5),
      },
      '& .MuiAccordionDetails-root': {
        padding: '0px 0px 16px !important'
      }
    },
    formLabel: {
      textAlign: 'left',
      fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      //color: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      // margin: theme.spacing(0.5), 
      fontWeight: ` ${theme.typography.h6.fontWeight} !important`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textTransform: 'capitalize'
    },
    accordionSummary: {
      // height: theme.spacing(4.4),
      minHeight: theme.spacing(4.4),
      maxHeight: theme.spacing(4.4),
      paddingLeft: '0',
      justifyContent : 'flex-start', 
      width:'fit-content',
      position:'relative', 
      '&.Mui-expanded': {
        minHeight : theme.spacing(4.4),
      },
      '& .MuiAccordionSummary-expandIconWrapper':{
        color:'#1f8a70',
        marginLeft:'20px'
  
      },
      '& .MuiAccordionSummary-content' : {
        margin: `0`,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexGrow: 'inherit',
        '&.Mui-expanded': {
          // margin :`${theme.spacing(1)} 0`,
        }
      },

      '&:hover $hint': {
        display:'flex'
      } 
    },
    accordionContent: {
      // display: 'flex',
      flex: '1',
      // alignItems: 'flex-strech',
      borderRadius: theme.shape.borderRadius,
      // overflow: 'hidden',
      boxSizing: 'content-box',
       '&>div': {
        padding:'0'
       }
    },
    hint: {
      display: 'none', 
       
    },
    
  })