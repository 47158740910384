import { State, useState } from '@hookstate/core';
import { KagamiControlModel } from '../../../application/builder/home/builder/body/builder/process/builder/controls/model/kagamiControlModel';

export class KagamiActivityLogModel {
  isOpen: boolean;
  state: any;
  action: string;
  entityId: string;
  referenceId: string;
  activityLogContainer: any;
  isDataLoaded: boolean;
  statusInfo: any;
  listChatInfo: any;
  controlIdentifier: string;
  attributeName: string;
  isMandatory: boolean;
  isVisible: boolean;
  controlType: string;
  attributeLabel: string;
  uiSettings: any;
  controlValue: any;
  htmlControl: String;
  readOnly: boolean;
  getControlWidth(uiSettings: any) {
    const fieldWidth = uiSettings ? uiSettings.fieldWidth?.value : null;
    if (fieldWidth) {
      if (
        fieldWidth === 1 ||
        fieldWidth === 5 ||
        fieldWidth === 7 ||
        fieldWidth === 9 ||
        fieldWidth === 10 ||
        fieldWidth === 11
      ) {
        let uiSettingsClassName = `col`;
        return uiSettingsClassName;
      } else if (fieldWidth === 4) {
        let uiSettingsClassName = `col_3`;
        return uiSettingsClassName;
      } else {
        let uiSettingsClassName = `col_${fieldWidth}`;
        return uiSettingsClassName;
      }
    } else {
      let uiSettingsClassName = `col_4`;
      return uiSettingsClassName;
    }
  }
  constructor() {
    this.isOpen = false;
    this.action = '';
    this.entityId = '';
    this.referenceId = '';
    this.activityLogContainer = {};
    this.isDataLoaded = false;
    this.statusInfo = {};
    this.listChatInfo = [];
    this.readOnly = false;

    this.controlIdentifier = '';
    this.attributeName = '';
    this.isMandatory = false;
    this.isVisible = true;
    this.controlType = '';
    this.attributeLabel = 'activityLog';
    this.controlValue = '';
    this.htmlControl = '';
  }
}

export class KagamiActivityLogControlModel extends KagamiControlModel {
  isOpen: boolean;
  state: any;
  action: string;
  referenceId: string;
  activityLogContainer: any;
  isDataLoaded: boolean;
  statusInfo: any;
  listChatInfo: any;
  uiSettings: any;
  htmlControl: String;
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any[],
    onChangeRequired: boolean,
    validation: any,
    pickAttribute: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,

    policyMap: any,
    presentationPolicy: any,
    businessPolicy: any,
    initiallyReadOnly: boolean,
    initiallyVisible: boolean,
    uiSettings: any,
    htmlControl: string,
    formDataIdentifier: string,
    isGridField: boolean,
    isEmbddedField: boolean
  ) {
    super(
      controlIdentifier,
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField
    );
    this.uiSettings = uiSettings;
    (this.htmlControl = ''), (this.isOpen = false);
    this.action = '';
    this.referenceId = '';
    this.activityLogContainer = {};
    this.isDataLoaded = false;
    this.statusInfo = {};
    this.listChatInfo = [];
  }
}

export interface KagamiActivityLogProps {
  KagamiActivityLogModel: KagamiActivityLogModel;
}
export const wrapKagamiActivityLogModelState = (state: State<KagamiActivityLogModel>) => {
  let activityLogState = state;

  return {
    get isOpen() {
      return activityLogState.isOpen.get();
    },

    setOpen(isOpen: boolean) {
      activityLogState.isOpen.set(isOpen);
    },

    get isDataLoaded() {
      return activityLogState.isDataLoaded.get();
    },

    setDataLoaded(dataLoaded: boolean) {
      activityLogState.isDataLoaded.set(dataLoaded);
    }
  };
};
export const useKagamiActivityLogState = (state: State<KagamiActivityLogModel>) =>
  wrapKagamiActivityLogModelState(useState(state));
