export class RuntimeInput {
  id: string = 'RuntimeInput';
  calendarStart: string;
  calendarEnd: string;
  searchKey?: string | null;

  constructor(calendarStart: string, calendarEnd: string, searchKey?: string | null) {
    this.id = 'RuntimeInput';
    this.calendarStart = calendarStart;
    this.calendarEnd = calendarEnd;
    this.searchKey = searchKey;
  }

  toMap() {
    let map = new Map();
    map.set('id', this.id);
    map.set('calendarStart', this.calendarStart);
    map.set('calendarEnd', this.calendarEnd);
    map.set('searchKey', this.searchKey);

    return map;
  }
}
