import { KagamiSelectModel } from '../model/kagamiSelectModel';
import { buildSelectBoxComponent } from '../ui/kagamiSelectField';
import KagamiControlValidator from '../../validations/KagamiControlValidator';
import { handleOnsearch, notifyChangeToForm } from '../../../form/controller/KagamiFormController';
 
import { KagamiModalModel } from '../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { buildMultiSelectContent } from '../ui/MultiSelectContent';

let selectModel: KagamiSelectModel;

export function buildSelectBox(kagamiSelectModel: KagamiSelectModel) {
  return buildSelectBoxComponent(kagamiSelectModel);
}

export function getSelectModel() {
  return selectModel;
}

export function onSearchCall(kagamiSelectModel: KagamiSelectModel, value: any , asyncSearch: boolean = false, presentationId? : string ) {
  handleOnsearch(kagamiSelectModel, value, asyncSearch ,presentationId);
}

export function validateSelectTextBox(kagamiSelectModel: KagamiSelectModel, value: any) {
  selectModel = kagamiSelectModel;
  let errorMsg = KagamiControlValidator.validate(kagamiSelectModel.controlType, value, kagamiSelectModel.validation);
  if (errorMsg === null) {
    kagamiSelectModel.state.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiSelectModel, null);
  } else {
    kagamiSelectModel.state.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiSelectModel, null);
  }
}

 
export function getMultiSelectReadOnlyTitle(kagamiSelectModel: KagamiSelectModel) {
  return kagamiSelectModel.controlDisplayName ? kagamiSelectModel.controlDisplayName :null;
}

export function buildMultiSelectMoreContent(fieldState: any, kagamiSelectModel: KagamiSelectModel, popupModel: KagamiModalModel) {
  return buildMultiSelectContent(fieldState, kagamiSelectModel, popupModel);
}