import { hideLoader, showLoader } from '../../components/loader/controller/KagamiLoaderController';
import { KagamiApplicationModel } from '../model/KagamiApplicationModel';
import { BuildApplication } from '../ui/KagamiApplication';

export class KagamiApplicationController {
  static applicationModel: KagamiApplicationModel;

  static onApplicationLogin(userId: string) {
    KagamiApplicationController.applicationModel.userId = userId;
    KagamiApplicationController.applicationModel.state.setUserLoggedIn(true);
  }

  static renderApplication(kagamiApplicationModel: KagamiApplicationModel) {
    return BuildApplication(kagamiApplicationModel);
  }

  static onApplicationLogout() {
    this.getAppplicationModel().state.setUserLoggedIn(false);
  }

  static handleRefresh() {
 //   KagamiLoginController.validateToken(this.getAppplicationModel().loginModel);
  }

  static getAppplicationModel() {
    return KagamiApplicationController.applicationModel;
  }

  static showLoader() {
    // showLoader(this.getAppplicationModel().loaderModel);
  }

  static hideLoader() {
    // hideLoader(this.getAppplicationModel().loaderModel);
  }
}
