import { ReportModalCustomButton } from '../../../../../view/ReportModal/model/ReportModalModel';
import { buildReportSchedulerForm } from '../builder/SchedulerForm/controller/SchedulerFormController';
import { SchedulerFormModel } from '../builder/SchedulerForm/model/SchedulerFormModel';
import { ReportSchedulerModel } from '../model/ScheduleReportsModel';
import { buildReportSchedulerComponent } from '../ui/ScheduleReports';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { updateExpression } from '../builder/SchedulerForm/service/SchedulerFormService';
import { ScheduleReportsService } from '../service/SchedulerReportService';
import { translateToLocalistaion } from '../../../../../../process/service/PresentationService';

let schedulerModel: ReportSchedulerModel;

export const buildReportScheduler = (reportSchedulerModel: ReportSchedulerModel,translations?:any) => {
  schedulerModel = reportSchedulerModel;
  return buildReportSchedulerComponent(reportSchedulerModel,translations);
};
export const rebuildReportScheduler = (newschedulerModel: any) => {
  newschedulerModel.state.setDataLoaded(true);
};

export const handleNewScheduler = (reportSchedulerModel: ReportSchedulerModel,translations?:any) => {
  let schedulerFormModel = new SchedulerFormModel();
  schedulerFormModel.reportList = reportSchedulerModel.reportList;
  reportSchedulerModel.reportModalModel.title = translateToLocalistaion(translations, "New Scheduler");
  reportSchedulerModel.reportModalModel.content = buildReportSchedulerForm(schedulerFormModel,translations);
  reportSchedulerModel.reportModalModel.translations = translations;

  const clickFunction = () => {
    if(CommonUtils.isNotEmpty(schedulerFormModel.initialFormData.schedule.schedulerName)){
      updateExpression(schedulerFormModel.initialFormData)
      console.log(schedulerFormModel.initialFormData);
      ScheduleReportsService.SchedulerReport(schedulerFormModel.initialFormData, reportSchedulerModel);
      reportSchedulerModel.reportModalModel.state.setOpen(false);
    }else{
      alert('Please enter scheduler name');
    }
  };

  let submitBtn: ReportModalCustomButton = new ReportModalCustomButton('Submit', false, clickFunction);
  reportSchedulerModel.reportModalModel.listOfButton.length = 0;
  reportSchedulerModel.reportModalModel.listOfButton.push(submitBtn);
  reportSchedulerModel.reportModalModel.state.setOpen(true);
};
