import { getExistingActiveTemplate } from '../../../application/builder/home/builder/body/builder/process/service/PolicyExecutionService';
import {
  getSupportedTemplateTypes,
  makeOnCancelCall,
  makeOnChangeCall,
  makeOnLoadCall
} from '../../../application/builder/home/builder/body/builder/process/service/ProcessService';
import { showServerErrorPopUp } from '../../../application/controller/KagamiApplicationController';
import { CommonUtils } from '../../../application/utils/CommonUtils';
import { KagamiProcessModel } from '../model/kagami_process_model';
import { EventType, KagamiProcessRequestModel } from '../model/kagami_process_request_model';

export class KagamiProcessController {
  static activeProcessModel: KagamiProcessModel;
  static processModels: [];

  static executeProcess(kagamiProcessModel: KagamiProcessModel) {
    this.onStartCall(kagamiProcessModel);
  }

  static onStartCall(currentProcessModel: KagamiProcessModel) {
    this.onOutBoundCall(currentProcessModel);
  }

  static onSubmitCall(processModel: KagamiProcessModel) {
    this.onOutBoundCall(processModel);
  }

  static onOutBoundCall(currentProcessModel: KagamiProcessModel) {}

  static renderPageContent(dynamicPageModel: any) {
    if (getSupportedTemplateTypes().includes(dynamicPageModel.templateType)) {
      //clearing nonfield presenatation map in order to load other pages
      let existingActiveTemplate = getExistingActiveTemplate();
      if (CommonUtils.isNotEmpty(existingActiveTemplate)) {
        existingActiveTemplate.clear();
      }
      let content;
    } else if (dynamicPageModel.templateType === 'noUI') {
      let kagamiProcessModel = new KagamiProcessModel(
        dynamicPageModel.processName,
        false,
        dynamicPageModel.processStepName,
        showServerErrorPopUp
      );
      kagamiProcessModel.submitCall = true;
      kagamiProcessModel.data = null;
      KagamiProcessController.onSubmitCall(kagamiProcessModel);
    }
  }

  static startUiSubProcessIfReceived(response: any) {}

  static async onSubProcessCall(requestModel: KagamiProcessRequestModel) {
    switch (requestModel.eventType) {
      case EventType.onLoad:
        return makeOnLoadCall(
          requestModel.processName,
          requestModel.entityPresentation,
          requestModel.changedData,
          requestModel.data,
          requestModel.runtimeInput
        );

      case EventType.onChange:
        return makeOnChangeCall(
          requestModel.processName,
          requestModel.changedAttribute,
          requestModel.entityPresentation,
          requestModel.changedData,
          requestModel.data
        );

      case EventType.onCancel:
        return makeOnCancelCall(
          requestModel.processName,
          requestModel.entityPresentation,
          requestModel.changedData,
          requestModel.data
        );

      default:
        break;
    }
  }
}
