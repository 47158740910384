import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import { KagamiRadioModel } from '../model/kagamiRadioModel';
import { buildRadioButtons } from '../ui/kagamiRadioField';

export function buildRadioButton(kagamiRadioModel: KagamiRadioModel) {
  return buildRadioButtons(kagamiRadioModel);
}

export function handleRadioButtonOnChange(kagamiRadioModel: KagamiRadioModel, value: any) {
  kagamiRadioModel.state.setControlValue(value);
  notifyChangeToForm(kagamiRadioModel, value);
}
export function getDisplayDirection(uiSettings:any) {
  let displayRow:boolean;
if(CommonUtils.isNotEmpty(uiSettings) && uiSettings.hasOwnProperty('displayDirection')) {
    if(uiSettings.displayDirection?.value?.toLowerCase() === 'sidebyside') {
      displayRow = true 
    } else{
      displayRow = false
    }
} else {
  displayRow = false
}
  return displayRow
}