import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { getHomeModel } from '../../../controller/KagamiHomeController';
import { DashboardType } from '../../body/builder/dashboard/model/KagamiDashboardModel';
import { KagamiNavigatorModel } from '../../navigator/model/KagamiNavigatorModel';
import {
  hideProfile,
  // KagamiProfileController,
  showProfile
} from '../builder/profile/controller/kagamiProfileController';
import { KagamiHomeHeaderModel } from '../model/KagamiPageHeaderModel';
import { buildPageHeaderComponent } from '../ui/KagamiPageHeader';

let kagamiPageHeaderModel: KagamiHomeHeaderModel;

export function buildhomeHeader() {
  const pageHeaderModel: KagamiHomeHeaderModel = new KagamiHomeHeaderModel();
  kagamiPageHeaderModel = pageHeaderModel;
  return buildPageHeaderComponent(pageHeaderModel);
}

export function onNotificationClick() {
  const homeModel = getHomeModel();
  homeModel.bodyModel.dashboardModel.dashboardType = DashboardType.NOTIFICATIONS;
  const dashboard_state = KagamiStateManager.getStateToHolder(SystemConstants.DASHBOARD_STATE);
  dashboard_state.setDataLoaded(true);
}

export function onFavoriteClick() {
  const homeModel = getHomeModel();
  homeModel.bodyModel.dashboardModel.reportData = getPageHeaderFavoritesModel().content;
  homeModel.bodyModel.dashboardModel.dashboardType = DashboardType.REPORTS;
  const dashboard_state = KagamiStateManager.getStateToHolder(SystemConstants.DASHBOARD_STATE);
  dashboard_state.setDataLoaded(true);
}

export function handleResetLayout() {
  
}

export function onProfileClick(pageHeaderModel: KagamiHomeHeaderModel) {
  showProfile(pageHeaderModel.profileModel);
}

export function onHideProfile(pageHeaderModel: KagamiHomeHeaderModel) {
  hideProfile(pageHeaderModel.profileModel);
}

export function re_renderDashboardFavorites() {
  //  KagamiFavoriteController.showFavorite(KagamiDashboardController.dashboardModel.favoritesModel);
}

export function handleLogoutClick() {}

export function getPageHeaderFavoritesModel() {
  return kagamiPageHeaderModel.favoritesModel;
}

export function getUserProfileData() {
  return kagamiPageHeaderModel?.profileModel?.userDataForProfile;
}

export function setUserProfileData(userData: any) {
  return (kagamiPageHeaderModel.profileModel.userDataForProfile = userData);
}

export function setNavigationTransalationData(navModel: any) {
  return (kagamiPageHeaderModel.translations = navModel?.navigationData?.otherProperties?.translation);
}
