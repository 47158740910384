import { buildtoastNotification } from '../../../../../../../../ToastNotification/controller/ToastNotificationController';
import { updateRoleReportMapping } from '../../../../../view/report/service/KagamiReportService';
import { PinnedReportsModel } from '../Model/PinnedReportsModel';
import { buildPinnedReportsComponent } from '../ui/PinnedReports';

let kagamiPinnedReportsModel: PinnedReportsModel;

export function buildPinnedReports(pinnedReportsViewModel: PinnedReportsModel, translations?: any) {
  kagamiPinnedReportsModel = pinnedReportsViewModel;
  return buildPinnedReportsComponent(pinnedReportsViewModel, translations);
}

export const getOptionsData = (reportIdMapWithConfig: any, pinnedReportViewModel: any) => {
  let allReportNamesForRoleId: any[] = [];
  pinnedReportViewModel.roleReportsMapping.map((eachReport: any) => {
    if (reportIdMapWithConfig[eachReport['reportId']] !== undefined) {
      allReportNamesForRoleId.push({
        label: reportIdMapWithConfig[eachReport['reportId']].name,
        reportId: eachReport['reportId']
      });
    }
  });
  return allReportNamesForRoleId;
};

export const getEachReportMapping = (roleName: string) => {
  let eachReportReportMapping: any[] = [];
  kagamiPinnedReportsModel.dbRoleReportMapping.map((roleReportMapping: any) => {
    if (roleReportMapping.roleId === roleName) {
      eachReportReportMapping.push(roleReportMapping);
    }
  });
  return eachReportReportMapping;
};

export const havePinnedReports = (roleName: string, reportIdMapWithConfig: any) => {
  let reportsPinned: any[] = [];
  kagamiPinnedReportsModel.dbRoleReportMapping.map((roleReportMapping: any) => {
    if (reportIdMapWithConfig[roleReportMapping.reportId] !== undefined && roleReportMapping.roleId === roleName) {
      if (roleReportMapping.pinned) {
        reportsPinned.push(reportIdMapWithConfig[roleReportMapping.reportId]);
      }
    }
  });
  return reportsPinned;
};

export const pinnedReportsOnSave = (kagamiPinnedReportsViewModel: PinnedReportsModel, setDisable: any) => {
  updateRoleReportMapping(kagamiPinnedReportsViewModel.allSelectedData)
    .then((response: any) => {
      if (response.status === 200) {
        let count = kagamiPinnedReportsViewModel.allSelectedData.length;
        // showToastAlert('SUCCESS',0)
        buildtoastNotification('success', 'Save Successfull' ,0)

        while(count!==0){
          kagamiPinnedReportsViewModel.allSelectedData.pop();
          count--;
        }
        setDisable(true);
      }
    })
    .catch((err: any) => {
      console.log('getting error while updating record', err);
    });
};

export function enableAndDisableSaveButton() {
  if (kagamiPinnedReportsModel) {
    if (kagamiPinnedReportsModel.allSelectedData.length) {
      kagamiPinnedReportsModel.setDisableSave(false);
    } else {
      kagamiPinnedReportsModel.setDisableSave(true);
    }
  }
}
