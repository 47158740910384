import * as React from 'react';
import { createState ,useState } from '@hookstate/core';
import {
  AgeingReportModel,
  AgeingReportModelProps,
  useAgeingReportModelState
} from '../model/AgeingReportModel';
import { makeReport,makeLedgerData ,getData,onChangeState,onChangeAccount,downloadExcel,downloadPDF} from '../service/AgeingReportService';
import Grid from '@mui/material/Grid';
import { Autocomplete,TextField,Button,ButtonGroup } from '@mui/material';
import {  AgGridReact } from 'ag-grid-react';
import times from 'lodash/times';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';


export const BuildAgeingReportView = (ageingReportModel: AgeingReportModel) => {
  return (
    <AgeingReportView key={'ageing-report-view'} ageingReportModel={ageingReportModel}></AgeingReportView>
  );
};


const AgeingReportView = (props: AgeingReportModelProps) => {
     //Open
     const [ageingAsOnDate, setAgeingAsOnDate] = React.useState(false);
     // Date
     let todayDate = new Date().toISOString().substring(0, 10);
     const [asonDate, setAsonDate] = React.useState(todayDate);
     const [selectstate,setSelectState] = React.useState(false);
     const [selectproject, setSelectProject] = React.useState(false);

  let runReportConfig:any = useState({
    loadReport : false,
    loadFilterData:true,
    showLoader:false,
    reportInfo:{}
  });
  let ageingReportModel = props.ageingReportModel;
  let ageingChartState = useAgeingReportModelState(createState(ageingReportModel));
  ageingReportModel.state = ageingChartState;
  let staticFilterData :any;
  if(ageingReportModel?.reportData?.staticFilterData) {
    staticFilterData  = ageingReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo:any ={}
  if(ageingReportModel?.reportData?.gridReportInfo) {
    gridReportInfo  = ageingReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig= (props.ageingReportModel.reportConfig);
  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo =  makeReport(ageingReportModel,inputReportConfig);
    let ageingData =  await getData(ageingReportModel,inputReportConfig);
    let rowData = await makeLedgerData(ageingData,inputReportConfig);
    reportInfo['rowData'] = rowData;
    const obj = Object.assign({}, reportInfo);
    props.ageingReportModel.reportData['gridReportInfo'] = obj;
    // runReportConfig.reportInfo.set(obj);
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }

  const handleAccountChange =  async (value:any) => {
    let  gstData  = await  onChangeAccount(value,ageingReportModel);
    staticFilterData['gst'] =gstData;
    runReportConfig.loadFilterData.set(true);
  }
  
  const handleAgingPeriodChange =  async (value:any) => {
    staticFilterData['agingPeriod'] =value ?  parseInt(value) : 0;
    runReportConfig.loadFilterData.set(true);
  }
  const handleAgingPeriodRangeChange =  async (value:any,type:any,index:any) => {
        if (!inputReportConfig['fromto']) {
          inputReportConfig['fromto'] = [];
        }
        if (type == 'From') {
          if (inputReportConfig['fromto'][index] && inputReportConfig['fromto'][index].split("-").length > 1) {
            inputReportConfig['fromto'][index] = value +"-" + inputReportConfig['fromto'][index].split("-")[1];
          } else {
            inputReportConfig['fromto'][index] = value;
          }
        } else if (type == 'To') {
          inputReportConfig['fromto'][index] = inputReportConfig['fromto'][index].split("-")[0] + "-" + value;
        }
  }


  const handleStateChange =  async (value:any) => {
    let projectPlan = [];
    if (value && value.length > 0) {
      projectPlan = await onChangeState(value, ageingReportModel);
    } else {
      projectPlan = staticFilterData['allProjects'];
    }
    staticFilterData['projectPlan'] = projectPlan;
    runReportConfig.loadFilterData.set(true);
  }
  const handleStaticFilters = (value: any, type: any) => {
    if (type == 'endDate') {
      if (!inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {};
      }
      inputReportConfig['leftPanel'][type] = value;
    } else if (type == 'state' || type == 'projectPlan') {
      inputReportConfig[type] = value && value.length > 0 ? value.join(",") : [];
    } else {
      inputReportConfig[type] = value;
    }
  }
  const onDownloadExcel = () =>{
    downloadExcel(props.ageingReportModel);
  }
  const onDownloadPDF = () =>{
    downloadPDF(props.ageingReportModel);
  }

  return (
    <div className="Report-MuiFormControl-root" style={{width:'100%' ,background: '#fff',padding: '10px'}}>
      {/* fields  */}
      <Grid container spacing={{ xs: 2, md: 3 }}>
      {/* As On Date */}
      <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month','day']}
              open={ageingAsOnDate}
              onOpen={() => setAgeingAsOnDate(true)}
              onClose={() => setAgeingAsOnDate(false)}
              label = {"As On Date"}
              value={asonDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setAsonDate(newDate);
                  handleStaticFilters(newDate+" 23:59:59",'asonDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                  fullWidth
                    className='full-width'
                     {...params} 
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setAgeingAsOnDate(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        {/* Sub Organization */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ageingSubOrganization"
            options={staticFilterData  &&  staticFilterData.suborganization||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                
                 {...params}
              InputLabelProps={{ shrink: true }}
                label="Sub Organization"
                placeholder="Select Sub Organization"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters([newValue],'subOrganization');
            }}

          />
        </Grid>
        {/*  Ageing List */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ageingAccount"
            options={staticFilterData  &&  staticFilterData.accountResult||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                
                 {...params}
              InputLabelProps={{ shrink: true }}
                label="Ageing List"
                placeholder="Select Ageing List"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleAccountChange(newValue);
              handleStaticFilters(newValue,'selectedValue');
            }}
          />
        </Grid>
        {/* Currency */}
        {staticFilterData?.currency && staticFilterData.currency.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ageingCurrency"
            options={staticFilterData  &&  staticFilterData.currency||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                
                 {...params}
              InputLabelProps={{ shrink: true }}
                label="Currency List "
                placeholder="Select Currency"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'selectedCurrency');
            }}
          />
          </Grid>  
          }
          {/* GST */}
          {runReportConfig.loadFilterData.get() && staticFilterData?.gst && staticFilterData.gst.length > 0 && 
          <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ageingAccount"
            options={staticFilterData  &&  staticFilterData.gst||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                
                 {...params}
              InputLabelProps={{ shrink: true }}
                label="GST"
                placeholder="Select GST"
              />
            )}
          />
        </Grid>
          }
        {/* State */}
        {runReportConfig.loadFilterData.get() && staticFilterData?.state && staticFilterData.state.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
           size="small"
            multiple
            id="ageingState"
            options={staticFilterData  &&  staticFilterData.state||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                
                 {...params}
              InputLabelProps={{ shrink: true }}
                label="State"
                placeholder={selectstate === false?"Select state":''}              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStateChange(newValue);
              handleStaticFilters(newValue,'state');
              if(newValue.length>0){setSelectState(true)}
              else{
                setSelectState(false)
              }
            }}
          />
        </Grid>
          }
        {/* Project */}
        {runReportConfig.loadFilterData.get() && staticFilterData?.projectPlan && staticFilterData.projectPlan.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            multiple
            id="ageingProject"
            options={staticFilterData  &&  staticFilterData.projectPlan||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                
                 {...params}
              InputLabelProps={{ shrink: true }}
                label="Project"
                placeholder={selectproject === false?"Select Project":''}              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'projectPlan');
              if(newValue.length>0){setSelectProject(true)}
              else{
                setSelectProject(false)
              }
            }}
          />
        </Grid>
          }

        <Grid item xs={3}>
        <TextField
            
            size="small"
            id="ageingPeriod"
            label="Ageing Periods"
            type="number"
            onChange={(event:any) => {
              handleAgingPeriodChange(event.target.value);
               handleStaticFilters(event.target.value,'agingPeriod');
             }}
          />
        </Grid>

         {/* Project */}
        {runReportConfig.loadFilterData.get() && staticFilterData?.agingPeriod && staticFilterData.agingPeriod > 0 && 
        // <Grid item xs={9}>
        // </Grid>
        <Grid container style={{padding: '10px 25px'}} spacing={{ xs: 1, md: 2 }}>
          {times(staticFilterData.agingPeriod, (index:any) => (
            <>
              <Grid item xs={6}>
                <TextField
                  
                  size="small"
                  label="From "
                  type="number"
                  variant="standard" 
                  onChange={(event: any) => {
                    handleAgingPeriodRangeChange(event.target.value,'From',index);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  
                  size="small"
                  label="To"
                  type="number"
                  variant="standard" 
                  onChange={(event: any) => {
                    handleAgingPeriodRangeChange(event.target.value,'To',index);
                  }}
                />
              </Grid>
              </>
          ))}
        </Grid>
        }     

      </Grid>
        {/* fields end */}
      {/* Run */}
      <Grid container style={{ margin: '10px',textAlign:'right',paddingRight:'10%'}} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        <Grid item xs={12}>
        <ButtonGroup>
        <Button style={{ margin: '2px',textAlign:'center'}} variant="contained" onClick={() => { runReport()}}>
            Run
          </Button>
          <Button
            style={{ margin: '2px',textAlign:'center'}}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onDownloadExcel()}>
            Download Excel
        </Button>
        <Button
            style={{ margin: '2px',textAlign:'center'}}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onDownloadPDF()}>
            Print
        </Button>
        </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '10px',textAlign:'center'}}  >
        <Grid item xs={12}>
        {runReportConfig.showLoader.get()  && 
              <CircularProgress color="secondary" />
        }
        </Grid>
      </Grid>
      {runReportConfig.loadReport.get()  && 
      <div className="ag-theme-alpine kgm-report-aggrid kgm-report-hwm dashboard-report" >
        <AgGridReact 
          columnDefs={gridReportInfo.columnDefs}
          defaultColDef={gridReportInfo.defaultColDef}
          getRowHeight = {gridReportInfo.getRowHeight}
          groupIncludeTotalFooter = {gridReportInfo.groupIncludeTotalFooter}
          icons = {gridReportInfo.icons}
          pagination = {gridReportInfo.pagination}
          paginationPageSize = {gridReportInfo.paginationPageSize}
          rowHeight =   {gridReportInfo.rowHeight}
          rowData={gridReportInfo.rowData}
          suppressAggFuncInHeader={gridReportInfo.suppressAggFuncInHeader}
         ></AgGridReact>
        </div>
        }
      </div>
  );
};
