import { extractDropdownOption, getHtmlControl } from "../../../../../../service/PresentationService";
import { createFieldComponents } from "../../../../../form/builder/FieldPresentationBuilder"
import { getFormModel } from "../../../../../form/controller/KagamiFormController"
import { KagamiGridModel } from "../../model/KagamiGridModel";

export default (props: any) =>{
   let kagamiGridModel : KagamiGridModel= props.kagamiGridModel;
    
   if(kagamiGridModel.activeRowNode !== null && kagamiGridModel.activeRowNode.data.id === props.data.id){
      return createFieldComponents(props.attributePresentation,getFormModel().formControls[kagamiGridModel.mainEntityId],props.data,true,false,true,false)
   }
   else{
      return <div>{getGridCellValue(props.attributePresentation,props)}</div>
   }
}

function getGridCellValue(attributePresentation:any,gridParams : any){
   let fieldValue = gridParams.value;
   let controlType = getHtmlControl(attributePresentation);
   switch(controlType){
      case 'select':
      case 'search':
      case 'multiselect':
         let value = gridParams.value;
         if(value instanceof Object){
          fieldValue = extractDropdownOption(attributePresentation,value);
         }
         break;
      case 'password':
      case 'number':
      case 'text':
      case 'textarea':
        if ((fieldValue == null || fieldValue === undefined) && controlType === 'number') {
            fieldValue = 0;
         }
         break;
      default:
         break;   
   }
   return fieldValue;
}