import { KagamiEmbeddedFormPresentationModel } from '../../../../../model/KagamiEmbeddedFormPresentationModel';

export class KagamiExpandedEmbeddedPresentationModel extends KagamiEmbeddedFormPresentationModel {
  nestedEmbeddedModels: KagamiEmbeddedFormPresentationModel[];
  initialEmbedFormPresentationModel : KagamiEmbeddedFormPresentationModel;

  constructor(
    onStartData: any,
    mainEntityId: any,
    formEntityId: string,
    formHeader: string,
    processName: string,
    processStepName: string,
    uiTemplate: string,
    initiallyVisible : boolean,
    initiallyReadOnly : boolean,
    initialEmbedFormPresentationModel : KagamiEmbeddedFormPresentationModel,
    isDynamicForm : boolean
  ) {
    super(onStartData, mainEntityId, formEntityId, formHeader, processName, processStepName, uiTemplate,initiallyVisible,initiallyReadOnly,isDynamicForm);
    this.nestedEmbeddedModels = [];
    this.initialEmbedFormPresentationModel = initialEmbedFormPresentationModel;
  }
}

export interface ExpandedEmbeddedPresentationModelProps {
  expandedEmbeddedPresentationModel: KagamiExpandedEmbeddedPresentationModel;
}
