import { SystemConstants } from '../../../application/constants/SystemConstants';
import { KagamiStateManager } from '../../../state/KagamiStateManager';
import { KagamiLoaderModel } from '../model/KagamiLoaderModel';
import { buildKagamiLoader } from '../ui/KagamiLoader';

export function buildLoader(loaderModel: KagamiLoaderModel) {
  return buildKagamiLoader(loaderModel);
}

const disableKeyPressEvents = (event: KeyboardEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

let loaderDebounce: any;
export function showLoader(isSearch : boolean = false) {
  loaderDebounce && clearTimeout(loaderDebounce);
  document.addEventListener('keydown', disableKeyPressEvents);
  loaderDebounce = setTimeout(() => {
    KagamiStateManager.getStateToHolder(SystemConstants.APP_LOADER).setLoaded(true);
  },isSearch ? 0 : 700);
}

export function hideLoader() {
  loaderDebounce && clearTimeout(loaderDebounce);
  document.removeEventListener('keydown', disableKeyPressEvents);
  KagamiStateManager.getStateToHolder(SystemConstants.APP_LOADER).setLoaded(false);
}
