import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const ReportsPageStyle = (theme: Theme) =>
createStyles({
    main_page_reports: {
      display: 'flex',
      flexDirection: 'column',
      background: '#fff'
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px',
      padding: '4px',
      marginBottom:'0'
    },
    header_row: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    header_row_reportName: {
      color: theme.palette.primary.dark,
      lineHeight: '0',
      fontSize: '17.6px',
      fontWeight: '500'
    },

    header_report_back_button: {
      'backgroundColor': '#ffff',
      'color': '#6ebe44',
      'border': '2px solid #dbdbdb',
      'transitionDuration': '0.4s',
      'width': '50px',
      'height': '30px',
      'borderRadius': '5px',
      'cursor':'pointer',
      '&:hover': {
        backgroundColor: '#ffff',
        color: '#2d72b9',
        border: '2px solid #6ebe44'
      }
    },


    report_header_link: {
      // display: 'flex',
      // justifyContent: 'space-between',
      'cursor': 'pointer',
      '&:hover': {
        textDecoration: 'underline',
        // '& asondate':{
        //   textDecoration:'none'
        // }
      }
      
    },
    report_header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    asondate: {
      textDecoration:'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    
    report_content: {
      margin: '0px 10px',
      padding: '4px',
      '& .NoDataFound':{
        color:'red',
        fontSize:'12px',
        margin:'0px',
        padding:'0px'
      },
      '& .MuiInputBase-root' : {
        minHeight: '37px'
      }
    },

    report_header_link_main: {
      'fontSize': '14px',
      'cursor': 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },

    report_header_link_main_title: {
      color: '#2d72b9'
    },

    report_header_link_main_relationName: {
      color: '#6ebe44'
    },
    report_as_on_date: {
      'display': "flex",
    'flex-direction': "row-reverse",
    'margin-bottom': '11px'
    
    },

    hidden: {
      display:'none'
    },


  });
