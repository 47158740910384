import { Avatar, AvatarGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AgGridReact } from 'ag-grid-react';

import { buildModal } from '../../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { KagamiModalModel } from '../../../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { getDmsViewUrl, getDownloadFileUrl } from '../../../../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getAttributeLabel, getAttributeName, getDefaultEmbeddedFormView } from '../../../../../../service/PresentationService';
import { KagamiAttachmentsModalModel } from '../../../../../controls/file/builder/attachments/model/KagamiAttachmentsModalModel';
import { buildAttachmentsModal } from '../../../../../controls/file/builder/attachments/ui/AttachmentsModal';
import { buildDms } from '../../../../../dms/controller/kagamiDMSController';
import { KagamiDMSModel } from '../../../../../dms/model/kagamiDMSModel';
import { cellRendererCustomComponentsStyle } from '../../style/CellRendererCustomComponents'; 
import uploadIcon from "../../../../../../../../../../../../../assets/images/custom-icons/multi_doc_upload.svg"; 
import React, { useEffect } from 'react';
import { KagamiFormModel } from '../../../../../form/model/KagamiFormModel';
import { getFormModel } from '../../../../../form/controller/KagamiFormController';
import { KagamiGridModel } from '../../model/KagamiGridModel';
import { getKagamiListModel } from '../../../../controller/KagamiListController';

 const KagamiThumbnailCellRenderer = (props: any) => {
  let start = Date.now();
  useEffect(() => {
    // console.timeEnd('Execution time');
    let timeTaken = Date.now() - start;
    // console.log('KagamithumbnaliCellRenderer',timeTaken+'ms')
      });
    return (
          <div style={{display:'flex', alignItems:'center', gap:'5px' }}> 
            <ThumbnailsView thumbnailData={props}    />

             {/* <RenderCountData value={1}/> */}
           

      </div>
    )
}







export const ThumbnailsView = React.memo((props: any) => {
     let kagamiFormModel : KagamiFormModel = getFormModel();
     const classes = makeStyles(cellRendererCustomComponentsStyle)();
     let popupModel = props.thumbnailData.popupModel;
     let attachmentsModel:KagamiAttachmentsModalModel = new KagamiAttachmentsModalModel();
     const attrName = getAttributeName(props.thumbnailData.attributePresentation);
     let docDataString = props.thumbnailData.data[attrName];
    //  let controlValue  = CommonUtils.isNotEmpty(docDataString) ? docDataString.split(',') : ''
    //  let nonEmptyControlValues = controlValue ?controlValue.filter((item: any) => item  ): [];
     
    
  let controlValue = CommonUtils.isNotEmpty(docDataString) ?  docDataString.split(',') : []
  let controlValuedocids:any[] =[] ;
    controlValue.map((item:any) => {
      if (item.includes('.')){
        controlValuedocids.push( item.split(':')[0] ) ;
      } else {
        controlValuedocids.push(item)
       }
    })


 function getdocInfofromForm(attributeName:string){
  let formModel = getFormModel();
  let docIn:any;
  if(props.thumbnailData.gridModel.isEmbedForm) {
    let listModel = getKagamiListModel();
 return listModel.docInfo =  formModel ? formModel.docInfo[attributeName] : {}
  } else {
docIn =  formModel ? formModel.docInfo[attributeName] : {}
return CommonUtils.isNotEmpty(docIn) ? docIn : {}
}
}

let docInfo = CommonUtils.isNotEmpty(props.thumbnailData.gridModel.docInfo)?props.thumbnailData.gridModel.docInfo :  getdocInfofromForm(attrName) ;
const thumbnailhandleClick =() => {
           //opening attachments modal for card view
          attachmentsModel.attrName = getAttributeLabel(props.thumbnailData.attributePresentation);
          attachmentsModel.kagamiGridAttachemntsData = props.thumbnailData
          attachmentsModel.state.setOpen(true);
    } 

  const buildThumbnails = (docInfoData: any, controlValues:any) => {
   
    

  return controlValues.map((fileString:any, index:any) => {

    let fileId  =  fileString.split(":")[0];
    let fileName= '';
    let fileType= '';
    let baseUrl = '';
    let imageFromDocInfo = CommonUtils.isNotEmpty(docInfoData) && CommonUtils.isNotEmpty( docInfoData[fileId]) ? docInfoData[fileId] : {} ;
    if(CommonUtils.isNotEmpty(imageFromDocInfo)) {
      fileName = imageFromDocInfo['fileName'];
      fileType = imageFromDocInfo['fileType'] ;
      baseUrl = docInfoData[fileId]['thumbnail'] ;
      fileString = fileId + ':' + fileName  ;
    } else {
       
      fileString = fileId 
    }
    if(index < 3) { 
      if(CommonUtils.isNotEmpty(baseUrl)) {
        return (
          <img title={fileName}
            onClick={(event: any) => { 
             CommonUtils.isNotEmpty(fileId) &&  CommonUtils.isNotEmpty(fileName) ?  CommonUtils.handlePreviewOrDownloadFileInGrid( attrName,   popupModel, fileString) :CommonUtils.previewFileInGrid( attrName,fileId,   popupModel, fileName)
              }}
            style={{
              display: 'flex',
              gap: '0px',
              alignItems: 'center', 
              maxWidth:controlValues.length === 1 ? '60px' : '50px',
              width:controlValues.length === 1 ?'60px' : '',
              height: '25px',
              border: '1px solid #006E7F',
              borderRadius: '3px'
            }}
            src={`data:image/png;base64,${baseUrl}`}
                 /> 
        )
      } else {
        return(
             <img
             style={{ width: 29, height: 29}}
             alt={fileName} title={fileName} 
             src={CommonUtils.getFileTypeICon(fileType)}
             onClick={() =>{
               // CommonUtils.isEmpty(fileName) && fileType === allowedFormats ?  CommonUtils.previewFileInGrid( attrName,fileId,   popupModel): 
                CommonUtils.handlePreviewOrDownloadFileInGrid( attrName,   popupModel, fileString) 
               }}
             />
             )
      }
    }

  })
  }; 

  const defaultIcon = () => {
    if(props?.thumbnailData?.gridModel?.isEditableEmbedList) {
    return (
      <img  style={{ height: '20%', width: '20%' }}src={uploadIcon}/>
    )
    }
  }
 
  return (
     <>
     {buildThumbnails(docInfo,controlValuedocids)}
     {/* {CommonUtils.isEmpty(controlValuedocids) ? defaultIcon() : ''} */}
     {controlValuedocids.length > 3 ? (
            <span style={{ margin: '0px', cursor:"pointer" , fontSize:'12px', fontWeight:'bold' }}
            onClick={thumbnailhandleClick}   >
            
            { '+ ' +  `${controlValuedocids.length - 3}` + ' more' } 
            </span>
          ) : <></>}


          
          {buildAttachmentsModal(attachmentsModel)}
     </>
     
  )

    

})

 

//if grid view is needed for list of files use this
// const ThumbnailViewGridData = (props: any) => {
//     const {thumbnailData} = props;


//     const getFileDataForFile = (thumbnailData:any) => {
//      let fileGridData: any[] = [];
//      let controlDisplayName =  getAttributeLabel(thumbnailData.attributePresentation) 
     
//      if (thumbnailData.value.includes(',')) {
//        let arr = thumbnailData.value.split(',');
//        let result = arr.filter((e: any) => e);
//        result.forEach((ele: any, index: number) => {
//          let gridObjData: any = {};
//          let eleData = ele.split(':');
//          gridObjData['SNo'] = index + 1;
//          gridObjData[controlDisplayName] = eleData[1];
//          gridObjData['Action'] = eleData[0];
//          fileGridData.push(gridObjData);
//        });
//      }
//      return fileGridData;
   
//    }
   
//      const columnDefs = [
//           { field: 'SNo' },
//           {
//                field: `${getAttributeLabel(thumbnailData.attributePresentation)}`
//           },
//           {
//                field: 'Actions', cellRenderer: 'PreviewFileCellRenderer', cellRendererParams: { thumbnailData:thumbnailData  }
//           }
//      ];
//      return (
//           <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
//                <AgGridReact
//                     columnDefs={columnDefs}
//                     components={{ PreviewFileCellRenderer: PreviewFileCellRenderer }}
//                     rowData={getFileDataForFile(thumbnailData)}
//                     domLayout= 'autoHeight'
//                >
//                </AgGridReact>
              
//           </div>
//      );
// };


export default KagamiThumbnailCellRenderer
