import { createState } from '@hookstate/core';
import {
  StackedBarReportModel,
  StackedBarReportModelProps,
  useStackedBarReportModelState
} from '../model/StackedBarReportModel';
import { makeStackedBar } from '../service/StackedBarReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { CircularProgress } from '@mui/material';
const HC_grouped = require("highcharts-grouped-categories");
HC_grouped(Highcharts);



export const BuildStackedBarReportView = (stackedBarReportModel: StackedBarReportModel) => {
  return (
    <KagamiStackedBarChartReportView key={'stacked-bar-chart-report'} stackedBarReportModel={stackedBarReportModel}></KagamiStackedBarChartReportView>
  );
};


const KagamiStackedBarChartReportView = (props: StackedBarReportModelProps) => {
  let stackedBarReportModel = props.stackedBarReportModel;
  let stackedBarChartState = useStackedBarReportModelState(createState(stackedBarReportModel));
  stackedBarReportModel.state = stackedBarChartState;
  const chartComponent:any = React.useRef(null);
  React.useEffect(() => {
    getReportDataForDynamicReports(props.stackedBarReportModel)
  }, [])

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartComponent.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);
  
  if(!stackedBarChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{
    if(stackedBarReportModel.reportData){
  const stackedBarConfig :any = makeStackedBar(stackedBarReportModel);

  return (
    <div ref={containerRef} className="kgm-report-container dashboard-highchart kgm-report-hw" >
      <Chart ref={chartComponent} highcharts={Highcharts} options={stackedBarConfig} />
    </div>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
}
};
