import { State, useState } from "@hookstate/core";

export class TriggerAlertModalModel{
    alertType: string;
    alertName: string;
    alertMessage: string;
    open:boolean;
    flag:boolean;
    callback:any;
    state:any;
    key : string;
    constructor(key: string){
        this.alertType = '';
        this.alertName = '';
        this.alertMessage = '';
        this.open = false;
        this.flag = false;
        this.key =key
    }
}

export interface TriggerAlertModalModelProps{
    triggerAlertModalModel:TriggerAlertModalModel;
}

export const useTriggerAlertModalState = (state: State<TriggerAlertModalModel>) => {
    const triggerAlertModalState = useState(state);
    return {
        get isOpen(){
            return triggerAlertModalState.open.get();
        },

        setOpen(open:boolean){
            triggerAlertModalState.open.set(open);
        }
    }
}