import { createState } from '@hookstate/core';
import { Button, Typography, Divider, FormLabel, Theme } from '@mui/material';
import { Fragment, useState } from 'react';
import {
  getAttributePresentations,
  getAttributePresentationType,
  getBusinessPolicy,
  getEmbeddedView,
  getFormPresentationFormAttribute,
  getHeaderForPresentation,
  getMainEntityPresentationsFromUIResource,
  getMappedEntityIdForPresentation,
  getPresenationHeaderFromPresentation,
  getPresentationPolicy,
  isListPresentation,
  isMandatoryPresentation,
  isOnCancelRequiredForPresentation,
  isOnLoadRequiredForPresentation,
  isReadOnlyAttribute,
  isVisiblePresentation,
  retrievePresentationsForEntity,
  retrievePresentationsFromUIResource,
  getUiSettings,
  fetchIsDynamicForm,
  getAttributeEventsForDynamicForm,
  getPRuleIdForPresentation,
  getPresenationIdFromPresentation,
  isPivot,
  getHeaderForprocess,
  translateToLocalistaion
} from '../../../service/PresentationService';

import { getControlWidth, getFormModel, handleFormReset, handleFormSubmit } from '../controller/KagamiFormController';
import { FormButtonProps, FormModelProps, KagamiFormModel } from '../model/KagamiFormModel';
import {
  getActiveFormRecord,
  getFormRecords,
  handleEmbededReset,
  handleGroupBackButton,
  isSupportedTemplateForSections,
  showGroupBackButton
} from '../service/FormService';
import { createFieldComponents } from './FieldPresentationBuilder';
import { buildFormPresentation } from './form-presenation/controller/KagamiFormPresentationController';
import {
  KagamiFormPresentationModel,
  KagamiFormPresentationProps
} from './form-presenation/model/KagamiFormPresentationModel';
import { createNonFieldPresentation } from './NonFieldPresentationBuilder';
import { buildFormSectionPresentation } from './section-presentation/controller/KagamiFormSectionPresentationController';
import {
  FormSectionPresentationProps,
  KagamiFormSectionPresentationModel
} from './section-presentation/model/KagamiFormSectionPresentationModel';
import { ModalPopUpProps } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiTriggersController } from '../../Triggers/controller/KagamiTriggersController';
import { KagamiEmbeddedFormPresentationModel } from '../model/KagamiEmbeddedFormPresentationModel';
import { KagamiEmbeddedPresentationModel } from './embedded-presentation/model/KagamiEmbeddedPresentationModel';
import { buildEmbeddedPresentation } from './embedded-presentation/controller/KagamiEmbeddedPresentationController';
import { getHomeModel } from '../../../../../../../controller/KagamiHomeController';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { buildStepPresentation } from './embedded-presentation/controller/KagamiStepPresentationController';
import { KagamiStepPresentationModel } from './embedded-presentation/model/KagamiStepPresentationModel';
import {
  buildErrorModal,
  handleErrorModalOnClose
} from '../../../../../../../../../../components/errorModal/controller/KagamiErrorModalController';
import { KagamiExpandedEmbeddedPresentationModel } from './embedded-presentation/builder/expanded-embedded-presentation/model/KagamiExpandedEmbeddedPresentationModel';
import _, { delay } from 'lodash';
import { createStyles, makeStyles } from '@mui/styles';
import { KagamiFormStyle } from '../style/KagamiFormStyle';
import { buildNestedEmbedModels } from './embedded-presentation/builder/embedded-accordion-presentation/builder/EmbeddedFormBuilder';
import { KagamiDashboardStyle } from '../../../../dashboard/style/KagamiDashboard';
import { KagamiAccordionModel } from '../../../../../../../../../../components/accordion/model/KagamiAccordionModel';
import { buildAccordion } from '../../../../../../../../../../components/accordion/controller/KagamiAccordionController';
import { KagamiFormPresentationStyle } from './form-presenation/style/KagamiFormPresentation';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { buildProcessPageHeaderName } from '../../ProcessPageBuildler';
import { getActiveModel } from '../../../controller/KagamiProcessController';

export function buildPresentation(kagamiFormModel: KagamiFormModel) {
  let formComponents: any[] = [];
  let embedFormModels: any[] = [];
  let stepFormModels: any[] = [];
  let embeddedView: string = 'accordion';
  let isDynamicForm = false;
  let isStepForm: boolean = false;
  let formComponentObj: any = {
    mainTransaction: [],
    embeddedTransaction: []
  };

  let presentations: any[] = getMainEntityPresentationsFromUIResource(kagamiFormModel.onStartData);

  let pruleIndex: number;

  for (pruleIndex = 0; pruleIndex < presentations.length; pruleIndex++) {
    let presentation: any = presentations[pruleIndex];

    let entityId: string = getMappedEntityIdForPresentation(presentation);
    let formHeader: string = getHeaderForPresentation(presentation);
    let isMandatory: boolean = isMandatoryPresentation(presentation);
    let isVisible: boolean = isVisiblePresentation(presentation);
    let isList: boolean = isListPresentation(presentation);
    let isOnloadRequired: boolean = isOnLoadRequiredForPresentation(presentation);
    let presentationPolicy: any = getPresentationPolicy(presentation);
    let businessPolicy: any = getBusinessPolicy(presentation);
    let presentationId: any = getPresenationIdFromPresentation(presentation);
    isDynamicForm = fetchIsDynamicForm(presentation);

    if (pruleIndex === 0) {
      let formPresentationModel: KagamiFormPresentationModel = new KagamiFormPresentationModel(
        kagamiFormModel.onStartData,
        kagamiFormModel.mainEntityId,
        entityId,
        formHeader,
        kagamiFormModel.processName,
        kagamiFormModel.processStepName,
        kagamiFormModel.uiTemplate,
        isVisible,
        kagamiFormModel.readOnly,
        isDynamicForm
      );
      formPresentationModel.presentationId = presentationId;
      formPresentationModel.isGroupForm = kagamiFormModel.isGroupForm;
      formPresentationModel.presentation = presentation;
      formPresentationModel.readOnly = kagamiFormModel.readOnly;
      formPresentationModel.mandatory = isMandatory;
      formPresentationModel.visible = isVisible;
      formPresentationModel.isListPresentation = false;
      formPresentationModel.onloadRequired = isOnloadRequired;
      formPresentationModel.presentationPolicy = presentationPolicy;
      formPresentationModel.businessPolicy = businessPolicy;
      formPresentationModel.onLoadData = kagamiFormModel.onLoadData;

      embeddedView = getEmbeddedView(presentation['uiSettings']);
      kagamiFormModel.isPivot = isPivot(presentation);
      kagamiFormModel.pushToFormControls(entityId, formPresentationModel);

      isStepForm = isStepFormTrue(formPresentationModel);
      if (isStepForm) {
        stepFormModels.push(formPresentationModel);
        kagamiFormModel.isStepForm = true;
        lookForTreeFormModelsInStepForm(formPresentationModel,stepFormModels,embeddedView)
      } else {
        let formComponent: any = buildFormPresentation(formPresentationModel);
        formComponentObj.mainTransaction.push(formComponent);
        formComponents.push(formComponent);
      }
    } else {
      if (presentation.presentationRules) {
        let embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel = buildEmbeddedModel(
          presentation,
          kagamiFormModel.onStartData,
          kagamiFormModel.mainEntityId,
          entityId,
          formHeader,
          kagamiFormModel.processName,
          kagamiFormModel.processStepName,
          kagamiFormModel.uiTemplate,
          isMandatory,
          isVisible,
          kagamiFormModel.readOnly,
          isList,
          isOnloadRequired,
          CommonUtils.isNotEmpty(kagamiFormModel.onLoadData)
            ? _.cloneDeep(kagamiFormModel.onLoadData[entityId])
            : _.cloneDeep(kagamiFormModel.onLoadData),
          presentationPolicy,
          businessPolicy,
          isDynamicForm,
          presentationId
        );
        embeddedFormPresentationModel.embeddedView = embeddedView;
        if (isStepForm) {
          stepFormModels.push(embeddedFormPresentationModel);
        } else {
          embedFormModels.push(embeddedFormPresentationModel);
        }
        buildNestedEmbedModels(embeddedFormPresentationModel);
        kagamiFormModel.pushToFormControls(entityId, embeddedFormPresentationModel);
      }
    }
  }
  if (isStepForm) {
    let stepPresentationModel: KagamiStepPresentationModel = new KagamiStepPresentationModel(stepFormModels);
    formComponentObj.mainTransaction.push(buildStepPresentation(stepPresentationModel));
  } else if (embedFormModels.length > 0) {
    let embeddedPresentationModel: KagamiEmbeddedPresentationModel = new KagamiEmbeddedPresentationModel(
      embedFormModels,
      embeddedView
    );
    embeddedPresentationModel.processName = kagamiFormModel.processName;
    embeddedPresentationModel.processStepName = kagamiFormModel.processStepName;
    formComponentObj.embeddedTransaction.push(buildEmbeddedPresentation(embeddedPresentationModel));
  }
  return formComponentObj;
}

export function isStepFormTrue(formPresentationModel: KagamiFormPresentationModel) {
  let pageBreak: boolean = false;
  let nonFieldPresentation: boolean = false;
  try {
    let presentationRules = formPresentationModel.presentation.presentationRules;
    if (formPresentationModel.uiTemplate === 'pcEmbedEdit' || formPresentationModel.uiTemplate === 'pcEmbedForm'
         || formPresentationModel.uiTemplate === 'treeForm' || formPresentationModel.uiTemplate === 'treeEdit') {
      for (const object in presentationRules) {
        let nonField = presentationRules[object];
        for (const key in nonField) {
          if (nonField[key] === 'PAGE_BREAK') {
            pageBreak = true;
          } else if (nonField[key] === 'NonFieldPresentation') {
            nonFieldPresentation = true;
          }
        }
      }
    }
    if (pageBreak && nonFieldPresentation) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('Error step form condition', error);
    return false;
  }
}

export function buildEmbeddedModel(
  presentation: any,
  onStartData: any,
  mainEntityId: string,
  entityId: string,
  formHeader: string,
  processName: string,
  processStepName: string,
  uiTemplate: string,
  isMandatory: boolean,
  isVisible: boolean,
  isReadOnly: boolean,
  isList: boolean,
  isOnloadRequired: boolean,
  onLoadData: any,
  presentationPolicy: any,
  businessPolicy: any,
  isDynamicForm: boolean,
  presentationId: any
) {
  let isOnCancelRequired: boolean = isOnCancelRequiredForPresentation(presentation);
  let embeddedPresentationModel: KagamiEmbeddedFormPresentationModel = new KagamiEmbeddedFormPresentationModel(
    onStartData,
    mainEntityId,
    entityId,
    formHeader,
    processName,
    processStepName,
    uiTemplate,
    isVisible,
    isReadOnly,
    isDynamicForm
  );
  embeddedPresentationModel.presentationId = presentationId;
  embeddedPresentationModel.presentation = presentation;
  embeddedPresentationModel.mandatory = isMandatory;
  embeddedPresentationModel.visible = isVisible;
  embeddedPresentationModel.readonly = isReadOnly;
  embeddedPresentationModel.isListPresentation = isList;
  embeddedPresentationModel.onloadRequired = isOnloadRequired;
  embeddedPresentationModel.onLoadData = onLoadData;
  embeddedPresentationModel.onCancelRequired = isOnCancelRequired;
  embeddedPresentationModel.createState = createState(embeddedPresentationModel);
  embeddedPresentationModel.presentationPolicy = presentationPolicy;
  embeddedPresentationModel.businessPolicy = businessPolicy;
  return embeddedPresentationModel;
}

export function buildFormComponents(
  formPresentationModel: KagamiFormPresentationModel,
  sectionParentPresentationModel: KagamiFormPresentationModel | null
) {
  let formComponents: any[] = [];
  let footerComponents: any[] = [];
  let embeddedView =
    formPresentationModel.presentation['uiSettings'] !== undefined
      ? getEmbeddedView(formPresentationModel.presentation['uiSettings'])
      : 'tab';
  let embedFormModels: KagamiEmbeddedFormPresentationModel[] = [];
  let formComponentObj: any = {
    mainTransaction: [],
    embeddedTransaction: []
  };
  let formModel: KagamiFormModel = getFormModel();
  /// Note : this flag is used to avoid building embeds in case of step form and it is of tree type form
  let isStepForm = formModel.isStepForm && formPresentationModel.formEntityId === formModel.mainEntityId
  const presentation = formPresentationModel.presentation;

  const attributePresentations: any[] = getAttributePresentations(presentation);
  let attributeEvents: any[] = [];
  if (formPresentationModel.isDynamicForm) {
    /// Note : Checking whether it is section presentation or not to check where to fetch the presentation id
    attributeEvents = getAttributeEventsForDynamicForm(
      formPresentationModel.onStartData,
      getPRuleIdForPresentation(formPresentationModel)
    );
  }

  for (const attributeName in attributePresentations) {
    let attributePresentation = attributePresentations[attributeName];
    const uiSettings = getUiSettings(attributePresentation);

    if (uiSettings && uiSettings.fieldGap?.value) {
      formComponents.push(buildBreakLine());
    }
    if (getAttributePresentationType(attributePresentation) === 'FieldPresentation') {
      let attributeEvent: any;
      let isEmbedForm: boolean = false;
      let onloadData: any =
        sectionParentPresentationModel == null
          ? formPresentationModel.onLoadData
          : sectionParentPresentationModel.onLoadData;
      if (formPresentationModel.isDynamicForm) {
        attributeEvent = attributeEvents.find((p) => p.attributeName === attributePresentation.attrName);
      }
      if (formPresentationModel instanceof KagamiEmbeddedFormPresentationModel) {
        /// Note : taking active record data to populate single embed form data
        onloadData = _.cloneDeep(getActiveFormRecord(formModel, formPresentationModel.formEntityId));
        isEmbedForm = formPresentationModel.isActiveForm;
        if (CommonUtils.isEmpty(onloadData)) {
          onloadData = formPresentationModel.oldEditRecord;
        }
      }
      if (attributePresentation.hasOwnProperty('footerElement') && attributePresentation.footerElement) {
        footerComponents.push(
          createFieldComponents(
            attributePresentation,
            sectionParentPresentationModel == null ? formPresentationModel : sectionParentPresentationModel,
            onloadData,
            false,
            isEmbedForm,
            formPresentationModel.isDynamicForm,
            attributeEvent
          )
        );
      } else {
        CommonUtils.isEmpty(formComponentObj.embeddedTransaction)
          ? formComponentObj.mainTransaction.push(
              createFieldComponents(
                attributePresentation,
                sectionParentPresentationModel == null ? formPresentationModel : sectionParentPresentationModel,
                onloadData,
                false,
                isEmbedForm,
                formPresentationModel.isDynamicForm,
                attributeEvent
              )
            )
          : formComponentObj.embeddedTransaction.push(
              createFieldComponents(
                attributePresentation,
                sectionParentPresentationModel == null ? formPresentationModel : sectionParentPresentationModel,
                onloadData,
                false,
                isEmbedForm,
                formPresentationModel.isDynamicForm,
                attributeEvent
              )
            );
      }
    }
    /// Note : avoiding building non field presentations if it is single record list detail view
    else if (
      getAttributePresentationType(attributePresentation) === 'NonFieldPresentation' &&
      !formModel.isSingleListView
    ) {
      let presenationId =
        formPresentationModel instanceof KagamiFormSectionPresentationModel
          ? formPresentationModel.parentPRuleId
          : presentation.presentationId;
      let nonFieldComponent = createNonFieldPresentation(
        attributeName,
        attributePresentations[attributeName],
        presenationId,
        formPresentationModel.formEntityId,
        formPresentationModel instanceof KagamiEmbeddedFormPresentationModel ? false : true
        // true
      );
      if (!(formPresentationModel instanceof KagamiExpandedEmbeddedPresentationModel)) {
        const fieldWidth: any = getControlWidth(attributePresentations[attributeName]['uiSettings']);

        // formComponents.push(<div className={fieldWidth}>
        //   {nonFieldComponent}
        // </div>);

        switch (attributePresentations[attributeName]['type']) {
          case 'PARAGRAPH':
            formComponentObj.mainTransaction.push(
              <div className={fieldWidth + ' ' + 'non_field_p'}>
                <Typography key={'way-to-paragraph' + Math.random()} variant="caption" color="action">
                  {nonFieldComponent}
                </Typography>
              </div>
            );
            break;
          case 'HEADER':
            formComponentObj.mainTransaction.push(
              <div className={fieldWidth + ' ' + 'non_field_header'}>
                {/* <Typography key={"way-to-header" + Math.random()} variant="subtitle2" style={{ textAlign: 'left', color: '#000' }}> */}
                <Typography
                  key={'way-to-header' + Math.random()}
                  variant="body1"
                  style={{ textAlign: 'left', color: '#006e7f', margin: '0' }}
                >
                  {nonFieldComponent}
                </Typography>
              </div>
            );
            break;
          default:
            formComponentObj.mainTransaction.push(<div className={fieldWidth}>{nonFieldComponent}</div>);
            break;
        }
      }
    } else if (
      getAttributePresentationType(attributePresentation) === 'SectionPresentation' &&
      isSupportedTemplateForSections(formPresentationModel.uiTemplate)
    ) {
      let parentPresentationModel: KagamiFormPresentationModel =
        sectionParentPresentationModel == null ? formPresentationModel : sectionParentPresentationModel;
      let formSectionPresentationModel: KagamiFormSectionPresentationModel = new KagamiFormSectionPresentationModel(
        attributeName,
        parentPresentationModel,
        attributePresentation,
        formPresentationModel.onStartData,
        formPresentationModel.mainEntityId,
        formPresentationModel.formEntityId,
        getPresenationHeaderFromPresentation(attributePresentation),
        formPresentationModel.processName,
        formPresentationModel.processStepName,
        formPresentationModel.uiTemplate,
        isVisiblePresentation(attributePresentation),
        isReadOnlyAttribute(attributePresentation),
        uiSettings,
        formPresentationModel.isDynamicForm,
        sectionParentPresentationModel !== null
          ? getPRuleIdForPresentation(sectionParentPresentationModel)
          : getPresenationIdFromPresentation(formPresentationModel.presentation),
        createState
      );
      formSectionPresentationModel.presentationPolicy = getPresentationPolicy(attributePresentation);
      formSectionPresentationModel.businessPolicy = getBusinessPolicy(attributePresentation);
      formSectionPresentationModel.readOnly = formModel.readOnly;
      getFormModel().formControls[formPresentationModel.formEntityId + '_' + attributePresentation.identifier] =
        formSectionPresentationModel;

      formComponentObj.mainTransaction.push(buildFormSectionPresentation(formSectionPresentationModel));
    } else if (getAttributePresentationType(attributePresentation) === 'FormPresentation' && !isStepForm) {
      let entityPresentation: any = getFormPresentationFormAttribute(attributePresentation);
      if (entityPresentation.presentationRules) {
        let entityId: string = getMappedEntityIdForPresentation(entityPresentation);
        let formHeader: string = getHeaderForPresentation(entityPresentation);
        let isMandatory: boolean = isMandatoryPresentation(entityPresentation);
        let isVisible: boolean = isVisiblePresentation(entityPresentation);
        let isList: boolean = isListPresentation(entityPresentation);
        let isOnloadRequired: boolean = isOnLoadRequiredForPresentation(entityPresentation);
        let presentationPolicy: any = getPresentationPolicy(entityPresentation);
        let businessPolicy: any = getBusinessPolicy(entityPresentation);
        let presentationId: any = getPresenationIdFromPresentation(entityPresentation);
        let embeddedFormPresentationModel = buildEmbeddedModel(
          entityPresentation,
          formPresentationModel.onStartData,
          formPresentationModel.mainEntityId,
          entityId,
          formHeader,
          formPresentationModel.processName,
          formPresentationModel.processStepName,
          formPresentationModel.uiTemplate,
          isMandatory,
          isVisible,
          formPresentationModel.readonly,
          isList,
          isOnloadRequired,
          formPresentationModel.onLoadData !== undefined
            ? formPresentationModel.onLoadData[entityId]
            : formPresentationModel.onLoadData,
          presentationPolicy,
          businessPolicy,
          formPresentationModel.isDynamicForm,
          presentationId
        );
        embeddedFormPresentationModel.onLoadData = getFormRecords(embeddedFormPresentationModel);
        embeddedFormPresentationModel.embeddedView = embeddedView;
        if (formPresentationModel instanceof KagamiEmbeddedFormPresentationModel) {
          embeddedFormPresentationModel.isNested = true;
          embeddedFormPresentationModel.parentEmbedEntityId = formPresentationModel.formEntityId;
        }

        if (embeddedView === 'tab') {
          embedFormModels.push(embeddedFormPresentationModel);
        } else {
          let embeddedPresentationModel: KagamiEmbeddedPresentationModel = new KagamiEmbeddedPresentationModel(
            [embeddedFormPresentationModel],
            embeddedView
          );
          formComponentObj.embeddedTransaction.push(buildEmbeddedPresentation(embeddedPresentationModel));
        }
        getFormModel().pushToFormControls(embeddedFormPresentationModel.formEntityId, embeddedFormPresentationModel);
      }
    }
  }

  if (embedFormModels.length > 0) {
    let embeddedPresentationModel: KagamiEmbeddedPresentationModel = new KagamiEmbeddedPresentationModel(
      embedFormModels,
      embeddedView
    );
    formComponentObj.embeddedTransaction.push(buildEmbeddedPresentation(embeddedPresentationModel));
  }

  formModel.footerElements = footerComponents;
  return formComponentObj;
}

export function buildNestedEmbedPresentation(embeddedFormPresentationModel: KagamiFormPresentationModel) {
  let presentationMap: any = retrievePresentationsFromUIResource(embeddedFormPresentationModel.onStartData);
  let presentations: any[] = retrievePresentationsForEntity(
    presentationMap,
    embeddedFormPresentationModel.formEntityId
  );
  let pruleIndex: number;
  let kagamiFormModel: KagamiFormModel = getFormModel();

  for (pruleIndex = 0; pruleIndex < presentations.length; pruleIndex++) {
    let entityId: string = getMappedEntityIdForPresentation(presentations[pruleIndex]);
    let presentationPolicy: any = getPresentationPolicy(presentations[pruleIndex]);
    let businessPolicy: any = getBusinessPolicy(presentations[pruleIndex]);
    let presentationId: any = getPresenationIdFromPresentation(presentations[pruleIndex]);
    let nestedEmbedPresentationModel: KagamiEmbeddedFormPresentationModel = buildEmbeddedModel(
      presentations[pruleIndex],
      embeddedFormPresentationModel.onStartData,
      embeddedFormPresentationModel.mainEntityId,
      entityId,
      getHeaderForPresentation(presentations[pruleIndex]),
      kagamiFormModel.processName,
      kagamiFormModel.processStepName,
      kagamiFormModel.uiTemplate,
      isMandatoryPresentation(presentations[pruleIndex]),
      isVisiblePresentation(presentations[pruleIndex]),
      kagamiFormModel.readOnly,
      isListPresentation(presentations[pruleIndex]),
      isOnLoadRequiredForPresentation(presentations[pruleIndex]),
      kagamiFormModel.onLoadData,
      presentationPolicy,
      businessPolicy,
      embeddedFormPresentationModel.isDynamicForm,
      presentationId
    );
    embeddedFormPresentationModel.formControls[entityId] = nestedEmbedPresentationModel;
    nestedEmbedPresentationModel.isNested = true;

    // let formComponent: any = buildEmbeddedPresentation(nestedEmbedPresentationModel);
    kagamiFormModel.pushToFormControls(entityId, nestedEmbedPresentationModel);
  }
}

export function pushModalPopUpToFormControls(kagamiFormModel: KagamiFormModel) {
  kagamiFormModel.formComponents.push(buildErrorModal(kagamiFormModel.errorModal));
}

export const ModalPopUP = (props: ModalPopUpProps) => {
  return <>{buildErrorModal(props.kagamiFormModel.errorModal)}</>;
};

export const FormTriggers = (props: FormModelProps) => {
  props.kagamiFormModel.triggersModel.detailedObject =
    props.kagamiFormModel.formData.data[props.kagamiFormModel.formData.mainEntityId];
  return <> {KagamiTriggersController.buildTriggers(props.kagamiFormModel.triggersModel)}</>;
};

export const FormPageContent = (props: FormModelProps) => {
  const classes = makeStyles(KagamiFormPresentationStyle)();
  let formPageComponents: any[] = [];
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  if (showGroupBackButton(props.kagamiFormModel.formControls[props.kagamiFormModel.mainEntityId])) {
    formPageComponents.push(
      <div className={classes.groupbackbtn}>
        <Button
          variant="contained"
          size="small"
          onClick={handleGroupBackButton}
          startIcon={<ArrowBackIcon fontSize="small" />}
        >
        {translateToLocalistaion(translations, 'Back')}
        </Button>
      </div>
    );
  }
  formPageComponents.push(MainFormFunctionSub(props));
  if (CommonUtils.isNotEmpty(props.kagamiFormModel.formComponentObj.embeddedTransaction[0])) {
    formPageComponents.push(EmbeddedFormFunctionsub(props));
  }
  return <>{formPageComponents}</>;
};

export const MainFormFunctionSub = (props: FormModelProps) => {
  let formModel: KagamiFormModel = getFormModel();
  let isEmbeddedNotEmpty = CommonUtils.isNotEmpty(props.kagamiFormModel.formComponentObj.embeddedTransaction[0]);
  let content = props.kagamiFormModel.formComponentObj.mainTransaction[0];
  return buildMainAccordion(content, isEmbeddedNotEmpty, formModel, true, true);
};

export const EmbeddedFormFunctionsub = (props: FormModelProps) => {
  let content = props.kagamiFormModel.formComponentObj.embeddedTransaction[0];
  return content;
};

export const FormFooterContent = (props: FormModelProps) => {
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  let getFooterName = CommonUtils.isNotEmpty(
    props.kagamiFormModel.onStartData?.constructOutputData.uiResource.presentations.presentationRuleMap[
      props.kagamiFormModel.mainEntityId
    ][0].uiSettings?.footerName
  )
    ? props.kagamiFormModel.onStartData?.constructOutputData.uiResource.presentations.presentationRuleMap[
        props.kagamiFormModel.mainEntityId
      ][0].uiSettings?.footerName
    : 'Footer';
  return (
    <>
      {CommonUtils.isNotEmpty(props.kagamiFormModel.footerElements) ? (
        <div className="row">
          <Typography variant="subtitle2" color="secondary" style={{ fontSize: '1rem' }}>
            {/* {getFooterName} */}
            {translateToLocalistaion(translations, getFooterName)}
          </Typography>
          {props.kagamiFormModel.footerElements.map((item) => item)}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const FormButtons = (props: FormModelProps) => {
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  return props.kagamiFormModel.readOnly ? (
    <Fragment></Fragment>
  ) : (
    <>
      <FormButton
        buttonLabel={translateToLocalistaion(translations, props.kagamiFormModel.resetLabel)}
        onButtonClick={handleFormReset}
        kagamiFormModel={props.kagamiFormModel}
        isSubmitButton={false}
      />
      <FormButton
        buttonLabel={translateToLocalistaion(translations, props.kagamiFormModel.saveLabel)}
        onButtonClick={handleFormSubmit}
        kagamiFormModel={props.kagamiFormModel}
        isSubmitButton={true}
      />
    </>
  );
};

const primaryTriggers = ['Save', 'Submit'];

export const FormButton = (props: FormButtonProps) => {
  const classes = makeStyles(KagamiFormStyle)();
  const [disable , setDisable] = useState(false);
  return (
    <Button
      variant={primaryTriggers.includes(props.buttonLabel) ? 'contained' : 'outlined'}
      color="secondary"
      size="small"
      className={
        primaryTriggers.includes(props.buttonLabel) ? classes.formPrimaryButtons : classes.formSecondaryButtons
      }
      disabled={disable}
      // style={ {margin: '5px',padding : '5px' , width: '130px' ,backgroundColor: primaryTriggers.includes(props.buttonLabel) ? '#1f8a70': '#fff', color:primaryTriggers.includes(props.buttonLabel) ? '#fff': '#04263d' }}
      // style={ {marginRight: '10px',backgroundColor: primaryTriggers.includes(props.buttonLabel) ? '#1f8a70': '#fff', color:primaryTriggers.includes(props.buttonLabel) ? '#fff': '#04263d' }}
      // startIcon= {props.buttonLabel === "Save"? <SaveOutlinedIcon />: <RestartAltOutlinedIcon />}
      onClick={() => {
        if(props.isSubmitButton){
          setDisable(true)
          delay(() => {
            setDisable(false);
          },400)
        }
        if (props.kagamiFormModel.onKanbannClose && props.kagamiFormModel.onKanbannClose !== null) {
          props.kagamiFormModel.onKanbannClose();
        }
        if (props.isSubmitButton && props.kagamiFormModel.isModelDialog) {
          /// NOTE : Need to handle dialog model
          let modal = getHomeModel().errorModal;
          handleErrorModalOnClose(modal);
        }
        if (props.kagamiFormModel.pickItemDetail && props.isSubmitButton) {
          props.onButtonClick(props.kagamiFormModel.mainEntityId, props.kagamiFormModel.pickItemDetail);
        } else {
          props.onButtonClick(props.kagamiFormModel.mainEntityId);
        }
      }}
    >
      {props.buttonLabel}
    </Button>
  );
};
function showClearButton(formPresentationModel: KagamiEmbeddedFormPresentationModel) {
  let formModel = getFormModel();
  let pRules = formPresentationModel.presentation.presentationRules;
  // let isDetailsView = formPresentationModel.readonly
  let isDetailsView = formModel.readOnly;
  //edit action presence in Shlipcare for process there is no edit action but need to show clear button
  const editActionCase =
    CommonUtils.isNotEmpty(formPresentationModel.presentation.actions) &&
    formPresentationModel.presentation?.actions.find((action: any) => action?.actionName === 'edit');
  //all are readonly false case in prules
  const readOnlyCase: any = Object.values(pRules).filter(
    (presentation: any) => presentation.label !== 'Id' && presentation.readOnly === true
  );
  let readOnlyBool: boolean;

  //atleat one visible case
  const visiblePresentaionCase: any = Object.values(pRules).filter(
    (presentation: any) => presentation.visible === true
  );
  if (CommonUtils.isNotEmpty(readOnlyCase) && readOnlyCase.length > 0) {
    readOnlyBool = true;
  } else {
    readOnlyBool = false;
  }
  if (isDetailsView) {
    return false;
  } else {
    return readOnlyBool && CommonUtils.isNotEmpty(visiblePresentaionCase) && !formPresentationModel.isActiveForm;
  }
}

export const FormContent = (props: KagamiFormPresentationProps) => {
  return (
    <>
      {mainFormFunction(props)}
      {embeddedFunction(props)}
    </>
  );
};

export const mainFormFunction = (props: KagamiFormPresentationProps) => {
  let formComponentObj: any = [[props.kagamiFormPresentationModel.formComponentObj.mainTransaction]];
  let properties = extractFormProperties(props.kagamiFormPresentationModel.presentation.presentationRules);
  let formModel: KagamiFormModel = getFormModel();
  let isMain: boolean = props.kagamiFormPresentationModel.formEntityId === formModel.mainEntityId;
  let isEmbeddedNotEmpty = CommonUtils.isNotEmpty(
    props.kagamiFormPresentationModel.formComponentObj.embeddedTransaction
  );
  const classes = makeStyles(KagamiFormPresentationStyle)();
  console.log(props.kagamiFormPresentationModel);
  let isDetailedView = props.kagamiFormPresentationModel.readOnly ? props.classes.detailedView : '';
  const verticalView = props.kagamiFormPresentationModel.presentation?.uiSettings
    ? props.kagamiFormPresentationModel.getIsVerticalView(props.kagamiFormPresentationModel.presentation.uiSettings)
    : '';
  let content = (
    <div
      key={'FormContent' + Math.random()}
      className={
        props.kagamiFormPresentationModel.uiTemplate == 'treeForm'
          ? `row border_one ${isDetailedView} ${props.classes.root}`
          : props.classes.root
      }
    >
      {formComponentObj.map((element: any, index: any) => {
        let allElements = [...element];
        let propertyIndex = 0;
        allElements.map((item, index) => {
          if (Array.isArray(item) && item.length === 0) {
            let value = properties[propertyIndex];
            if (value?.type === 'HEADER') {
              allElements[index] = renderFormHeader(value.text);
            } else if (value?.type === 'LINE_BREAK') {
              allElements[index] = renderFormDivider();
            }
            propertyIndex = propertyIndex + 1;
          }
        });
        return (
          <div key={'FormContent' + index}>
            <div className={verticalView}>
              <div className={`row ${isDetailedView}`} key={'FormContent_row_' + index}>
                {allElements}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return buildMainAccordion(content, isEmbeddedNotEmpty, formModel, isMain, true);
};

export const embeddedFunction = (props: KagamiFormPresentationProps) => {
  let formComponentObj: any = [props.kagamiFormPresentationModel.formComponentObj.embeddedTransaction];
  let properties = extractFormProperties(props.kagamiFormPresentationModel.presentation.presentationRules);
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  console.log(props.kagamiFormPresentationModel);
  let isDetailedView = props.kagamiFormPresentationModel.readOnly ? props.classes.detailedView : '';
  const showClearBtn: boolean =
    props.kagamiFormPresentationModel instanceof KagamiEmbeddedFormPresentationModel
      ? showClearButton(props.kagamiFormPresentationModel)
      : false;
  const verticalView = props.kagamiFormPresentationModel.presentation?.uiSettings
    ? props.kagamiFormPresentationModel.getIsVerticalView(props.kagamiFormPresentationModel.presentation.uiSettings)
    : '';
  return (
    <div
      key={'FormContent' + Math.random()}
      className={
        props.kagamiFormPresentationModel.uiTemplate == 'treeForm'
          ? `row border_one ${isDetailedView} ${props.classes.root}`
          : props.classes.root
      }
    >
      {formComponentObj.map((element: any, index: any) => {
        let allElements = [...element];
        let propertyIndex = 0;
        allElements.map((item, index) => {
          if (Array.isArray(item) && item.length === 0) {
            let value = properties[propertyIndex];
            if (value?.type === 'HEADER') {
              allElements[index] = renderFormHeader(value.text);
            } else if (value?.type === 'LINE_BREAK') {
              allElements[index] = renderFormDivider();
            }
            propertyIndex = propertyIndex + 1;
          }
        });
        return (
          <div key={'FormContent' + index}>
            <div className={verticalView}>
              <div className={`row ${isDetailedView}`} key={'FormContent_row_' + index}>
                {allElements}
              </div>
            </div>
            {showClearBtn ? (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={(event: any) => {
                  event.preventDefault();
                  console.log('clicked on clear');
                  handleEmbededReset(props.kagamiFormPresentationModel);
                }}
              >
              {translateToLocalistaion(translations, 'Clear')}
              </Button>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const extractFormProperties = (presentations: any) => {
  let properties: any = [];
  Object.keys(presentations).forEach((key, index) => {
    if (key.includes('NonFieldPresentation')) {
      if (presentations[key].type === 'HEADER') {
        // properties.push({ type: "HEADER", text: presentations[key].metaData.content })
      } else if (presentations[key].type === 'LINE_BREAK') {
        properties.push({ type: 'LINE_BREAK', text: presentations[key].metaData.content });
      }
    }
  });
  return properties;
};

export const renderFormHeader = (text: string) => {
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  return (
    <Typography key={'FromHeader' + Math.random()} style={{ fontSize: '16px' }} variant="h5" color="secondary">
      {translateToLocalistaion(translations, text)}
    </Typography>
  );
};

export const renderFormDivider = () => {
  return <Divider key={'Divider' + Math.random()} />;
};

export const SectionPresentationContent = (props: FormSectionPresentationProps) => {
  const classes = makeStyles(KagamiFormStyle)();
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  let formComponentObj: any = [[props.kagamiFormSectionPresentationModel.formComponentObj.mainTransaction]];
  let uiSettingsClassName: any = props.kagamiFormSectionPresentationModel.getControlWidth(
    props.kagamiFormSectionPresentationModel.uiSettings
  );
  const verticalView = props.kagamiFormSectionPresentationModel.getIsVerticalView(
    props.kagamiFormSectionPresentationModel.uiSettings
  );

  return (
    <>
      {props.kagamiFormSectionPresentationModel.uiSettings?.fieldGap?.label === 'true' ? (
        <div style={{ flexBasis: '100%', height: '0' }}></div>
      ) : (
        <></>
      )}
      <div className={`${uiSettingsClassName} ${verticalView} border_two `}>
        <div
          className={
            props.kagamiFormSectionPresentationModel.readOnly
              ? `${classes.readOnlySection} section_presentation`
              : 'section_presentation'
          }
          style={{ backgroundColor: props.kagamiFormSectionPresentationModel.readOnly ? '#f5f8fa' : 'inherit' }}
        >
          {props.kagamiFormSectionPresentationModel.formHeader ? (
            // <p className={props.classes.formHeader}>{props.kagamiFormSectionPresentationModel.formHeader}</p>
            <p className={props.classes.formHeader}>{translateToLocalistaion(translations, props.kagamiFormSectionPresentationModel.formHeader)}</p>
          ) : (
            <></>
          )}
          <div className="row">
            {formComponentObj.map((element: any, index: any) => {
              return element;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

//breakline
export const buildBreakLine = () => {
  return <div style={{ flexBasis: '100%', minHeight: '0', margin: '0' }}></div>;
};

export const buildMainAccordion = (
  content: any,
  isEmbeddedNotEmpty: boolean,
  formModel: KagamiFormModel,
  isMain: any,
  accordion: true
) => {
  let title = (
    <>
      <FormLabel style={{ fontWeight: 500, color: '#006e7f', fontSize: '1rem', paddingLeft: '10px' }}>
        {CommonUtils.isNotEmpty(formModel.mainAccordionFormName) ? formModel.mainAccordionFormName : getHeaderForprocess(formModel?.onStartData?.constructOutputData, formModel?.onLoadData)}
      </FormLabel>
    </>
  );
  if (isEmbeddedNotEmpty && isMain) {
    const accordionModel: KagamiAccordionModel = new KagamiAccordionModel(title, content, true);
    accordionModel.transactionType = 'mainForm';
    return buildAccordion(accordionModel);
  } else {
    return content;
  }
};

export function lookForTreeFormModelsInStepForm(formPresentationModel : KagamiFormPresentationModel,stepFormModels : any[],embeddedView : string){
  let presentation : any = formPresentationModel.presentation
  const attributePresentations: any[] = getAttributePresentations(presentation);
  for (const attributeName in attributePresentations) {
    let attributePresentation = attributePresentations[attributeName];
    const uiSettings = getUiSettings(attributePresentation);
    if (getAttributePresentationType(attributePresentation) === 'FormPresentation'){
      let entityPresentation: any = getFormPresentationFormAttribute(attributePresentation);
      if (entityPresentation.presentationRules) {
        let entityId: string = getMappedEntityIdForPresentation(entityPresentation);
        let formHeader: string = getHeaderForPresentation(entityPresentation);
        let isMandatory: boolean = isMandatoryPresentation(entityPresentation);
        let isVisible: boolean = isVisiblePresentation(entityPresentation);
        let isList: boolean = isListPresentation(entityPresentation);
        let isOnloadRequired: boolean = isOnLoadRequiredForPresentation(entityPresentation);
        let presentationPolicy: any = getPresentationPolicy(entityPresentation);
        let businessPolicy: any = getBusinessPolicy(entityPresentation);
        let presentationId: any = getPresenationIdFromPresentation(entityPresentation);
        let embeddedFormPresentationModel = buildEmbeddedModel(
          entityPresentation,
          formPresentationModel.onStartData,
          formPresentationModel.mainEntityId,
          entityId,
          formHeader,
          formPresentationModel.processName,
          formPresentationModel.processStepName,
          formPresentationModel.uiTemplate,
          isMandatory,
          isVisible,
          formPresentationModel.readonly,
          isList,
          isOnloadRequired,
          formPresentationModel.onLoadData !== undefined
            ? formPresentationModel.onLoadData[entityId]
            : formPresentationModel.onLoadData,
          presentationPolicy,
          businessPolicy,
          formPresentationModel.isDynamicForm,
          presentationId
        );
        embeddedFormPresentationModel.onLoadData = getFormRecords(embeddedFormPresentationModel);
        embeddedFormPresentationModel.embeddedView = embeddedView;
        if (formPresentationModel instanceof KagamiEmbeddedFormPresentationModel) {
          embeddedFormPresentationModel.isNested = true;
          embeddedFormPresentationModel.parentEmbedEntityId = formPresentationModel.formEntityId;
        }

        // if (embeddedView === 'tab') {
        //   embedFormModels.push(embeddedFormPresentationModel);
        // } else {
        //   let embeddedPresentationModel: KagamiEmbeddedPresentationModel = new KagamiEmbeddedPresentationModel(
        //     [embeddedFormPresentationModel],
        //     embeddedView
        //   );
        //   formComponentObj.embeddedTransaction.push(buildEmbeddedPresentation(embeddedPresentationModel));
        // }
        getFormModel().pushToFormControls(embeddedFormPresentationModel.formEntityId, embeddedFormPresentationModel);
        stepFormModels.push(embeddedFormPresentationModel)
      }
    }
  }
}
