import { Theme } from "@mui/material";
import { createStyles } from "@mui/styles";

export const customPrintStyle = (theme:Theme) => createStyles({
    main: {
        width: '100%',
        display: 'flex',
    },
    editForm: {
        width: '40%',
        margin: '10px',
        

    },
    iframeComponent: {
        width: '60%',
        height: '60vh'
    },
 noteTitle : {
    fontWeight : 'bold',
    // color : 'red'
 },
 formWid : {
    // width  : '20px',
    display : 'flex',
    padding : '5px',
    margin : '2px',

     
 },

 formTestfieldWid : {
    // width  : '20px',
    display : 'flex',
    margin : '5px',
    '& .MuiFormLabel' : {
        fontweight : 'bold',
      },
 },

 MuiFormLabelroot :{
    
 },


})