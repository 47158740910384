import { Landscape } from '@mui/icons-material';
import _, { map } from 'lodash';
import moment from "moment"

import ExcelDownload from '../../../../service/exports/ExcelService';
import PDFDownload  from '../../../../service/exports/PDFService';
import { getFilterSelectedHeader } from '../../../../service/KagamiReportService';
import { getFilterCanvasContent, getReportName, getReportPageSize, getReportType, handleExcelFilterConfig, handleFilterCanvas } from '../../../../service/ReportUtilService';

/* EXCEL DOWNLOAD */
export const downloadExcel = (reportConfig:any,reportModel:any) => {
  let rowData = reportConfig['filteredRowData']?.length > 0 ?  reportConfig['filteredRowData'] : reportConfig['rowData'];
  
  let configInfo:any = {
    alternateRowColor:true,
    defaultColumnWidth:20,
    fileName: getReportName(reportModel) || 'Report',
    staticHeader:true,
    expandAllRows: true,
    hierarchicalDataFill: true, // false - it will display groupable cell value at first cell
    rowData:  _.cloneDeep(rowData), //reportConfig['rowData'] || [],
    excelTableRows:  _.cloneDeep(rowData), //reportConfig['rowData'] || [],
    reportColumns : reportConfig.columnDefs, 
    excelHeaderInfo :getReportHeaderInfo(reportConfig),
    columnDefs : reportConfig.columnDefs,
    exportableColumnDefinitions : reportConfig.columnDefs, //grid exportable column definitions
    staticHeaderValue:[],//list of strings ,
    excelHeaderRows : _.cloneDeep(reportModel?.reportConfig?.reportSettings?.excelHeaderRows),//custom headers with styles,
  }
  let totalRecordsArr:any = []
  if(isExistsGroupableColumns(reportConfig) ){
    configInfo['excelTableRows'] =  makeExcelData(configInfo.rowData,configInfo) || [];
  }else if(configInfo?.rowData?.length ) {
    totalRecordsArr = handleTotalRecords(configInfo);
  }
  configInfo['excelTableRows'] = configInfo['excelTableRows'].concat(reportConfig.pinnedBottomRowData[0]);
  if(totalRecordsArr?.length) {
    configInfo['excelTableRows'] = configInfo['excelTableRows'].concat(totalRecordsArr);
  }
  configInfo['staticHeaderValue'] = [];
  if(configInfo?.excelHeaderRows?.length) {
    handleExcelFilterConfig(reportModel?.reportConfig,reportModel,configInfo?.excelHeaderRows);
    _.remove(configInfo.excelHeaderRows,function(item:any) { return item?.filterValue == 'All' && item?.filterHideNoneSelection == true })
  }
  if ((!configInfo?.excelHeaderRows) || configInfo?.excelHeaderRows?.length == 0) {
    configInfo['staticHeaderValue'].push({
      value: configInfo.fileName
    })
    let filterVal = getFilterSelectedHeader(reportModel);
    if (filterVal) {
      configInfo['staticHeaderValue'].push({ value: filterVal })
    }
  }
  configInfo['staticHeaderValue'].push({value: "As On Date : " +moment(new Date()).format('DD-MMM-YYYY hh:mm:ss')})
  const excelLib =  ExcelDownload(configInfo);
  excelLib.downLoad();
}

  /*  HANDLE EXCEL DATA */

const makeExcelData = (data: any, config: any) => {
  let columns = _.cloneDeep(config.columnDefs); //|| ["C0", "C1", "C2", "C3", "C4"];
  let groupableColumnsArr = _.filter(config.columnDefs,{'rowGroup':true});
  let groupableColumns = _.map(groupableColumnsArr,'headerName');
  //shift the columns based on groupable columns
  let columnKeys = _.cloneDeep(_.map(columns, 'headerName'));
  let excelDownloadColumns = _.cloneDeep(columnKeys);
  if (groupableColumns && groupableColumns.length > 0) {
    excelDownloadColumns = [];
    excelDownloadColumns = groupableColumns;
    excelDownloadColumns = excelDownloadColumns.concat(_.xor(columnKeys, groupableColumns))
  }
  let dummyRecord: any = {};
  excelDownloadColumns.forEach(function (element:any) {
    dummyRecord[element] = "";
  });
  let resultsRecordsgroupBy = groupByMulti(data, groupableColumns);
  return prepareExcelRows(resultsRecordsgroupBy, groupableColumns, dummyRecord, config) || [];
}

 const groupByMulti =  (collection:any,keys:any) :any =>  {
    if (!keys.length) {
        return collection;
    }
    else {
        return _(collection).groupBy(keys[0]).mapValues(function (values) {
            return groupByMulti(values, keys.slice(1));
        }).value();
    }
};

const prepareExcelRows = (dataRecords: any, groupableColumns: any, dummyRecord: any, config: any) => {
  let dataKeys: any = _.keys(dataRecords);
  let exportableData = []
  if (dataKeys && dataKeys.length > 0) {
    for (let indx = 0; indx < dataKeys.length; indx++) {
      let firstRecordKey = dataKeys[indx];
      let excelRow = _.cloneDeep(dummyRecord);
      excelRow[groupableColumns[0]] = firstRecordKey; //first grouping column
      let nestedRowKeys = _.keys(dataRecords[firstRecordKey]);
      let nestedRowData = dataRecords[firstRecordKey];
      excelRow['nesteddata'] = [];
      let groupableColIndex = 1; //
      if (!Array.isArray(nestedRowData) && nestedRowKeys.length > 0) {
        let nestedRecords = makeExcelNestedRows(nestedRowKeys, nestedRowData, groupableColumns, dummyRecord, config, groupableColIndex)
        excelRow['nesteddata'] = nestedRecords;
        exportableData.push(excelRow);
      } else {
        excelRow['nesteddata'] = nestedRowData;
        exportableData.push(excelRow);
      }
    }
  } else {
    exportableData = dataRecords;
  }
  return exportableData;
}

const makeExcelNestedRows = (nestedRowKeys: any, nestedRowData: any, groupableColumns: any, dummyRecord: any, config: any, groupableColIndex: any) => {
  console.log("makeExcelNestedRows.. ")
  let subExportableData: any = []
  _.forEach(nestedRowKeys, function (key, index) {
    let firstNestedRecordKey = nestedRowKeys[index];
    let excelNestedRow = _.cloneDeep(dummyRecord);
    if (config.hierarchicalDataFill) {
      excelNestedRow[groupableColumns[groupableColIndex]] = firstNestedRecordKey; // hierarchical way presentation
    } else {
      excelNestedRow[groupableColumns[0]] = firstNestedRecordKey; //todo
    }
    let subNestedRowKeys = _.keys(nestedRowData[key]);
    let subNestedRowData = nestedRowData[key];
    if (!Array.isArray(subNestedRowData) && subNestedRowKeys.length > 0) {
      let _subRecords = makeExcelNestedRows(subNestedRowKeys, subNestedRowData, groupableColumns, dummyRecord, config, groupableColIndex + 1)
      excelNestedRow['nesteddata'] = _subRecords;
      subExportableData.push(excelNestedRow);

    } else {
      excelNestedRow['nesteddata'] = subNestedRowData;
      subExportableData.push(excelNestedRow);
    }
  })
  return subExportableData;
}
/* END HANDLE EXCEL DATA */
/* END EXCEL DOWNLOAD */


/* PDF DOWNLOAD */
export const downloadPDF = (type:any,reportConfig:any,reportModel:any) => {
  let rowData = reportConfig['filteredRowData']?.length > 0 ?  reportConfig['filteredRowData'] : reportConfig['rowData'];

  let columnsInfo = getPdfTableColumns(reportConfig,reportModel);
  let configInfo:any  = {
    pageOrientation:type,
    pageSize:getReportPageSize(reportModel.reportConfig),
    fileName: getReportName(reportModel) || 'Report',
    rowData: _.cloneDeep(rowData),//reportConfig['rowData'] || [],
    pdfTableRows : _.cloneDeep(rowData),// reportConfig['rowData'] || [],
    pdfHeaderInfo :getPdfHeaderInfo(reportConfig,reportModel),
    // pdfTableColumns : reportConfig.columnDefs, 
    // exportableColumnDefinitions : reportConfig.columnDefs,  //grid exportable column definitions
    // pdfTableColumnWidths : _.times(reportConfig.columnDefs.length, _.constant('auto')) || []
    pdfTableColumns : columnsInfo.exportableColumns,
    exportableColumnDefinitions : columnsInfo.columnsList, //grid exportable column definitions
    pdfTableColumnWidths : columnsInfo.widths,
    reportConfig :reportConfig,
    reportHeaderObject:reportModel.reportHeaderObject ||'',
    logoSettings : reportModel?.reportConfig?.reportSettings?.logoSettings,
    printSettings: reportModel?.reportConfig?.reportSettings?.printSettings,

  }
  configInfo['pdfTableRows'] =  makePdfData(configInfo,reportConfig);
  if (reportModel.reportConfig && configInfo) {
    configInfo.reportHeaderObject = handleFilterCanvas(reportModel.reportConfig, reportModel);
  }
  if (reportModel?.reportConfig && getFilterCanvasContent(reportModel.reportConfig)) {
    delete configInfo.pdfHeaderInfo['Title'];
    delete configInfo.pdfHeaderInfo['subTitle']
  }
  const pdfLib =  PDFDownload(configInfo);
  pdfLib.downLoad();
}

const getPdfTableColumns = (reportConfig: any,reportModel :any) => {
  let exportableColumns: any = [];
  let columnsList:any = [];
  if (reportConfig.columnDefs && reportConfig.columnDefs.length > 0) {
    _.forEach(reportConfig.columnDefs, (col: any) => {
      let headerCol: any = {
        text: col.headerName,
        style: 'tableHeader'
      };
        exportableColumns.push(headerCol);
        columnsList.push(col);
    });
  }
  exportableColumns.unshift({ text: 'SNo', style: 'tableHeader' });
  columnsList.unshift({ field: 'sno', headerName: 'SNo' });
  let userDefinedCoumnWidths:any =[]
  if(reportModel?.reportConfiguration?.columnConfig && reportModel?.reportConfiguration?.columnConfig?.length == columnsList.length-1 ) { // -1 for sno
    let widths  = _.map(reportModel.reportConfiguration.columnConfig,'columnWidth');
    widths.unshift(100);
    _.forEach(widths,function(width) {
      userDefinedCoumnWidths.push((width/_.sum(widths) * 100) +"%");
    })
  } else if(!reportModel?.reportConfiguration?.columnConfig) {
    console.log("Columns widths for pdf is not saved in db")
  } else if(! (reportModel?.reportConfiguration?.columnConfig && reportModel?.reportConfiguration?.columnConfig?.length == columnsList.length-1)) {
    console.log(" print settings column widhts are not matched with report column widths ,Either New columns added or deleted ...Resave print settings for column witdths ")
  }
  return {
    exportableColumns: exportableColumns || [],
    columnsList: columnsList || [],
    widths: userDefinedCoumnWidths?.length ? userDefinedCoumnWidths :(_.times(columnsList.length, _.constant(100/columnsList.length+"%")) || [])
  };
};

const getPdfHeaderInfo = (reportConfig:any,reportModel:any) => {
  let headerConfig:any = {};
  if(reportConfig) {
    headerConfig['Title'] = getReportName(reportModel.reportConfig) ||''; // report name
    headerConfig['subTitle'] = getFilterSelectedHeader(reportModel) || '';
    headerConfig['exportedDate'] = moment(new Date()).format('DD-MMM-YYYY hh:mm:ss'); 
  }
  return headerConfig;
}
const makePdfData =(configInfo:any,reportConfig:any) => {
  let rowsData:any = [];
  let pdfRows = configInfo.pdfTableRows;
  let isFooterExists = false;
  if(reportConfig.pinnedBottomRowData?.length){
    isFooterExists = true;
    pdfRows = configInfo.pdfTableRows.concat(reportConfig.pinnedBottomRowData[0]);
  }
  if(configInfo.exportableColumnDefinitions && configInfo.exportableColumnDefinitions.length > 0 && reportConfig.rowData && reportConfig.rowData.length > 0) {
    _.forEach(pdfRows,(rec,index)=>{
      let _row:any = []
      _.forEach(configInfo.exportableColumnDefinitions,(col)=>{
        let value = rec[col.field];
        if(value == undefined || value == null) {
          value = '';
        }
        if (col.field === 'sno') {
          _row.push({ text: index + 1 });
        } else {
          // _row.push({ text: rec[col.field] || '0' });
          _row.push({text:value});
        }
      });
      rowsData.push(_row);
    })
  }
  if(isFooterExists && rowsData?.[rowsData.length-1]?.[0]){
    rowsData[rowsData.length-1][0].text='';
  }
  if(rowsData)
  return rowsData;
}

const getReportHeaderInfo = (reportConfig:any) => {
  let headerConfig:any = {};
  if(reportConfig) {
    headerConfig['reportType'] =  getReportType(reportConfig) || '';
  }
  return headerConfig;
}
const isExistsGroupableColumns =  (reportConfig:any) => {
  return ( _.filter(reportConfig.columnDefs,{rowGroup:true}).length >0 ) ? true : false;
}

const handleTotalRecords = (configInfo: any) => {
  let totalRecArr = [];
  let recKeys = _.keys(configInfo.rowData[0])
  let emptyRow: any = {};
  let totalRecordsRow: any = {}
  _.forEach(recKeys, function (key, index) {
    emptyRow[key] = '';
    totalRecordsRow[key] = '';
    if (index == recKeys.length - 2) {
      totalRecordsRow[key] = 'Total Records : '
    } else if (index == recKeys.length - 1) {
      totalRecordsRow[key] = configInfo.rowData.length;
    }
  })
  totalRecArr.push(emptyRow);
  totalRecArr.push(totalRecordsRow);
  return totalRecArr;
}
/* END PDF DOWNLOAD */