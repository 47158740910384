import { KagamiHeaderFavoritesModel } from './kagamiHeaderFavorites';
import { KagamiHeaderNotificationModel } from './KagamiHeaderNotificationModel';
import { KagamiHeaderUserProfileModel } from './kagamiHeaderUserProfile.';

export class KagamiPageHeaderModel {
  pageHeader: boolean = true;
  notificationModel: KagamiHeaderNotificationModel;
  favoritesModel: KagamiHeaderFavoritesModel;
  profileModel: KagamiHeaderUserProfileModel;
  state: any;
  constructor() {
    this.notificationModel = new KagamiHeaderNotificationModel('Notification');
    this.favoritesModel = new KagamiHeaderFavoritesModel('Favorite');
    this.profileModel = new KagamiHeaderUserProfileModel([]);
  }
}

export interface KagamiPageHeaderProps {
  kagamiPageHeaderModel: KagamiPageHeaderModel;
}
