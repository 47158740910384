import { createState } from "@hookstate/core";
import makeStyles from "@mui/styles/makeStyles";
import Popover from "@mui/material/Popover";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
  KagamiPopoverModel,
  PopoverModelProps,
  useKagamiPopoverState,
} from "../model/KagamiPopoverModel";
import { KagamiPopoverStyle } from "../style/KagamiPopoverStyle";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grow from "@mui/material/Grow";
export const buildPopoverComponent = (popoverModel: KagamiPopoverModel) => {
  return <KagamiPopover popoverModel={popoverModel} />;
};
const useStyles = makeStyles(KagamiPopoverStyle);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const KagamiPopover = (props: PopoverModelProps) => {
  let classes = useStyles();
  let popoverState = useKagamiPopoverState(createState(props.popoverModel));
  props.popoverModel.state = popoverState;
  let open = Boolean(popoverState.popoverTarget);
  return (
    <Popover
      classes={{
        paper: classes.popoverPaper,
      }}
      PaperProps={{}}
      open={open}
      anchorEl={props.popoverModel.target}
      onClose={() => popoverState.setPopoverTarget(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      TransitionComponent={
        props.popoverModel.popoverPosition.transitionNeeded ? Transition : Grow
      }
    >
      <div className={classes.popoverBody}>{props.popoverModel.child}</div>
    </Popover>
  );
};
