import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const kagamiReportFilterStyle = (theme: Theme) => createStyles({
    filter_table: {
        fontFamily: 'arial, sans-serif',
        borderCollapse: 'collapse',
        color: 'black',
        width: '100%',
        '& td, th': {
            border: '1px solid #dddddd',
            textAlign: 'left',
            padding: '8px',
        },
        // '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root':{
        //     top: '-7px',
        //     color: 'rgb(121 121 121 / 60%)'
        // },
        '& .inner-table':{
            border:'none'
        }

      },
      filter_select: {
        width: '319px',

    },

    filter_date:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },

    filter_date_time_td:{
        border:'none'
    }

})