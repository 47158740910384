import * as React from 'react';
import { createState, useState } from '@hookstate/core';
import {
  StockLedgerReportModel,
  StockLedgerReportModelProps,
  useStockLedgerReportModelState
} from '../model/StockLedgerReportModel';
import { makeReport, makeLedgerData, getData, makeFooterRowData } from '../service/StockLedgerReportService';
import { downloadExcel, downloadPDF } from '../service/StockLedgerExportService';
import Grid from '@mui/material/Grid';
import { Autocomplete, TextField, Button, ButtonGroup } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { buildLedgerDetailsModal } from '../../modal/controller/ledgerDetailsModalController';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
export const BuildStockLedgerReportView = (stockLedgerReportModel: StockLedgerReportModel) => {
  return (
    <StockLedgerReportView key="{'StockLedgerReportView'}" stockLedgerReportModel={stockLedgerReportModel} />
  );
};
const StockLedgerReportView = (props: StockLedgerReportModelProps) => {
  //Open
  const [startDateCalendar, setStartDateCalendar] = React.useState(false);
  const [endDateCalendar, setEndDateCalendar] = React.useState(false);
  // Date
  let startDateYear = new Date();
  startDateYear.setDate(1);
  startDateYear.setMonth(0);
  let yearStartDate = new Date(startDateYear).toISOString().substring(0, 10);
  let todayDate = new Date().toISOString().substring(0, 10);
  const [startDate, setStartDate] = React.useState(yearStartDate);
  const [endDate, setEndDate] = React.useState(todayDate);
 
  const onDownloadExcel = (index: any) => {
    downloadExcel(index, props.stockLedgerReportModel);
  };
  const onDownloadPdf = (type: any, index: any) => {
    downloadPDF(type, index, props.stockLedgerReportModel);
  };
  let runReportConfig: any = useState({
    loadReport: false,
    loadFilterData: true,
    showLoader: false,
    reportInfo: {}
  });
  let stockLedgerReportModel = props.stockLedgerReportModel;
  let stockLedgerState = useStockLedgerReportModelState(createState(stockLedgerReportModel));
  stockLedgerReportModel.state = stockLedgerState;
  let staticFilterData: any;
  if (stockLedgerReportModel?.reportData?.staticFilterData) {
    staticFilterData = stockLedgerReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo: any = {}
  if (stockLedgerReportModel?.reportData?.gridReportInfo) {
    gridReportInfo = stockLedgerReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig = (props.stockLedgerReportModel.reportConfig);
  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo = makeReport(stockLedgerReportModel, inputReportConfig);
    let ledgerData = await getData(stockLedgerReportModel, inputReportConfig);
    let rowData = await makeLedgerData(stockLedgerReportModel, ledgerData);
    let footerRowData = await makeFooterRowData(ledgerData);
    reportInfo['pinnedBottomRowData'] = footerRowData;
    reportInfo['rowData'] = rowData;
    reportInfo['dbLedgerData'] = ledgerData;
    const obj = Object.assign({}, reportInfo);
    props.stockLedgerReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }
  const handleStaticFilters = (value: any, type: any) => {
    if (type == 'startDate' || type == 'endDate') {
      if (!inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {};
      }
      inputReportConfig['leftPanel'][type] = value;
    } else if (type == 'state' || type == 'projectPlan') {
      inputReportConfig[type] = value && value.length > 0 ? value.join(",") : [];
    } else {
      inputReportConfig[type] = value;
    }
  }
  return (
    <div className="ag-theme-alpine static-report-container static-report-wbp static-report-grid" >

      {buildLedgerDetailsModal(props.stockLedgerReportModel.modelPopup)}

      {/* fields  */}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {/* Sub Organization */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="ledgerSubOrganization"
            options={staticFilterData && staticFilterData.suborganization || []}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Sub Organization"
                placeholder="Select Sub Organization"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event: any, newValue: any | null) => {
              handleStaticFilters([newValue], 'subOrganization');
            }}

          />
        </Grid>

        {/*  Ledger */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="stockLedger"
            options={staticFilterData && staticFilterData.accountResult || []}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                label="Item Data"
                placeholder="Select  Item"
              />
            )}
            onChange={(event: any, newValue: any | null) => {
              handleStaticFilters(newValue, 'selectedValue');
            }}
          />
        </Grid>
        {/* From Date */}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month', 'day']}
              open={startDateCalendar}
              onOpen={() => setStartDateCalendar(true)}
              onClose={() => setStartDateCalendar(false)}
              label="From Date"
              value={startDate}
              onChange={(newValue: any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setStartDate(newDate);
                  let val = newDate + " 00:00:00";
                  handleStaticFilters(val, 'startDate');
                }
              }}
              renderInput={(params: any) => {
                return (
                  <TextField
                    fullWidth
                    required
                    className='full-width'
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setStartDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        {/* To Date */}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month', 'day']}
              open={endDateCalendar}
              onOpen={() => setEndDateCalendar(true)}
              onClose={() => setEndDateCalendar(false)}
              label="To Date"
              value={endDate}
              onChange={(newValue: any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setEndDate(newDate);
                  let val = newDate + " 23:59:59";
                  handleStaticFilters(val, 'endDate');
                }
              }}
              renderInput={(params: any) => {
                return (
                  <TextField
                    required
                    fullWidth
                    className='full-width'
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setEndDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>

      </Grid>
      {/* fields end */}
      {/* Run */}
      <Grid container style={{ margin: '10px', textAlign: 'right' }} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        <Grid item xs={12}>
          <ButtonGroup className="static-report-btngroup" variant="text" aria-label="text button group">
            {/* excel Donwloader  */}
            <div>
            <Button variant="contained" onClick={() => { runReport() }}>
              Run
            </Button>
              <Button variant="contained" onClick={() => { onDownloadExcel( 0) }}>
                  Excel
              </Button>
            </div>
            {/* print  */}
            <div>
              <Button variant="contained" onClick={() => { onDownloadPdf('potrait', 0) }}>
                Print Potrait
              </Button>
              <Button variant="contained" onClick={() => { onDownloadPdf('landscape', 0) }}>
                Print Land Scape
              </Button>
            </div>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '10px', textAlign: 'center' }}  >
        <Grid item xs={12}>
          {runReportConfig.showLoader.get() &&
            <CircularProgress color="secondary" />
          }
        </Grid>
      </Grid>
      {runReportConfig.loadReport.get() &&
        <div className="ag-theme-alpine kgm-report-aggrid kgm-report-hwm dashboard-report" >
          <AgGridReact
            columnDefs={gridReportInfo.columnDefs}
            defaultColDef={gridReportInfo.defaultColDef}
            detailCellRendererParams={gridReportInfo.detailCellRendererParams}
            detailRowHeight={gridReportInfo.detailRowHeight}
            groupIncludeTotalFooter={gridReportInfo.groupIncludeTotalFooter}
            icons={gridReportInfo.icons}
            masterDetail={gridReportInfo.masterDetail}
            rowHeight={gridReportInfo.rowHeight}
            rowData={gridReportInfo.rowData}
            pinnedBottomRowData={gridReportInfo.pinnedBottomRowData}
            suppressAggFuncInHeader={gridReportInfo.suppressAggFuncInHeader}
          ></AgGridReact>
        </div>
      }
    </div>
  );
};
