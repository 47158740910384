import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';
import { LedgerDetailsModalModel } from '../../modal/model/ledgerDetailsModalModel';


export class StockLedgerReportModel extends KagamiReportModel {
  modelPopup:LedgerDetailsModalModel;
  reportData: any;
  state: any;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.modelPopup = new LedgerDetailsModalModel()
  }
}
export interface StockLedgerReportModelProps {
  stockLedgerReportModel: StockLedgerReportModel;
}
export const useStockLedgerReportModelState = (state: State<StockLedgerReportModel>) => {
  let stockLedgerReportState = useState(state);
  return {
    reportConfig() {
      stockLedgerReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      stockLedgerReportState.reportData.set(reportContent);
    }
  };
};
