export class SystemConstants {
  //application

  static APP_STATE = 'app-state';

  // inpasinate 
  static IMPERSONATE_STATE = 'impersonate-state';

  //login
  static TOKEN = 'token';
  static DEVICE_INFO = "device_info"
  static ERROR_MESSAGE = 'errorMessage';
  static LOGIN_DATA = 'login-data';
  static LOGIN_STATE = 'login-state';

  //profile
  static PROFILE_STATE = 'profile-state';

  // about
  static ABOUT_STATE = 'about-state';

  //Update Password
  static UPDATEPASSWORD_STATE = 'updatepassword-state';

  // Bulk Upload
  static BULKUPLOAD_STATE = 'bulkupload-state';

  // Step State
  static STEP_STATE = 'step-state';

  // Matrix Form State
  static MATRIX_FORM_STATE = 'matrix-form-state';

  //home
  static HOME_STATE = 'home-state';

  //body
  static BODY_STATE = 'body-state';

  // Breadcrumb
  static BREADCRUMBS_STATE = 'breadcrumb-state';

  //dashboard
  static DASHBOARD_STATE = 'dashboard-state';
  static TODO = 'TODO';
  static Favourites = 'Favourites';
  static INFO = 'INFO';
  static HEADER_STATE = 'header-state';

  //ReportsHeader
  static VIEW_REPORTS = 'View';
  static PINNED_REPORTS = 'Pinned Reports';
  static MAP_REPORTS = 'Map Reports';
  static MAP_ROLES = 'Map Roles';
  static MAP_STUDIO_REPORTS = 'Map Studio Reports';
  static SCHEDULE_REPORTS = 'Schedule';

  //Process
  static PROCESS_STATE = 'process-state';

  //GROUP
  static GROUP_STATE = 'group-state';

  //Navigator
  static LEFT_PANEL = 'leftPanel';
  static MASTER_DATA = 'masterDataGlobal';
  static CONTAINER = 'container';
  static PANEL_TYPE = 'panelType';
  static NAV_STATE = 'nav-state';

  static YYYY_MM_DDTHH_mm_ss_SSSZ = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  static YYYY_MM_DDTHH_mm_ss_SSS = 'YYYY-MM-DDTHH:mm:ss.SSS';
  static dd_MM_yyyy = 'dd-MM-yyyy';
  static h_mm_a = 'h:mm a';
  static dd_MM_yyyy_h_mm_a = 'DD-MM-yyyy h:mm a';
  static yyyy_MM_DD = 'yyyy-MM-DD';
  static DD_MM_YYYY = 'DD/MM/YYYY';
  static DDMMYYYY = 'DD-MM-YYYY';
  static HH_mm = 'HH:mm';
  static yyyy_MM_DDTHH_mm = 'yyyy-MM-DDTHH:mm';
  static DD_MMM_YYYY_h_mm_a = 'DD MMM yyyy h:mm a';

  static REMOVED_ = 'removed_';
  static EMPTY = '';
  static NON_VISIBLE_EMPTY = '\u{200b}';
  static SPACE = ' ';
  static DOT = '.';
  static NA = '---';
  static DASH = '-';
  static DOUBLE_DASH = '--';

  // Construct VIEW
  static VIEW = 'view';
  static UI_INPUT = 'UIINPUT';
  static P_CHILD_VIEW_TYPE = 'pChild';
  static LIST_VIEW_TYPE = 'list';
  static GRID_VIEW_TYPE = 'grid';
  static CARD_VIEW_TYPE = 'card';
  static GANTT_VIEW_TYPE = 'Gantt';
  static CALENDAR_VIEW_TYPE = 'calendar';
  static HIERARCHICAL_VIEW_TYPE = 'hierarchical';
  static KANBAN_VIEW_TYPE = 'kanban';
  static CROSS_TAB = 'crossTab';

  static RUNTIME_INPUT = 'RuntimeInput';

  // Construct CREATE

  static CREATE = 'Create';
  static FORM = 'form';
  static PC_EMBEDDED_FORM = 'pcEmbedForm';
  static TREE_FORM = 'treeForm';

  static PASSWORD = 'password';
  static LOGIN_INFO = 'loginInfo';

  // message
  static PROCESS_COMPLETED = 'Process Completed !!';

  // html-control
  static MULTI_SELECT = 'multiselect';

  // error codes
  static INVALIDATE = 'INVALIDATE';

  // contexts
  static APPLICATION_CONTEXT = 'ApplicationContext';
  static USER_CONTEXT = 'UserContext';

  // API

  static POST = 'POST';
  static GET = 'GET';
  static POST_SINGLE_VALUE = 'POST_SINGLE_VALUE';

  //list
  static LIST_STATE = 'list-state';
  static MATERIAL_LIST_STATE = 'material-list-state';
  static CALENDAR_STATE = 'calendar-state';
  static CARD_VIEW_STATE = 'card-state';
  static KANNBANN_VIEW_STATE = 'kanbann-state';
  static EXISTING_ACTIVE_TEMPLATE = 'existingActiveTemplate';
  static MAIN_PRESENTATION_ID = 'mainPresentationId';
  static HIERARCHY_VIEW_STATE = 'hierarchy-view-state';
  static CROSS_TAB_STATE = 'cross-tab-state';
  //Filter
  static PRESENTATION_FILTER_STATE = 'presentation-filter-state';

  static GRID_LIST_FILTER_STATE = 'grid_filter_state';
  static CLEAR_LIST_FILTER_STATE = 'clear-filters';
  static CLEAR_LIST_FILTER_STATE_UPDATER = 'clear-filters-update';

  static ATTRIBUTESEQUENCE = 'attributeSequence';

  //Filters State
  static FILTER_STATE = 'filter_state';

  static APP_LOADER = 'app-loader';

  //toast messages
  static RECORD_SAVED_TOAST = 'Record saved';

  //Triggers with add icon
  static PRIMARY_TRIGGERS = [
    'Create',
    'create',
    'Add',
    'add',
    'ADD',
    'Add new',
    'AddNew',
    'Add New',
    'CREATE',
    'add',
    'create users',
    'Add New Project',
    'New',
    'Create Direct Issue',
    'NEW'
  ];

  static TABS_STATE = 'tabs state';
}
