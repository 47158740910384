import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class BalanceSheetReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  collapseAll:Boolean;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.collapseAll = false;
  }
}
export interface BalanceSheetReportModelProps {
  balanceSheetReportModel: BalanceSheetReportModel;
}
export const useBalanceSheetReportModelState = (state: State<BalanceSheetReportModel>) => {
  let balanceSheetReportState = useState(state);
  return {
    reportConfig() {
      balanceSheetReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      balanceSheetReportState.reportData.set(reportContent);
    }
  };
};
