import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const ReportModalStyle = (theme: Theme) =>
  createStyles({
    headerContent: {
      'display': 'flex',
      'height': '40px',
      'borderBottom': '1px solid #ccc',
      '& .report-title': {
        margin: '0 auto',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '.6',
        color: '#00856B'
      },
      '& .close-button': {
        'margin': '3px',
        'right': '2px',
        'color': '#0000008a',
        'borderRadius': '10px',
        '&:hover': {
          backgroundColor: '#e9e9e9'
        }
      }
    },
    bodyContent: {
      padding: '0px',
      margin: '0px',
      overflow: 'auto'
    },
    footerContent: {
      borderTop: '1px solid #ccc',
      paddingTop: '0px',
      paddingBottom: '0px'
    },
    eachButton: {
      'backgroundColor': '#00856B',
      'margin': theme.spacing(0.5),
      'right': '2px',
      'width': '96px',
      'height': '30.75px',
      'fontSize': 'small',
      '&:hover': {
        backgroundColor: '#1f8a70'
      }
    },
    cancelButton: {
      color: '#0000008a',
      width: '96px',
      height: '30.75px'
    },
    pdfButton: {
      margin: '5px'
    }
  });
