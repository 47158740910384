import moment from "moment";
import { SystemConstants } from "../../../application/constants/SystemConstants";
import { getAuditLogHeaders } from "../../../application/service/URLService";
import { APIService } from "../../../service/ApiService";
import { hideLoader, showLoader } from "../../loader/controller/KagamiLoaderController";
import { CommonUtils } from "../../../application/utils/CommonUtils";
import { getDateStringAsLocalString } from "../../../application/utils/DateUtils";
// import { renderNestedButton } from "../ui/AuditLog";



export function getAuditLogData(entityId: string, recordId: string, auditLogs: any) {
  showLoader();
  APIService.getData(SystemConstants.GET, getAuditLogHeaders(entityId, recordId), {})
    .then((auditLogData: any) => {
      hideLoader();
      let getPresentationRules = auditLogData.data.presentationRule;
      localStorage.setItem('presenationRulesForAuditLog', JSON.stringify(getPresentationRules));
      let getAuditDetailsData = auditLogData.data.auditLogs
      let getgetAuditDetailsDataAgainstEntityID = getAuditDetailsData.map((auditDetailData: any) => {
        auditDetailData.entityId = entityId; auditDetailData.transId = recordId;
        auditDetailData.updatedTimeStamp = getDateStringAsLocalString(auditDetailData.updatedTimeStamp,'dateTime')
        return auditDetailData;
      })
      auditLogs(getgetAuditDetailsDataAgainstEntityID || []);
    })
    .catch(() => {
      hideLoader();
    });
}
