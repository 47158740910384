import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const cellRendererCustomComponentsStyle = (theme: Theme) =>
  createStyles({
    kgmSearchOptions: {
      '& li': {
        fontSize: `${theme.typography.caption.fontSize} !important`
      }
  },
  kgm_multiselecteditor: {
    width: '100%',
    '& .MuiFormControl-root': {
      height: '100%',
      maxHeight : '100%',
    },
   '& .MuiOutlinedInput-root ': {
      height: '100%',
      maxHeight: '100%',
      flexDirection :'column',
      flexWrap : 'inherit',
      alignItems: 'flex-start',
      '& input' : {
        height: '35px',
        width: '100% !important'
      }
    },
    '& .MuiChip-root': {  
      '&.MuiAutocomplete-tag': {
        margin: '1px',
        // marginRight: '100px',
        display: 'flex',
        height: '23px',
        borderRadius: '0',
        border: '1px solid #ccc',
        backgroundColor: '#fff',
        '& .MuiChip-label' :{
          paddingLeft:theme.spacing(1),
          fontSize: theme.typography.caption.fontSize,
        },
        '& .MuiChip-deleteIcon': {
          fontSize:'14px'
        }
      }
    },

  },
      badgeRoot: {
        height:'100%',
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5)
        // justifyContent: 'center',

      },
      badge: {
        minWidth: theme.spacing(1.25),
        height: theme.spacing(1.25),
        borderRadius: '100%', 
      },
      chipContainer: {
        display:'flex',
      //  justifyContent: 'center',
        alignItems: 'center', 
        height: '100%' , 
        width: '100%',
        "& .MuiChip-root": {
          height: '30px'
        }
      },
      centerIcon: {
        display: 'flex',
        '& .MuiAvatar-root ':{
          width: '100%',
          border: '1px solid grey',
    height: '25px',
        }
      },
      avatarGroup:{
        '& .MuiAvatarGroup-root': {
          marginLeft : theme.spacing(1) ,
          alignItems: 'center',
          
          '& .MuiAvatar-root': {
            width: theme.spacing(3),
            height: theme.spacing(2.8),
            margin : '1px',
            fontSize : `${theme.typography.caption.fontSize} !important`,
            color : '#000',
            alignItems: 'center',
            
            },
        },
      },
      gridSearchFont:{
        '& .MuiOutlinedInput-input':{
          fontSize: `${theme.typography.caption.fontSize} !important`
        }
      },
      rootCheckBox: {
      padding: '0', 
      margin: '0',
      '& .MuiFormControlLabel-root': {
        marginLeft: '0',
        marginRight: '0',
        '& .MuiCheckbox-root ': {
          padding: '0'
          },
      },
    },
    kgmGridSearchOptions: {
        '& li': {
          fontSize: `${theme.typography.caption.fontSize} !important`
        }
 
    },

    dateEditorFeild: {
      minWidth: '150px',
      width: '100%',
      '& .MuiOutlinedInput-root ': {
        background: theme.palette.common.white,
        fontSize: '14px'
      },
       
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
        height: '0.75em',
       
      },
      '& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input':{
        height :'0.8em'
      }
    },
    gridCellImage :{
      width:'50px',
      heigth:'50px',
    }
  })