import { ContextType } from '../../../../../../../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../../../model/KagamiEmbeddedFormPresentationModel';
import { KagamiEmbedTriggerModel } from '../model/KagamiEmbedTriggerModel';

export function buildEmbeddedTriggerModel(
  embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel,
  triggerAction: any,
  callback: Function,
  embeddTriggers: boolean
) {
  const contextForward: boolean = triggerAction.contextForward;
  const selectMultiple: boolean = triggerAction.selectMultiple;

  let contextType: ContextType = contextForward
    ? selectMultiple
      ? ContextType.Mutli
      : ContextType.Single
    : ContextType.None;

  let triggerModel: KagamiEmbedTriggerModel = new KagamiEmbedTriggerModel(
    embeddedFormPresentationModel,
    triggerAction.processName,
    triggerAction.eventName,
    triggerAction.labelName === undefined ? triggerAction.actionName : triggerAction.labelName,
    triggerAction.confirmation,
    triggerAction.confirmationMsg,
    contextType,
    callback
  );
  triggerModel.enableTrigger = triggerModel.contextType === ContextType.None
  triggerModel.policy = triggerAction.policy;

  triggerModel.embeddedInList = embeddTriggers;

  return triggerModel;
}

