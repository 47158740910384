import React, { forwardRef, useImperativeHandle } from 'react'
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getAttributeLabel, getAttributeName } from '../../../../../../service/PresentationService';
import { KagamiAttachmentsModalModel } from '../../../../../controls/file/builder/attachments/model/KagamiAttachmentsModalModel';
import { buildAttachmentsModal } from '../../../../../controls/file/builder/attachments/ui/AttachmentsModal';
import { getValueForGridDate, setActiveRowNode } from '../../service/GridService'

export default forwardRef((props: any, ref: any) => {
    setActiveRowNode(props,props.gridModel)
    let renderedValue = getValueForGridDate(props.node,props.attributePresentation);// gridvalue for file need to be done
    const [ displayValue, setDisplayValue ] = React.useState(renderedValue); 
    useImperativeHandle(ref, () => { 
        return {
          getValue: () => { 
            return displayValue;
          },
          afterGuiAttached: () => { 
            let renderedDateValue : any= '';
            if(CommonUtils.isNotEmpty(props.gridModel.activeRowNode) && props.gridModel.activeRowNode.data[getAttributeName(props.attributePresentation)]){
                renderedDateValue =  props.gridModel.activeRowNode.data[getAttributeName(props.attributePresentation)]
            }
            // return renderedDateValue; 
            setDisplayValue(renderedDateValue);
           }
        };
      });
     
    return (
    <div>

<ThumbnailsView thumbnailData={props}    />
    </div>
  )
})
 
export const ThumbnailsView = (props: any) => {

    // const classes = makeStyles(cellRendererCustomComponentsStyle)();
    let popupModel = props.thumbnailData.popupModel;
    let attachmentsModel:KagamiAttachmentsModalModel = new KagamiAttachmentsModalModel();
    const attrName = getAttributeName(props.thumbnailData.attributePresentation);
    let docDataString = props.thumbnailData.data[attrName];
    let controlValue  = CommonUtils.isNotEmpty(docDataString) ? docDataString.split(',') : ''
    let nonEmptyControlValues = controlValue ?controlValue.filter((item: any) => item  ): [];
    
    let thumbnails = CommonUtils.isNotEmpty(props.thumbnailData.gridModel.thumbnails) ? props.thumbnailData.gridModel.thumbnails : {};


    
    const thumbnailhandleClick = () => {
          //opening attachments modal for card view
         attachmentsModel.attrName = getAttributeLabel(props.thumbnailData.attributePresentation);
         attachmentsModel.kagamiGridAttachemntsData = props.thumbnailData
         attachmentsModel.state.setOpen(true);
    }

 const thumbNailForReadOnlyFields = (thumbNailsForReadOnly: any, controlValues:any) => {
   let docId = '';
    
  return  controlValues.map((fileString:any, i:any) => {
     let fileId  =  fileString.split(":")[0];
     let fileName = fileString.split(':')[1];
     let fileType = fileString.split('.').pop() ;
     if(i < 3) {
     if(CommonUtils.isNotEmpty(thumbNailsForReadOnly) && Object.keys(thumbNailsForReadOnly).includes(fileId)){
       docId = fileId;
         return(
           <div onClick={(event: any) => { 
              CommonUtils.handlePreviewOrDownloadFileInGrid( attrName,   popupModel, fileString)
               }}
           >
               <img title={fileName}
               style={{
                 display: 'flex',
                 gap: '0px',
                 alignItems: 'center',
                 maxWidth: '50px',
                 height: 'auto',
                 border: '1px solid #006E7F',
                 borderRadius: '3px'
               }}
               src={`data:image/png;base64,${thumbNailsForReadOnly[fileId]}`}
             />
           </div>
         )
         
       } 
       else {
         return(
           <img
           style={{ width: 29, height: 29     }}
           alt={fileName} title={fileName} 
           src={CommonUtils.getFileTypeICon(fileType)}
           onClick={() => CommonUtils.handlePreviewOrDownloadFileInGrid( attrName,   popupModel, fileString)}
           />
           )
         }
         
       }
   })
 
 }; 

 return (
    <>
    {thumbNailForReadOnlyFields(thumbnails,nonEmptyControlValues)}
    {nonEmptyControlValues.length > 3 ? (
           <h4 style={{ margin: '0PX', cursor:"pointer" }}
           onClick={thumbnailhandleClick}   >
           
           { '+ ' +  `${nonEmptyControlValues.length - 3}` + ' more' } 
           </h4>
         ) : <></>}



         {buildAttachmentsModal(attachmentsModel)}
    </>

 )

   

}
