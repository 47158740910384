import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiFormSectionPresentationStyle = (theme: Theme) =>
  createStyles({
    root: {
      // '& .row': { 
      //         display: 'flex',
      //         flexWrap: 'wrap',  
      //       },
      //       '& .row>*': {
      //         width: '100%',
      //         maxWidth: "100%",
      //         padding: `0 ${theme.spacing(1)}`,
      //         margin: `${theme.spacing(0.75)} 0`,
      //         boxSizing: 'border-box'
      //       },
      //       '& .row>.row': {
      //         padding: '0',
      //         margin: '0'
      //       },
      //       '& .row.border_one > .col_12 > .row > .border_two':{
      //         border: `1px solid ${theme.palette.info.light}`

      //       },
      //       '& .row.border_one > .col_12':{
      //         border: `1px solid ${theme.palette.info.light}`
      //       },
      //       '& .col': {
      //         flex: '1 0 0%'
      //       },
      //       //this styles should work in the entire form 
      //         '& .col_4': {
      //           width: 'calc(100% / 3)' ,
      //           flex:' 0 0 auto',

      //         },
      //         '& .col_12': {
      //           width: '100%',
      //           flex:' 0 0 auto',

      //         },
      //         '&  .col_3': {
      //           width: 'calc(100% / 4)' ,
      //           flex:' 0 0 auto',

      //         },
      //         '& .col_2': {
      //           width: 'calc(100% / 6)' ,
      //           flex:' 0 0 auto',

      //         }, 
      //         '& .col_6': {
      //           width: 'calc(100% / 2)' ,
      //           flex:' 0 0 auto',

      //         },

      //         '& .verticalFormView .row': {
      //           flexDirection : 'column',
      //           '&>div': {
      //             display: 'flex',
      //             alignItems: 'center',
      //             width: '100%',
      //             '& span': {
      //               width: '40%',
      //             },
      //             '&>div': {
      //               width: '60%'
      //             }
      //           }
      //         }






    },
    control: {
      // padding: theme.spacing(2),
      // border: theme.spacing(2),
      // margin: theme.spacing(0.5),
      // textAlign: 'center',
    },
    formHeader: {
      fontSize: 16,
      fontWeight: 500,
      color:theme.palette.secondary.main,
      margin: '0 1.2rem 1rem 0',
    }
  })