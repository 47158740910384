import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { rebuildProfile } from '../../header/builder/profile/controller/kagamiProfileController';
import { KagamiNavigatorModel } from '../model/KagamiNavigatorModel';
import { buildNavigatorComponent } from '../ui/KagamiNavigator';

export function buildNavigator(navigatorModel: KagamiNavigatorModel): JSX.Element {
  return buildNavigatorComponent(navigatorModel);
}

export function rebuildNavigator(navigatorModel: KagamiNavigatorModel) {
  rebuildProfile(navigatorModel.navigationData , navigatorModel.userData); 
  navigatorModel.state.setNavigatorDataLoaded(true);
}
export function togglecollapseSidebar() {
    const currentDraweStatus: boolean = KagamiStateManager.getStateToHolder(SystemConstants.NAV_STATE).collapseSidebar;
    KagamiStateManager.getStateToHolder(SystemConstants.NAV_STATE).drawerNeeded(!currentDraweStatus);
       let pageHeaderState = KagamiStateManager.getStateToHolder('PageHeader')
       if(pageHeaderState){
        pageHeaderState.setSidebarExpanded(currentDraweStatus)
       }
  }

  