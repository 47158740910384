import { KagamiStateManager } from "../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../constants/SystemConstants";
import { getKagamiListModel } from "../../../controller/KagamiListController";
import { KagamiKanbanSectionsViewModel } from "../model/KagamiKanbanSectionsModel";
import { buildKanbannView } from "../ui/KagamiKanbanSections";

export function buildKanbann(kagamiKanbannViewModel: KagamiKanbanSectionsViewModel) {
    return buildKanbannView(kagamiKanbannViewModel);
  }


  export function reBulidKanbannView(response:any ){
    getKagamiListModel().detailedObjects = response;
    let  kanbanListState = KagamiStateManager.getStateToHolder(SystemConstants.KANNBANN_VIEW_STATE);
     kanbanListState.setListLoaded(true);
  }