import { Autocomplete, Chip, IconButton, TextField } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';

import CloseIcon from '@mui/icons-material/Close';
import { MapReportsViewModelProps } from '../model/MapReportsViewModel';
import { KagamiReportHeaderModelStyle } from '../../../ReportHeader/styles/ReportHeaderStyles';
import { enableAndDisableSaveButton } from '../../service/MapReportsService';
import { translateToLocalistaion } from '../../../../../../../process/service/PresentationService';

export const KagamiMapReportsView = (props: MapReportsViewModelProps) => {
  const classes = makeStyles(KagamiReportHeaderModelStyle)();
  const [value, SetValue] = React.useState(null);
  const mapReportsViewModel = props.mapReportsViewModel;
  const [mapReportState, setMapReportState] = React.useState(mapReportsViewModel.selectedData);
  let selectedData: string = '';

  const handleAddReportIcon =()=>{
    if (selectedData !== '') {
      let roleData: any = selectedData;
      SetValue(null);
      setMapReportState((prev: any) => {
        if (
          roleData['reportName'] !== undefined &&
          prev.find((ele: any) => {
            return ele['reportName'] === roleData['reportName'];
          }) === undefined
        ) {
          mapReportsViewModel.allSelectedData.push({
            reportId: roleData.reportId,
            roleId: mapReportsViewModel.name,
            pinned: false,
            opCode: '+'
          });
          return [...prev, roleData];
        } else {
          return [...prev];
        }
      });
    }
    enableAndDisableSaveButton();
  }

  const handleDeleteReportIcon=(report:any)=>{
    let obj: any = null;
    if (mapReportState.length) {
      setMapReportState((prev: any) => {
        let allSelectedConfigData: any[] = prev.filter((ele: any) => {
          if (ele.reportName === report['reportName']) {
            obj = {
              reportId: report.reportId,
              roleId: mapReportsViewModel.name,
              pinned: false,
              opCode: '-'
            };
          } else {
            return ele;
          }
        });
        if (obj !== null) {
          mapReportsViewModel.allSelectedData.push(obj);
        }
        return allSelectedConfigData;
      });
    }
    enableAndDisableSaveButton()
  }
  return (
    <tr style={{ height: '45px' }}>
      <td>{translateToLocalistaion(props.translations,mapReportsViewModel.name)}</td>
      <td>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Autocomplete
            size="small"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            options={translateToLocalistaion(props.translations,mapReportsViewModel.options)}
            getOptionLabel={(options: any) => options.reportName || ''}
            sx={{ width: 300 }}
            onChange={(event, child) => {
              if (child !== null) {
                selectedData = child;
              }
            }}
            value={value}
            renderInput={(params: any) => <TextField {...params} />}
          />

          <IconButton
            color="success"
            onClick={(event: any) => {
              handleAddReportIcon()
            }}
            size="small"
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
      </td>
      <td>
        {mapReportState.map((report: any) => {
          {
            return (
              <>
                <Chip
                  deleteIcon={<CloseIcon className={classes.removeIcon} />}
                  size="small"
                  sx={{ borderRadius: '1px', border: '1px solid #6ebe44', marginLeft: '10px' }}
                  variant={'outlined'}
                  key={report['reportId']}
                  label={translateToLocalistaion(props.translations, report['reportName'])}
                  onDelete={(event: any) => {
                    handleDeleteReportIcon(report)
                  }}
                  
                />
              </>
            );
          }
        })}
      </td>
    </tr>
  );
};
