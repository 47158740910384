import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { KagamiTriggersModel } from '../model/KagamiTriggersModel';
import { buildTriggerComponents, reBuildAllTriggers } from '../service/KagamiTriggersService';
import { buildTriggers } from '../ui/KagamiTriggers';

export class KagamiTriggersController {
  static buildTriggers(triggersModel: KagamiTriggersModel) {
    triggersModel.triggerComponents = buildTriggerComponents(triggersModel);
    return buildTriggers(triggersModel);
  }

  static reBuildTriggers(triggersModel: any, selectedObjects: any[]) {
    if (CommonUtils.isNotEmpty(triggersModel)) {
      reBuildAllTriggers(triggersModel, selectedObjects);
    }
  }
}
