import { createState } from '@hookstate/core'
import { buildListPresentationFilters,  listPresentationFilters } from '../buliders/ListFilterBuilder'
import { KagamiListFilterModelProps,KagamiListFilterModel, useListFiltersState } from '../model/KagamiListFilterModel'


export function buildListFilters(kagamiListFilterModel: KagamiListFilterModel){   
    
    return <KagamiListFilter key = 'list_filters' kagamiListFilterModel={kagamiListFilterModel}/>
}



export function KagamiListFilter(props :KagamiListFilterModelProps) {
    props.kagamiListFilterModel.state = useListFiltersState(createState(props.kagamiListFilterModel));
  
  return (  


    <>
    {props.kagamiListFilterModel.state.getShowFilters() 
    ? <>
    {props.kagamiListFilterModel.isPickItem ? 
                  props.kagamiListFilterModel.state.getIsListFilterDataLoaded()
                  ? <ListPresentationFilters listPresentationFilters = {props.kagamiListFilterModel} /> : <></>
                  :  <ListPresentationFilters listPresentationFilters = {props.kagamiListFilterModel} />
    } 
    </>
  : <></>
  }
   </>
  )
}

const ListPresentationFilters = (props: any) => {
  return <>{buildListPresentationFilters(props.listPresentationFilters)}</>
}