import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
export const kagamiCardStyles = (theme: Theme) =>
  createStyles({
    grid: {
      margin: theme.spacing(1),
      display: 'flex',
      float: 'left',
      width: '100%',
      overflow: 'auto'
    },
  });
