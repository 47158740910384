import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Popper,
  Paper,
  CardMedia,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  KagamiPageHeaderModel,
  KagamiPageHeaderProps,
} from "../model/KagamiPageHeaderModel";
import { KagamiHomeController } from "../../../controller/KagamiHomeController";
import { KagamiPageHeaderController } from "../controller/KagamiPageHeaderControllerStatic";
import { createState } from "@hookstate/core";
import sidenavmenulogo from "../../../../../../assets/images/custom-icons/side_navigation_left.png";
import userImage from "../../../../../../assets/images/user.png";
import kagamilogo from "../../../../../../assets/images/logo.png";
import { useKagamiHeaderNotificationModelState } from "../model/KagamiHeaderNotificationModel";
import { useKagamiHeaderFavoriteState } from "../model/kagamiHeaderFavorites";
import { KagamiProfileController } from "../builder/profile/controller/kagamiProfileControllerStatic";
import { useKagamiHeaderProfileState } from "../model/kagamiHeaderUserProfile.";
import { KagamiHeaderStyle } from "../style/KagamiHeader";
const useStyles = makeStyles(KagamiHeaderStyle);

export const BuildPageHeader = (
  kagamiPageHeaderModel: KagamiPageHeaderModel
) => {
  return <KagamiPageHeader kagamiPageHeaderModel={kagamiPageHeaderModel} />;
};

export default function KagamiPageHeader(props: KagamiPageHeaderProps) {
  const classes = useStyles();
  const handleMenuOnClick = () => {
    KagamiHomeController.togglePageDrawer();
  };
  let headerNotificationState = useKagamiHeaderNotificationModelState(
    createState(props.kagamiPageHeaderModel.notificationModel)
  );
  let headerFavoritesState = useKagamiHeaderFavoriteState(
    createState(props.kagamiPageHeaderModel.favoritesModel)
  );
  let headerUserProfileState = useKagamiHeaderProfileState(
    createState(props.kagamiPageHeaderModel.profileModel)
  );
  props.kagamiPageHeaderModel.profileModel.state = headerUserProfileState;
  KagamiPageHeaderController.pageHeaderModel = props.kagamiPageHeaderModel;
  let userName = localStorage.getItem("userId");
  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar>
        <Grid
          container
          alignItems="center"
          className={classes.icons_on_headers}
        >
          <Grid item>
            <IconButton onClick={() => handleMenuOnClick()} size="large">
              <CardMedia
                className={classes.hamburger_icon}
                component="img"
                alt="<menu>"
                height="130"
                image={sidenavmenulogo}
                title="Menu"
              />
            </IconButton>
          </Grid>
          <Grid item>
            <CardMedia
              className={classes.kagami_image}
              component="img"
              alt="<Kagami>"
              height="140"
              image={kagamilogo}
              title="Kagami"
            />
          </Grid>
          <Grid item sm></Grid>
          <Grid item>
            <IconButton
              onClick={(e: any) =>
                KagamiPageHeaderController.onProfileClick(
                  props.kagamiPageHeaderModel
                )
              }
              size="large"
            >
              <CardMedia
                className={classes.userImage}
                component="img"
                alt="<user>"
                height="130"
                image={userImage}
                title="userlogo"
              />
            </IconButton>
            <Popper className={classes.poppers} open={true}>
              {
                <Paper className={classes.backgroundPaper}>
                  {headerUserProfileState.isOpen ? (
                    KagamiProfileController.buildProfile(
                      props.kagamiPageHeaderModel.profileModel
                    )
                  ) : (
                    <div></div>
                  )}
                </Paper>
              }
            </Popper>
            <IconButton
              onClick={(e: any) => KagamiPageHeaderController.logout()}
              size="large"
            >
              <PowerSettingsNewIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
