import { MenuList, ClickAwayListener } from "@mui/material";
import { buildProfileMenu } from "../builder/profileBuilder";
import {
  KagamiProfileModel,
  KagamiProfileProps,
} from "../model/KagamiProfileModel";

export function buildUserProfile(kagamiProfileModel: KagamiProfileModel) {
  return (
    <ProfileProcess kagamiProfileModel={kagamiProfileModel}></ProfileProcess>
  );
}

export const ProfileProcess = (props: KagamiProfileProps) => {
  let profileState = props.kagamiProfileModel.state;
  if (profileState.isOpen) {
    let menuItems = buildProfileMenu(props.kagamiProfileModel);
    return (
      <ClickAwayListener onClickAway={() => profileState.setOpen(false)}>
        <MenuList autoFocusItem={profileState.isOpen}>{menuItems}</MenuList>
      </ClickAwayListener>
    );
  }
  return null;
};
