import { ListItem, ListItemText, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { KagamiProcessController } from "../../../../../../../../service/process/controller/KagamiProcessController";
import { KagamiProcessModel } from "../../../../../../../../service/process/model/kagami_process_model";
import { KagamiProfileModel } from "../model/KagamiProfileModel";
import { KagamiPageStyle } from "../styles/kagamiProfile";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { KagamiHomeController } from "../../../../../controller/KagamiHomeController";
import Avatar from "@mui/material/Avatar";
import usermanagement from "../../../../../../../../assets/images/custom-icons/user_management.png";
import errorlog from "../../../../../../../../assets/images/custom-icons/error_log.png";
import announcement from "../../../../../../../../assets/images/custom-icons/announcement.png";
import role_management from "../../../../../../../../assets/images/custom-icons/role_management.png";
import user_settings from "../../../../../../../../assets/images/custom-icons/user_settings.png";
import application_settings from "../../../../../../../../assets/images/custom-icons/application_settings.png";
import supportlink from "../../../../../../../../assets/images/custom-icons/support_link.png";
import organization from "../../../../../../../../assets/images/custom-icons/hierarchy_view.png";

const useStyles = makeStyles(KagamiPageStyle);

export const buildProfileMenu = (kagamiProfileModel: KagamiProfileModel) => {
  let profileMenu = kagamiProfileModel.state.content;
  return profileMenu.map((process: any) => {
    return BuildMenuItems(process);
  });
};

export const BuildMenuItems = (items: any) => {
  const classes = useStyles();
  const handleProcessOnclick = () => {
    var kagamiProcessModel: KagamiProcessModel = new KagamiProcessModel(
      items.event["process"],
      true,
      "",
      KagamiHomeController.showServerError
    );
    KagamiProcessController.executeProcess(kagamiProcessModel);
  };
  const Processnameconditioncase = () => {
    switch (items.name) {
      case "Profile":
        return (
          <Avatar
            alt="User Management"
            src={usermanagement}
            className={classes.icon}
          />
        );
      case "User Management":
        return (
          <Avatar
            alt="User Management"
            src={usermanagement}
            className={classes.icon}
          />
        );
      case "Support Link":
        return (
          <Avatar
            alt="Support Link"
            src={supportlink}
            className={classes.icon}
          />
        );
      case "ErrorLog":
        return (
          <Avatar alt="errorlog" src={errorlog} className={classes.icon} />
        );
      case "Announcement":
        return (
          <Avatar
            alt="announcement"
            src={announcement}
            className={classes.icon}
          />
        );
      case "fetchAnnouncement":
        return (
          <Avatar
            alt="announcement"
            src={announcement}
            className={classes.icon}
          />
        );
      case "Role Management":
        return (
          <Avatar
            alt="role_management"
            src={role_management}
            className={classes.icon}
          />
        );
      case "User Setting":
        return (
          <Avatar
            alt="user_settings"
            src={user_settings}
            className={classes.icon}
          />
        );
      case "Application Setting":
        return (
          <Avatar
            alt="Application Setting"
            src={application_settings}
            className={classes.icon}
          />
        );
      case "View_Settings":
        return (
          <Avatar
            alt="application_settings"
            src={application_settings}
            className={classes.icon}
          />
        );
      case "Organization":
        return (
          <Avatar
            alt="organization"
            src={organization}
            className={classes.icon}
          />
        );
      default:
        return (
          <Avatar
            alt="usermanagement"
            src={usermanagement}
            className={classes.icon}
          />
        );
    }
  };
  return (
    <ListItem
      className={classes.userprofile_list_items}
      button
      onClick={handleProcessOnclick}
    >
      <Processnameconditioncase />
      <ListItemText
        className={classes.userprofile_sublist_processname}
        primary={
          <Typography
            className={classes.user_profile_processname}
            variant="caption"
            display="block"
            gutterBottom
          >
            {items.name}
          </Typography>
        }
      ></ListItemText>
    </ListItem>
  );
};

export const NoImageHandler = (classes: any, fieldState: any) => {
  return (
    <div onBlur={() => (fieldState.errorMessage = "hello")}>
      <img
        src={process.env.PUBLIC_URL + "images/no-image-found.png"}
        width="100"
      />
    </div>
  );
};
