import { lazy, Suspense} from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { ENV_DOMAIN } from '../service/AppURL';
import {CircularProgress} from '@mui/material';

const ENV_DOMAIN_REACT = '/'+ ENV_DOMAIN;
let linkProcessId : string | undefined = '';
export const routes = (appState:any) => {

  const LoginPage = lazy(() => import('./LoginPageRoute'))
  const LogsEnablePage = lazy(() => import('./LogsRoute'));
  const UpdateUserSettingsForAllUsersPage = lazy(() => import('./UserSettingsRoute'));
  const BuildHome = lazy(() => import('./HomeRoute'));
  const BuildHome2 = lazy(() => import('./Home2Route'));
  const BuildLoginForCall = lazy(() => import('./LoginWithLinkRoute'));
  const ResetPassword = lazy(() => import('./ResetPasswordRoute'));
  const UnloadReportConfigData = lazy(() => import('./UnloadReportConfigRoute'));
  const ReloadReportConfigData = lazy(() => import('./ReloadReportConfigRoute'));
  const ReloadAllReportConfigData = lazy(() => import('./ReloadAllReportConfigRoute'));
  const CompilePolicy = lazy(() => import('./CompilePolicyRoute'));

  return (
    <BrowserRouter>
      <Suspense fallback={<div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100vh', width:'100vw'}}><CircularProgress style={{height:'50px', width:'50px'}} /></div>}>
          <Switch>
            <Route exact path={ENV_DOMAIN_REACT +"/login"} >
              {appState.isUserLoggedIn   ? <Redirect to={ENV_DOMAIN_REACT } /> :  <LoginPage /> }
            </Route> 
            <Route exact path={ENV_DOMAIN_REACT+'/logger/:configLevel'}>
            {appState.isUserLoggedIn   ?<LogsEnablePage />: <Redirect to={"/login"} />}
            </Route>
            <Route exact path={ENV_DOMAIN_REACT+'/settings/updateUserSettingForAllUsers'}>
              {appState.isUserLoggedIn   ? <UpdateUserSettingsForAllUsersPage />: <Redirect to={"/login"} />}
            </Route>
            <Route exact path={ENV_DOMAIN_REACT }>
              {appState.isUserLoggedIn   ?<BuildHome2 />: <Redirect to={"/login"} />}
            </Route>
            <Route exact path={ENV_DOMAIN_REACT + '/call/:processId'}  >
              {appState.isUserLoggedIn   ? <BuildHome  /> : <BuildLoginForCall />}
            </Route>
            <Route exact path={ENV_DOMAIN_REACT + "/resetPassword/:userId/:userEmail/:token"} >
<ResetPassword />
            </Route> 
            <Route exact path={ENV_DOMAIN_REACT + "/report/unloadReportConfigData/:reportId"}>
            {appState.isUserLoggedIn   ?<UnloadReportConfigData />: <Redirect to={"/login"} />}
            </Route>
            <Route path={ENV_DOMAIN_REACT + "/report/reloadReportConfigData/:reportId"}>
            {appState.isUserLoggedIn   ?<ReloadReportConfigData />: <Redirect to={"/login"} />}
            </Route>
            <Route exact path={ENV_DOMAIN_REACT + "/report/reloadAllReportConfigData"}>
            {appState.isUserLoggedIn   ?<ReloadAllReportConfigData />: <Redirect to={"/login"} />}
            </Route>
            <Route exact path={ENV_DOMAIN_REACT+'/util/compileAllPolicy'}>
              {appState.isUserLoggedIn   ? <CompilePolicy />: <Redirect to={"/login"} />}
            </Route>
             <Route path="*">
            {appState.isUserLoggedIn   ? <Redirect to={ENV_DOMAIN_REACT } /> :  <Redirect to={ENV_DOMAIN_REACT  +"/login"} />}
            </Route>
          </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export function getLinkProcessId(){
  return linkProcessId;
}

export function setLinkProcessId(id : any){
  linkProcessId = id;
}
