import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { isTypeText } from '../../../../../service/PresentationService';
import { KagamiGridModel } from '../model/KagamiGridModel';
import { formatFile } from './FieldHelper';

export const handleGridCellData = (params: any) => {
    let _colConfig = params.colDef?.headerComponentParams?.$$colConfig;
    let kagamiGridModel: KagamiGridModel = _colConfig?.kagamiGridModel
    // if(params.event.key !='Escape' && params.event.key !='Tab' && params.event.key !='Enter' && params.event.key !='Control' && !params.event.ctrlKey ){
    if ((kagamiGridModel && kagamiGridModel.isEditableEmbedList && params.event.key != 'Escape' && params.event.key != 'Tab' && params.event.key != 'Enter' && !params.event.ctrlKey && params.event.key != 'Shift') || (params.event.keyCode == 86 && params.event.ctrlKey)) {
        let columnType = '';
        if (params?.colDef?.headerComponentParams?.$$colConfig?.columnType) {
            columnType = params.colDef.headerComponentParams.$$colConfig.columnType
        }
        if (columnType == 'number') {
            handleGridNumber(params, columnType);
        }
        else if (isTypeText(columnType)) {
            handledGridFieldChange(params, columnType);
        }
        else if (columnType == 'search' || columnType == 'select') {
            handleGridSearch(params, columnType);
        }
    }

}

export const handleGridNumber = (params: any, columnType: any) => {
    // if (params?.colDef?.cellClass && params.colDef.cellClass()?.length && params.colDef.cellClass().indexOf('kgm-number-cell') != -1) {
        let currVal = params?.event?.target?.value ;
        if (currVal && currVal.length > 0) {
            if((currVal.charAt(0) === '-' && currVal.length === 1) || currVal.charAt(currVal.length - 1) === '.'){
                console.log('not making on load because the current data is characters instead of numbers')
            }
            else if (currVal.length === 1 && !(currVal.charAt(0) === '-')) {
                if (handleSymbols(currVal)) {
                    if (isNaN(currVal.charAt(currVal.length - 1))) {
                        params.event.target.value = currVal.slice(0, -1);
                    }
                    else if (isNaN(currVal)) {
                        params.event.target.value = currVal.replace(/[^\d]/g, '');
                    }
                    else if (currVal) {
                        handledGridNumberFieldChange(params, columnType);
                    }
                }
            }else{
                if (handleSymbols(currVal)) {
                    if (isNaN(currVal.charAt(currVal.length - 1))) {
                        params.event.target.value = currVal.slice(0, -1);
                    }
                    else if (isNaN(currVal)) {
                        params.event.target.value = currVal.replace(/[^\d]/g, '');
                    }
                    else if (currVal) {
                        handledGridNumberFieldChange(params, columnType);
                    }
                }
            }
        }
        else if(currVal === ''){
            // params.event.target.value = 0;
            handledGridNumberFieldChange(params, columnType);
        }
    // }
}

const handleSymbols = (currVal: any) => {
    if ((currVal.charAt(currVal.length - 1) == '.')) {
        return (currVal.split(".").length > 2) ? true : false;
    }
    if ((currVal.charAt(currVal.length - 1) == '-')) {
        if ((currVal.split("-").length > 2)) {
            return true;
        }
        return (currVal.length - 1 == 0) ? false : true;
    }
    return true;
}

export const handleGridSearch = (params: any, columnType: any) => {
    if (params?.event?.target?.value) {
        handledGridFieldSearch(params, columnType);
    }
}

const handledGridNumberFieldChange = debounce((params: any, columnType: any) => {
    let _colConfig = params.colDef.headerComponentParams.$$colConfig;
    let currentValue = params.event.target.value
    if(currentValue !== '-' && (CommonUtils.isEmpty(currentValue) || ((CommonUtils.isNotEmpty(currentValue) && currentValue.toString().charAt(currentValue.length-1) !== '.')))){
        if (_colConfig.columnType === 'number') {
            params.node.data[_colConfig.column.attrName] = _colConfig.columnType === 'number' ? Number(currentValue !== ''  ? currentValue : '0') : currentValue || "0";
        } 
        params.colDef.headerComponentParams.$$colConfig.onCellValueChanged(_colConfig.kagamiGridModel, params.node, _colConfig.column.onChangeRequired, params.column.colId, params);
    }   
}
    , 400)


const handledGridFieldChange = debounce((params: any, columnType: any) => {
    // console.log("handled fieldchagne after 1000 seconds.....")
    // if (params?.colDef?.headerComponentParams?.$$colConfig?.column?.onChangeRequired) {
    let _colConfig = params.colDef.headerComponentParams.$$colConfig;
    if (_colConfig.columnType === 'number') {
        params.node.data[_colConfig.column.attrName] = _colConfig.columnType === 'number' ? Number(params.event.target.value) : params.event.target.value || "0";
    } else {
        params.node.data[_colConfig.column.attrName] = params.event.target.value || "";
    }
    params.colDef.headerComponentParams.$$colConfig.onCellValueChanged(_colConfig.kagamiGridModel, params.node, _colConfig.column.onChangeRequired, params.column.colId, params);
    //}
}
    , 600)

const handledGridFieldSearch = debounce((params: any, columnType: any) => {
    if (params?.colDef?.headerComponentParams?.$$colConfig?.column?.onChangeRequired) {
        let _colConfig = params.colDef.headerComponentParams.$$colConfig;
        params.node.data[_colConfig.column.attrName] = params.event.target.value;
        params.colDef.headerComponentParams.$$colConfig.onCellValueSearch(_colConfig.kagamiGridModel, params.node, _colConfig.column.onChangeRequired, params.column.colId);
    }
}
    , 600)

export const isNumber = (val: any) => {
    if (val && typeof val === 'number') val = val.toString()
    return (val && !(isNaN(val.charAt(val.length - 1)))) ? true : false;
}
export const removeLastLetter = (str: any) => {
    return str ? str.slice(0, -1) : str;
}
export const handleRequestProcess = (kagamiGridModel: any, isRequestInProgress: any) => {
    /// Note : commented this because its causing nested styling related issues
    if (isRequestInProgress) {
        // document.getElementsByClassName(kagamiGridModel.mainEntityId)[0]?.classList.add('mouseevents-none')
    } else {
        // document.getElementsByClassName(kagamiGridModel.mainEntityId)[0]?.classList.remove('mouseevents-none')
    }
}


/** GRID CELL RENDERER UTILS */
export const getGridCellPresentations = (presentationRule: any) => {
    let cellStyle: any = {};
    if (presentationRule?.uiSettings?.fontStyling?.label?.fontColor || presentationRule?.uiSettings?.fontStyling?.fontColor) {
        cellStyle['color'] = presentationRule?.uiSettings?.fontStyling?.label?.fontColor || presentationRule?.uiSettings?.fontStyling?.fontColor;
    }
    return isEmpty(cellStyle) ? undefined : cellStyle
}

export const getPresentationRuleImagePath = (presentationRule: any, data: any, dispPresentationRuleConfig: any) => {
    var entityId = presentationRule.entityPrefix + presentationRule.entityConsumed;
    let value = "";
    let imgPath = "";
    forEach(dispPresentationRuleConfig.$$visibleAttributes, function (attr, index) {
        if (attr.type && attr.type === 'file') {
            if (!isEmpty(get(data, entityId))) {
                value = get(get(data, entityId), attr.name);
            }
            imgPath = formatFile(value);
        }
    });
    return imgPath;
}

/** GRID CELL RENDERER UTILS */


export const getFirstEditableColumn = (colDefs: any[]) => {
    const editableCols = colDefs.filter(def => def.colDef.editable);
    return editableCols.length !== 0 ?  editableCols[0]?.colDef.field :undefined;
}