import { makeStyles } from '@mui/styles';
import uniqueId from 'lodash/uniqueId';
import { buildDateFilter } from '../builder/Date/controller/DateFilterController';
import { ReportDateFilterModel } from '../builder/Date/model/DateFilterModel';
import { buildDateTimeFilter } from '../builder/DateTime/controller/DateTimeFilterController';
import { ReportDateTimeFilterModel } from '../builder/DateTime/model/DateTimeFilterModel';
import { buildNumberFilter } from '../builder/Number/controller/NumberFilterController';
import { ReportNumberFilterModel } from '../builder/Number/model/NumberFilterModel';
import { buildTextFilter } from '../builder/Text/controller/TextFilterController';
import { ReportTextFilterModel } from '../builder/Text/model/TextFilter';
import { ReportFilterModel, ReportFilterModelProps } from '../model/KagamiReportFilterModel';
import { kagamiReportFilterStyle } from '../style/KagamiReportFilter';
import { translateToLocalistaion } from '../../../process/service/PresentationService';

const useStyles = makeStyles(kagamiReportFilterStyle);

export const buildReportFilterComponent = (reportFilterModel: ReportFilterModel, translations?: any) => {
  return <ReportFilter key={uniqueId()} reportFilterModel={reportFilterModel} translations={translations} />;
};

const ReportFilter = (props: ReportFilterModelProps) => {
  const reportFilterModel = props.reportFilterModel;
  let translationWords = props?.translations;
  const classes = useStyles();
  let theadData: any[] = [];
  let tbodyData: any[] = [];
  theadData.push(reportFilterHeader(translationWords));

  let filterValuesResponse = reportFilterModel.filterValues;
  reportFilterModel.filters.map((filterConfig: any) => {
    if (!filterConfig.isuser) {
      switch (filterConfig.type) {
        case 'Text':
          let kagamiTextFilterModel = new ReportTextFilterModel(
            filterConfig.dependentValues,
            filterConfig.name,
            filterConfig.relationName,
            reportFilterModel,
            filterConfig
          );
          reportFilterModel.filterModels.set(filterConfig.name, kagamiTextFilterModel);
          kagamiTextFilterModel.translations = translationWords;
          tbodyData.push(buildTextFilter(kagamiTextFilterModel, kagamiTextFilterModel.translations));
          break;
        case 'Number':
          let kagamiNumberFilterModel = new ReportNumberFilterModel(
            filterValuesResponse,
            filterConfig.name,
            filterConfig.relationName,
            reportFilterModel,
            filterConfig
          );
          reportFilterModel.filterModels.set(filterConfig.name, kagamiNumberFilterModel);
          kagamiNumberFilterModel.translations = translationWords;
          tbodyData.push(buildNumberFilter(kagamiNumberFilterModel, kagamiNumberFilterModel.translations));
          break;
        case 'Date':
          const kagamiDateFilter = new ReportDateFilterModel(
            filterConfig,
            filterConfig.name,
            filterConfig.relationName,
            reportFilterModel
          );
          kagamiDateFilter.translations = translationWords;
          tbodyData.push(buildDateFilter(kagamiDateFilter, kagamiDateFilter.translations));
          break;
        case 'DateTime':
          const kagamiDateTimeFilter = new ReportDateTimeFilterModel(
            filterConfig,
            filterConfig.name,
            filterConfig.relationName,
            reportFilterModel
          );
          kagamiDateTimeFilter.translations = translationWords;
          tbodyData.push(buildDateTimeFilter(kagamiDateTimeFilter, kagamiDateTimeFilter.translations));
          break;
        default:
          tbodyData.push(filterNotImplemented());
      }
    }
  });

  return (
    <>
      <table className={classes.filter_table}>
        <thead>{theadData}</thead>
        <tbody>{tbodyData}</tbody>
      </table>
    </>
  );
};

const filterNotImplemented = () => {
  return (
    <tr>
      <td>Not implemented</td>
      <td>Not Implemented</td>
      <td>Not Implemented</td>
    </tr>
  );
};

const reportFilterHeader = (translationWords: any) => {
  return (
    <tr key={uniqueId()}>
      <th>{translateToLocalistaion(translationWords, 'Name')}</th>
      <th>{translateToLocalistaion(translationWords, 'Filter Input')}</th>
      <th>{translateToLocalistaion(translationWords, 'Data')}</th>
    </tr>
  );
};
