import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const ReportHeaderStyle = (theme: Theme) =>
  createStyles({
    reportHeaderContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      background: 'transparent',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 10%)',
      width: 'auto',
      height: '46px'
    },
    left: {
      display: 'flex'
    },
    right: {
      'display': 'flex',
      '& .ClearIconBtn': {
        position: 'absolute',
        top: '8px',
        right: '16px'
      },
      '& .SearchIconBtn': {
        position: 'absolute',
        top: '14px',
        right: '20px'
      }
    },
    search_bar: {
      'width': '100%',
      '& .MuiOutlinedInput-root': {
        'backgroundColor': '#fff !important',
        '& .MuiOutlinedInput-input': {
          position: 'absolute',
          padding: '0 !important',
          marginLeft: '8px'
        },
        '& .MuiInputBase-input': {
          width: '80%',
          whiteSpace: 'nowrap !important',
          overflow: 'hidden !important',
          textOverflow: 'ellipsis !important',
          fontSize: '0.9rem !important'
        },
        '& fieldset': {
          // borderColor: 'red !important',
          transitionDuration: '0.5s',
          marginTop: '6px',
          width: '200px',
          height: '40px',
          position: 'relative'
        },
        '&:hover fieldset': {
          width: '200px'
        },
        '&.Mui-focused fieldset': {
          right: '100%',
          width: '35rem'
          // borderRadius: 'none',
        }
      }
    },
    backButton: {
      'background': '#CBCBCB',
      'width': '27px',
      'height': '27px',
      'margin': 'auto',
      'borderRadius': '13px',
      '& .backArrow': {
        margin: 'auto 7px'
      },
      '&:hover': {
        background: 'rgb(237 237 237)'
      }
    }
  });
