import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { KagamiProfilePopoverStyles } from '../../../../../../header/builder/profile/styles/kagamiProfile';
import { handleUpdate } from '../controller/UpdatePasswordController';
import { KagamiUpdatePasswordModel, KagamiUpdatePasswordProps } from '../model/updatePasswordModel';
import { useKagamiUpdatePasswordState } from '../model/updatePasswordModel';
import UpdatePasswordTextField from './UpdatePasswordTextField';

const useStyles = makeStyles(KagamiProfilePopoverStyles);

export const buildKagamiUpdatePassword = (
  kagamiUpdatePasswordModel: KagamiUpdatePasswordModel,
  popupModel: KagamiModalModel
) => {
  return <KagamiUpdatePassword kagamiUpdatePasswordModel={kagamiUpdatePasswordModel} popupModel={popupModel} />;
};

export const KagamiUpdatePassword = (props: KagamiUpdatePasswordProps) => {
  const classes = useStyles();
  let updatePasswordState = useKagamiUpdatePasswordState(createState(props.kagamiUpdatePasswordModel));
  let profileState = KagamiStateManager.getStateToHolder(SystemConstants.PROFILE_STATE);

  const isAllFieldsValidationSuccess = () => {
    if (
      updatePasswordState.currentPassword &&
      updatePasswordState.newPassword &&
      updatePasswordState.confirmNewPassword &&
      updatePasswordState.isNewPasswordSame
    ) {
      props.kagamiUpdatePasswordModel.kagamiModalModel.state.setButtonDisabled(false);
    } else {
      props.kagamiUpdatePasswordModel.kagamiModalModel.state.setButtonDisabled(true);
    }
  };

  return (
    <>
      {updatePasswordState?.errors?.passwordmismatch && (
        <h6 className={classes.updatePassword}>{updatePasswordState?.errors?.passwordmismatch}</h6>
      )}
      <div className={classes.mainModalContainer}>
        <div className={classes.inputContainer}>
          {/* <h4 className={classes.label}>
            User Id <span className={classes.star}>*</span>
          </h4> */}

        <label className={classes.label}>
        User Id <span className={classes.star}>*</span>
      </label>


          <UpdatePasswordTextField
            className={classes.input}
            size="small"
            type="text"
            disabled
            value={profileState.userDataForProfile.userId}
          />
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>
            Current Password <span className={classes.star}>*</span>
          </label>
          <UpdatePasswordTextField
            size="small"
            className={classes.input}
            type="password"
            value={updatePasswordState.currentPassword}
            onChange={(e: any) => {
              updatePasswordState.setCurrentPassword(e.target.value);
              isAllFieldsValidationSuccess();
              let errors = { ...updatePasswordState.errors };
              if (e.target.value.length < 1) {
                errors['currentPassword'] = 'Enter current password';
                console.log(errors);
                updatePasswordState.setErrors(errors);
              } else {
                errors['currentPassword'] = '';
                updatePasswordState.setErrors(errors);
              }
            }}
          />
          {updatePasswordState?.errors?.currentPassword && (
            <h6 className={classes.updatePasswordError}>{updatePasswordState?.errors?.currentPassword}</h6>
          )}
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>
            New Password <span className={classes.star}>*</span>
          </label>
          <UpdatePasswordTextField
            size="small"
            className={classes.input}
            type="password"
            value={updatePasswordState.newPassword}
            onChange={(e: any) => {
              updatePasswordState.setNewPassword(e.target.value);
              isAllFieldsValidationSuccess();
              let errors = { ...updatePasswordState.errors };
              if (e.target.value.length < 1) {
                errors['newPassword'] = 'Enter new password';
                console.log(errors);
                updatePasswordState.setErrors(errors);
              } else if (!updatePasswordState.isOldPasswordDifferent) {
                errors['newPassword'] = 'New Password cannot be same as old password';
                updatePasswordState.setErrors(errors);
              } else {
                errors['newPassword'] = '';
                updatePasswordState.setErrors(errors);
              }
              console.log(updatePasswordState.newPassword);
            }}
          />
          {updatePasswordState?.errors?.newPassword && (
            <h6 className={classes.updatePasswordError}>{updatePasswordState?.errors?.newPassword}</h6>
          )}
        </div>
        <div className={classes.inputContainer}>
          <label className={classes.label}>
            Confirm New Password <span className={classes.star}>*</span>
          </label>
          <UpdatePasswordTextField
            size="small"
            className={classes.input}
            type="password"
            value={updatePasswordState.confirmNewPassword}
            onChange={(e: any) => {
              updatePasswordState.setConfirmNewPassword(e.target.value);
              isAllFieldsValidationSuccess();
              let errors = { ...updatePasswordState.errors };
              if (e.target.value.length < 1) {
                errors['confirmPassword'] = 'Enter password again';
                console.log(errors);
                updatePasswordState.setErrors(errors);
              } else if (!updatePasswordState.isNewPasswordSame) {
                errors['confirmPassword'] = 'New Password must be same as confirm password';
                updatePasswordState.setErrors(errors);
              } else {
                errors['confirmPassword'] = '';
                updatePasswordState.setErrors(errors);
              }
            }}
          />
          {updatePasswordState?.errors?.confirmPassword && (
            <h6 className={classes.updatePasswordError}>{updatePasswordState?.errors?.confirmPassword}</h6>
          )}
        </div>
      </div>
    </>
  );
};

export const handleUpdateButton = () => {
  handleUpdate();
};
