import { KagamiDateModel } from '../model/kagamiDateModel';
import KagamiDateField from '../ui/kagamiDateField';
import KagamiTextBoxValidator from '../../validations/KagamiControlValidator';
import { notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import { parseDateToUTCString } from '../../../../../../../../../../utils/DateUtils';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';

export function buildDateField(kagamiDateModel: KagamiDateModel) {
  return <KagamiDateField key={"DateField"+kagamiDateModel.controlIdentifier}kagamiDateModel={kagamiDateModel}></KagamiDateField>;
}

export function validateDateOnChange(kagamiDateModel: KagamiDateModel, value: any) {
  if (kagamiDateModel.controlValue != value) {
    // let localTime = value;
    let errorMsg = KagamiTextBoxValidator.validate(kagamiDateModel.controlType, value, kagamiDateModel.validation);
    if (errorMsg === null && value !== '') {
      // kagamiDateModel.state.setDisplayValue(value);
      kagamiDateModel.state.setErrorMessage(errorMsg);
      if(CommonUtils.isEmpty(value)){
        kagamiDateModel.controlValue = null 
      }
      kagamiDateModel.controlValue = parseDateToUTCString(value, kagamiDateModel.controlType);
      notifyChangeToForm(kagamiDateModel, null);
    } else {
      kagamiDateModel.controlValue = null
      notifyChangeToForm(kagamiDateModel, null);
      kagamiDateModel.state.setErrorMessage(errorMsg);  
    }
  }
}

export function openCloseDatePicker(kagamiDateModel: KagamiDateModel,value:boolean){
  kagamiDateModel.state.setIsOpen(value);
}