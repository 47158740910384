import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiEmbedTriggerModel } from '../model/KagamiEmbedTriggerModel';
import IconButton from '@mui/material/IconButton';
import { updateInFocusMap } from '../../../../../../../service/FormService';
import { getFormModel } from '../../../../../../../controller/KagamiFormController';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../../../model/KagamiEmbeddedFormPresentationModel';
import { CommonUtils } from '../../../../../../../../../../../../../../../utils/CommonUtils';
import { KagamiFormModel } from '../../../../../../../model/KagamiFormModel';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
  },
  secondary: {
    'backgroundColor': theme.palette.info.light,
    '& .MuiButton-label': {
      color: theme.palette.secondary.main
    }
  },
  primary: {
    'backgroundColor': theme.palette.primary.light,
    '& .MuiButton-label': {
      color: theme.palette.primary.main
    }
  }
}));

export const getActionButtons = (embeddedTriggerControl: KagamiEmbedTriggerModel) => {
  switch (embeddedTriggerControl.triggerName) {
    case 'add':
      return AddButton(embeddedTriggerControl);

    case 'edit':
      return EditButton(embeddedTriggerControl);

    case 'remove':
      return DeleteButton(embeddedTriggerControl);

    case 'reset':
      return DeleteButton(embeddedTriggerControl);

    default:
      return <div>Y this</div>;
  }
};

const AddButton = (embeddedTriggerControl: KagamiEmbedTriggerModel) => {
  const classes = useStyles();
  return embeddedTriggerControl.state.enableTrigger ?
    // <Fab className="floating-add-container" onClick={() => embeddedTriggerControl.triggerCallback()}>
    //   <AddRounded style={{ fontSize: '22px', color: "#0059B3" }} />
    // </Fab> 
    // <Button variant="outlined" 
    //   startIcon={ <AddRounded style={{ fontSize: '22px', color: "#0059B3" }} />}
    //   onClick={() => embeddedTriggerControl.triggerCallback()}>
    //       Add Row
    //   </Button>

    <div className='floating-add-container'>
      <div className='floatingAddButton'
       onClick={() => embeddedTriggerControl.triggerCallback()}
       >
      <PlaylistAddOutlinedIcon style={{ fontSize: '22px', color: "#29947F" }} />
      <span style={{margin:'6px 0',color: '#29947F'}}>ADD NEW</span>
      </div>
    </div>
    : 
    <div></div>
};

const EditButton = (embeddedTriggerControl: KagamiEmbedTriggerModel) => {
  let embeddedPresentationModel = embeddedTriggerControl.embeddedFormPresentationModel;
  if(embeddedTriggerControl.embeddedData === undefined){
    return '';
  }
  let formDataIdentifier = embeddedTriggerControl.embeddedData.id;
  const classes = useStyles();
  return (
    <IconButton size='small' 
      className={classes.root}
      title="Edit row"
      // variant="outlined"
      onClick={() => {
        console.log('edit button');
        embeddedPresentationModel.formDataIdentifier = formDataIdentifier;
        embeddedTriggerControl.triggerCallback(embeddedTriggerControl.embeddedFormPresentationModel, 'modal', formDataIdentifier);
      }}
    // sx={{ width: 23, height: 23 }}
    >
      <EditOutlinedIcon color='success' fontSize="small" />
    </IconButton>
  );
};

const DeleteButton = (embeddedTriggerControl: KagamiEmbedTriggerModel) => {
  if(embeddedTriggerControl.embeddedData === undefined) return '';
  let formDataId: string = embeddedTriggerControl.embeddedData.id
  const classes = useStyles();
  return (
    <IconButton size='small' 
      className={classes.root}
      title="Delete row"
      // variant="outlined"
      onClick={() => {
        let embeddedFormPresentationModel : KagamiEmbeddedFormPresentationModel = embeddedTriggerControl.embeddedFormPresentationModel;
        if (embeddedFormPresentationModel.isNested && CommonUtils.isNotEmpty(embeddedFormPresentationModel.parentEmbedIdForNestedEmbed)) {
          let formModel : KagamiFormModel = getFormModel();
          let entityHierarchy = formModel.formData.entityHierarchies.get(embeddedFormPresentationModel.presentationId)
          let parentEntityId : string = entityHierarchy.parentEntity
          updateInFocusMap(parentEntityId,embeddedFormPresentationModel.parentEmbedIdForNestedEmbed,formModel)
          embeddedFormPresentationModel = formModel.formControls[embeddedFormPresentationModel.formEntityId + '.' + embeddedFormPresentationModel.parentEmbedIdForNestedEmbed]
        }        
        embeddedFormPresentationModel.formDataIdentifier = formDataId
        updateInFocusMap(embeddedFormPresentationModel.formEntityId,formDataId,getFormModel())
        embeddedTriggerControl.triggerCallback(embeddedFormPresentationModel);
      }}
    // sx={{ width: 23, height: 23 }}
    >
      <CloseIcon color='error' fontSize="small" />
    </IconButton>
  );
};
