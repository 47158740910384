import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiNavigatorStyle = (theme: Theme) =>
  createStyles({
    proSidebar: {
        'willChange': 'width',
        transition: 'width 0.1s',
        '& .pro-menu' :{
            paddingTop: '0',
            paddingBottom: '0',
        },
        '& .MuiSvgIcon-root': {
            // fontSize: '16px'
        },
        '& .pro-menu .pro-menu-item': {
            fontSize: '13px',
            '& > .pro-inner-item':{
                padding: '8px 16px 8px 7px !important',
            },
        }, 
        '& .pro-sidebar-inner ': {
            // backgroundImage: "-webkit-linear-gradient(left,#141e30,#243b55)",
            backgroundColor: theme.palette.primary.main
            
        },
       '& .pro-sidebar-layout::-webkit-scrollbar' : {
           display: 'none'
       },
        '& .popper-inner::-webkit-scrollbar': {
            display: 'none',
        },   
        
        '& .pro-inner-list-item > .popper-inner' : { 
            // backgroundImage: "-webkit-linear-gradient(left,#141e30,#243b55)", 
            backgroundColor: theme.palette.primary.main
        },
        
        '& .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item':{ 
          backgroundColor: theme.palette.primary.main,
        
        //   background-image: linear-gradient(to right, red , yellow);
        backgroundImage: "linear-gradient(to right,#05263d, #13344d)",
        // background:"linear-gradient(rgba(0,0,0,0.5),transparent)",
          boxShadow: '0 0 0 1px rgba(0, 0, 0, .2)'
        },
        
        '& .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper': {
            background:'transparent'
        } ,
        '& .masterData_ul .react-slidedown  ul': {
        paddingTop: '0 !important'
        },
        '& .textBox_masterData':{ 
            // marginLeft: '-35px',
            '&>.pro-inner-item ': {
                padding: '8px 8px 8px 20px !important', 
            }
        },

        '& .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul':{
            padding: '6px 0',
        },
        '& .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item': {
            paddingLeft: '6px'
        },
        '& .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content':{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            color:'#bbdcfe',
        },
        '&:not(.collapsed) .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content':{
            wordBreak: 'break-word',
        },
        '& .MuiCheckbox-root' : {
            padding: '0'
        },

        '&  .pro-menu .pro-menu-item.active .pro-inner-item ':{
            // color:theme.palette.success.dark
        },
        '& .pro-sub-menu.open .pro-icon' :{
            // border: '1px solid #fff',
            // backgroundColor: 'rgba(255,255,255,0.16)',
            // borderRadius: '15%',
            
            // backgroundImage: "-webkit-linear-gradient(left,#141e30,#243b55)",

        },
        '& .activeMainMenu': {
            backgroundColor: 'rgba(255,255,255,0.16)',
           padding:'5px',
            borderRadius:'15%',
            boxSizing:'content-box',
            
            
        },
        '& .activeSubMenu': {
            '& > .pro-inner-item:first-child':{
                '& .pro-item-content':{
                    color: theme.palette.success.dark + '!important',
                    fontWeight: '600',
                }
            }
        },
        '& .popper-element':{
            display: 'none'
        },
        '& .pro-inner-list-item .pro-icon img': {
            height:'25px',
            width:'25px'
        },
        '& .pro-icon img': {
            height:'30px',
            width:'30px'
        },
//these styles apply in navbar closed state
        '&.collapsed':{
            width: '65px',
            minWidth: '65px',
            '& .popper-inner:has(> ul > .textBox_masterData)': {
                height:'100vh !important',
            },
             '& .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner': {
                backgroundColor: theme.palette.primary.main
             },
            '& .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content':{
            
                wordBreak: 'normal', 
            },
            '& .pro-menu .pro-menu-item': { 
                '& > .pro-inner-item':{
                    // padding: '8px 20px 8px 20px',
                    paddingLeft: '15px !important',
                },
            },
            '& .textBox_masterData':{ 
                marginLeft: '0',
                '&>.pro-inner-item ': {
                    padding: '8px 8px 8px 0 !important', 
                }
            },
            '& .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item' : {
                background: 'transparent'
            },
            '& .popper-element' : {
                display: 'block'
            }
        },
         

    },
   
    hamburgerimg: {
        height: theme.spacing(5),
        padding:'0 8px',
        display: 'flex',
        alignItems: 'center',   
      },

    hamburgerimgnrml: {
        transform : 'none',
        transition:'0.5s ease-in',
        cursor : 'pointer'
      },
    hamburgerimgrevers: {
        transform : 'scaleX(-1)',
        transition:'0.5s ease-out',
        cursor : 'pointer'
        
      },
  });