import { getDefaultListView, getMainEntityPresentationsFromUIResource } from "../../../service/PresentationService";
import { handlePickItemTriggerClick, handleTriggerClick, setKagamiListModel } from "../../list/controller/KagamiListController";
import { KagamiListModel } from "../../list/model/KagamiListModel";
import { KagamiTriggersModel } from "../../Triggers/model/KagamiTriggersModel";
import { buildListEditView } from "../ui/KagamiListEdit";

export function buildListEdit(kagamiListEditModel: KagamiListModel): any {
  kagamiListEditModel.listTemplate = getDefaultListView(kagamiListEditModel.onStartData);
  kagamiListEditModel.triggersModel = new KagamiTriggersModel(
    kagamiListEditModel.mainEntityId,
    getMainEntityPresentationsFromUIResource(kagamiListEditModel.onStartData)[0].actions,
    kagamiListEditModel.isPickItemListModel ? handlePickItemTriggerClick : handleTriggerClick
  );
  setKagamiListModel(kagamiListEditModel);
  return buildListEditView(kagamiListEditModel);
}