import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { CircularProgress, Button } from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import { MapRolesModel, MapRolesModelProps, useMapRolesState } from '../model/MapRolesModel';
import { setAllRolesAndMapReportRolesData, mapRolesOnSave } from '../service/MapRolesService';
import { createState } from '@hookstate/core';
import React from 'react';
import { MapRolesViewModel } from '../builder/MapRolesViewPage/model/MapRolesViewPageModel';
import { MapRolesView } from '../builder/MapRolesViewPage/ui/MapRolesViewPageView';
import { KagamiReportHeaderModelStyle } from '../../ReportHeader/styles/ReportHeaderStyles';
import { translateToLocalistaion } from '../../../../../../process/service/PresentationService';

export const buildMapRolesComponent = (mapRolesModel: MapRolesModel,translations:any) => {
  return <MapRoles key={Math.random()} mapRolesModel={mapRolesModel} translations={translations} />;
};
export const MapRoles = (props: MapRolesModelProps) => {
  const classes = makeStyles(KagamiReportHeaderModelStyle)();
  const [disable, setDisable] = React.useState(true);
  props.mapRolesModel.setDisableSave = setDisable;
  
  
  return (
    <>
      <div className={classes.root}>
      <p className={classes.ReportsHeaderName}>{translateToLocalistaion(props.translations, "Manage Report Roles")}</p>
        <table>
          <thead>
            <tr>
              <th>{translateToLocalistaion(props.translations, "Report Name")}</th>
              <th>{translateToLocalistaion(props.translations, "Map Roles")}</th>
              <th>{translateToLocalistaion(props.translations, "Mapped Roles")}</th>
            </tr>
          </thead>
          <tbody>
          <LoadMaprolesData mapRolesModel={props.mapRolesModel} translations={props.translations}/>
          </tbody>
        </table>

        <Button
          variant="contained"
          color="success"
          style={{ position: 'fixed', bottom: '0px', right: '57px', width: '125px' }}
          disabled={disable}
          onClick={() => {
            mapRolesOnSave(props.mapRolesModel,setDisable);
          }}
        >
          {translateToLocalistaion(props.translations, "Save")}
        </Button>
      </div>
    </>
  );
};


const LoadMaprolesData = (props:MapRolesModelProps)=>{
  let getMapRolesData:any[]=[]
  let mapRolesModel = props.mapRolesModel;
  const mapRoleState = useMapRolesState(createState(mapRolesModel));
  mapRolesModel.state = mapRoleState;
  useEffect(() => {
    setAllRolesAndMapReportRolesData(mapRolesModel);
  }, []);

    if (!mapRoleState.isDataLoaded) {
      return <CircularProgress sx={{ marginTop: '4%', marginLeft: '140%' }} color="secondary" />;
    }else{
       getMapRolesData = mapRolesModel.dbReportList.map((reportConfigSummary: any, index:number) => {
        let selectedData: any[] = [];
        let reportId: string = reportConfigSummary.id;
        let mappedReportData: any = mapRolesModel.roleMappedWithReportId.filter(
          (data: any) => data.reportId === reportId
        )[0];
        if (mappedReportData !== undefined) {
          selectedData = mappedReportData.rolesMapping.map((roleName: any) => {
            return {
              reportId: reportId,
              roleId: roleName
            };
          });
        }
      let mapRolesViewModel = new MapRolesViewModel();
      mapRolesViewModel.reportId = reportId;
      mapRolesViewModel.name = reportConfigSummary.name;
      mapRolesViewModel.options = mapRolesModel.dbRolesList;
      mapRolesViewModel.selectedData = selectedData;
      mapRolesViewModel.allSelectedData = mapRolesModel.allSelectedData;
      // mapRolesViewModel.roleMappedWithReportId = mapRolesModel.roleMappedWithReportId;
      return <MapRolesView key={`map-roles-report-${index}`} mapRolesViewModel={mapRolesViewModel} translations={props.translations}/>;
    });
    return <>{getMapRolesData}</>
    }
  }