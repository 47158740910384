import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';

export class KagamiLoginModel {
  userName: string;
  password: string;
  userId:string;
  loginErrorMessage: string;
  forgotPassword: boolean;
  forgotPasswordSucessMessage:any;
  forgotPasswordErrorMessage:any;
  signupNeeded: boolean;
  isUserNameHasError: boolean;
  isPasswordHasError: boolean;
  isLogoVisible: boolean;
  logodata: any;
  state: any;
  enableButton:boolean;
  enableForgotPasswordButton:boolean;
  noDeviceFoundMsg: string;
  constructor() {
    this.userName = '';
    this.password = '';
    this.userId='';
    this.loginErrorMessage = '';
    this.forgotPasswordSucessMessage=null;
    this.forgotPasswordErrorMessage=null;
    this.forgotPassword = false;
    this.signupNeeded = false;
    this.isUserNameHasError = false;
    this.isPasswordHasError = false;
    this.isLogoVisible = false;
    this.enableButton=false;
    this.enableForgotPasswordButton=false;
    this.noDeviceFoundMsg = ""

}
}
export interface KagamiLoginProps {
  kagamiLoginModel: KagamiLoginModel;
}

export const wrapLoginState = (state: State<KagamiLoginModel>) => {
  const loginState = state;

  return {
    get userName() {
      return loginState.userName.get();
    },

    setUserName(name: string) {
      loginState.userName.set(name);
    },
    get userId() {
      return loginState.userId.get();
    },

    setUserId(name: string) {
      loginState.userId.set(name);
    },

    get passWord() {
      return loginState.password.get();
    },

    setPassword(pwd: string) {
      loginState.password.set(pwd);
    },

    getLoginErrorMessage() {
      return loginState.loginErrorMessage.get();
    },

    get getforgotPasswordSucessMessage(){
      return loginState.forgotPasswordSucessMessage.get();
    },

    setforgotPasswordSucessMessage(message:any){
      loginState.forgotPasswordSucessMessage.set(message);
    },

    get getforgotPasswordErrorMessage(){
      return loginState.forgotPasswordErrorMessage.get();
    },

    setforgotPasswordErrorMessage(message:any){
      loginState.forgotPasswordErrorMessage.set(message);
    },

    setLoginErrorMessage(errorMessage: string) {
      loginState.loginErrorMessage.set(errorMessage);
    },
    getIsUserNameHasError() {
      return loginState.isUserNameHasError.get();
    },
    setIsUserNameHasError(isUserNameHasError:boolean) {
      return loginState.isUserNameHasError.set(isUserNameHasError);
    },

    getIsPasswordHasError() {
      return loginState.isPasswordHasError.get();
    },
    setIsPasswordHasError(ispasswordhaserror : boolean) {
      return loginState.isPasswordHasError.set(ispasswordhaserror);
    },

    getForgotPassword() {
      return loginState.forgotPassword.get();
    },

    setForgotPassword(forgotpwd: boolean) {
      loginState.forgotPassword.set(forgotpwd);
    },

    isSignupNeeded() {
      loginState.signupNeeded.get();
    },

    setSignupNeeded(signupNeeded: boolean) {
      loginState.signupNeeded.set(signupNeeded);
    },

    setLogoVisible(isLogoVisible: boolean){
      loginState.isLogoVisible.set(isLogoVisible);
    },

    get isLogoVisible(){
      return loginState.isLogoVisible.get();
    },

    setLogodata(logodata: any){
      loginState.logodata.set(logodata);
    },

    get logodata(){
      return loginState.logodata.get();
    },
    getEnableButton(){
      return loginState.enableButton.get();
    },
    setEnableButton(value: boolean){
      loginState.enableButton.set(value)
    },
    getEnableForgotPasswordButton(){
      return loginState.enableForgotPasswordButton.get()
    },
    setEnableForgotPasswordButton(value:boolean){
      loginState.enableForgotPasswordButton.set(value)
    },
    
    getNoDeviceFoundMsg(){
      return loginState.noDeviceFoundMsg.get()
    },
    setNoDeviceFoundMsg(noDeviceFoundMsg:string){
      loginState.noDeviceFoundMsg.set(noDeviceFoundMsg)
    },

}
};
export const useLoginState = (state: State<KagamiLoginModel>) => {
  const loginState = wrapLoginState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.LOGIN_STATE, loginState);
  return loginState;
};
