import makeStyles from '@mui/styles/makeStyles';
import { DashboardType, KagamiDashboardModel } from '../model/KagamiDashboardModel';

import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import InfoIcon from '@mui/icons-material/Info';
import {
  buildDashboardNotificationSections,
} from './NotificationBuilder';
import { KagamiAccordionModel } from '../../../../../../../../components/accordion/model/KagamiAccordionModel';
import { buildAccordion } from '../../../../../../../../components/accordion/controller/KagamiAccordionController';
import { buildDashboardFavouriteSection } from './FavouriteBuilder';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { DashboardGrid } from '../ui/KagamiDashboard';
import { CircularProgress } from '@mui/material';

export function buildDashboardSection(dashboardModel: KagamiDashboardModel) {
  const favouritesData = dashboardModel.state.getfavouritesData;
  let dashboardComponents: any[] = [];
  if (dashboardModel.dashboardType === DashboardType.REPORTS) {
    const dashboardFavSection = buildDashboardFavouriteSection(favouritesData); 
    const reportAndFavouriteSection = [...dashboardModel.reportDataUx];
    reportAndFavouriteSection.push(dashboardFavSection)
    // dashboardComponents.push(reportAndFavouriteSection)
    dashboardComponents.push(<DashboardGrid key={'dashboard-all-layout'} component={reportAndFavouriteSection} dashboardModel={dashboardModel}/>)
  } else if (dashboardModel.dashboardType === DashboardType.NOTIFICATIONS) {
    if( CommonUtils.isNotEmpty(dashboardModel.notificationData)) {
      dashboardComponents.push(buildDashboardNotificationSections(dashboardModel));
    } else {
      const dashInfoAndFavSection = [];
      const dashboardInfoNotification = buildInfoNotificationSection(dashboardModel)
      dashInfoAndFavSection.push(dashboardInfoNotification)
      if(CommonUtils.isNotEmpty(favouritesData)){
        const dashboardFavSection = buildDashboardFavouriteSection(favouritesData);
        dashInfoAndFavSection.push(dashboardFavSection)
      }
      // dashboardComponents.push(dashInfoAndFavSection);
      dashboardComponents.push(<DashboardGrid key={'dashboard-info-and-fav-layout'} component={dashInfoAndFavSection} dashboardModel={dashboardModel}/>);
    }
  } else {
    //dummy accordion till dashboard response comes
    const dashInfoNotiAndFavSection = [];
    const dashboardInfoNotification = buildInfoNotificationSection(dashboardModel)
    dashInfoNotiAndFavSection.push(dashboardInfoNotification);
    if(CommonUtils.isNotEmpty(favouritesData)){
      const dashboardFavSection = buildDashboardFavouriteSection(favouritesData);
      dashInfoNotiAndFavSection.push(dashboardFavSection)
    }
    // dashboardComponents.push(dashInfoNotiAndFavSection);
    dashboardComponents.push(<DashboardGrid key={'dashboard-default-layout'} component={dashInfoNotiAndFavSection} dashboardModel={dashboardModel}/>);
  }
  return dashboardComponents;
}


export const buildInfoNotificationSection = (dashboardModel:any) => {
  return <DashboardInfoNotificationSection key={'dashboard-info-notification'} dashboardModel= {dashboardModel}/>;
};

const useStyles = makeStyles(KagamiDashboardStyle);
const DashboardInfoNotificationSection = (props:any) => {
  const {dashboardModel } = props
  const classes = useStyles();

  const title: JSX.Element = (
    <div>
      <div className={classes.dashboard_icon_header}>
        <InfoIcon className={classes.dashboard_icon}></InfoIcon>

        <>
          {' '}
          <span className={classes.dashboard_header}>INFO</span>
        </>
      </div>
    </div>
  );

  const active = true;

  const content: JSX.Element = dashboardModel.state.isDataLoaded ? <p>NO NOTIFICATIONS</p> : <div style={{display:'flex', alignItems:'center'}}><CircularProgress style={{height:'15px', width:'15px', marginRight:'5px'}}/> LOADING NOTIFICATIONS...!</div>;
  const accordionModel: KagamiAccordionModel = new KagamiAccordionModel(title, content, active);
  return <div className={`col_4 ${classes.dashboardNotificationSectionRoot}`}> {buildAccordion(accordionModel)}</div>;
};
