import { createState } from '@hookstate/core';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import compact from 'lodash/compact';
import uniqueId from 'lodash/uniqueId';
import { useState } from 'react';

import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { ReportConstant } from '../../../../view/ReportConstant/ReportConstant';
import { handlePopupButtonVisibility } from '../../../service/ReportFilterUtilService';
import { kagamiReportFilterStyle } from '../../../style/KagamiReportFilter';
import { ReportDateFilterModel, ReportDateFilterModelProps, useReportDateFilterModel } from '../model/DateFilterModel';
import { translateToLocalistaion } from '../../../../../process/service/PresentationService';

dayjs.extend(isSameOrBefore);

const useStyles = makeStyles(kagamiReportFilterStyle);

export const buildDateFilterView = (reportDateFilterModel: ReportDateFilterModel, translations: any) => {
  return <ReportDateFilter key={uniqueId()} reportDateFilterModel={reportDateFilterModel} translations={translations} />;
};

const ReportDateFilter = (props: ReportDateFilterModelProps) => {
  const classes = useStyles();
  let reportDateFilterModel = props.reportDateFilterModel;

  /**
   * dateFilterSetting requires backend configuration,
   * we need to pass an object in "filterSetting" that contains filterName as a key and 
   * {from:{days: {some Integer value}, time: "{selected time}"},to:{days: {some Integer value}, time: "{selected time}"}}
   * as a value, days can be ranges from {-Integer Value} to {+Integer value} and time will always be 24 hours format.
   * Currently the application which is using this feature is DCM.
   * with this feature we can set nth days back and forth from todays date.
   */
  const dateFilterSetting = reportDateFilterModel.reportFilterModel?.filterSetting[reportDateFilterModel.filterName] ?? {
    from: {
      days: 0,
      time: '00:00:00'
    },
    to: {
      days: 0,
      time: '23:59:59'
    }
  };

  
  const dateTimeFormats = {
    originalFormat: 'YYYY-MM-DD HH:mm:ss',
    zeroTimeFormat: `YYYY-MM-DD ${dateFilterSetting.from.time}` ?? 'YYYY-MM-DD 00:00:00',
    endTimeFormat: `YYYY-MM-DD ${dateFilterSetting.to.time}` ?? 'YYYY-MM-DD 23:59:59',
    displayFormat: 'DD-MM-YYYY'
  };

  let translateWords = props?.translations;

  let defaultDate = compact(
    reportDateFilterModel.reportFilterModel.selectedValue.get(reportDateFilterModel.filterName)?.split(',') ?? []
  );

  let splitDefaultDate: any = defaultDate.filter((val: any) => {
    return val !== 'NOPOP' && val !== 'undefined';
  });

  const initialDate: any = {
    start:
      splitDefaultDate.length > 0 && dayjs(splitDefaultDate[0]).isValid()
        ? dayjs(splitDefaultDate[0]).format(dateTimeFormats.originalFormat)
        : dayjs().add(dateFilterSetting.from.days, 'days').format(dateTimeFormats.zeroTimeFormat),
    end:
      splitDefaultDate.length > 1 && dayjs(splitDefaultDate[0]).isValid()
        ? dayjs(splitDefaultDate[1]).format(dateTimeFormats.originalFormat)
        : dayjs().add(dateFilterSetting.to.days, 'days').format(dateTimeFormats.endTimeFormat)
  };

  // Open calendar on click of textfield
  const dateFilterState = useReportDateFilterModel(createState(reportDateFilterModel));
  reportDateFilterModel.state = dateFilterState;

  const [fromDateValue, setFromDateValue] = useState<string | null>(dayjs(initialDate.start).format(dateTimeFormats.displayFormat));
  const [toDateValue, setToDateValue] = useState<string | null>(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));

  const [selectedRadio, setSelectedRadio] = useState<string | null>(null);
  if (
    reportDateFilterModel.filterRelationName === ReportConstant.Is_In_The_Range ||
    reportDateFilterModel.filterRelationName === ReportConstant.Is_Not_In_The_Range
  ) {
    reportDateFilterModel.reportFilterModel.selectedValue.set(
      reportDateFilterModel.filterName,
      initialDate.start + ',' + initialDate.end
    );

    return (
      <>
        <tr>
          <td style={{ color: '#007ecc' }}>
            {' '}
            {/* {reportDateFilterModel.filterName} */}
            {translateToLocalistaion(translateWords, reportDateFilterModel.filterName)}
            {!reportDateFilterModel.filterConfig.optional ? (
              <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
            ) : (
              <></>
            )}
          </td>
          {/* <td style={{ color: '#007ecc' }}> {reportDateFilterModel.filterRelationName}</td> */}
          <td style={{ color: '#007ecc' }}> {translateToLocalistaion(translateWords, reportDateFilterModel.filterRelationName)}</td>
          <td>
            <>
              <div className={classes.filter_date}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div>{translateToLocalistaion(translateWords, 'From')} :</div>
                  <DatePicker
                    // className= {classes.datePickerCustom}
                    views={['year', 'month' ,'day']}
                    PopperProps={{
                      sx: {
                        '.MuiCalendarOrClockPicker-root': {
                          'height': '250px',

                          '.MuiCalendarPicker-root': {
                            'maxHeight': '250px',
                            'overflow': 'hidden',
                            '.MuiYearPicker-root': {
                              maxHeight: '190px'
                            },
                            '.MuiDayPicker-header ': {
                              '.MuiDayPicker-weekDayLabel': {
                                height: '10px'
                              }
                            },
                            '.MuiPickersDay-root': {
                              height: '28px'
                            }
                          }
                        }
                      }
                    }}
                    open={dateFilterState.fromCalendar}
                    onClose={() => dateFilterState.setFromCalendar(false)}
                    maxDate={dayjs(toDateValue, dateTimeFormats.displayFormat)}
                    inputFormat={dateTimeFormats.displayFormat}
                    value={fromDateValue !== null ? dayjs(fromDateValue, dateTimeFormats.displayFormat) : null}
                    onChange={(newValue) => {
                      // reportDateFilterModel.reportFilterModel['selectedRadio'] = '';
                      if (newValue !== null) {
                        initialDate.start = `${dayjs(newValue).format(dateTimeFormats.zeroTimeFormat)}`;
                        setFromDateValue(dayjs(initialDate.start).format(dateTimeFormats.displayFormat));
                        reportDateFilterModel.isFromDateChangedManually = true;
                        reportDateFilterModel.reportFilterModel.selectedValue.set(
                          reportDateFilterModel.filterName,
                          initialDate.start + ',' + initialDate.end
                        );
                        if (initialDate.end !== 'Invalid Date')
                          reportDateFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(false);
                      } else {
                        initialDate.start = 'Invalid Date';
                        setFromDateValue(null);
                        reportDateFilterModel.isFromDateChangedManually = true;
                        reportDateFilterModel.reportFilterModel.selectedValue.set(
                          reportDateFilterModel.filterName,
                          'NOPOP'
                        );
                        if (!reportDateFilterModel.reportFilterModel.fromDashboard) {
                          handlePopupButtonVisibility(reportDateFilterModel);
                        }
                        // reportDateFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(true);
                      }
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          size="small"
                          style={{ width: '200px' }}
                          onClick={(e) => dateFilterState.setFromCalendar(true)}
                        />
                      );
                    }}
                  />
                  <div>{translateToLocalistaion(translateWords, 'To')} :</div>
                  <DatePicker
                    views={['year', 'month','day']}
                    // className= {classes.datePickerCustom}
                    PopperProps={{
                      sx: {
                        '.MuiCalendarOrClockPicker-root': {
                          'height': '250px',

                          '.MuiCalendarPicker-root': {
                            'maxHeight': '250px',
                            'overflow': 'hidden',
                            '.MuiYearPicker-root': {
                              maxHeight: '190px'
                            },
                            '.MuiPickersDay-root': {
                              height: '28px'
                            },
                            '.MuiDayPicker-header ': {
                              '.MuiDayPicker-weekDayLabel': {
                                height: '10px'
                              }
                            }
                          }
                        }
                      }
                    }}
                    open={dateFilterState.toCalendar}
                    onClose={() => dateFilterState.setToCalendar(false)}
                    minDate={dayjs(fromDateValue, dateTimeFormats.displayFormat)}
                    inputFormat={dateTimeFormats.displayFormat}
                    value={toDateValue !== null ? dayjs(toDateValue, dateTimeFormats.displayFormat) : null}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        initialDate.end = `${dayjs(newValue).format(dateTimeFormats.endTimeFormat)}`;
                        setToDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));
                        reportDateFilterModel.reportFilterModel.selectedValue.set(
                          reportDateFilterModel.filterName,
                          initialDate.start + ',' + initialDate.end
                        );
                        if (initialDate.start !== 'Invalid Date')
                          reportDateFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(false);
                      } else {
                        initialDate.end = 'Invalid Date';
                        setToDateValue(null);
                        reportDateFilterModel.reportFilterModel.selectedValue.set(
                          reportDateFilterModel.filterName,
                          'NOPOP'
                        );
                        if (!reportDateFilterModel.reportFilterModel.fromDashboard) {
                          handlePopupButtonVisibility(reportDateFilterModel);
                        }
                        // reportDateFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(true);
                      }
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          size="small"
                          style={{ width: '200px' }}
                          onClick={(e) => dateFilterState.setToCalendar(true)}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              <>
                <RadioGroup
                  row
                  name="select-date-radio-buttons-group"
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={reportDateFilterModel.reportFilterModel['selectedRadio'] ?? ''}
                  value={selectedRadio}
                  onClick={(event: any) => {
                    if (
                      (CommonUtils.isNotEmpty(event.target.value) && event.target.value === 'today') ||
                      event.target.value === 'week' ||
                      event.target.value === 'month' ||
                      event.target.value === 'year'
                    ) {
                      
                      setSelectedRadio(event.target.value);
                      let currentStartDate:any;
                      let currentEndDate:any;
                      if(reportDateFilterModel.reportFilterModel['selectedRadio'] &&
                       reportDateFilterModel.reportFilterModel['selectedRadio'] === event.target.value&&
                       !reportDateFilterModel.isFromDateChangedManually){
                        
                        currentStartDate = dayjs().startOf(event.target.value);
                        currentEndDate = dayjs();

                        initialDate.start = `${currentStartDate.format(dateTimeFormats.zeroTimeFormat)}`;
                        initialDate.end = `${currentEndDate.format(dateTimeFormats.endTimeFormat)}`;

                        setFromDateValue(dayjs(initialDate.start).format(dateTimeFormats.displayFormat));
                        setToDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));
                      }else{
                        if(event.target.value==="today"){
                        reportDateFilterModel.reportFilterModel['selectedRadio'] = event.target.value;
                        currentStartDate = dayjs(initialDate.start).startOf(event.target.value);
                        currentEndDate = dayjs();

                        initialDate.start = `${currentStartDate.format(dateTimeFormats.zeroTimeFormat)}`;
                        initialDate.end = `${currentEndDate.format(dateTimeFormats.endTimeFormat)}`;

                        setFromDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));
                        setToDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));

                        }else if(event.target.value==="month" || event.target.value==="week") {
                          reportDateFilterModel.reportFilterModel['selectedRadio'] = event.target.value;
                          currentStartDate = dayjs(initialDate.start).startOf(event.target.value);
                          currentEndDate = dayjs(initialDate.start).endOf(event.target.value);

                          initialDate.start = `${currentStartDate.format(dateTimeFormats.zeroTimeFormat)}`;
                          initialDate.end = `${currentEndDate.format(dateTimeFormats.endTimeFormat)}`;

                          setFromDateValue(dayjs(initialDate.start).format(dateTimeFormats.displayFormat));
                          setToDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));
                        }
                        else{
                          reportDateFilterModel.reportFilterModel['selectedRadio'] = event.target.value;
                          currentStartDate=dayjs(initialDate.start).startOf(event.target.value);
                          currentEndDate = reportDateFilterModel.isFromDateChangedManually
                          ? dayjs(initialDate.start).startOf(event.target.value)
                          : dayjs();
                          initialDate.start = `${currentStartDate.format(dateTimeFormats.zeroTimeFormat)}`;
                          initialDate.end = `${currentEndDate.format(dateTimeFormats.zeroTimeFormat)}`  ;

                          setFromDateValue(dayjs(initialDate.start).format(dateTimeFormats.displayFormat));
                          setToDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));
                          
                        }
                      }
                      
                      if (
                        event.target.value !== 'today' &&
                        currentEndDate.endOf(event.target.value).isSameOrBefore(dayjs())
                      ) {
                        initialDate.end = `${dayjs(initialDate.start)
                          .endOf(event.target.value)
                          .format(dateTimeFormats.endTimeFormat)}`;
                        setToDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));
                      } else {
                        initialDate.end = `${dayjs().format(dateTimeFormats.endTimeFormat)}`;
                        setToDateValue(dayjs(initialDate.end).format(dateTimeFormats.displayFormat));
                      }

                      reportDateFilterModel.reportFilterModel.selectedValue.set(
                        reportDateFilterModel.filterName,
                        initialDate.start + ',' + initialDate.end
                      );
                      if (!reportDateFilterModel.reportFilterModel.fromDashboard) {
                        handlePopupButtonVisibility(reportDateFilterModel);
                      }
                    }
                  }}
                >
                  <FormControlLabel value="today" control={<Radio />} label={translateToLocalistaion(translateWords, "Today")} />
                  <FormControlLabel value="week" control={<Radio />} label={translateToLocalistaion(translateWords, "Week")} />
                  <FormControlLabel value="month" control={<Radio />} label={translateToLocalistaion(translateWords, "Month")} />
                  <FormControlLabel value="year" control={<Radio />} label={translateToLocalistaion(translateWords, "Year")} />
                </RadioGroup>
              </>
            </>
          </td>
        </tr>
      </>
    );
  } else if (
    reportDateFilterModel.filterRelationName === ReportConstant.Is ||
    reportDateFilterModel.filterRelationName === ReportConstant.Is_Not ||
    reportDateFilterModel.filterRelationName === ReportConstant.Is_After ||
    reportDateFilterModel.filterRelationName === ReportConstant.Is_Before ||
    reportDateFilterModel.filterRelationName === ReportConstant.Is_After_Or_Equal ||
    reportDateFilterModel.filterRelationName === ReportConstant.Is_Before_Or_Equal ||
    reportDateFilterModel.filterRelationName === ReportConstant.Today
  ) {
    reportDateFilterModel.reportFilterModel.selectedValue.set(reportDateFilterModel.filterName, initialDate.start);
    return (
      <>
        <tr>
          <td style={{ color: '#007ecc' }}>
            {' '}
            {/* {reportDateFilterModel.filterName} */}
            {translateToLocalistaion(translateWords, reportDateFilterModel.filterName)}
            {!reportDateFilterModel.filterConfig.optional ? (
              <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
            ) : (
              <></>
            )}
          </td>
          {/* <td style={{ color: '#007ecc' }}> {reportDateFilterModel.filterRelationName}</td> */}
          <td style={{ color: '#007ecc' }}>{translateToLocalistaion(translateWords, reportDateFilterModel.filterRelationName)}</td>
          <td>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year', 'month' ,'day']}
                // className= {classes.datePickerCustom}
                PopperProps={{
                  sx: {
                    '.MuiCalendarOrClockPicker-root': {
                      'height': '250px',

                      '.MuiCalendarPicker-root': {
                        'maxHeight': '250px',
                        'overflow': 'hidden',
                        '.MuiYearPicker-root': {
                          maxHeight: '190px'
                        },
                        '.MuiPickersDay-root': {
                          height: '28px'
                        },
                        '.MuiDayPicker-header ': {
                          '.MuiDayPicker-weekDayLabel': {
                            height: '10px'
                          }
                        }
                      }
                    }
                  }
                }}
                open={dateFilterState.fromCalendar}
                onClose={() => dateFilterState.setFromCalendar(false)}
                value={fromDateValue !== null ? dayjs(fromDateValue, dateTimeFormats.displayFormat) : null}
                inputFormat={dateTimeFormats.displayFormat}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    initialDate.start = dayjs(newValue).format(dateTimeFormats.originalFormat);
                    setFromDateValue(dayjs(initialDate.start).format(dateTimeFormats.displayFormat));
                    reportDateFilterModel.reportFilterModel.selectedValue.set(
                      reportDateFilterModel.filterName,
                      initialDate.start
                    );
                    reportDateFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(false);
                  } else {
                    initialDate.start = 'Invalid Date';
                    reportDateFilterModel.reportFilterModel.selectedValue.set(reportDateFilterModel.filterName, 'NOPOP');
                    setFromDateValue(null);
                    if (!reportDateFilterModel.reportFilterModel.fromDashboard) {
                      handlePopupButtonVisibility(reportDateFilterModel);
                    }
                    // reportDateFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(true);
                  }
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: '200px' }}
                      onClick={(e) => dateFilterState.setFromCalendar(true)}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </td>
        </tr>
      </>
    );
  } else {
    return <></>;
  }
};
