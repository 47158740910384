import _ from 'lodash';

/** Stacked Bar Chart Multiple Grouped Y Axis */
export const makeStackedBarMultipleYAxisSeriesData = (reportConfig: any, dbData: any) => {
    if (reportConfig.horizontalAxis && reportConfig.horizontalAxis.length) {
        let firstXAttribute = reportConfig.verticalAxis[0];
        let secondXAttribute = reportConfig.verticalAxis[1];
        let firstXAtrributesDatakeys = _.uniq(_.map(dbData, firstXAttribute));
        let secondXAtrributesDatakeys = _.uniq(_.map(dbData, secondXAttribute));
        let groupBySecondAttributeData = _.groupBy(dbData, secondXAttribute)
        let yAtrributes = reportConfig.horizontalAxis;
        let seriesData: any = [];
        _.forEach(secondXAtrributesDatakeys, function (secondAttributeKey: any) {
            let secondXAttributeData = groupBySecondAttributeData[secondAttributeKey];
            _.forEach(yAtrributes, function (eachYAttribute) {
                let data: any = [];
                _.forEach(firstXAtrributesDatakeys, function (firstXAtrributesDataVal: any) {
                    let eachReac = _.filter(secondXAttributeData, function (secondXAttributeDataVal: any) {
                        return secondXAttributeDataVal[firstXAttribute] == firstXAtrributesDataVal;
                    })
                    if (eachReac && eachReac.length) {
                        let yVal = _.map(eachReac, eachYAttribute);
                        if (yVal && yVal.length) {
                            data.push(yVal[0]);
                        }
                    } else {
                        data.push(0)
                    }
                })
                let tempObj = {
                    name: secondAttributeKey + "-" + eachYAttribute,
                    stack: secondAttributeKey,
                    data: data
                }
                seriesData.push(tempObj)
            })
        });
        console.log("seriesData" + seriesData)
        return seriesData;
    }
    return []
}
/** END Stacked Bar Chart Multiple Grouped Y Axis */

/** Stacked Bar Chart Multiple Grouped x Axis */
export const getXAxisCategoriesForGroupedChart = (reportConfig: any, dbChartData: any) => {
    if (reportConfig?.verticalAxis?.length) {
        let firstXAttribute = reportConfig.verticalAxis[0];
        let secondXAttribute = reportConfig.verticalAxis[1];
        let dbData = makeReportData(dbChartData);
        let firstXAtrributesDatakeys = _.uniq(_.map(dbData, firstXAttribute));
        let xCategories: any = [];
        _.forEach(firstXAtrributesDatakeys, function (firstXAtrributesDataVal) {
            let categroryDataByFirstAttr = _.filter(dbData, function (rec) {
                return rec[firstXAttribute] == firstXAtrributesDataVal
            });
            let categoreis = _.uniq(_.map(categroryDataByFirstAttr, secondXAttribute));
            categoreis = _.filter(categoreis, el => !_.isNull(el));
            xCategories.push({
                name: firstXAtrributesDataVal,
                categories: categoreis,
            })
        })
        return xCategories
    }
    return [];
}

export const makeMultipleGroupedStackedBarChartData = (reportConfig: any, dbData: any) => {
    if (reportConfig.horizontalAxis && reportConfig.horizontalAxis.length) {
        let firstXAttribute = reportConfig.verticalAxis[0];
        let secondXAttribute = reportConfig.verticalAxis[1];
        let firstXAtrributesDatakeys = _.uniq(_.map(dbData, firstXAttribute));
        let groupBySecondAttributeData = _.groupBy(dbData, secondXAttribute)
        let yAtrributes = reportConfig.horizontalAxis;
        let seriesData: any = [];
        _.forEach(yAtrributes, function (eachYAttribute) {
            let data: any = [];
            _.forEach(firstXAtrributesDatakeys, function (firstXAtrributesDataVal) {
                let categroryDataByFirstAttr = _.filter(dbData, function (rec) {
                    return rec[firstXAttribute] == firstXAtrributesDataVal
                });
                let categoreis = _.uniq(_.map(categroryDataByFirstAttr, secondXAttribute));
                _.forEach(categoreis, function (secondAttributeKey) {
                    let secondXAttributeData = groupBySecondAttributeData[secondAttributeKey];
                    let eachReac = _.filter(secondXAttributeData, function (secondXAttributeDataVal: any) {
                        return secondXAttributeDataVal[firstXAttribute] == firstXAtrributesDataVal;
                    })
                    if (eachReac && eachReac.length) {
                        let yVal = _.map(eachReac, eachYAttribute);
                        if (yVal && yVal.length) {
                            data.push(yVal[0]);
                        }
                    } else {
                        data.push(0)
                    }
                });
            })
            let tempObj = {
                name: eachYAttribute,
                data: data
            }
            seriesData.push(tempObj)
        })
        console.log("seriesData" + seriesData)
        return seriesData;
    }
    return []
}
/* END*/


export const getYAxisLabels = (arr: any) => {
    return (arr && arr.length > 0) ? arr.join(",") : ''
}
export const getCagtegories = (colId: any, chartData: any) => {
    return _.map(chartData, function (row) {
        return row[colId];
    });
}

export const getSeriesData = (reportConfig: any, dbData: any) => {
    if ( reportConfig?.horizontalAxis?.length) {
        let firstVerticalAxis = reportConfig.verticalAxis[0];
        let secondVerticalAxis = reportConfig.verticalAxis[1];
        let fisrtVerticalDatakeys = _.uniq(_.map(dbData, firstVerticalAxis));
        let secondVerticalDataKeys = _.uniq(_.map(dbData, secondVerticalAxis));
        let firstHorizontalAxis = reportConfig.horizontalAxis[0];
        let seriesData:any = []
        _.forEach(secondVerticalDataKeys, function (secondVerticalDataVal) {
            let yaxisData :any = [];
            _.forEach(fisrtVerticalDatakeys, function (firstVerticalVal) {
                let groupByRec = _.filter(dbData, function (eachRec) {
                    return eachRec[firstVerticalAxis] == firstVerticalVal && eachRec[secondVerticalAxis] == secondVerticalDataVal;
                });
                let total:any = _.sumBy(groupByRec, function (verticaldata) {
                    return verticaldata[firstHorizontalAxis]
                })
                if (total == 0) {
                    total = '';
                }
                yaxisData.push(total)
            })
            seriesData.push({
                name: secondVerticalDataVal,
                data: yaxisData
            })
        })
        return seriesData;
    }

    return []
}

const makeReportData = function(chartData:any) {
    let dbData:any =[];
    if( chartData.table){
        let keys=_.map( chartData.table.cols,"label");
        chartData.table.rows.map(function (row:any) {
            let tempData:any = {};
            _.map(keys, function (key, i) {
                tempData[key] = row.c[i].v;
            });
            dbData.push(tempData);
        });
   }
    return dbData;
}   
