import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { CircularProgress } from "@mui/material";
import {
  KagamiLoaderModel,
  LoaderProps,
  useKagamiLoaderState,
} from "../model/KagamiLoaderModel";
import { createState } from "@hookstate/core";
import { Backdrop } from "@mui/material";

export const buildKagamiLoader = (loaderModel: KagamiLoaderModel) => {
  return <KagamiLoader loaderModel={loaderModel} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      backgroundColor: "transparent !important",
      zIndex: 1301,
      color: theme.palette.common.white,
      flexDirection: "column",
      cursor: "not-allowed",
    },
    invisibleLoader: {
      backgroundColor: "transparent !important",
      zIndex: 1301,
    },
    loaderCircle: {
      color: theme.palette.secondary.main,
    },
  })
);

export const KagamiLoader = (props: LoaderProps) => {
  let loaderState = useKagamiLoaderState(createState(props.loaderModel));
  props.loaderModel.state = loaderState;

  return <FullScreenLoader loaderModel={props.loaderModel} />;
};

const FullScreenLoader = (props: LoaderProps) => {
  const classes = useStyles();

  return (
    <>
      {props.loaderModel.state.loadingStatus ? (
        <Backdrop
          className={classes.backdrop}
          open={props.loaderModel.state.loadingStatus}
        >
          <CircularProgress className={classes.loaderCircle} />
          <br />
        </Backdrop>
      ) : (
        <></>
      )}
    </>
  );
};

const InvisibleLoader = (props: LoaderProps) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.invisibleLoader}
      open={props.loaderModel.state.loadingStatus}
    ></Backdrop>
  );
};

export const Loader = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.loaderCircle} />;
};

export const PageLoader = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress className={classes.loaderCircle} />
      <br />
    </Backdrop>
  );
};
