import { useEffect } from "react";
import { buildEmbedTrigger } from "../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/controller/KagamiEmbedTriggerController"
import { KagamiEmbedTriggerModel } from "../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/model/KagamiEmbedTriggerModel";

export default (props: any) => {
    let deleteTriggerModel : KagamiEmbedTriggerModel = props.deleteTrigger
    deleteTriggerModel.embeddedData  = props.data;
    useEffect(()=>{
        deleteTriggerModel.addRecordIdToPresentationModel = addRecordIdToPresentationModel;
    });

    const addRecordIdToPresentationModel = () => {
        deleteTriggerModel.embeddedFormPresentationModel.formDataIdentifier = props.data.id
    }
    return <div>{buildEmbedTrigger(deleteTriggerModel)}</div>
}