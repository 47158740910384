import { KagamiEmbeddedFormPresentationModel } from '../../../../../model/KagamiEmbeddedFormPresentationModel';

export class KagamiEmbeddedAccordionPresentationModel extends KagamiEmbeddedFormPresentationModel {
  constructor(
    onStartData: any,
    mainEntityId: any,
    formEntityId: string,
    formHeader: string,
    processName: string,
    processStepName: string,
    uiTemplate: string,
    initiallyVisible : boolean,
    initiallyReadOnly : boolean,
    isDynamicForm : boolean,
  ) {
    super(onStartData, mainEntityId, formEntityId, formHeader, processName, processStepName, uiTemplate,initiallyVisible,initiallyReadOnly,isDynamicForm);
  }
}

export interface KagamiEmbedddedAccordionPresentationProps {
  embeddedAccordionPresentationModel: KagamiEmbeddedAccordionPresentationModel;
}

export interface KagamiEmbedAccordionProps {
  embeddedAccordionPresentationModel: KagamiEmbeddedAccordionPresentationModel;
  classes: any;
  readOnly :boolean
}
