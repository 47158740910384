import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles'; 

export const TriggerAlertStyle = (theme: Theme) =>
  createStyles({
    modalRoot: {
      '& .MuiDialog-container ' : {
        height: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }
    },
    modal: {
      
      // borderRadius: theme.shape.borderRadius,
      // overflow: 'visible',
      // maxHeight: '70%',
      // width: '100%'
    },
    modalHeader: {
      borderBottom: '1px solid #ccc',
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      minHeight: theme.spacing(1.1),
      cursor: 'move',
      textAlign: 'center',
      color: theme.palette.secondary.main,
      // display :' flex',
      // justifyContent: 'space-between',
        },

    modalTitle: {
      margin: '0',
      lineHeight: '1.5',
      textAlign: 'center'
    },
    modalTitle_for_error: {
      margin: '0',
      lineHeight: '1.5'
    },
     
   
    modalBody: {
      // paddingTop: `${theme.spacing(3)} !important`,
      // paddingRight: theme.spacing(3),
      // paddingBottom: theme.spacing(2),
      // paddingLeft: theme.spacing(3),
      padding: `${theme.spacing(2)} !important`,
      position: 'relative',
      wordBreak: 'break-all',
      Width: '100%',
      textAlign: 'center',
      float: 'left',
      color: theme.palette.primary.main,
      background: theme.palette.background.paper,
      fontSize: theme.spacing(2),
      '& .hide-buttons-from-desktop-report-container': {
        display: 'none'
      }
    },
    modalHeaderClose:{
        // marginTop:"1px",
        // color:"#3a3b49",
        // fontSize:"2.2em",
        // fontWeight:"600",
        // marginLeft:"50px",
        // opacity:"1"
      },
    modalFooter: {
      padding: theme.spacing(0),
      textAlign: 'center',
      paddingTop: 0,
      margin: 0,
      // background: theme.palette.info.light,
      boxShadow: theme.shadows[1],
      borderTop: '0.5px solid #ccc'
    },
    modalFooterCenter: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },

    
    model_button: {
      margin: theme.spacing(0.5),
      color: '#fff',
      lineHeight:'1.75 !important',
      padding : '0px 16px',
      borderRadius : '4px',
      fontWeight:'600',
      backgroundColor: theme.palette.secondary.main,
      // background: theme.palette.secondary.main,
      // borderColor: theme.palette.primary.dark,
      '&:hover':{
        backgroundColor: '#006e7fdd',
        
      }
    },
    model_buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(0.5),
      backgroundColor:' #fff',
      // color:theme.palette.secondary.main,
      // border:  '1px solid',
      // width: "70px",
      borderColor: theme.palette.secondary.main,
      fontWeight:'600',
    },
    model_icon_error: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    model_button_disabled: {
      backgroundColor: 'grey'
    },
    paperFullWidth: {
      // overflowY: 'visible'
    },
    dialogContentRoot: {
      overflowY: 'visible'
    }
  });
