import {getStaticFilterData,getStockLedgerReportData} from '../../../../service/KagamiReportService';
import {getReportType,handleStaticReportDates,getSelectedCurrency} from '../../../../service/ReportUtilService';
import extend from 'lodash/extend';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
export async function getDBStaticFilterData(stockLedgerReportController: any) {
    let reportType =  getReportType(stockLedgerReportController.reportConfig);
    return await getStaticFilterData(reportType);
}
export const makeReport = (stockLedgerReportController: any,inputReportConfig:any) => {
        let defaultConfig ={
          autoGroupColumnDef: {  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
          columnDefs: [],
          defaultColDef: { flex :1,sortable: true,minWidth: 170,filter: true,resizable: true,editable: false },
          detailCellRendererParams: {
            detailGridOptions: {
              columnDefs: [  ],
              defaultColDef: {
                flex: 1,
              },
            },
            getDetailRowData: (params:any) => {
              params.successCallback(params.data.nestedFinanceRecord);
            },
          },
          detailRowHeight: 190,
          groupDefaultExpanded: -1,//expand everything by default
          groupHideOpenParents:true,
          groupIncludeTotalFooter: false,
          getRowStyle: (params:any) => {
            if (params?.node?.rowPinned) {
                return {'font-weight': 'bold'}
            }
          },
          getRowHeight :(params: any) => 30,
          rowHeight:34,
          icons: {
            groupExpanded:'<div class="kgm-minus-icon radius"></div>'
          },
          masterDetail: true,
          onFirstDataRendered: (params:any) => {
            setTimeout(function () {
              params.api.getDisplayedRowAtIndex(1).setExpanded(true);
            }, 0);
          },
          pivotMode : false,
          pagination: true,
          paginationPageSize: 50,
          pinnedBottomRowData: [],
          rowData:[],
          suppressAggFuncInHeader: true, //hide aggregate name in a row 
        };
        let userConfig = prapareUserConfigForReport(stockLedgerReportController,defaultConfig);  
        let reportConfig = extend(true, {}, defaultConfig, userConfig);
        return reportConfig;

} 


function prapareUserConfigForReport (listReportController: any,defaultConfig:any) {
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    let columnDefs: any = [];
    columnDefs =[
       {headerName: 'Date', field: 'date' },
       {headerName: 'Type', field: 'type'},
       {headerName: 'Quantity', field: 'runningQty'},
       {headerName: 'Qty Value', field: 'runningValue'},
       {headerName: 'Closed Qty', field: 'closingQty'},
       {headerName: 'Closing Value', field: 'closingValue'},
      ]
    userConfig['columnDefs'] = columnDefs;
    return userConfig;
   }
    export function makeLedgerData( reportModel:any,dbReportData: any) {
      let currency = getSelectedCurrency(reportModel.reportConfig);
      return getReportData(cloneDeep(dbReportData), currency) || [];
    }
    export const getData = async (reportModel :any,inputReportConfig: any) => {

      if((!inputReportConfig.state || inputReportConfig.state.length == 0 ) && (reportModel?.reportData?.staticFilterData?.data?.state)){
        inputReportConfig['state'] = "NOPOP";
      }
      if((!inputReportConfig.projectPlan || inputReportConfig.projectPlan.length == 0) && (reportModel?.reportData?.staticFilterData?.data?.projectPlan)){
        inputReportConfig['projectPlan'] = "NOPOP";
      }
    handleStaticReportDates(inputReportConfig);
    // let res = await getLedgerReportData(inputReportConfig);
    var importQueryParam = '';
    importQueryParam = importQueryParam.concat("itemName="+inputReportConfig.selectedValue+ '&');
    importQueryParam = importQueryParam.concat("fromdate="+ inputReportConfig.leftPanel.startDate+ '&');
    importQueryParam = importQueryParam.concat("todate="+ inputReportConfig.leftPanel.endDate+ '&');
    importQueryParam = importQueryParam.concat("suborgName="+ inputReportConfig.subOrganization[0]);
    let res = await getStockLedgerReportData(importQueryParam);
    if (res?.data) {
        return res.data['financeReportRow'];
    }
    return [];
    };

  const customCreditOrDebitCellFormatter =  ( cellvalue:any,currency:any) => {
    if(cellvalue){
     let locale  = (currency && currency =='INR') ? 'en-IN' :'en-US';
     let changedVal =parseFloat(cellvalue).toLocaleString(locale);
     if(cellvalue.split(".").length>0 && cellvalue.split(".")[1]=="00"){
        changedVal +=".00";
     }
     return changedVal;
    }
    return "";
  }

  const getReportData = (financeReportRow:any,currency:any) => {
    forEach(financeReportRow, function (ledgerRow) {
      if (ledgerRow.credit) {
        ledgerRow.credit = customCreditOrDebitCellFormatter(ledgerRow.credit,currency);
      }
      if (ledgerRow.debit) {
        ledgerRow.debit = customCreditOrDebitCellFormatter(ledgerRow.debit,currency);
      }
      if (ledgerRow.balance) {
          let withCommas = customCreditOrDebitCellFormatter(ledgerRow.balance,currency);
          ledgerRow.balance = withCommas;
          if(ledgerRow.balanceType){
            ledgerRow.balance = withCommas +" "+ledgerRow.balanceType;
          }
      }
      if (ledgerRow.date) {
            ledgerRow.date = ledgerRow.date.split(" ")[0];
      }
    })
    return financeReportRow || [];
  }

  export function makeFooterRowData (dbReportData: any) {
    let footerRows = [
      { runningValue : 'Opening Balance :','closingQty':displayOpeningBalance(dbReportData)},
      { runningValue : 'Closing Value : ','closingQty':displayClosingBalance(dbReportData)}, 
    ];
    return footerRows;
  }
/* FOR FOOTER */
const displayOpeningBalance = (financeReportRow:any) => {
  if(financeReportRow && financeReportRow.length > 0){
      let value= financeReportRow[0];
      return customCreditOrDebitCellFormatter(value.closingValue,'')
  }   
  return "";
}
 const displayClosingBalance = (financeReportRow:any ) => {
  if(financeReportRow && financeReportRow.length > 0){
    var value= financeReportRow[financeReportRow.length-1];
    return customCreditOrDebitCellFormatter(value.closingValue ,"");
  }   
  return "";
}
/* END  FOR FOOTER */
