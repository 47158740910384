import { State, useState } from "@hookstate/core";
import { KagamiReportModel } from "../../../../model/KagamiReportModel";

export class GaugeReportModel extends KagamiReportModel {
    reportData:any;
    isDataLoaded:boolean;
    state:any;
    constructor(reportContent: any) {
        super()
        this.reportData = reportContent;
        this.isDataLoaded = false;
      }
}

export interface GaugeReportModelProps{
    gaugeReportModel:GaugeReportModel
}

export const useGaugeReportModelState = (state:State<GaugeReportModel>) => {

    let gaugeReportState = useState(state);

    return {
        reportConfig() {
          gaugeReportState.reportData.get();
        },
        setReportConfig(reportContent: any) {
          gaugeReportState.reportData.set(reportContent);
        },
    
        get isDataLoaded(){
          return gaugeReportState.isDataLoaded.get()
        },
    
        setDataLoaded(isDataLoaded:boolean){
          gaugeReportState.isDataLoaded.set(isDataLoaded);
        }
      };
}