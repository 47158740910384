import { createState } from '@hookstate/core';
import { Component } from 'react';
import {
  KagamiCrossTabViewModel,
  KagamiCrossTabViewModelProps,
  useCrossTabListState
} from '../model/KagamiCrossTabViewModel';
import { makePivot } from '../service/KagamiCrossTabViewService';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiCrossTabStyle } from '../../crosstab/styles/CrossTabStyles';
import {  AgGridReact } from 'ag-grid-react'; 
import _ from 'lodash';
import { PageLoader } from '../../../../../../../../../../../../components/loader/ui/KagamiLoader';


export const BuildCrossTabView = (kagamiCrossTabViewModel: KagamiCrossTabViewModel) => {
  return (
    <KagamiCrossTabView key="cross-tab-view" kagamiCrossTabViewModel={kagamiCrossTabViewModel}></KagamiCrossTabView>
  );
};

export class KagamiCrossTabView extends Component<KagamiCrossTabViewModelProps> {
  render() {
    return (
      <CrossTabViewList
        key="crossTab-view-list"
        kagamiCrossTabViewModel={this.props.kagamiCrossTabViewModel}
      ></CrossTabViewList>
    );
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'center',
      margin: theme.spacing(2),
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      alignItems: 'flex-strech',
      width: '75%',
      marginTop: theme.spacing(4),
      borderRadius: theme.spacing(1),
      height: '100%'
    },
  })
);



export const CrossTabViewList = (props: KagamiCrossTabViewModelProps) => {
  const useStyles = makeStyles(KagamiCrossTabStyle);
  const classes = useStyles();
  let kagamiCrossTabViewModel = props.kagamiCrossTabViewModel;
  let crossTabState = useCrossTabListState(createState(kagamiCrossTabViewModel));
  kagamiCrossTabViewModel.state = crossTabState;
  
  

  // TODO REFACTORING
  const pivotConfig :any = makePivot(kagamiCrossTabViewModel);


  if (!crossTabState.getListLoaded()) {
    return <PageLoader />;
  } else {   
    return (
      // <div className={classes.root}>
        <div className="ag-theme-alpine kgm-cross-tab-grid" style={{ height: '450px', width:'100%' }}>
        <AgGridReact 
          rowData={pivotConfig.rowData}
          suppressDragLeaveHidesColumns={true}
          columnDefs={pivotConfig.columnDefs}
          groupDefaultExpanded = {pivotConfig.groupDefaultExpanded}
          groupHideOpenParents = {pivotConfig.groupHideOpenParents}
          sideBar = {pivotConfig.sideBar }
          suppressExpandablePivotGroups = {pivotConfig.suppressExpandablePivotGroups}
          suppressAggFuncInHeader={pivotConfig.suppressAggFuncInHeader}
          // suppressColumnVirtualisation={true}
          // suppressRowVirtualisation ={true}
          // pivotRowTotals =  {'before'}
          // pivotColumnGroupTotals = {'before'}
          getRowHeight = {pivotConfig.getRowHeight}
          pivotMode = {pivotConfig.pivotMode}
          rowGroupPanelShow = {pivotConfig.rowGroupPanelShow}
          pivotPanelShow =  {pivotConfig.pivotPanelShow}
          defaultColDef={pivotConfig.defaultColDef}
         ></AgGridReact>
        </div>

      // </div>
    );
  }



};
