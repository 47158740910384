import { State, useState } from '@hookstate/core';

export class SchedulerFormModel {
  userList: any;
  allROles: any;
  reportList: any;
  initialFormData: InitialFormData;
  isDataLoaded: boolean;
  state: any;
  constructor() {
    this.initialFormData = {
      '@type': 'trigger',
      'metaData': {
        emailBody: {},
        emailSub: {},
        fileType: '',
        emailUser: {},
        repeatEvery: {},
        startTime: {},
        serverDetails: {
          server: '',
          port: '',
          userName: '',
          password: '',
          path: ''
        },
        type: 'hourly'
      },
      'schedule': {
        param: '',
        schedulerName: ''
      },
      'reportsList': []
    };
    this.isDataLoaded = false;
  }
}

export interface SchedulerFormModelProps {
  schedulerFormModel: SchedulerFormModel;
  translations?:any;
}

export const useScheduleFormState = (state: State<SchedulerFormModel>) => {
  const schedulerFormState = useState(state);
  return {
    get isDataLoaded() {
      return schedulerFormState.isDataLoaded.get();
    },
    setDataLoaded(isDataLoaded: boolean) {
      schedulerFormState.isDataLoaded.set(isDataLoaded);
    }
  };
};

export class TimeZone {
  static Hourly = 'Hourly';
  static Daily = 'Daily';
  static Weekly = 'Weekly';
  static Monthly = 'Monthly';
  static Yearly = 'Yearly';
}

type MetaDataType = 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly';
type MetaDataFileType = '' | 'pdf' | 'excel';

type StartTime = {
  hours: number;
  minutes: number;
};

type ServerDetails = {
  server: string;
  port: string;
  userName: string;
  password: string;
  path: string;
};

type MetaData = {
  startTime: any | StartTime;
  repeatEvery: any;
  type: MetaDataType;
  emailSub: any;
  emailBody: any;
  emailUser: any;
  fileType: MetaDataFileType;
  role?: string[];
  serverDetails: ServerDetails;
};

type Scheduler = {
  param: string;
  schedulerName: string;
};

export type InitialFormData = {
  '@type': 'trigger';
  'enableEmail'?: boolean;
  'enableFTP'?: boolean;
  'metaData': MetaData;
  'repeteCount'?: number;
  'schedule': Scheduler;
  'daysOfWeek'?: any;
  'reportsList': string[];
};
