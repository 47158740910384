import { State, useState } from '@hookstate/core';
import { feedbackType, getPivotOnChangeAttr, isFeedBackControl } from '../../../../service/PresentationService';
import { KagamiControlModel } from '../../model/kagamiControlModel';

export class KagamiNumberModel extends KagamiControlModel {
  forNumber: any;
  createState: any;
  uiSettings: any;
  isCommaSeparated: boolean;
  isHavedefaultValue: boolean;
  iscurrencySymbol: boolean;
  isconversion: boolean;
  isPrefixtype: boolean;
  hasExternalData: boolean;
  weighBridgeValue: any;
  intervalId: any;
  extDataEnabled: boolean;
  isFeedBackControl: boolean;
  feedBackType: any;
  pivotOnChangeAttr : string;
  constructor(
    controlIdentifier: string,
    attributeName: string,
    attributePresentation: any,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any,
    onChangeRequired: boolean,
    validation: any,
    pickAttribute: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    state: any,
    createState: State<KagamiNumberModel> | null,
    policyMap: any,
    presentationPolicy: any,
    businessPolicy: any,
    initiallyReadOnly: boolean,
    initiallyVisible: boolean,
    uiSettings: any,
    formDataIdentifier: string,
    isGridField: boolean,
    isEmbddedField: boolean,
    hasExternalData: boolean,
    $$attrSettings?: any
  ) {
    super(
      controlIdentifier,
      attributeName,

      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField,
      $$attrSettings
    );
    this.uiSettings = uiSettings;
    this.attributePresentation = attributePresentation;
    this.previouseControlValue = controlType === 'number' ? controlValue : controlValue || '';
    this.isCommaSeparated = false;
    this.isHavedefaultValue = false;
    this.iscurrencySymbol = false;
    this.isconversion = false;
    this.isPrefixtype = false;
    this.hasExternalData = hasExternalData;
    this.weighBridgeValue = 0;
    this.extDataEnabled = false;
    this.isFeedBackControl = isFeedBackControl(uiSettings);
    this.feedBackType = feedbackType(uiSettings);
    this.pivotOnChangeAttr = getPivotOnChangeAttr(attributePresentation)
  }
}

export interface KagamiNumberFieldProps {
  kagamiNumberModel: KagamiNumberModel;
}

export const wrapKagamiNumberModelState = (state: State<KagamiNumberModel>) => {
  let controlState = state;

  return {
    get isMandatory() {
      return controlState.mandatory.get();
    },

    setMandatory(isMandatory: boolean) {
      controlState.mandatory.set(isMandatory);
    },

    get isVisible() {
      return controlState.visible.get();
    },

    setVisible(isVisible: boolean) {
      controlState.visible.set(isVisible);
    },

    get isReadOnly() {
      return controlState.readonly.get();
    },

    setReadonly(isReadonly: boolean) {
      controlState.readonly.set(isReadonly);
    },

    get errorMessage() {
      return controlState.errorMessage.get();
    },

    setErrorMessage(value: string) {
      controlState.errorMessage.set(value);
    },

    get controlValue() {
      return controlState.controlValue.get();
    },

    setControlValue(value: any) {
      controlState.controlValue.set(value);
    },

    get atttributeSettings() {
      return controlState.$$attrSettings.get();
    },

    setAtttributeSettings(value: boolean) {
      controlState.$$attrSettings.set(value);
    },

    get weighBridgeValue() {
      return controlState.weighBridgeValue.get();
    },

    setWeighBridgeValue(value: any) {
      controlState.weighBridgeValue.set(value);
    },
    get extDataEnabled() {
      return controlState.extDataEnabled.get();
    },
    setExtDataEnabled(enabled: boolean) {
      controlState.extDataEnabled.set(enabled);
    }
  };
};

export const useKagamiNumberModelState = (state: State<KagamiNumberModel>) =>
  wrapKagamiNumberModelState(useState(state));

export const accessKagamiNumberModelState = (state: State<KagamiNumberModel>) => wrapKagamiNumberModelState(state);
