import { createTheme, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiProfilePopoverStyles = (theme: Theme) =>
  createStyles({
    userInfo_banner: {
      padding: '10px',
      background: theme.palette.primary.dark,
      color: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2)
    },
    profile_pic: {
      'position': 'relative',
      'width': 'fit-content',
      'borderRadius': '50%',
      'background': theme.palette.common.white,
      border: '2px solid #fff',
      boxSizng: 'border-box',
      '& div:last-child': {
        display: 'none'
      },
      '&:hover': {
        '& div:last-child': {
          display: 'block'
        }
      },
      '& img': {
        objectFit: 'fill'
      }
    },
    editIcon: {
      position: 'absolute',
      right: '-2px',
      top: '50%',
      height: '25px',
      width: '25px',
      background: theme.palette.common.white,
      border: '1px solid #000',
      borderRadius: '50%',
      color: theme.palette.common.black,
      boxSizing: 'border-box',
      padding: '2px'
    },

    icon: {
      width: '14%',
      padding: '10px',
      margin: '-6px'
    },

    user_profile_processname: {
      'fontSize': theme.spacing(1.25),
      'color': theme.palette.info.main,
      
      'margin': 1,
      '&:hover': {
        // color: '#007ecc',
        color: theme.palette.primary.light,
        
        fontStyle: 'italic',
        cursor: 'pointer'
      }
    },
    mainModalContainer: {
      // margin: '15px',
      // boxShadow: '0 0 5px',
      border: 'none',
      padding: '10px'
   },
    inputContainer: {
      width: '100% !important',
      textAlign: 'left',
      paddingTop: '10px',

    },
    label: {
      textAlign: 'left',
      margin: '10px 0 0 0',
      // fontSize: '13px',
      // color: '#0CA6D8'
      color:'rgb(41, 148, 127)',
      fontSize:'13px'
     
    },
    input: {
      // margin: '10px 0 0 0',
      width: '100%',
      textAlign: 'left',
      // padding: '10px'
    },
    star: {
      color: 'red'
    },
    profileMenuItems:{
      margin:0,
      padding:0
    },
    updatePassword: {
    padding: '20px',
    marginBottom: '23px',
    borderRadius: '4px',
    backgroundColor: '#e51c23',
    color: '#ffffff',
    fontSize: '13px'
    },
    updatePasswordError: {
      color: '#e51c23',
      marginBottom: '-6px'
    }
  });
