import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiBreadcrumbsStyle = (theme: Theme) =>
  createStyles({
    breadcrumb_spacing: {
      marginLeft: theme.spacing(0.5),
      'fontSize': theme.typography.caption.fontSize,
      // 'background': theme.palette.background.paper,
      'padding': ` ${theme.spacing(1)}`,
      // 'boxShadow': theme.shadows[1],
      '& .MuiTypography-body1': {
        fontSize: 'inherit'
      },
      '& .MuiBreadcrumbs-li': {
        //  fontWeight: '700'
      },
      '& .MuiBreadcrumbs-separator ': {
        marginLeft:'0',
        marginRight:'0'
      }
    },
    crumbBold: {
      fontWeight: '700',
      cursor:'default',
      color: '#33475b'
    },
    breadcrumb: {
      // fontWeight: '700',
      color: '#33475b',
      cursor: 'pointer',
      '&:hover': {
         textDecoration: 'underline', 
       }
    },
    crumbHover: {
      // '&:hover': {
      //   color: '#29947f',
      // }
    }
    // breadcrumb_spacing: {
    //   marginLeft: theme.spacing(1.),
    //   // padding: theme.spacing(1.),
    //   // background: theme.palette.background.paper,
    //   // boxShadow: theme.shadows[1],
    //   alignItems: 'center',
    //   '& .MuiBreadcrumbs-separator': {
    //     display: 'none',

    //   },
    //   '&  .MuiBreadcrumbs-ol':{
    //     padding:0,
    //   },
    //   '& .MuiBreadcrumbs-li' :{
    //     margin: '0px',
    //     display: 'inline-block',
    //     position: 'relative',
    //     '& div': {
    //       padding: `${theme.spacing(0.7)} ${theme.spacing(2.2)}`,
    //       display: 'inline-block',
    //       position: 'relative',
    //       color: '#666',
    //       textDecoration: 'none',
    //       zIndex: '1',
    //       fontSize: theme.spacing(1.5),
    //     },
    //     "&:nth-child(1)":{
    //       backgroundColor: 'white',
    //       borderLeft: `0.5px solid ${theme.palette.info.light}`,
    //       left:' -5px',
    //       boxSizing: 'content-box',
    //       "&::before": {
    //         borderLeft: 'none'
    //       },
    //       "&::after": {
    //         borderLeft: 'none'
    //       },
    //     },
    //     "&::before":{
    //       content: '""',
    //       position: 'absolute',
    //       left: 0,
    //       height: '50%',
    //       width: '100%',
    //       background: 'white',
    //       borderLeft: `0.5px solid ${theme.palette.info.light}`,
    //       borderRight: `0.5px solid ${theme.palette.info.light}`,
    //       zIndex: '0',
    //       top:' 0',
    //       transform:' skew( 40deg )',
    //       borderTop: `0.5px solid ${theme.palette.info.light}`,
    //     },
    //     "&::after":{
    //       content: '""',
    //       position: 'absolute',
    //       left: 0,
    //       height: '50%',
    //       width: '100%',
    //       background: 'white',
    //       borderLeft: `0.5px solid ${theme.palette.info.light}`,
    //       borderRight: `0.5px solid ${theme.palette.info.light}`,
    //       zIndex: '0',
    //       bottom:' 0',
    //       transform: 'skew( -40deg )',
    //       borderBottom: `0.5px solid ${theme.palette.info.light}`,
    //     },
    //     '&:first-child::after': {
    //       left: theme.spacing(0.75),
    //     },
    //     '&:first-child::before': {
    //        left: theme.spacing(0.75),
    //     },
    //     "&:last-child>div" :{
    //       color: theme.palette.background.paper
    //     },
    //     '&:last-child::after': {
    //       background: theme.palette.primary.dark,

    //     },
    //     '&:last-child::before': {
    //       background: theme.palette.primary.dark
    //     },
    //   },

    // }
  });
