import { KagamiImageModel,KagamiImageFieldProps, useKagamiImageModelState } from '../model/kagami_ImageModel';
import FormLabel from '@mui/material/FormLabel';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiImageFieldStyle } from '../styles/Kagami_ImageFieldStyle';
import { getFormModel } from '../../../form/controller/KagamiFormController'; 
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';  
import { buildModal } from '../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { showImageAfterUpload, showingImageControllerForFile } from '../builder/kagami_ImageBuilder';


const useStyles = makeStyles(KagamiImageFieldStyle);

export const buildImageFieldComponent = (kagamiImageModel: KagamiImageModel ) => {
  return <KagamiImageField   kagamiImageModel={kagamiImageModel} /> 
};



const KagamiImageField = (props: KagamiImageFieldProps) => {
  const classes = useStyles();
  let controlerindentifier = props.kagamiImageModel.controlIdentifier;
  let fieldState = useKagamiImageModelState(props.kagamiImageModel.createState);
  props.kagamiImageModel.state = fieldState;
  const uiSettingsClassName = props.kagamiImageModel.getControlWidth(props.kagamiImageModel.uiSettings);
  const fontProperties = props.kagamiImageModel.getFontStyling(props.kagamiImageModel.uiSettings);
  
  getFormModel().formControlStates[props.kagamiImageModel.controlIdentifier] = fieldState;
  if (fieldState.isVisible === false) {
    return null;
  } else {
   
        return (
          <div className={`${uiSettingsClassName}`}>
            <div className={classes.root}>
              {props.kagamiImageModel.controlDisplayName ? (
                <FormLabel
                 component="span" required={fieldState.isMandatory}  
                //  className={classes.controlLabel}
                style={{
                  color:fontProperties.labelFontColor,
                  fontWeight: fontProperties.labelFontBold ,
                  fontStyle: fontProperties.labelFontItalic,
                  textDecoration: fontProperties.labelFontUnderline,
                 }}
                 >
                  {props.kagamiImageModel.controlDisplayName}
                </FormLabel>
              ) : (
                <></>
              )}
              { CommonUtils.isNotEmpty(props.kagamiImageModel.controlValue) ? showImageAfterUpload( props.kagamiImageModel) : showingImageControllerForFile( classes ,props.kagamiImageModel)}

            </div>
            {buildModal(props.kagamiImageModel.popupModel)}
          </div>
        );
       
  }
};
export default KagamiImageField;

 