import { KagamiImageModel } from '../model/kagami_ImageModel';
import { buildImageFieldComponent } from '../ui/kagami_imageField';
import { notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import { FileUrlService } from '../../../../../../../../../../service/FileUrlService';



export function buildImageField(kagamiImageModel: KagamiImageModel ) {
  return buildImageFieldComponent(kagamiImageModel );
}

 
export function deleteImage(fieldState: any, imageModel: KagamiImageModel) {
  const newSortedFiles = fieldState.controlValue;
  let getDocId: any;
  if (newSortedFiles && newSortedFiles.split(':')[0]) {
    getDocId = newSortedFiles.split(':')[0];
    FileUrlService.deleteImage(getDocId);
    notifyChangeToForm(imageModel, null);
  } else {
    getDocId = newSortedFiles;
    FileUrlService.deleteImage(getDocId);
    notifyChangeToForm(imageModel, null);
  }
}
