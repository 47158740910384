import { State, useState } from "@hookstate/core";
import { KagamiModalModel } from "../../../../../../../../../../../components/modal/model/KagamiModalModel";
import { KagamiFormModel } from "../../../form/model/KagamiFormModel";
import { KagamiControlModel } from "../../model/kagamiControlModel";

export class KagamiImageModel extends KagamiControlModel {
  selectedFile: any[] = [];
  selectedFileName: any[] = [];
  selectedFileURL: string[] = [];
  onClickMenu: boolean = false;
  onClickModelOpen:boolean = false;
   
  state: any;
  htmlControl : string;
  thumbNails: any[] = [];
  // adding this filetype variable to handle file field 
  //in edit forms need to revisit
  fileType:any[]=[];
  popupModel : KagamiModalModel;
  uiSettings: any;
  // attachmentsModel:KagamiAttachmentsModalModel;
  KagamiFormModel: KagamiFormModel;
  docInfo :any;
  newUploadsDocInfo:any;

  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any[],
    onChangeRequired: boolean,
    validation: any,
    pickAttribute:boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    selectedFile: null,
    selectedFileName: null,
    policyMap:any,
    presentationPolicy : any,
    businessPolicy : any,
    initiallyReadOnly: boolean,
    initiallyVisible : boolean,
    uiSettings: any,
    htmlControl : string,
    formDataIdentifier : string,
    isGridField : boolean,
    isEmbddedField:boolean,
    kagamiFormModel:KagamiFormModel,
    docInfo :any
  ) {
    super(
      controlIdentifier,
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField,
    );
    this.uiSettings = uiSettings
    this.htmlControl = htmlControl;
    this.thumbNails = [];
    this.popupModel = new KagamiModalModel();
    // this.attachmentsModel = new KagamiAttachmentsModalModel()
    this.KagamiFormModel = kagamiFormModel;
    this.docInfo = docInfo;
    this.newUploadsDocInfo = {}
  }
}
export interface KagamiImageFieldProps {
  kagamiImageModel: KagamiImageModel; 
}



export const wrapKagamiImageModelState = (
  state: State<KagamiImageModel> 
) => {
  let controlState = state;

  return {
    get isMandatory() {
      return controlState.mandatory.get();
    },

    setMandatory(isMandatory: boolean) {
      controlState.mandatory.set(isMandatory);
    },

    get isVisible() {
      return controlState.visible.get();
    },

    setVisible(isVisible: boolean) {
      controlState.visible.set(isVisible);
    },

    get isReadOnly() {
      return controlState.readonly.get();
    },

    setReadonly(isReadonly: boolean) {
      controlState.readonly.set(isReadonly);
    },

    get errorMessage() {
      return controlState.errorMessage.get();
    },

    setErrorMessage(value: string) {
      controlState.errorMessage.set(value);
    },

    get controlValue() {
      return controlState.controlValue.get();
    },

    setControlValue(value: any) {
      controlState.controlValue.set(value);
    },
//
//     get selectedFile() {
//       return controlState.selectedFile.get();
//     },

//     mergeSelectedFile(value: any) {
//       controlState.selectedFile.merge(value);
//     },

//     get selectedFileName() {
//       return controlState.selectedFileName.get();
//     },

//     mergeSelectedFileName(value: any) {
//       controlState.selectedFileName.merge(value);
//     },

//     setSelectedFile(value: any) {
//       controlState.selectedFile.set(value);
//     },

//     setSelectedFileName(value: any) {
//       controlState.selectedFileName.set(value);
//     },

//     get selectedFileURL() {
//       return controlState.selectedFileURL.get();
//     },

//     mergeselectedFileURL(value: any) {
//       controlState.selectedFileURL.merge(value);
//     },

//     setSelectedFileURL(value: any) {
//       controlState.selectedFileURL.set(value);
//     },
// //
//     get onClickMenu() {
//       return controlState.onClickMenu.get();
//     },

//     setonClickMenu(value: boolean) {
//       controlState.onClickMenu.set(value);
//     },

//     get fileType(){
//       return controlState.fileType.get();
//     },

//     setFileType(value:any){
//       controlState.fileType.set(value);
//     },

//     mergeFileType(value:any){
//       controlState.fileType.merge(value);
//     },

//     get onClickModelOpen(){
//       return controlState.onClickModelOpen.get();
//     },

//     setonClickModelOpen(value: boolean){
//       controlState.onClickModelOpen.set(value);
//     },

    
// //
//     get thumbNails(){
//       return controlState.thumbNails.get();
//     },
    
//     mergeThumbNails(value:any){
//       controlState.thumbNails.merge(value)
//     },
// //

    //collecting data from new uploads
    get newUploadsDocInfo() {
      return controlState.newUploadsDocInfo.get();
    },
    setNewUploadsDocInfo(value:any) {
      return controlState.newUploadsDocInfo.set(value);
    }
  };
};

export const useKagamiImageModelState = (state: State<KagamiImageModel>) => wrapKagamiImageModelState(useState(state))

export const accessKagamiImageModelState = (state:State<KagamiImageModel>) => wrapKagamiImageModelState(state);

