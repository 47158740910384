import { createState } from '@hookstate/core';
import { BuildClientError, BuildServerError } from '../builder/header/ui/ErrorBuilder';
import { KagamiPageHeaderController } from '../builder/header/controller/KagamiPageHeaderControllerStatic';
import { KagamiHomeModel } from '../model/KagamiHomeModel';
import { BuildHome } from '../ui/KagamiHome';

export class KagamiHomeController {
  static homeModel: KagamiHomeModel;

  static buildHome(kagamiHomeModel: KagamiHomeModel) {
    kagamiHomeModel.pageHeaderComponent = KagamiPageHeaderController.buildPageHeader();
    kagamiHomeModel.state = createState(kagamiHomeModel);
    return BuildHome(kagamiHomeModel);
  }

  static togglePageDrawer() {
    let currentDrawerState = KagamiHomeController.homeModel.state.showDrawer;
    KagamiHomeController.homeModel.state.drawerNeeded(!currentDrawerState);
  }

  static showDefaultDashboard() {}
  static re_renderFavorites() {
    KagamiPageHeaderController.re_renderDashboardFavorites();
  }

  static renderDashboard(pageContent: any) {
    KagamiHomeController.homeModel.state.setPageContent(pageContent);
  }

  static showServerError(error: any) {
    let homeModel = KagamiHomeController.homeModel;
    homeModel.errorModal.content = BuildServerError(error);
    homeModel.errorModal.title = 'Error';
    homeModel.errorModal.modalWidth = 'sm';
    let alertDialogState = homeModel.errorModal.state;
    alertDialogState.setOpen(true);
  }

  static showClientError(error: any) {
    let homeModel = KagamiHomeController.homeModel;
    homeModel.errorModal.content = BuildClientError(error);
    homeModel.errorModal.title = 'Error';
    homeModel.errorModal.modalWidth = 'sm';
    let alertDialogState = homeModel.errorModal.state;
    alertDialogState.setOpen(true);
  }
}
