import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class PivotReportModel extends KagamiReportModel {

  reportData: any;
  isDataLoaded:boolean;
  state: any;
  contentReport:any;
  isContentReportLoaded:boolean;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
    this.isContentReportLoaded = false;
    // this.reportHeaderName=reportHeaderName;
    // this.reportedOn=reportedOn;
    // this.chartControls=chartControls;
    // this.reportsForRole=reportForRole;
  }
}

export interface PivotReportModelProps {
  pivotReportModel: PivotReportModel;
}

export const usePivotReportModelState = (state: State<PivotReportModel>) => {
  let listReportState = useState(state);

  return {
    reportConfig() {
      listReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      listReportState.reportData.set(reportContent);
    },
    get isContentReportLoaded(){
      return listReportState.isContentReportLoaded.get()
    },
    setContentReport(isContentReportLoaded:boolean){
      listReportState.isContentReportLoaded.set(isContentReportLoaded);
    },
    get isDataLoaded(){
      return listReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      listReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
