import { KagamiProfileModel } from '../model/KagamiProfileModel';
import { buildUserProfile } from '../ui/kagamiProfile';

export class KagamiProfileController {
  static buildProfile(kagamiProfileModel: KagamiProfileModel) {
    return buildUserProfile(kagamiProfileModel);
  }

  static showProfile(kagamiProfileModel: KagamiProfileModel) {
    let currentState = kagamiProfileModel.state.isOpen;

    if (!currentState) {
    }
    kagamiProfileModel.state.setOpen(!currentState);
  }

  static hideProfile(kagamiProfileModel: KagamiProfileModel) {
    if (kagamiProfileModel.state.isOpen) {
      return kagamiProfileModel.state.setOpen(false);
    }
  }
}
