import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { SystemConstants } from '../../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { buildtoastNotification } from '../../../../../../../ToastNotification/controller/ToastNotificationController';
import { extractDropdownOption, isLoadRequiredForPresentation } from '../../../../service/PresentationService';
import { PresentationHierarchy } from '../../../form/builder/hierarchy/PresentationHierarchy';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { KagamiFormData } from '../../../form/model/formData';
import { KagamiFormModel } from '../../../form/model/KagamiFormModel';
import { getActiveFormRecord, getSelectedValueForReadOnlySearch, isDependentControlVisible, isEmbedDataModified, isSingleLovOnchangeRequired } from '../../../form/service/FormService';
import { KagamiGridModel } from '../../../list/builder/grid/model/KagamiGridModel';
import { cellRendererCustomComponentsStyle } from '../../../list/builder/grid/style/CellRendererCustomComponents';
import { getKagamiListModel } from '../../../list/controller/KagamiListController';
import { KagamiTriggerController } from '../../../Triggers/builder/trigger/controller/KagamiTriggerController';
import { confirmBox, setTriggetModel } from '../../../Triggers/builder/TriggerAlertModal/controller/TriggerAlertModalController';
import { onSearchCall, validateSelectTextBox } from '../controller/kagamiSelectController';
import { GridSelectFieldProps, SelectFieldProps } from '../model/kagamiSelectModel';
import { KagamiSelectFieldStyle } from '../styles/KagamiSelectFieldStyle';

const useStyles = makeStyles(KagamiSelectFieldStyle);
const attrStyles = makeStyles(cellRendererCustomComponentsStyle);
function SearchField(props: SelectFieldProps) {
  const classes = useStyles();
  const {kagamiSelectModel} = props
  const attributeClassess = attrStyles();
  let fieldState = props.kagamiSelectModel.state;
  const uiSettingsClassName = props.kagamiSelectModel.getControlWidth(props.kagamiSelectModel.uiSettings);
  const fontProperties = props.kagamiSelectModel.getFontStyling(props.kagamiSelectModel.uiSettings);
  const isCustomSearch = props.kagamiSelectModel.uiSettings?.customField?.Color;
  const debounceCallForOnSearch = React.useCallback(CommonUtils._debounceFtn(onSearchCallForSearchFeild, 1000), []);

  useEffect(() => {
    let formModel: KagamiFormModel = getFormModel();
    /// Note : Avoiding single lov on change in case the form has onload call to avoid conflicts
    if (
      !isLoadRequiredForPresentation(formModel.onStartData) &&
      !props.kagamiSelectModel.readonly &&
      props.kagamiSelectModel.entityId === formModel.mainEntityId &&
      isSingleLovOnchangeRequired(props.kagamiSelectModel)
    ) {
      let associatedObject: any = props.kagamiSelectModel.lovRecords[0];
      props.kagamiSelectModel.associatedObject = associatedObject;
      fieldState.setControlValue(associatedObject[props.kagamiSelectModel.entityAssociatedAttribute]);
      validateSelectTextBox(
        props.kagamiSelectModel,
        associatedObject[props.kagamiSelectModel.entityAssociatedAttribute]
      );
    }
  }, []);

  function onSearchCallForSearchFeild(searchKey: string) {
    props.kagamiSelectModel.pageNumber = 1;
    props.kagamiSelectModel.inputValue = searchKey;
    onSearchCall(props.kagamiSelectModel, searchKey);
  }

  function handleAsyncSearch() {
    console.log('handle Async Search ');
    if (props.kagamiSelectModel.verbProperties === undefined && props.kagamiSelectModel.pagination) {
      // need to handle when getting pagination data from backend
      props.kagamiSelectModel.pageNumber = +props.kagamiSelectModel.pageNumber + 1;
      onSearchCall(props.kagamiSelectModel, props.kagamiSelectModel.inputValue, true);
    } else if (props.kagamiSelectModel.lovRecords.length < props.kagamiSelectModel.verbProperties.totalRecords) {
      props.kagamiSelectModel.pageNumber = +props.kagamiSelectModel.pageNumber + 1;
      onSearchCall(props.kagamiSelectModel, props.kagamiSelectModel.inputValue, true);
    }
  }
  let formModel: KagamiFormModel = getFormModel();
  formModel.triggerAlertModalModel.alertName = 'Warning';

  let bgColor = '';
  let attrData = props?.kagamiSelectModel?.associatedObject;
  if (typeof attrData === 'object' && !Array.isArray(attrData) && attrData) {
    if ('Color' in attrData) {
      bgColor = attrData?.Color?.bgCode || '';
    }
  }

  return props.kagamiSelectModel.isGridField ? (
    <GridSearchField kagamiSelectModel={props.kagamiSelectModel} selectFieldState={fieldState} />
  ) : (
    <div className={`${uiSettingsClassName} ${classes.root}`}>
      {/* {buildTriggerAlertModal(formModel.triggerAlertModalModel)} */}
      {formModel?.triggersModel?.isDetailView && fieldState.isReadOnly === false ? (
        <div className={classes.readOnlyField_cont} onClick={KagamiTriggerController.handleHyperLinkClick}>
          <FormLabel
            component="span"
            className={classes.controlLabel}
            required={fieldState.isMandatory}
            style={{ color: fontProperties.labelFontColor }}
          >
            {' '}
            {props.kagamiSelectModel.controlDisplayName}
          </FormLabel>
          <p className={classes.readOnlyField} style={{ color: fontProperties.controlFontColor }}>
            {
              CommonUtils.isEmpty(props.kagamiSelectModel.associatedObject) &&
              (fieldState.controlValue === null ||
                fieldState.controlValue === undefined ||
                fieldState.controlValue === '' ||
                (fieldState.controlValue instanceof Array && fieldState.controlValue.length === 0)) //fieldState.controlValue == null || fieldState.controlValue.length === 0
                ? '-----'
                : getSelectedValueForReadOnlySearch(props.kagamiSelectModel) //extractDropdownOption(props.kagamiSelectModel.attributePresentation, props.kagamiSelectModel.associatedObject)
            }
          </p>
        </div>
      ) : fieldState.isReadOnly === false ? (
        <>
          <FormControl fullWidth>
            <FormLabel
              component="span"
              required={fieldState.isMandatory}
              style={{
                color: fontProperties.labelFontColor,
                fontWeight: fontProperties.labelFontBold,
                fontStyle: fontProperties.labelFontItalic,
                textDecoration: fontProperties.labelFontUnderline,
                
              }}
            >
              {props.kagamiSelectModel.controlDisplayName}
            </FormLabel>
            <Autocomplete
              fullWidth
              size="small"
              sx={{
                '& .MuiInputBase-input': {
                  height: '18px'
                },
                // color: props.kagamiSelectModel.inputValue.length? 'red':'',
              }}
              classes={{ paper: classes.paperForSearchPopper, popper: 'customPopover' }}
              // disablePortal={true}
              freeSolo={
                CommonUtils.isNotEmpty(props.kagamiSelectModel.associatedObject)
                  ? false
                  : CommonUtils.isNotEmpty(props.kagamiSelectModel.lovRecords)
                  ? true
                  : false
              }
              id={props.kagamiSelectModel.controlIdentifier}
              options={props.kagamiSelectModel.lovRecords} // to avoid converting proxy object
              disableClearable={CommonUtils.isEmpty(fieldState.controlValue)}
              value={
                (CommonUtils.isEmpty(fieldState.controlValue) ||
                  (fieldState.controlValue instanceof Array && fieldState.controlValue.length === 0)) &&
                CommonUtils.isEmpty(props.kagamiSelectModel.associatedObject)
                  ? ''
                  : props.kagamiSelectModel.associatedObject
              }
              getOptionLabel={(selectedRecord: any) =>
                // extractDropdownOption(props.kagamiSelectModel.attributePresentation, selectedRecord, props.kagamiSelectModel.dispValues)
                extractDropdownOption(props.kagamiSelectModel.attributePresentation, selectedRecord)
              }
              isOptionEqualToValue={(option, value) => {
                return value === '' ? true : option && value ? option.id === value.id : true;
              }}
              disabled={fieldState.isReadOnly}
              forcePopupIcon={true}
              renderInput={(params) => (
                <TextField
                  color="primary"
                  
                  sx={{
                    '& .MuiInputLabel-root ': {
                      color: fontProperties.labelFontColor,
                      fontWeight: fontProperties.labelFontBold,
                      fontStyle: fontProperties.labelFontItalic,
                      textDecoration: fontProperties.labelFontUnderline
                    },
                    '& .MuiInputLabel-root.Mui-focused ': {
                       color: fontProperties?.labelFontColor,
                      fontWeight: fontProperties?.labelFontBold,
                      fontStyle: fontProperties?.labelFontItalic,
                      textDecoration: fontProperties?.labelFontUnderline,
                      
                    },
                    '& .MuiOutlinedInput-root': {
                      color: fontProperties.controlFontColor + ' ' + '!important',
                      fontWeight: fontProperties.controlFontBold,
                      fontStyle: fontProperties.controlFontItalic,
                      textDecoration: fontProperties.controlFontUnderline,
                      // color:serachKey? 'red':'',
                    }, 
                    
                  }}
                  // key={props.kagamiSelectModel.controlIdentifier}
                  {...params}
                  // label={props.kagamiSelectModel.controlDisplayName}
                  variant="outlined"
                  required={fieldState.isMandatory}
                  onKeyPress={(event: any) => { event.key === 'Enter' && event.preventDefault() }}
                  onChange={(e) => debounceCallForOnSearch(e.target.value)}
                  onBlur={() => {
                    /// Note : Commenting the on search call with empty string to avoid conflict with the on change response
                    /// Need to implement default lov records functionality in case of search strings
                    // if(props.kagamiSelectModel.inputValue!==''){
                    //   props.kagamiSelectModel.inputValue = '';
                    //   debounceCallForOnSearch('');
                    // }
                  }}
                  style={{color: props.kagamiSelectModel.inputValue? 'red':'',}}
                  // InputLabelProps={{ shrink: true }}
                  error={fieldState.errorMessage ? true : false}
                  helperText={fieldState.errorMessage ? fieldState.errorMessage : null}
                />
              )}
              // Note :need to discuss with functional team and impliment   further
              onFocus={(event: any) => {
                if (
                  CommonUtils.isNotEmpty(props.kagamiSelectModel.noValueMsg) &&
                  CommonUtils.isEmpty(props.kagamiSelectModel.lovRecords) &&
                  !props.kagamiSelectModel.isEmbddedField
                ) {
                  let activeRecord: any = getActiveFormRecord(
                    getFormModel(),
                    props.kagamiSelectModel.noValueMsg['dependentEntity']
                  );
                  if (CommonUtils.isEmpty(activeRecord[props.kagamiSelectModel.noValueMsg['dependentOn']])) {
                    // let toastMessage = {
                    //   text: props.kagamiSelectModel.noValueMsg.message,
                    //   background: '#00acd6',
                    //   textColor: '#fff',
                    //   time:2
                    // }
                    buildtoastNotification('info', props.kagamiSelectModel.noValueMsg.message);
                    // triggerNotification(toastMessage);
                  }
                }
              }}  

              onChange={(event : any, changedValue)=>{
                if (event != null) {
                  if (event.type === 'click' || (event.code === 'Enter' && event.type === 'keydown')) {
                    if (changedValue == null) {
                      fieldState.setControlValue(null);
                      props.kagamiSelectModel.associatedObject = undefined;
                    } else {
                      /// Note : Commenting the on search call with empty string to avoid conflict with the on change response
                      /// Need to implement default lov records functionality in case of search strings
                      // if(props.kagamiSelectModel.inputValue!==''){
                      //   props.kagamiSelectModel.inputValue = '';
                      //   onSearchCallForSearchFeild('');
                      // }
                      props.kagamiSelectModel.associatedObject = changedValue;
                      // props.kagamiSelectModel.dispValues = true;
                      // extractDropdownOption(props.kagamiSelectModel.attributePresentation, changedValue, props.kagamiSelectModel.dispValues)
                      fieldState.setControlValue(changedValue[props.kagamiSelectModel.entityAssociatedAttribute]);
                    }

                    validateSelectTextBox(props.kagamiSelectModel, changedValue);
                  }
                }
              }
              }
              // ListboxProps={{
              //   onScroll: (event: React.SyntheticEvent) => {

              //     const listboxNode = event.currentTarget;
              //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              //       handleAsyncSearch();
              //     }
              //   }
              // }}
              // ListboxComponent={ListBox} // handle scroll position
              renderOption={(props, option, { inputValue }) => {
               const optionTitle= extractDropdownOption(kagamiSelectModel.attributePresentation, option)
                const matches = match(optionTitle, inputValue, { findAllOccurrences: true });
                const parts = parse(optionTitle, matches);
        
                return (
                  <li {...props}>
                    <div>
                      {parts.map((part:any, index:any) => (
                        <span
                          key={index} 
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            color: part.highlight ? '#29947f' : '',
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
            />
          </FormControl>
        </>
      ) : (
        <div className={classes.readOnlyField_cont} onClick={KagamiTriggerController.handleHyperLinkClick}>
          <FormLabel
            component="span"
            style={{ color: fontProperties.labelFontColor }}
            className={classes.controlLabel}
            required={fieldState.isMandatory}
          >
            {' '}
            {props.kagamiSelectModel.controlDisplayName}
          </FormLabel>
          <p className={classes.readOnlyField}>
            {bgColor ? (
              <div className={attributeClassess.badgeRoot}>
                <div
                  className={attributeClassess.badge}
                  style={{ backgroundColor: bgColor ? bgColor : 'default' }}
                ></div>
                <div style={{ color: 'inherit' }}>
                  {
                    (fieldState.controlValue === null ||
                      fieldState.controlValue === undefined ||
                      fieldState.controlValue === '' ||
                      (fieldState.controlValue instanceof Array && fieldState.controlValue.length === 0)) &&
                    CommonUtils.isEmpty(props.kagamiSelectModel.associatedObject) //fieldState.controlValue == null || fieldState.controlValue.length === 0
                      ? '-----'
                      : getSelectedValueForReadOnlySearch(props.kagamiSelectModel) //extractDropdownOption(props.kagamiSelectModel.attributePresentation, props.kagamiSelectModel.associatedObject)
                  }
                </div>
              </div>
            ) : (fieldState.controlValue === null ||
                fieldState.controlValue === undefined ||
                fieldState.controlValue === '' ||
                (fieldState.controlValue instanceof Array && fieldState.controlValue.length === 0)) &&
              CommonUtils.isEmpty(props.kagamiSelectModel.associatedObject) ? ( //fieldState.controlValue == null || fieldState.controlValue.length === 0
              '-----'
            ) : (
              getSelectedValueForReadOnlySearch(props.kagamiSelectModel)
            ) //extractDropdownOption(props.kagamiSelectModel.attributePresentation, props.kagamiSelectModel.associatedObject)
            }
          </p>
        </div>
      )}
    </div>
  );
}

export default SearchField;

const GridSearchField = (props: GridSelectFieldProps) => {
  const classes = useStyles();
  let fieldState = props.selectFieldState;
  const debounceCallForOnSearch = React.useCallback(CommonUtils._debounceFtn(onSearchCallForSearchFeild, 350), []);
  function onSearchCallForSearchFeild(searchKey: string) {
    onSearchCall(props.kagamiSelectModel, searchKey);
  }
  return fieldState.isReadOnly === false ? (
    <Autocomplete
      fullWidth
      size="small"
      disablePortal={false}
      disableClearable={true}
      id={props.kagamiSelectModel.controlIdentifier}
      options={fieldState.lovRecords}
      value={
        fieldState.controlValue == null || fieldState.controlValue.length === 0
          ? SystemConstants.EMPTY
          : props.kagamiSelectModel.associatedObject
      }
      getOptionLabel={(selectedRecord: any) =>
        extractDropdownOption(props.kagamiSelectModel.attributePresentation, selectedRecord)
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={fieldState.isReadOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          required={fieldState.isMandatory}
          onChange={(e) => debounceCallForOnSearch(e.target.value)}
          // onChange={(e) => {
          //   onSearchCall(props.kagamiSelectModel, e.target.value);
          // }}
          // InputProps={{
          //   disableUnderline : true
          // }}
          helperText={fieldState.errorMessage ? fieldState.errorMessage : null}
        />
          
        
      )}
      onChange={(event, changedValue) => {
        if (event != null) {
          if (event.type === 'click') {
            if (changedValue == null) {
              fieldState.setControlValue(null);
              props.kagamiSelectModel.associatedObject = undefined;
            } else {
              if (props.kagamiSelectModel.isGridField) {
                let gridModel = getKagamiListModel();
                if (gridModel instanceof KagamiGridModel) {
                  let activeRowNode: any = gridModel.activeRowNode;
                  activeRowNode.data[props.kagamiSelectModel.attributeName] = changedValue;
                }
              }
              props.kagamiSelectModel.associatedObject = changedValue;
              fieldState.setControlValue(changedValue.id);
            }

            validateSelectTextBox(props.kagamiSelectModel, changedValue);
          }
        }
      }}
    />
  ) : (
    <>
      <p className={classes.readOnlyField}>
        {fieldState.controlValue === null ||
        fieldState.controlValue === undefined ||
        fieldState.controlValue === '' ||
        fieldState.controlValue.length === 0
          ? '-----'
          : extractDropdownOption(
              props.kagamiSelectModel.attributePresentation,
              props.kagamiSelectModel.associatedObject
            )}
      </p>
    </>
  );
};

interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {}

export const ListBox = forwardRef(function ListBoxBase(props: ListBoxProps, ref: ForwardedRef<HTMLUListElement>) {
  const { children, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current);

  return (
    <ul {...rest} ref={innerRef} role="list-box">
      {children}
    </ul>
  );
});

type NullableUlElement = HTMLUListElement | null;
