import { useEffect } from "react"
import { buildEmbedTrigger } from "../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/controller/KagamiEmbedTriggerController"
import { KagamiEmbedTriggerModel } from "../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/model/KagamiEmbedTriggerModel";

export default (props: any) => {
    let editTriggerModel : KagamiEmbedTriggerModel = props.editTrigger
    console.log('edit record id ----' + props.data.id)
    editTriggerModel.embeddedData  = props.data;

    useEffect(()=>{
        editTriggerModel.addRecordIdToPresentationModel = addRecordIdToPresentationModel;
    },[]);

    const addRecordIdToPresentationModel = () => {
        editTriggerModel.embeddedFormPresentationModel.formDataIdentifier = props.data.id
    }

    return <div>{buildEmbedTrigger(editTriggerModel)}</div>
}