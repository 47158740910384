import { KagamiTextModel } from '../model/kagamiTextModel';
import KagamiTextBoxValidator from '../../validations/KagamiControlValidator';
import { notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import KagamiTextFieldCustom from '../ui/KagamiTextField_Custom';


export function buildTextBox(kagamiTextModel: KagamiTextModel) {
  return <KagamiTextFieldCustom key={kagamiTextModel.controlIdentifier} kagamiTextModel={kagamiTextModel}></KagamiTextFieldCustom>;
}

export function validateTextBox(kagamiTextModel: KagamiTextModel, value: any ) {
  kagamiTextModel.controlValue = value;
  let errorMsg = KagamiTextBoxValidator.validate(kagamiTextModel.controlType,kagamiTextModel.isContact ? value.toString().slice(1): value, kagamiTextModel.validation);
  // let errorMsg = KagamiTextBoxValidator.validate(kagamiTextModel.controlType,kagamiTextModel.isContact ? value.toString().slice(1): value, kagamiTextModel.validation);
  if (errorMsg === null) {
    kagamiTextModel.state.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiTextModel, value);
  } else {
    kagamiTextModel.state.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiTextModel, value);
  }
}
