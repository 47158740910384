import { State, useState } from '@hookstate/core';
import { KagamiModalModel } from '../../../../../../../components/modal/model/KagamiModalModel';

export class KagamiKeyBoardShortCutsModel {
  open: boolean;
  kagamiModalModel: KagamiModalModel;
  state: any;
  constructor() {
    this.open = false;
    this.kagamiModalModel = new KagamiModalModel();
  }
}

export interface KagamiKeyBoardShortCutsModelProps {
    keyBoardShortCutsModel: KagamiKeyBoardShortCutsModel;
    translations?:any;
}

export const useKagamiKeyBoardShortCutsModelState = (state: State<KagamiKeyBoardShortCutsModel>) => {
  let keyBoardShortCutsModelState = useState(state);

  return {
    get isOpen() {
      return keyBoardShortCutsModelState.open.get();
    },

    setOpen(isOpen: boolean) {
        keyBoardShortCutsModelState.open.set(isOpen);
    }
  };
};