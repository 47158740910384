import { KagamiAccordionModel } from '../model/KagamiAccordionModel';
import buildAccordionComponent from '../ui/KagamiAccordion';

export function buildAccordion(accordionModel: KagamiAccordionModel) {
  return buildAccordionComponent(accordionModel);
}

export function toggleAccordion(accordionModel: KagamiAccordionModel) {
  accordionModel.state.setActive(!accordionModel.state.active);
}
