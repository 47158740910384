import { createState } from '@hookstate/core';
import {
  StackedColumnReportModel,
  StackedColumnReportModelProps,
  useStackedColumnReportModelState
} from '../model/StackedColumnReportModel';
import { makeStackedColumn } from '../service/StackedColumnReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';

export const BuildStackedColumnReportView = (stackedColumnReportModel: StackedColumnReportModel) => {
  return (
    <KagamiStackedColumnChartReportView key={'stacked-column-chart-reporrt'} stackedColumnReportModel={stackedColumnReportModel}></KagamiStackedColumnChartReportView>
  );
};


const KagamiStackedColumnChartReportView = (props: StackedColumnReportModelProps) => {
  let stackedColumnReportModel = props.stackedColumnReportModel;
  let stackedColumnChartState = useStackedColumnReportModelState(createState(stackedColumnReportModel));
  const chartComponent:any = React.useRef(null);
  stackedColumnReportModel.state = stackedColumnChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(props.stackedColumnReportModel)
  }, [])

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartComponent.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);

  if(!stackedColumnChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{   
    if(stackedColumnReportModel.reportData){
  const stackedColumnConfig :any = makeStackedColumn(stackedColumnReportModel);

  return (
    <div ref={containerRef} className="ag-theme-alpine dashboard-highchart kgm-report-hw">
         <Chart ref={chartComponent} highcharts={Highcharts} options={stackedColumnConfig} />
      </div>
  );
} else{
  return <p className='NoDataFound'>No Data Found</p>
}
}
};
