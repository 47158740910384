
export class KagamiFavoriteModel {
  title: any;
  content: any;
  state: any;

  constructor(title: any) {
    this.title = title;
  }
}

export interface KagamiFavoriteProps {
  kagamiFavoriteModel: KagamiFavoriteModel;
}
