export class PresentationHierarchy {
    currentPrule : string;
    parentPrule: string| undefined;
    currentEntity : string;
    parentEntity : string;
    levelList: Array<string>;
    currentPruleChildren : string[];
    isListPrule : boolean;


    constructor(){
        this.currentPrule = "";
        this.parentPrule = "";
        this.currentEntity = "";
        this.parentEntity = "";
        this.levelList = [];
        this.currentPruleChildren = [];
        this.isListPrule = true;
    }
}