import makeStyles from "@mui/styles/makeStyles";
import {
  KagamiHomeModel,
  HomeModelProps,
  usePageState,
} from "../model/KagamiHomeModel";
import { KagamiHomeController } from "../controller/KagamiHomeController";
import { createState } from "@hookstate/core";
import { KagamiHomeStyle } from "../style/KagamiHome";
import { buildModal } from "../../../../components/modal/controller/KagamiModalController";
import { buildErrorModal } from "../../../../components/errorModal/controller/KagamiErrorModalController";

const useStyles = makeStyles(KagamiHomeStyle);

export const BuildHome = (pageModel: KagamiHomeModel) => {
  return <KagamiHome homeModel={pageModel} />;
};

export const KagamiHome = (homeModelprops: HomeModelProps) => {
  let classes = useStyles();
  let pageState = usePageState(createState(homeModelprops.homeModel));
  homeModelprops.homeModel.state = pageState;
  KagamiHomeController.homeModel = homeModelprops.homeModel;
  return (
    <div className={classes.page}>
      <div>{buildErrorModal(homeModelprops.homeModel.errorModal)} </div>
      <div>{buildModal(homeModelprops.homeModel.dialogModel)} </div>
      {pageState.showDrawer ? (
        <aside className={classes.pageSide}>
          {homeModelprops.homeModel.pageSidebarComponent}
        </aside>
      ) : null}
      <div className={classes.pageconent}>
        <header className={classes.pageheader}>
          {homeModelprops.homeModel.pageHeaderComponent}
        </header>
        <div className={classes.pageDashboard}>
          {homeModelprops.homeModel.state.pageContent != null ? (
            KagamiHomeController.homeModel.pageContentComponent
          ) : (
            <p>dashboard is empty </p>
          )}
        </div>
        <footer className={classes.pageFooter}></footer>
      </div>
    </div>
  );
};
