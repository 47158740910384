import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class TrialBalanceReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  collapseAll:Boolean;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.collapseAll=false;
  }
}
export interface TrialBalanceReportModelProps {
  trialBalanceReportModel: TrialBalanceReportModel;
}
export const useTrialBalanceReportModelState = (state: State<TrialBalanceReportModel>) => {
  let trialBalanceReportState = useState(state);
  return {
    reportConfig() {
      trialBalanceReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      trialBalanceReportState.reportData.set(reportContent);
    }
  };
};
