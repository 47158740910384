import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiPageStyle = (theme: Theme) =>
  createStyles({
    userprofile_list_items: {
      'display': 'flex',
      'width': '100%',
      'paddingBottom': theme.spacing(0),
      'paddingLeft': theme.spacing(0.5),
      'paddingRight': theme.spacing(0.5),
      'paddingTop': theme.spacing(0),
      'marginBottom': theme.spacing(0),
      'boxSizing': 'border-box',
      '&:hover': {
        color: theme.palette.primary.light,
        fontStyle: 'italic',

        cursor: 'pointer'
      }
    },
    userprofile_sublist_processname: {
      display: 'flex',
      width: '100%',
      paddingTop: theme.spacing(0.12),
      paddingBottom: theme.spacing(0.12),
      paddingLeft: theme.spacing(0.25),
      paddingRight: theme.spacing(0.25),
      boxSizing: 'border-box',
      textDecoration: 'none'
    },
    icon: {
      width: '20%',
      padding: '10px',
      margin: '-7px'
    },

    user_profile_processname: {
      'fontSize': theme.spacing(1.25),
      'color': '#666666',

      'margin': 1,
      '&:hover': {
        color: theme.palette.primary.light,
        fontStyle: 'italic',
        cursor: 'pointer'
      }
    }
  });
