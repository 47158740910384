import { createState } from "@hookstate/core";
import { EmbeddedDataRecordModelProps, EmbeddedRowCountModel, useEmbeddedDataRecordModelState } from "./model/EmbeddedRowCountModel"
import React, { useEffect } from "react";



export function buildEmbeddedRowCount(embeddedRowCountModel: EmbeddedRowCountModel) {
  return <EmbeddedRecordCount key={embeddedRowCountModel.formEntityId + Math.random().toString()} embeddedDataRecordModel={embeddedRowCountModel}/>
}


export const EmbeddedRecordCount = (props: EmbeddedDataRecordModelProps) => {
  props.embeddedDataRecordModel.state=useEmbeddedDataRecordModelState(createState(props.embeddedDataRecordModel), props.embeddedDataRecordModel.formEntityId);
  let dataRecordsLength = props.embeddedDataRecordModel.state.getDataCount()


  const counter = dataRecordsLength?.toString();  

  const adjustFontSize = () => {
    const numDigits = counter.length; 
    if(numDigits > 3 ){
      return '10px';
    } else if (numDigits === 3) {
      return '12px';
    } else if (numDigits === 2) {
      return '14px';
    } else {
      return '16px';
    }
  };
  if(dataRecordsLength > 0) {
    return (
    <>
     {/* <span  style={{ color:"#006e7f", border:'1px solid',padding:'1px',borderRadius:'50px',width:'24px', height:'24px', margin:'0px',marginLeft: '5px', lineHeight:'14px',position:'relative',top:'-8px'}}>
        <p style={{ color:"#006e7f",textAlign:'center',width:'16px', height:'16px', margin:'0px',marginLeft: '5px', lineHeight:'14px',fontWeight: '500',fontSize:'12px',position:'relative',top:'4px',left :'-3px'}}>
          {dataRecordsLength}            
        </p>     
      </span> */}
      <div 
      style={{position: 'absolute',
       top:"-5px", right: props.embeddedDataRecordModel.presentationType ==='accordion' ? '35px' :'-5px',
       height:'25px', width:'25px', 
       borderRadius:'50%', 
       border:'1px solid #29947F',
       display:'flex', 
       alignItems:'center', 
       justifyContent:'center' 
       }}>
        <div className="counter" style={{ fontSize: adjustFontSize() , color: '#29947F'}}>{counter}</div>
      </div>
    </>
    )
 }else {
    return <></>
  }   
}