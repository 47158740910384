
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiReportHeaderModelStyle = (theme: Theme) =>
  createStyles({
    root: {
      '& table': {
        'borderCollapse': 'collapse',
        'width': '100%',
        '& tbody': {
          width: '100%'
        },
        '& th': {
          backgroundColor: '#e6e6e6',
          color: '#005ca9',
          fontSize: '13px!important',
          padding: '8px',
          textAlign: 'left',
          verticalAlign: 'middle',
          width: 'calc(100% / 3)'
        }
      },
      '& tr': {
        border: '1px solid #dddddd',
        width: '500px',
        '& td': {
          fontSize:'13px!important',
          padding: '10px',
          minHeight: theme.spacing(6),
          width: '500px',

          '&:last-child': {
            maxWidth: '450px'
          }
        }
      }
    },
    removeIcon: {
      color: `${theme.palette.error.main} !important`
    },
    searchBox_container: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },
    ReportsHeaderName:{
      color: '#00856B',
      margin: '10px'
    }
  });
