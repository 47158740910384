export class UIEvent {
  uiEventName: string;
  uiEventType: string | undefined  | null;
  uiEventValue: string|null;
  uiModalResponse:string | undefined;

  constructor() {
    this.uiEventName = '';
    this.uiEventType = undefined;
    this.uiEventValue = '';
  }
}
