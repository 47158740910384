import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { makeStyles } from '@mui/styles';
import dayjs, { Dayjs } from 'dayjs';
import uniqueId from 'lodash/uniqueId';

import { translateToLocalistaion } from '../../../../../process/service/PresentationService';
import { ReportConstant } from '../../../../view/ReportConstant/ReportConstant';
import { handleReportLinkCLick } from '../../../controller/ReportsLandingPageController';
import { ReportsPageStyle } from '../style/ReportsPageStyle';

const useStyles = makeStyles(ReportsPageStyle);

export const buildReportsPageComponent = (component: any, hideHeader: boolean,translations?:any) => {
  return <ReportsPage key={uniqueId()} component={component} hideHeader={hideHeader} translations={translations}/>;
};

const ReportsPage = (props: any) => {
  const classes = useStyles();
  let content: any;
  let component = props.component;
  if (Array.isArray(component)) {
    content = component.length > 0 ? Object.values(component[0].props)[0] : null;
  } else {
    content = Object.values(component.props)[0];
  }
  if (content !== null) {
    if (content['filters'] !== undefined) {
      return component;
    } else {
      return (
        <div className={classes.main_page_reports}>
          {' '}
          <div className={`${classes.header} `}>
            {' '}
            <div className={classes.header_row}>
              {' '}
              <p className={classes.header_row_reportName}>{translateToLocalistaion(props.translations,content.reportConfig.reportConfigSummary.name)}</p>{' '}
              
            </div>
            {content.reportConfig.dynamicParameterList.length ? (
              <div className={classes.report_header}>
                {' '}
                <div
                  key={`report-header-link-1`}
                  className={classes.report_header_link}
                  onClick={() => handleReportLinkCLick(content.reportConfig, content.reportFilterModel)}
                >
                  <CreateLinkForModel key={`create-link`} content={content} />
                </div>
                <div key={`report-header-link-2`} className={classes.asondate}>{`As On Date : ${dayjs().format('DD/MM/YYYY')}`}</div>
              </div>
            ) : (
              <div className={classes.report_as_on_date}>{`As On Date : ${dayjs().format('DD/MM/YYYY')}`}</div>
            )}
          </div>
          <div className={classes.report_content}> {component} </div>
        </div>
      );
    }
  } else {
    return <BuildNotImplementedReportMessage key={uniqueId()} />;
  }
};

const CreateLinkForModel = (props: any) => {
  let content: any = props.content;
  const classes = useStyles();
  const filterLength = content?.reportFilterModel?.displayFilterValue.length ?? 0;
  return filterLength ? (
    <span key={`header-link`} className={classes.report_header_link_main}>
      <span key={`start-parenthesis`} style={{ color: '#2d72b9' }}>
        ({' '}
      </span>
      <span key={`filter-icon`}>
        <FilterAltIcon sx={{ color: '#2d72b9', fontSize: '1.2rem' }} />
      </span>
      {content.reportFilterModel.displayFilterValue.map((filterSelectedData: any, index: number) => {
        let filterName = filterSelectedData.name;
        let relationName = filterSelectedData.relationName;
        let filterValue = filterSelectedData.value;
        let filterType = filterSelectedData.type;

        return (
          <>
            <span key={`filterName-${index}`} className={classes.report_header_link_main_title}>
              {filterName}
              {'  '}
            </span>
            <span key={`filterRelationName-${index}`} className={classes.report_header_link_main_relationName}>
              {relationName}
              {'  '}
            </span>
            <span key={`filterSelectedValue-${index}`}>
              {((filterType === 'Date' || filterType === 'DateTime') &&
                relationName === ReportConstant.Is_In_The_Range) ||
              relationName === ReportConstant.Is_Not_In_The_Range ? (
                <>
                  {filterValue.split(',').map((value: any, index: number) => {
                    return (
                      <>
                        <span key={`Filter${filterType}Label-${index}`}>
                          {index === 0 ? (
                            <>From{'  '}</>
                          ) : (
                            <>
                              {'  '}To{'  '}
                            </>
                          )}{' '}
                        </span>
                        <span key={`Filter${filterType}Value-${index}`}>{value}</span>
                      </>
                    );
                  })}
                </>
              ) : filterType === 'Number' &&
                (relationName === ReportConstant.Is_In_The_Range ||
                  relationName === ReportConstant.Is_Not_In_The_Range) ? (
                <>
                  {filterValue.split(',').map((value: any, index: number) => {
                    return (
                      <>
                        <span key={`FilterNumberLabel-${index}`}>
                          {index === 0 ? (
                            <>Min{'  '}</>
                          ) : (
                            <>
                              {'  '}Max{'  '}
                            </>
                          )}{' '}
                        </span>
                        <span key={`FilterNumberValue-${index}`}>{value}</span>
                      </>
                    );
                  })}
                </>
              ) : (
                <>{filterValue}</>
              )}
            </span>
            <span key={`split-with-comma-${index}`}>{filterLength !== index + 1 ? <>, </> : <></>}</span>
          </>
        );
        // }
      })}
      <span key={`end-parenthesis`} style={{ color: '#2d72b9' }}>
        {'  '})
      </span>
    </span>
  ) : (
    <></>
  );
};

export const BuildNotImplementedReportMessage = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '65vh'}}>
      {' '}
      <h2 style={{color: '#205e8b'}}>Work in progress update soon...!</h2>
      <p style={{color: '#205e8b'}}>Click to report icon in the navigation</p>
    </div>
  );
};
