import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles'; 

export const KagamiModalDialogStyle = (theme: Theme) =>
  createStyles({
    modalDialogTemplateRoot: {
      overflow: 'auto',
      display: 'block'
    },
    modalPaper: {
      // [theme.breakpoints.up('sm')]: {
      //   // maxWidth: theme.spacing(62.5),
      //   // height:'300px',
      //   // maxWidth:'auto',
      //   margin: 'auto',
      //   marginTop: `${theme.spacing(16.5)} !imporatant`
      // },
      borderRadius: "8px",
      overflowY: 'inherit',
     
    },
    modalPaperMaxHeight:{
      borderRadius: "8px",
      overflowY: 'inherit',
      height:'100%'
    },
    modalHeader: {
      borderBottom: '1px solid #ccc',
      padding:'10px',
      paddingLeft:'35px',
      borderTopLeftRadius:'8px',
      borderTopRightRadius:'8px',
      // paddingRight: theme.spacing(3),
      // paddingTop:'8px',
      // paddingBottom:'8px',
      // paddingLeft: theme.spacing(3),
      minHeight: theme.spacing(2.1),
      // cursor: 'move',
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
      // color: theme.palette.common.black
      textTransform : 'capitalize',
       
      // borderBottom: '1px solid #eee',
      // paddingTop: theme.spacing(1),
      // paddingRight: theme.spacing(3),
      // paddingBottom: theme.spacing(1),
      // paddingLeft: theme.spacing(3),
      // minHeight: theme.spacing(1.1),
      // // cursor: 'move',
      // backgroundColor: theme.palette.background.default,
      // color: theme.palette.secondary.main
    },
    modalTitle: {
      margin: '0',
      // lineHeight: '1.5',
      // textAlign: 'center'
    },
    modalTitle_for_error: {
      margin: '0',
      lineHeight: '1.5'
    },
     
   
    modalBody: {
      paddingTop: `${theme.spacing(3)} !important`,
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      position: 'relative',
      // overflow: 'visible',
      wordBreak: 'break-all',
      Width: '100%',
      textAlign: 'start',
      float: 'left',
      color: theme.palette.common.black,
      background: theme.palette.background.paper,
      fontSize: theme.spacing(2), 
      '& .hide-buttons-from-desktop-report-container': {
        display: 'none'
      },
      '& .isPickItem': {
        // height:'calc(100vh - 150px)',
        height:' 100%',
        maxHeight: 'calc(100vh - 150px)',
      },
      '& .MuiFormLabel-root ' : {
        fontSize:'13px'
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor:'#f5f8fa',
        '&.Mui-focused fieldset': {
          border: '0.8px solid',
          borderColor: theme.palette.secondary.main
        },

      
      },
      '& textarea' : {
        backgroundColor:'#f5f8fa',
        fontFamily:"'Lexend deca',sans-serif",
        borderColor:'rgba(0, 0, 0, 0.23)',
        borderRadius:'4px',
        padding:'5px 10px',
        maxWidth:'100%',
        minWidth: '100%',
        '&:hover': {
          borderColor:theme.palette.secondary.main
        },
      },
      '& textarea:focus':{ 
        minHeight:' 65px',
        outlineColor:theme.palette.secondary.main,
        outline:'1px !important',
        // borderColor:' red',
        // boxShadow:' 0 0 10px #719ECE',
        borderWidth:'1px !important', 
        borderColor:theme.palette.secondary.main,
        

    },
    },
    modalFooter: {
      // padding: theme.spacing(0),
      // textAlign: 'center',
      // paddingTop: 0,
      // margin: 0,
      // background: theme.palette.background.default,
      // boxShadow: theme.shadows[1],
      // borderTop: '0.5px solid #ccc'
      padding: theme.spacing(0),
      textAlign: 'center',
      paddingTop: 0,
      margin: 0,
      backgroundColor: theme.palette.background.default,
      // background: theme.palette.info.light,
      boxShadow: theme.shadows[1],
      borderTop: '0.5px solid #ccc',
      borderBottomLeftRadius:'8px',
      borderBottomRightRadius:'8px'
    
    },
    // modalWithoutActions: {
    //   borderBottomLeftRadius:'8px',
    //   borderBottomRightRadius:'8px'
    // },
    modalFooterCenter: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },

    
    model_button: {
      margin: theme.spacing(0.5),
      // color: theme.palette.background.paper,
      // background: theme.palette.primary.dark,
      // borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.secondary.main,
      color:'#fff',
      '&:hover': {
        // backgroundColor:`${ theme.palette.secondary.main}+'dd'`,
        backgroundColor: '#006e7fdd'
        
        // color:'#fff'
      }
      // '&:hover':{
      //   background: theme.palette.secondary.dark,
      //   color: theme.palette.common.black,
      //   borderColor: theme.palette.secondary.dark,
      // }
    },
    model_buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(0.5)
    },
    model_icon_error: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiButtonBase-root': {
        // top: "-14px",
        right: "-10px",
        padding: "0 !important"
      },
    },
    model_button_disabled: {
      backgroundColor: 'grey'
    },
    paperFullWidth: {
      // overflowY: 'visible'
    },
    dialogContentRoot: {
      overflowY: 'visible'
    },
    closeModalBtn: {
      position: 'absolute',
      top: '-15px',
      right: '-15px',
      width: '30px',
      height: '30px',
      padding: '1px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '100%',
      boxShadow: '0 3px 10px 2px rgb(0 0 0 / 0.2);',
      cursor: 'pointer',
      '&:hover': {
        // height: '20px',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.error.main
      }
    },
  });
