import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiInpasinateStyles = (theme: Theme) =>
  createStyles({
    inputContainer: {
      width: '100% !important',
      textAlign: 'left',
      paddingTop: '10px'
    },
    label: {
      textAlign: 'left',
      margin: '10px 0 0 0',
      color: 'rgb(41, 148, 127)',
      fontSize: '13px'
    },
    input: {
      width: '100%',
      textAlign: 'left'
    },
    star: {
      color: 'red'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px'
    },
    button: {
      color: 'white',
      border: '1px solid #006e7f',
      backgroundColor: '#006e7f',
      borderRadius: '5px',
      '&:hover': {
        background:'#006e7f'
      },
    },
  });
