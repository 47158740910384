import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiImageFieldStyle = (theme: Theme) =>
  createStyles({
    root: {
      // marginTop: theme.spacing(-1.375),
      // alignItems: 'center'
      display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    },

    // controlLabel: {
    //   '&.MuiFormLabel-root ': {
    //     // paddingLeft: theme.spacing(2.1),
    //     fontSize: '13px',
    //     color: theme.palette.info.dark
    //   }
    // },
    // commonFlexOptions: {
    //   'display': 'flex',
    //   'alignItems': 'center',
    //   'flexDirection': 'row-reverse',
    //   'width': 'fit-content',
    //   'fontSize': '14px',
    //   'padding': `0 ${theme.spacing(1)}`,
    //   '& .MuiAvatar-root': {
    //     'height': 'fit-content',
    //     '& .MuiAvatar-img': {
    //       width: '50%',
    //       height: '50%'
    //     }
    //   }
    // },
    // agGridRoot: {
    //   '&  .ag-react-container': {
    //     height: '100%',
    //     display: 'flex',
    //     width: '100%',
    //     alignItems: 'center'
    //   }
    // },
    // image: {
    //   backgroundImage: 'none',
    //   backgroundSize: 'cover',
    //   height: '75px',
    //   width: '75px',
    //   borderRadius: '25em',
    //   objectFit: 'cover',
    //   // display: 'block',
    //   float: 'left',
    //   border: '2px dotted #d4d4d4',
    //   position: 'relative',
    //   textDecoration: 'none'
    // },
    // multiImage: {
    //   'width': '150px',
    //   'height': '150px',
    //   'borderRadius': '2px',
    //   'margin': '2px',
    //   'border': '1px solid #ccc',
      
    // },
    // cancelIcon: {
    //   position: 'absolute',
    //   top: '0px',
    //   right: '0px',
    //   backgroundColor: '#fff',
    //   height: '20px',
    //   width: '20px',
    //   borderRadius: '100%',
    //   zIndex: 100
    // },
    // pdfOverlay: {
    //   position: 'absolute',
    //   top: '0px',
    //   left: '0px',
    //   backgroundColor: 'rgba(0, 0, 0, 0.4)',
    //   height: '100%',
    //   width: '100%',
    //   zIndex: 99,
    //   borderRadius: '2px',
    //   display: 'flex',
    //   justContent: 'center',
    //   alignItems: 'center',
    //   opacity: 0
        
    // },
    // dropZone:{
    //   margin: '0',
    //   minHeight: '100px !important',
    //   '& .MuiTypography-root' :{
    //     margin: '10px !important',
    //     fontSize: '20px'
    //   }
    // }


    dropZone: {
      display: 'flex',    
      flexDirection: 'column'  ,
      '& .MuiDropzoneArea-text ' : {
        color:'rgba(0, 0, 0, 0.4)'
      } ,
      '& .MuiDropzoneArea-icon ' : {
        color:theme.palette.secondary.main
      },
      '& .MuiDropzonePreviewList-removeButton' : {
        color:theme.palette.error.main
      },
    },
    previewContainer: {
      
      marginTop:'0',
      marginLeft:'0'
    },
    preview: {
     
      paddingLeft:'0 !important',
      paddingTop:'0 !important'
    },
    previewImg: {
     
    },
  });
