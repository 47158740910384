import moment from 'moment';
import { KagamiDMSModel } from '../model/kagamiDMSModel';

export class KagamiDMSServices {
  static setPdfUrl(kagamiDml: KagamiDMSModel) {
    const token = localStorage.getItem('token')
    fetch(kagamiDml.dmsResponseUrl, {
      method: 'GET',
      // credentials: "omit",
      headers: {
        'content-type': 'application/pdf',
        'accept': 'application/pdf, text/plain, */* ',
        'access-control-allow-origin': '*',
        'utcoffset' :  moment().utcOffset().toString(),
        'authorization' : `kreact ${token}`
      }
    })
      // .then((resp) =>  resp.blob())
      .then((resp) => {
        debugger;
        console.log('resp', resp);
        if (resp.status == 500) {
          kagamiDml.isRespSuccess = false;
        } else {
          kagamiDml.isRespSuccess = true;
        }
        return resp.blob();
      })
      .then((myBlob) => {
        // kagamiDml.state.setPdfUrl(URL.createObjectURL(myBlob));
        kagamiDml.pdfUrl = URL.createObjectURL(myBlob);
        kagamiDml.state.setPdfUrlLoaded(true);
      })
      .catch((err) => {
        console.log(err.message());
      });
  }
}
