import { KagamiFormPresentationModel } from '../../form-presenation/model/KagamiFormPresentationModel';
import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';

export class KagamiMatrixFormModel {
  matrixFormModels: KagamiFormPresentationModel[];
  incrementIndex: number;
  decrementIndex: number;

  constructor() {
    this.matrixFormModels = [];
    this.incrementIndex = 0;
    this.decrementIndex = 0;
  }
}

export interface KagamiMatrixFormProps {
  matrixModel: KagamiMatrixFormModel;
}

export const wrapKagamiMatrixFormModelState = (state: State<KagamiMatrixFormModel>) => {
  const kagamMatrixFormState = state;

  return {
    get getIncrementIndex() {
        return kagamMatrixFormState.incrementIndex.get();
    },

    setIncrementIndex(incrementIndex: number) {
        kagamMatrixFormState.incrementIndex.set(incrementIndex);
    },

    get getDecrementIndex() {
        return kagamMatrixFormState.decrementIndex.get();
    },
  
    setDecrementIndex(decrementIndex: number) {
        kagamMatrixFormState.decrementIndex.set(decrementIndex);
      }
  };
};

export const useKagamiMatrixForm = (state: State<KagamiMatrixFormModel>) => {
  let matrixFormState = wrapKagamiMatrixFormModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.MATRIX_FORM_STATE, matrixFormState);
  return matrixFormState;
};
