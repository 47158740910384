import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
export const KagamiCardStyle = (theme: Theme) =>
  createStyles({
    cardWrapper: {
      minHeight: theme.spacing(33.75),
      position:'relative'
    },
    col5ths: {
      width: '20%',
      float: 'left',
      position: 'relative',
      paddingTop: theme.spacing(1.25),
      paddingLeft: theme.spacing(1.25),
      [theme.breakpoints.up('xs')]: {
        width: '50%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '33%',
      },
      [theme.breakpoints.up('md')]: {
        width: '20%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '20%',
      },
      
    },
    card: {
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '0',
      wordWrap: 'break-word',
      boxSizinng: 'border-box',
      border: '0',
      boxShadow: '0 2px 4px rgb(0 0 0 / 20%)',
      // marginBottom: theme.spacing(3.125),
      borderRadius: '3px'
    },
  card1: {
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '0',
      wordWrap: 'break-word',
      boxSizinng: 'border-box',
      boxShadow: '0 2px 4px rgb(0 0 0 / 20%)',
      // marginBottom: theme.spacing(3.125),
      borderRadius: '3px'
  },
    arrowbox:{
      width:'95%',
      float:'right',
      marginBottom: theme.spacing(2),
    },
    icon: {
      color: theme.palette.success.main,
      float: 'right',
      fontSize: theme.spacing(3)
    },
    cardMediaWrapper: {
      textAlign: 'center',
      width: '100%',
      float: 'left'
    },
    image: {
      borderRadius: '50%',
      width: '100px',
      height: '100px',
      textAlign: 'center',
      marginTop: theme.spacing(3.125),
      background: theme.palette.info.light,
      display: 'inline-block',
      objectFit: 'contain'
    },
    cardTextBlock: {
      padding: `0 ${theme.spacing(1.875)}`
    },
    cardstatus: {
      // display: 'flex',
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    column_name: {
      // float: 'left',
      color: theme.palette.grey[500],
      // fontWeight: theme.spacing(50),
      fontSize: theme.spacing(1.5),
    },
    column_value: {
      paddingLeft:'3px',
      cursor: 'pointer',
      // color: '#13344d',
      color: theme.palette.primary.main,
      fontSize: theme.spacing(1.5),
      
    },
    popoverContent: {
      minWidth: '150px',
      // width:'150px',
      maxWidth:'250px',
      padding: theme.spacing(2),
    },
    // popoverColumn: {
    //   marginBottom: theme.spacing(1)
    // },
    column_result: {
      color: theme.palette.primary.dark
    }
  });
