import React, { Component } from "react";
import {
  getNetworkConnection,
  NETWORKCHECK_URL,
} from "../../application/service/URLService";
import makeStyles from "@mui/styles/makeStyles";
import { NetworkDetectorStyle } from "../style/NetworkDetectorStyle";
import Typography from "@mui/material/Typography";
import { SystemConstants } from "../../application/constants/SystemConstants";
import { APIService } from "../../application/service/ApiService";

const useStyles = makeStyles(NetworkDetectorStyle);
export default function NetworkDetector() {
  const [isDisconnected, setIsDisconnected] = React.useState(false);
  const classes = useStyles();
  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      let callInProgress: boolean = false;
      const webPing = setInterval(() => {
        if (!callInProgress) {
          callInProgress = true;
          APIService.getData(SystemConstants.GET, getNetworkConnection(), null)
            .then((res: any) => {
              callInProgress = false;
              if (res.data.status == "UP") {
                setIsDisconnected(false);
              } else setIsDisconnected(true);

              clearInterval(webPing);
            })
            .catch(() => {
              callInProgress = false;
              setIsDisconnected(true);
            });
        }
      }, 2000);
      return;
    }

    return setIsDisconnected(true);
  };

  React.useEffect(() => {
    handleConnectionChange();
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  return (
    <>
      {isDisconnected && (
        <div
          className={
            isDisconnected
              ? `${classes.isDisconnected}  ${classes.root}`
              : classes.root
          }
        >
          <Typography variant="subtitle1" className={classes.errorMessage}>
            Bad Connection : Please Check your connection and try again
          </Typography>
        </div>
      )}
    </>
  );
}
