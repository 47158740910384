import * as React from 'react';
import { createState ,useState } from '@hookstate/core';
import {
  PaymentDueListReportModel,
  PaymentDueListReportModelProps,
  usePaymentDueListReportModelState
} from '../model/PaymentDueListReportModel';
import { makeReport,makeLedgerData ,getData,onChangeState,onChangeAccount,downloadExcel,downloadPDF} from '../service/PaymentDueListReportService';
import Grid from '@mui/material/Grid';
import { Autocomplete,TextField,Button,ButtonGroup } from '@mui/material';
import {  AgGridReact } from 'ag-grid-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';


export const BuildPaymentDueListReportView = (paymentDueListReportModel: PaymentDueListReportModel) => {
  return (
    <PaymentDueListReportView key={'paymentDueList-report-view'} paymentDueListReportModel={paymentDueListReportModel}></PaymentDueListReportView>
  );
};


const PaymentDueListReportView = (props: PaymentDueListReportModelProps) => {
     //Open
     const [paymentDueListAsOnDate, setPaymentDueListAsOnDate] = React.useState(false);
     // Date
     let todayDate = new Date().toISOString().substring(0, 10);
     const [asonDate, setAsonDate] = React.useState(todayDate);
     
  let runReportConfig:any = useState({
    loadReport : false,
    loadFilterData:true,
    showLoader:false,
    reportInfo:{}
  });
  let paymentDueListReportModel = props.paymentDueListReportModel;
  let paymentDueListChartState = usePaymentDueListReportModelState(createState(paymentDueListReportModel));
  paymentDueListReportModel.state = paymentDueListChartState;
  let staticFilterData :any;
  if(paymentDueListReportModel?.reportData?.staticFilterData) {
    staticFilterData  = paymentDueListReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo:any ={}
  if(paymentDueListReportModel?.reportData?.gridReportInfo) {
    gridReportInfo  = paymentDueListReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig= (props.paymentDueListReportModel.reportConfig);
  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo =  makeReport(paymentDueListReportModel,inputReportConfig);
    let paymentDueListData =  await getData(inputReportConfig);
    let rowData = await makeLedgerData(paymentDueListData,inputReportConfig);
    reportInfo['rowData'] = rowData;
    const obj = Object.assign({}, reportInfo);
    props.paymentDueListReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }

  const handleAccountChange =  async (value:any) => {
    let  gstData  = await  onChangeAccount(value,paymentDueListReportModel);
    staticFilterData['gst'] =gstData;
    runReportConfig.loadFilterData.set(true);
  }
  const handleStaticFilters = (value: any, type: any) => {
    if (type == 'endDate') {
      if (!inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {};
      }
      inputReportConfig['leftPanel'][type] = value;
    } else if (type == 'state' || type == 'projectPlan') {
      inputReportConfig[type] = value && value.length > 0 ? value.join(",") : [];
    } else {
      inputReportConfig[type] = value;
    }
  }
  const onDownloadExcel = () =>{
    downloadExcel(props.paymentDueListReportModel);
  }
  const onDownloadPDF = () =>{
    downloadPDF(props.paymentDueListReportModel);
  }

  return (
    <div className="ag-theme-alpine static-report-container static-report-wbp static-report-grid" >
      {/* fields  */}
      <Grid container spacing={{ xs: 2, md: 3 }}>
     
        {/* Sub Organization */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="paymentDueListSubOrganization"
            options={staticFilterData  &&  staticFilterData.suborganization||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                label="Sub Organization"
                placeholder="Select Sub Organization"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters([newValue],'subOrganization');
            }}

          />
        </Grid>
        {/*  PaymentDueList List */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="paymentDueListAccount"
            options={staticFilterData  &&  staticFilterData.accountResult||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                label="Payment Due List"
                placeholder="Select Payment Due List"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleAccountChange(newValue);
              handleStaticFilters(newValue,'selectedValue');
            }}
          />
        </Grid>
        {/* Currency */}
        {staticFilterData?.currency && staticFilterData.currency.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="paymentDueListCurrency"
            options={staticFilterData  &&  staticFilterData.currency||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                label="Currency List "
                placeholder="Select Currency"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'selectedCurrency');
            }}
          />
          </Grid>  
          }
        
           {/* Date */}
      <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              open={paymentDueListAsOnDate}
              onOpen={() => setPaymentDueListAsOnDate(true)}
              onClose={() => setPaymentDueListAsOnDate(false)}
              label = {"Date"}
              value={asonDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setAsonDate(newDate);
                  handleStaticFilters(newDate+" 23:59:59",'asonDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                    {...params}
                    className='full-width'
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setPaymentDueListAsOnDate(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>


      </Grid>
        {/* fields end */}
      {/* Run */}
      <Grid container style={{ margin: '10px',textAlign:'right',paddingRight:'10%'}} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        <Grid item xs={12}>
        <ButtonGroup  className="static-report-btngroup" variant="text" aria-label="text button group">
        <Button variant="contained" onClick={() => { runReport()}}>
            Run
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onDownloadExcel()}>
            Download Excel
        </Button>
        <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onDownloadPDF()}>
            Print
        </Button>
        </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '10px',textAlign:'center'}}  >
        <Grid item xs={12}>
        {runReportConfig.showLoader.get()  && 
              <CircularProgress color="secondary" />
        }
        </Grid>
      </Grid>
      {runReportConfig.loadReport.get()  && 
      <div className="ag-theme-alpine kgm-report-aggrid kgm-report-hwm dashboard-report" >
        <AgGridReact 
          columnDefs={gridReportInfo.columnDefs}
          defaultColDef={gridReportInfo.defaultColDef}
          getRowHeight = {gridReportInfo.getRowHeight}
          groupIncludeTotalFooter = {gridReportInfo.groupIncludeTotalFooter}
          icons = {gridReportInfo.icons}
          pagination = {gridReportInfo.pagination}
          paginationPageSize = {gridReportInfo.paginationPageSize}
          rowHeight =   {gridReportInfo.rowHeight}
          rowData={gridReportInfo.rowData}
          suppressAggFuncInHeader={gridReportInfo.suppressAggFuncInHeader}
         ></AgGridReact>
        </div>
        }
      </div>
  );
};
