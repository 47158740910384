import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import {
  DashboardNotificationProps,
  DashboardNotificationSectionProps,
} from '../model/KagamiDashboardModel';
import { deleteNotificationById, groupByWidgetType } from '../service/DashboardService';
import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import { KagamiAccordionModel } from '../../../../../../../../components/accordion/model/KagamiAccordionModel';
import { buildAccordion } from '../../../../../../../../components/accordion/controller/KagamiAccordionController';
import { KagamiPopoverModel } from '../../../../../../../../components/popover/model/KagamiPopoverModel';
import { buildPopover } from '../../../../../../../../components/popover/controller/KagamiPopoverController';
import { executeProcess } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { MenuItem, MenuList } from '@mui/material';
import { KagamiModalModel } from '../../../../../../../../components/modal/model/KagamiModalModel';
import { buildModal } from '../../../../../../../../components/modal/controller/KagamiModalController';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { buildDashboardFavouriteSection } from './FavouriteBuilder';
import Badge from '@mui/material/Badge';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Fragment } from 'react';
import { buildTriggerModel, enableTrigger } from '../../process/builder/Triggers/service/KagamiTriggersService';
import { ContextType } from '../../process/builder/Triggers/builder/trigger/model/KagamiTriggerModel';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { DashboardGrid, highPriorityWidget } from '../ui/KagamiDashboard';
import { translateToLocalistaion } from '../../process/service/PresentationService';
import { getActiveModel } from '../../process/controller/KagamiProcessController';
import highpriority from '../../../../../../../../assets/images/highpriority.svg';

const useStyles = makeStyles(KagamiDashboardStyle);

export function buildDashboardNotificationSections(dashboardModel: any) {
  const notificationData = dashboardModel.notificationData;
  const favouritesData = dashboardModel.state.getfavouritesData;
  const notificationComponents: any[] = [];
  const notifcationGroups: Map<string, any[]> = groupByWidgetType(notificationData);
  // const favouritesGroups :  Map<string, any[]> = groupByFavourites(dashboardModel.favouritesData)
  let statusModel: KagamiModalModel = new KagamiModalModel();
  notifcationGroups.forEach((value: any[], key: string) => {
    notificationComponents.push(
      <DashboardNotificationSection
        key={key}
        header={key}
        notifications={value}
        statusModel={statusModel}
        dashboardModel={dashboardModel}
      />
    );
  });
  let favData = favouritesData ? JSON.parse(JSON.stringify(favouritesData)) : [];
  notificationComponents.unshift(buildDashboardFavouriteSection(favData));
  return (
    <Fragment key={'dashboard_notification'}>
      {/* {notificationComponents} -hc */}
      <DashboardGrid
        key={'dashboard-notification-layout'}
        component={notificationComponents}
        dashboardModel={dashboardModel}
      />{' '}
      {/* added this*/}
      {/* { CommonUtils.isNotEmpty(favouritesData)  && favouritesData.length > 0? buildDashboardFavouriteSection(favouritesData):null} */}
      {/* {buildDashboardFavouriteSection(favouritesData)} -hc */}
      {buildModal(statusModel)}
    </Fragment>
  );
}

export const DashboardNotificationSection = (props: DashboardNotificationSectionProps) => {
  const classes = useStyles();

  //returns sub groups
  let groupAccordions = Array.from(new Set(props.notifications.map((notification) => notification.displayName)));

  let toiterate = (item: any, list: any) => {
    let resArr: any[] = [];

    list.map((i: any) => {
      if (item == i.displayName) {
        resArr.push(i);
      }
    });
    return resArr;
  };
  let notificationsToiterate = Array.from(
    groupAccordions.map((item: any) => [item, toiterate(item, props.notifications)])
  );

  const title: JSX.Element = (
    <div>
      <div className={classes.dashboard_icon_header}>
        {props.header != 'Favourites' ? props.header.toLowerCase() === highPriorityWidget.toLowerCase() ? <img
             src={highpriority}
             alt='no image'
             style={{ height: 27, width: 30, marginLeft: '8px'}}
             className = 'highpriority-icon-color' 
           />: (
          <InfoIcon className={classes.dashboard_icon}></InfoIcon>
        ) : ( 
          <StarBorderOutlinedIcon className={classes.dashboard_icon}></StarBorderOutlinedIcon>
        )}
        {props.header != 'INFO' && props.header != 'Favourites' ? (
          <>
            {' '}
            <span className={`${classes.dashboard_icon_header } ${props.header.toLowerCase() == highPriorityWidget?.toLowerCase() ? 'highpriority-widget-title' :'' }`} >{props.header}</span>
            {/* <span className={classes.notifications_length}>{props.notifications.length}</span> */}
            {props.header.toLowerCase()!== highPriorityWidget.toLowerCase() ?<Badge badgeContent={props.notifications.length} color="secondary">
              <MailOutlineIcon color="primary" />
            </Badge>: <div className="highpriority-badge-body">
              <p className="highpriority-badge-count">
                {props.notifications.length}
              </p>
            </div>
            }
          </>
        ) : (
          <span className={classes.dashboard_header}>{props.header}</span>
        )}
      </div>
    </div>
  );

  const active = true;

  const content: JSX.Element = (
    <div>
      {groupAccordions.length > 1 || props.header === 'INFO' ? (
        <BuildSubAccords
          list={notificationsToiterate}
          statusModel={props.statusModel}
          dashboardModel={props.dashboardModel}
          header={props.header}
        />
      ) : (
        props.notifications.map((notification) => {
          return <DashboardNotification key={Math.random()} notification={notification} header={props.header} />;
        })
      )}
    </div>
  );
  const accordionModel: KagamiAccordionModel = new KagamiAccordionModel(title, content, active);
  return <div className={`col_4 ${classes.dashboardNotificationSectionRoot}`}> {buildAccordion(accordionModel)}</div>;
};

export function BuildSubAccords(props: any) {
  const { list, statusModel, dashboardModel, header } = props;
  const classes = useStyles();
  let translations = dashboardModel?.containerData?.otherProperties?.translation;

  return (
    <>
      {list.length === 0 ? (
        <p>{translateToLocalistaion(translations, "No Notifications")}</p>) : (
        list.map((item: any) => {
          let subTitle: JSX.Element = (
            <div className={classes.dashboard_icon_header}>
              <span className={classes.dashboard_header}>{item[0]}</span>
              <Badge badgeContent={item[1].length} color="secondary">
                <MailOutlineIcon color="primary" />
              </Badge>
              {/* <span className={classes.notifications_length}>{item[1].length}</span> */}
            </div>
          );
          let active = false;
          let subcont: JSX.Element = item[1].map((notification: any) => {
            return (
              <DashboardNotification
                header={header}
                key={Math.random()}
                notification={notification}
                statusModel={statusModel}
                dashboardModel={dashboardModel}
              />
            );
          });
          const accordionModel: KagamiAccordionModel = new KagamiAccordionModel(subTitle, subcont, active);
          return buildAccordion(accordionModel);
        })
      )}
    </>
  );
}
export const DashboardNotification = (props: DashboardNotificationProps) => {
  const { notification, statusModel, dashboardModel, header } = props;
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  const notId = notification['notificationId'];
  let popupModel: KagamiPopoverModel = new KagamiPopoverModel();
  let statusModelPopup: KagamiModalModel = new KagamiModalModel();
  const classes = useStyles();

  const handleDeleteNotification = () => {
    deleteNotificationById(notId, dashboardModel!);
  };

  const handleClick = (
    processName: string,
    detailedObject: any,
    entityId: string,
    popoverModel: KagamiPopoverModel,
    action: any
  ) => {
    if (action.confirmation) {
      statusModelPopup.state.setOpen(true);
      statusModelPopup.content = action.confirmationMsg;
      statusModelPopup.title = action.actionName;
      statusModelPopup.handleOnOk = handleOnOkAction;
    } else {
      handleOnOkAction();
    }
    function handleOnOkAction() {
      executeProcess(processName, detailedObject);
    }
    popoverModel.state.setPopoverTarget(null);
  };
  const handleIconColor = (notification: any, theme: any) => {
    let color: any = CommonUtils.isNotEmpty(notification.colorName) ? notification.colorName : theme.palette.info.dark;
    return color;
  };
  let triggerModels: any[] =
    notification.hasOwnProperty('actions') && notification['actions'].length > 0
      ? getEnabledTriggerModels(notification, handleClick)
      : [];
  let MenuComponent = (
    <div className={classes.notificationMenu}>
      <MenuList style={{ padding: '0' }}>
        {triggerModels.map((item: any) => {
          return (
            <>
              <MenuItem
                className={classes.PopoverMenuItem}
                onClick={() =>
                  handleClick(
                    item.processName,
                    notification.transactionEntity ? notification.transactionEntity : {},
                    notification.transactionEntity ? notification.transactionEntity.entityType : '',
                    popupModel,
                    item
                  )
                }
              >
                {/* {item.actionName} */}
                {translateToLocalistaion(translations, item.labelName)}
              </MenuItem>
            </>
          );
        })}
      </MenuList>
    </div>
  );
  return (
    <>
      {header !== 'INFO' ? (
        <div className={`${classes.dashboardNotificationRoot} ${props.header.toLowerCase() === highPriorityWidget.toLowerCase()?'highpriority-row-content':''}`}>
          <div className={`${classes.dashboard_notification_header} highpriority-row`}>
            {/* <span className={classes.dashboard_notification_header}> */}
            <FiberManualRecordIcon
              sx={{
                color: (theme: any) => handleIconColor(notification, theme)
              }}
              fontSize="small"
              className={classes.dashboard_notofication_circleicon}
            ></FiberManualRecordIcon>
            <span
              className= {`${classes.dashboard_notification_message } ${props.header.toLowerCase() == highPriorityWidget?.toLowerCase() ? 'highpriority-widget-text' :'' }`}
              onClick={() => executeProcess(notification.detail, notification.transactionEntity)}
            >
              {notification['message']} -{' '}
              <span  className= {`${classes.dashboard_notification_timedifference } ${props.header.toLowerCase() == highPriorityWidget?.toLowerCase() ? 'highpriority-widget-timediff' :'' }`}  >{notification['timeDiffMessage']}</span>
            </span>
            {/* </span> */}
          </div>

          {triggerModels.length > 0 ? (
            <>
              <IconButton
                size="small"
                style={{ padding: '2px' }}
                onClick={(event: any) => {
                  popupModel.transactionId = notification['transactionEntity'];
                  //MenuComponent holds a component after all trigger policies are executed
                  popupModel.child = MenuComponent;
                  popupModel.state.setPopoverTarget(event.currentTarget);
                }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
              {buildPopover(popupModel)}
              {buildModal(statusModelPopup)}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          <div className={classes.dashboardNotificationRoot}>
            <div>
              <span className={classes.dashboard_notification_header}>
                <FiberManualRecordIcon
                  sx={{
                    color: (theme: any) => handleIconColor(notification, theme)
                  }}
                  fontSize="small"
                  className={classes.dashboard_notofication_circleicon}
                ></FiberManualRecordIcon>
                <p
                  className={classes.dashboard_notification_message}
                  onClick={() => handleDetailModal(notification, statusModel)}
                >
                  {notification['message']}
                  <span className={classes.dashboard_notification_timedifference}>
                    {'-' + ' ' + notification['timeDiffMessage']}
                  </span>
                </p>
              </span>
            </div>
            <>
              <IconButton size="small" style={{ padding: '2px' }} onClick={handleDeleteNotification}>
                <CancelPresentationOutlinedIcon fontSize="small" color="error" />
              </IconButton>
            </>
          </div>
        </>
      )}
    </>
  );
};

const handleDetailModal = (notification: any, statusModal: KagamiModalModel) => {
  // const htmlToString = notification.detail.replace(/<[^>]+>/g, '');
  // statusModal.content = htmlToString;
  statusModal.content = (
    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: notification.detail }}></div>
  );
  statusModal.title = notification.message;
  statusModal.state.setOpen(true);
};

//Notification menus are built  based on trigger policies
export const getEnabledTriggerModels = (notification: any, handleClick: any) => {
  let enabledTriggers: any = [];
  {
    notification.actions.map((item: any) => {
      let triggerModel = buildTriggerModel(
        notification.transactionEntity ? notification.transactionEntity['entityType'] : '',
        item,
        handleClick,
        false
      );
      triggerModel.detailedObject = notification.transactionEntity;
      triggerModel.contextType === ContextType.None
        ? (triggerModel.enableTrigger = true)
        : (triggerModel.enableTrigger = false);

      triggerModel.policy = item.policy;
      if (triggerModel.detailedObject) {
        triggerModel.enableTrigger = enableTrigger(
          triggerModel,
          triggerModel.detailedObject instanceof Array ? triggerModel.detailedObject : [triggerModel.detailedObject],
          triggerModel.enableTrigger
        );
      }
      triggerModel.enableTrigger && enabledTriggers.push(item);
    });
  }
  return enabledTriggers;
};
