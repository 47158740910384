import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  KagamiFolderTileProps,
  KagamiReportTileProps,
  ParentId,
  ReportLandingPageModel
} from '../model/ReportsLandingPageModel';
import { reportsLandingPageStyle } from '../style/ReportsLandingPage';
import fallbackimage from '../../../../../../../../../assets/images/report_icons/fallbackimage.png';
import folderIcon from '../../../../../../../../../assets/images/folderIcon.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  getLandingPageModel,
  handleFolderTileClick,
  handleReportTileClick
} from '../controller/ReportsLandingPageController';
import { getReportsIcons } from '../service/GetReportsIconsUtils';
import IconButton from '@mui/material/IconButton';
import { CommonUtils } from '../../../../../../../../utils/CommonUtils';
import { SystemConstants } from '../../../../../../../../constants/SystemConstants';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteReportFolder, deleteReport } from '../../view/report/service/KagamiReportService';
import { translateToLocalistaion } from '../../../process/service/PresentationService';

const useStyles = makeStyles(reportsLandingPageStyle);

// building landing page report and folder tiles below
export const buildLandingPage = (reportLandingPageModel: ReportLandingPageModel) => {
  const tiles: any[] = [];
  reportLandingPageModel.newFolderList !== undefined &&
    reportLandingPageModel.newFolderList.map((folderConfig: any) => {
      const folderTiles = (
        <FolderTile
          key={`folder_${folderConfig.id}`}
          folderConfig={folderConfig}
          translations={reportLandingPageModel.translations}
        />
      );
      if (
        reportLandingPageModel.folderTiles[folderConfig.id].length ||
        reportLandingPageModel.reportTiles[folderConfig.id].length
      )
        tiles.push(folderTiles);
    });
  reportLandingPageModel.newReportList !== undefined &&
    reportLandingPageModel.newReportList.map((reportConfig: any) => {
      let reportTiles: JSX.Element;
      reportTiles = (
        <ReportTile
          key={`report_${reportConfig.id}`}
          reportConfig={reportConfig}
          translations={reportLandingPageModel.translations}
        />
      );
      tiles.push(reportTiles);
    });
  return tiles;
};

// building each report tiles here
const ReportTile = (props: KagamiReportTileProps) => {
  let icon: any = getReportsIcons[props.reportConfig.type] ?? fallbackimage;
  const classes = useStyles();
  if (
    props.reportConfig['reportIcon'] !== undefined &&
    CommonUtils.isNotEmpty(props.reportConfig['reportIcon']['reportIconFile'])
  ) {
    icon = props.reportConfig['reportIcon']['reportIconFile'];
  }
  //  else {
  //   icon = getReportsIcons[props.reportConfig.type] ?? fallbackimage;
  // }

  return (
    <>
      <div
        className={classes.reportTileRoot}
        title={'Open ' + props.reportConfig.name}
        onClick={(e: any) => handleReportTileClick(props.reportConfig, props?.translations)}
      >
        <ReportMenuIcon reportConfig={props.reportConfig} />

        <div className={classes.report_image_box}>
          <img className={`${classes.reportIcon} reporticon`} src={icon} alt={props.reportConfig.type}></img>
        </div>

        <Typography variant="subtitle1" className={classes.report_title}>
          {/* {props.reportConfig.name} */}
          {translateToLocalistaion(props?.translations, props.reportConfig.name)}
        </Typography>
      </div>
    </>
  );
};

// building each folder tiles here
const FolderTile = (props: KagamiFolderTileProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.folderTileRoot} onClick={(e: any) => handleFolderTileClick(props.folderConfig)}>
        <FolderMenuIcon folderConfig={props.folderConfig} />
        <>
        <div>
        <img className={classes.folderIcon} src={folderIcon} alt={props.folderConfig.type} />
        {CommonUtils.isNotEmpty(props.folderConfig.folderImage) ? (
              <img
                className={classes.folderImg}
                src={props.folderConfig.folderImage}
              />
            ) : (
              <></>
            )}
        </div>
          {/* <p className={classes.folderTitle}>{props.folderConfig.name}</p> */}
          <p className={classes.folderTitle}>{translateToLocalistaion(props?.translations, props.folderConfig.name)}</p>
        </>
      </div>
    </>
  );
};

// adding MoreVertIcon icon for report
const ReportMenuIcon = (props: KagamiReportTileProps) => {
  const classes = useStyles();
  const landingPageModel = getLandingPageModel();
  const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
  let loginObject: any;
  if (loginData !== null) {
    loginObject = JSON.parse(loginData);
  }
  return (
    <div className={classes.report_icon}>
      {loginObject['userId'] !== undefined && loginObject['userId'].toLowerCase() === 'admin' ? (
        <IconButton
          size="small"
          onClick={(event: any) => {
            event.stopPropagation();
            landingPageModel.deleteAndEditPopover.child = buildMenuItemsForReports(
              props.reportConfig,
              landingPageModel
            );
            landingPageModel.deleteAndEditPopover.target = event.currentTarget;
            landingPageModel.deleteAndEditPopover.state.setPopoverTarget(event.currentTarget);
          }}
          title={translateToLocalistaion(props.translations, "Reports")}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      ) : (
        <>
          <IconButton
            onClick={(event: any) => {
              event.stopPropagation();
            }}
            title={translateToLocalistaion(props.translations, "Reports")}
            size="small"
          >
            <StarBorderIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </div>
  );
};

// adding functionality for edit and delete icon in reports popover
export const buildMenuItemsForReports = (reportConfig: any, landingPageModel: ReportLandingPageModel) => {
  // handling on click of ok in the popup
  const handleOnOk = () => {
    deleteReport(reportConfig['id'])
      .then((response: any) => {
        if (response['status'] === 200) {
          landingPageModel.reportList = landingPageModel.reportList.filter((reportConfigSummary: any) => {
            return reportConfigSummary['id'] !== reportConfig['id'];
          });

          if (landingPageModel.folderId !== undefined) {
            landingPageModel.reportTiles[landingPageModel.folderId] = landingPageModel.reportTiles[
              landingPageModel.folderId
            ].filter((reportConfigSummary: any) => {
              return reportConfigSummary['id'] !== reportConfig['id'];
            });
            landingPageModel.newReportList = landingPageModel.reportTiles[landingPageModel.folderId];
          } else {
            landingPageModel.reportTiles[ParentId.id] = landingPageModel.reportTiles[ParentId.id].filter(
              (reportConfigSummary: any) => {
                return reportConfigSummary['id'] !== reportConfig['id'];
              }
            );
            landingPageModel.newReportList = landingPageModel.reportTiles[ParentId.id];
          }
          landingPageModel.modalModel.state.setOpen(false);
          landingPageModel.state.setDataLoaded(true);
        }
      })
      .catch((error: any) => {
        console.log('getting error while deleting report', error);
      });
  };

  // Taking permission before deleting report through popup
  const handleDeleteReport = () => {
    landingPageModel.deleteAndEditPopover.state.setPopoverTarget(null);
    landingPageModel.modalModel.title =  translateToLocalistaion(landingPageModel?.translations, 'Warning');
    landingPageModel.modalModel.content = 'Are you sure, You want to delete the report?';
    landingPageModel.modalModel.modalWidth = 'sm';
    landingPageModel.modalModel.handleOnOk = handleOnOk;
    landingPageModel.modalModel.state.setOpen(true);
  };
  return (
    <div
      style={{
        display: 'grid',
        width: '36px',
        padding: '5px',
        left: '745px',
        overflow: 'none'
      }}
    >
      {/* <EditIcon /> */}
      <DeleteIcon onClick={handleDeleteReport} />
    </div>
  );
};

// adding MoreVertIcon icon for Folder
const FolderMenuIcon = (props: KagamiFolderTileProps) => {
  const classes = useStyles();
  const landingPageModel = getLandingPageModel();
  const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
  let loginObject: any;
  if (loginData !== null) {
    loginObject = JSON.parse(loginData);
  }
  return (
    <div className={classes.folder_icon}>
      {loginObject['userId'] !== undefined && loginObject['userId'].toLowerCase() === 'admin' ? (
        <IconButton
          onClick={(event: any) => {
            event.stopPropagation();
            landingPageModel.deleteAndEditPopover.child = buildMenuItemsForFolders(
              props.folderConfig,
              landingPageModel
            );
            landingPageModel.deleteAndEditPopover.target = event.currentTarget;
            landingPageModel.deleteAndEditPopover.state.setPopoverTarget(event.currentTarget);
          }}
          title={translateToLocalistaion(props.translations, "reportFolder")}
          size="small"
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  );
};

// adding functionality for edit and delete icon in folder popover
export const buildMenuItemsForFolders = (folderConfig: any, landingPageModel: ReportLandingPageModel) => {
  // handling on click of ok in the popup
  const handleOnOk = () => {
    deleteReportFolder(folderConfig['id'])
      .then((response: any) => {
        console.log(response);
        if (response['status'] === 200) {
          landingPageModel.folderList = filterFolderList(landingPageModel.folderList, folderConfig);
          if (landingPageModel.folderId !== undefined) {
            landingPageModel.folderTiles[landingPageModel.folderId] = landingPageModel.folderTiles[
              landingPageModel.folderId
            ].filter((folderConfigSummary: any) => {
              return folderConfigSummary['id'] !== folderConfig['id'];
            });
            landingPageModel.newFolderList = landingPageModel.folderTiles[landingPageModel.folderId];
          } else {
            landingPageModel.folderTiles[ParentId.id] = landingPageModel.folderTiles[ParentId.id].filter(
              (folderConfigSummary: any) => {
                return folderConfigSummary['id'] !== folderConfig['id'];
              }
            );
            landingPageModel.newFolderList = landingPageModel.folderTiles[ParentId.id];
          }
          landingPageModel.modalModel.state.setOpen(false);
          landingPageModel.state.setDataLoaded(true);
        }
      })
      .catch((error: any) => {
        console.log('getting error while deleting folder', error);
      });
  };

  // Taking permission before deleting folder through popup
  const handleDeleteFolder = () => {
    landingPageModel.deleteAndEditPopover.state.setPopoverTarget(null);
    landingPageModel.modalModel.title = translateToLocalistaion(landingPageModel?.translations, 'Warning');
    landingPageModel.modalModel.content = 'Are you sure, You want to delete the folder?';
    landingPageModel.modalModel.modalWidth = 'sm';
    landingPageModel.modalModel.handleOnOk = handleOnOk;
    landingPageModel.modalModel.state.setOpen(true);
  };
  return (
    <div
      style={{
        display: 'grid',
        width: '36px',
        padding: '5px',
        right: '745px',
        overflow: 'none'
      }}
    >
      {/* <EditIcon /> */}
      <DeleteIcon onClick={handleDeleteFolder} />
    </div>
  );
};

// filtering folderList which is having tree like structure
export const filterFolderList = (folderList: any, folderConfig: any) => {
  return folderList.filter((folderConfgSummary: any) => {
    if (folderConfgSummary['id'] !== folderConfig['id']) {
      folderConfgSummary['strucutureList'] = filterFolderList(folderConfgSummary['strucutureList'], folderConfig);
      return true;
    }
  });
};
