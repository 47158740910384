import { Avatar } from "@mui/material";
import { getDmsViewUrl } from "../../../../../../../../../../../../service/URLService";
import { CommonUtils } from "../../../../../../../../../../../../utils/CommonUtils";
import defaultImage from '../../../../../../../../../../../../../assets/images/defaultImg.png';
import { useEffect } from "react";
import React from "react";

const styles = {
    display: 'flex',
    gap: '0px',
    alignItems: 'center', 
    maxWidth: '60px',
    width:'60px' ,
    height: '25px',
    border: '1px solid #006E7F',
    borderRadius: '3px'
}
 const kagamiImageCellRenderer = React.memo((props: any) => {
    let attributeName = props.attributePresentation.attrName;
    let getattributeRendererData = props.data[attributeName];
    let getDocId = (CommonUtils.isNotEmpty(getattributeRendererData) && getattributeRendererData.includes(':')) ? getattributeRendererData.split(':')[0] : getattributeRendererData
    let getbase64ThumbanilUrl = CommonUtils.isNotEmpty(props.gridModel.docInfo) ? CommonUtils.isNotEmpty(getDocId) ? props.gridModel.docInfo[getDocId]?.thumbnail: null :null;
    if (CommonUtils.isNotEmpty(getbase64ThumbanilUrl)) {
        return CommonUtils.isNotEmpty(getbase64ThumbanilUrl) ? <Avatar src={`data:image/png;base64,${getbase64ThumbanilUrl}`} style={styles} ></Avatar> : <Avatar src={defaultImage} style={styles}></Avatar>
    } else {
        return CommonUtils.isNotEmpty(getattributeRendererData) ? <Avatar src={getDmsViewUrl(getattributeRendererData)} style={styles} ></Avatar> : <Avatar src={defaultImage} style={styles}></Avatar>
    }
})


export default kagamiImageCellRenderer;