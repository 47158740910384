import { APIService } from '../../../../../../../../../../../../service/ApiService';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import {
  deleteSchedulerURL,
  fetchAllSchedulerURL,
  scheduleReportURL
} from '../../../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { TimeZone } from '../builder/SchedulerForm/model/SchedulerFormModel';
import { rebuildReportScheduler } from '../controller/ScheduleReportsController';
import { ReportSchedulerModel } from '../model/ScheduleReportsModel';

export class ScheduleReportsService {
  static FetchAllScheduler(schedulerModel: ReportSchedulerModel) {
    getAllScheduler()
      .then((response: any) => {
        schedulerModel.allScheduler = response.data.respObject ?? [];
        rebuildReportScheduler(schedulerModel);
      })
      .catch((err: any) => {
        console.log('issues coming while fetching record from fetchAllScheduler');
      });
  }

  static DeleteScheduler(dataToDelete: any, schedulerModel: ReportSchedulerModel) {
    deleteScheduler(dataToDelete)
      .then((response: any) => {
        console.log(response);
        if(response.data.respCode == -1)
          alert(response.data.respMsg);
        ScheduleReportsService.FetchAllScheduler(schedulerModel);
      })
      .catch((err: any) => {
        console.log('issues coming while fetching record from fetchAllScheduler');
      });
  }

  static SchedulerReport(newScheduler: any, schedulerModel: ReportSchedulerModel) {
    scheduleReport(newScheduler)
      .then((response: any) => {
        console.log('response : ',response.data);
        if(response.data.respCode == -1)
          alert(response.data.respMsg);
        ScheduleReportsService.FetchAllScheduler(schedulerModel);
      })
      .catch((err: any) => {
        console.log('issues coming while fetching record from fetchAllScheduler');
      });
  }
}

async function getAllScheduler() {
  return await APIService.getData(SystemConstants.GET, fetchAllSchedulerURL(), '');
}

async function deleteScheduler(schedulerData: any) {
  return await APIService.getData(SystemConstants.POST, deleteSchedulerURL(), schedulerData);
}

export async function scheduleReport(formData: any) {
  return await APIService.getData(SystemConstants.POST, scheduleReportURL(), formData);
}

//---------------------------------------------------------------------------------------------------

export function makeScheduleReports(reportSchedulerModel: ReportSchedulerModel) {
  const scheduleReports: any[] = [];
  Array.isArray(reportSchedulerModel.allScheduler) &&
    reportSchedulerModel.allScheduler.forEach((schedulerData: any) => {
      let mainScheduler = mergeShedulerData(schedulerData, reportSchedulerModel);
      scheduleReports.push(mainScheduler);
    });
  return scheduleReports;
}

function mergeShedulerData(schedulerData: any, reportSchedulerModel: ReportSchedulerModel) {
  const emailUser = checkValueType(schedulerData['metaData']['emailUser']);
  const emailRole = checkValueTypeRole(schedulerData['metaData']['role']);
  let emailUserObj = {};

  if (emailUser) {
    emailUserObj = {
      label: 'Email Users',
      value: emailUser
    };
  }

  if (emailRole) {
    emailUserObj = {
      label: 'Email Roles',
      value: emailRole
    };
  }

  const dataObject = {
    schedulerID: { id: schedulerData['scheduleId'] },
    schedulerDetails: [
      { label: 'Scheduler Name', value: schedulerData['schedule']['schedulerName'] },
      {
        label: 'Start Time',
        value: checkValueType(schedulerData['metaData']['startTime'])
      },
      {
        label: 'Repeat Every',
        value: checkValueType(schedulerData['metaData']['repeatEvery']['type'])
      },
      { label: 'Scheduler Type', value: schedulerData['metaData']['type'] },
      { label: 'Email Subject', value: checkValueType(schedulerData['metaData']['emailSub']) },
      { label: 'Email Body', value: checkValueType(schedulerData['metaData']['emailBody']) },
      emailUserObj,
    ],
    defaultReportList: getReportConfig(schedulerData['reportsList'], reportSchedulerModel)
  };

  return dataObject;
}

function checkValueType(value: any) {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return emailCommaSeprator(value);
    }  
    else {
      return objectToStringFormat(value);
    }
  }
}
function checkValueTypeRole(value: any) {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return roleCommaSeprator(value);
    }  
    else {
      return objectToStringFormat(value);
    }
  }
}


function objectToStringFormat(schedulerTime: any) {
  let newString = '';
  Object.keys(schedulerTime).forEach((key: any) => {
    newString += key;
    newString += ':';
    newString += schedulerTime[key];
    newString += ' ';
  });
  if (CommonUtils.isEmpty(newString)) {
    return `{}`;
  } else {
    return newString;
  }
}

function emailCommaSeprator(emails: any) {
  let arrayOfEmail = '[';
  Array.isArray(emails) &&
    emails.forEach((email: any, index: number) => {
      if (index + 1 < emails.length) {
        arrayOfEmail += `${email} , `;
      }
      else {
        arrayOfEmail += email;
      }
    });
  arrayOfEmail += ']';
  return arrayOfEmail;
}
function roleCommaSeprator(roles: any) {
  let arrayOfRole = '[';
  Array.isArray(roles) &&
    roles.forEach((role: any, index: number) => {
      if (index + 1 < roles.length) {
        arrayOfRole += `${role} , `;
      } else {
        arrayOfRole += role;
      }
    });
  arrayOfRole += ']';
  return arrayOfRole;
}

function getReportConfig(schedulerList: any, reportSchedulerModel: ReportSchedulerModel) {
  let reportConfigSummarys: any[] = [];
  schedulerList.forEach((reportId: any) => {
    let reConfig = reportSchedulerModel.reportList.filter((reportConfigSummary: any) => {
      return reportConfigSummary['id'] === reportId;
    })[0];

    if (reConfig !== undefined) {
      reportConfigSummarys.push(reConfig);
    }
  });
  return reportConfigSummarys;
}

//-------------------------------------------------------------------------------------------------------

export const handleDeleteScheduler = (schedulerData: any, reportSchedulerModel: ReportSchedulerModel) => {
  // console.log(schedulerData, reportSchedulerModel);
  const dataToDelete = reportSchedulerModel.allScheduler.filter((o: any) => {
    return o['scheduleId'] === schedulerData['schedulerID']['id'];
  });
  if (dataToDelete.length) {
    dataToDelete[0]['@type'] = 'trigger';
    ScheduleReportsService.DeleteScheduler(dataToDelete[0], reportSchedulerModel);
  }
  // console.log(dataToDelete);
};
