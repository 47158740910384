import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class BellCurveReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
  }
}
export interface BellCurveReportModelProps {
  bellCurveReportModel: BellCurveReportModel;
}
export const useBellCurveReportModelState = (state: State<BellCurveReportModel>) => {
  let bellCurveReportState = useState(state);
  return {
    reportConfig() {
      bellCurveReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      bellCurveReportState.reportData.set(reportContent);
    },

    get isDataLoaded(){
      return bellCurveReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      bellCurveReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
