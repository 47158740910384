
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { KagamiPresentationFilterModel, KagamiPresentationModelProps, usePresentationFiltersState } from '../model/KagamiPresentationFilterModel'; 
import makeStyles from '@mui/styles/makeStyles';
import { kagamiPresentationFilterstyle } from '../styles/KagamiPresentationFilterStyle';
import { Typography } from '@mui/material'; 
import { KagamiListFilterModel } from '../../../model/KagamiListFilterModel';
import { getListFiltersList } from '../builder/KagamiPresentationFilterBuilder';
import { getKagamiListModel } from '../../../../../controller/KagamiListController';
import { buildPopover } from '../../../../../../../../../../../../../../components/popover/controller/KagamiPopoverController';
import { KagamiPopoverModel } from '../../../../../../../../../../../../../../components/popover/model/KagamiPopoverModel';
import { KagamiListFiltersPopoverModel, useKagamiListFiltersPopoverState } from '../../../../../../../../../../../../../../components/ListFilters_Popover/model/KagamiListFiltersModelPopover';
import { buildListFiltersPopover } from '../../../../../../../../../../../../../../components/ListFilters_Popover/controller/KagamiListFiltersPopoverController';
import React from 'react';
import Popover from '@mui/material/Popover';
 import {createState} from "@hookstate/core"
import { getActiveModel } from '../../../../../../../controller/KagamiProcessController';
import { translateToLocalistaion } from '../../../../../../../service/PresentationService';

export const presentationFilter = (kagamiPresentationFilterModel: KagamiPresentationFilterModel, kagamiListFilterModel: KagamiListFilterModel) => {
  return <KagamiPresentationFilter key={"way-to-" + kagamiPresentationFilterModel?.dataRecordsForPresentationAttribute?.label} kagamiPresentationFilterModel={kagamiPresentationFilterModel} kagamiListFilterModel={kagamiListFilterModel} />;
};

const KagamiPresentationFilter =(props: KagamiPresentationModelProps) => {
  //this filters are not working without React useState and could need a work around to remove it
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = makeStyles(kagamiPresentationFilterstyle)();
 
  const kagamiListModel = getKagamiListModel()

  const listFilterpopoverModel =  new KagamiListFiltersPopoverModel();
   

  // let classes = useStyles();
  let popoverState = useKagamiListFiltersPopoverState(createState(listFilterpopoverModel));
  listFilterpopoverModel.state = popoverState;
  let open = Boolean(popoverState.popoverTarget);
  let activeModel = getActiveModel();
  let transalations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  return (  
    <>
      <div
        className={classes.root}
        // id={props.kagamiPresentationFilterModel.dataRecordsForPresentationAttribute}
        onClick={(event: any) => setAnchorEl(event.target)}
      >
        <Typography 
        //  color={Boolean(anchorEl) ? "secondary" :"primary" }
        style={{color:Boolean(anchorEl) ? '#000'  : '#808080', fontSize:'13px' }}
         >
          {/* {props.kagamiPresentationFilterModel.dataRecordsForPresentationAttribute.label} */}
          {translateToLocalistaion(transalations, props.kagamiPresentationFilterModel.dataRecordsForPresentationAttribute.label)}
        </Typography>
        <ArrowDropDownIcon fontSize="small" 
        sx={{
          color:(theme) => theme.palette.secondary.main
        }}
        // color={Boolean(anchorEl) ? "secondary" :"primary" } 
        // style={{color:Boolean(anchorEl) ? '#000'  : '#808080' }}
        />
      </div> 
        
        <Popover  
      
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div>
       {getListFiltersList(kagamiListModel,props.kagamiPresentationFilterModel, props.kagamiListFilterModel)}
      </div>
    </Popover>
    </>
  );
};
 