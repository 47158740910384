import { createState } from '@hookstate/core';
import {
  AreaReportModel,
  AreaReportModelProps,
  useAreaReportModelState
} from '../model/AreaReportModel';
import { makeArea } from '../service/AreaReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';

export const BuildAreaReportView = (areaReportModel: AreaReportModel) => {
  return (
    <KagamiAreaChartReportView key={'area-chart-report'} areaReportModel={areaReportModel}></KagamiAreaChartReportView>
  );
};

const KagamiAreaChartReportView = (props: AreaReportModelProps) => {
  let areaReportModel = props.areaReportModel;
  const chartComponent:any = React.useRef(null);
  let areaChartState = useAreaReportModelState(createState(areaReportModel));
  areaReportModel.state = areaChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(props.areaReportModel)
  }, [])
  
  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartComponent.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);

  if(!areaChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{
    if(areaReportModel.reportData){
  const areaConfig :any = makeArea(areaReportModel);

  return (
    <div ref={containerRef} className="ag-theme-alpine dashboard-highchart kgm-report-hw">
      <Chart ref={chartComponent} highcharts={Highcharts} options={areaConfig} />
    </div>
  );
    }else{
      return <p className='NoDataFound'>No Data Found</p>
    }
  }
};
