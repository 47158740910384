import { State, useState } from '@hookstate/core';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { KagamiListModel } from '../../../model/KagamiListModel';

export class KagamiCrossTabViewModel extends KagamiListModel {
  crossTabInfo : any;
  constructor(processName: string, processStepName: string, mainEntityId: string, presentation: any) {
    super(processName, processStepName, null, mainEntityId);
    this.state = null;
    this.presentation = presentation;
    this.crossTabInfo = {
      clickedActions:[]
    }
  }
}

export interface KagamiCrossTabViewModelProps {
  kagamiCrossTabViewModel: KagamiCrossTabViewModel;
}
export const wrapKagamiCrossTabViewModelState = (state: State<KagamiCrossTabViewModel>) => {
  let crossTabState = state;

  return {
    getListLoaded() {
      return crossTabState.isListLoaded.get();
    },
    setListLoaded(value: boolean) {
      crossTabState.isListLoaded.set(value);
    }
  };
};

export const useCrossTabListState = (state: State<KagamiCrossTabViewModel>) => {
  const crossTabState = wrapKagamiCrossTabViewModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.CROSS_TAB_STATE, crossTabState);
  return crossTabState;
};
