import { InputData } from './InputData';
import { UIEvent } from './UIEvent';
import { Event } from './Event';

export class RequestEvent {
  event: Event | undefined;
  fromUi: boolean;
  guid: string;
  inputData: InputData | undefined;
  uiEvent: UIEvent | undefined;
  windowId: string;
  properties: any;

  constructor() {
    this.event = undefined;
    this.fromUi = false;
    this.inputData = new InputData();
    this.uiEvent = new UIEvent();
    this.windowId = '';
    this.guid = '';
  }
}
