import { Avatar} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { computeDisplayString } from '../../service/FieldHelper';
import { getPresentationRuleImagePath } from '../../service/GridUtilService';
import { cellRendererCustomComponentsStyle } from '../../style/CellRendererCustomComponents';
import { executeDisplayCondition } from '../../../../../../service/PolicyExecutionService';
import { extractDropdownOption, getHtmlControl } from '../../../../../../service/PresentationService';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { setActiveRowNode } from '../../service/GridService';
import { useEffect, useRef } from "react";

const useStyles = makeStyles(cellRendererCustomComponentsStyle);
const KagamiTextCellRenderer = (props: any) => {
  const handleOnClick = () => {
    console.log('clicked on text cell renderer');
    if (props.gridModel && props.gridModel.isEditableEmbedList) {
      setActiveRowNode(props, props.gridModel);
    }
  };

    const classes = useStyles();
    let controlType = getHtmlControl(props.attributePresentation)
    let fontColor: any = props?.attributePresentation?.uiSettings?.fontStyling?.fontColor;
    let fontSize: any = props?.attributePresentation?.uiSettings?.fontStyling?.fontSize;
    let attrData: any = '';
    let attrLabel = '';
    let imagePath = getPresentationRuleImagePath(props.attributePresentation, props.data,props.dispPresentationRuleConfig) || '';
    let dispConditions :any={};
    if(props.attributePresentation?.policyMap?.presentation){
        dispConditions = executeDisplayCondition(props.data,props.attributePresentation);
    }
    let dispVal = '';
    if (props.entityConsumed) {
        attrData = props.data[props.entityConsumed];
    } else if (props?.colDef?.field.split(".").length > 1) {
        let reqEntity = props.colDef.field.split(".")[props.colDef.field.split(".").length - 2];
        attrLabel = props.colDef.field.split(".")[props.colDef.field.split(".").length - 1];
        attrData = props.data[reqEntity];
        dispVal = props.data[props.column.colId]
        // if (attrLabel && attrData) {
        //     dispVal = _.get(attrData[0], attrLabel);
        // }
    }
    if (props?.dispPresentationRuleConfig?.$$displayAttributes) {
        dispVal = computeDisplayString(attrData, props?.dispPresentationRuleConfig?.$$visibleAttributes || props?.dispPresentationRuleConfig?.$$displayAttributes || [], "View");
    }
    if (attrData) {
        if (typeof attrData === 'object' && !Array.isArray(attrData) && attrData !== null && attrData !== undefined) {
            if ('Color' in attrData || dispConditions?.color) {
                let bgColor = attrData?.Color?.bgCode || dispConditions?.color;
                return (
                    <div className={classes.badgeRoot} onClick={handleOnClick}>
                        <div className={classes.badge} style={{ backgroundColor: bgColor ? bgColor : 'default' }}></div>
                        <div>
                            <span style={{color: fontColor ? fontColor : 'inherit', fontSize: fontSize ? fontSize : 'inherit'}}>{ dispVal || (props.displayAttribute ? attrData[props.displayAttribute] : attrData[attrLabel] || props.value)}</span>
                        </div>
                    </div>
                )
            }
            else if(imagePath) {
               if(CommonUtils.isNotEmpty(imagePath) && CommonUtils.isNotEmpty(dispVal)){
                     return (
                        <div style={{display:'flex' , justifyContent:'space-between' , gap:'5px',maxWidth:'100%'}}>
                            <Avatar src={imagePath} sx={{width:'22px', height:'22px',marginTop:'6px'}}/>
                             <div style={{display:'block' ,whiteSpace:'nowrap', overflow: 'hidden', textOverflow :'ellipsis'}}>{dispVal}</div> 
                        </div>
                     )
               }else{
                return (
                    <div onClick={handleOnClick}>
                    <div style={{ color: 'inherit'}}>
                        <span style={{textOverflow :'ellipsis',whiteSpace:'nowrap', overflow: 'hidden', padding: 0 }}>{dispVal || (props.displayAttribute ? attrData[props.displayAttribute] : attrData[attrLabel] || props.value)}</span>
                    </div>
                </div>
                )
               }
            }
        }
    }
    if(controlType === 'search' || controlType === 'select'){
        let option : any =  extractDropdownOption(props.attributePresentation,attrData)
         let value : string = CommonUtils.isNotEmpty(option) ? option : '--';
         return <div title={value} onClick={handleOnClick} style={{textOverflow :'ellipsis',display:'block',whiteSpace:'nowrap', overflow: 'hidden', padding: 0 }}>{value}</div>

    }
    return  (<div title={dispVal || props.value} onClick={handleOnClick}
        style={{textOverflow :'ellipsis',display:'block',whiteSpace:'nowrap', overflow: 'hidden', padding: 0 }}
        >{dispVal || props.value}</div>)
}

export default KagamiTextCellRenderer;