import { buildDashboardReport } from "../../reports/DashboardReports/controller/DashboardReportsController";
import { DashboardReportsModel } from "../../reports/DashboardReports/model/DashboardReportsModel";
import { ReportFilterModel } from "../../reports/filter/model/KagamiReportFilterModel";
import { executeReportWithOrWithoutFilter } from "../../reports/view/report/builder/KagamiReportBuilder";
import { setDefaultSelectedValue, setReportModel } from "../../reports/view/report/controller/KagamiReportController";
import { KagamiReportModel } from "../../reports/view/report/model/KagamiReportModel";
import { getReportConfig } from "../../reports/view/report/service/KagamiReportService";
import { KagamiDashboardModel } from "../model/KagamiDashboardModel";

export async function buildDashboardReportSections(dashboardModel: KagamiDashboardModel) {
    // if(!dashboardModel.reportsLoaded){
        await getReportDataComponent(dashboardModel);
    // }
  }

export async function getReportDataComponent(dashboardModel: KagamiDashboardModel){
    const reportsComponent:any[]=[];
    dashboardModel.reportData?.forEach((report:any) => {
        reportsComponent.push(
            getReportContent(report,dashboardModel.reportData.length)
        );
      });
    let fav_reports_comp = Promise.all(reportsComponent);
    await fav_reports_comp.then((reports_comp:any)=>{
        dashboardModel.reportDataUx = reports_comp??[];
        // dashboardModel.reportsLoaded = true;
    }).catch((err:any)=>{
        console.log('promise.all not resolve properly : ',err)
    })
}

async function getReportContent(report: any, lengthOfReport: number) {
  let reportId = report.id;
  let response: any = await getReportConfig(reportId);
  let reportConfig = response?.data?.respObject;
  if (reportConfig != undefined && reportConfig['reportConfigSummary']) {
    let reportModel: KagamiReportModel = new KagamiReportModel();
    reportModel.reportConfig = reportConfig;
    reportModel.reportConfigSummary = reportConfig.reportConfigSummary;
    setReportModel(reportModel);
    let filters = reportConfig.dynamicParameterList;
    reportModel.filter = filters;
    let reportFilterModel = new ReportFilterModel(filters, {}, reportId);
    reportFilterModel.filterSetting = reportConfig?.reportSettings?.filterSettings ?? {};
    setDefaultSelectedValue(reportFilterModel);
    reportFilterModel.filters = filters;
    reportModel.reportFilterModel = reportFilterModel;
    reportModel.fromDashboard = true;
    return await executeReportWithOrWithoutFilter(reportModel)
      .then((content: any) => {
        const dashboardReportsModel = new DashboardReportsModel(report.id, report.name, content);
        dashboardReportsModel.lengthOfReport = lengthOfReport;
        dashboardReportsModel.reportModel = reportModel;
        return buildDashboardReport(dashboardReportsModel);
      })
      .catch((err: any) => {
        console.log('getReportContent have some issue', err);
      });
  }
}