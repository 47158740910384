 
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const kagamiClearFilterButtonstyle = (theme: Theme) =>
  createStyles({
    root: {
        display: 'flex', 
        textTransform: 'capitalize',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: theme.spacing(0.5),
        margin: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} `,
        marginLeft: theme.spacing(1),
        border: '1px solid ',
        borderColor: theme.palette.error.main,
        padding:`${theme.spacing(1.6)} ${theme.spacing(1)}`,
        '& .css-1y1kcm-MuiSvgIcon-root':{
            height: theme.spacing(1.75)
        }
    }
   
  });
