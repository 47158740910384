import { State, useState } from '@hookstate/core';
import { ReportModalModel } from '../../ReportModal/model/ReportModalModel';

export class KagamiReportModel {
  filter: any;
  reportFilterModel:any
  reportModalModel = new ReportModalModel();
  reportConfig:any;
  reportConfigSummary: any;
  fromDashboard:Boolean;
  fromDrillDown:Boolean;
  reportSettings:any;
  constructor() {
    this.fromDashboard = false;
    this.fromDrillDown = false;
  }
}

export interface KagamiReportModelProps {
 kagamiReportModel: KagamiReportModel;
}