import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import { getFormModel } from "../../../form/controller/KagamiFormController";
import { useKagamiControlModelState } from "../../model/kagamiControlModel";
import { handleMultiSelectCheckBoxOnChange } from "../controller/kagamiCheckboxController";
import {
  CheckboxFieldProps,
  KagamiCheckboxModel,
} from "../model/kagamiCheckboxModal";
import { KagamiCheckBoxStyle } from "../style/KagamiCheckBoxStyle";
import FormLabel from "@mui/material/FormLabel";
import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { extractDropdownOption } from "../../../../service/PresentationService";

export const buildMultiSelectCheckBoxComponent = (
  kagamiCheckboxModel: KagamiCheckboxModel
) => {
  return (
    <KagamiMultiSelectCheckBox
      kagamiCheckboxModel={kagamiCheckboxModel}
    ></KagamiMultiSelectCheckBox>
  );
};

const useStyles = makeStyles(KagamiCheckBoxStyle);

const KagamiMultiSelectCheckBox = (props: CheckboxFieldProps) => {
  const classes = useStyles();
  let fieldState = useKagamiControlModelState(
    props.kagamiCheckboxModel.createState
  );
  props.kagamiCheckboxModel.state = fieldState;
  let controlValue = JSON.parse(
    JSON.stringify(props.kagamiCheckboxModel.state.controlValue)
  );
  let displayCondition =
    props?.kagamiCheckboxModel?.uiSettings?.displayDirection?.value;
  const uiSettingsClassName = props.kagamiCheckboxModel.getControlWidth(
    props.kagamiCheckboxModel.uiSettings
  );
  const fontProperties = props.kagamiCheckboxModel.getFontStyling(
    props.kagamiCheckboxModel.uiSettings
  );

  getFormModel().formControlStates[
    props.kagamiCheckboxModel.controlIdentifier
  ] = fieldState;

  if (fieldState.isVisible === false) {
    return null;
  } else {
    if (props.kagamiCheckboxModel.readonly) {
      return (
        <div className={`${uiSettingsClassName} ${classes.root}`}>
          <div className={classes.readOnlyField_cont}>
            <FormLabel
              component="span"
              className={classes.controlLabel}
              style={{
                color: fontProperties.labelFontColor,
                fontWeight: fontProperties.labelFontBold,
                fontStyle: fontProperties.labelFontItalic,
                textDecoration: fontProperties.labelFontUnderline,
              }}
            >
              {" "}
              {props.kagamiCheckboxModel.controlDisplayName}
            </FormLabel>
            <FormGroup
              style={{
                display:
                  displayCondition === "sidebyside" ? "-webkit-box" : "block",
              }}
            >
              {CommonUtils.isNotEmpty(props.kagamiCheckboxModel.displayValue ||  props.kagamiCheckboxModel.controlValue )
                ? (props.kagamiCheckboxModel.displayValue || props.kagamiCheckboxModel.controlValue).map((option: any) => {
                    let isFound =
                      CommonUtils.isNotEmpty(controlValue) &&
                      controlValue?.find(
                        (item: any) => item?.id === option?.id
                      );
                    return (
                      <FormControlLabel
                        key={option["id"]}
                        value={option["id"]}
                        control={
                          <Checkbox
                            checked={isFound ? true : false}
                            disabled
                            color="primary"
                            size="small"
                            sx={{
                              color: "0.8px solid #000",
                              "&.Mui-checked": {
                                color: "#000",
                              },
                            }}
                          />
                        }
                        label={extractDropdownOption(
                          props.kagamiCheckboxModel.attributePresentation,
                          option
                        )}
                      />
                    );
                  })
                : ""}
            </FormGroup>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`${uiSettingsClassName} ${classes.root}`}>
          <FormControl
            required={fieldState.isMandatory}
            id={props.kagamiCheckboxModel.controlIdentifier}
            className={
              "field " +
              (fieldState.isReadOnly ? "readOnlyField" : "defaultField")
            }
          >
            <FormLabel
              component="div"
              style={{
                color: fontProperties.labelFontColor,
                fontWeight: fontProperties.labelFontBold,
                fontStyle: fontProperties.labelFontItalic,
                textDecoration: fontProperties.labelFontUnderline,
              }}
            >
              {props.kagamiCheckboxModel.controlDisplayName}
            </FormLabel>
            <FormGroup
              style={{
                display: CommonUtils.isNotEmpty(displayCondition)
                  ? "-webkit-box"
                  : "block",
              }}
            >
              {CommonUtils.isNotEmpty(props.kagamiCheckboxModel.selectValues)
                ? props.kagamiCheckboxModel.selectValues.map((option: any) => {
                    let isFound =
                      CommonUtils.isNotEmpty(controlValue) &&
                      controlValue.find((item: any) => item?.id === option?.id);
                    return (
                      <FormControlLabel
                        key={option["id"]}
                        value={option["id"]}
                        control={
                          <Checkbox
                            checked={isFound ? true : false}
                            color="primary"
                            size="small"
                            sx={{
                              color: "0.8px solid #000",
                              "&.Mui-checked": {
                                color: "#000",
                              },
                            }}
                          />
                        }
                        label={extractDropdownOption(
                          props.kagamiCheckboxModel.attributePresentation,
                          option
                        )}
                        onChange={(e: any) =>
                          handleMultiSelectCheckBoxOnChange(
                            props.kagamiCheckboxModel,
                            option
                          )
                        }
                      />
                    );
                  })
                : ""}
            </FormGroup>
            {fieldState.errorMessage ? (
              <span style={{ color: "#f05252", fontSize: "12px" }}>
                {fieldState.errorMessage}
              </span>
            ) : null}
          </FormControl>
        </div>
      );
    }
  }
};

export default KagamiMultiSelectCheckBox;
