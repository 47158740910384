import _ from 'lodash';
import * as htmlToImage from 'html-to-image';
// import { toPng } from 'html-to-image';


import {
    getReportData,
    reportColumnDisplayFormatter,
    reportFilterHeader,
    getReportId,
    getReportName
} from '../../../../service/ReportUtilService';
import { getFilterSelectedHeader } from '../../../../service/KagamiReportService';
import { getDrillDownReportData, handleDrillDownReport } from '../../../../service/ReportDrillDownService';
const doughnutDefaultConfig = () => {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 450,
        },
        exporting: {
            enabled: false
        },
        title: {
            text: '',
            align: 'center',
        },
        caption: {
            text: '',
            align: 'center',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y}',
                },
                showInLegend: false
            }
        },
        credits: {
            enabled: false,
        },
        series: [{
            name: '',
            colorByPoint: true,
            data: [],
            innerSize: '80%', //doughnut
        }]
    }
}

export const makeDoughnut = (doughnutReportController: any) => {
    let defaultConfig = doughnutDefaultConfig();
    let dbChartData = JSON.parse(doughnutReportController.reportData);
    let tablerecords = getReportData(dbChartData);
    let userConfig = prapareUserConfigForChart(tablerecords, doughnutReportController, false);
    let dispchartOptions = _.extend(true, {}, defaultConfig, userConfig);
    return {
        doughnutConfig: dispchartOptions,
        tableConfig: makeTableDetails(doughnutReportController.reportConfig, userConfig)
    }
}

export const makeAdditionalReport = (additionalReportId:any,doughnutReportController:any) => {
    getDrillDownReportData(additionalReportId,doughnutReportController,{isAddionalReport:true});
}

const prapareUserConfigForChart = (tablerecords:any,doughnutReportController:any,isDashboard:any)  => {
     let reportConfig = doughnutReportController.reportConfig;
    let userConfig:any ={
        series:[{
            data:[],
            innerSize: '70%', 
        }],
    };
    let otherSettings :any;
    if(reportConfig.reportSettings){
        let otherSettings= reportConfig.reportSettings;
         userConfig = {
            title:otherSettings.title || '',
            subtitle:otherSettings.subtitle || '',
            tooltip: {
                pointFormat: otherSettings.tooltipvalueinpercent ? '{series.name}: <b>{point.percentage:.1f}%</b>': '{series.name}: <b>{point.y}</b>',
            },
            legend: {
                align: otherSettings.legendposition || 'right',
                verticalAlign: otherSettings.legendverticalalign || 'right',
                layout: otherSettings.legendlayout || 'vertical',
                labelFormat:'<b>{name}</b>: {percentage:.1f} %<b> ({y})</b>' ,
            },
            plotOptions: {
            pie :{
                showInLegend: ( otherSettings.showlegend &&  !otherSettings.customlegend )|| false,
                cursor: 'pointer',
                dataLabels: {
                    enabled:otherSettings.showlegend ? false: true, 
                    format: otherSettings.chartvalueinpercent ? '<b>{point.name}</b>: {point.percentage:.1f} %' :'<b>{point.name}</b>: {point.y}',
                },
            }
            },
            series:[{
                data:[],
                point: {
                    events: {
                        click: function (event:any) {
                            // return;
                            let $$this:any = this;
                            let value =  $$this?.['options']?.['name'] || '';
                            handleDrillDownReport(event,doughnutReportController,reportConfig,value,reportConfig.verticalAxis[0]);
                        }
                    }
                },
                innerSize: otherSettings.innerRadiusSize ? otherSettings.innerRadiusSize +"%":'70%'
            }],
        }
  
        if(otherSettings.colors && otherSettings.colors.length >=1 ){
            userConfig['colors'] =otherSettings.colors;
        }
        if(otherSettings.height && isDashboard ){
            userConfig['chart'] = {
                height: otherSettings.height
            };
        }
  
        if(otherSettings.titleinsideradius) {
            let filtertxt="";
            let reportFilterHeaderArr:any = reportFilterHeader(reportConfig);
            for(let i=0;i<reportFilterHeaderArr.length;i++){
                filtertxt += " "+(reportFilterHeaderArr[i] ? reportFilterHeaderArr[i].name +" "+ reportFilterHeaderArr[i].relation +" "+ reportFilterHeaderArr[i].value :' ');
            }
            userConfig['title'] =  {
                text: filtertxt,
                align: 'center',
                verticalAlign: 'middle',
                // y: 40,
                style: { "color": "#333333", "fontSize": otherSettings.titlefontsize ?  otherSettings.titlefontsize+'px':'12px'}
            }
        }
        }//
            let seriesData=[];
            let customLegendData = [];
            let legendMetaData = [];
            if(reportConfig && reportConfig.reportSettings && reportConfig.reportSettings.singleRecordReport){
                if(tablerecords.length >0 && reportConfig.horizontalAxis ){
                    for(let i=0;i<reportConfig.horizontalAxis.length;i++){
                        seriesData.push({
                            name: reportConfig.horizontalAxis[i],
                            y: parseFloat(tablerecords[0][reportConfig.horizontalAxis[i]])
                        });
                        let legendVal =  parseFloat(tablerecords[0][reportConfig.horizontalAxis[i]]);
                        let legendFormattedVal =legendVal;
                        legendFormattedVal= legendVal && reportColumnDisplayFormatter(reportConfig,legendFormattedVal,reportConfig.horizontalAxis[i],'');
                        customLegendData.push({
                            name: reportConfig.horizontalAxis[i],
                            value: legendFormattedVal,
                            color: otherSettings && otherSettings.colors.length > i ? otherSettings.colors[i]:''
                        });
                    }
                }
                if(tablerecords.length >0 && reportConfig.reportSettings.additionalColConfigList ){
                    for(let j=0;j<reportConfig.reportSettings.additionalColConfigList.length;j++){
                        let additionalcol =reportConfig.reportSettings.additionalColConfigList[j];
                        let legendVal = parseFloat(tablerecords[0][additionalcol]);
                        let legendFormattedVal =legendVal;
                        legendFormattedVal= legendVal && reportColumnDisplayFormatter(reportConfig,legendFormattedVal,additionalcol,'');
                        if(reportConfig.reportSettings.metaDataDisplay){
                            legendMetaData.push({
                                name: additionalcol,
                                value:legendFormattedVal ,
                                color: 'gray'
                            });
                        }else {
                            customLegendData.push({
                                name: additionalcol,
                                value: legendFormattedVal,
                                color: 'gray'
                            });
                        }
                    }
                }
  
            }else {
              //series data
              let xaxis = reportConfig.horizontalAxis[0];
              let yaxis = reportConfig.verticalAxis[0];
              if(xaxis && yaxis){
               _.forEach(tablerecords, function (rec, index) {
                  seriesData.push( {
                      name: rec[yaxis],
                      y: parseFloat(rec[xaxis])
                  });
                  let legendVal =  parseFloat(rec[xaxis]);
                  let legendFormattedVal =legendVal;
                  legendFormattedVal= legendVal && reportColumnDisplayFormatter(reportConfig,legendFormattedVal,rec[xaxis],'');
                  customLegendData.push({
                      name: rec[yaxis],
                      value: legendVal,
                      color: otherSettings && otherSettings.colors.length > index ? otherSettings.colors[index]:''
                  });
              });
              }
    }
    userConfig.series[0].data = seriesData;
    return userConfig;
  }

const prapareUserConfigForChartOLD = (tablerecords: any, doughnutReportController: any, isDashboard: any) => {
    let reportConfig = doughnutReportController.reportConfig;
    let userConfig: any = {
        series: [{
            data: [],
            innerSize: '70%', 
            point: {
                events: {
                    click: function (event:any) {
                        // return;
                        let $$this:any = this;
                        let value =  $$this?.['options']?.['name'] || '';
                        handleDrillDownReport(event,doughnutReportController,reportConfig,value,reportConfig.verticalAxis[0]);
                    }
                }
            }
            //   innerSize: '70%', 
        }],
    };
    let otherSettings: any;
    let seriesData: any = [];
    let customLegendData = [];
    //series data
    let xaxis = reportConfig.horizontalAxis[0];
    let yaxis = reportConfig.verticalAxis[0];
    if (xaxis && yaxis) {
        _.forEach(tablerecords, function (rec, index) {
            seriesData.push({
                name: rec[yaxis],
                y: parseFloat(rec[xaxis])
            });
            let legendVal = parseFloat(rec[xaxis]);
            let legendFormattedVal = legendVal;
            legendFormattedVal = legendVal && reportColumnDisplayFormatter(reportConfig, legendFormattedVal, rec[xaxis], '');
            customLegendData.push({
                name: rec[yaxis],
                value: legendVal,
                color: otherSettings && otherSettings.colors.length > index ? otherSettings.colors[index] : ''
            });
        });
    }
    userConfig.series[0].data = seriesData;
    return userConfig;
}
const makeTableDetails = (reportConfig: any, doughnutReportConfigDetails: any) => {
    let isBesideTableDetails = false;
    let showTableDetails = false;
    let detailedTableconfig;
    let additionalReportId = false;
    if (reportConfig?.reportSettings) {
        let otherSettings = reportConfig.reportSettings;
        if (otherSettings && otherSettings.showTableDetails) {
            showTableDetails = true;
            isBesideTableDetails = otherSettings.isBesideTableDetails || false;
            detailedTableconfig = {
                columns: [
                    { field: "name", headerName: reportConfig?.verticalAxis?.[0] || 'Title' },
                    { field: "y", headerName: reportConfig.horizontalAxis[0] || 'Count' },
                ],
                data: doughnutReportConfigDetails?.series?.[0].data || []
            }
        }
        if(otherSettings && otherSettings.additionalReportId) {
            additionalReportId = otherSettings.additionalReportId;
            isBesideTableDetails = true;
       }
    }
    return {
        isBesideTableDetails,
        detailedTableconfig,
        showTableDetails,
        additionalReportId
    }
}

export const downloadDoughnutPDF = (chartRef: any, downloadConfig: any,doughnutReportModel:any) => {
    let html = chartRef.current.container.current.getInnerHTML()
    if(htmlToImage){
        htmlToImage.toPng(chartRef.current.container.current)
        .then(function (dataUrl: any) {
            var img = new Image();
            img.src = dataUrl;
            downloadPdfTable(img, '', downloadConfig,doughnutReportModel);
        })
        .catch(function (error: any) {
            console.error('oops, something went wrong!', error);
        });
    }
}
const downloadPdfTable = function (pdfImage: any, mode: any, downloadConfig: any,doughnutReportModel:any) {
    let reportName = getReportName(doughnutReportModel);
    let doc: any = {
        content: [
            {
                text: reportName,
                alignment: 'center',
                fontSize: 13,
            },
            {
                text: getFilterSelectedHeader(doughnutReportModel),
                alignment: 'center',
                fontSize: 9,
            },
            {
                image: pdfImage.src,
                fit: [490,490]
            },
            {
                table: {
                    body: getPdfTableData(downloadConfig?.tableConfig?.detailedTableconfig) || [],
                    alignment: "center"
                }
            },
        ],
    }
    let headers = [];
    if (doc.content && doc.content[1] && doc.content[1].table && doc.content[1].table.body) {
        headers = _.cloneDeep(doc.content[1].table.body[0]);
        doc.content[1].table.body[0] = [];
    }
    _.each(headers, function (val) {
        var obj = { text: val, bold: true, fontSize: 14 };
        doc.content[1].table.body[0].push(obj);
    })

    import('pdfmake/build/pdfmake').then(({default : pdfMake}) => {
        import('pdfmake/build/vfs_fonts').then(({default : pdfFonts}) => {
          (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
          if (mode == 'print') {
            pdfMake.createPdf(doc).print();
           } else {
               pdfMake.createPdf(doc).download(reportName || 'Report');
            }
        })
    })
    
}

const getPdfTableData = (detailedTableconfig: any) => {
    let tableData = [_.map(detailedTableconfig.columns, 'headerName')];
    if (detailedTableconfig?.data?.length) {
        _.forEach(detailedTableconfig.data, function (rec) {
            tableData.push(_.values(rec));
        })
        let totalRow:any = []
        _.forEach(detailedTableconfig.columns, function (col:any,idx:any,array) {
            if (idx === array.length - 1){ 
                totalRow.push("Total : "+( _.sum(_.map(detailedTableconfig.data,'y'))))
            }else {
                totalRow.push("");
            }
        })
        tableData.push(totalRow)
    }
    return tableData;
}

export const getTotalOfReportColumn = (_config:any) => {
    if(_config?.tableConfig?.detailedTableconfig?.data){
        return  _.sum(_.map(_config.tableConfig.detailedTableconfig.data,'y'))
      }
      return 0;
}
