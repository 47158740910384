import { State, useState } from "@hookstate/core"; 
import { KagamiFormModel } from "../../form/model/KagamiFormModel";
import { Breakpoint } from "@mui/material";

export class KagamiModalDialogModel{
  title: any;
  content: any;
  openDialog: boolean;
  modalWidth: Breakpoint;
  state: any;
  isModalDialogTemplate: boolean; 
  handleOnOk: any;
  handleOnClose: any;
  numberofListRecords : number;
    
  
    constructor() {
      this.openDialog = false;
      this.modalWidth = 'md';
      this.isModalDialogTemplate = false;
      this.numberofListRecords = 0
      
    }
    
   
}



export interface KagamiModalDialogModelProps {
  kagamiModalDialogModel: KagamiModalDialogModel;
}
const wrapKagamiModalDialogModelState = (state: State<KagamiModalDialogModel>) => {
  let alertDialogState = state;

  return {
    get isOpen() {
      return alertDialogState.openDialog.get();
    },

    setOpen(isOpen: boolean) {
      alertDialogState.openDialog.set(isOpen);
    },
    get modalWidth() {
      return alertDialogState.modalWidth.get();
    },
    setModalWidth(modalWidth: any) {
      alertDialogState.modalWidth.set(modalWidth);
    },
 
  };
};
export const useKagamiModalDialogModelState = (state: State<KagamiModalDialogModel> ) => {
  let modalState = wrapKagamiModalDialogModelState(useState(state));
  return modalState;
};

export interface ModalDialogProps {
  kagamiFormModel: KagamiFormModel;
}
