import { State, useState } from '@hookstate/core';
import { ReportModalModel } from '../../../../../../view/ReportModal/model/ReportModalModel';

export class ReportIconPopoverModel {
  open: boolean = false;
  anchorEl: HTMLButtonElement | null = null;

  reportList: any[] = [];
  folderList: any[] = [];
  newReportList: any[] = [];
  setOfFolderConfig: any[] = [];
  listOfReportConfigs: any[] = [];
  moveReportsToFolder: any[] = [];
  reportTiles: any = {};
  folderTiles: any = {};
  currentTiles: any = {};
  folderConfig: any = {};
  isDataLoaded: boolean = false;
  reportModalModel: ReportModalModel = new ReportModalModel();
  state: any;
  setRenderer:any;
  // render:any
  key: any;

  constructor() {
    this.key = Math.random().toString();
    // this.render = Math.random();
  }
}

export interface ReportIconPopoverModelProps {
  reportIconPopoverModel: ReportIconPopoverModel;
  translations?: any;
}

export const useReportIconPopoverState = (state: State<ReportIconPopoverModel>) => {
  let reportIconState = useState(state);
  return {
    get isOpen() {
      return reportIconState.open.get();
    },
    setOpen(open: boolean) {
      reportIconState.open.set(open);
    },

    get isDataLoaded() {
      return reportIconState.isDataLoaded.get();
    },
    setDataLoaded(isDataLoaded: boolean) {
      reportIconState.isDataLoaded.set(isDataLoaded);
    },

    // get render() {
    //   return reportIconState.render.get();
    // },
    // setRenderer(len:number){
    //   reportIconState.render.set(len);
    // }
  };
};
