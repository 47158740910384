import { KagamiPopoverModel } from "../../../../../../../../../../../../../../components/popover/model/KagamiPopoverModel";

export class KagamiKanbannSectionModel {
  kannbannDatarecord: any;
  visibleAttributes: any[];
  dataRecord: any[];
  presentation: any;
  kanbannAttribute:string;
  kanbanHeaderColor:any;
  popoverModel: KagamiPopoverModel;
  processName : string
  mainEntityId: string
  constructor(kannbannDatarecord: any,dataRecord: any, presentation: any, visibleAttributes: any[] , kanbannAttribute:string , kanbanHeaderColor:any, processName :string, mainEntityId: string){
    this.kannbannDatarecord = kannbannDatarecord;
    this.visibleAttributes = visibleAttributes;
    this.dataRecord = dataRecord;
    this.presentation = presentation;
    this.kanbannAttribute = kanbannAttribute;
    this.kanbanHeaderColor = kanbanHeaderColor;
    this.popoverModel = new KagamiPopoverModel();
    this.processName = processName;
    this.mainEntityId = mainEntityId
  }
}
export interface KagamiKanbannSectionModelProps {
  kagamiKanbannSectionModel: KagamiKanbannSectionModel;
}
