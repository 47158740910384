import React from "react";
import { createState } from "@hookstate/core";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { VisibleConstants } from "../../../application/constants/VisibeConstants";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";
import { KagamiErrorModalStyle } from "../style/KagamiErrorModalStyle";
import {
  KagamiErrorModalModel,
  KagamiErrorModalModelProps,
  useKagamiErrorModalModelState,
} from "../model/KagamiErrorModalModel";
import { handleModalOnOk } from "../controller/KagamiErrorModalController";
import gitErrorImg from "../../../assets/images/gifs/giferror.gif";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { translateToLocalistaion } from "../../../application/builder/home/builder/body/builder/process/service/PresentationService";
import { getActiveModel } from "../../../application/builder/home/builder/body/builder/process/controller/KagamiProcessController";
import { CommonUtils } from "../../../application/utils/CommonUtils";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Zoom
      timeout={{ appear: 1000, enter: 7000, exit: 7000 }}
      mountOnEnter
      unmountOnExit
      ref={ref}
      {...props}
    />
  );
});

export function builModal(kagamiErrorModalModel: KagamiErrorModalModel) {
  return <KagamiErrorModal kagamiErrorModalModel={kagamiErrorModalModel} />;
}

const useStyles = makeStyles(KagamiErrorModalStyle);
const KagamiErrorModal = (props: KagamiErrorModalModelProps) => {
  let classes = useStyles();
  let modalState: any = useKagamiErrorModalModelState(
    createState(props.kagamiErrorModalModel),
    props.kagamiErrorModalModel.isPickItem
  );
  let translations: any;
  let activeModel = getActiveModel();
  translations =
    activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties
      ?.translation;
  props.kagamiErrorModalModel.state = modalState;
  return (
    <Dialog
      fullWidth={true}
      scroll={"paper"}
      maxWidth={props.kagamiErrorModalModel.modalWidth}
      open={modalState.isOpen}
      onBackdropClick={() => (props.kagamiErrorModalModel.openDialog = true)}
      classes={{}}
      TransitionComponent={Transition}
      aria-labelledby={props.kagamiErrorModalModel.title}
      aria-describedby="classic-modal-slide-description"
    >
      <DialogContent
        style={{
          textAlign:
            props.kagamiErrorModalModel.error == true ? "start" : "center",
          padding: "30px",
        }}
      >
        <div className={classes.errormodal_p1}>
          <div className={classes.errormodal_p2}>
            {props.kagamiErrorModalModel.isServerError ? (
              <ErrorOutlineOutlinedIcon
                color="error"
                fontSize="large"
                sx={{ width: 40, height: 40 }}
              />
            ) : (
              <img
                src={gitErrorImg}
                alt="errorImg"
                width={40}
                height={40}
              ></img>
            )}
          </div>
          <div className={classes.errormodal_p3}>
            <div className={classes.errormodal_p4}>
              {props.kagamiErrorModalModel.isServerError ? (
                <></>
              ) : (
                <Typography
                  variant="h2"
                  style={{ margin: "0", color: "#33475b", fontSize: "1.75rem" }}
                >
                  {props.kagamiErrorModalModel.title === "Error"
                    ? "Validations Failed..."
                    : translateToLocalistaion(
                        translations,
                        props.kagamiErrorModalModel.title
                      )}
                </Typography>
              )}
              <div
                style={{
                  color: props.kagamiErrorModalModel.isServerError
                    ? "#f05252"
                    : "#33475b",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                {props.kagamiErrorModalModel.content}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleModalOnOk(props.kagamiErrorModalModel)}
          >
            {translateToLocalistaion(translations, VisibleConstants.okLabel)}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
