import { Accordion, AccordionSummary, FormLabel, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { KagamiDashboardStyle } from '../style/KagamiDashboard';
import StarIcon from '@mui/icons-material/Star';
import { buildAccordion } from '../../../../../../../../components/accordion/controller/KagamiAccordionController';
import { KagamiAccordionModel } from '../../../../../../../../components/accordion/model/KagamiAccordionModel';
import { executeProcess } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { translateToLocalistaion } from '../../process/service/PresentationService';
import { KagamiDashboardModel } from '../model/KagamiDashboardModel';
import { getDashboardModel } from '../ui/KagamiDashboard';

const useStyles = makeStyles(KagamiDashboardStyle);

export function buildDashboardFavouriteSection(favouriteData: any) {
  return <FavouriteSection key={'kagami-dashboard-favourites'} favouriteData={favouriteData} />;
}
const FavouriteSection = (props: any) => {
  let dashboardModel: KagamiDashboardModel = getDashboardModel();
  let translations = dashboardModel?.containerData?.otherProperties?.translation;
  const classes = useStyles();
  let getGroupNamesFOrFavouriteData = CommonUtils.isNotEmpty(props.favouriteData) ? props.favouriteData.map((ele: any) => ele.groupName) :[];
  let uniqGroupNamesFavouriteData = getGroupNamesFOrFavouriteData.filter((obj: any, index: any) => {
    return getGroupNamesFOrFavouriteData.indexOf(obj) == index;
  });

  const title: JSX.Element = (
    <div>
      <div className={classes.dashboard_icon_header}>
        <StarIcon fontSize="medium" className={classes.dashboard_icon} />
        {/* <FavoriteIcon className={classes.dashboard_icon}></FavoriteIcon> */}
        <span className={classes.dashboard_header}>{translateToLocalistaion(translations, "Favourites")}</span>
      </div>
    </div>
  );
   
  const active = true;

  const content = (
    <div>
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        {uniqGroupNamesFavouriteData.map((ele: any, index:any) => {
          return (
            <li className={classes.dashboardNotificationListItem} key={index}>
              <p className={classes.dashboardNotificationGroupName}>{ele !=undefined ? ele :"Master Data"} </p>
                {': '}
              <div className={classes.dashboardNotificationGroupProcess}>
                {props.favouriteData.map((processLinkData:any) =>{
                  if(processLinkData.groupName ===ele){
                    return <span key={processLinkData.id} className={classes.dashboardNotificationGroupProcessLink } onClick={()=>executeProcess(processLinkData.processId)} title={processLinkData.processName}>{processLinkData.processName}</span>
                  }
                })}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const accordionModel: KagamiAccordionModel = new KagamiAccordionModel(title, content, active);
  return <div className={`col_4 ${classes.dashboardNotificationSectionRoot}`}> {buildAccordion(accordionModel)}</div>;
};
