export class VisibleConstants {
  // login page
  static 'userNamePlaceHolder' = 'Enter User Name';
  static 'userName' = 'User Name';
  static 'passwordPlaceHolder' = 'Enter Password';
  static 'passwordLabel' = 'Password';

  //Button labels
  static 'okLabel' = 'ok';
  static 'cancelLabel' = 'Cancel';
  static 'closeLabel' = 'Cancel';
  static 'saveLabel' = 'Save';
  static 'SubmitLabel' = 'Submit';

  //Error Labels
  static 'UNEXPECTED_ERROR' = 'Unexpected Error';
  static 'EMPTY_RESPONSE_SERVER' = 'Empty response by server';

  //Loader
  static 'LOADING' = 'Loading! Please Wait...';
  static 'assignItem' = 'Assign Item';
}
