import { buildEmbeddedRowCount } from "../EmbeddedRowCount";
import { EmbeddedRowCountModel } from "../model/EmbeddedRowCountModel";



export function embeddedRowCountController(embeddedRowCountModel :EmbeddedRowCountModel){
    return buildEmbeddedRowCount(embeddedRowCountModel)
}



