import ClearIcon from '@mui/icons-material/Clear';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormControl, FormHelperText, FormLabel, IconButton, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react'

import { SystemConstants } from '../../../../../../../../../../constants/SystemConstants';
import { getDmsViewUrl } from '../../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { extractDropdownOption, isLoadRequiredForPresentation } from '../../../../service/PresentationService';
import { PresentationHierarchy } from '../../../form/builder/hierarchy/PresentationHierarchy';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { KagamiFormData } from '../../../form/model/formData';
import { KagamiFormModel } from '../../../form/model/KagamiFormModel';
import { isSingleLovOnchangeRequired } from '../../../form/service/FormService';
import { KagamiTriggerController } from '../../../Triggers/builder/trigger/controller/KagamiTriggerController';
import { confirmBox, setTriggetModel } from '../../../Triggers/builder/TriggerAlertModal/controller/TriggerAlertModalController';
import { validateSelectTextBox } from '../controller/kagamiSelectController';
import { SelectFieldProps, useKagamiSelectModelState } from '../model/kagamiSelectModel';
import { KagamiSelectFieldStyle } from '../styles/KagamiSelectFieldStyle';

const useStyles = makeStyles(KagamiSelectFieldStyle);

function getImageFieldforSelectFields(value: any) {
    if (value && value !== '' && value != undefined) {
      return <img src={getDmsViewUrl(value.includes(':') ? value.split(':')[0] : value)} height="20" width="20" />;
    }
  }
  
function SelectField(props: SelectFieldProps) {
    const classes = useStyles();
  let fieldState = useKagamiSelectModelState(props.kagamiSelectModel.createState);
  const fontProperties = props.kagamiSelectModel.getFontStyling(props.kagamiSelectModel.uiSettings);
  const uiSettingsClassName = props.kagamiSelectModel.getControlWidth(props.kagamiSelectModel.uiSettings)
  
  props.kagamiSelectModel.state = fieldState;
  getFormModel().formControlStates[props.kagamiSelectModel.controlIdentifier] = fieldState;

  useEffect(()=>{
     let formModel : KagamiFormModel = getFormModel();
     /// Note : Avoiding single lov on change in case the form has onload call to avoid conflicts
      if(!(isLoadRequiredForPresentation(formModel.onStartData)) && !props.kagamiSelectModel.readonly && props.kagamiSelectModel.entityId === formModel.mainEntityId && isSingleLovOnchangeRequired(props.kagamiSelectModel)){
        let associatedObject : any = props.kagamiSelectModel.lovRecords[0]
        props.kagamiSelectModel.associatedObject = associatedObject;
        fieldState.setControlValue(associatedObject['id']);
        validateSelectTextBox(props.kagamiSelectModel,associatedObject['id']);
      }

  },[])

  const handleClearClick = () => {
    props.kagamiSelectModel.associatedObject = null;
    fieldState.setControlValue(null);
    validateSelectTextBox(props.kagamiSelectModel, null);
  }

const handleFocus = (value:boolean) =>{
  fieldState.setcancelVisible(value)
}
  let lovRecords = CommonUtils.isNotEmpty(fieldState.lovRecords)
  ? fieldState.lovRecords
  : CommonUtils.isNotEmpty(fieldState.associatedObject)
  ? [fieldState.associatedObject]
  : []; 
  let formModel : KagamiFormModel = getFormModel();
 formModel.triggerAlertModalModel.alertName = 'Warning';
  return (
    <div className = {`${ uiSettingsClassName} ${classes.root}`}>
      {/* {buildTriggerAlertModal(props.kagamiSelectModel.triggerAlertModalModel)} */}
      { (fieldState.isReadOnly === false ) 
      ? <FormControl  variant="outlined" fullWidth  size="small">
           
            {/* <InputLabel 
             id="simple-select-label"
             shrink style={{
              color:fontProperties.labelFontColor,
              fontWeight: fontProperties.labelFontBold ,
              fontStyle: fontProperties.labelFontItalic,
              textDecoration: fontProperties.labelFontUnderline,
             }}
             required={fieldState.isMandatory}
              htmlFor={props.kagamiSelectModel.controlDisplayName}
             >{props.kagamiSelectModel.controlDisplayName}</InputLabel> */}
              
              <FormLabel component="span"  
            required={fieldState.isMandatory}  
            style={{
              color:fontProperties.labelFontColor,
              fontWeight: fontProperties.labelFontBold ,
              fontStyle: fontProperties.labelFontItalic,
              textDecoration: fontProperties.labelFontUnderline,
             }}>{props.kagamiSelectModel.controlDisplayName}</FormLabel>
            <Select  
                className={classes.selectRoot}
                MenuProps={{ classes: { paper: classes.selectPaper } }}
                error={fieldState.errorMessage ? true : false}
                disabled={fieldState.isReadOnly}
                // onFocus={handleFocus}
                onMouseOver={() => handleFocus(true)}
                onMouseLeave={() => handleFocus(false)}
                onFocus={() => handleFocus(true)}
                // onBlur={() => handleFocus(false)}
                // endAdornment={CommonUtils.isNotEmpty(fieldState.controlValue) ? <IconButton onClick={handleClearClick}><ClearIcon/></IconButton> : ""}
                endAdornment={fieldState.cancelVisible && <IconButton size="small" sx={{visibility: CommonUtils.isNotEmpty(fieldState.controlValue) ? "visible": "hidden" , mr: '12px'}} onClick={handleClearClick}> <ClearOutlinedIcon
                sx={{color: (theme: any) => theme.palette.error.main }}
                fontSize="small"/></IconButton>}
                value={fieldState.controlValue === undefined ? SystemConstants.EMPTY : fieldState.controlValue}
                // labelId="simple-select-label"
                id={props.kagamiSelectModel.controlDisplayName}          
                inputProps={{ 'aria-label': 'Without label'  }} 
                // label={props.kagamiSelectModel.controlDisplayName}
                color= "primary"
                style={{color: fontProperties.controlFontColor}}
                notched={true}               
                onChange={(e) => {
                let associatedObject : any = props.kagamiSelectModel.lovRecords.find((record) => record.id === e.target.value)
                props.kagamiSelectModel.associatedObject = associatedObject;
                fieldState.setControlValue(e.target.value);
                validateSelectTextBox(props.kagamiSelectModel, e.target.value);
                }} 
            >
                {lovRecords.map((lovRecord: any) =>
                    lovRecord.fileUrl ? (
                        <MenuItem key={lovRecord.id} value={lovRecord.id}>
                        {getImageFieldforSelectFields(lovRecord.fileUrl)}
                        {extractDropdownOption(props.kagamiSelectModel.attributePresentation, lovRecord)}
                        </MenuItem>
                    ) : (
                        <MenuItem key={lovRecord.id} value={lovRecord.id}>
                        {extractDropdownOption(props.kagamiSelectModel.attributePresentation, lovRecord)}
                        </MenuItem>
                    )
                )}
            </Select>
            <FormHelperText error={fieldState.errorMessage ? true : false}>{fieldState.errorMessage}</FormHelperText>
        </FormControl>
      : <div className={classes.readOnlyField_cont} onClick={KagamiTriggerController.handleHyperLinkClick}>
        <FormLabel component="span" className={classes.controlLabel} style={{color: fontProperties.labelFontColor}} required={fieldState.isMandatory}> {props.kagamiSelectModel.controlDisplayName}</FormLabel>  
        {fieldState.controlValue === undefined 
          ?   <p className={classes.readOnlyField}> ----- </p>
          : <p className={classes.readOnlyField} style={{color: fontProperties.controlFontColor}}>
            {extractDropdownOption(props.kagamiSelectModel.attributePresentation, fieldState.associatedObject)}
          </p>
        }
        </div>
      } 
      
     
    </div>
  )
}

export default SelectField