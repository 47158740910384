import map from 'lodash/map';
import filter from 'lodash/filter';
import { buildtoastNotification } from '../../../../../../../../ToastNotification/controller/ToastNotificationController';
import { getAllRoles, getRoleReportMapping, updateRoleReportMapping } from '../../../../../view/report/service/KagamiReportService';
import { MapRolesModel } from '../model/MapRolesModel';
let kagamiMapRolesModel:MapRolesModel;
export const setAllRolesAndMapReportRolesData = (mapRolesModel: MapRolesModel) => {
  getAllRolesAndMapReportRolesData().then((response:any)=>{
    kagamiMapRolesModel = mapRolesModel;
    mapRolesModel.dbRolesList = response[0].data.respObject;
    mapRolesModel.dbRoleReportMapping = response[1].data.respObject;
    makeMapRoles(mapRolesModel);
    mapRolesModel.state.setDataLoaded(true);
  }).catch((err:any)=>{
    console.log(err);
  })
}

const getAllRolesAndMapReportRolesData = () => {
  return Promise.all([getAllRoles(), getRoleReportMapping()]);
}

export const makeMapRoles = (mapRolesModel: MapRolesModel) => {
  let rolesMappingList: any = [];
  mapRolesModel.dbReportList.forEach((report: any) => {
    if (report.id !== undefined) {
      let mappedrolesList = filter(mapRolesModel.dbRoleReportMapping, { reportId: report.id });
      let mappedRoles = map(mappedrolesList, 'roleId');
      let temp = {
        reportId: report.id,
        rolesMapping: mappedRoles
      };
      rolesMappingList.push(temp);
    }
  });
  mapRolesModel.roleMappedWithReportId = rolesMappingList;
};


export const mapRolesOnSave = (mapRolesModel: MapRolesModel,setDisable:any) => {
  updateRoleReportMapping(mapRolesModel.allSelectedData)
    .then((response: any) => {
      if (response.status === 200) {
        let count = mapRolesModel.allSelectedData.length;
        //showing success alert
        // showToastAlert('SUCCESS', 0)
        buildtoastNotification('success', 'Save Successfull', 0);
      
       while(count!==0){
        mapRolesModel.allSelectedData.pop();
        count--;
       }
       setDisable(true);
      }
    })
    .catch((error: any) => {
      console.log('getting error while updating record', error);
    });
    
};

export const enableAndDisableSaveButton=()=> {
  if (kagamiMapRolesModel) {
    if (kagamiMapRolesModel.allSelectedData.length) {
      kagamiMapRolesModel.setDisableSave(false);
    } else {
      kagamiMapRolesModel.setDisableSave(true);
    }
  }
}