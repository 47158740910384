import { createState } from '@hookstate/core';
import { Box, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { KagamiDMSModel, KagamiDMSProps, useKagamiDMS } from '../model/kagamiDMSModel';
import { KagamiDMSServices } from '../service/kagamiDMSServices';
import { kagamiDMSStyle } from '../style/kagamiDMS';

const useStyles = makeStyles(kagamiDMSStyle);

export const buildKagamiDms = (kagamiDms: KagamiDMSModel) => {
  return <KagamiDms key={Math.random()} kagamiDms={kagamiDms} />;
};

export const KagamiDms = (props: KagamiDMSProps) => {
  const classes = useStyles();
  const kagamiDmsState = useKagamiDMS(createState(props.kagamiDms));
  props.kagamiDms.state = kagamiDmsState;

  React.useEffect(() => {
    KagamiDMSServices.setPdfUrl(props.kagamiDms);
  }, []);

  if (!kagamiDmsState.getPdfUrlLoaded()) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress style={{margin: '0 auto'}}/>
      </Box>
    );
  } else {
    return (
      <>
           {props.kagamiDms.isRespSuccess ? (
          <iframe
            src={props.kagamiDms.pdfUrl}
            className={classes.iframe}
          ></iframe>
        ) : (
          <Typography> File type not supported </Typography>
        )}

      </>
    );
  }
};
