import { Button, Select, MenuItem, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
  getBulkUploadList,
  getExcelHeadersList,
  getOrderedEntityIdList
} from '../../../../../../navigator/BulkUploadService';
import { KagamiBulkUploadModel, KagamiBulkUploadProps, useKagamiBulkUpload } from '../model/BulkUploadModel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinearProgress from '@mui/material/LinearProgress';
import { createState } from '@hookstate/core';
import { applicationURL } from '../../../../../../../../../service/URLService';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiProcessRequestModel } from '../../../model/KagamiProcessRequestModel';
import { loadProcessContent, onSubmitCall } from '../../../controller/KagamiProcessController';
import { showServerError } from '../../form/controller/KagamiFormController';
import { loadGroupContent } from '../../group/controller/KagamiGroupController';
import { getHomeModel } from '../../../../../../../controller/KagamiHomeController';
import { BulkUploadStyle } from '../style/BulkUpload';
import { start } from 'repl';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const buildKagamiBulkUpload = (
  res: any,
  bulkUploadModel: KagamiBulkUploadModel,
  popupModel: KagamiModalModel
) => {
  return <KagamiBulkUpload res={res} bulkUploadModel={bulkUploadModel} popupModel={popupModel} />;
};

const useStyles = makeStyles(BulkUploadStyle);
export const KagamiBulkUpload = (props: KagamiBulkUploadProps) => {
  const bulkUploadState = useKagamiBulkUpload(createState(props.bulkUploadModel));
  let classes = useStyles();

  getOrderedEntityIdList().then((response: any) => {});

  const uploadExcelSheet = (e: any) => {
    let file = e?.target?.files[0];
    bulkUploadState.setIsFileUploadCancelled(false);
    if (e.target.files.length > 0) {
      bulkUploadState.setFileUploaed(file);
      let formData = new FormData();
      bulkUploadState.setIsLogoLoading(true);
      formData.append('fileDetail', file);
      getBulkUploadList(formData).then((response: any) => {
        let docId = response?.data?.data;
        bulkUploadState.setDocIdResponse(docId);
        let excelSheetreq = {
          processName: props.res.processName,
          eventName: props.res.stepName
        };
        getExcelHeadersList(docId, excelSheetreq.eventName, excelSheetreq.processName)
          .then((response: any) => {
            bulkUploadState.setHeaderColumns(response?.data);
            bulkUploadState.setIsLogoLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
            bulkUploadState.setIsLogoLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
            bulkUploadState.setIsLogoLoading(false);
          });
      });
    } else {
      bulkUploadState.setIsFileUploadCancelled(true);
    }
  };

  const saveHandler = (bulkUploadModal: KagamiBulkUploadModel) => {
    let data = {
      bulkUpload: bulkUploadState?.getDocIdResponse,
      bulkUploadXmlObj: bulkUploadState?.getHeaderColumns,
      bulkUploadConfig: null
    };

    let obj = {
      processName: props.res.processName,
      stepName: props.res.stepName
    };

    const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
      obj.processName,
      bulkUploadModal.isGroupForm ? loadGroupContent : loadProcessContent,
      showServerError
    );
    processRequestModel.fromDashboard = false;
    processRequestModel.data = null;
    processRequestModel.bulkUploadProperties = data;
    processRequestModel.submitCall = true;
    if (bulkUploadModal.isGroupForm) processRequestModel.isGroupTemplateNetworkCall = true;
    onSubmitCall(processRequestModel);
    props.popupModel.state.setOpen(false);
  };

  const downloadSampleExcel = () => {
    let excelSheetreq = {
      processName: props.res.processName,
      eventName: props.res.stepName
    };
    let landingUrl =
      applicationURL +
      'verb/bulk/ExcelMetaData?eventName=' +
      excelSheetreq.eventName +
      '&processName=' +
      excelSheetreq.processName;
    window.open(landingUrl, '_blank');
  };

  let homeModel = getHomeModel();
 return (
    <>
      <div>
        <p className={classes.excelUpload}>Excel upload</p>
        <Stack direction="row" alignItems="center" spacing={2} paddingBottom={2}>
          <input 
            type="file"
            name="upload_input"
            id="upload_input"
            hidden
            onChange={uploadExcelSheet}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
           <label htmlFor="upload_input" style={{marginLeft:'0px'}} className={classes.uploadInput }>
            {bulkUploadState?.getFileUploaed ? bulkUploadState?.getFileUploaed?.name : 'No file chosen'}
          </label>
           <> 
          <label htmlFor="upload_input" className={classes.uploadeButton}>UPLOAD
            <FileUploadIcon className={classes.uploadIconeBtn}></FileUploadIcon>
          </label>
          </> 
          <button className={classes.downloadBtn} onClick={() => downloadSampleExcel()}>
          <label>DOWNLOAD
          <FileDownloadIcon className={classes.downloadIconBtn} ></FileDownloadIcon>
          </label>
          </button>
       </Stack>
        {bulkUploadState?.getIsLogoLoading ? (
          <div className={classes.imageIcon} style={{textAlign:'left'}}>
            <div className={classes.image} >
              <img 
                // src={imageBase64()}
                src={homeModel.logodata}
                className={classes.logo}
               />
              <LinearProgress />
            </div>
          </div>
        ) : null}
     {/* Excel file upload successfully*/} 
        <div className={classes.excelUploadedText}>
    <div className='successfullIcon'>
    {bulkUploadState.getHeaderColumns.length > 0 ? (
          <CheckCircleOutlineIcon className={classes.successfullIconBtb}/>
          ) : null}
    </div>
     {bulkUploadState.getHeaderColumns.length > 0 ? (
            <h6 className={classes.excelUploadedColor}>Excel file uploaded successfully.</h6>
          ) : null}
        </div>
        {/* Excel file upload failed*/}
        <div className={classes.excelUploadedText}>
       <div className={classes.failUploadIcon}>
       {bulkUploadState.getIsFileUploadCancelled ? (
         <HighlightOffIcon className={classes.failUploadIconBtn}/>
          ) : null}
       </div>
         {bulkUploadState.getIsFileUploadCancelled ? (
            <h6 className={classes.excelUploadFailColor}>Excel file upload failed</h6>
          ) : null}
        </div>
        {bulkUploadState.getHeaderColumns.length > 0 ? (
          <div className={classes.configUpload}>
            <div className={classes.configUploadText}>
              <h6 className={classes.configUploadData}>Config upload</h6>
            </div>
            <div className={classes.headerColumns}>
              {bulkUploadState?.getHeaderColumns?.map((item: any, i: number) => {
                return (
               <div className={classes.headerRows}>
                  <div className={classes.headersInModal}>{item?.displayName}  </div>
                 
                    <div className={classes.valuesInModal}>
                      <Select variant="standard" value={item?.column} disabled fullWidth label={item?.column}>
                        <MenuItem value={item?.column}>{item?.column}</MenuItem>
                      </Select>
                    </div>
                  </div>
                 );
              })}
            </div>
          </div>
        ) : null}
      </div>
      <Divider />
      <div className={classes.buttonContainer}>
        {bulkUploadState?.getHeaderColumns?.length > 0 ? (
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            onClick={() => saveHandler(props.bulkUploadModel)}
          >
            Save
          </Button>
        ) : null}
      </div>
    </>
  );
};
