export class KagamiTriggersModel {
  entityId: string;
  actions: any[];
  triggerCallback: Function;
  triggerControls: any;
  triggerStates: any;
  triggerComponents: any[];
  detailedObject: any;
  isnotification: boolean = false;
  isDetailView : boolean;
  viewType : any;

  constructor(entityId: string, actions: any[], triggerCallback: Function) {
    this.entityId = entityId;
    this.actions = actions;
    this.triggerCallback = triggerCallback;
    this.triggerControls = {};
    this.triggerStates = {};
    this.triggerComponents = [];
    this.isDetailView = false;
  }
}

export interface TriggersModelProps {
  kagamiTriggersModel: KagamiTriggersModel;
}
