import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { KagamiStateManager } from '../../../../../../../../../../../../../state/KagamiStateManager';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { extractDropdownOption } from '../../../../../../service/PresentationService';
import { getValueForGridSelect, setActiveRowNode } from '../../service/GridService';
export default forwardRef((props: any, ref: any) => {
  setActiveRowNode(props,props.gridModel)
  const [value, setValue] = useState(getValueForGridSelect(props.gridModel, props.attributePresentation));
  const [inputValue, setInputValue] = useState('');
  const {rowData} = props;
  const [options,setOptions] = useState<any>(props.options);
  KagamiStateManager.addStateToHolder(props.gridModel.mainEntityId+'.'+props.gridSelectModel.attributeName,setOptions);
  function onChangeHandler(event: any, child: any) {
    console.log('called onchange handler');
    setValue(event.target.value);
    if(CommonUtils.isEmpty(props.gridModel.activeRowNode)){
      props.gridModel.activeRowNode = props.node;
    }
    props.node.data[props.column.colId] = event.target.value;
    props.gridModel.activeRowNode.data[props.column.colId] = event.target.value;
    // props.node.setDataValue(props.column.colId,value.id);
    props.onCellValueChange(
      props.gridModel,
      props.gridModel.activeRowNode,
      props.attributePresentation.onChangeRequired,
      props.column.colId
    );
  }

  useImperativeHandle(ref, () => {
    console.log('imperative handler');
    return {
      getValue: () => getValueForGridSelect(props.gridModel, props.attributePresentation),
      afterGuiAttached: () => {
        // setActiveRowNode(props,props.gridModel)
        setValue(getValueForGridSelect(props.gridModel, props.attributePresentation));
      },
      refreshEditor:()=>{
        setValue(CommonUtils.isNotEmpty(rowData[props.entityConsumed]) ? rowData[props.entityConsumed].id : '');
      }
    };
  });

  const associatedObject = props.data[props.entityConsumed];
   

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <Select style={{width:'100%'}}
       value={
          (CommonUtils.isNotEmpty(value) ? value : getValueForGridSelect(props.gridModel, props.attributePresentation) || null)
       }
       onChange={onChangeHandler}
       >
        {options.length?options?.map((lovRecord: any) =>
          lovRecord ? (
            <MenuItem key={lovRecord.id} value={lovRecord.id}>
              {extractDropdownOption(props.attributePresentation, lovRecord)}
            </MenuItem>
          ) : (
            <MenuItem key={lovRecord.id} value={lovRecord.id}>
              {extractDropdownOption(props.attributePresentation, lovRecord)}
            </MenuItem>
          )
        ):
        (CommonUtils.isNotEmpty(associatedObject) ?
        [
          <MenuItem key={associatedObject[props.attributePresentation?.entityAssociatedAttribute]} value={associatedObject[props.attributePresentation?.entityAssociatedAttribute]}>
          {extractDropdownOption(props.attributePresentation, associatedObject)}
        </MenuItem>
        ] : <></>)
        }
      </Select>
    </FormControl>
  );
});
