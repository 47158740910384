import * as React from 'react';
import { KagamiInfoIconModel, KagamiInfoIconModelProps, useKagamiAboutState } from '../model/KagamiInfoIconModel';
import { createState } from '@hookstate/core';
import { buildModal } from '../../../../../../../components/modal/controller/KagamiModalController';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { translateToLocalistaion } from '../../../body/builder/process/service/PresentationService';
import { InfoOutlined } from '@mui/icons-material';
import { KagamiAboutUsStyle } from '../styles/KagamiInfoIconStyle';
import logo from '../../../../../../../assets/images/logo.png';
import makeStyles from '@mui/styles/makeStyles';

export const buildInfoIcon: any = (infoIconModel: KagamiInfoIconModel, transitions? : any) => {
  return <KagamiInfoIcon infoIconModel = {infoIconModel} translations = {transitions}/>
};
const useStyles = makeStyles(KagamiAboutUsStyle);

const KagamiInfoIcon = (props: KagamiInfoIconModelProps) => {
  const classes = useStyles();
  const infoIconModel = props.infoIconModel;
  let translateWords = props?.translations;
  const infoIconState = useKagamiAboutState(createState(infoIconModel));
  infoIconModel.state = infoIconState;
  props.infoIconModel.kagamiModalModel.isInfo = true;

  const handleAboutUs = () => {
    infoIconModel.kagamiModalModel.title = translateToLocalistaion(translateWords, "About Us");
    infoIconModel.kagamiModalModel.content = (
      <div>
        <div className={classes.aboutUs_kagamiLogo}>
          <img src={logo}/>
        </div>
        <div className={classes.aboutUs_bottomCard}>
          <Typography style={{fontSize: '14px'}}>Kagami Runtime</Typography>
          <Typography style={{fontSize: '14px'}}>Copyright &copy; <a href="https://kagamierp.com/" target="_blank" style={{color: '#006e7f'}}>2023 Kagami India pvt. ltd. All rights reserved.</a></Typography>
          <Typography style={{fontSize: '14px'}}>
            Warning: This program is protected by copyright law and international treaties.
            Unauthorized reproduction or distribution of this program, or any portions of it, may result in severe civil
            and criminal penalties, and will be prosecuted to the maximum extent possible under the law.
          </Typography>
        </div>
      </div>  
    )
    infoIconModel.kagamiModalModel.state.setOpen(true);
  };
  return(
    <>
      {buildModal(infoIconModel.kagamiModalModel)}{' '}
      <Tooltip title = {translateToLocalistaion(translateWords, "Info")} arrow>
        <IconButton onClick={handleAboutUs}>
          <InfoOutlined/>
        </IconButton>
      </Tooltip>
    </>
  )
}
