import { State, useState } from '@hookstate/core';
import { KagamiHierarchyViewModel } from '../../../model/KagamiHierarchyViewModel';

export interface NodeIn {
  node: any;
  parent: any;
}

export class NodeModel {
  visibleAttributes: any[];
  presentation: any;
  collapsed: boolean;
  data: any;
  children: NodeModel[];
  parent: any;
  state: any;

  constructor(hierarchyViewModel: KagamiHierarchyViewModel, presentation: any, visibleAttributes: any) {
    this.visibleAttributes = visibleAttributes;
    this.presentation = presentation;
    this.collapsed = false;
    this.data = hierarchyViewModel.node.data;
    this.children = hierarchyViewModel.node.children;
  }
}

export interface NodeProps {
  nodeModel: NodeModel;
}

export const wrapNodeModel = (state: State<NodeModel>) => {
  const hierarchyState = state;
  return {
    getCollapsed() {
      return hierarchyState.collapsed.get();
    },
    setCollapsed(collapse: any) {
      hierarchyState.collapsed.set(collapse);
    }
  };
};

export const useHierarchyNode = (state: State<NodeModel>) => {
  const hierarchyViewState = wrapNodeModel(useState(state));
  return hierarchyViewState;
};
