import { Theme } from '@mui/material';
import { red } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
export const BulkUploadStyle = (theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px'
    },
    button: {
      color: 'white',
      border: '1px solid #006e7f',
      backgroundColor: '#006e7f',
      borderRadius: '5px',
      '&:hover': {
        background:'#006e7f'
      },
    },
    uploadInput: {
      // width: '50%',
      width:'40%',
      textAlign:'left',
      borderBottom: '1px solid grey',
      paddingBottom: '5px',
      marginLeft:'0px'
    
    },
    configUpload: {
      display: 'flex',
      // alignItems: 'flex-start',
      // justifyContent: 'space-between'
      flexDirection:'column',
      gap:'20px'

    },
    headersInModal: {
      borderBottom: '1px dotted grey',
       width: '100%',
      textAlign: 'left',
      paddingBottom: '10px',
      marginRight: '10px',
      cursor: 'not-allowed',
      marginBottom:'10px',
      fontSize:'13px',
      marginTop:'5px',
    },
    valuesInModal: {
      width: '100%',
      marginBottom: '5px',
      paddingBottom: '5px',
      textAlign: 'left',
      // marginLeft:'14px'
    },
    excelUpload: {
      textAlign: 'left'
      },
    headerRows: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    configUploadData: {
      margin: '0'
    },
    imageIcon: {
      textAlign: 'center',
      marginTop: '20px'
    },
    image: {
      width: '10%',
      display: 'inline-block'
    },
    excelUploadedText: {
      textAlign: 'left',
      position:'relative'
    },
    excelUploadedColor: {
      color: '#4caf50'
    },
    excelUploadFailColor: {
      color: '#e51c23'
    },
    configUploadText: {
      flex: 0.2,
      textAlign: 'left',
      // border:'1px solid gray',
      backgroundColor: '#6bb5c1',
      border:'none',
      padding: '8px',
      borderRadius: '5px',
      fontSize: '20px',
      color:'#fff'
    },
    headerColumns: {
      flex: 0.7
    },
    logo: {
      width: '100%',
     },
    // ------------------------>
    uploadeButton:{
      background:'#6bb5c1',
      borderRadius:'7PX',
      border: 'none',
      color: '#fff',
      padding:'10px 40px 4px 10px',
      cursor: 'pointer',
      position:'relative',
      fontSize:'12px',
      fontWeight:'500',
   },
    uploadIconeBtn:{
      position:'absolute',
      top:'5px',
      bottom:'0px',
      // left:'0px',
      right:'12px'
    },
    downloadBtn:{
      background:'#6bb5c1',
      borderRadius:'7PX',
      border: 'none',
      color: '#fff',
      padding:'9px 43px 9px 15px',
      cursor: 'pointer',
      position:'relative',
      fontSize:'12px',
      fontWeight:'600'
     },
    downloadIconBtn:{
      position:'absolute',
      top:'5px',
      bottom:'0px',
      // left:'0px',
      right:'12px'
     },
    successfullIconBtb:{
    position:'absolute',
    bottom:'59px',
    left:'269px',
    color:'green'
  },
  failUploadIcon:{
    position:'relative'
  },
  failUploadIconBtn:{
    position:'absolute',
    bottom:'84px',
    left:'302px',
    color:'red'
 },
  });
