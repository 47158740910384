import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const kagamiDMSStyle = (theme:Theme) => createStyles({
    iframe:{
       minHeight:'510px',
       maxHeight:'800px',
       height:'calc(100vh - 110px)',
       width: '100%',
    }
})