import { createState } from '@hookstate/core';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { buildLazyHierarchy } from '../builder/hierarchy-node/ui/KagamiLazyHierarchy';
import { HierarchyViewLine } from '../builder/KagamiHierarchyLineBuilder';
import { getKagamiLazyHierarchyModel } from '../controller/KagamiHierarchyViewController';
import {
  KagamiHierarchyViewModel,
  KagamiHierarchyViewProps,
  useHierarchyViewState
} from '../model/KagamiHierarchyViewModel';

import { HierarchyViewData } from '../service/KagamiHierarchyViewService';

export function buildHierarchyView(hierarchyViewModel: KagamiHierarchyViewModel) {
  return <KagamiHierarchyView key={'kagami-hierarchy-view'} kagamiHierarchyViewModel={hierarchyViewModel} />;
}

export const KagamiHierarchyView = (props: KagamiHierarchyViewProps) => {
  let hierarchyViewModel = props.kagamiHierarchyViewModel;

  if(CommonUtils.isArray(props.kagamiHierarchyViewModel.detailedObjects) && props.kagamiHierarchyViewModel.detailedObjects.length!=0){
    hierarchyViewModel.isListLoaded = true;
  }
  
  let hierarchyState = useHierarchyViewState(createState(hierarchyViewModel));
  hierarchyViewModel.state = hierarchyState;

  if(hierarchyState.getListLoaded() && !hierarchyState.getTreeDataLoaded()){
    HierarchyViewData.getDynamicTreeData(hierarchyViewModel);
  }

  if (!hierarchyState.getTreeDataLoaded()) {
    return <p> </p>;
  } else {
    if(hierarchyViewModel.presentation.uiSettings.cardViewConfiguration.lazyHierarchy) {
    let lazyHierarcyViewModel = getKagamiLazyHierarchyModel();
    return buildLazyHierarchy(lazyHierarcyViewModel)
    } else {
    return <HierarchyViewLine key={'node1'} kagamiHierarchyViewModel={hierarchyViewModel} />;
    }
  }
};
