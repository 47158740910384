import { v4 as uuidv4 } from 'uuid';
import { InputData } from '../model/InputData';
import { RequestEvent } from '../model/RequestEvent';
import { UIEvent } from '../model/UIEvent';
import { Event } from '../model/Event';
import { RuntimeInput } from '../model/RuntimeInput';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { getPresenationIdFromPresentation, getViewTypeByPresentation } from './PresentationService';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { getFormModel } from '../builder/form/controller/KagamiFormController';
import { setEmbeddedInfo, setEmbeddedInfo_scroll } from './ProcessService';

let guid = '';
let windowId = generateWindowId();

export function createOnStartEvent(
  processName: string,
  data: any,
  previousProcessName: string,
  fromDashboard: boolean
) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();

  requestEvent.event = event;
  requestEvent.fromUi = true;

  if (fromDashboard) {
    guid = generateGuid();
    requestEvent.guid = guid;
  } else {
    requestEvent.guid = guid;
  }
  requestEvent.windowId = windowId;

  requestEvent.inputData = inputData;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = previousProcessName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;

  if (data != null) {
    requestEvent.inputData.setDetailedObjects(data);
  }

  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'START';
  requestEvent.uiEvent.uiEventValue = 'start';

  return requestEvent;
}

export function generateGuid() {
  let guid = uuidv4();
  return guid;
}

export function generateWindowId() {
  let guid = uuidv4();
  return guid;
}

export function createOnloadEvent(
  processName: string,
  entityPresentation: any,
  changedData: any,
  data: any,
  runtimeInput: RuntimeInput | null,
  verbProperties?: any,
  isHierarchy: boolean = false,
  embeddedInfo : any = {}
) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();

  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = generateWindowId();

  requestEvent.inputData = inputData;

  requestEvent.inputData.isAttributeChange = true;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;

  if (runtimeInput != null && runtimeInput.calendarStart != null && runtimeInput.calendarStart != '') {
    requestEvent.inputData.verbProperties = { template: 'calendar' };
  }
  if (verbProperties && verbProperties !== null) {
    requestEvent.inputData.verbProperties = verbProperties;
  }

  if (runtimeInput != null) {
    runtimeInput.id = 'RuntimeInput';
    let input = {
      RuntimeInput: [runtimeInput]
    };
    requestEvent.inputData.detailedObjects = input;
  }

  if (data != null && requestEvent.inputData.verbProperties.template != 'calendar') {
    requestEvent.inputData.setDetailedObjects(data);
  }

  if (changedData != null) {
    requestEvent.inputData.changeFor = changedData;
  }
  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'ONLOAD';
  if(entityPresentation['uiTemplate'] === 'list' && getViewTypeByPresentation(entityPresentation) == 'hierarchical'){
    requestEvent.inputData.verbProperties.template = 'calendar';
    requestEvent.inputData.verbProperties.byMeForMe = 'DEFAULT';
  }
  if(isHierarchy) {
    requestEvent.uiEvent.uiEventValue = getPresenationIdFromPresentation(entityPresentation) + '_hierarchy';
    
  } else {
    requestEvent.uiEvent.uiEventValue = getPresenationIdFromPresentation(entityPresentation) + '_onLoad';
  }
   if(CommonUtils.isNotEmpty(embeddedInfo)){
     requestEvent.inputData.embeddedInfo = embeddedInfo;
   }

  return requestEvent;
}

export function createOnChangeEvent(
  processName: string,
  changedAttribute: string,
  entityPresentation: any,
  changedData: any,
  data: any,
  control?: any,
) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();

  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = windowId;

  requestEvent.inputData = inputData;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;

  requestEvent.inputData.properties['attrSequence'] = {
    attrName: changedAttribute,
    timeStampKey: new Date().getTime()
  };

  if (data != null) {
    requestEvent.inputData.setDetailedObjects(data);
  }

  requestEvent.inputData.isAttributeChange = true;

  requestEvent.inputData.changeFor = changedData;
  
  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'ONCHANGE';
  requestEvent.uiEvent.uiEventValue =
    getPresenationIdFromPresentation(entityPresentation) + '_' + changedAttribute + '_onChange';
  return requestEvent;
}

export function createOnSubmitEvent(processName: string, data: any, bulkUploadProperties?: any, changeFor?: any,isPickItem:boolean=false,isModalResponse:boolean = false) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();
  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = windowId;
  requestEvent.inputData = inputData;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;


  if (data != null) {
    console.log(data);
    requestEvent.inputData.detailedObjects = data;
  }

  // if(isModalResponse){
  //   requestEvent.inputData.detailedObjects = changeFor;
  // }

  if (Object.keys(bulkUploadProperties)?.length > 0) {
    requestEvent.properties = bulkUploadProperties;
  }

  if (CommonUtils.isNotEmpty(changeFor)) {
    requestEvent.inputData.changeFor = changeFor;
  }

    requestEvent.uiEvent = uiEvent;
    requestEvent.uiEvent.uiEventName = 'ONSUBMIT';
    requestEvent.uiEvent.uiEventValue = 'onSubmit';

  if(isPickItem){
    requestEvent.uiEvent.uiEventType = 'EXPLICIT';
  }
  if(isModalResponse){
    requestEvent.uiEvent.uiModalResponse ='YES'
  }
  return requestEvent;
}

export function createOnCancelEvent(processName: string, entityPresentation: any, changedData: any, data: any) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();

  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = windowId;

  requestEvent.inputData = inputData;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;

  if (data && data != null) {
    requestEvent.inputData.setDetailedObjects(data);
  }

  requestEvent.inputData.isAttributeChange = true;
  requestEvent.inputData.changeFor = changedData;

  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'ONCANCEL';
  requestEvent.uiEvent.uiEventValue = getPresenationIdFromPresentation(entityPresentation) + '_onCancel';

  return requestEvent;
}

export function createOnSearchEvent(processName: string, presentationId: any, runtimeInput: any, attributeName: any) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();
  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = generateWindowId();
  requestEvent.inputData = inputData;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;
  requestEvent.inputData.detailedObjects[SystemConstants.RUNTIME_INPUT] = [runtimeInput];
  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'SEARCH';
  if (attributeName == null) {
    requestEvent.uiEvent.uiEventValue = presentationId + '_Global_onSearch';
  } else {
    requestEvent.uiEvent.uiEventValue = presentationId + '_' + attributeName + '_onSearch';
  }
  return requestEvent;
}

export function createOnSearchListEvent(
  processName: string,
  presentationId: any,
  runtimeInput: any,
  attributeName: any,
  pageNumber: Number,
  changeFor?:any,
  detailedObjects?:any
) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  requestEvent.uiEvent = uiEvent;
  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = windowId;
  requestEvent.uiEvent.uiEventName = 'SEARCH';
  if (attributeName == null || attributeName == undefined) {
    requestEvent.uiEvent.uiEventValue = presentationId + '_Global_onSearch';
    requestEvent.uiEvent.uiEventType = null;
  } else {
    requestEvent.uiEvent.uiEventValue = presentationId + '_' + attributeName + '_onSearch';
  }
  if (requestEvent.inputData != null || requestEvent.inputData != undefined) {
    let runtimeInputEvent = [];
    if (runtimeInput.searchKey != null || runtimeInput.searchKey != undefined) {
      runtimeInputEvent.push({ id: runtimeInput.id, searchKey: runtimeInput.searchKey });
    }
    if(CommonUtils.isNotEmpty(detailedObjects)){
      requestEvent.inputData.detailedObjects = detailedObjects
    }
    requestEvent.inputData.detailedObjects['RuntimeInput'] = runtimeInputEvent;
    if(CommonUtils.isNotEmpty(changeFor)){
      requestEvent.inputData.changeFor = changeFor;
    }
    requestEvent.inputData.processName = processName;
    requestEvent.inputData.properties = {
      fromUI: requestEvent.fromUi,
      guid: requestEvent.guid,
      windowId: requestEvent.windowId
    };
    requestEvent.inputData.verbProperties = {
      pageNumber: pageNumber
    };
  }

  return requestEvent;
}

export function createOnSearchFiltersListEvent(
  processName: string,
  presentationId: any,
  runtimeInput: any,
  attributeName: any,
  searchRecord?: any,
  pageNumber?:Number
) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  requestEvent.uiEvent = uiEvent;
  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = windowId;
  requestEvent.uiEvent.uiEventName = 'SEARCH';
  if (attributeName == null || attributeName == undefined) {
    requestEvent.uiEvent.uiEventValue = presentationId + '_Global_onSearch';
    requestEvent.uiEvent.uiEventType = null;
  } else {
    requestEvent.uiEvent.uiEventValue = presentationId + '_' + attributeName + '_onSearch';
  }
  if (requestEvent.inputData != null || requestEvent.inputData != undefined) {
    let runtimeInputEvent = [];
    runtimeInputEvent.push({ id: runtimeInput.id });
    requestEvent.inputData.detailedObjects['RuntimeInput'] = runtimeInputEvent;
    requestEvent.inputData.processName = processName;
    requestEvent.inputData.properties = {
      fromUI: requestEvent.fromUi,
      guid: requestEvent.guid,
      windowId: requestEvent.windowId,
    };
    requestEvent.inputData.verbProperties = {
      pageNumber: pageNumber === undefined ? 1 : pageNumber
    };
    requestEvent.inputData.verbProperties['searchObj'] = searchRecord;
  }
  delete requestEvent.inputData?.changeFor;
  delete requestEvent.inputData?.embeddedInfo;
  delete requestEvent.inputData?.isAttributeChange;
  delete requestEvent.inputData?.nextStepToBeexecuted;
  delete requestEvent.inputData?.previousProcessName;
  return requestEvent;
}
export function createOnClickEvent(
  processName: any,
  mainEntityId: any,
  embeddedEntity: any,
  detailedObject: any,
  mainPresenationId: any
) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();
  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = generateWindowId();

  requestEvent.inputData = inputData;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;
  let idTobeSendToOnClickCall: any = {};
  idTobeSendToOnClickCall[mainEntityId] = [{ id: detailedObject }];
  requestEvent.inputData.detailedObjects = idTobeSendToOnClickCall;
  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'ONCLICK';
  if (mainPresenationId != null) {
    requestEvent.uiEvent.uiEventValue = mainPresenationId + '_onClick_' + embeddedEntity;
  }

  return requestEvent;
}

export function createExplicitEvent(
  processName: string,
  entityPresentation: any,
  detailedObjects: any,
  fieldName: any,
  presenationId: any,
  changeFor : any
) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();

  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = generateWindowId();

  requestEvent.inputData = inputData;

  requestEvent.inputData.isAttributeChange = true;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.previousProcessName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;

  if (detailedObjects != null) {
    requestEvent.inputData.detailedObjects = detailedObjects;
  }

  if(changeFor !== null){
    requestEvent.inputData.changeFor = changeFor
  }

  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'EXPLICIT';
  setEmbeddedInfo(requestEvent, getFormModel())
  requestEvent.uiEvent.uiEventValue = presenationId + '_onClick_of_' + fieldName;

  return requestEvent;
}

export function createExplicitAssignEvent(processName: string, parentProcessName: string, data: any) {
  var requestEvent: RequestEvent = new RequestEvent();
  var event: Event = new Event(processName);
  var uiEvent: UIEvent = new UIEvent();
  var inputData: InputData = new InputData();

  requestEvent.event = event;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = generateWindowId();

  requestEvent.inputData = inputData;

  // requestEvent.inputData.isAttributeChange = true;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.parentProcessName = parentProcessName;
  requestEvent.inputData.previousProcessName = parentProcessName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.nextStepToBeexecuted = null
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;

  if (CommonUtils.isNotEmpty(data)) {
    requestEvent.inputData.detailedObjects = data;
  }

  requestEvent.uiEvent = uiEvent;
  requestEvent.uiEvent.uiEventName = 'START';
  requestEvent.uiEvent.uiEventType = 'EXPLICIT';
  requestEvent.uiEvent.uiEventValue = 'start';

  return requestEvent;
}

export function createLinkProcessRequestEvent(linkProcessId : string){
  guid = generateGuid();
  let requestData = {
    "linkId": linkProcessId,
    "guid": guid,
    "windowId": windowId
  }
  return requestData;
}

export function createEmbedColumnSearchEvent(processName : string,detailedObjects : any,runtimeInput : RuntimeInput,displayAttribute : string,searchValue :string,entityId : string, embeddedId?:string, pageNumber:Number=1){
  let requestEvent : RequestEvent = new RequestEvent();
  let event : Event = new Event(processName);
  let uiEvent : UIEvent = new UIEvent();
  let inputData : InputData = new InputData();
  requestEvent.event = event;
  requestEvent.uiEvent = uiEvent;
  requestEvent.fromUi = true;
  requestEvent.guid = guid;
  requestEvent.windowId = windowId;
  requestEvent.uiEvent.uiEventName = 'PAGECHANGE';
  requestEvent.uiEvent.uiEventType = null;
  requestEvent.uiEvent.uiEventValue = null;

  requestEvent.inputData = inputData;
  requestEvent.inputData.processName = processName;
  requestEvent.inputData.properties = new Map();
  requestEvent.inputData.properties['fromUI'] = true;
  requestEvent.inputData.properties['guid'] = guid;
  requestEvent.inputData.properties['windowId'] = windowId;

  if(CommonUtils.isNotEmpty(detailedObjects)){
    requestEvent.inputData.setDetailedObjects(detailedObjects);
  }

  if(CommonUtils.isNotEmpty(embeddedId)){
    // requestEvent.inputData.verbProperties = {pageNumber : 2}
    setEmbeddedInfo_scroll(requestEvent,getFormModel(),displayAttribute,searchValue,embeddedId, pageNumber)
  }
  else{
    if(CommonUtils.isNotEmpty(runtimeInput)){
      let runtimeInputEvent = [];
        runtimeInputEvent.push({ id: runtimeInput.id, searchKey: runtimeInput.searchKey });
      requestEvent.inputData.detailedObjects['RuntimeInput'] = runtimeInputEvent
    }

    requestEvent.inputData.verbProperties = {pageNumber : 1}
    setEmbeddedInfo(requestEvent,getFormModel(),displayAttribute,searchValue,entityId)
  }
  return requestEvent;
}
