import { KagamiEmbeddedPresentationModel } from "../model/KagamiEmbeddedPresentationModel";
import { buildEmbeddedPresentationComponent } from "../ui/KagamiEmbeddedPresentation";

export function buildEmbeddedPresentation(embeddedPresentationModel : KagamiEmbeddedPresentationModel){
    setEmbedViewId(embeddedPresentationModel);
    return buildEmbeddedPresentationComponent(embeddedPresentationModel);
}

function setEmbedViewId(embeddedPresentationModel : KagamiEmbeddedPresentationModel){
    if(embeddedPresentationModel.embeddedFormModels.length > 0){
        embeddedPresentationModel.embedViewId = embeddedPresentationModel.embeddedFormModels[0].isNested ? 'ParentNestedEmbedState' : 'ParentEmbedState';
    }
}