import _ from "lodash";
import { CommonUtils } from "../../../../../../../../../../../../utils/CommonUtils";
import { isOnCancelRequiredForPresentation } from "../../../../../../service/PresentationService";
import { buildEmbeddedPresentation } from "../../../../../form/builder/embedded-presentation/controller/KagamiEmbeddedPresentationController";
import { KagamiEmbeddedPresentationModel } from "../../../../../form/builder/embedded-presentation/model/KagamiEmbeddedPresentationModel";
import { buildEmbeddedModel } from "../../../../../form/builder/FormBuilder";
import { getFormModel } from "../../../../../form/controller/KagamiFormController";
import { KagamiEmbeddedFormPresentationModel } from "../../../../../form/model/KagamiEmbeddedFormPresentationModel";
import { KagamiFormModel } from "../../../../../form/model/KagamiFormModel";

export default (props: any) => {
  console.log('building nested embed presentations');
  
  let embeddedPresentationModel: KagamiEmbeddedPresentationModel = new KagamiEmbeddedPresentationModel(
    getNestedEmbedModels(props.nestedEmbedModels,props.data),
    'tab'
  );


   return <div style={{padding : 20}}>{buildEmbeddedPresentation(embeddedPresentationModel)}</div>;
}

function getNestedEmbedModels(nestedEmbedModels : any[],dataRecord : any){
  let nestedEmbedModel : any;
  let clonedNestedEmbedModels : any[] = [];
  let formModel : KagamiFormModel = getFormModel();
  for(nestedEmbedModel of nestedEmbedModels){
    let clonedModel = formModel.formControls[nestedEmbedModel.formEntityId+'.'+dataRecord.id]
    if(CommonUtils.isNotEmpty(clonedModel)){
      clonedModel.onLoadData = dataRecord[clonedModel.formEntityId]
      clonedNestedEmbedModels.push(clonedModel)
    }
  }
  return clonedNestedEmbedModels;
}

export function cloneAndPushNestedModelsToFormControls(nestedEmbedModels : any[],embedRecord : any){
  let clonedNestedEmbedModels : KagamiEmbeddedFormPresentationModel[] = [];
  let formControls : any = getFormModel().formControls
  nestedEmbedModels.forEach((embedModel : KagamiEmbeddedFormPresentationModel)=> {
    let modelIdentifier : string = embedModel.formEntityId+'.'+embedRecord.id
  if(!Object.keys(formControls).includes(modelIdentifier)){
    let nestedEmbedModel = buildEmbeddedModel(
      embedModel.presentation,
      embedModel.onStartData,
      embedModel.mainEntityId,
      embedModel.formEntityId,
      embedModel.formHeader,
      embedModel.processName,
      embedModel.processStepName,
      embedModel.uiTemplate,
      embedModel.mandatory,
      embedModel.visible,
      embedModel.readonly,
      embedModel.isListPresentation,
      embedModel.onloadRequired,
      embedRecord[embedModel.formEntityId],
      embedModel.presentationPolicy,
      embedModel.businessPolicy,
      embedModel.isDynamicForm,
      embedModel.presentationId
   );
   nestedEmbedModel.parentEmbedIdForNestedEmbed = embedRecord.id
   nestedEmbedModel.onCancelRequired = isOnCancelRequiredForPresentation(nestedEmbedModel.presentation);
   nestedEmbedModel.isNested = true;
   nestedEmbedModel.parentEmbedEntityId = embedModel.parentEmbedEntityId;
   clonedNestedEmbedModels.push(nestedEmbedModel);
   getFormModel().pushToFormControls(nestedEmbedModel.formEntityId+'.'+embedRecord.id,nestedEmbedModel);
  }
  else{
    embedModel.onLoadData = _.clone(embedRecord[embedModel.formEntityId])
    clonedNestedEmbedModels.push(embedModel)
  }
});
return clonedNestedEmbedModels;
}
