import axios from 'axios';
axios.defaults.headers.get['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers['Cache-Control'] = 'no-cache, no-store';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.source = 'runtime';
axios.defaults.withCredentials = false;

export class APIService {
  static getData(httpMethod: string, endPoint: string, requestData: any): any {
    let response: Promise<string> = new Promise((resolve) => {
      // console.log(resolve);
      // wat to do with this
    });
    switch (httpMethod) {
      case 'GET':
        response = axios.get(endPoint, requestData);
        break;

      case 'POST':
        response = axios.post(endPoint, requestData);

        break;
    }
    return response;
  }

  static setDeviceInfo(hardwareId : string,serialNo : string):any{
    axios.defaults.headers['device-info'] = `hardware_id=${hardwareId}, serial_no=${serialNo}`
  }
}

axios.interceptors.request.use(
  function (config) {
   // config.withCredentials = true;

    if (config.baseURL === '') {
      config.timeout = 4000;
    } else {
      return config;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
