import * as React from 'react';
import { createState ,useState } from '@hookstate/core';
import {
  TrialBalanceReportModel,
  TrialBalanceReportModelProps,
  useTrialBalanceReportModelState
} from '../model/TrialBalanceReportModel';
import { makeReport,makeReportData ,getData,onChangeState,downloadExcel}  from '../service/TrialBalanceReportService';
import Grid from '@mui/material/Grid';
import { Autocomplete,TextField,Button,ButtonGroup } from '@mui/material';
import {  AgGridReact } from 'ag-grid-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';


export const BuildTrialBalanceReportView = (trialBalanceReportModel: TrialBalanceReportModel) => {
  return (
    <TrialBalanceReportView key={'trial-balance-report'} trialBalanceReportModel={trialBalanceReportModel}></TrialBalanceReportView>
  );
};


const TrialBalanceReportView = (props: TrialBalanceReportModelProps) => {
   //Open
   const [startDateCalendar, setStartDateCalendar] = React.useState(false);
   const [endDateCalendar, setEndDateCalendar] = React.useState(false);
   // Date
   let startDateYear=new Date();
   startDateYear.setDate(1);
   startDateYear.setMonth(0);
   let yearStartDate = new Date(startDateYear).toISOString().substring(0, 10);
   let todayDate = new Date().toISOString().substring(0, 10);
   const [startDate, setStartDate] = React.useState(yearStartDate);
   const [endDate, setEndDate] = React.useState(todayDate);
   const [selectstate,setSelectState] = React.useState(false);
   const [selectproject, setSelectProject] = React.useState(false)


  let runReportConfig:any = useState({
    loadReport : false,
    loadFilterData:true,
    showLoader:false,
    reportInfo:{}
  });

  let trialBalanceReportModel = props.trialBalanceReportModel;
  let trialBalanceChartState = useTrialBalanceReportModelState(createState(trialBalanceReportModel));
  trialBalanceReportModel.state = trialBalanceChartState;
  let staticFilterData :any;
  if(trialBalanceReportModel?.reportData?.staticFilterData) {
    staticFilterData  = trialBalanceReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo:any ={}
  if(trialBalanceReportModel?.reportData?.gridReportInfo) {
    gridReportInfo  = trialBalanceReportModel.reportData['gridReportInfo'];
  }
  let inputReportConfig= (props.trialBalanceReportModel.reportConfig);
  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo =  makeReport(trialBalanceReportModel);
    let trailBalanceReportData =  await getData(trialBalanceReportModel,inputReportConfig)
    let rowData = await makeReportData(trialBalanceReportModel,trailBalanceReportData)
    reportInfo['rowData']= rowData;
    reportInfo['reportDBData']= trailBalanceReportData;
    const obj = Object.assign({}, reportInfo);
    props.trialBalanceReportModel.reportData['gridReportInfo'] = obj;
    // runReportConfig.reportInfo.set(obj);
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }

  const handleStateChange =  async (value:any) => {
    let projectPlan = [];
    if (value && value.length > 0) {
      projectPlan = await onChangeState(value, trialBalanceReportModel);
    } else {
      projectPlan = staticFilterData['allProjects'];
    }
    staticFilterData['projectPlan'] = projectPlan;
    runReportConfig.loadFilterData.set(true);
  }
  const handleStaticFilters = (value: any, type: any) => {
    if (type == 'startDate' || type == 'endDate') {
      if (!inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {};
      }
      inputReportConfig['leftPanel'][type] = value;
    } else if (type == 'state' || type == 'projectPlan') {
      inputReportConfig[type] = value && value.length > 0 ? value.join(",") : [];
    } else {
      inputReportConfig[type] = value;
    }
  }

  const onDownloadExcel = () =>{
    downloadExcel(props.trialBalanceReportModel);
  }
  const gridRef:any = React.useRef();
  const expandAll = React.useCallback(() => {
    if(!trialBalanceReportModel.collapseAll){
      gridRef.current!.api.expandAll();
      trialBalanceReportModel.collapseAll=true;
    }else{
      gridRef.current!.api.collapseAll();
      trialBalanceReportModel.collapseAll=false;
    }
    
  }, []);
  return (
    <div className="Report-MuiFormControl-root" style={{width:'100%' ,background: '#fff',padding: '10px'}}>
      {/* fields  */}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {/* Sub Organization */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="trailBalanceReportSubOrganization"
            options={staticFilterData && staticFilterData.suborganization||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Sub Organization"
                placeholder="Select Sub Organization"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'selectedValue');
            }}

          />
        </Grid>
        {/* Currency */}
        {staticFilterData?.currency && staticFilterData.currency.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="trailBalanceReportCurrency"
            options={staticFilterData && staticFilterData.currency||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                required
                {...params}
                label="Currency List "
                placeholder="Select Currency"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'selectedCurrency');
            }}
          />
          </Grid>  
          }
        {/* State */}
        { runReportConfig.loadFilterData.get() &&  staticFilterData?.state && staticFilterData.state.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
           size="small"
            multiple
            id="trailBalanceReportState"
            options={staticFilterData && staticFilterData.state||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="State"
                placeholder={selectstate === false?"Select state":''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStateChange(newValue);
              handleStaticFilters(newValue,'state');
              if(newValue.length>0){setSelectState(true)}
              else{
                setSelectState(false)
              }
            }}
          />
        </Grid>
          }
        {/* Project */}
        { runReportConfig.loadFilterData.get() &&  staticFilterData?.projectPlan && staticFilterData.projectPlan.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            multiple
            id="trailBalanceReportProject"
            options={staticFilterData && staticFilterData.projectPlan||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Project"
                placeholder={selectproject === false?"Select project":''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'projectPlan');
              if(newValue.length>0){setSelectProject(true)}
              else{
                setSelectProject(false)
              }
            }}
          />
        </Grid>
          }
         {/* From Date */}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month','day']}
              open={startDateCalendar}
              onOpen={() => setStartDateCalendar(true)}
              onClose={() => setStartDateCalendar(false)}
              label="From Date"
              value={startDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setStartDate(newDate);
                  let val = newDate+" 00:00:00";
                  handleStaticFilters(val,'startDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                  fullWidth
                    className='full-width'
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setStartDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
         {/* To Date */}
         <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month','day']}
              open={endDateCalendar}
              onOpen={() => setEndDateCalendar(true)}
              onClose={() => setEndDateCalendar(false)}
              label="To Date"
              value={endDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setEndDate(newDate);
                  let val = newDate+" 23:59:59";
                  handleStaticFilters(val,'endDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                  fullWidth
                    className='full-width'
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setEndDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>

      </Grid>
        {/* fields end */}
      {/* Run */}
      <Grid container style={{ margin: '10px',textAlign:'right',paddingRight:'10%'}} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        {/* Sub Organization */}
        <Grid item xs={12}>
        <ButtonGroup  className="static-report-btngroup" variant="text" aria-label="text button group">
        <Button 
           style={{ margin: '2px',textAlign:'center'}} 
          variant="contained" onClick={() => { runReport()}}>
            Run
          </Button>
        <Button
            style={{ margin: '2px',textAlign:'center'}} 
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onDownloadExcel()}>
            Download Excel
        </Button>
          <Button 
           style={{ margin: '2px',textAlign:'center'}} 
           color="primary"
           variant="contained"
           size="small"
           onClick={() => expandAll()}>
            Expand All
          </Button>
        </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '10px',textAlign:'center'}}  >
        <Grid item xs={12}>
        {runReportConfig.showLoader.get()  && 
              <CircularProgress color="secondary" />
        }
        </Grid>
      </Grid>
      {runReportConfig.loadReport.get()  && 
        <div className="ag-theme-alpine kgm-report-aggrid kgm-report-hwm dashboard-report">
           <AgGridReact 
            ref={gridRef}
            autoGroupColumnDef = {gridReportInfo.autoGroupColumnDef}
            columnDefs={gridReportInfo.columnDefs}
            defaultColDef={gridReportInfo.defaultColDef}
            getDataPath = {gridReportInfo.getDataPath}
            groupIncludeTotalFooter = {gridReportInfo.groupIncludeTotalFooter}
            icons = {gridReportInfo.icons}
            rowData={gridReportInfo.rowData}
            // pagination = {gridReportInfo.pagination}
            // paginationPageSize = {gridReportInfo.paginationPageSize}
            treeData = {gridReportInfo.treeData }
          ></AgGridReact>
        </div>
        }
      </div>
  );
};
