import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class ListReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  reportConfiguration:any;
  reportHeaderObject: any;
  presentation: any;
  contentReport:any;
  isContentReportLoaded:boolean;
  excelDownloaded:boolean;
  constructor(reportContent: any) {
    super();
    this.isDataLoaded = false;
    this.reportData = reportContent;
    this.reportHeaderObject ="";
    this.isContentReportLoaded = false;
    this.excelDownloaded = false;
  }
}

export interface KagamiListReportModelProps {
  listReportModel: ListReportModel;
}

export const useListReportModelState = (state: State<ListReportModel>) => {
  let listReportState = useState(state);
  return {
    ReportConfig() {
      listReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      listReportState.reportData.set(reportContent);
    },
    get isContentReportLoaded(){
      return listReportState.isContentReportLoaded.get()
    },
    setContentReport(isContentReportLoaded:boolean){
      listReportState.isContentReportLoaded.set(isContentReportLoaded);
    },
    get isDataLoaded(){
      return listReportState.isDataLoaded.get()
    },
    setDataLoaded(isDataLoaded:boolean){
      listReportState.isDataLoaded.set(isDataLoaded);
    },
    get isExcelDownloaded() {
      return listReportState.excelDownloaded.get();
    },
    setExcelDownloaded(flag:boolean) {
      listReportState.excelDownloaded.set(flag);
    }

  };
};
