import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { cellRendererCustomComponentsStyle } from "../../style/CellRendererCustomComponents";
import comment from '../../../../../../../../../../../../../assets/images/filetypes/comment.svg';
import { useEffect } from 'react';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';


const useStyles = makeStyles(cellRendererCustomComponentsStyle);

 const KagamiActivityLogCellRenderer =(props: any) => {
  
    const classes = useStyles();
    let activitylogId = props.data.activitylog;
    if(CommonUtils.isNotEmpty(activitylogId)){
      props.activityLogModel.controlValue = activitylogId
    }
    let entityType = props.mainEntityId // using mainEntityId as the entityType i.e.., (props.data.entityType) is different from mainEntityId in one case 
    if (activitylogId){
        return (
            <div className={classes.centerIcon} >
               <IconButton
              size="small"
              onClick = {() => props.handleActivityLogOnClick(activitylogId, entityType,props.activityLogModel)}
              >
            <img
                alt="comment"
                style={{ height: '25px' }}
                src={comment}
                // onClick={() => previewFileForEmbddedGridAttachments(attachmentcontrolValue, popupModel)}
              />
                {/* <CommentOutlinedIcon color='action' fontSize='small'/> */}
              </IconButton>
            </div>
        )
    } else {
        return <></>
    }
}


export default KagamiActivityLogCellRenderer