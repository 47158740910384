import React, { forwardRef, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { cellRendererCustomComponentsStyle } from '../../style/CellRendererCustomComponents';
import PhoneInput from 'kgm-phone-input';
import 'kgm-phone-input/lib/style.css';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { isValidPhoneNumber} from 'react-phone-number-input';
import { setActiveRowNode } from '../../service/GridService';
import { Box, Popper } from '@mui/material';

const useStyles = makeStyles(cellRendererCustomComponentsStyle);

const getCountryCode: any = {
  'Ghana': 'GH',
  'India': 'IN',
  'Guinea': 'GN',
  'Guinea-Bissau': 'GW'
};

export default forwardRef((props: any, ref: any) => {
  setActiveRowNode(props, props.gridModel)
  const anchorEl = useRef(null);
  const classes = useStyles();
  const { rowData } = props;
  const [initValue, setInitValue] = useState({
    countryCode: getCountryCode[rowData['country']] ?? 'IN',
    contactNumber: rowData['contactNumber'] ?? ''
  });
  const debounceOnChange = React.useCallback(CommonUtils._debounceFtn(onChange, 700), []);
  function onChange(value: string, country: any, e: any, formattedValue: any) {
    console.log(`value : ${value}, country : ${country.name}, e : ${e}, formattedVaue : ${formattedValue}`);
    if(CommonUtils.isEmpty(props.gridModel.activeRowNode)){
      props.gridModel.activeRowNode = props.node;
    }
    if (CommonUtils.isNotEmpty(country)) {
      props.gridModel.activeRowNode.data[props.column.colId] = formattedValue
    }
    props.onCellValueChange(
      props.gridModel,
      props.gridModel.activeRowNode,
      props.attributePresentation.onChangeRequired,
      props.column.colId
    );
    setInitValue({
      countryCode: country['countryCode'].toUpperCase(),
      contactNumber: formattedValue
    });
  }

  return (
    <>
      <Box ref={anchorEl} style={{ width: "100%", zIndex: "-1", position: "absolute", top: 0, left: 0 }}>&nbsp;</Box>
      <PhoneInput appendToBody={true} ContainerEle = {Popper}
        country={'in'} anchorEl={anchorEl}
        value={initValue['contactNumber']}
        //   dropdownClass={classes.kgmGridSearchOptions}
        onChange={(value: string, country: string, e: any, formattedValue: any) =>
          debounceOnChange(value, country, e, formattedValue)
        }
        isValid={isValidPhoneNumber(initValue['contactNumber'], initValue['countryCode']) ? true : false}
      />
    </>
  );
});
