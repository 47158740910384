import { State, useState } from "@hookstate/core";

export class MapRolesModel{
    dbReportList:any[];
    dbRoleReportMapping:any;
    isDataLoaded:boolean;
    dbRolesList:any[];
    state:any;
    roleMappedWithReportId:any[];
    allSelectedData:any[];
    setDisableSave:any;
    constructor(){
       this.dbReportList = [];
       this.dbRolesList = [];
       this.isDataLoaded = false;
       this.roleMappedWithReportId=[]
       this.allSelectedData=[];
    
       
    }
}

export interface MapRolesModelProps{
    mapRolesModel:MapRolesModel;
    translations?:any;
}

export const useMapRolesState = (state: State<MapRolesModel>) => {
    let mapRoleState = useState(state);
    return {
        get isDataLoaded(){
            return mapRoleState.isDataLoaded.get();
        },

        setDataLoaded(isDataLoaded:boolean){
            mapRoleState.isDataLoaded.set(isDataLoaded);
        }
    }
}


