import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class ColumnReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
  }
}
export interface ColumnReportModelProps {
  columnReportModel: ColumnReportModel;
}
export const useColumnReportModelState = (state: State<ColumnReportModel>) => {
  let columnReportState = useState(state);
  return {
    reportConfig() {
      columnReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      columnReportState.reportData.set(reportContent);
    },

    get isDataLoaded(){
      return columnReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      columnReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
