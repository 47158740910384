import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { greyColor, whiteColor } from '../../../application/style/KagamiApplicationStyles';

export const KagamiModalStyle = (theme: Theme) =>
  createStyles({
    modalRoot: {
      overflow: 'auto',
      display: 'block'
    },
    paperFullWidth: {},
    topScrollPaper: {
      alignItems: 'flex-start'
    },
    modalPaper: {
      borderRadius: '8px',
      overflowY: 'inherit'
    },
    modalHeader: {
      borderBottom: '1px solid #ccc',
      padding: '10px',
      paddingLeft: '35px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      minHeight: theme.spacing(2.1),
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
      textTransform: 'capitalize'
    },
    modalTitle: {
      margin: '0'
    },
    closeModalBtn: {
      'position': 'absolute',
      'top': '-15px',
      'right': '-15px',
      'width': '30px',
      'height': '30px',
      'padding': '1px',
      'backgroundColor': theme.palette.background.paper,
      'borderRadius': '100%',
      'boxShadow': '0 3px 10px 2px rgb(0 0 0 / 0.2);',
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: '#f05252',
        color: '#fff'
      }
    },
    modalCloseButton: {
      '&, &:hover': {
        color: greyColor[0]
      },
      '&:hover': {
        opacity: '1'
      },
      'cursor': 'pointer',
      'padding': theme.spacing(2),
      'marginTop': theme.spacing(-2),
      'marginRight': theme.spacing(-2),
      'marginLeft': theme.spacing(-2),
      'marginBottom': theme.spacing(-2),
      'backgroundColor': 'transparent',
      'border': theme.spacing(0),
      'WebkitAppearance': 'none',
      'float': 'right',
      'fontSize': theme.spacing(3),
      'fontWeight': theme.typography.h6.fontWeight,
      'lineHeight': 1,
      'textShadow': '0 1px 0 ' + whiteColor,
      'opacity': 0.5
    },
    modalClose: {
      width: theme.spacing(2),
      height: theme.spacing(2)
    },
    modalBody: {
      'paddingRight': theme.spacing(3),
      'paddingBottom': theme.spacing(2),
      'paddingLeft': theme.spacing(3),
      'wordBreak': 'break-all',
      'Width': '100%',
      'textAlign': 'start',
      'float': 'left',
      'color': theme.palette.common.black,
      'background': theme.palette.background.default,
      'fontSize': theme.spacing(2),
      '& .hide-buttons-from-desktop-report-container': {
        display: 'none'
      },
      '& .MuiFormLabel-root ': {
        fontSize: '13px'
      },
      '& .MuiOutlinedInput-root': {
        'backgroundColor': '#f5f8fa',
        '&.Mui-focused fieldset': {
          border: '0.8px solid',
          borderColor: theme.palette.secondary.main
        }
      },
      '& textarea': {
        'backgroundColor': '#f5f8fa',
        'fontFamily': "'Lexend deca',sans-serif",
        'borderColor': 'rgba(0, 0, 0, 0.23)',
        'borderRadius': '4px',
        'padding': '5px 10px',
        'maxWidth': '100%',
        'minWidth': '100%',
        '&:hover': {
          borderColor: theme.palette.secondary.main
        }
      },
      '& textarea:focus': {
        minHeight: ' 65px',
        outlineColor: theme.palette.secondary.main,
        outline: '1px !important',
        borderWidth: '1px !important',
        borderColor: theme.palette.secondary.main
      },
      '& .isPickItem': {
        height: ' 100%',
        maxHeight: 'calc(100vh - 150px)'
      },
      '& iframe': {
        height: '70vh',
        minHeight: '70vh',
        maxHeight: '70vh'
      },

      '& .form_header': {
        display: 'none'
      },
      '& .listHeader_name': {
        display: 'none'
      },
      '& .list_search': {
        marginTop: '0',
        marginBottom: '4px'
      }
    },
    modalFooter: {
      padding: theme.spacing(0),
      textAlign: 'center',
      paddingTop: 0,
      margin: 0,
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[1],
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px'
    },
    modalFooterCenter: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    modalWithoutActions: {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px'
    },
    modalLarge: {
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing(100)
      }
    },
    modalSmall: {
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(37.5),
        margin: 'auto'
      },
      margin: '0 auto'
    },
    modalSmallBody: {
      marginTop: theme.spacing(2.4)
    },
    modalSmallFooterFirstButton: {
      margin: 0,
      paddingLeft: theme.spacing(2.4),
      paddingRight: theme.spacing(2.4),
      width: 'auto'
    },
    modalSmallFooterSecondButton: {
      marginBottom: 0,
      marginLeft: theme.spacing(0.95)
    },
    model_button: {
      margin: theme.spacing(0.5),
      fontSize: 'small'
    },
    model_close_button: {
      margin: theme.spacing(0.5),
      color: theme.palette.primary.dark,
      variant: 'outlined',
      borderColor: theme.palette.primary.dark,
      fontSize: 'small',
      border: '1px solid black'
    },
    model_buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: `${theme.spacing(0.5)} ${theme.spacing(3)}`,
      marginRight: '20px'
    },
    model_icon_error: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    model_button_disabled: {
      backgroundColor: 'grey'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px'
    },
    button: {
      color: 'white',
      border: '1px solid #007ecc',
      backgroundColor: '#007ecc',
      variant: 'contained',
      borderRadius: '5px'
    }
  });
