import { KagamiFormPresentationModel } from '../../form-presenation/model/KagamiFormPresentationModel';
import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';

export class KagamiStepPresentationModel {
  stepFormModels: KagamiFormPresentationModel[];
  activeTabIndex: number;

  constructor(stepFormModels: KagamiFormPresentationModel[]) {
    this.stepFormModels = stepFormModels;
    this.activeTabIndex = 0;
  }
}

export interface KagamiStepPresentationProps {
  stepPresentationModel: KagamiStepPresentationModel;
  // isStepForm?: boolean;
}

export const wrapKagamiStepFormModelState = (state: State<KagamiStepPresentationModel>) => {
  const kagamStepFormState = state;

  return {
    get getActiveTabIndex() {
      return kagamStepFormState.activeTabIndex.get();
    },

    setActiveTabIndex(activeTabIndex: number) {
      kagamStepFormState.activeTabIndex.set(activeTabIndex);
    }
  };
};

export const useKagamiStepForm = (state: State<KagamiStepPresentationModel>) => {
  let stepFormState = wrapKagamiStepFormModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.STEP_STATE, stepFormState);
  return stepFormState;
};
