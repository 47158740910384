import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { KagamiProcessPageModel, ProcessModelProps, useProcessState } from '../model/KagamiProcessModel';
import { KagamiProcessPageStyle } from '../style/KagamiProcessPage';
import { buildBreadcrumbs } from '../builder/breadcrumbs/controller/kagamiBreadcrumbsController';
import { makeInitialProcessContentCall } from '../service/ProcessPageService';

export const buildProcessPageComponent = (processModel: KagamiProcessPageModel) => {
  return <KagamiProcessPage key={Math.random().toString()} kagamiProcessModel={processModel} />;
};
const useStyles = makeStyles(KagamiProcessPageStyle);

const KagamiProcessPage = (props: ProcessModelProps) => {
  const classes = useStyles();
  let processState = useProcessState(createState(props.kagamiProcessModel));
  props.kagamiProcessModel.breadcrumbModel.activeModel = props.kagamiProcessModel.activeModel;
  if(processState.processContent == null) {
    props.kagamiProcessModel.loaderModel.loading = true 
  }


  useEffect(() => {
    if(!props.kagamiProcessModel.isLinkProcess){
      makeInitialProcessContentCall(props.kagamiProcessModel);
    }
    return () => {
      //clean up
    };
  }, []);
  
  return processState.processContent == null ? (
    <></>
  ) : (
    <>
{console.log(props.kagamiProcessModel.breadcrumbModel)}
      { props.kagamiProcessModel.breadcrumbModel.showBreadCrumb &&
      buildBreadcrumbs(props.kagamiProcessModel.breadcrumbModel)
      }
      
      <div className={classes.mainAppRoot}>
      {props.kagamiProcessModel.processContent}
      </div>
    </>
  );
};
