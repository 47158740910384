import { Autocomplete, Chip, IconButton, TextField } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiReportHeaderModelStyle } from '../../../ReportHeader/styles/ReportHeaderStyles';
import { buildtoastNotification } from '../../../../../../../../../ToastNotification/controller/ToastNotificationController';
import { enableAndDisableSaveButton } from '../../controller/PinnedReportsController';
import { PinnedReportsViewModelProps } from '../model/PinnedReportViewModel';
import { translateToLocalistaion } from '../../../../../../../process/service/PresentationService';


export const PinnedReportsView = (props: PinnedReportsViewModelProps) => {
  const classes = makeStyles(KagamiReportHeaderModelStyle)();

  let selectedData: any = props.pinnedReportViewModel.selectedData;
  const [value, SetValue] = React.useState(null);
  const [pinnedReportState, setPinnedReportState] = React.useState(props.pinnedReportViewModel.pinnedReports);

  const handleAddReportIcon = () => {
    SetValue(null);
    if (pinnedReportState.length < 6) {
      setPinnedReportState((prev: any) => {
        if (
          selectedData['label'] !== undefined &&
          prev.find((ele: any) => {
            return ele['name'] === selectedData['label'];
          }) === undefined
        ) {
          props.pinnedReportViewModel.allSelectedData.push({
            roleId: props.pinnedReportViewModel.name,
            reportId: selectedData['reportId'],
            pinned: true,
            opCode: '+-'
          });
          return [...prev, props.pinnedReportViewModel.reportIdMapWithConfig[selectedData['reportId']]];
        } else {
          return [...prev];
        }
      });
    } else {
      // showing success alert
      buildtoastNotification('info', 'Each Role Is Allowed To Pin Only 6 Reports', 0);
    }
    enableAndDisableSaveButton();
  };

  const handleDeleteReportIcon = (reportConfigSummary: any) => {
    let pinnedReport: any = null;
    if (pinnedReportState.length) {
      setPinnedReportState((prev: any) => {
        let allSelectedConfigData: any[] = prev.filter((rcs: any) => {
          if (rcs.name === reportConfigSummary['name']) {
            pinnedReport = {
              roleId: props.pinnedReportViewModel.name,
              reportId: rcs.id,
              pinned: false,
              opCode: '+-'
            };
          } else {
            return rcs;
          }
        });
        if (pinnedReport !== null) {
          props.pinnedReportViewModel.allSelectedData.push(pinnedReport);
        }
        return allSelectedConfigData;
      });
      enableAndDisableSaveButton();  
    }
  };

  return (
    <>
      <tr style={{ height: '45px' }}>
        <td>{translateToLocalistaion(props.translations,props.pinnedReportViewModel.name)}</td>
        <td>
          <div className={classes.searchBox_container}>
            {props.pinnedReportViewModel.options.length > 0 ? (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Autocomplete
                  size="small"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  options={props.pinnedReportViewModel.options}
                  getOptionLabel={(options: any) => options.label || ''}
                  sx={{ width: 300 }}
                  onChange={(event, child) => {
                    selectedData = child;
                  }}
                  value={translateToLocalistaion(props.translations,value)}
                  renderInput={(params: any) => <TextField {...params} />}
                />

                <IconButton color="success" onClick={handleAddReportIcon} size="small">
                  <AddIcon fontSize="small" />
                </IconButton>
              </div>
            ) : (
              <></>
            )}
          </div>
        </td>
        <td>
          <>
            {pinnedReportState.map((reportConfigSummary: any) => {
              return (
                <Chip
                  deleteIcon={<CloseIcon className={classes.removeIcon} />}
                  size="small"
                  sx={{ borderRadius: '1px', border: '1px solid #6ebe44', marginLeft: '10px' }}
                  variant={'outlined'}
                  key={reportConfigSummary['id']}
                  label={translateToLocalistaion(props.translations,reportConfigSummary['name'])}
                  onDelete={() => {
                    handleDeleteReportIcon(reportConfigSummary);
                  }}
                />
              );
            })}
          </>
        </td>
      </tr>
    </>
  );
};
