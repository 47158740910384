import { grey, orange, green, blue } from '@mui/material/colors';
import { PaletteOptions } from '@mui/material/styles';


declare module '@mui/material/styles' {
  // interface Theme {
  //   status: {
  //     danger: React.CSSProperties['color'];
  //   };
  // }

  interface Palette {
    textboxBorderColor: Palette['primary'];
    labelColor: Palette['primary'];
    controlValueColor:Palette['primary'];
    defaultBlue: Palette['primary']
  }
  interface PaletteOptions {
    textboxBorderColor: PaletteOptions['primary'];
    labelColor: PaletteOptions['primary'];
    controlValueColor:Palette['primary'];
    defaultBlue: Palette['primary']

  }

  // interface PaletteColor {
  //   darker?: string;
  // }
  // interface SimplePaletteColorOptions {
  //   darker?: string;
  // }
  export interface TypeBackground {
    defaultBlue?: string;
  }
}


export default function getColorPalette(): any {
  return {
    primary: {
      light: '#99d8ff',  //  light blue for nav unopened folders
      // main: '#2f404f',   //  darker blue fror nav 
      main:'#11344d',
      dark: '#04263d'    //  blue for headings and comments
    },
    secondary: {
      light: grey.A200,
      // main: '#05263d',  //  embed add icon color
      // dark: '#05263d'   //  blue button add icon color
      main:'#006e7f', //headings color
      dark:'#1f8a70'
    },
    error: {
      light: '#ef5350',
      // dark: '#c62828',
      dark: 'rgb(198 40 40 / 25%)',
      main: '#f05252'
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700]
    },
    info: {  
      
      light: grey.A200, //  grey borders
      main: '#33475b',  //  content font color
      dark: '#808080'   //  grey notification text color
    },
    success: {
      light: '#6bc03f',
      main: '#29947f',
      dark: '#29947f'
    },
    drawer: {
      light: green[300],
      main: green[500],
      dark: green[700]
    },
    background: {
      paper: '#fff',
      default: '#fff',
      
    },

    textboxBorderColor : {
      main: '#0066B3',

    },
    labelColor: {
      main:'#2D9290'
    }, 
    controlValueColor:{
      main: '#000'
    }
     
  };
}


// declare module "@mui/material/styles/createPalette" {
//   export interface PaletteOptions {
//     chip: {
//       color: string;
//       expandIcon: {
//         background: string;
//         color: string;
//       };
//     };
//   }
// }
