import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { applicationURL } from '../../../../../../../../../../../service/URLService';
import moment from 'moment';


export const computeDisplayString = function (option:any, displayAttributes:any, mode:any) {
    if(option){
        let result = '';
         forEach(displayAttributes, function (attr, index) {
            let value ="";
            if (index) {
                result = result + ' - ';
            }
            if(option[attr.name]){
                value = option[attr.name];
            }else{
                 value =  get(option,attr.name);
            }
            if(attr.type && attr.type === 'date'){
                value = formatDate(value);
            } else if(attr.type && attr.type === 'dateTime'){
                value = formatDateTime(value);
            } else if(attr.type && attr.type === 'time'){
                value = formatTime(value);
            } 
            else if(attr.type && attr.type === 'file'){
                value = '';
            } 
            if(typeof(value)=='number' && !value){
                result = result + value;
            }else{
                result = result + (value || '');
            }
        });
        result=trimChar(result.trim(),'-');
        return result;
    } else if(mode){
        return '';
    } else {
        return '-- SELECT --';
    }
};

const trimChar = (string:any, charToRemove:any) => {
    while(string.charAt(0)==charToRemove) {
        string = string.substring(1);
    }
    while(string.charAt(string.length-1)==charToRemove) {
        string = string.substring(0,string.length-1);
    }
    return string;
}

const formatDate = (dt:any) => {
    let dtObj = moment(dt);
    return dtObj.format('DD-MM-YYYY');
};

const formatDateTime = function (dt:any,convertToDec?:any) {
    let dtObj = moment(dt);
    if(convertToDec){
        let str = dtObj.format("DD-MM-YYYY");
        let decimalStr :any  = dtObj.format("HH:mm:ss.SSS");
        decimalStr = Math.round(Number(moment.duration(decimalStr).asHours())* 100) / 100;
        return str+" "+decimalStr;
    }
    return dtObj.format('DD-MM-YYYY hh:mm A');
};

const formatTime = (dt:any,convertToDec?:any) => {
    let dtObj = moment(dt);
    if(convertToDec){
        let decimalStr:any=dtObj.format("HH:mm:ss.SSS");
        decimalStr=Math.round(Number(moment.duration(decimalStr).asHours())* 100) / 100;
        return decimalStr;
    }
    return dtObj.format('LT');
};

export const formatFile = function (val:any) {
    let img= 'assets/images/user.png';
    if (val) {
        val=val.split(":")[0];
        img= applicationURL + 'dms/viewDocument?docId=' + val;
    }
    return img;
};
