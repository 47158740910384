import { NodeModel } from "../model/KagamiHierarchyViewNodeModel";
import { buildNodeComponent } from "../ui/KagamiHierarchyViewNode";

export const buildNode = (nodeModel:NodeModel) => {
    return buildNodeComponent(nodeModel);
}

export const onCollapse = (node:NodeModel) => {
    node.state.setCollapsed(!node.collapsed);
  }
