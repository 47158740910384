import { Report } from '@mui/icons-material';
import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { executeProcess } from '../../navigator/builder/Menu/controller/KagamiMenuController';
import { buildDashboard } from '../builder/dashboard/controller/KagamiDashboardController';
import { DashboardType, KagamiDashboardModel } from '../builder/dashboard/model/KagamiDashboardModel';
import { buildReportLandingPageController } from '../builder/reports/landingPage/controller/ReportsLandingPageController';
import { ReportLandingPageModel } from '../builder/reports/landingPage/model/ReportsLandingPageModel';
import { KagamiBodyModel } from '../model/KagamiBodyModel';
import { buildBodyComponent } from '../ui/KagamiBody';
import { getAllFavouriteReportsData } from '../builder/dashboard/service/DashboardService';

export function buildBody(bodyModel: KagamiBodyModel): JSX.Element {
  return buildBodyComponent(bodyModel);
}

export function renderBody(bodyModel: KagamiBodyModel) {
  if (bodyModel.processName == null) {
    // if(bodyModel.dashboardModel.dashboardType === DashboardType.REPORTS) {
    getAllFavouriteReportsData(bodyModel.dashboardModel);
    // }
    renderDashboardPage(bodyModel.dashboardModel);
  } else  {
    executeProcess(bodyModel.processName);
   
  }
}
export function renderDashboardPage(dashboardModel: KagamiDashboardModel) {
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(buildDashboard(dashboardModel));
}

export function renderProcessPage(content: JSX.Element) {
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(content);
}

export function renderReportPage() {
  let reportLandingPageModel : ReportLandingPageModel = new ReportLandingPageModel()
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
    buildReportLandingPageController(reportLandingPageModel)
  );
  KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
  KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);
}

export function renderReport(content: JSX.Element) {
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(content);
}


export function renderDataMigrationPage() {
  let reportLandingPageModel : ReportLandingPageModel = new ReportLandingPageModel()
  KagamiStateManager.getStateToHolder(SystemConstants.BODY_STATE).loadBodyContent(
    buildReportLandingPageController(reportLandingPageModel)
  );
  KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
  KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);
}