

import { getFormModel } from '../../../../../controller/KagamiFormController';
import { getEmbeddedSectionContent } from '../../embedded-accordion-presentation/builder/EmbeddedFormBuilder'; 


export const EmbedTabsContent = (props:any) => {
    let activeTabIndex = props.activeIndex;
    let activeEmbedModel = props.embedFormModels[activeTabIndex]
    return <div>{getEmbeddedSectionContent(activeEmbedModel, activeEmbedModel.readonly )}</div>

}

export const EmbedStepsContent = (props: any) => {
    let activeTabIndex = props.activeTabIndex;
    let activeEmbedModel = props.visibleStepForms[activeTabIndex];
    getFormModel().formControls[activeEmbedModel.formEntityId] = activeEmbedModel;
    return <div>{getEmbeddedSectionContent(activeEmbedModel, activeEmbedModel.readonly)}</div>
}