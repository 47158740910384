
import { LedgerDetailsModalModel } from "../model/ledgerDetailsModalModel";
import { buildLedgerDetailsModalComponent } from "../ui/ledgerDetailsModal";
import _ from 'lodash';

let ledgerDetailsModal: LedgerDetailsModalModel;

export const buildLedgerDetailsModal = (ledgerDetailsModalModel: LedgerDetailsModalModel) => {
    ledgerDetailsModal = ledgerDetailsModalModel;
    return buildLedgerDetailsModalComponent(ledgerDetailsModalModel)
}

export const handleOnCancel = () => {
    ledgerDetailsModal.state.setOpen(false)
    if(ledgerDetailsModal.handleClose){
        ledgerDetailsModal.handleClose()
    }
}

export const printLedgerDetails = (inputData:any) => {
    let creditTotal:any = 0;
    let debitTotal:any = 0;
    let ledgerDetails = inputData.ledgerDetailsModel;
    if (ledgerDetails && ledgerDetails.nestedFinanceRecord) {
      _.forEach(ledgerDetails.nestedFinanceRecord, function (data) {
        if (data['credit']) {
          creditTotal += parseFloat(data['credit']);
        }
        if (data['debit']) {
          debitTotal += parseFloat(data['debit']);
        }
      });
      if (creditTotal) {
        creditTotal = creditTotal.toFixed(2);
      }
      if (debitTotal) {
        debitTotal = debitTotal.toFixed(2);
      }
    }
    let docNumber = '';
    let docDate = '';
    let narration:any = '';
    let perticularsData:any = [
      [
        { text: 'Perticulars', style: 'tableHeader' },
        { text: 'Debit', style: 'tableHeader', alignment: 'right' },
        { text: 'Credit', style: 'tableHeader', alignment: 'right' }
      ]
    ];
    if (ledgerDetails) {
      docNumber = ledgerDetails.headerDeatils ? ledgerDetails.headerDeatils['Document No'] : '';
      docDate = ledgerDetails.headerDeatils ? ledgerDetails.headerDeatils['Date'] : '';
      narration = ledgerDetails.otherDeatils ? ledgerDetails.otherDeatils.Narration : '';

      if (ledgerDetails.nestedFinanceRecord) {
        _.forEach(ledgerDetails.nestedFinanceRecord, function (data) {
          perticularsData.push([
            { text: data['particulars'], color: '#212020' },
            { text: data['debit'], color: '#212020', alignment: 'right' },
            { text: data['credit'], color: '#212020', alignment: 'right' }
          ]);
        });
        perticularsData.push([
          { text: 'Total  : ', color: '#212020', bold: true, alignment: 'right' },
          { text: debitTotal, color: '#212020', bold: true, alignment: 'right' },
          { text: creditTotal, color: '#212020', bold: true, alignment: 'right' }
        ]);
      }
    }
    let subHeader = [
      ['Document No :', docNumber],
      ['Date :', docDate]
    ];
    let narrationData = [['Narration :', narration ||'']];

    let docDefinition:any = {
      content: [
        { text: 'Account Details', style: 'header', alignment: 'center' },
        {
          style: 'table',
          table: {
            widths: [60, '*'],
            body: subHeader
          },
          layout: 'noBorders'
        },
        {
          style: 'perticulartable',
          table: {
            widths: ['*', '*','*'],
            body: perticularsData
          }
        },
        {
          style: 'table',
          table: {
            widths: [50, '*'],
            body: narrationData
          },
          layout: 'noBorders'
        }
      ],
      styles: {
        header: {
          fontSize: 11,
          bold: true,
          margin: [0, 0, 0, 10]
        },

        table: {
          margin: [0, 5, 0, 5],
          fontSize: 9
        },
        perticulartable: {
          margin: [0, 5, 0, 10],
          fontSize: 9
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black'
        }
      }
    };
    import('pdfmake/build/pdfmake').then(({default : pdfMake}) => {
      import('pdfmake/build/vfs_fonts').then(({default : pdfFonts}) => {
        (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(docDefinition).download('AccountDetails.pdf');
      })
    })

}