import moment from "moment";
import { SystemConstants } from "../constants/SystemConstants";
import { CommonUtils } from "./CommonUtils";


export function parseDateToUTCString(value: any, type: any): any {
  let dateValueForControl: string | null;
  if (value === null) {
    dateValueForControl = null
    return dateValueForControl
  } else {
    let formatingseconds = moment(value).set({second : 0,millisecond : 0})
    let dateForControlValue = formatingseconds.format(SystemConstants.YYYY_MM_DDTHH_mm_ss_SSSZ);
    let splitDateValueControlValue = dateForControlValue.split('.')[1].slice(0, 3);
    let splitWithPlusSymbol = dateForControlValue.split('+')[1]
    let replaceDateValueControlValue: any = splitDateValueControlValue.replace(splitDateValueControlValue, '.000')
    dateValueForControl = dateForControlValue.split('.')[0].concat(replaceDateValueControlValue).concat('+' + splitWithPlusSymbol);
    return dateValueForControl;
  }
  // if (type === "time") {
  //   // let time = value.split(":");
  //   // let localTime = moment()
  //   //   .startOf("day")
  //   //   .add(time[0], "hours") 
  //   //   .add(time[1], "minutes")
  //     dateValueForControl = moment(value).format(SystemConstants.YYYY_MM_DDTHH_mm_ss_SSSZ);
  //   return dateValueForControl
  // } else {
  //   if(value){
  //     dateValueForControl = moment(value).format(SystemConstants.YYYY_MM_DDTHH_mm_ss_SSSZ);
  //     return dateValueForControl ;
  //   } else if(value === null) {
  //     dateValueForControl = null
  //     return dateValueForControl 
  //   }

  // }
}
export function isTypeDate(type: string) {
  return (
    type &&
    type != null &&
    (type == "date" || type == "dateTime" || type == "time")
  );
}

export function getDateStringAsLocalString(dateValue: any, type: string) {
  if (CommonUtils.isEmpty(dateValue) || CommonUtils.isRemoved(dateValue)) {
    return null;
  }
  let localDateString;
  switch (type) {
    case "date":
      // localDateString = moment(dateValue).format(SystemConstants.dd_MM_yyyy);
      localDateString = moment(dateValue).format(SystemConstants.DD_MM_YYYY);
      break;
    case "time":
      localDateString = moment(dateValue).format(SystemConstants.h_mm_a);
      break;
    case "dateTime":
      localDateString = moment(dateValue).format(SystemConstants.dd_MM_yyyy_h_mm_a);
      break;
    case 'formattedDate':
      localDateString = moment(dateValue).format(SystemConstants.DD_MMM_YYYY_h_mm_a);
      break;  
    default:
      return null;
  }
  return localDateString;
}

export function getDateStringToPopulateInForm(dateValue: any, type: string) {
  if (CommonUtils.isEmpty(dateValue) || CommonUtils.isRemoved(dateValue)) {
    return null;
  }
  let localDateString;
  switch (type) {
    case "date":
      localDateString = dateValue
      break;
    case "time":
      localDateString = dateValue
      break;
    case "dateTime":
      localDateString = dateValue
      break;

    default:
      return null;
  }
  return localDateString;
}

export function getEnableDates(type: string, attributePresentation: any, valueKey: string, validations: any) {
  if (validations) {
    return getEnableDatesBasedonValidation(validations, valueKey, type);
  }
  let uiSettings = attributePresentation["uiSettings"];
  if (!uiSettings) { return null }
  let dateToValue = uiSettings[valueKey];
  if (!dateToValue) {
    return null
  }
  let enableDates
  switch (type) {
    case "date":
      if (dateToValue.includes("-")) {
        let date = dateToValue.split("-");
        enableDates = moment().subtract(date[1], "days").format(SystemConstants.yyyy_MM_DD);
      } else {
        let date = dateToValue.split("+");
        enableDates = moment().add(date[1], "days").format(SystemConstants.yyyy_MM_DD);
      }
      break;
    case "time":
      // localDateString = moment(dateValue).format(SystemConstants.HH_mm);
      break;
    case "dateTime":
      if (dateToValue.includes("-")) {
        let date = dateToValue.split("-");
        enableDates = moment().subtract(date[1], "days").format(SystemConstants.yyyy_MM_DDTHH_mm);
      } else {
        let date = dateToValue.split("+");
        enableDates = moment().add(date[1], "days").format(SystemConstants.yyyy_MM_DDTHH_mm);
      }
      break;

    default:
      return null;
  }
  return enableDates;
}

export function getEnableDatesBasedonValidation(validations: any, valueKey: string, type: string) {
  if (validations.past && valueKey === 'dateToValue') {
    if (type === 'date') {
      return moment().subtract(1, "days").format(SystemConstants.yyyy_MM_DD);
    } else if (type === 'dateTime') {
      return moment().subtract(1, "days").format(SystemConstants.yyyy_MM_DDTHH_mm);
    }
  } else if (validations.future && valueKey === 'dateFromValue') {
    if (type === 'date') {
      return moment().add(1, "days").format(SystemConstants.yyyy_MM_DD);
    } else if (type === 'dateTime') {
      return moment().add(1, "days").format(SystemConstants.yyyy_MM_DDTHH_mm);
    }
  }
}

export const convertIntoRelativeTime = (dateValue: any) => {


const momentObj = moment(dateValue);
  const today = moment().startOf('day');
  const oneDaysAgo = moment().subtract(1, 'days');
  const eightHoursAgo = moment().subtract(8, 'hours');
  const startOfWeek = moment().startOf('week');
let toReturn;

  if (momentObj.isAfter(eightHoursAgo)) {
    const relativeTimeString = momentObj.fromNow();
   
    return relativeTimeString
  } else if (momentObj.isSame(today, 'd')) {
    const timeString = momentObj.format('h:mm A');
    toReturn  = `Today at ${timeString}`
    return toReturn
  } else if (momentObj.isSame(oneDaysAgo, 'd')) {
    const timeString = momentObj.format('h:mm A');
    toReturn = `Yesterday at ${timeString}`
    return toReturn
  } else if (momentObj.isAfter(startOfWeek)) {
    const lastWeekday = momentObj.format('dddd');
    const timeString = momentObj.format('h:mm A');
    const displayString = `Last ${lastWeekday} at ${timeString}`;
    return  displayString 
  } else {
    const dateTimeString = momentObj.format('DD/MM/yyyy [at] h:mm A');
    return  dateTimeString
  }

}

export const convertDecimalValueToMinutes = (mins: any) => {
  // let seconds:any;
  // var setMinutesData:any;
  // let setMinutes:any;
  // let  sign = minutes < 0 ? "-" : "";
  // let  minutees = Math.floor(Math.abs(minutes.toString().split('.')[0]));
  // let  CheckingMinToseconds = minutes.toString().split('.')[1];
  // let decimalValue = 1.666666666666667;
  // if(CheckingMinToseconds.length > 1){
  //   setMinutes = (CheckingMinToseconds * 60 / 100 ).toString();
  //   setMinutesData = Math.ceil(setMinutes);
  //   seconds = setMinutesData;
  //   // if(setMinutesData.includes('.')){
  //   //   if(setMinutesData.split('.')[1] > '4'){
  //   //     seconds = Number(setMinutesData).toFixed()
  //   //   }else{
  //   //     seconds  = Number((setMinutesData) + 1).toFixed()
  //   //   }
  //   // }else{
  //   //   seconds = Number(setMinutesData) + 1
  //   // }
  // }else{
  //   setMinutes =  CheckingMinToseconds + '0' ;
  //   setMinutesData = (Math.abs(setMinutes * 60 / 100 )).toString();
  //   seconds = Math.ceil(setMinutesData);
  //   // if(setMinutesData.includes('.')){
  //   //   if(setMinutesData.split('.')[1] > '4'){
  //   //     seconds = Number(setMinutesData).toFixed()
  //   //   }else{
  //   //     seconds  = Number((setMinutesData) + 1).toFixed()
  //   //   }
  //   // }else{
  //   //   seconds = Number(setMinutesData) + 1
  //   // }
  // }
  // return sign + (minutees < 10 ? "0" : "") + minutees + ":" + ((seconds < 10 ? "0" : "") + seconds);
  const sign = mins < 0 ? "-" : "";
  const decimalHours = mins;
  const date = new Date(0, 0);
  date.setMinutes(+Math.ceil(decimalHours * 60));
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return sign + (hours < 10 ? "0" : "") + hours + ":" + ((minutes < 10 ? "0" : "") + (minutes))
}

