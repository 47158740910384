import _ from 'lodash';
import { SystemConstants } from '../../../../../../../../../../../../../constants/SystemConstants';
import { APIService } from '../../../../../../../../../../../../../service/ApiService';
import { getAllRolesURL, getUserListURL } from '../../../../../../../../../../../../../service/URLService';
import { InitialFormData, SchedulerFormModel, TimeZone } from '../model/SchedulerFormModel';

async function getUserList() {
  return await APIService.getData(SystemConstants.GET, getUserListURL(), '');
}

async function getAllRoles() {
  return await APIService.getData(SystemConstants.GET, getAllRolesURL(), '');
}

export async function getUserAndRolesAtOnce() {
  return await Promise.all([getUserList(), getAllRoles()]);
}

export const makeSchedulerForm = (schedulerFormModel: SchedulerFormModel) => {
  let defaultFormData = defaultSchedulerForm();
  let initialFormData = mergeSchedulerFormData(defaultFormData, schedulerFormModel);
  return initialFormData;
};

export const mergeSchedulerFormData = (defaultFormData: any, schedulerFormModel: SchedulerFormModel) => {
  defaultFormData.reportList = schedulerFormModel.reportList;
  defaultFormData.userList = schedulerFormModel.userList;
  defaultFormData.allRoles = schedulerFormModel.allROles;
  return defaultFormData;
};

export const defaultSchedulerForm = () => {
  return {
    timeTriggerTypes: [TimeZone.Hourly, TimeZone.Daily, TimeZone.Weekly, TimeZone.Monthly, TimeZone.Yearly],
    daysOfWeek: [
      { id: 0, value: 'Sun', label: 'Sunday' },
      { id: 1, value: 'Mon', label: 'Monday' },
      { id: 2, value: 'Tue', label: 'Tuesday' },
      { id: 3, value: 'Wed', label: 'Wednesday' },
      { id: 4, value: 'Thu', label: 'Thursday' },
      { id: 5, value: 'Fri', label: 'Friday' },
      { id: 6, value: 'Sat', label: 'Saturday' }
    ],
    monthsOfYear: [
      { id: 1, label: 'January' },
      { id: 2, label: 'February' },
      { id: 3, label: 'March' },
      { id: 4, label: 'April' },
      { id: 5, label: 'May' },
      { id: 6, label: 'June' },
      { id: 7, label: 'July' },
      { id: 8, label: 'August' },
      { id: 9, label: 'September' },
      { id: 10, label: 'October' },
      { id: 11, label: 'November' },
      { id: 12, label: 'December' }
    ],
    orderOptions: [
      { id: 1, label: 'First' },
      { id: 2, label: 'Second' },
      { id: 3, label: 'Third' },
      { id: 4, label: 'Fourth' },
      { id: 'last', label: 'Last' }
    ],
    fileType:[{id:1, label: 'EXCEL'},{id:2, label: 'PDF'}],
    allRoles: [],
    userList: []
  };
};

export function updateExpression(timeTriggerTypes: InitialFormData) {
  var dayOfWeek;
  let type:string = timeTriggerTypes.metaData.type;
  timeTriggerTypes.schedule.param =
    '0 ' + timeTriggerTypes.metaData.startTime.minutes + ' ' + timeTriggerTypes.metaData.startTime.hours;

  switch (type) {
    case 'hourly':
      timeTriggerTypes.schedule.param += '/' + _.get(timeTriggerTypes.metaData.repeatEvery, 'hours') + ' * * ?';
      break;
    case 'daily':
      if (timeTriggerTypes.metaData.repeatEvery.type === 'day') {
        timeTriggerTypes.schedule.param += ' 1/' + timeTriggerTypes.metaData.repeatEvery.days + ' * *';
      } else if (timeTriggerTypes.metaData.repeatEvery.type === 'week') {
        timeTriggerTypes.schedule.param += ' ? * MON-FRI';
      }
      break;
    case 'weekly':
      var param = '';
      _.forEach(timeTriggerTypes.metaData.repeatEvery.weekDays, function (value, key) {
        if (value) {
          param = param + key + ',';
        }
      });
      param = param.substring(0, param.length - 1);
      timeTriggerTypes.schedule.param += ' ? * ' + param;

      break;

    case 'monthly':
      if (timeTriggerTypes.metaData.repeatEvery.type === 'dayOfMonth') {
        timeTriggerTypes.schedule.param +=
          ' ' + timeTriggerTypes.metaData.repeatEvery.day + ' 1/' + timeTriggerTypes.metaData.repeatEvery.months + ' ?';
      } else if (timeTriggerTypes.metaData.repeatEvery.months === 'dayOfWeekOfMonth') {
        if (timeTriggerTypes.metaData.repeatEvery.order === 'last') {
          dayOfWeek = timeTriggerTypes.metaData.repeatEvery.dayOfWeek + 'L';
        } else {
          dayOfWeek =
            _.find(timeTriggerTypes.daysOfWeek, {
              id: timeTriggerTypes.metaData.repeatEvery.dayOfWeek
            }).value.toUpperCase() +
            '#' +
            timeTriggerTypes.metaData.repeatEvery.order;
        }
        timeTriggerTypes.schedule.param += ' ? 1/' + timeTriggerTypes.metaData.repeatEvery.months + ' ' + dayOfWeek;
      }

      break;

    case 'yearly':
      if (timeTriggerTypes.metaData.repeatEvery.type === 'dayOfMonthEveryYear') {
        timeTriggerTypes.schedule.param +=
          ' ' + timeTriggerTypes.metaData.repeatEvery.day + ' ' + timeTriggerTypes.metaData.repeatEvery.month + ' ? *';
      } else if (timeTriggerTypes.metaData.repeatEvery.type === 'dayOfWeekOfMonthEveryYear') {
        if (timeTriggerTypes.metaData.repeatEvery.order === 'last') {
          dayOfWeek = timeTriggerTypes.metaData.repeatEvery.dayOfWeek + 'L';
        } else {
          dayOfWeek =
            _.find(timeTriggerTypes.daysOfWeek, {
              id: timeTriggerTypes.metaData.repeatEvery.dayOfWeek
            }).value.toUpperCase() +
            '#' +
            timeTriggerTypes.metaData.repeatEvery.order;
        }

        timeTriggerTypes.schedule.param +=
          ' ? ' + timeTriggerTypes.metaData.repeatEvery.yearMonth + ' ' + dayOfWeek + ' *';
      }
      break;
  }
}

export const getExpression = function (timeTriggerTypes:any) {
  var expression = timeTriggerTypes.schedule.param;
  if (timeTriggerTypes.metaData.type === 'weekly' && timeTriggerTypes.schedule.param) {
      _.forEach(timeTriggerTypes.daysOfWeek, function (day) {
          expression = expression.replace(day.value, day.id);
      });
  }
  return expression;
};

export const startTimeChanged = function (startTime:any,timeTriggerTypes:any) {
  timeTriggerTypes.metaData.startTime.hours = startTime.getHours();
  timeTriggerTypes.metaData.startTime.minutes = startTime.getMinutes();
};
