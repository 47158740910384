import { State, useState } from '@hookstate/core';
import { SystemConstants } from '../../../application/constants/SystemConstants';
import { VisibleConstants } from '../../../application/constants/VisibeConstants';
import { KagamiStateManager } from '../../../state/KagamiStateManager';

export class KagamiLoaderModel {
  loading: boolean;
  timeout: number;
  loadingMsg: string;
  state: any;

  constructor() {
    this.loading = false;
    this.timeout = 500;
    this.loadingMsg = VisibleConstants.LOADING;
  }
}

export interface LoaderProps {
  loaderModel: KagamiLoaderModel;
}

export const wrapKagamiLoaderState = (state: State<KagamiLoaderModel>) => {
  let favoriteState = state;

  return {
    get loadingStatus() {
      return favoriteState.loading.get();
    },

    setLoaded(loaded: boolean) {
      favoriteState.loading.set(loaded);
    }
  };
};
export const useKagamiLoaderState = (state: State<KagamiLoaderModel>) => {
  const kagamiAppLoaderState = wrapKagamiLoaderState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.APP_LOADER, kagamiAppLoaderState);
  return kagamiAppLoaderState;
};
