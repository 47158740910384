import { IToolPanelParams } from "ag-grid-community";

export default (props: IToolPanelParams) => {
    console.log('props :: ',props);
    const expandAll = () => {
        props.api!.expandAll();
    }
    const collapseAll = () => {
        props.api!.collapseAll();
    }
    const resetColumn = () => {
        // props.api!.reset()
        props.columnApi!.resetColumnState();
    }

    return <div className="custom-ag-menu-list custom-ag-focus-managed">
        <div className="custom-ag-menu-option" onClick={resetColumn}>Reset Columns</div>
        <div className="custom-ag-menu-option" onClick={expandAll}>Expand All Row Groups</div>
        <div className="custom-ag-menu-option" onClick={collapseAll}>Collapse All Row Groups</div>
    </div>
}