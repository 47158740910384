import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class StackedColumnReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
  }
}
export interface StackedColumnReportModelProps {
  stackedColumnReportModel: StackedColumnReportModel;
}
export const useStackedColumnReportModelState = (state: State<StackedColumnReportModel>) => {
  let stackedColumnReportState = useState(state);
  return {
    reportConfig() {
      stackedColumnReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
        stackedColumnReportState.reportData.set(reportContent);
    },
    get isDataLoaded(){
      return stackedColumnReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      stackedColumnReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
