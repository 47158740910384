import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { buildPopover } from '../../../../../../../../../../../../../../components/popover/controller/KagamiPopoverController';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { MenuList, MenuItem, Typography, createStyles, Theme } from '@mui/material';
import { ContextType, KagamiTriggerModel } from '../../../../../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { KagamiModalModel } from '../../../../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { enableTrigger } from '../../../../../../Triggers/service/KagamiTriggersService';
import EditIcon from '@mui/icons-material/Edit';
import { buildModal } from '../../../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { buildActivityControlKanban, buildActivityLogModal, getKagamiActivityLogModel } from '../../../../../../../../../../../../../../components/activityLog/controller/KagamiActivityLogModalController';
import { KagamiActivityLogModel } from '../../../../../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel';
import {  getActiveModel, onStartCall } from '../../../../../../../controller/KagamiProcessController';
import { KagamiProcessRequestModel } from '../../../../../../../model/KagamiProcessRequestModel';
import { buildFormPage, showServerError } from '../../../../../../form/controller/KagamiFormController';
import { enableTriggerByPolicy } from '../../../../../../../service/PolicyExecutionService';
import moment from 'moment';
import { getValueForPickAttribute } from '../../../../../service/kagamiListService';
import { extractDropdownOption, getAttributeName, translateToLocalistaion } from '../../../../../../../service/PresentationService';
import { makeStyles } from '@mui/styles';
import { KagamiKanbanSectionStyle } from '../styles/KanbanStyle';
import { KagamiKanbanSectionsViewModel } from '../../../model/KagamiKanbanSectionsModel';
import { KagamiFormModel } from '../../../../../../form/model/KagamiFormModel';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { executeProcess } from '../../../../../../../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { KagamiPopoverModel } from '../../../../../../../../../../../../../../components/popover/model/KagamiPopoverModel';


export const KagamiKanbanViewStyle:any = (theme: Theme) => {
  createStyles({
    cardItem: {
      background: 'red', 
      '& .MuiButtonBase' :{
        color : theme.palette.secondary.main ,
      },                                                                                                                                                   
      '&:hover $editIcon' : {
        display:'block',
      }
    },
    moreoververticon :{
      color : theme.palette.secondary.main ,
      '& .MuiButtonBase' :{
        color : theme.palette.secondary.main ,
      }
    },
    editIcon: {
      display:'none'
    },
    statusLength : {border: '1px solid #fff', borderRadius: '50%',fontSize:'10px', height:15, width:15, display:'flex', alignItems: 'center', justifyContent: 'center' },
    kanbanColumn: {
      width:'200px',
      borderTopLeftRadius:"5px",
      borderTopRightRadius:"5px",
      minHeight: 500,
      maxHeight:'100%',
      overflowY:'auto',
      overflowX:'hidden',
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        height: theme.spacing(1)
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.4)'
      },
      '&::-webkit-scrollbar-track': {
        background: 'hsla(0,0,0,.2)'
      }
    }
  })}

export const bulidKanbanSectionView = (columns:any,popupModel : KagamiModalModel,kagamiKanbannViewModel : KagamiKanbanSectionsViewModel) => {
    // let activityLogModel: KagamiActivityLogModel = new KagamiActivityLogModel();
    return (
        <> 
        <KagamiKanbann
        // key={'kanban-columns'}
        key={Math.random().toString()}
        columns={columns}
        popupModel={popupModel}
        kanbannViewModel={kagamiKanbannViewModel}
        />
      {/* {buildActivityLogModal(activityLogModel)} */}
        </>
    );
  };

const onDragEnd = (result:any,columns:any, setColumns:any,handleDragSuccess : any,popupModel : KagamiModalModel) => {
    console.log('result' , result)
    if(!result.destination) return;
    const { source, destination} = result

    function onKanbannClose(){
      popupModel.state.setOpen(false);
    }

    if(source.droppableId !== destination.droppableId){
        
        const sourceColumn = columns.find((column:any) => source.droppableId === column.kannbannDatarecord.key  )
        const destColumn = columns.find((column:any) => destination.droppableId === column.kannbannDatarecord.key);
        const sourceItems = [...sourceColumn.dataRecord];
        const destItems = [...destColumn.dataRecord];
        const [removed] = sourceItems.splice(source.index,1)
        destItems.splice(destination.index, 0,  removed);
        let statusOfColumn = destColumn.kannbannDatarecord.status;

       let destStatusCheck = destColumn.presentation.uiSettings.mappedStatus.find((action:any) => action.status === statusOfColumn ) ;
        let sourceStatusCheck = sourceColumn.presentation.uiSettings.mappedStatus.find((action:any) => action.status === statusOfColumn ) 
        
        let destAction = destColumn.presentation.actions.find((action:any) => action.labelName === destStatusCheck.actionMapped.name)
        if(CommonUtils.isEmpty(destAction)){
            return
        }
        if(CommonUtils.isNotEmpty(destStatusCheck.actionMapped)){
           const keepColumns =  columns.map((column:any) => {
               if(source.droppableId !== column.kannbannDatarecord.key) {
   
                   if(destination.droppableId !== column.kannbannDatarecord.key) {
                       return column
                   } else {
                       return {...column, dataRecord:destItems}
                   }
                   
               } else {
                   return {...column, dataRecord:sourceItems}
               }
               
           })  
           let isDraggable : boolean = CommonUtils.isNotEmpty(destAction.policy) ? enableTriggerByPolicy(destAction.policy,removed) : true
           if(isDraggable) {

               let detailedObject: any = {};
               detailedObject[sourceColumn.mainEntityId] = [removed]
               const processRequestModel = new KagamiProcessRequestModel( CommonUtils.isNotEmpty(destStatusCheck.actionMapped) ? destStatusCheck.actionMapped.routeTo.processName : sourceColumn.processName, handleDragSuccess, showServerError);
               processRequestModel.previousProcessName = CommonUtils.isNotEmpty(destStatusCheck.actionMapped) ? destStatusCheck.actionMapped.routeTo.processName : sourceColumn.processName;
               processRequestModel.data = detailedObject
               processRequestModel.onKanbannClose = onKanbannClose
               processRequestModel.fromDashboard = false;
               onStartCall(processRequestModel)
    
               setColumns(keepColumns)
           } else {
            return 
           }
       } else {
        return
       }

        


    } else {

        const column  = columns.find((column:any) => source.droppableId === column.kannbannDatarecord.key  );
        const copiedItems = [...column.dataRecord]
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0 , removed); 

        const sourceColumns =  columns.map((column:any) => {
            if(source.droppableId !== column.kannbannDatarecord.key) {
                return column
            } else {
                return {...column, dataRecord:copiedItems}
            }
        })
        setColumns(sourceColumns)
    }
 }
 function handleEditClick(processName: any, cardItem: any,  popupModel:KagamiModalModel ,kagamiKanbannSectionModel : any) {
    let detailedObject:any = {};
    function handleOnSuccess(content :  any){
        // popupModel.title = "Edit Applicant"
        popupModel.content = content;
        popupModel.isModalDialogTemplate = true
        popupModel.state.setOpen(true);
        }
    detailedObject[kagamiKanbannSectionModel.mainEntityId] = [cardItem]
    const processRequestModel = new KagamiProcessRequestModel(processName, handleOnSuccess, showServerError);
    processRequestModel.data = detailedObject;
    processRequestModel.fromDashboard = false;
    onStartCall(processRequestModel) 
}

export function getKanbannDetailView (kanbanModel : KagamiKanbanSectionsViewModel,dataRecord : any,popupModel : KagamiModalModel) {
  kanbanModel.onStartData.constructOutputData.detailedObjects[kanbanModel.mainEntityId] = [dataRecord];
  const kagamiviewFormModel: KagamiFormModel = new KagamiFormModel(
    kanbanModel.onStartData,
    kanbanModel.mainEntityId,
    kanbanModel.processName,
    kanbanModel.processStepName,
    'pChild'
  );
  kagamiviewFormModel.readOnly = true;
  kagamiviewFormModel.triggersModel.isDetailView = kagamiviewFormModel.readOnly;
  kagamiviewFormModel.isKanbannDetailView = true;
  popupModel.content =buildFormPage(kagamiviewFormModel);
  popupModel.isModalDialogTemplate = true
  popupModel.state.setOpen(true);
}

// export function handleDragSuccess(content :  any,popupModel: KagamiModalModel){
//   //make processCall
//   //NOTE: we are getting NO ui as response thus making on submit call .. which is leading to load process content.
//   //because of this ... this is an empty function NEED TO CHECK FOR FUTURE REFERENCES
//   popupModel.content = content;
//   popupModel.isModalDialogTemplate = true
//   popupModel.state.setOpen(true);
// console.log("successfull drag")
   
// }


export const KagamiKanbann = (props:any ) => {

    const [columns, setColumns ] = useState(props.columns)
    let popupModel: KagamiModalModel = props.popupModel;
    let activityLogModel: KagamiActivityLogModel = new KagamiActivityLogModel();
    let activeModel = getActiveModel();
    let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  
    const classes = makeStyles(KagamiKanbanViewStyle)();
    const classes1 = makeStyles(KagamiKanbanSectionStyle)();

    const onDragPopupClose = () => {
      /// Note : If the user didn't save or submit instead closed the popup the card should go back to the 
      /// initial section
      setColumns(columns);
    }

    const dragSuccess = (content : any) => {
      popupModel.content = content;
      popupModel.isModalDialogTemplate = true
      popupModel.handleOnClose = onDragPopupClose;
      popupModel.state.setOpen(true);
      console.log("successfull drag")
    }
    
    return (
        <>
        <div style =  {{display : 'flex', gap: '10px' , marginLeft: '10px' }}>
            <DragDropContext onDragEnd={(result) =>{
              console.log('dragged')
            onDragEnd(result, columns, setColumns,dragSuccess,popupModel)
            } }>
                {columns.map((item:any) => {
                    return (
                        <Droppable droppableId={item.kannbannDatarecord.key} key = {item.kannbannDatarecord.key}>
                            {(provided, snapshot) => {
                                return (
                                    <div  
                                    style={{  
                                        background: snapshot.isDraggingOver ? `${item.kanbanHeaderColor} `: '#f5f8fa', 
                                    }}>
                                        <div
                                         style={{
                                            minHeight:36,
                                            backgroundColor: item.kanbanHeaderColor,
                                            borderTopLeftRadius:"5px",
                                            borderTopRightRadius:"5px",
                                            color: 'white',
                                            width:'200px',
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                             borderBottom: '1px solid #ebedf0'
                                        }}>
                                          <Typography variant="subtitle2" >
                                            {/* {item.kannbannDatarecord.status}  */}
                                            {translateToLocalistaion(translations, item.kannbannDatarecord.status)}
                                          </Typography> &nbsp;
                                         {item.dataRecord.length > 0 && <span style={{border: '2px solid #fff', borderRadius: '50%',fontSize:'15px',fontWeight:'bold', height:25, width:25, display:'flex', alignItems: 'center', justifyContent: 'center', marginLeft:'3px' }}>{item.dataRecord.length}</span> } 
                                        </div>
                                    <div
                                    className={classes1.kanbanColumn}
                                    {...provided.droppableProps}
                                    ref = {provided.innerRef}
                                    style={{
                                        background: snapshot.isDraggingOver ? `${item.kanbanHeaderColor}`: '#f5f8fa', 
                                       
                                        padding: 4,
                                        width:'200px',
                                        minHeight: 500
                                    }}
                                    >  
                                        {item.dataRecord.map((cardItem:any, index:any) => {
                                            return (
                                                <Draggable key={cardItem.id} draggableId={cardItem.id} index={index} >
                                                    {(provided, snapshot) => { 
                                                        return (
                                                            <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                userSelect:'none',
                                                                padding:'2px 8px',
                                                                margin:' 0 0 5px 0',
                                                                minHeight:'40px', 
                                                                borderRadius:"5px",
                                                                backgroundColor: snapshot.isDragging ? '#f4f4f4': '#fff',
                                                                borderLeft: snapshot.isDragging ? '1px solid #fff' : `2px solid ${item.kanbanHeaderColor}` ,
                                                                color:'#000',
                                                                ...provided.draggableProps.style
                                                            }}
                                                            >
                                                                <div  className ={classes1.cardItemKanban}>

                                                                <div style={{width:'85%'}}>
                                                                     
                                                                {item.visibleAttributes.map((attribute:any) => {
                                                                    let color = CommonUtils.isNotEmpty( attribute.color) 
                                                                    ? attribute.color 
                                                                    : CommonUtils.isNotEmpty(attribute.attributePresentation?.uiSettings?.fontStyling?.fontColor) 
                                                                      ? attribute.attributePresentation?.uiSettings?.fontStyling?.fontColor 
                                                                      : '#33475b';
                                                                    return (<>
                                                                 
                                                                    {getCardFieldValueForKanbann(cardItem, attribute, color)}
                                                                        
                                                                    </>)
                                                                })}
                                                                </div>

                                                                <div  style={{width:'15%'}}>
                                                               
                                                                {item.presentation.hasOwnProperty('actions') ? (
                                                                    <>
                                                                    <IconButton  size='small'
                                                                     onClick={(event: any) => {
                                                                        item.popoverModel.transactionId = cardItem.transactionEntityId;
                                                                        item.popoverModel.child  = <KagamiKanbanPopOver kagamiKanbannSectionModel={item} cardModel = {cardItem}/>
                                                                        item.popoverModel.state.setPopoverTarget(event.currentTarget)
                                                                        // kanbannModel.popoverModel.state.setPopoverStatus(true);
                                                                        // item.popoverModel.state.setPopoverShowIcon(false);
                                                                        }}>
                                                                    <MoreVertIcon fontSize='small' className='moreoververticon'
                                                                        style={{  fontSize: '18px',color :'#006e7f' }}
                                                                       
                                                                    />
                                                                    </IconButton>
                                                                    {buildPopover(item.popoverModel)}
                                                                    </>
                                                                ) : <></>}
                                                                


                                                                <div style={{height:30, width:30}}>
                                                                    <IconButton  size='small' className = {classes.editIconKanban}
                                                                        onClick={() =>{  
                                                                        let editaction = item.presentation.actions.find((action :any) => action.labelName === 'edit' )
                                                                        if(editaction){
                                                                          handleEditClick(editaction.processName,cardItem, popupModel ,item)
                                                                        }
                                                                        else{
                                                                          getKanbannDetailView(props.kanbannViewModel,cardItem,popupModel)
                                                                        }
                                                                        }}
                                                                    >
                                                                         <EditIcon fontSize='small'
                                                                            style={{ color: '#666666', fontSize: '18px' }}
                                                                           
                                                                        /> 
                                                                    </IconButton>
                                                                </div>


                                                              
                                                                </div>
                                                               
                                                                </div>
                                                                
                                                               

                                                            </div>
                                                        )
                                                    }}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                    </div>
                                )
                            }}
                        </Droppable>
                    )
                })}
            </DragDropContext>
      {buildModal(popupModel)}
      
        </div>
  {buildActivityLogModal(activityLogModel)}
        </>
    )
}




export const getCardFieldValueForKanbann = (dataRecord: any, column: any, color: any) => {
  let attributeName: string = column.field;
  let value: any;
  let getDataValue:any;
  let htmlControlType = column?.attributePresentation?.htmlControl;
  let isPickAttribute = column.attributePresentation.attrName.includes('.');

  if (attributeName.includes('.')) {
    
    if(isPickAttribute){
      let a = column.attributePresentation.attrName.split(".")
      let colorId = dataRecord[a[0]].colorId
      if(colorId) {
        let bgcode=  dataRecord[a[0]]['Color']['bgCode'];
     

        value =   <div style={{
          height:'100%',
          width:'100%',
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}>
        <div style={{
           height:'10px',width:'10px',
           borderRadius: '100%',
          backgroundColor: bgcode ? bgcode : 'default'}}></div>
        <div >
            <span>{getValueForPickAttribute(attributeName, dataRecord)} </span>
        </div>
    </div>
      }
      }
    else if(htmlControlType ==="date" || htmlControlType ==="Date"){
      getDataValue = getValueForPickAttribute(attributeName, dataRecord); 
      value = moment(getDataValue).format('DD-MM-yyyy');
    }else if(htmlControlType === 'customactivitylog'){
      let activityModel = getKagamiActivityLogModel()
      activityModel.controlValue = value;
      activityModel.entityId = dataRecord.entityType; 
      
      value = buildActivityControlKanban(activityModel)

    }  
    else {
      value = getValueForPickAttribute(attributeName, dataRecord);

    }
   
  } else {
    if (typeof dataRecord[column.field] === 'object') {
      let valueData = dataRecord[column.field]
      if(Object.keys(column.headerName).length ==2){
        let value1 = valueData[column.headerName[0]];
        let value2 = valueData[column.headerName[1]]
        value = value1 + '-' + value2;
      }else{
        if(htmlControlType === "date" || htmlControlType === "Date"){
          getDataValue = valueData[column.headerName]
          value = moment(getDataValue).format('DD-MM-yyyy');
        } 
        else if(htmlControlType.toLowerCase() === 'search' ) {
          const entityConsumed = column.attributePresentation.entityConsumed
          value =  extractDropdownOption(column.attributePresentation,dataRecord[entityConsumed])
         }
         else{
          value = valueData[column.headerName];
        }
      }
    } else {
      if(htmlControlType.toLowerCase() === "date"){
        getDataValue  = dataRecord[column.field];
        value = moment(getDataValue).format('DD-MM-yyyy');
      } else if(htmlControlType === 'customactivitylog'){
        let activityModel = getKagamiActivityLogModel();
        activityModel.attributeName = getAttributeName(column);
        activityModel.controlValue = dataRecord.activitylog;
        activityModel.entityId = dataRecord.entityType; 
        
        value = buildActivityControlKanban(activityModel)

      } 
     
       else{
        value = dataRecord[column.field];
      }
     
    }
  }
  return CommonUtils.isNotEmpty(value) ? <Typography component="h6" variant="h6"  style={{ width: '100%', float: 'left', textAlign: 'left', fontSize: '12px', color: `${  color  }` }}>{value}</Typography> : <></>
};

const rebulidTriggersForSelectionProcess = (kanbanModel: any, index: any, popupModel: any) => {
    let attributes = kanbanModel.visibleAttributes;
    const selectedObjects: any[] = [];
    if (kanbanModel.dataRecord.length > 1) {
      kanbanModel.dataRecord.filter((ele: any) => {
        if (ele.transactionEntityId === index) {
          popupModel.content = GetModelDataForKanbanSection(ele, attributes);
          popupModel.state.setOpen(true);
        }
      });
    } else {
      // kanbanModel.dataRecord.forEach((ele: any) => {
      //   selectedObjects.push(ele);
      //   getKagamiListModel().selectedObjects = selectedObjects;
      //   reBuildTriggers();
      // });
      let kanbanPopModelData = kanbanModel.dataRecord[0];
      popupModel.content = GetModelDataForKanbanSection(kanbanPopModelData, attributes);
      popupModel.state.setOpen(true);
    }
  };




  export const GetModelDataForKanbanSection = (selectedObjects: any, attributes: any) => {
    // let objectData = new Map();
    // let object = selectedObjects;
    // for (var value in object) {
    //   objectData.set(value, object[value]);
    // }
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%'
      }}>
        {attributes.map((ele: any) => {
          // let color = ele.color != undefined ? ele.color : 
          let color = CommonUtils.isNotEmpty( ele.color) 
          ? ele.color 
          : CommonUtils.isNotEmpty(ele.attributePresentation?.uiSettings?.fontStyling?.fontColor) 
          ? ele.attributePresentation?.uiSettings?.fontStyling?.fontColor 
          : '#33475b';
          return (
            <div style={{
              display: 'block',
              width: '33.33%',
              padding: '5px',
              marginTop: '20px'
            }}>
              <Typography
                gutterBottom
                style={{
                  fontSize: '11px !important',
                  color: '#0066b3',
                  fontWeight: '600',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  // lineHeight: '22px',
                  whiteSpace: 'nowrap',
                  width: '50%',
                  textTransform: 'capitalize'
                }}
              >
                {ele.keyName}
              </Typography>
              <Typography
                gutterBottom
                style={{
                  color: `${color}`,
                  fontSize: '10px !important',
                  // lineHeight: '12px',
                  whiteSpace: 'nowrap',
                  width: '50%',
                  textTransform: 'capitalize'
                }}
              >
                {getCardFieldValueForKanbann(selectedObjects, ele, color)}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };

export const KagamiKanbanPopOver = (props: any) => {
  const classes = makeStyles(KagamiKanbanSectionStyle)()
    let popoverkanbanModel = props.kagamiKanbannSectionModel;
    // let kagamiTriggersModel = new KagamiTriggersModel(
    //   popoverkanbanModel.presentation.entityId,
    //   popoverkanbanModel.presentation.actions,
    //   handleTriggerClick
    // );
    // if (popoverkanbanModel.dataRecord.length > 1) {
    //   popoverkanbanModel.dataRecord.filter((dataRc) => {
    //     if (dataRc.transactionEntityId === popoverkanbanModel.popoverModel.transactionId) {
    //       kagamiTriggersModel.detailedObject = dataRc;
    //     }
    //   });
    // } else {
    //   kagamiTriggersModel.detailedObject = popoverkanbanModel.dataRecord;
    // }
    // return <>{KagamiTriggersController.buildTriggers(kagamiTriggersModel)}</>;
  let statusModelPopup: KagamiModalModel = new KagamiModalModel()
   
    const handleClick = (processName: string, detailedObject: any, entityId: string, popoverModel: KagamiPopoverModel,  action: any) => {
         if(action.confirmation){
        statusModelPopup.state.setOpen(true);
        statusModelPopup.content = action.confirmationMsg
        statusModelPopup.title = action.actionName;
        statusModelPopup.handleOnOk = handleOnOkAction
         }else{
          handleOnOkAction()
         }
        function handleOnOkAction (){
          executeProcess(processName,detailedObject)
        }
        popoverModel.state.setPopoverTarget(null);
      };
      let enabledTriggers: any[] = []
      popoverkanbanModel.presentation.actions.map((item:any) => {
        const contextForward: boolean = item.contextForward;
        const selectMultiple: boolean = item.selectMultiple;
      
        let contextType: ContextType = contextForward
          ? selectMultiple
            ? ContextType.Mutli
            : ContextType.Single
          : ContextType.None;
      
          let triggerModel: KagamiTriggerModel = new KagamiTriggerModel(
            popoverkanbanModel.presentation.entityId,
              item.processName,
              item.eventName,
              item.labelName === undefined ? item.actionName : item.labelName,
              item.confirmation,
              item.confirmationMsg,
              contextType,
              handleClick
            );

          triggerModel.detailedObject = props.cardModel

            if(triggerModel.contextType === ContextType.None){
              triggerModel.enableTrigger = false;
            }
            
            triggerModel.policy = item.policy
            if (triggerModel.detailedObject) {
              triggerModel.enableTrigger = enableTrigger(
                triggerModel,
                triggerModel.detailedObject instanceof Array ? triggerModel.detailedObject : [triggerModel.detailedObject],
                triggerModel.enableTrigger
              );
              if(triggerModel.contextType === ContextType.None && CommonUtils.isEmpty(triggerModel.policy)){
                triggerModel.enableTrigger = false;
              }
            }
           triggerModel.enableTrigger &&  enabledTriggers.push(item)
      })

    // let triggerModels : any[] = popoverkanbanModel.presentation.hasOwnProperty('actions') && popoverkanbanModel.presentation['actions'].length > 0  ? getEnabledTriggerModels(popoverkanbanModel.presentation, handleClick) : []
  
      
  

    return (
        <>
        <div style={{
           minWidth: '105px',
          //  position:'relative',
          //  border:'1px solid #d4d4d4',
          //  borderTop: '3px solid #d4d4d4',
          //  fontSize:'15px'
        }}>
            <MenuList style={{padding:'0'}}>
              {enabledTriggers.map((item: any) => {
                return    (
                  <>
                  <MenuItem 
                  className={classes.PopoverMenuItem}
                  
                  onClick={() => handleClick(item.processName, props.cardModel, popoverkanbanModel.mainEntityId, props.kagamiKanbannSectionModel.popoverModel, item)}
                  >
                    {CommonUtils.isNotEmpty(item.labelName) ? item.labelName : item.actionName}
                  </MenuItem>
                  </>
                )
              })}
            </MenuList>
        </div>
        </>
    )
  };