import extend from 'lodash/extend';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import {getReportData,
        } from '../../../../service/ReportUtilService';
const columnDefaultConfig = ()=> {
  return  {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        height: 450,
        zoomType:'xy',
    },
    exporting: {
        enabled: false
    },
    title: {
        text: '',
        align: 'center',
    },
    tooltip: {
      shared: true,
      valueSuffix: ''
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: false
            },
            enableMouseTracking: true
        }
    },
    credits: {
      enabled: false,
    },
    series: [{
        name: '',
        colorByPoint: true,
        data:[],
    }] 
  }	
}

export const makeColumn = (columnReportController: any) => {
  let defaultConfig  = columnDefaultConfig();
  let dbChartData = JSON.parse(columnReportController.reportData);
  let tablerecords = getReportData(dbChartData);
  let userConfig = prapareUserConfigForChart(tablerecords,columnReportController.reportConfig,false);
  let dispchartOptions =  extend(true, {}, defaultConfig, userConfig);
  return dispchartOptions;
}

const prapareUserConfigForChart = (tablerecords:any,reportConfig:any,isDashboard:any)  => {
    let userConfig: any = {
        yAxis: {
        title: {
            text: getYAxisLabels(reportConfig.horizontalAxis)
        },
        min: 0
        },
        xAxis: {
        title: {
            text: reportConfig.verticalAxis
        },
        min: 0,
        categories: column_getColumn(reportConfig.verticalAxis,tablerecords)
        },
        series: [
        // {
        //     data: []
        // }
        ]
    };
    let seriesData: any = [];
    // let xaxis = reportConfig.horizontalAxis;
    let yaxis = reportConfig.verticalAxis[0];
    if (reportConfig.horizontalAxis && yaxis) {
       forEach(reportConfig.horizontalAxis, function (xaxis, index) {
        seriesData.push({
          name: xaxis,
          pointWidth: 20,
          // data: [getValue(tablerecords, xaxis)??'']
          data: map(tablerecords,xaxis)

        })
      })
    }

    function getValue(tablerecords:any,xaxis:string){
      for(let [key,value] of Object.entries(tablerecords[0])){
        if(key==xaxis){
          return value;
        }
      }
    return null;
    }
    // if (xaxis && yaxis) {
    //    forEach(tablerecords, function (rec, index) {
    //     seriesData.push({
    //       name: parseFloat(rec[xaxis]),
    //       pointWidth: 250,
    //       y: rec[yaxis]
    //     });
    //   });
    // }
    
  // userConfig.series[0].data = seriesData;

  userConfig.series = seriesData;
  return userConfig;
}


const getYAxisLabels = (arr:any) => {
     return  (arr && arr.length > 0) ? arr.join(",") : ''
 }
const column_getColumn = (colId:any,chartData:any) => {
    return  map(chartData, function (row) {
        return row[colId];
    });
}