import makeStyles from '@mui/styles/makeStyles';
import { createState } from '@hookstate/core';
import { KagamiCardViewModel, KagamiCardViewModelProps, useCardViewState } from '../model/KagamiCardViewModel';
import { kagamiCardStyles } from '../styles/KagamiCardViewStyle';
import {  CardView } from '../builder/CardBuilder';

const useStyles = makeStyles(kagamiCardStyles);
export const buildCardViews = (kagamiCardViewModel: KagamiCardViewModel) => {
  return <KagamiCardView key="card-view" kagamiCardViewModel={kagamiCardViewModel}></KagamiCardView>;
};

const KagamiCardView = (props: KagamiCardViewModelProps) => {
  let kagamiCardViewModel = props.kagamiCardViewModel;
  let cardState = useCardViewState(createState(kagamiCardViewModel));
  kagamiCardViewModel.state = cardState;

  if (!cardState.getListLoaded()) {
    return <p></p>;
  } else {
    return (
      <CardView key="card-view-details" kagamiCardViewModel={kagamiCardViewModel} > </CardView>
    );
  }
};
