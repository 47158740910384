import { createState,  useHookstate } from "@hookstate/core";
 
import { KagamiFolderModel } from "./KagamiFolderModel";

export interface KagamiPanelModel {
  activeFolder: KagamiFolderModel;
  activeItem: string;
  sideBarCollapesed: boolean;
  activeItemParents:string[];
  sideBarToggled:boolean;
  activeSubFolder:boolean;
}

export const panelState = createState({
  activeFolder: null as any,
  activeItem: "",
  sideBarCollapesed: true,
  sideBarToggled:false,
  activeItemParents: [] as string[],
  activeSubFolder:null as any,
 
});

export const usePanelState = () => {
  let _state = useHookstate(panelState);

  return {
    get activeFolder() {
      return _state.activeFolder.get();
    },
    SetActiveFolder(value: KagamiFolderModel) {
      _state.activeFolder.set(value)
    },
    get activeSubFolder() {
      return _state.activeSubFolder.get();
    },
    SetActiveSubFolder(value: KagamiFolderModel) {
      _state.activeSubFolder.set(value)
    },
   
    get activeItem() {
      return _state.activeItem.get();
    },
    SetActiveItem(value: string) {
      _state.activeItem.set(value)
    },

    get sideBarCollapesed() {
      return _state.sideBarCollapesed.get();
    },

    SetSideBarCollapsed(value: boolean) {
      _state.sideBarCollapesed.set(value)
    },

    get sideBarToggled() {
      return _state.sideBarToggled.get();
    },

    SetSideBarToggled(value: boolean) {
      _state.sideBarToggled.set(value)
    },

    get activeItemParents(){
      return _state.activeItemParents.get();
    },

    SetActiveItemParents(value:string[]){
      _state.activeItemParents.set(value)
    },

    SetClearPanelState(){
      _state.activeFolder.set(null);
      _state.activeItemParents.set([])
      _state.activeItem.set("")
      _state.activeSubFolder.set(null)
    }

  };
};
