import { makeStyles } from '@mui/styles';
import { DashboardReportsProps } from '../model/DashboardReportsModel';
import { DashboardReportsStyle } from '../style/DashboardReports';

const useStyles = makeStyles(DashboardReportsStyle);

export const DashboardReportBody = (props: DashboardReportsProps) => {
  const classes = useStyles();
  return <div className={classes.dashboard_content}>{props.dashboardReportsModel.content}</div>;
};
