import toast from "react-hot-toast";
import { ToastNotificationModel } from "../model/ToastNotificationModel";
import { buildToast } from "../ui/ToastNotification";


export function buildtoastNotification (statusType:string , statusMessage: string, timeOutDuration?:number){
    const toastNotificationModel = new ToastNotificationModel();
    toastNotificationModel.statusType = statusType;
    toastNotificationModel.statusMessage = statusMessage
    toastNotificationModel.timeOutDuration = timeOutDuration
   return buildToast(toastNotificationModel)
}


interface triggerNotifcationTypes {
    text: string;
    background: string;
    textColor: string;
    time: number;
  }

export const triggerNotification = ({ text, background, textColor, time }: triggerNotifcationTypes) => {
    toast(text, {
      // style: {
      //   borderRadius: '15px',
      //   background: background,
      //   color: textColor
      // },
      duration:time*1000
    });
  };
  
  export const showToastAlert = ( message: string,time: number) => {
    return setTimeout(() => {
      triggerNotification({
        text: message,
        background: '#8dc63f',
        textColor: '#ffffff',
        time: 3
      });
    }, time);
  } 