import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";

export class SelectFieldValidator {
    static errorText:any = '';
   
    static validate(validations:any, value:any) {
      
      if (null != validations && validations) {
        let k:any
        SelectFieldValidator.errorText = null
        //let value:any
        // for (var k in validations.keys) {
        for ([k] of  Object.entries(validations)) {
          switch (k) {
            case 'notNull':
                SelectFieldValidator.notNullValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
            case 'onlyAlphabets':
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'alphaNumeric':
                SelectFieldValidator.alphaNumericValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'email':
                SelectFieldValidator.emailValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'mobile':
                SelectFieldValidator.mobileValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'website':
                SelectFieldValidator.websiteValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'fax':
                SelectFieldValidator.faxValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'upperCase':
                SelectFieldValidator.upperCaseValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'lowerCase':
                SelectFieldValidator.lowerCaseValidation(value);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            case 'length':
                SelectFieldValidator.lengthValidation(value, validations[k]);
              if (SelectFieldValidator.errorText != null) {
                return SelectFieldValidator.errorText;
              }
              break;
  
            default:
              break;
          }
        }
        return SelectFieldValidator.errorText;
      }
      return null;
    }
  
    static notNullValidation(value:any) {
      if(value instanceof Object){
        (value
          && Object.keys(value).length === 0)
         ? SelectFieldValidator.errorText = 'This field is mandatory' : SelectFieldValidator.errorText = null;
      }else{
        SelectFieldValidator.errorText = CommonUtils.isNotEmpty(value) ?  null : 'This field is mandatory';
      }
      
    }
  
    static alphaNumericValidation(value:any) {
      SelectFieldValidator.errorText =  RegExp('^[a-zA-Z0-9]+$').test(value) ? null : 'Entered value is not alpha numeric';
    }
  
    static emailValidation(value:any) {
      SelectFieldValidator.errorText =  RegExp('^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$')
              .test(value)
          ? null
          :'Not a valid email';
    }
  
    static mobileValidation(value:any) {}
  
    static websiteValidation(value:any) {
      SelectFieldValidator.errorText =  RegExp('^((http:\/\/)|(https:\/\/)|(www.))[a-zA-Z_0-9-]{3,}(\.(:?[a-zA-Z0-9]{2,}))*(\.(:?[a-zA-Z0-9]{2,}))$').test(value)
          ? null
          : 'Not a valid website';
    }
  
    static faxValidation(value:any) {}
  
    static upperCaseValidation(value:any) {
      SelectFieldValidator.errorText =  RegExp('^[A-Z]+(\s[A-Z]+)?$').test(value) ? null : 'Only uppercase alphabets are allowed';
    }
  
    static lowerCaseValidation(value:any) {
      SelectFieldValidator.errorText =  RegExp('^[a-z]+(\s[a-z]+)?$').test(value) ? null :  'Only lowercase alphabets are allowed';
    }
  
    static lengthValidation(value:any, map:any) {
      for (var k in map.keys) {
        switch (k) {
          case 'min':
            if (value.length < parseInt(map[k])) {
                SelectFieldValidator.errorText = 'This field must have minimum of ' + map[k] + ' characters';
              break;
            }
            break;
  
          case 'max':
            if (value.length > parseInt(map[k])) {
                SelectFieldValidator.errorText = 'This field can have maximum of ' + map[k] + ' characters';
              break;
            }
            break;
  
          default:
            break;
        }
        if (SelectFieldValidator.errorText != null) {
          break;
        }
      }
    }
  }
  