import * as React from 'react';
import { sliceEvents, createPlugin } from '@fullcalendar/core';
import Grid from '@mui/material/Grid';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getEventsInfoForCustomCalendar } from '../../../service/KagamiCalendarViewService';
import { enableTrigger, onTriggerClick } from '../../../../../../Triggers/service/KagamiTriggersService';
import { handleServerError } from '../../../../../controller/KagamiListController';
import { loadProcessContent } from '../../../../../../../controller/KagamiProcessController';
import { getCalendarViewModel } from '../../../controller/KagamiCalendarViewController';
import { buildGridEventForPopup } from '../../KagamiCalendarBuilder';
import { KagamiModalModel } from '../../../../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { buildModal } from '../../../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { SystemConstants } from '../../../../../../../../../../../../../constants/SystemConstants';
import { ContextType } from '../../../../../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { confirmBox } from '../../../../../../Triggers/builder/TriggerAlertModal/controller/TriggerAlertModalController';

const CustomYear = (props:any) =>{
    let segs = sliceEvents(props, true); // allDay=true
    const [slideVisibleIndex, setSlideVisisbleIndex] = React.useState({index:-1,monthIndex:-1,month:''});

    let eventsInfo:any = getEventsInfoForCustomCalendar(props);

    let kagamiModalModel = new KagamiModalModel();

    const handleMonthClick = (index: any,monthIndex:any,month:any) => {
         if(slideVisibleIndex.index != index || slideVisibleIndex.monthIndex != monthIndex && eventsInfo[month]) {
          setSlideVisisbleIndex({index,monthIndex,month});
         }else {
          setSlideVisisbleIndex({index:-1,monthIndex:-1,month:''});
         }
    };
    
    const handeView = (monthData:any) =>{
      let calendarViewModel = getCalendarViewModel();
      kagamiModalModel.title = props.eventStore.defs[monthData.defId].title;
      kagamiModalModel.content = buildGridEventForPopup(calendarViewModel, props.eventStore.defs[monthData.defId], "year");
      kagamiModalModel.modalWidth = 'lg';
      kagamiModalModel.modalOnTop = true;
      kagamiModalModel.state.setOpen(true);
    }


    // const handleActionEvent = (monthData:any, action:any) => {
    //   // switch(action.labelName.toLowerCase()){
    //   //   case 'edit':
    //   // let triggerModel = kagamiListModel.triggersModel.triggerControls[action.labelName.toLowerCase()];
    //       let detailedObjects = [props.eventStore.defs[monthData.defId]?.extendedProps.calendarDetailedObject.find((ele: any)=>{
    //         return ele.id === props.eventStore.defs[monthData.defId].publicId;
    //       })]
    //       let kagamiListModel: KagamiListModel = getKagamiListModel();
    //       let triggerModel = CommonUtils.isNotEmpty(kagamiListModel.triggersModel.triggerControls[action.labelName]) ? kagamiListModel.triggersModel.triggerControls[action.labelName] :kagamiListModel.triggersModel.triggerControls[action.labelName.toLowerCase()];
    //       onTriggerClick(detailedObjects,triggerModel,handleServerError, loadProcessContent,false,false);
    //   //     break;
    //   //   case 'delete':
    //   //     break;
    //   // }
    // }

    const getAction = (monthData: any) => {
      return props.eventStore.defs[monthData.defId].extendedProps.presentation.actions.map((action: any) => {
        let triggerModel = CommonUtils.isNotEmpty(
          getCalendarViewModel().triggersModel.triggerControls[action.labelName]
        )
          ? getCalendarViewModel().triggersModel.triggerControls[action.labelName]
          : getCalendarViewModel().triggersModel.triggerControls[action.labelName.toLowerCase()];
        if (CommonUtils.isNotEmpty(triggerModel)) {
          let detailedObject = [
            props.eventStore.defs[monthData.defId]?.extendedProps.calendarDetailedObject.find((ele: any) => {
              return ele.id === props.eventStore.defs[monthData.defId].publicId;
            })
          ];
          getCalendarViewModel().triggerAlertModalModel = triggerModel.triggerAlertModalModel;
          triggerModel.detailedObject = detailedObject;
          if (triggerModel.detailedObject) {
            triggerModel.enableTrigger = enableTrigger(
              triggerModel,
              triggerModel.detailedObject,
              triggerModel.enableTrigger
            );
          }
          if (triggerModel.contextType === ContextType.None && CommonUtils.isEmpty(triggerModel.policy)) {
            triggerModel.enableTrigger = false;
          }
          if (triggerModel.enableTrigger) {
            return (
              <a
                href="javascript:;"
                className="event-item-action"
                onClick={() =>{
                  // onTriggerClick(detailedObject, triggerModel, handleServerError, loadProcessContent, false, false)
                  if(triggerModel.confirmationNeeded){
                    confirmBox('Confirm', triggerModel.confirmationMsg, function(flag:boolean){
                      if(flag){
                        // triggerModel.triggerCallback(triggerModel);
                        onTriggerClick(detailedObject, triggerModel, handleServerError, loadProcessContent, false, false)
                      }
                    })
                  }else{
                    // triggerModel.triggerCallback(triggerModel);
                    onTriggerClick(detailedObject, triggerModel, handleServerError, loadProcessContent, false, false)
                  }
                }}
              >
                {action.actionName === 'ClaimCompOff' && result ? null : (
                  <span className="eventsTrigger">{action.labelName}</span>
                )}
              </a>
            );
          }
        }
        return;
      });
    };
    
  const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
  let userId = null;
  let result: any;
    if (loginData !== null) {
      let loginObject = JSON.parse(loginData);
      userId =  loginObject.userId;
      result =  userId.includes('KGM');
     }
      
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let monthsList = [['January', 'February', 'March', 'April'], ['May', 'June', 'July', 'August'], ['September', 'October', 'November', 'December']];
    return (
      <>
      {buildModal(kagamiModalModel)}
      <div style={{width: '100%', minHeight: 'calc( 100vh - 250px)',height: 'calc( 100vh - 250px)'}}>
      <Grid container className='year-calendar'>
          {
            monthsList.map((monthsStack: any, index: any) => {
              return (
                <>
                  {
                    monthsStack.map((month: any, monthIndex: any) => {
                      return (
                        <Grid item xs={3}   onClick={() => handleMonthClick(index,monthIndex,month)}>
                        <div className='calendar-cell' key={monthIndex}>
                           <span className='month-name'>{month}</span>
                            {eventsInfo && eventsInfo[month] &&
                              <span className='badge holidays-badge'>
                                {eventsInfo[month]?.length || ''}
                              </span>
                            }
                           {eventsInfo[slideVisibleIndex.month] && <div className={(index == slideVisibleIndex.index && monthIndex == slideVisibleIndex.monthIndex ) ? "calendar-arrow-tick" : "calendar-arrow-tick hidden"}>
                            <ExpandLessIcon fontSize='small' />
                          </div>}
                        </div>
                        </Grid>
                        )
                    })
                  }
                  { slideVisibleIndex.month && eventsInfo[slideVisibleIndex.month] &&
                  <Grid  className={index == slideVisibleIndex.index? "calendar-slide-box" : "calendar-slide-box hidden"}  item xs={12} >
                    <div className="cal-slide-box">
                      <div className="cal-slide-content cal-event-list">
                        <ul className="unstyled list-unstyled">
                            {eventsInfo && eventsInfo[slideVisibleIndex.month] &&
                            eventsInfo[slideVisibleIndex.month].map((monthData: any) => {
                              let actions:any = getAction(monthData);
                              return (
                                <li>
                                  <div className="event-list">
                                    <span className="event-radio" style={{background:props.eventStore.defs[monthData.defId].ui.textColor}}></span>
                                    &nbsp;
                                    <a href="javascript:;" className="event-item">
                                      <span> {monthData.title || ''}</span>
                                    </a>
                                    <a
                                      href="javascript:;"
                                      className="event-item-action"
                                      onClick={() => handeView(monthData)}
                                    >
                                      <span className="eventsTrigger">View</span>
                                    </a>
                                    {actions.length > 0 ? ( <>{actions}</>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </Grid>
              }
                </>
                // </div>
            /*end row stack*/
                )
            })
          }
          </Grid>
      </div>
      </>


    );

}

export default createPlugin({
  views: {
    CustomYear
  }
});