import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';
import { CommonUtils } from '../../../utils/CommonUtils';
import { getNotification } from '../builder/body/builder/dashboard/service/DashboardService';
import { renderBody } from '../builder/body/controller/KagamiBodyController';
import { KagamiHomeModel } from '../model/KagamiHomeModel';
import { buildHomeComponent } from '../ui/KagamiHome';

let kagamiHomeModel: KagamiHomeModel;

export function buildHome(linkProcessId : any) {
  const homeModel: KagamiHomeModel = new KagamiHomeModel();
  if(CommonUtils.isNotEmpty(linkProcessId)){
    homeModel.linkProcessId = linkProcessId;
    homeModel.executeLinkProcess = true;
  }
  // kagamiHomeModel = homeModel;
  return buildHomeComponent(homeModel);
}

export function showBody() {
  renderBody(kagamiHomeModel.bodyModel);
   getNotification(kagamiHomeModel.bodyModel.dashboardModel)
   KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(true);
   KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(true);


}


export function getHomeModel(){
  return kagamiHomeModel;
}

export function setHomeModel(model : KagamiHomeModel){
  kagamiHomeModel = model;
}
