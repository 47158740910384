import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import { TextValidator } from '../../../../../../controls/textbox/validations/TextValidator';

export const KagamiEmbeddedTabsStyle = (theme: Theme) =>
  createStyles({
    mainRoot :{
      '&.fullWidthAccordSection':{
        // margin: '-12px',
        // padding: theme.spacing(1),
        // border: '2px solid #ebebeb', 
        background: theme.palette.background.default,
        width: '100%',
        // marginRight:'40px',
        '& .MuiTabs-root': {
          minHeight:'0',
        },
        '& .MuiButtonBase-root' : {
          overflow:'inherit',
          height: '45px',
        },
        '& .MuiTabs-scroller.MuiTabs-fixed': {
          overflow:'auto !important',
          border:'1px solid #006e7f',
           borderRadius:'4px',
           backgroundColor: "#ffff",
           marginBottom: '4px!important',
           maxWidth: 'fit-content'
        },
      '& > div:last-child': {
        // borderLeft: '1px solid #ebebeb',
        // borderBottom: '1px solid #ebebeb',
        // // borderTop: '1px solid #ebebeb', 
        // borderRight: '1px solid #ebebeb',
      }
      },
      '& .MuiTabs-flexContainer':{
        // backgroungColor: theme.palette.background.default,
        padding: '5px',
      },
    },
    root: { 
      // background: theme.palette.background.default,
      // //  background: '#EEF4FB',
      //  width: 'fit-content',
      // //  borderTop: '1px solid #ebebeb',
      // //  borderRight: '1px solid #ebebeb',
      //  height: theme.spacing(4.5),
      //  minHeight: theme.spacing(4.5), 
       marginTop:'10px',
       marginLeft:'7px',
       overflow: 'inherit',
       '& .MuiTab-root': {
         minHeight: theme.spacing(5.62), 
        //  margin: `0 ${theme.spacing(1)}`,
        //  borderLeft: '1px solid #ebebeb',
        //  borderRight: '1px solid #ebebeb',
        color: '#29947f',
        fontWeight: '500',
        padding:'10px',
        '&> div': {
          alignItems:'center',
          // background:'red',
        },

        '&.Mui-selected': {
          // background: theme.palette.common.white,
          // background: theme.palette.background.default,
          // background: theme.palette.background.defaultBlue,
          // borderBottom: theme.palette.common.white,
          // color: '#29947f',
          // color: '#0059b3',
          // fontWeight: '600',
          color: '#fff',
          background: '#006e7f',
          borderRadius: '4px',

        },
       '& .MuiTab-wrapper': {
        //  color: theme.palette.common.black,
         textTransform: 'capitalize',
         fontSize: theme.typography.subtitle1.fontSize,
         paddingRight:'6px',
       }
      }
    }, 
    tabLabel: { 
      fontSize: theme.typography.subtitle1.fontSize,
      color: 'inherit', 
      fontWeight:  'inherit',
      paddingRight:'10px',
    },
    indicator: {
      bottom: '0',
      height: '0',
      backgroundColor: '#156282',
    }

    

  });

 



