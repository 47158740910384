import { FormLabel,FormControl, InputAdornment, Typography } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyFrancIcon from '@mui/icons-material/CurrencyFranc';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { KagamiNumberModel } from '../model/KagamiNumberModel';
import { validateNumberBox } from '../controller/KagamiNumberController';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import { styled } from '@mui/material/styles';




export class KagamiNumberBuilder { }

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

export const buildIconsForNumberBox = (validations: any) => {
  if (null != validations && validations) {
    let validation: any;
    for ([validation] of Object.entries(validations)) {
      switch (validation) {
        case 'email':
          return (
            <div>
              <InputAdornment position="start">
                <EmailOutlinedIcon fontSize='small' />
              </InputAdornment>
            </div>
          );

        case 'cell':
          return (
            <div>
              <InputAdornment position="start">
                <LocalPhoneOutlinedIcon fontSize='small' />
              </InputAdornment>
            </div>
          );

        case 'fax':
        case 'website':
          return (
            <div>
              <InputAdornment position="start">
                <LanguageOutlinedIcon fontSize='small' />
              </InputAdornment>
            </div>
          );


        default:
          return (
            <></>
          )
        // case 'fax':
        //   return (
        //     <div>
        //       <InputAdornment position="start">
        //         <LanguageOutlinedIcon  style={{fontSize: '15px'}}/>
        //       </InputAdornment>
        //     </div>
        //   );
      }
    }
  }
};

export const showPlaceHolder = (validations: any) => {
  if (null != validations && validations) {
    let validation: any;
    for ([validation] of Object.entries(validations)) {
      switch (validation) {
        case 'email':
          return "abc@gmail.com"
        case 'fax':
        case 'website':
          return "www.xyz.com"
        default:
          return ""
      }
    }
  }
}

export const bulidIconsForCurrencySymbols = (uiSettings: any) => {
  if (CommonUtils.isNotEmpty(uiSettings) && CommonUtils.isNotEmpty(uiSettings.prefixType)) {
    switch (uiSettings.prefixType.code) {
      case 'Indian Rupee':
      case 'Rupee':
      case 'rupee':
      case 'Rupees':
      case 'rupees':
      case "INR":
        return (
          <div>
            <InputAdornment position="start">
              <CurrencyRupeeIcon fontSize='small' sx={{
                color: (theme) => theme.palette.secondary.main
              }}
                style={{ fontSize: '15px' }} />
            </InputAdornment>
          </div>
        )
      case "GBP":
        return (
          <div>
            <InputAdornment position="start">
              <CurrencyPoundIcon fontSize='small' sx={{
                color: (theme) => theme.palette.secondary.main
              }}
                style={{ fontSize: '15px' }} />
            </InputAdornment>
          </div>
        );
      case "USD":
        return (
          <div>
            <InputAdornment position="start">
              <AttachMoneyIcon fontSize='small' sx={{
                color: (theme) => theme.palette.secondary.main
              }}
                style={{ fontSize: '15px' }} />
            </InputAdornment>
          </div>
        )
      case "FRF":
        return (
          <div>
            <InputAdornment position="start">
              <  CurrencyFrancIcon fontSize='small' sx={{
                color: (theme) => theme.palette.secondary.main
              }}
                style={{ fontSize: '15px' }} />
            </InputAdornment>

          </div>
        )
      case "XAF":
        return (
          <div>
            <InputAdornment position="start">
              <Typography sx={{
                color: (theme: any) => theme.palette.secondary.main
              }}
                style={{ fontSize: '15px' }}>&#8355;</Typography>
            </InputAdornment>
          </div>
        )
      case "GHS":
        return (
          <div>
            <InputAdornment position="start">
              <Typography sx={{
                color: (theme: any) => theme.palette.secondary.main
              }}
                style={{ fontSize: '15px' }}>&#8373;</Typography>
            </InputAdornment>
          </div>
        )
      case "GHC":
        return (
          <div>
            <InputAdornment position="start">
              <Typography sx={{
                color: (theme: any) => theme.palette.secondary.main
              }}
                style={{ fontSize: '15px' }}>GHC</Typography>
            </InputAdornment>
          </div>
        )
      default:
        // return <></>
        return (<InputAdornment position="start">
          <Typography sx={{
            color: (theme: any) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }}>
            <span dangerouslySetInnerHTML={{ __html: uiSettings.prefixType.code }}></span>
          </Typography>
        </InputAdornment>)
    }
  }
}

export const getCurrencyIcon = (symbol: any) => {
  if (symbol) {
    switch (symbol) {
      case 'Indian Rupee':
      case 'Rupee':
      case 'rupee':
      case 'Rupees':
      case 'rupees':
      case "INR":
      case '?': //this is because currency is comming as '?' need to remove after backend solution
        return (
          <CurrencyRupeeIcon fontSize='small' sx={{
            color: (theme) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }} />
        )
      case "GBP":
        return (
          <CurrencyPoundIcon fontSize='small' sx={{
            color: (theme) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }} />
        );
      case "USD":
        return (
          <AttachMoneyIcon fontSize='small' sx={{
            color: (theme) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }} />
        )
      case "FRF":
        return (
          <  CurrencyFrancIcon fontSize='small' sx={{
            color: (theme) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }} />
        )
      case "XAF":
        return (
          <Typography component='span' sx={{
            color: (theme: any) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }}>&#8355;</Typography>
        )
      case "GHS":
        return (
          <Typography component='span' sx={{
            color: (theme: any) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }}>&#8373;</Typography>
        )
      default:
        return (
          <Typography component='span' sx={{
            color: (theme: any) => theme.palette.secondary.main
          }}
            style={{ fontSize: '15px' }}>
            <span dangerouslySetInnerHTML={{ __html: symbol }} ></span>
          </Typography>
        )
    }
  }
}

export const FeedBackControl = (kagamiNumberModel: KagamiNumberModel, classes: any, fontProperties: any) => {
  const onChange = (value: any) => {
    validateNumberBox(kagamiNumberModel, value);
  }
  return <>
  <FormControl fullWidth>
    <FormLabel component="span" className={classes.controlLabel} style={{ color: fontProperties.labelFontColor }}>
      {' '}
      {kagamiNumberModel.controlDisplayName}
    </FormLabel>
    <StyledRating style={{
      
      width:'fit-content',
    }}
      name='highlight-selected-only'
      defaultValue={CommonUtils.isNotEmpty(kagamiNumberModel.controlValue) ? kagamiNumberModel.controlValue : null}
      IconContainerComponent={IconContainer}
      getLabelText={(value: number) => customIcons[value].label}
      highlightSelectedOnly
      onChange={(event: any, value: number | null) => {
        console.log('changed')
        validateNumberBox(kagamiNumberModel, value)
      }}
    />
  </FormControl>

  </>
}

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
   
  return <span {...other} style={{margin:'5px'}}>{customIcons[value].icon}</span>;
}

const StyledRating = styled(Rating)(({ theme }) => ({
  
  '& .MuiRating-iconFilled .MuiSvgIcon-root' : {
    
    transform:'scale(1.6)'
  }
}));