import { createState } from '@hookstate/core';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import _, { uniqueId } from 'lodash';
import React, { useState } from 'react';

import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { ReportConstant } from '../../../../view/ReportConstant/ReportConstant';
import { handlePopupButtonVisibility } from '../../../service/ReportFilterUtilService';
import { kagamiReportFilterStyle } from '../../../style/KagamiReportFilter';
import { ReportDateTimeFilterModel, ReportDateTimeFilterModelProps, useReportDateTimeModelState } from '../model/DateTimeFilterModel';
import { translateToLocalistaion } from '../../../../../process/service/PresentationService';

const useStyles = makeStyles(kagamiReportFilterStyle);

export const buildDateTimeFilterComponent = (reportDateTimeFilterModel: ReportDateTimeFilterModel, translations?: any) => {
  return <ReportDateTimeFilter key={uniqueId()} reportDateTimeFilterModel={reportDateTimeFilterModel} translations={translations}/>;
};

const ReportDateTimeFilter = (props: ReportDateTimeFilterModelProps) => {
  const classes = useStyles();
  const reportDateTimeFilterModel = props.reportDateTimeFilterModel;


    /**
   * dateTimeFilterSetting requires backend configuration,
   * we need to pass an object in "filterSetting" that contains filterName as a key and 
   * {from:{days: {some Integer value}, time: "{selected time}"},to:{days: {some Integer value}, time: "{selected time}"}}
   * as a value, days can be ranges from {-Integer Value} to {+Integer value} and time will always be 24 hours format.
   * Currently the application which is using this feature is DCM.
   * with this feature we can set nth days back and forth from todays date.
   */
  const dateTimeFilterSetting = reportDateTimeFilterModel.reportFilterModel?.filterSetting[
    reportDateTimeFilterModel.filterName
  ] ?? {
    from: {
      days: 0,
      time: '00:00:00'
    },
    to: {
      days: 0,
      time: '23:59:59'
    }
  };

  const dateTimeFormats = {
    originalFormat: "YYYY-MM-DD HH:mm:ss",
    zeroTimeFormat: `YYYY-MM-DD ${dateTimeFilterSetting.from.time}` ?? "YYYY-MM-DD 00:00:00",
    endTimeFormat: `YYYY-MM-DD ${dateTimeFilterSetting.to.time}` ?? "YYYY-MM-DD 23:59:59",
    displayFormat: "DD-MM-YYYY HH:mm:ss"
  }
  
  let translateWords: any = props?.translations;

  let defaultDate = _.compact(
    reportDateTimeFilterModel.reportFilterModel.selectedValue.get(reportDateTimeFilterModel.filterName)?.split(',') ??
      []
  );

  let splitDefaultDate: any = defaultDate.filter((val: any) => {
    return val !== 'NOPOP' && val !== 'undefined';
  });

  const dateTimeFilterState = useReportDateTimeModelState(createState(reportDateTimeFilterModel));
  reportDateTimeFilterModel.state = dateTimeFilterState;

  const dateString: any = {
    start:
      splitDefaultDate.length > 0 && dayjs(splitDefaultDate[0]).isValid()
        ? dayjs(splitDefaultDate[0]).format(dateTimeFormats.originalFormat)
        : dayjs().add(dateTimeFilterSetting.from.days, 'days').format(dateTimeFormats.zeroTimeFormat),  // btkm proj
    end:
      splitDefaultDate.length > 1 && dayjs(splitDefaultDate[0]).isValid()
        ? dayjs(splitDefaultDate[1]).format(dateTimeFormats.originalFormat)
        : dayjs().add(dateTimeFilterSetting.to.days, 'days').format(dateTimeFormats.endTimeFormat)
  };

  // set startDate date and last date
  const [fromDateTimeValue, setFromDateTimeValue] = useState<string | null>(
    dayjs(dateString.start).format(dateTimeFormats.zeroTimeFormat)
  );
  const [toDateTimeValue, setToDateTimeValue] = useState<string | null>(
    dayjs(dateString.end).format(dateTimeFormats.originalFormat)
  );
  const [selectedRadio, setSelectedRadio] = React.useState<string | null>(null);
  if (
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is_In_The_Range ||
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is_Not_In_The_Range
  ) {
    reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
      reportDateTimeFilterModel.filterName,
      dayjs(fromDateTimeValue).format(dateTimeFormats.originalFormat) +
        ',' +
        dayjs(toDateTimeValue).format(dateTimeFormats.originalFormat)
    );
    // if (!reportDateTimeFilterModel.reportFilterModel.fromDashboard) {
    //   handlePopupButtonVisibility(reportDateTimeFilterModel);
    // }

    return (
      <>
        <tr>
          <td style={{ color: '#007ecc' }}>
            {' '}
            {/* {reportDateTimeFilterModel.filterName} */}
            {translateToLocalistaion(translateWords, reportDateTimeFilterModel.filterName)}
            {!reportDateTimeFilterModel.filterConfig.optional ? (
              <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
            ) : (
              <></>
            )}
          </td>
          {/* <td style={{ color: '#007ecc' }}> {reportDateTimeFilterModel.filterRelationName}</td> */}
          <td style={{ color: '#007ecc' }}> {translateToLocalistaion(translateWords, reportDateTimeFilterModel.filterRelationName)}</td>
          <td>
            <table>
              <tbody>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <tr>
                    <td className="inner-table">{translateToLocalistaion(translateWords, "From")}:</td>
                    <td className="inner-table">
                      <DateTimePicker
                        views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                        open={dateTimeFilterState.fromCalendar}
                        onClose={() => {
                          dateTimeFilterState.setFromCalendar(false);
                        }}
                        maxDate={toDateTimeValue}
                        inputFormat={dateTimeFormats.displayFormat}
                        value={fromDateTimeValue}
                        onChange={(newValue: any) => {
                          if (newValue !== null) {
                            dateString.start = newValue;
                            setFromDateTimeValue(newValue);
                            // reportDateTimeFilterModel.isFromDateTimeChangedManually = true;
                            reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
                              reportDateTimeFilterModel.filterName,
                              dateString.start + ',' + dateString.end
                            );
                            if (dateString.end !== 'Invalid Date')
                              reportDateTimeFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(false);
                          } else {
                            dateString.start = 'Invalid Date';
                            setFromDateTimeValue(null);
                            reportDateTimeFilterModel.isFromDateTimeChangedManually = true;
                            reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
                              reportDateTimeFilterModel.filterName,
                              'NOPOP'
                            );
                            if (!reportDateTimeFilterModel.reportFilterModel.fromDashboard) {
                              handlePopupButtonVisibility(reportDateTimeFilterModel);
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            onClick={() => {
                              dateTimeFilterState.setFromCalendar(true);
                            }}
                          />
                        )}
                        // componentsProps={{
                        //   actionBar: {
                        //     actions: ['clear']
                        //   }
                        // }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="inner-table">{translateToLocalistaion(translateWords, 'To')} :</td>
                    <td className="inner-table">
                      <DateTimePicker
                        views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                        open={dateTimeFilterState.toCalendar}
                        onClose={() => {
                          dateTimeFilterState.setToCalendar(false);
                        }}
                        minDate={fromDateTimeValue}
                        inputFormat={dateTimeFormats.displayFormat}
                        value={toDateTimeValue}
                        onChange={(newValue: any) => {
                          if (newValue !== null) {
                            dateString.end = `${dayjs(newValue).format(dateTimeFormats.originalFormat)}`;
                            setToDateTimeValue(dayjs(dateString.end).format(dateTimeFormats.originalFormat));
                            reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
                              reportDateTimeFilterModel.filterName,
                              dateString.end + ',' + dateString.end
                            );
                            if (dateString.end !== 'Invalid Date')
                              reportDateTimeFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(false);
                          } else {
                            dateString.end = 'Invalid Date';
                            setToDateTimeValue(null);
                            reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
                              reportDateTimeFilterModel.filterName,
                              'NOPOP'
                            );
                            if (!reportDateTimeFilterModel.reportFilterModel.fromDashboard) {
                              handlePopupButtonVisibility(reportDateTimeFilterModel);
                            }
                            // reportDateFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(true);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            onClick={() => {
                              dateTimeFilterState.setToCalendar(true);
                            }}
                          />
                        )}
                        // componentsProps={{
                        //   actionBar: {
                        //     actions: ['clear']
                        //   }
                        // }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="inner-table" colSpan={2}>
                      <RadioGroup
                        row
                        name="select-date-radio-buttons-group"
                        defaultValue={reportDateTimeFilterModel.reportFilterModel['selectedRadio'] ?? ''}
                        value={selectedRadio}
                        onClick={(event: any) => {
                          if (
                            (CommonUtils.isNotEmpty(event.target.value) && event.target.value === 'today') ||
                            event.target.value === 'week' ||
                            event.target.value === 'month' ||
                            event.target.value === 'year'
                          ) {
                            setSelectedRadio(event.target.value);
                            let currentEndDate: any;
                            if (
                              reportDateTimeFilterModel.reportFilterModel['selectedRadio'] &&
                              reportDateTimeFilterModel.reportFilterModel['selectedRadio'] === event.target.value
                            ) {
                              currentEndDate = dayjs(dateString.start).startOf(event.target.value);
                            } else {
                              // currentEndDate = reportDateTimeFilterModel.isFromDateTimeChangedManually                              //   ?dayjs(dateString.end).startOf(event.target.value)
                              //   ?dayjs(dateString.end).startOf(event.target.value)
                              //   : dayjs(dateString.start).startOf(event.target.value);
                              // currentEndDate=dayjs(dateString.start).startOf(event.target.value);
                              if (event.target.value === 'today') {
                                currentEndDate = dayjs(dateString.end).startOf(event.target.value);
                                dateString.start = `${currentEndDate.format(dateTimeFormats.originalFormat)}`;
                                setFromDateTimeValue(dayjs(dateString.end).format(dateTimeFormats.zeroTimeFormat));
                              } else {
                                currentEndDate = dayjs(dateString.start).startOf(event.target.value);
                                dateString.start = `${currentEndDate.format(dateTimeFormats.originalFormat)}`;
                                setFromDateTimeValue(dayjs(dateString.start).format(dateTimeFormats.originalFormat));
                              }
                            }

                            if (
                              event.target.value !== 'today' &&
                              currentEndDate.endOf(event.target.value).isSameOrBefore(dayjs())
                            ) {
                              dateString.end = `${dayjs(dateString.start)
                                .endOf(event.target.value)
                                .format(dateTimeFormats.originalFormat)}`;
                              setToDateTimeValue(dayjs(dateString.end).format(dateTimeFormats.originalFormat));
                            } else {
                              dateString.end = `${dayjs().format(dateTimeFormats.originalFormat)}`;
                              setToDateTimeValue(dayjs(dateString.end).format(dateTimeFormats.originalFormat));
                            }
                            reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
                              reportDateTimeFilterModel.filterName,
                              dateString.start + ',' + dateString.end
                            );
                            if (!reportDateTimeFilterModel.reportFilterModel.fromDashboard) {
                              handlePopupButtonVisibility(reportDateTimeFilterModel);
                            }
                          }
                        }}
                      >
                        <FormControlLabel value="today" control={<Radio />} label={translateToLocalistaion(translateWords, "Today")} />
                        <FormControlLabel value="week" control={<Radio />} label={translateToLocalistaion(translateWords, "Week")} />
                        <FormControlLabel value="month" control={<Radio />} label={translateToLocalistaion(translateWords, 'Month')} />
                        <FormControlLabel value="year" control={<Radio />} label={translateToLocalistaion(translateWords, "Year")} />
                      </RadioGroup>
                    </td>
                  </tr>
                </LocalizationProvider>
              </tbody>
            </table>
          </td>
        </tr>
      </>
    );
  } else if (
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is ||
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is_Not ||
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is_After ||
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is_Before ||
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is_After_Or_Equal ||
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Is_Before_Or_Equal ||
    reportDateTimeFilterModel.filterRelationName === ReportConstant.Today
  ) {
    reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
      reportDateTimeFilterModel.filterName,
      dayjs(fromDateTimeValue).format(dateTimeFormats.originalFormat)
    );
    if (!reportDateTimeFilterModel.reportFilterModel.fromDashboard) {
      handlePopupButtonVisibility(reportDateTimeFilterModel);
    }
    return (
      <>
        <tr>
          <td>
            {' '}
            {/* {reportDateTimeFilterModel.filterName}{' '} */}
            {translateToLocalistaion(translateWords, reportDateTimeFilterModel.filterName)}
            {!reportDateTimeFilterModel.filterConfig.optional ? (
              <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
            ) : (
              <></>
            )}
          </td>
          {/* <td style={{ color: '#007ecc' }}> {reportDateTimeFilterModel.filterRelationName}</td> */}
          <td style={{ color: '#007ecc' }}> {translateToLocalistaion(translateWords, reportDateTimeFilterModel.filterRelationName)}</td>
          <td>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                open={dateTimeFilterState.fromCalendar}
                onClose={() => {
                  dateTimeFilterState.setFromCalendar(false);
                }}
                inputFormat={dateTimeFormats.displayFormat}
                value={fromDateTimeValue}
                // onChange={(selectedValue: any) => {
                //   dateString.start = selectedValue;
                //   setFromDateTimeValue(selectedValue);
                // }}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    dateString.start = dayjs(newValue).format(dateTimeFormats.originalFormat);
                    setFromDateTimeValue(dayjs(dateString.start).format(dateTimeFormats.originalFormat));
                    reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
                      reportDateTimeFilterModel.filterName,
                      dateString.start
                    );
                    reportDateTimeFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(false);
                  } else {
                    dateString.start = 'Invalid Date';
                    reportDateTimeFilterModel.reportFilterModel.selectedValue.set(
                      reportDateTimeFilterModel.filterName,
                      'NOPOP'
                    );
                    setFromDateTimeValue(null);
                    if (!reportDateTimeFilterModel.reportFilterModel.fromDashboard) {
                      handlePopupButtonVisibility(reportDateTimeFilterModel);
                    }
                    // reportDateTimeFilterModel.reportFilterModel.modalModel.state.setButtonDisabled(true);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    onClick={() => {
                      dateTimeFilterState.setFromCalendar(true);
                    }}
                  />
                )}
                // componentsProps={{
                //   actionBar: {
                //     actions: ['clear']
                //   }
                // }}
              />
            </LocalizationProvider>
          </td>
        </tr>
      </>
    );
  } else {
    return <></>;
  }
};
