import { State, useState } from '@hookstate/core';
import { ReportModalModel } from '../../../../../view/ReportModal/model/ReportModalModel';
export class  ReportSchedulerModel {
  isDataLoaded:boolean;
  reportList:any;
  reportModalModel:ReportModalModel;
  fetchData:boolean;
  allScheduler:any;
  state: any;
  constructor() {
    this.isDataLoaded = false;
    this.fetchData = false;
    this.reportModalModel = new ReportModalModel();
  }
}

export const useScheduleReportsState = (state: State<ReportSchedulerModel>) => {
  const reportSchedulerState = useState(state);

  return {
    get isDataLoaded() {
      return reportSchedulerState.isDataLoaded.get();
    },

    setDataLoaded(isDataLoaded: boolean) {
      reportSchedulerState.isDataLoaded.set(isDataLoaded);
    },

    get isDataFetched() {
      return reportSchedulerState.fetchData.get();
    },

    setFetchData(fetchData: boolean) {
      reportSchedulerState.fetchData.set(fetchData);
    },  

    // get getStyle() {
    //   return reportSchedulerState.style.get();
    // },

    // setStyle(style: any) {
    //   reportSchedulerState.style.set(style);
    // }
  };
};

export interface ReportSchedulerModelProps {
  reportSchedulerModel: ReportSchedulerModel;
  translations?:any;
}

