


export class ToastNotificationModel  {

    statusType: string;
    statusMessage :string;
    icon:any;
    timeOutDuration: number | undefined;
    toastId:string;
  constructor() {
    this.statusType = ''
    this.statusMessage = ''
    this.icon = null ;
    this.timeOutDuration = 0;
    this.toastId = ''
  }
}

 
export interface ToastNotificationModelProps {
    toastNotificationModel :ToastNotificationModel;
    toastProperties: any
}