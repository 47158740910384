import { Checkbox, } from '@mui/material';
import { useEffect, useState } from 'react';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { setActiveRowNode } from '../../service/GridService';
import checkboxEditor from '../editors/checkboxEditor';

  const checkboxCellRenderer = (props: any) => {
  const [checkedValue, setCheckedvalue] = useState(props.node.data[props.column.colId]);
   useEffect(()=>{
    setCheckedvalue(props.node.data[props.column.colId]);
  } , [props.node.data[props.column.colId] ,checkedValue]);

  const handleCheckBoxOnChangeCellRenderer = (e: any) => {
    if(CommonUtils.isNotEmpty(props.gridModel.activeRowNode) && props.gridModel.activeRowNode.data.id === props.data.id){
      props.node.data[props.column.colId] = e.target.checked;
      props.gridModel.activeRowNode.data[props.column.colId] = e.target.checked;
    setCheckedvalue(props.node.data[props.column.colId]);
    console.log(props);
    props.onCellValueChange(
      props.gridModel,
      props.gridModel.activeRowNode,
      props.attributePresentation.onChangeRequired,
      props.column.colId
   );
    }
    else if(props.gridModel && props.gridModel.isEditableEmbedList){
      props.node.data[props.column.colId] = e.target.checked;
      setActiveRowNode(props,props.gridModel,true);
      props.gridModel.activeRowNode.data[props.column.colId] = e.target.checked;
      setCheckedvalue(props.node.data[props.column.colId]);
      props.onCellValueChange(
        props.gridModel,
        props.gridModel.activeRowNode,
        props.attributePresentation.onChangeRequired,
        props.column.colId
      );
  }
  };
 return (
    <Checkbox
      disableRipple={props.attributePresentation.readOnly} style={{padding:'0'}}
      color={props.isEditableEmbedList ? 'primary' : 'secondary'}
      onChange={handleCheckBoxOnChangeCellRenderer}
      checked={checkedValue}
      disabled={!props.isEditableEmbedList}
       />
   );
};
export default checkboxCellRenderer;






