import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const reportsIconPopoverStyle = (theme: Theme) =>
  createStyles({
    root: {
      '& .createFolderComponent': {
        'width': '100%',
        '& .create-folder-before': {
          float: 'right',
          margin: '15px 30px',
          background: '#00856B'
        },
        '& .create-folder-after': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '15px 30px',
          margin: 'auto',
          '& .createBtn': {
            background: '#00856B',
            // '&:hover': {
            //   background: '#00856B',
            // }
          }
        }
      },
      '& .MoveReportToFolderTable': {
        '& table, tr': {
          border: '2px solid #dddddd',
          borderCollapse: 'collapse'
        },
        '& table': {
          'width': '95%',
          'margin': 'auto',
          '& thead': {
            '& tr': {
              'width': '100%',
              '& td': {}
            }
          },
          '& tbody': {
            '& tr': {
              'width': '100%',
              '& .folders': { width: '20%', color: '#565656' },
              '& .reports': {
                'width': '20%',
                '& .container': { width: '100%', display: 'flex', alignItems: 'center' }
              },
              '& .existingReports': { width: '60%' }
            }
          }
        }
      }
    },
    ReportDropdownHeader: {
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'gap': '2px',
      'background': '#00856B',
      'color': '#fff',
      'width': '100px',
      'height': '33px',
      'cursor': 'pointer',
      'transition': '0.2s ease-in',
      'margin': 'auto 8px',
      'borderRadius': '4px',
      '&:hover': {
        background: '#00856Bdd',
        color: '#fff'
      }
    },
    ReportTitleheader: {
      fontSize: '0.8rem'
    },
    ReportDropdownLoader: {
      padding: '2px',
      width: '280px'
    },
    ReportDropdownContent: {
      'padding': '2px',
      'width': '280px',
      '& .report-list': {
        '& .MuiTreeItem-content': {
          'color': '#6ebe44',
          '&:hover': {
            color: '#4db8ff'
          }
        },
        '& .MuiTreeItem-label': {
          'color': '#6ebe44',
          '&:hover': {
            color: '#4db8ff'
          }
        }
      }
    },

    AddButtonPopupModel: {
      'background': '#00856B',
      '&:hover': {
        color: '#fff',
        background: '#00856B'
      }
    },
    CreateFolderPopup: {
      'margin': '3px',
      'right': '2px',
      'color': '#0000008a',
      'borderRadius': '10px',
      '&:hover': {
        backgroundColor: '#e9e9e9'
      }
    },
    ListOfReportAndFolder: {
      'minHeight': theme.spacing(2.5),
      'maxHeight': theme.spacing(40),
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        height: theme.spacing(1)
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.4)'
      },
      '&::-webkit-scrollbar-track': {
        background: 'hsla(0,0,0,.2)'
      }
    }
  });
