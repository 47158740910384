import Typography from "@mui/material/Typography";
import { CommonUtils } from "../../../utils/CommonUtils";

export const BuildServerError = (error: any) => {
  let errorMessage: any[] = ['Oops! Something went wrong'];
  if (error['error'] != null) {
    if (error['error']['DEFAULT_ERROR_MESSAGE'] && error['error']['DEFAULT_ERROR_MESSAGE'] !== null) {
      // errorMessage[0] = error['error']['DEFAULT_ERROR_MESSAGE'];
      // errorMessage[0] = ''
      if(CommonUtils.isNotEmpty(error['error']['BV_000'])) {errorMessage[0] = error['error']['BV_000']}
      else if(CommonUtils.isNotEmpty(error['error']['DATAINTEGRITYVIOLATIONEXCEPTION'])) {
        errorMessage[0] = error['error']['DATAINTEGRITYVIOLATIONEXCEPTION']
      }
      else if(CommonUtils.isNotEmpty(error['error']['CUSTOM_ERROR_MESSAGE'])){
        errorMessage[0] = error['error']['CUSTOM_ERROR_MESSAGE'];
      }else if(CommonUtils.isNotEmpty(error['error']['DEFAULT_ERROR_MESSAGE'])){
        // errorMessage.push(error['error']['DEFAULT_ERROR_MESSAGE']);
      }
    } else if (error['error']['BUSINESS_VALIDATION_FAILED'] && error['error']['BUSINESS_VALIDATION_FAILED'] !== null) {
      errorMessage[0] = error['error']['BUSINESS_VALIDATION_FAILED'];
    } else if (error['error']['INVALIDATE'] && error['error']['INVALIDATE'] !== null) {
      errorMessage[0] = error['error']['INVALIDATE'];
    } else if (error['error']['SYS-000'] && error['error']['SYS-000'] !== null) {
      errorMessage[0] = error['error']['SYS-000'];
    }
    else if(error['error'] && error['error']['BCV.000']){
      errorMessage[0] = error['error']['BCV.000']
    }
    else if(error['error'] && error['error']['BV_00']){
      errorMessage[0] = error['error']['BV_00']

    }
  } else if (error['stackTrace'] && error['stackTrace'] !== null) {
    errorMessage[0] = error['stackTrace'];
  }
  else if(error['_errorMsg']){
    errorMessage[0] = error['_errorMsg']
  }

  return <>{errorMessage.map((message : any) => <Typography variant='body1' style={{margin: '0', fontSize:'1.15rem'}}>{message}</Typography>
    // <ul style={{ listStyle: 'none'}}>
    //   <li>{message}</li>
    // </ul> 
    )}
     </>;
};

export const BuildClientError = (error: any) => {
  let errorMessage: string = 'Unexpected error';
  if (error['stackTrace'] !== null) {
    errorMessage = error['stackTrace'];
  }
  return <div>{errorMessage} </div>;
};
