import { Grid } from '@mui/material';
import { buildModal } from '../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { buildPopover } from '../../../../../../../../../../../../components/popover/controller/KagamiPopoverController';
import { KagamiPopoverModel } from '../../../../../../../../../../../../components/popover/model/KagamiPopoverModel';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { BuildNonFieldPresentations } from '../../../../form/builder/NonFieldPresentationBuilder';
import { retrieveListColumns } from '../../ListBuilder';
import { KagamiCardViewModel } from '../model/KagamiCardViewModel';
import { buildCard } from './card/controller/KagamiCardController';
import { KagamiCardModel } from './card/model/KagamiCardModel';

export const buildCards = (cardViewModel: KagamiCardViewModel) => {
  const cardComponents: any = [];
  let visibleAttributes = retrieveListColumns(cardViewModel);
  let dataRecords: any =[];
   if(cardViewModel.detailedObjects != undefined){
    if(cardViewModel.detailedObjects.hasOwnProperty([cardViewModel.mainEntityId])){
      dataRecords = cardViewModel.detailedObjects.hasOwnProperty([cardViewModel.mainEntityId])? cardViewModel.detailedObjects[cardViewModel.mainEntityId] : [];
     }else{
       dataRecords =  cardViewModel.detailedObjects ? cardViewModel.detailedObjects :[]
     }
   }else {
     dataRecords =[];
   };
  let popoverModel = new KagamiPopoverModel();
  // let popoverComponent = buildPopover(popoverModel);
  let cardModel: KagamiCardModel;
   let cardcomponents =  CommonUtils.isNotEmpty(dataRecords) ? dataRecords?.map((dataRecord:any) =>{
    cardModel = new KagamiCardModel(dataRecord , cardViewModel.presentation, visibleAttributes);

    cardModel.popoverModel = popoverModel;
      return <SingleCard key={cardModel.dataRecord.id} cardModel={cardModel}/>;
  }) : []
  if(cardcomponents.length > 0){
    cardComponents.push(cardcomponents);
    return cardComponents;
  }else{
    return cardComponents;
  }
};
const SingleCard = (props:any) => {
return (
  <>
  {buildCard(props.cardModel)}
  </>
)
}
export const CardView = (props: any) => {
  let cards = buildCards(props.kagamiCardViewModel);
  if(cards.length >0){
    return (<>
    <div style={{marginLeft:'14px'}}>
    {buildModal(props.kagamiCardViewModel.kagamiModalModel)}
    <BuildNonFieldPresentations
                key="nonFieldPresentation"
                kagamiGridModel={props.kagamiCardViewModel}
                isPickAttributeButtonVisible={false}
       />
    </div>
      <Grid container spacing={1} direction="row" style={{paddingLeft: '10px', paddingRight:'6px'}}>
        {cards[0].map((card: any) => {
          return card;
        })}
      </Grid>

      </>
    );
  }else{
     return <div style={{display:'flex', height: '200px', alignItems: 'center', justifyContent: 'center'}}>
       <p style={{color:'#95c221', paddingLeft:'8px'}}>No Data Found</p>
     </div>
  }
};
