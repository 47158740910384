import { State, useState } from "@hookstate/core";

export class LedgerDetailsModel{
    headerDeatils:any;
    nestedFinanceRecord:any;
    otherDeatils:any;
    isDataLoaded:boolean;
    state:any;
    ledgerInputs :any;
    constructor(){
        this.headerDeatils = {};
        this.nestedFinanceRecord = [];
        this.otherDeatils = {};
        this.isDataLoaded = false;
        this.ledgerInputs = {}
    }
}

export interface LedgerDetailsModelProps{
    ledgerDetailsModel:LedgerDetailsModel
}

const wrapLedgerDetailsModel = (state: State<LedgerDetailsModel>) => {
    const ExcelState = state;
    return {
        get isDataLoaded(){
            return ExcelState.isDataLoaded.get();
        },

        setDataLoaded(isDataLoaded:boolean){
            ExcelState.isDataLoaded.set(isDataLoaded)
        }
    }
}

export const useLedgerDetailsModelState = (state:State<LedgerDetailsModel>) => {
    return wrapLedgerDetailsModel(useState(state));
}