import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class StackedBarReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  isDataLoaded:boolean;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.isDataLoaded = false;
  }
}
export interface StackedBarReportModelProps {
  stackedBarReportModel: StackedBarReportModel;
}
export const useStackedBarReportModelState = (state: State<StackedBarReportModel>) => {
  let stackedBarReportState = useState(state);
  return {
    reportConfig() {
      stackedBarReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
        stackedBarReportState.reportData.set(reportContent);
    },

    get isDataLoaded(){
      return stackedBarReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      stackedBarReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
