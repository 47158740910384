import { State, useState } from '@hookstate/core';
import { KagamiControlModel } from '../../model/kagamiControlModel';

export class KagamiTextModel extends KagamiControlModel {
  forNumber: any;
  createState: any;
  uiSettings: any;
  hasPasswordChanges : boolean;
  isContact:boolean;
  dialCode : string;
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any,
    onChangeRequired: boolean,
    validation: any,
    pickAttribute: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    state: any,
    createState: State<KagamiTextModel> | null,
    policyMap: any,
    presentationPolicy : any,
    businessPolicy : any,
    initiallyReadOnly: boolean,
    initiallyVisible : boolean,
    uiSettings: any,
    formDataIdentifier : string, 
    isGridField : boolean,
    isEmbddedField:boolean,
  ) {
    super(
      controlIdentifier,
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField,
    );
    this.uiSettings = uiSettings;
    this.previouseControlValue = controlValue || '';
    this.hasPasswordChanges = false;
    this.isContact = false;
    this.dialCode = '91';
  }
}

export interface KagamiTextFieldProps {
  kagamiTextModel: KagamiTextModel;
}

export const wrapKagamiTextModelState = (state: State<KagamiTextModel>) => {
  let controlState = state;

  return {
    get isMandatory() {
      return controlState.mandatory.get();
    },

    setMandatory(isMandatory: boolean) {
      controlState.mandatory.set(isMandatory);
    },

    get isVisible() {
      return controlState.visible.get();
    },

    setVisible(isVisible: boolean) {
      controlState.visible.set(isVisible);
    },

    get isReadOnly() {
      return controlState.readonly.get();
    },

    setReadonly(isReadonly: boolean) {
      controlState.readonly.set(isReadonly);
    },

    get errorMessage() {
      return controlState.errorMessage.get();
    },

    setErrorMessage(value: string) {
      controlState.errorMessage.set(value);
    },

    get controlValue() {
      return controlState.controlValue.get();
    },

    setControlValue(value: any) {
      controlState.controlValue.set(value);
    },

    get hasPasswordChanges(){
      return controlState.hasPasswordChanges.get();
    },
    setPasswordChanges(value:any){
      controlState.hasPasswordChanges.set(value)
    }
  };
};

export const useKagamiTextModelState = (state: State<KagamiTextModel>) => wrapKagamiTextModelState(useState(state));

export const accessKagamiTextModelState = (state: State<KagamiTextModel>) => wrapKagamiTextModelState(state);
