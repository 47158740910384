import list from '../../../../../../../../../assets/images/report_icons/list.png';
import pivotchart from '../../../../../../../../../assets/images/report_icons/pivotchart.png';
import linechart from '../../../../../../../../../assets/images/report_icons/linechart.png';
import kpi from '../../../../../../../../../assets/images/report_icons/kpi.png';
import piechart from '../../../../../../../../../assets/images/report_icons/piechart.png';
import generalledger from '../../../../../../../../../assets/images/report_icons/generalledger.png';
import blreport from '../../../../../../../../../assets/images/report_icons/blreport.png';
import tileview from '../../../../../../../../../assets/images/report_icons/tileview.png';
import doughnut from '../../../../../../../../../assets/images/report_icons/doughnut.png';
import tilecharts from '../../../../../../../../../assets/images/report_icons/tilecharts.png';
import trialblreport from '../../../../../../../../../assets/images/report_icons/trialblreport.png';
import columnchart from '../../../../../../../../../assets/images/report_icons/columnchart.png';
import customerageing from '../../../../../../../../../assets/images/report_icons/customerageing.png';
import bankaccount from '../../../../../../../../../assets/images/report_icons/bankaccount.png';
import customerledger from '../../../../../../../../../assets/images/report_icons/customerledger.png';
import plreport from '../../../../../../../../../assets/images/report_icons/plreport.png';
import vendorledger from '../../../../../../../../../assets/images/report_icons/vendorledger.png';
import funnel from '../../../../../../../../../assets/images/report_icons/funnel.png';
import vendorageing from '../../../../../../../../../assets/images/report_icons/vendorageing.png';
import subledger from '../../../../../../../../../assets/images/report_icons/subledger.png';
import cashledger from '../../../../../../../../../assets/images/report_icons/cashledger.png';
import stackedcolumnchart from '../../../../../../../../../assets/images/report_icons/stackedcolumnchart.png';
import gaugechart from '../../../../../../../../../assets/images/report_icons/gaugechart.png';
import calendarchart from '../../../../../../../../../assets/images/report_icons/calendarchart.png';
import pettycash from '../../../../../../../../../assets/images/report_icons/pettycash_account.png';
import stackedbarchart from '../../../../../../../../../assets/images/report_icons/stackedbarchart.png';
import barchart from '../../../../../../../../../assets/images/report_icons/barchart.png';
import multicolumnchart from '../../../../../../../../../assets/images/report_icons/multicolumnchart.png';
import vendor_payment_due_list from '../../../../../../../../../assets/images/report_icons/vendor_payment_due_list.png';
import heatchart from '../../../../../../../../../assets/images/report_icons/heatchart.png';
import bellcurve from '../../../../../../../../../assets/images/report_icons/bellcurve.png';

export const getReportsIcons:any = {
    "Sub Ledger":subledger, 
    "Tilecharts":tilecharts, 
    "Bank Account":bankaccount, 
    "BarChart": barchart,
    "TrialBLReport":trialblreport, 
    "List":list, 
    "BLReport":blreport, 
    "LineChart":linechart, 
    "Bank Ledger":subledger, 
    "Customer Ledger":customerledger,
    "doughnut": doughnut, 
    "PLReport":plreport, 
    "Vendor Ledger":vendorledger, 
    "KPI":kpi, 
    "MultiColumnChart":multicolumnchart,
    "PivotChart":pivotchart, 
    "Pivot":pivotchart,
    "Funnel":funnel, 
    "Vendor Ageing":vendorageing, 
    "Customer Ageing":customerageing, 
    "TileView":tileview, 
    "General Ledger":generalledger, 
    "PieChart":piechart, 
    "Cash Ledger":cashledger, 
    "Cash Account": cashledger,
    "ColumnChart":columnchart,
    "StackedcolumnChart":stackedcolumnchart,
    "StackedbarChart": stackedbarchart,
    "GaugeChart":gaugechart,
    "CalendarChart":calendarchart,
    "PivotUi":pivotchart,
    "Pettycash Account":pettycash,
    "Vendor Payment Due List":vendor_payment_due_list,
    "Heatchart":heatchart,
    "matrixjoin":heatchart,
    "BellCurve": bellcurve,
}