import { KagamiMatrixFormModel, KagamiMatrixFormProps } from "../model/KagamiMatrixFormmodel";
import { useState } from "react";
import styles from "../style/Kagamimatrix.module.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
export const buildMatrixFormPresentationComponent = (matrixFormModel: KagamiMatrixFormModel) => {
    return (
        <KagamiMatrixFormPresentation
            matrixModel={matrixFormModel}
        />
    );
};

const KagamiMatrixFormPresentation = (props: KagamiMatrixFormProps) => {

    const top100Films = [
        { label: "The Shawshank Redemption", year: 1994 },
        { label: "The Godfather", year: 1972 },
        { label: "The Godfather: Part II", year: 1974 },
        { label: "Monty Python and the Holy Grail", year: 1975 },
      ];
      
        const [rows, setRows] = useState([{}]);
      
        const addRowHandler = (id: number) => {
          let array = [...rows];
          array.splice(id, 0, {});
          console.log("added array after", id, array);
          setRows(array);
        };
      
        const removeRowHandler = (id: number) => {
          let array = [...rows];
          array.splice(id, 1);
          console.log("Removed array", id);
          setRows(array);
        };
      
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.projectContainer}>
          <Typography variant="h6" component="h6" color="#65A6D9">
            Project
          </Typography>
        </div>
        <div className={styles.projectActivityContainer}>
          <Typography variant="h6" component="h6" color="#65A6D9">
            Project Activity
          </Typography>
        </div>
        <div className={styles.dateContainer}>
          <Typography variant="h6" component="h6" color="#65A6D9">
            Date
          </Typography>
        </div>
        <div className={styles.actionContainer}></div>
      </div>

      {rows.map((item, i) => {
        return (
          <div key={i} className={styles.bodyContainer}>
            <div className={styles.projectContainer}>
              <Autocomplete
                disablePortal
                options={top100Films}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search" />
                )}
              />
            </div>
            <div className={styles.projectActivityContainer}>
              <Autocomplete
                disablePortal
                options={top100Films}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search" />
                )}
              />
            </div>
            <div className={styles.dateContainer}></div>
            <div className={styles.actionContainer}>
              <AddIcon
                className={styles.add}
                onClick={() => addRowHandler(i)}
              />
              <RemoveIcon
                className={styles.remove}
                onClick={() => removeRowHandler(i)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}




