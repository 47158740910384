import { createState } from '@hookstate/core';
import React, { useEffect } from 'react';
import {
  getEachReportMapping,
  getOptionsData,
  havePinnedReports,
  pinnedReportsOnSave
} from '../controller/PinnedReportsController';
import {
  PinnedReportsModel,
  PinnedReportsModelProps,
  usePinnedReportState
} from '../Model/PinnedReportsModel';
import { setPinnedRoleAndReportData } from '../service/PinnedReportsService';
import { PinnedReportsView } from '../builder/ui/PinnedReportsView';
import makeStyles from '@mui/styles/makeStyles';
import { Button, CircularProgress } from '@mui/material';
import { KagamiReportHeaderModelStyle } from '../../ReportHeader/styles/ReportHeaderStyles';
import { PinnedReportViewModel } from '../builder/model/PinnedReportViewModel';
import { translateToLocalistaion } from '../../../../../../process/service/PresentationService';

export const buildPinnedReportsComponent = (pinnedReportsModel: PinnedReportsModel, translations: any) => {
  return <PinnedReports key={Math.random()} pinnedReportsModel={pinnedReportsModel} translations={translations} />;
};

export const PinnedReports = (props: PinnedReportsModelProps) => {
  const classes = makeStyles(KagamiReportHeaderModelStyle)();
  const [disable, setDisable] = React.useState(true);
  props.pinnedReportsModel.setDisableSave = setDisable;


  return (
    <div className={classes.root}>
      <h4 className={classes.ReportsHeaderName}>{translateToLocalistaion(props.translations, "Pin Reports")}</h4>
      <table>
        <thead>
          <tr>
            <th>{translateToLocalistaion(props.translations, "Role Name")}</th>
            <th>{translateToLocalistaion(props.translations, "Reports")}</th>
            <th>{translateToLocalistaion(props.translations, "Pinned Reports")}</th>
          </tr>
        </thead>
        <tbody>
          <LoadPinnedReportData pinnedReportsModel={props.pinnedReportsModel} translations={props.translations}/>
        </tbody>
      </table>

      <Button
        variant="contained"
        color="success"
        disabled={disable}
        style={{ position: 'fixed', bottom: '0px', right: '57px', width: '125px' }}
        onClick={() => {
          pinnedReportsOnSave(props.pinnedReportsModel,setDisable);
        }}
      >
        {translateToLocalistaion(props.translations, "Save")}
      </Button>
    </div>
  );
};


const LoadPinnedReportData = (props: PinnedReportsModelProps) => {
  let pinnedReportRows: any[] = [];
  const pinnedReportsModel = props.pinnedReportsModel;
  const pinnedReportState = usePinnedReportState(createState(pinnedReportsModel));
  pinnedReportsModel.state = pinnedReportState;

  useEffect(() => {
    setPinnedRoleAndReportData(pinnedReportsModel);
  }, []);

  let allReportIdAndConfig: any = React.useMemo(() => {
    let eachReportIdAndConfig: any = {};
    props.pinnedReportsModel.dbReportList.map((reportConfig: any) => {
      eachReportIdAndConfig[reportConfig.id] = reportConfig;
    });
    return eachReportIdAndConfig;
  }, []);

  if (!pinnedReportState.isDataLoaded) {
    return <CircularProgress sx={{ marginTop: '4%', marginLeft: '140%' }} color="secondary" />;
  } else {
    pinnedReportRows = pinnedReportsModel.dbRolesList.map((rolename: any, index: number) => {
      let pinnedReportViewModel = new PinnedReportViewModel();
      pinnedReportViewModel.name = rolename;
      pinnedReportViewModel.reportIdMapWithConfig = allReportIdAndConfig;
      pinnedReportViewModel.roleReportsMapping = getEachReportMapping(rolename);
      pinnedReportViewModel.allSelectedData = pinnedReportsModel.allSelectedData;
      pinnedReportViewModel.pinnedReports = havePinnedReports(rolename, allReportIdAndConfig);
      pinnedReportViewModel.options = getOptionsData(allReportIdAndConfig, pinnedReportViewModel);
      return <PinnedReportsView key={`pinnedRreports-${index}`} pinnedReportViewModel={pinnedReportViewModel} translations={props.translations}/>;
    });
    return <>{pinnedReportRows}</>;
  }
};