import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiEmbeddedModalStyle = (theme: Theme) =>
createStyles({
    
    modalTitle: {
      padding: theme.spacing(0.5)
    },
  
    modalFooter: {
      padding: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
    },
  
    formlabel: {
      textAlign: 'start',
      padding: theme.spacing(1),
      fontSize: theme.typography.h6.fontSize,
      // color: theme.palette.primary.dark
      color: theme.palette.primary.dark
    },
    embedModalHeader: {
      display: 'flex', 
      justifyContent: 'space-between',
    },
    nonfields_container: {
      display:'flex',
      flexDirection: 'column',
      '& button' : {
        marginLeft: 'auto !important',
      }
    },

  });