import { hideLoader, showLoader } from "../../../../../../../../../components/loader/controller/KagamiLoaderController";
import { SystemConstants } from "../../../../../../../../constants/SystemConstants";
import { APIService } from "../../../../../../../../service/ApiService";
import { getReportFolderURL, getReportListURL, getUpdateFolderDetailsForReportsURL } from "../../../../../../../../service/URLService";
import { CommonUtils } from "../../../../../../../../utils/CommonUtils";
import { buildtoastNotification } from "../../../../../ToastNotification/controller/ToastNotificationController";
import { ParentId, ReportLandingPageModel } from "../model/ReportsLandingPageModel";

export const extractAndSetReportAndFolderData = (reportLandingPageModel:ReportLandingPageModel) => {
  showLoader();
  getReportsAndFolderData().then((response:any)=>{
    hideLoader();
    if(Array.isArray(response)){
      reportLandingPageModel.reportList = removeDuplicateReport(response[0]?.data.respObject) ?? [];
      reportLandingPageModel.folderList = response[1]?.data.respObject ?? [];
      reportLandingPageModel.translations = response[0]?.data?.otherProperties?.translation;

      // for reports
      let newReportList:any[] = [];
      reportLandingPageModel.reportList.map((reportConfig:any)=>{
        if(CommonUtils.isEmpty(reportConfig['folderId'])){
          newReportList.push(reportConfig);
        }
      })
      reportLandingPageModel.newReportList = newReportList;

      //for folders
      reportLandingPageModel.newFolderList = reportLandingPageModel.folderList ?? [];
      reportLandingPageModel.folderTiles[ParentId.id] = reportLandingPageModel.folderList ?? [];

      // after setting data rerendering landing page
      reportLandingPageModel.state.setDataLoaded(true);
    }
  }).catch((error:any)=>{
    hideLoader()
    console.log('getting error while extracting data from backend', error);
  });
}

export const getReportsAndFolderData = async () => {
  return await Promise.all([getReportsByRoleData(), getReportFolderStructureData()]);
}

export const getReportsByRoleData = async () => {
  return await APIService.getData(SystemConstants.GET, getReportListURL(), {});
};

export const getReportFolderStructureData = async () => {
  return await APIService.getData(SystemConstants.GET, getReportFolderURL(), {});
};

export const updateFolderDetailsForReport = async (listOfReportsObject: any[]) => {
  await APIService.getData(SystemConstants.POST, getUpdateFolderDetailsForReportsURL(), listOfReportsObject)
    .then((response: any) => {
      buildtoastNotification('success', 'Updated Successfully' ,0);
    })
    .catch((err: any) => {
      buildtoastNotification('error', 'Failed to update' ,0);
      console.log(err);
    });
};

const removeDuplicateReport = (reportList:any[]) => {
  const removeDuplicate:any[] = [];
  reportList.forEach((reportConfigSummary:any)=>{
    if(removeDuplicate.find((rcs:any)=>{
      return reportConfigSummary['id'] === rcs['id'];
    })===undefined){
      removeDuplicate.push(reportConfigSummary);
    }
  }) 
  return removeDuplicate
}

// old code

// export const getReportLoaded = async (reportLandingPageModel:ReportLandingPageModel) => {
//     showLoader()
//     await APIService.getData(SystemConstants.GET, getReportListURL(),{}).then((response: any) => {
//         // const reportList: any[] = [];
//         // response.data?.respObject?.forEach((ele: any)=>{ 
//         //   let reportData = reportList.find((o:any)=>o.id===ele.id)
//         //   if(reportData === undefined){
//         //     reportList.push(ele);
//         //   }
//         // })
//         // reportLandingPageModel.reportList = reportList; 
//         reportLandingPageModel.reportList = response.data?.respObject ?? [];
//         let newReportList:any[] = [];
//         reportLandingPageModel.reportList.map((reportConfig:any)=>{
//           if(reportConfig['folderId']===undefined){
//             newReportList.push(reportConfig);
//           }
//         })
//         reportLandingPageModel.newReportList = newReportList;
//         getReportFolderStructure(reportLandingPageModel);
//       })
//       .catch((error: any) => {
//         hideLoader()
//         console.log('Failed to load report',error);
//       });
// }

// export const getReportFolderStructure = async (reportLandingPageModel:ReportLandingPageModel) => {
//     await APIService.getData(SystemConstants.GET, getReportFolderURL(),{}).then((response: any) => {
//         hideLoader()
//         reportLandingPageModel.folderList = response.data.respObject;
//         reportLandingPageModel.newFolderList = reportLandingPageModel.folderList ?? [];
//         reportLandingPageModel.folderTiles[ParentId.id] = reportLandingPageModel.folderList ?? [];
//         reportLandingPageModel.state.setDataLoaded(true);
//       })
//       .catch((error: any) => {
//         hideLoader()
//         console.log('Failed to load report folder structure');
//       });
// }
