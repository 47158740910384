import { State, useState } from '@hookstate/core';

export class KagamiPopoverModel {
  showPopover: boolean;
  popoverPosition: any;
  showIcon: boolean;
  selectedVisualAttributes: any;
  child: any;
  target: SVGSVGElement | HTMLButtonElement | null;
  state: any;
  transactionId?: string | any;

  constructor(transactionId?: string) {
    this.showPopover = false;
    this.showIcon = true;
    this.target = null;
    this.child = null;
    this.selectedVisualAttributes = null;
    this.transactionId = transactionId;
    this.popoverPosition = {
      slide: '',
      transitionNeeded: false
    };
  }
}

export interface PopoverModelProps {
  popoverModel: KagamiPopoverModel;
}

export const wrapKagamiPopoverState = (state: State<KagamiPopoverModel>) => {
  let popoverState = state;
  return {
    get popoverVisible() {
      return popoverState.showPopover.get();
    },
    setPopoverStatus(showpopover: boolean) {
      popoverState.showPopover.set(showpopover);
    },
    get popoverIconVisible() {
      return popoverState.showIcon.get();
    },
    setPopoverShowIcon(showIcon: boolean) {
      popoverState.showIcon.set(showIcon);
    },
    get popoverTarget() {
      return popoverState.target.get();
    },
    setPopoverTarget(target: any) {
      popoverState.target.set(target);
    },
    get popoverPosition() {
      return popoverState.popoverPosition.get();
    },
    setPopoverPosition(slide: string, transitionNeeded: boolean) {
      popoverState.popoverPosition.set({
        slide: slide,
        transitionNeeded: transitionNeeded
      });
    }
  };
};
export const useKagamiPopoverState = (state: State<KagamiPopoverModel>) => wrapKagamiPopoverState(useState(state));
