import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiGlobalSearchStyle = (theme: Theme) =>
  createStyles({
    root: {
      'backgroundColor': theme.palette.background.paper,
      '& .MuiInput-root': {
        paddingRight: '0 !important'
      }
    },
    globalbreakpoint: {
      top: '20%',
      left: '50%',
      borderRadius: '6px',
      transform: 'translate(-50%)',

      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },
    globalSearchOptions: {
      '& li': {
        fontSize: `${theme.typography.subtitle2.fontSize} !important`
      }
    },
    globalSearchClose: {
      'position': 'absolute',
      'top': '-7px',
      'right': '-7px',
      'width': '18px',
      'height': '18px',
      'padding': '1px',
      'backgroundColor': theme.palette.background.paper,
      'borderRadius': '100px',
      'boxShadow': '0 3px 10px 2px rgb(0 0 0 / 0.2);',
      'cursor': 'pointer',
      '&:hover': {
        height: '20px',
        color: theme.palette.error.main
      }
    },
    globalSearchOptionsFilterData: {
      'minHeight': theme.spacing(2.5),
      'maxHeight': theme.spacing(38),
      'overflow': 'auto',
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        height: theme.spacing(1)
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.4)'
      },
      '&::-webkit-scrollbar-track': {
        background: 'hsla(0,0,0,.2)'
      }
    },
    filterOptions: {
      width: '100%',
      padding: '0px !important',
      float: 'left',
      minHeight: '1px',
      position: 'relative',
      cursor: 'pointer'
    },
    filterOptionsFirstTag: {
      fontWeight: 600,
      color: '#3c3c3c',
      padding: 2,
      fontSize: theme.typography.subtitle2.fontSize
    },
    highlightedEnterValue:{
      // color:'white',
      background:'rgb(255 , 241 ,0)',
      fontWeight:700,
      fontSize:'14px'
    },
    filterOptionsSecondTag: {
      margin: 0,
      padding: 2,
      color: '#3c3c3c',
      fontSize: theme.typography.subtitle2.fontSize
    },
    globalList: {
      'width': '100%',
      'padding': '0 !important',
      // MuiListItemButton-root
      '& .MuiListItemButton-root': {
        padding: '0 !important'
      }
    },
    selected: {
      backgroundColor: '#007ecc !important',
      color: 'white !important'
    }
  });
