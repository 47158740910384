
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiFormStyle = (theme: Theme) =>
    createStyles({
        root: {



          '& .MuiOutlinedInput-root': {
           ' &.Mui-focused fieldset': {
              // borderColor:theme.palette.primary.main,
              // borderWidth: '0.8px',
            }
          },
          '& .MuiInputLabel-root': {
            // ' &.Mui-focused': {
            //    color: theme.palette.primary.main
            //  }
           },
         display: 'flex',
            // backgroundColor: theme.palette.background.default,
            background: theme.palette.background.defaultBlue,

            // background: theme.palette.common.white,
            flex: '1',
            marginTop: '2px',
            borderRadius: theme.spacing(1),
            flexDirection: 'column',
            overflowY: 'auto',
            // border:'1px solid #ebebeb',
            // "& .css-vzo399-MuiFormLabel-root-MuiInputLabel-root":{
            //   transform: 'translate(11px, -8px) scale(0.7)'
            // },
            "& div": {
                "& h6":{
                    padding: 0, 
                    color:theme.palette.secondary.main,
                    fontSize:'1.4rem',
                }
            },
            '& .row': { 
              display: 'flex',
              flexWrap: 'wrap',  
              '& .MuiFormHelperText-root': {
                marginTop: '-3px', 
                marginLeft:'0',
                fontSize:'0.7rem'
              }
            },
            '& .row> div': {
              // minHeight: '72px',
            },
            '& .row>*': {
              width: '100%',
              maxWidth: "100%",
              padding: `0 ${theme.spacing(0.5)}`,
              // margin: `${theme.spacing(1)} 0`,
              boxSizing: 'border-box'
            },
            '& .row>.row': {
              padding: '0',
              margin: '0'
            },
            '& .row.border_one > .col_12 > .row > .border_two':{
              border: `1px solid ${theme.palette.info.light}`

            },
            '& .row.border_one > .col_12':{
              border: `1px solid ${theme.palette.info.light}`
            },
            '& .col': {
              flex: '1 0 0%'
            },
            //this styles should work in the entire form 
              '& .col_4': {
                width: 'calc(100% / 3)' ,
                flex:' 0 0 auto',

              },
              '& .col_5': {
                width: '20%',
                flex:' 0 0 auto',

              },
              '& .col_8': {
                width: 'calc(2 * (100% / 3))',
                flex:' 0 0 auto',
              },
              '& .col_12': {
                width: '100%',
                flex:' 0 0 auto',

              },
              '&  .col_3': {
                width: 'calc(100% / 4)' ,
                flex:' 0 0 auto',

              },
              '& .col_2': {
                width: 'calc(100% / 6)' ,
                flex:' 0 0 auto',

              }, 
              '& .col_6': {
                width: 'calc(100% / 2)' ,
                flex:' 0 0 auto',

              },

              '& .verticalFormView .row': {
                flexDirection : 'column',

                '&>.fieldPresentation': {
                  display: 'flex',
                  alignItems: 'flex-start',
                  width: '100%',
                  // '& span': {
                  //   width: '40%',
                  // },
                  '&>div': {
                    width: '60%'
                  },
                  '&>.fieldPresentation': {
                    width: '60%'

                  }
                }
              },
              '& .verticalFormView>.row': {
                flexDirection:'row'
              },







          "& .section_presentation":{
            padding:'15px 25px',
            // padding: theme.spacing(1),
            height: '100%',
            width: '100%',
            // boxShadow: '0 0 1px 1px rgb(51 51 51 / 30%)',
            borderRadius: '3px',
            '& .row': {
              marginLeft:'0',
            },
            '& .row>*' :{
              paddingLeft:'0',
              paddingRight:'8px'
            },
          }



        },
        readOnlySection: {
          backgrounColor:'#f5f8fa',
          padding:'8px 0',
          borderRadius:'6px',
          '&.section_presentation':{
           padding:'8px',
          }
        },
        embedAccordionsRoot: {
          padding: '0'
        },
        formHeader: {
          display: 'flex', 
          // justifyContent: 'space-between',
          alignItems: 'center', 
          paddingLeft: theme.spacing(1),
          // marginBottom: theme.spacing(3),
          padding:`0`,
          background: theme.palette.background.defaultBlue,
          // boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 10%)',
          maxHeight: theme.spacing(7.5),
          width: 'auto',
          '&.center':{
            justifyContent:'center',

          },
          '&.left':{
            justifyContent:'flex-start',
            
          },
          '&.right':{
            justifyContent:'end',
            
          },

        },
        formFooter: {
       '&>button:first-child':{
            marginRight:'10px',
          },
          

          marginTop: theme.spacing(3),
          textAlign: 'center',
          marginBottom: theme.spacing(3),
          display:'flex',
          justifyContent:'center',
          alignItems:'center',

        },
        formPrimaryButtons: {
          marginRight: '2px',
          backgroundColor: theme.palette.secondary.main,
          color:'#fff',
          // width: "70px",
          fontWeight:'600',
          '&:hover': {
            // backgroundColor:`${ theme.palette.secondary.main}+'dd'`,
            backgroundColor: '#006e7fdd'
            
            // color:'#fff'
          }
        },
        formSecondaryButtons: {
          
          // marginRight: '0',
          backgroundColor:' #fff',
          color:theme.palette.secondary.main,
          border:  '1px solid',
          // width: "70px",
          borderColor: theme.palette.secondary.main,
          fontWeight:'600',
          '&:hover': {
            // backgroundColor:`${ theme.palette.secondary.main}+'12'`,
            backgroundColor: '#006e7f12'
            // color:'#fff'
          }
        },
        formFooterTriggers: {
          "& button": {
            color: '#fff',
            // fontWeight: '600',
            marginRight: '2px',
            margin:'0',
            lineHeight:'1.75 !important',
            // backgroundColor:' #006e7f',
          backgroundColor: theme.palette.secondary.main,

          },
        },
    })