import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const activityLogStyle = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {},
    activityControlroot: {
      display: 'flex',
      flexDirection: 'column'
    },
    controlLabel: {
      '&.MuiFormLabel-root ': {
        fontSize: '13px',
        color: '#29947F'
      }
    },
    dialogContent: {
      'padding': '0 !important',
      '&>div': {
        borderBottom: '1px solid #ebebeb'
      },
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        height: theme.spacing(1)
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.4)'
      },
      '&::-webkit-scrollbar-track': {
        background: 'hsla(0,0,0,.2)'
      }
    },
    ActivityLogTile: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1)
    },

    flexBody: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: theme.spacing(0.5)
    },
    activity_title: {
      'display': 'flex',
      'alignItems': 'center',
      'width': '100%',
      'padding': `${theme.spacing(1)} `,
      'background': theme.palette.primary.main,
      'color': theme.palette.common.white,
      '& div:first-child': {
        display: 'flex',
        gap: theme.spacing(2)
      },
      '& div:last-child': {
        display: 'flex',
        marginLeft: ' auto'
      }
    },
    activitybody: {
      display: 'flex',
      flexDirection: 'column',
      width: 'inherit'
    },
    user_info: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    datetime: {
      marginLeft: 'auto'
    },
    user_comment: {},
    activityActions: {
      display: 'flex',
      flexDirection: 'row'
    },
    edit: {
      marginLeft: 'auto'
    },
    reply: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    send_btn: {
      background: theme.palette.primary.main,
      cursor: 'pointer',
      height: '33px',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0'
    },
    footer_form: {
      'padding': theme.spacing(1),
      '& form': {
        width: '100%'
      }
    },
    activityLog_input: {
      '& .MuiOutlinedInput-root ': {
        padding: 0
      }
    },
    form: {
      marginBottom: theme.spacing(1)
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      justifyContent: 'center'
    }
  });
