export class ReportConstant {
  // Filter Relation Name
    static In = 'In';
    static Not_In = 'Not In';
    static Is = 'Is';
    static Is_Not = 'Is Not';
    static Is_Less_Than = 'Is Less Than';
    static Is_Less_Than_Or_Equals_To = 'Is Less Than Or Equals To';
    static Is_Greater_Than = 'Is Greater Than';
    static Is_Greater_Than_Or_Equals_To = 'Is Greater Than Or Equals To';
    static Is_In_The_Range = 'Is In The Range';
    static Is_Not_In_The_Range = 'Is Not In The Range';
    static Is_After = 'Is After';
    static Is_Before = 'Is Before';
    static Is_After_Or_Equal = 'Is After Or Equal';
    static Is_Before_Or_Equal = 'Is Before Or Equal';
    static Today = 'Today';

  // Filter Type
    static Date = 'Date';
    static DateTime = 'DateTime';
    static Number = 'Number';
    static Text = 'Text';
  }
  