import moment from 'moment';
import { KagamiModalModel } from '../../../../../../../../components/modal/model/KagamiModalModel';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { KagamiBeanUtils } from '../../../../../../../utils/KagamiBeanUtils';
import { KagamiDateModel } from '../builder/controls/date/model/kagamiDateModel';
import { KagamiControlModel } from '../builder/controls/model/kagamiControlModel';
import { KagamiTextModel } from '../builder/controls/textbox/model/kagamiTextModel';
import { getFormModel, getPreviousFormModel, handleFormDataChange, setFormModel, showServerError } from '../builder/form/controller/KagamiFormController';
import { KagamiEmbeddedFormPresentationModel } from '../builder/form/model/KagamiEmbeddedFormPresentationModel';
import { KagamiFormModel } from '../builder/form/model/KagamiFormModel';
import { getActiveContext, _getChangeForDetail, _getUnSyncedFormRecord } from '../builder/form/service/FormService';
import { KagamiGridModel } from '../builder/list/builder/grid/model/KagamiGridModel';
import { getKagamiListModel } from '../builder/list/controller/KagamiListController';
import { processPageResponse } from '../builder/ProcessPageBuildler';
import { getActiveModel, updateActiveModel } from '../controller/KagamiProcessController';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { NavigationService } from './NavigationService';
import { embedHasFormControls, getHeaderForprocess } from './PresentationService';
import { makeExplixitCallForNonFieldButton } from './ProcessService';
import {merge,values,countBy} from 'lodash';
import { KagamiFormSectionPresentationModel } from '../builder/form/builder/section-presentation/model/KagamiFormSectionPresentationModel';
import { KagamiSelectModel } from '../builder/controls/select/model/kagamiSelectModel';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { KagamiFileModel } from '../builder/controls/file/model/KagamiFileModel';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { hideLoader, showLoader } from '../../../../../../../../components/loader/controller/KagamiLoaderController';

//declaring variable to store existing template for nonfieldpresenatationButton
let existingActiveTemplate = new Map();
//[x: string]: any;

const PRESENTATION = 'presentation';
const PRE_CONDITION = 'preCondition';
const BUSINESS_VALIDATION = 'businessValidation';
let pContextData: any;
let presentationRule: any;
let presentationRuleMetaData: any;
let defaultPRule: any;
let policyType: any;
let auxiliaryEntity: any;
let output: Map<String, any>;
export let appContext : any;
export let userContext : any;

function executePolicy(policy: any) {
  output = new Map();
  policyType = policy['type'];
  auxiliaryEntity = policy['auxiliaryEntity'];

  if (policy['rules'] != null) {
    for (let key in policy['rules']) {
      let rule = policy['rules'][key];
      if (rule['@type'] == 'rule') {
        if (policyType == PRE_CONDITION) {
          return executeRule(rule);
        } else if (policyType == BUSINESS_VALIDATION) {
          if (!executeRule(rule)) {
            return rule['failure']['message'];
          }
        }else {
          executeRule(rule);
        }
        // else if (policyType == PRESENTATION) {
        //   if (executeRule(rule)) {
        //     return output;
        //   }
        // } else {
        //   return executeRule(rule);
        // }
      }
    }
  }
  return output.size ==0 ? null : output;
}

function executeRule(rule: any) {
  let _condition:any = false;
  if (rule != null && rule['condition'] != null) {
    _condition = executeCondition(rule['condition']);
  }
  if (policyType == PRE_CONDITION || policyType == BUSINESS_VALIDATION) {
    return _condition;
  }
  if (_condition) {
    if (rule['success'] != null) {
      executeAction(rule['success']);
    }
  } else {
    if (rule['failure'] != null) {
      executeAction(rule['failure']);
      if (policyType == PRESENTATION) {
        if (rule['success'] != null && rule['success']['statements'] != null) {
          for (var _statement in rule['success']['statements']) {
            executeDefaultPresentationAssignment(_statement);
          }
        }
      }
    }
  }
  return _condition;
}

function executeAction(action: any) {
  if (action['statements'] != null) {
    for (var key in action['statements']) {
      let statement = action['statements'][key];
      executeStatement(statement);
    }
  }
}

function executeStatement(statement: any) {
  if (statement['@type'] == 'presentation_assignment') {
    executePresentationAssignment(statement);
  }
}

function executePresentationAssignment(statement: any) {
  var _operator = statement['operator'];
  var _assignable = statement['lhs'];
  var _rhsValue = getEvaluableValue(statement['rhs'])[0];
  // var _rhsValue:any = getEvaluableValue(statement['rhs'])

  var attribute = _assignable['value'].substring(_assignable['value'].indexOf('.') + 1);
  var pRuleMetaData = presentationRuleMetaData;
  let isEmbedModel : boolean = pRuleMetaData instanceof KagamiEmbeddedFormPresentationModel
  if (
    presentationRule != null &&
    presentationRule['presentationRules'] != null &&
    presentationRule['presentationRules'][attribute] != null
  ) {
    pRuleMetaData = presentationRule['presentationRules'][attribute];
  }
  if (pRuleMetaData != null && _operator != null && _operator['actualValue'] != null) {
    switch (_operator['actualValue']) {
      case 'hide':
        /// Note : avoiding setting output if we are executing policies for embed form and it has presentation assignments of embed form controls not for overall embed form
        /// Example :Wdcl> Material issue note > Issue items should be visible and issue status in embed should be hidden if reference is none
        if(pRuleMetaData instanceof KagamiControlModel || pRuleMetaData instanceof KagamiFormSectionPresentationModel || (pRuleMetaData instanceof KagamiEmbeddedFormPresentationModel && (attribute === pRuleMetaData.formEntityId || attribute === 'id')) ){
          output.set('visible', !(_rhsValue.toString() == 'true'));
        }
        if( pRuleMetaData.formEntityId) {
          let activeModel = getActiveModel();
          if(activeModel instanceof KagamiFormModel){
            let entityAttrName = pRuleMetaData.formEntityId + "." + attribute;
            if(isEmbedModel && attribute === 'id'){
              let contextEmbedModel : KagamiEmbeddedFormPresentationModel = activeModel.formControls[pRuleMetaData.formEntityId]
              if(contextEmbedModel && CommonUtils.isNotEmpty(contextEmbedModel.state)){
                contextEmbedModel.state.setVisible(!(_rhsValue.toString() == 'true'))
              }
            }
           else if (activeModel.formControlStates[entityAttrName] ) {
              activeModel.formControlStates[entityAttrName].setVisible(!(_rhsValue.toString() == 'true'))
            }
          }
        }

        break;
      case 'disable':
        output.set('readonly', _rhsValue.toString() == 'true');
        break;
      case 'color':
        output.set('color', _rhsValue );
        break;
        
      case 'currency':
      case 'currency-attribute':
           output.set('currency-attribute',_rhsValue);
        break;
    }
  }
}

function executeDefaultPresentationAssignment(statement: any) {
  var _operator = statement['operator'];
  var _assignable = statement['lhs'];
  var attribute = _assignable['value'].substring(_assignable['value'].indexOf('.') + 1);
  if (
    presentationRule != null &&
    presentationRule['presentationRules'] != null &&
    presentationRule['presentationRules'][attribute] != null &&
    defaultPRule['presentationRules'] != null &&
    defaultPRule['presentationRules'] != null &&
    defaultPRule['presentationRules'][attribute] != null
  ) {
    var _pRuleMetaData = presentationRule['presentationRules'][attribute];
    var _defaultPRuleMetaData = defaultPRule['presentationRules'][attribute];
    if (_operator != null && _operator['actualValue'] != null) {
      switch (_operator['actualValue']) {
        case 'hide':
          _pRuleMetaData['visible'] = _defaultPRuleMetaData['visible'];
          break;
        case 'disable':
          _pRuleMetaData['readOnly'] = _defaultPRuleMetaData['readOnly'];
          break;
      }
    }
  }
}

function executeCondition(condition: any) {
  if (condition['@type'] == 'logical') {
    return executeLogicalCondition(condition);
  } else if (condition['@type'] == 'relational') {
    return executeRelationalCondition(condition);
  }
  return false;
}

function executeLogicalCondition(logicalCondition: any) {
  if (logicalCondition['conditions'] == null || logicalCondition['conditions'].length == 0) {
    return true;
  }
  if (logicalCondition['type'] == 'all') {
    for (let key in logicalCondition['conditions']) {
      let condition = logicalCondition['conditions'][key];
      let result = executeCondition(condition);
      if (!result) {
        return false;
      }
    }
    return true;
  } else if (logicalCondition['type'] == 'any') {
    for (let key in logicalCondition['conditions']) {
      let condition = logicalCondition['conditions'][key];
      let result = executeCondition(condition);
      if (result) {
        return true;
      }
    }
    return false;
  }
  // return false;
}

function executeRelationalCondition(relationalCondition: any) {
  try {
    var _lhs = relationalCondition['lhs'];
    var _rhs = relationalCondition['rhs'];
    var _operator = relationalCondition['operator'];

    var _rhsValues = getEvaluableValue(_rhs);
    var _lhsValues = getEvaluableValue(_lhs);

    _rhsValues = _rhsValues.map((e: any) => (e != undefined && e != null ? e : null));
    _lhsValues = _lhsValues.map((e: any) => (e != undefined && e != null ? e : null));

    if (_operator['expressionType'] == 'relational') {
      switch (_operator['dataType']) {
        case 'text':
        case 'longtext':
          return evaluateTextBooleanExpressionList(_lhsValues, _rhsValues, _operator);
        case 'number':
          return evaluateNumberBooleanExpressionList(_lhsValues, _rhsValues, _operator);
        case 'date':
          for (var i = 0; i < _rhsValues.length; i++) {
            if (_rhsValues[i] != null) {
              let dateValue = _rhsValues[i].toString();
              // DateTime dateTime = DateTime.parse(dateValue);
              // _rhsValues[i] = DateUtils.parseDateToUTCString(DateTime(dateTime.year, dateTime.month, dateTime.day));
            }
          }
          return evaluateDateBooleanExpressionList(_lhsValues, _rhsValues, _operator, 'date');
        case 'dateTime':
        case 'time':
          return evaluateDateBooleanExpressionList(_lhsValues, _rhsValues, _operator, 'dateTime');
        case 'boolean':
          return evaluateBooleanExpressionList(_lhsValues, _rhsValues, _operator);
        case "file": 
        return evaluateFIleExpressionList(_lhsValues, _rhsValues, _operator);

        default:
          return true;
      }
    }
  } catch (err) {
    console.log('error occurred while relational comparison .. $err');
    return false;
  }
  return true;
}
function evaluateFIleExpressionList(lhsValues: any, rhsValues: any, operator: any) {
  try {
    let _any =
      operator['listOperatorType'] != null && operator['listOperatorType'].toString().toLowerCase() == 'any'
        ? true
        : false;
    let _condition = false;
    for (var i = 0; i < lhsValues.length; i++) {
      for (var j = 0; j < rhsValues.length; j++) {
        var _lhsValue = lhsValues[i]; 
        var _rhsValue = rhsValues[j]; 
        switch (operator['actualValue']) {
          case '==':
            _condition = _lhsValue == _rhsValue;
            break;
          case '!=':
            _condition = _lhsValue != _rhsValue;
            break;
          default:
            _condition = false;
        }
        if (_condition && _any) {
          return true;
        }
        if (!_condition && !_any) {
          return false;
        }
      }
    }
    return _condition;
  } catch (err) {
    console.log('error occurred while text comparison .. $err');
    return false;
  }
}

function evaluateBooleanExpressionList(lhsValues: any, rhsValues: any, operator: any) {
  try {
    let _any =
      operator['listOperatorType'] != null && operator['listOperatorType'].toString().toLowerCase() == 'any'
        ? true
        : false;
    let _condition = false;
    for (var i = 0; i < lhsValues.length; i++) {
      for (var j = 0; j < rhsValues.length; j++) {
        var _lhsValue = lhsValues[i]; 
        var _rhsValue = rhsValues[j]; 
        switch (operator['actualValue']) {
          case '==':
            _condition = _lhsValue == _rhsValue;
            break;
          case '!=':
            _condition = _lhsValue != _rhsValue;
            break;
          default:
            _condition = false;
        }
        if (_condition && _any) {
          return true;
        }
        if (!_condition && !_any) {
          return false;
        }
      }
    }
    return _condition;
  } catch (err) {
    console.log('error occurred while text comparison .. $err');
    return false;
  }
}

function evaluateTextBooleanExpressionList(lhsValues: any, rhsValues: any, operator: any) {
  try {
    let _any =
      operator['listOperatorType'] != null && operator['listOperatorType'].toString().toLowerCase() == 'any'
        ? true
        : false;
    let _condition = false;
    for (var i = 0; i < lhsValues.length; i++) {
    //  for (var j = 0; j < rhsValues.length; j++) {
        var _lhsValue = lhsValues[i]; //.toString();
        var _rhsValue = rhsValues.length > 1 ?  rhsValues[i] : rhsValues[0]; //.toString();
        // var _rhsValue = rhsValues[j]
        switch (operator['actualValue']) {
          case '==':
            _condition = _lhsValue == _rhsValue;
            break;
          case '!=':
            _condition = _lhsValue != _rhsValue;
            break;
          case 'contains':
            _condition = _lhsValue.includes(_rhsValue);
            break;
          case 'not contains':
            _condition = !_lhsValue.includes(_rhsValue);
            break;
          case 'starts with':
            _condition = _lhsValue.startsWith(_rhsValue);
            break;
          case 'ends with':
            _condition = _lhsValue.endsWith(_rhsValue);
            break;
          default:
            _condition = false;
        }
        if (_condition && _any) {
          return true;
        }
        if (!_condition && !_any) {
          return false;
        }
      //  }
    }
    return _condition;
  } catch (err) {
    console.log('error occurred while text comparison .. $err');
    return false;
  }
}

export function evaluateNumberBooleanExpressionList(lhsValues: any, rhsValues: any, operator: any) {
  try {
    var _any =
      operator['listOperatorType'] != null && operator['listOperatorType'].toString().toLowerCase() == 'any'
        ? true
        : false;
    var _condition = false;
    for (var i = 0; i < lhsValues.length; i++) {
      // for (var j = 0; j < rhsValues.length; j++) {
        var _lhsValue = parseNumber(lhsValues[i]);
        // var _rhsValue = rhsValues[j];
        var _rhsValue = rhsValues[i];
        switch (operator['actualValue']) {
          case '==':
            _condition = _lhsValue == parseNumber(_rhsValue);
            break;
          case '!=':
            _condition = _lhsValue != parseNumber(_rhsValue);
            break;
          case '>':
            _condition = _lhsValue > parseNumber(_rhsValue);
            break;
          case '<':
            _condition = _lhsValue < parseNumber(_rhsValue);
            break;
          case '>=':
            _condition = _lhsValue >= parseNumber(_rhsValue);
            break;
          case '<=':
            _condition = _lhsValue <= parseNumber(_rhsValue);
            break;
          case 'range':
            _condition = parseNumber(_rhsValue['min']) < _lhsValue && _lhsValue < parseNumber(_rhsValue['max']);
            break;
          case 'notinrange':
            _condition = parseNumber(_rhsValue['min']) > _lhsValue || _lhsValue > parseNumber(_rhsValue['max']);
            break;
          case 'rangeInclusive':
            _condition = parseNumber(_rhsValue['min']) <= _lhsValue && _lhsValue <= parseNumber(_rhsValue['max']);
            break;
          default:
            return false;
        }
        if (_condition && _any) {
          return true;
        }
        if (!_condition && !_any) {
          return false;
        }
      // }
    }
    return _condition;
  } catch (err) {
    console.log('error occurred while number comparison .. $err');
    return false;
  }
}

function parseNumber(numberValue: any) {
  return numberValue != null ? (isFloat(numberValue) ? numberValue : parseFloat(numberValue)) : null;
}

export function isFloat(n: any) {
  return Number(n) === n && n % 1 !== 0;
}

function evaluateDateBooleanExpressionList(lhsValues: any, rhsValues: any, operator: any, type: any) {
  try {
    let _any =
      operator['listOperatorType'] != null && operator['listOperatorType'].toString().toLowerCase() == 'any'
        ? true
        : false;
    let _condition = false;
    for (var i = 0; i < lhsValues.length; i++) {
      for (var j = 0; j < rhsValues.length; j++) {
        var _lhsValue = parseDateSinceEpoch(lhsValues[i], type);
        var _rhsValue = rhsValues[j];
        switch (operator['actualValue']) {
          case '==':
            _condition = _lhsValue == parseDateSinceEpoch(_rhsValue, type);
            break;
          case '!=':
            _condition = _lhsValue != parseDateSinceEpoch(_rhsValue, type);
            break;
          case '>':
            _condition = _lhsValue > parseDateSinceEpoch(_rhsValue, type);
            break;
          case '<':
            _condition = _lhsValue < parseDateSinceEpoch(_rhsValue, type);
            break;
          case '>=':
            _condition = _lhsValue >= parseDateSinceEpoch(_rhsValue, type);
            break;
          case '<=':
            _condition = _lhsValue <= parseDateSinceEpoch(_rhsValue, type);
            break;
          case 'range':
            _condition =
              parseDateSinceEpoch(_rhsValue['min'], type) < _lhsValue &&
              _lhsValue < parseDateSinceEpoch(_rhsValue['max'], type);
            break;
          case 'notinrange':
            _condition =
              parseDateSinceEpoch(_rhsValue['min'], type) > _lhsValue ||
              _lhsValue > parseDateSinceEpoch(_rhsValue['max'], type);
            break;
          case 'rangeInclusive':
            _condition =
              parseDateSinceEpoch(_rhsValue['min'], type) <= _lhsValue &&
              _lhsValue <= parseDateSinceEpoch(_rhsValue['max'], type);
            break;
          default:
            return false;
        }
        if (_condition && _any) {
          return true;
        }
        if (!_condition && !_any) {
          return false;
        }
      }
    }
    return _condition;
  } catch (err) {
    console.log('error occurred while date comparison .. $err');
    return false;
  }
}

function parseDateSinceEpoch(dateValue: any, type : string) {
  if(type == "date"){
    dateValue = moment(dateValue).format('YYYY-MM-DD') +  "T00:00:00.000+HH:mm"
  }
  return dateValue != null ? dateValue : null;
}

function getEvaluableValue(evaluable: any) {
  let _toReturn : any;
  try {
    if (evaluable['@type'] == 'variable') {
      var _entity = evaluable['id'].substring(0, evaluable['id'].indexOf('.'));
      _entity = _entity == 'AuxiliaryEntity' ? auxiliaryEntity : _entity;

    //Context data
      let activeModel : any = getActiveModel();
      var _data;
    if((activeModel?.formData?.detailedObjects[_entity]?.[0]) && (activeModel && activeModel instanceof KagamiFormModel && activeModel?.formData?.data[_entity]?.[0]))  { /** changes made for getting parent detailed object data  */
        _data = merge(activeModel.formData.detailedObjects[_entity][0],activeModel?.formData?.data[_entity]?.[0]);
    }else if(activeModel && activeModel instanceof KagamiFormModel && activeModel?.formData?.data[_entity]?.[0]){
        _data = activeModel?.formData?.data[_entity]?.[0];
       }else {
        _data = pContextData && pContextData[_entity] != null ? pContextData[_entity][0] : pContextData;
       }
      // var _data = pContextData[_entity] != null ? pContextData[_entity][0] : pContextData;
      _toReturn = getVariableValue(evaluable['id'], _data);
    } else if (evaluable['@type'] == 'variables') {
      var _values: any[] = [];
      let variable: any;
      for (variable in evaluable['variables']) {
        var _entity = variable['id'].substring(0, variable['id'].indexOf('.'));
        var _data = pContextData[_entity] != null ? pContextData[_entity][0] : pContextData;
        _values.push(getVariableValue(variable['id'], _data));
      }
      _toReturn = _values;
    } else if (evaluable['@type'] == 'literal') {
      _toReturn = getKeywordValue(evaluable['value']);
    } else if (evaluable['@type'] == 'keyword') {
      _toReturn = getKeywordValue(evaluable['value']);
    } else if (evaluable['@type'] == 'range') {
    } else {
      _toReturn = null;
    }
  } catch (e) {
    //console.log('err while getting variable value ${evaluable['']} .. $e');
  }
  // format return value ot list
  if (!(_toReturn instanceof Array)) {
    if (_toReturn != null && (_toReturn instanceof String || typeof _toReturn === 'string') && _toReturn.toString().includes(';')) {
      /// TODO : Need to check for number currency issues. In that case uncomment 525 line
      _toReturn = _toReturn.toString().split(';');
      // _toReturn = [_toReturn.toString()];
    } else {
      _toReturn = [_toReturn];
    }
  }
  return _toReturn;
}

function getContextVariable(type: any, context: any, variable: any) {
  if (context === undefined){ return null}  
  let contextVariable = variable.replace(type+'.', '');
  let entity = contextVariable.substring(0, contextVariable.indexOf('.'));
  let _contextData = KagamiBeanUtils.getProperty(entity+'.0', context);
  if (_contextData !== undefined) {
    let variableValue : any = getVariableValue(contextVariable, _contextData);
    if(variableValue && variableValue !== null){
      return variableValue
    }
    else{
      return null;
    }
  }
  return null;
}



/// NOTE : need to discuss how to send app context and user context
export function getVariableValue(variable: any, data: any) {
  if (variable == null) return null;
  if (!variable) return '';
  if (variable.startsWith('UserContext')) {
    return getContextVariable('UserContext', userContext, variable);
  } else if (variable.startsWith('ApplicationContext')) {
    return getContextVariable('ApplicationContext', appContext, variable);
  }
  let _attributes = variable.split('.');
  if (_attributes.length == 2) { //'GrnHeader_448.Currency_141.prefix'
    return data != null ? (data[_attributes[1]] !== undefined && data[_attributes[1]] !== '' && data[_attributes[1]] !== SystemConstants.REMOVED_ ? data[_attributes[1]] : null) : null;
    /* let _evalVal =  data != null ? (data[_attributes[1]] !== undefined && data[_attributes[1]] !== '' && data[_attributes[1]] !== SystemConstants.REMOVED_ ? data[_attributes[1]] : null) : null;
    let kagamiFormModel: KagamiFormModel = getFormModel();
    if(_evalVal == null && kagamiFormModel?.formData?.data[kagamiFormModel.formData.mainEntityId] && kagamiFormModel?.formData?.data[kagamiFormModel.formData.mainEntityId]?.[0]?.[_attributes[1]]){
      let $$formModelData = kagamiFormModel.formData.data[kagamiFormModel.formData.mainEntityId][0];
      return   $$formModelData != null ? ($$formModelData[_attributes[1]] !== undefined && $$formModelData[_attributes[1]] !== '' && $$formModelData[_attributes[1]] !== SystemConstants.REMOVED_ ? $$formModelData[_attributes[1]] : null) : null;      
    }
    return _evalVal;     */
  }
  var _local = data != null ? data[_attributes[1]] : null;
  _attributes.reverse();
  _attributes.pop(0);
  _attributes.reverse();
  if (_local instanceof Array) {
    var _toReturn: any[] = [];
    for (var i = 0; i < _local.length; i++) {
      var _result = getVariableValue(_attributes.join('.'), _local[i]);
      _result instanceof Array ? _toReturn.push(_result) : _toReturn.push(_result);
    }
    return _toReturn;
  }
  _toReturn = getVariableValue(_attributes.join('.'), _local);
  return _toReturn;
}

function getKeywordValue(keyword: string) {
  const NULL = 'null';
  const TODAY = 'today';
  if (keyword == NULL) {
    return null;
  } else if (keyword.startsWith(TODAY)) {
    let _delta = keyword.replace(TODAY, '');
    let _days = 0;
    if (_delta) {
      _days = parseInt(_delta);
    }
    const moment = require('moment');
    const _date = moment();
    if(_days !== 0){
      _date.add(_days,'days');
    }
    // console.log(_date.format());
    return _date.format();
  }
  else if(keyword === 'true' || keyword === 'false'){
    keyword = JSON.parse(keyword)
  }
  return keyword;
}
export function executePolicyForFormPresentations(formModel : KagamiFormModel,formControls : any){
      let entityId : string;
      let formPresentationModel : any;
      let parentEmbedState : any;
      for([entityId,formPresentationModel] of Object.entries(formControls)){
        let formContextData : any;
        let isNestedModel : boolean = entityId.includes('.');
        if(isNestedModel){
          let formDataId : string = entityId.split('.')[1]
          formContextData =  getActiveContext(formModel,formPresentationModel.formEntityId,formDataId)
        }
        else{
           formContextData = getActiveContext(formModel,formPresentationModel.formEntityId)
        }
       if(formContextData){
        // let readOnly = isPresentationDisable(formContextData,formPresentationModel);
        // let visible = isPresentationVisible(formContextData,formPresentationModel);

        let $$policyDetails  = getPresentationRulePolicyConfigs(formContextData, formPresentationModel);
        let readOnly = $$policyDetails.readOnly;
        let visible = $$policyDetails.visible;

        if(formModel.formControlStates[entityId]  && readOnly !== formPresentationModel.readonly){
          formModel.formControlStates[entityId].setReadonly(readOnly)
        }
        else if(readOnly !== formPresentationModel.readonly){
          formPresentationModel.readonly = readOnly;
        }
        if(formModel.formControlStates[entityId] && visible !== formPresentationModel.visible){
          formModel.formControlStates[entityId].setVisible(visible);
         if(formPresentationModel.isNested && formPresentationModel.embeddedView === 'accordion'){
            /// Note : Accordion model will appear in expanded embedded form so to make sure the same props apply to the master detail
            /// of grid we access the form presentation model of the nested embed and will assign the same props
            let parentEmbedEntityId : string = formPresentationModel.parentEmbedEntityId;
            let parentEmbedFormPresentationModel : KagamiEmbeddedFormPresentationModel = formControls[parentEmbedEntityId]
            let parentGridModel : KagamiGridModel | undefined = parentEmbedFormPresentationModel.kagamiGridModels.get(parentEmbedEntityId)
            if(parentGridModel){
              let nestedEmbedModels : any[] = parentGridModel.nestedEmbedModels
              let currentNestedEmbedModel : KagamiEmbeddedFormPresentationModel = nestedEmbedModels.find((nestedModel : any) => nestedModel.formEntityId === formPresentationModel.formEntityId)
              if(currentNestedEmbedModel){
                currentNestedEmbedModel.visible = visible
              }
              let enableMasterDetail : boolean = false;
                  for(let nestedEmbedModel of nestedEmbedModels){
                     if(nestedEmbedModel.visible){
                       enableMasterDetail = true;
                     }
                  }
                  if(parentGridModel.isMasterDetailEnabled !== enableMasterDetail){
                    parentGridModel.isMasterDetailEnabled = enableMasterDetail;
                  }
            }
         }
        }
        else if(formPresentationModel instanceof KagamiFormSectionPresentationModel && formPresentationModel.visible !== visible){
          formPresentationModel.state.setVisible(visible)
        }
        else if(visible !== formPresentationModel.visible){
          formPresentationModel.visible = visible;
          if(formPresentationModel.isNested){
            let parentEmbedEntityId : string = formPresentationModel.parentEmbedEntityId;
            let parentEmbedPresentationModel : KagamiEmbeddedFormPresentationModel = formControls[parentEmbedEntityId];
            if(parentEmbedPresentationModel){
              let gridModel : KagamiGridModel | undefined = parentEmbedPresentationModel.kagamiGridModels.get(parentEmbedEntityId);
              if(gridModel){
                let nestedEmbedModels : any[] = gridModel.nestedEmbedModels;
                if(nestedEmbedModels.length !== 0){
                  let enableMasterDetail : boolean = false;
                  for(let nestedEmbedModel of nestedEmbedModels){
                     if(nestedEmbedModel.visible){
                       enableMasterDetail = true;
                     }
                  }
                  if(gridModel.state && gridModel.state !== null && gridModel.isMasterDetailEnabled !== enableMasterDetail){
                    gridModel.state.setMasterDetaileEnabled(enableMasterDetail);
                  }
                }
              }
            }
          }
          else if(formPresentationModel.embeddedView === 'tab'){
             parentEmbedState = KagamiStateManager.getStateToHolder('ParentEmbedState')
          }
        }
       }
      }
      if(parentEmbedState){
        let embedFormModels : KagamiEmbeddedFormPresentationModel[] = parentEmbedState.embedFormModels;
        let visibility : boolean = isTabsVisible(embedFormModels);
        parentEmbedState.setVisible(visibility);
      }
}

function isTabsVisible(embedFormModels : any[]){
  let updateTabState : boolean = false;
  for(let embedModel of embedFormModels){
     if(embedModel.visible){
       updateTabState = true;
     }
  }
  return updateTabState;
}

export function isPresentationVisible(_contextData: any, pRuleMataData: any) {
  if (_contextData) {
    if (pRuleMataData != null && pRuleMataData.presentationPolicy) {
      try {
        // console.log(
        //   'trying policy execution .. ' + (pRuleMataData['attrName'] ?? pRuleMataData['entityId'] ?? 'section')
        // );
        pRuleMataData.presentationPolicy['type'] = PRESENTATION;
        pContextData = _contextData;
        presentationRuleMetaData = pRuleMataData;
        let _result = executePolicy(pRuleMataData.presentationPolicy);
        // console.log(
        //   'policy execution done ..' +
        //     (pRuleMataData['attrName'] ?? pRuleMataData['entityId'] ?? 'section') +
        //     '$_result'
        // );
        return _result != null && _result.get('visible') != null ? _result.get('visible') : pRuleMataData['initiallyVisible'];
      } catch (e) {
        console.log(pRuleMataData);
      }
    }
  }
  return pRuleMataData['visible'];
}

export function executePoliciesForNestedOnMounting(nestedEmbedModels : any[],dataRecord : any){
  let embedFormModel : any;
  for(embedFormModel of nestedEmbedModels){
    let activeContext = getActiveContext(getFormModel(),embedFormModel.formEntityId,dataRecord.id)
    // let readOnly = isPresentationDisable(activeContext,embedFormModel)
    // let visible = isPresentationVisible(activeContext,embedFormModel)

    let $$policyDetails  = getPresentationRulePolicyConfigs(activeContext, embedFormModel);
    let readOnly = $$policyDetails.readOnly;
    let visible = $$policyDetails.visible;


    if(readOnly !== embedFormModel.readonly){
      embedFormModel.readonly = readOnly
    }
    if(visible !== embedFormModel.visible){
      embedFormModel.visible = visible
    }
  }
}


export function getPresentationRulePolicyConfigs(contextData: any, pRuleMataData: any) {
  if (contextData) {
    if (pRuleMataData != null && pRuleMataData.presentationPolicy) {
      pRuleMataData.presentationPolicy['type'] = PRESENTATION;
      pContextData = contextData;
      presentationRuleMetaData = pRuleMataData;
      let _result = executePolicy(pRuleMataData.presentationPolicy);
      let isReadOnly =  _result && _result.get('readonly') != null ? _result.get('readonly') : pRuleMataData['initiallyReadOnly'];
      let isVisible =  _result != null && _result.get('visible') != null ? _result.get('visible') : pRuleMataData['initiallyVisible'];
      let color =  _result && _result.get('color') != null ? _result.get('color') : '';
      let currencyAttribute =  _result && _result.get('currency-attribute') != null ? _result.get('currency-attribute') : '';
      
      return {readOnly : isReadOnly,visible:isVisible,color:color,'currency-attribute':currencyAttribute};
    }
  }
  return {readOnly : pRuleMataData['readonly'],visible:pRuleMataData['visible']}; ;
}


export function isPresentationDisable(contextData: any, pRuleMataData: any) {
  if (contextData) {
    if (pRuleMataData != null && pRuleMataData.presentationPolicy) {
      pRuleMataData.presentationPolicy['type'] = PRESENTATION;
      pContextData = contextData;
      presentationRuleMetaData = pRuleMataData;
      let _result = executePolicy(pRuleMataData.presentationPolicy);
      return _result && _result.get('readonly') != null ? _result.get('readonly') : pRuleMataData['initiallyReadOnly'];
    }
  }
  return pRuleMataData['readonly'];
}

export function executeGridUiPolicy(contextData : any,formPresentationModel : any){
  let hasChangesToUpdate : boolean = false;
  let _contextData : any = contextData;
  if(_contextData && _contextData !== null){
    if(formPresentationModel instanceof KagamiEmbeddedFormPresentationModel && formPresentationModel.isListPresentation){
      let gridModel : KagamiGridModel | undefined = formPresentationModel.kagamiGridModels.get(formPresentationModel.formEntityId);
      if(gridModel && gridModel.gridApi !== null && !formPresentationModel.isActiveForm){
        let attributeName: any,control : any;
        let existingColDefs: any = null;
        if(!gridModel.gridApi?.destroyCalled){
          existingColDefs = gridModel.gridApi?.getColumnDefs();
        }
        for([attributeName,control] of Object.entries(gridModel.gridControls)){
        let currentColDef:any = null;
        if(existingColDefs && existingColDefs.length){
          currentColDef = existingColDefs.filter((colDef:any)=>colDef.field === attributeName);
          if(currentColDef && currentColDef.length)
          {
            currentColDef = currentColDef[0];
            const index = existingColDefs.findIndex((obj:any) => {
              return obj.field === attributeName;
            });
            let _minWidth:any = gridModel.textWidth.filter((mw:any)=>mw.key === attributeName);
            if(_minWidth.length > 0){
              existingColDefs[index].minWidth = _minWidth[0].minWidth;
            }
          }
        } 
        // let readOnly = isPresentationDisable(_contextData, control);
        // let visible = isPresentationVisible(_contextData, control);
        let $$policyDetails  = getPresentationRulePolicyConfigs(_contextData, control);
        let readOnly = $$policyDetails.readOnly;
        let visible = $$policyDetails.visible;
        let visibleProps =  handleGridColumnPolicies(currentColDef, _contextData, readOnly, visible ,control);        
        readOnly = visibleProps.readOnly;
        visible = visibleProps.visible;
         if(control.readonly !== readOnly){
           if(!hasChangesToUpdate) hasChangesToUpdate = true;
           control.readonly = readOnly;  
           if(currentColDef){
             currentColDef.editable = !readOnly;
            }
         }
         if(control.visible !== visible){
          if(!hasChangesToUpdate) hasChangesToUpdate = true;
           control.visible = visible;   
           if(currentColDef){
            currentColDef.hide = !visible;
           }    
         }
        }
        if(hasChangesToUpdate){
          if(existingColDefs){
            gridModel.gridApi!.setColumnDefs(existingColDefs);
            // Hack for dynamic editable while editing column
            // TODO: Search for a permanent fix
            /// Note : Commented this code because if we stop editing, row editing call back function is getting called 
            /// which is throwing validations popup. To avoid that we have commented this piece of code
            const currentCell = gridModel.gridApi!.getFocusedCell();
            if(currentCell){
              gridModel.gridApi!.stopEditing();
              gridModel.gridApi!.startEditingCell({
                rowIndex: currentCell?.rowIndex,
                colKey: currentCell?.column.colId
              });
            }
          } else{
            gridModel.state.setListLoaded(true);
          }
        }
      }
      }
  }
}

export function executeUiPolicy(contextData: any, formModel: KagamiFormModel) {
  let _contextData:any = {};
  let controlIdsToUpdate = new Set();
  for (let formControlskey in formModel.formControls) {
    let formPresentationModel = formModel.formControls[formControlskey];
    let isEmbedModel : boolean = formPresentationModel instanceof KagamiEmbeddedFormPresentationModel
    let gridModel : KagamiGridModel | undefined;
    let existingColDefs: any = null;
    let hasGridChanges : boolean = false;
    if(isEmbedModel){
     gridModel =  formPresentationModel.kagamiGridModels.get(formPresentationModel.formEntityId);
     if(gridModel && gridModel.gridApi &&  !gridModel.gridApi?.destroyCalled){
      existingColDefs = gridModel?.gridApi?.getColumnDefs();
     }
    }
    let formControls = formPresentationModel.formControls;
    if (contextData != null) _contextData = contextData;
    if (_contextData) {
      for (let controlIdentifier in formControls) {
        let control = formControls[controlIdentifier];
        let currentColDef:any = null;
        let $$policyDetails  = getPresentationRulePolicyConfigs(_contextData, control);
        let readOnly = $$policyDetails.readOnly;
        let visible = $$policyDetails.visible;
        if(existingColDefs && existingColDefs.length){
          currentColDef = existingColDefs.filter((colDef:any)=>colDef.field === control.attributeName);
          if(currentColDef && currentColDef.length){
            currentColDef = currentColDef[0]
          }
        }

        if (formModel.formControlStates[controlIdentifier] ) {
          if(typeof formModel.formControlStates[controlIdentifier].setAtttributeSettings === 'function'){
            formModel.formControlStates[controlIdentifier].setAtttributeSettings($$policyDetails);
          }
        }
          
        if (formModel.formControlStates[controlIdentifier] &&readOnly != formModel.formControlStates[controlIdentifier].isReadOnly) {
          /// Updating grid controls read only and visibility if it is in expanded embed form mode
          if(isEmbedModel && gridModel && control instanceof KagamiControlModel){
            if(!hasGridChanges) hasGridChanges = true;
            gridModel.gridControls[control.attributeName].readonly = readOnly;
          }
          if(currentColDef){
            currentColDef.editable = !readOnly;
           }
          formModel.formControlStates[controlIdentifier].setReadonly(readOnly);
        }
        if (formModel.formControlStates[controlIdentifier] && visible != formModel.formControlStates[controlIdentifier].isVisible) {
          if(isEmbedModel && gridModel  && control instanceof KagamiControlModel){
            if(!hasGridChanges) hasGridChanges = true;
            gridModel.gridControls[control.attributeName].visible = visible;
          }
          if(currentColDef){
            currentColDef.hide = !visible;
           }
          formModel.formControlStates[controlIdentifier].setVisible(visible);
        }
      }
      /// Note : updating grid columns if there are any changes in expanded embed form based on policies using grid column defs
      if(hasGridChanges && existingColDefs && existingColDefs !== null){
        gridModel?.gridApi!.setColumnDefs(existingColDefs);
      }
      /// Note : Checking whether the formcontrols object contains field controls other than presentation models
      if(Object.keys(formControls).length === 0 || !embedHasFormControls(formControls)){
        /** current grid data is not available in context at start level */  
        /*
        let currentEntityId = formPresentationModel.formEntityId;
        _contextData[currentEntityId]= formPresentationModel.onLoadData;  */
        /// Note : added this condition because even if there is context data for nested embed in expanded,  the respective nested embed in master detail has no data and that is 
        /// making the data empty even for expanded nested embed popup form so added this condition here
        if(_contextData[formPresentationModel.formEntityId] && _contextData[formPresentationModel.formEntityId] === null){
          _contextData[formPresentationModel.formEntityId] = gridModel?.detailedObjects || [];
        }
        executeGridUiPolicy(_contextData,formPresentationModel);
      }
    }
  }
  return controlIdsToUpdate;
}

export function executeBusinessPolicy(contextData: any, formModel: KagamiFormModel,currentControl?:any) {
  let defaultContextData = {};
  for (let formControlskey in formModel.formControls) {
    let formControls = formModel.formControls[formControlskey].formControls;
    if (contextData != null) defaultContextData = contextData;
    if (defaultContextData) {
      for (let controlIdentifier in formControls) {
        let control = formControls[controlIdentifier];
        let pRuleMataData = control;
       // if (!control.errorMessage) {
          if (pRuleMataData != null && pRuleMataData.businessPolicy) {
            if (
              control.controlType !== 'multiselect' &&control.controlValue && control.controlValue !== '' ||
              (control instanceof KagamiTextModel &&
                control.forNumber &&
                (control.controlValue == '0.0' || control.controlValue == 0.0))
            ) {
                            pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

              pContextData = defaultContextData;
              presentationRuleMetaData = pRuleMataData;
              let _result = executePolicy(pRuleMataData.businessPolicy);
              if (formModel.formControlStates[controlIdentifier] && _result != control.errorMessage) {
                formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
              }
            }
            else if((control.controlValue && control.controlValue !== '') && (control instanceof KagamiTextModel && control.controlType !== 'number')){

              pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

              pContextData = defaultContextData;
              presentationRuleMetaData = pRuleMataData;
              let _result = executePolicy(pRuleMataData.businessPolicy);
              if (formModel.formControlStates[controlIdentifier] && _result != control.errorMessage) {
                formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
              }
            }
            else if(control.readonly  && control.controlValue === '' && control.controlType && control.controlType !== 'number' && control.controlType !== 'multiselect'){
              pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

              pContextData = defaultContextData;
              presentationRuleMetaData = pRuleMataData;
              let _result = executePolicy(pRuleMataData.businessPolicy);
              if (formModel.formControlStates[controlIdentifier] && _result != control.errorMessage) {
                formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
              }
            }
            else if(control instanceof KagamiDateModel && control.controlValue){
              pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

              pContextData = defaultContextData;
              presentationRuleMetaData = pRuleMataData;
              let _result = executePolicy(pRuleMataData.businessPolicy);
              if (formModel.formControlStates[controlIdentifier] && _result != control.errorMessage) {
                formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
              }
            }
            else if(control instanceof KagamiDateModel && control.controlType ==='date' && CommonUtils.isNotEmpty(control.controlValue)){
              pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

              pContextData = defaultContextData;
              presentationRuleMetaData = pRuleMataData;
              let _result = executePolicy(pRuleMataData.businessPolicy);
              if (formModel.formControlStates[controlIdentifier]  && _result != control.errorMessage) {
                formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
              }
            }
            else if(control instanceof KagamiSelectModel && control.controlType === 'multiselect' && control.selectValues.length !== 0){
              pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

              pContextData = defaultContextData;
              presentationRuleMetaData = pRuleMataData;
              let _result = executePolicy(pRuleMataData.businessPolicy);
              if (formModel.formControlStates[controlIdentifier] && _result != control.errorMessage) {
                formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
              }
            }
            else if(control instanceof KagamiFileModel   && control.controlValue == ''){
             pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

              pContextData = defaultContextData;
              presentationRuleMetaData = pRuleMataData;
              let _result = executePolicy(pRuleMataData.businessPolicy);
              if (formModel.formControlStates[controlIdentifier] && _result != control.errorMessage) {
                formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
              }
            }
          }
          /** ADDED IF TWO FIIELDS CONTAINS SAME ERROR MESSAGE ON CHANGE OF ONE OTHER NEED NOT TO SHOW */
          // if(currentControl?.attributeName != control?.attributeName) { 
          //   formModel.formControlStates[controlIdentifier].setErrorMessage(null);
          // }
      }
    }
  }
}

function executePolicyWithContextData(pRuleMataData : any,defaultContextData : any,control : any){
  let formModel : KagamiFormModel = getFormModel();
  let controlIdentifier = control.controlIdentifier
  pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;

  pContextData = defaultContextData;
  presentationRuleMetaData = pRuleMataData;
  let _result = executePolicy(pRuleMataData.businessPolicy);
  if (formModel.formControlStates[controlIdentifier] && _result != control.errorMessage) {
    formModel.formControlStates[controlIdentifier].setErrorMessage(_result);
  }
}

export function executeBusinessPolicyForControl(contextData: any, control: KagamiControlModel) {
  let pRuleMataData = control;
  if (contextData) {
    if (pRuleMataData != null && pRuleMataData.businessPolicy) {
      pRuleMataData.businessPolicy['type'] = BUSINESS_VALIDATION;
      pContextData = contextData;
      presentationRuleMetaData = pRuleMataData;
      return executePolicy(pRuleMataData.businessPolicy);
    }
  }
  return null;
}

export function enableTriggerByPolicy(policy: any, dataRecord: any) {
  let _contextData: any = {};

  _contextData = dataRecord;

  if (policy['type'] == PRE_CONDITION) {
    pContextData = dataRecord;
    let result = executePolicy(policy);
    // console.log('${getTriggerName(trigger)} trigger enabled: $result');
    return result;
  }

  return true;
}

export function nonFieldButtonOnClickCall(currentActiveTemplateModel: any, modalModel : KagamiModalModel,fieldName: any, presentationId: any,processName?: string) {
  let activeTemplate: any = currentActiveTemplateModel;
  // let mainProcessName = processName && processName !== '' ? processName : activeTemplate.processName;
  _getUnSyncedFormRecord(activeTemplate,activeTemplate.mainEntityId)
  let detailedObjects = activeTemplate.formData.changedData
  let changeFor = _getChangeForDetail(activeTemplate,modalModel.parentEntityId,null,null)
 showLoader()
  makeExplixitCallForNonFieldButton(activeTemplate.processName, activeTemplate, detailedObjects, fieldName, presentationId,changeFor).then(
    (response: any) => {
      hideLoader();
      let kagamiFormModel: KagamiFormModel = getFormModel();
      kagamiFormModel.formData.clearChangedData();
      const modalClose = () => {
        if(currentActiveTemplateModel instanceof KagamiFormModel)  {
        let previousFormModel = getPreviousFormModel();
            if(CommonUtils.isNotEmpty(previousFormModel) && previousFormModel instanceof KagamiFormModel) {
            updateActiveModel(previousFormModel);
            setFormModel(previousFormModel);
          }
        }
      }

      if (response.data.constructOutputData && response.data.constructOutputData.uiResource !== undefined) {
        let subProcessResposeModel : KagamiProcessResponseModel | undefined = NavigationService.processResponse(response,null,true);
        if(subProcessResposeModel){
          subProcessResposeModel.isPickItemCall = true;
          let pickItemContent = processPageResponse(subProcessResposeModel);
          modalModel.content = pickItemContent;
          modalModel.title =  getHeaderForprocess(subProcessResposeModel?.onStartData?.constructOutputData);
          modalModel.modalWidth = 'lg'
          modalModel.isPickItem = true;
          if(modalModel.handleOnClose){
            modalModel.handleOnClose = undefined
          }
          modalModel.state.setOpen(!modalModel.state.isOpen);
          modalModel.handleOnClose = modalClose;
        }
      } 
      else if(response.data.constructError){
        showServerError(response.data['constructError'])
      }
      else if(response.data.constructOutputData.responseMap && response.data.constructOutputData.responseMap.EOP && response.data.constructOutputData.responseMap.confirmMessage){
        NavigationService.processResponse(response,null,false,true,modalModel.parentEntityId);
      }
      else {
        if(activeTemplate instanceof KagamiFormModel){
            handleFormDataChange(modalModel.parentEntityId ?? activeTemplate.mainEntityId,response.data,undefined,false,false,modalModel.parentEntityId === activeTemplate.mainEntityId)
        }
        else if (activeTemplate.presentation && activeTemplate.presentation.uiTemplate === SystemConstants.LIST_VIEW_TYPE) {
          getKagamiListModel().embedModal.content = 'No Response';
          getKagamiListModel().embedModal.state.setOpen(true);
        } else {
          kagamiFormModel.errorModal.content = 'No Response';
          kagamiFormModel.errorModal.state.setOpen(true);
        }
      }
    }
  );
}

export function getExistingActiveTemplate() {
  return existingActiveTemplate;
}

export function assignAppAndUserContextData(applicationContext : any,userCont : any){
  appContext = applicationContext;
  userContext = userCont;
}


export function executeDisplayCondition(contextData: any, pRuleMataData: any) {
  if (contextData) {
    if (pRuleMataData != null) {
      if(!pRuleMataData?.policyMap?.presentation['type'] ){
        pRuleMataData.policyMap.presentation['type'] = PRESENTATION;
      }
      pContextData = contextData;
      presentationRuleMetaData = pRuleMataData;
      let _result:any = executePolicy(pRuleMataData?.policyMap?.presentation);
      let isReadOnly =  _result && _result.get('readonly') != null ? _result.get('readonly') : pRuleMataData['initiallyReadOnly'];
      let isVisible =  _result != null && _result.get('visible') != null ? _result.get('visible') : pRuleMataData['initiallyVisible'];
      let color =  _result && _result.get('color') != null ? _result.get('color') : '';
      let currencyAttribute =  _result && _result.get('currency-attribute') != null ? _result.get('currency-attribute') : '';
      return {readOnly : isReadOnly,visible:isVisible,color:color,'currency-attribute':currencyAttribute};
    }
    return '';
  }
  return pRuleMataData['color'];
}

const handleGridColumnPolicies = (currentColDef: any, _contextData: any, readOnly: any, visible: any,control:any) => {
  if(CommonUtils.isNotEmpty(currentColDef) && currentColDef.headerComponentParams === undefined){
    currentColDef.headerComponentParams={}
  }
  if (CommonUtils.isNotEmpty(currentColDef) && !currentColDef.headerComponentParams?.['$$properties']) {
    currentColDef.headerComponentParams['$$properties'] = []
  }
  if(_contextData[control.entityId]?.[0]?.id){
    if(CommonUtils.isNotEmpty(currentColDef)) {
    currentColDef.headerComponentParams['$$properties'][_contextData[control.entityId][0].id] = {
      readOnly: readOnly,
      visible: visible
    }
  }
  }
  if (CommonUtils.isNotEmpty(currentColDef) && currentColDef.headerComponentParams?.['$$properties']) {
    let $$propsArr = values(currentColDef.headerComponentParams['$$properties'])
    if (countBy($$propsArr, 'readOnly')?.['false'] > 0 ) {
      readOnly = false;
    }
    if (countBy($$propsArr, 'visible')?.['true'] > 0) {
      visible = true;
    }
  }
  return {readOnly,visible}
}