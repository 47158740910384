
import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { buildFormComponents, SectionPresentationContent } from '../../FormBuilder';
import {
  FormSectionPresentationProps,
  KagamiFormSectionPresentationModel,
  useKagamiFormSectionPresentaionModelState
} from '../model/KagamiFormSectionPresentationModel';
import {KagamiFormSectionPresentationStyle} from '../style/KagamiFromSectionPresentationStyles';

export const buildFormSectionPresentationComponent = (sectionPresentationModel: KagamiFormSectionPresentationModel) => {
  sectionPresentationModel.formComponentObj = buildFormComponents(sectionPresentationModel, sectionPresentationModel.parentFormPresentationModel)
  return <KagamiFormSectionPresentation kagamiFormSectionPresentationModel={sectionPresentationModel} />;
};
export const KagamiFormSectionPresentation = (props: FormSectionPresentationProps) => {
  const classes : any= makeStyles(KagamiFormSectionPresentationStyle)()
  let sectionPresentationState = useKagamiFormSectionPresentaionModelState(createState(props.kagamiFormSectionPresentationModel));
  props.kagamiFormSectionPresentationModel.state = sectionPresentationState;
  return props.kagamiFormSectionPresentationModel.state.isVisible ? <SectionPresentationContent kagamiFormSectionPresentationModel = {props.kagamiFormSectionPresentationModel} classes = {classes} /> : null;
};
