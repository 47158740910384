import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { KagamiFileModel } from '../model/KagamiFileModel';
import { FileValidator } from '../validations/FileValidations';
import { hideLoader, showLoader } from '../../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { FileUrlService } from '../../../../../../../../../../service/FileUrlService';
import { getFormModel, notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import { buildtoastNotification } from '../../../../../../../ToastNotification/controller/ToastNotificationController';
import { KagamiImageModel } from '../../image/model/kagami_ImageModel';
import { KagamiFormModel } from '../../../form/model/KagamiFormModel';
import { KagamiEmbeddedFormPresentationModel } from '../../../form/model/KagamiEmbeddedFormPresentationModel';
 
export function getFileData( kagamiFileModel:any) {

  let fileGridData: any[] = [];
  let controlDisplayName = kagamiFileModel.controlDisplayName || "Attachment";
  const fieldState: any = kagamiFileModel.state
  if (CommonUtils.isEmpty(fieldState.controlValue)) {
    return fileGridData;
  } else {
    let arr;
    if (fieldState.controlValue.includes(',')) {
      let arr = fieldState.controlValue.split(',');
      let result = arr.filter((e: any) => e);
      result.forEach((ele: any, index:any) => {
        let gridObjData: any = {};
        if(ele !="removed_" && ele !=""){
          let eleData = ele.split(':');
          gridObjData['SNo'] = index+1 ;
          gridObjData[controlDisplayName] = eleData[1];
          gridObjData['filename'] = ele;
          fileGridData.push(gridObjData);
        }
      });
    } else {
      if (fieldState.controlValue.includes(':')) {
        let eleData = fieldState.controlValue.split(':');
        let gridObjData: any = {};
        gridObjData['SNo'] = 1;
        gridObjData[controlDisplayName] = eleData[1];
        gridObjData['filename'] =fieldState.controlValue;
        fileGridData.push(gridObjData);
      } else {
        let gridObjData: any = {};
        gridObjData['SNo'] = 1;
        gridObjData[controlDisplayName] = fieldState.controlValue;
        gridObjData['filename'] =fieldState.controlValue;
        fileGridData.push(gridObjData);
      }
    }
    return fileGridData;
  }
}
 
export function handleImageChange(event: any,   kagamiFileModel: KagamiFileModel | KagamiImageModel ) {
    const fieldState = kagamiFileModel.state
    let fileList: any = [];
    if( kagamiFileModel.controlType === 'image'){
      if(Array.isArray(event) && event.length >0) {

        fileList= event
      } else {
        fileList = []
      }
    } else {
      fileList = event.target.files;

    }
    
    for (const fileItem of fileList) {
        let errorMsg:boolean   =  validateFile(kagamiFileModel, fileItem);
        if (!errorMsg) {
     
          // fieldState.mergeSelectedFileName([fileItem]);
          // fieldState.mergeFileType([fileItem.type]);
          // fieldState.mergeselectedFileURL([URL.createObjectURL(fileItem)]);
          validateAndUploadPickedFile(kagamiFileModel, fieldState, fileItem);
        
      } 
    }
      
      

  }

  function validateFile(fileModel:any, fileItem: any) {
    
     let errorMessage = FileValidator.validate(fileModel.validation, fileItem, fileModel.validation.fileFormat, fileModel);
     
    errorMessage && buildtoastNotification('error',errorMessage, 0 )
    return CommonUtils.isNotEmpty(errorMessage) ? true : false

  }

  function validateAndUploadPickedFile(fileModel: KagamiFileModel | KagamiImageModel , fieldState: any, fileItem: any) {
    let kagamiFormModel : KagamiFormModel = getFormModel();
    if (fileModel.selectedFile != null) {
      /// Note : incase of embed file controls, we have to send entityId without prefix
      let entityId : string = fileModel.entityId;
      if(entityId !== kagamiFormModel.mainEntityId){
        let embedFormPresentationModel : KagamiEmbeddedFormPresentationModel = kagamiFormModel.formControls[fileModel.entityId];
        if(embedFormPresentationModel && embedFormPresentationModel.presentation){
          entityId = embedFormPresentationModel.presentation['entityId'];
        }
      }
      showLoader()
      FileUrlService.uploadImage(entityId, fileModel.attributeName, fileItem).then((response: any) => {
      //   hideLoader();
      //   fieldState.mergeSelectedFile([getDmsViewUrl(response.data.data)]);
      //   fieldState.mergeThumbNails([{...response.data}])
      //   let newControlValue = fieldState.controlValue ? fieldState.controlValue : '';
      //  let newUploads = fieldState.newUploads ? fieldState.newUploads :'[]'
      //  CommonUtils.isNotEmpty(newUploads) ? newUploads =  newUploads.split(',').map((item:any) => item.split(":")[0]).filter((item:any) => item) : newUploads = [];

      //   CommonUtils.isNotEmpty(newControlValue) ? newControlValue =  newControlValue.split(',').map((item:any) => item.split(":")[0]).filter((item:any) => item) : newControlValue = [];
        
      //    if ( response.data.data == 'File type doesnt match the specified'){
      //     buildtoastNotification('error',`Upload of ${fileItem?.name} failed. File type doesnt match the specified`, 0 )
      //    } else if (fileModel.controlType === 'image'){
      //     newControlValue.pop();
      //     newUploads.push(response.data.data +  ':' + fileItem.name)
      //     newControlValue.push(response.data.data)

      //   }  else {
      //      newUploads.push(response.data.data +  ':' + fileItem.name)
      //      newControlValue.push(response.data.data)

      //    }
      //   // }

      
        
      //   fieldState.setNewUploads(newUploads.join(','))
      //   fieldState.setControlValue( newControlValue.join(','))
      //   notifyChangeToForm(fileModel,  newControlValue.join(","));

        hideLoader();
        let newUploadsDocInfo = CommonUtils.isNotEmpty(fieldState.newUploadsDocInfo) ? JSON.parse(JSON.stringify(fieldState.newUploadsDocInfo)): {};
        // newUploadsDocInfo[response.data.data] = {};
        // newUploadsDocInfo[response.data.data]['fileName'] = fileItem.name
        // newUploadsDocInfo[response.data.data]['fileType'] = fileItem.name.split(".").pop()
        // newUploadsDocInfo[response.data.data]['thumbnail']  = response.data.thumbnail
        
        //image field only handles singleFileUpload till restricted to 1 case is handled funtionally
        if(fileModel.htmlControl === 'image') {
          newUploadsDocInfo = {} ;
          newUploadsDocInfo[response.data.data] = {};
          newUploadsDocInfo[response.data.data]['fileName'] = fileItem.name
          newUploadsDocInfo[response.data.data]['fileType'] = fileItem.name.split(".").pop()
          newUploadsDocInfo[response.data.data]['thumbnail']  = response.data.thumbnail
         } else {
          newUploadsDocInfo[response.data.data] = {};
        newUploadsDocInfo[response.data.data]['fileName'] = fileItem.name
        newUploadsDocInfo[response.data.data]['fileType'] = fileItem.name.split(".").pop()
        newUploadsDocInfo[response.data.data]['thumbnail']  = response.data.thumbnail
        fieldState.setNewUploadsDocInfo(newUploadsDocInfo) ;
         }


        let newControlValue = fieldState.controlValue ? fieldState.controlValue : '';
        CommonUtils.isNotEmpty(newControlValue) ? newControlValue =  newControlValue.split(',').map((item:any) => item.split(":")[0]).filter((item:any) => item) : newControlValue = [];
       if(fileModel.htmlControl === 'image') {
        newControlValue.pop()
        newControlValue.push(response.data.data);
       } else {

         newControlValue.push(response.data.data);
       }


        fieldState.setControlValue( newControlValue.join(','));
        notifyChangeToForm(fileModel,  newControlValue.join(","));






        //updating the docinfo in gridmodel after every file upload
       let formModel = getFormModel()
        if (fileModel.entityId !== formModel.mainEntityId){
        let kagamiExpandedEmbeddedPresentationModel =   formModel.formControls[fileModel.entityId];
         if (CommonUtils.isNotEmpty(kagamiExpandedEmbeddedPresentationModel) && kagamiExpandedEmbeddedPresentationModel.isListPresentation){
           let gridModel =  kagamiExpandedEmbeddedPresentationModel.kagamiGridModels.get(fileModel.entityId)
           if(gridModel) {
            //assigning newDoInfo to gridModel that will be used in embedded grid file view after upload
             gridModel.docInfo ={...fileModel.docInfo, ...newUploadsDocInfo}
             //updating the new uploaded file info in form model
              if (!formModel.docInfo.hasOwnProperty(fileModel.attributeName)){ formModel.docInfo[fileModel.attributeName] = {} }
             formModel.docInfo[fileModel.attributeName][response.data.data] = {};
             formModel.docInfo[fileModel.attributeName][response.data.data]['fileName'] = fileItem.name
             formModel.docInfo[fileModel.attributeName][response.data.data]['fileType'] = fileItem.name.split(".").pop()
             formModel.docInfo[fileModel.attributeName][response.data.data]['thumbnail']  = response.data.thumbnail

           }
         }
        }


      })
      
      .catch((err : any)=>{
        hideLoader();
        console.log(err)
        buildtoastNotification('error',`Upload of ${fileItem?.name} failed`, 0 )
      })
    } else {
    }
  }


  export function deleteSingleFile( fileObj: any, fileModel: KagamiFileModel | KagamiImageModel) {
    let fieldState = fileModel.state
    let indexId = fileObj.SNo - 1;
    if (fieldState.controlValue.includes(',')) {
      var id = fieldState.controlValue.includes(',')
        ? fieldState.controlValue.split(',').filter((ele: any) => ele)
        : fieldState.controlValue;
      const newSortedFile: any[] = id.filter((item: any) => item !== id[indexId]);
      notifyChangeToForm(fileModel, newSortedFile.toString());
    } else {
      const newSortedFiles = fieldState.controlValue;
      let getDocId: any;
      if (newSortedFiles && newSortedFiles.split(':')[0]) {
        getDocId = newSortedFiles.split(':')[0];
        FileUrlService.deleteImage(getDocId);
        notifyChangeToForm(fileModel, null);
      } else {
        getDocId = newSortedFiles;
        FileUrlService.deleteImage(getDocId);
        notifyChangeToForm(fileModel, null);
      }
    }
    const newFileNameArray: any[] = fieldState.selectedFileName.filter(
      (item: any) => item !== fieldState.selectedFileName[fileObj.SNo]
    );
    const newFileArray: any[] = fieldState.selectedFile.filter(
      (item: any) => item !== fieldState.selectedFile[fileObj.SNo]
    );
    const newFileURLArray: any[] = fieldState.selectedFileURL.filter(
      (item: any) => item !== fieldState.selectedFileURL[fileObj.SNo]
    );
    fieldState.setSelectedFile(newFileArray);
    fieldState.setSelectedFileName(newFileNameArray);
    fieldState.setSelectedFileURL(newFileURLArray);

let formModel = getFormModel();


          CommonUtils.isNotEmpty(formModel.docInfo[fileModel.attributeName]) &&  formModel.docInfo[fileModel.attributeName].hasOwnProperty(fieldState.controlValue) &&   delete   formModel.docInfo[fileModel.attributeName][fieldState.controlValue] ;
             
  }

  export function deleteAllFile( kagamiFileModel: any) {
    kagamiFileModel.state.setControlValue(null);
    notifyChangeToForm( kagamiFileModel, kagamiFileModel.state.controlValue);
  }


  export function deleteDocument(fileId:string, kagamiFileModel:KagamiFileModel) {
    let fieldState = kagamiFileModel.state 
    let newControlValue = fieldState.controlValue ? fieldState.controlValue : '';
    CommonUtils.isNotEmpty(newControlValue) ? newControlValue =  newControlValue.split(',').map((item:any) => item.split(":")[0]).filter((item:any) => item) : newControlValue = [];
    newControlValue.splice(newControlValue.indexOf(fileId), 1); 
    FileUrlService.deleteImage(fileId).then((res:any) => console.log(res.status)).catch((err:any) => console.log(err))
  
    fieldState.setControlValue( newControlValue.join(','));
    notifyChangeToForm(kagamiFileModel,  newControlValue.join(","));
  
  }