import { KagamiApplicationModel, useApplicationState } from '../model/KagamiApplicationModel';
import { createState } from '@hookstate/core';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useEffect } from 'react';
import { validateToken } from '../builder/login/controller/KagamiLoginController';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from '../Router/Route';
import { Loader } from '../builder/home/builder/HomeBuilder';
import NetworkDetector from '../../networkDetector/ui/NetworkDetector';
import { onApplicationLoginFailure } from '../controller/KagamiApplicationController';

// const Home = lazy(() =>
//  import('../builder/home/controller/KagamiHomeController').then((ele: any) => ele.buildHome())
// );
// const Login = lazy(() =>
//   import('../builder/login/controller/KagamiLoginController').then(({ buildLoginPage }) => ({
//     default: buildLoginPage
//   }))
// );
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backGround: {
      // backgroundImage: `linear-gradient(to bottom,  rgba(59, 187, 214, 0.52),  rgba(122, 94, 178, 0.73)), url('/images/login/loginbackground.jpg')`,
      opacity: '3.5',
      position: 'fixed',
      minWidth: '100%',
      minHeight: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      // display: 'grid',
      placeItems: 'center',
      overflow: 'hidden'
    }
  })
);

// const routes = (loggedIn: any) => {
//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route exact path="/">
//           {loggedIn ? buildHome() : <Redirect to="/login" />}
//         </Route>
//         <Route exact path='./login'>
//            {!loggedIn  ?  buildLoginPage() : <Redirect to="/" />}
//         </Route>
//       </Switch>
//     </BrowserRouter>
//   );
// };

export const BuildApplication = (appModel: KagamiApplicationModel) => {
  const classes = useStyles();
  const appState = useApplicationState(createState(appModel));
  appModel.loaderModel.loading = true;
  const logoutChannel = appModel.logoutChannel
  useEffect(() => {
    if (appState.isUserLoggedIn == null) {
      validateToken();
    }
    /// Note : added this event listener to logout from all tabs 
    /// if the app got logger out in a single tab
    logoutChannel.addEventListener("message", (event : any) => {
      onApplicationLoginFailure();
    });

    return () => {
      logoutChannel.close(); // Close the channel when the component is unmounted
    };
  }, []);

  if (appState.isUserLoggedIn !== null) {
    {
      appState.isUserLoggedIn ? <Redirect to="/"></Redirect> : <Redirect to="/login"></Redirect>;
    }
  } else {
    return (
      <div className={classes.backGround}>
        <NetworkDetector />
        <div>
          <Loader loaderModel={appModel.loaderModel} />
        </div>
        {/* <KagamiWebSocket /> */}
      </div>
    );
  }
  return routes(appState);
};
