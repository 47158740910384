import * as React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiAboutUsStyle } from '../styles/KagamiInfoIconStyle';
import { createState } from '@hookstate/core';
import { KagamiKeyBoardShortCutsModel, KagamiKeyBoardShortCutsModelProps, useKagamiKeyBoardShortCutsModelState } from '../model/KagamiKeyBoardShortCutsModel';
import { buildModal } from '../../../../../../../components/modal/controller/KagamiModalController';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { translateToLocalistaion } from '../../../body/builder/process/service/PresentationService';

export const keyBoardShortCutsInfoIcon = (
    keyBoardShortCutsModel: KagamiKeyBoardShortCutsModel,
    translations?: any
  ) => {
    return <KeyBoardShortCutsInfo keyBoardShortCutsModel={keyBoardShortCutsModel} translations={translations}/>;
  };
;
const useStyles = makeStyles(KagamiAboutUsStyle);

const KeyBoardShortCutsInfo = (props: KagamiKeyBoardShortCutsModelProps) => {
  const classes = useStyles();
  const keyBoardShortCutsModel = props.keyBoardShortCutsModel;
  let popupModel = keyBoardShortCutsModel.kagamiModalModel;
  let translateWords = props?.translations;
  const keyBoardShortCutsModelState = useKagamiKeyBoardShortCutsModelState(createState(keyBoardShortCutsModel));
  keyBoardShortCutsModel.state = keyBoardShortCutsModelState;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);


  const onModalOkClickCall = () => {
    popupModel.state.setOpen(false);
  }

  const handleKeyBoardClick = (popupModel: any) => {
    popupModel.title = translateToLocalistaion(translateWords, 'KeyBoard Short Cut Keys For Application');
    popupModel.content = keyBoardShortKeys();
    popupModel.state.setOpen(true);
    popupModel.handleOnOk = onModalOkClickCall;
  };

  props.keyBoardShortCutsModel.kagamiModalModel.isInfo = true;

  const keyBoardShortKeys = () => {
    return (
      <>
        <Typography variant="caption" display="block" style={{ fontSize : '14px', textAlign:'left'}} >
            <ul>
                <li>Global Search: <b>Ctrl + Space Bar</b></li>
                <li>Focus Changes from One Field to Other Field: <b>Tab</b></li> 
                <li>Focus Changes Back from One Field to Other Field: <b>Shift + Tab</b></li> 
                <li>Remove Selected Value from Dropdown Fields: <b>Delete</b></li>
                <li>Navigate between Dropdown Values List: <b>Arrow Upward Key / Arrow Downward Key</b></li> 
                <li>To Select Value from Dropdown List: <b>Enter</b></li>
                <li>In Grid, Focus Changes from One Field to Other Field: <b>Tab / Ctrl + Right Arrow</b></li> 
                <li>In Grid, Focus Changes Back from One Field to Other Field: <b>Shift + Tab / Ctrl + Left Arrow</b></li>   
             </ul>
         </Typography>
       
      </>
    )
  }

  return (
    <>
      {buildModal(keyBoardShortCutsModel.kagamiModalModel)}{' '}
      <Tooltip title={translateToLocalistaion(translateWords, 'KeyBoard Short Cut Keys For Application')}  arrow>
      <IconButton onClick={()=> handleKeyBoardClick(keyBoardShortCutsModel.kagamiModalModel)} size="large">
        <KeyboardIcon />
      </IconButton>
      </Tooltip>
    </>
  );
}
