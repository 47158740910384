import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';

export class KagamiProfileModel {
  content: any;
  state: any;
  open: boolean;
  userDataForProfile: any;
  impersonate: string;
  constructor(content: any, userDataForProfile: any) {
    this.content = content;
    this.userDataForProfile = userDataForProfile;
    this.open = false;
    this.impersonate = localStorage.getItem('isImpersonation') ?? 'false';
  }
}

export interface KagamiProfileProps {
  kagamiProfileModel: KagamiProfileModel;
  translations?: any;
}

export const wrapKagamiHeaderProfileState = (state: State<KagamiProfileModel>) => {
  let profileState = state;

  return {
    get isOpen() {
      return profileState.open.get();
    },

    setOpen(isOpen: boolean) {
      profileState.open.set(isOpen);
    },

    get isImpersonate() {
      return profileState.impersonate.get();
    },

    setImpersonate(impersonate: string) {
      profileState.impersonate.set(impersonate);
    },

    get content() {
      return profileState.content.get();
    },

    setContent(content: any) {
      profileState.content.set(content);
    },

    get userDataForProfile() {
      return profileState.userDataForProfile.get();
    },

    setuserDataForProfile(userDataForProfile: any) {
      profileState.userDataForProfile.set(userDataForProfile);
    }
  };
};

export const useKagamiHeaderProfileState = (state: State<KagamiProfileModel>) => {
  let userprofileState = wrapKagamiHeaderProfileState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.PROFILE_STATE, userprofileState);
  return userprofileState;
};
