import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiListModel } from '../../../model/KagamiListModel';
import { KagamiHierarchyViewModel } from '../model/KagamiHierarchyViewModel';
import { KagamiLazyHierarchyViewModel } from '../model/KagamiLazyHierarchyViewModel';
import { buildHierarchyView } from '../ui/KagamiHierarchyView';

let kagamiHierarchyViewModel: KagamiHierarchyViewModel;
let kagamilazyhierarchyViewModel: KagamiLazyHierarchyViewModel;

export function buildHierarchy(hierarchyViewModel: KagamiHierarchyViewModel) {
  kagamiHierarchyViewModel = hierarchyViewModel;
  kagamilazyhierarchyViewModel = hierarchyViewModel;
  return buildHierarchyView(hierarchyViewModel);
}

export function reBulidHierarchyView(kagamiListModel : KagamiListModel, dataRecords: any, listIdentifier: any) {
  let hierarchyViewState = KagamiStateManager.getStateToHolder(SystemConstants.HIERARCHY_VIEW_STATE);
  hierarchyViewState.setTreeDataLoaded(false);
  kagamiListModel.detailedObjects = dataRecords;
  hierarchyViewState.setListLoaded(true);
}

export function getKagamiHierarchyModel() {
  return kagamiHierarchyViewModel;
}

export function getKagamiLazyHierarchyModel() {
  return kagamilazyhierarchyViewModel;
}