
import React from 'react'
import { ToastNotificationModel, ToastNotificationModelProps } from '../model/ToastNotificationModel'
import makeStyles from '@mui/styles/makeStyles';
import { ToastNotificationStyle } from '../style/ToastNotificationStyle';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

export function buildToast (toastNotificationModel : ToastNotificationModel) {
//  let component:JSX.Element =   buildToast(toastNotificationModel)
let toastId = toastNotificationModel.statusType + '_' + Math.floor(Math.random() * 10)
toastNotificationModel.toastId = toastId
    return setTimeout(() => {
        toast(
            (t) => <ToastNotification toastNotificationModel ={toastNotificationModel} toastProperties = {t} />,
            {
                id:toastId ,
              }
          );
       
      }, toastNotificationModel.timeOutDuration);


 
}
 
function ToastNotification(props:ToastNotificationModelProps) {
    const classes = makeStyles(ToastNotificationStyle)();
 
    switch (props.toastNotificationModel.statusType) {
        case 'success':
            
        return (
            
             <div className={classes.toastRoot}> 
             <div className = {classes.toastIcon}>
                <div className={classes.iconContainer} style={{background:'#9CE85E'}}> 
                <CheckCircleOutlineIcon color='success' fontSize='large'/>
                </div>
             </div>  
             <div className={classes.toastBody} style={{background:'#aafd6763'}}>
                 <Typography variant="subtitle1" style={{fontWeight:'bold', textTransform:'capitalize', lineHeight:'1.2'}} >
                     {props.toastNotificationModel.statusType}
                 </Typography>
                 <Typography variant="caption"   >
                     {props.toastNotificationModel.statusMessage}
                     </Typography>
             </div>
             <div className={classes.toastDelete} style={{background:'#aafd6763'}}>
                 <IconButton size='medium' onClick={() => toast.dismiss(props.toastNotificationModel.toastId)}>
                     <ClearIcon fontSize='medium' />
                 </IconButton>
             </div>
         </div>
        )
        case 'error':
            
        return (
            <div className={classes.toastRoot}> 
            <div className = {classes.toastIcon}>
               {/* <div className={classes.iconContainer} style={{background:'#9CE85E'}}> 
               <CheckCircleOutlineIcon color='success' fontSize='large'/>
               </div> */}
            <div className={classes.iconContainer} style={{background:'#FFC3C3'}}>
            <HighlightOffIcon color='error'  fontSize='large'/>
            </div>
            </div>  
            <div className={classes.toastBody}  style={{background:'#FFC3C385'}}>
                <Typography variant="subtitle1" style={{fontWeight:'bold', textTransform:'capitalize', lineHeight:'1.2'}} >
                    {props.toastNotificationModel.statusType}
                </Typography>
                <Typography variant="caption"   >
                    {props.toastNotificationModel.statusMessage}
                    </Typography>
            </div>
            <div className={classes.toastDelete}  style={{background:'#FFC3C385'}}>
                <IconButton size='medium' onClick={() => toast.dismiss(props.toastNotificationModel.toastId)}>
                    <ClearIcon fontSize='medium' />
                </IconButton>
            </div>
        </div>
        )
        case 'info':
            
        return (
            <div className={classes.toastRoot}> 
            <div className = {classes.toastIcon}>
            <div className={classes.iconContainer} style={{background:'#006e7f70'}}>
            <InfoOutlinedIcon sx={{color:'#33475B'}} fontSize='large'/>
            </div>
               {/* <div className={classes.iconContainer} style={{background:'#9CE85E'}}> 
               <CheckCircleOutlineIcon color='success' fontSize='large'/>
               </div> */}
            </div>  
            <div className={classes.toastBody} style={{background:'#2cabbf55'}}>
                <Typography variant="subtitle1" style={{fontWeight:'bold', textTransform:'capitalize', lineHeight:'1.2'}} >
                    {props.toastNotificationModel.statusType}
                </Typography>
                <Typography variant="caption"   >
                    {props.toastNotificationModel.statusMessage}
                    </Typography>
            </div>
            <div className={classes.toastDelete} style={{background:'#2cabbf55'}}>
                <IconButton size='medium' onClick={() => toast.dismiss(props.toastNotificationModel.toastId)}>
                    <ClearIcon fontSize='medium' />
                </IconButton>
            </div>
        </div>
        )
        case 'warning':
            
        return (

<div className={classes.toastRoot}> 
<div className = {classes.toastIcon}>
            <div className={classes.iconContainer} style={{background: '#ed6c0263'}}>
                <ReportProblemOutlinedIcon color='warning' fontSize='large'/>
            </div>
   {/* <div className={classes.iconContainer} style={{background:'#9CE85E'}}> 
   <CheckCircleOutlineIcon color='success' fontSize='large'/>
   </div> */}
</div>  
<div className={classes.toastBody}>
    <Typography variant="subtitle1" style={{fontWeight:'bold', textTransform:'capitalize', lineHeight:'1.2'}} >
        {props.toastNotificationModel.statusType}
    </Typography>
    <Typography variant="caption"   >
        {props.toastNotificationModel.statusMessage}
        </Typography>
</div>
<div className={classes.toastDelete}>
    <IconButton size='medium' onClick={() => toast.dismiss(props.toastNotificationModel.toastId)}>
        <ClearIcon fontSize='medium' />
    </IconButton>
</div>
</div>
        ) 

    default:
        return (<></>)
    }


//   return (
   
//     <>
//     <div className={classes.toastRoot}> 
//         <div className = {classes.toastIcon}>
//             {icon}
//         </div>  
//         <div className={classes.toastBody}>
//             <Typography variant="subtitle1" style={{fontWeight:'bold', textTransform:'capitalize', lineHeight:'1.2'}} >
//                 {props.toastNotificationModel.statusType}
//             </Typography>
//             <Typography variant="caption"   >
//                 {props.toastNotificationModel.statusMessage}
//                 </Typography>
//         </div>
//         <div className={classes.toastDelete}>
//             <IconButton size='medium' onClick={() => toast.dismiss(props.toastNotificationModel.toastId)}>
//                 <ClearIcon fontSize='medium' />
//             </IconButton>
//         </div>
//     </div>
//     </>
//   )
}

 