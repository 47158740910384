import { notifyChangeToForm } from '../../../application/builder/home/builder/body/builder/process/builder/form/controller/KagamiFormController';
import { SystemConstants } from '../../../application/constants/SystemConstants';
import { APIService } from '../../../application/service/ApiService';
import { getActivityLogUrl, getListChatUrl, getStatusInfoUrl } from '../../../application/service/URLService';
import { showLoader, hideLoader } from '../../loader/controller/KagamiLoaderController';
import { rebuildActivityLogModal } from '../controller/KagamiActivityLogModalController';
import { KagamiActivityLogControlModel } from '../model/KagamiActivityLogModel';

export function getActivityLogData(data: any, activityLogModel: any) {
  showLoader();
  APIService.getData(SystemConstants.POST, getActivityLogUrl(), data)
    .then((response: any) => {
      hideLoader();
      if (data.action === 'read') {
        activityLogModel.activityLogContainer = response.data.activityLogContainer;
        rebuildActivityLogModal(activityLogModel);
      }
      if (data.action === 'create' || data.action === 'update') {
        if (activityLogModel instanceof KagamiActivityLogControlModel) {
          activityLogModel.referenceId = response.data.referenceId;
          activityLogModel.controlValue = response.data.referenceId;
          notifyChangeToForm(activityLogModel, response.data.referenceId);
        }
      }
      if (response.data.result === 'success') rebuildActivityLogModal(activityLogModel);
    })
    .catch((err: any) => {
      hideLoader();
      console.log(err.message);
    });
}

export async function getStatusInfo(entityId: string) {
  const apiResonse = await APIService.getData(SystemConstants.POST, getStatusInfoUrl(entityId), null).then(
    (res: any) => res.data
  );
  return apiResonse;
}

export async function getListChatInfo() {
  const apiResonse = await APIService.getData(SystemConstants.POST, getListChatUrl(), null).then(
    (res: any) => res.data
  );
  return apiResonse;
}
