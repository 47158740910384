 
import { State, useState } from "@hookstate/core"; 
import { KagamiStateManager } from "../../../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../../../constants/SystemConstants";
import { KagamiListFilterModel } from "../../../model/KagamiListFilterModel";
 
 
export class KagamiClearFiltersButtonModel {
  
enableClearFiltersButton : boolean;
state: any
  constructor() {
      this.enableClearFiltersButton = false

    }
}
export interface KagamiClearButtonProps {
  clearFilterButtonModel: KagamiClearFiltersButtonModel;
    // kagamiListModel:KagamiListModel;
    kagamiListFilterModel:KagamiListFilterModel
}



export const wrapClearFilterButtonModelState = (state: State<KagamiClearFiltersButtonModel>) => {
  let filterState =  state;

  return {
      get isEnabled(){
         return filterState.enableClearFiltersButton.get();
      },

      SetIsEnabled(value :boolean){
          filterState.enableClearFiltersButton.set(value)
      },
      

  };
};

export const useClearFilterButtonModelState = (state: State<KagamiClearFiltersButtonModel>) => {
  const clearFilterState = wrapClearFilterButtonModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE, clearFilterState)
  return clearFilterState
}
