import { State, useState } from "@hookstate/core";
import { KagamiModalModel } from "../../../../../../../../../../../../components/modal/model/KagamiModalModel";
import { KagamiStateManager } from "../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../constants/SystemConstants";
import { KagamiListModel } from "../../../model/KagamiListModel";

export class KagamiKanbanSectionsViewModel extends KagamiListModel {
    popupModel : KagamiModalModel;
    constructor(processName: string, processStepName: string, mainEntityId: string, presentation: any) {
      super(processName, processStepName, null, mainEntityId);
      this.state = null;
      this.presentation = presentation;
      this.popupModel = new KagamiModalModel()
    }
  }
  export interface KagamiKanbanSectionsViewModelProps {
    kagamikanbannViewModel: KagamiKanbanSectionsViewModel;
  }

  export const wrapKagamiKanbanState = (state: State<KagamiKanbanSectionsViewModel>) => {
    let kanbanState = state;
  
    return {
      getListLoaded() {
        return kanbanState.isListLoaded.get();
      },
      setListLoaded(value: boolean) {
        kanbanState.isListLoaded.set(value);
      }
    };
  };
  
  export const useKagamiKanbanState = (state: State<KagamiKanbanSectionsViewModel>) => {
    const kagamiKanbanState = wrapKagamiKanbanState(useState(state));
    KagamiStateManager.addStateToHolder(SystemConstants.KANNBANN_VIEW_STATE, kagamiKanbanState);
    return kagamiKanbanState;
  };
