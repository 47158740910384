
import { useState, State } from "@hookstate/core";
import { KagamiListFiltersPopoverModel } from "../../../../../../../../../../../../../../components/ListFilters_Popover/model/KagamiListFiltersModelPopover";
import { KagamiStateManager } from "../../../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../../../constants/SystemConstants";
import { KagamiListModel } from "../../../../../model/KagamiListModel";
import { KagamiListFilterModel } from "../../../model/KagamiListFilterModel";


export class KagamiPresentationFilterModel {
  dataRecordsForPresentationAttribute:any;
  filterDataForSearchableAttributes:any;
  // kagamiListModel:any;
  attributeName :string;
  // listFilterpopoverModel: KagamiListFiltersPopoverModel;

  checkedList: any[];
  searchProcessName:string;
  state: any
  constructor(dataRecordsForPresentationAttribute:any , filterDataForSearchableAttributes:any ,attributeName:string) {
     this.dataRecordsForPresentationAttribute = dataRecordsForPresentationAttribute;
     this.filterDataForSearchableAttributes = filterDataForSearchableAttributes;
    //  this.kagamiListModel = kagamiListModel;
     this.attributeName = attributeName;

     this.checkedList=[];
     this.searchProcessName = ''
    }
}
export interface KagamiPresentationModelProps {
  kagamiPresentationFilterModel: KagamiPresentationFilterModel;
  kagamiListFilterModel: KagamiListFilterModel
}

 


export const wrapPresentationFiltersState = (state: State<KagamiPresentationFilterModel>) => {
  let filterState = useState(state)  
  return {
      get checkedList() {
        return filterState.checkedList.get();
      },

      SetCheckedList(value: string[]) {
        filterState.checkedList.set(value)
      },




      get searchProcessName() {
        return filterState.searchProcessName.get();
      },

      SetSearchProcessName(value: string) {
        filterState.searchProcessName.set(value)
      }
       

  };
};


export const usePresentationFiltersState =  (state: State<KagamiPresentationFilterModel>) => {
  const clearListState = wrapPresentationFiltersState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE_UPDATER, clearListState)
  return clearListState
}
 

// export class DataFilterModel  {
//   filtersupdate: boolean;
//   state:any
//   constructor() {
//     this.filtersupdate = false
//   }

// }

export interface GetListFilterListProps{
  kagamiListModel:KagamiListModel;
  kagamiPresentationFilterModel:KagamiPresentationFilterModel;
  kagamiListFilterModel:KagamiListFilterModel;
  // clearModel:DataFilterModel
}
// export const wrapDataFilterState = (state: State<DataFilterModel>) => {
//   let filterContentstate = state
//   return {
//     get filterUpdata(){
//       return filterContentstate.filtersupdate.get()
//     },
//     SetFiltersUpdate(value: boolean) {
//       filterContentstate.filtersupdate.set(value)
//     }
//   }
// }
// export const useDynamicDataFilterState =  (state: State<DataFilterModel>) => {
//   const clearListState = wrapDataFilterState(useState(state));
//   KagamiStateManager.addStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE_UPDATER, clearListState)
//   return clearListState
// }
 
