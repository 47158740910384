import { State, useState } from '@hookstate/core';
import { KagamiErrorModalModel } from '../../../../components/errorModal/model/KagamiErrorModalModel';
import { KagamiLoaderModel } from '../../../../components/loader/model/KagamiLoaderModel';
import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants'; 
import { KagamiModalDialogModel } from '../builder/body/builder/process/builder/modal-dialog/model/KagamiModalDialogModel';
import { KagamiBodyModel } from '../builder/body/model/KagamiBodyModel';
import { KagamiNavigatorModel } from '../builder/navigator/model/KagamiNavigatorModel';

export class KagamiHomeModel {
  navigatorModel: KagamiNavigatorModel;
  bodyModel: KagamiBodyModel;
  // collapseSidebar: boolean;
  // showSidebar: boolean;
  // pageSidebarComponent: JSX.Element | null;
  // pageHeaderComponent: any;
  pageContentComponent: any;
  // pageFooterComponent?: any;
  errorModal: KagamiErrorModalModel;
  loaderModel : KagamiLoaderModel;
  modalDialogModel: KagamiModalDialogModel;
  logodata: any;
  isLogoVisible: boolean;
  state: any;
  executeLinkProcess : boolean;
  linkProcessId : string;

  constructor() {
    this.navigatorModel = new KagamiNavigatorModel();
    this.bodyModel = new KagamiBodyModel();
    // this.collapseSidebar = true;
    // this.showSidebar = false;
    // this.pageSidebarComponent = null;
    this.state = null;
    this.errorModal = new KagamiErrorModalModel();
    this.loaderModel = new KagamiLoaderModel();
    this.modalDialogModel = new KagamiModalDialogModel();
    this.isLogoVisible = false;
    this.executeLinkProcess  = false;
    this.linkProcessId = '';
  }
}

 
export const wrapHomeState = (state: State<KagamiHomeModel>) => {
  const homeState = state;

  return {
    // get collapseSidebar() {
    //   return homeState.collapseSidebar.get();
    // },

    // drawerNeeded(drawerNeeded: boolean) {
    //   homeState.collapseSidebar.set(drawerNeeded);
    // },

    // get showSidebar() {
    //   return homeState.showSidebar.get();
    // },

    // sidebarNeeded(sidebarNeeded: boolean) {
    //   homeState.showSidebar.set(sidebarNeeded);
    // },

    get pageContent() {
      return homeState.pageContentComponent.get();
    },
    setPageContent(pageContent: JSX.Element) {
      homeState.pageContentComponent.set(pageContent);
    },

    setLogoVisible(isLogoVisible: boolean){
      homeState.isLogoVisible.set(isLogoVisible);
    },

    get isLogoVisible(){
      return homeState.isLogoVisible.get();
    },

    setLogodata(logodata: any){
      homeState.logodata.set(logodata);
    },

    get logodata(){
      return homeState.logodata.get();
    }
  };
};

export const useHomeState = (state: State<KagamiHomeModel>) => {
  const homeState = wrapHomeState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.HOME_STATE, homeState);
  return homeState;
};

export interface HomeModelProps {
  homeModel: KagamiHomeModel;
}
