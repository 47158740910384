import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { getKagamiListModel, reBuildTriggers } from '../../../controller/KagamiListController';
import { KagamiCalendarViewModel } from '../model/KagamiCalendarViewModel';
import { BuildCalendarView } from '../ui/KagamiCalendarView';

let calendarViewModel: KagamiCalendarViewModel;

export function buildCalendar(kagamiCalendarViewModel: KagamiCalendarViewModel) {
  calendarViewModel = kagamiCalendarViewModel;
  return BuildCalendarView(kagamiCalendarViewModel);
}

export function getCalendarViewModel() {
  return calendarViewModel;
}

export function reBuildCalendarView(responses: any) {
  getKagamiListModel().detailedObjects = responses;
  let calendarListState = KagamiStateManager.getStateToHolder(SystemConstants.CALENDAR_STATE);
  calendarListState.setListLoaded(true);
}

export function buildEditFormForCalendarView(id: any, selectedObject: any) {
  console.log(id);
  if (selectedObject && selectedObject.id === id) {
    getKagamiListModel().selectedObjects = [selectedObject];
    reBuildTriggers();
  }
}
