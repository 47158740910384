import { KagamiSelectModel } from '../model/kagamiSelectModel'
import { KagamiModalModel } from '../../../../../../../../../../../components/modal/model/KagamiModalModel';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiMultiSelectContentStyle } from '../styles/KagamiMultiSelectContentStyle';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { extractDropdownOption } from '../../../../service/PresentationService';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { validateSelectTextBox } from '../controller/kagamiSelectController';

export function buildMultiSelectContent (fieldState: any, kagamiSelectModel: KagamiSelectModel, popupModel: KagamiModalModel) {
    return <MultiSelectContent fieldState={fieldState} kagamiSelectModel={kagamiSelectModel} popupModel={popupModel} />
}
 
const useStyles = makeStyles(KagamiMultiSelectContentStyle);

function EnhancedTableHead(props: any) {
  const classes = useStyles()
  const {  onRequestSort, fieldState , popupModel} = props;
  const order = fieldState.order
  const orderBy= fieldState.orderBy
    const createSortHandler = (property: any) => (event: any) => {
      onRequestSort(event, property);
    };
  
    const headCells = () => {
      let headers = props?.kagamiSelectModel?.attributePresentation?.displayAttributes?.map((item: any) => {
        return {
          id: item,
          numeric: false,
          disablePadding: true,
          label: item
        }
      })
      return headers
    };
  
    return (
      <> 
      {
        popupModel.state.isNonEditableList  
        ? <>
        <TableHead>
        <TableRow>
          {headCells()?.map((headCell: any) => (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'normal'}
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : 'asc'}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      </> : <>
      <TableHead >
        <TableRow>
          <TableCell>
          S.No
          </TableCell>
          <TableCell style= {{textTransform: 'capitalize'}}>
            {props.kagamiSelectModel?.controlDisplayName ? props.kagamiSelectModel.controlDisplayName : null}
            <TextField
            className={classes.searchFiled}
                  type="text"
                  size="small"             
                  value={fieldState.search}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment  position="end">
                        <SearchIcon fontSize='small' />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => fieldState.setSearch(e.target.value)}
                /> 
          </TableCell>
          <TableCell>
          Actions
          </TableCell>
        </TableRow>
      </TableHead>
      </>
      }
      </>
    );
  }
  
  function MultiSelectContent(props:any) {
    const classes = useStyles();
    const handleRequestSort = (event: any, property: any) => {
        const isAsc = props?.fieldState?.orderBy === property && props?.fieldState?.order === 'asc';
        props?.field?.setOrder(isAsc ? 'desc' : 'asc');
        props?.fieldState?.setOrderBy(property);
      };

      const handleClick = (event: any, name: any) => {
        let selected = props?.fieldState?.selected;
        const selectedIndex = selected.indexOf(name);
        let newSelected: any = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        props?.fieldState?.setSelected(newSelected);
      };
      
      const rows = props?.fieldState?.getMultiSelectSelectedValues?.map((item: any) =>
      extractDropdownOption(props?.kagamiSelectModel?.attributePresentation, item)
      );   

      const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
          const newSelecteds = rows?.map((n: any) => n?.name);
          props?.fieldState?.setSelected(newSelecteds);
          return;
        }
        props?.fieldState?.setSelected([]);
      };

  const isSelected = (name: any) => props?.fieldState?.selected?.indexOf(name) !== -1;
    
  const filteredSelectedValues = JSON.parse(JSON.stringify(props?.fieldState?.getMultiSelectPopoverFilteredValues));

  const itemDeleteHandler = (item: any) => {
    props.fieldState?.setModalDeletedValue(item?.id)
  };
  
  const onCloseHandler = () => {
    if (props?.popupModel?.state?.isNonEditableList) {
      props.popupModel.state.setOpen(false);
    } else {
      if (props.kagamiSelectModel.selectValues.length === filteredSelectedValues?.length) {
        props.popupModel.state.setOpen(false);
      }
      else {
        let multiSelectValues = JSON.parse(JSON.stringify(props.fieldState?.getMultiSelectSelectedValues))
        let multiSelectDeleteValues = JSON.parse(JSON.stringify(props?.fieldState?.getMultiSelectDeleteValues))
        let values = multiSelectValues?.filter((item: any) => !multiSelectDeleteValues?.includes(item?.id))
        let previousValues = props?.kagamiSelectModel?.selectValues?.map((item: any) => item?.id);
        let selectedValues = values?.map((item: any) => item?.id);
        for (var itemId of previousValues) {
          if (!selectedValues.includes(itemId) && !selectedValues.includes("remove")) {
            values.push({ id: 'removed_' + itemId });
          }
        }
        props?.fieldState.setControlValue(values);
        props?.fieldState?.setSelectValues(values)
        props.popupModel.state.setOpen(false);
        validateSelectTextBox(props.kagamiSelectModel, values);
        props.fieldState.setModalDeleteValueDefault();
        props.fieldState.setModalSearchValueDefault();
      }
    }
  };

  props.popupModel.handleOnClose = onCloseHandler;

  return (
   <div>
              <TableContainer className={classes.tableBorder}>
                <Table  aria-labelledby="tableTitle" size={'medium'}>
                  <EnhancedTableHead
                    numSelected={props?.fieldState?.selected?.length}
                    popupModel = {props.popupModel}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    kagamiSelectModel={props?.kagamiSelectModel}
                    fieldState = {props.fieldState}
                  />
                  <TableBody>
                  {
                  props.popupModel.state.isNonEditableList 
                  ? <> 
                  {CommonUtils.stableSort(rows, CommonUtils.getComparator(props?.fieldState?.order, props?.fieldState?.orderBy))?.map(
                    (row: any, index: number) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                          onClick={(event: any) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                      {
                          row.split("- ").map((item: any,i: number)=>{
                            return(
                              <TableCell className={classes.column1}  key={i} component="th" id={labelId} scope="row" padding="normal">
                            {item} 
                            </TableCell>
                            )
                          })
                        }
                        </TableRow>
                      );
                    }
                  )}
                  </> : <>  
                  {filteredSelectedValues.map((item:any, index: number) => {
                  return (
                      <TableRow 
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                      <TableCell className={classes.column1} component="th" scope="row" > {index + 1} </TableCell>
                      <TableCell className={classes.column1} component="th" scope="row" >  {extractDropdownOption(props?.kagamiSelectModel?.attributePresentation, item)} </TableCell>
                      <TableCell className={classes.column1} component="th" scope="row"  >  
                        <IconButton aria-label="delete" onClick={() => itemDeleteHandler(item)}>
                            <DeleteIcon />
                        </IconButton>  
                      </TableCell>
                      </TableRow>
                    )
                  })}
                  </>
                  }
                  </TableBody>
                </Table>
              </TableContainer>
                  {
                  !props.popupModel.state.isNonEditableList 
                  && <Typography variant ="subtitle1" color='primary' textAlign='start' marginTop = '10px' >
                  Records {filteredSelectedValues?.length}
                  </Typography>  
                  }
              </div>     
  )
}

 
 
