import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const reportsLandingPageStyle = (theme: Theme) =>
  createStyles({
    reportTileRoot: {
      'backgroundColor': theme.palette.background.paper,
      'border': '1px solid transparent',
      'cursor': 'pointer',
      'borderRadius': theme.spacing(1),
      'height': '175px',
      'minWidth': '0',
      'boxShadow': 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
      '&:hover': {
        '& .reporticon': {
          height: '70px',
          transition: '0.2s all'
        }
      }
    },

    reportIcon: {
      height: '65px'
    },
    divider: {
      height: theme.spacing(0.01),
      backgroundColor: theme.palette.grey[500]
    },
    report_header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    report_title: {
      padding: `0 ${theme.spacing(1)}`,
      paddingTop: theme.spacing(1),

      display: 'block',
      lineHeight: '1.2',
      fontSize: '0.9rem',
      fontWeight: '500',
      // whiteSpace: 'nowrap',
      // width: '90%',
      textAlign: 'center'
    },
    report_icon: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    folder_icon: {
      position: 'absolute',
      top: '46px',
      right: '0px',
      width:'20%'
    },
    report_filter: {
      width: '100%',
      height: '6vh',
      background: 'white',
      border: '1px solid black',
      elevation: '1px',
      margin: '3px',
      padding: '4px'
    },
    report_image_box: {
      width: '100%',
      height: '70px',
      textAlign: 'center'
    },

    reports_landing_page_header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      background: 'transparent',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 10%)',
      // maxHeight: theme.spacing(7.5),
      width: 'auto',
      height: '40px'
    },

    reports_landing_page_header_left: {
      display: 'flex'
    },

    reports_landing_page_header_right: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    back_button: {
      'cursor': 'pointer',
      'margin': '10px',
      'position': 'inherit',
      'float': 'right',
      'backgroundColor': 'white',
      'color': 'green',
      'border': '2px solid #dbdbdb',
      'transitionDuration': '0.4s',
      'width': '50px',
      'height': '30px',
      'borderRadius': '5px',
      '&:hover': {
        backgroundColor: 'white',
        color: 'blue',
        border: '2px solid  #6ebe44'
      }
    },

    search_bar: {
      'margin': 'auto 20px',
      'color': '#dbdbdb',
      '& .MuiOutlinedInput-root ': {
        height: '30px',
        backgroundColor: theme.palette.background.paper
      }
    },

    folderTileRoot: {
      margin: '0 auto',
      height: '175px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative'
    },

    folderIcon: {
      cursor: 'pointer',
      height: '110px',
      minWidth: '140px'
    },
    folderImg:{
      height: '71px',
      minWidth: '80px',
      position:'absolute',
      top:'44px',
      left:'25px',
      width:'61%'

    },
 folderTitle: {
      color: '#2d72b9',
      fontSize: theme.spacing(1.6),
      fontWeight: 600,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      whiteSpace: 'nowrap',
      width: '125px',
      textAlign: 'center',
      marginTop: '0px'
    },
    ReportsHeaderName: {
      color: theme.palette.primary.dark,
      margin: '8px',
      fontSize: theme.spacing(2.2),
      fontWeight: 500
    },
    Tabs: {
      'minWidth': '30px',
      'fontSize': '13px',
      'padding': '8px 0',
      'marginRight': '10px',
      'marginTop': '-2px',
      '&:hover': {
        backgroundColor: 'transparent',
        borderBottom: '2px solid #8dc63f',
        marginTop: '0'
      }
    },
    popoverReport: {
      'display': 'grid',
      '& .MuiPaper-root-MuiPopover-paper': {
        width: '36px',
        padding: '5px',
        overflow: 'none',
        wordBreak: 'break-word'
      }
    }
  });
