import moment from 'moment';
import { CommonUtils } from '../../../application/utils/CommonUtils';
import { KagamiActivityLogControlModel, KagamiActivityLogModel } from '../model/KagamiActivityLogModel';
import { getActivityLogData } from '../service/ActivityLogService';
import { buildActivityControlForForm, buildActivityControlForKanban, buildActivityLog } from '../ui/ActivityLogModal';

let actLogModel: KagamiActivityLogModel;

export function buildActivityLogControl(activityLogModel: KagamiActivityLogControlModel) {
  return buildActivityControlForForm(activityLogModel);
}

export function buildActivityControlKanban(activityLogModel: KagamiActivityLogModel) {
  return buildActivityControlForKanban(activityLogModel);
}
export function setKagamiActivityLogModel(newActModel: KagamiActivityLogModel) {
  actLogModel = newActModel;
}
export function getKagamiActivityLogModel() {
  if (CommonUtils.isEmpty(actLogModel)) {
    actLogModel = new KagamiActivityLogModel();
    return actLogModel;
  } else {
    return actLogModel;
  }
}

export function buildActivityLogModal(activityLogModel: KagamiActivityLogModel) {
  //setActivivtyLogModel is needed for kanban view
  setKagamiActivityLogModel(activityLogModel);
  return buildActivityLog(activityLogModel);
}

export function handleActivityLogModalOnClose(activityLogModel: KagamiActivityLogModel) {
  activityLogModel.activityLogContainer = {};
  activityLogModel.isDataLoaded = false;
  activityLogModel.state.setOpen(false);
}

export function rebuildActivityLogModal(activityLogModel: KagamiActivityLogModel) {
  activityLogModel.state.setDataLoaded(true);
}

export function submitNewComent(newActivityComent: any, activityLogModel: KagamiActivityLogModel) {
  const { state, isEditMode, isReplyMode, selfrefActivity, ...activity } = newActivityComent;

  activityLogModel.activityLogContainer.activityList.push(activity);
  activityLogModel.action = 'update';
  let data = {
    referenceId: activityLogModel.referenceId,
    entityId: activityLogModel.entityId,
    action: activityLogModel.action,
    activityLogContainer: activityLogModel.activityLogContainer
  };
  getActivityLogData(data, activityLogModel);
}

export function handleEditComment(editactivity: any, activityLogModel: KagamiActivityLogModel) {
  const { state, isEditMode, isReplyMode, ...activity } = editactivity;
  let activities = activityLogModel.activityLogContainer.activityList;

  activityLogModel.action = 'update';
  let data = {
    referenceId: activityLogModel.referenceId,
    entityId: activityLogModel.entityId,
    action: activityLogModel.action,
    activityLogContainer: activityLogModel.activityLogContainer
  };
  const newActivityList = editHelperFunction(editactivity, activities);
  activityLogModel.activityLogContainer.activityList = newActivityList;
  getActivityLogData(data, activityLogModel);
}

export function handleReply(reply: any, Replyactivity: any, activityLogModel: KagamiActivityLogModel) {
  const { state, isEditMode, isReplyMode, ...pointedActivity } = Replyactivity;

  let activities = activityLogModel.activityLogContainer.activityList;

  activityLogModel.action = 'update';
  let data = {
    referenceId: activityLogModel.referenceId,
    entityId: activityLogModel.entityId,
    action: activityLogModel.action,
    activityLogContainer: activityLogModel.activityLogContainer
  };
  const newactivityList = replyingHelperFunction(reply, pointedActivity, activities);

  activityLogModel.activityLogContainer.activityList = newactivityList;

  getActivityLogData(data, activityLogModel);
}

function deleteActivtyHelper(data1: any, key1: any) {
  function removeKey(data: any, key: any) {
    const index = data.findIndex((x: any) => x.createDateTime === key);
    if (index > -1) {
      data.splice(index, 1);
      return true;
    } else {
      return data.some((x: any) => {
        if (x.selfrefActivity) {
          return removeKey(x.selfrefActivity, key);
        } else {
          return false;
        }
      });
    }
  }
  removeKey(data1, key1);
  console.log(removeKey(data1, key1));
  console.log(data1);
  return data1;
}

export function handleDeleteComment(delactivity: any, activityLogModel: KagamiActivityLogModel) {
  const { state, isEditMode, isReplyMode, ...dActivity } = delactivity;
  const newActivityList = deleteActivtyHelper(
    activityLogModel.activityLogContainer.activityList,
    dActivity.createDateTime
  );

  activityLogModel.action = 'update';
  let data = {
    referenceId: activityLogModel.referenceId,
    entityId: activityLogModel.entityId,
    action: activityLogModel.action,
    activityLogContainer: activityLogModel.activityLogContainer
  };

  activityLogModel.activityLogContainer.activityList = newActivityList;
  getActivityLogData(data, activityLogModel);
}

const replyingHelperFunction = (reply: any, pointedActivity: any, activityList: any) => {
  const { state, isEditMode, isReplyMode, selfrefActivity, ...replyobj } = reply;

  return buildHelper(replyobj, pointedActivity, activityList);
};

function buildHelper(replyobj: any, Activity: any, activityList: any) {
  const { state, isEditMode, isReplyMode, ...pointedActivity } = Activity;

  return activityList.map((activity: any) => {
    if (activity.createDateTime === pointedActivity.createDateTime) {
      if (activity.selfrefActivity) {
        activity.selfrefActivity.push(replyobj);
        return activity;
      } else {
        activity.selfrefActivity = [];
        activity.selfrefActivity.push(replyobj);
        return activity;
      }
    } else {
      if (Array.isArray(activity.selfrefActivity) && activity.selfrefActivity.length) {
        activity.selfrefActivity = replyingHelperFunction(replyobj, pointedActivity, activity.selfrefActivity);
        return activity;
      } else {
        return activity;
      }
    }
  });
}

const editHelperFunction = (editedActivity: any, activityList: any) => {
  const { state, isEditMode, isReplyMode, selfrefActivity, ...editObj } = editedActivity;

  return activityList.map((activity: any) => {
    if (editObj.createDateTime === activity.createDateTime) {
      activity.message = editObj.message;
      activity.updateDateTime = moment().format();

      return activity;
    } else {
      if (Array.isArray(activity.selfrefActivity) && activity.selfrefActivity.length) {
        activity.selfrefActivity = editHelperFunction(editedActivity, activity.selfrefActivity);
        return activity;
      } else {
        return activity;
      }
    }
  });
};
