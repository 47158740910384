import { APIService } from '../../../../../../../service/ApiService';
import { getAsyncBpmURL, getbpmURL, linkProcessURL } from '../../../../../../../service/URLService';
import { RequestEvent } from '../model/RequestEvent';
import { RuntimeInput } from '../model/RuntimeInput';
import {
  createEmbedColumnSearchEvent,
  createExplicitAssignEvent,
  createExplicitEvent,
  createLinkProcessRequestEvent,
  createOnCancelEvent,
  createOnChangeEvent,
  createOnClickEvent,
  createOnloadEvent,
  createOnSearchEvent,
  createOnSearchFiltersListEvent,
  createOnSearchListEvent,
  createOnStartEvent,
  createOnSubmitEvent
} from './ServerEventService';
import { KagamiProcessRequestModel } from '../model/KagamiProcessRequestModel';
import { NavigationService } from './NavigationService';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { processPageResponse } from '../builder/ProcessPageBuildler';
import { getActiveModel, loadProcessContent, onSubmitCall } from '../controller/KagamiProcessController';
import { showServerError } from '../builder/form/controller/KagamiFormController';
import { hideLoader, showLoader } from '../../../../../../../../components/loader/controller/KagamiLoaderController';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { buildModalDialogContent } from '../builder/modal-dialog/builder/KagamiModalDialogContentBuilder';
import { loadGroupContent } from '../builder/group/controller/KagamiGroupController';
import each from 'lodash/each';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import { getEntityIdForPresentation, getMainEntityIdForPresentation, getMappedEntityIdForPresentation, getUiResourceFromPresentation, isModalListView } from './PresentationService';
import { KagamiSubProcessRequestModel } from '../model/KagamiSubProcessRequestModel';


export function onOutBoundCall(currentProcessModel: KagamiProcessRequestModel) {
  showLoader();
  NavigationService.navigateTo(currentProcessModel)
    .then((processResponseModel: any) => {
      hideLoader();
      if (processResponseModel instanceof KagamiProcessResponseModel) {
        /// Note : ModalListView is an implementation which provides modal popup list view of main list view context trigger actions
        let modalListView : boolean = processResponseModel.templateType == 'noUI' ? false : isModalListView(processResponseModel)
        if (processResponseModel.onErrorData != null) {
          currentProcessModel.onError(processResponseModel.onErrorData);
        } else if (processResponseModel.templateType == 'noUI') {
          const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
            processResponseModel.processName,
            processResponseModel.isGroupTemplateNetworkCall ? loadGroupContent :  loadProcessContent,
            showServerError
          );
          processRequestModel.fromDashboard = false;
          processRequestModel.data = null;
          processRequestModel.submitCall = true;
          processRequestModel.processStepName = processResponseModel.processStepName;
          processRequestModel.isToastMessageHide = false;
          if(processResponseModel.isGroupTemplateNetworkCall) processRequestModel.isGroupTemplateNetworkCall = true;

          onSubmitCall(processRequestModel);
        } else if (processResponseModel.templateType == 'modalDialog' || modalListView) {
          let activeContextModel = getActiveModel();
          buildModalDialogContent(processResponseModel, activeContextModel,currentProcessModel.fromDashboard,modalListView);
        } else {
          currentProcessModel.templateType = processResponseModel.templateType;
          if (currentProcessModel.isGroupTemplateNetworkCall) processResponseModel.isGroupTemplateNetworkCall = true;
          const content: JSX.Element | null = processPageResponse(processResponseModel);

          currentProcessModel.onSuccess(content);
          
        }
      } else {
        console.log('error while creating page model');
      }
    })
    .catch((err) => {
      hideLoader();
      console.log(err);
    });
}

export async function makeOnStartCall(
  processName: string,
  data: any,
  fromDashboard: boolean,
  previousProcessName: string,
  processModel?: KagamiProcessRequestModel
) {
  let requestEvent = createOnStartEvent(processName, data, previousProcessName, fromDashboard);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnLoadCall(
  processName: string,
  entityPresentation: any,
  changedData: any,
  data: any,
  runtimeInput: RuntimeInput | null,
  verbProperties?: any,
  isHierarchy: boolean = false,
  embeddedInfo : any = {},
) {
  let requestEvent = createOnloadEvent(
    processName,
    entityPresentation,
    changedData,
    data,
    runtimeInput,
    verbProperties,
    isHierarchy,
    embeddedInfo
  );
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnChangeCall(
  processName: string,
  changedAttribute: string,
  entityPresentation: any,
  changedData: any,
  data: any,
  formModel?:any,
  control?:any
) {
  let requestEvent: any = createOnChangeEvent(processName, changedAttribute, entityPresentation, changedData, data, control);
  if(formModel && control){
    // setEmbeddedInfo(requestEvent,formModel,control);
  }
  // localStorage.setItem(
  //   SystemConstants.ATTRIBUTESEQUENCE,
  //   JSON.stringify(requestEvent.inputData.properties.attrSequence)
  // );
  let attrObj = {
    attrName: requestEvent.inputData.properties.attrSequence.attrName,
    timeStampKey: requestEvent.inputData.properties.attrSequence.timeStampKey
  };
  let clientsAttribute=[];
  if (!localStorage.getItem(SystemConstants.ATTRIBUTESEQUENCE)) {
    clientsAttribute.push(attrObj);
    localStorage.setItem(SystemConstants.ATTRIBUTESEQUENCE, JSON.stringify(clientsAttribute));
  } else {
    let attributeKeys = localStorage.getItem(SystemConstants.ATTRIBUTESEQUENCE)
    if( attributeKeys !== null){
      clientsAttribute = JSON.parse(attributeKeys);
    }
    clientsAttribute.push(attrObj);
    localStorage.setItem(SystemConstants.ATTRIBUTESEQUENCE, JSON.stringify(clientsAttribute));
  }
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnCancelCall(processName: string, entityPresentation: any, changedData: any, data: any) {
  let requestEvent: RequestEvent = createOnCancelEvent(processName, entityPresentation, changedData, data);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnSubmitCall(processName: string, data: any, bulkUploadProperties?: any, changeFor?: any, isPickItem:boolean=false,isModalResponse: boolean=false) {
  let requestEvent = createOnSubmitEvent(processName, data, bulkUploadProperties, changeFor,isPickItem,isModalResponse);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeOnSearchCall(
  processName: string,
  presentationId: any,
  runtimeInput: any,
  attributeName: any,
  pageNumber:Number,
  searchRecord?: any,
  changeFor?:any,
  detailedObjects?:any
) {
  if (CommonUtils.isNotEmpty(searchRecord)) {
    let requestEvent = createOnSearchFiltersListEvent(
      processName,
      presentationId,
      runtimeInput,
      attributeName,
      searchRecord,
      pageNumber,
    );
    return APIService.getData('POST', getbpmURL(), requestEvent);
  } else {
    let requestEvent = createOnSearchListEvent(processName, presentationId, runtimeInput, attributeName, pageNumber,changeFor,detailedObjects);
    return APIService.getData('POST', getbpmURL(), requestEvent);
  }
}

export async function makeOnClickCall(
  processName: any,
  mainEntityId: any,
  embeddedEntity: any,
  detailedObject: any,
  mainPresenationId: any
) {
  let requestEvent = createOnClickEvent(processName, mainEntityId, embeddedEntity, detailedObject, mainPresenationId);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeExplixitCallForNonFieldButton(
  processName: string,
  entityPresentation: any,
  detailedObjects: any,
  fieldName: any,
  presenationId: any,
  changeFor : any
) {
  let requestEvent = createExplicitEvent(processName, entityPresentation, detailedObjects, fieldName, presenationId,changeFor);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export async function makeExplicitAssignCallForNonFieldButton(
  processName: string,
  parentProcessName: string,
  data: any
) {
  let requestEvent = createExplicitAssignEvent(processName, parentProcessName, data);
  return APIService.getData('POST', getbpmURL(), requestEvent);
}

export function getSupportedTemplateTypes() {
  return [
    'list',
    'DMS',
    'form',
    'edit',
    'pcEmbedForm',
    'pcEmbedEdit',
    'treeForm',
    'treeEdit',
    'pChild',
    'treeView',
    'tabbed',
    'modalDialog',
    'ConformDialog',
    'upload'
  ];
}

export function getEmbedInfo(onStartData : any){
  let baseFormEntity=  getMainEntityIdForPresentation(onStartData);
  let uiResource = getUiResourceFromPresentation(onStartData);
  let embeddedParticipatingEntities= getPageEmbeddedParticipatingEntitiesWithoutNested(uiResource);
  let formulaPresentInPRule = getFormulaPresentInPRule(uiResource);
  let embeddedInfo : any ={
          "entityPageMap":{},
          "entitySearchMap":{},
          "entityInput":{}
      }
     embeddedInfo.entityInput={
      "entityId":baseFormEntity,
      "participatingEntities":embeddedParticipatingEntities
  }

  if(onStartData?.constructOutputData?.embeddedInfo?.entityPageMap) {
    // request.inputData.embeddedInfo.entityPageMap[control.entityId] = formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap[control.entityId]
       embeddedInfo.entityPageMap = onStartData?.constructOutputData?.embeddedInfo?.entityPageMap;
  }
  else if(embeddedParticipatingEntities){
         embeddedInfo.entityPageMap[embeddedParticipatingEntities] = {
        "pageNumber" : 1,
        "pageSize" : 10
      }
    
  }
  if(!embeddedParticipatingEntities.length){
    embeddedInfo = {};
  }
  return embeddedInfo;
}

export function setEmbeddedInfo_scroll(request:any, formModel:any,displayAttribute ?: string,searchValue?:string,entityId:string = "", pageNumber:Number=1) {
  let baseFormEntity=  getMainEntityIdForPresentation(formModel.onStartData);
  let uiResource = getUiResourceFromPresentation(formModel.onStartData);
  let embeddedParticipatingEntities= getPageEmbeddedParticipatingEntitiesWithoutNested_scroll(uiResource, formModel.onStartData);
  let formulaPresentInPRule = getFormulaPresentInPRule(uiResource);
  if(request.inputData?.embeddedInfo === undefined){
    if(request.inputData === undefined){
      request["inputData"] = {};
    }
    request.inputData["embeddedInfo"]={
          "entityPageMap":{},
          "entitySearchMap":{},
          "entityInput":{}
      }
  }
  request.inputData.embeddedInfo.entityInput={
      "entityId":baseFormEntity,
      "participatingEntities":embeddedParticipatingEntities
  }

  if(formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap) {
    request.inputData.embeddedInfo.entityPageMap = formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap;
    request.inputData.embeddedInfo.entityPageMap[entityId].pageNumber = pageNumber;
  }
  else if(embeddedParticipatingEntities){
    request.inputData.embeddedInfo.entityPageMap = {
      embeddedParticipatingEntities : {
        "pageNumber" : 1,
        "pageSize" : 10
      }
    }
  }

  if(CommonUtils.isNotEmpty(displayAttribute) && CommonUtils.isNotEmpty(searchValue) && entityId){
    request.inputData.embeddedInfo.entitySearchMap = {}
    request.inputData.embeddedInfo.entitySearchMap[entityId] = {
      "attributeName" : displayAttribute,
      "attributeValue" : searchValue
    }     
  }
  return request;
}

export function getPageEmbeddedParticipatingEntitiesWithoutNested_scroll(uiResource:any, startData:any) {
  let entityLevelMap = uiResource.presentations.entityLevelMap[0];
  let firstPRuleMap = uiResource.presentations.presentationRuleMap[entityLevelMap];
  let arr :any=[];
  //  each(firstPRuleMap,function (val,key,ref) {
  //    let entityId : string = getMappedEntityIdForPresentation(val)
  //     // // if(!val.matrixView && entityId && entityId!==entityLevelMap){
  //     // //     arr.push(entityId);
  //     // // }

  //     // arr = val.participatingEntities; //***********

  //     // arr = [
  //     //   "BoqTubeSummaryLine_1401",
  //     //   "PartMark_1395",
  //     //   "BoqSummaryLine_1400",
  //     //   "PartMarkSummaryLines_1404",
  //     //   "UniqueBoqItems_1420",
  //     //   "AssemblySummary_1422",
  //     //   "Uniquepartmarkitems_1421"
  //     // ];
  // })

  let entityPageMap:any = startData.constructOutputData.embeddedInfo.entityPageMap;
  Object.keys(entityPageMap).map((item, i) => (
    arr.push(item)
  ))

  return arr;
}

export function setEmbeddedInfo(request:any, formModel:any,displayAttribute ?: string,searchValue?:string,entityId ?:string) {
  let baseFormEntity=  getMainEntityIdForPresentation(formModel.onStartData);
  let uiResource = getUiResourceFromPresentation(formModel.onStartData);
  let embeddedParticipatingEntities= getPageEmbeddedParticipatingEntitiesWithoutNested(uiResource);
  if(embeddedParticipatingEntities.length === 0 && CommonUtils.isNotEmpty(entityId)){
     embeddedParticipatingEntities.push(entityId)
  }
  let formulaPresentInPRule = getFormulaPresentInPRule(uiResource);
  if(!request.inputData.embeddedInfo){
      request.inputData.embeddedInfo={
          "entityPageMap":{},
          "entitySearchMap":{},
          "entityInput":{}
      }
  }
  request.inputData.embeddedInfo.entityInput={
      "entityId":baseFormEntity,
      "participatingEntities":embeddedParticipatingEntities
  }

  if(formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap) {
    // request.inputData.embeddedInfo.entityPageMap[control.entityId] = formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap[control.entityId]
    request.inputData.embeddedInfo.entityPageMap = formModel?.onStartData?.constructOutputData?.embeddedInfo?.entityPageMap;
  }
  else if(embeddedParticipatingEntities){
    request.inputData.embeddedInfo.entityPageMap = {
      embeddedParticipatingEntities : {
        "pageNumber" : 1,
        "pageSize" : 10
      }
    }
  }
  if(!embeddedParticipatingEntities.length){
    request.inputData.embeddedInfo = {};
  }
  if(CommonUtils.isNotEmpty(displayAttribute) && CommonUtils.isNotEmpty(searchValue) && entityId){
    request.inputData.embeddedInfo.entitySearchMap = {}
    request.inputData.embeddedInfo.entitySearchMap[entityId] = {
      "attributeName" : displayAttribute,
      "attributeValue" : searchValue
    }     
  }
  return request;
}

export function getPageEmbeddedParticipatingEntitiesWithoutNested(uiResource:any) {
  let entityLevelMap = uiResource.presentations.entityLevelMap[0];
  let firstPRuleMap = uiResource.presentations.presentationRuleMap[entityLevelMap];
  let arr :any=[];
   each(firstPRuleMap,function (val,key,ref) {
     let entityId : string = getMappedEntityIdForPresentation(val)
      if(!val.matrixView && entityId && entityId!==entityLevelMap){
          arr.push(entityId);
      }
  })
  return arr;
}

  export function getFormulaPresentInPRule (uiResource:any) {
  let formulaPresent :any= [];
   forEach(uiResource.presentations.presentationRuleMap, function(presentationRuleMap, key){
       forEach(presentationRuleMap,function (value,key) {
           forEach(value, function (pMetaData, index, ref) {
              if(index == 'presentationRules'){
                  var entity = ref.entityId;
                   forEach(pMetaData, function(pRule){
                      if(pRule && pRule.uiSettings && pRule.uiSettings.attributeVariable && pRule.uiSettings.resolveVariable){
                          formulaPresent.push(entity)
                      }
                  })
              }
          });
      });
  })
  return  uniq(formulaPresent);
}

export async function makeAsyncOnStartCall(
  processName: string,
  data: any,
  fromDashboard: boolean,
  previousProcessName: string,
  processModel?: KagamiProcessRequestModel
) {
  let requestEvent = createOnStartEvent(processName, data, previousProcessName, fromDashboard);
  return APIService.getData('POST', getAsyncBpmURL(), requestEvent);
}

export async function makeLinkProcessOnStartCall(linkProcessId : string){
  let requestData = createLinkProcessRequestEvent(linkProcessId)
  return APIService.getData('POST',linkProcessURL(),requestData);
}

export async function makeEmbedColumnSearchCall(requestModel : KagamiSubProcessRequestModel,searchValue:any, embeddedId?:string, pageNumber:Number=1){
  let entityId = getMappedEntityIdForPresentation(requestModel.entityPresentation);
  let requestEvent = createEmbedColumnSearchEvent(requestModel.processName,requestModel.data,requestModel.runtimeInput,requestModel.changedAttribute,searchValue,entityId, embeddedId, pageNumber)
  return APIService.getData(SystemConstants.POST,getbpmURL(),requestEvent)
}
