import { createState } from '@hookstate/core';
import {
  LineReportModel,
  LineReportModelProps,
  useLineReportModelState
} from '../model/LineReportModel';
import { downloadLinePDF, getTotalOfReportColumn, makeAdditionalReport, makeLine } from '../service/LineReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React, {  useRef } from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts)



import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { buildReportsPageController } from '../../../../../../landingPage/builder/ReportsPage/controller/ReportsPageController';



export const BuildLineReportView = (lineReportModel: LineReportModel) => {
  return (
    <KagamiLineChartReportView key={'line-chart-report'} lineReportModel={lineReportModel}></KagamiLineChartReportView>
  );
};


const KagamiLineChartReportView = (props: LineReportModelProps) => {
  const chartRef:any = useRef();
  let lineReportModel = props.lineReportModel;
  let lineChartState = useLineReportModelState(createState(lineReportModel));
  lineReportModel.state = lineChartState;
  let _config :any ={};
  let totalValue :any = 0;
  if(lineReportModel.contentReport)console.log(lineReportModel?.contentReport[0]??'getting undefined');
  React.useEffect(() => {
    getReportDataForDynamicReports(props.lineReportModel);
    if(props.lineReportModel?.reportConfig?.reportSettings?.additionalReportId) {
        makeAdditionalReport(props.lineReportModel.reportConfig.reportSettings.additionalReportId,props.lineReportModel);
    }

  }, [])

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartRef.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);

  
  const downLoad = ()=> {
    downloadLinePDF(chartRef,_config,lineReportModel);
  }

  if(!lineChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" /> 
  }else{
    if (lineReportModel.reportData){
        
  _config  = makeLine(lineReportModel);
  totalValue = getTotalOfReportColumn(_config);
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} style={{ marginTop:'5px'}} className='dashboard-line-report-container'>
      <Grid item xs={_config.tableConfig?.isBesideTableDetails && !lineChartState.isAdditionalReportLoaded ? 6:12} className='dashboard-chart-grid-container'>
        <div ref ={containerRef} className="ag-theme-alpine dashboard-highchart" style={{ width: '100%' }}>
          <Chart ref ={chartRef} highcharts={Highcharts} options={_config.lineConfig}/>
        </div>
      </Grid>
        {(_config?.tableConfig?.showTableDetails && !lineChartState.isAdditionalReportLoaded) &&
        <Grid item xs={6}>
          <Grid item xs={12} style={{ float:'right',marginBottom:'10px'}}>
          {!lineChartState.isAdditionalReportLoaded &&  
            <Button
              id="print-button"
              variant="contained"
              onClick={downLoad}
            >
              Download
            </Button>
         }
          </Grid>
          <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                    <TableCell style={{background:'#f2f2f2'}} component="th" scope="row">
                      {col.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {_config?.tableConfig?.detailedTableconfig?.data?.map((rec:any) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                   {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                    <TableCell component="th" scope="row">
                      {rec[col.field]}
                    </TableCell>
                  ))}
                  </TableRow>
                ))}
                  <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {_config?.tableConfig?.detailedTableconfig?.columns?.map((col: any) => (
                      <TableCell component="th" scope="row">
                        {col.field =='y' ? ("Total : "+totalValue) :''}
                      </TableCell>
                    ))}
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>
        
        </Grid>
        }
        <Grid item xs={12} style={{ marginBottom:'25px'}} className='dashboard-no-margin'>
          {lineChartState.isAdditionalReportLoaded && 
            <div>
              {buildReportsPageController(lineReportModel?.additionalReportContent, true)}
            </div>
          }
        </Grid>

        <Grid item xs={12} style={{ marginBottom:'25px'}} className='dashboard-no-margin'>
          {
            lineChartState.isContentReportLoaded && <div>
              {buildReportsPageController(lineReportModel?.contentReport, true)}
            </div>
          }
        </Grid>
    </Grid>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
}
};
