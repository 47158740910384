export class KagamiFormData {
    data: any;
    changedData: any;
    formData: any;
    detailedObjects: any;
    formDataId: string;
    mainEntityId: string;
    entityHierarchies: Map<string, any>;
  
    constructor(formDataId: any, mainEntityId: any) {
      this.data = {};
      this.changedData = {};
      this.formData = {};
      this.detailedObjects = new Map();
      this.formDataId = formDataId;
      this.mainEntityId = mainEntityId;
      this.entityHierarchies = new Map();
    }
  
    clearFormData() {
      this.data = {};
      this.changedData = {};
      this.formData = {};
      this.detailedObjects = new Map();
    }
  
    clearChangedData() {
      this.changedData = {};
      //    this.detailedObjects = new Map();
    }
  }
  