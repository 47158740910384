import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiGroupStyle = (theme: Theme) =>
  createStyles({
    mainRoot :{
      height:'calc(100% - 100px)',
      '& > div:last-child': {
        borderLeft: '1px solid #ebebeb',
        borderBottom: '1px solid #ebebeb',
        // borderTop: '1px solid #ebebeb', 
        borderRight: '1px solid #ebebeb',
      }
    },
    root: { 
      //  background: theme.palette.background.default,
      background: theme.palette.background.defaultBlue,
       width: 'fit-content',
      //  borderTop: '1px solid #ebebeb',
      //  borderRight: '1px solid #ebebeb',
       height: theme.spacing(4.5),
       minHeight: theme.spacing(4.5), 
       paddingTop: '1px',
       '& .MuiTab-root': {
         minHeight: theme.spacing(3.75), 
         padding: `0 ${theme.spacing(1)}`,
        //  borderLeft: '1px solid #ebebeb',
        //  borderRight: '1px solid #ebebeb',
         color: '#29947f',
        fontWeight: '500',

        '&.Mui-selected': {
          // background: theme.palette.common.white,
          // borderBottom: theme.palette.common.white,
          // color: theme.palette.primary.dark,
          // color: '#0059b3'
          fontWeight: '600',
          color: '#29947f',
        },
       '& .MuiTab-wrapper': {
        //  color: theme.palette.common.black,
         textTransform: 'capitalize',
         fontSize: theme.typography.subtitle1.fontSize,
       }
      }
    }, 
   
    indicator: {
      // top: '0',
      bottom: '0',
      height: '2.5px',
      backgroundColor: '#156282',
      // backgroundColor: theme.palette.primary.dark,
    },

    groupHeader : {
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center', 
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      padding:`${theme.spacing(0.5)} ${theme.spacing(1)}`,
      background: 'transparent',
      boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 10%)',
      maxHeight: theme.spacing(7.5),
      width: 'auto',
    }
  });
