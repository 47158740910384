



import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const kagamiPresentationFilterstyle = (theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
        textTransform: 'capitalize',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: theme.spacing(0.5),
        margin: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
        border: '1px solid #BABFC7',
        padding:`${theme.spacing(1.6)} ${theme.spacing(1)}`
    },
    listItems: {
      width: '100%',
      maxHeight: '260px',
      overflowY: 'scroll', 
      overflowX: 'hidden',
      "&::-webkit-scrollbar":{
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      '&::-webkit-scrollbar-thumb' :{
          backgroundColor: 'rgba(0,0,0,.4)',
      },
      '&::-webkit-scrollbar-track' :{
          background: 'hsla(0,0,0,.2)',
      },
    },
    filtersListBody: {
      maxWidth: theme.spacing(25),
      maxHeight: '300px',
      padding: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
      '& .MuiOutlinedInput-root': {
        // '& fieldset': {
        //   borderColor: 'red',
        // },
        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        '&.Mui-focused fieldset': {
          border: '0.8px solid',
        },
      },
     
      '& .MuiInputBase-root': {
        height: theme.spacing(3.75),
        fontSize: theme.typography.subtitle1.fontSize
      } ,
      '& .MuiListItemButton-root':{
        
        padding:`0 ${theme.spacing(1)}`,
        '&:hover':{
          backgroundColor:'#006e7f10'
        }
      },
      '& .MuiCheckbox-root': {
        padding: 0
      },
      '& .MuiListItemIcon-root ':{
        minWidth: theme.spacing(4),
        '& .MuiCheckbox-root':{
          marginLeft: '0'
        }

      },
      '& .MuiListItemText-root .MuiTypography-root ':{
        fontSize: theme.typography.caption.fontSize
      }
    },
    listItem: {
      color:theme.palette.info.main
    }
  });
