import { KagamiPopoverModel } from '../../../../../../../../../../../../../../components/popover/model/KagamiPopoverModel';

export class KagamiCardModel {
  visibleAttributes: any[];
  showPopover: boolean;
  dataRecord: any;
  presentation: any;
  popoverModel: KagamiPopoverModel; 
  state: any;

  constructor(dataRecord: any, presentation: any, visibleAttributes: any[]) {
    this.visibleAttributes = visibleAttributes;
    this.dataRecord = dataRecord;
    this.presentation = presentation;
    this.showPopover = false;
    this.popoverModel = new KagamiPopoverModel();
  }
}

export interface KagamiCardModelProps {
  kagamiCardModel: KagamiCardModel;
}
