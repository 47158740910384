import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';  

export const KagamiHomeStyle = (theme: Theme) =>
  createStyles ({
    root: {
      fontFamily: theme.typography.fontFamily,
      // display: 'grid',
      // gridTemplateAreas :'"nav header" "nav content"',
      // // gridTemplateAreas: ({ showDrawer}):any =>
      // //   showDrawer
      // //     ? '"nav header" "nav content"'
      // //     : '"nav header" "nav content"',
      // // gridTemplateColumns:  ({ showDrawer }) => showDrawer ? 'max-content auto' : 'min-content auto',
      // gridTemplateColumns: 'min-content auto',
      // gridTemplateRows: 'auto 1fr ',
      // height: '100vh',  
      display : 'flex',
      height:'100vh',
      position:'relative',
      [theme.breakpoints.down('md')]: {
        // gridTemplateAreas: '"header" "nav" "content" "side"',

        gridTemplateColumns: '1fr',
        gridTemplateRows: 'inherit'
      },

      
    },
    right_cont: {
      display:'flex',
      flexDirection: 'column',
      height:'100vh',
      maxHeight: '100vh',
      width: '100%',
      overflowY: 'auto',
    },
    header: {
      // gridArea: 'header',
      boxShadow: '0px 4px 1px -2px rgb(0 0 0 / 10%)',
      zIndex: 1 , 
      
      
    },

    nav: {
      // gridArea: 'nav',
      backgroundColor: theme.palette.primary.main,
      height:'100%',
      // width: 'auto',
      // width: ({ showDrawer }) => showDrawer ? 'max-content' : '0',
      // transition: 'width 0.3s',  
      '&::-webkit-scrollbar': {
        display: 'none',
    }
    },

    hiddenNav: {
      gridArea: 'none'
    },

    main: {
       
      background: theme.palette.background.defaultBlue,
       
      overflowX: 'hidden',
      // padding: theme.spacing(0.5),
      paddingTop: '0',
      width: 'auto', 
      marginBottom: 'auto',
       
      height: '100%',

      "&::-webkit-scrollbar":{
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      '&::-webkit-scrollbar-thumb' :{
          backgroundColor: 'rgba(0,0,0,.4)',
      },
      '&::-webkit-scrollbar-track' :{
          background: 'hsla(0,0,0,.2)',
      },

    },

    aside: {
      gridArea: 'side',
      // backgroundColor: ({ showDrawer }) => (showDrawer ? 'teal' : 'yellow')
    },
    homeFooter: {
      display: 'flex',
      height: '25px',
      alignItems: 'center',
      
    },
    footercopyright: {
      marginLeft: 'auto',
      marginRight: theme.spacing(3)
    },

    toasterClass: {
      '& div[role=status]' : {
        margin:0
      }
    },
  });
