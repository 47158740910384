import { createState } from '@hookstate/core';
import { Button, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _, { uniqueId } from 'lodash';
import { buildReportFilter } from '../../filter/controller/KagamiReportFilterController';
import { executeReportWithOrWithoutFilter } from '../../view/report/builder/KagamiReportBuilder';
import { DashboardReportBody } from '../builder/DashboardReportBody';
import { DashboardReportHeader } from '../builder/DashboardReportHeader';
import { DashboardReportsModel, DashboardReportsProps, useDashboardReportState } from '../model/DashboardReportsModel';
import { DashboardReportsStyle } from '../style/DashboardReports';

const useStyles = makeStyles(DashboardReportsStyle);

export const buildDashboardReportsComponent = (dashboardReportsModel: DashboardReportsModel) => {
  return <DashboardReports key={dashboardReportsModel.id} dashboardReportsModel={dashboardReportsModel} />;
};

const DashboardReports = (props: DashboardReportsProps) => {
  const classes = useStyles();
  let dashboardReportsModel = props.dashboardReportsModel;
  const dashboardReportState = useDashboardReportState(createState(dashboardReportsModel));
  dashboardReportsModel.state = dashboardReportState;



  return (
      <>
        <DashboardReportHeader key={uniqueId()} dashboardReportsModel={dashboardReportsModel} />
        <DashboardReportBody key={uniqueId()} dashboardReportsModel={dashboardReportsModel} />
        <Drawer
          anchor={'right'}
          key={uniqueId()}
          open={dashboardReportState.drawerOpenDialog}
          PaperProps={{ style: { height: 'fit-content', width: '800px' } }} // , borderRadius: '6px 0px 0px 6px', transition: '2s width'
        >
          <>{ReportFilter(dashboardReportsModel)}</>
        </Drawer>
        </>
  );
};

const ReportFilter = (dashboardReportsModel: DashboardReportsModel) => {
  return (
    <div>
      <div style={{ height: 'fit-content', zIndex: '2', margin: '8px', fontWeight:'bold' }}> Filters</div>
      <div>{buildReportFilter(dashboardReportsModel.reportModel.reportFilterModel, true)}</div>
      <div style={{width:'100%', textAlign:'right', padding:'1%'}}>
        <Button
          style={{background: '#007ecc', borderColor:'#007ecc', color: '#fff'}}
          onClick={async () => {
            dashboardReportsModel.content = await executeReportWithOrWithoutFilter(dashboardReportsModel.reportModel);
            dashboardReportsModel.state.setDrawerOpenDialog(false);
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};
