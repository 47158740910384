import { State, useState } from '@hookstate/core';
import { ReportFilterModel } from '../../../model/KagamiReportFilterModel';

export class ReportTextFilterModel {
  state: any;
  dependentValues: any;
  filterName: string;
  filterRelationName: string;
  reportFilterModel: ReportFilterModel;
  filterValues: any;
  filterConfig: any;
  pageNo:number;
  translations: any
  constructor(
    dependentValues: any,
    filterName: string,
    filterRelationName: string,
    reportFilterModel: ReportFilterModel,
    filterConfig: any,
    translations?: any,
  ) {
    this.dependentValues = dependentValues;
    this.filterName = filterName;
    this.filterRelationName = filterRelationName;
    this.reportFilterModel = reportFilterModel;
    this.filterValues = [];
    this.filterConfig = filterConfig;
    this.pageNo = 0;
    this.translations = translations;
  }
}
export interface ReportTextFilterModelProps {
  reportTextFilterModel: ReportTextFilterModel;
  translationWords?: any;
}
export const wrapReportTextFilterState = (state: State<ReportTextFilterModel>) => {
  let textFilterState = state;

  return {
    get filterValues() {
      return textFilterState.filterValues.get();
    },
    setFilterValues(filterValues: any) {
      textFilterState.filterValues.set(filterValues);
    }
  };
};
export const useReportTextFilterState = (state: State<ReportTextFilterModel>) =>
wrapReportTextFilterState(useState(state));
