import { State, useState } from '@hookstate/core';
import { KagamiErrorModalModel } from '../../../../components/errorModal/model/KagamiErrorModalModel';
import { KagamiModalModel } from '../../../../components/modal/model/KagamiModalModel';

export class KagamiHomeModel {
  showDrawer: boolean = true;
  pageSidebarComponent: JSX.Element | undefined;
  pageHeaderComponent: any;
  pageContentComponent: any;
  pageFooterComponent: any;
  errorModal: KagamiErrorModalModel;
  state: any;
  dialogModel: KagamiModalModel;

  constructor() {
    this.showDrawer = true;
    this.pageSidebarComponent = undefined;
    this.errorModal = new KagamiErrorModalModel();
    this.dialogModel = new KagamiModalModel();
  }
}

export const usePageState = (state: State<KagamiHomeModel>) => {
  let pageState = useState(state);

  return {
    get showDrawer() {
      return pageState.showDrawer.get();
    },

    drawerNeeded(drawerNeeded: boolean) {
      pageState.showDrawer.set(drawerNeeded);
    },

    get pageContent() {
      return pageState.pageContentComponent.get();
    },
    setPageContent(pageContent: JSX.Element) {
      pageState.pageContentComponent.set(pageContent);
    }
  };
};

export interface HomeModelProps {
  homeModel: KagamiHomeModel;
}
