import { State, useState } from "@hookstate/core";
import { KagamiModalModel } from "../../../../../../../../../components/modal/model/KagamiModalModel";
import { KagamiPopoverModel } from "../../../../../../../../../components/popover/model/KagamiPopoverModel";
import { SystemConstants } from "../../../../../../../../constants/SystemConstants";
import { ReportIconPopoverModel } from "../builder/header/builder/ReportIconPopover/model/ReportIconPopoverModel";

export class ReportLandingPageModel{
    reportList:any;
    folderList:any;
    setOfFolderConfig: any[];
    newReportList:any[];
    newFolderList:any[];
    isDataLoaded:boolean;
    folderTiles:any;
    reportTiles:any;
    folderId:any;
    reportId:any;
    folderConfig:any;
    reportConfigSummary:any;
    modalModel:KagamiModalModel;
    fromLink:boolean;
    content:any;
    fromHeader:boolean;
    isContentLoaded:any;
    state:any;
    active:string;
    reportIconPopoverModel : ReportIconPopoverModel | null;
    deleteAndEditPopover: KagamiPopoverModel;
    translations: any;
    constructor(){
        this.folderList = [];
        this.reportList = [];
        this.newFolderList = [];
        this.newReportList = [];
        this.isDataLoaded = false;
        this.isContentLoaded = false;
        this.fromHeader = false;
        this.fromLink = false;
        this.folderTiles = {};
        this.reportTiles = {};
        this.setOfFolderConfig = [];
        this.state = null;
        this.active = SystemConstants.VIEW_REPORTS;
        this.modalModel = new KagamiModalModel();
        this.reportIconPopoverModel = null;
        this.deleteAndEditPopover = new KagamiPopoverModel()
    }
}

export interface ReportLandingPageModelProps{
    reportLandingPageModel:ReportLandingPageModel;
}

const wrapReportLandingPageState = (state:State<ReportLandingPageModel>) => {
    let reportLandingPageState = state; 
    return {
        get isDataLoaded(){
            return reportLandingPageState.isDataLoaded.get();
        },

        setDataLoaded(loaded:boolean){
            reportLandingPageState.isDataLoaded.set(loaded);
        },

    }
}

export const useReportLandingPageState = (state:State<ReportLandingPageModel>) => {
    return wrapReportLandingPageState(useState(state));
}

export interface KagamiReportTileProps {
    reportConfig: any;
    translations?: any;
  }
  
export interface KagamiFolderTileProps{
    folderConfig: any;
    translations?: any;
}

export class ParentId{
    static id = 'parentId1'
}