import { notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import { KagamiCheckboxModel } from '../model/kagamiCheckboxModal';
import { buildCheckboxComponent } from '../ui/kagamiCheckboxField';
import { buildMultiSelectCheckBoxComponent } from '../ui/kagamiMultiSelectCheckBox';

export function buildCheckbox(kagamiCheckboxModel: KagamiCheckboxModel) {
  return buildCheckboxComponent(kagamiCheckboxModel);
}

export function handleCheckBoxOnChange(kagamiCheckboxModel: KagamiCheckboxModel, checked: any) {
  kagamiCheckboxModel.state.setControlValue(checked);
  notifyChangeToForm(kagamiCheckboxModel, null);
}
export function buildMultiSelectCheckBox(kagamiCheckboxModel: KagamiCheckboxModel) {
  return buildMultiSelectCheckBoxComponent(kagamiCheckboxModel);
}

export function handleMultiSelectCheckBoxOnChange(kagamiCheckboxModel: KagamiCheckboxModel, option: any) {
  let alreadyCheckedItems = kagamiCheckboxModel.controlValue;
  if (Array.isArray(alreadyCheckedItems)) {
    let isAlreadyFound = alreadyCheckedItems?.filter((item: any) => item?.id === option?.id);
    if (isAlreadyFound?.length > 0) {
      alreadyCheckedItems = alreadyCheckedItems?.filter((item: any) => item?.id !== option?.id);
    } else {
      alreadyCheckedItems = [...alreadyCheckedItems, option];
    }
  }else if(alreadyCheckedItems == ""){
    alreadyCheckedItems = [option];
  }
  kagamiCheckboxModel.state.setControlValue(alreadyCheckedItems);
  notifyChangeToForm(kagamiCheckboxModel, null);
}
