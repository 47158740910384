import { createState } from '@hookstate/core';
// import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { buildModal } from '../../../../../../../../../components/modal/controller/KagamiModalController';
import { buildPopover } from '../../../../../../../../../components/popover/controller/KagamiPopoverController';
import { generateWindowId } from '../../../process/service/ServerEventService';
import { BodyContent } from '../builder/body/ui/BodyContent';
import { HeaderContent } from '../builder/header/ui/HeaderContent';
import {
  // flatteningFolders,
  // mappingFolderWithRelatedFolder,
  // mappingReportsWithRelatedFolder,
  flatteningFoldersValueToArray,
  mappingFolderWithParentFolder,
  mappingReportsWithParentFolder
} from '../controller/ReportsLandingPageController';
import {
  ParentId,
  ReportLandingPageModel,
  ReportLandingPageModelProps,
  useReportLandingPageState
} from '../model/ReportsLandingPageModel';
import { extractAndSetReportAndFolderData } from '../service/ReportsLandingPageService';

export const buildReportLandingPage = (reportLandingPageModel: ReportLandingPageModel) => {
  return <ReportLandingPage key={generateWindowId()} reportLandingPageModel={reportLandingPageModel} />;
};

export const ReportLandingPage = (props: ReportLandingPageModelProps) => {
  let landingPageState = useReportLandingPageState(createState(props.reportLandingPageModel));
  props.reportLandingPageModel.state = landingPageState;
  useEffect(() => {
    extractAndSetReportAndFolderData(props.reportLandingPageModel);
  }, []);

  if (!landingPageState.isDataLoaded) {
    return <></>;
  } else {
    // mappingFolderWithRelatedFolder(props.reportLandingPageModel.folderTiles[ParentId.id]);
    // mappingReportsWithRelatedFolder();
    mappingFolderWithParentFolder(props.reportLandingPageModel.folderList, props.reportLandingPageModel.folderTiles, props.reportLandingPageModel.translations);
    mappingReportsWithParentFolder(
      props.reportLandingPageModel.reportList,
      props.reportLandingPageModel.folderTiles,
      props.reportLandingPageModel.reportTiles,
      props.reportLandingPageModel.translations
    );
    flatteningFoldersValueToArray();

    return (
      <div className="reportsMainContainer">
        <HeaderContent reportLandingPageModel={props.reportLandingPageModel} />
        <BodyContent reportLandingPageModel={props.reportLandingPageModel} />
        {buildModal(props.reportLandingPageModel.modalModel)}
        {buildPopover(props.reportLandingPageModel.deleteAndEditPopover)}
      </div>
    );
  }
};
