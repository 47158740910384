import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class PaymentDueListReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
  }
}
export interface PaymentDueListReportModelProps {
  paymentDueListReportModel: PaymentDueListReportModel;
}
export const usePaymentDueListReportModelState = (state: State<PaymentDueListReportModel>) => {
  let paymentDueListReportState = useState(state);
  return {
    reportConfig() {
      paymentDueListReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      paymentDueListReportState.reportData.set(reportContent);
    }
  };
};
