import { createState } from '@hookstate/core';
import {  AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef } from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { Button,ButtonGroup,Menu,MenuItem,Grid, CircularProgress } from '@mui/material';
import Save from '@mui/icons-material/Save';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import {
  PivotReportModel,
  PivotReportModelProps,
  usePivotReportModelState
} from '../model/PivotReportModel';
import { downloadPivotExcel, onPivotReportRowClick, makePivot,onDetailedExcel } from '../service/PivotReportService';
import { buildReportsPageController } from '../../../../../../landingPage/builder/ReportsPage/controller/ReportsPageController';

export const BuildPivotReportView = (pivotReportModel: PivotReportModel) => {
  return (
    <KagamiPivotChartReportView key={'pivot-chat-report'} pivotReportModel={pivotReportModel}></KagamiPivotChartReportView>
  );
};


const KagamiPivotChartReportView = (props: PivotReportModelProps) => {
  const gridRef:any = useRef();
  let pivotReportModel = props.pivotReportModel;
  let pivotChartState = usePivotReportModelState(createState(pivotReportModel));
  pivotReportModel.state = pivotChartState;

  const onRowClick = (event:any) => {
    onPivotReportRowClick(event,pivotReportModel);
  }

  React.useEffect(() => {
    getReportDataForDynamicReports(props.pivotReportModel)
  }, [])

  const onDetailedExcelDownload = useCallback(() => {
    onDetailedExcel(gridRef,pivotReportModel);

  }, []);
  const onDownloadExcel = useCallback(() => {
    downloadPivotExcel(gridRef,pivotReportModel);

  }, []);
  if(!pivotChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{
    
  if(pivotReportModel?.reportConfig?.reportConfigSummary?.template == 'shilpaPerformanceReport'){
    return (
      <div style={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
      <Card sx={{ marginTop: '4%' }} onClick={() => { onDetailedExcelDownload() }}>
        <Save sx={{ fontSize: 180 }} color="success" />
        <CardContent  style={{padding:'0px 10px 10px 10px',textAlign:'center'}}>
          Download
      </CardContent>
      </Card>
      </div>
      )
  }
  else if(pivotReportModel.reportData ){
  const pivotConfig :any = makePivot(pivotReportModel);

  return (
    <div className="ag-theme-alpine kgm-report-aggrid">
      <Grid className="hide-buttons-from-desktop-report-container" container rowSpacing = {1} style={{ margin: '10px 0px',textAlign:'right'}} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        <Grid item xs={12}>
          <ButtonGroup className="report-rtl-btngroup"  variant="text" aria-label="text button group">
            <Button style={{ background: '#2d72b9'}}  variant="contained" onClick={() => { onDownloadExcel() }}>
              Download Excel
            </Button>
          </ButtonGroup>
        </Grid>
      <Grid item xs = {12} className = "kgm-report-hw dashboard-report">
        <AgGridReact 
          ref={gridRef}
          rowData={pivotConfig.rowData}
          columnDefs={pivotConfig.columnDefs}
          sideBar = {pivotConfig.sideBar }
          suppressExpandablePivotGroups = {pivotConfig.suppressExpandablePivotGroups}
          suppressAggFuncInHeader={pivotConfig.suppressAggFuncInHeader}
          pivotRowTotals =  {pivotConfig.pivotRowTotals}
          // pivotColumnGroupTotals = {'before'}
          getRowHeight = {pivotConfig.getRowHeight}
          pivotMode = {true}
          onRowClicked={onRowClick}
          // rowGroupPanelShow = {'always'}//never
          groupHideOpenParents = {pivotConfig.groupHideOpenParents}
          defaultColDef={pivotConfig.defaultColDef}
          autoGroupColumnDef = {pivotConfig.autoGroupColumnDef}
          groupDefaultExpanded = {pivotConfig.groupDefaultExpanded}
          groupIncludeFooter = {pivotConfig.groupIncludeFooter}
          groupIncludeTotalFooter = {pivotConfig.groupIncludeTotalFooter} 
          removePivotHeaderRowWhenSingleValueColumn= {true}
          excelStyles = {pivotConfig.ExcelStyles}
          
         ></AgGridReact>
        </Grid>

        <div key="printDiv" style = {{ position: 'absolute', opacity: '0.0' }}>
              <div id="printDiv" style = {{ display: 'none' }} ></div> 
        </div>   

        <Grid item xs={12} key="detailsreport" style={{ marginBottom: '25px' }}>
          {
            pivotChartState.isContentReportLoaded && <div>
              {buildReportsPageController(pivotReportModel?.contentReport, true)}
            </div>
          }
        </Grid>

      </Grid>
        </div>
  );
  }else{
    return <p className='NoDataFound'>No Data Found</p>
  }
  }
};
