import { State, useState } from "@hookstate/core";
import React from "react";
import { KagamiListModel } from "../../../model/KagamiListModel";

export class KagamiListSearchModel {
    searchValue: string;
    state : any;
    inputRef : any;
    kagamiListModel : KagamiListModel
    constructor(listModel : KagamiListModel){
      this.searchValue = ''
      this.inputRef = React.useRef(null);
      this.kagamiListModel = listModel;

    }
  
  }
  export const wrapSearchState = (state:State<KagamiListSearchModel>) => {
    let searchState = state;
    return {
      get searchValue() {
        return searchState.searchValue.get();
      },
      setSearchValue(value: string) {
        searchState.searchValue.set(value);
      }
      
    }
  
  }

  export interface ListSearchBarProps{
      listSearchbarModel : KagamiListSearchModel,
  }
  
  export const useSearchState = (state:State<KagamiListSearchModel>) => wrapSearchState(useState(state));