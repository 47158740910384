import React from 'react';
import { KagamiReportModel } from '../model/KagamiReportModel';
import { getFilterSelectedData ,getStaticFilterData } from '../service/KagamiReportService';
import { getStaticReportTypes} from '../service/ReportUtilService';

import { AreaReportModel } from './graphic/area/model/AreaReportModel';
import { buildAreaReport } from './graphic/area/controller/AreaReportController';
import { BarReportModel } from './graphic/bar/model/BarReportModel';
import { buildBarReport } from './graphic/bar/controller/BarReportController';
import { ColumnReportModel } from './graphic/column/model/ColumnReportModel';
import { buildColumnReport } from './graphic/column/controller/ColumnReportController';
import { PivotReportModel } from './data/pivot/model/PivotReportModel';
import { buildPivotReport } from './data/pivot/controller/PivotReportController';
import { ListReportModel } from './data/list/model/ListReportModel';
import { buildListReport } from './data/list/controller/ListReportController';
import { DoughnutReportModel } from './graphic/doughnut/model/DoughnutReportModel';
import { buildDoughnutReport } from './graphic/doughnut/controller/DoughnutReportController';
import { PieReportModel } from './graphic/pie/model/PieReportModel';
import { buildPieReport } from './graphic/pie/controller/PieReportController';
import { StackedBarReportModel } from './graphic/stackedbar/model/StackedBarReportModel';
import { buildStackedBarReport } from './graphic/stackedbar/controller/StackedBarController';
import { StackedColumnReportModel } from './graphic/stackedcolumn/model/StackedColumnReportModel';
import { buildStackedColumnReport } from './graphic/stackedcolumn/controller/StackedColumnController';
import { KPIReportModel } from './graphic/kpi/model/KPIReportModel';
import { buildKPIReport } from './graphic/kpi/controller/KPIReportController';
import { LineReportModel } from './graphic/line/model/LineReportModel';
import { buildLineReport } from './graphic/line/controller/LineReportController';

import { SubLedgerReportModel } from './ledgers/subLedger/model/SubLedgerReportModel';
import { buildSubLedgerReport } from './ledgers/subLedger/controller/SubLedgerReportController';

import { AgeingReportModel } from './ledgers/ageingReport/model/AgeingReportModel';
import { buildAgeingReport } from './ledgers/ageingReport/controller/AgeingReportController';

import { BalanceSheetReportModel } from './ledgers/balanceSheet/model/BalanceSheetReportModel';
import { buildBalanceSheetReport } from './ledgers/balanceSheet/controller/BalanceSheetReportController';
import { ProfitLossReportModel } from './ledgers/profitloss/model/ProfitLossReportModel';
import { buildProfitLossReport } from './ledgers/profitloss/controller/ProfitLossReportController';
import { TrialBalanceReportModel } from './ledgers/trialBalance/model/TrialBalanceReportModel';
import { buildTrialBalanceReport } from './ledgers/trialBalance/controller/TrialBalanceReportController';

import { LedgerAccountReportModel } from './ledgers/ledgerAccounts/model/LedgerAccountReportModel';
import { buildLedgerAccountReport } from './ledgers/ledgerAccounts/controller/LedgerAccountReportController';

import { StockLedgerReportModel } from './ledgers/stockLedger/model/StockLedgerReportModel';
import { buildStockLedgerReport } from './ledgers/stockLedger/controller/StockLedgerReportController';

import { TileChartReportModel } from './graphic/tilechart/model/TileChartReportModel';
import { buildTileChartReport } from './graphic/tilechart/controller/TileChartReportCotroller';
import { FunnelReportModel } from './graphic/funnel/model/funnelReportModel';
import { buildFunnelReport } from './graphic/funnel/controller/funnelReportController';
import { GaugeReportModel } from './graphic/gauge/model/GaugeReportModel';
import { buildGaugeReport } from './graphic/gauge/controller/GaugeReportController';
import { TileViewReportModel } from './graphic/tileview/model/TileViewReportModel';
import { buildTileViewReport } from './graphic/tileview/controller/TileViewReportCotroller';
import { setReportModel } from '../controller/KagamiReportController';
import { DayBookReportModel } from './ledgers/dayBook/model/DayBookReportModel';
import { buildDayBookReport } from './ledgers/dayBook/controller/DayBookReportController';
import { PaymentDueListReportModel } from './ledgers/paymentDueList/model/PaymentDueListReportModel';
import { buildPaymentDueListReport } from './ledgers/paymentDueList/controller/PaymentDueListReportController';
import { BellCurveReportModel } from './graphic/bellcurve/model/BellCurveReportModel';
import { buildBellCurveReport } from './graphic/bellcurve/controller/BellCurveReportController';
import { BuildBellCurveReportView } from './graphic/bellcurve/ui/BellCurveChart';
import { buildHierarchyListReport } from './data/hierarchylist/controller/HierarchyListController';
import { HierarchyListReportModel } from './data/hierarchylist/model/HierarchyListModel';

export async function executeReportWithOrWithoutFilter(kagamiReportModel: KagamiReportModel, fromDrillDown:Boolean = false) {
  let component: JSX.Element[] = [];
  let type: any;
  let reportConfig:any = kagamiReportModel['reportConfig']??{};
  let reportConfigSummary:any = reportConfig['reportConfigSummary'];
  // if(!kagamiReportModel.fromDrillDown)setReportModel(kagamiReportModel);
  if(!fromDrillDown)setReportModel(kagamiReportModel);
  getFilterSelectedData(kagamiReportModel);
  if (reportConfigSummary != null) {
    type = reportConfigSummary.type;
  }

    let staticList = getStaticReportTypes();
    let isStaticReport = false;
    let reportInfo:any = { staticFilterData:{},reportContent:''};
    if(staticList && staticList.length > 0  && staticList.indexOf(type) !=-1){
      isStaticReport  = true;
      reportInfo['staticFilterData'] = await getStaticFilterData(type);
      if(reportInfo?.staticFilterData?.data?.projectPlan) {
        reportInfo.staticFilterData.data['allProjects'] = reportInfo.staticFilterData.data['projectPlan']
      }
    } 
    
    let reportDataResponse = {
      data: {
        respObject: {}
      }
    };

        switch (type) {
          case 'AreaChart':
          case 'Area':
            let areaReportModel = new AreaReportModel(reportDataResponse.data.respObject);
            areaReportModel.reportConfig = reportConfig;
            areaReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            areaReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // areaReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildAreaReport(areaReportModel));
            break;
          case 'Bar':
          case 'BarChart':
            let barReportModel = new BarReportModel(reportDataResponse.data.respObject);
            barReportModel.reportConfig = reportConfig;
            barReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            barReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // barReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildBarReport(barReportModel));
            break; 
          case 'ColumnChart':
            let columnReportModel = new ColumnReportModel(reportDataResponse.data.respObject);
            columnReportModel.reportConfig = reportConfig;
            columnReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            columnReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // columnReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildColumnReport(columnReportModel));
            break; 
          case 'doughnut':  
            let doughnutReportModel = new DoughnutReportModel(reportDataResponse.data.respObject);
            doughnutReportModel.reportConfig = reportConfig;
            doughnutReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            doughnutReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // doughnutReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildDoughnutReport(doughnutReportModel));
          break;    
          case 'Funnel': 
            let funnelReportModel = new FunnelReportModel(reportDataResponse.data.respObject);
            funnelReportModel.reportConfig = reportConfig;
            funnelReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            funnelReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // funnelReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildFunnelReport(funnelReportModel));
            break;     
            case 'GaugeChart':
              let gaugeReportModel = new GaugeReportModel(reportDataResponse.data.respObject)
              gaugeReportModel.reportConfig = reportConfig;
              gaugeReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
              gaugeReportModel.fromDashboard = kagamiReportModel.fromDashboard;
              // gaugeReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
              component.push(buildGaugeReport(gaugeReportModel));
              break;     
          case 'KPI':
            let kpiReportModel = new KPIReportModel(reportDataResponse.data.respObject);
            kpiReportModel.reportConfig = reportConfig;
            kpiReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            kpiReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // kpiReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildKPIReport(kpiReportModel));
            break;  
          case 'List':
            let listReportModel = new ListReportModel(reportDataResponse.data.respObject);
            listReportModel.reportConfig = reportConfig;
            listReportModel.reportConfigSummary = reportConfig.reportConfigSummary;
            listReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            listReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // listReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildListReport(listReportModel));
            break;   
          case "Tree List":
            let hierarchyListReportModel = new HierarchyListReportModel(reportDataResponse.data.respObject);
            hierarchyListReportModel.reportConfig = reportConfig;
            hierarchyListReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            hierarchyListReportModel.treeColumn = reportConfig['reportSettings']['treeColumn'] ?? 'Grouped Column';
            hierarchyListReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // hierarchyListReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildHierarchyListReport(hierarchyListReportModel));
            break; 
          case 'LineChart':
            let lineReportModel = new LineReportModel(reportDataResponse.data.respObject);
            lineReportModel.reportConfig = reportConfig;
            lineReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            lineReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // lineReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildLineReport(lineReportModel));
            break; 
          case 'PieChart':
            let pieReportModel = new PieReportModel(reportDataResponse.data.respObject);
            pieReportModel.reportConfig = reportConfig;
            pieReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            pieReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // pieReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildPieReport(pieReportModel));
            break;  
          case 'PivotChart':
          case 'Pivot':
          case 'PivotUi':
            let pivotReportModel = new PivotReportModel(reportDataResponse.data.respObject);
            pivotReportModel.reportConfig = reportConfig;
            pivotReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            pivotReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // pivotReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildPivotReport(pivotReportModel));
            break;
          case 'StackedbarChart':
            let stackedBarReportModel = new StackedBarReportModel(reportDataResponse.data.respObject);
            stackedBarReportModel.reportConfig = reportConfig;
            stackedBarReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            stackedBarReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // stackedBarReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildStackedBarReport(stackedBarReportModel));
            break; 
          case 'StackedcolumnChart':
            let stackedColumnReportModel = new StackedColumnReportModel(reportDataResponse.data.respObject);
            stackedColumnReportModel.reportConfig = reportConfig;
            stackedColumnReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            stackedColumnReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // stackedColumnReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildStackedColumnReport(stackedColumnReportModel));
            break;
          case 'Tilecharts':
            let tileReportModel = new TileChartReportModel(reportInfo);
            tileReportModel.reportConfig = reportConfig;
            tileReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            tileReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // tileReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildTileChartReport(tileReportModel));
            break;          
          case 'TileView':
            let tileViewReportModel = new TileViewReportModel(reportInfo);
            tileViewReportModel.reportConfig = reportConfig;
            tileViewReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            tileViewReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // tileViewReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildTileViewReport(tileViewReportModel));
          break; 
          case 'BellCurve':
            let bellCurveReportModel = new BellCurveReportModel(reportInfo);
            bellCurveReportModel.reportConfig = reportConfig;
            bellCurveReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            bellCurveReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // bellCurveReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(BuildBellCurveReportView(bellCurveReportModel));
          break; 
           /** static reports */
          case 'Bank Account':
          case 'Cash Account':
          case 'Bank Ledger':
          case 'Cash Ledger':
          case 'Customer Ledger':
          case 'General Ledger':
          case 'Vendor Ledger':
            let ledgerAccountReportModel = new LedgerAccountReportModel(reportInfo);
            ledgerAccountReportModel.reportConfig = reportConfig;
            ledgerAccountReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            ledgerAccountReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // ledgerAccountReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildLedgerAccountReport(ledgerAccountReportModel));
            break;  
          case 'Sub Ledger':
          let subLedgerReportModel = new SubLedgerReportModel(reportInfo);
          subLedgerReportModel.reportConfig = reportConfig;
          subLedgerReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
          subLedgerReportModel.fromDashboard = kagamiReportModel.fromDashboard;
          // subLedgerReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
          component.push(buildSubLedgerReport(subLedgerReportModel));
          break;   
          case 'Stock Ledger':
          let stockLedgerReportModel = new StockLedgerReportModel(reportInfo);
          stockLedgerReportModel.reportConfig = reportConfig;
          stockLedgerReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
          stockLedgerReportModel.fromDashboard = kagamiReportModel.fromDashboard;
          // stockLedgerReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
          component.push(buildStockLedgerReport(stockLedgerReportModel));
          break;   
          case 'Customer Ageing':
          case 'Vendor Ageing':
            let ageingReportModel = new AgeingReportModel(reportInfo);
            ageingReportModel.reportConfig = reportConfig;
            ageingReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            ageingReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // ageingReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildAgeingReport(ageingReportModel));
            break;
          case 'BLReport':
            let balanceSheetReportModel = new BalanceSheetReportModel(reportInfo);
            balanceSheetReportModel.reportConfig = reportConfig;
            balanceSheetReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            balanceSheetReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // balanceSheetReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildBalanceSheetReport(balanceSheetReportModel));
            break;
          case 'PLReport':
            let profitLossReportModel = new ProfitLossReportModel(reportInfo);
            profitLossReportModel.reportConfig = reportConfig;
            profitLossReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            profitLossReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // profitLossReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildProfitLossReport(profitLossReportModel));
            break;
          case 'TrialBLReport':
            let trialBalanceReportModel = new TrialBalanceReportModel(reportInfo);
            trialBalanceReportModel.reportConfig = reportConfig;
            trialBalanceReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            trialBalanceReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // trialBalanceReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildTrialBalanceReport(trialBalanceReportModel));
            break;
          case 'DayBook':  
            let dayBookReportModel = new DayBookReportModel(reportInfo);
            dayBookReportModel.reportConfig = reportConfig;
            dayBookReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
            dayBookReportModel.fromDashboard = kagamiReportModel.fromDashboard;
            // dayBookReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
            component.push(buildDayBookReport(dayBookReportModel));
          break;  
          case 'Vendor Payment Due List':
          case 'Customer Payment Due List':
          let paymentDueListReportModel = new PaymentDueListReportModel(reportInfo);
          paymentDueListReportModel.reportConfig = reportConfig;
          paymentDueListReportModel.reportFilterModel = kagamiReportModel.reportFilterModel;
          paymentDueListReportModel.fromDashboard = kagamiReportModel.fromDashboard;
          // paymentDueListReportModel.fromDrillDown = kagamiReportModel.fromDrillDown;
          component.push(buildPaymentDueListReport(paymentDueListReportModel));
          break;  
          default:
            return component;
        }

return component;
}