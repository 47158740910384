import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiHomeStyle = (theme: Theme) =>
  createStyles({
    page: {
      display: 'flex',
      height: '100%'
    },
    pageSide: {
      display: 'flex',
      flex: '3',
      alignItems: 'flex-strech',
      backgroundColor: '#13344d',
      overflow: 'auto',
      overflowY: 'auto',
      overflowX: 'auto',
      [theme.breakpoints.down('md')]: {
        flex: '0'
      }
    },

    pageconent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '20'
    },
    pageheader: {
      display: 'flex',
      minHeight: theme.spacing(7),
      maxHeight: theme.spacing(7)
    },
    pageDashboard: {
      'flex': '1',
      'display': 'flex',
      'flexWrap': 'wrap',
      'backgroundColor': theme.palette.grey[200],
      'overflowY': 'auto',
      'overflowX': 'auto',
      '&::-webkit-scrollbar': {
        width: '0.5em'
      }
    },
    pageFooter: {
      minHeight: '5%',
      backgroundColor: theme.palette.background.paper,
      flex: '0'
    },

    root: {
      flexGrow: 1
    }
  });
