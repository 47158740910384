import { State, useState } from "@hookstate/core";
import { KagamiReportModel } from "../../../../model/KagamiReportModel";

export class TileViewReportModel extends KagamiReportModel {
    reportData:any;
    isDataLoaded:boolean;
    state:any;
    constructor(reportContent: any) {
        super()
        this.reportData = reportContent;
        this.isDataLoaded = false;
      }
}

export interface TileViewReportModelProps{
    tileViewReportModel:TileViewReportModel
}

export const useTileViewReportModelState = (state:State<TileViewReportModel>) => {

    let tileViewReportState = useState(state);

    return {
        reportConfig() {
          tileViewReportState.reportData.get();
        },
        setReportConfig(reportContent: any) {
          tileViewReportState.reportData.set(reportContent);
        },
    
        get isDataLoaded(){
          return tileViewReportState.isDataLoaded.get()
        },
    
        setDataLoaded(isDataLoaded:boolean){
          tileViewReportState.isDataLoaded.set(isDataLoaded);
        }
      };
}