import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export const ScheduleReportStyle = (theme: Theme) =>
  createStyles({
    root: {
      'boxShadow': '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0 2px 7px 0 rgba(0, 0, 0, 0.19)',
      'margin': '20px',
      'borderRadius': '5px',
      '& .box-header':{
        'height': '44px',
        'borderBottom': '1px solid #CBCBCB',
        '& .header-count':{
          'float': 'left',
          'margin': '10px',
          'background': '#CBCBCB',
        },
        '& .header-name': {
          'float': 'left',
          'margin': '11px 5px 9px 20px',
          'color': '#1E3148'
        },
        '& .delete-scheduler-icon': {
          'float': 'right',
          'margin': '5px',
          'background': '#F62A2A',
          'height': '30px'
        }
      },
      '& .box-body' : {
        'height': 'fit-content',
        'width': '100%',
        'display': 'flex',
        'justifyContent': 'space-between',
        '& .sche-left-box': {
          'display': 'flex',
          'width': '70%',
          'margin': '10px 0px',
          'borderRight': '1px dashed #CBCBCB',
          '& .sche-left-main-box': {
            'width': '100%',
            'display': 'flex',
            'flexWrap': 'wrap',
            '& .sche-each-boxes': {
              'display': 'flex',
              'width': 'calc(100%/3 - 20px)',
              'margin': '10px',
              '& .label': {
                'color': '#00856B',
                'minWidth': '140px',
                'maxWidth': '140px'
              },
              '& .colon': {
                'color': '#565656',
                'marginRight': '8px'
              },
              '& .value': {
                'color': '#565656',
                'overflow': 'hidden',
                'whiteSpace': 'unset',
                'textOverflow': 'ellipsis',
              },
              '& .expand': {
                'whiteSpace': 'nowrap'
              },
            },
            '& .sche-last-box': {
              'width': 'calc(100% - 20px)',
              'cursor': 'pointer',
            }
          },
        },
        '& .sche-right-box': {
          'width': '30%',
          'padding': '20px'
        }
      },
    },

    addScheduler: {
      'height': '60px',
      '& .addSchedulerButton': {
        'margin': '20px',
        'backgroundColor': '#00856B',
        '&:hover': {
          'background': 'rgb(237 237 237)',
          'color': 'rgba(0, 0, 0, 0.54)'
        }
      }
    },
    saveButton: {
      'float': 'left',
      'marginTop': '20px',
      'marginLeft': '6px',
      'borderRadius': '5px',
      'backgroundColor': '#00856B',
      'height': '30px',
      '&:hover': {
        'background': 'rgb(237 237 237)',
        'color': 'rgba(0, 0, 0, 0.54)'
      }
    },
    schedulername: {
      height: '0px',
      width: '46%',
      marginRight: '20px'
    },
    scheduleradio: {
      display: 'flex',
      flexDirection: 'inherit'
    },

    CardContent: {
      height: '100%',
      paddingLeft: '20px',
      border: '1px solid',
      display: 'block',
      position: 'relative'
    },
    cardbox: {
      display: 'flex',
      flexDirection: 'column',
      width: '55%'
    },
    maincard: { display: 'flex', width: '100%', fontSize: '14px', fontWeight: 'lighter' }
  });

export const SchedulerExpandStyle = (theme: Theme) =>
  createStyles({
    container: {
      '& p': {
        color: '#565656',
        display: 'inline'
      },
      '& .extra': {
        display: 'none'
      },
      '& input[type="checkbox"]': {
        height: '2em',
        display: 'inline',
        appearance: 'none'
      },
      '& label': {
        'position': 'relative',
        'cursor': 'pointer',
        'color': 'red',
        'fontSize': '11px',
        'padding': '1em',
        '&::before': {
          // 'content': (props:any)=>`"${props.before}"`
          content: `"Read More"`
        }
      },
      '& input[type="checkbox"]:checked ~ label::before': {
        // 'content': (props:any)=>`"${props.after}"`,
        content: `"Read Less"`
      },
      '& .handleExtra': {
        display: 'inline'
      },
      '& .handleDots': {
        display: 'none'
      }
    }
  });
  

export default ScheduleReportStyle;
