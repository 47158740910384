import { createState } from '@hookstate/core';
import {
  ColumnReportModel,
  ColumnReportModelProps,
  useColumnReportModelState
} from '../model/ColumnReportModel';
import { makeColumn } from '../service/ColumnReportService';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { CircularProgress } from '@mui/material';

export const BuildColumnReportView = (columnReportModel: ColumnReportModel) => {
  return (
    <KagamiColumnChartReportView key={'column-chart-report'} columnReportModel={columnReportModel}></KagamiColumnChartReportView>
  );
};


const KagamiColumnChartReportView = (props: ColumnReportModelProps) => {
  let columnReportModel = props.columnReportModel;
  const chartComponent:any = React.useRef(null);
  let columnChartState = useColumnReportModelState(createState(columnReportModel));
  columnReportModel.state = columnChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(props.columnReportModel)
  }, [])


  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartComponent.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);
  
  if(!columnChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{
    if(columnReportModel.reportData){
  const columnConfig :any = makeColumn(columnReportModel);

  return (
    <div ref={containerRef} className="ag-theme-alpine dashboard-highchart kgm-report-hw">
         <Chart ref={chartComponent} highcharts={Highcharts} options={columnConfig} />
      </div>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
  }
};
