import { createState } from '@hookstate/core';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import { getGuageChartData } from '../../../../service/KagamiReportService';
import { CircularProgress } from '@mui/material';
import { GaugeReportModel, GaugeReportModelProps, useGaugeReportModelState } from '../model/GaugeReportModel';
import { makeGauge } from '../service/GaugeReportService';
import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';

ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

export const BuildGaugeReportView = (gaugeReportModel: GaugeReportModel) => {
  return (
    <KagamiGaugeChartReportView key={'gauge-chart-report'} gaugeReportModel={gaugeReportModel}></KagamiGaugeChartReportView>
  );
};

const KagamiGaugeChartReportView = (props: GaugeReportModelProps) => {
  let gaugeReportModel = props.gaugeReportModel;
  let gaugeChartState = useGaugeReportModelState(createState(gaugeReportModel));
  const chartComponent:any = React.useRef(null);
  gaugeReportModel.state = gaugeChartState;
  
  React.useEffect(() => {
    getGuageChartData().then((response:any)=>{
      gaugeReportModel.reportData = response.data.employeecount??0;
        gaugeReportModel.state.setDataLoaded(true);
    }).catch(()=>{
        gaugeReportModel.state.setDataLoaded(true);
    })
  }, [])

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartComponent.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);
  
  if(!gaugeChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" /> 
  }else{
  const gaugeConfig :any = makeGauge(gaugeReportModel);

  return (
    <div ref={containerRef} className="ag-theme-alpine dashboard-highchart kgm-report-hw">
         <Chart ref={chartComponent} highcharts={Highcharts} options={gaugeConfig} />
      </div>
  );
}
};
