import { SystemConstants } from '../../../../../../../../../../../../constants/SystemConstants';
import { APIService } from '../../../../../../../../../../../../service/ApiService';
import { ParentId } from '../../../../../model/ReportsLandingPageModel';
import { getReportFolderStructureData, getReportsAndFolderData } from '../../../../../service/ReportsLandingPageService';
import { ReportIconPopoverModel } from '../model/ReportIconPopoverModel';
import { getCreateFolderURL } from '../../../../../../../../../../../../service/URLService';
import { buildtoastNotification } from '../../../../../../../../../ToastNotification/controller/ToastNotificationController';
import { getReportIconPopover } from '../controller/ReportIconPopoverController';
import { mappingFolderWithParentFolder } from '../../../../../controller/ReportsLandingPageController';
// import { showToastAlert } from '../../../../../../../../../../../../controller/KagamiApplicationController';

export const getReportsAndFolderDataForReportIconPopover = async (reportIconPopoverModel: ReportIconPopoverModel) => {
  await getReportsAndFolderData()
    .then((response: any) => {
      if (Array.isArray(response)) {
        reportIconPopoverModel.reportList = response[0]?.data?.respObject ?? [];
        reportIconPopoverModel.folderList = response[1]?.data?.respObject ?? [];

        // for reports
        let newReportList: any[] = [];
        reportIconPopoverModel.reportList.forEach((reportConfig: any) => {
          if (reportConfig['folderId'] === undefined) {
            newReportList.push(reportConfig);
          }
        });
        reportIconPopoverModel.newReportList = newReportList;

        //for folders
        // reportIconPopoverModel.newFolderList = reportIconPopoverModel.folderList ?? [];
        reportIconPopoverModel.folderTiles[ParentId.id] = reportIconPopoverModel.folderList ?? [];
        // reportIconPopoverModel.newReportList = reportIconPopoverModel.reportList;

        reportIconPopoverModel.state.setDataLoaded(true);
      }
    })
    .catch((error: any) => {
      console.log('getting error while fetching record from reportIcon click', error);
    });
};

const getFolderStructure = async () => {
  await getReportFolderStructureData().then((response:any)=>{
    const reportIconPopoverModel: ReportIconPopoverModel = getReportIconPopover();
    reportIconPopoverModel.folderList = response?.data?.respObject ?? [];
    reportIconPopoverModel.folderTiles = {};
    reportIconPopoverModel.folderTiles[ParentId.id] = reportIconPopoverModel.folderList ?? [];
    mappingFolderWithParentFolder(reportIconPopoverModel.folderList, reportIconPopoverModel.folderTiles);

    // todo's
    // modify flattend func and use same function rather creating new 
    // already availaible in landing page service
    flatteningFoldersValueToArrayReportIcon(reportIconPopoverModel);

    //showing success alert
    buildtoastNotification('success', 'Save Successfull' ,0);
    // rendering state here
    // reportIconPopoverModel.state.setRenderer(Math.random());
    reportIconPopoverModel.setRenderer(Math.random());

  }).catch((err:any)=>{
    console.error(err);
  })
}

export const createFolder = (folderData:any) => {
  getCreateFolder(folderData).then((response:any)=>{
    getFolderStructure();
    
  }).catch((error:any)=>{
    console.error(error);
  });
}

const getCreateFolder = async (folderData:any) => {
  return await APIService.getData(SystemConstants.POST,getCreateFolderURL(),folderData)
}

export const flatteningFoldersValueToArrayReportIcon = (reportIconPopoverModel: ReportIconPopoverModel) => {
  let flattenedArray: any[] = [];
  for (const setOfValues of Object.values(reportIconPopoverModel.folderTiles)) {
    if (Array.isArray(setOfValues)) {
      flattenedArray = flattenedArray.concat(setOfValues);
    }
  }
  reportIconPopoverModel.setOfFolderConfig = flattenedArray;
};