import { State, useState } from '@hookstate/core';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';

export class KagamiBulkUploadModel {
  state: any;
  headerColumns: Array<any>;
  isLogoLoading: boolean;
  fileUploaed: any;
  kagamiModalModel: KagamiModalModel;
  isFileUploadCancelled: boolean;
  isButtonActive: boolean;
  isGroupForm: boolean;
  uploadedResponse: any;
  docIdResponse: string;
  constructor() {
    this.headerColumns = [];
    this.isLogoLoading = false;
    this.isFileUploadCancelled = false;
    this.isButtonActive = false;
    this.docIdResponse = '';
    this.isGroupForm = false;
    this.kagamiModalModel = new KagamiModalModel();
  }
}

export interface KagamiBulkUploadProps {
  bulkUploadModel: KagamiBulkUploadModel;
  res: any;
  popupModel: KagamiModalModel;
}

export const wrapKagamiBulkUploadModelState = (state: State<KagamiBulkUploadModel>) => {
  const kagamiBulkUploadState = state;

  return {
    get getHeaderColumns() {
      return kagamiBulkUploadState.headerColumns.get();
    },

    setHeaderColumns(headerColumns: any) {
      kagamiBulkUploadState.headerColumns.set(headerColumns);
    },

    get getFileUploaed() {
      return kagamiBulkUploadState.fileUploaed.get();
    },

    setFileUploaed(fileUploaed: any) {
      kagamiBulkUploadState.fileUploaed.set(fileUploaed);
    },

    get getIsLogoLoading() {
      return kagamiBulkUploadState.isLogoLoading.get();
    },

    setIsLogoLoading(isLogoLoading: boolean) {
      kagamiBulkUploadState.isLogoLoading.set(isLogoLoading);
    },

    get getIsFileUploadCancelled() {
      return kagamiBulkUploadState.isFileUploadCancelled.get();
    },

    setIsFileUploadCancelled(isFileUploadCancelled: boolean) {
      kagamiBulkUploadState.isFileUploadCancelled.set(isFileUploadCancelled);
    },

    get getIsButtonActive() {
      return kagamiBulkUploadState.isButtonActive.get();
    },

    setIsButtonActive(isButtonActive: boolean) {
      kagamiBulkUploadState.isButtonActive.set(isButtonActive);
    },

    get getUploadedResponse() {
      return kagamiBulkUploadState.uploadedResponse.get();
    },

    setUploadedResponse(uploadedResponse: any) {
      kagamiBulkUploadState.uploadedResponse.set(uploadedResponse);
    },

    get getDocIdResponse() {
      return kagamiBulkUploadState.docIdResponse.get();
    },

    setDocIdResponse(docIdResponse: string) {
      kagamiBulkUploadState.docIdResponse.set(docIdResponse);
    }
  };
};

export const useKagamiBulkUpload = (state: State<KagamiBulkUploadModel>) => {
  let bukUploadState = wrapKagamiBulkUploadModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.BULKUPLOAD_STATE, bukUploadState);
  return bukUploadState;
};
