import extend from 'lodash/extend';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import {getReportData,
        } from '../../../../service/ReportUtilService';
const barDefaultConfig = ()=> {
  return  {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'bar',
        height: 450,
    },
    exporting: {
        enabled: false
    },
    title: {
        text: '',
        align: 'center',
    },
    tooltip: {
      shared: true,
      valueSuffix: ' '
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            },
            enableMouseTracking: true
        }
    },
    credits: {
      enabled: false,
    },
    series: [{
        name: '',
        colorByPoint: true,
        data:[],
    }] 
  }	
}

export const makeBar = (barReportController: any) => {
  let defaultConfig  = barDefaultConfig();
  let dbChartData = JSON.parse(barReportController.reportData);
  let tablerecords = getReportData(dbChartData);
  let userConfig = prapareUserConfigForChart(tablerecords,barReportController.reportConfig,false);
  let dispchartOptions =  extend(true, {}, defaultConfig, userConfig);
  return dispchartOptions;
}

const prapareUserConfigForChart = (tablerecords:any,reportConfig:any,isDashboard:any)  => {
    let userConfig: any = {
        yAxis: {
        title: {
            text: getYAxisLabels(reportConfig.horizontalAxis)
        },
        min: 0
        },
        xAxis: {
        title: {
            text: reportConfig.verticalAxis
        },
        min: 0,
        categories: bar_getColumn(reportConfig.verticalAxis,tablerecords)
        },
        series: [
        {
            data: []
        }
        ]
    };
    let seriesData: any = [];
    let xaxis = reportConfig.horizontalAxis[0];
    let yaxis = reportConfig.verticalAxis[0];
    if (xaxis && yaxis) {
       forEach(tablerecords, function (rec, index) {
        seriesData.push({
          name: rec[yaxis],
          y: parseFloat(rec[xaxis])
        });
      });
    }
  userConfig.series[0].data = seriesData;
  return userConfig;
}


const getYAxisLabels = (arr:any) => {
     return  (arr && arr.length > 0) ? arr.join(",") : ''
 }
const bar_getColumn = (colId:any,chartData:any) => {
    return  map(chartData, function (row) {
        return row[colId];
    });
}