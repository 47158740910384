import * as React from 'react';
import { createState ,useState } from '@hookstate/core';
import {
  ProfitLossReportModel,
  ProfitLossReportModelProps,
  useProfitLossReportModelState
} from '../model/ProfitLossReportModel';
import { makeReport,makeReportData ,getData ,onChangeState,downloadExcel} from '../service/ProfitLossReportService';
import Grid from '@mui/material/Grid';
import { Autocomplete,TextField,Button,ButtonGroup } from '@mui/material';
import {  AgGridReact } from 'ag-grid-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';


export const BuildProfitLossReportView = (profitLossReportModel: ProfitLossReportModel) => {
  return (
    <ProfitLossReportView key={'profit-loss-report'} profitLossReportModel={profitLossReportModel} />
  );
};


const ProfitLossReportView =  (props: ProfitLossReportModelProps) => {

  let initialGridApi :any = null;
  const [isExpanded, setISExpanded] = React.useState(false);
  const [gridApi, setGridApi] = React.useState(initialGridApi);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const expandOrCollapse = () => {
    isExpanded ? gridApi?.collapseAll() : gridApi?.expandAll();
    setISExpanded(!isExpanded)
  };
   //Open
   const [startDateCalendar, setStartDateCalendar] = React.useState(false);
   const [endDateCalendar, setEndDateCalendar] = React.useState(false);
   // Date
   let startDateYear=new Date();
   startDateYear.setDate(1);
   startDateYear.setMonth(0);
   let yearStartDate = new Date(startDateYear).toISOString().substring(0, 10);
   let todayDate = new Date().toISOString().substring(0, 10);
   const [startDate, setStartDate] = React.useState(yearStartDate);
   const [endDate, setEndDate] = React.useState(todayDate);
   const [selectstate,setSelectState] = React.useState(false);

  let runReportConfig:any = useState({
    loadReport : false,
    loadFilterData:true,
    showLoader:false,
    reportInfo:{}
  });

  let profitLossReportModel = props.profitLossReportModel;
  let profitLossChartState = useProfitLossReportModelState(createState(profitLossReportModel));
  profitLossReportModel.state = profitLossChartState;
  let staticFilterData :any;
  if(profitLossReportModel?.reportData?.staticFilterData) {
    staticFilterData  = profitLossReportModel.reportData['staticFilterData']['data'];
  }
  let gridReportInfo:any ={}
  if(profitLossReportModel?.reportData?.gridReportInfo) {
    gridReportInfo  = profitLossReportModel.reportData['gridReportInfo'];
  }

  let inputReportConfig= (props.profitLossReportModel.reportConfig);
  const runReport = async () => {
    runReportConfig.showLoader.set(true);
    let reportInfo =  makeReport(profitLossReportModel);
    let plReportData =  await getData(profitLossReportModel,inputReportConfig)
    let rowData = await makeReportData(profitLossReportModel,plReportData)
    reportInfo['rowData']= rowData;
    reportInfo['reportDBData']= plReportData;
    const obj = Object.assign({}, reportInfo);
    props.profitLossReportModel.reportData['gridReportInfo'] = obj;
    runReportConfig.showLoader.set(false);
    runReportConfig.loadReport.set(true);
  }

  const handleStateChange =  async (value:any) => {
    let projectPlan = [];
    if (value && value.length > 0) {
      projectPlan = await onChangeState(value, profitLossReportModel);
    } else {
      projectPlan = staticFilterData['allProjects'];
    }
    staticFilterData['projectPlan'] = projectPlan;
    runReportConfig.loadFilterData.set(true);
  }
  const handleStaticFilters = (value: any, type: any) => {
    if (type == 'startDate' || type == 'endDate') {
      if (!inputReportConfig['leftPanel']) {
        inputReportConfig['leftPanel'] = {};
      }
      inputReportConfig['leftPanel'][type] = value;
    } else if (type == 'state' || type == 'projectPlan') {
      inputReportConfig[type] = value && value.length > 0 ? value.join(",") : [];
    } else {
      inputReportConfig[type] = value;
    }
  }

  const onDownloadExcel = () =>{
    downloadExcel(props.profitLossReportModel);
  }
  return (
    <div className="Report-MuiFormControl-root " style={{width:'100%' ,background: '#fff',padding: '10px'}}>
      {/* fields  */}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {/* Sub Organization */}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="plReportSubOrganization"
            options={staticFilterData && staticFilterData.suborganization||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
                label="Sub Organization"
                placeholder="Select Sub Organization"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'selectedValue');
            }}

          />
        </Grid>
        {/* Currency */}
        {staticFilterData?.currency && staticFilterData.currency.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="plReportCurrency"
            options={staticFilterData && staticFilterData.currency||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
              required
              {...params}
              InputLabelProps={{ shrink: true }}
                label="Currency List "
                placeholder="Select Currency"
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'selectedCurrency');
            }}
          />
          </Grid>  
          }
        {/* State */}
        { runReportConfig.loadFilterData.get() &&  staticFilterData?.state && staticFilterData.state.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
           size="small"
            multiple
            id="plReportState"
            options={staticFilterData && staticFilterData.state||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
                label="State"
                placeholder={selectstate === false?"Select state":''}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStateChange(newValue);
              handleStaticFilters(newValue,'state');
              if(newValue.length>0){setSelectState(true)}
              else{
                setSelectState(false)
              }
            }}
          />
        </Grid>
          }
        {/* Project */}
        { runReportConfig.loadFilterData.get() &&  staticFilterData?.projectPlan && staticFilterData.projectPlan.length > 0 && 
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            multiple
            id="plReportProject"
            options={staticFilterData && staticFilterData.projectPlan||[]}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions
            renderInput={(params: any) => (
              <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
                label="Project"
                placeholder={selectstate === false?"Select project":''}
              />
            )}
            onChange={(event:any,newValue:any|null) => {
              handleStaticFilters(newValue,'projectPlan');
              if(newValue.length>0){setSelectState(true)}
              else{
                setSelectState(false)
              }
            }}
          />
        </Grid>
          }
         {/* From Date */}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker 
              views={['year', 'month','day']}
              open={startDateCalendar}
              onOpen={() => setStartDateCalendar(true)}
              onClose={() => setStartDateCalendar(false)}
              label="From Date"
              value={startDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setStartDate(newDate);
                  let val = newDate+" 00:00:00";
                  handleStaticFilters(val,'startDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                   
                  fullWidth
                  className='full-width'
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setStartDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
         {/* To Date */}
         <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month','day']}
              open={endDateCalendar}
              onOpen={() => setEndDateCalendar(true)}
              onClose={() => setEndDateCalendar(false)}
              label="To Date"
              value={endDate}
              onChange={(newValue:any) => {
                if (newValue !== null) {
                  const newDate = moment(newValue).format('YYYY-MM-DD');
                  setEndDate(newDate);
                  let val = newDate+" 23:59:59";
                  handleStaticFilters(val,'endDate');
                }
              }}
              renderInput={(params:any) => {
                return (
                  <TextField
                  fullWidth
                    
                    {...params}
                    size="small"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onClick={(e) => setEndDateCalendar(true)}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>

      </Grid>
        {/* fields end */}
      {/* Run */}
      <Grid container style={{ margin: '10px',textAlign:'right',paddingRight:'10%'}} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        <Grid item xs={12}>
        <ButtonGroup  className="static-report-btngroup" >
        <Button style={{ margin: '2px',textAlign:'center'}} variant="contained" onClick={() => { runReport()}}>
            Run
          </Button>
        <Button
        style={{ margin: '2px',textAlign:'center'}}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onDownloadExcel()}>
            Download Excel
        </Button>
          <Button style={{ margin: '2px',textAlign:'center'}} variant="contained" onClick={() => { expandOrCollapse()}}>
            {isExpanded ? 'Collapse All':'Expand All'}
          </Button>
          

        </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '10px',textAlign:'center'}}  >
        <Grid item xs={12}>
        {runReportConfig.showLoader.get()  && 
              <CircularProgress color="secondary" />
        }
        </Grid>
      </Grid>
      {runReportConfig.loadReport.get()  && 
        <div className="ag-theme-alpine kgm-report-aggrid kgm-report-hwm dashboard-report" >
           <AgGridReact 
            autoGroupColumnDef = {gridReportInfo.autoGroupColumnDef}
            columnDefs={gridReportInfo.columnDefs}
            defaultColDef={gridReportInfo.defaultColDef}
            getDataPath = {gridReportInfo.getDataPath}
            groupIncludeTotalFooter = {gridReportInfo.groupIncludeTotalFooter}
            icons = {gridReportInfo.icons}
            rowData={gridReportInfo.rowData}
            // pagination = {gridReportInfo.pagination}
            // paginationPageSize = {gridReportInfo.paginationPageSize}
            treeData = {gridReportInfo.treeData }
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
        }
      </div>
  );
};
