import { State, useState } from "@hookstate/core";
import { KagamiStateManager } from "../../../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../../../constants/SystemConstants";
import { CommonUtils } from "../../../../../../../../../../../../../utils/CommonUtils";
import { embedHasFormControls } from "../../../../../../../service/PresentationService";
import { getFormRecords } from "../../../../../service/FormService";

export class KagamiEmbeddedTabsPresentationModel{
   activeIndex : any;
   embeddedPresentationsList : any[];
   state : any;
   visible : boolean;
   key:string;
   formEntityId : any;
   embeddedDataCount:any;
    isNestedEmbedModel : boolean
     
   constructor(activeTabIndex : any,embeddedPresentationsList : any[],key : string){
       this.activeIndex = activeTabIndex;
       this.embeddedPresentationsList = embeddedPresentationsList;
       this.state = null;
       this.visible = true;
       this.key = key;
       this.formEntityId = ''
       this.embeddedDataCount = populateEmbedDataCounts(embeddedPresentationsList)
       this.isNestedEmbedModel = false
   }
}

export interface EmbeddedTabPresentationProps{
     embeddedTabsPresentationModel : KagamiEmbeddedTabsPresentationModel
}

export const wrapEmbedTabsState = (state: State<KagamiEmbeddedTabsPresentationModel>) => {
    let embedTabsState = state;
  
    return {
      getActiveTabIndex(){
          return embedTabsState.activeIndex.get();
      },
      setActiveTabIndex(tabIndex : any){
        embedTabsState.activeIndex.set(tabIndex);
      },
      get isVisible(){
        return embedTabsState.visible.get()
      },
      setVisible(visible : boolean){
        embedTabsState.visible.set(visible)
      },
      getDataCount(){         
        return embedTabsState.embeddedDataCount.get()
      },
      setDataCount(embedCount:any){
        let oldDataCount:any = embedTabsState.embeddedDataCount.get()
        let newDataCount = {
          ...oldDataCount,
          ...embedCount
        }
        let keys = Object.keys(embedCount)
        if(embedCount[keys[0]] != oldDataCount[keys[0]]) {

          embedTabsState.embeddedDataCount.set(newDataCount) 
        }        
      }
    };
  };
  
  export const useKagamiEmbedTabsModelState = (state: State<KagamiEmbeddedTabsPresentationModel>, isNested:boolean) =>{
    let tabsState = wrapEmbedTabsState(useState(state));
    if (isNested) {
      KagamiStateManager.addStateToHolder('nested_tabs_state', tabsState);      
    } else {
        KagamiStateManager.addStateToHolder(SystemConstants.TABS_STATE, tabsState);
    }
    return tabsState

  }  

function populateEmbedDataCounts(embedPresentatinList : any[]){
  let dataCounts : any = {}
  for(let embedModel of embedPresentatinList){
     dataCounts[embedModel.formEntityId] = CommonUtils.isNotEmpty(embedModel.onLoadData) ? embedModel.onLoadData.length : 0
  }
  return dataCounts;
}

