import { createState } from '@hookstate/core';
import { MenuList  } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { onHideProfile } from '../../../controller/KagamiPageHeaderController';

import {  } from '../../../model/kagamiHeaderUserProfile.';
import {  } from '../../../model/KagamiPageHeaderModel';

import { buildProfileMenu } from '../builder/profileBuilder';
import { hideProfile } from '../controller/kagamiProfileController';
// import { KagamiProfileController } from '../controller/kagamiProfileController';
import { KagamiProfileModel, KagamiProfileProps, useKagamiHeaderProfileState,  } from '../model/KagamiProfileModel';

export function buildUserProfile(kagamiProfileModel: KagamiProfileModel, translations?: any) {
  // return ProfileProcess(null);
  return <ProfileProcess key='profile' kagamiProfileModel={kagamiProfileModel} translations={translations}></ProfileProcess>;
}

export const ProfileProcess = (props: KagamiProfileProps) => {
let profileModalState = useKagamiHeaderProfileState(createState(props.kagamiProfileModel));
  return (
    <>
    {profileModalState.isOpen ?
     profileModalState?.content?.length > 1 ? 
     (<></>)
     :
     (
      <ClickAwayListener onClickAway={() =>  hideProfile(props.kagamiProfileModel)}>
             <div>{buildProfileMenu(props.kagamiProfileModel, props?.translations)}</div>
       </ClickAwayListener>
   ) : null}
    </>
  )
}
