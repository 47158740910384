export class InputData {
  detailedObjects: any;
  nextStepToBeexecuted?: string | undefined | null;
  previousProcessName: string | undefined;
  parentProcessName : string | undefined;
  processName: string;
  properties: any;
  verbProperties: any;
  changeFor?: any;
  isAttributeChange?: boolean;
  embeddedInfo? : any;

  constructor() {
    this.detailedObjects = {};
    this.processName = '';
    this.properties = {};
    this.verbProperties = {};
  }

  setDetailedObjects(detailedObjects: any) {
    this.detailedObjects = detailedObjects;
  }
}
