import React, { useEffect } from 'react';
import { createState } from '@hookstate/core';
import { makeStyles } from '@mui/styles';
import { CustomPrintModel, CustomPrintModelProps, useCustomPrintState } from '../model/customPrintModel';
import { customPrintStyle } from '../style/customPrintStyle';
import { createPdf, printCustomPDF } from '../service/customPrintService';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';

const useStyles = makeStyles(customPrintStyle);

export const buildCustomPrintComponent = (customPrintModel: CustomPrintModel) => {
  return <CustomPrint customPrintModel={customPrintModel} />;
};

export const CustomPrint = (props: CustomPrintModelProps) => {
  const classes = useStyles();
  const customPrintState = useCustomPrintState(createState(props.customPrintModel));
  props.customPrintModel.state = customPrintState;

  useEffect(() => {
    printCustomPDF(props.customPrintModel);
    return () => {};
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.editForm}>
        <PdfFormEdit key={'pdf-form-data'} customPrintModel={props.customPrintModel} />
      </div>
      <div className={classes.iframeComponent}>
        <iframe src={customPrintState.getPdfUrl} width="100%" height="100%"></iframe>
      </div>
    </div>
  );
};

const PdfFormEdit = (props: CustomPrintModelProps) => {
  const classes = useStyles();
  const pagesize = ['A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'EXECUTIVE', 'FOLIO', 'LEGAL', 'LETTER'];
  const handlePageSize = (event: any) => {
    props.customPrintModel.configInfo.pageSize = event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleRadio = (event: any) => {
    props.customPrintModel.configInfo.pageOrientation = event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleWatermarks = (event: any) => {
    props.customPrintModel.configInfo.watermark.text = event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleRowsFontSize = (event: any) => {
    props.customPrintModel.configInfo.rowFontSize = +event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleFontHeaderSize = (event: any) => {
    props.customPrintModel.configInfo.tableHeaderFontSize = +event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleLeft = (event: any) => {
    props.customPrintModel.configInfo.margin[0] = +event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleTop = (event: any) => {
    props.customPrintModel.configInfo.margin[1] = +event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleRight = (event: any) => {
    props.customPrintModel.configInfo.margin[2] = +event.target.value;
    createPdf(props.customPrintModel);
  };

  const handleBottom = (event: any) => {
    props.customPrintModel.configInfo.margin[3] = +event.target.value;
    createPdf(props.customPrintModel);
  };
  
  return (
    <div className={classes.formWid}>
    <FormControl>
      <div><span className={classes.noteTitle}>Note:</span>Only few records showing in Preview.</div>
      <div className={classes.noteTitle}>Print and Preview Settings  :  </div>
      <div>
        <FormLabel id="page-size-label">Page Size :</FormLabel>
        <Select id="page-size-select" defaultValue={pagesize[pagesize.length - 1]} onChange={handlePageSize}>
          {pagesize.map((option: any) => {
            return <MenuItem value={option}>{option}</MenuItem>;
          })}
        </Select>
      </div>
      <div>
        <FormLabel id="page-orientation-label">Page Orientation :</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue="landscape"
          onChange={handleRadio}
        >
          <FormControlLabel value="portrait" control={<Radio />} label="Portrait" />
          <FormControlLabel value="landscape" control={<Radio />} label="Landscape" />
        </RadioGroup>
      </div>
      <div>
        <FormLabel id="page-margins-label">Page Margins :</FormLabel>
        <div className={classes.formTestfieldWid}>
          <div>
            <FormLabel id="left-margin-label">Left : </FormLabel>
            <TextField 
              variant="standard"
              size="small"
              defaultValue={0}
              onChange={handleLeft}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
            <FormLabel id="top-margin-label">Top : </FormLabel>
            <TextField
              variant="standard"
              size="small"
              defaultValue={5}
              onChange={handleTop}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </div>
          <div>
            <FormLabel id="right-margin-label">Right : </FormLabel>
            <TextField
              variant="standard"
              size="small"
              defaultValue={0}
              onChange={handleRight}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
            <FormLabel id="bottom-margin-label">Bottom : </FormLabel>
            <TextField
              variant="standard"
              size="small"
              defaultValue={15}
              onChange={handleBottom}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </div>
        </div>
      </div>
      <div>
        <FormLabel id="page-watermarks-label">Page Watermarks :</FormLabel>
        <div>
          <FormLabel id="watermark-text-label">Watermark Text : </FormLabel>
          <TextField id="standard-basic" variant="standard" size="small" onChange={handleWatermarks} />
        </div>
      </div>
      <div>
        <FormLabel id="pdf-rows-font-size-label">Pdf Rows Font Size :</FormLabel>
        <div>
          <FormLabel id="rows-font-size-label">Font Size : </FormLabel>
          <TextField
            variant="standard"
            defaultValue={10}
            size="small"
            onChange={handleRowsFontSize}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </div>
      </div>
      <div>
        <FormLabel id="pdf-font-header-size-label">Pdf Font Header Size :</FormLabel>
        <div>
          <FormLabel id="font-header-size-label">Font Size : </FormLabel>
          <TextField
            variant="standard"
            defaultValue={12}
            size="small"
            onChange={handleFontHeaderSize}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </div>
      </div>
    </FormControl>
    </div>
  );
};
