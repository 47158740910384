import { State, useState } from "@hookstate/core";
import { KagamiListModel } from "../../../model/KagamiListModel";


export class kagamiListHeaderModel {
    state: any;
    headerloaded : boolean;
    kagamiListModel : any;
    processHeader : any;
    totalRecords : number;
    constructor() {
        this.headerloaded = false;
        this.totalRecords = 0;
    }
}

export interface kagamiListHeaderModelProps {
    kagamiListHeaderModel: kagamiListHeaderModel;   
}

export const wrapListHeaderState = (state: State<kagamiListHeaderModel>) => {
    let headerState = useState(state);
    return{
        get headerVisible(){
           return headerState.headerloaded.get()
        },
        setheaderVisible(value: boolean) {
            headerState.headerloaded.set(value);
          },
         get totalRecords() {
            return headerState.totalRecords.get();
          },
          settotalRecords(value: number) {
            headerState.totalRecords.set(value);
          }, 
    }
}

export const useListHeaderState = (state:State<kagamiListHeaderModel>) => wrapListHeaderState(useState(state));

