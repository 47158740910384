import { State, useState } from '@hookstate/core';
 
export class KagamiAttachmentsModalModel   {
    attrName: string;
    // thumbnails: any;
    // controlValues: any;
    // selectedFiles:any;
    // fileFieldState :any;
    openDialog:boolean;
    kagamiFileModel:any;
    readOnly :any;
    state:any;
    kagamiGridAttachemntsData:any;

    constructor( ) {
        this.attrName ='';
        // this.thumbnails='';
        // this.controlValues = '';
        // this.selectedFiles = '';
        // this.fileFieldState = '';
        this.openDialog = false;
        this.kagamiFileModel = null;
        this.readOnly = false;
        this.kagamiGridAttachemntsData = false
        
    }
}
 
export interface KagamiAttachmentsModalModelProps {
    kagamiAttachmentsModalModel: KagamiAttachmentsModalModel;
  }
export const wrapKagamiAttachmentsModalState = (state: State<KagamiAttachmentsModalModel>) => {
    let attachmentsModalState = state;
  
   return {
    get isOpen() {
        return attachmentsModalState.openDialog.get();
      },
  
      setOpen(isOpen: boolean) {
        attachmentsModalState.openDialog.set(isOpen);
      },
   }
  };
  export const useKagamiModalModelState = (state: State<KagamiAttachmentsModalModel> ) => {
    let modalState = wrapKagamiAttachmentsModalState(useState(state));
    
    return modalState;
  };