import { Chip } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { cellRendererCustomComponentsStyle } from "../../style/CellRendererCustomComponents";
import { useEffect } from "react";
import { KagamiBeanUtils } from "../../../../../../../../../../../../utils/KagamiBeanUtils";

const useStyles = makeStyles(cellRendererCustomComponentsStyle);

 const KagamiStatusCellRenderer = (props: any) => {
  const classes = useStyles();
  if(props.consumedEntity){
      let entity = props.consumedEntity;
      let reqdAttribute =  props.data[entity] ;
      if( typeof reqdAttribute === 'object' && !Array.isArray(reqdAttribute) && reqdAttribute !== null && reqdAttribute !== undefined){
       
        if('Color' in reqdAttribute) {
            let bgColor = reqdAttribute.Color.bgCode
            return( 
                <div className={classes.badgeRoot}>
                    <div className={classes.badge} style={{backgroundColor: bgColor ? bgColor : 'default'}}></div>
                    <div style={{color: 'inherit'}}>
                        <span style={{ color: 'inherit',display:'block' ,whiteSpace:'nowrap', overflow: 'hidden', textOverflow :'ellipsis' }}>{props.displayAttribute ? reqdAttribute[props.displayAttribute]: reqdAttribute.name }</span>
                    </div>
                </div>
            )
        }  else {
          return( 
              <span style={{ color: 'inherit',display:'block' ,whiteSpace:'nowrap', overflow: 'hidden', textOverflow :'ellipsis' }}>{props.value}</span>
          )
        }
   
    
      }
      else {
        return( 
            <span style={{ color: 'inherit',display:'block' ,whiteSpace:'nowrap', overflow: 'hidden', textOverflow :'ellipsis' }}>{props.value}</span>
        )
    }
  } else if(props.attributeToConsume) {
    let entity = props.attributeToConsume;
    let dataValue = KagamiBeanUtils.getProperty(props.attributeValueToConsume,props.data)
    let reqdAttribute =  props.data[entity] ;
    if( typeof reqdAttribute === 'object' && !Array.isArray(reqdAttribute) && reqdAttribute !== null && reqdAttribute !== undefined){
        if('Color' in reqdAttribute) {
            let bgColor = reqdAttribute.Color.bgCode
            return( 
                <div className={classes.badgeRoot}>
                    <div className={classes.badge} style={{backgroundColor: bgColor ? bgColor : 'default'}}></div>
                    <div style={{color: 'inherit'}}>
                        <span style={{ color: 'inherit',display:'block' ,whiteSpace:'nowrap', overflow: 'hidden', textOverflow :'ellipsis' }}>{dataValue}</span>
                    </div>
                </div>
            )
        } else {
            return  (<span style={{ color: 'inherit',display:'block' ,whiteSpace:'nowrap', overflow: 'hidden', textOverflow :'ellipsis' }}>{dataValue}</span>)
        }
    
        
        
    }
    else {
        let val = props.value ?? dataValue;
       
        if (Array.isArray(val) && val.length){
            return (<span>{dataValue}</span>)
        } else return (<span>{val}</span>)
      
  } 
    
} 
 else {
    return (<span style={{ color: 'inherit',display:'block' ,whiteSpace:'nowrap', overflow: 'hidden', textOverflow :'ellipsis' }}>{props.value}</span>)
 }

}

export default KagamiStatusCellRenderer
