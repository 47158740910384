import { ColDef } from 'ag-grid-community';
import _ from 'lodash';
import { handleDrillDownReport } from '../../../../service/ReportDrillDownService';
import {getVisibleColumns,getDisplayRulesForColumn,prepareReportAggregateData,getLocaleValue,reportColumnDisplayFormatter} from '../../../../service/ReportUtilService';
import { updateReportPrintConfigurationURL } from '../../../../../../../../../../../../service/URLService';
import { APIService } from '../../../../../../../../../../../../service/ApiService';
import { SystemConstants } from '../../../../../../../../../../../../constants/SystemConstants';
import { buildtoastNotification } from '../../../../../../../../../ToastNotification/controller/ToastNotificationController';

export function  makeReportData(listReportController: any) {
  let defaultConfig ={
    autoGroupColumnDef: {  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
    columnDefs: [],
    defaultColDef: { flex :1,sortable: true ,minWidth: 125,filter: 'agMultiColumnFilter',resizable: true,editable: false },
    groupDefaultExpanded: -1,//expand everything by default
    groupHideOpenParents:false,
    groupIncludeTotalFooter: false,
    groupIncludeFooter:false,
    getRowHeight :(params: any) => 30,
    icons: {
      groupContracted:'<div class="kgm-plus-icon radius radius-25"></div>',
      groupExpanded:'<div class="kgm-minus-icon radius radius-25"></div>'
    },
    pivotMode : false,
    pagination: !listReportController.fromDashboard,
    paginationPageSize: 50,
    pinnedBottomRowData:[],
    hideTotals: false,
    rowData:[],
    sideBar : {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      // position: 'left',
      defaultToolPanel: ''
    },
    suppressAggFuncInHeader: true, //hide aggregate name in a row 
    suppressFieldDotNotation: true, //ex: field contains 32.5R
    
    /*aggFuncs: {
      '$$sum': (params :any) => {
          let sum = 0;
          params.values.forEach((value:any) => {
            if(value) {
              sum += Number(value);
            }
          });
          return sum;
      }
    }*/
  };
  
  
  let userConfig = prapareUserConfigForReport(listReportController,defaultConfig);  
  let dbChartData = JSON.parse(listReportController.reportData);
  let disptabelData = getReportData(dbChartData);
  userConfig['rowData'] = disptabelData;
  let footerRowData = getFooterRowData(disptabelData,dbChartData,listReportController.reportConfig);
  if(footerRowData && footerRowData.length ){
    let footerValues = _.values(footerRowData[0]);
    if(footerValues && footerValues.length) {
      let footerData =  _.filter(footerValues,function(val) {
        return val !="";
      })
      if(footerData?.length){
        userConfig['pinnedBottomRowData'] = footerRowData;
      }
    }
  }
  let otherSettings = listReportController?.reportConfig.reportSettings;
  if (otherSettings) {
    if (otherSettings.showGrandTotals) {
      if (otherSettings.showGrandTotals == 'off') {
        userConfig['hideTotals'] = true;
      }
    }
  }


  let reportConfig = _.extend(true, {}, defaultConfig, userConfig);
  return reportConfig;
}

  function prapareUserConfigForReport (listReportController: any,defaultConfig:any) {
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    // userConfig['columnDefs'] = getReportColumnDefs(listReportController,defaultConfig);
    let columnDefs = getReportColumnDefs(listReportController,defaultConfig);
    userConfig['columnDefs']=  orderColumnDefsByGrouping(columnDefs,listReportController);
    return userConfig;
  }
  const  getReportColumnDefs = (listReportController:any,defaultConfig:any) => {
    let columnDefs: any = [];
    /* if(listReportController?.reportConfig?.criteria?.columns) {
          let visibleColumns = getVisibleColumns(listReportController.reportConfig.criteria.columns);
        _.each(visibleColumns, function (field) {
            let temp:any = {
              field: field.name,
              headerName: field.name,
            }
            appendDisplayRulesToColumn(field,temp,listReportController,defaultConfig);
            columnDefs.push(temp);
          });
    }*/
    /** ITS IMPLEMENTED BASED ON RESPONSE TABLE COLUMNS **/
    let dbChartData  = JSON.parse(listReportController.reportData);
    if(dbChartData?.table?.cols) {
      dbChartData.table.cols.map((field: any) => {
        let temp:any = {
          field: field.label,
          headerName: field.label,
          onCellClicked: (event:any) => {
            if(event?.colDef?.headerComponentParams?.column?.columnReport) {
              handleDrillDownReport(event,listReportController,listReportController.reportConfig,event.value,field.label);
            }
          }
        }
        appendDisplayRulesToColumn(field,temp,listReportController,defaultConfig);
        temp['headerComponentParams'] = { 
          column:field
        }
        columnDefs.push(temp);
      });
    }
    return columnDefs;
  }

 const orderColumnDefsByGrouping = (columnDefs:any,listReportController:any) => {
    if(listReportController.reportConfig?.groupingColumns && listReportController.reportConfig.groupingColumns.length > 0) {
      let groupingCols:any = [];
      _.forEach(listReportController.reportConfig.groupingColumns,(groupCol)=>{
        let colArr =  _.filter(columnDefs,{field:groupCol});
        if(colArr.length > 0) {
          groupingCols.push(colArr[0]);
        }

      })
      return _.unionBy(groupingCols,columnDefs, 'field');
     }
     return columnDefs;
  }

   const getReportData = (dbChartData:any) => {
    let reportJsonData:any = [];
    if( dbChartData.table){
        let keys=_.map( dbChartData.table.cols,"label");
        dbChartData.table.rows.map(function (row:any) {
            let tempData :any = {};
            _.map(keys, function (key, i) {
            tempData[key] = row.c[i].v;
            });
            reportJsonData.push(tempData);
        });
   }
    return reportJsonData;
}       

export const getFooterRowData = (disptabelData:any,dbChartData:any,reportConfig:any)  => {
  let footerRowData:any = [];
  if(dbChartData?.table?.cols && disptabelData ) {
    footerRowData =   [prepareReportAggregateData(disptabelData,dbChartData.table.cols,reportConfig,false)] || [];
  }
  return footerRowData;
}



export const buildColumns = (data: any) => {
    let columns: any[] = [];
    data.table.cols.map((col: any) => {
      const column = {
        field: col.label,
        headerName: col.label,
        width: 300
      };
      columns.push(column);
    });
    return columns;
}

const appendDisplayRulesToColumn =(field:any,temp:any,listReportController:any,defaultConfig:any) =>{
  let definedField = _.cloneDeep(field);
  if (listReportController?.reportConfig?.criteria?.columns) {
    let definedColArr = _.filter(listReportController.reportConfig.criteria.columns, { name: field.label });
    if (definedColArr && definedColArr.length > 0) {
      definedField = definedColArr[0];
    }
  }
  let dispRules = getDisplayRulesForColumn(definedField);
  if (dispRules['aggregate']) {
    temp['aggFunc'] = dispRules['aggregate'].toLowerCase();
    // defaultConfig['groupIncludeTotalFooter'] = true;
  }
  if(listReportController.reportConfig?.groupingColumns && listReportController.reportConfig.groupingColumns.indexOf(field.label) !=-1) {
   temp['rowGroup'] = true;
   temp['hide'] = true;
   defaultConfig['groupIncludeTotalFooter'] = true;
   defaultConfig['groupIncludeFooter']=true;
  }
  let cellStyles:any = {};
  if (dispRules['style'] && dispRules['style'].length > 0) {
    _.forEach(dispRules['style'], function (style: any) {
      cellStyles[style['key']] = style['value'];
    });
  }
  if (field.rtl) {
    cellStyles['text-align'] = 'right';
  }else {
    cellStyles['text-align'] = 'left';
  }

  if(field.columnReport) {
    cellStyles['color'] = 'blue';
  }
  if(_.keys(cellStyles).length > 0) {
   temp['cellStyle'] = cellStyles;
  }
  if(dispRules.thousandSeparator || dispRules.currencySymbol) {
    temp['cellRenderer'] = (params: any) => {
      if(params!==undefined && params.data !== undefined && params.data[temp.field]  == 0) {
        return "";
      }
      let value = params!==undefined && params.data !== undefined   ? params.data[temp.field] : ( params?.node?.aggData && params.node.aggData[temp.field]);
      return (value || value == 0 ) ? (reportColumnDisplayFormatter(listReportController.reportConfig, value || '' , definedField.name, definedField) || value) : '';
    };
  }else if(temp['aggFunc']) {
    temp['cellRenderer'] = (params: any) => {
      let value:any = params!==undefined && params.data !== undefined   ? params.data[temp.field] : ( params?.node?.aggData && params.node.aggData[temp.field]);
      if (typeof (value) == "object" && Object.keys(value).indexOf('value') != -1) {
        return value.value;
      } else {
        return value;
      }
    };
  }else if(!temp['rowGroup'] ) {
    temp['cellRenderer'] = (params: any) => {
      return params!==undefined && params.data !== undefined   ? params.data[temp.field] : ( params?.node?.aggData && params.node.aggData[temp.field]);
    };
  }
}

export const saveReportConfig = (
  gridRef: any,
  listReportModel: any
) => {
  let updatedObject = {
    reportId: listReportModel['reportConfigSummary']['id'],
    columnConfig: gridRef?.current.columnApi.columnModel.displayedColumns.map((col: any) => {
      return { columnName: col.label, columnWidth: col.actualWidth };
    })
  };
  updateReportPrintConfig(updatedObject).then((response:any)=>{
    if(response.status==200){
      listReportModel['reportConfiguration'] = response.data;
      buildtoastNotification('success', 'Report Configuration Saved Successfully' ,1000);
    }
  }).catch((err:any)=>{
    console.error('err : ',err);
    buildtoastNotification('error', 'Failed to Update Record' ,1000);
  })
};

const updateReportPrintConfig = async (updatedObject:any) => {
  return await APIService.getData(SystemConstants.POST, updateReportPrintConfigurationURL(), updatedObject);
}
