import { DropzoneArea } from "react-mui-dropzone";
import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";
import { deleteSingleFile, getFileData, handleImageChange } from "../../file/service/KagamiFileService";
import { KagamiImageModel } from "../model/kagami_ImageModel";
import userImage from '../../../../../../../../../../../assets/images/user.png'
import { getDmsViewUrl } from "../../../../../../../../../../service/URLService";
import CardMedia from "@mui/material/CardMedia";
import CancelIcon from '@mui/icons-material/Cancel';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import noImage from "../../../../../../../../../../../assets/images/user.png"

//NOTE: ALL CALLS MADE FROM THIS FILE ARE IMPORTED FROM FILE/FILESERVICE AND NOT IMAGESERVICE

export function showImageAfterUpload(kagamiImageModel:KagamiImageModel) {
  let popupModel = kagamiImageModel.popupModel;
  const fieldState = kagamiImageModel.state;
  //as this is image field it is restricted to 1 file and file is of type image/*
  let controlValue = CommonUtils.isNotEmpty(kagamiImageModel.controlValue) ?  kagamiImageModel.controlValue  : ''
  let fileId  =  controlValue.split(":")[0];
  let fileName = controlValue.split(':')[1];
  let fileType = controlValue.split('.').pop() ;
  const errorHandler = (event:any) => {
    event?.target?.src ? (event.target.src = userImage) : '';
  }
  function handleDelete (event:any) {
    const uploadedImages = getFileData(kagamiImageModel)
   console.log(event);
   deleteSingleFile(uploadedImages[0] , kagamiImageModel)
  }
  return (
    <>
        <div style={{position:'relative', width:'fit-content'}}>

        <CardMedia 
        sx={{ height: 60, width: 60, borderRadius: '50%', border:'1px solid #ebebeb' }} 
        image={getDmsViewUrl(fileId)}  
        component="img"
        onError ={errorHandler} 
        onClick={() => CommonUtils.previewFileInGrid(kagamiImageModel.controlDisplayName,fileId, popupModel, fileName )} 
        />
         {
          !kagamiImageModel.readonly &&
              <CancelIcon
                style={{ position: 'absolute', top:'-5px', right:'-5px' }}
                color='error'
                sx={{ width: '20px', height: '20px' }}
                onClick={handleDelete}
              />
         }
      
        </div>
    </>
    )
}
export function showingImageControllerForFile(classes :any ,kagamiImageModel:KagamiImageModel){
  let popupModel = kagamiImageModel.popupModel;
  const fieldState = kagamiImageModel.state;
  let fileTobeUploaded:any
  function handleUpload(event:any) {
    if (CommonUtils.isNotEmpty(event)){
      fileTobeUploaded = event
    }
  }
  function handleDelete (event:any) {
    fileTobeUploaded = []
  //   const uploadedImages = getFileData(kagamiImageModel)
 
  //  CommonUtils.isNotEmpty(uploadedImages[0]) && deleteSingleFile(uploadedImages[0] , kagamiImageModel)
  }

function handleOnOk () {
  if (CommonUtils.isNotEmpty(fileTobeUploaded)){
    //uploading the file onokClick
  handleImageChange(fileTobeUploaded,  kagamiImageModel)
  }
}
  return(
  <>
   
           <img
             src={noImage}
             alt='no image'
             style={{ height: 60, width: 60, borderRadius: '50%' }} 
             onClick={(event: any) => { 
              popupModel.title = `${kagamiImageModel.controlDisplayName}`;
              popupModel.content = <BulidingImageToShowOnModelPopup classes={classes}  kagamiImageModel ={kagamiImageModel} handleDelete={handleDelete} handleUpload={handleUpload}/>
              popupModel.modalWidth = 'sm',
              popupModel.handleOnOk = handleOnOk
              {!kagamiImageModel.readonly && kagamiImageModel.htmlControl === "image" ? popupModel.state.setOpen(true) : ''}
             }}
           /> 
  </>
  )
}


const BulidingImageToShowOnModelPopup = (props:any) => {
  const fieldState = props.kagamiImageModel.state;
const classes =props.classes
let isMultiUploadFile =    props.kagamiImageModel.uiSettings?.hasOwnProperty('singleFile') ? ! props.kagamiImageModel.uiSettings.singleFile : true

//  function handleDelete(event:any) {
//   props.handleDelete
//  }
// if(!props.kagamiImageModel.readonly && props.kagamiImageModel.htmlControl === "image") {
  return (
      <DropzoneArea
      dropzoneText="CLICK / DROP IMAGE HERE TO UPLOAD"
      Icon= {BackupOutlinedIcon}
      acceptedFiles={['image/*']}
      onChange={props.handleUpload}
     onDelete = {(event:any) => props.handleDelete(event)}
      dropzoneClass={classes.dropZone}
      previewGridClasses={{
         container: classes.previewContainer,
         item: classes.preview,
         image: classes.previewImg,
      }}
      // filesLimit={isMultiUploadFile? undefined : 1}
      filesLimit={1}
      showAlerts={false}
        />
  );
    // } else {
    // return  null
    // }
};
 