import { memo, useCallback, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import { CommonUtils } from "../../../../../../../../../../../../utils/CommonUtils";
import { embedColumnSearch } from "../../../../service/kagamiListService";
import { getKagamiListModel, setKagamiListModel } from "../../../../controller/KagamiListController";
import SortIcon from '@mui/icons-material/Sort';

const HeaderSearchRenderer =  memo((props:any) => {
    let obj = {colName:"", clickCount:0, direction:"asc"};
    const [isSearch,setSearch] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const handleSearch = (searchKey : string) => {
        console.log('search call has been made'+props)
        debounceOnChange(props.gridModel,props.attributeName,searchKey,props.displayAttr)
    }
    const debounceOnChange = useCallback(CommonUtils._debounceFtn(embedColumnSearch,600),[])
    
    const returnHeaderSortOrder = () => {
        return(
            <div>
        <a data-colId={props.column.colId} onClick={(event:any)=>{
            if(props.enableSorting){
                if(obj.colName != props.column.colId){
                    props.columnApi.applyColumnState({
                        state: [{ colId: props.column.colId, sort: "asc"}],
                        defaultState: { sort: null },
                    });
                    obj = {colName: props.column.colId, clickCount: 1, direction: "desc"};
                }
                else if(obj.colName === props.column.colId && obj.clickCount == 1){
                    props.columnApi.applyColumnState({
                        state: [{ colId: props.column.colId, sort: obj.direction}],
                        defaultState: { sort: null },
                    });
                    obj = {colName: props.column.colId, clickCount: 2, direction: "asc"};
                }
                else{
                    props.columnApi.applyColumnState({
                        defaultState: { sort: null },
                    });
                    obj = {colName:"", clickCount:0, direction: obj.direction};
                }
            }}} href="javascript:void(0)" style={{color:"#006e7f", textDecoration:"none"}}>{props.displayName}
            </a>
        {props.displayName === '#' ? null : <SearchIcon onClick={(event:any) => {
            setKagamiListModel(props.gridModel)
            setSearch(true)}}/>}
        </div>
        );
    }

    return isSearch ? 
    <div><TextField
            size="small" color='secondary'
            variant="outlined"
            placeholder={'Search...'}
            value={searchValue}
            autoFocus
            onChange={(event: any) => {
                setSearchValue(event.target.value)
                handleSearch(event.target.value);
            }}
            onBlur={(event:any) => setSearch(false)}
        />
    </div>
    : returnHeaderSortOrder();
})

export default HeaderSearchRenderer