import { State, useState } from '@hookstate/core';
import { extractDropdownOption, getEntityAssociatedAttribute } from '../../../../service/PresentationService';
import { KagamiControlModel } from '../../model/kagamiControlModel';

export class KagamiSelectModel extends KagamiControlModel {
  attributePresentation: any;
  presentationId: any;
  createState: any;
  multiValue: any;
  singleValues = [];
  lovRecords: any[];
  associatedObject: any;
  entityConsumed: string;
  uiSettings: any;
  inputValue: string;
  order: string;
  selected = [];
  orderBy: string;
  entityAssociatedAttribute: string;
  search: string;
  multiSelectSelectedValues: any[];
  multiSelectDeleteValues: any[];
  multiSelectPopoverFilteredValues: any[];
  verbProperties:any;
  noValueMsg : any;
  broadcastRequired:boolean;
  pagination: boolean;
  cancelVisible : boolean ;
  dispValues: boolean;
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any,
    onChangeRequired: boolean,
    validation: any,
    pickAttribute: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    attributePresentation: any,
    policyMap: any,
    entityConsumed: string,
    presentationId: any,
    presentationPolicy: any,
    businessPolicy: any,
    initiallyReadOnly: boolean,
    initiallyVisible: boolean,
    uiSettings: any,
    formDataIdentifier: string,
    isGridField: boolean,
    isEmbddedField: boolean,
    noValueMsg : string,
    broadcastRequired:boolean,
  ) {
    super(
      controlIdentifier,
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField
    );
    this.uiSettings = uiSettings;
    this.attributePresentation = attributePresentation;
    this.lovRecords = [];
    this.entityConsumed = entityConsumed;
    this.presentationId = presentationId;
    this.controlType === 'mutliselect' ? (this.associatedObject = []) : (this.associatedObject = {});
    this.inputValue = '';
    this.order = 'asc';
    this.orderBy = 'vendorCode';
    this.selected = [];
    this.entityAssociatedAttribute = getEntityAssociatedAttribute(attributePresentation);
    this.search = '';
    this.multiSelectSelectedValues = [];
    this.multiSelectDeleteValues = [];
    this.multiSelectPopoverFilteredValues = [];
    this.noValueMsg = noValueMsg;
    this.broadcastRequired =broadcastRequired;
    this.pagination = false;
    this.selectValues = [];
    this.cancelVisible = false;
    this.dispValues = false;
  }
}

export interface SelectFieldProps {
  kagamiSelectModel: KagamiSelectModel;
}

export interface GridSelectFieldProps {
  kagamiSelectModel: KagamiSelectModel;
  selectFieldState: any;
}
export const wrapKagamiSelectModelState = (state: State<KagamiSelectModel>) => {
  let controlState = state;

  return {
    get isMandatory() {
      return controlState.mandatory.get();
    },

    setMandatory(isMandatory: boolean) {
      controlState.mandatory.set(isMandatory);
    },

    get isVisible() {
      return controlState.visible.get();
    },

    setVisible(isVisible: boolean) {
      controlState.visible.set(isVisible);
    },

    get isReadOnly() {
      return controlState.readonly.get();
    },

    setReadonly(isReadonly: boolean) {
      controlState.readonly.set(isReadonly);
    },

    get errorMessage() {
      return controlState.errorMessage.get();
    },

    setErrorMessage(value: string) {
      controlState.errorMessage.set(value);
    },

    get controlValue() {
      return controlState.controlValue.get();
    },

    setControlValue(value: any) {
      controlState.controlValue.set(value);
    },

    setAssociatedObject(value: any) {
      controlState.associatedObject.set(value);
    },

    get associatedObject() {
      return controlState.associatedObject.get();
    },

    get selectValues() {
      return controlState.selectValues.get();
    },

    setSelectValues(value: any) {
      controlState.selectValues.set(value);
    },

    get multivalue() {
      return controlState.multiValue.get();
    },

    setMultiValue(value: any) {
      controlState.multiValue.set(value);
    },

    get singleValues() {
      return controlState.singleValues.get();
    },

    setSingleValues(value: any) {
      controlState.singleValues.set(value);
    },

    get displayValue() {
      return controlState.displayValue.get();
    },

    setDisplayValue(value: any) {
      controlState.displayValue.set(value);
    },

    setLovRecords(value: any[]) {
      controlState.lovRecords.set(value);
    },

    get lovRecords(): any[] {
      return controlState.lovRecords.get();
    },

    setSearch(value: string) {
      controlState.search.set(value);
      let filteredValues = controlState.multiSelectSelectedValues.get().filter((item) => {
        let value = extractDropdownOption(controlState.attributePresentation.get(), item);
        return (
          (!controlState.multiSelectDeleteValues.get().includes(item?.id) &&
            value.toLowerCase().match(controlState.search?.get().toLowerCase())) 
        );
      });
      controlState.multiSelectPopoverFilteredValues.set(filteredValues);
    },

    get search(): string {
      return controlState.search.get();
    },

    setOrder(value: string) {
      controlState.order.set(value);
    },

    get order(): string {
      return controlState.order.get();
    },

    setOrderBy(value: string) {
      controlState.orderBy.set(value);
    },

    get orderBy(): string {
      return controlState.orderBy.get();
    },
     
    get cancelVisible():boolean{
      return controlState.cancelVisible.get();
    },
    setcancelVisible(value : any){
      controlState.cancelVisible.set(value);
    },

    setSelected(value: any) {
      controlState.selected.set(value);
    },

    get selected() {
      return controlState.selected.get();
    },

    get getMultiSelectDeleteValues() {
      return controlState.multiSelectDeleteValues.get();
    },

    setModalDeletedValue(value: string) {
      let array = [...controlState.multiSelectDeleteValues.get(), value];
      controlState.multiSelectDeleteValues.set(array);
      let filteredValues = controlState.multiSelectSelectedValues.get().filter((item) => {
        let value = extractDropdownOption(controlState.attributePresentation.get(), item);
        if (
          (!controlState.multiSelectDeleteValues.get().includes(item?.id) &&
            value?.toLowerCase().match(controlState.search?.get()?.toLowerCase()))
        ) {
          return controlState.multiSelectDeleteValues.get().filter((ele: any) => {
            ele.id != value;
          });
        }
      });
      controlState.multiSelectPopoverFilteredValues.set(filteredValues);
    },

    setModalDeleteValueDefault() {
      controlState.multiSelectDeleteValues.set([]);
    },

    setModalSearchValueDefault(value: '') {
      controlState.search.set(value);
    },

    get getMultiSelectSelectedValues() {
      return controlState.multiSelectSelectedValues.get();
    },

    setMultiSelectSelectedValues(value: any) {
      let filteredValues = value?.filter((item: any) => !item?.id?.includes('remove'));
      controlState.multiSelectSelectedValues.set(filteredValues);
      controlState.multiSelectPopoverFilteredValues.set(filteredValues);
    },

    get getMultiSelectPopoverFilteredValues() {
      return controlState.multiSelectPopoverFilteredValues.get();
    },

    get inputValue(){
      return controlState.inputValue.get();
    },
    setInputValue(value : string){
      controlState.inputValue.set(value);
    }
  };
};

export const useKagamiSelectModelState = (state: State<KagamiSelectModel>) =>
  wrapKagamiSelectModelState(useState(state));

export const accessKagamiSelectModelState = (state: State<KagamiSelectModel>) => wrapKagamiSelectModelState(state);
