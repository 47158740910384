import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { userContext } from '../../../../../process/service/PolicyExecutionService';
import { getSearchData, globalSearchData, handleOnBackForReport } from '../../../controller/ReportsLandingPageController';
import { ReportLandingPageModelProps } from '../../../model/ReportsLandingPageModel';
import { buildCustomReportController } from '../builder/CustomReportPopover/controller/CustomReportPopoverController';
import { buildReportIconPopover } from '../builder/ReportIconPopover/controller/ReportIconPopoverController';
import { ReportIconPopoverModel } from '../builder/ReportIconPopover/model/ReportIconPopoverModel';
import { ReportHeaderStyle } from '../style/HeaderContent';

const useStyles = makeStyles(ReportHeaderStyle);

export const HeaderContent = (props: ReportLandingPageModelProps) => {
  const classes = useStyles();
  const reportLandingPageModel = props.reportLandingPageModel;
  const [value, setvalue] = useState('');

  if (reportLandingPageModel.reportIconPopoverModel === null) {
    reportLandingPageModel.reportIconPopoverModel = new ReportIconPopoverModel();
  }

  return (
    <div className={classes.reportHeaderContent}>
      <div className={classes.left}>
        {(reportLandingPageModel.folderId !== undefined || reportLandingPageModel.isContentLoaded) && (
          <IconButton className={classes.backButton} size="small" onClick={handleOnBackForReport}>
            <KeyboardBackspaceRoundedIcon fontSize="small" />
          </IconButton>
        )}
        {buildReportIconPopover(reportLandingPageModel.reportIconPopoverModel, reportLandingPageModel?.translations)}
        {userContext?.User[0]?.Role[0]?.roleName.toLowerCase() === 'admin' &&
          buildCustomReportController(reportLandingPageModel)}
      </div>

      <div className={classes.right}>
        <TextField
          size="small"
          color="secondary"
          variant="outlined"
          placeholder="Search"
          className={classes.search_bar}
          value={value}
          onChange={(event: any) => {
            setvalue(event.target.value);
            // getSearchData(event,reportLandingPageModel);
            globalSearchData(event, reportLandingPageModel);
          }}
          InputProps={{
            endAdornment: value ? (
              <>
                <IconButton
                  size="small"
                  className='ClearIconBtn'
                  onClick={() => {
                    setvalue('');
                    globalSearchData({ target: { value: '' } }, reportLandingPageModel);
                  }}
                >
                  <ClearOutlinedIcon
                    sx={{
                      color: (theme) => theme.palette.error.main,
                      stroke: (theme) => theme.palette.error.main,
                      strokeWidth: 1
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </>
            ) : (
              <div className='SearchIconBtn'>
                <SearchOutlinedIcon
                  fontSize="small"
                  style={{ opacity: '0.6' }}
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    stroke: (theme) => theme.palette.secondary.main,
                    strokeWidth: 1
                  }}
                />
              </div>
            )
          }}
        />
      </div>
    </div>
  );
};
