import { ReportModalCustomButton, ReportModalModel } from '../../../../../ReportModal/model/ReportModalModel';
import { buildCustomPrint } from '../builder/customPrint/controller/customPrintController';
import { CustomPrintModel } from '../builder/customPrint/model/customPrintModel';
import { ListReportModel } from '../model/ListReportModel';
import { BuildListReportView } from '../ui/ListReport';

let listReportModel: ListReportModel;

export function buildListReport(listReport: ListReportModel) {
  listReportModel = listReport;
  return BuildListReportView(listReport);
}

export const onPrintPDF = (reportModalModel: ReportModalModel, reportConfig: any,listReportModel:any) => {
  const customPrintModel = new CustomPrintModel();
  customPrintModel.reportConfig = reportConfig;
  customPrintModel.reportModel = listReportModel;
  reportModalModel.title = 'Preview Report';
  customPrintModel.reportHeaderObject = listReportModel.reportHeaderObject || '';
  reportModalModel.content = buildCustomPrint(customPrintModel);
  let downloadFunction = () => {
    console.log('Download Clicked');
    let reportName = customPrintModel?.configInfo?.fileName || 'file';
    customPrintModel.mainDocDefinition.download(reportName);
  }
  let downloadBtn: ReportModalCustomButton = new ReportModalCustomButton('Download', false, downloadFunction);

  let printFunction = () => {
    console.log('Print Clicked');
    customPrintModel.mainDocDefinition.print();
  }
  let printBtn: ReportModalCustomButton = new ReportModalCustomButton('Print', false, printFunction);
  
  reportModalModel.listOfButton.length = 0;
  reportModalModel.listOfButton.push(downloadBtn);
  reportModalModel.listOfButton.push(printBtn);
  reportModalModel.state.setOpen(true);
};

export const excelDownloadLoader = (flag:boolean) => {
  listReportModel.state?.setExcelDownloaded(flag);
}  