import { State, useState } from '@hookstate/core';
import { KagamiActivityLogModel } from '../../../model/KagamiActivityLogModel';

export class KagamiActivityModel {
  createDateTime: any;
  createdBy: string;
  message: string;
  readOnly: boolean;
  updateDateTime: any;
  updatedBy: string;
  selfrefActivity: any[];
  isEditMode: boolean;
  isReplyMode: boolean;
  state: any;
  constructor(
    createDateTime: any,
    createdBy: string,
    message: string,
    readOnly: boolean,
    updateDateTime: any,
    updatedBy: string
  ) {
    this.createDateTime = createDateTime;
    this.createdBy = createdBy;
    this.message = message;
    this.readOnly = readOnly;
    this.updateDateTime = updateDateTime;
    this.updatedBy = updatedBy;

    this.selfrefActivity = [];
    this.isEditMode = false;
    this.isReplyMode = false;
  }
}
export interface KagamiActivityProps {
  KagamiActivityModel: KagamiActivityModel;
  KagamiActivityLogModel: KagamiActivityLogModel;
}

export const wrapKagamiActivityModelState = (state: State<KagamiActivityModel>) => {
  let activityState = state;

  return {
    setActivityMessage(message: string) {
      activityState.message.set(message);
    },

    get isEditMode() {
      return activityState.isEditMode.get();
    },
    setIsEditMode(edit: boolean) {
      return activityState.isEditMode.set(edit);
    },

    get isReplyMode() {
      return activityState.isReplyMode.get();
    },
    setIsReplyMode(edit: boolean) {
      return activityState.isReplyMode.set(edit);
    }
  };
};
export const useKagamiActivityState = (state: State<KagamiActivityModel>) =>
  wrapKagamiActivityModelState(useState(state));
