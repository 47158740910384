import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

export const CustomReportPopoverStyle = (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        'borderRadius': 6,
        'marginTop': theme.spacing(1),
        'minWidth': 180,
        'color': theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        'boxShadow':
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
          padding: '4px 0'
        },
        '& .MuiButtonBase-root': {
          color: '#565656',
        },
        '& .MuiMenuItem-root': {
          '& .MuiSvgIcon-root': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5)
          },
          '&:active': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
          }
        }
      }
    },
    adminPanel: {
      'gap': '2px',
      'background': '#00856B',
      'color': '#fff',
      'width': '100px',
      'height': '33px',
      'cursor': 'pointer',
      'margin': 'auto',
      'borderRadius': '4px',
      'transition': '0.2s ease-in',
      '&:hover': {
        background: '#00856Bdd',
        color: '#fff'
      }
    }
  });
