import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';



export const KagamiMultiSelectContentStyle = (theme: Theme) =>
  createStyles({

    searchFiled : {
      // paddingLeft: theme.spacing(1.6),
      marginTop: theme.spacing(-0.875),
      background : '#fff' ,
      float: 'right',
    },
      
    tableBorder : {
       border : '1px solid #ccc',
      marginTop : theme.spacing(2.5),
      paddingBottom:'1px',
      textAlign:'center',
    },


    column1 :{
      padding : ' 0px 16px',
      borderTop: '1px solid #ccc',
      height: theme.spacing(5.625),
    },
    
   
  })