import makeStyles from '@mui/styles/makeStyles';
import { KagamiHomeModel, HomeModelProps, useHomeState } from '../model/KagamiHomeModel';
import { createState } from '@hookstate/core';
import { KagamiHomeStyle } from '../style/KagamiHome';
import React, {useEffect } from 'react';
import { Body, ErrorModal, Header, Navigator, Loader, ModalDialogTemplateType, Footer } from '../builder/HomeBuilder';
import { getAppAndUserContext, getLogoData, getSummaryDashBoard } from '../../login/service/LoginService';
import logo from '../../login/images/logo.png';
import { getAppAndUserContextUrl } from '../../../service/URLService';
import { getAppplicationModel } from '../../../controller/KagamiApplicationController';
import { KagamiApplicationModel } from '../../../model/KagamiApplicationModel';
import { assignAppAndUserContextData } from '../builder/body/builder/process/service/PolicyExecutionService';
import NetworkDetector from '../../../../networkDetector/ui/NetworkDetector';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { executeProcess } from '../builder/navigator/builder/Menu/controller/KagamiMenuController';
import { setHomeModel } from '../controller/KagamiHomeController';
import { Toaster } from 'react-hot-toast';
import { buildtoastNotification } from '../builder/ToastNotification/controller/ToastNotificationController';
import { CommonUtils } from '../../../utils/CommonUtils';
import { getLogoAppAndUserContextData } from '../service/KagamiHomeService';
// import { LicenseManager } from 'ag-grid-enterprise';

export const buildHomeComponent = (pageModel: KagamiHomeModel) => {
  return <KagamiHome key={'kagami-home'} homeModel={pageModel} />;
};

export const KagamiHome = React.memo((props: HomeModelProps) => {

  const classes = makeStyles(KagamiHomeStyle)();
  const homeState = useHomeState(createState(props.homeModel));
  props.homeModel.state = homeState;
  setHomeModel(props.homeModel);

  useEffect(() => {
    // LicenseManager.setLicenseKey("LICENSE_HACK_BY_SUDHEER_[v26.1.1]__b1507244ab90657f098d0e85024b36d5")
    getLogoAppAndUserContextData(props.homeModel);
    import('ag-grid-enterprise').then(({LicenseManager : LicenseManager}) => {
      LicenseManager.setLicenseKey("LICENSE_HACK_BY_SUDHEER_[v26.1.1]__b1507244ab90657f098d0e85024b36d5")
    })
  }, []);
  if (!homeState.isLogoVisible) {
    // show Loader here
    return <></>;
  } else {
    return (
      <div className={classes.root}>
        <Navigator kagamiNavigatorModel={props.homeModel.navigatorModel} />

        <div className={classes.right_cont}>
          <header className={classes.header}>
            <Header />
          </header>

          <main className={classes.main}>
            <NetworkDetector />
            <ErrorModal homeModel={props.homeModel} />
            <ModalDialogTemplateType homeModel={props.homeModel} />
            <Loader loaderModel={props.homeModel.loaderModel} />
            <Body kagamiBodyModel={props.homeModel.bodyModel} />
          </main>
          <footer>
            <Footer />
          </footer>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerClassName={classes.toasterClass}
            containerStyle={{}}
            toastOptions={{
              // Define default options
              // duration: Infinity,//infinite case
              duration: 3000,
              style: {
                maxWidth: '400px',
                minWidth: '300px',
                // background: '#363636',

                // color: '#fff',
                margin: 0,
                padding: '0'
              }

              // Default options for specific types
              // success: {
              //   duration: 3000,
              //   // theme: {
              //   //   primary: 'green',
              //   //   secondary: 'black',
              //   // },
              // },
            }}
          />
        </div>
      </div>
    );
  }
});
