import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";
import { KagamiControlModel } from "../../model/kagamiControlModel";


export class KagamiCheckboxModel extends KagamiControlModel {
  uiSettings :any;
  isHavedefaultValue: any;
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any,
    onChangeRequired: boolean,
    validation: any,
    pickAttribute: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void, //changed control:KagamiCheckboxModel to control: KagamiControlModel
    policyMap: any,
    presentationPolicy : any,
    businessPolicy : any,
    initiallyReadOnly: boolean,
    initiallyVisible : boolean,
    uiSettings: any,
    formDataIdentifier : string,
    isGridField : boolean,
    isEmbddedField:boolean,
  ) {
    super(
      controlIdentifier,
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField
    );
    this.uiSettings = uiSettings
  }
}

export interface CheckboxFieldProps {
  kagamiCheckboxModel: KagamiCheckboxModel;
}


export function isDefaultValuesPresent(attributePresentation: any) {
  return CommonUtils.isNotEmpty(attributePresentation) && CommonUtils.isNotEmpty(attributePresentation['defaultValue'])
    ? attributePresentation['defaultValue']
    : false;
}