import { createState } from '@hookstate/core';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import {
  KPIReportModel,
  KPIReportModelProps,
  useKPIReportModelState
} from '../model/KPIReportModel';
import { makeKPI } from '../service/KPIReportService';

export const BuildKPIReportView = (kpiReportModel: KPIReportModel) => {
  return (
    <KagamiKPIChartReportView key={'KPI-chart-report'} kpiReportModel={kpiReportModel}></KagamiKPIChartReportView>
  );
};


const KagamiKPIChartReportView = (props: KPIReportModelProps) => {
  let kpiReportModel = props.kpiReportModel;
  let kpiChartState = useKPIReportModelState(createState(kpiReportModel));
  kpiReportModel.state = kpiChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(props.kpiReportModel)
  }, [])
  
  if(!kpiChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{
    if(kpiReportModel.reportData){
  const kpiConfig :any = makeKPI(kpiReportModel);

  return (
    <div className="ag-theme-alpine" style={{ height: '85vh',width:'100%' }}>
          NEED  TO IMPLEMENT 
      </div>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
  }
};
