import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiDashboardStyle = (theme: Theme) =>
  createStyles({
    root: {
      '&.row':{
        textAlign: 'start',
          display: 'flex',
          flexWrap: 'wrap',  
          '&>*': {
            width: '100%',
            maxWidth: "100%",
            'padding': theme.spacing(1),
            boxSizing: 'border-box',
          },
        '& .col': {
          flex: '1 0 0%'
        },
        //this styles should work in the entire form 
          '& .col_4': {
            width: 'calc(100% / 3)' ,
            flex:' 0 0 auto',
  
          
            [theme.breakpoints.down('sm')]: {
       
              width: 'calc(100% / 1)' ,
            
            },
            [theme.breakpoints.between('sm', 'md')]: {
             
              width: 'calc(100% / 2)' ,
            
            },
            [theme.breakpoints.up('lg')]: {
              width: 'calc(100% /3)' ,
              
            },
          },
          '& .col_8': {
            width: 'calc(2 * (100% / 3))',
            flex:' 0 0 auto',
          },
          '& .col_12': {
            width: '100%',
            flex:' 0 0 auto',
  
          },
          '&  .col_3': {
            width: 'calc(100% / 4)' ,
            flex:' 0 0 auto',
  
          },
          '& .col_2': {
            width: 'calc(100% / 6)' ,
            flex:' 0 0 auto',
  
          }, 
          '& .col_5': {
            width: '20%' ,
            flex:' 0 0 auto',
  
          }, 
          '& .col_6': {
            width: 'calc(100% / 2)' ,
            flex:' 0 0 auto',
  
          },
          
      },
    },






    dashboardNotificationSectionRoot: {

      height: 'inherit',
      '& .MuiPaper-root': {
        height: 'inherit',
        background:'#f5f8fa'
      }, 
      '&::-webkit-scrollbar': {
        width: '0.1em'
      },
      '& .MuiCollapse-root': {
        // 'height': theme.spacing(45),  // need change
        'height': 'inherit',
        'overflowY': 'auto',
        '&.MuiCollapse-entered': {
          // maxHeight: theme.spacing(45) // need change
          height: 'inherit !important' ,
          maxHeight: 'inherit'
        },
        '&::-webkit-scrollbar': {
          width: '0.1em'
      },
        '& .MuiPaper-root': {
          boxShadow: '0 0 0 0',
          // borderBottom: '0.2px solid #ccc',
          '& .MuiCollapse-root': {
            borderBottom: '0.2px solid #ccc',
            height: '0',
            maxHeight: theme.spacing(45),
            '& .MuiAccordionDetails-root' :{
              minHeight: '0',
            },
            
          },
          
        },
      },

      '& .MuiAccordionSummary-root': {
        padding: '0 6px 0 0',
        minHeight: '50px'
      },
    

      
    },
    dashboardNotificationSectionHeader: {
      fontSize: theme.typography.subtitle1.fontSize
    },
    dashboardNotificationRoot: {
      display: 'flex',
      alignItems: 'start',
      width: '100%',
      justifyContent: 'space-between',
      gap:'10px',
    },
    dashboard_icon_header: {
      marginLeft: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      color:theme.palette.secondary.main,
      position: 'relative'
    },
    dashboard_icon: {
      fontSize: '13px',
      marginRight: '7px'
    },
    dashboard_header: {
      fontSize: '13px',
      lineHeight: 1,
      fontWeight: 600,
      textTransform: 'uppercase',
      paddingRight: theme.spacing(2),
      // 
      // 
      '&.BaseBadge-badge': {
        backgroundColor: theme.palette.primary.main
      },
    },
    // notifications_length: {
    //   top: '-8px',
    //   right: '-10px',
    //   color: theme.palette.background.paper,
    //   minWidth: '18px',
    //   minHeight: '18px',
    //   maxWidth: ' 35px',
    //   maxHeight: '35px',
    //   position: 'absolute',
    //   fontSize:'11px',
    //   background: theme.palette.secondary.dark,
    //   textAlign: 'center',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   display: 'flex',
    //   borderRadius: '100%'
    // },
    dashboard_notification_message: {
      color: theme.palette.common.black,
      fontSize:'14px',
      margin: '0 0 10px 8px',
      cursor:'pointer',
      width: '100%',
      textAlign: 'start',
    },
    dashboard_notification_header: {
      display: 'flex',
      width: '100%',
      '& .MuiSvgIcon-root': {
        width: '0.7em'
      },
    },
    dashboard_notification_timedifference: {
      color: theme.palette.secondary.main,
      fontSize: '13px',
      fontStyle: 'italic',
      display: 'inline-flex',
      // fontWeight:' 500'
    },
    dashboard_notofication_circleicon: {
     
      //color: theme.palette.info.dark,
      marginTop: '2px'
    },
    dashboard_notification_vertical_icon: {
      color: theme.palette.primary.dark
    },
    notificationMenu: {
      minWidth: '105px',
      position:'relative',
      border:'1px solid #d4d4d4',
      
    },
    dashboardNotificationListItem: {
      width: '100%',
      display : 'flex',
      gap: theme.spacing(1.5),  
      padding:theme.spacing(1.75),
      borderBottom: '1px solid #ccc',
      '&:last-child':{
        borderBottom: 'none'
      }
        

    },
    dashboardNotificationGroupName: {
      minWidth: '30%',
      maxWidth: '30%',
     
      // color: theme.palette.secondary.main,
      margin:'0',
      fontWeight: 500,
      textTransform: 'uppercase',
       
      fontSize: '14px',
      
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    dashboardNotificationGroupProcess:{
      width: '60%',
       
    },
    dashboardNotificationGroupProcessLink:{
      // color: theme.palette.secondary.dark,
      // fontWeight: 600, 
      fontSize: '13px',
      padding: `0 ${theme.spacing(1.5)}`,
      paddingTop: '2px',
      display: 'inline-block',
      borderRight: `2px solid ${theme.palette.secondary.dark}`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth:' 50%',
      maxWidth:' 50%',
      cursor: 'pointer',
      '&:hover':{
        textDecoration:'underline'
      },
      '&:last-child':{
        borderRight: 'none'
      }
    },

    PopoverMenuItem: {
      fontSize:'12px', 
      color: theme.palette.secondary.main,
      borderBottom:'1px solid #ebebeb',
      '&:last-child': {
        border:'none'
      },
      '&:hover': {
        background:'#006e7f10'
      }
    },

    dashboard_grid_layout: {
      '& .grid-component': {
        border: '1px solid #eee',
        borderRadius: '4px',
        overflow: 'hidden',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        '& .hide-buttons-from-desktop-report-container':{
          display:'none'
        },
        '& .react-resizable-handle': {
          visibility: 'hidden',
        },
        '&:hover':{
          '& .VisibleExpandedButton':{
            visibility:'visible',
          },
          '& .react-resizable-handle': {
            visibility: 'visible',
          }
        },
      },
    },
  });
