import { CommonUtils } from '../../../../../application/utils/CommonUtils';
import { KagamiActivityLogModel } from '../../../model/KagamiActivityLogModel';
import { buildActivityList } from '../ui/KagamiActivity';

export const buildActivityLogs = (activityLogModel: KagamiActivityLogModel) => {
  let activityList: any[];
  if (CommonUtils.isEmpty(activityLogModel.activityLogContainer)) {
    activityLogModel.activityLogContainer = {};
    activityLogModel.activityLogContainer.activityList = [];
  }
  activityList = activityLogModel.activityLogContainer.activityList;
  return buildActivityList(activityList, activityLogModel);
};
