import { State, useState } from "@hookstate/core";

import { KagamiStateManager } from "../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../constants/SystemConstants";
import { KagamiInfoIconModel } from "../AboutUs/model/KagamiInfoIconModel";
import { KagamiProfileModel } from "../builder/profile/model/KagamiProfileModel";
import { KagamiHeaderFavoritesModel } from "./kagamiHeaderFavorites";
import { KagamiHeaderNotificationModel } from "./KagamiHeaderNotificationModel";
import { KagamiHeaderUserProfileModel } from "./kagamiHeaderUserProfile.";
import { KagamiKeyBoardShortCutsModel } from "../AboutUs/model/KagamiKeyBoardShortCutsModel";

export class KagamiHomeHeaderModel {
  pageHeader: boolean;
  notificationModel: KagamiHeaderNotificationModel;
  favoritesModel: KagamiHeaderFavoritesModel;
  infoIconModel: KagamiInfoIconModel;
  keyBoardShortCutsModel: KagamiKeyBoardShortCutsModel;
  translations: any;
  profileModel:KagamiProfileModel;
  state : any;
  sidebarExpanded : boolean;
  reportVisible:boolean;
  notificationVisible:boolean;
  showAboutUs :  boolean
  
  constructor() {
    this.notificationModel = new KagamiHeaderNotificationModel('Notification');
    this.favoritesModel = new KagamiHeaderFavoritesModel('Favorite');
    this.profileModel = new KagamiProfileModel(null , null);
    this.infoIconModel = new KagamiInfoIconModel();
    this.keyBoardShortCutsModel = new KagamiKeyBoardShortCutsModel();
    this.state=null;
    this.pageHeader = true;
    this.sidebarExpanded = false;
    this.reportVisible = true;   
    this.notificationVisible=true;
    this.showAboutUs = false;    
  }
}
export interface KagamiPageHeaderProps {
  kagamiPageHeaderModel: KagamiHomeHeaderModel;
}

export const wrapKagamiPageHeaderState = (state: State<KagamiHomeHeaderModel>) => {
  let pageHeaderState = state;

  return {
    getSidebarExpanded() {
      return pageHeaderState.sidebarExpanded.get();
    },
    setSidebarExpanded(value: boolean) {
      pageHeaderState.sidebarExpanded.set(value);
    },
    getReportIconVisible() {
      return pageHeaderState.reportVisible.get()
     },
      setReportIconVisible(value:boolean){
     pageHeaderState.reportVisible.set(value)
     },
     notificationIconVisible() {
      return pageHeaderState.notificationVisible.get()
     },
     setNotificationIconVisible(value:boolean) {
      pageHeaderState.notificationVisible.set(value)
     },
     get showAboutUs(){
      return pageHeaderState.showAboutUs.get()
     },
     setAboutUsVisibile(visible : boolean){
         pageHeaderState.showAboutUs.set(visible)
     }
    } 
  
};

export const useKagamiPageHeaderState = (state: State<KagamiHomeHeaderModel>) => {
  const pageHeaderState = wrapKagamiPageHeaderState(useState(state));
  KagamiStateManager.addStateToHolder('PageHeader', pageHeaderState);
  return pageHeaderState;
};