import { RuntimeInput } from '../../../../../../../../../../../../model/form/runtime_input';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { handleOnload } from '../../../controller/KagamiListController';
import { KagamiCalendarViewModel } from '../model/KagamiCalendarViewModel';
import _ from 'lodash';
import moment from 'moment';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { retrieveListRows } from '../../../service/kagamiListService';

// ----------------------------------------------------------------------------------------------------------------------
export const makeOnLoadCallToFetchCalendarData = (event: any, kagamiCalendarViewModel: KagamiCalendarViewModel) => {
  let firstDay: any;
  let lastDay: any;
  if(kagamiCalendarViewModel?.mainEntityId?.includes("AttendanceValidate")) {
  firstDay = moment(event.view.currentStart).format(SystemConstants.YYYY_MM_DDTHH_mm_ss_SSSZ);
  lastDay = moment(event.view.currentEnd).format(SystemConstants.YYYY_MM_DDTHH_mm_ss_SSSZ);
  } else {
  firstDay = moment(event.view.currentStart).subtract(7, 'days').format(SystemConstants.YYYY_MM_DDTHH_mm_ss_SSSZ);
  lastDay = moment(event.view.currentEnd).add(7, 'days').format(SystemConstants.YYYY_MM_DDTHH_mm_ss_SSSZ);
  }
  let runtimeInput = new RuntimeInput(firstDay, lastDay, null);
  if(runtimeInput.searchKey === null || runtimeInput.searchKey === '') {
    runtimeInput = new RuntimeInput(firstDay, lastDay);
  }
  kagamiCalendarViewModel.runtimeInput = runtimeInput;
  handleOnload(kagamiCalendarViewModel);
}

export const loadInitialEventsforFirstLoad2 = (kagamiCalendarViewModel: KagamiCalendarViewModel) => {
  let events: any[] = [];
  let calendarConfiguration = kagamiCalendarViewModel.presentation?.uiSettings?.cardViewConfiguration;
  let displayEventNameCalendar = calendarConfiguration?.calendar?.displayEventNameCalendar;
  let fromDateEntityId = kagamiCalendarViewModel?.customFieldAttrName;
  let fromDateCalendar = calendarConfiguration?.calendar?.fromDateCalendar;
  let toDateCalendar = calendarConfiguration?.calendar?.toDateCalendar;
  let mainEntityId = kagamiCalendarViewModel?.mainEntityId;
  let AttendenceColum:any = kagamiCalendarViewModel?.customFieldAttrName;
  let rows: any[] = retrieveListRows(kagamiCalendarViewModel);

  events = rows.map((row:any)=>{
    let titleToBeDisplayed: string = '';
    let fromDate:any;
    let toDate:any;
    let singleEvent:any;
    if(displayEventNameCalendar && displayEventNameCalendar.length){
      displayEventNameCalendar.forEach((displayEvent:any,index:number)=>{
        let displayColumnName:string = '';
        if(displayEvent.value){
          displayColumnName = displayEvent.value.replace(mainEntityId+'.', ''); 
          if(index == 0)AttendenceColum = displayColumnName.split('.')[0];
          titleToBeDisplayed = getDisplayValue(displayColumnName, row, titleToBeDisplayed, kagamiCalendarViewModel);
        }

      })
    }
    if(fromDateCalendar && fromDateCalendar.value){
      fromDate = fromDateCalendar.value.replace(mainEntityId+'.', '');
    }
    if(toDateCalendar && toDateCalendar.value){
      toDate = toDateCalendar.value.replace(mainEntityId+'.', '');
    }else{
      toDate = fromDate;
    }
    console.log(titleToBeDisplayed);
    singleEvent = {
      id: row['id'],
      title: titleToBeDisplayed,
      allDay: false,
      color: 'white',
      textColor: kagamiCalendarViewModel.isCustomField
        ? getColorForCalendarHolidayList(row[kagamiCalendarViewModel.customFieldAttrName])
        : row[AttendenceColum]?.Color?.bgCode || 'rgb(30, 144, 255)',
      start: new Date(row[fromDate] ? row[fromDate] : row[fromDateEntityId][fromDate]),
      end: new Date(row[toDate] ? row[toDate] : row[fromDateEntityId][fromDate])
    };
    singleEvent['extendedProps'] = {
      calendarConfiguration: calendarConfiguration,
      calendarDetailedObject: kagamiCalendarViewModel?.detailedObjects ?? [],
      presentation: kagamiCalendarViewModel.presentation
    }
    return singleEvent;
  })
  return events;
}

export const getDisplayValue = (
  displayColumnName: string,
  row: any,
  titleToBeDisplayed: string,
  kagamiCalendarViewModel: KagamiCalendarViewModel
) => {
  let currentTitle: any = '';
  let pRule = kagamiCalendarViewModel.presentation.presentationRules[displayColumnName];

  if(row){
    if (displayColumnName.includes('.')) {
      let rowData: any = _.clone(row);
      displayColumnName.split('.').forEach((columnName: any) => {
        if(rowData && rowData[columnName]) {
        rowData = rowData[columnName];
        }
      });
      currentTitle = typeof rowData === 'string'? rowData: '';
    } else{
      currentTitle = getFormatForEventData(row[displayColumnName]??'',pRule);
    }
  }

  if (titleToBeDisplayed && currentTitle) {
      titleToBeDisplayed += '-' + currentTitle;
  } else {
    titleToBeDisplayed += currentTitle;
  }
  return titleToBeDisplayed;
};

const getFormatForEventData = (decimal:any, pRule:any) => {
  if(pRule?.htmlControl === 'text'){
    return typeof decimal === 'number'?  convertDecimalToDateTimeString(decimal, pRule?.uiSettings?.conversion ?? 'null'): decimal;
  }else if(pRule?.htmlControl === 'number'){
    return typeof decimal === 'number'?  convertDecimalToDateTimeString(decimal, pRule?.uiSettings?.conversion ?? 'null'): decimal;
  }else if(pRule?.htmlControl === 'dateTime'){
    return moment(decimal).isValid() ? moment(decimal).format('DD MMM YYYY hh:mm a'): '';
  }else{
    return decimal;
  }
}

const convertDecimalToDateTimeString = (decimal:any, conversionType:any) => {
  if (decimal == null || decimal==="") {
      return "";
  }
  if (conversionType == 'null') {
      return decimal + "";
  }
  let splitArr = decimal.toString().split('.');
  let hourNumber, minuteNumber, finalStr, minutes;
  if (conversionType === 'h m') {
      if (splitArr.length > 1) {
          hourNumber = Number(splitArr[0]);
          minuteNumber = Number(splitArr[1]);
          hourNumber = padLeadingZero(hourNumber);
          finalStr = hourNumber + " h ";
          minutes = Math.round(Number("." + splitArr[1]) * 60);
          minutes = padLeadingZero(minutes);
          finalStr += minutes.toString().split('.')[0] + " m";
          return finalStr;
      } else {
          decimal = padLeadingZero(decimal);
          return decimal.toString() + " h";
      }

  } else if (conversionType === 'hh:mm') {
      if (splitArr.length > 1) {
          hourNumber = Number(splitArr[0]);
          minuteNumber = Number(splitArr[1]);
          hourNumber = padLeadingZero(hourNumber);
          finalStr = hourNumber + ":";
          minutes = Math.round(Number("." + splitArr[1]) * 60);
          minutes = padLeadingZero(minutes);
          finalStr += minutes.toString().split('.')[0];
          return finalStr;
      } else {
          decimal = padLeadingZero(decimal);
          return decimal.toString() + ":00";
      }
  } else if (conversionType === 'hh:mm:ss') {
      if (splitArr.length > 1) {
          hourNumber = Number(splitArr[0]);
          minuteNumber = Number(splitArr[1]);
          hourNumber = padLeadingZero(hourNumber);
          finalStr = hourNumber + ":";
          minutes = Number("." + splitArr[1]) * 60;
          minutes = padLeadingZero(minutes);
          finalStr += minutes.toString().split('.')[0] + ":";
          let seconds = minutes.toString().split('.')[1];
          seconds = Number(seconds);
          seconds = padLeadingZero(seconds);
          finalStr += seconds.toString();
          return finalStr;
        }else{
          decimal = padLeadingZero(decimal);
          return decimal.toString() + ":00" + ":00";
        }
  }
}

const padLeadingZero = (params:any) => {
  return (params < 10) ? ("0" + params) : params;
}

// ----------------------------------------------------------------------------------------------------------------------
export const getEventsInfoForCustomCalendar = (props: any) => {
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let instanceValues = [];
  let defValues: any = [];
  if (props?.eventStore?.instances) {
    instanceValues = _.values(props.eventStore.instances)
  }
  if (props?.eventStore?.defs) {
    defValues = _.values(props.eventStore.defs)
  }
  if (instanceValues?.length) {
    _.forEach(instanceValues, (item) => {
      let def = _.filter(defValues, { defId: item.defId });
      if (def?.length) {
        item['title'] = def[0]['title'];
      }
      if (item?.range?.start) {
        item['month'] = months[new Date(item.range.start)?.getMonth()] || months[0];
      }
    })
  }
  return instanceValues?.length ? _.groupBy(instanceValues, 'month') : '';
}


// ----------------------------------------------------------------------------------------------------------------------
const getColorForCalendarHolidayList = (calendarCellData:any) =>{
    if(CommonUtils.isNotEmpty(calendarCellData?.Color) && CommonUtils.isNotEmpty(calendarCellData?.Color?.bgCode)){
       return calendarCellData?.Color?.bgCode;
    }
    return "#1e90ff"
}

// ----------------------------------------------------------------------------------------------------------------------
// export const loadInitialEventsforFirstLoad = (rows: any, kagamiCalendarViewModel: any) => {
//   var calendarConfiguration = kagamiCalendarViewModel?.presentation?.uiSettings?.cardViewConfiguration;
//   var fromDateEntityId: any = '';
//   var fromDateColumn: any = '';
//   var AttendenceEntityId: any = '';
//   var AttendenceColum: any = '';
//   var key: any = '';
//   var value: any = '';
//   var displayEntityId: any = '';
//   var dataType : string = '';
//   var displayColumnName: any = '';
//   let map: any[] = [];
//   var events: any[] = [];
//   // let rows: any[] = retrieveListRows(kagamiCalendarViewModel);

//   if (rows.length > 0 && calendarConfiguration && calendarConfiguration.calendar) {
//     let singleEvent: any;
//     var calConfig = calendarConfiguration.calendar;
//     var attendenceName = calConfig.displayEventNameCalendar[0].value.split('.');
//     var fromDate = calConfig.fromDateCalendar.value.split('.');
//     for ([key, value] of Object.entries(attendenceName)) {
//       if (!value.includes('_')) {
//         AttendenceEntityId = attendenceName[key - 2];
//         AttendenceColum = attendenceName[key - 1];
//       }
//     }
//     for ([key, value] of Object.entries(fromDate)) {
//       if (!value.includes('_')) {
//         fromDateEntityId = fromDate[key - 1];
//         fromDateColumn = value;
//       }
//     }
//     calConfig.displayEventNameCalendar.forEach((element: any) => {
//       if (CommonUtils.isNotEmpty(element)) {
//         var elements = element.value.split('.');
//         for ([key, value] of Object.entries(elements)) {
//           if (!value.includes('_')) {
//             displayEntityId = elements[key - 1];
//             displayColumnName = value;
//             dataType = element['dataType']
//           }
//         }
//         map.push({ displayColumnName, displayEntityId,dataType });
//       }
//     });
//     rows.map((row: any,index : number) => {
//       let titleToBeDisplayed: string = '';
//       map.forEach(function (value, key) {
//         if (row[value.displayColumnName] != null) {
//           if(value.dataType === 'dateTime'){
//             //  let dateValue : any = getDateStringAsLocalString(row[value.displayColumnName],'formattedDate')
//             //  console.log('date value is '+dateValue.toString())
//             //  titleToBeDisplayed = titleToBeDisplayed.concat(dateValue.toString()+ '_'+"\n");
//           }
//           else{
//             titleToBeDisplayed = titleToBeDisplayed.concat(row[value.displayColumnName] + '-'+"\n");
//           }
//         } else {
//           if (row[value.displayEntityId] != null) {
//             titleToBeDisplayed = titleToBeDisplayed.concat(row[value.displayEntityId][value.displayColumnName] + '_');
//           }
//         }
//       });
//       let currentTitle = getTimeFormatForCalendarCell(titleToBeDisplayed);
      
//       if(currentTitle[currentTitle.length - 1] === '\n'){
//         if(currentTitle[currentTitle.length - 2] === '-') {
//           currentTitle = currentTitle.slice(0, currentTitle.length - 2);
//         }
//       }else if(currentTitle[currentTitle.length - 1] === '-'){
//         currentTitle = currentTitle.slice(0, currentTitle.length - 1);
//       }
//       singleEvent = {
//         id: row['id'],
//         title: currentTitle,
//         // title: titleToBeDisplayed,
//         allDay: false,
//         color: 'white',
//         textColor: kagamiCalendarViewModel.isCustomField ? getColorForCalendarHolidayList(row[kagamiCalendarViewModel.customFieldAttrName]): row[AttendenceColum]?.Color?.bgCode || '#000',
//         start: new Date(row[fromDateColumn] ? row[fromDateColumn] : row[fromDateEntityId][fromDateColumn]),
//         end: new Date(row[fromDateColumn] ? row[fromDateColumn] : row[fromDateEntityId][fromDateColumn])
//       };
//       singleEvent['extendedProps'] = {
//         calendarConfiguration: calendarConfiguration,
//         calendarDetailedObject: kagamiCalendarViewModel?.detailedObjects ?? [],
//         presentation: kagamiCalendarViewModel.presentation
//       }
//       events.push(singleEvent);
//     });

//     return events;
//   } else {
//     return events;
//   }
// };


// ----------------------------------------------------------------------------------------------------------------------
// export const getTimeFormatForCalendarCell = (title: any) => {
//   let eventTitle: any;
//   if (title.includes("_")) {
//     let getTitleSplitData = title.split('_').filter((ele: any) => ele !== "");
//     if(getTitleSplitData.length === 1){
//        eventTitle = getTitleSplitData[0]
//        return eventTitle;
//     }
//     else if (getTitleSplitData.length === 2) {
//       if(getTitleSplitData[1] === "\n"){
//         eventTitle = getTitleSplitData[0]
//       }
//       else{
//         eventTitle = getTitleSplitData[0] + '-' + getHours(getTitleSplitData[1]);
//       }
//       return eventTitle
//     } else if (getTitleSplitData.length === 3 && getTitleSplitData[2] !== "\n") {
//       if(getTitleSplitData[2] !== "\n"){
//         eventTitle = getTitleSplitData[0] + '-' + getHours(getTitleSplitData[1])
//         + '' + '' + moment(getTitleSplitData[2]).format('MMMM Do YYYY h:mm a')
//       }
//       else{
//         eventTitle = getTitleSplitData[0] + '-' + getHours(getTitleSplitData[1]);
//       }
//       return eventTitle;
//     } else if (getTitleSplitData.length === 4) {
//       eventTitle = getTitleSplitData[0] + '-' + getHours(getTitleSplitData[1])
//         + '' + '' + moment(getTitleSplitData[2]).format('MMMM Do YYYY h:mm a') + '' + '-' + moment(getTitleSplitData[3]).format('MMMM Do YYYY h:mm a');
//       return eventTitle;
//     } else {
//       return title;
//     }
//   } else {
//     eventTitle = title;
//     return eventTitle;
//   }
// }

// ----------------------------------------------------------------------------------------------------------------------
// const getHours = (hours:any) => {
//    if(hours.includes(":")){
//      return hours
//    }else{
//      if(hours.charAt(0) === "0"){
//       return hours + ":" + '00'
//      }else{
//       return hours;
//      }
//    }
// }

