export class KagamiProfileModel {
  content: any;
  state: any;
  appAndUserContext: any;

  constructor(content: any) {
    this.content = content;
  }
}

export interface KagamiProfileProps {
  kagamiProfileModel: KagamiProfileModel;
}
