import { CommonUtils } from "../../../../../../../../../../../utils/CommonUtils";
import { retrieveAttributePresentation } from "../../../../../service/PresentationService";
import { getKagamiListModel } from "../../../controller/KagamiListController";
import { KagamiListFilterModel } from "../model/KagamiListFilterModel";
import { buildClearFilterButton } from "./clearFilterButton/controller/KagamiClearFiltersController";
import { buildpresentationfilter } from "./kagamiPresentationFilter/controller/KagamiPresentationFilterController";
import { KagamiPresentationFilterModel } from "./kagamiPresentationFilter/model/KagamiPresentationFilterModel";
import { buildPresentationObjForWidget } from "./listPresentationFilterWidget/controller/ListFilterWidgetController";



export const listPresentationFilters = (kagamiListFilterModel :KagamiListFilterModel) => { 
    return buildListPresentationFilters(kagamiListFilterModel); 
}



export const buildListPresentationFilters:any = (kagamiListFilterModel:KagamiListFilterModel) => {
    const kagamiListModel = getKagamiListModel();
    let dataRecordsForPresentationAttributes = retrieveAttributePresentation(kagamiListModel.onStartData);

    const presentationFiltersComponents = [];
    let displayAttributesNames: string;
    let sourceMapChildEntityId: string;
    let entityPrefix: string;
    let sourceMapEntityKey: string;
    let addEntityPrefix: string;
    let presentationFilterModel: KagamiPresentationFilterModel;
    let filterData: any;

    presentationFiltersComponents.push(
      dataRecordsForPresentationAttributes.map((dataRecordsForPresentationAttribute: any) => {
        if (dataRecordsForPresentationAttribute.source && dataRecordsForPresentationAttribute.source.parent) {
          let sourceMapParentEntityId = dataRecordsForPresentationAttribute.source.parent.entityId;
          if (dataRecordsForPresentationAttribute.source.parent.entityPrefix === '') {
            if (dataRecordsForPresentationAttribute.source.parent.for_attr.length > 1) {
              if( dataRecordsForPresentationAttribute.source.parent.for_attr[1].type === "file"){
                sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[0].name;
                sourceMapEntityKey = sourceMapParentEntityId.concat('.', sourceMapChildEntityId);
              }else{
                sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[1].name;
                sourceMapEntityKey = sourceMapParentEntityId.concat('.', sourceMapChildEntityId);
              }
            } else {
              sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[0].name;
              sourceMapEntityKey = sourceMapParentEntityId.concat('.', sourceMapChildEntityId);
            }
          } else {
            entityPrefix = dataRecordsForPresentationAttribute.source.parent.entityPrefix;
            if (dataRecordsForPresentationAttribute.source.parent.for_attr.length > 1) {
              sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[1].name;
              addEntityPrefix = entityPrefix.concat('', sourceMapParentEntityId);
              sourceMapEntityKey = addEntityPrefix.concat('.', sourceMapChildEntityId);
            } else {
              sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[0].name;
              addEntityPrefix = entityPrefix.concat('', sourceMapParentEntityId);
              sourceMapEntityKey = addEntityPrefix.concat('.', sourceMapChildEntityId);
            }
          }
          //  sourceMapEntityKey = sourceMapParentEntityId.concat('.',sourceMapChildEntityId);
          displayAttributesNames = sourceMapEntityKey;
          if (Object.keys(kagamiListFilterModel.filterData).length > 0) {
            filterData = kagamiListFilterModel.filterData[displayAttributesNames];
          } else {
            filterData = [];
          }
          // filterData =  kagamiListFilterModel.filterData[displayAttributesNames]
        } else {
          displayAttributesNames = dataRecordsForPresentationAttribute.attrName;
          if (Object.keys(kagamiListFilterModel.filterData).length > 0) {
            filterData = kagamiListFilterModel.filterData[displayAttributesNames];
          } else {
            filterData = [];
          }
          // filterData =  kagamiListFilterModel.filterData[displayAttributesNames];
        }

        if(CommonUtils.isNotEmpty(filterData)){
          presentationFilterModel = new KagamiPresentationFilterModel(
            dataRecordsForPresentationAttribute,
            filterData,
            // kagamiListModel,
            displayAttributesNames
          );
        //  let filterComponents =  
          // kagamiListFilterModel.presentationFilters.set(displayAttributesNames,presentationFilterModel)
          return kagamiListFilterModel.isWidget ?  buildPresentationObjForWidget(presentationFilterModel, kagamiListFilterModel) : buildpresentationfilter(presentationFilterModel, kagamiListFilterModel);
        } else {
          return null
        }
      })
    );
    // kagamiListFilterModel.filters = presentationFiltersComponents;
    // console.log('kagamiListFilterModel',kagamiListFilterModel)

    if(!kagamiListFilterModel.isWidget) {
      presentationFiltersComponents.push(buildClearFilterButton(kagamiListFilterModel.clearFiltersModel, kagamiListFilterModel))
    }
    console.log('presentationFiltersComponents', presentationFiltersComponents)
//  console.log("filtersmap", kagamiListFilterModel.presentationFilters )
    return presentationFiltersComponents;
  };
