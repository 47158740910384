import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiFileStyle = (theme: Theme) =>
  createStyles({
    root: { 
      marginTop: '-4px',
      alignItems: 'center'
      
    },

    read_only:{
      // paddingLeft: '14.8px !important',
      fontSize: theme.spacing(1.75),
      display:'flex',
      flexDirection:'column',
      gap:'1px',
    },
    thumbnailImage: {
      display: 'flex',
      gap: '0px',
      alignItems: 'center',
      maxWidth: '50px',
      height: '30px',
      border: '1px solid #006E7F',
      borderRadius: '3px'
    },
    deleteIconContainer: {
      position: 'absolute',
      top: '-9px',
      right: '-7px',
      cursor: 'pointer',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid red',
      borderRadius: '50%'
    },

  });
