import { createState } from '@hookstate/core';
import { CircularProgress, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import times from "lodash/times";
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { TileChartReportModel, TileChartReportModelProps, useTileChartReportState } from '../model/TileChartReportModel';
import { makeTile } from '../service/TileChartReportService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '85vh',
      width: '100%'
    },
    main: {
      margin: '4px'
    },
    each_tile: {
      borderRadius: '5px',
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      textAlign: 'center',
      marginBottom: '10px',
      color: '#fff',
      padding: '15px',
      boxShadow: '0 0 2px #55bf1e',
        '& .tile_header': {
          fontWeight: '600',
          fontSize: '14px',
          margin: '5px 0',
          color: '#666',
          wordBreak: 'break-all',
          textAlign: 'center'
        },
        '& .tile_data': {
          fontSize: '20px',
          marginBottom: '0',
          fontWeight: '400',
          color: '#0066b3',
          wordBreak: 'break-all',
          lineHeight: 'initial',
          paddingTop: '8px',
          textAlign: 'center'
        }
    }
  })
);

export const BuildTileChartReportView = (tileChartReportModel: TileChartReportModel) => {
  return <KagamiTileChartReportView key={'tile-chart-report'} tileChartReportModel={tileChartReportModel}></KagamiTileChartReportView>;
};

const KagamiTileChartReportView = (props: TileChartReportModelProps) => {
  let classes = useStyles();
  let tileChartReportModel = props.tileChartReportModel;
  let tileChartState = useTileChartReportState(createState(tileChartReportModel));
  tileChartReportModel.state = tileChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(tileChartReportModel);
  }, []);

  if (!tileChartState.isDataLoaded) {
    return <CircularProgress sx={{ marginTop: '4%', marginLeft: '48%' }} color="secondary" />;
  } else {
    if(tileChartReportModel.reportData){
    const tileInfo: any = makeTile(tileChartReportModel);
    const tiles: any[] = [];
     forEach( keys(tileInfo), (key: any) => {
      tiles.push(
        <Grid  item xs={6}>
        <div className={classes.each_tile}>
          { times(tileInfo[key].length, (index: number) =>
            index === 0 ? (
              <>
                <p className='tile_header'>{key}</p>
                <p className='tile_data'>{tileInfo[key][index]}</p>
              </>
            ) : (
              <p>{tileInfo[key][index]}</p>
            )
          )}
          </div>
        </Grid>
      );
    });
    return (
      <>
        <Grid className='custom-dashboard-tile-report' container spacing={2} >
          {tiles}
        </Grid>
      </>
    );
  }else{
    return <p className='NoDataFound'>No Data Found</p>
  }
  }
};