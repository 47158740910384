import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { callLocalPolices, getFormModel } from '../../../controller/KagamiFormController';
import { buildFormComponents, FormContent } from '../../FormBuilder';
import { KagamiFormPresentationModel, KagamiFormPresentationProps } from '../model/KagamiFormPresentationModel';
import { KagamiFormPresentationStyle } from '../style/KagamiFormPresentation';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getGroupModel } from '../../../../group/service/GroupService';
import { handleServerError, loadGroupContent } from '../../../../group/controller/KagamiGroupController';
import { onTriggerClick } from '../../../../Triggers/service/KagamiTriggersService';
import { showGroupBackButton } from '../../../service/FormService';
import { KagamiEmbeddedFormPresentationModel } from '../../../model/KagamiEmbeddedFormPresentationModel';

export const buildFormPresentationComponent = (kagamiFormPresentationModel: KagamiFormPresentationModel) => {
  // Form builder getting called twice // only due to dev mode ?? // Temp fix
  kagamiFormPresentationModel.formComponentObj = {};

  kagamiFormPresentationModel.formComponentObj = buildFormComponents(kagamiFormPresentationModel, null);
  return (
    <KagamiFormPresentation
      key={kagamiFormPresentationModel.formEntityId}
      kagamiFormPresentationModel={kagamiFormPresentationModel} embeddedAccordionPresentationModel={undefined}    ></KagamiFormPresentation>
  );
};

const KagamiFormPresentation = (props: KagamiFormPresentationProps) => {
  const classes = makeStyles(KagamiFormPresentationStyle)();

  useEffect(() => {
    /// Note : not calling local policies for expanded embedded form because mostly they'll have onload calls and policies
    /// will get executed if there is a network call
    if(!(props.kagamiFormPresentationModel instanceof KagamiEmbeddedFormPresentationModel)){
      callLocalPolices(props.kagamiFormPresentationModel.mainEntityId, getFormModel(),'',true);
    }
    // callPoliciesForEmbedTriggers(getFormModel())
  });


  const handleGroupBackButton = () => {
    let triggerModel = getGroupModel().kagamiTriggerModel;
    if (triggerModel !== null) {
      onTriggerClick([], triggerModel, handleServerError, loadGroupContent, true, true);
    }
  };

  return (
    <>
      <FormContent
        key={props.kagamiFormPresentationModel.formEntityId + 'formContent'}
        kagamiFormPresentationModel={props.kagamiFormPresentationModel}
        classes={classes} embeddedAccordionPresentationModel={undefined}      />
    </>
  );
};
