import { getAllRoles, getRoleReportMapping } from '../../../../../view/report/service/KagamiReportService';
import {  PinnedReportsModel } from '../Model/PinnedReportsModel';

export function setPinnedRoleAndReportData (pinnedReportsModel: PinnedReportsModel) {
  getPinnedRoleAndReport().then((response:any)=>{
    console.log(response);
    pinnedReportsModel.dbRolesList = response[0].data.respObject;
    pinnedReportsModel.dbRoleReportMapping = response[1].data.respObject;
    pinnedReportsModel.state.setDataLoaded(true);
  }).catch((err:any)=>{
    console.log(err);
  })
}

export async function getPinnedRoleAndReport() {
  return await Promise.all([getAllRoles(), getRoleReportMapping()]);
}