export class RuntimeInput {
  id: string;
  calendarStart?: string | null;
  calendarEnd?: string | null;
  searchKey?: string | null;
  ktriggerName?: string | null;

  constructor() {
    this.id = '';
    this.calendarStart = null;
    this.calendarEnd = null;
    this.searchKey = null;
    this.ktriggerName = null;
  }

  toMap() {
    let map = new Map();
    map.set('id', this.id);
    map.set('calendarStart', this.calendarStart);
    map.set('calendarEnd', this.calendarEnd);
    map.set('searchKey', this.searchKey);
    map.set('ktriggerName', this.ktriggerName);

    return map;
  }
}
