import { State, useState } from "@hookstate/core";


export class kagamiProcessHeaderModel {
    state: any;
    headerloaded : boolean;
    constructor() {
        this.headerloaded = true
    }
}

export interface kagamiProcessHeaderModelProps {
    kagamiProcessHeaderModel: kagamiProcessHeaderModel;   
}

export const wrapProcessHeaderState = (state: State<kagamiProcessHeaderModel>) => {
    let headerState = useState(state);
    return{
        get headerVisible(){
           return headerState.headerloaded.get()
        },
        setheaderVisible(value: boolean) {
            headerState.headerloaded.set(value);
          }   
    }
}

export const useProcessHeaderState = (state:State<kagamiProcessHeaderModel>) => wrapProcessHeaderState(useState(state));

