import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';
import { LedgerDetailsModalModel } from '../../modal/model/ledgerDetailsModalModel';


export class DayBookReportModel extends KagamiReportModel {
  modelPopup:LedgerDetailsModalModel;
  reportData: any;
  state: any;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.modelPopup = new LedgerDetailsModalModel()
  }
}
export interface DayBookReportModelProps {
  dayBookReportModel: DayBookReportModel;
}
export const useDayBookReportModelState = (state: State<DayBookReportModel>) => {
  let dayBookReportState = useState(state);
  return {
    reportConfig() {
      dayBookReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      dayBookReportState.reportData.set(reportContent);
    }
  };
};
