import { getDmsViewUrl } from '../../../../../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { extractDropdownOption, getEntityConsumedInAttribute } from '../../../../../../../service/PresentationService';
import { getValueForPickAttribute } from '../../../../../service/kagamiListService';

export function retrieveFileUrl(record: any, presentation: any) {
  var entityId: any = '';
  var column: any = '';
  var key: any = '';
  var value: any = '';
  var cardviewconfig:any;
  if(presentation &&  presentation.uiSettings &&  presentation.uiSettings.cardViewConfiguration &&  presentation.uiSettings.cardViewConfiguration.imgUrl){
    cardviewconfig =  presentation.uiSettings.cardViewConfiguration.imgUrl.split('.'); 
    for ([key, value] of Object.entries(cardviewconfig))
    if (!value.includes('_')) {
      entityId = cardviewconfig[key - 1];
      column = value;
    }
  }else{
    cardviewconfig = null
  }
  if (record[entityId] && record[entityId][column] != undefined) {
    console.log(record[entityId][column]);
    var fileid = record[entityId][column].includes(':')
      ? record[entityId][column].split(':')[0]
      : record[entityId][column];
    return getDmsViewUrl(fileid);
  } else if (record[column] && record[column] != undefined) {
    var fileid = record[column].includes(':') ? record[column].split(':')[0] : record[column];
    return getDmsViewUrl(fileid);
  } else {
    return process.env.PUBLIC_URL + 'images/no-image-found.jpg';
  }
}

export const getCardFieldValue = (dataRecord: any, column: any) => {
  let attributeName: string = column.field;
  let value: any;
  if (attributeName.includes('.')) {
    value = getValueForPickAttribute(attributeName, dataRecord);
  }
  else if( column.headerName.includes('.')) {
    let entityConsumed = getEntityConsumedInAttribute(column.attributePresentation);
    let data : any = dataRecord.get(entityConsumed);
    value = extractDropdownOption(column.attributePresentation,data);
  }
  else if(column.headerName && column.headerName instanceof Array){
    if (typeof dataRecord.get(column.field) === 'object') {
      let valueData = dataRecord.get(column.field);
      value = extractDropdownOption(column.attributePresentation,valueData);
    } else {
      value = dataRecord.get(column.field);
      if(CommonUtils.isEmpty(value)){
        value = getValueForPickAttribute(column?.attributePresentation?.attrName, dataRecord);
      }
    }
  }
  else {
    if (typeof dataRecord.get(column.field) === 'object') {
      let valueData = dataRecord.get(column.field);
      value = valueData[column.headerName];
    } else {
      value = dataRecord.get(column.field);
    }
  }
  return value;
};
