import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const ToastNotificationStyle = (theme: Theme) =>
  createStyles({
    toastRoot : {
        display:'flex',
        maxWidth:'400px',
        minWidth:'300px',
        minHeight:'40px',
        alignItems:'center',
        borderRadius:'10px '
    },
    toastIcon :{
        width:'40px',
        height:'100%',
    },
    toastBody: {
        // minWidth:'200px',
        // maxWidth:'250px',
        width:"100%",
        height:'100%',
      
        textAlign:'left',
        padding:'0px 10px',
        paddingTop:'4px'
    },
    toastDelete: {
        width:'40px',
        height:'100%',
    },
    iconContainer: {
        display:'flex',
        width:'100%',
        height:'100%',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:'10px 0 0 10px'
    }

  })