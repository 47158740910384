import { KagamiBeanUtils } from '../../../../../../../utils/KagamiBeanUtils';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { KagamiFormPresentationModel } from '../builder/form/builder/form-presenation/model/KagamiFormPresentationModel';
import { KagamiFormModel } from '../builder/form/model/KagamiFormModel';
import { KagamiFormSectionPresentationModel } from '../builder/form/builder/section-presentation/model/KagamiFormSectionPresentationModel';
import { getFormModel } from '../builder/form/controller/KagamiFormController';
import { KagamiFormData } from '../builder/form/model/formData';
import { KagamiControlModel } from '../builder/controls/model/kagamiControlModel';
import { getCalendarViewModel } from '../builder/list/builder/calendar/controller/KagamiCalendarViewController';
import { getActiveModel } from '../controller/KagamiProcessController';
import { display } from '@mui/system';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { PollRounded } from '@mui/icons-material';

export function getPolicyMap(attributePresentation: any) {
  return attributePresentation['policyMap'];
}

export function getHtmlControl(attributePresentation: any) {
  return attributePresentation['htmlControl'];
}

export function getAttributeLabel(attributePresentation: any, isForm: boolean = false) {
  let activeModel = getActiveModel();
  if (
    CommonUtils.isNotEmpty(activeModel) &&
    activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation
  ) {
    let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
    let label = CommonUtils.isNotEmpty(attributePresentation['label'])
      ? attributePresentation['label']
      : isForm
      ? ''
      : attributePresentation['attrName'];
    return translateToLocalistaion(translations, label);
  } else {
    return CommonUtils.isNotEmpty(attributePresentation['label'])
      ? attributePresentation['label']
      : isForm
      ? ''
      : attributePresentation['attrName'];
  }
}

export function translateToLocalistaion(translation: any, label: any) {
  // let value : string = label;
  // if(CommonUtils.isNotEmpty(translation) && CommonUtils.isNotEmpty(translation[label])){
  //   value = translation[label];
  // }
  // return value;

  let value: string = '';
  if (CommonUtils.isNotEmpty(translation)) {
    Object.keys(translation).map((key: any, i: number) => {
      if (key === label) {
        value = translation[key];
      }
    });
    return value !== '' ? value : label;
  } else return label;
}

export function getAttributeName(attributePresentation: any) {
  if (attributePresentation != null) {
    return attributePresentation['attrName'];
  } else {
    return null;
  }
}

export function isReadOnlyAttribute(attributePresentation: any) {
  return attributePresentation['readOnly'];
}

export function isRequiredAttribute(attributePresentation: any) {
  return attributePresentation['mandatory'];
}

export function isVisibleAttribute(attributePresentation: any) {
  return attributePresentation['visible'];
}
export function noValueMsgAtrribute(attributePresenatation: any) {
  return attributePresenatation['noValueMsg'];
}
export function broadcastRequiredAttribute(attributePresenatation: any) {
  return attributePresenatation['broadCastRequired'];
}
export function getMainEntityIdForPresentation(entityPresentation: any) {
  const uiResource: any = getUiResourceFromPresentation(entityPresentation);
  if (uiResource !== undefined) {
    return uiResource['presentations']['entityLevelMap']['0'];
  } else {
    return [];
  }
}

export function retrievePresentations(onStartData: any) {
  let presentations = retrievePresentationsFromUIResource(onStartData);
  let entityLevelMap = presentations['entityLevelMap'];
  let entityId = entityLevelMap[0];
  return retrievePresentationForEntity(presentations, entityId);
}

export function getMappedEntityIdForPresentation(entityPresentation: any) {
  let entityId: string = getEntityIdForPresentation(entityPresentation);
  return CommonUtils.isNotEmpty(entityPresentation['entityPrefix'])
    ? entityPresentation['entityPrefix'].concat(entityId)
    : entityId;
}

export function getEntityIdForPresentation(entityPresentation: any): string {
  return entityPresentation['entityId'];
}
export function getPresenationIdFromPresentation(entityPresentation: any) {
  return entityPresentation['presentationId'];
}
export function getPresenationHeaderFromPresentation(entityPresentation: any) {
  const headerName = entityPresentation['headerName'];
  if (entityPresentation.entityLevelMap) {
    let entityId: string = entityPresentation.entityLevelMap[0];
    let presentationRuleMap: any = entityPresentation['presentationRuleMap'];
    if (presentationRuleMap[entityId] != null) {
      let list: any = presentationRuleMap[entityId];
      let processHeader: string = list[0].headerName;
      if (processHeader.indexOf('{{') !== -1) {
        let headerNameList: any[] = [];
        if (processHeader.charAt(0) === '{') {
          headerNameList = processHeader.split('}}');
          processHeader = headerNameList.length > 1 ? headerNameList[1] : processHeader;
          if (processHeader.indexOf('-') !== -1) {
            processHeader = processHeader.replace('-', '');
          }
        } else {
          headerNameList = processHeader.split('{{');
          processHeader = headerNameList.length > 1 ? headerNameList[0] : processHeader;
          if (processHeader.indexOf('-') !== -1) {
            processHeader = processHeader.replace('-', '');
          }
        }
      }
      return processHeader;
    } else {
      return null;
    }
  } else if (headerName.includes('-')) {
    return headerName;
  } else {
    return headerName;
  }
}

export function getHeaderForprocess(constructOutputDataForStartCall: any, constructOutputDataForLoadCall?: any) {
  let translations = CommonUtils.isNotEmpty(constructOutputDataForStartCall?.uiResource?.otherProperties?.translation)
    ? constructOutputDataForStartCall?.uiResource?.otherProperties?.translation
    : constructOutputDataForLoadCall?.uiResource?.otherProperties?.translation;
  let processHeader: any='';
  let entityPresentation = constructOutputDataForStartCall.uiResource.presentations;
  let calenderModel = getCalendarViewModel();
  if (entityPresentation.entityLevelMap) {
    let entityId: string = entityPresentation.entityLevelMap[0];
    let presentationRuleMap: any = entityPresentation['presentationRuleMap'];
    if (presentationRuleMap[entityId] != null) {
      let list: any = presentationRuleMap[entityId];
      if (list[0].headerName.includes('{{')) {
        let headerStrings : any[] = list[0].headerName.split(' ')
        let detailedObjects : any = CommonUtils.isEmpty(constructOutputDataForLoadCall) ? constructOutputDataForStartCall.detailedObjects
                                    : CommonUtils.isNotEmpty(constructOutputDataForLoadCall.detailedObjects) ? constructOutputDataForLoadCall.detailedObjects : constructOutputDataForLoadCall;
        let ch:string;
        for(let i=0;i<headerStrings.length;i++){
          ch = headerStrings[i]
          if(CommonUtils.isNotEmpty(ch)){
            if(ch.includes('{{')){
              ch = ch.replace('{{','')
              ch = ch.replace('}}','')
              if(detailedObjects instanceof Array){
                detailedObjects = detailedObjects[0]
              }
              let value = KagamiBeanUtils.getProperty(ch,detailedObjects)
              if(value != undefined)
                processHeader = i !==0 ? processHeader+' '+value : processHeader+value;
            }
            else{
              processHeader = (processHeader != undefined && processHeader != "") ? processHeader+' '+ch : ""
            }
          }
        }
        calenderModel?.state?.setIsHeaderLoadedFromOnStartData(true);
      } else {
        processHeader = list[0].headerName;
        calenderModel?.state.setIsHeaderLoadedFromOnStartData(true);
      }
      return translateToLocalistaion(translations, processHeader);
    } else {
      return null;
    }
  } else if (processHeader.includes('-')) {
    return translateToLocalistaion(translations, processHeader);
  } else {
    return translateToLocalistaion(translations, processHeader);
  }
}

export function getHeaderForPresentation(entityPresentation: any) {
  return entityPresentation['formName'] ?? '';
}

function getPresenationFormNameFromPresentation(entityPresentation: any) {
  return entityPresentation['formName'];
}

export function isLoadRequiredForPresentation(entityPresentation: any) {
  let presentation: any = retrievePresentation(entityPresentation);
  return presentation['onLoadRequired'];
}

export function isMandatoryPresentation(entityPresentation: any): boolean {
  return entityPresentation['mandatory'];
}

export function isVisiblePresentation(entityPresentation: any): boolean {
  return entityPresentation['visible'];
}

export function isCancelRequiredForPresentation(entityPresentation: any) {
  return entityPresentation['onCancelRequired'];
}

export function isListPresentation(entityPresentation: any): boolean {
  return entityPresentation['list'] === undefined ? true : entityPresentation['list'];
}

export function getAttributePresentations(entityPresentation: any): any[] {
  return entityPresentation['presentationRules'];
}

export function getFormPresentationFormAttribute(attributePresentation: any): any {
  return attributePresentation['presentationRule'];
}
export function getAttributePresentationType(attributePresentation: any): string {
  return attributePresentation['@type'];
}
export function getMainEntityPresentationsFromUIResource(onStartData: any): any[] {
  const uiResource: any = getUiResourceFromPresentation(onStartData);
  const entityId = getMainEntityIdForPresentation(onStartData);
  return uiResource['presentations']['presentationRuleMap'][entityId];
}
export function getEntityConsumed(attributePresentation: any) {
  return attributePresentation['entityConsumed'];
}
export function getEntityPresentationsFromUIResource(onStartData: any, entityId: string): [] {
  const uiResource: any = getUiResourceFromPresentation(onStartData);
  return uiResource['presentations']['presentationRuleMap'][entityId];
}

export function isOnLoadRequiredForPresentation(entityPresentation: any) {
  return entityPresentation['onLoadRequired'];
}

export function isOnCancelRequiredForPresentation(entityPresentation: any) {
  return entityPresentation['onCancelRequired'];
}

export function isOnChangeRequiredForAttribute(attributePresentation: any): boolean {
  return attributePresentation['onChangeRequired'];
}

function isOnClickRequiredForAttribute(attributePresentation: any): boolean {
  return attributePresentation['onClickRequired'];
}

export function getUiSettings(attributePresentation: any) {
  return attributePresentation['uiSettings'];
}
export function getBusinessPolicy(attributePresentation: any) {
  return KagamiBeanUtils.getProperty('policyMap.businessValidations', attributePresentation);
}

export function getPrefixtypes(attributePresentation: any) {
  return attributePresentation['uiSettings']['prefixType'];
}
export function getPresentationPolicy(attributePresentation: any) {
  return KagamiBeanUtils.getProperty('policyMap.presentation', attributePresentation);
}

export function getDisplayAttributes(attributePresentation: any): any {
  if (attributePresentation['displayAttributes']) {
    // return attributePresentation['displayAttributes'][0];
    if (attributePresentation['displayAttributes'].length > 1) {
      return attributePresentation['displayAttributes'];
    } else {
      return attributePresentation['displayAttributes'][0];
    }
  } else {
    // return  attributePresentation.source.parent.for_attr.map((element:any)=>{if(element){
    //   if( element.name!=='id' ){
    //     return element.name;
    //   }}})
    if (attributePresentation.source && attributePresentation.source.parent) {
      for (const attribute of attributePresentation.source.parent.for_attr) {
        let element = attributePresentation.source.parent.for_attr[attribute];
        // console.log(element);
        if (attribute && attribute.name !== 'id') {
          return attribute.name;
        }
      }
    }
  }

  if (
    CommonUtils.isNotEmpty(attributePresentation['displayAttributes']) &&
    attributePresentation.hasOwnProperty('displayAttributes')
  ) {
    if (attributePresentation['displayAttributes'].length > 1) {
      return attributePresentation['displayAttributes'];
    } else {
      return attributePresentation['displayAttributes'][0];
    }
  } else {
    if (attributePresentation.source && attributePresentation.source.parent) {
      for (const attribute of attributePresentation.source.parent.for_attr) {
        let element = attributePresentation.source.parent.for_attr[attribute];
        // console.log(element);
        if (attribute && attribute.name !== 'id') {
          return attribute.name;
        }
      }
    }
  }
}

export function getUiResourceFromPresentation(entityPresentation: any) {
  return entityPresentation['constructOutputData']['uiResource'];
}

export function retrievePresentation(onStartData: any) {
  let constructOutputData: any = onStartData['constructOutputData'];
  let uiResource: any = constructOutputData['uiResource'];
  let presentations: any = uiResource['presentations'];
  let entityLevelMap: any = presentations['entityLevelMap'];
  let entityId: string = entityLevelMap[0];
  let presentationRuleMap: any = presentations['presentationRuleMap'];
  if (presentationRuleMap[entityId] != null) {
    let list: any = presentationRuleMap[entityId];
    return list[0];
  } else {
    return null;
  }
}

export function retrieveMappedstatus(onStartData: any, status: string) {
  // export function retrieveMappedstatus(onStartData: any) {
  let mappedStatusData: any = [];
  onStartData.forEach((obj: any) => {
    mappedStatusData.push({
      key: obj.masterEntityId,
      status: obj.name || obj[status],
      // status: obj.name,
      statusHeaderColor: obj.Color.bgCode,
      dataMap: obj
    });
  });
  return mappedStatusData;

  // let mappedStatusData:any= [];
  // let constructOutputData: any = onStartData['constructOutputData'];
  // let uiResource: any = constructOutputData['uiResource'];
  // let presentations: any = uiResource['presentations'];
  // let entityLevelMap: any = presentations['entityLevelMap'];
  // let entityId: string = entityLevelMap[0];
  // let presentationRuleMap: any = presentations['presentationRuleMap'];
  // if (presentationRuleMap[entityId] != null) {
  //   let list: any = presentationRuleMap[entityId][0];
  //   list.uiSettings.mappedStatus.forEach((obj:any) =>{
  //     mappedStatusData.push({key:obj.status , dataMap: obj})
  //    })
  //    return mappedStatusData;
  // } else {
  //   return null;
  // }
}

export function getkanbanAttributePresentation(onStartData: any) {
  let constructOutputData: any = onStartData['constructOutputData'];
  let uiResource: any = constructOutputData['uiResource'];
  let presentations: any = uiResource['presentations'];
  let entityLevelMap: any = presentations['entityLevelMap'];
  let entityId: string = entityLevelMap[0];
  let presentationRuleMap: any = presentations['presentationRuleMap'];
  if (presentationRuleMap[entityId] != null) {
    return presentationRuleMap[entityId][0].uiSettings.cardViewConfiguration.kanbanAttribute;
  } else {
    return null;
  }
}

export function retrieveAttributePresentation(onStartData: any) {
  let displayHeadersKeyLabel: any = [];
  let constructOutputData: any = onStartData['constructOutputData'];
  let uiResource: any = constructOutputData['uiResource'];
  let presentations: any = uiResource['presentations'];
  let entityLevelMap: any = presentations['entityLevelMap'];
  let entityId: string = entityLevelMap[0];
  let presentationRuleMap: any = presentations['presentationRuleMap'];
  if (presentationRuleMap[entityId] != null) {
    let list: any = presentationRuleMap[entityId];
    let attributeToShow: any = list[0].presentationRules;
    let keys = Object.keys(attributeToShow);
    keys.forEach((key) => {
      let headersKeys = attributeToShow[key];
      if (headersKeys.searcheable === true) {
        displayHeadersKeyLabel.push(headersKeys);
      }
    });
    return displayHeadersKeyLabel;
  } else {
    return null;
  }
}
export function retrievePresentationsFromUIResource(onStartData: any) {
  let constructOutputData = onStartData['constructOutputData'];
  let uiResource = constructOutputData['uiResource'];
  return uiResource['presentations'];
}
export function getUITemplateForPresentation(entityPresentation: any) {
  const uiResource: any = getUiResourceFromPresentation(entityPresentation);
  return uiResource['uiTemplate'];
}
function retrievePresentationForEntity(presentations: any, entityId: string): [] {
  let presentationRuleMap = presentations['presentationRuleMap'];
  if (null != presentationRuleMap[entityId]) {
    return presentationRuleMap[entityId][0];
  } else {
    return [];
  }
}
export function retrievePresentationsForEntity(presentations: any, entityId: string): [] {
  let presentationRuleMap = presentations['presentationRuleMap'];
  if (null != presentationRuleMap[entityId]) {
    return presentationRuleMap[entityId];
  } else {
    return [];
  }
}
export function getEmbeddedPresentationId(attributePresentation: any) {
  return attributePresentation['embeddedPresentationId'];
}
export function getDefaultListView(onStartData: any) {
  let presentation: any = getMainEntityPresentationsFromUIResource(onStartData);
  let viewType = presentation[0]['uiTemplate'];
  try {
    let uiSettings: any = presentation[0]['uiSettings'];
    if (uiSettings) {
      let cardViewConfiguration: any = uiSettings['cardViewConfiguration'];
      if (cardViewConfiguration) {
        viewType = cardViewConfiguration['defaultView'];
        for (var i = 0; i < cardViewConfiguration['visibleViews'].length; i++) {
          if (
            cardViewConfiguration['visibleViews'][i]['name'] === 'hierarchical' &&
            cardViewConfiguration['visibleViews'][i]['selected'] === true
          ) {
            viewType = 'hierarchical';
          }
        }
      }
      console.log(viewType);
    }
  } catch (e) {
    viewType = presentation['uiTemplate'];
  }
  if (viewType === undefined) {
    viewType = presentation[0]['uiTemplate'];
  }
  return viewType;
}

export function isModalListView(processResponseModel: KagamiProcessResponseModel) {
  let modalListView: boolean = false;
  let onStartData: any = processResponseModel.onStartData;
  if (processResponseModel.onErrorData !== null) {
    modalListView = false;
  } else if (
    CommonUtils.isNotEmpty(onStartData.constructOutputData) &&
    CommonUtils.isNotEmpty(onStartData.constructOutputData['uiResource'])
  ) {
    let presentation: any = getMainEntityPresentationsFromUIResource(onStartData);
    if (presentation) {
      let viewType = presentation[0]['uiTemplate'];
      if (viewType === 'list') {
        let uiSettings: any = presentation[0]['uiSettings'];
        if (uiSettings) {
          let cardViewConfiguration: any = uiSettings['cardViewConfiguration'];
          if (cardViewConfiguration && CommonUtils.isNotEmpty(cardViewConfiguration.listType)) {
            modalListView = cardViewConfiguration.listType === 'modalListView';
          }
        }
      }
    }
  }
  return modalListView;
}

export function getViewTypeByPresentation(presentation: any) {
  let viewType = presentation['uiTemplate'];
  if (presentation && presentation.uiSettings) {
    let cardViewConfiguration: any = presentation.uiSettings['cardViewConfiguration'];
    if (cardViewConfiguration) {
      viewType = cardViewConfiguration['defaultView'];
      for (var i = 0; i < cardViewConfiguration['visibleViews'].length; i++) {
        if (
          cardViewConfiguration['visibleViews'][i]['name'] === 'hierarchical' &&
          cardViewConfiguration['visibleViews'][i]['selected'] === true
        ) {
          viewType = 'hierarchical';
        }
      }
    }
  }
  return viewType;
}

export function isVisibleViewsPresent(presentation: any) {
  let isVisbleViewExits: any = [];
  let uiSettings: any = presentation['uiSettings'];
  if (uiSettings) {
    let cardViewConfiguration: any = uiSettings['cardViewConfiguration'];
    if (cardViewConfiguration) {
      let visibleview = cardViewConfiguration['visibleViews'];
      if (CommonUtils.isNotEmpty(visibleview)) {
        isVisbleViewExits = visibleview.filter((visibleviewes: any) => visibleviewes.selected === true);
      }
    }
  }
  return isVisbleViewExits;
}
export function getEntityConsumedInAttribute(attributePresentation: any) {
  if (attributePresentation['entityPrefix'] == null || attributePresentation['entityPrefix'] === '') {
    return attributePresentation['entityConsumed'];
  } else {
    return attributePresentation['entityPrefix'] + attributePresentation['entityConsumed'];
  }
}
export function getEntityAssociatedAttribute(attributePresentation: any) {
  return attributePresentation['entityAssociatedAttribute'] ?? 'id';
}
export function getTriggerName(trigger: any) {
  let triggerName: string;
  if (trigger['actionName'] != null && trigger['actionName'] !== '') {
    triggerName = trigger['actionName'];
  } else {
    triggerName = trigger['labelName'];
  }
  return triggerName;
}
export function extractDropdownOption(attributePresentation: any, dataRecord: any
  // , dispValue?: any
  ) {
  // TODO remove this line and pass it as param, 25 drop down 25 calls.
  let dropdownAttributes = getDropDownAttribute(attributePresentation);
  let dispAttributes =  attributePresentation?.uiSettings?.visibleAttributes ? attributePresentation?.uiSettings?.visibleAttributes : "";
  if (dropdownAttributes && CommonUtils.isNotEmpty(dataRecord)) {
    let toReturn: any = {};
    toReturn['id'] = dataRecord['id'];
    toReturn['record'] = dataRecord;
    toReturn['value'] = '';
    let displayValues: string[] = [];
    getDisplayValuesForDropdownAttributes(dropdownAttributes, dataRecord, displayValues);
    if (CommonUtils.isNotEmpty(displayValues)) {
      toReturn['value'] = displayValues.join(' - ');
    } else if (CommonUtils.isNotEmpty(getFormModel())) {
      let formData: KagamiFormData = getFormModel().formData;
      let detailedObjects: any = formData.data;
      let entityConsumed: string = getEntityConsumed(attributePresentation);
      if (
        Object.keys(detailedObjects).includes(entityConsumed) &&
        CommonUtils.isNotEmpty(detailedObjects[entityConsumed])
      ) {
        let selectedDataRecord = detailedObjects[entityConsumed].find((record: any) => record.id === dataRecord.id);
        if (selectedDataRecord) {
          getDisplayValuesForDropdownAttributes(dropdownAttributes, selectedDataRecord, displayValues);
          if (CommonUtils.isNotEmpty(displayValues)) {
            toReturn['value'] = displayValues.join('-');
          }
        }
      }
    }
    // if (CommonUtils.isEmpty(toReturn['value'])) {
    //   toReturn['value'] = dataRecord['id'];
    // }
    // let newValue: any;
    // if(dispValue && CommonUtils.isNotEmpty(dispAttributes) && dispAttributes.length === 1) {
    //   if(toReturn['value'].includes(dataRecord[dispAttributes])){
    //     newValue = dataRecord[dispAttributes]
    //     }  
    //   toReturn['value'] = newValue;
    // }
    return CommonUtils.isNotEmpty(toReturn['value']) ? toReturn['value'].toString() : SystemConstants.EMPTY;
  }
  return SystemConstants.EMPTY;
}

export function getDisplayValuesForDropdownAttributes(
  dropdownAttributes: any[],
  dataRecord: any,
  displayValues: string[]
) {
  for (let dropdownAttribute of dropdownAttributes) {
    if (dropdownAttribute['name'] === 'id' || dropdownAttribute['type'] === 'file') {
      continue;
    } else {
      const attributeName: string = dropdownAttribute['name'];
      const attributeType: string = dropdownAttribute['type'];
      const displayValue: string =
        CommonUtils.getDisplayValueForProperty(attributeName, attributeType, dataRecord) ?? SystemConstants.EMPTY;
      if (CommonUtils.isNotEmpty(displayValue) && displayValue !== SystemConstants.REMOVED_) {
        displayValues.push(displayValue);
      }
    }
  }
  // if (CommonUtils.isEmpty(displayValues)) {
  //   for (let dropdownAttribute of dropdownAttributes) {
  //     if (dropdownAttribute['name'] === 'id') {
  //       displayValues.push(dataRecord.id);
  //     }
  //   }
  // }
}

export function getColorForMappedStatus(onStartData: any) {
  let colorCodeData = onStartData.constructOutputData['detailedObjects'];
  return colorCodeData;
}
function getDropDownAttribute(attributePresentation: any) {
  return KagamiBeanUtils.getProperty('source.parent.for_attr', attributePresentation);
}
export function getAttributeValidations(attributePresentation: any) {
  return attributePresentation['validations'];
}
export function getForAttrFromPresentation(attributePresenatation: any) {
  return attributePresenatation['source'] && attributePresenatation['source']['parent']
    ? attributePresenatation['source']['parent']['for_attr']
    : null;
}
export function evaluateDynamicHeader(header: any, dataRecords: any) {
  if (CommonUtils.isEmpty(header)) {
    return SystemConstants.EMPTY;
  } else {
    let dyanamicKeys = header.match(/(?<=\{{).+?(?=\}})/g);
    if (dyanamicKeys != null) {
      for (let dyanamicKey of dyanamicKeys) {
        // let _attributes = dyanamicKey.split('.');
        // if (_attributes.length == 2) {
        //   let dyanamicValue= dataRecords != null ? (dataRecords[_attributes[1]] ? dataRecords[_attributes[1]] : null) : null;
        //   header =header.replace('{{'+dyanamicKey+'}}',dyanamicValue)
        // }
        // }
        let dyanamicValue = CommonUtils._getVariableValue(dyanamicKey, dataRecords);
        header = header.replace('{{' + dyanamicKey + '}}', dyanamicValue);
      }
    }
    return header;
  }
}

export function getEmbeddedView(uiSettings: any) {
  let embedView: string = 'acccordion';
  if (uiSettings !== undefined) {
    embedView = uiSettings['defaultView'] !== undefined ? uiSettings['defaultView'] : 'accordion';
  }
  return embedView;
}

export function getDefaultEmbeddedFormView(formModel: KagamiFormModel) {
  let mainFormPresentationModel: KagamiFormPresentationModel = formModel.formControls[formModel.mainEntityId];
  let defaultEmbeddedView: string = 'inline';
  if (
    mainFormPresentationModel &&
    mainFormPresentationModel.presentation['uiSettings'] &&
    mainFormPresentationModel.presentation['uiSettings']['defaultEmbeddedView']
  ) {
    defaultEmbeddedView = mainFormPresentationModel.presentation['uiSettings']['defaultEmbeddedView'];
  }
  return defaultEmbeddedView;
}

export function removeDuplicateObjectFromArray(array: any[], key: string | number) {
  return array.filter(
    (obj: { [x: string]: any }, index: any, self: any[]) =>
      index === self.findIndex((el: { [x: string]: any }) => el[key] === obj[key])
  );
}

export function fetchIsDynamicForm(presentation: any) {
  return CommonUtils.isNotEmpty(presentation.dynamicForm) ? presentation.dynamicForm : false;
}

export function getAttributeEventsForDynamicForm(onStartData: any, pruleId: string) {
  let attributeEvents: any[] = [];
  let uiResource: any = onStartData['constructOutputData']['uiResource'];
  let dynamicFormPresentations: any[] = uiResource['dynamicForm'];
  let presentation: any = dynamicFormPresentations.find((p) => p.presentationRuleId === pruleId);
  if (presentation) {
    attributeEvents = presentation['attributeEvents'];
  }
  return attributeEvents;
}

export function getPolicyMapFromAttributeEvent(attributeEvent: any) {}

export function isOnChangeRequiredFromAttributeEvent(attributeEvent: any) {
  let onChangeRequired: boolean = false;
  if (attributeEvent && attributeEvent['actionForCompute']) {
    onChangeRequired = attributeEvent['actionForCompute']['kagos'].length > 0;
  }
  return onChangeRequired;
}

export function getPresentationPolicyForDynamicForm(attributeEvent: any) {
  let presentationPolicy: any;
  if (
    attributeEvent &&
    attributeEvent['actionForDisplay'] &&
    attributeEvent['actionForDisplay']['policy'] &&
    attributeEvent['actionForDisplay']['policy']['type'] === 'presentation'
  ) {
    presentationPolicy = attributeEvent['actionForDisplay']['policy'];
  }
  return presentationPolicy;
}

export function getBusinessPolicyForDynamicForm(attributeEvent: any) {
  if (attributeEvent) {
    console.log('attribute name ---- ' + attributeEvent.attributeName);
  }
  let businessPolicy: any;
  if (
    attributeEvent &&
    attributeEvent['actionForValidation'] &&
    attributeEvent['actionForValidation']['policy'] &&
    attributeEvent['actionForValidation']['policy']['type'] === 'businessValidation'
  ) {
    businessPolicy = attributeEvent['actionForValidation']['policy'];
  }
  return businessPolicy;
}

export function getPRuleIdForPresentation(formPresentationModel: KagamiFormPresentationModel) {
  let pRuleId: string;
  if (formPresentationModel instanceof KagamiFormSectionPresentationModel) {
    pRuleId = formPresentationModel.parentPRuleId;
  } else {
    pRuleId = getPresenationIdFromPresentation(formPresentationModel.presentation);
  }
  return pRuleId;
}

export function getEntityPrefix(attributePresenatation: any) {
  let entityPrefix: any;
  if (attributePresenatation['entityPrefix']) {
    entityPrefix = attributePresenatation['entityPrefix'];
  }
  return entityPrefix;
}

export function isTypeText(htmlControl: string) {
  let textTypes: any[] = ['text', 'textarea', 'number', 'password'];
  return textTypes.includes(htmlControl);
}

export function isCommaSeparated(uiSettings: any) {
  return CommonUtils.isNotEmpty(uiSettings) && CommonUtils.isNotEmpty(uiSettings['commaSeparated'])
    ? uiSettings['commaSeparated']
    : false;
}

export function isConversion(uiSettings: any) {
  return CommonUtils.isNotEmpty(uiSettings) && CommonUtils.isNotEmpty(uiSettings['commaSeparated'])
    ? uiSettings['conversion']
    : false;
}

export function currencySymbolValue(uiSettings: any) {
  if (CommonUtils.isNotEmpty(uiSettings) && CommonUtils.isNotEmpty(uiSettings['prefixType'])) {
    if (CommonUtils.isNotEmpty(uiSettings['prefixType']['type'])) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function isPrefixType(uiSettings: any) {
  if (CommonUtils.isNotEmpty(uiSettings) && CommonUtils.isNotEmpty(uiSettings['prefixType'])) {
    if (
      CommonUtils.isNotEmpty(uiSettings['prefixType']['type']) &&
      CommonUtils.isNotEmpty(uiSettings['prefixType']['value'] === 'Currency')
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function embedHasFormControls(formControls: any) {
  let attributeName: any, control: any;
  let hasFormControls: boolean = false;
  for ([attributeName, control] of Object.entries(formControls)) {
    if (control instanceof KagamiControlModel) {
      hasFormControls = true;
      break;
    }
  }
  return hasFormControls;
}

export function isContactNumber(attributePresenatation: any) {
  let isContact: boolean = false;
  if (
    CommonUtils.isNotEmpty(
      attributePresenatation['validations'] && Object.keys(attributePresenatation['validations']).length > 0
    )
  ) {
    if (
      attributePresenatation.validations.cell ||
      attributePresenatation.validations.phone ||
      attributePresenatation.validations.fax ||
      attributePresenatation.validations.mobile
    ) {
      isContact = true;
    }
  }
  return isContact;
}

export function getFormButtonLabel(presentation: any, labelName: string) {
  let saveLabel: string = labelName === 'saveLabel' ? 'Save' : 'Reset';
  if (
    CommonUtils.isNotEmpty(presentation['uiSettings']) &&
    CommonUtils.isNotEmpty(presentation['uiSettings'][labelName])
  ) {
    saveLabel = presentation['uiSettings'][labelName];
  }
  return saveLabel;
}

export function getGridAttributeType(gridControlModel: any) {
  switch (gridControlModel.controlType) {
    case 'search':
      return (gridControlModel.policyMap && gridControlModel.policyMap.presentation) ||
        gridControlModel?.attributePresentation?.uiSettings?.customField?.Color
        ? 'customSearch'
        : gridControlModel.controlType;
      break;
    case 'number':
      return (gridControlModel.policyMap && gridControlModel.policyMap.presentation) ||
        gridControlModel?.attributePresentation?.uiSettings?.commaSeparated
        ? 'customNumber'
        : gridControlModel.controlType;
      break;
    case 'text':
      return !gridControlModel.readonly &&
        gridControlModel?.attributePresentation?.validations !== undefined &&
        CommonUtils.isNotEmpty(
          Object.keys(gridControlModel?.attributePresentation?.validations).find((controlType: string) => {
            return controlType === 'cell' || controlType === 'phone';
          })
        )
        ? 'customPhoneNumber'
        : (gridControlModel.policyMap && gridControlModel.policyMap.presentation) ||
          gridControlModel?.attributePresentation?.uiSettings?.customField?.Color
        ? 'customText'
        : gridControlModel.controlType;
    default:
      return gridControlModel.controlType;
      break;
  }
}

export function hasExternalData(uiSettings: any) {
  let hasExternalData: boolean = false;
  if (CommonUtils.isNotEmpty(uiSettings) && CommonUtils.isNotEmpty(uiSettings['externalData'])) {
    hasExternalData = true;
  }
  return hasExternalData;
}

export function getNonFieldPresentationTriggers(metadata: any, controlType: string) {
  let showNonFieldPresentation: boolean = true;
  if (controlType === 'BUTTON') {
    if (CommonUtils.isNotEmpty(metadata) && CommonUtils.isNotEmpty(metadata['linkProcess'])) {
      showNonFieldPresentation = true;
    } else {
      showNonFieldPresentation = false;
    }
  }
  return showNonFieldPresentation;
}

export function getDataForDisplayAttribute(attributePresentation: any, detailsObjects: any, id: any) {
  let getData: any;
  let getDataForDisplayAttr: any = [];
  let getEntityDataFormDetailsObjects: any;
  let getdisplayAttribute: any = getDisplayAttributes(attributePresentation);
  let getEntityName = attributePresentation.attrName;
  let getDetailsObjectData = detailsObjects.filter((ele: any) => ele.id === id);
  if (CommonUtils.isNotEmpty(getDetailsObjectData[0][getEntityName])) {
    getEntityDataFormDetailsObjects = getDetailsObjectData[0][getEntityName];
  } else {
    getEntityDataFormDetailsObjects = getDetailsObjectData;
  }
  getEntityDataFormDetailsObjects.forEach((detalsObj: any) => {
    if (getdisplayAttribute instanceof Array) {
      if (getdisplayAttribute.length > 1) {
        getData = getdisplayAttribute
          .map((displayAtt: any) => detalsObj.hasOwnProperty(displayAtt) && detalsObj[displayAtt])
          .join('--');
        getDataForDisplayAttr.push(getData);
      } else {
        if (detalsObj.hasOwnProperty(getdisplayAttribute[0])) {
          getData = detalsObj[getdisplayAttribute[0]];
          getDataForDisplayAttr.push(getData);
        }
      }
    } else {
      if (detalsObj.hasOwnProperty(getdisplayAttribute)) {
        getData = detalsObj[getdisplayAttribute];
        getDataForDisplayAttr.push(getData);
      }
    }
  });
  return getDataForDisplayAttr;
}

export function getShowCalendarSummary(uiSettings: any) {
  let calendarEventsVisble: boolean = false;
  if (
    CommonUtils.isNotEmpty(uiSettings) &&
    CommonUtils.isNotEmpty(uiSettings['cardViewConfiguration']['showCalendarSummary'])
  ) {
    calendarEventsVisble = true;
  }
  return calendarEventsVisble;
}

export function isUiInputReadOnly(onStartData: any) {
  let readOnly: boolean = true;
  let mainPresentation: any = getMainEntityPresentationsFromUIResource(onStartData)[0];
  if (mainPresentation) {
    const attributePresentations: any[] = getAttributePresentations(mainPresentation);
    for (let attributePresenatation of Object.values(attributePresentations)) {
      if (!attributePresenatation.readOnly) {
        readOnly = false;
      }
    }
  }

  return readOnly;
}

export function isCustomField(onStartData: any) {
  let customField: any;
  let getAttributePresentations: any = onStartData.presentationRules;
  let getAttribute = getAttributePresentations['status'];
  let getAttdStatusId = getAttributePresentations['attdStatusId'];
  if (getAttribute) {
    if (
      CommonUtils.isNotEmpty(getAttribute['uiSettings']) &&
      CommonUtils.isNotEmpty(getAttribute['uiSettings']['customField'])
    ) {
      return true;
    } else if (getAttdStatusId) {
      let uiSettings: any = CommonUtils.isNotEmpty(getAttdStatusId['uiSettings']);
      if (uiSettings) {
        let customField: any = CommonUtils.isNotEmpty(getAttdStatusId['uiSettings']['customField']);
        if (customField) {
          let getEnityConsumed = getAttdStatusId.entityConsumed;
          return getEnityConsumed;
        }
      }
    }
  } else {
    return false;
  }
  if (customField) {
    let getEnityConsumed = getAttribute.entityConsumed;
    return getEnityConsumed;
  }
}

export function getAttrForCustomFiled(onStartData: any) {
  let customField: any;
  let getAttributePresentations: any = onStartData.presentationRules;
  let getAttribute = getAttributePresentations['status'];
  let getAttdStatusId = getAttributePresentations['attdStatusId'];
  if (getAttribute) {
    let uiSettings: any = CommonUtils.isNotEmpty(getAttribute['uiSettings']);
    if (uiSettings) {
      customField = CommonUtils.isNotEmpty(getAttribute['uiSettings']['customField']);
    } else if (getAttdStatusId) {
      let uiSettings: any = CommonUtils.isNotEmpty(getAttdStatusId['uiSettings']);
      if (uiSettings) {
        let customField: any = CommonUtils.isNotEmpty(getAttdStatusId['uiSettings']['customField']);
        if (customField) {
          let getEnityConsumed = getAttdStatusId.entityConsumed;
          return getEnityConsumed;
        }
      }
    }
    if (customField) {
      let getEnityConsumed = getAttribute.entityConsumed;
      return getEnityConsumed;
    }
  }
  // if (CommonUtils.isNotEmpty(getAttribute['uiSettings']) && CommonUtils.isNotEmpty(getAttribute['uiSettings']['customField'])) {
  //     let getEnityConsumed = getAttribute.entityConsumed;
  //     return getEnityConsumed
  // }
}

export function isLovTypeControl(htmlControl: string) {
  let lovControls: string[] = ['search', 'select', 'radio'];
  return lovControls.includes(htmlControl);
}

export function isFeedBackControl(uiSettings: any) {
  let isFeedBackControl: boolean = false;
  if (uiSettings && uiSettings['isFeedbackControl']) {
    isFeedBackControl = uiSettings['isFeedbackControl'];
  }
  return isFeedBackControl;
}

export function feedbackType(uiSettings: any) {
  let feedback: any;
  if (uiSettings && uiSettings['FeedbackType']) {
    feedback = uiSettings['FeedbackType'];
  }
  return feedback;
}

export function getOptionsData(attr: any) {
  let getOptions = CommonUtils.isNotEmpty(attr.options) ? attr.options : '';
  return getOptions;
}

export function isPivot(presentation: any) {
  let isPivot: boolean = false;
  let uiSettings: any = getUiSettings(presentation);
  if (uiSettings) {
    isPivot = uiSettings['defaultListView'] === 'pivot';
  }
  return isPivot;
}

export function isDefaultValuesPresent(attributePresentation: any) {
  return CommonUtils.isNotEmpty(attributePresentation) && CommonUtils.isNotEmpty(attributePresentation['defaultValue'])
    ? attributePresentation['defaultValue']
    : false;
}

export function getCammaSeperatedValue(value: any, countryCode?: any) {
  let convertingCurrenyFormat: any;
  if (CommonUtils.isNotEmpty(value)) {
    let getNumber =
      value !== 0 && value.toString().includes(',') ? value.toString().replaceAll(',', '') : value.toString();
    switch (countryCode) {
      case 'INR':
      case 'Indian Rupee':
      case 'Rupee':
      case 'rupee':
      case 'Rupees':
      case 'rupees':
        if(isNaN(getNumber)){
                    return "";
                  }
                  else if (getNumber !== 0 && getNumber.includes('.')) {
          let splitNumberwithdot = getNumber.split('.');
          let getNumberFormat = Number(splitNumberwithdot[0]).toLocaleString('en-IN');
          convertingCurrenyFormat = getNumberFormat + '.' + splitNumberwithdot[1];
        } else {
          convertingCurrenyFormat = Number(getNumber).toLocaleString('en-IN');
        }
        break;
      case 'USD':
      case 'Dollar':
      case 'dollar':
      case 'Dollars':
      case 'dollars':
      case '$':
      case 'CFA':
      case 'XAF':
      case 'GCD':
      case 'GBP':
        if (getNumber !== 0 && getNumber.includes('.')) {
          let splitNumberwithdot = getNumber.split('.');
          let getNumberFormat = Number(splitNumberwithdot[0]).toLocaleString('en-US');
          convertingCurrenyFormat = getNumberFormat + '.' + splitNumberwithdot[1];
        } else {
          convertingCurrenyFormat = Number(getNumber).toLocaleString('en-US');
        }
        break;
      default:
        if (getNumber !== 0 && getNumber.includes('.')) {
          let splitNumberwithdot = getNumber.split('.');
          let getNumberFormat = Number(splitNumberwithdot[0]).toLocaleString('en-IN');
          convertingCurrenyFormat = getNumberFormat + '.' + splitNumberwithdot[1];
        } else {
          convertingCurrenyFormat = Number(getNumber).toLocaleString('en-IN');
        }
    }
    return convertingCurrenyFormat;
  }
}

export function isMatrisAxisX(control: KagamiControlModel | any) {
  let matrixAxisX: boolean = false;
  if (control instanceof KagamiControlModel && CommonUtils.isNotEmpty(control.attributePresentation)) {
    matrixAxisX = control.attributePresentation.matrixAxis === 'x';
  } else {
    matrixAxisX = control.matrixAxis === 'x';
  }
  return matrixAxisX;
}

export function getPivotOnChangeAttr(attributePresenatation: any) {
  return attributePresenatation['pivotOnChangeAttr'];
}

export function getDisplayAttrForEmbedSearch(attributePresentation: any) {
  let entityConsumedControls = ['search', 'select', 'multiselect', 'radio'];
  let htmlControl = getHtmlControl(attributePresentation);
  let displayAttr: string;
  let attrName: string = getAttributeName(attributePresentation);
  if (entityConsumedControls.includes(htmlControl)) {
    let dropdownAttributes = getDropDownAttribute(attributePresentation);
    if (dropdownAttributes && dropdownAttributes.length > 0) {
      let entityConsumed: string = getEntityConsumedInAttribute(attributePresentation);
      displayAttr = entityConsumed + '.' + dropdownAttributes[0]['name'];
    } else {
      displayAttr = attrName;
    }
  } else {
    displayAttr = attrName;
  }
  return displayAttr;
}

export const getLocaleCodeCurrency = (currencySymbol: any) => {
  let currencyLocaleCode: string = '';
  let localeCodesAndCurrencySymbols = [
    { localeCode: 'fr-FR', currencyIcon: 'FRF' },
    { localeCode: 'fr-FR', currencyIcon: '₣' },
    { localeCode: 'fr-FR', currencyIcon: 'EUR' },
    { localeCode: 'fr-FR', currencyIcon: '€' },
    { localeCode: 'en-GB', currencyIcon: 'GBP' },
    { localeCode: 'en-GB', currencyIcon: '£' },
    { localeCode: 'en-GH', currencyIcon: 'GHS' },
    { localeCode: 'en-GH', currencyIcon: '¢' },
    { localeCode: 'en-IN', currencyIcon: 'Indian Rupee' },
    { localeCode: 'en-IN', currencyIcon: 'Rupees' },
    { localeCode: 'en-IN', currencyIcon: 'Rupee' },
    { localeCode: 'en-IN', currencyIcon: 'rupees' },
    { localeCode: 'en-IN', currencyIcon: 'rupee' },
    { localeCode: 'en-IN', currencyIcon: 'INR' },
    { localeCode: 'en-IN', currencyIcon: '₹' },
    { localeCode: 'en-IN', currencyIcon: '?' },
    { localeCode: 'en-US', currencyIcon: 'USD' },
    { localeCode: 'en-US', currencyIcon: 'Dollars' },
    { localeCode: 'en-US', currencyIcon: 'Dollar' },
    { localeCode: 'en-US', currencyIcon: 'dollars' },
    { localeCode: 'en-US', currencyIcon: 'dollar' },
    { localeCode: 'en-US', currencyIcon: '$' },  
    { localeCode: 'en-AE', currencyIcon: 'AED' },  
    { localeCode: 'en-BD', currencyIcon: 'BDT' },  
    { localeCode: 'en-MM', currencyIcon: 'MMK' },
    { localeCode: 'en-NG', currencyIcon: 'NGN' },
    { localeCode: 'en-NP', currencyIcon: 'NPR' },
    { localeCode: 'en-PH', currencyIcon: 'PHP' },
    { localeCode: 'en-IR', currencyIcon: 'IRR' },
    { localeCode: 'en-SR', currencyIcon: 'SRD' },  
    { localeCode: 'en-BR', currencyIcon: 'BRL' },  
    { localeCode: 'en-TR', currencyIcon: 'TRY' },  
  ];

  if (CommonUtils.isNotEmpty(currencySymbol)) {
    localeCodesAndCurrencySymbols.map((item: any) => {
      if (currencySymbol === item.currencyIcon) {
        currencyLocaleCode = item.localeCode;
      } else if (
        currencySymbol === 'XOF' ||
        currencySymbol === 'CFA' ||
        currencySymbol === 'F CFA' ||
        currencySymbol === 'XAF' ||
        currencySymbol === 'GNF' ||
        currencySymbol === 'CNF' || 
        currencySymbol === 'GCD'
      ) {
        currencyLocaleCode = 'en-US';
      }
    });
  } else {
    currencyLocaleCode = 'en-US';
  }
  if (currencyLocaleCode === '') {
    currencyLocaleCode = 'en-US';
  }
  return currencyLocaleCode;
};

export const differentCurrenyFormats = (value: string, differentCurrenyFormats?: string) => {
  let newValue: any;
  if (CommonUtils.isNotEmpty(differentCurrenyFormats) && CommonUtils.isNotEmpty(value)) {
    if (
      differentCurrenyFormats === '#,##,###.###' ||
      differentCurrenyFormats === '#,##,###.##' ||
      differentCurrenyFormats === '#,##,###'
    ) {
      let formattedValue = new Intl.NumberFormat('en-IN');
      newValue = String(formattedValue.format(Number(value)));
    } else if (
      differentCurrenyFormats === '# ###,###' ||
      differentCurrenyFormats === '# ###,##' ||
      differentCurrenyFormats === '# ###'
    ) {
      let formattedValue = new Intl.NumberFormat('fr-DE');
      newValue = String(formattedValue.format(Number(value)));
    } else if (
      differentCurrenyFormats === '#,###.###' ||
      differentCurrenyFormats === '#,###.##' ||
      differentCurrenyFormats === '#,###'
    ) {
      let formattedValue = new Intl.NumberFormat('en-US');
      newValue = String(formattedValue.format(Number(value)));
    } else if (
      differentCurrenyFormats === '# ###.###' ||
      differentCurrenyFormats === '# ###.##' ||
      differentCurrenyFormats === '# ###'
    ) {
      let formattedValue = new Intl.NumberFormat('ZW');
      let newData = String(formattedValue.format(Number(value)));
      if (newData.includes(',')) {
        newValue = newData.replaceAll(',', ' ');
      }
    } else if (
      differentCurrenyFormats === '#.###,###' ||
      differentCurrenyFormats === '#.###,##' ||
      differentCurrenyFormats === '#.###'
    ) {
      let formattedValue = new Intl.NumberFormat('nl-SR');
      newValue = String(formattedValue.format(Number(value)));
    }
  } else {
    let formattedValue = new Intl.NumberFormat('en-IN');
    newValue = String(formattedValue.format(Number(value)));
  }
  return decimalValueCheck(newValue, differentCurrenyFormats);
};

export const decimalValueCheck = (value: string, formatType: any) => {
  if (CommonUtils.isNotEmpty(value) && CommonUtils.isNotEmpty(formatType)) {
    if (formatType.includes('.')) {
      let indexOfDecimalFormat = formatType.lastIndexOf('.');
      if (indexOfDecimalFormat === -1) {
        return value;
      } else {
        let indexOfDecimalValue = value?.lastIndexOf('.');
        if (indexOfDecimalValue === -1) {
          return value;
        } else {
          let lengthOfDecimalFormat = formatType.length - 1 - indexOfDecimalFormat;
          let lengthOfDecimalValue = value?.length - 1 - indexOfDecimalValue;
          if (lengthOfDecimalValue >= lengthOfDecimalFormat) {
            return value;
          } else {
            let zeroAdd = lengthOfDecimalFormat - lengthOfDecimalValue;
            return value + '0'.repeat(zeroAdd);
          }
        }
      }
    } else if (formatType.includes(',')) {
      let indexOfDecimalFormat = formatType.lastIndexOf(',');
      if (indexOfDecimalFormat === -1) {
        return value;
      } else {
        let indexOfDecimalValue = value?.lastIndexOf(',');
        if (indexOfDecimalValue === -1) {
          return value;
        } else {
          let lengthOfDecimalFormat = formatType.length - 1 - indexOfDecimalFormat;
          let lengthOfDecimalValue = value?.length - 1 - indexOfDecimalValue;
          if (lengthOfDecimalValue >= lengthOfDecimalFormat) {
            return value;
          } else {
            let zeroAdd = lengthOfDecimalFormat - lengthOfDecimalValue;
            return value + '0'.repeat(zeroAdd);
          }
        }
      }
    }
  }
};

export const thousandsSeparator = (differentCurrenyFormats?: string) => {
  let newValue: any;
  if (CommonUtils.isNotEmpty(differentCurrenyFormats)) {
    if (
      differentCurrenyFormats === '#,##,###.###' ||
      differentCurrenyFormats === '#,##,###.##' ||
      differentCurrenyFormats === '#,##,###'
    ) {
      if (differentCurrenyFormats.includes(',')) {
        newValue = ',';
      }
    } else if (
      differentCurrenyFormats === '# ###,###' ||
      differentCurrenyFormats === '# ###,##' ||
      differentCurrenyFormats === '# ###'
    ) {
      if (differentCurrenyFormats.includes(' ')) {
        newValue = ' ';
      }
    } else if (
      differentCurrenyFormats === '#,###.###' ||
      differentCurrenyFormats === '#,###.##' ||
      differentCurrenyFormats === '#,###'
    ) {
      if (differentCurrenyFormats.includes(',')) {
        newValue = ',';
      }
    } else if (
      differentCurrenyFormats === '# ###.###' ||
      differentCurrenyFormats === '# ###.##' ||
      differentCurrenyFormats === '# ###'
    ) {
      if (differentCurrenyFormats.includes(' ')) {
        newValue = ' ';
      }
    } else if (
      differentCurrenyFormats === '#.###,###' ||
      differentCurrenyFormats === '#.###,##' ||
      differentCurrenyFormats === '#.###'
    ) {
      if (differentCurrenyFormats.includes('.')) {
        let newData = differentCurrenyFormats.replaceAll('.', ',');
        if (newData.includes(',')) {
          newValue = '.';
        }
      }
    }
  } else {
    newValue = ',';
  }
  return newValue;
};

export const decimalsSeparator = (differentCurrenyFormats?: string) => {
  let newValue: any;
  if (CommonUtils.isNotEmpty(differentCurrenyFormats)) {
    if (differentCurrenyFormats === '#,##,###.###' || differentCurrenyFormats === '#,##,###.##') {
      if (differentCurrenyFormats.includes('.')) {
        newValue = '.';
      }
    } else if (differentCurrenyFormats === '# ###,###' || differentCurrenyFormats === '# ###,##') {
      if (differentCurrenyFormats.includes(',')) {
        let newData = differentCurrenyFormats.replaceAll(',', '.');
        if (newData.includes('.')) {
          newValue = ',';
        }
      }
    } else if (differentCurrenyFormats === '#,###.###' || differentCurrenyFormats === '#,###.##') {
      if (differentCurrenyFormats.includes('.')) {
        newValue = '.';
      }
    } else if (differentCurrenyFormats === '# ###.###' || differentCurrenyFormats === '# ###.##') {
      if (differentCurrenyFormats.includes('.')) {
        newValue = '.';
      }
    } else if (differentCurrenyFormats === '#.###,###' || differentCurrenyFormats === '#.###,##') {
      if (differentCurrenyFormats.includes(',')) {
        let newData = differentCurrenyFormats.replaceAll(',', '.');
        if (newData.includes('.')) {
          newValue = ',';
        }
      }
    }
  }
  else {
    newValue = '.';
  }
  return newValue;
};

export const decimalScale = (differentCurrenyFormats?: string) => {
  let newDecimalScale: any;
  if (CommonUtils.isNotEmpty(differentCurrenyFormats)) {
    if (
      differentCurrenyFormats === '#,##,###.###' ||
      differentCurrenyFormats === '# ###,###' ||
      differentCurrenyFormats === '#,###.###' ||
      differentCurrenyFormats === '# ###.###' ||
      differentCurrenyFormats === '#.###,###'
    ) {
      newDecimalScale = 3;
    } else if (
      differentCurrenyFormats === '#,##,###.##' ||
      differentCurrenyFormats === '# ###,##' ||
      differentCurrenyFormats === '#,###.##' ||
      differentCurrenyFormats === '# ###.##' ||
      differentCurrenyFormats === '#.###,##'
    ) {
      newDecimalScale = 2;
    } else if (
      differentCurrenyFormats === '#,##,###' ||
      differentCurrenyFormats === '# ###' ||
      differentCurrenyFormats === '#,###' ||
      differentCurrenyFormats === '#.###'
    ) {
      newDecimalScale = 0;
    }
   
  } else {
    newDecimalScale = 3;
  }
  return newDecimalScale;
};
