import { createState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../constants/SystemConstants';
// import { KagamiNavigatorModel } from '../../../../navigator/model/KagamiNavigatorModel';

import { KagamiProfileModel } from '../model/KagamiProfileModel';
import { buildUserProfile } from '../ui/kagamiProfile';


   let profileModel : KagamiProfileModel;


   function setKagamiProfileModel(kagamiProfileModel : KagamiProfileModel){
    profileModel = kagamiProfileModel
   }
  export function buildProfile(kagamiProfileModel: KagamiProfileModel, translations?: any) { 
     setKagamiProfileModel(kagamiProfileModel);
    return buildUserProfile(kagamiProfileModel, translations);
  }

  export function showProfile(kagamiProfileModel: KagamiProfileModel) {
    let currentState = kagamiProfileModel.state.isOpen;

    if (!currentState) {
    }
    kagamiProfileModel.state.setOpen(!currentState);
  }

  export function hideProfile(kagamiProfileModel: KagamiProfileModel) {
    if (kagamiProfileModel.state.isOpen) {
      return kagamiProfileModel.state.setOpen(false);
    }
  }

  export function rebuildProfile(content :any,userDataForProfile:any
    // ,emailDataForProfile:any
    ) { 
    KagamiStateManager.getStateToHolder(SystemConstants.PROFILE_STATE).setContent(content);
    KagamiStateManager.getStateToHolder(SystemConstants.PROFILE_STATE).setuserDataForProfile(userDataForProfile);
    // KagamiStateManager.getStateToHolder(SystemConstants.PROFILE_STATE).setemailDataForProfile(emailDataForProfile);
  }

  export function getKagamiProfileModel(){
    return profileModel
  }
  
  export function getUserInfoFromProfileModel(){
    
    if(profileModel){
      return profileModel.userDataForProfile
    } else {
      return null
    }
  }