import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const NetworkDetectorStyle = (theme: Theme) =>
  createStyles({
    root: { 
        height: '0',
    },
    isDisconnected: {
        height:theme.spacing(4),
        display:' flex',
        width: '100%', 
        alignItems: 'center',
        background:theme.palette.error.main,
        marginTop: '0', 
        transition :'height 2s',
    },
    errorMessage:{  
        color: '#fff',
        margin: '0',
        padding: '0 10px'
    }
  });

 