import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { greyColor, whiteColor } from '../../../application/style/KagamiApplicationStyles';

export const KagamiErrorModalStyle = (theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      width: '100%',
      justifyContent: 'end'
    },
    errormodal_p1: {
      display: 'flex',
      width: '100%',
      paddingBottom: '8px'
    },
    errormodal_p2: {
      width: '10%',
      alignItems: 'start'
    },
    errormodal_p3: {
      width: '90%',
      paddingLeft: '10px'
    },
    errormodal_p4: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start'
    }
  });
