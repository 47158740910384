import { State, useState } from '@hookstate/core';
import { TriggerAlertModalModel } from '../../TriggerAlertModal/model/TriggerAlertModalModel';

export class KagamiTriggerModel {
  entityId: string;
  policy: any;
  processName: string;
  eventName: string;
  triggerName: string;
  confirmationNeeded: boolean;
  confirmationMsg:string;
  enableTrigger: boolean;

  triggerIcon: any;
  embeddedInList: boolean = false;
  triggerCallback: Function;
  contextType: ContextType;
  state: any;
  detailedObject: any;
  isDetailView : boolean;
  triggerAlertModalModel:TriggerAlertModalModel;
  constructor(
    entityId: string,
    processName: string,
    eventName: string,
    triggerName: string,
    confirmationNeeded: boolean,
    confirmationMsg:string,
    contextType: ContextType,
    triggerCallback: Function
  ) {
    this.entityId = entityId;
    this.processName = processName;
    this.eventName = eventName;
    this.triggerName = triggerName;
    this.confirmationNeeded = confirmationNeeded;
    this.confirmationMsg = confirmationMsg;
    this.contextType = contextType;
    this.triggerCallback = triggerCallback;
    this.enableTrigger = false;
    this.isDetailView = false;
    this.triggerAlertModalModel = new TriggerAlertModalModel(processName);
  }
}

export enum ContextType {
  'None',
  'Single',
  'Mutli'
}

export interface TriggerModelProps {
  kagamiTriggerModel: KagamiTriggerModel;
}

export const wrapKagamiTriggerState = (state: State<KagamiTriggerModel>) => {
  let triggerState = state;

  return {
    enableTrigger() {
      return triggerState.enableTrigger.get();
    },

    setEnableTrigger(enableTrigger: boolean) {
      triggerState.enableTrigger.set(enableTrigger);
    }
  };
};

export const useKagamiTriggerState = (state: State<KagamiTriggerModel>) => wrapKagamiTriggerState(useState(state));
