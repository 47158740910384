import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../../../../../../../state/KagamiStateManager';
import { ContextType, KagamiTriggerModel } from '../../../../../../../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../../../model/KagamiEmbeddedFormPresentationModel';

export class KagamiEmbedTriggerModel extends KagamiTriggerModel {
  embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel;
  embeddedModal: any;
  embeddedData: any;
  addRecordIdToPresentationModel?: any;
  constructor(
    embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel,
    processName: string,
    eventName: string,
    triggerName: string,
    confirmationNeeded: boolean,
    confirmationMsg: string,
    contextType: ContextType,
    triggerCallback: Function
  ) {
    super(
      embeddedFormPresentationModel.formEntityId,
      processName,
      eventName,
      triggerName,
      confirmationNeeded,
      confirmationMsg,
      contextType,
      triggerCallback
    );
    this.embeddedFormPresentationModel = embeddedFormPresentationModel;
    this.processName = processName;
    this.eventName = eventName;
    this.triggerName = triggerName;
    this.confirmationNeeded = confirmationNeeded;
    this.confirmationMsg = confirmationMsg;
    this.contextType = contextType;
    this.triggerCallback = triggerCallback;
    this.enableTrigger = false;
    this.embeddedModal = null;
    this.embeddedData = {};
    this.addRecordIdToPresentationModel = null;
    this.state = null;
  }
}

export interface EmbedTriggerModelProps {
  embeddedTriggerModel: KagamiEmbedTriggerModel;
}

export const wrapKagamiEmbedGridState = (state: State<KagamiEmbedTriggerModel>) => {
   let embedTriggerState = state;
   return {
     get enableTrigger(){
        return embedTriggerState.enableTrigger.get();
     },
     setEnableTrigger(isVisible : boolean){
       embedTriggerState.enableTrigger.set(isVisible);
     }
   }
 
}

export const useKagamiEmbedTriggerState = (state: State<KagamiEmbedTriggerModel>,triggerIdentifier : string) => {
  const kagamiEmbedTriggerState = wrapKagamiEmbedGridState(useState(state));
  KagamiStateManager.addStateToHolder(triggerIdentifier, kagamiEmbedTriggerState);
  return kagamiEmbedTriggerState;
};
