import { SystemConstants } from '../../../../constants/SystemConstants';
import { APIService } from '../../../../service/ApiService';
import {
  getbpmbulkmetadataURL,
  getbpmmetadataURL,
  getBulkUploadUrl,
  getExcelSheetHeaders
} from '../../../../service/URLService';

export async function getMetaData(data: any) {
  return await APIService.getData(SystemConstants.POST, getbpmbulkmetadataURL(), data);
}

export function getOrderedEntityIdList() {
  return APIService.getData(SystemConstants.GET, getbpmmetadataURL(), {});
}

export function getBulkUploadList(data: any) {
  return APIService.getData(SystemConstants.POST, getBulkUploadUrl(), data);
}

export function getExcelHeadersList(docId: string, eventName: string, processName: string) {
  return APIService.getData(SystemConstants.GET, getExcelSheetHeaders(docId, eventName, processName), {});
}
