import { makeStyles } from '@mui/styles';
import { DashboardReportsProps } from '../model/DashboardReportsModel';
import { DashboardReportsStyle } from '../style/DashboardReports';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import LoopIcon from '@mui/icons-material/Loop';
import expandIcon from '../../../../../../../../../assets/images/expandIcon.png';
import { buildModal } from '../../../../../../../../../components/modal/controller/KagamiModalController';

const useStyles:any = makeStyles(DashboardReportsStyle);

export const DashboardReportHeader = (props: DashboardReportsProps) => {
  const classes = useStyles();
  let isFilterIconVisible: boolean = false;

  if (props.dashboardReportsModel.content.length) {
    let reportModel: any = props.dashboardReportsModel.reportModel;
    reportModel.reportConfig.dynamicParameterList.forEach((filterConfig: any) => {
      if (!filterConfig.isuser) {
        isFilterIconVisible = true;
      }
    });
  }

  const handleOnExpandableButtonClick = () => {
    props.dashboardReportsModel.modal.title = props.dashboardReportsModel.name;
    props.dashboardReportsModel.modal.modalWidth = 'xl';
    props.dashboardReportsModel.modal.content = props.dashboardReportsModel.content;
    props.dashboardReportsModel.modal.state.setOpen(true);
  };

  return (
    <>
      <div className={`${classes.dashboard_header} `}>
        <div className='dashboard-handle-drag drag-handle'>
          <span className={classes.dashboard_header_report_name}>{props.dashboardReportsModel.name}</span>
        </div>
        <div className='handle-filter-and-popup-icon'>
          {/* <span className={`${classes.expandedButton} VisibleExpandedButton`} onClick={() => {}}>
            <LoopIcon sx={{ color: '#2d72b9', fontSize: '1.2rem', fontWeight: 600 }} />
          </span> */}

          <span className={`${classes.expandedButton} VisibleExpandedButton`} onClick={handleOnExpandableButtonClick}>
            <img src={expandIcon} className={classes.expandedIcon} />
          </span>
          {isFilterIconVisible ? (
            <span
              className={classes.filterIcon}
              onClick={() => {
                props.dashboardReportsModel.state.setDrawerOpenDialog(true);
              }}
            >
              <FilterAltIcon sx={{ color: '#2d72b9', fontSize: '1.2rem' }} />
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      {buildModal(props.dashboardReportsModel.modal)}
    </>
  );
};
