import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';

export class NumberValidator {
  static errorText: any;

  static validate(validations: any, value: number) {
    if (CommonUtils.isNotEmpty(validations) && Object.keys(validations).length !== 0) {
      NumberValidator.errorText = null;
      for (var k in validations) {
        switch (k) {
          case 'precision':
              NumberValidator.precisionValueValidation(value, validations[k]);
              if (NumberValidator.errorText != null) {
                return NumberValidator.errorText;
            }
            break;
          case 'notNull':
            if (validations[k] == true) {
              NumberValidator.notNullValidation(value);
              if (NumberValidator.errorText != null) {
                return NumberValidator.errorText;
              }
            }
            break;
          case 'length':
            NumberValidator.lengthValidation(value, validations[k]);
            if (NumberValidator.errorText != null) {
              return NumberValidator.errorText;
            }

            break;
        }
      }
    }
    return null;
  }

  static notNullValidation(value: number) {
    CommonUtils.isNotEmpty(value)
      ? (NumberValidator.errorText = null)
      : (NumberValidator.errorText = 'This field is mandatory');
  }

  static precisionValueValidation(value: any, map: any) {
    if (CommonUtils.isNotEmpty(value)) {
      for (var k of Object.keys(map)) {
        switch (k) {
          case 'afterDecimal':
            if(CommonUtils.isNotEmpty(value)){
              if(value.toString().includes('.')){
                let errorValueData = value.toString().split('.')[1];
                if(errorValueData.length !== parseInt(map[k]) && !(errorValueData.length < parseInt(map[k]))){
                  NumberValidator.errorText = 'This field must have of '+ map[k] + 'precision afterDecimal';
                }else if(errorValueData.length < parseInt(map[k])){
                  NumberValidator.errorText = null;
                }
              }else{
                NumberValidator.errorText = null;
              }
            }
            break;
          default:
            break;
        }
        if (NumberValidator.errorText != null) {
          break;
        }
      }
    }
  }

  static lengthValidation(value: any, map: any) {
    if (CommonUtils.isNotEmpty(value)) {
      for (var k of Object.keys(map)) {
        switch (k) {
          case 'min':
            if (value.toString().length < parseInt(map[k])) {
              NumberValidator.errorText = 'This field must have minimum of ' + map[k] + ' characters';
              break;
            }
            break;

          case 'max':
            if (value.toString().length > parseInt(map[k])) {
              NumberValidator.errorText = 'This field can have maximum of ' + map[k] + ' characters';
              break;
            }
            break;

          default:
            break;
        }
        if (NumberValidator.errorText != null) {
          break;
        }
      }
    }
  }
}
