import { State, useState } from '@hookstate/core';
import { ReportFilterModel } from '../../../model/KagamiReportFilterModel';

export class ReportDateTimeFilterModel {
  filterConfig: any;
  filterName: string;
  filterRelationName: string;
  reportFilterModel: ReportFilterModel;

  fromCalendar:boolean;
  toCalendar:boolean;
  state: any;
  isFromDateTimeChangedManually:boolean;
  translations: any;
  constructor(
    filterConfig: any,
    filterName: string,
    filterRelationName: string,
    kagamiReportFilterModel: ReportFilterModel
  ) {
    this.filterConfig = filterConfig;
    this.reportFilterModel = kagamiReportFilterModel;
    this.filterName = filterName;
    this.fromCalendar = false;
    this.toCalendar = false;
    this.filterRelationName = filterRelationName;
    this.isFromDateTimeChangedManually=false;
  }
}

export interface ReportDateTimeFilterModelProps {
  reportDateTimeFilterModel: ReportDateTimeFilterModel;
  translations?: any;
}

export const useReportDateTimeModelState = (state:State<ReportDateTimeFilterModel>) => {
  const dateTimeFilterState = useState(state);
  return {
get fromCalendar(){
      return dateTimeFilterState.fromCalendar.get();
    },

    setFromCalendar(fromCalendar:boolean){
      dateTimeFilterState.fromCalendar.set(fromCalendar);
    },

    get toCalendar(){
      return dateTimeFilterState.toCalendar.get();
    },

    setToCalendar(toCalendar:boolean){
      dateTimeFilterState.toCalendar.set(toCalendar);
    },
  }
}