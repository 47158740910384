import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  extractDropdownOption,
  getPresenationIdFromPresentation
} from '../../../../../../service/PresentationService';
import Autocomplete from '@mui/material/Autocomplete';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getLovRecodsIfAny, getValueForGridSearch, getValueForGridMultiSelect, setActiveRowNode } from '../../service/GridService';
import TextField from '@mui/material/TextField';
import { cellRendererCustomComponentsStyle } from '../../style/CellRendererCustomComponents';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import { onSearchCall } from '../../../../../controls/select/controller/kagamiSelectController';
import { ListBox } from '../../../../../controls/select/ui/SearchField';
import { KagamiStateManager } from '../../../../../../../../../../../../../state/KagamiStateManager';
import { getFormModel } from '../../../../../form/controller/KagamiFormController';
import { getActiveFormRecord } from '../../../../../form/service/FormService';
import { KagamiFormModel } from '../../../../../form/model/KagamiFormModel';
export default forwardRef((props: any, ref: any) => {
  setActiveRowNode(props,props.gridModel)
  let selectedValues = getValueForGridMultiSelect(props.gridModel, props.attributePresentation);
  let setnewHeight = props.setnewHeight;
  const [value, setValue] = useState<any>(selectedValues);
  const [options,setOptions] = useState<any>(props.options)
  KagamiStateManager.addStateToHolder(props.gridModel.mainEntityId+'.'+props.gridMultiSelectModel.attributeName,setOptions);
  let newValuesHeight = 25 * value.length + 40;
  setnewHeight(value.length >= 1 ? newValuesHeight : 60);
  let classes = makeStyles(cellRendererCustomComponentsStyle)();
  let searchValue = getValueForGridSearch(props.gridModel, props.attributePresentation);

  // const [inputValue,setInputValue] = useState(CommonUtils.isNotEmpty(searchValue) ? searchValue : '');
  const debounceOnChange = React.useCallback(CommonUtils._debounceFtn(onChange, 700), []);
  function onChange(searchString: string) {
    props.gridMultiSelectModel.pageNumber = 1;
    props.searchString.searchValue = searchString;
    let presentationId = getPresenationIdFromPresentation(props.gridModel.presentation)
    onSearchCall(
      props.gridMultiSelectModel,
      searchString,
      false,
      presentationId
    );
  }

  function onChangeHandler(event: any, changedValue: any, reason: string) {
    let currentSelectedValues: any[] = [];
    for (var selectedValue of changedValue) {
      currentSelectedValues.push(selectedValue);
    }
    let values = changedValue;
    if (value.length !== 0 && reason === 'removeOption') {
      let previousValues = value.map((item: any) => item.id);
      let selectedValues = values.map((item: any) => item.id);
      for (var itemId of previousValues) {
        if (!selectedValues.includes(itemId)) {
          values.push({ id: 'removed_' + itemId });
        }
      }
    } else if (reason === 'clear') {
      let previousValues = value.map((item: any) => item.id);
      for (var itemId of previousValues) {
        values.push({ id: 'removed_' + itemId });
      }
    }
    setValue(currentSelectedValues);
    if(CommonUtils.isEmpty(props.gridModel.activeRowNode)){
      props.gridModel.activeRowNode = props.node;
    }
    props.gridModel.activeRowNode.data[props.column.colId] = values;
    props.onCellValueChange(
      props.gridModel,
      props.gridModel.activeRowNode,
      props.attributePresentation.onChangeRequired,
      props.column.colId
    );
  }

  function handleAsyncSearch() {
    console.log("handle async search");
    if(props.gridMultiSelectModel.verbProperties === undefined){
      props.gridMultiSelectModel.pageNumber = +props.gridMultiSelectModel.pageNumber+1;
      let presentationId = getPresenationIdFromPresentation(props.gridModel.presentation)
      onSearchCall(
        props.gridMultiSelectModel,
        props.searchString.searchValue,
        true,
        presentationId
      );
    }else if(options.length < props.gridMultiSelectModel.verbProperties.totalRecords){
      props.gridMultiSelectModel.pageNumber = +props.gridMultiSelectModel.pageNumber+1;
      let presentationId = getPresenationIdFromPresentation(props.gridModel.presentation)
      onSearchCall(
        props.gridMultiSelectModel,
        props.searchString.searchValue,
        true,
        presentationId
      );
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => (CommonUtils.isNotEmpty(value) ? value : []),
      afterGuiAttached: () => setValue(getValueForGridMultiSelect(props.gridModel, props.attributePresentation)),
      refreshEditor:()=>{
        /// Note : assigning value to editor and updating it in form data
        let formModel : KagamiFormModel = getFormModel();
       let activeRecord = getActiveFormRecord(formModel,props.gridModel.mainEntityId)
       let values : any[] = []
      if(CommonUtils.isNotEmpty(props.data[props.attributePresentation.attrName])){
          activeRecord[props.attributePresentation.attrName] = props.data[props.attributePresentation.attrName]
          values = activeRecord[props.attributePresentation.attrName] 
        }
        setValue(values);
      }

    };
  });
  const MyChip = (props: any) => {
    return <Chip {...props} />;
  };

  return (
    <Autocomplete
      options={CommonUtils.isNotEmpty(options) ? options : getLovRecodsIfAny(props.entityConsumed)}
      multiple
      classes={{
        root: classes.kgm_multiselecteditor,
        popper: classes.kgmSearchOptions
      }}
      noOptionsText = 'no options'
      forcePopupIcon= {true}
      isOptionEqualToValue={(option, value) => (value === '' ? true : option && value ? option.id === value.id : false)}
      freeSolo={CommonUtils.isNotEmpty(options) ? true : false}
      value={value}
      onChange={(event: any, changedValue: any, reason: string) => {
        onChangeHandler(event, changedValue, reason);
      }}
      onBlur = {()=>{
        if(props.searchString.searchValue !== ''){
          onChange('');
        }
      }} 
      getOptionLabel={(option) => extractDropdownOption(props.attributePresentation, option)}
      disableClearable
      filterSelectedOptions
      // renderTags={(tagValue, getTagProps) => {
      //   tagValue = value?.filter((item:any) => !item?.id?.includes("remove"))
      //   return tagValue.map((option, index) => {
      //     if (index < 2) {
      //       return (
      //         <MyChip
      //           {...getTagProps({ index })}
      //           label={extractDropdownOption(props.attributePresentation,option)}
      //         />
      //       );
      //     }
      //     if (tagvalue.length > 2 && tagvalue.length - 1 === index) {
      //       return (
      //         <Chip
      //           label={`+${tagvalue.length - 2} more`}
      //           style={{ marginLeft: '10px', cursor: 'pointer' }}
      //           onClick={moreChipHandler}
      //         />
      //       );
      //     }
      //   });
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => debounceOnChange(e.target.value)}
          // style={{ padding: '5px 0' }}
        />
      )}
      // ListboxProps={{
      //   onScroll: (event: React.SyntheticEvent) => {
      //     const listboxNode = event.currentTarget;
      //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
      //      handleAsyncSearch();
      //     }
      //   }
      // }}
      // ListboxComponent={ListBox}
    />
  );
});
