import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class KPIReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
  }
}
export interface KPIReportModelProps {
  kpiReportModel: KPIReportModel;
}
export const useKPIReportModelState = (state: State<KPIReportModel>) => {
  let kpiReportState = useState(state);
  return {
    reportConfig() {
      kpiReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      kpiReportState.reportData.set(reportContent);
    },

    get isDataLoaded(){
      return kpiReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      kpiReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
