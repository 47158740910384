import { State, useState } from '@hookstate/core';
import { ReportFilterModel } from '../../../model/KagamiReportFilterModel';

export class ReportNumberFilterModel {
  reportData: any;
  state: any;
  dependentValues: any;
  filterName: string;
  filterRelationName: string;
  reportFilterModel: ReportFilterModel;
  filterValues: any;
  filterConfig: any;
  filterModels: any;
  min:string;
  max:string;
  pageNo:number;
  translations: any;

  constructor(
    reportContent: any,
    filterName: string,
    filterRelationName: string,
    reportFilterModel: ReportFilterModel,
    filterConfig: any
  ) {
    this.reportData = reportContent;
    this.state = null;
    this.dependentValues = this.dependentValues;
    this.filterName = filterName;
    this.filterRelationName = filterRelationName;
    this.reportFilterModel = reportFilterModel;
    this.filterValues = [];
    this.filterConfig = filterConfig;
    this.filterModels = new Map();
    this.min = '0';
    this.max = '0';
    this.pageNo = 0;
  }
}
export interface ReportNumberFilterModelProps {
    reportNumberFilterModel: ReportNumberFilterModel;
    translations: any
}
export const wrapReportNumberFilterModelState = (state: State<ReportNumberFilterModel>) => {
  let numberFilterState = state;

  return {
    get filterValues() {
      return numberFilterState.filterValues.get();
    },
    setFilterValues(filterValues: any) {
      numberFilterState.filterValues.set(filterValues);
    },
    get getMin() {
      return numberFilterState.min.get();
    },
    setMin(min: string) {
      numberFilterState.min.set(min);
    },
    get getMax() {
      return numberFilterState.max.get();
    },
    setMax(max: string) {
      numberFilterState.max.set(max);
    }
  };
};
export const useReportNumberFilterState:any = (state: State<ReportNumberFilterModel>) =>
  wrapReportNumberFilterModelState(useState(state));
