import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { logout } from '../../../../../../../../login/controller/KagamiLoginController';
import { convertToSha256 } from '../../../../../../../../login/service/LoginService';
import { buildtoastNotification } from '../../../../../../ToastNotification/controller/ToastNotificationController';
import { changeUpdatePasswordUser } from '../../../service/updatePasswordService';
import { showServerError } from '../../form/controller/KagamiFormController';
import { KagamiUpdatePasswordModel } from '../model/updatePasswordModel';
import { buildKagamiUpdatePassword } from '../ui/UpdatePassword';

let passwordModel: KagamiUpdatePasswordModel;

export const buildUpdatePasswordBuilder = (
  KagamiUpdatePasswordModel: KagamiUpdatePasswordModel,
  popupModel: KagamiModalModel
) => {
  passwordModel = KagamiUpdatePasswordModel;
  return buildKagamiUpdatePassword(KagamiUpdatePasswordModel, popupModel);
};

export function getPasswordModel() {
  return passwordModel;
}

export function setPasswordModel(KagamiUpdatePasswordModel: KagamiUpdatePasswordModel) {
  passwordModel = KagamiUpdatePasswordModel;
}

export const handleUpdate = () => {
  let profileState = KagamiStateManager.getStateToHolder(SystemConstants.PROFILE_STATE);
  let updatePasswordState = KagamiStateManager.getStateToHolder(SystemConstants.UPDATEPASSWORD_STATE);
  let passwordModel = getPasswordModel();

  updatePasswordState?.setErrors({});
  let errors: any = {};
  if (
    updatePasswordState.currentPassword &&
    updatePasswordState.newPassword &&
    updatePasswordState.confirmNewPassword
  ) {
    let loginData: any = localStorage.getItem('login-data');
    let getPassWord = JSON.parse(loginData).password;
    if (convertToSha256(updatePasswordState.currentPassword) !== getPassWord) {
      errors['passwordmismatch'] = 'current password is not matching with actual password';
      updatePasswordState.setErrors(errors);
    } else {
      let data = {
        password: convertToSha256(updatePasswordState.currentPassword),
        newPassword: convertToSha256(updatePasswordState.newPassword),
        userId: profileState.userDataForProfile.userId
      };
      changeUpdatePasswordUser(data)
        .then(() => {
          buildtoastNotification('success', 'Password changed Successfully, you will be redirected to login', 0);
          passwordModel.kagamiModalModel.state.setOpen(false);
          setTimeout(() => {
            logout();
          }, 2000);
        })
        .catch((error) => showServerError(error));
    }
  }
};
