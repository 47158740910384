import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { getKagamiGridModel } from '../../../../list/builder/grid/controller/KagamiGridController';
import { handleTriggerClick } from '../../../../list/controller/KagamiListController';
import { KagamiTriggerModel } from '../model/KagamiTriggerModel';
import { buildTrigger } from '../ui/KagamiTrigger';

export class KagamiTriggerController {
  static buildTrigger(triggerModel: KagamiTriggerModel) {
    return buildTrigger(triggerModel);
  }
  //case hyperlink
  static handleHyperLinkClick () { 
    let kagamiGridModel = getKagamiGridModel() ;
        if(CommonUtils.isNotEmpty(kagamiGridModel) && kagamiGridModel.hasHyperlink){
          let triggerModel = kagamiGridModel.triggersModel.triggerControls["HYPERLINK"] ||  kagamiGridModel.triggersModel.triggerControls["hyperlink"]
          handleTriggerClick(triggerModel)
        }

}
}
