import { State, useState } from "@hookstate/core";

export class CustomPrintModel{
    docDefinition:any;
    mainDocDefinition:any;
    pdfUrl:any;
    reportConfig:any;
    reportModel:any;
    configInfo:any;
    state:any;
    reportHeaderObject:any;
    constructor(){

    }
}

export interface CustomPrintModelProps{
    customPrintModel:CustomPrintModel;
}

export const useCustomPrintState = (state:State<CustomPrintModel>) => {
    const customPrintState = useState(state);
    return {
        get getPdfUrl(){
            return customPrintState.pdfUrl.get();
        },
        setPdfUrl(pdfUrl:string){
            customPrintState.pdfUrl.set(pdfUrl);
        }
    }
}