import { createState } from '@hookstate/core';
import { EmbedAccordionPresentations, EmbedTabPresentation } from '../builder/KagamiEmbeddedPresentationBuilder';
import {
  KagamiEmbeddedPresentationModel,
  KagamiEmbeddedPresentationProps,
  useKagamiEmbedState
} from '../model/KagamiEmbeddedPresentationModel';

export const buildEmbeddedPresentationComponent = (
  kagamiEmbeddedPresentationModel: KagamiEmbeddedPresentationModel
) => {
  return (
    <KagamiEmbeddedPresentation
      key={kagamiEmbeddedPresentationModel.processStepName}
      embeddedPresentationModel={kagamiEmbeddedPresentationModel}
    />
  );
};

const KagamiEmbeddedPresentation = (props: KagamiEmbeddedPresentationProps) => {
  let embedState = useKagamiEmbedState(createState(props.embeddedPresentationModel),props.embeddedPresentationModel.embedViewId);
  props.embeddedPresentationModel.state = embedState;
  if(embedState.isVisible){
    if (props.embeddedPresentationModel.embeddedView === 'accordion') {
      return <EmbedAccordionPresentations embedFormModels={props.embeddedPresentationModel.embeddedFormModels} />;
    } else if (props.embeddedPresentationModel.embeddedView === 'tab') {
      return <EmbedTabPresentation embedPresentationModel={props.embeddedPresentationModel} />;
    } else {
      return <div style={{paddingLeft: '8px'}}>Not implemented yet</div>;
    }
  }
  else{
   return <div></div>
  }
  
 
};
