import { SystemConstants } from '../../../../../../../../constants/SystemConstants';
import { getLandingPageModel } from '../../landingPage/controller/ReportsLandingPageController';
import { executeReportWithOrWithoutFilter } from '../../view/report/builder/KagamiReportBuilder';
import { getReportModel } from '../../view/report/controller/KagamiReportController';
import { getFilterData } from '../../view/report/service/KagamiReportService';
import { ReportFilterModel } from '../model/KagamiReportFilterModel';
import { buildReportFilterComponent } from '../ui/ReportFilter';

let kagamiReportFilterModel: ReportFilterModel;

export const buildReportFilter = (
  reportFilterModel: ReportFilterModel,
  fromDashboard: boolean = false,
  translations?: any
) => {
  kagamiReportFilterModel = reportFilterModel;
  kagamiReportFilterModel.fromDashboard = fromDashboard;
  kagamiReportFilterModel.translation = translations;
  return buildReportFilterComponent(reportFilterModel, kagamiReportFilterModel.translation);
};

export function handleOnOkClick() {
  getReportModel().reportFilterModel = kagamiReportFilterModel;
  executeReportWithOrWithoutFilter(getReportModel()).then((content: any) => {
    getLandingPageModel().content = content;
    getLandingPageModel().isContentLoaded = true;
    getLandingPageModel().state.setDataLoaded(true);
  });
}

export function handleOnCloseClick() {
  // renderReportPage();
  // let landingPageModel = getLandingPageModel();
  // renderReport(buildReportLandingPageController(landingPageModel))
  // getLandingPageModel().state.setDataLoaded(true);
}

export function getReportFilterModel() {
  return kagamiReportFilterModel;
}

export const onTextBoxTap = async (filterConfig: any, textFilterModel: any, targetValue: any) => {
  const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
  let userId = null;
  if (loginData !== null) {
    let loginObject = JSON.parse(loginData);
    userId = loginObject.userId;
  }

  if (filterConfig['dependentValues']) {
    let reportModel = getReportModel();
    let reportId = reportModel.reportConfig['reportConfigSummary']['id'];
    let dependentValues = filterConfig['dependentValues'].map((element: any) => {
      return {
        tableName: element.tableName,
        columnName: element.columnName,
        value:
          textFilterModel.reportFilterModel.selectedValue.get(element.columnName) === 'NOPOP'
            ? ''
            : textFilterModel.reportFilterModel.selectedValue.get(element.columnName)
      };
    });

    let searchValue = [{ columnName: filterConfig['name'], searchValue: targetValue ?? '' }];
    let requestData = {
      reportId: reportId,
      pageNo: textFilterModel.pageNo,
      userId: userId,
      dependentValues: dependentValues,
      searchValue: searchValue
    };
    let filterDataResponse = await getFilterData(reportId, requestData);
    let filtersData = filterDataResponse.data.respObject;
    for (let [filterName, filterValues] of Object.entries(filtersData)) {
      let filterModel = textFilterModel.reportFilterModel.filterModels.get(filterName);
      // filterModel.filterValues = filterModel.filterValues.concat(filterValues);
      if (filterModel) {
        // filterModel.state.setFilterValues(filterValues);
        // filterModel.state.setFilterValues(filterModel.filterValues);
        if (textFilterModel.pageNo !== 0) {
          filterModel.filterValues = filterModel.filterValues.concat(filterValues);
          filterModel.state.setFilterValues(filterModel.filterValues);
        } else {
          filterModel.filterValues = filterValues;
          filterModel.state.setFilterValues(filterValues);
        }
      }
    }
    console.log(filterDataResponse);
  } else {
    let reportId = textFilterModel.reportFilterModel.reportId;
    let searchValue = [{ columnName: textFilterModel.filterConfig['name'], searchValue: targetValue ?? '' }];
    let requestData = { reportId: reportId, pageNo: textFilterModel.pageNo, userId: userId, searchValue: searchValue };
    let filterDataResponse = await getFilterData(reportId, requestData);
    let filtersData = filterDataResponse.data.respObject;
    for (let [filterName, filterValues] of Object.entries(filtersData)) {
      let filterModel = textFilterModel.reportFilterModel.filterModels.get(filterName);
      if (filterModel) {
        if (textFilterModel.pageNo !== 0) {
          filterModel.filterValues = filterModel.filterValues.concat(filterValues);
          filterModel.state.setFilterValues(filterModel.filterValues);
        } else {
          filterModel.filterValues = filterValues;
          filterModel.state.setFilterValues(filterValues);
        }
      }
    }
  }
};
