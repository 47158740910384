
import './App.scss';
import { useEffect } from 'react';
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { KagamiLoaderModel } from './components/loader/model/KagamiLoaderModel';
import { KagamiApplicationController } from './application1/controller/KagamiApplicationController';
import { KagamiApplicationModel } from './application/model/KagamiApplicationModel';
import { renderApplication } from './application/controller/KagamiApplicationController';
import { kagamiAppTheme } from './application/style/KagamiApplicationTheme';
import { ENV_DOMAIN } from './application/service/AppURL';



declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}
 const App = () => {
  useEffect(() => {
    document.title = ENV_DOMAIN;
}, []);

  let applicationModel: KagamiApplicationModel = new KagamiApplicationModel();
  if (KagamiApplicationController.getAppplicationModel() !== undefined) {
    applicationModel = KagamiApplicationController.getAppplicationModel();
  } else {
    applicationModel = new KagamiApplicationModel();
  }
  const loaderModel = new KagamiLoaderModel();
  applicationModel.loaderModel = loaderModel;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={kagamiAppTheme}>
        {renderApplication(applicationModel)} <CssBaseline />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
//  const App =   NetworkDetector(WrappedApp);

 export default App