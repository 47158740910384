import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useKagamiStepForm } from '../model/KagamiStepPresentationModel';
import { KagamiStepPresentationModel, KagamiStepPresentationProps } from '../model/KagamiStepPresentationModel';
import { EmbedStepsContent } from '../builder/embedded-tabs-presentation/builder/KagamiEmbedTabsBuilder';
import { buildFormPresentation } from '../../form-presenation/controller/KagamiFormPresentationController';
import { styled } from '@mui/material/styles';
import { makeOnLoadCall } from '../../../../../service/ProcessService';
import {
  getRecordId,
  updateInFocusMap,
  _getChangeForDetail,
  _getUnSyncedFormRecord
} from '../../../service/FormService';
import { KagamiFormModel } from '../../../model/KagamiFormModel';
import { getFormModel, handleFormDataChange } from '../../../controller/KagamiFormController';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { KagamiEmbeddedFormPresentationModel } from '../../../model/KagamiEmbeddedFormPresentationModel';
import { KagamiStepStyle } from '../style/kagamiStepStyle';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { KagamiFormPresentationModel } from '../../form-presenation/model/KagamiFormPresentationModel';
import { translateToLocalistaion } from '../../../../../service/PresentationService';
import { getActiveModel } from '../../../../../controller/KagamiProcessController';

export const buildStepPresentationComponent = (stepPresentationModel: KagamiStepPresentationModel) => {
  return <KagamiStepPresentation stepPresentationModel={stepPresentationModel} />;
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

export const StepFormButtons = (props: any) => {
  let kagamiFormModel = getFormModel();
  const { stepFormState, stepFormModel, loadFormData } = props;
  const classes = makeStyles(KagamiStepStyle)();

  const previousHandler = () => {
    if (stepFormState.getActiveTabIndex > 0) {
      stepFormState.setActiveTabIndex(stepFormState.getActiveTabIndex - 1);
      loadFormData(stepFormModel[stepFormState?.getActiveTabIndex]);
    }
  };

  const nextHandler = () => {
    if (stepFormState.getActiveTabIndex < stepFormModel.length - 1)
      stepFormState.setActiveTabIndex(stepFormState.getActiveTabIndex + 1);
    loadFormData(stepFormModel[stepFormState?.getActiveTabIndex]);
  };
  return (
    <div className={classes.buttonsRoot}>
      {stepFormState.getActiveTabIndex !== 0 && (
        <ArrowCircleLeftOutlinedIcon
          className={classes.leftButton}
          onClick={previousHandler}
          color="primary"
          fontSize="large"
        />
      )}

      {stepFormState.getActiveTabIndex < stepFormModel.length - 1 && (
        <ArrowCircleRightOutlinedIcon
          className={classes.rightButton}
          onClick={nextHandler}
          color="primary"
          fontSize="large"
        />
      )}
    </div>
  );
};

const KagamiStepPresentation = (props: KagamiStepPresentationProps) => {
  const useStyles = makeStyles(KagamiStepStyle);
  let classes = useStyles();
  const { stepPresentationModel } = props;
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  const stepFormState = useKagamiStepForm(createState(stepPresentationModel));
  let visibleStepForms = props.stepPresentationModel.stepFormModels.filter((model) => model.visible === true);
  let mainEntityForm = props.stepPresentationModel.stepFormModels[0];

  const loadFormData = (stepFormModel: any) => {
    if (stepFormModel instanceof KagamiFormPresentationModel && !stepFormModel.onloadRequired) {
      handleFormDataChange(stepFormModel.formEntityId, stepFormModel.onStartData);
    } else if (
      stepFormModel instanceof KagamiEmbeddedFormPresentationModel &&
      !stepFormModel.isListPresentation &&
      stepFormModel.onloadRequired
    ) {
      let formModel: KagamiFormModel = getFormModel();
      if (CommonUtils.isNotEmpty(stepFormModel.onLoadData)) {
        updateInFocusMap(stepFormModel.formEntityId, stepFormModel['onLoadData']['id'], formModel);
      } else {
        updateInFocusMap(stepFormModel.formEntityId, getRecordId(formModel, stepFormModel), formModel);
      }
      let changeFor = _getChangeForDetail(formModel, stepFormModel.formEntityId, null, null);
      _getUnSyncedFormRecord(formModel, stepFormModel.formEntityId);
      let data = formModel.formData.changedData;
      makeOnLoadCall(mainEntityForm.processName, stepFormModel.presentation, changeFor, data, null, {}).then(
        (response) => {
          formModel.formData.clearChangedData();
          handleFormDataChange(
            stepFormModel.formEntityId,
            response.data,
            undefined,
            false,
            !stepFormModel.isListPresentation
          );
        }
      );
    }
  };

  const renderStepForms = () => {
    if (stepFormState.getActiveTabIndex === 0) {
      return buildFormPresentation(mainEntityForm);
    } else {
      if (visibleStepForms) {
        return (
          <EmbedStepsContent visibleStepForms={visibleStepForms} activeTabIndex={stepFormState.getActiveTabIndex} />
        );
      }
    }
  };

  return (
    <div className={classes.stepRoot}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={stepFormState.getActiveTabIndex} alternativeLabel connector={<QontoConnector />}>
          {visibleStepForms.map((stepform: any, i: any) => {
            return (
              <Step
                key={i}
                onClick={() => {
                  stepFormState.setActiveTabIndex(i);
                  loadFormData(stepform);
                }}
              >
                {/* <StepLabel>{stepform.formHeader}</StepLabel> */}
                {<StepLabel>{translateToLocalistaion(translations, stepform.formHeader)}</StepLabel>}
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <div className={classes.stepFormBody}>
        {renderStepForms()}
        <StepFormButtons stepFormState={stepFormState} stepFormModel={visibleStepForms} loadFormData={loadFormData} />
      </div>
    </div>
  );
};
