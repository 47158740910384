import extend from 'lodash/extend';
import {getReportData,
        reportColumnDisplayFormatter,
        reportFilterHeader,
        getReportId
        } from '../../../../service/ReportUtilService';
const gaugeDefaultConfig = ()=> {
  return  {
	    chart: {
	        type: 'gauge',
	        plotBackgroundColor: null,
	        plotBackgroundImage: null,
	        plotBorderWidth: 0,
	        plotShadow: false,
			  
	    },
	    exporting: {
				enabled: false
				},
	    title: {
	        text: ''
	    },
	    
	    pane: {
	        startAngle: -90,
	        endAngle: 90,
            background: null
	    },
        
        plotOptions: {
            gauge: {
                dataLabels: {
                    enabled: false
             },
                dial: {
                    baseLength: '0%',
                    baseWidth: 10,
                    radius: '100%',
                    rearLength: '0%',
                    topWidth: 1
                }
            }
        },
	       
	    // the value axis
	    yAxis: {
           labels: {
                enabled: true,
				   distance:15
              //  x: 35, y: -30
            },
            //tickPositions: [80, 90],
		tickPositions: [0,10,20,30,40,50,60,70,80, 90,100],
            minorTickLength: 0,
	        min: 0,
	        max: 100,
	        plotBands: [{
	            from: 0,
	            to: 40,
	            color: '#FF0040',//'rgb(192, 0, 0)', // red
                thickness: '50%'
	        },
			{
	            from: 40,
	            to: 60,
	            color: '#DDDA02',//'rgb(255, 192, 0)', // yellow
                thickness: '50%'
	        },
			{
	            from: 60,
	            to: 70,
	            color: '#A9E2F3',//'rgb(255, 192, 0)', // yellow
                thickness: '50%'
	        },
			{
	            from: 70,
	            to: 80,
	            color: '#81F7F3',//'rgb(255, 192, 0)', // yellow
                thickness: '50%'
	        },
			{
	            from: 80,
	            to: 90,
	            color: '#2EFEC8',//'rgb(255, 192, 0)', // yellow
                thickness: '50%'
	        },
			{
	            from: 90,
	            to: 100,
	            color: '#00FF40',//'rgb(255, 192, 0)', // yellow
                thickness: '50%'
	        },
			]        
	    },
	    credits: {
			enabled: false,
		},
	    series: [{
	        name: 'Speed',
	        data: []
	    }]
  }	
}



export const makeGauge = (gaugeReportModel: any) => {
  let defaultConfig  = gaugeDefaultConfig();
  let tablerecords = gaugeReportModel.reportData;
  let userConfig = prapareUserConfigForChart(tablerecords,false);
  let dispchartOptions = extend(true, {}, defaultConfig, userConfig);
  return dispchartOptions;
}

const prapareUserConfigForChart = (tablerecords:any,isDashboard:any)  => {
    let userConfig:any = {
	    series: [{
	        name: 'Speed',
	        data: []
	    }]
    }

    userConfig.series[0].name = calculatePerformance(tablerecords);
    userConfig.series[0].data.pop();
    userConfig.series[0].data.push(tablerecords);
    return userConfig
}

const calculatePerformance = (tablerecords:any) => {
    if(tablerecords<=40){
        return 'Poor';
    }else if(tablerecords>40 && tablerecords<=60){
        return 'Mediocre';
    }else if(tablerecords>60 && tablerecords<=70){
        return 'Satisfactory';
    }else if(tablerecords>70 && tablerecords<=80){
        return 'Fair';
    }else if(tablerecords>80 && tablerecords<=90){
        return 'Good';
    }else {
        return 'Excellent';
    }
}