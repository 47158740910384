import { APIService } from '../../../../service/ApiService';
import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';
import { onApplicationLoginFailure, onApplicationLoginSuccess } from '../../../controller/KagamiApplicationController';
import { DOMAIN, getAppAndUserContextUrl, getDeviceInfo } from '../../../service/URLService';
import { CommonUtils } from '../../../utils/CommonUtils';
import { KagamiLoginModel } from '../model/KagamiLoginModel';
import { loginRequest, logoutRequest, makeDeviceBasedLoginCall } from '../service/LoginService';
import { buildLogin } from '../ui/KagamiLoginThree';

export function validateToken() {
  if (localStorage.getItem('currentDomain') != DOMAIN) {
    onApplicationLoginFailure();
  } else if (localStorage.getItem(SystemConstants.TOKEN) !== null) {
    const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
    let contextData: boolean = false;
    if (loginData !== null) {
      /// Note : Avoiding login request on refresh to make sure only one token is in the context in all windows
      // const loginObject = JSON.parse(loginData);
      // loginRequest(loginObject.userId, loginObject.password);
        makeDeviceBasedLoginCall(null)
    } else {
      onApplicationLoginFailure();
    }
  } else {
    onApplicationLoginFailure();
  }
}

export function buildLoginPage(): JSX.Element {
  const kagamiLoginModel: KagamiLoginModel = new KagamiLoginModel();
  return buildLogin(kagamiLoginModel);
}

export function showErrorMessage(errorMsg: string) {
  if (errorMsg) {
    KagamiStateManager.getStateToHolder(SystemConstants.LOGIN_STATE).setLoginErrorMessage(errorMsg);
  }
}

export function logout() {
  logoutRequest();
}
