import { getDmsViewUrl } from '../../../../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { buildDms } from '../../../../../../../process/builder/dms/controller/kagamiDMSController';
import { KagamiDMSModel } from '../../../../../../../process/builder/dms/model/kagamiDMSModel';
import { buildLedgerDetails } from '../../ledgerDetails/controller/LedgerDetailsController';
import { LedgerDetailsModel } from '../../ledgerDetails/model/LedgerDetailsModel';
import { StockLedgerReportModel } from '../model/StockLedgerReportModel';
import { BuildStockLedgerReportView } from '../ui/StockLedgerReport';

let stockLedgerReport: StockLedgerReportModel;

export function buildStockLedgerReport(stockLedgerReportModel: StockLedgerReportModel) {
    stockLedgerReport = stockLedgerReportModel
    return BuildStockLedgerReportView(stockLedgerReportModel);
  }

export const handleOnLedgerClick = (ledgerInputs:any,row:any) => {
  if(row.data !== undefined){
    if(CommonUtils.isNotEmpty(row.data['otherDeatils']) && CommonUtils.isNotEmpty(row.data['otherDeatils']['file'])){
      let kagamiDMSModel:KagamiDMSModel = new KagamiDMSModel();
      kagamiDMSModel.dmsResponseUrl = getDmsViewUrl(row.data['otherDeatils']['file']);
      stockLedgerReport.modelPopup.title = 'preview file';
      stockLedgerReport.modelPopup.maxWidth = 'xl';
      stockLedgerReport.modelPopup.content = buildDms(kagamiDMSModel);
      stockLedgerReport.modelPopup.pdfIconVisible = false;
      stockLedgerReport.modelPopup.state.setOpen(true);
    }else{
      const ledgerDetailsModel = new LedgerDetailsModel();
      ledgerDetailsModel.ledgerInputs = ledgerInputs;
      stockLedgerReport.modelPopup.title = stockLedgerReport.reportConfig.reportConfigSummary.name;
      stockLedgerReport.modelPopup.content = buildLedgerDetails(ledgerDetailsModel);
      stockLedgerReport.modelPopup.pdfIconVisible = true;
      stockLedgerReport.modelPopup.state.setOpen(true);
    }
  }
}