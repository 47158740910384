export class PinnedReportViewModel{
    roleReportsMapping:any;
    name:string;
    options:any[];
    pinnedReports:any;
    allSelectedData:any;
    reportIdMapWithConfig:any;
    selectedData:any;
    
    constructor(){
        this.name = '';
        this.options = [];
        this.selectedData = {};
    }
}

export interface PinnedReportsViewModelProps{
    pinnedReportViewModel:PinnedReportViewModel;
    translations?:any;
}

