import { State, useState } from '@hookstate/core';
import { Breakpoint } from '@mui/material';
import { KagamiExpandedEmbeddedPresentationModel } from '../../../application/builder/home/builder/body/builder/process/builder/form/builder/embedded-presentation/builder/expanded-embedded-presentation/model/KagamiExpandedEmbeddedPresentationModel';
import { KagamiFormModel } from '../../../application/builder/home/builder/body/builder/process/builder/form/model/KagamiFormModel';
import { KagamiStateManager } from '../../../state/KagamiStateManager';

export class KagamiErrorModalModel {
  title: any;
  content: any;
  openDialog: boolean;
  modalWidth: Breakpoint;
  state: any;
  isModalDialogTemplate: boolean;
  error: boolean;
  handleOnOk: any;
  handleOnClose: any;
  disabledButton: boolean;
  handleupdate: any;
  embeddedFormPresentationModel: KagamiExpandedEmbeddedPresentationModel | null;
  isTriggerModal: boolean;
  isPickItem: boolean;
  isUpdatePassword: boolean;
  parentEntityId: string;
  handleSave: any;
  isBulkUploadSave: boolean;
  isBulkUploadSaveActive: boolean;
  isSessionInvalid: boolean;
  isVendorListButtonDisable: boolean;
  isNonEditableList: boolean;
  isServerError: boolean;
  constructor() {
    this.openDialog = false;
    this.modalWidth = 'md';
    this.isModalDialogTemplate = false;
    this.error = false;
    this.disabledButton = false;
    this.embeddedFormPresentationModel = null;
    this.isTriggerModal = false;
    this.isPickItem = false;
    this.isUpdatePassword = false;
    this.parentEntityId = '';
    this.isBulkUploadSave = false;
    this.isBulkUploadSaveActive = false;
    this.isSessionInvalid = false;
    this.isVendorListButtonDisable = false;
    this.isNonEditableList = false;
    this.isServerError = false;
  }
}

export interface KagamiErrorModalModelProps {
  kagamiErrorModalModel: KagamiErrorModalModel;
}
export const wrapKagamiErrorModalModelState = (state: State<KagamiErrorModalModel>) => {
  let alertDialogState = state;

  return {
    get isOpen() {
      return alertDialogState.openDialog.get();
    },

    setOpen(isOpen: boolean) {
      alertDialogState.openDialog.set(isOpen);
    },

    get modalWidth() {
      return alertDialogState.modalWidth.get();
    },
    setModalWidth(modalWidth: any) {
      alertDialogState.modalWidth.set(modalWidth);
    },

    setButtonDisabled(disabledButton: boolean) {
      alertDialogState.disabledButton.set(disabledButton);
    },

    get buttonVisiblity() {
      return alertDialogState.disabledButton.get();
    },

    get isUpdatePassword() {
      return alertDialogState.isUpdatePassword.get();
    },

    setIsUpdatePassword(isUpdatePassword: boolean) {
      alertDialogState.isUpdatePassword.set(isUpdatePassword);
    },

    get parentEntityId() {
      return alertDialogState.parentEntityId.get();
    },

    get isBulkUploadSave() {
      return alertDialogState.isBulkUploadSave.get();
    },

    setisBulkUploadSave(isBulkUploadSave: boolean) {
      alertDialogState.isBulkUploadSave.set(isBulkUploadSave);
    },

    get isBulkUploadSaveActive() {
      return alertDialogState.isBulkUploadSaveActive.get();
    },

    setisBulkUploadSaveActive(isBulkUploadSaveActive: boolean) {
      alertDialogState.isBulkUploadSaveActive.set(isBulkUploadSaveActive);
    },

    get isNonEditableList() {
      return alertDialogState.isNonEditableList.get();
    },

    setIsNonEditableList(isNonEditableList: boolean) {
      alertDialogState.isNonEditableList.set(isNonEditableList);
    }
  };
};
export const useKagamiErrorModalModelState = (state: State<KagamiErrorModalModel>, isPickItem: boolean) => {
  let modalState = wrapKagamiErrorModalModelState(useState(state));
  if (isPickItem) {
    KagamiStateManager.addStateToHolder('PickItemModal', modalState);
  }
  return modalState;
};

export interface ModalPopUpProps {
  kagamiFormModel: KagamiFormModel;
}
