import { State, useState } from '@hookstate/core';
import { Key } from 'react';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { KagamiGridModel } from '../../../../list/builder/grid/model/KagamiGridModel';
import { KagamiFormModel } from '../../../model/KagamiFormModel';
import { PresentationHierarchy } from '../../hierarchy/PresentationHierarchy';

export class KagamiFormPresentationModel extends KagamiFormModel {
  formDataIdentifier: string;
  presentationHireachy: PresentationHierarchy | undefined;
  presentation: any;
  formEntityId: string;
  formHeader: string;
  mandatory: boolean;
  visible: boolean;
  readonly: boolean;
  isListPresentation: boolean;
  onloadRequired: boolean;
  onLoadData: any;
  hasChanges: boolean;
  actions: any[];
  createState: any;
  state: any;
  presentationPolicy: any;
  businessPolicy: any;
  initiallyVisible: boolean;
  initiallyReadOnly: boolean;
  isDynamicForm: boolean;
  isDefaultExpanded : boolean;
  presentationId : any;
  formComponentObj: any;
  Accordion: boolean = false;
  getControlWidth(uiSettings: any) {
    // const isVerticalView = uiSettings?.formView === 'vertical' ? true : false;
    let fieldWidth;
    let fieldGap;
    fieldGap = uiSettings && uiSettings.fieldGap?.value ? 'fieldGapTrue' : '' 
    fieldWidth = uiSettings ? uiSettings.fieldWidth?.value : null;
    if (fieldWidth === undefined) {
      if (uiSettings.fieldWidth?.label === '1/3') {
        fieldWidth = '4';
      } else if (uiSettings.fieldWidth?.label === '2/3') {
        fieldWidth = '8';
      }
    }
    const sectionBorder = (uiSettingsK:any) => {
      let borders:any[] = []
     let alluiSettingsKeys = Object.keys(uiSettingsK);
     let uiSettingsKeys = alluiSettingsKeys.filter(function(uiSettingsKey) {
      return uiSettingsK[uiSettingsKey]
    })
      if(uiSettingsKeys) {
       uiSettingsKeys.forEach((item)=> {
        if(item === 'sectionRightBorder' ){
          borders.push('borderRight')
        } else if(item === 'sectionLeftBorder' ){
          borders.push('borderLeft')
    
        } else if(item === 'sectionTopBorder' ) {
          borders.push('borderTop')
    
        } else if(item === 'sectionBottomBorder' ){
          borders.push('borderDown')
    
        } else if(item === 'sectionWithoutBorder'){
          borders.push('noBorder')
    
        } 
       
       })
       
        CommonUtils.isEmpty(borders) &&  borders.push('borderAll')

      
      }
    return borders
    }
    
    const sectionBorders:any[] = uiSettings ?  sectionBorder(uiSettings) : []
     
    //  uiSettings
    //   ? uiSettings.sectionRightBorder
    //     ? 'borderRight'
    //     : uiSettings.sectionLeftBorder
    //     ? 'borderLeft'
    //     : uiSettings.sectionTopBorder
    //     ? 'borderTop'
    //     : uiSettings.sectionDownBorder
    //     ? 'borderDown'
    //     : uiSettings.sectionWithoutBorder
    //     ? 'noBorder'
    //     : 'borderAll'
    //   : null;

    // const labelWidth =  isVerticalView ? uiSettings.fieldWidth?.label : ''
    // if (isVerticalView) {
    //   let uiSettingsClassName = `col_${fieldWidth} ${fieldGap}  ${sectionBorders.join(" ")}`;
    //   return uiSettingsClassName;
    // } else
   if (fieldWidth) {
      if (
        fieldWidth === 1 ||
        // fieldWidth === 5 ||
        fieldWidth === 7 ||
        // fieldWidth === 8 ||
        fieldWidth === 9 ||
        fieldWidth === 10 ||
        fieldWidth === 11
      ) {
        let uiSettingsClassName = `col ${sectionBorders.join(" ")}`;
        return uiSettingsClassName;
      } else {
        let uiSettingsClassName = `col_${fieldWidth} ${sectionBorders.join(" ")} ${fieldGap}`;
        return uiSettingsClassName;
      }
    } else {
      let uiSettingsClassName = `col_4 ${sectionBorders.join(" ")} ${fieldGap}`;
      return uiSettingsClassName;
    }
  }
  getIsVerticalView(uiSettings:any) {
    const isVerticalView = uiSettings?.formView === 'vertical' ? true : false;
    let uiSettingsKeys =  Object.keys(uiSettings).filter(function(uiSettingsKey) {
      return uiSettings[uiSettingsKey]
    })
    let sectionAlignClassName :string = '';
    if(isVerticalView) { 
    let alignItems = uiSettings?.sectionAlign
      switch (alignItems) {
          case 'center':
            sectionAlignClassName = 'sectionAlignCenter'
          break;
          case 'left':
            sectionAlignClassName = 'sectionAlignLeft'
          
          break;
          case 'right':
            sectionAlignClassName = 'sectionAlignRight'

          
          break;
          case undefined:
            sectionAlignClassName = ''
          break;
        default:
          break;
          
      }
      
  }   else  sectionAlignClassName = ''
  return isVerticalView ? `verticalFormView ${sectionAlignClassName}`:''
} 
  constructor(
    onStartData: any,
    mainEntityId: any,
    formEntityId: string,
    formHeader: string,
    processName: string,
    processStepName: string,
    uiTemplate: string,
    initiallyVisible: boolean,
    initiallyReadOnly: boolean,
    isDynamicForm: boolean,
  ) {
    super(onStartData, mainEntityId, processName, processStepName, uiTemplate);
    this.formDataIdentifier = '';
    this.presentationHireachy = undefined;
    this.formEntityId = formEntityId;
    this.formHeader = formHeader;
    this.mandatory = false;
    this.visible = true;
    this.readonly = false;
    this.isListPresentation = false;
    this.onloadRequired = false;
    this.hasChanges = false;
    this.actions = [];
    this.createState = null;
    this.state = null;
    this.presentationPolicy = null;
    this.businessPolicy = null;
    this.initiallyVisible = initiallyVisible;
    this.initiallyReadOnly = initiallyReadOnly;
    this.isDynamicForm = isDynamicForm;
    this.isDefaultExpanded = true;
  }
  pushToFormControls(formControl: string, value: any) {
    this.formControls[formControl] = [value];
  }
}

export interface KagamiFormPresentationProps {
  embeddedAccordionPresentationModel: any;
  kagamiFormPresentationModel: KagamiFormPresentationModel;
  key: Key;
  classes?: any;
}

export const wrapFormModelState = (state: State<KagamiFormPresentationModel>) => {
  let formState = state;

  return {
    get isMandatory() {
      return formState.mandatory.get();
    },

    setMandatory(isMandatory: boolean) {
      formState.mandatory.set(isMandatory);
    },

    get isVisible() {
      return formState.visible.get();
    },

    setVisible(isVisible: boolean) {
      formState.visible.set(isVisible);
    },

    get isReadOnly() {
      return formState.readonly.get();
    },

    setReadonly(isReadonly: boolean) {
      formState.readonly.set(isReadonly);
    },

    getData() {
      formState.onLoadData.get();
    },

    setData(data: any) {
      formState.onLoadData.set(data);
    },

    getDataCount(embedFormPresentationModel: any) {
      let records: any[] = [];
      let gridModel: KagamiGridModel = embedFormPresentationModel.kagamiGridModels.get(formState.formEntityId.get());
      if (gridModel) {
        try {
          records = gridModel.detailedObjects;
          if (records === undefined) {
            records = [];
          }
        } catch (e) {
          records = [];
        }
      }
      return records.length;
    },

    get defaultExpanded(){
      return formState.isDefaultExpanded.get();
    },

    setExpanded(expanded : boolean){
      formState.isDefaultExpanded.set(expanded);
    }

  };
};

export const useKagamiFormModelState = (state: State<KagamiFormPresentationModel>) =>
  wrapFormModelState(useState(state));

export const accessFormModelState = (state: State<KagamiFormPresentationModel>) => wrapFormModelState(state);
