import { State, useState } from "@hookstate/core";
import { KagamiReportModel } from "../../../../model/KagamiReportModel";

export class TileChartReportModel extends KagamiReportModel {
    reportData:any;
    isDataLoaded:boolean;
    state:any;
    constructor(reportContent: any) {
        super()
        this.reportData = reportContent;
        this.isDataLoaded = false;
      }
}

export interface TileChartReportModelProps{
    tileChartReportModel:TileChartReportModel
}

export const useTileChartReportState = (state:State<TileChartReportModel>) => {

    let tileChartReportState = useState(state);

    return {
        reportConfig() {
          tileChartReportState.reportData.get();
        },
        setReportConfig(reportContent: any) {
          tileChartReportState.reportData.set(reportContent);
        },
    
        get isDataLoaded(){
          return tileChartReportState.isDataLoaded.get()
        },
    
        setDataLoaded(isDataLoaded:boolean){
          tileChartReportState.isDataLoaded.set(isDataLoaded);
        }
      };
}