import { State, useState } from "@hookstate/core";
import { KagamiExpandedEmbeddedPresentationModel } from "../../../../expanded-embedded-presentation/model/KagamiExpandedEmbeddedPresentationModel";


export class KagamiEmbeddedModalModel {
  embeddedExpandedPresentationModel: KagamiExpandedEmbeddedPresentationModel;
  modalOpen: boolean;
  modalComponent: any;
  createState: any;
  state: any;

  constructor(embeddedExpandedPresentationModel: KagamiExpandedEmbeddedPresentationModel) {
    this.embeddedExpandedPresentationModel = embeddedExpandedPresentationModel;
    this.modalComponent = null;
    this.modalOpen = false;
  }
}
export interface EmbeddedModalModelProps {
  embeddedModalModel: KagamiEmbeddedModalModel;
  classes : any;
}

export const wrapEmbeddedModalState = (state: State<KagamiEmbeddedModalModel>) => {
  let modalState = state;

  return {
    modalOpen() {
      return modalState.modalOpen.get();
    },

    setModalState(modalOpen: boolean) {
      modalState.modalOpen.set(modalOpen);
    }
  };
};

export const useEmbeddedModalState = (state: State<KagamiEmbeddedModalModel>) =>
  wrapEmbeddedModalState(useState(state));
