import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiAccordionStyle = (theme: Theme) =>
  createStyles({
    root: {
      'flexGrow': 1,
      'alignContent': 'center',
      'height': 'inherit',
      '& .expand-more-icon:hover': {
        cursor: 'pointer'
      }
    },
    accordion: {
      'boxShadow': theme.shadows[1],

      '&:before': {
        display: 'none !important'
      }
    },
    accordionExpanded: {
      margin: '0 !important'
    },
    accordionSummary: {
      'backgroundColor': 'transparent',
      'borderBottom': '1px solid ' + theme.palette.grey[400],
      'padding': '25px 10px 5px 0px',
      'borderTopLeftRadius': '3px',
      'borderTopRightRadius': '3px',
      'color': theme.palette.primary.light,
      'justifyContent': 'flex-start',

      '&:hover:not(.Mui-disabled)': {
        cursor: 'grab'
      },
      '&:active:not(.Mui-disabled)': {
        cursor: 'grabbing'
      }
    },
    mainFormSummary: {
      padding: '0 10px 0px 0px'
    },

    accordionSummaryExpaned: {
      '& $accordionSummaryExpandIcon': {
        [theme.breakpoints.up('md')]: {
          top: 'auto !important'
        },
        'transform': 'rotate(180deg)',
        [theme.breakpoints.down('md')]: {
          top: '10px !important'
        },
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          display: 'inline-block !important',
          top: '10px !important'
        }
      }
    },
    accordionSummaryExpandIcon: {
      [theme.breakpoints.up('md')]: {
        top: 'auto !important'
      },
      'transform': 'rotate(0deg)',
      'color': 'inherit',
      'right': theme.spacing(1),
      'position': 'absolute',
      [theme.breakpoints.down('md')]: {
        top: '10px !important'
      },
      // some jss/css to make the cards look a bit better on Internet Explorer
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        display: 'inline-block !important'
      }
    },
    accordionSummaryContent: {
      margin: '0 !important'
    },

    accordionDetails: {
      'display': 'block',
      'padding': ' 5px',
      'fontSize': '.875rem',
      'color': theme.palette.info.main,
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        height: theme.spacing(1)
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.4)'
      },
      '&::-webkit-scrollbar-track': {
        background: 'hsla(0,0,0,.2)'
      }
    },
    accordion_details_content: {},

    mainTransaction: {
      'paddingLeft': '4px',
      '& .MuiPaper-root': {
        boxShadow: 'none'
      },
      '& $accordionSummary': {
        borderBottom: '0px !important'
      },
      '& .MuiAccordionSummary-content': {
        flexGrow: 'inherit'
      },
      '& .MuiAccordionDetails-root': {
        paddingLeft: '0px'
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        color: ' #1f8a70'
      }
    }
  });
