import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';

import { GlobalSearchComponent } from '../builder/globalSearch/ui/GlobalSearch';
import { renderBody } from '../controller/KagamiBodyController';
import { BodyModelProps, KagamiBodyModel, useBodyState } from '../model/KagamiBodyModel';
import { KagamiBodyStyle } from '../style/KagamiBody';
import { getHomeModel } from '../../../controller/KagamiHomeController';
import { KagamiHomeModel } from '../../../model/KagamiHomeModel';
import { CommonUtils } from '../../../../../utils/CommonUtils';

export const buildBodyComponent = (kagamiBodyModel: KagamiBodyModel): JSX.Element => {
  return <KagamiBody key={'kagami-body'} kagamiBodyModel={kagamiBodyModel} />;
};

const useStyles = makeStyles(KagamiBodyStyle);

export const KagamiBody = (props: BodyModelProps) => {
  const bodystate = useBodyState(createState(props.kagamiBodyModel));
  const classes = useStyles();

  useEffect(() => {
    renderBody(props.kagamiBodyModel);
    document.addEventListener("keydown", keyDownFunction);
    document.addEventListener("keyup", keyUpFunction);
    return () => {
      //  cleanup;
      document.removeEventListener("keydown", keyDownFunction); 
      document.removeEventListener("keyup", keyDownFunction); 
    };
  }, []);
  const keyDownFunction = (event: any) => {
    if (event.ctrlKey && event.code === 'Space') {
      let kagamiHomeModel: KagamiHomeModel = getHomeModel();
      if(CommonUtils.isNotEmpty(kagamiHomeModel)){
      let alertDialogState = kagamiHomeModel.errorModal.state;
      alertDialogState.setOpen(false);
      }
      bodystate.setGlobalMenuSearchLoaded(true);
    } 
  }
  const keyUpFunction = (event: any) => {
    if(event.keyCode === 27){
      bodystate.setGlobalMenuSearchLoaded(false);
    }
  }



  return bodystate.bodyContent !== null ? (
    <>
    <div  className={classes.mainAppBodyRoot}>
      {props.kagamiBodyModel.bodyContent}
    </div>
      {bodystate.isGlobalMenuSearchLoaded ? <GlobalSearchComponent  bodyState={bodystate}/> : null}
    </>
  ) : (
    <div className={classes.root}></div>
  );
};
