import { KagamiListFilterModel } from "../model/KagamiListFilterModel";
import { buildListFilters } from "../ui/KagamiListFilter";


let kagamiListFilterModel: KagamiListFilterModel;

export function getKagamiListFilterModel() {
  return kagamiListFilterModel;
}

export function setKagamiListFilterModel(kagamiListFilter: KagamiListFilterModel) {
  kagamiListFilterModel = kagamiListFilter;
}

export function buildListFilterComponent(kagamiListFilterModel: KagamiListFilterModel) {

    setKagamiListFilterModel(kagamiListFilterModel)
    return buildListFilters(kagamiListFilterModel)
}


export function reBuildListFilterComponent( response : any ){
   const kagamiListFilterModel = getKagamiListFilterModel()
   kagamiListFilterModel.filterData = {}
   kagamiListFilterModel.filterData = response.constructOutputData.detailedObjects;
   let bool = kagamiListFilterModel.state.getIsListFilterDataLoaded()
  console.log(bool);
  kagamiListFilterModel.state.SetIsListFilterDataLoaded(true);
  let boolX = kagamiListFilterModel.state.changeStateToRenderAllfilters

  kagamiListFilterModel.state.SetChangeStateToRenderAllfilters(!boolX)
}


export function handleClearFilters(){
  
  kagamiListFilterModel.state.SetIsListFiltersApplied(false);
  
}