import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
export const KagamiCalendarStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',  
      gap:  theme.spacing(1),
      backgroundColor: theme.palette.background.paper, 
      borderRadius: theme.spacing(1),
      flexDirection: 'row',
      marginTop: theme.spacing(1), 
      padding:'0 10px '
    },
    process_headername_and_searchfilter: {
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center', 
      paddingLeft: '0',
      marginBottom: theme.spacing(0.5),
      padding:`${theme.spacing(0.5)} ${theme.spacing(1)}`,
      background: theme.palette.background.defaultBlue,
      boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 10%)',
      maxHeight: theme.spacing(7.5),
      width: 'auto',
      
      // margin: '-10px -10px 8px -10px',

       
    },
    calendar: {
      width: '75%',
      maxWidth: '75%',
      '& .fc .fc-toolbar.fc-header-toolbar': {
        marginBottom: theme.spacing(0),
        borderBottom :`1px solid ${theme.palette.secondary.dark}`, 
        padding: theme.spacing(1),
      },
      '& .fc-toolbar-chunk:first-child': {
        display : 'contents',
      },
      '& .fc-toolbar-title': {
        fontSize: theme.typography.h6.fontSize,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.caption.fontWeight
      },
      "& .fc-button":{
        textTransform: "capitalize ",
        fontSize:'14px'
      },
      '& .fc-button-primary':{
        backgroundColor: `#F4F4F4 !important`,
        color: `${theme.palette.common.black} !important`,
        borderColor: `${theme.palette.info.light} !important`
      },
      '& .fc-button-primary.fc-button-active': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        color: `${theme.palette.common.white}  !important`,
        borderColor: `${theme.palette.info.light} !important`,

      },
      
      '& .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus': {
        boxShadow: 'none !important'
      },
      '& .fc .fc-button-primary:focus':{
        boxShadow: 'none !important'
      },
      '& .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title':{
        whiteSpace: 'pre-line',
      },
      '& .event-radio': {
        marginRight: "4px !important",
        marginLeft: "4px !important"
      },
      "& .fc-daygrid-event":{
        marginRight: "0px !important",
        marginLeft: "0px !important",
        background: "#006e7f10"
      },
      "& .fc-daygrid-day-number": {
        fontSize: '15px',
        padding: "4px 4px 0 4px"
      },
      '& .fc-daygrid-more-link': {
        fontSize: "12px"
      },
      '& .fc-event-time': {
        display:'none',
        fontSize: "10px",
        marginRight: "1px !important",
        marginLeft: "1px !important"
      },
      '& .fc-daygrid-event-dot':{
        border: "none",
        margin: "0"
      },
      '& .fc-event-title':{
        fontSize: "10px !important",
        fontWeight: "500"
      },
      '& .fc-event-subTitle':{
        fontSize: "10px !important",
        fontWeight: "100",
        paddingLeft : "10px",
      },
      '& .fc-daygrid-day-frame': {
        overflow: 'hidden'
      }
    },
    eventsCalendarWrapper: {
      width: `calc(25% - ${theme.spacing(1)})`,
      maxWidth: '25%',
      
    },

    
    eventsCalendar_wrapper_heading: {
      height: theme.spacing(9),
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    eventsCalendarWrapper_head: {
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.secondary.dark,

    },
    eventsCalendar_wrapper_with_content: {
      borderTop: `1px solid ${theme.palette.secondary.dark}`,
      maxHeight: theme.spacing(49),
      overflow: 'auto',
      padding: theme.spacing(1),
      backgroundColor: '#F4F4F4',
      "&::-webkit-scrollbar":{
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      '&::-webkit-scrollbar-thumb' :{
          backgroundColor: 'rgba(0,0,0,.4)',
      },
      '&::-webkit-scrollbar-track' :{
          background: 'hsla(0,0,0,.2)',
      },
    
    },
    
    heading: {
      display: 'flex'
    },
    date_heading:{
      padding: '5px 15px 5px 0',
      width: '20%', 
      position: 'relative',
      visibility: 'visible',
       
    },
    event_heading:{
      padding: '5px 0 10px',
      width: '75%',
      float: 'left', 
      marginLeft: '-1px',
      '& span' : { 
        borderRadius: '4px',
        marginLeft: '10px',
        padding: '0px 15px 10px', 
        width: '100%'
      }
    },
    eventList: {
      margin: '0', 
      listStyle: 'none'

    },
    
    groupEvent: {
      width: '100%',  
      display:'flex'
    },
    eventDate: {
      padding: '0 15px 5px 0',
      width: '20%', 
      position: 'relative',
      visibility: 'visible',
      '&:after' :{
        content: '""',
        background: '#889097',
        position: 'absolute', 
        borderRadius: '50%', 
        top: '13px',
        right: '-4.3px',
        width: '10px',
        height: '10px', 
      }
    },

    eventDay: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '20px',
      padding: 0,
      margin: 0,
      textAlign: 'right'
    },
    eventDayofWeek: {
      fontSize: '12px',
      padding: 0,
      margin: 0,
      textAlign: 'right'
    },
    eventName: {
      padding: '5px 0 10px',
      width: '75%',
      float: 'left',
      borderLeft: '1px solid #d4d4d4',
      marginLeft: '-1px', 
    },
    name: {
     
      borderRadius: '4px',
      marginLeft: '10px',
      padding: '0px 15px 10px',
      float: 'left',
      width: '100%'
    }
  });
