import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const DashboardReportsStyle = (theme: Theme) =>
  createStyles({
    root: {
      'border': '1px solid #eee',
      'borderRadius': '4px',
      'boxShadow':
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
      '& .hide-buttons-from-desktop-report-container': {
        display: 'none'
      },
      '&:hover': {
        '& .VisibleExpandedButton': {
          visibility: 'visible'
        }
      }
    },

    dashboard_header: {
      'display': 'flex',
      'color': '#05263d',
      'borderBottom': '1px solid #bdbdbd',
      'maxHeight': '50px',
      'height': '50px',
      'width': 'inherit',
      '& .dashboard-handle-drag': {
        'margin': '10px',
        'width': '93%',
        'whiteSpace': 'nowrap',
        'overflow': 'hidden',
        'textOverflow': 'ellipsis',
        '&:hover': {
          cursor: 'grab'
        },
        '&:active': {
          cursor: 'grabbing'
        }
      },
      '& .handle-filter-and-popup-icon': {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '7%',
        margin: '10px'
      }
    },
    dashboard_content: {
      'height': 'calc(100% - 50px)',
      'width': 'inherit',

      // '&::-webkit-scrollbar':{
      //   display:'none',
      // },

      '& .dashboard-pie-report-container': {
        'margin': '0px !important',
        'width': 'inherit',
        'height': '100%',
        '& .dashboard-chart-grid-container': {
          'padding': '0px !important',
          'height': '100%',
          '& .dashboard-highchart': {
            'height': '100%',
            '& div:first-child': {
              'height': 'inherit',
              '& .highcharts-container': {
                'height': 'inherit !important',
                '& .highcharts-root': {
                  'height': '100%',
                  '& .highcharts-credits': {
                    display: 'none'
                  }
                }
              }
            }
          }
        },

        '& .dashboard-no-margin': {
          margin: '0px !important'
        }
      },

      '& .dashboard-highchart': {
        'height': '100%',
        '& div:first-child': {
          'height': 'inherit',
          '& .highcharts-container': {
            'height': 'inherit !important',
            '& .highcharts-root': {
              'height': '100%',
              '& .highcharts-credits': {
                display: 'none'
              }
            }
          }
        }
      },

      '& .NoDataFound': {
        color: 'red',
        fontSize: '12px',
        margin: '0px',
        padding: '0px'
      },
      '& .dashboard-report': {
        // height: 'calc(100% - 50px)'
        height: 'inherit'
      },
      '& .static-report-grid': {
        padding: '30px'
      },
      '& .ag-status-bar': {
        display: 'none'
      },
      '& .Report-MuiFormControl-root': {
        paddingTop: '15px !important',
        height: 'inherit'
      },
      '& .custom-dashboard-tile-report': {
        width: 'calc(100% + -22px)',
        margin: 'inherit'
      }
    },

    dashboard_header_report_name: {
      fontSize: '13px',
      fontWeight: '600'
    },

    expandedButton: {
      'visibility': 'hidden',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    filterIcon: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    expandedIcon: {
      width: theme.spacing(1.75),
      margin: theme.spacing(0.25)
    }
  });
