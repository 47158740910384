import { KagamiTriggersModel } from '../model/KagamiTriggersModel';
import { ContextType, KagamiTriggerModel } from '../builder/trigger/model/KagamiTriggerModel';
import { KagamiTriggerController } from '../builder/trigger/controller/KagamiTriggerController';
import { getActiveModel, onStartCall, onSubmitCall } from '../../../controller/KagamiProcessController';
import { KagamiProcessRequestModel } from '../../../model/KagamiProcessRequestModel';
import { enableTriggerByPolicy } from '../../../service/PolicyExecutionService';
import {
  getFormModel,
  handleFormDataChange,
  showServerError,
  showValidationError
} from '../../form/controller/KagamiFormController';
import { makeExplicitAssignCallForNonFieldButton } from '../../../service/ProcessService';
import { KagamiFormModel } from '../../form/model/KagamiFormModel';
import {
  hideLoader,
  showLoader
} from '../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { editRecord } from '../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { getGroupModel } from '../../group/service/GroupService';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { buildAuditLogPage } from '../../../../../../../../../../components/auditLog/controller/AuditLogController';
import { validate } from '../../form/service/FormValidationService';
import { KagamiCalendarViewModel } from '../../list/builder/calendar/model/KagamiCalendarViewModel';
import { KagamiListModel } from '../../list/model/KagamiListModel';

export function getTriggerFiringProcess(trigger: any) {
  return trigger['processName'];
}

export function onTriggerClick(
  selectedObjects: any[],
  triggerModel: KagamiTriggerModel,
  onFailure: Function,
  successCallBack: any,
  isGroupTemplateTrigger: boolean,
  isBackToHome?: boolean,
  kTrigger: boolean = false
) {
  if(triggerModel.triggerName === 'RESUME_UPLOAD'){
    console.log('red flag')
  }
  let detailedObject: any = {};
  let processName: string = triggerModel.processName;
  if (selectedObjects) {
    let selectedObject: any = [];
    if (selectedObjects.length > 0) {
      selectedObjects.forEach((record: any) => selectedObject.push({ id: record?.id }));
    }

    if (selectedObject != null && selectedObject.length > 0) {
      if(kTrigger){
        detailedObject[triggerModel.entityId] = selectedObjects[0]
      }
      else{
        detailedObject[triggerModel.entityId] = selectedObject;
      }
    }
  } else {
    detailedObject[triggerModel.detailedObject.entityType] = [triggerModel.detailedObject];
  }

  const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
    processName,
    successCallBack,
    onFailure
  );
  processRequestModel.data = detailedObject;
  processRequestModel.fromDashboard = false;

  if (isGroupTemplateTrigger) {
    let groupModel = getGroupModel();
    // groupModel.processList.push(processRequestModel.processName);
    if (isBackToHome) {
      groupModel.state.setProcessList(groupModel.processList.slice(0, 1));
    } else {
      groupModel.processList.push(processRequestModel.processName);
    }
    processRequestModel.isGroupTemplateNetworkCall = true;
  }

  // SUBMIT TRIGGER ALL CASES HANDLED IN SRINIVASA FARMS
  if (triggerModel.processName?.toLowerCase() !== 'auditlog') {
    if (kTrigger) {
      let kagamiFormModel : KagamiFormModel = getFormModel();
      (processRequestModel.runTimeInput.id = 'RuntimeInput'),
        (processRequestModel.runTimeInput.ktriggerName = triggerModel.triggerName);
      let input = {
        ...detailedObject,
        RuntimeInput: [processRequestModel.runTimeInput]
      };
      processRequestModel.data = input;
      processRequestModel.submitCall = true;
      let validations = validate(kagamiFormModel, kagamiFormModel.mainEntityId, false);
      if (validations.size === 0) {
        onSubmitCall(processRequestModel);
      } else {
        showValidationError(kagamiFormModel, validations);
      }
    } else {
      onStartCall(processRequestModel);
    }
  }
   else {
    const content = buildAuditLogPage(triggerModel?.entityId, selectedObjects[0]?.transactionEntityId);
    KagamiStateManager.getStateToHolder(SystemConstants.PROCESS_STATE).loadProcessContent(content);
  }
}

export function enableTrigger(
  triggerModel: KagamiTriggerModel,
  dataRecords: any[],
  isTriggerEnable: boolean,
  isSingleListRecord: boolean = false
) {
  if (triggerModel.contextType === ContextType.None) {
    let enableTrigger: boolean = true;
    if (triggerModel.policy) {
      enableTrigger = enableTriggerByPolicy(triggerModel.policy, dataRecords[0]);
    }
    /// Note : disable the add if a record is selected in grid but in case of detailed view of single list record need to show add
    else if (CommonUtils.isNotEmpty(dataRecords) && isAddTrigger(triggerModel.triggerName) && !isSingleListRecord) {
      enableTrigger = false;
    }
    return enableTrigger;
  } else if (triggerModel.contextType === ContextType.Single) {
    if (isAddTrigger(triggerModel.triggerName)) {
      return false;
    } else if (dataRecords) {
      if (triggerModel.isDetailView) {
        return CommonUtils.isNotEmpty(triggerModel.policy)
          ? enableTriggerByPolicy(triggerModel.policy, dataRecords[0])
          : true;
      } else if (dataRecords.length > 1 || dataRecords.length === 0) {
        return false;
      } else {
        if (triggerModel.policy) {
          let enable = isTriggerEnable;
          for (let record of dataRecords) {
            enable = enableTriggerByPolicy(triggerModel.policy, record);
          }
          return enable;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  } else if (triggerModel.contextType === ContextType.Mutli) {
    if (dataRecords) {
      if (dataRecords.length === 0) {
        return false;
      } else {
        // return true;
        let enable: boolean = isTriggerEnable;
        if (triggerModel.policy) {
          for (let record of dataRecords) {
            enable = enableTriggerByPolicy(triggerModel.policy, record);
            if (!enable) {
              return enable;
            }
          }
          return enable;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }
  return false;
}

export function reBuildAllTriggers(
  KagamiTriggersModel: KagamiTriggersModel,
  selectedObjects: any[],
  isSingleListRecord: boolean = false
) {
  let triggerModels: any = KagamiTriggersModel.triggerControls;

  for (const key in triggerModels) {
    const triggerModel = triggerModels[key];
    let enable = enableTrigger(triggerModel, selectedObjects, triggerModel.state.enableTrigger(), isSingleListRecord);
    if(!KagamiTriggersModel.isDetailView){
      if(CommonUtils.isNotEmpty(selectedObjects)){
        if(triggerModel.contextType === ContextType.None){
          if(CommonUtils.isEmpty(triggerModel.policy))
          enable = false;
        }
      }
      else if(CommonUtils.isEmpty(selectedObjects)){
        if(triggerModel.contextType === ContextType.None && CommonUtils.isEmpty(triggerModel.policy)){
          enable = true;
        }
        if(triggerModel.contextType === ContextType.None && CommonUtils.isNotEmpty(triggerModel.policy)){
          let activeModel : any = getActiveModel()
          let dataRecords : any[] = [];
          if(activeModel instanceof KagamiListModel && activeModel.onStartData?.constructOutputData?.detailedObjects){
            console.log('list type model')
            dataRecords = [activeModel.onStartData?.constructOutputData?.detailedObjects]
          }
         enable =  enableTriggerByPolicy(triggerModel.policy, dataRecords ? dataRecords[0] : [])
        }
    }   
    }
    if (triggerModel.triggerName?.toLowerCase() !== 'hyperlink') {
      triggerModel.state.setEnableTrigger(enable);
    }
    if(triggerModel.triggerName === 'auditlog'){
      // console.log(triggersModel.triggerCallback)
      triggerModel.state.setEnableTrigger(!triggerModel.enableTrigger);

    }
  }
  // triggerModels.forEach((triggerModel: KagamiTriggerModel) => {
  //   let enable = enableTrigger(triggerModel, selectedObjects, triggerModel.state.enableTrigger());
  //   triggerModel.state.setEnableTrigger(enable);
  // });
}

export function buildTriggerComponents(triggersModel: KagamiTriggersModel) {
  let components: any[] = [];
  if (triggersModel.actions != undefined) {   
  //   if(!triggersModel.actions.some(x => x.actionName === "auditlog")){
  //     triggersModel.actions.push({
  //       actionName: "auditlog",
  //       confirmation: false,
  //       contextForNextProcess: false,
  //       contextForward:false,
  //       defaultProcessOfGroup: false,
  //       eventName: "auditlog",
  //       inLine:false,
  //       inTemplate:false,
  //       isAssociatedTo:"CREATE",
  //       labelName:"auditlog",
  //       processName:"auditlog",
  //       selectMultiple:false,
  //       subProcessCall:false,
  //     })
     
  // } 
   const component = triggersModel.actions.map((triggerAction: any) => {
      let triggerModel = buildTriggerModel(triggersModel.entityId, triggerAction, triggersModel.triggerCallback, false);
      triggerModel.detailedObject = triggersModel.detailedObject;
      triggerModel.isDetailView = triggersModel.isDetailView;
      // triggerModel.state = useKagamiTriggerState(createState(triggerModel));
      triggerModel.contextType === ContextType.None
        ? (triggerModel.enableTrigger = true)
        : (triggerModel.enableTrigger = false);
      if (triggersModel.isnotification) {
        triggerModel.enableTrigger = true;
      }
      triggerModel.policy = triggerAction.policy;
      if (triggerModel.detailedObject) {
        triggerModel.enableTrigger = enableTrigger(
          triggerModel,
          triggerModel.detailedObject instanceof Array ? triggerModel.detailedObject : [triggerModel.detailedObject],
          triggerModel.enableTrigger
        );
      }
      else if(triggerModel.contextType === ContextType.None && CommonUtils.isNotEmpty(triggerModel.policy)){
        let activeModel : any = getActiveModel()
        let dataRecords : any[] = [];
        if(activeModel instanceof KagamiListModel && activeModel.onStartData?.constructOutputData?.detailedObjects){
          console.log('list type model')
          dataRecords = [activeModel.onStartData?.constructOutputData?.detailedObjects]
        }
        triggerModel.enableTrigger = enableTrigger(
          triggerModel,
          dataRecords ?? [],
          triggerModel.enableTrigger
        );
      }
    if(triggerAction.labelName === 'RESUME_UPLOAD'){
      console.log(triggersModel.triggerCallback)
      triggerModel.enableTrigger = true

    }
    if(triggerAction.labelName === 'auditlog'){
      console.log(triggersModel.triggerCallback)
      triggerModel.enableTrigger = false

    }
      let component = KagamiTriggerController.buildTrigger(triggerModel);
      triggersModel.triggerControls[triggerModel.triggerName] = triggerModel;
      //triggersModel.triggerControls.push(triggerModel);
      return component;
    });

    return component;
  }
  return [];
}

export function buildTriggerModel(entityId: string, triggerAction: any, callback: Function, embeddTriggers: boolean) {
  const contextForward: boolean = triggerAction.contextForward;
  const selectMultiple: boolean = triggerAction.selectMultiple;

  let contextType: ContextType = contextForward
    ? selectMultiple
      ? ContextType.Mutli
      : ContextType.Single
    : ContextType.None;

  let triggerModel: KagamiTriggerModel = new KagamiTriggerModel(
    entityId,
    triggerAction.processName,
    triggerAction.eventName,
    triggerAction.labelName === undefined ? triggerAction.actionName : triggerAction.labelName,
    triggerAction.confirmation,
    triggerAction.confirmationMsg,
    contextType,
    callback
  );
  triggerModel.embeddedInList = embeddTriggers;
  return triggerModel;
}

export function handleAssignItemClick(
  triggerModel: KagamiTriggerModel,
  parentProcessName: string,
  kagamiFormModel: KagamiFormModel,
  parentEntityId: string
) {
  let detailedObjects = kagamiFormModel.formData.changedData;
  showLoader();
  makeExplicitAssignCallForNonFieldButton(triggerModel.processName, parentProcessName, detailedObjects)
    .then((response) => {
      if (response.status === 200) {
        hideLoader();
        getFormModel().formData.clearChangedData();
        let pickItemModalState = KagamiStateManager.getStateToHolder('PickItemModal');
        pickItemModalState.setOpen(false);
        if(response.data.constructError){
           showServerError(response.data['constructError'])
        }
        else if(response.data.constructOutputData){
          handleFormDataChange(parentEntityId, response.data);
        }
        //editRecord(kagamiFormModel.formControls[parentEntityId], 'inline', '');
      }
    })
    .catch((err) => console.log(err.message));
}

function isAddTrigger(actionName: string) {
  let addActions = ['add', 'new', 'create'];
  return addActions.includes(actionName.toLowerCase());
}

export function disableAllTheTriggers(triggersModel : KagamiTriggersModel){
    let triggerModels: any = triggersModel.triggerControls;
  for (const key in triggerModels) {
    const triggerModel = triggerModels[key];
    triggerModel.state.setEnableTrigger(false);
  }
}

export function filterTriggersWithLabelName(calendarModel:KagamiCalendarViewModel){
  let triggers =  calendarModel?.triggersModel?.actions?.filter((item:any) => item.labelName !== undefined)
return triggers

}
