import { State, useState } from '@hookstate/core';
import { KagamiModalModel } from '../../../../../../../components/modal/model/KagamiModalModel';

export class KagamiInfoIconModel {
  open: boolean;
  kagamiModalModel: KagamiModalModel;
  state: any;
  constructor() {
    this.open = false;
    this.kagamiModalModel = new KagamiModalModel();
  }
}

export interface KagamiInfoIconModelProps {
  infoIconModel: KagamiInfoIconModel;
  translations?: any;
}

export const useKagamiAboutState = (state: State<KagamiInfoIconModel>) => {
  let infoIconState = useState(state);

  return {
    get isOpen() {
      return infoIconState.open.get();
    },

    setOpen(isOpen: boolean) {
      infoIconState.open.set(isOpen);
    }
  };
};