import { State, useState } from '@hookstate/core';
import { KagamiFormPresentationModel } from "../../form-presenation/model/KagamiFormPresentationModel";


export class KagamiFormSectionPresentationModel extends KagamiFormPresentationModel {
  formSectionIdentifier: string;
  parentFormPresentationModel: KagamiFormPresentationModel;
  uiSettings: any;
  parentPRuleId : string;
  constructor(
    formSectionIdentifier: string,
    parentFormPresentationModel: KagamiFormPresentationModel,
    presentation: any,
    onStartData: any,
    mainEntityId: any,
    formEntityId: string,
    formHeader: string,
    processName: string,
    processStepName: string,
    uiTemplate: string,
    initiallyVisible : boolean,
    initiallyReadOnly : boolean,
    uiSettings: any,
    isDynamicForm : boolean,
    parentPRuleId : string,
    createState:any
  ) {
    super(onStartData, mainEntityId, formEntityId, formHeader, processName, processStepName, uiTemplate,initiallyVisible,initiallyReadOnly,isDynamicForm);
    this.uiSettings = uiSettings;
    this.formSectionIdentifier = formSectionIdentifier;
    this.parentFormPresentationModel = parentFormPresentationModel;
    this.presentation = presentation;
    this.parentPRuleId = parentPRuleId;
    this.visible = initiallyVisible;
    this.createState=null;
  }
}

export interface FormSectionPresentationProps {
  kagamiFormSectionPresentationModel: KagamiFormSectionPresentationModel;
  classes? :any;
}

export const wrapFormSectionPresentationModelState = (state: State<KagamiFormSectionPresentationModel>) => {
  let sectionState = state;

  return {
    get isVisible() {
      return sectionState.visible.get();
    },

    setVisible(isVisible: boolean) {
      sectionState.visible.set(isVisible);
    }
  };
};

export const useKagamiFormSectionPresentaionModelState = (state: State<KagamiFormSectionPresentationModel>) =>
wrapFormSectionPresentationModelState(useState(state));

export const accessFormModelState = (state: State<KagamiFormSectionPresentationModel>) => wrapFormSectionPresentationModelState(state);