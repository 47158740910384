import { SystemConstants } from "../constants/SystemConstants";

export class KagamiBeanUtils {
  static getProperty(nestedProperty: string, object: any) {
    if (nestedProperty == null) {
      return null;
    }
    if (nestedProperty.includes('.')) {
      let properties = nestedProperty.split('.');
      for (let property of properties) {
        if (object == null || (object && object['entityType'] != null && object['entityType'] === property)) {
          continue;
        }
        // if (property != 'date'){
          object = KagamiBeanUtils._getSimpleProperty(property, object);
        // }
      } 
    } else {
      object = KagamiBeanUtils._getSimpleProperty(nestedProperty, object);
    }

    return object;
  }

  //   static String getDynamicHeaderValue(String header, List objects) {
  //     Set<String> values = LinkedHashSet();
  //     for (Map object in objects) {
  //       dynamic result = getProperty(header, object);
  //       if (CommonUtils.isNotEmpty(result)) {
  //         values.add(result.toString());
  //       }
  //     }
  //     String toReturn = StringUtils.EMPTY;
  //     int i = 0;
  //     for (String value in values) {
  //       if (i > 1) {
  //         toReturn += '..';
  //         break;
  //       }
  //       toReturn += value;
  //     }
  //     return toReturn;
  //   }

  static _getSimpleProperty(property: string, object: any) {
    if (property != null && object != null) {
      if(object instanceof Array){
        var toReturn;
        if(object.length > 0){
           toReturn = property === '0' ? object[property] : object[0][property]
        }
        return toReturn;
      }
      else if (object instanceof Object) {
        var toReturn = object[property];
        if (toReturn != null && (toReturn instanceof String || typeof toReturn === 'string') && toReturn === SystemConstants.REMOVED_) {
          return null;
        }
        return toReturn;
      } else if (object instanceof Object) {
        if (object) {
          return null;
        } else {
          let index = parseInt(property);
          return index != null ? object[index] : object.entries((o: any) => o[property]);
        }
      }
    }
    return null;
  }

  //   static Color getColorFromRecord(Map record) {
  //     if (CommonUtils.isNotEmpty(record) && CommonUtils.isNotEmpty(record['Color'])) {
  //       String primaryColor = KagamiBeanUtils.getProperty('Color.bgCode', record);
  //       //secondaryColor = entityConsumedPresentation.Color.fgCode;
  //       if (CommonUtils.isNotEmpty(primaryColor)) {
  //         int color = int.tryParse(primaryColor.replaceFirst('#', '0xFF'));
  //         if (CommonUtils.isNotEmpty(color)) {
  //           return Color(color);
  //         }
  //       }
  //     }
  //     return null;
  //   }
}
