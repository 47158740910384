import { State, useState } from '@hookstate/core';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { KagamiProfileModel } from '../../../../../../header/builder/profile/model/KagamiProfileModel';

export class KagamiUpdatePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  validation: boolean;
  errors: any;
  state: any;
  kagamiProfileModel: KagamiProfileModel;
  kagamiModalModel: KagamiModalModel;
  content: any;
  userDataForProfile: any;

  constructor() {
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';
    this.validation = false;
    this.errors = {};
    this.state = '';
    this.kagamiProfileModel = new KagamiProfileModel(this.content, this.userDataForProfile);
    this.kagamiModalModel = new KagamiModalModel();
    this.kagamiModalModel.disabledButton = true;
    this.kagamiModalModel.isUpdatePassword = true;
  }
}

export interface KagamiUpdatePasswordProps {
  kagamiUpdatePasswordModel: KagamiUpdatePasswordModel;
  popupModel: KagamiModalModel;
}

export const wrapKagamiUpdatePasswordState = (state: State<KagamiUpdatePasswordModel>) => {
  let updatePasswordState = state;

  return {
    get currentPassword() {
      return updatePasswordState.currentPassword.get();
    },

    setCurrentPassword(currentPassword: any) {
      updatePasswordState.currentPassword.set(currentPassword);
    },

    get newPassword() {
      return updatePasswordState.newPassword.get();
    },

    setNewPassword(newPassword: any) {
      updatePasswordState.newPassword.set(newPassword);
    },
    get confirmNewPassword() {
      return updatePasswordState.confirmNewPassword.get();
    },

    setConfirmNewPassword(confirmNewPassword: string) {
      updatePasswordState.confirmNewPassword.set(confirmNewPassword);
    },

    get isCurrentPassword() {
      return updatePasswordState.currentPassword.get().length > 0;
    },

    get isNewPassword() {
      return updatePasswordState.newPassword.get().length > 0;
    },

    get isConfirmNewPassword() {
      return updatePasswordState.confirmNewPassword.get().length > 0;
    },

    get isOldPasswordDifferent() {
      return updatePasswordState.newPassword.get() !== updatePasswordState.currentPassword.get();
    },

    get isNewPasswordSame() {
      return updatePasswordState.newPassword.get() === updatePasswordState.confirmNewPassword.get();
    },

    get validation() {
      return updatePasswordState.validation.get();
    },

    setValidation(value: boolean) {
      updatePasswordState.validation.set(value);
    },

    get errors() {
      return updatePasswordState.errors.get();
    },

    setErrors(value: any) {
      updatePasswordState.errors.set(value);
    }
  };
};

export const useKagamiUpdatePasswordState = (state: State<KagamiUpdatePasswordModel>) => {
  let updatePasswordState = wrapKagamiUpdatePasswordState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.UPDATEPASSWORD_STATE, updatePasswordState);
  return updatePasswordState;
};
