import { KagamiProcessRequestModel } from './KagamiProcessRequestModel';
import { RuntimeInput } from './RuntimeInput';

export class KagamiSubProcessRequestModel extends KagamiProcessRequestModel {
  changedAttribute: string;
  entityPresentation: any;
  changedData: any;
  runtimeInput: RuntimeInput;
  eventType: EventType;
  presentationId : string;
  searchRecord?:Object;
  constructor(
    processName: string,
    changedAttribute: string,
    entityPresentation: any,
    changedData: any,
    eventType: EventType,
    onSuccess: Function,
    onError: Function,

  ) {
    super(processName, onSuccess, onError );
    this.processName = processName;
    this.changedAttribute = changedAttribute;
    this.entityPresentation = entityPresentation;
    this.changedData = changedData;
    this.eventType = eventType;
    this.searchRecord = {};
    this.runtimeInput = new RuntimeInput();
    this.presentationId = '';
  }
}

export enum EventType {
  ONLOAD,
  ONCHANGE,
  ONCANCEL,
  ONSEARCH,
  ONSEARCHFILTERS,
  PAGECHANGE
}
