import { State, useState } from "@hookstate/core";
import { KagamiModalModel } from "../../../../../../../../../components/modal/model/KagamiModalModel";

export class DashboardReportsModel{
    id:string;
    name:string;
    content:any;
    modal:KagamiModalModel;
    reportModel:any
    drawerOpenDialog:boolean;
    lengthOfReport:number;
    isContentLoaded:boolean
    state:any;
    constructor(id:string, name:string,content:any){
        this.id = id;
        this.name = name;
        this.content = content;
        this.modal = new KagamiModalModel()
        this.drawerOpenDialog = false;
        this.isContentLoaded = true;
        this.lengthOfReport = 0;
    }
}

export interface DashboardReportsProps{
    dashboardReportsModel:DashboardReportsModel
}

export const useDashboardReportState = (state:State<DashboardReportsModel>) => {
    const dashboardReportState = useState(state);
    return {
        get drawerOpenDialog(){
            return dashboardReportState.drawerOpenDialog.get();
        },

        setDrawerOpenDialog(open:boolean){
            dashboardReportState.drawerOpenDialog.set(open);
        },
    }
}