import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { getKagamiListModel } from '../../../controller/KagamiListController';
import { KagamiCrossTabViewModel } from '../model/KagamiCrossTabViewModel';
import { BuildCrossTabView } from '../ui/KagamiCrossTabView';

export function buildCrossTab(kagamiCrossTabViewModel: KagamiCrossTabViewModel) {
  return BuildCrossTabView(kagamiCrossTabViewModel);
}

// export function reBuildCrossTabView(responses: any) {
//   getKagamiListModel().detailedObjects = responses;
//   let calendarListState = KagamiStateManager.getStateToHolder(SystemConstants.CROSS_TAB_STATE);
//   calendarListState.setListLoaded(true);
// }

// export function buildEditFormForCrossTabView(id: any, selectedObject: any) {
//   console.log(id);
//   if (selectedObject && selectedObject.id === id) {
//     getKagamiListModel().selectedObjects = [selectedObject];
//     reBuildTriggers();
//   }
// }
  export function reBuildCrossTabView(response:any ,listIdentifier? : string){
  getKagamiListModel().detailedObjects = response;
  let  crossTabState = KagamiStateManager.getStateToHolder(SystemConstants.CROSS_TAB_STATE);
  crossTabState.setListLoaded(true);
}
