import { State, useState } from '@hookstate/core';
import { KagamiLoaderModel } from '../../components/loader/model/KagamiLoaderModel';
import { KagamiStateManager } from '../../state/KagamiStateManager';
import { SystemConstants } from '../constants/SystemConstants';

export class KagamiApplicationModel {
  isUserLoggedIn: boolean | null;
  userContext: any;
  appContext: any;
  loaderModel: KagamiLoaderModel;
  logoutChannel : any;

  constructor() {
    this.isUserLoggedIn = null;
    this.loaderModel = new KagamiLoaderModel();
    this.logoutChannel = new BroadcastChannel("logout");

  }
}
export interface KagamiApplicationModelProps {
  applicationModel: KagamiApplicationModel;
}
export const wrapApplicationState = (state: State<KagamiApplicationModel>) => {
  const appState = state;

  return {
    get isUserLoggedIn() {
      return appState.isUserLoggedIn.get();
    },

    setUserLoggedIn(userLoggedIn: boolean) {
      appState.isUserLoggedIn.set(userLoggedIn);
    },
  };
};

export const useApplicationState = (state: State<KagamiApplicationModel>) => {
  const appState = wrapApplicationState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.APP_STATE, appState);
  return appState;
};
