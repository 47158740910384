import React, { useState } from "react";

export default (props: any) => {
  const [pageConfig, setPageConfig] = useState({
    loadPagination: true,
    pageSizesList: [5, 10, 15, 25, 50, 100, 200, 500, 1000],
    pageSize: props.api.paginationGetPageSize(),
  });
  const updatePageConfig = (key: any, value: any) => {
    setPageConfig((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }
  const resetPagination = (type: any, value?: any) => {
    pageConfig.loadPagination = false;
    updatePageConfig('loadPagination', false)
    if (type == 'pageSizeChange') {
      props.api.paginationGoToFirstPage();
      props.api.paginationSetPageSize(Number(value));
      updatePageConfig('pageSize', Number(value));
    } else if (type == 'prev') {
      props.api.paginationGoToPreviousPage();
    } else if (type == 'next' && props.api.paginationGetCurrentPage() < props.api.paginationGetTotalPages()) {
      props.api.paginationGoToNextPage();
    } else {
      props.api.paginationGoToFirstPage();
    }
    updatePageConfig('loadPagination', true);
  }
  return (
    <div className='pagination-right-box'>
      {pageConfig.loadPagination ? (
        <ul >
          <li>
            <select value={pageConfig.pageSize} onChange={(event) => resetPagination("pageSizeChange", event?.target.value)} >
              {pageConfig.pageSizesList.map((pageSize, index) => (
                <option key={'key' + index}>{pageSize}</option>
              ))}
            </select>
          </li>
          {/* <li>
            Current Page : {props.api.paginationGetCurrentPage()+1}  of {props.api.paginationGetTotalPages()}
          </li>
          <li onClick={() => resetPagination("prev")}>
            <span>Previous</span>
          </li>
          <li onClick={() => resetPagination("next")} >
            <span>Next</span>
          </li> */}
        </ul>
      ) : <span></span>}
    </div>
  );
};
