import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
export const KagamiPopoverStyle = (theme: Theme) =>
  createStyles({
    popoverPaper: {},
    closeIcon: {
      'display': 'flex',
      'width': '100%',
      'height': '6px',
      '& .MuiButtonBase-root': {
        position: 'absolute',
        top: '-1px',
        right: '-1px'
      }
    },
    popoverBody: {},
    popoverCancel: {
      width: '15px',
      height: '15px'
    },
    popoverBodywid: {}
  });
