import { useEffect, useState } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { OrganizationStyle } from '../style/KagamiHierarchyViewNode';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { makeOnLoadCall } from '../../../../../../../service/ProcessService';
import {
  KagamiLazyHierarchyViewModel,
  KagamiLazyHierarchyViewProps,
  useLazyHierarchyViewState
} from '../../../model/KagamiLazyHierarchyViewModel';
import { Tree, TreeNode } from 'react-organizational-chart';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { createState } from '@hookstate/core';

const useStyles = makeStyles(OrganizationStyle);

export const buildLazyHierarchy = (kagamiLazyHierarchyViewModel: KagamiLazyHierarchyViewModel) => {
  return <LazyHierarchyNode key={'lazy-Hierarchy'} kagamiLazyHierarchyViewModel={kagamiLazyHierarchyViewModel} />;
};

export const LazyHierarchyNode = (props: KagamiLazyHierarchyViewProps) => {
  let lazyhierarchyState = useLazyHierarchyViewState(createState(props.kagamiLazyHierarchyViewModel));
  const [expanded, setExpanded]: any = useState({});
  const classes = useStyles();
  let treeObj = props.kagamiLazyHierarchyViewModel.detailedObjects;
  let employeeData = treeObj[0];

  const addChildrenHandler = (userData: any) => {
    let changeFor = {};
    let data = {
      [`${props.kagamiLazyHierarchyViewModel.mainEntityId}`]: [userData],
      [`${props.kagamiLazyHierarchyViewModel.runtimeInput.id}`]: [
        { id: props.kagamiLazyHierarchyViewModel.runtimeInput.id, hierarchyViewLevel: -1 }
      ]
    };
    if (expanded.hasOwnProperty(userData.id)) {
      let object = {
        ...expanded,
        [`${userData.id}`]: true
      };
      setExpanded(object);
    } else {
      makeOnLoadCall(
        props.kagamiLazyHierarchyViewModel.processName,
        props.kagamiLazyHierarchyViewModel.presentation,
        changeFor,
        data,
        null,
        {},
        true
      ).then((response) => {
        let tree = JSON.parse(JSON.stringify(lazyhierarchyState.getHierarchyData()));
        let mainEntityId = props.kagamiLazyHierarchyViewModel.mainEntityId;
        let array = tree.concat(response.data.constructOutputData.detailedObjects[mainEntityId]);
        let object = {
          ...expanded,
          [`${userData.id}`]: true
        };
        lazyhierarchyState.setHierarchyData(array);
        setExpanded(object);
      });
    }
  };

  const addParentHandler = () => {
    let topHierarchy = JSON.parse(JSON.stringify(lazyhierarchyState.getTopHierarchy()));
    let changeFor = {};
    let data = {
      [`${props.kagamiLazyHierarchyViewModel.mainEntityId}`]: [topHierarchy],
      [`${props.kagamiLazyHierarchyViewModel.runtimeInput.id}`]: [
        { id: props.kagamiLazyHierarchyViewModel.runtimeInput.id, hierarchyViewLevel: 1 }
      ]
    };
    makeOnLoadCall(
      props.kagamiLazyHierarchyViewModel.processName,
      props.kagamiLazyHierarchyViewModel.presentation,
      changeFor,
      data,
      null,
      {},
      true
    ).then((response) => {
      let tree = JSON.parse(JSON.stringify(lazyhierarchyState.getHierarchyData()));
      let mainEntityId = props.kagamiLazyHierarchyViewModel.mainEntityId;
      let topNode = response.data.constructOutputData.detailedObjects[mainEntityId];
      if (topNode.length > 0) {
        let array = tree.concat(topNode);
        let object = {
          ...expanded,
          [`${topNode[0].id}`]: true
        };
        lazyhierarchyState.setHierarchyData(array);
        lazyhierarchyState.setTopHierarchy(topNode[0]);
        setExpanded(object);
      }
    });
  };

  const expandCollapseHandler = (userData: any) => {
    let object = {
      ...expanded,
      [`${userData.id}`]: false
    };
    setExpanded(object);
  };

  const addHandler = (userData: any) => {
    addChildrenHandler(userData);
  };

  const upwardHandler = () => {
    addParentHandler();
  };

  const removeHandler = (userData: any) => {
    expandCollapseHandler(userData);
  };

  const Card = ({ userData }: any) => {
    let mainData = JSON.parse(JSON.stringify(lazyhierarchyState.getTopHierarchy()));
    let positionKey = '';
    let departmentKey = '';
    Object.keys(userData).map((item: any) => {
      let key = item.toLowerCase();
      if (key.includes('position')) {
        positionKey = item;
      } else if (key.includes('department')) {
        departmentKey = item;
      }
    });

    const position = (userData: any) => {
      if (userData[positionKey]) {
        return userData[positionKey]['name'];
      } else {
        return userData.positionKey;
      }
    };

    const department = (userData: any) => {
      if (userData[departmentKey]) {
        return userData[departmentKey]['name'];
      } else {
        return userData.departmentKey;
      }
    };

    return (
      <div className={classes.mainContainer}>
        <div className={classes.dataContainer}>
          {userData?.empCode === mainData.empCode ? <ArrowUpwardIcon onClick={() => upwardHandler()} /> : <></>}
          <h5 className={classes.name}>{userData?.empName}</h5>
          <h5 className={classes.employeeId}>{userData?.empCode}</h5>
          <h5 className={classes.department}>{position(userData)}</h5>
          <h5 className={classes.designation}>{department(userData)}</h5>
          {expanded[userData?.id] == true ? (
            userData?.children?.length > 0 ? (
              <RemoveCircleOutlineIcon fontSize="small" onClick={() => removeHandler(userData)} />
            ) : null
          ) : (
            <ControlPointIcon fontSize="small" onClick={() => addHandler(userData)} />
          )}
        </div>
      </div>
    );
  };

  const renderTree = (data: any) => {
    if (Array.isArray(data)) {
      return data?.map((item, i) => {
        return (
          <TreeNode
            label={<Card userData={item} />}
            key={i}
            children={
              expanded[item?.id] === true ? (item?.children?.length > 0 ? renderTree(item?.children) : null) : null
            }
          />
        );
      });
    } else {
      return (
        <Tree
          label={<Card userData={data} />}
          children={
            expanded[data?.id] === true ? (data?.children?.length > 0 ? renderTree(data?.children) : null) : null
          }
        />
      );
    }
  };

  const renderHierarchy = () => {
    let data = JSON.parse(JSON.stringify(lazyhierarchyState.getHierarchyData()));
    let mainData = JSON.parse(JSON.stringify(lazyhierarchyState.getTopHierarchy()));
    if (Array.isArray(data) && data.length > 0) {
      let tree = createTree(data);
      let treeData = tree.nodes[`${mainData.id}`];
      return renderTree(treeData);
    }
  };

  function createTree(data: Array<any>) {
    let hierarchyMappingKey = JSON.parse(JSON.stringify(lazyhierarchyState.getHierarchyKey()));
    let nodes = Object.fromEntries(data.map((o) => [o.id, o]));
    let roots: Array<any> = [];
    nodes.null = { children: roots };
    for (let item of data) {
      if (item[hierarchyMappingKey] !== '') {
        if (nodes[item[hierarchyMappingKey]]) {
          (nodes[item[hierarchyMappingKey]].children ??= []).push(nodes[item?.id]);
        }
      }
    }
    return { nodes, roots };
  }

  useEffect(() => {
    let hierarchyMappingId = '';
    if (
      props.kagamiLazyHierarchyViewModel.presentation &&
      props.kagamiLazyHierarchyViewModel.presentation.uiSettings &&
      props.kagamiLazyHierarchyViewModel.presentation.uiSettings.cardViewConfiguration &&
      props.kagamiLazyHierarchyViewModel.presentation.uiSettings.cardViewConfiguration.hierarchicalAttribute &&
      props.kagamiLazyHierarchyViewModel.presentation.uiSettings.cardViewConfiguration.hierarchicalAttribute.value
    ) {
      if (
        props.kagamiLazyHierarchyViewModel.presentation.uiSettings.cardViewConfiguration.hierarchicalAttribute.value.split(
          '.'
        ).length > 1
      ) {
        hierarchyMappingId =
          props.kagamiLazyHierarchyViewModel.presentation.uiSettings.cardViewConfiguration.hierarchicalAttribute.value.split(
            '.'
          )[1];
      } else {
        hierarchyMappingId =
          props.kagamiLazyHierarchyViewModel.presentation.uiSettings.cardViewConfiguration.hierarchicalAttribute.value;
      }
    }
    lazyhierarchyState.setHierarchyKey(hierarchyMappingId);
    let mainData: any = { ...employeeData };
    if (mainData.hasOwnProperty(hierarchyMappingId)) {
    } else {
      mainData[hierarchyMappingId] = '';
    }

    let array = [mainData];
    lazyhierarchyState.setHierarchyData(array);
    lazyhierarchyState.setTopHierarchy(mainData);
  }, []);

  return (
    <div className={classes.lazyHierarchyContainer}>
      <div>{renderHierarchy()}</div>
    </div>
  );
};
