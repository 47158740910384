import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class HierarchyListReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  reportHeaderObject: any;
  presentation: any;
  contentReport:any;
  treeColumn:any;
  isContentReportLoaded:boolean;
  constructor(reportContent: any) {
    super();
    this.isDataLoaded = false;
    this.reportData = reportContent;
    this.reportHeaderObject ="";
    // this.treeColumn = 'Department'
    this.isContentReportLoaded = false;
  }
}

export interface KagamiHierarchyListReportModelProps {
  listReportModel: HierarchyListReportModel;
}

export const useHierarchyListReportModelState = (state: State<HierarchyListReportModel>) => {
  let listReportState = useState(state);
  return {
    ReportConfig() {
      listReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      listReportState.reportData.set(reportContent);
    },
    get isContentReportLoaded(){
      return listReportState.isContentReportLoaded.get()
    },
    setContentReport(isContentReportLoaded:boolean){
      listReportState.isContentReportLoaded.set(isContentReportLoaded);
    },
    get isDataLoaded(){
      return listReportState.isDataLoaded.get()
    },
    setDataLoaded(isDataLoaded:boolean){
      listReportState.isDataLoaded.set(isDataLoaded);
    }

  };
};
