import { getFormModel } from '../../../../../controller/KagamiFormController';
import {
  NestedEmbedPresentation,
  ExpandedEmbedFormPresentation
} from '../../embedded-accordion-presentation/builder/EmbeddedFormBuilder';
import { useKagamiFormModelState } from '../../../../form-presenation/model/KagamiFormPresentationModel';
import {
  ExpandedEmbeddedPresentationModelProps,
  KagamiExpandedEmbeddedPresentationModel
} from '../model/KagamiExpandedEmbeddedPresentationModel';
import { isSupportedTemplateForSections } from '../../../../../service/FormService';

export const buildExpandedEmbeddedPresentationComponent = (
  expandedEmbeddedPresentationModel: KagamiExpandedEmbeddedPresentationModel
) => {
  return (
    <KagamiExpandedEmbeddedPresentation
      key={expandedEmbeddedPresentationModel.formDataIdentifier}
      expandedEmbeddedPresentationModel={expandedEmbeddedPresentationModel}
    />
  );
};

const KagamiExpandedEmbeddedPresentation = (props: ExpandedEmbeddedPresentationModelProps) => {
  let embeddedFormstate = useKagamiFormModelState(props.expandedEmbeddedPresentationModel.createState);
  props.expandedEmbeddedPresentationModel.state = embeddedFormstate;
  getFormModel().formControlStates[props.expandedEmbeddedPresentationModel.formEntityId] = embeddedFormstate;

  return (
    <div>
      <ExpandedEmbedFormPresentation
        key={props.expandedEmbeddedPresentationModel.formEntityId}
        expandedEmbeddedPresentationModel={props.expandedEmbeddedPresentationModel}
      />
      {isSupportedTemplateForSections(props.expandedEmbeddedPresentationModel.uiTemplate) ? <></> : <NestedEmbedPresentation expandedEmbeddedPresentationModel={props.expandedEmbeddedPresentationModel} />}
    </div>
  );
};
