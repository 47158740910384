import { State, useState } from '@hookstate/core';


export class MapReportsModel{
  dbReportList:any[];
  dbRoleReportMapping:any;
  isDataLoaded:boolean;
  dbRolesList:any[];
  state:any;
  roleIdAndMappedReportData:any[]
  allSelectedData:any[];
  reportHeaderModel:any;
  setDisableSave:any;

  constructor(){
     this.dbReportList = [];
     this.dbRolesList = [];
     this.isDataLoaded = false;
     this.roleIdAndMappedReportData=[]
     this.allSelectedData=[];
  
     
  }
}

export const wrapMapReportsViewState = (state: State<MapReportsModel>) => {
  const KagamiMapReportsViewState = state;

  return {
    get isDataLoaded() {
      return KagamiMapReportsViewState.isDataLoaded.get();
    },

    setDataLoaded(isDataLoaded: boolean) {
      KagamiMapReportsViewState.isDataLoaded.set(isDataLoaded);
    }
  };
};

export const useMapReportsViewState = (state: State<MapReportsModel>) => {
  return wrapMapReportsViewState(useState(state));
};

export interface MapReportsModelProps {
  mapReportsModel: MapReportsModel;
  translations?:any;
}
