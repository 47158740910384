import { KagamiProfileController } from '../builder/profile/controller/kagamiProfileControllerStatic';
import { KagamiPageHeaderModel } from '../model/KagamiPageHeaderModel';
import { BuildPageHeader } from '../ui/KagamiPageHeaderStatic';

export class KagamiPageHeaderController {
  static pageHeaderModel: KagamiPageHeaderModel;
  static buildPageHeader() {
    let pageHeaderModel: KagamiPageHeaderModel = new KagamiPageHeaderModel();
    return BuildPageHeader(pageHeaderModel);
  }

  static onNotificationClick(pageHeaderModel: KagamiPageHeaderModel) {}

  static onFavoriteClick(pageHeaderModel: KagamiPageHeaderModel) {}

  static onProfileClick(pageHeaderModel: KagamiPageHeaderModel) {
    KagamiProfileController.showProfile(pageHeaderModel.profileModel);
  }

  static hideProfile() {
    let pageHeaderModel = KagamiPageHeaderController.pageHeaderModel;
    KagamiProfileController.hideProfile(pageHeaderModel.profileModel);
  }
  static logout() {}

  static re_renderDashboardFavorites() {}
}
