import React, { useState } from 'react';
import {
  ReportSchedulerModel,
  ReportSchedulerModelProps,
  useScheduleReportsState
} from '../model/ScheduleReportsModel';
import Button from '@mui/material/Button';
import { buildReportModal } from '../../../../../view/ReportModal/controller/ReportModalController';
import { handleNewScheduler } from '../controller/ScheduleReportsController';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { createState } from '@hookstate/core';
import { handleDeleteScheduler, makeScheduleReports, ScheduleReportsService } from '../service/SchedulerReportService';
import { makeStyles } from '@mui/styles';
// import ScheduleReportStyle, { SchedulerExpandStyle } from '../Styles/ScheduleReportStyle';
import ScheduleReportStyle, { SchedulerExpandStyle } from '../styles/ScheduleReportStyle';
import { Box, Chip } from '@mui/material';
import { translateToLocalistaion } from '../../../../../../process/service/PresentationService';

export const buildReportSchedulerComponent = (reportSchedulerModel: ReportSchedulerModel,translations:any) => {
  return <ScheduleReports key={Math.random()} reportSchedulerModel={reportSchedulerModel} translations={translations}/>;
};

const useStyles = makeStyles(ScheduleReportStyle);
const useStyles2 = makeStyles(SchedulerExpandStyle);

const ScheduleReports = (props: ReportSchedulerModelProps) => {
  const classes = useStyles();
  const reportSchedulerModel = props.reportSchedulerModel;
  const reportSchedulerState = useScheduleReportsState(createState(props.reportSchedulerModel));
  reportSchedulerModel.state = reportSchedulerState;

  React.useEffect(() => {
    ScheduleReportsService.FetchAllScheduler(reportSchedulerModel);
    return () => {};
  }, []);
  
  const handleRead = (event: any) => {
    event.target.parentElement.children[1].classList.toggle('handleDots');
    event.target.parentElement.children[2].classList.toggle('handleExtra');
  };

  const TextComp = (props: any) => {
    const { $sc, ...remain } = props;
    const { _id } = remain;
    const classes2 = useStyles2(remain);
    return (
      <div key={_id} className={classes2.container}>
        <p className="orginal">{$sc['value'].slice(0, 100) ?? ''}</p>
        <span className="dots">...</span>
        <p className="extra">{$sc['value'].slice(100, $sc['value'].length) ?? ''}</p>
        <input type="checkbox" id={_id} />
        <label htmlFor={_id} onClick={handleRead}></label>
      </div>
    );
  };

  if (!reportSchedulerState.isDataLoaded) {
    return <></>;
  } else {
    const scheduleReports = makeScheduleReports(reportSchedulerModel);
    return (
      <>
        {buildReportModal(reportSchedulerModel.reportModalModel)}
        <div className={classes.addScheduler}>
          <Button
            variant="contained"
            size="small"
            className="addSchedulerButton"
            onClick={() => handleNewScheduler(reportSchedulerModel,props.translations)}
          >
            {translateToLocalistaion(props.translations, "Add New Scheduler")}
          </Button>
        </div>

        {scheduleReports.map((schedulerData: any, pindex: number) => {
          return (
            <Box key={`scheduler_card_${pindex}`} className={classes.root}>
              <div className="box-header">
                <Chip size="small" className="header-count" label={pindex + 1} />
                <div className="header-name">{translateToLocalistaion(props.translations, schedulerData.schedulerDetails[0].value)}</div>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteScheduler(schedulerData, reportSchedulerModel)}
                  className="delete-scheduler-icon"
                >
                  <DeleteIcon />
                </Button>
              </div>
              <div className="box-body">
                <div className="sche-left-box">
                  <div className="sche-left-main-box">
                    {schedulerData.schedulerDetails.map(($sc: any, cindex: number, $scda: any[]) => {
                      if ($scda.length - 1 == cindex) {
                        // let _id = `sche-last-box-p-${pindex + 1}-c-${cindex + 1}`;
                        // reportSchedulerModel.tooltipData[_id] = true;
                        return (
                            <div className="sche-each-boxes sche-last-box">
                              <span className="label">{$sc['label'] ?? ''}</span>
                              <span className="colon">:</span>
                              <TextComp
                                key={`parent-p-${pindex}-c-${cindex}`}
                                $sc={$sc}
                                _id={`child-p-${pindex}-c-${cindex}`}
                                // before={'Read More'}
                                // after={'Read Less'}
                              />
                            </div>
                        );
                      }
                      return (
                        <div key={`each-box-p-${pindex + 1}-c-${cindex + 1}`} className="sche-each-boxes">
                          <span className="label">{$sc['label'] ?? ''}</span>
                          <span className="colon">:</span>
                          <span className="value">{$sc['value'] ?? ''}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="sche-right-box">
                  <Autocomplete
                    id="combo-box-demo"
                    disablePortal
                    options={[]}
                    multiple={true}
                    getOptionLabel={(option: any) => option.name ?? ''}
                    onChange={(event: any, child: any) => {
                      console.log(child);
                    }}
                    limitTags={2}
                    defaultValue={schedulerData.defaultReportList.length ? schedulerData.defaultReportList : []}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                  <Button variant="contained" className={classes.saveButton}>
                  {translateToLocalistaion(props.translations, "Save")} 
                  </Button>
                </div>
              </div>
            </Box>
          );
        })}
      </>
    );
  }
};
