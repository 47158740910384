import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiGridStyle = (theme: Theme) =>
  createStyles({
    root: {
       flex: 1,
       overflowX: 'auto',
       padding:'0 8px',
      // overflowY : 'hidden',

      // '& .ag-body-viewport' : { 
      //   "&::-webkit-scrollbar":{
      //     width: theme.spacing(1),
      //     height: theme.spacing(1),
      //   },
      //   '&::-webkit-scrollbar-thumb' :{
      //       backgroundColor: 'rgba(0,0,0,.4)',
      //       '&:horizontal': {
      //         backgroundColor: 'rgba(0,0,0,.4)',
      //       },
      //   },
      //   '&::-webkit-scrollbar-track' :{
      //       background: 'hsla(0,0,0,.2)',
      //   },
      // },
      '& .ag-cell-value': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',

      },


    //  '& .ag-body-horizontal-scroll': {
    //   //  display: 'none',
    //    minHeight:`${theme.spacing(1)} !important`,
    //    height:`${theme.spacing(1)} !important`,
    //    maxHeight:`${theme.spacing(1)} !important`,

    //  }
     
      
    },

    embedGrid: {
      // height: "100%"
      '& .ag-root-wrapper-body': {
        '& .ag-root': {
          '& .ag-body-viewport': {
            maxHeight:'50vh',
            overflowY: 'scroll',
          }
        }
      }
    },
    embedGridAutoHeight: {
      '& .ag-root-wrapper-body': {
        '& .ag-root': {
          '& .ag-body-viewport': {
            height: "auto",
          }
        }
      }
    },
    grid_with_nonfieldPresentaation: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      // padding: `0 ${theme.spacing(1)}`,
      
      // '&>div:first-child': {
      //   maxHeight: '60px',

      // },
      '&>div:last-child': {
        flex: '1'
      }
    },
    gridSearchOptions:{
      fontSize:'10px'
    },
    filterPresentation:{
      display:'flex',
      maxHeight: theme.spacing(4.5),
      paddingLeft:'5px',
      paddingRight:'5px', 
    },
    button: {
      // margin: theme.spacing(0.5)
    },
    noRowsOverlay: {
      // height: theme.spacing(15),
      // minHeight: theme.spacing(10)
    },
    footer: {
      fontWeight:'bold',
      // display: 'flex',
      // height: theme.spacing(6),
      // paddingTop: theme.spacing(0),
      // marginLeft: theme.spacing(2),
      // marginRight: theme.spacing(2),
      // flex: '1',
      // alignItems: 'center',
      // justifyContent: 'space-between'
    },
    grid_pagination_comp: {
      display:'flex', 
      width: '100%'
    },
    searchGridOptions: {

    },
    pagination: {
      marginLeft:'auto',
      fontSize: theme.spacing(1.5),
      '& .MuiButtonBase-root ': {
        padding: theme.spacing(1),

      }
    }
  });
