import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { createState } from '@hookstate/core';
import { onTextBoxTap } from '../../../controller/KagamiReportFilterController';
import {
  ReportNumberFilterModel,
  ReportNumberFilterModelProps,
  useReportNumberFilterState
} from '../model/NumberFilterModel';
import { handlePopupButtonVisibility } from '../../../service/ReportFilterUtilService';
import uniqueId from 'lodash/uniqueId';
import compact from 'lodash/compact';
import { ReportConstant } from '../../../../view/ReportConstant/ReportConstant';
import { ListBox } from '../../../../../process/builder/controls/select/ui/SearchField';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { translateToLocalistaion } from '../../../../../process/service/PresentationService';

export const buildNumberFilterComponent = (reportNumberFilterModel: ReportNumberFilterModel, translations: any) => {
  return <ReportNumberFilter key={uniqueId()} reportNumberFilterModel={reportNumberFilterModel} translations={translations} />;
};

export const ReportNumberFilter = (props: ReportNumberFilterModelProps) => {
  const reportNumberFilterModel = props.reportNumberFilterModel;
  let translateWords: any = props?.translations; 
  const numberFilterState = useReportNumberFilterState(createState(reportNumberFilterModel));
  reportNumberFilterModel.state = numberFilterState;
  let filterRelationName = reportNumberFilterModel.filterRelationName ?? '';


  const debounceOnChange = React.useCallback(CommonUtils._debounceFtn(onChange, 700), []);
  function onChange(searchString:string) {
    onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, searchString);
  }

  function handleAsyncSearch(){
    reportNumberFilterModel.pageNo+=1;
    onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, '');
  }

  let options = [...numberFilterState.filterValues];
  // options.sort();;

  let oldValue = compact(
    reportNumberFilterModel.reportFilterModel.selectedValue.get(reportNumberFilterModel.filterName)?.split(',') ?? []
  );

  let newValue: any = oldValue.filter((val: any) => {
    return val !== 'NOPOP' && val !== 'undefined';
  });

  if (
    filterRelationName === ReportConstant.Is ||
    filterRelationName === ReportConstant.Is_Not ||
    filterRelationName === ReportConstant.Is_Less_Than ||
    filterRelationName === ReportConstant.Is_Less_Than_Or_Equals_To ||
    filterRelationName === ReportConstant.Is_Greater_Than ||
    filterRelationName === ReportConstant.Is_Greater_Than_Or_Equals_To
  ) {
    newValue = newValue.length === 0 ? '' : newValue[0];
    return (
      <>
        <tr key={`report-number-${reportNumberFilterModel.filterName}`}>
          <td style={{ color: '#007ecc' }}>
            {/* {reportNumberFilterModel.filterName} */}
            {translateToLocalistaion(translateWords, reportNumberFilterModel.filterName)}
            {!reportNumberFilterModel.filterConfig.optional ? (
              <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
            ) : (
              <></>
            )}
          </td>
          {/* <td style={{ color: '#007ecc' }}>{filterRelationName}</td> */}
          <td style={{ color: '#007ecc' }}>{translateToLocalistaion(translateWords, filterRelationName)}</td>
          <td>
            <Autocomplete
              size="small"
              disablePortal={false}
              id={reportNumberFilterModel.filterName}
              options={options}
              sx={{ width: 300 }}
              defaultValue={newValue}
              onOpen={() => {
                onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, '');
              }}
              onChange={(event, child) => {
                if (child !== null && typeof child === 'string') {
                  reportNumberFilterModel.reportFilterModel.selectedValue.set(
                    reportNumberFilterModel.filterName,
                    child
                  );
                } else {
                  reportNumberFilterModel.reportFilterModel.selectedValue.set(
                    reportNumberFilterModel.filterName,
                    'NOPOP'
                  );
                }
                if (!reportNumberFilterModel.reportFilterModel.fromDashboard) {
                  handlePopupButtonVisibility(reportNumberFilterModel);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select or Search"
                  // onChange={(event: any) => {
                  //   onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, event.target.value);
                  // }}
                  onChange={(event:any)=>debounceOnChange(event.target.value)}
                />
              )}
              // ListboxProps={{
              //   onScroll: (event: React.SyntheticEvent) => {
              //     const listboxNode = event.currentTarget;
              //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              //       handleAsyncSearch();
              //       console.log('number type');
              //     }
              //   }
              // }}
              // ListboxComponent={ListBox} // handle scroll position
            />
          </td>
        </tr>
      </>
    );
  } else if (
    filterRelationName === ReportConstant.Is_In_The_Range ||
    filterRelationName === ReportConstant.Is_Not_In_The_Range
  ) {
    return (
      <>
        <tr key={uniqueId()}>
          <td style={{ color: '#007ecc' }}>
            {/* {reportNumberFilterModel.filterName} */}
            {translateToLocalistaion(translateWords, reportNumberFilterModel.filterName)}
            {!reportNumberFilterModel.filterConfig.optional ? (
              <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
            ) : (
              <></>
            )}
          </td>
          {/* <td style={{ color: '#007ecc' }}>{filterRelationName}</td> */}
          <td style={{ color: '#007ecc' }}>{translateToLocalistaion(translateWords, filterRelationName)}</td>
          <td>
            <div>min :</div>
            <Autocomplete
              size="small"
              disablePortal={false}
              options={options}
              defaultValue={newValue[0]}
              sx={{ width: 300 }}
              onOpen={() => {
                onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, '');
              }}
              onChange={(event, child) => {
                if (child !== null && typeof child === 'string') {
                  numberFilterState.setMin(child);
                  reportNumberFilterModel.reportFilterModel.selectedValue.set(
                    reportNumberFilterModel.filterName,
                    `${child},${numberFilterState.getMax}`
                  );
                } else {
                  numberFilterState.setMin('NOPOP');
                  reportNumberFilterModel.reportFilterModel.selectedValue.set(
                    reportNumberFilterModel.filterName,
                    `${'NOPOP'},${numberFilterState.getMax}`
                  );
                }
                if (!reportNumberFilterModel.reportFilterModel.fromDashboard) {
                  handlePopupButtonVisibility(reportNumberFilterModel);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select or Search"
                  // onChange={(event: any) => {
                  //   onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, event.target.value);
                  // }}
                  onChange={(event:any)=>debounceOnChange(event.target.value)}
                />
              )}
              // ListboxProps={{
              //   onScroll: (event: React.SyntheticEvent) => {
              //     const listboxNode = event.currentTarget;
              //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              //       handleAsyncSearch();
              //       console.log('number type');
              //     }
              //   }
              // }}
              // ListboxComponent={ListBox} // handle scroll position
            />
            <div>{translateToLocalistaion(translateWords, 'max')} :</div>
            <Autocomplete
              size="small"
              disablePortal={false}
              options={options}
              sx={{ width: 300 }}
              defaultValue={newValue[1]}
              onOpen={() => {
                onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, '');
              }}
              onChange={(event, child) => {
                if (child !== null && typeof child === 'string') {
                  numberFilterState.setMax(child);
                  reportNumberFilterModel.reportFilterModel.selectedValue.set(
                    reportNumberFilterModel.filterName,
                    `${numberFilterState.getMin},${child}`
                  );
                } else {
                  numberFilterState.setMax('NOPOP');
                  reportNumberFilterModel.reportFilterModel.selectedValue.set(
                    reportNumberFilterModel.filterName,
                    `${numberFilterState.getMin},${'NOPOP'}`
                  );
                }
                if (!reportNumberFilterModel.reportFilterModel.fromDashboard) {
                  handlePopupButtonVisibility(reportNumberFilterModel);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select or Search"
                  // onChange={(event: any) => {
                  //   onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, event.target.value);
                  // }}
                  onChange={(event:any)=>debounceOnChange(event.target.value)}
                />
              )}
              // ListboxProps={{
              //   onScroll: (event: React.SyntheticEvent) => {
              //     const listboxNode = event.currentTarget;
              //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              //       handleAsyncSearch();
              //       console.log('number type');
              //     }
              //   }
              // }}
              // ListboxComponent={ListBox} // handle scroll position
            />
          </td>
        </tr>
      </>
    );
  } else if (
    filterRelationName === ReportConstant.In ||
    filterRelationName === ReportConstant.Not_In
  ) {
    return (
      <>
        <tr>
          <td style={{ color: '#007ecc' }}>
            {reportNumberFilterModel.filterName}
            {translateToLocalistaion(translateWords, reportNumberFilterModel.filterName)}
            {!reportNumberFilterModel.filterConfig.optional ? (
              <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
            ) : (
              <></>
            )}
          </td>
          {/* <td style={{ color: '#007ecc' }}>{filterRelationName}</td> */}
          <td style={{ color: '#007ecc' }}>{translateToLocalistaion(translateWords, filterRelationName)}</td>
          <td>
            <Autocomplete
              size="small"
              disablePortal={false}
              multiple={true}
              id={reportNumberFilterModel.filterName}
              defaultValue={newValue}
              options={options}
              sx={{ width: 300 }}
              onOpen={() => {
                onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, '');
              }}
              onChange={(event, child) => {
                if (child !== null) {
                  if (Array.isArray(child)) {
                    reportNumberFilterModel.reportFilterModel.selectedValue.set(
                      reportNumberFilterModel.filterName,
                      child.join(',')
                    );
                  } else {
                    reportNumberFilterModel.reportFilterModel.selectedValue.set(
                      reportNumberFilterModel.filterName,
                      child
                    );
                  }
                } else {
                  reportNumberFilterModel.reportFilterModel.selectedValue.set(
                    reportNumberFilterModel.filterName,
                    'undefined'
                  );
                }
                if (!reportNumberFilterModel.reportFilterModel.fromDashboard) {
                  handlePopupButtonVisibility(reportNumberFilterModel);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select or Search"
                  // onChange={(event: any) => {
                  //   onTextBoxTap(reportNumberFilterModel.filterConfig, reportNumberFilterModel, event.target.value);
                  // }}
                  onChange={(event:any)=>debounceOnChange(event.target.value)}
                />
              )}
              // ListboxProps={{
              //   onScroll: (event: React.SyntheticEvent) => {
              //     const listboxNode = event.currentTarget;
              //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              //       handleAsyncSearch();
              //       console.log('number type');
              //     }
              //   }
              // }}
              // ListboxComponent={ListBox} // handle scroll position
            />
          </td>
        </tr>
      </>
    );
  } else {
    return <></>;
  }
};
