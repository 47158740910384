export const BuildValidationError = (errors: any) => {
    return <div>{buildErrorMessage(errors, [])} </div>;
  };
  
  export function buildErrorMessage(errorMessage: any, errorList: any) {
    for (var key of errorMessage.keys()) {
      let value = errorMessage.get(key);
      if (value instanceof Map) {
        buildErrorMessage(value, errorList);
      } else {
        errorList.push(<p style={{fontSize : '12px', marginBottom: '0'}}> <b>{key}&nbsp; :</b> {value}</p>);
      }
    }
    return (
      <>
      <ol style={{textAlign: 'left', fontSize : '12px', marginBottom: '0', paddingLeft: '20px'}}>
      {errorList.map((item:any) => <li>{item}</li>)}
      </ol>
      </>
    )
  }
  