import React from 'react';
import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiTriggerModel, TriggerModelProps, useKagamiTriggerState } from '../model/KagamiTriggerModel';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { createState } from '@hookstate/core';
import { buildTriggerAlertModal, confirmBox } from '../../TriggerAlertModal/controller/TriggerAlertModalController';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiModalModel } from '../../../../../../../../../../../../components/modal/model/KagamiModalModel';
import { buildModal } from '../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { getActiveModel } from '../../../../../controller/KagamiProcessController';
import { translateToLocalistaion } from '../../../../../service/PresentationService';

export const buildTrigger = (triggerModel: KagamiTriggerModel) => {
  return <KagamiTrigger kagamiTriggerModel={triggerModel} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiButton-root': {
        lineHeight: 'inherit',
        boxShadow: 'none',
        fontWeight: '600',
      },
      '& > *': {
        margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`
      },
      
    },
    formTriggerPrimary: {
      backgroundColor:theme.palette.primary.main,
      '&:hover': {
        // backgroundColor:`${ theme.palette.secondary.main}+'dd'`,
        backgroundColor: '#11344ddd'
        
        // color:'#fff'
      }
    },
    formTriggerSecondary: {
      backgroundColor:theme.palette.secondary.main,
      '&:hover': {
        // backgroundColor:`${ theme.palette.secondary.main}+'dd'`,
        backgroundColor: '#006e7fdd'
        
        // color:'#fff'
      }

    }
  })
);
const KagamiTrigger = (props: TriggerModelProps) => {
  // let triggerState = useKagamiTriggerState(props.kagamiTriggerModel.createstate);
 let triggerState = useKagamiTriggerState(createState(props.kagamiTriggerModel));
   props.kagamiTriggerModel.state = triggerState;
  // let triggerState =props.kagamiTriggerModel.state
  let triggerName = props.kagamiTriggerModel.triggerName;
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  let classes = useStyles(); 

  let popupModel: KagamiModalModel = new KagamiModalModel();
  popupModel.handleOnOk = () => {
    popupModel.state.setOpen(false);
  };
  popupModel.handleOnOk = () => {
    popupModel.state.setOpen(false);
  };
  return (!triggerState.enableTrigger() ? <></>:
    <div key={triggerName} className={classes.root}>
      {buildTriggerAlertModal(props.kagamiTriggerModel.triggerAlertModalModel)}
      {buildModal(popupModel)}
      <Button
        disabled={!triggerState.enableTrigger()}
        variant="contained"
        className={SystemConstants.PRIMARY_TRIGGERS.includes(triggerName) ? classes.formTriggerPrimary : classes.formTriggerSecondary}
        // color={triggerName === "Create" ? 'primary' :undefined}
        // style={{backgroundColor: SystemConstants.PRIMARY_TRIGGERS.includes(triggerName) ? '#11344d': '#29947f'}}
        size='small'
        onClick={() => {
          if(props.kagamiTriggerModel.confirmationNeeded){
            
            confirmBox('Confirm', props.kagamiTriggerModel.confirmationMsg, function(flag:boolean){
              if(flag){
                props.kagamiTriggerModel.triggerCallback(props.kagamiTriggerModel);
              }
            })
          } else if(props.kagamiTriggerModel.triggerName === 'RESUME_UPLOAD'){
            openDropZonePopover(popupModel);
          }
          else{
            props.kagamiTriggerModel.triggerCallback(props.kagamiTriggerModel);
          }

        }}
        startIcon={SystemConstants.PRIMARY_TRIGGERS.includes(triggerName) ? <AddCircleOutlineOutlinedIcon /> : null}

      >
        {/* {triggerName}  */}
        {translateToLocalistaion(translations, triggerName)}
      </Button>
    </div>
  );
};

function openDropZonePopover(popupModel:KagamiModalModel) {
  popupModel.title = 'Upload Resume(s)';
  popupModel.content =  <DropzoneAreaBase
                        onAdd={(event: any) => console.log(event)}
                        onDrop={(event:any) => console.log(event)}
                        showPreviews={true}
                        dropzoneText="CLICK / DROP FILES HERE TO UPLOAD"
                        fileObjects={[]}
                      />
  popupModel.modalWidth = 'md';
  popupModel.state.setOpen(true);
}