import { ReportIconPopoverModel } from "../model/ReportIconPopoverModel"
import { buildReportIconPopoverComponent } from "../ui/ReportIconPopover"

let reportIconPopover:ReportIconPopoverModel;

export const buildReportIconPopover = (reportIconPopoverModel:ReportIconPopoverModel, translations?: any) => {
    reportIconPopover = reportIconPopoverModel;
    return buildReportIconPopoverComponent(reportIconPopoverModel, translations);
}

export const getReportIconPopover = () => reportIconPopover;

export const handleClose = () => {
    reportIconPopover.anchorEl = null;
    reportIconPopover.state.setOpen(false);
  };

export const handleClick = (event: any) => {
  reportIconPopover.anchorEl = event.currentTarget;
  reportIconPopover.state.setOpen(true);
};