import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import {
  getAttributePresentations,
  getAttributePresentationType,
  getEntityIdForPresentation,
  getEntityPresentationsFromUIResource,
  getFormPresentationFormAttribute,
  getMainEntityPresentationsFromUIResource,
  getMappedEntityIdForPresentation,
  getPresenationIdFromPresentation,
  isListPresentation
} from '../../../../service/PresentationService';
import { PresentationHierarchy } from './PresentationHierarchy';

let presentationHierarchyMap: Map<string, PresentationHierarchy> = new Map();

export function buildHierarchy(onStartData: any) {
  let presentations: any[] = getMainEntityPresentationsFromUIResource(onStartData);
  presentationHierarchyMap = new Map();

  let mainPresentation: any;

  let pruleIndex: number;
  let mainEntityId: string = '';
  let parentPrule : string = getPresenationIdFromPresentation(presentations[0])


  for (pruleIndex = 0; pruleIndex < presentations.length; pruleIndex++) {
    let presentation: any = presentations[pruleIndex];
    let presentationId = getPresenationIdFromPresentation(presentation);
    let currentEntityId = getMappedEntityIdForPresentation(presentation);

    if (pruleIndex == 0) {
      mainPresentation = presentations[pruleIndex];

      let presentationHierarchy: PresentationHierarchy = new PresentationHierarchy();
      mainEntityId = getMappedEntityIdForPresentation(mainPresentation);

      presentationHierarchy.currentEntity = mainEntityId;
      let levelList: Array<string> = new Array();
      levelList.push(mainEntityId);
      presentationHierarchy.levelList = levelList;
      presentationHierarchy.currentPruleChildren = Array();
      presentationHierarchy.currentPrule = presentationId;
      presentationHierarchyMap.set(presentationId, presentationHierarchy);
      lookForTreePresentation(presentation);
    } else {
      let presentation: any = presentations[pruleIndex];
      let embedEntityId: string = getMappedEntityIdForPresentation(presentation);
      let presentationHierarchy: PresentationHierarchy = new PresentationHierarchy();
      presentationHierarchy.currentEntity = embedEntityId;
      presentationHierarchy.isListPrule = isListPresentation(presentation);
      let levelList: Array<string> = new Array();
      levelList.push(mainEntityId);
      levelList.push(embedEntityId);
      presentationHierarchy.levelList = levelList;
      presentationHierarchy.parentEntity = mainEntityId;
      presentationHierarchy.parentPrule = parentPrule
      presentationHierarchy.currentPruleChildren = new Array();
      presentationHierarchyMap.set(presentationId, presentationHierarchy);
      presentationHierarchyMap
        .get(presentationHierarchy.parentPrule)
        ?.currentPruleChildren.push(presentationHierarchy.currentEntity);

      lookForTreePresentation(presentation);
      lookForEmbeddedPresentations(presentation, onStartData);
    }
  }

  console.log('for hierarchy:' + presentationHierarchyMap);
  return presentationHierarchyMap;
}

export function lookForEmbeddedPresentations(embeddedpresentation: any, onStartData: any) {
  const entityId: string = getEntityIdForPresentation(embeddedpresentation);
  let presentations: any[] = getEntityPresentationsFromUIResource(onStartData, entityId);
  if (presentations !== undefined) {
    populateEmbeddedEntityHierarchy(presentations, embeddedpresentation, onStartData);
  }
}

export function lookForTreePresentation(presentation: any,sectionParentPresentationId ?:string) {
  const attributePresentations: any[] = getAttributePresentations(presentation);
  for (const attribute in attributePresentations) {
    let attributePresentation = attributePresentations[attribute];
    if (getAttributePresentationType(attributePresentation) === 'FormPresentation') {
      let formPresentation: any = getFormPresentationFormAttribute(attributePresentation);
      let mappedEntityId : string = getMappedEntityIdForPresentation(formPresentation)
      if(CommonUtils.isNotEmpty(mappedEntityId)){
        let presentationHierarchy: PresentationHierarchy = new PresentationHierarchy();
        presentationHierarchy.currentEntity = mappedEntityId;
        presentationHierarchy.currentPrule = getPresenationIdFromPresentation(formPresentation);
        presentationHierarchy.isListPrule = isListPresentation(formPresentation);
  
        let currentLevelList: string[] = new Array<string>();
        let parentEntity = getMappedEntityIdForPresentation(presentation);
        let parentPresentationId = getPresenationIdFromPresentation(presentation)
        if(parentPresentationId === undefined){
          parentPresentationId = sectionParentPresentationId;
        }
  
        if (presentationHierarchyMap.get(parentPresentationId) !== undefined) {
          let parentPresentationHierarchy = presentationHierarchyMap.get(parentPresentationId);
  
          if (parentPresentationHierarchy !== undefined) {
            currentLevelList = parentPresentationHierarchy.levelList.slice();
            currentLevelList.push(presentationHierarchy.currentEntity);
            presentationHierarchy.parentEntity = parentPresentationHierarchy.currentEntity;
            presentationHierarchy.parentPrule = parentPresentationHierarchy.currentPrule;
            presentationHierarchy.levelList = currentLevelList;
            parentPresentationHierarchy.currentPruleChildren.push(presentationHierarchy.currentEntity);
          }
        }
  
        presentationHierarchyMap.set(presentationHierarchy.currentPrule, presentationHierarchy);
        lookForTreePresentation(formPresentation);
      }     
    }
    else if(getAttributePresentationType(attributePresentation) === "SectionPresentation"){
      lookForTreePresentation(attributePresentation,presentation.presentationId)
    }
  }
}

export function populateEmbeddedEntityHierarchy(presentations: any, embeddedpresentation: any, onStartData: any) {
  let pruleIndex: number;

  for (pruleIndex = 0; pruleIndex < presentations.length; pruleIndex++) {
    let presentation: any = presentations[pruleIndex];

    let presentationHierarchy: PresentationHierarchy = new PresentationHierarchy();
    presentationHierarchy.currentEntity = getMappedEntityIdForPresentation(presentation);
    presentationHierarchy.currentPrule = getPresenationIdFromPresentation(presentation);
    presentationHierarchy.isListPrule = isListPresentation(presentation);

    let currentLevelList: string[] = new Array<string>();
    let parentEntity = getMappedEntityIdForPresentation(embeddedpresentation);
    let parentPRuleId = getPresenationIdFromPresentation(embeddedpresentation)

    if (presentationHierarchyMap.get(parentPRuleId) !== undefined) {
      let parentPresentationHierarchy = presentationHierarchyMap.get(parentPRuleId);

      if (parentPresentationHierarchy !== undefined) {
        currentLevelList = parentPresentationHierarchy.levelList.slice();
        currentLevelList.push(presentationHierarchy.currentEntity);
        presentationHierarchy.parentEntity = parentPresentationHierarchy.currentEntity;
        presentationHierarchy.parentPrule = parentPresentationHierarchy.currentPrule;
        presentationHierarchy.levelList = currentLevelList;
        parentPresentationHierarchy.currentPruleChildren.push(presentationHierarchy.currentEntity);
      }
    }

    presentationHierarchyMap.set(presentationHierarchy.currentPrule, presentationHierarchy);

    lookForEmbeddedPresentations(presentation, onStartData);
  }
}
