import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { handleOnCancel, printLedgerDetails } from '../controller/ledgerDetailsModalController';
import { createState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import { createStyles, makeStyles } from '@mui/styles';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { LedgerDetailsModalModel, LedgerDetailsModalModelProps, useLedgerDetailsState } from '../model/ledgerDetailsModalModel';
const useStyles = makeStyles(()=>createStyles({
  content:{
    maxHeight: '60vh',
    padding: '0px',
    margin: '0px',
    overflow: 'auto',
    minHeight: '80px',
    // '&::-webkit-scrollbar':{
    //   display:'none'
    // }
  },
  cancelButton:{
    background: '#b3b3b3',
    margin:'3px',
    right: '2px'
  },
  pdfButton:{
    margin: '5px',
  }
}))

export const buildLedgerDetailsModalComponent = (ledgerDetailsModalModel: LedgerDetailsModalModel) => {
  return <LedgerDetailsModal ledgerDetailsModalModel={ledgerDetailsModalModel}/>
}

export function LedgerDetailsModal(props:LedgerDetailsModalModelProps) {
  let ledgerModel:any = {}
  if(props.ledgerDetailsModalModel.content!==undefined){
    ledgerModel = props.ledgerDetailsModalModel.content.props
  }
  const classes = useStyles();
  props.ledgerDetailsModalModel.state = useLedgerDetailsState(createState(props.ledgerDetailsModalModel));
  return (
    <Dialog
      fullWidth={true}
      maxWidth = {props.ledgerDetailsModalModel.maxWidth??'lg'}
      open={props.ledgerDetailsModalModel.state.isOpen}
      // onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={{display:'flex',height: '40px' }}>
        <DialogTitle color="primary" id={props.ledgerDetailsModalModel.title} sx={{margin:'0 auto', fontSize:'15px',fontWeight:'600',lineHeight:'.6'}}>
        {props.ledgerDetailsModalModel.title}
      </DialogTitle>
        {props.ledgerDetailsModalModel.pdfIconVisible?<PictureAsPdfIcon className={classes.pdfButton} onClick={()=>printLedgerDetails(ledgerModel)}/>:<></>}
        <CloseIcon className={classes.cancelButton} onClick={handleOnCancel}/>
      </div>
        
      <DialogContent className={classes.content}>
        {props.ledgerDetailsModalModel.content !== undefined ? <Box>{props.ledgerDetailsModalModel.content}</Box> : <></>}
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelButton} onClick={handleOnCancel}>cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
