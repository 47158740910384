import React from 'react';
import { Badge, Card, CardHeader, CardMedia, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import size from 'lodash/size';
import { OrganizationStyle } from '../style/KagamiHierarchyViewNode';
import { NodeModel, NodeProps } from '../model/KagamiHierarchyViewNodeModel';
import { onCollapse } from '../Controller/KagamiHierarchyViewNodeController';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  getHierarchyFieldsSearchValue,
  retrieveFileUrlForHierarchyView
} from '../../../service/KagamiHierarchyViewService';
import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { KagamiListModel } from '../../../../../model/KagamiListModel';
import { getKagamiListModel, handleServerError } from '../../../../../controller/KagamiListController';
import { KagamiTriggerModel } from '../../../../../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { onTriggerClick } from '../../../../../../Triggers/service/KagamiTriggersService';
import { loadGroupContent } from '../../../../../../group/controller/KagamiGroupController';
import { getActiveModel, loadProcessContent } from '../../../../../../../controller/KagamiProcessController';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { enableTriggerByPolicy } from '../../../../../../../service/PolicyExecutionService';
import { translateToLocalistaion } from '../../../../../../../service/PresentationService';

const useStyles = makeStyles(OrganizationStyle);

export const buildNodeComponent = (nodeModel: NodeModel) => {
  return <Node key={'hel'} nodeModel={nodeModel} />;
};

export const Node = (props: NodeProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // let buildTriggers = props.nodeModel.presentation.actions;
  const classes = useStyles();
  const node = props.nodeModel;
  let object = node.data;
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;

  const contextBasedTriggers = () => {
    let buildTriggers: any = [];
    let hierarchyModel: KagamiListModel = getKagamiListModel();
    let triggerModel = hierarchyModel.triggersModel;
    let triggers = triggerModel.actions;
    let enableTrigger: boolean = false;
    if (CommonUtils.isNotEmpty(triggers)) {
      triggers.map((item: any) => {
        if (CommonUtils.isNotEmpty(item.policy)) {
          enableTrigger = enableTriggerByPolicy(item.policy, object);
          if (enableTrigger) {
            buildTriggers.push(item);
          }
        } else {
          buildTriggers.push(item);
        }
      });
    }
    return buildTriggers;
  };

  let fileData: string[] = [];
  let allData: string[] = [];

  node.visibleAttributes.slice(0, 6).forEach((attribute: any) => {
    if (node.presentation.presentationRules[attribute.attributePresentation.attrName] !== undefined) {
      if (
        node.presentation.presentationRules[attribute.attributePresentation.attrName].htmlControl === 'file' ||
        node.presentation.presentationRules[attribute.attributePresentation.attrName].htmlControl === 'image'
      ) {
        fileData.push(attribute.attributePresentation.attrName);
      } else if (
        node.presentation.presentationRules[attribute.attributePresentation.attrName].htmlControl === 'text' ||
        node.presentation.presentationRules[attribute.attributePresentation.attrName].htmlControl === 'search' ||
        node.presentation.presentationRules[attribute.attributePresentation.attrName].htmlControl === 'select' ||
        node.presentation.presentationRules[attribute.attributePresentation.attrName].htmlControl === 'multiselect'
      ) {
        allData.push(attribute);
      }
    }
  });

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getClassName = (index: number) => {
    if (index === 0 || index === 3) return classes.actionName;
    else if (index === 1 || index === 4) return classes.employeeId;
    else if (index === 2 || index === 5) return classes.designation;
    else {
      return classes.employeeId;
    }
  };

  let randomBorder = '1px solid ' + CommonUtils.getRandomColor();
  return (
    <>
      <div className={classes.arrowdown}></div>
      {props.nodeModel.presentation.headerName !== 'Organogram' ? (
        <div className={classes.cardParant}>
          <CardMedia
            className={classes.cardmedia}
            component="img"
            height="140"
            align-items="center"
            image={retrieveFileUrlForHierarchyView(node.data, fileData[0])}
          />
        </div>
      ) : (
        ''
      )}
      <Card sx={{ border: randomBorder }} variant="outlined" className={classes.root}>
        <CardHeader
          avatar={fileData.length ? <Badge></Badge> : <></>}
          title={
            <div className={classes.details}>
              {contextBasedTriggers()?.length > 0 ? (
                <>
                  <IconButton
                    className={classes.morevrtIcon}
                    size="small"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    <MoreVertIcon fontSize="small" color="primary" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    classes={{
                      paper: classes.paperRoot
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    {contextBasedTriggers()?.map((item: any) => {
                      if (item.contextForward === true) {
                        return (
                          <MenuItem
                            classes={classes.menuItemStyle}
                            style={{ fontSize: '12px' }}
                            onClick={() => {
                              let hierarchyModel: KagamiListModel = getKagamiListModel();
                              let triggerModel: KagamiTriggerModel =
                                hierarchyModel.triggersModel.triggerControls[item.labelName];
                              onTriggerClick(
                                [node.data],
                                triggerModel,
                                handleServerError,
                                hierarchyModel.isGroupListModel ? loadGroupContent : loadProcessContent,
                                hierarchyModel.isGroupListModel
                              );
                            }}
                          >
                            {/* {item.actionName} */}
                            {translateToLocalistaion(translations, item.actionName)}
                          </MenuItem>
                        );
                      }
                    })}
                  </Menu>
                </>
              ) : (
                <></>
              )}
              {allData.map((field: string, i: number) => {
                return (
                  <Typography key={i} component="h6" variant="h6" fontSize="12px" className={getClassName(i)}>
                    {getHierarchyFieldsSearchValue(object, field)}
                  </Typography>
                );
              })}
            </div>
          }
        />
        {node.children.length ? (
          <div className={classes.badgeParant}>
            <AccountCircleOutlinedIcon
              sx={{ fontSize: '18px', color: '#fff', position: 'absolute', left: '7px', top: '6px' }}
            />
            <IconButton
              size="small"
              onClick={() => {
                onCollapse(node);
              }}
              className={classes.expand}
            >
              {node.collapsed ? <ControlPointIcon fontSize="small" /> : <RemoveCircleOutlineIcon fontSize="small" />}
            </IconButton>
            <Badge
              style={{ cursor: 'pointer' }}
              color="secondary"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              overlap="circular"
              badgeContent={size(node.children)}
            ></Badge>
          </div>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};
