import isEqual from 'lodash/isEqual';
import { hideLoader, showLoader } from '../../../../../../../../components/loader/controller/KagamiLoaderController';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { KagamiControlModel } from '../builder/controls/model/kagamiControlModel';
import { KagamiFormPresentationModel } from '../builder/form/builder/form-presenation/model/KagamiFormPresentationModel';
import { getFormModel, handleFormDataChange, showServerError } from '../builder/form/controller/KagamiFormController';
import { KagamiEmbeddedFormPresentationModel } from '../builder/form/model/KagamiEmbeddedFormPresentationModel';
import { KagamiFormModel } from '../builder/form/model/KagamiFormModel';
import { getActiveFormRecord, _mergeContextData, _mergeFormData, _updateControlsWithFormData } from '../builder/form/service/FormService';
import { KagamiGridModel } from '../builder/list/builder/grid/model/KagamiGridModel';
import { buildGridControlModels } from '../builder/list/builder/grid/service/GridService';
import { getKagamiListModel } from '../builder/list/controller/KagamiListController';
import { KagamiListModel } from '../builder/list/model/KagamiListModel';
import { getActiveModel } from '../controller/KagamiProcessController';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { KagamiSubProcessRequestModel, EventType } from '../model/KagamiSubProcessRequestModel';
import { NavigationService } from './NavigationService';
import { getMainEntityPresentationsFromUIResource } from './PresentationService';
import { updateOnloadDataContent } from './ProcessPageService';
import { makeEmbedColumnSearchCall, makeOnCancelCall, makeOnChangeCall, makeOnLoadCall,makeOnSearchCall } from './ProcessService';

export function startSubProcessIfReceived(response: any): KagamiProcessResponseModel | undefined {
  let pRuleMap: any;
  if (
    response['constructOutputData'] &&
    response['constructOutputData']['uiResource'] &&
    response['constructOutputData']['uiResource']['presentations'] &&
    response['constructOutputData']['uiResource']['presentations']['presentationRuleMap']
  ) {
    pRuleMap = response['constructOutputData']['uiResource']['presentations']['presentationRuleMap'];
  }
  if (pRuleMap) {
    let pageModel: KagamiProcessResponseModel | undefined = NavigationService.processResponse(response, null, true);
    return pageModel;
  }
}
export async function onSubProcessCall(requestModel: KagamiSubProcessRequestModel , formModel:any,control:any,pageNumber:Number = 1,verbProperties? : any, embeddedId?:string) {
  switch (requestModel.eventType) {
    case EventType.ONLOAD:
      return makeOnLoadCall(
        requestModel.processName,
        requestModel.entityPresentation,
        requestModel.changedData,
        requestModel.data,
        requestModel.runtimeInput,
        verbProperties,
        false,
        requestModel.embeddedInfo
      );

    case EventType.ONCHANGE:
      return makeOnChangeCall(
        requestModel.processName,
        requestModel.changedAttribute,
        requestModel.entityPresentation,
        requestModel.changedData,
        requestModel.data,
        formModel,
        control
      );

    case EventType.ONCANCEL:
      return makeOnCancelCall(
        requestModel.processName,
        requestModel.entityPresentation,
        requestModel.changedData,
        requestModel.data
      );

    case EventType.ONSEARCH :
      return makeOnSearchCall( requestModel.processName,
        requestModel.presentationId,
        requestModel.runtimeInput,
        requestModel.entityPresentation, 
        pageNumber,
        requestModel.searchRecord,
        requestModel.changeFor,
        requestModel.data );

    case EventType.PAGECHANGE : 
      return makeEmbedColumnSearchCall(requestModel,requestModel.searchRecord, embeddedId, pageNumber)   

    default:
      break;
  }
}

export function prepareForLoadCall(
  processName: string,
  entityPresentation: any,
  changeFor: any,
  detailedObjects: any,
  runtimeInput: any,
  isPickItemCall : boolean = false,
  updateRecord : boolean = false,
  templateType : string = '',
  verbProperties ?: any,
  mergeOnloadData?: boolean,
  embeddedInfo : any = {},
  embeddedId?:any,
  pageNumber:number = 1
) {
  let requestModel: KagamiSubProcessRequestModel = new KagamiSubProcessRequestModel(
    processName,
    '',
    entityPresentation,
    changeFor,
    CommonUtils.isNotEmpty(embeddedId) ? EventType.PAGECHANGE : EventType.ONLOAD,
    updateOnloadDataContent,
    showServerError,
  );
  if(CommonUtils.isNotEmpty(embeddedInfo)){
    requestModel.embeddedInfo = embeddedInfo;
  }
  requestModel.data = detailedObjects;
  if (runtimeInput) {
    requestModel.runtimeInput = runtimeInput;
  }
showLoader()
  onSubProcessCall(requestModel,'','',pageNumber,verbProperties, embeddedId)
    .then((response: any) => {
      hideLoader()
      if(CommonUtils.isNotEmpty(response.data['constructOutputData'])){
        if(CommonUtils.isNotEmpty(templateType)){
          requestModel.onSuccess(response,isPickItemCall,updateRecord,templateType,mergeOnloadData, embeddedId);
        }else{
          requestModel.onSuccess(response,isPickItemCall,updateRecord,'',mergeOnloadData);
        }
        let activeModel = getActiveModel();
        if (activeModel instanceof KagamiListModel) {
          let listactiveModel = getKagamiListModel();
          if(activeModel.listTemplate === 'calendar') {
            listactiveModel.state.setHeaderLoaded(true);
          }
        }
      }
      else if(CommonUtils.isNotEmpty(response.data['constructError'])){
         requestModel.onError(response.data['constructError'])
      }
    })
    .catch((error: any) => {
      hideLoader()
      requestModel.onError(error);
    });
}

export function onChangeSuccess(response : any,control : KagamiControlModel | any){
  let formModel : KagamiFormModel = getFormModel();
  formModel.formData.clearChangedData();
  if(formModel.isPivot && response.constructOutputData && response.constructOutputData.uiResource){
    let embedEntityId : string = formModel.pivotEntityId;
    let embedFormModel : KagamiEmbeddedFormPresentationModel = formModel.formControls[embedEntityId];
    console.log('updating presentations');
    let presentations : any[] = getMainEntityPresentationsFromUIResource(response)
    presentations.forEach((presentation:any) => {
      if(presentation.entityId === embedFormModel.formEntityId && !(isEqual(presentation,embedFormModel.presentation))){
        embedFormModel.presentation = presentation;
        let gridModel : KagamiGridModel | undefined = embedFormModel.kagamiGridModels.get(embedEntityId)
        if(gridModel){
          gridModel.presentation = presentation;
          gridModel.gridControls = {};
          buildGridControlModels(gridModel);
        }
      }
    })
  }
  handleFormDataChange(control.entityId, response,control);
  startSubProcessIfReceived(response);
}

export function onCancelSuccess(response : any,formPresentationModel : KagamiFormPresentationModel){
  let formModel : KagamiFormModel = getFormModel();
  formModel.formData.clearChangedData();
  let removeRecords : boolean = formPresentationModel instanceof KagamiEmbeddedFormPresentationModel && formPresentationModel.isNested ? false : true;
  handleFormDataChange(formPresentationModel.formEntityId, response,null,false,false,false,removeRecords);
}

export function onEmbedOnLoadSuccess(response : any,embeddedFormPresentationModel : KagamiEmbeddedFormPresentationModel,isFirstOnloadCall : boolean){
  let kagamiFormModel : KagamiFormModel = getFormModel();
  kagamiFormModel.formData.clearChangedData();
  handleFormDataChange(embeddedFormPresentationModel.formEntityId, response,undefined,false,isFirstOnloadCall);
      let activeRecord: any = getActiveFormRecord(kagamiFormModel, embeddedFormPresentationModel.formEntityId);
          // kagamiFormModel.formData.detailedObjects[embeddedFormPresentationModel.formEntityId] = activeRecord;
}

export function onSearchSuccess(response : any){
  let formModel : KagamiFormModel = getFormModel();
  _mergeContextData(formModel.formData, response.constructOutputData.detailedObjects);
  _mergeFormData(formModel.formData, response.constructOutputData.detailedObjects);
  _updateControlsWithFormData(formModel,formModel.mainEntityId);
}