import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { KagamiCalendarStyle } from '../../calendar/styles/CalendarStyles';

export const EventWrapperForCalendarView = (props: any) => {
  const useStyles = makeStyles(KagamiCalendarStyle);
  const classes = useStyles();
  let eventDataRecords = props.eventRecords;
  return (
    <>
      <div className={classes.eventsCalendar_wrapper_heading}>
        <Typography variant = 'h6' className={classes.eventsCalendarWrapper_head} >
           All Events
        </Typography>
      </div>
      <div className={classes.eventsCalendar_wrapper_with_content}>
        {eventDataRecords.length > 0 ? (
          <>
            <ul className={classes.eventList}>
                  <li className={classes.heading}>
                    <div className={classes.date_heading}>
                       <span>Date</span>
                    </div>
                    <div className={classes.event_heading}> 
                        <span>Events</span>
                    </div>
                  </li>
              {eventDataRecords.map((ele: any, index:number) => {
                return (
                  <li key={index} className={classes.groupEvent}>
                    <div className={classes.eventDate}>
                      <p className={classes.eventDay}>{ele.start.getDate()}</p>
                      <p className={classes.eventDayofWeek}>{ele.start.toString().split(' ')[0]}</p>
                    </div>
                    <div className={classes.eventName}>
                      <span className={classes.name}>{ele.title}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <div>
            <p>No event found</p>
          </div>
        )}
      </div>
    </>
  );
};
