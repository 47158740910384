
import { KagamiTriggerModel } from '../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { KagamiTriggersController } from '../../Triggers/controller/KagamiTriggersController';
import { onTriggerClick } from '../../Triggers/service/KagamiTriggersService';
import { KagamiFileModel } from '../../controls/file/model/KagamiFileModel';
import { KagamiControlModel } from '../../controls/model/kagamiControlModel';
import { KagamiSelectModel } from '../../controls/select/model/kagamiSelectModel';
import { KagamiFormPresentationModel } from '../builder/form-presenation/model/KagamiFormPresentationModel';
import { BuildValidationError } from '../builder/FormErrorBuilder';
import { KagamiFormModel } from '../model/KagamiFormModel';
import {
  getActiveContext,
  handleOnChange,
  handleOnSubmit,
  prepareUnSyncForReset,
  searchRecords, 
  updateFormControls, 
  updateFormHierarchyAndOnloadData, 
  updateLovControls, 
  _getChangeForDetail,
  _getUnSyncedFormRecord,
  _mergeContextData,
  _mergeFormData,
  _updateControlsWithFormData
} from '../service/FormService';
import { validate } from '../service/FormValidationService';
import { buildForm } from '../ui/KagamiForm';
import {
  enableTriggerByPolicy,
  executeBusinessPolicy,
  executePolicyForFormPresentations,
  executeUiPolicy
} from '../../../service/PolicyExecutionService';
import { showServerErrorPopUp } from '../../../../../../../../../controller/KagamiApplicationController';
import { loadProcessContent } from '../../../controller/KagamiProcessController';
import { KagamiEmbeddedFormPresentationModel } from '../model/KagamiEmbeddedFormPresentationModel';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import delay from 'lodash/delay';
import clone from 'lodash/clone';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep'
import { convertToSha256 } from '../../../../../../../../login/service/LoginService';
import { loadGroupContent } from '../../group/controller/KagamiGroupController';
import { getGroupModel } from '../../group/service/GroupService';
import { KagamiImageModel } from '../../controls/image/model/kagami_ImageModel';
import { validateSelectTextBox } from '../../controls/select/controller/kagamiSelectController';
import { getEntityIdForPresentation, getMainEntityIdForPresentation } from '../../../service/PresentationService';

let formModel: KagamiFormModel;
let previousFormModel : KagamiFormModel | undefined;
let pickItemFormModel : KagamiFormModel;
export function buildFormPage(kagamiFormModel: KagamiFormModel) {
  if(kagamiFormModel.isSingleListView 
    && (CommonUtils.isEmpty(previousFormModel) 
    || formModel.mainEntityId !== kagamiFormModel.mainEntityId)) {
    previousFormModel = clone(formModel)
  }  
  else if(kagamiFormModel.pickItemDetail){
    previousFormModel = clone(formModel)
  }
  updateFormHierarchyAndOnloadData(kagamiFormModel);
  setFormModel(kagamiFormModel);
  return buildForm(kagamiFormModel);
}
export function getPreviousFormModel () {
  return previousFormModel 
}
export function getFormModel() {
  return formModel;
}
export function setPickItemFormModel(pickItemModel : KagamiFormModel){
  pickItemFormModel = pickItemModel;
}

export function getPickItemFormModel(){
  return pickItemFormModel;
}

export function setPrevFormModel(kagamiFormModel:KagamiFormModel|undefined){
  previousFormModel = kagamiFormModel;
}

export function handleFormDataChange(entityId: string, response: any,currentControl?:any,isPickItemCall : boolean = false,isSingleEmbedOnload : boolean = false,isExplicit : boolean = false,removeRecords : boolean =false) {
  // let kagamiFormModel: KagamiFormModel = isPickItemCall ? getPickItemFormModel(): getFormModel(); 
  /// Note : added removeRecords to delete records in case of 2 scenarios
  /// 1. deleting embed records on de selection of multi select control values in parent
  /// 2. deleting multi select control values on deletion of embed records
  let kagamiFormModel: KagamiFormModel = getFormModel();
  
   if (response && response.constructOutputData) {
    _mergeContextData(kagamiFormModel.formData, response.constructOutputData.detailedObjects);
    _mergeFormData(kagamiFormModel.formData, response.constructOutputData.detailedObjects,isExplicit,(entityId === kagamiFormModel.mainEntityId || removeRecords));
    if(kagamiFormModel.isPivot && CommonUtils.isNotEmpty(kagamiFormModel.pivotConsumedEntity) && CommonUtils.isEmpty(kagamiFormModel.pivotLOV) && 
        CommonUtils.isNotEmpty(kagamiFormModel.formData.data[kagamiFormModel.pivotConsumedEntity])){
      kagamiFormModel.pivotLOV = cloneDeep(kagamiFormModel.formData.data[kagamiFormModel.pivotConsumedEntity]);
    }
    if(response.constructOutputData.hasOwnProperty('docInfo')) {
      kagamiFormModel.docInfo =   {...kagamiFormModel.docInfo, ...response.constructOutputData.docInfo}  
    }
    _updateControlsWithFormData(kagamiFormModel, entityId,isSingleEmbedOnload);
    let activeContext = getActiveContext(kagamiFormModel, kagamiFormModel.mainEntityId);
    callLocalPolices(entityId, kagamiFormModel,currentControl);
    callPoliciesForEmbedTriggers(kagamiFormModel);
    if (kagamiFormModel.readOnly && kagamiFormModel.triggersModel) {
      KagamiTriggersController.reBuildTriggers(
        kagamiFormModel.triggersModel,
        response.constructOutputData.detailedObjects[kagamiFormModel.mainEntityId]
      );
    }
     if(hasErrorCode(response, kagamiFormModel.mainEntityId)){
      console.log('has error code')
      showServerErrorPopUp(response.constructOutputData.detailedObjects[kagamiFormModel.mainEntityId][0])
    }

    const channel = new BroadcastChannel("inline-edit");
    channel.postMessage("startEditing");
    channel.close();
  }
}

function hasErrorCode(response : any,mainEntityId : string){
  let hasErrorCode : boolean = false;
   if(response && response.constructOutputData){
    let mainObject = response.constructOutputData?.detailedObjects[mainEntityId] && response.constructOutputData.detailedObjects[mainEntityId][0]
    if(mainObject){
      hasErrorCode = CommonUtils.isNotEmpty(mainObject['_errorCode']) && mainObject['_errorCode'] !== 0
    }
   }

  return hasErrorCode;
}

export function handleOnsearch(control: KagamiSelectModel, value: any, asyncSearch: boolean, presentationId?: string) {
  // let attributePresentation = control.attributePresentation;
  searchRecords(
    getFormModel(),
    CommonUtils.isNotEmpty(presentationId) ? presentationId : control.presentationId,
    control,
    value,
    asyncSearch
  );
}

export function handleTriggerClick(triggerModel: KagamiTriggerModel) {
  let formModel: KagamiFormModel = getFormModel();
  let data = formModel.formData.formData;
  // triggerModel.triggerCallback = loadProcessContent;
  if(CommonUtils.isEmpty(triggerModel.processName) && !formModel.readOnly){
    triggerModel.processName = formModel.processName
  }

  // UNSYNCED DATA FOR KTRIGGER
  if(!formModel.readOnly){
    _getUnSyncedFormRecord(formModel,formModel.mainEntityId)
    data = formModel.formData.changedData[formModel.mainEntityId]
  }
  onTriggerClick(
    [data],
    triggerModel,
    showServerError,
    formModel.isGroupForm ? loadGroupContent : loadProcessContent,
    formModel.isGroupForm,
    undefined,
    !formModel.readOnly,
  );
}

export function handleChange(control: KagamiControlModel, value: any) {
  console.log('notified the form');
  handleOnChange(getFormModel(), control, value);
}

export function handleFormSubmit(mainEntityId: string,isPickItemCall:boolean=false) {
  let kagamiFormModel:KagamiFormModel =isPickItemCall ? getPickItemFormModel(): getFormModel();
  if(kagamiFormModel.isGroupForm) {
  let groupModel=getGroupModel();
  if(groupModel){
    groupModel.state.setProcessList(groupModel.processList.slice(0, 1));
  }}
  delay(() => {
    let validations = validate(kagamiFormModel, mainEntityId, false);
    if (validations.size === 0) {
      console.log('form has been submitted')
      if(kagamiFormModel.isPivot && CommonUtils.isNotEmpty(kagamiFormModel.pivotConsumedEntity)){
        kagamiFormModel.formData.changedData[kagamiFormModel.pivotConsumedEntity] = kagamiFormModel.pivotLOV ?? []
      }
      handleOnSubmit(kagamiFormModel);
    } else {
      showValidationError(kagamiFormModel, validations);
    }
  }, 100);
}

/// Note : Entered value is specific to file model
export function notifyChangeToForm(controlModel: KagamiControlModel, enteredvalue: any | null) {
  if (controlModel instanceof KagamiFileModel || controlModel instanceof KagamiImageModel ) {
    if (controlModel.controlValue != enteredvalue) {
      controlModel.state.setControlValue(enteredvalue);
      controlModel.formDataChange(controlModel, controlModel.controlValue);
    } else {
      controlModel.state.setControlValue(enteredvalue);
      controlModel.formDataChange(controlModel, controlModel.controlValue);
    }
  } else if (controlModel.controlType === 'number') {
    let formattedValue: any;
    let controlValue = controlModel.controlValue.toString()
    if (controlValue.includes(',')) {
      formattedValue = controlValue.replace(/,/g, '');
    } else {
      formattedValue = controlValue;
    }
    controlModel.formDataChange(controlModel, Number(formattedValue));
  } else {
    controlModel.formDataChange(
      controlModel,
      controlModel.controlType === 'password' ? convertToSha256(controlModel.controlValue) : controlModel.controlValue
    );
  }
}

export function setErrorMessages(formModel: any){
  let mainEntity = getMainEntityIdForPresentation(formModel.onStartData)
  let mainEntityData = formModel.formControls[mainEntity]
  let transactionControls = mainEntityData.formControls
  for (var key in transactionControls) {
    let controlModel : KagamiControlModel =  transactionControls[key]
    if(controlModel && CommonUtils.isNotEmpty(controlModel.errorMessage) && CommonUtils.isNotEmpty(controlModel.state)){
      console.log(controlModel)
      controlModel.state.setErrorMessage(null)
    }
    console.log(key + ": " + transactionControls[key].errorMessage);
  }
}

export function handleFormReset(entityId: string) {
  let kagamiFormModel: KagamiFormModel = getFormModel();
  setErrorMessages(kagamiFormModel)
  let formPresentationModel: KagamiFormPresentationModel = kagamiFormModel.formControls[entityId];
  prepareUnSyncForReset(kagamiFormModel, entityId);
  // let activeRecord: any = getActiveFormRecord(kagamiFormModel, formPresentationModel.formEntityId);
  // activeRecord = new Map();
  // activeRecord = kagamiFormModel.formData.changedData;
  // kagamiFormModel.formData.formData = kagamiFormModel.formData.changedData;
  // let changeFor = _getChangeForDetail(kagamiFormModel, entityId, 'id', activeRecord['id']);
  each(kagamiFormModel.formControls,(formControl:any)=>{
    if(CommonUtils.isNotEmpty(formControl.kagamiGridModels)) {
    formControl.kagamiGridModels?.forEach((kagamiGridModel:any)=>{
      if(CommonUtils.isNotEmpty(kagamiGridModel?.gridApi)) {
      kagamiGridModel?.gridApi?.stopEditing();
      }
    });
  }
  });
  
  updateFormControls(kagamiFormModel.formData.changedData[formModel.mainEntityId][0],formModel.mainEntityId,kagamiFormModel,'',false,true)
  updateLovControls(formModel.formData.data,formModel);
  // kagamiFormModel.formData.clearChangedData();
}

export function showValidationError(kagamiFormModel: KagamiFormModel, validations: any) {
  kagamiFormModel.errorModal.content = BuildValidationError(validations);
  kagamiFormModel.errorModal.title = 'Error';
  kagamiFormModel.errorModal.modalWidth = 'sm';
  let alertDialogState = kagamiFormModel.errorModal.state;
  if (alertDialogState) {
    alertDialogState.setOpen(true);
  }
}

export function showServerError(error: any) {
  showServerErrorPopUp(error);
}

export function callLocalPolices(entityIdentifier: any, kagamiFormModel: KagamiFormModel,currentControl?:any,notRequiredBusinessPolicy?:any) {
  executePolicyForFormPresentations(kagamiFormModel, kagamiFormModel.formControls);
  for (let [entityId, formPresentationModel] of Object.entries(kagamiFormModel.formControls)) {
    if (
      formPresentationModel instanceof KagamiFormPresentationModel &&
      formPresentationModel.formEntityId === entityIdentifier
    ) {
      let activeContext = getActiveContext(kagamiFormModel, entityIdentifier);
      /// Note : setting active context data with only EntityId instead of entity with prefix to avoid conflicts while 
      /// executing policies
      if(formPresentationModel instanceof KagamiEmbeddedFormPresentationModel){
        let contextEntityId : string = getEntityIdForPresentation(formPresentationModel.presentation)
        activeContext[contextEntityId] = activeContext[entityIdentifier]
      }
      executeUiPolicy(activeContext, kagamiFormModel);
      if(!notRequiredBusinessPolicy){
        executeBusinessPolicy(activeContext, kagamiFormModel,currentControl);
      }
    }
  }
}

export function callPoliciesForEmbedTriggers(kagamiFormModel: KagamiFormModel) {
  let entityId: any, formPresentationModel: any;
  for ([entityId, formPresentationModel] of Object.entries(kagamiFormModel.formControls)) {
    if (formPresentationModel instanceof KagamiEmbeddedFormPresentationModel) {
      let embedTriggers = formPresentationModel.embedTriggers;
      let triggerName: string, triggerModel: any;
      for ([triggerName, triggerModel] of Object.entries(embedTriggers)) {
        if (CommonUtils.isNotEmpty(triggerModel.policy)) {
          let enableTrigger = enableTriggerByPolicy(triggerModel.policy, formModel.formData.formData);
          if (enableTrigger !== triggerModel.enableTrigger) {
            if (triggerModel.state) {
              triggerModel.state.setEnableTrigger(enableTrigger);
            } else {
              triggerModel.enableTrigger = enableTrigger;
            }
          }
        }
      }

      let gridModel = formPresentationModel.kagamiGridModels.get(formPresentationModel.formEntityId);
      if(gridModel){
        // handleFloatingActions('',gridModel,true,true);
      }

    }
  }
}

export function setFormModel(kagamiFormModel: KagamiFormModel) {
  formModel = kagamiFormModel;
}

export function clearFormModel(kagamiFormModel : KagamiFormModel) {
  console.log('clearing the formModel');
  kagamiFormModel.clearFormData(kagamiFormModel);
}
export function initOrReplaceFormControlForEntity(formPresentationModel: KagamiFormPresentationModel) {
  getFormModel().formControls[formPresentationModel.formEntityId] = formPresentationModel;
}

export function getControlWidth(uiSettings :any){
  const fieldWidth = uiSettings ? uiSettings.fieldWidth?.value : null
    if(fieldWidth) {
      if(fieldWidth === 1 || fieldWidth === 5 ||fieldWidth === 7 ||  fieldWidth === 9 || fieldWidth === 10 ||fieldWidth === 11 ){
        let uiSettingsClassName = `col`
        return uiSettingsClassName
      } else if(fieldWidth === 4){
        let uiSettingsClassName = `col_3`
        return uiSettingsClassName
      } else {
        let uiSettingsClassName = `col_${fieldWidth}`
        return uiSettingsClassName
      } 
    }else {
      let uiSettingsClassName = `col_4`
      return uiSettingsClassName
    }
}