import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiAboutUsStyle = (theme: Theme) =>
  createStyles({
    aboutUs_popover: {
        // // position: 'fixed',
        // width: theme.spacing(25),
        // right: theme.spacing(1),
        // top : theme.spacing(4),
        // padding :theme.spacing(0),
        // margin : theme.spacing(0),
        //  display: 'flex',
        // justifyContent:'left',
      },

      aboutUs_text: {
        // position: 'fixed',
        //  width: theme.spacing(40),
        
        // padding :theme.spacing(0.25),
        // margin : theme.spacing(0),
        // display: 'flex',
        // justifyContent:'start',
      },

    aboutUs_kagamiLogo : {
      padding :theme.spacing(0.5),
      //border : '1px solid grey',
      display: 'block',
      alignItems:'left',
      justifyContent:'left',
      boxShadow : '2px solid black',
      margin:'20px'

    },

    aboutUs_bottomCard : {
      margin : '20px',
      border : '1px solid #ccc',
      display: 'block',
      alignItems:'left',
      justifyContent:'start',
      //boxShadow : '2px solid black',
      padding :theme.spacing(1),
      fontFamily : "open sans",
      color : '#999',
      fontSize : '14px',
      boxShadow: '0px 0px 2px 1px #ccc',
      borderRadius: '6px',
      wordBreak: 'break-word',
    },
  }
     
  );


