import { makeStyles } from '@mui/styles';
import uniqueId from 'lodash/uniqueId';

import { ReportLandingPageModelProps } from '../../../model/ReportsLandingPageModel';
import { reportLandingPageBodyContent } from '../style/BodyContentStyle';
import { buildLandingPage } from '../../ReportPageBuilder';
import { buildReportsPageController } from '../../ReportsPage/controller/ReportsPageController';

const useStyles = makeStyles(reportLandingPageBodyContent);

export const BodyContent = (props: ReportLandingPageModelProps) => {
  const classes = useStyles();
  return (
    <div className={classes.reportBodyContent}>
      {props.reportLandingPageModel.isContentLoaded ? (
        props.reportLandingPageModel.fromHeader ? (
          props.reportLandingPageModel.content
        ) : (
          buildReportsPageController(
            props.reportLandingPageModel.content,
            false,
            props?.reportLandingPageModel?.translations
          )
        )
      ) : (
        <div className={classes.reportLandingPage} key={uniqueId('reports-page')}>
          {buildLandingPage(props.reportLandingPageModel)}
        </div>
      )}
    </div>
  );
};
