export class KagamiNotificationModel {
    title: any;
    content: any;
    
    selectTab: any;
    state: any;
  
    constructor(title: any) {
      this.title = title;
      this.content = [];
      this.selectTab = 'TODO';
    }
  }
  
  export interface KagamiNotificationProps {
    kagamiNotificationModel: KagamiNotificationModel;
  }