import { KagamiRadioModel, RadioFieldProps } from '../model/kagamiRadioModel';
import { useKagamiControlModelState } from '../../model/kagamiControlModel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiRadioFieldStyle } from '../styles/KagamiRadioFieldStyle';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { getDisplayDirection, handleRadioButtonOnChange } from '../controller/kagamiRadioController';
import { extractDropdownOption, getOptionsData } from '../../../../service/PresentationService';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';

export const buildRadioButtons = (kagamiRadioModel: KagamiRadioModel) => {
  return (
    <KagamiRadioField
      key={'KagamiRadioField' + kagamiRadioModel.controlIdentifier}
      kagamiRadioModel={kagamiRadioModel}
    ></KagamiRadioField>
  );
};

const useStyles = makeStyles(KagamiRadioFieldStyle);

const KagamiRadioField = (props: RadioFieldProps) => {
  const classes = useStyles();
  let fieldState = useKagamiControlModelState(props.kagamiRadioModel.createState);
  props.kagamiRadioModel.state = fieldState;
  const uiSettingsClassName = props.kagamiRadioModel.getControlWidth(props.kagamiRadioModel.uiSettings);
  const fontProperties = props.kagamiRadioModel.getFontStyling(props.kagamiRadioModel.uiSettings);
  const displayDirectionRow: boolean = getDisplayDirection(props.kagamiRadioModel.uiSettings);
  // const displayDirectionRow = props.kagamiRadioModel.getDisplayDirection(props.kagamiRadioModel.uiSettings)
  getFormModel().formControlStates[props.kagamiRadioModel.controlIdentifier] = fieldState;
  let getExtraOptionsValue = getOptionsData(props.kagamiRadioModel.attributePresentation);

  const ReadOnlyOption = CommonUtils.isNotEmpty(
    extractDropdownOption(props.kagamiRadioModel.attributePresentation, props.kagamiRadioModel.associatedObject)
  )
    ? extractDropdownOption(props.kagamiRadioModel.attributePresentation, props.kagamiRadioModel.associatedObject)
    : '---';
  if (fieldState.isVisible === false) {
    return null;
  } else {
    return (
      <div className={`${uiSettingsClassName} `}>
        <div className={`${classes.readOnlyField_cont} ${classes.radio_field}`}>
          {/* <FormLabel  component="span" className = {classes.controlLabel}>{props.kagamiRadioModel.controlDisplayName}</FormLabel> */}
          {props.kagamiRadioModel.isGridField ? null : (
            <FormLabel
              component="span"
              className={classes.controlLabel}
              required={fieldState.isMandatory}
              style={{
                color: fontProperties.labelFontColor,
                fontWeight: fontProperties.labelFontBold,
                fontStyle: fontProperties.labelFontItalic,
                textDecoration: fontProperties.labelFontUnderline
              }}
            >
              {props.kagamiRadioModel.controlDisplayName}
            </FormLabel>
          )}
          {(!props.kagamiRadioModel.state.isReadOnly || props.kagamiRadioModel.isEmbddedField) ?
            getExtraOptionsValue ? (
              <RadioGroup
                row={displayDirectionRow}
                className={`${fieldState.selectValues?.length > 0 ? classes.radio_group : ''}`}
                // value={props.kagamiRadioModel.state.controlValue}
                style={{ color: fontProperties.controlFontColor }}
                // helperText={fieldState.errorMessage ?fieldState.errorMessage:null}
                onChange={(e, value) => handleRadioButtonOnChange(props.kagamiRadioModel, value)}
              >
                {/* {CommonUtils.isNotEmpty(fieldState.selectValues) ? (
                fieldState.selectValues.map((value: any, index: any) => {
                  let radioValue = value;
                  return (
                    <FormControlLabel
                      key={value['id']}
                      value={value[props.kagamiRadioModel.entityAssociatedAttribute]}
                      // sx={{pl: 2}}
                      control={<Radio size="small" />}
                      onKeyPress={(event: any) => {
                        if (event.code === 'Enter') {
                          handleRadioButtonOnChange(
                            props.kagamiRadioModel,
                            radioValue[props.kagamiRadioModel.entityAssociatedAttribute]
                          );
                        }
                      }}
                      label={extractDropdownOption(props.kagamiRadioModel.attributePresentation, value)}
                      disabled={props.kagamiRadioModel.state.isReadOnly}
                    />
                  );
                })
              ) : CommonUtils.isNotEmpty(getExtraOptionsValue) ? (
                Object.values(getExtraOptionsValue).map((option: any, index: any) => {
                  let radioValues = option;
                  return (
                    <FormControlLabel
                      key={index}
                      value={radioValues}
                      label={radioValues}
                      disabled={props.kagamiRadioModel.state.isReadOnly}
                      // sx={{pl: 2}}
                      control={<Radio size="small" />}
                    />
                  );
                })
              ) : (
                <></>
              )} */}
                {CommonUtils.isNotEmpty(getExtraOptionsValue) ? Object.values(getExtraOptionsValue).map((option: any, index: any) => {
                  let radioValues = option;
                  return (
                    <FormControlLabel
                      key={index}
                      value={radioValues}
                      label={radioValues}
                      disabled={props.kagamiRadioModel.state.isReadOnly}
                      control={<Radio size="small" />}
                    />
                  );
                }) : <></>}
              </RadioGroup>
            ) :
              (<RadioGroup
                row={displayDirectionRow}
                className={`${fieldState.selectValues?.length > 0 ? classes.radio_group : ''}`}
                value={props.kagamiRadioModel.state.controlValue}
                style={{ color: fontProperties.controlFontColor }}
                // helperText={fieldState.errorMessage ?fieldState.errorMessage:null}
                onChange={(e, value) => handleRadioButtonOnChange(props.kagamiRadioModel, value)}
              >
                {CommonUtils.isNotEmpty(fieldState.selectValues) ?
                  (
                    fieldState.selectValues.map((value: any, index: any) => {
                      let radioValue = value;
                      return (
                        <FormControlLabel
                          key={value['id']}
                          value={value[props.kagamiRadioModel.entityAssociatedAttribute]}
                          // sx={{pl: 2}}
                          control={<Radio size="small" />}
                          onKeyPress={(event: any) => {
                            if (event.code === 'Enter') {
                              handleRadioButtonOnChange(
                                props.kagamiRadioModel,
                                radioValue[props.kagamiRadioModel.entityAssociatedAttribute]
                              );
                            }
                          }}
                          label={extractDropdownOption(props.kagamiRadioModel.attributePresentation, value)}
                          disabled={props.kagamiRadioModel.state.isReadOnly}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
              </RadioGroup>) :
            (
              <p className={classes.readOnlyField} style={{ color: fontProperties.controlFontColor }}>
                <div>
                  {CommonUtils.isNotEmpty(fieldState.controlValue)
                    ? extractDropdownOption(
                      props.kagamiRadioModel.attributePresentation,
                      props.kagamiRadioModel.associatedObject
                    )
                    : '---'}
                </div>
                {/* props.kagamiRadioModel.selectValues */}
                {/* {extractDropdownOption(props.kagamiRadioModel.attributePresentation,fieldState.selectValues[0])} */}
              </p>
            )}
          {fieldState.errorMessage ? (
            <span style={{ color: '#f05252', fontSize: '12px' }}>{fieldState.errorMessage}</span>
          ) : null}
        </div>
      </div>
    );
  }
};
