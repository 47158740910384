import {getStaticFilterData,getLedgerReportData,getStaticFilterInfo} from '../../../../service/KagamiReportService';
import {getReportType,handleStaticReportDates,getSelectedCurrency} from '../../../../service/ReportUtilService';
import _ from 'lodash';

export async function getDBStaticFilterData(dayBookReportController: any) {
    let reportType =  getReportType(dayBookReportController.reportConfig);
    return await getStaticFilterData(reportType);
}
export const makeReport = (dayBookReportController: any,inputReportConfig:any) => {
        let defaultConfig ={
          autoGroupColumnDef: {  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
          columnDefs: [],
          defaultColDef: { flex :1,sortable: true,minWidth: 170,filter: true,resizable: true,editable: false },
          detailCellRendererParams: {
            detailGridOptions: {
              columnDefs: [  ],
              defaultColDef: {
                flex: 1,
              },
            },
            getDetailRowData: (params:any) => {
              params.successCallback(params.data.nestedFinanceRecord);
            },
          },
          detailRowHeight: 190,
          groupDefaultExpanded: -1,//expand everything by default
          groupHideOpenParents:true,
          groupIncludeTotalFooter: false,
          getRowStyle: (params:any) => {
            if (params?.node?.rowPinned) {
                return {'font-weight': 'bold'}
            }
          },
          getRowHeight :(params: any) => 30,
          rowHeight:34,
          icons: {
            // groupExpanded:'<i class="fa fa-arrow-down"/>',
            // groupContracted:'<i class="fa fa-user"/>'
            groupContracted:'<div class="kgm-plus-icon radius"></div>',
            groupExpanded:'<div class="kgm-minus-icon radius"></div>'
          },
          masterDetail: true,
          onFirstDataRendered: (params:any) => {
            setTimeout(function () {
              params.api.getDisplayedRowAtIndex(1).setExpanded(true);
            }, 0);
          },
          pivotMode : false,
          pagination: true,
          paginationPageSize: 50,
          pinnedBottomRowData: [],
          rowData:[],
          suppressAggFuncInHeader: true, //hide aggregate name in a row 
        };
        let userConfig = prapareUserConfigForReport(dayBookReportController,defaultConfig);  
        let reportConfig = _.extend(true, {}, defaultConfig, userConfig);
        return reportConfig;

} 


function prapareUserConfigForReport (listReportController: any,defaultConfig:any) {
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    let columnDefs: any = [];
    columnDefs =[
       {headerName: 'Date', field: 'date',cellRenderer: 'agGroupCellRenderer' },
       {headerName: 'Particulars', field: 'type'},
       {headerName: 'Project Name', field: 'projectName'},
       {headerName: 'Reference No', field: 'referenceNo'},
       {headerName: 'Voucher No', field: 'docno',minWidth:'200',cellStyle:{'font-weight':'bold','text-decoration':'underline'}},
       {headerName: 'Voucher Type', field: 'transtype',minWidth:'200'},
       {headerName: 'Debit', field: 'debit',type: 'rightAligned'},
       {headerName: 'Credit', field: 'credit',type: 'rightAligned'},
      ]
    userConfig['columnDefs'] = columnDefs;
    defaultConfig['detailCellRendererParams']['detailGridOptions']['columnDefs'] = [
      {headerName: '', field: 'accountName'},
      {headerName: '', field: 'accountValue'},
    ]
    return userConfig;
  }

    export const  handledColumnsBasedOnProjects = (reportInfo:any,ledgerData:any) => {
      if(ledgerData) {
        var projectNames = _.filter(ledgerData,function(o) { return _.has(o,'projectName')})
        if(projectNames && projectNames.length == 0 && reportInfo.columnDefs){
          reportInfo.columnDefs = _.filter(reportInfo.columnDefs,function(o) { return o.field !='projectName' })
        }
        var referenceNos = _.filter(ledgerData,function(o) { return _.has(o,'referenceNo')})
        if(referenceNos && referenceNos.length == 0 && reportInfo.columnDefs){
          reportInfo.columnDefs = _.filter(reportInfo.columnDefs,function(o) { return o.field !='referenceNo' })
        }
      }
    }
  

    export function makeLedgerData( reportModel:any,dbReportData: any) {
      let currency = getSelectedCurrency(reportModel.reportConfig);
      return getReportData(_.cloneDeep(dbReportData), currency) || [];
    }
    export const getData = async (reportModel :any,inputReportConfig: any) => {
      inputReportConfig['templateType'] =  inputReportConfig?.ktreeReport?.templateType || null;
      if((!inputReportConfig.state || inputReportConfig.state.length == 0 ) && (reportModel?.reportData?.staticFilterData?.data?.state)){
        inputReportConfig['state'] = "NOPOP";
      }
      if((!inputReportConfig.projectPlan || inputReportConfig.projectPlan.length == 0) && (reportModel?.reportData?.staticFilterData?.data?.projectPlan)){
        inputReportConfig['projectPlan'] = "NOPOP";
      }
    handleStaticReportDates(inputReportConfig);
    let res = await getLedgerReportData(inputReportConfig);
    if (res?.data) {
        return res.data['financeReportRow'];
    }
    return [];
    };

  const customCreditOrDebitCellFormatter =  ( cellvalue:any,currency:any) => {
    if(cellvalue){
     let locale  = (currency && currency =='INR') ? 'en-IN' :'en-US';
     let changedVal =parseFloat(cellvalue).toLocaleString(locale);
     if(cellvalue.split(".").length>0 && cellvalue.split(".")[1]=="00"){
        changedVal +=".00";
     }
     return changedVal;
    }
    return "";
  }

  const getReportData = (financeReportRow:any,currency:any) => {
    _.forEach(financeReportRow, function (ledgerRow) {
      if (ledgerRow.credit) {
        ledgerRow.credit = customCreditOrDebitCellFormatter(ledgerRow.credit,currency);
      }
      if (ledgerRow.debit) {
        ledgerRow.debit = customCreditOrDebitCellFormatter(ledgerRow.debit,currency);
      }
      if (ledgerRow.balance) {
          let withCommas = customCreditOrDebitCellFormatter(ledgerRow.balance,currency);
          // ledgerRow.balance = currency + " " + withCommas;
          ledgerRow.balance = withCommas;

          if(ledgerRow.balanceType){
          // ledgerRow.balance = currency + " " + withCommas +" "+ledgerRow.balanceType;
            ledgerRow.balance = withCommas +" "+ledgerRow.balanceType;
          }
      }
      if (ledgerRow.date) {
            ledgerRow.date = ledgerRow.date.split(" ")[0];
      }
    })
    return financeReportRow || [];
  }

  export function makeFooterRowData (dbReportData: any) {
    let footerRows = [
      { transtype : 'Current Total : ','debit':getAggregationValue('DEBIT',dbReportData),'credit':getAggregationValue('CREDIT',dbReportData)}, 
    ];
    return footerRows;
  }
  export const onChangeState = async (state:any,dayBookReportController:any) => {
    let reportType =  getReportType(dayBookReportController.reportConfig);
    if(reportType && state && state.length >0) {
        let QueryParam='';
        QueryParam = QueryParam.concat("ledgerType=" + reportType +'&');
        QueryParam = QueryParam.concat("dependant=" + "state|" + state.join(","));
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
          return _.cloneDeep(staticData.data.projectPlan || []);
        }
    }
    return [];
}
/* FOR FOOTER */
const displayOpeningBalance = (type:any,financeReportRow:any) => {
  if(financeReportRow && financeReportRow.length > 0){
      let value= financeReportRow[0];
      return type =='CREDIT'  ? (customCreditOrDebitCellFormatter(value.credit,'')): (customCreditOrDebitCellFormatter(value.debit,''));
  }   
  return "";
}

 const displayClosingBalance = (type:any,financeReportRow:any ) => {
  if(financeReportRow && financeReportRow.length > 0){
      let value= financeReportRow[financeReportRow.length-1];
     if(type=='CREDIT') {
          if(value.balanceType=="Cr" && value.balance){
            // return value.balance +" Cr";
            return customCreditOrDebitCellFormatter(value.balance ,"")+" Cr";
          }else { return ""};
     }else if(type=='DEBIT'){
        if(value.balanceType=="Dr" && value.balance){
          // return value.balance +" Dr";
          return customCreditOrDebitCellFormatter(value.balance ,"")+" Dr";
         }else { return ""};
    }
  }   
  return "";
}
const getAggregationValue = (type: any, financeReportRow: any) => {
  if (financeReportRow && financeReportRow.length > 0) {
    let totalVal = 0;
    _.forEach(financeReportRow, function (rec,index:any) {
      // if(index !=0  ) {
        if (type == 'CREDIT' && rec.credit) {
          totalVal += parseFloat(rec.credit);
        } else if (type == 'DEBIT' && rec.debit) {
          totalVal += parseFloat(rec.debit);
        }
      // }
    });
    if (totalVal) {
      return customCreditOrDebitCellFormatter(""+totalVal, '');
    }
  }
  return '';
};



/* END  FOR FOOTER */
