import { State, useState } from '@hookstate/core';

export class ReportModalModel {
  title: string;
  content: any;
  open: boolean;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  height: ReportModalHeight;
  listOfButton: any[];
  defaultCancelButton: boolean;
  crossIcon: boolean;
  state: any;
  translations: any;
  constructor() {
    this.title = 'Preview';
    this.open = false;
    this.listOfButton = [];
    this.defaultCancelButton = true;
    this.crossIcon = true;
    this.maxWidth = 'lg';
    this.height = ReportModalHeight.large;
  }
}

export enum ReportModalHeight {
  small = 40,
  medium = 60,
  large = 80
}

export interface ReportModalModelProps {
  reportModalModel: ReportModalModel;
}

export const useReportModalState = (state: State<ReportModalModel>) => {
  const reportModalState = useState(state);

  return {
    get open() {
      return reportModalState.open.get();
    },
    setOpen(open: boolean) {
      reportModalState.open.set(open);
    }
  };
};

// export type ReportModalCustomButton = {
//     buttonName:string;
//     disabledButton:boolean;
//     clickFunction: Function;
//     buttonClassName?:any;
// }

export class ReportModalCustomButton {
  buttonName: string;
  disabledButton: boolean;
  clickFunction: Function;
  buttonClassName?: any;
  state: any;
  constructor(btnName: string, disabledBtn: boolean, callbackFunction: Function) {
    this.buttonName = btnName;
    this.disabledButton = disabledBtn;
    this.clickFunction = callbackFunction;
  }
}

export interface ReportModalCustomButtonProps {
  reportModalCustomButton: ReportModalCustomButton;
}

export const useReportModalCustomButton = (state: State<ReportModalCustomButton>) => {
  const reportModalCustomButtonState = useState(state);
  return {
    get disabledButton() {
      return reportModalCustomButtonState.disabledButton.get();
    },
    setDisabledButton(disabledButton: boolean) {
      reportModalCustomButtonState.disabledButton.set(disabledButton);
    }
  };
};
