import { useMemo } from "react";
import { buildEmbedTrigger } from "../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/controller/KagamiEmbedTriggerController"
import { KagamiEmbedTriggerModel } from "../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/model/KagamiEmbedTriggerModel";
import { BuildNonFieldPresentations } from "../../../../../form/builder/NonFieldPresentationBuilder";

 

 const actionCellRenderer = (props: any) => {
    let trigerComponents = []
    let editTriggerModel: KagamiEmbedTriggerModel = props.editTrigger
    let deleteTriggerModel: KagamiEmbedTriggerModel = props.deleteTrigger
    
    if (editTriggerModel !== null) {
        // console.log('edit record id ----' + props.data.id)

        editTriggerModel.embeddedData = props.data;
        trigerComponents.push(buildEmbedTrigger(editTriggerModel))
    }
    if (deleteTriggerModel !== null) {
        // console.log('del record id ----' + props.data.id)
        
        deleteTriggerModel.embeddedData = props.data;
        trigerComponents.push(buildEmbedTrigger(deleteTriggerModel))
    }

    trigerComponents.push(<BuildNonFieldPresentations key="nonFieldPresentation" kagamiGridModel={props.kagamiGridModel} isPickAttributeButtonVisible={true} isActionColumn={true} embedData={props.data}/>)
    return trigerComponents
}

export default actionCellRenderer;