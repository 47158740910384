 import {State, useState } from '@hookstate/core' 
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiClearFiltersButtonModel } from '../buliders/clearFilterButton/model/ClearFiltersModel';

export class KagamiListFilterModel {

    isDataLoaded :boolean;
    state :any;
    filterData : any;
    isPickItem : boolean; 
    searchObj: any;
    isListFiltersApplied: boolean;
    //  presentationFilters: Map<string,{}>
   presentationFiltersApplied : any[]
   clearFiltersModel : KagamiClearFiltersButtonModel
   dummyStateForrerender: boolean;
   showFilters: boolean;
   isWidget: boolean;

  constructor() {
    this.isDataLoaded = false
    this.filterData = null;
    this.isPickItem = false;
    this.searchObj = {};
    this.isListFiltersApplied = false
    this.clearFiltersModel = new KagamiClearFiltersButtonModel();
    this.presentationFiltersApplied = [];
    this.dummyStateForrerender = false;
    this.showFilters = true;
    this.isWidget = false;
    }
}



export interface KagamiListFilterModelProps {
    kagamiListFilterModel: KagamiListFilterModel;
    
}


export const wrapListFiltersState = (state: State<KagamiListFilterModel>) => {
    let filterState = useState(state);
  
    return {
        getIsListFilterDataLoaded(){
           return filterState.isDataLoaded.get();
        },

        SetIsListFilterDataLoaded(value :boolean){
            filterState.isDataLoaded.set(value)
        },
        get changeStateToRenderAllfilters() {
            return filterState.dummyStateForrerender.get()
        },
        SetChangeStateToRenderAllfilters(value :boolean){
            filterState.isDataLoaded.set(value)
        },
        get isListFiltersApplied(){
            return filterState.isListFiltersApplied.get();
         },
 
         SetIsListFiltersApplied(value :boolean){
             filterState.isDataLoaded.set(value)
         },
         
        get searchObj() {
            return filterState.searchObj.get();
        },
        SetSearchObj(value: any){
            filterState.searchObj.set(value)
        },
        getShowFilters(){
            return filterState.showFilters.get();
        },
        setShowFilters(value:boolean){
            filterState.showFilters.set(value);
        }
 
    };
  };
  
  export const useListFiltersState = (state:State<KagamiListFilterModel>) => {
    const kagamiListFilterState = wrapListFiltersState(useState(state));
    KagamiStateManager.addStateToHolder(SystemConstants.GRID_LIST_FILTER_STATE, kagamiListFilterState);
    return kagamiListFilterState
  }