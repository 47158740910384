import { RuntimeInput } from '../../../model/form/runtime_input';

export class KagamiProcessRequestModel {
  processName: string;
  changedAttribute: string;
  entityPresentation: any;
  changedData: any;
  data: any;
  runtimeInput: RuntimeInput;
  eventType: EventType;

  constructor(
    processName: string,
    changedAttribute: string,
    entityPresentation: any,
    changedData: any,
    data: any,
    eventType: EventType
  ) {
    this.processName = processName;
    this.changedAttribute = changedAttribute;
    this.entityPresentation = entityPresentation;
    this.changedData = changedData;
    this.data = data;
    this.eventType = eventType;
    this.runtimeInput = new RuntimeInput('', '', '');
  }
}

export enum EventType {
  onLoad,
  onChange,
  onCancel,
  onSearch
}
