import { createState } from "@hookstate/core";
import makeStyles from "@mui/styles/makeStyles";
import {
  KagamiImpersonateModel,
  KagamiImpersonateModelProps,
  useKagamiInpasinateState,
} from "../model/impersonateModel";
import { KagamiModalModel } from "../../../../components/modal/model/KagamiModalModel";
import { KagamiInpasinateStyles } from "../style/ippersonateStyles";
import UpdatePasswordTextField from "../../home/builder/body/builder/process/builder/updatePassword/ui/UpdatePasswordTextField";
import { DOMAIN, getImpersonateURL } from "../../../service/URLService";
import { hideLoader } from "../../../../components/loader/controller/KagamiLoaderController";
import { SystemConstants } from "../../../constants/SystemConstants";
import { CommonUtils } from "../../../utils/CommonUtils";
import {
  getKagamiImpersonateModel,
  setTokenForImpersonate,
  setUserIdForImpersonate,
} from "../controller/impersonateController";
import { Button } from "@mui/material";
import { getKagamiProfileModel } from "../../home/builder/header/builder/profile/controller/kagamiProfileController";
import { APIService } from "../../../../service/ApiService";
import { useEffect } from "react";
import { delay } from "lodash";

const useStyles = makeStyles(KagamiInpasinateStyles);

export const buildImpasinate = (
  impersonateModel: KagamiImpersonateModel,
  impersonateModelPopupModel: KagamiModalModel
) => {
  return (
    <KgaamiInpasinate
      impersonateModel={impersonateModel}
      impersonateModelPopupModel={impersonateModelPopupModel}
    />
  );
};

export const KgaamiInpasinate = (props: KagamiImpersonateModelProps) => {
  const classes = useStyles();
  let impersonateState = useKagamiInpasinateState(
    createState(props.impersonateModel)
  );
  props.impersonateModel.state = impersonateState;

  const onChangeHandler = (e: any) => {
    props.impersonateModel.state.setUserId(e.target.value);
    props.impersonateModel.state.setError("");
  };

  const saveHandler = () => {
    let impersonateModel: KagamiImpersonateModel = getKagamiImpersonateModel();
    let impersonateId = props.impersonateModel.userId;
    const loginData = {
      userdomain: DOMAIN,
      userId: impersonateId,
    };
    APIService.getData(SystemConstants.POST, getImpersonateURL(), loginData)
      .then((impersonateResponse: any) => {
        const response = impersonateResponse.data.SUCCESS;
        let responseToken: any;
        console.log("resposne", response);
        if (CommonUtils.isNotEmpty(response)) {
          responseToken = JSON.parse(response);
          hideLoader();
          setTokenForImpersonate(responseToken.token);
          setUserIdForImpersonate(impersonateId);
          CommonUtils._needWindowReload(100);
          impersonateModel.state.setError("");
          props.impersonateModelPopupModel.state.setOpen(false);
          props.impersonateModel.state.setUserId("");
        }
      })
      .catch((err: any) => {
        impersonateModel.state.setError(err?.response?.data?.data?.message);
        props.impersonateModelPopupModel.state.setOpen(true);
      });
  };

  return (
    <>
      <div className={classes.inputContainer}>
        <label className={classes.label}>User Id</label>
        <UpdatePasswordTextField
          className={classes.input}
          size="small"
          type="text"
          value={impersonateState.userId}
          onChange={(e: any) => {
            onChangeHandler(e);
          }}
        />
      </div>
      <h6 style={{ color: "#f05252", fontSize: "12px" }}>
        {CommonUtils.isNotEmpty(impersonateState.error)
          ? impersonateState.error
          : ""}
      </h6>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          onClick={() => saveHandler()}
        >
          Impersonate
        </Button>
      </div>
    </>
  );
};
