import { Autocomplete, Chip, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { MapRolesViewModelProps } from '../model/MapRolesViewPageModel';
import { KagamiReportHeaderModelStyle } from '../../../../ReportHeader/styles/ReportHeaderStyles';
import { enableAndDisableSaveButton } from '../../../service/MapRolesService';
import { translateToLocalistaion } from '../../../../../../../../process/service/PresentationService';


export const MapRolesView = (props: MapRolesViewModelProps) => {
  const classes = makeStyles(KagamiReportHeaderModelStyle)();
  const mapRolesViewModel = props.mapRolesViewModel;
  const [rolesPinned, setRolesPinned] = React.useState(mapRolesViewModel.selectedData);
  const [value, SetValue] = React.useState(null);
  let selectedData: string = '';

  const handleAddReportIcon=()=>{
    if (selectedData !== '') {
      let roleData: any = { reportId: mapRolesViewModel.reportId, roleId: selectedData };
      SetValue(null);
      setRolesPinned((prev: any) => {
        if (
          roleData['roleId'] !== undefined &&
          prev.find((ele: any) => {
            return ele['roleId'] === roleData['roleId'];
          }) === undefined
        ) {
          mapRolesViewModel.allSelectedData.push({
            reportId: roleData.reportId,
            roleId: roleData['roleId'],
            pinned: true,
            opCode: '+'
          });
          return [...prev, roleData];
        } else {
          return [...prev];
        }
      });
      enableAndDisableSaveButton();
      console.log("buttonEnabled")
    }
  }


  const handleDeleteReportIcon=(mapped: any)=>{
    let mapRole: any = null;
    if (rolesPinned.length) {
      setRolesPinned((prev: any) => {
        let allSelectedConfigData: any[] = prev.filter((ele: any) => {
          if (ele.roleId === mapped['roleId']) {
            mapRole = {
              reportId: mapRolesViewModel.reportId,
              roleId: mapped['roleId'],
              pinned: false,
              opCode: '-'
            };
          } else {
            return ele;
          }
        });
        if (mapRole !==null) {
          mapRolesViewModel.allSelectedData.push(mapRole);
        }
        return allSelectedConfigData;
      });
      enableAndDisableSaveButton();
      console.log("buttonEnabled");
    }
  }
  return (
    <>
      <tr style={{ height: '45px' }}>
        <td>{translateToLocalistaion(props.translations, mapRolesViewModel.name)}</td>
        <td>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Autocomplete
              size="small"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              options={translateToLocalistaion(props.translations, mapRolesViewModel.options)}
              getOptionLabel={(options: any) => options || ''}
              sx={{ width: 300 }}
              onChange={(event, child) => {
                if (typeof child === 'string') {
                  selectedData = child;
                }
              }}
              value={value}
              renderInput={(params: any) => <TextField {...params} />}
            />
            <IconButton
              color="success"
              onClick={(event: any) => {
                handleAddReportIcon()
              }}
              size="small"
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </div>
        </td>
        <td>
          {rolesPinned.map((mapped: any) => {
            {
              return (
                <>
                  <Chip
                    deleteIcon={<CloseIcon className={classes.removeIcon} />}
                    size="small"
                    sx={{ borderRadius: '1px', border: '1px solid #6ebe44', marginLeft: '10px' }}
                    variant={'outlined'}
                    key={mapped['reportId']}
                    label={translateToLocalistaion(props.translations,mapped['roleId'])}                    
                    onDelete={(event: any) => {
                      handleDeleteReportIcon(mapped);
                    }}
                  />
                </>
              );
            }
          })}
        </td>
      </tr>
    </>
  );
};
