import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const activityStyle = (theme: Theme) =>
  createStyles({
    activity: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: theme.spacing(0.5),
      padding: theme.spacing(1)
    },

    activitybody: {
      display: 'flex',
      flexDirection: 'column',
      width: 'inherit'
    },
    user_info: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    datetime: {
      marginLeft: 'auto'
    },
    user_comment: {},
    activityActions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    edit: {
      marginLeft: 'auto'
    },
    reply: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    lightText: {
      color: theme.palette.grey[500]
    },
    activity_heading: {
      color: theme.palette.grey[500],
      fontWeight: theme.typography.h6.fontWeight
    },
    activityLog_input: {
      '& .MuiOutlinedInput-root ': {
        padding: 0
      }
    },
    reply_btn: {
      background: theme.palette.success.light,
      cursor: 'pointer',
      height: '33px'
    },
    cancel_btn: {
      background: theme.palette.error.main,
      cursor: 'pointer',
      height: '35px'
    },
    reply_Form: {
      width: '100%',
      display: 'flex'
    }
  });
