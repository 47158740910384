import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { memo, useCallback } from 'react';

import { KagamiActivityLogModel } from '../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel';
import {
  getActivityLogData,
  getListChatInfo,
  getStatusInfo
} from '../../../../../../../../../../components/activityLog/service/ActivityLogService';
import { buildModal } from '../../../../../../../../../../components/modal/controller/KagamiModalController';
import { KagamiModalModel } from '../../../../../../../../../../components/modal/model/KagamiModalModel';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { getDmsViewUrl } from '../../../../../../../../../service/URLService';
import { CommonUtils } from '../../../../../../../../../utils/CommonUtils';
import { isTypeDate } from '../../../../../../../../../utils/DateUtils';
import {
  getGridCellPresentations,
  handleRequestProcess,
  isNumber,
  removeLastLetter
} from '../../../../process/builder/list/builder/grid/service/GridUtilService';
import {
  extractDropdownOption,
  getAttributeLabel,
  getAttributeName,
  getAttributePresentations,
  getAttributePresentationType,
  getAttributeValidations,
  getBusinessPolicy,
  getDefaultListView,
  getDisplayAttrForEmbedSearch,
  getDisplayAttributes,
  getEmbeddedPresentationId,
  getEntityAssociatedAttribute,
  getEntityConsumed,
  getEntityConsumedInAttribute,
  getEntityPrefix,
  getForAttrFromPresentation,
  getGridAttributeType,
  getHtmlControl,
  getPolicyMap,
  getPrefixtypes,
  getPresentationPolicy,
  isOnChangeRequiredForAttribute,
  isOnLoadRequiredForPresentation,
  isReadOnlyAttribute,
  isRequiredAttribute,
  isTypeText,
  isVisibleAttribute,
  isVisiblePresentation,
  retrievePresentation
} from '../../../service/PresentationService';
import { KagamiControlModel } from '../../controls/model/kagamiControlModel';
import { buildEmbedTrigger } from '../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/controller/KagamiEmbedTriggerController';
import { KagamiEmbedTriggerModel } from '../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/model/KagamiEmbedTriggerModel';
import { editRecord } from '../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController';
import { getFormModel } from '../../form/controller/KagamiFormController';
import { KagamiEmbeddedFormPresentationModel } from '../../form/model/KagamiEmbeddedFormPresentationModel';
import { KagamiFormModel } from '../../form/model/KagamiFormModel';
import {
  getActiveFormRecord,
  getAssociatedObject,
  handleGridOnChange,
  updateInFocusMap
} from '../../form/service/FormService';
import { makeDefaultValidation } from '../../form/service/FormValidationService';
import { KagamiGroupModel } from '../../group/model/KagamiGroupModel';
import { getGroupModel } from '../../group/service/GroupService';
import { KagamiTriggersController } from '../../Triggers/controller/KagamiTriggersController';
import { getKagamiListModel, setKagamiListModel } from '../controller/KagamiListController';
import { KagamiListModel, ListModelProps } from '../model/KagamiListModel';
import {
  enableHeaderCheckbox,
  fetchEmbedRecords,
  getEndRecord,
  getListTotalRecords,
  getStartRecord,
  getValueForTextPickAttribute,
  haveHyperlink
} from '../service/kagamiListService';
import { KagamiListStyle } from '../style/ListStyle';
import { buildCalendar } from './calendar/controller/KagamiCalendarViewController';
import { KagamiCalendarViewModel } from './calendar/model/KagamiCalendarViewModel';
import { buildCard } from './card_view/controller/KagamiCardViewController';
import { KagamiCardViewModel } from './card_view/model/KagamiCardViewModel';
import { buildCrossTab } from './crosstab/controller/KagamiCrossTabViewController';
import { KagamiCrossTabViewModel } from './crosstab/model/KagamiCrossTabViewModel';
import { KagamiGridPaginationModel } from './grid/builder/pagination/model/KagamiGridPaginationModel';
import { buildGrid } from './grid/controller/KagamiGridController';
import { KagamiGridModel } from './grid/model/KagamiGridModel';
import { buildGridControlModels, setActiveRowNode } from './grid/service/GridService';
import { getDispPresentationRuleConfig } from './grid/service/ProcessHelperService';
import { buildHierarchy } from './hierarchy_view/controller/KagamiHierarchyViewController';
import { KagamiHierarchyViewModel } from './hierarchy_view/model/KagamiHierarchyViewModel';
import { buildKanbann } from './kanbann/controller/KagamiKanbanSectionsController';
import { KagamiKanbanSectionsViewModel } from './kanbann/model/KagamiKanbanSectionsModel';
import { buildListFilterComponent } from './presentationFilters/controller/KagamiListFilterController';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import KagamiThumbnailCellRenderer from './grid/builder/cell_renderers/ThumbnailCellRenderer';
import React from 'react';
import KagamiTextCellRenderer from './grid/builder/cell_renderers/KagamiTextCellRenderer';
import listEmbedFieldRenderer from './grid/builder/cell_renderers/ListEmbedFieldRenderer';
import KagamiNumberCellRenderer from './grid/builder/cell_renderers/KagamiNumberCellRenderer';
import kagamiImageCellRenderer from './grid/builder/cell_renderers/KagamiImageRenderer';
import KagamiStatusCellRenderer from './grid/builder/cell_renderers/KagamiStatusCellRenderer';
import KagamiActivityLogCellRenderer from './grid/builder/cell_renderers/KagamiActivityLogCellRenderer';
import checkboxCellRenderer from './grid/builder/cell_renderers/CheckboxCellRenderer';
import { ColDef, ColGroupDef, Column } from 'ag-grid-community';
import { buildListFilterWidget } from './presentationFilters/buliders/listPresentationFilterWidget/controller/ListFilterWidgetController';
import { keys } from 'lodash';
import KagamiBarCodeCellRenderer from './grid/builder/cell_renderers/KagamiBarCodeCellRenderer';
const useStyles = makeStyles(KagamiListStyle);

export function buildListPresentation(kagamiListModel: KagamiListModel, templateType?: string) {
  //maintaining this value in kagamiListService and removing this local storage value to handle embed list modal pop up
  localStorage.removeItem(SystemConstants.MAIN_PRESENTATION_ID);
  let viewType: string = CommonUtils.isNotEmpty(templateType)
    ? templateType
    : getDefaultListView(kagamiListModel.onStartData);
  kagamiListModel.listTemplate = viewType;
  kagamiListModel.docInfo = kagamiListModel.onStartData.constructOutputData['docInfo']; //getting documents  data from onstartdata
  switch (kagamiListModel.listTemplate) {
    case SystemConstants.LIST_VIEW_TYPE:
    case SystemConstants.GRID_VIEW_TYPE:
      let paginationModel = new KagamiGridPaginationModel(
        false,
        false,
        getListTotalRecords(kagamiListModel.onStartData),
        getStartRecord(kagamiListModel.onStartData),
        getEndRecord(kagamiListModel.onStartData),
        kagamiListModel.mainEntityId,
        kagamiListModel.processName,
        retrievePresentation(kagamiListModel.onStartData)
      );

      let kagamiGridModel: KagamiGridModel = new KagamiGridModel(
        kagamiListModel.processName,
        kagamiListModel.processStepName,
        kagamiListModel.mainEntityId,
        kagamiListModel.presentation,
        paginationModel
      );
      kagamiGridModel.isGroupListModel = kagamiListModel.isGroupListModel;
      kagamiGridModel.detailedObjects = kagamiListModel.detailedObjects;
      kagamiGridModel.isListLoaded = kagamiListModel.isListLoaded;
      kagamiGridModel.isFirst = kagamiListModel.isListLoaded;
      kagamiGridModel.kagamiListHeaderModel = kagamiListModel.kagamiListHeaderModel;
      kagamiGridModel.kagamiListHeaderModel.headerloaded = kagamiListModel.kagamiListHeaderModel.headerloaded;
      kagamiGridModel.triggersModel = kagamiListModel.triggersModel;
      kagamiGridModel.docInfo = kagamiListModel.docInfo;
      kagamiGridModel.isPickItemListModel = kagamiListModel.isPickItemListModel;
      kagamiGridModel.totalRecords = getListTotalRecords(kagamiListModel.onStartData);
      kagamiListModel.childListModel = kagamiGridModel;
      setKagamiListModel(kagamiGridModel);
      getKagamiListModel().onStartData = kagamiListModel.onStartData;
      kagamiGridModel.hasHyperlink = haveHyperlink(kagamiGridModel.presentation);
      if (kagamiGridModel.isGroupListModel) {
        let groupModel: KagamiGroupModel = getGroupModel();
        groupModel.activeGroupModel = kagamiGridModel;
      }
      buildGridControlModels(kagamiGridModel);
      return buildGrid(kagamiGridModel);
    case SystemConstants.CARD_VIEW_TYPE:
      let cardViewModel: KagamiCardViewModel = new KagamiCardViewModel(
        kagamiListModel.processName,
        kagamiListModel.processStepName,
        kagamiListModel.mainEntityId,
        kagamiListModel.presentation
      );
      cardViewModel.isGroupListModel = kagamiListModel.isGroupListModel;
      cardViewModel.detailedObjects = kagamiListModel.detailedObjects;
      cardViewModel.isListLoaded = kagamiListModel.isListLoaded;

      cardViewModel.kagamiListHeaderModel = kagamiListModel.kagamiListHeaderModel;
      cardViewModel.kagamiListHeaderModel.headerloaded = kagamiListModel.kagamiListHeaderModel.headerloaded;
      cardViewModel.triggersModel = kagamiListModel.triggersModel;
      setKagamiListModel(cardViewModel);
      getKagamiListModel().onStartData = kagamiListModel.onStartData;
      return buildCard(cardViewModel);
    case SystemConstants.CALENDAR_VIEW_TYPE:
    case SystemConstants.GANTT_VIEW_TYPE:
      let calendarViewModel: KagamiCalendarViewModel = new KagamiCalendarViewModel(
        kagamiListModel.processName,
        kagamiListModel.processStepName,
        kagamiListModel.mainEntityId,
        kagamiListModel.presentation
      );
      calendarViewModel.isGroupListModel = kagamiListModel.isGroupListModel;
      calendarViewModel.detailedObjects = kagamiListModel.onStartData.constructOutputData.detailedObject;
      calendarViewModel.isListLoaded = !isOnLoadRequiredForPresentation(kagamiListModel.presentation);

      calendarViewModel.kagamiListHeaderModel = kagamiListModel.kagamiListHeaderModel;
      calendarViewModel.kagamiListHeaderModel.headerloaded = !isOnLoadRequiredForPresentation(
        kagamiListModel.presentation
      );
      calendarViewModel.isCustomField = kagamiListModel.isCustomField;
      calendarViewModel.customFieldAttrName = kagamiListModel.customFieldAttrName;
      // calendarViewModel.isListLoaded = true;
      calendarViewModel.triggersModel = kagamiListModel.triggersModel;
      setKagamiListModel(calendarViewModel);
      getKagamiListModel().onStartData = kagamiListModel.onStartData;
      if (calendarViewModel.isGroupListModel) {
        let groupModel: KagamiGroupModel = getGroupModel();
        groupModel.activeGroupModel = calendarViewModel;
      }
      return buildCalendar(calendarViewModel);
    case SystemConstants.KANBAN_VIEW_TYPE:
      let kanbannViewModel: KagamiKanbanSectionsViewModel = new KagamiKanbanSectionsViewModel(
        kagamiListModel.processName,
        kagamiListModel.processStepName,
        kagamiListModel.mainEntityId,
        kagamiListModel.presentation
      );
      kanbannViewModel.isGroupListModel = kagamiListModel.isGroupListModel;
      kanbannViewModel.detailedObjects = kagamiListModel.onStartData.constructOutputData.detailedObjects;
      kanbannViewModel.triggersModel = kagamiListModel.triggersModel;
      kanbannViewModel.isListLoaded = kagamiListModel.isListLoaded;

      kanbannViewModel.kagamiListHeaderModel = kagamiListModel.kagamiListHeaderModel;
      kanbannViewModel.kagamiListHeaderModel.headerloaded = kagamiListModel.kagamiListHeaderModel.headerloaded;
      setKagamiListModel(kanbannViewModel);
      getKagamiListModel().onStartData = kagamiListModel.onStartData;
      if (kanbannViewModel.isGroupListModel) {
        let groupModel: KagamiGroupModel = getGroupModel();
        groupModel.activeGroupModel = kanbannViewModel;
      }
      return <div className="kanbanPresentation">{buildKanbann(kanbannViewModel)}</div>;
    // return <div className="kanbanPresentation">{kagamiKanbannView(kagamiListModel)}</div>
    case SystemConstants.HIERARCHICAL_VIEW_TYPE:
      let hierarchyViewModel: KagamiHierarchyViewModel = new KagamiHierarchyViewModel(
        kagamiListModel.processName,
        kagamiListModel.processStepName,
        kagamiListModel.mainEntityId,
        kagamiListModel.presentation
      );
      hierarchyViewModel.isGroupListModel = kagamiListModel.isGroupListModel;
      hierarchyViewModel.triggersModel = kagamiListModel.triggersModel;
      hierarchyViewModel.detailedObjects =
        kagamiListModel.onStartData.constructOutputData.detailedObjects[kagamiListModel.mainEntityId] ?? [];
      if (kagamiListModel.isVisibleViewHierarchy === true) {
        hierarchyViewModel.detailedObjects = kagamiListModel.detailedObjects;
      }
      setKagamiListModel(hierarchyViewModel);
      getKagamiListModel().onStartData = kagamiListModel.onStartData;
      if (hierarchyViewModel.isGroupListModel) {
        let groupModel: KagamiGroupModel = getGroupModel();
        groupModel.activeGroupModel = hierarchyViewModel;
      }
      return buildHierarchy(hierarchyViewModel);
    case SystemConstants.CROSS_TAB:
      let crossTabViewModel: KagamiCrossTabViewModel = new KagamiCrossTabViewModel(
        kagamiListModel.processName,
        kagamiListModel.processStepName,
        kagamiListModel.mainEntityId,
        kagamiListModel.presentation
      );
      crossTabViewModel.isGroupListModel = kagamiListModel.isGroupListModel;
      if (Object.keys(kagamiListModel.onStartData.constructOutputData.detailedObjects).length === 1) {
        crossTabViewModel.detailedObjects =
          kagamiListModel.onStartData.constructOutputData.detailedObjects[kagamiListModel.mainEntityId];
      } else {
        crossTabViewModel.detailedObjects = kagamiListModel.onStartData.constructOutputData.detailedObjects;
      }
      crossTabViewModel.isListLoaded = true;

      crossTabViewModel.kagamiListHeaderModel = kagamiListModel.kagamiListHeaderModel;
      crossTabViewModel.kagamiListHeaderModel.headerloaded = true;
      crossTabViewModel.triggersModel = kagamiListModel.triggersModel;
      setKagamiListModel(crossTabViewModel);
      getKagamiListModel().onStartData = kagamiListModel.onStartData;
      if (crossTabViewModel.isGroupListModel) {
        let groupModel: KagamiGroupModel = getGroupModel();
        groupModel.activeGroupModel = crossTabViewModel;
      }
      return buildCrossTab(crossTabViewModel);

    default:
      return <div> 'This template is not yet implemented please come back later...; </div>;
  }
}

export const EmbedValuesToDisplayInList = (props: any) => {
  const classes = useStyles();
  let key: any;
  let value: any;
  let toreturn: any = [];
  if (props.params.value != undefined && props.params.value instanceof Object) {
    for ([key, value] of props.params.value) {
      if (value != undefined) {
        if (key == 'file') {
          var fileid = value.includes(':') ? value.split(':')[0] : value;
          var url = getDmsViewUrl(fileid);
          toreturn.push(
            <span
              className={classes.embedListStyle}
              onClick={() => fetchEmbedRecords(props.attributePresentation, props.params)}
            >
              {' '}
              {<img src={url} className="imageWidth" />}{' '}
            </span>
          );
        } else {
          if (value.length > 0) {
            toreturn.push(
              <span
                className={classes.embedListStyle}
                onClick={() => fetchEmbedRecords(props.attributePresentation, props.params)}
              >
                {' '}
                {value[0]}{' '}
              </span>
            );
            if (value.length > 1) {
              toreturn.push(
                <span
                  className={classes.embedListStyle}
                  onClick={() => fetchEmbedRecords(props.attributePresentation, props.params)}
                >
                  {' '}
                  {'+' + (value.length - 1)}{' '}
                </span>
              );
            }
          }
        }
      }
    }
  } else {
    if (props.params.value) {
      toreturn.push(
        <span
          className={classes.embedListStyle}
          onClick={() => fetchEmbedRecords(props.attributePresentation, props.params)}
        >
          {' '}
          {props.params.value}{' '}
        </span>
      );
    }
  }
  return toreturn;
};

export const ListTriggers = (props: ListModelProps) => {
  return <> {KagamiTriggersController.buildTriggers(props.kagamiListModel.triggersModel)}</>;
};

export function retrieveListColumns(kagamiGridModel: KagamiListModel) {
  let presentation: any = kagamiGridModel.presentation;
  const attributePresentations: any = getAttributePresentations(presentation);
  let listAttribute: any[] = [];
  for (const attributeName in attributePresentations) {
    if (!attributeName.includes('NonFieldPresentation')) {
      let attributePresentation = attributePresentations[attributeName];
      if (
        getAttributePresentationType(attributePresentation) === 'FieldPresentation' &&
        isVisiblePresentation(attributePresentation)
      ) {
        if (getEmbeddedPresentationId(attributePresentation)) {
          listAttribute.push(getValuesForEmbedded(attributePresentation));
          continue;
        }
        const column = {
          field: attributePresentation.entityConsumed
            ? getEntityConsumed(attributePresentation)
            : getAttributeName(attributePresentation),
          headerName: attributePresentation.displayAttributes
            ? getDisplayAttributes(attributePresentation)
            : getAttributeLabel(attributePresentation),
          keyName: attributePresentation.label,
          color:
            attributePresentation.uiSettings &&
            attributePresentation.uiSettings.fontStyling &&
            attributePresentation.uiSettings.fontStyling.label &&
            attributePresentation.uiSettings.fontStyling.label.fontColor &&
            attributePresentation.uiSettings.fontStyling.label.fontColor,
          width: 200,
          attributePresentation: attributePresentation
        };
        listAttribute.push(column);
      }
    }
  }
  if (kagamiGridModel.isEditableEmbedList) {
    const editactionColumn = {
      field: 'e',
      headerName: '',
      width: '20',
      renderCell: (params: any) => {
        if (kagamiGridModel.actions.get('edit') !== undefined) {
          let triggerModel: KagamiEmbedTriggerModel = kagamiGridModel.actions.get('edit');
          const onClick = () => {
            console.log(JSON.stringify(params.row));
            triggerModel.embeddedFormPresentationModel.onLoadData = params.row;
            triggerModel.triggerCallback(triggerModel.embeddedFormPresentationModel);
          };
          return <div onClick={onClick}>{buildEmbedTrigger(kagamiGridModel.actions.get('edit'))}</div>;
        }
      }
    };
    listAttribute.push(editactionColumn);

    const deleteActionColumn = {
      field: 'd',
      headerName: '',
      width: '20',
      renderCell: (params: any) => {
        if (kagamiGridModel.actions.get('remove') !== undefined) {
          let triggerModel: KagamiEmbedTriggerModel = kagamiGridModel.actions.get('remove');
          const onClick = () => {
            console.log(JSON.stringify(params.row));
            triggerModel.embeddedFormPresentationModel.onLoadData = params.row;
            triggerModel.triggerCallback(triggerModel.embeddedFormPresentationModel);
          };
          return <div onClick={onClick}>{buildEmbedTrigger(kagamiGridModel.actions.get('remove'))}</div>;
        }
      }
    };
    listAttribute.push(deleteActionColumn);
  }

  return listAttribute;
}
function getValuesForEmbedded(attributePresentation: any) {
  return {
    field: getAttributeName(attributePresentation),
    headerName: getAttributeLabel(attributePresentation),
    width: 300,
    renderCell: (params: any) => (
      <EmbedValuesToDisplayInList params={params} attributePresentation={attributePresentation} />
    )
  };
}
// //not using this
// export const presentationFilters = (kagamiListModel: KagamiListModel) => {
//   const presentationFiltersComponents = [];
//   let displayAttributesNames: string;
//   let sourceMapChildEntityId: string;
//   let entityPrefix: string;
//   let sourceMapEntityKey: string;
//   let addEntityPrefix: string;
//   let dataRecordsForPresentationAttributes = retrieveAttributePresentation(kagamiListModel.onStartData);
//   let presentationFilterModel: KagamiPresentationFilterModel;
//   let filterData: any;
//   presentationFiltersComponents.push(
//     dataRecordsForPresentationAttributes.map((dataRecordsForPresentationAttribute: any) => {
//       if (dataRecordsForPresentationAttribute.source && dataRecordsForPresentationAttribute.source.parent) {
//         let sourceMapParentEntityId = dataRecordsForPresentationAttribute.source.parent.entityId;
//         if (dataRecordsForPresentationAttribute.source.parent.entityPrefix === '') {
//           if (dataRecordsForPresentationAttribute.source.parent.for_attr.length > 1) {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[1].name;
//             sourceMapEntityKey = sourceMapParentEntityId.concat('.', sourceMapChildEntityId);
//           } else {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[0].name;
//             sourceMapEntityKey = sourceMapParentEntityId.concat('.', sourceMapChildEntityId);
//           }
//         } else {
//           entityPrefix = dataRecordsForPresentationAttribute.source.parent.entityPrefix;
//           if (dataRecordsForPresentationAttribute.source.parent.for_attr.length > 1) {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[1].name;
//             addEntityPrefix = entityPrefix.concat('', sourceMapParentEntityId);
//             sourceMapEntityKey = addEntityPrefix.concat('.', sourceMapChildEntityId);
//           } else {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[0].name;
//             addEntityPrefix = entityPrefix.concat('', sourceMapParentEntityId);
//             sourceMapEntityKey = addEntityPrefix.concat('.', sourceMapChildEntityId);
//           }
//         }
//         //  sourceMapEntityKey = sourceMapParentEntityId.concat('.',sourceMapChildEntityId);
//         displayAttributesNames = sourceMapEntityKey;
//         if (Object.keys(kagamiListModel.onStartData.constructOutputData.detailedObjects).length > 0) {
//           filterData = kagamiListModel.onStartData.constructOutputData.detailedObjects[displayAttributesNames];
//         } else {
//           filterData = [];
//         }
//       } else {
//         displayAttributesNames = dataRecordsForPresentationAttribute.attrName;
//         if (Object.keys(kagamiListModel.onStartData.constructOutputData.detailedObjects).length > 0) {
//           filterData = kagamiListModel.onStartData.constructOutputData.detailedObjects[displayAttributesNames];
//         } else {
//           filterData = [];
//         }
//       }
//       presentationFilterModel = new KagamiPresentationFilterModel(
//         dataRecordsForPresentationAttribute,
//         filterData,
//         kagamiListModel,
//         displayAttributesNames
//       );
//       return buildpresentationfilter(presentationFilterModel);
//     })
//   );
//   return presentationFiltersComponents;
// };
//using this
// export const listPresentationFilters = (kagamiListModel: KagamiListModel) => {
//   const presentationFiltersComponents = [];
//   let displayAttributesNames: string;
//   let sourceMapChildEntityId: string;
//   let entityPrefix: string;
//   let sourceMapEntityKey: string;
//   let addEntityPrefix: string;
//   let dataRecordsForPresentationAttributes = retrieveAttributePresentation(kagamiListModel.onStartData);
//   let presentationFilterModel: KagamiPresentationFilterModel;
//   let filterData: any;
//   presentationFiltersComponents.push(
//     dataRecordsForPresentationAttributes.map((dataRecordsForPresentationAttribute: any) => {
//       if (dataRecordsForPresentationAttribute.source && dataRecordsForPresentationAttribute.source.parent) {
//         let sourceMapParentEntityId = dataRecordsForPresentationAttribute.source.parent.entityId;
//         if (dataRecordsForPresentationAttribute.source.parent.entityPrefix === '') {
//           if (dataRecordsForPresentationAttribute.source.parent.for_attr.length > 1) {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[1].name;
//             sourceMapEntityKey = sourceMapParentEntityId.concat('.', sourceMapChildEntityId);
//           } else {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[0].name;
//             sourceMapEntityKey = sourceMapParentEntityId.concat('.', sourceMapChildEntityId);
//           }
//         } else {
//           entityPrefix = dataRecordsForPresentationAttribute.source.parent.entityPrefix;
//           if (dataRecordsForPresentationAttribute.source.parent.for_attr.length > 1) {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[1].name;
//             addEntityPrefix = entityPrefix.concat('', sourceMapParentEntityId);
//             sourceMapEntityKey = addEntityPrefix.concat('.', sourceMapChildEntityId);
//           } else {
//             sourceMapChildEntityId = dataRecordsForPresentationAttribute.source.parent.for_attr[0].name;
//             addEntityPrefix = entityPrefix.concat('', sourceMapParentEntityId);
//             sourceMapEntityKey = addEntityPrefix.concat('.', sourceMapChildEntityId);
//           }
//         }
//         //  sourceMapEntityKey = sourceMapParentEntityId.concat('.',sourceMapChildEntityId);
//         displayAttributesNames = sourceMapEntityKey;
//         if (Object.keys(kagamiListModel.onStartData.constructOutputData.detailedObjects).length > 0) {
//           filterData = kagamiListModel.onStartData.constructOutputData.detailedObjects[displayAttributesNames];
//         } else {
//           filterData = [];
//         }
//         // filterData =  kagamiListModel.onStartData.constructOutputData.detailedObjects[displayAttributesNames]
//       } else {
//         displayAttributesNames = dataRecordsForPresentationAttribute.attrName;
//         if (Object.keys(kagamiListModel.onStartData.constructOutputData.detailedObjects).length > 0) {
//           filterData = kagamiListModel.onStartData.constructOutputData.detailedObjects[displayAttributesNames];
//         } else {
//           filterData = [];
//         }
//         // filterData =  kagamiListModel.onStartData.constructOutputData.detailedObjects[displayAttributesNames];
//       }
//       presentationFilterModel = new KagamiPresentationFilterModel(
//         dataRecordsForPresentationAttribute,
//         filterData,
//         kagamiListModel,
//         displayAttributesNames
//       );
//       return buildpresentationfilter(presentationFilterModel);
//     })
//   );
//   return presentationFiltersComponents;
// };
export const BuildEmbedModalForList = (props: any) => {
  return buildModal(props.kagamiGridModel.embedModal);
};

export const BuildTriggers = (props: any) => {
  let template: string = props.templateType;
  return template === 'kanban' ? (
    <ListTriggers key={props.kagamiListModel.processStepName + 'kanban'} kagamiListModel={props.kagamiListModel} />
  ) : (
    <ListTriggers key={props.kagamiListModel.processStepName} kagamiListModel={props.kagamiListModel} />
  );
};

export const BuildListPresentation = (props: any) => {
  return buildListPresentation(props.kagamiListModel, props.templateType);
};

export const BuildListFilterComponent = (props: any) => {
  return <>{buildListFilterComponent(props.kagamiListFilterModel)}</>;
};
export const BuildListFilterWidget = (props: any) => {
  return <>{buildListFilterWidget(props.kagamiListFilterModel)}</>;
};
export function onGridCellClicked(kagamiGridModel: KagamiGridModel, params: any) {
  setKagamiListModel(kagamiGridModel);
  updateInFocusMap(kagamiGridModel.mainEntityId, params.data.id, getFormModel());
  let redrawRows: any[] = [];
  if (kagamiGridModel.activeRowNode !== null && kagamiGridModel.activeRowNode.data.id !== params.data.id) {
    redrawRows.push(kagamiGridModel.activeRowNode);
    redrawGridRow(kagamiGridModel, params, redrawRows);
  } else if (kagamiGridModel.activeRowNode === null) {
    redrawGridRow(kagamiGridModel, params, redrawRows);
  }
}

export function redrawGridRow(kagamiGridModel: KagamiGridModel, params: any, redrawRows: any[]) {
  let embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel =
    getFormModel().formControls[kagamiGridModel.mainEntityId];
  if (params.node.rowIndex !== null) {
    let activeRowNode = params.api.getDisplayedRowAtIndex(params.node.rowIndex);
    kagamiGridModel.activeRowNode = activeRowNode;
    embeddedFormPresentationModel.formDataIdentifier = params.data.id;
    if (activeRowNode) {
      redrawRows.push(activeRowNode);
      setTimeout(function () {
        params.api.redrawRows({ rowNodes: redrawRows });
        //  api.redrawRows();
      }, 0);
    }
  }
  editRecord(getFormModel().formControls[kagamiGridModel.mainEntityId], 'inline', params.data.id);
}

export function onCellValueChanged(
  kagamiGridModel: KagamiGridModel,
  rowNode: any,
  isOnChangeRequired: boolean,
  colId: any,
  params?: any
) {
  // if(kagamiGridModel.defaultEmbedFormView === 'inform'){
  let cellData: any = {};
  let controlModel: KagamiControlModel = kagamiGridModel.gridControls[colId];
  cellData['entityId'] = kagamiGridModel.mainEntityId;
  cellData['attributeName'] = colId;
  cellData['onChangeRequired'] = isOnChangeRequired;
  cellData['controlValue'] = rowNode.data[colId];
  cellData['recordId'] = rowNode.data['id'];
  cellData['matrixAxis'] = controlModel.attributePresentation['matrixAxis'];
  cellData['pivotOnChangeAttr'] = controlModel.attributePresentation['pivotOnChangeAttr'];
  // controlModel.controlValue = cellData['controlValue']
  handleGridOnChange(getFormModel(), cellData, controlModel, params);
  // }
}

export function onCellValueSearch(
  kagamiGridModel: KagamiGridModel,
  rowNode: any,
  isOnChangeRequired: boolean,
  colId: any
) {
  let cellData: any = {};
  cellData['entityId'] = kagamiGridModel.mainEntityId;
  cellData['attributeName'] = colId;
  cellData['onChangeRequired'] = isOnChangeRequired;
  cellData['controlValue'] = rowNode.data[colId];
}

export const handleActivityLogOnClick = (
  activityLogId: string,
  entityId: string,
  activityLogModel: KagamiActivityLogModel
) => {
  activityLogModel.action = 'read';
  activityLogModel.entityId = entityId;
  activityLogModel.referenceId = activityLogId;
  activityLogModel.state.setOpen(true);
  let data: any;
  if (Object.keys(activityLogModel.activityLogContainer).length != 0) {
    data = {
      referenceId: activityLogModel.referenceId,
      entityId: activityLogModel.entityId,
      action: activityLogModel.action,
      activityLogContainer: activityLogModel.activityLogContainer
    };
  } else {
    data = {
      referenceId: activityLogModel.referenceId,
      entityId: activityLogModel.entityId,
      action: activityLogModel.action
    };
  }
  if (CommonUtils.isEmpty(activityLogModel.controlValue) && !activityLogModel.readOnly) {
    activityLogModel.activityLogContainer.activityList = [];

    data = {
      // referenceId: activityLogModel.referenceId,
      entityId: activityLogModel.entityId,
      action: 'create',
      activityLogContainer: activityLogModel.activityLogContainer
    };
  }
  resolveStatusInfo(data, activityLogModel);
  resolveListChatInfo(activityLogModel);
};

function buildGridControlModel(
  attributePresentation: any,
  entityId: string,
  onCellValueChanged: any,
  kagamiGridModel: KagamiGridModel
) {
  let attributeName = getAttributeName(attributePresentation);
  let controlId = entityId + '.' + attributeName;
  const pickAttribute = attributeName.includes('.') ? true : false;
  let isReadOnly = isReadOnlyAttribute(attributePresentation);
  let isVisible = isVisibleAttribute(attributePresentation);
  let controlModel: KagamiControlModel = new KagamiControlModel(
    controlId,
    attributeName,
    isRequiredAttribute(attributePresentation),
    isVisible,
    entityId,
    isReadOnly,
    getHtmlControl(attributePresentation),
    null,
    getAttributeLabel(attributePresentation),
    true,
    isOnChangeRequiredForAttribute(attributePresentation),
    onCellValueChanged,
    null,
    pickAttribute,
    getPolicyMap(attributePresentation),
    getPresentationPolicy(attributePresentation),
    getBusinessPolicy(attributePresentation),
    isReadOnly,
    isVisible,
    '',
    true,
    true,
    getPrefixtypes(attributePresentation)
  );
  controlModel.validation = getAttributeValidations(attributePresentation);
  return controlModel;
}

export function getPresentationRules(presentation: any) {
  let presentationRules: any = [];
  if (presentation.presentationRules) {
    presentationRules = presentation.presentationRules;
  } else if (presentation.presentationRule) {
    presentationRules = presentation.presentationRule;
  }
  return presentationRules;
}
const resolveStatusInfo = async (data: any, activityLogModel: KagamiActivityLogModel) => {
  let listModel: KagamiListModel = getKagamiListModel();
  let initialStatusInfo = listModel.statusInfo;
  if (!initialStatusInfo) {
    const statusInfo = await getStatusInfo(activityLogModel.entityId);
    listModel.statusInfo = statusInfo;
    // initialStatusInfo = statusInfo
    activityLogModel.statusInfo = listModel.statusInfo;
  } else {
    activityLogModel.statusInfo = initialStatusInfo;
  }

  getActivityLogData(data, activityLogModel);
};

const resolveListChatInfo = async (activityLogModel: KagamiActivityLogModel) => {
  let listModel: KagamiListModel = getKagamiListModel();
  let initialListChatInfo = listModel.listChatInfo;
  if (initialListChatInfo.length == 0) {
    const listChatInfo = await getListChatInfo();
    listModel.listChatInfo = listChatInfo;
    activityLogModel.listChatInfo = listModel.listChatInfo;
  } else {
    activityLogModel.listChatInfo = initialListChatInfo;
  }
  // getActivityLogData(data,activityLogModel);
};
function getOptionLabels(attributePresentation: any, entityConsumed: any) {
  let listModel: KagamiListModel = getKagamiListModel();
  let optionLabels: any[] = [];
  if (listModel.isEditableEmbedList) {
    let records: any[] = getFormModel().formData.data[entityConsumed];
    if (records) {
      for (let record of records) {
        optionLabels.push(extractDropdownOption(attributePresentation, record));
      }
    }
  }
  return optionLabels;
}

export function getLovRecords(entityConsumed: string, kagamiGridModel: KagamiGridModel) {
  let lovRecords: any[] = [];
  let formModel: KagamiFormModel = getFormModel();
  if (kagamiGridModel.isEditableEmbedList && formModel.formData.data[entityConsumed]) {
    let radioButtons = formModel.formData.data[entityConsumed];
    let buildRadioButtons: any = [];
    if (CommonUtils.isNotEmpty(radioButtons)) {
      radioButtons.map((item: any) => {
        if (item != 'removed_') {
          buildRadioButtons.push(item);
        }
      });
    }
    lovRecords = buildRadioButtons;
    // lovRecords = formModel.formData.data[entityConsumed];
  }
  if (kagamiGridModel?.detailedObjects[0] && kagamiGridModel?.detailedObjects[0][entityConsumed]) {
    let localOptions = kagamiGridModel?.detailedObjects[0][entityConsumed];
    if (!Array.isArray(localOptions)) {
      localOptions = [localOptions];
    }
    localOptions.forEach(function (option: any) {
      const existing = lovRecords.find((o) => {
        return o.id == option.id;
      });
      if (!existing) {
        lovRecords.push(option);
      }
    });
  }
  return lovRecords;
}

export function getProcessContent(data: any, attributePresentation: any) {
  let values: any[];
  let displayAttributes: any[] = getForAttrFromPresentation(attributePresentation);
  let attributeName: string = attributePresentation.attrName;
  let displayAttributePresentation: any = displayAttributes[1];
  // let displayAttribute : string = displayAttributePresentation['name'].toString().charAt(0).toUpperCase()+displayAttributePresentation['name'].toString().slice(1)
  let displayAttribute: string = displayAttributePresentation['name'].toString();

  if (data[attributeName] instanceof Object) {
    //  values = data[attributeName][0].itemDescription.split("---")
    // values = data[attributeName][0][displayAttribute]?.split("---")
    if (attributePresentation.label === 'stockRecnLinesId' && attributePresentation.htmlControl === 'multiselect') {
      values = data[attributeName][0][displayAttribute]?.split('---');
    } else {
      values = data[attributeName][0][displayAttribute];
    }
  } else {
    values = data[attributeName]?.split('---');
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start' }}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={'medium'}>
          <TableHead>
            {displayAttribute}
            <TableRow>
              {(attributePresentation.label === 'stockRecnLinesId' &&
                attributePresentation.htmlControl === 'multiselect') ||
              (attributePresentation.label === 'Gprequest Lines' &&
                attributePresentation.htmlControl === 'multiselect') ? (
                ''
              ) : (
                <TableCell>{values}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(values).map((value: any, index) => {
              if (index === values.length - 1) {
                return (
                  <TableRow key={index}>
                    <TableCell>{value?.replace('--', '')}</TableCell>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow key={index}>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

//Commenting code displaying wrong data for multi select embdded
// export function getContentForDisplayattributes(disattr: any, data: any) {
//   let dataSplit: any[] = [];
//   //issue not fixed in main list embed chips click
//   Array.isArray(data) ? (dataSplit = data) : (dataSplit = data.includes('---') ? data.split('---') : data.split('.'));
//   return (
//     <div style={{ paddingLeft: '15px', paddingRight: '15px', display: 'grid' }}>
//       <Typography style={{ color: '3C3C3C', fontSize: '12px' }} align={'left'} variant="caption">{disattr.displayAttributes ? disattr.displayAttributes[0].toUpperCase() : ""}</Typography>
//       {dataSplit.map((displayvalue: any) => {
//         return (
//           <Typography style={{ color: 'grey', fontSize: '12px' }} align={'left'} variant="caption">{displayvalue.includes('--') ? displayvalue.replace('--', '') : displayvalue}</Typography>
//         )
//       })}
//     </div>
//   )
// }

export function getContentForEmbdeeddata(disattr: any, data: any) {
  let dataSplit: any = [data];
  // Array.isArray(data) ? (dataSplit = data) : (dataSplit = data.includes('----') ? data.split('----') : data.split('.'));
  if (CommonUtils.isNotEmpty(disattr)) {
    let getSourceParentForAttrs: any = disattr.source.parent.for_attr;
    let getkeyForAttrdAt: any = getSourceParentForAttrs.filter(
      (getSourceParentForAttr: any) => getSourceParentForAttr.name !== 'id'
    );
    const columnDefs: any = [];
    if (getkeyForAttrdAt.length > 0) {
      getkeyForAttrdAt.forEach((getkeyForAttr: any) => {
        let fieldName = getkeyForAttr.name.includes('.') ? getkeyForAttr.name.split('.')[1] : getkeyForAttr.name;
        columnDefs.push({
          field: getkeyForAttr.name,
          minWidth: 250,
          cellClass: 'ag-left-aligned-cell-popup',
          headerComponentParams: { displayName: fieldName }
        });
      });
    }
    // const columnDefs = [{ field: getkeyForAttrdAt[0].name, minWidth: 250, cellClass: 'ag-left-aligned-cell-popup' }];
    return (
      <div className="ag-theme-alpine" style={{ height: '150px' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={getMultiSelectEmbddedColData(dataSplit, getkeyForAttrdAt)}
          suppressDragLeaveHidesColumns={true}
        ></AgGridReact>
      </div>
    );
  }
}

export function bulidEntityConsumedGrid(attr: any, data: any) {
  if (CommonUtils.isNotEmpty(attr)) {
    return (
      <div className="ag-theme-alpine" style={{ height: '150px' }}>
        <AgGridReact
          columnDefs={getColDef(attr)}
          rowData={getRowData(data, attr)}
          suppressDragLeaveHidesColumns={true}
        ></AgGridReact>
      </div>
    );
  } else {
    return null;
  }
}
const getRowData = (data: any, key: any) => {
  return data;
};
const getColDef = (attr: any) => {
  let coldefArray: any = [];
  if (attr.length > 0) {
    attr.forEach((ele: any) => {
      coldefArray.push({ field: ele.name, headerName: ele.name.toUpperCase() });
    });
    return coldefArray;
  } else {
    return coldefArray;
  }
};

const getMultiSelectEmbddedColData = (data: any, col: any) => {
  let gridObjData: any = {};
  let multiSelectEmbddedGridData: any[] = [];
  let forAttrDisplayName = col[0];
  let result: any;
  if (data.includes(',')) {
    let arr = data.value.split(',');
    result = arr.filter((e: any) => e);
    result.forEach((ele: any, index: number) => {
      gridObjData[forAttrDisplayName] = ele;
      multiSelectEmbddedGridData.push(gridObjData);
    });
  } else {
    result = data.filter((e: any) => e);
    result.forEach((ele: any, index: number) => {
      gridObjData[forAttrDisplayName.name] = ele.includes('--') ? ele.replace('--', '') : ele;
      // multiSelectEmbddedGridData.push(gridObjData[forAttrDisplayName.name][0]);
      if (typeof gridObjData === 'object') {
        let dataListItem: any;
        let dataList = gridObjData[forAttrDisplayName.name];
        if (dataList instanceof Array) {
          dataList.forEach((ele: any) => {
            dataListItem = ele;
            multiSelectEmbddedGridData.push(dataListItem);
          });
        }
      } else {
        multiSelectEmbddedGridData.push(gridObjData);
      }
    });
  }
  return multiSelectEmbddedGridData;
};

/**** NEW COLUMN DEFS  */
export function agGridColumns(
  gridControlModels: any,
  kagamiGridModel: KagamiGridModel,
  popupModel: KagamiModalModel,
  activityLogModel: KagamiActivityLogModel,
  setnewHeight: any,
  rows: any[],
  sort?: boolean,
) {
  const classes = useStyles();
  let gridColumns: any[] = [];
  let attrName: string;
  // let addTrigger: any;
  let editTrigger: any = kagamiGridModel.actions.has('edit') ? kagamiGridModel.actions.get('edit') : null;
  let deleteTrigger: any = kagamiGridModel.actions.has('remove') ? kagamiGridModel.actions.get('remove') : null;
  let visibleColumns: any[] = Object.values(gridControlModels).filter((model: any) => model.visible === true);
  /// Note : Making grid non editable if presentation has no Edit action
  if (kagamiGridModel.isEmbedModel && !kagamiGridModel.actions.has('edit')) {
    kagamiGridModel.isEditableEmbedList = false;
  }

  const getValueGetter = (params: any) => {
    // alert("getValueGetter...")
    if (kagamiGridModel.isPivot && params.node.footer) {
      return '';
    }


    if (kagamiGridModel.defaultEmbedFormView === 'inform' || kagamiGridModel.defaultEmbedFormView === 'inline') {
      return CommonUtils.isNotEmpty(params.node.childIndex) ? parseInt(params.node.id) + 1 : params.node.rowIndex + 1;
    } 
    else if (CommonUtils.isEmpty(kagamiGridModel.onLoadData) || CommonUtils.isNotEmpty(kagamiGridModel.onLoadData) && sort) {
      return params.node.rowIndex + 1;
    } 
     else if (
      (CommonUtils.isEmpty(kagamiGridModel.onLoadData) && kagamiGridModel.defaultEmbedFormView === 'modal') ||
      (CommonUtils.isNotEmpty(kagamiGridModel.onLoadData) && kagamiGridModel.defaultEmbedFormView === 'modal')
    ) {
      return parseInt(params.node.id) + 1;
    } 
    else if (CommonUtils.isEmpty(kagamiGridModel.onLoadData) || CommonUtils.isNotEmpty(kagamiGridModel.onLoadData)) {
      return params.node.rowIndex + 1;
    } 
    else {
      return parseInt(params.node.id) + 1;
    }
  };

  const cellClass = useCallback((params: any) => {
    return ['kgm-sno-cell'];
  }, []);

  kagamiGridModel.showGridDataCount &&
    gridColumns.push({
      key: kagamiGridModel.mainEntityId + '_add',
      field: '#',
      // filter={true}
      suppressMenu: false,
      headerComponentParams: {
        // displayName: addTrigger !== undefined ? '' : '#',
        // addTrigger: addTrigger
      },
      checkboxSelection: enableHeaderCheckbox(kagamiGridModel, rows),
      headerCheckboxSelectionFilteredOnly: enableHeaderCheckbox(kagamiGridModel, rows),
      headerCheckboxSelection: enableHeaderCheckbox(kagamiGridModel, rows),
      cellStyle: { marginLeft: 0 },
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      resizable: false,
      editable: false,
      sortable: false,
      filterParams: {
        values: visibleColumns.map((column) => column.controlDisplayName)
      },
      cellRenderer: 'agGroupCellRenderer',
      suppressNavigable: true,
      valueGetter: getValueGetter,
      cellClass: cellClass
    });
  for (attrName of Object.keys(gridControlModels)) {
    let gridControlModel = gridControlModels[attrName];
    let attributeName: string = gridControlModel.attributeName;
    let isMandatory: boolean = gridControlModel.mandatory;
    let attributePresentation = gridControlModel.attributePresentation;
    let fontColor: any = attributePresentation?.uiSettings?.fontStyling?.fontColor;
    let fontSize: any = attributePresentation?.uiSettings?.fontStyling?.fontSize;
    let dateFontColor: any = attributePresentation?.uiSettings?.fontStyling?.label?.fontColor;
    let dateFontSize: any = attributePresentation?.uiSettings?.fontStyling?.label?.fontSize;
    let displayAttributeToconsume = getDisplayAttributes(attributePresentation);
    let dispPresentationRuleConfig = getDispPresentationRuleConfig(attributePresentation);
    let displayAttrForEmbedSearch = getDisplayAttrForEmbedSearch(attributePresentation);
    let mandatoryIcon: any =
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
      '   <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      ' <span style="color:#f05252;"> *</span> </div>' +
      '</div>';
    if (
      attributePresentation &&
      gridControlModel instanceof KagamiControlModel &&
      getAttributePresentationType(attributePresentation) === 'FieldPresentation'
    ) {
      let entityConsumed = getEntityConsumedInAttribute(attributePresentation);
      const embedPresentationId = getEmbeddedPresentationId(attributePresentation);

      // let controlType = getGridAttributeType(gridControlModel) || gridControlModel.controlType;
      let controlType = getGridAttributeType(gridControlModel) || gridControlModel.controlType;
      //pickAttribute check
      ///need to discuss why text is excluded
      if (
        attributeName.includes('.') &&
        !isTypeDate(gridControlModel.controlType)
        // &&
        // isTypeText(gridControlModel.controlType)
      ) {
        let properties = attributeName.split('.');
        let pickAttributeProperty: string = properties[0];

        gridColumns.push({
          key: kagamiGridModel.mainEntityId + '_' + pickAttributeProperty,
          field: attributeName,
          headerComponentParams: {
            displayName: gridControlModel.controlDisplayName,
            template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
            displayAttr: displayAttrForEmbedSearch,
            gridModel: kagamiGridModel
          },
          cellRenderer: memo(KagamiStatusCellRenderer),
          cellRendererParams: {
            attributeToConsume: pickAttributeProperty,
            attributeValueToConsume: attributeName
          },
          hide: !gridControlModel.visible,
          editable: !gridControlModel.readonly
        });
      } else if (gridControlModel.controlType === 'customactivitylog' || gridControlModel.controlType === 'comments') {
        gridColumns.push({
          key: kagamiGridModel.mainEntityId + '_' + attributeName,
          field: attributeName,
          headerComponentParams: {
            displayName: gridControlModel.controlDisplayName,
            template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
            displayAttr: displayAttrForEmbedSearch,
            gridModel: kagamiGridModel
          },
          cellRenderer: memo(KagamiActivityLogCellRenderer),
          cellRendererParams: {
            activityLogModel: activityLogModel,
            mainEntityId: kagamiGridModel.mainEntityId,
            handleActivityLogOnClick: handleActivityLogOnClick,
            attributePresentation: attributePresentation
          },
          hide: !gridControlModel.visible
        });
      } else {
        switch (controlType) {
          case 'multiselect':
            if (!kagamiGridModel.isEditableEmbedList) {
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
                cellRendererParams: {
                  popupModel: popupModel,
                  mainEntityId: kagamiGridModel.mainEntityId,
                  attributePresentation: attributePresentation,
                  kagamiGridModel: kagamiGridModel
                },
                cellRenderer: memo(listEmbedFieldRenderer),
                hide: !gridControlModel.visible
              });
            } else {
              const valueFormatter = useCallback((params: any) => {
                let displayValue: string = '';
                let value = params.node.data[attributeName];
                let activeRecord = getActiveFormRecord(
                  getFormModel(),
                  kagamiGridModel.mainEntityId,
                  params.node.data.id
                );
                if (CommonUtils.isEmpty(activeRecord)) activeRecord = params.node.data;
                if (params.value || CommonUtils.isNotEmpty(value)) {
                  if (value instanceof Array) {
                    for (let record of value) {
                      displayValue = displayValue + extractDropdownOption(attributePresentation, record) + '-';
                    }
                  } else if (typeof value === 'string') {
                    if (value.length > 4 && value.charAt(value.length - 1) === '-') {
                      value = value.slice(0, value.length - 3);
                    }
                    displayValue = value;
                  }
                  return displayValue;
                }
                return params.value;
              }, []);

              const tooltipValue = kagamiGridModel.isEditableEmbedList
                ? undefined
                : useCallback((params: any) => {
                    let displayValue: string = '';
                    let value = params.node.data[attributeName];
                    let activeRecord = getActiveFormRecord(
                      getFormModel(),
                      kagamiGridModel.mainEntityId,
                      params.node.data.id
                    );
                    if (CommonUtils.isEmpty(activeRecord)) activeRecord = params.node.data;
                    if (params.value || CommonUtils.isNotEmpty(value)) {
                      if (value instanceof Array) {
                        for (let record of value) {
                          displayValue = displayValue + extractDropdownOption(attributePresentation, record) + '-';
                        }
                      }
                      return displayValue;
                    }
                    return params.value;
                  }, []);

              const cellEditorParams = useCallback((params: any) => {
                return {
                  options: getLovRecords(entityConsumed, kagamiGridModel),
                  searchString: { searchValue: '' },
                  attributePresentation: attributePresentation,
                  gridModel: kagamiGridModel,
                  onCellValueChange: onCellValueChanged,
                  entityConsumed: entityConsumed,
                  setnewHeight: setnewHeight,
                  gridMultiSelectModel: gridControlModel,
                  rowData: params.data
                };
              }, []);

              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                hide: !gridControlModel.visible,
                editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
                valueFormatter: (params: any) => valueFormatter(params),
                tooltipValueGetter: tooltipValue,
                singleClickEdit: !gridControlModel.readonly,
                cellEditor: 'multiSelectEditor',
                minWidth: 300,
                width: 300,
                cellEditorParams: cellEditorParams
              });
            }
            break;

          case 'search':
            if (kagamiGridModel.isEditableEmbedList) {
              const valueFormatterSearch = useCallback((params: any) => {
                if (kagamiGridModel.isPivot && params.node.footer) return '';
                let value = params.node.data[attributeName];
                let activeRecord = getActiveFormRecord(
                  getFormModel(),
                  kagamiGridModel.mainEntityId,
                  params.node.data.id
                );
                if (CommonUtils.isEmpty(activeRecord)) activeRecord = params.node.data;
                let assoaciatedObject = getAssociatedObject(
                  activeRecord,
                  entityConsumed,
                  value,
                  getEntityAssociatedAttribute(gridControlModel.attributePresentation)
                );
                if (params.value || CommonUtils.isNotEmpty(value) || CommonUtils.isNotEmpty(assoaciatedObject)) {
                  let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                  return option;
                }
                return params.value;
              }, []);
              const cellEditorParamsSearch = useCallback((params: any) => {
                return {
                  options: getLovRecords(entityConsumed, kagamiGridModel),
                  searchString: { searchValue: '' },
                  attributePresentation: attributePresentation,
                  gridModel: kagamiGridModel,
                  onCellValueChange: onCellValueChanged,
                  entityConsumed: entityConsumed,
                  entityAssociatedAttribute: getEntityAssociatedAttribute(attributePresentation),
                  gridSelectModel: gridControlModel,
                  rowData: params.data
                };
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                // headerComponentParams={{ displayName: gridControlModel.controlDisplayName }}
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  $$colConfig: {
                    onCellValueSearch: onCellValueSearch,
                    column: attributePresentation,
                    kagamiGridModel,
                    columnType: gridControlModel.controlType
                  },
                  gridModel: kagamiGridModel,
                  displayAttr: displayAttrForEmbedSearch,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null
                },
                hide: !gridControlModel.visible,
                editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
                valueFormatter: valueFormatterSearch,
                // cellStyle={{
                //   textOverflow: 'ellipsis',
                //   whiteSpace: 'nowrap',
                //   overflow: 'hidden',
                //   display: 'block',
                //   padding: 0
                // }}
                singleClickEdit: !gridControlModel.readonly,
                cellEditor: 'searchEditor',
                // minWidth={300}
                // width={300}
                cellEditorParams: cellEditorParamsSearch
              });
            } else {
              const valueFormattter = useCallback((params: any) => {
                if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                  let assoaciatedObject: any = {};
                  if (params.node.data[entityConsumed]) {
                    assoaciatedObject = params.node.data[entityConsumed];
                  }
                  let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                  return CommonUtils.isNotEmpty(option) ? option : '';
                }
                return params.value;
              }, []);
              const cellClasss = useCallback((params: any) => {
                return ['kgm-ellipsis-span-cell'];
              }, []);
              const tooltipValueGetterr = kagamiGridModel.isEditableEmbedList
                ? undefined
                : useCallback((params: any) => {
                    if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                      let assoaciatedObject: any = {};
                      if (params.node.data[entityConsumed]) {
                        assoaciatedObject = params.node.data[entityConsumed];
                      }
                      let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                      return CommonUtils.isNotEmpty(option) ? option : params.node.data[attributeName];
                    }
                    return params.value;
                  }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                hide: !gridControlModel.visible,
                valueFormatter: valueFormattter,
                // cellClass={useCallback((params: any) => {
                //   return ['kgm-number-cell'];
                // }, [])}
                cellClass: cellClasss,
                cellStyle: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'block',
                  padding: 0
                },
                tooltipValueGetter: tooltipValueGetterr,
                editable: false
              });
            }
            break;
          case 'select':
            if (kagamiGridModel.isEditableEmbedList) {
              const valueFormatter = useCallback((params: any) => {
                if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                  let value = params.node.data[attributeName];
                  let activeRecord = getActiveFormRecord(
                    getFormModel(),
                    kagamiGridModel.mainEntityId,
                    params.node.data.id
                  );
                  if (CommonUtils.isEmpty(activeRecord)) activeRecord = params.node.data;
                  let option = extractDropdownOption(
                    attributePresentation,
                    getAssociatedObject(
                      activeRecord,
                      entityConsumed,
                      value,
                      getEntityAssociatedAttribute(gridControlModel.attributePresentation)
                    )
                  );
                  return option;
                }
                return params.value;
              }, []);
              const cellEditorParams = useCallback((params: any) => {
                return {
                  options: getLovRecords(entityConsumed, kagamiGridModel),
                  pageCount: { pageNumber: 1 },
                  attributePresentation: attributePresentation,
                  gridModel: kagamiGridModel,
                  onCellValueChange: onCellValueChanged,
                  gridSelectModel: gridControlModel,
                  rowData: params.data,
                  entityConsumed: entityConsumed
                };
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                hide: !gridControlModel.visible,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                valueFormatter: (params: any) => valueFormatter(params),
                cellStyle: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'block',
                  padding: 0
                },
                singleClickEdit: !gridControlModel.readonly,
                editable: !gridControlModel.readonly,
                cellEditor: 'selectEditor',
                cellEditorParams: cellEditorParams
              });
            } else {
              const valueFormatter = useCallback((params: any) => {
                if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                  let assoaciatedObject: any = {};
                  let entityPrefix = getEntityPrefix(attributePresentation);
                  entityConsumed = CommonUtils.isNotEmpty(entityPrefix)
                    ? entityPrefix + entityConsumed
                    : entityConsumed;
                  if (params.node.data[entityConsumed]) {
                    assoaciatedObject = params.node.data[entityConsumed];
                  }
                  let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                  return option;
                }
                return params.value;
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                valueFormatter: (params: any) => valueFormatter(params),
                cellStyle: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'block',
                  padding: 0
                },
                tooltipValueGetter: kagamiGridModel?.isEditableEmbedList
                  ? undefined
                  : useCallback((params: any) => {
                      if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                        let assoaciatedObject: any = {};
                        let entityPrefix = getEntityPrefix(attributePresentation);
                        entityConsumed = CommonUtils.isNotEmpty(entityPrefix)
                          ? entityPrefix + entityConsumed
                          : entityConsumed;
                        if (params.node.data[entityConsumed]) {
                          assoaciatedObject = params.node.data[entityConsumed];
                        }
                        let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                        return option;
                      }
                      return params.value;
                    }, []),
                editable: !gridControlModel.readonly,
                hide: !gridControlModel.visible
              });
            }
            break;
          case 'radio':
            if (kagamiGridModel.isEditableEmbedList) {
              const valueFormatter = useCallback((params: any) => {
                if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                  let value = params.node.data[attributeName];
                  let activeRecord = getActiveFormRecord(
                    getFormModel(),
                    kagamiGridModel.mainEntityId,
                    params.node.data.id
                  );
                  if (CommonUtils.isEmpty(activeRecord)) activeRecord = params.node.data;
                  let option = extractDropdownOption(
                    attributePresentation,
                    getAssociatedObject(
                      activeRecord,
                      entityConsumed,
                      value,
                      getEntityAssociatedAttribute(gridControlModel.attributePresentation)
                    )
                  );
                  return option;
                }
                return params.value;
              }, []);
              const cellEditorParams = useCallback((params: any) => {
                return {
                  options: getLovRecords(entityConsumed, kagamiGridModel),
                  attributePresentation: attributePresentation,
                  gridModel: kagamiGridModel,
                  onCellValueChange: onCellValueChanged,
                  gridRadioModel: gridControlModel,
                  setnewHeight: setnewHeight,
                  rowData: params.data
                };
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                hide: !gridControlModel.visible,
                editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                minWidth: 300,
                valueFormatter: (params: any) => valueFormatter(params),
                singleClickEdit: !gridControlModel.readonly,
                cellEditor: 'radioEditor',
                cellEditorParams: cellEditorParams
              });
            } else {
              const valueFormattter = useCallback((params: any) => {
                if (
                  params.value ||
                  CommonUtils.isNotEmpty(params.node.data[attributeName])
                ) {
                  let assoaciatedObject: any = {};
                  if (params.node.data[entityConsumed]) {
                    assoaciatedObject = params.node.data[entityConsumed];
                  }
                  let option = extractDropdownOption(
                    attributePresentation,
                    assoaciatedObject
                  );
                  return CommonUtils.isNotEmpty(option) ? option : "";
                }
                return params.value;
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                valueFormatter: valueFormattter,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                editable: !gridControlModel.readonly,
                hide: !gridControlModel.visible
              });
            }

            break;
          case 'file':
          case 'fileViewer':
          case 'preview':
          case 'fileManager':
            if (kagamiGridModel.isEditableEmbedList) {
              gridColumns.push(
                //renderer being used as editor is not done!
                {
                  key: kagamiGridModel.mainEntityId + '_' + attributeName,
                  field: attributeName,
                  hide: !gridControlModel.visible,
                  editable: false,
                  headerComponentParams: {
                    displayName: gridControlModel.controlDisplayName,
                    template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                    displayAttr: displayAttrForEmbedSearch,
                    gridModel: kagamiGridModel
                  },

                  cellRenderer: React.memo(KagamiThumbnailCellRenderer),
                  cellRendererParams: {
                    popupModel: popupModel,
                    attributePresentation: attributePresentation,
                    gridModel: kagamiGridModel
                  }
                }
              );
            } else {
              gridColumns.push(
                //renderer
                {
                  key: kagamiGridModel.mainEntityId + '_' + attributeName,
                  field: attributeName,
                  hide: !gridControlModel.visible,
                  editable: false,
                  headerComponentParams: {
                    displayName: gridControlModel.controlDisplayName,
                    template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                    displayAttr: displayAttrForEmbedSearch,
                    gridModel: kagamiGridModel
                  },
                  // cellRenderer="kagamiEmbeddedGridAttachmentsRenderer"
                  cellEditorParams: {
                    attributePresentation: attributePresentation,
                    gridModel: kagamiGridModel
                  },
                  cellRenderer: React.memo(KagamiThumbnailCellRenderer),
                  cellRendererParams: {
                    popupModel: popupModel,
                    attributePresentation: attributePresentation,
                    gridModel: kagamiGridModel
                  }
                }
              );
            }
            break;
          case 'image':
            gridColumns.push({
              key: kagamiGridModel.mainEntityId + '_' + gridControlModel.attributeName,
              field: gridControlModel.attributeName,
              headerComponentParams: {
                displayName: gridControlModel.controlDisplayName,
                template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                displayAttr: displayAttrForEmbedSearch,
                gridModel: kagamiGridModel
              },
              cellRenderer: memo(kagamiImageCellRenderer),
              cellRendererParams: { attributePresentation: attributePresentation, gridModel: kagamiGridModel },
              filter: true,
              hide: !gridControlModel.visible,
              sortable: true
            });
            break;
          case 'dateTime':
          case 'date':
          case 'time':
            if (kagamiGridModel.isEditableEmbedList) {
              const valueFormatter = useCallback((params: any) => {
                //if (params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)) {
                if (params.value === SystemConstants.REMOVED_) {
                  return '';
                } else if (params.value === 'today') {
                  return moment(new Date()).format(SystemConstants.DD_MM_YYYY);
                } else if (gridControlModel.controlType === 'dateTime') {
                  return params.value && params.value != 'Invalid date'
                    ? moment(params.value).format(SystemConstants.dd_MM_yyyy_h_mm_a)
                    : '';
                } else if (gridControlModel.controlType === 'time') {
                  return params.value && params.value != 'Invalid date' ? moment(params.value).format('hh:mm') : '';
                } else {
                  return params.value && params.value != 'Invalid date'
                    ? moment(params.value).format(SystemConstants.DDMMYYYY)
                    : '';
                }
              }, []);
              const cellEditorParamsTime = useCallback((params: any) => {
                return {
                  options: getLovRecords(entityConsumed, kagamiGridModel),
                  attributePresentation: attributePresentation,
                  gridModel: kagamiGridModel,
                  onCellValueChange: onCellValueChanged,
                  kagamiDateModel: gridControlModel,
                  rowData: params.data
                };
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
                hide: !gridControlModel.visible,
                valueFormatter: valueFormatter,
                minWidth: 150,
                width: 150,
                singleClickEdit: !gridControlModel.readonly,
                // cellEditorPopup= {true}
                cellEditor: 'dateEditor',
                cellEditorParams: cellEditorParamsTime
              });
            } else {
              const valueFormatter = useCallback((params: any) => {
                //if (params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)) {
                if (params.value === SystemConstants.REMOVED_) {
                  return '';
                } else if (params.value === 'today') {
                  return moment(new Date()).format(SystemConstants.DDMMYYYY);
                } else if (gridControlModel.controlType === 'dateTime') {
                  return params.value && params.value != 'Invalid date'
                    ? moment(params.value).format(SystemConstants.dd_MM_yyyy_h_mm_a)
                    : '';
                } else if (gridControlModel.controlType === 'time') {
                  return params.value && params.value != 'Invalid date' ? moment(params.value).format('hh:mm A') : '';
                } else {
                  return params.value && params.value != 'Invalid date'
                    ? moment(params.value).format(SystemConstants.DDMMYYYY)
                    : '';
                }
              }, []);

              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                hide: !gridControlModel.visible,
                editable: false,
                valueFormatter: valueFormatter,
                cellStyle: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'block',
                  padding: 0,
                  color: dateFontColor ? dateFontColor : 'inherit',
                  fontSize: dateFontSize ? dateFontSize : 'inherit'
                }
                // onCellValueChanged={(params: any) => {
                //   let rowNode: any = params.node;
                //   onCellValueChanged(kagamiGridModel, rowNode, column.onChangeRequired, params.column.colId);
                // }}
              });
            }
            break;
          case 'checkbox':
          case 'boolean':
            if (kagamiGridModel.isEditableEmbedList) {
              const cellRendererParams = useCallback((params: any) => {
                return {
                  gridModel: kagamiGridModel,
                  setnewHeight: setnewHeight,
                  attributePresentation: attributePresentation,
                  isEditableEmbedList: kagamiGridModel.isEditableEmbedList,
                  onCellValueChange: onCellValueChanged,
                  rowData: params.data
                };
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                hide: !gridControlModel.visible,
                editable: false,
                cellRendererParams: cellRendererParams,
                cellRenderer: memo(checkboxCellRenderer)
              });
            } else {
              const cellRendererParams = useCallback((params: any) => {
                return {
                  gridModel: kagamiGridModel,
                  attributePresentation: attributePresentation,
                  rowData: params.data
                };
              }, []);
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                hide: !gridControlModel.visible,
                editable: false,
                cellRendererParams: cellRendererParams,
                cellRenderer: memo(checkboxCellRenderer)
              });
            }
            break;
          case 'number':
            const valueFormatter = useCallback((params: any) => {
              if (
                attributePresentation.hasOwnProperty('uiSettings') &&
                attributePresentation.uiSettings.hasOwnProperty('commaSeparated')
              ) {
                if (attributePresentation.uiSettings.commaSeparated) {
                  return params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)
                    ? 0
                    : params.value.toString().includes('.')
                    ? params.value
                        .toString()
                        .split('.')[0]
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                      '.' +
                      params.value.toString().split('.')[1]
                    : params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                  return params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)
                    ? 0
                    : params.value;
                }
              } else {
                if (kagamiGridModel.isPivot && params.node.footer) {
                  return CommonUtils.isNotEmpty(params.value) ? params.value.toLocaleString() : 0;
                }
                return params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)
                  ? 0
                  : params.value.toString();
              }
            }, []);
            const cellClass = useCallback((params: any) => {
              let textAlignment = attributePresentation?.uiSettings?.fieldAlign;
              if (
                kagamiGridModel.isEditableEmbedList &&
                CommonUtils.isNotEmpty(gridControlModel.validation) &&
                CommonUtils.isNotEmpty(params)
              ) {
                let validation = makeDefaultValidation(gridControlModel, params.node.data);
                return ['kgm-number-cell'].concat(CommonUtils.isNotEmpty(validation) ? classes.cellError : '');
              }
              let textAlignmentCls =
                textAlignment == 'right'
                  ? 'kgm-number-cell-alignRight'
                  : textAlignment == 'center'
                  ? 'kgm-number-cell-alignCenter'
                  : textAlignment == 'left'
                  ? 'kgm-number-cell-alignLeft'
                  : '';
              return ['kgm-number-cell', textAlignmentCls];
            }, []);
            // const cellClass1 = useCallback((params: any) => {
            //   return ['kgm-number-cell-alignRight'];
            // }, []);
            const tooltipValueGetter = kagamiGridModel.isEditableEmbedList
              ? undefined
              : useCallback((params: any) => {
                  if (CommonUtils.isNotEmpty(gridControlModel.validation) && kagamiGridModel.isEditableEmbedList) {
                    let validation = makeDefaultValidation(gridControlModel, params.node.data);
                    return CommonUtils.isNotEmpty(validation) ? validation : undefined;
                  }
                  return undefined;
                }, []);

            const onCellValueChangedNm = useCallback((params: any) => {
              console.log('in cell value changed');
              let rowNode: any = params.node;
              let prevVal = '';
              let isNumVal = true;
              if (!isNumber(params.newValue) && params.newValue?.length > 0) {
                prevVal = removeLastLetter(params.newValue) || 0;
                params.newValue = prevVal;
                rowNode.data[attributeName] = prevVal;
                isNumVal = false;
              }
              if (prevVal != params.oldValue || isNumVal) {
                if (CommonUtils.isNotEmpty(params.newValue)) {
                  // nCellValueChanged(kagamiGridModel, rowNode, column.onChangeRequired, params.column.colId);
                } else {
                  rowNode.data[attributeName] = 0;
                  // onCellValueChanged(kagamiGridModel, rowNode, column.onChangeRequired, params.column.colId);
                  // rowNode.setDataValue(attributeName,params.oldValue)
                }
              } else {
                rowNode.data[attributeName] = prevVal;
              }
            }, []);
            const valueSetter = useCallback((params: any) => {
              let prevVal;
              if (!isNumber(params.newValue) && params.newValue?.length > 0) {
                prevVal = removeLastLetter(params.newValue) || 0;
                params.data[attributeName] = prevVal;
              } else {
                params.data[attributeName] = params.newValue;
              }
              return true;
            }, []);
            const suppressKeyboardEvent = useCallback((params: any) => {
              var keyCode = params.event.keyCode;
              if (keyCode == 17 || (params.event.ctrlKey && (keyCode == 67 || keyCode == 86))) {
                handleRequestProcess(kagamiGridModel, false);
                // params.colDef.headerComponentParams.$$colConfig.requestInProcess = false;
                return false;
              }
              if (params?.colDef?.headerComponentParams?.$$colConfig.column.onChangeRequired) {
                if (keyCode != 9 && keyCode != 13 && keyCode !== 16) {
                  params.colDef.headerComponentParams.$$colConfig.requestInProcess = true;
                  handleRequestProcess(kagamiGridModel, true);
                }
                if (
                  (keyCode == 9 || keyCode === 16) &&
                  params?.colDef?.headerComponentParams?.$$colConfig?.requestInProcess
                ) {
                  params.event.preventDefault();
                  return true;
                }
              }
              return false;
            }, []);

            const cellStyle = useCallback(() => getGridCellPresentations(attributePresentation), []);

            gridColumns.push({
              key: kagamiGridModel.mainEntityId + '_' + attributeName,
              field: attributeName,
              aggFunc: kagamiGridModel.isPivot ? 'sum' : '',
              valueParser: (params: any) => {
                return CommonUtils.isNotEmpty(params.newValue) ? Number(params.newValue) : 0;
              },
              headerComponentParams: {
                displayName: gridControlModel.controlDisplayName,
                $$colConfig: {
                  onCellValueChanged: onCellValueChanged,
                  column: attributePresentation,
                  kagamiGridModel,
                  columnType: gridControlModel.controlType,
                  requestInProcess: attributePresentation.onChangeRequired || false,
                  handleRequestProcess: handleRequestProcess
                },
                gridModel: kagamiGridModel,
                displayAttr: displayAttrForEmbedSearch,
                template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null
              },
              hide: !gridControlModel.visible,
              onCellClicked: (event: any) => {
                if (kagamiGridModel.isPivot && event.node.footer) {
                } else if (kagamiGridModel.isEditableEmbedList) {
                  setActiveRowNode(event, kagamiGridModel);
                }
              },
              singleClickEdit: true,
              valueFormatter: valueFormatter,
              cellClass: cellClass,
              tooltipValueGetter: tooltipValueGetter,
              editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
              onCellValueChanged: onCellValueChangedNm,
              valueSetter: valueSetter,
              // suppressNavigable = {true}
              suppressKeyboardEvent: suppressKeyboardEvent,
              cellStyle: cellStyle
            });
            break;

          case 'customNumber':
            const valueFormatterr = useCallback((params: any) => {
              if (
                attributePresentation.hasOwnProperty('uiSettings') &&
                attributePresentation.uiSettings.hasOwnProperty('commaSeparated')
              ) {
                if (attributePresentation.uiSettings.commaSeparated) {
                  return params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)
                    ? 0
                    : params.value.toString().includes('.')
                    ? params.value
                        .toString()
                        .split('.')[0]
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                      '.' +
                      params.value.toString().split('.')[1]
                    : params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                  return params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)
                    ? 0
                    : params.value;
                }
              } else {
                return params.value === SystemConstants.REMOVED_ || CommonUtils.isEmpty(params.value)
                  ? 0
                  : params.value;
              }
            }, []);
            const cellClasss = useCallback((params: any) => {
              let textAlignment = attributePresentation?.uiSettings?.fieldAlign;
              if (
                kagamiGridModel.isEditableEmbedList &&
                CommonUtils.isNotEmpty(gridControlModel.validation) &&
                CommonUtils.isNotEmpty(params)
              ) {
                let validation = makeDefaultValidation(gridControlModel, params.node.data);
                return ['kgm-number-cell'].concat(CommonUtils.isNotEmpty(validation) ? classes.cellError : '');
              }
              // if (textAlignment) {
              //   return textAlignment ? ['kgm-number-cell-alignRight']:['kgm-number-cell-alignCenter']
              // }
              //  let textAlignmentCls = textAlignment == 'right' ? 'kgm-number-cell-alignRight':(textAlignment == 'center' ? 'kgm-number-cell-alignCenter':'')
              //  let textAlignmentCls = 'praveen';
              let textAlignmentCls =
              textAlignment == 'right'
                ? 'kgm-number-cell-alignRight'
                : textAlignment == 'center'
                ? 'kgm-number-cell-alignCenter'
                : textAlignment == 'left'
                ? 'kgm-number-cell-alignLeft'
                : '';

              return ['kgm-number-cell', textAlignmentCls];
            }, []);
            const tooltipValueGetterr = kagamiGridModel.isEditableEmbedList
              ? undefined
              : useCallback((params: any) => {
                  if (CommonUtils.isNotEmpty(gridControlModel.validation) && kagamiGridModel.isEditableEmbedList) {
                    let validation = makeDefaultValidation(gridControlModel, params.node.data);
                    return CommonUtils.isNotEmpty(validation) ? validation : undefined;
                  }
                  return undefined;
                }, []);

            const onCellValueChangeCn = useCallback((params: any) => {
              console.log('in cell value changed');
              let rowNode: any = params.node;
              let prevVal = '';
              let isNumVal = true;
              if (!isNumber(params.newValue) && params.newValue?.length > 0) {
                prevVal = removeLastLetter(params.newValue) || 0;
                params.newValue = prevVal;
                rowNode.data[attributeName] = prevVal;
                isNumVal = false;
              }
              if (prevVal != params.oldValue || isNumVal) {
                if (CommonUtils.isNotEmpty(params.newValue)) {
                } else {
                  rowNode.data[attributeName] = 0;
                }
              } else {
                rowNode.data[attributeName] = prevVal;
              }
            }, []);

            const valueSetters = useCallback((params: any) => {
              let prevVal;
              if (!isNumber(params.newValue) && params.newValue?.length > 0) {
                prevVal = removeLastLetter(params.newValue) || 0;
                params.data[attributeName] = prevVal;
              } else {
                params.data[attributeName] = params.newValue;
              }
              return true;
            }, []);

            const suppressKeyboardEventcn = useCallback((params: any) => {
              var keyCode = params.event.keyCode;
              if (keyCode == 17 || (params.event.ctrlKey && (keyCode == 67 || keyCode == 86))) {
                handleRequestProcess(kagamiGridModel, false);
                return false;
              }
              if (params?.colDef?.headerComponentParams?.$$colConfig.column.onChangeRequired) {
                if (keyCode != 9 && keyCode != 13 && keyCode !== 16) {
                  params.colDef.headerComponentParams.$$colConfig.requestInProcess = true;
                  handleRequestProcess(kagamiGridModel, true);
                }
                if (
                  (keyCode == 9 || keyCode === 16) &&
                  params?.colDef?.headerComponentParams?.$$colConfig?.requestInProcess
                ) {
                  params.event.preventDefault();
                  return true;
                }
              }
              return false;
            }, []);
            const cellRendererParams = useCallback((params: any) => {
              return {
                attributePresentation: attributePresentation,
                entityConsumed: entityConsumed,
                dispPresentationRuleConfig: dispPresentationRuleConfig,
                displayAttribute: Array.isArray(displayAttributeToconsume)
                  ? displayAttributeToconsume[0]
                  : displayAttributeToconsume,
                rowData: params.data,
                gridModel: kagamiGridModel
              };
            }, []);
            const cellStyleCn = useCallback(() => getGridCellPresentations(attributePresentation), []);
            gridColumns.push({
              key: kagamiGridModel.mainEntityId + '_' + attributeName,
              field: attributeName,
              headerComponentParams: {
                displayName: gridControlModel.controlDisplayName,
                $$colConfig: {
                  onCellValueChanged: onCellValueChanged,
                  column: attributePresentation,
                  kagamiGridModel,
                  columnType: gridControlModel.controlType,
                  requestInProcess: attributePresentation.onChangeRequired || false,
                  handleRequestProcess: handleRequestProcess
                },
                gridModel: kagamiGridModel,
                displayAttr: displayAttrForEmbedSearch,
                template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null
              },
              hide: !gridControlModel.visible,
              singleClickEdit: true,
              valueFormatter: valueFormatterr,
              cellClass: cellClasss,
              tooltipValueGetter: tooltipValueGetterr,
              editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
              onCellClicked: (event: any) => {
                if (kagamiGridModel.isEditableEmbedList) {
                  setActiveRowNode(event, kagamiGridModel);
                }
              },
              onCellValueChanged: onCellValueChangeCn,
              valueSetter: valueSetters,
              suppressKeyboardEvent: suppressKeyboardEventcn,
              cellStyle: cellStyleCn,
              cellRenderer: memo(KagamiNumberCellRenderer),
              cellRendererParams: cellRendererParams
            });
            break;
          case 'customPhoneNumber':
            const cellEditorParamsCPN = useCallback((params: any) => {
              return {
                attributePresentation: attributePresentation,
                gridModel: kagamiGridModel,
                onCellValueChange: onCellValueChanged,
                entityConsumed: entityConsumed,
                gridSelectModel: gridControlModel,
                rowData: params.data
              };
            }, []);
            gridColumns.push({
              key: kagamiGridModel.mainEntityId + '_' + attributeName,
              field: attributeName,
              headerComponentParams: {
                displayName: gridControlModel.controlDisplayName,
                $$colConfig: {
                  onCellValueSearch: onCellValueSearch,
                  column: attributePresentation,
                  kagamiGridModel,
                  columnType: gridControlModel.controlType
                },
                gridModel: kagamiGridModel,
                displayAttr: displayAttrForEmbedSearch,
                template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null
              },
              hide: !gridControlModel.visible,
              editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
              singleClickEdit: !gridControlModel.readonly,
              cellEditor: 'PhoneNumberEditor',
              minWidth: 200,
              width: 200,
              cellEditorParams: cellEditorParamsCPN
            });
            break;
          case 'customText':
            const valueFormatterCt = useCallback(
              (params: any) => (params.value === SystemConstants.REMOVED_ ? '' : params.value),
              []
            );
            const cellClassCt = useCallback((params: any) => {
              if (
                kagamiGridModel.isEditableEmbedList &&
                CommonUtils.isNotEmpty(gridControlModel.validation) &&
                CommonUtils.isNotEmpty(params)
              ) {
                let validation = makeDefaultValidation(gridControlModel, params.node.data);
                return ['kgm-text-cell'].concat(
                  CommonUtils.isNotEmpty(validation) && !Object.keys(gridControlModel.validation).includes('notNull')
                    ? classes.cellError
                    : ''
                );
              }
              return ['kgm-text-cell'];
            }, []);
            const tooltipValueGetterCt = kagamiGridModel.isEditableEmbedList
              ? undefined
              : useCallback((params: any) => {
                  if (kagamiGridModel.isEditableEmbedList && CommonUtils.isNotEmpty(gridControlModel.validation)) {
                    let validation = makeDefaultValidation(gridControlModel, params.node.data);
                    return CommonUtils.isNotEmpty(validation) &&
                      !Object.keys(gridControlModel.validation).includes('notNull')
                      ? validation
                      : undefined;
                  }
                  return undefined;
                }, []);

            const cellRendererParamsCt = useCallback((params: any) => {
              return {
                attributePresentation: attributePresentation,
                entityConsumed: entityConsumed,
                dispPresentationRuleConfig: dispPresentationRuleConfig,
                displayAttribute: Array.isArray(displayAttributeToconsume)
                  ? displayAttributeToconsume[0]
                  : displayAttributeToconsume,
                rowData: params.data,
                gridModel: kagamiGridModel
              };
            }, []);
            gridColumns.push({
              key: kagamiGridModel.mainEntityId + '_' + attributeName,
              field: attributeName,
              headerComponentParams: {
                displayName: gridControlModel.controlDisplayName,
                displayAttr: displayAttrForEmbedSearch,
                gridModel: kagamiGridModel,
                $$colConfig: {
                  onCellValueChanged: onCellValueChanged,
                  column: attributePresentation,
                  kagamiGridModel,
                  columnType: gridControlModel.controlType,
                  requestInProcess: attributePresentation.onChangeRequired || false,
                  handleRequestProcess: handleRequestProcess
                },
                template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null
              },
              hide: !gridControlModel.visible,
              valueFormatter: valueFormatterCt,
              cellClass: cellClassCt,
              tooltipValueGetter: tooltipValueGetterCt,
              editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
              cellRenderer: memo(KagamiTextCellRenderer),
              cellRendererParams: cellRendererParamsCt,
              cellEditor: 'agTextCellEditor'
            });
            break;
          case 'customSearch':
            if (kagamiGridModel.isEditableEmbedList) {
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  $$colConfig: {
                    onCellValueSearch: onCellValueSearch,
                    column: attributePresentation,
                    kagamiGridModel,
                    columnType: gridControlModel.controlType
                  },
                  gridModel: kagamiGridModel,
                  displayAttr: displayAttrForEmbedSearch,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null
                },
                hide: !gridControlModel.visible,
                editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false,
                valueFormatter: useCallback((params: any) => {
                  if (params.node.footer) {
                    return '';
                  }
                  let value = params.node.data[attributeName];
                  let activeRecord = params.node.data;
                  let assoaciatedObject = getAssociatedObject(
                    activeRecord,
                    entityConsumed,
                    value,
                    getEntityAssociatedAttribute(gridControlModel.attributePresentation)
                  );
                  if (params.value || CommonUtils.isNotEmpty(value) || CommonUtils.isNotEmpty(assoaciatedObject)) {
                    let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                    return option;
                  }
                  return params.value;
                }, []),
                tooltipValueGetter: kagamiGridModel.isEditableEmbedList
                  ? undefined
                  : useCallback((params: any) => {
                      if (params.node.footer) return '';
                      if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                        let assoaciatedObject: any = {};
                        if (params.node.data[entityConsumed]) {
                          assoaciatedObject = params.node.data[entityConsumed];
                        }
                        let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                        return CommonUtils.isNotEmpty(option)
                          ? option
                          : // params.node.data[attributeName];
                            '';
                      }
                      return params.value;
                    }, []),
                cellStyle: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'block',
                  padding: 0
                },
                singleClickEdit: !gridControlModel.readonly,
                cellEditor: 'searchEditor',
                // minWidth={300}
                // width={300}
                cellEditorParams: useCallback((params: any) => {
                  return {
                    options: getLovRecords(entityConsumed, kagamiGridModel),
                    searchString: { searchValue: '' },
                    attributePresentation: attributePresentation,
                    gridModel: kagamiGridModel,
                    onCellValueChange: onCellValueChanged,
                    entityConsumed: entityConsumed,
                    entityAssociatedAttribute: getEntityAssociatedAttribute(attributePresentation),
                    gridSelectModel: gridControlModel,
                    rowData: params.data
                  };
                }, [])
              });
            } else {
              gridColumns.push({
                key: kagamiGridModel.mainEntityId + '_' + attributeName,
                field: attributeName,
                headerComponentParams: {
                  displayName: gridControlModel.controlDisplayName,
                  template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null,
                  displayAttr: displayAttrForEmbedSearch,
                  gridModel: kagamiGridModel
                },
                hide: !gridControlModel.visible,
                valueFormatter: useCallback((params: any) => {
                  if (params.value || CommonUtils.isNotEmpty(params.node.data[attributeName])) {
                    let assoaciatedObject: any = {};
                    if (params.node.data[entityConsumed]) {
                      assoaciatedObject = params.node.data[entityConsumed];
                    }
                    let option = extractDropdownOption(attributePresentation, assoaciatedObject);
                    return option;
                  }
                  return params.value;
                }, []),
                cellStyle: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'block',
                  padding: 0
                },
                editable: false,
                // minWidth={150}
                cellRenderer: memo(KagamiTextCellRenderer),
                cellRendererParams: useCallback((params: any) => {
                  return {
                    attributePresentation: attributePresentation,
                    entityConsumed: entityConsumed,
                    dispPresentationRuleConfig: dispPresentationRuleConfig,
                    displayAttribute: Array.isArray(displayAttributeToconsume)
                      ? displayAttributeToconsume[0]
                      : displayAttributeToconsume,
                    rowData: params.data,
                    gridModel: kagamiGridModel
                  };
                }, [])
              });
            }
            break;
          default:
            const valueFormatterDefault = useCallback((params: any) => {
              if (attributeName.includes('.')) {
                let valueData = getValueForTextPickAttribute(attributeName, params.data);
                return valueData;
              } else {
                return params.value === SystemConstants.REMOVED_ ? '' : params.value;
              }
            }, []);
            const cellClassDefault = useCallback((params: any) => {
              if (
                kagamiGridModel.isEditableEmbedList &&
                CommonUtils.isNotEmpty(gridControlModel.validation) &&
                CommonUtils.isNotEmpty(params)
              ) {
                let validation = makeDefaultValidation(gridControlModel, params.node.data);
                return ['kgm-text-cell', 'kgm-ellipsis-span-cell'].concat(
                  CommonUtils.isNotEmpty(validation) && !Object.keys(gridControlModel.validation).includes('notNull')
                    ? classes.cellError
                    : ''
                );
              }
              return ['kgm-text-cell', 'kgm-ellipsis-span-cell'];
            }, []);
            const tooltipValueGetterDefault = kagamiGridModel.isEditableEmbedList
              ? undefined
              : useCallback((params: any) => {
                  if (kagamiGridModel.isEditableEmbedList && CommonUtils.isNotEmpty(gridControlModel.validation)) {
                    let validation = makeDefaultValidation(gridControlModel, params.node.data);
                    return CommonUtils.isNotEmpty(validation) &&
                      !Object.keys(gridControlModel.validation).includes('notNull')
                      ? validation
                      : undefined;
                  }
                  return undefined;
                }, []);
            gridColumns.push({
              key: kagamiGridModel.mainEntityId + '_' + attributeName,
              cellStyle: {
                color: fontColor ? fontColor : 'inherit',
                fontSize: fontSize ? fontSize : 'inherit'
              },
              field: attributeName,
              headerComponentParams: {
                displayName: gridControlModel.controlDisplayName,
                $$colConfig: {
                  onCellValueChanged: onCellValueChanged,
                  column: attributePresentation,
                  kagamiGridModel,
                  columnType: gridControlModel.controlType,
                  requestInProcess: attributePresentation.onChangeRequired || false,
                  handleRequestProcess: handleRequestProcess
                },
                gridModel: kagamiGridModel,
                displayAttr: displayAttrForEmbedSearch,
                template: isMandatory && kagamiGridModel.isEmbedModel ? mandatoryIcon : null
              },
              hide: !gridControlModel.visible,
              valueFormatter: valueFormatterDefault,
              cellClass: cellClassDefault,
              tooltipValueGetter: tooltipValueGetterDefault,
              // cellRenderer: React.memo(KagamiBarCodeCellRenderer),
              editable: kagamiGridModel.isEditableEmbedList ? !gridControlModel.readonly : false
            });
            break;
        }
      }
    }
  }
  const valueSetter = useCallback((params: any) => {
    console.log('ValueSetter:' + params.newValue);
    params.newValue = CommonUtils.isNotEmpty(params.newValue) ? Number(params.newValue) : 0;
    return true;
  }, []);
  const valueFormatter = useCallback((params: any) => {
    if (params.node.footer && params.value) {
      return params.value.toFixed(1);
    }
    return params.value === undefined ? 0 : params.value;
  }, []);
  const cellClasss = useCallback((params: any) => {
    return ['kgm-sno-cell'];
  }, []);
  kagamiGridModel.isPivot &&
    gridColumns.push({
      key: kagamiGridModel.mainEntityId + '_hours',
      field: 'hours',
      aggFunc: 'sum',
      valueParser: (params: any) => {
        return CommonUtils.isNotEmpty(params.newValue) ? Number(params.newValue) : 0;
      },
      valueSetter: valueSetter,
      valueFormatter: valueFormatter,
      suppressMenu: false,
      headerComponentParams: {
        displayName: 'Hours'
      },
      cellStyle: { marginLeft: 0 },
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      resizable: false,
      editable: false,
      filterParams: {
        values: visibleColumns.map((column) => column.controlDisplayName)
      },
      suppressNavigable: true,
      cellClass: cellClasss
    });

  if (deleteTrigger !== null || editTrigger !== null) {
    const cellClass = useCallback((params: any) => {
      return ['kgm-actions actions_cell'];
    }, []);
    gridColumns.push({
      key: kagamiGridModel.mainEntityId + '_edit' + '_remove',
      field: 'Actions',
      headerName: 'Actions',
      editable: false,
      minWidth: 150,
      // hide={!kagamiGridModel.gridControls.isVisible}
      suppressNavigable: true,
      // width={150}
      headerComponentParams: { displayName: 'Actions' },
      cellRendererParams: {
        editTrigger: editTrigger,
        deleteTrigger: deleteTrigger,
        kagamiGridModel: kagamiGridModel
      },
      cellRenderer: 'actionCellRenderer',
      cellClass: cellClass
    });
    const cellStyle = useCallback((params: any) => {
      return {
        position: 'absolute',
        top: '0px',
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        width: 'auto',
        left: '-100px',
        visibility: 'hidden'
      };
    }, []);
    gridColumns.push({
      key: kagamiGridModel.mainEntityId + '_edit' + '_remove_floating',
      suppressAutoSize: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      field: 'action',
      width: 0,
      minWidth: 0,
      headerClass: 'dn',
      headerName: '',
      editable: false,
      cellStyle: cellStyle,
      cellRendererParams: {
        editTrigger: editTrigger,
        deleteTrigger: deleteTrigger,
        kagamiGridModel: kagamiGridModel
      },
      cellRenderer: 'floatingActionRenderer'
    });
  }

  return gridColumns;
}
