import { State, useState } from '@hookstate/core';
import { KagamiFavoriteModel } from '../../../../../../application/builder/home/builder/header/model/KagamiFavoriteModel';

export class KagamiHeaderFavoritesModel extends KagamiFavoriteModel {
  open: boolean;
  constructor(title: any) {
    super(title);
    this.title = title;
    this.open = false;
  }
}

export const wrapKagamiHeaderFavoriteState = (state: State<KagamiHeaderFavoritesModel>) => {
  let favoriteState = state;

  return {
    get isOpen() {
      return favoriteState.open.get();
    },

    setOpen(isOpen: boolean) {
      favoriteState.open.set(isOpen);
    },

    get content() {
      return favoriteState.content.get();
    },

    setContent(content: any) {
      favoriteState.content.set(content);
    }
  };
};
export const useKagamiHeaderFavoriteState = (state: State<KagamiHeaderFavoritesModel>) =>
  wrapKagamiHeaderFavoriteState(useState(state));
