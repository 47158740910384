import _ from 'lodash';
import React from 'react';
import { buildModal } from '../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { buildPopover } from '../../../../../../../../../../../../components/popover/controller/KagamiPopoverController';
import { KagamiPopoverModel } from '../../../../../../../../../../../../components/popover/model/KagamiPopoverModel';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { loadProcessContent } from '../../../../../controller/KagamiProcessController';
import { retrievePresentation } from '../../../../../service/PresentationService';
import { enableTrigger, onTriggerClick } from '../../../../Triggers/service/KagamiTriggersService';
import { getKagamiListModel, handleServerError } from '../../../controller/KagamiListController';
import { getEndRecord, getListTotalRecords, getStartRecord } from '../../../service/kagamiListService';
import { KagamiGridPaginationModel } from '../../grid/builder/pagination/model/KagamiGridPaginationModel';
import { buildGrid } from '../../grid/controller/KagamiGridController';
import { KagamiGridModel } from '../../grid/model/KagamiGridModel';
import { buildGridControlModels } from '../../grid/service/GridService';
import { KagamiCalendarViewModel } from '../model/KagamiCalendarViewModel';
import { buildProcessPageHeaderName } from '../../../../ProcessPageBuildler';
import dayjs from 'dayjs';
import { KagamiTriggerModel } from '../../../../Triggers/builder/trigger/model/KagamiTriggerModel';
import { confirmBox } from '../../../../Triggers/builder/TriggerAlertModal/controller/TriggerAlertModalController';

export const BuildPopOverComponentForCalendar = React.memo((props: any) => {
  let lisModel = getKagamiListModel();
  let popoverModelTest = new KagamiPopoverModel();
  let popoverComponent = buildPopover(popoverModelTest);
  lisModel.popoverModel = popoverModelTest;
  popoverComponent = buildModal(lisModel.kagamiModalModel);
  return popoverComponent;
});

export const callbackWhenDone = () => {
  let parentElement = document.querySelector('.fc-header-toolbar');
  if (parentElement) {
    parentElement.addEventListener('click', () => {
      let selectedElement = document.querySelectorAll('.EventDetail');
      if (CommonUtils.isNotEmpty(selectedElement)) {
        selectedElement[0].remove();
      }
    });
  }
};

export const handleDateClick = (
  clickedType: any,
  clickedArgs: any,
  events: any,
  kagamiCalendarViewModel: KagamiCalendarViewModel
) => {
  let currentDateEvents: any[] = [];
  let targetElement: any;
  if (clickedType == 'event') {
    currentDateEvents = events.filter((event: any) => {
      return (
        dayjs(event.start).format('YYYY-MM-DD') ==
        dayjs(clickedArgs.el.fcSeg.eventRange.range.start).format('YYYY-MM-DD')
      );
    });
    targetElement = getTargetElement(clickedArgs?.el);
    // console.log(targetElement);
  } else if (clickedType == 'date') {
    currentDateEvents = events.filter((event: any) => {
      return dayjs(event.start).format('YYYY-MM-DD') == clickedArgs.dateStr;
    });
    targetElement = getTargetElement(clickedArgs?.dayEl);
    // console.log(targetElement);
  } else if (clickedType == 'more-link') {
    currentDateEvents = events.filter((event: any) => {
      return dayjs(event.start).format('YYYY-MM-DD') == dayjs(clickedArgs.date).format('YYYY-MM-DD');
    });
    targetElement = getTargetElement(clickedArgs.jsEvent.target);
    // console.log(targetElement);
  }

  if (currentDateEvents.length && targetElement!==undefined) {
      let getCurrentMonthClickedDate = new Date(clickedArgs.view.currentStart);
      let getCalendarActiveCurrentMonthStartDate = new Date(clickedArgs.view.activeStart);
      if(clickedArgs.view.type === "dayGridMonth"){
        if(new Date(clickedArgs.el.fcSeg.eventRange.range.start).getMonth() === getCurrentMonthClickedDate.getMonth()){
          createRowEventsAndTrigger(currentDateEvents, targetElement, kagamiCalendarViewModel); 
        }
      }else{
        createRowEventsAndTrigger(currentDateEvents, targetElement, kagamiCalendarViewModel);
      }
  }else if(targetElement!==undefined){
    deleteSiblingIfAny(targetElement)
  }
};

const getTargetElement = (target:any) => {
  let parentElement = target;
  if (parentElement !== undefined) {
    while (true) {
      if (parentElement.tagName === 'TR') {
        break;
      }
      parentElement = parentElement.parentNode;
    }
  }
  return parentElement;
}

const createRowEventsAndTrigger = (
  events: any,
  targetElement: any,
  kagamiCalendarViewModel: KagamiCalendarViewModel
) => {
  deleteSiblingIfAny(targetElement)
  let childElement = document.createElement('tr');
  childElement.classList.add('EventDetail');
  let innerElement = document.createElement('td');
  innerElement.style.color = 'white';
  innerElement.style.background = '#555';
  innerElement.style.verticalAlign = 'middle';
  innerElement.style.position = 'relative';
  innerElement.colSpan = 7;
  events.forEach((event: any,index:number) => {
    let inBuiltItem =
      '<div style="display:flex; margin: 10px;"><div style="margin: 0px 0px 0px 20px; font-size: 14px">' + 
      '<span class="event-radio"></span>' +
      event?.title || '' +
      '</div></div>';
      innerElement.insertAdjacentHTML('beforeend', inBuiltItem);
    if((innerElement.lastChild?.firstChild?.firstChild as HTMLElement).className === 'event-radio'){
      (innerElement.lastChild?.firstChild?.firstChild as HTMLElement).style.background =
        event['textColor'] ?? 'rgb(30, 144, 255)';
        (innerElement.lastChild?.firstChild?.firstChild as HTMLElement).style.width = '14px';
        (innerElement.lastChild?.firstChild?.firstChild as HTMLElement).style.height = '14px';
    }
    createTriggersForAllEvent(event, kagamiCalendarViewModel).forEach((actionBtn: any) => {
      innerElement.childNodes[index].appendChild(actionBtn);
    });
  });
  let closeButton = '<button style="position:absolute; top: 0px; right:170px">^</button>';
  innerElement.insertAdjacentHTML('afterbegin', closeButton);
  innerElement.childNodes[0].addEventListener('click', ()=>{
    targetElement.nextSibling.remove();
  })
  childElement.append(innerElement);
  targetElement.after(childElement);
};

const createTriggersForAllEvent = (event: any, kagamiCalendarViewModel: KagamiCalendarViewModel) => {
  console.log(event);
  let btnArray: any[] = [];
  let viewBtn = createButton('View');
  viewBtn.style.margin = '0px 0px 0px 15px';
  viewBtn.style.fontSize = "14px"
  viewBtn.addEventListener('click', () => {
    // kagamiCalendarViewModel.kagamiModalModel.title = getTimeFormatForCalendarCell(event.title) ?? '';
    kagamiCalendarViewModel.kagamiModalModel.title = event?.title ?? '';
    kagamiCalendarViewModel.kagamiModalModel.content = buildGridEventForPopup(
      kagamiCalendarViewModel,
      event,
      "month"
    );
    kagamiCalendarViewModel.kagamiModalModel.modalWidth = 'lg';
    kagamiCalendarViewModel.kagamiModalModel.modalOnTop = true;
    kagamiCalendarViewModel.kagamiModalModel.state.setOpen(true);
  });

  //-------------------------------------------------------------------------------------------------

  btnArray = getAction(event).map((action: any) => {
    let triggerModel:KagamiTriggerModel = kagamiCalendarViewModel.triggersModel?.triggerControls[action.labelName];
    if(CommonUtils.isNotEmpty(triggerModel)){
      kagamiCalendarViewModel.triggerAlertModalModel = triggerModel.triggerAlertModalModel;
      let detailedObject: any = [getDetailedObject(event)];
      triggerModel.detailedObject = detailedObject;
      if (triggerModel.detailedObject) {
        triggerModel.enableTrigger = enableTrigger(triggerModel, triggerModel.detailedObject, triggerModel.enableTrigger);
      }
      if (triggerModel.enableTrigger) {
        let actionBtn = createButton(action.labelName);
        actionBtn.style.margin = '0px 0px 0px 5px';
        viewBtn.style.fontSize = "14px"
        let detailedObject: any = [getDetailedObject(event)];
        triggerModel.detailedObject = detailedObject;
        // triggerModel.triggerCallback = onTriggerClick(triggerModel.detailedObject, triggerModel, handleServerError, loadProcessContent, false, false);
        actionBtn.addEventListener('click', () => {
          if(triggerModel.confirmationNeeded){
            confirmBox('Confirm', triggerModel.confirmationMsg, function(flag:boolean){
              if(flag){
                // triggerModel.triggerCallback(triggerModel);
                onTriggerClick(triggerModel.detailedObject, triggerModel, handleServerError, loadProcessContent, false, false);
              }
            })
          }else{
            // triggerModel.triggerCallback(triggerModel);
            onTriggerClick(triggerModel.detailedObject, triggerModel, handleServerError, loadProcessContent, false, false);
          }
        });
        return actionBtn;
      }
    }
    return;
  });

  //-------------------------------------------------------------------------------------------------
  btnArray.unshift(viewBtn);
  console.log(btnArray);
  return _.compact(btnArray);
};

export const buildGridEventForPopup = (calendarViewModel: KagamiCalendarViewModel, eventDetails: any, fromWhere:string) => {
  let paginationModel = new KagamiGridPaginationModel(
    false,
    false,
    getListTotalRecords(calendarViewModel.onStartData),
    getStartRecord(calendarViewModel.onStartData),
    getEndRecord(calendarViewModel.onStartData),
    calendarViewModel.mainEntityId,
    calendarViewModel.processName,
    retrievePresentation(calendarViewModel.onStartData)
  );

  let kagamiGridModel: KagamiGridModel = new KagamiGridModel(
    calendarViewModel.processName,
    calendarViewModel.processStepName,
    calendarViewModel.mainEntityId,
    calendarViewModel.presentation,
    paginationModel
  );
  if(fromWhere=="month"){
    kagamiGridModel.detailedObjects = _.filter(eventDetails?.extendedProps.calendarDetailedObject ?? {}, {
      id: eventDetails.id
    });
  }else if(fromWhere=="year"){
    kagamiGridModel.detailedObjects = _.filter(eventDetails?.extendedProps.calendarDetailedObject ?? {}, {
      id: eventDetails.publicId
    });
  }
  kagamiGridModel.showGridDataCount = false;
  kagamiGridModel.isListLoaded = true;
  kagamiGridModel.isReadOnly = true;
  kagamiGridModel.isListEmbedPopModel = true;
  kagamiGridModel.isEmbedModel = true;

  buildGridControlModels(kagamiGridModel);
  return buildGrid(kagamiGridModel);
};

let deleteSiblingIfAny = function (currentNode: any) {
  let prev: any = currentNode.previousSibling;
  let next: any = currentNode.nextSibling;
  while (prev) {
    if (prev.className === 'EventDetail') {
      prev.remove();
      break;
    }
    prev = prev.previousSibling;
  }
  while (next) {
    if (next.className === 'EventDetail') {
      next.remove();
      break;
    }
    next = next.nextSibling;
  }
};

const getDetailedObject = (event: any) => {
  return event.extendedProps.calendarDetailedObject.find((detailedObject: any) => {
    return event.id === detailedObject.id;
  });
};

const getAction = (event: any) => {
  return _.filter(event.extendedProps.presentation.actions, { contextForward: true });
};

const createButton = (labelName: any) => {
  let actionBtn = document.createElement('button');
  actionBtn.style.backgroundColor = '#007ecc';
  actionBtn.style.borderColor = '#007ecc';
  actionBtn.style.color = '#fff';
  actionBtn.innerHTML = labelName;
  return actionBtn;
};

// const getTimeFormat = (title: any) => {
//   let displaySplitTime: any
//   let getMinutesForAttendence: any;
//   let getUtcMinsafterSpliting: any;
//   let attendenceStatus: any;
//   let attendenceStartTime: any;
//   let attendenceEndTime: any
//   if (title.includes(':')) {
//     if (title.split(":").length === 8) {
//       displaySplitTime = title.split(":");
//       getMinutesForAttendence = displaySplitTime[1].split('-')[0]
//       getUtcMinsafterSpliting = displaySplitTime[4].split('-')[0]
//       attendenceStatus = displaySplitTime[0] + ':' + getMinutesForAttendence;
//       attendenceStartTime = moment(displaySplitTime[1].split('-')[1] + '-' + displaySplitTime[1].split('-')[2] + '-' + displaySplitTime[1].split('-')[3] + ':' + displaySplitTime[2] + ':' + displaySplitTime[3] + ':' + getUtcMinsafterSpliting).format('MMMM Do YYYY h:mm a');
//       attendenceEndTime = moment(displaySplitTime[4].split('-')[1] + '-' + displaySplitTime[4].split('-')[2] + '-' + displaySplitTime[4].split('-')[3] + ':' + displaySplitTime[5] + ':' + displaySplitTime[6] + ':' + displaySplitTime[7]).format('MMMM Do YYYY h:mm a');
//       return attendenceStatus + ' ' + '-' + ' ' + attendenceStartTime + ' ' + '-' + ' ' + attendenceEndTime
//     } else if (title.split(":").length === 5) {
//       displaySplitTime = title.split(":");
//       getMinutesForAttendence = displaySplitTime[1].split('-')[0]
//       getUtcMinsafterSpliting = displaySplitTime[4].split('-')[0]
//       attendenceStatus = displaySplitTime[0] + ':' + getMinutesForAttendence;
//       attendenceStartTime = moment(displaySplitTime[1].split('-')[1] + '-' + displaySplitTime[1].split('-')[2] + '-' + displaySplitTime[1].split('-')[3] + ':' + displaySplitTime[2] + ':' + displaySplitTime[3] + ':' + getUtcMinsafterSpliting).format('MMMM Do YYYY h:mm a');
//       return attendenceStatus + ' ' + '-' + ' ' + attendenceStartTime
//     } else if (title.split(":").length === 2) {
//       return title;
//     }
//     else {
//       displaySplitTime = title.split(":");
//       getMinutesForAttendence = displaySplitTime[1].split('-')[0]
//       getUtcMinsafterSpliting = displaySplitTime[4].split('-')[0]
//       attendenceStatus = displaySplitTime[0] + ':' + getMinutesForAttendence;
//       attendenceStartTime = moment(displaySplitTime[1].split('-')[1] + '-' + displaySplitTime[1].split('-')[2] + '-' + displaySplitTime[1].split('-')[3] + ':' + displaySplitTime[2] + ':' + displaySplitTime[3] + ':' + getUtcMinsafterSpliting).format('MMMM Do YYYY h:mm a');
//       attendenceEndTime = moment(displaySplitTime[4].split('-')[1] + '-' + displaySplitTime[4].split('-')[2] + '-' + displaySplitTime[4].split('-')[3] + ':' + displaySplitTime[5] + ':' + displaySplitTime[6]).format('MMMM Do YYYY h:mm a');
//       return attendenceStatus + ' ' + '-' + ' ' + attendenceStartTime + ' ' + '-' + ' ' + attendenceEndTime
//     }
//   } else {
//     return title
//   }
// }

export function getCalendarHeader(calendarViewModel : KagamiCalendarViewModel){
  if(CommonUtils.isEmpty(calendarViewModel.header)){
    let header : any =  buildProcessPageHeaderName(calendarViewModel);
    if(calendarViewModel.isHeaderLoadedFromOnStartData) {
      calendarViewModel.header = '';
    } else {
    calendarViewModel.header = header;
    }
  }
   return calendarViewModel.header;
}


// old code onEventClick

// export const handleMoreEventClick = (event : any,kagamiCalendarViewModel : KagamiCalendarViewModel) => {
//   // createMoreRowEvents(event.allSegs,kagamiCalendarViewModel)
//   // const query = document.getElementById('fc-dom-2090');
//   const query = document.querySelector('.fc-daygrid');
//   // const query = event.jsEvent.target;
//   console.log(query);
// }

// const createMoreRowEvents = (events : any[],kagamiCalendarViewModel : KagamiCalendarViewModel)=>{
//   let childElement = document.createElement('tr');
//   childElement.classList.add('EventDetail');
//   childElement.id = 'randomId'
//   events!=undefined && events.map((event : any,index : number) => {
//     let innerElement = document.createElement('td');
//     let inBuiltItem = 
//       '<div style="display:flex;"><div style="margin: 0px 0px 0px 20px;">' +
//       getTimeFormatForCalendarCell(event.event._def.title) +
//       '</div></div>';
//     innerElement.insertAdjacentHTML('beforeend', inBuiltItem);
//     createTriggerForEvent(event, kagamiCalendarViewModel).forEach((actionBtn: any) => {
//       innerElement.childNodes[0].appendChild(actionBtn);
//     });
//     let closeButton = '<button style="position:absolute; top: 0px; right:170px">^</button>';
//     innerElement.insertAdjacentHTML('afterbegin', closeButton);
//     // innerElement.childNodes[0].addEventListener('click', ()=>{
//     //   parentElement.nextSibling.remove();
//     // })
  
//     innerElement.style.color = 'white';
//     innerElement.style.background = '#555';
//     innerElement.style.height = '60px';
//     innerElement.style.verticalAlign = 'middle';
//     innerElement.style.position = 'relative';
//     innerElement.colSpan = 7;
//     childElement.append(innerElement);
//     // parentElement.after(childElement);
//   })
// }

// export const handleEventClick = (event: any, kagamiCalendarViewModel: KagamiCalendarViewModel) => {
//   console.log(event);

//   let parentElement = event?.el;
//   if (parentElement !== undefined) {
//     while (true) {
//       if (parentElement.tagName === 'TR') {
//         break;
//       }
//       parentElement = parentElement.parentNode;
//     }
//   }
//   createRowForEvents(parentElement, event, kagamiCalendarViewModel);
//   // if (parentElement && parentElement['nextSibling'] && parentElement['nextSibling'].classList[0]==='EventDetail') {
//   //   createRowForEvents(parentElement, event, kagamiCalendarViewModel)
//   //   console.log(parentElement);
//   // } 
//   // else {
//   //   if (parentElement.nextSibling.id !== event?.el.fcSeg.eventRange.def.defId) {
//   //     parentElement.nextSibling.id = event?.el.fcSeg.eventRange.def.defId;
//   //     let inBuiltItem = '<div style="display:flex;"><div style="margin: 0px 0px 0px 20px;">' + getTimeFormatForCalendarCell(event?.el.fcSeg.eventRange.def.title) + '</div></div>';

//   //     if (parentElement.nextSibling.firstChild.childNodes) {
//   //       parentElement.nextSibling.firstChild.childNodes[0].remove();
//   //     }
//   //     parentElement.nextSibling.firstChild.insertAdjacentHTML('beforeend', inBuiltItem);
//   //     createTriggerForEvent(event, kagamiCalendarViewModel).forEach((actionBtn: any) => {
//   //       parentElement.nextSibling.firstChild.childNodes[0].appendChild(actionBtn);
//   //     });
//   //   } else {
//   //     parentElement.nextSibling.remove();
//   //   }
//   // }
// };

// const createRowForEvents = (parentElement:any, event: any, kagamiCalendarViewModel: KagamiCalendarViewModel) => {
//   deleteSiblingIfAny(parentElement);
//   let childElement = document.createElement('tr');
//   childElement.classList.add('EventDetail');
//   childElement.id = event.el.fcSeg.eventRange.def.defId;
//   let innerElement = document.createElement('td');
//   let inBuiltItem = 
//     '<div style="display:flex;"><div style="margin: 0px 0px 0px 20px;">' +
//     getTimeFormatForCalendarCell(event.el.fcSeg.eventRange.def.title) +
//     '</div></div>';
//   innerElement.insertAdjacentHTML('beforeend', inBuiltItem);
//   createTriggerForEvent(event, kagamiCalendarViewModel).forEach((actionBtn: any) => {
//     innerElement.childNodes[0].appendChild(actionBtn);
//   });
//   let closeButton = '<button style="position:absolute; top: 0px; right:170px">^</button>';
//   innerElement.insertAdjacentHTML('afterbegin', closeButton);
//   innerElement.childNodes[0].addEventListener('click', ()=>{
//     parentElement.nextSibling.remove();
//   })

//   innerElement.style.color = 'white';
//   innerElement.style.background = '#555';
//   innerElement.style.height = '60px';
//   innerElement.style.verticalAlign = 'middle';
//   innerElement.style.position = 'relative';
//   innerElement.colSpan = 7;
//   childElement.append(innerElement);
//   parentElement.after(childElement);
// }

// const createTriggerForEvent = (event: any, kagamiCalendarViewModel: KagamiCalendarViewModel) => {
//   console.log(event);
//   let btnArray: any[] = [];
//   let viewBtn = createButton('View');
//   viewBtn.style.margin = '0px 0px 0px 15px';
//   viewBtn.addEventListener('click', () => {
//     kagamiCalendarViewModel.kagamiModalModel.title = getTimeFormatForCalendarCell(event?.el.fcSeg.eventRange.def.title) ?? '';
//     kagamiCalendarViewModel.kagamiModalModel.content = buildGridEventForPopup(
//       kagamiCalendarViewModel,
//       event.el.fcSeg.eventRange.def
//     );
//     kagamiCalendarViewModel.kagamiModalModel.modalWidth = 'lg';
//     kagamiCalendarViewModel.kagamiModalModel.modalOnTop = true;
//     kagamiCalendarViewModel.kagamiModalModel.state.setOpen(true);
//   });

//   //-------------------------------------------------------------------------------------------------

//   btnArray = getAction(event.el.fcSeg.eventRange.def).map((action: any) => {
//     let triggerModel = kagamiCalendarViewModel.triggersModel?.triggerControls[action.labelName];
//     let detailedObject: any = [getDetailedObject(event.el.fcSeg.eventRange.def)];
//     triggerModel.detailedObject = detailedObject;
//     if (triggerModel.detailedObject) {
//       triggerModel.enableTrigger = enableTrigger(triggerModel, triggerModel.detailedObject, triggerModel.enableTrigger);
//     }
//     if (triggerModel.enableTrigger) {
//       let actionBtn = createButton(action.labelName);
//       actionBtn.style.margin = '0px 0px 0px 5px';
//       actionBtn.addEventListener('click', () => {
//         onTriggerClick(triggerModel.detailedObject, triggerModel, handleServerError, loadProcessContent, false, false);
//       });
//       return actionBtn;
//     }
//     return;
//   });

//   //-------------------------------------------------------------------------------------------------
//   btnArray.unshift(viewBtn);
//   console.log(btnArray);
//   return _.compact(btnArray);
// };

// const getDetailedObject = (def: any) => {
//   return def.extendedProps.calendarDetailedObject.find((detailedObject: any) => {
//     return def.publicId === detailedObject.id;
//   });
// };


// export const buildGridEventForPopup = (calendarViewModel: KagamiCalendarViewModel, eventDetails: any) => {
//   let paginationModel = new KagamiGridPaginationModel(
//     false,
//     false,
//     getListTotalRecords(calendarViewModel.onStartData),
//     getStartRecord(calendarViewModel.onStartData),
//     getEndRecord(calendarViewModel.onStartData),
//     calendarViewModel.mainEntityId,
//     calendarViewModel.processName,
//     retrievePresentation(calendarViewModel.onStartData)
//   );

//   let kagamiGridModel: KagamiGridModel = new KagamiGridModel(
//     calendarViewModel.processName,
//     calendarViewModel.processStepName,
//     calendarViewModel.mainEntityId,
//     calendarViewModel.presentation,
//     paginationModel
//   );
//   kagamiGridModel.detailedObjects = _.filter(eventDetails?.extendedProps.calendarDetailedObject ?? {}, {
//     id: eventDetails.publicId
//   });
//   kagamiGridModel.showGridDataCount = false;
//   kagamiGridModel.isListLoaded = true;
//   kagamiGridModel.isReadOnly = true;
//   kagamiGridModel.isListEmbedPopModel = true;
//   kagamiGridModel.isEmbedModel = true;

//   buildGridControlModels(kagamiGridModel);
//   return buildGrid(kagamiGridModel);
// };