import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';
import { LedgerDetailsModalModel } from '../../modal/model/ledgerDetailsModalModel';


export class SubLedgerReportModel extends KagamiReportModel {
  modelPopup:LedgerDetailsModalModel;
  reportData: any;
  state: any;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.modelPopup = new LedgerDetailsModalModel()
  }
}
export interface SubLedgerReportModelProps {
  subLedgerReportModel: SubLedgerReportModel;
}
export const useSubLedgerReportModelState = (state: State<SubLedgerReportModel>) => {
  let subLedgerReportState = useState(state);
  return {
    reportConfig() {
      subLedgerReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      subLedgerReportState.reportData.set(reportContent);
    }
  };
};
