import { State, useState } from '@hookstate/core';
import { KagamiLoaderModel } from '../../components/loader/model/KagamiLoaderModel';
import { KagamiApplicationController } from '../controller/KagamiApplicationController';

export class KagamiApplicationModel {
  userId: string = '';
  isUserLoggedIn: boolean = false;
  loaderModel: KagamiLoaderModel;
 // loginModel: KagamiLoginModel;
  state: any;
  userContext: any;
  appContext: any;
  logoutChannel : any

  constructor() {
    this.isUserLoggedIn = false;
    this.loaderModel = new KagamiLoaderModel();
    this.logoutChannel = new BroadcastChannel('logout');
   // this.loginModel = new KagamiLoginModel(KagamiApplicationController.onApplicationLogin);
  }
}

export const useApplicationState = (state: State<KagamiApplicationModel>) => {
  let appState = useState(state);

  return {
    get isUserLoggedIn() {
      return appState.isUserLoggedIn.get();
    },

    setUserLoggedIn(userLoggedIn: boolean) {
      appState.isUserLoggedIn.set(userLoggedIn);
    }
  };
};
