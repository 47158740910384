import { createState } from '@hookstate/core';
import {
  KagamiCalendarViewModel,
  KagamiCalendarViewModelProps,
  useCalendarListState
} from '../model/KagamiCalendarViewModel';
import { makeOnLoadCallToFetchCalendarData, loadInitialEventsforFirstLoad2 } from '../service/KagamiCalendarViewService';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import customViewPlugin from '../builder/YearView/ui/CustomYear';
import makeStyles from '@mui/styles/makeStyles';
import { callbackWhenDone, handleDateClick } from '../builder/KagamiCalendarBuilder';
import { EventWrapperForCalendarView } from './KagamiEventWrapperForCalendarview';
import { KagamiCalendarStyle } from '../../calendar/styles/CalendarStyles';
import { buildModal } from '../../../../../../../../../../../../components/modal/controller/KagamiModalController';
import { getShowCalendarSummary, translateToLocalistaion } from '../../../../../service/PresentationService';
import { disableAllTheTriggers, filterTriggersWithLabelName } from '../../../../Triggers/service/KagamiTriggersService';
import { BuildNonFieldPresentations } from '../../../../form/builder/NonFieldPresentationBuilder';
import '../styles/CalendarRadioButton.css';
import { buildTriggerAlertModal } from '../../../../Triggers/builder/TriggerAlertModal/controller/TriggerAlertModalController';
import { PageLoader } from '../../../../../../../../../../../../components/loader/ui/KagamiLoader';
import { getActiveModel } from '../../../../../controller/KagamiProcessController';

export const BuildCalendarView = (kagamiCalendarViewModel: KagamiCalendarViewModel) => {
  return (
    <CalendarViewList key="calendar-view" kagamiCalendarViewModel={kagamiCalendarViewModel}></CalendarViewList>
  );
};

export const CalendarViewList = (props: KagamiCalendarViewModelProps) => {
  const useStyles = makeStyles(KagamiCalendarStyle);
  const classes = useStyles();
  let kagamiCalendarViewModel = props.kagamiCalendarViewModel;
  // const searchModel = new KagamiListSearchModel(kagamiCalendarViewModel);
  let calendarState = useCalendarListState(createState(kagamiCalendarViewModel));
  kagamiCalendarViewModel.state = calendarState;
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;
  // let rows: any[] = retrieveListRows(kagamiCalendarViewModel);

 const triggers:any[] = filterTriggersWithLabelName(kagamiCalendarViewModel);

 const displayEventContent = (data: any) => {
   let title = data.event._def.title.split('-');
   let firstTitle: string = title.length > 0 ? title[0] : '';
   let secondTitle: string = title.length > 1 ? title[1] : '';
   let subTitle1: string = title.length > 2 ? title[2] : '';
   let subTitle2: string = title.length > 3 ? title[3] : '';
   return {
     html: `
          <div style="display:flex;white-space: break-spaces;">
            <span class="event-radio" style="background:${data.textColor}"></span> 
            <div style="display:flex; flex-direction: column">
              <div style="display:flex;  ">
                <span class='fc-event-title' style="color:${data.textColor}">${firstTitle}</span>
                <span class='fc-event-subTitle' style="color:#999">${
                  secondTitle != '' ? ' - ' + secondTitle : ''
                }</span>
              </div>
              
              ${
                subTitle1 != '' || subTitle2 != ''
                  ? `<span class="fc-event-subTitle" style="color:#999; word-wrap: break-word;">${
                      subTitle1 != '' ? subTitle1 : ''
                    }${subTitle2 != '' ? ' -' + subTitle2 : ''}</span>`
                  : ''
              }
              
            </div>
            </div>
          `
   };
 };

  if(calendarState.isListLoaded && triggers?.length == 0){
    disableAllTheTriggers(kagamiCalendarViewModel.triggersModel)
  }
  let data = {Today: "Aujourd'hui"}
  let events: any = loadInitialEventsforFirstLoad2(kagamiCalendarViewModel);
  // let events: any = loadInitialEventsforFirstLoad(rows, kagamiCalendarViewModel);

  if (!calendarState.isListLoaded && events === "undefined") {
    return <PageLoader />;
  } else {
    return (
      <>
        <div className={classes.process_headername_and_searchfilter}>
          {/* {calendarState.isHeaderLoaded ? getCalendarHeader(kagamiCalendarViewModel) : <></>} */}
          {/* {calendarState.isHeaderLoaded ? buildListSearchBar(searchModel) : <></>} */}
      </div>
        <div className={classes.root}>
          <div className={`${classes.calendar} custom-calendar`} >
            {buildTriggerAlertModal(kagamiCalendarViewModel.triggerAlertModalModel)}
            {buildModal(kagamiCalendarViewModel.kagamiModalModel)}
            <BuildNonFieldPresentations key="nonFieldModal" kagamiGridModel={kagamiCalendarViewModel} />
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, customViewPlugin]}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: 'short'
              }    
              }
              headerToolbar={{
                left: 'prev title next',
                center: '',
                right: 'customYearView,dayGridMonth,dayGridWeek,timeGridDay,today'
              }}
              views={{
                dayGridMonth: {
                  weekday: 'short',
                  buttonText: translateToLocalistaion(translations, 'Month')
                },
                customYearView: {
                  duration: { year: 1 },
                  type: 'CustomYear',
                  buttonText: translateToLocalistaion(translations, 'Year')
                },
                dayGridWeek: {
                  buttonText: translateToLocalistaion(translations, 'Week')
                },
                timeGridDay: {
                  buttonText: translateToLocalistaion(translations, 'Day')
                },
                today: {
                  buttonText: translateToLocalistaion(translations, 'Today')
                }
              }}
              viewDidMount={callbackWhenDone}
              contentHeight={'auto'}
              dayMaxEventRows={3}
              moreLinkClick={(event : any) => {
                handleDateClick('more-link',event,events,kagamiCalendarViewModel)
              }}
              fixedWeekCount={false}
              initialView="dayGridMonth"
              droppable={true}
              nowIndicator={true}
              selectable={true}
              editable={false}
              events={events}
              eventContent = {displayEventContent}
              // rerenderDelay={2000}
              dateClick={(dayClickArgs:any)=>handleDateClick('date',dayClickArgs, events,kagamiCalendarViewModel)}
              eventClick={(event: any) => handleDateClick('event',event,events,kagamiCalendarViewModel)}
              datesSet={(event: any) => makeOnLoadCallToFetchCalendarData(event, kagamiCalendarViewModel)}
              windowResize={function (arg) {
                console.log('The calendar has adjusted to a window resize. Current view: ' + arg.view.type);
              }}
              windowResizeDelay={2000}
            ></FullCalendar>
          </div>
          {getShowCalendarSummary(kagamiCalendarViewModel.onStartData.constructOutputData.uiResource.presentations.presentationRuleMap[kagamiCalendarViewModel.mainEntityId][0].uiSettings) && <div className={classes.eventsCalendarWrapper}>
            <EventWrapperForCalendarView
              eventRecords={events}
            />
          </div>}
        </div>
      </>

    );
  }
};


