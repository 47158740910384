import { createState } from '@hookstate/core';
import {  getkanbanAttributePresentation, removeDuplicateObjectFromArray, retrieveMappedstatus } from '../../../../../service/PresentationService';
import {
  KagamiKanbanSectionsViewModel,
  KagamiKanbanSectionsViewModelProps,
  useKagamiKanbanState
} from '../model/KagamiKanbanSectionsModel';
import { retrieveListColumns } from '../../ListBuilder';
import { KagamiKanbannSectionModel } from '../bulider/KanbannSection/model/KagamiKanbanSectionModel';
import { bulidKanbanSection } from '../bulider/KanbannSection/controller/KagamiKanbanSectionController';
 
export const buildKanbannView = (kagamiKanbannViewModel: KagamiKanbanSectionsViewModel) => {
  return <KagamiKanbannView key="kanbann-view" kagamikanbannViewModel={kagamiKanbannViewModel}></KagamiKanbannView>;
};

const KagamiKanbannView = (props: KagamiKanbanSectionsViewModelProps) => {
  let kagamiKanbannViewModel = props.kagamikanbannViewModel;
  let kanbanState = useKagamiKanbanState(createState(kagamiKanbannViewModel));

  kagamiKanbannViewModel.state = kanbanState;


  if(!kanbanState.getListLoaded()) {
      return <p></p>
  } else {
    let visibleAttributes = retrieveListColumns(kagamiKanbannViewModel);
    let kanbannAttribute = getkanbanAttributePresentation(kagamiKanbannViewModel.onStartData);
    let kanbannAttributeSplit = kanbannAttribute.split('.');
    let kanbannEntityId = kanbannAttributeSplit[0];
    let kanbannSecondEntityId = kanbannAttributeSplit[1];
    let kanbannSecondEntityIdStatus = kanbannAttributeSplit[2];
    let dataRecords = kagamiKanbannViewModel.detailedObjects;
    let mappedStatus = kagamiKanbannViewModel.presentation.uiSettings?.mappedStatus;
    let hiddenMappedStatus = mappedStatus.filter((column:any) => column.visible === false) ;
    let visibleMappedStatus = mappedStatus.filter((column:any) => column.visible !== false) ;
    let showMappedStatus = visibleMappedStatus.map((column:any ) => column.status);

    if(Object.keys(dataRecords).length > 0) {
      let kanbanData = retrieveMappedstatus(dataRecords[kanbannSecondEntityId] || dataRecords[kanbannSecondEntityId], kanbannSecondEntityIdStatus);
    
      let kanbanSectionsData:any= removeDuplicateObjectFromArray(kanbanData ,'status');
      let kanbannModel : KagamiKanbannSectionModel;
      if(Object.keys(dataRecords).length > 0) {



        let columns: any[] = []
        let visibleCols = kanbanSectionsData.filter((column:any) =>   showMappedStatus.includes(column.status))
        // let visibleCols = allcols.filter((column:any) => !disableMappedStatus.includes(column.status));
        columns =  visibleCols.map((ele:any) => { 
          let kanbannCardsData: any[] = [];
          dataRecords[kanbannEntityId]?.filter((datarecord:any) => {
            if(datarecord[kanbannSecondEntityId][kanbannSecondEntityIdStatus] === ele.status) {
              kanbannCardsData.push(datarecord)
            }
          }) 
        
            kanbannModel = new KagamiKanbannSectionModel(ele,kanbannCardsData,kagamiKanbannViewModel.presentation, visibleAttributes , kanbannAttribute , ele.statusHeaderColor, kagamiKanbannViewModel.processName, kagamiKanbannViewModel.mainEntityId);
            return kanbannModel
        
         
         })
        
          const mappedStatusNames = visibleMappedStatus.map((column:any) => column.status);
          columns.sort((a, b) => {
            const aIndex = mappedStatusNames.indexOf(a['kannbannDatarecord']['status']);
            const bIndex = mappedStatusNames.indexOf(b['kannbannDatarecord']['status']);
            return aIndex - bIndex;
          });

          return bulidKanbanSection(columns,kagamiKanbannViewModel.popupModel,kagamiKanbannViewModel)
      } else {
        return <p>Loading...!!</p>
      }
    }else {
      return null
    }
    
  }
};



