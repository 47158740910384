import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiFormPresentationStyle = (theme: Theme) =>
  createStyles({
    root: {
       padding: '0 4px',
      'backgroundColor': 'inherit',
      '& .row': {
        textAlign: 'start',
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '4px',
        '& .MuiFormHelperText-root': {
          marginTop: '-3px', 
          marginLeft:'0',
          fontSize:'0.7rem'
        }
      },
      '& .row> div': {
        // minHeight: '72px',
        '&:has(p)': {
          // background: red;
          // minHeight: '0',
          marginBottom:'0',
          marginTop: '0',
         
        }

        // }
      },
      '& .row > .fieldPresentation' :{
        minHeight: '72px',
        // margin: `${theme.spacing(1)} 0`,
      },
      '& .row>*': {
        width: '100%',
        maxWidth: '100%',
        padding: `0 ${theme.spacing(0.5)}`,
        // margin: `${theme.spacing(1)} 0`,
        boxSizing: 'border-box'
      },
      '& .row>.row': {
        padding: '0',
        margin: '0'
      },
      '& .row.border_one > .col_12 > .row > .border_two': {
        border: `1px solid ${theme.palette.info.light}`
      },
      '& .row.border_one > .col_12': {
        border: `1px solid ${theme.palette.info.light}`
      },
      '& .col': {
        flex: '1 0 0%'
      },
      //this styles should work in the entire form
      '& .col_4': {
        width: 'calc(100% / 3)',
        flex: ' 0 0 auto',
        [theme.breakpoints.down('sm')]: {
       
          width: '100%' ,
        
        },
      },
      '& .col_8': {
        width: 'calc(2 * (100% / 3))',
        flex: ' 0 0 auto',
        [theme.breakpoints.down('sm')]: {
       
          width: '100%' ,
        
        },
      },
      '& .col_12': {
        width: '100%',
        flex: ' 0 0 auto',
        [theme.breakpoints.down('sm')]: {
       
          width: '100%' ,
        
        },
        '&.non_field_header': {
          margin:`${theme.spacing(2)} 0`,
          minHeight:'0'
        },
        '&.non_field_p': {
           
          minHeight:'0',
        }

      },
      '&  .col_3': {
        width: 'calc(100% / 4)',
        flex: ' 0 0 auto', 
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        }, 
      },
      '& .col_2': {
        width: 'calc(100% / 6)',
        flex: ' 0 0 auto',
        [theme.breakpoints.down('sm')]: {
       
          width: '100% ' ,
        
        },
      
        
      },
      '& .col_5': {
        width: '20%',
        flex: ' 0 0 auto',
        [theme.breakpoints.down('sm')]: {
       
          width: '100%' ,
        
        },
        
      },
      '& .col_6': {
        width: 'calc(100% / 2)',
        flex: ' 0 0 auto',
        [theme.breakpoints.down('sm')]: {
       
          width: '100%' ,
        
        },
        
      },

      '& .verticalFormView .section_presentation .row': {
        'flexDirection': 'column',
        '&>.fieldPresentation': {
          'display': 'flex',
          'alignItems': 'flex-start',
          'width': '100%',
          // '& span': {
          //   width: '40%'
          // },
          '&>div': {
            width: '60%'
          }
        },
      },
      '& .verticalFormView.sectionAlignCenter .section_presentation': {
       
        '& .row> div': {
          justifyContent:'center'
        } ,
        '&>p': {
          textAlign:'center'
        }
      },
      '& .verticalFormView.sectionAlignLeft .section_presentation': {
        '& .row> div': {
          justifyContent:'flex-start'
        } ,
        '&>p': {
          textAlign:'left'
        }
      },
      '& .verticalFormView.sectionAlignRight .section_presentation': {
        '& .row> div': {
          justifyContent:'end'
        } ,
        '&>p': {
          textAlign:'right'
        }
      },
      // '& .verticalFormView.sectionAlignCenter .section_presentation .row> div': {
      //   justifyContent:'center'
      // },
      // '& .verticalFormView.sectionAlignLeft .section_presentation .row> div': {
      //   justifyContent:'flex-start'
      // },
      // '& .verticalFormView.sectionAlignRight .section_presentation .row> div': {
      //   justifyContent:'end'
      // },
    
      '& .noBorder': {
        //gave important because it should over write above borders
         border:'none'


      },
      '& .borderAll': {
        border: '1px solid #BABEC3',

        '&>div': {

          // paddingLeft:theme.spacing(1),
          // boxShadow: '0 0 1px 1px rgb(51,51,51,0.3) ',
          // background: theme.palette.background.default
        }
      },
      '& .borderRight': {
        borderRight: '1px solid #BABEC3'
      },
      '& .borderLeft': {
        borderLeft: '1px solid #BABEC3',
        '&>.section_presentation' : {
          // paddingLeft:theme.spacing(1.25)
        }
      },
      '& .borderTop': {
        borderTop: '1px solid #BABEC3'
        // '&>div': {
        // }
      },
      '& .borderDown': {
        borderBottom: '1px solid #BABEC3'
        // '&>div': {
        // }
      },
     
    },
    detailedView: {
      gap:'7px 0',
      padding:'10px',
      '&>div': {
        minHeight:'0'
      }
    },
    tree_form_grid: {
      backgroundColor: 'inherit'
    },
    groupbackbtn: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '8px',
      paddingLeft: '4px'
    }
  });
