import { createState } from '@hookstate/core';
import { Breadcrumbs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { executeProcess } from '../../../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { checkForFlowerBraces, createBreadcrumb, getHeaderNameFromActiveModel, getPresentationIdFromActiveModel } from '../controller/kagamiBreadcrumbsController';
import {
  CrumbType,
  KagamiBreadcrumbsModel,
  KagamiBreadcrumbsModelProps,
  useKagamiBreadcrumbsState
} from '../model/KagamiBreadcrumbsModel';
import { KagamiBreadcrumbsStyle } from '../style/KagamiBreadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles(KagamiBreadcrumbsStyle);

export const buildBreadcrumbComponent = (kagamiBreadcrumbsModel: KagamiBreadcrumbsModel) => {
  return <KagamiBreadcrumbs key={'kagami-breadcrumb'} kagamiBreadcrumbsModel={kagamiBreadcrumbsModel} />;
};

export const KagamiBreadcrumbs = (props: KagamiBreadcrumbsModelProps) => {
  const classes = useStyles();
  const kagamiBreadcrumbsModel = props.kagamiBreadcrumbsModel;
  const breadcrumbState = useKagamiBreadcrumbsState(createState(kagamiBreadcrumbsModel));
  kagamiBreadcrumbsModel.state = breadcrumbState;

  if (breadcrumbState.isOnLoadRequired) {
    let index = kagamiBreadcrumbsModel.breadcrumbs.findIndex((crumb: CrumbType) => {
      return (
        getPresentationIdFromActiveModel(crumb.activeModel) ==
        getPresentationIdFromActiveModel(kagamiBreadcrumbsModel.activeModel)
      );
    });
    if (index > -1 && !checkForFlowerBraces(kagamiBreadcrumbsModel.activeModel['onStartData']['constructOutputData'])) {
      let headerName = getHeaderNameFromActiveModel(kagamiBreadcrumbsModel.activeModel);
      kagamiBreadcrumbsModel.breadcrumbs[index].headerName = headerName;
    } else {
      kagamiBreadcrumbsModel.breadcrumbs.pop();
      createBreadcrumb(kagamiBreadcrumbsModel);
    }
  }

  return kagamiBreadcrumbsModel.breadcrumbs.length > 1 ? (
    <div className={classes.breadcrumb_spacing}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {kagamiBreadcrumbsModel.breadcrumbs.map((crumb: CrumbType, index:number) => {
          return crumb.activeModel.listTemplate === 'calendar' && breadcrumbState.isOnLoadRequired ? (
            <span
              key={crumb.activeModel.processName}
              className={`${
                kagamiBreadcrumbsModel.breadcrumbs.length-1 === index 
                  ? classes.crumbBold
                  : classes.breadcrumb
              } ${classes.crumbHover}`}
              onClick={() => {
                kagamiBreadcrumbsModel.breadcrumbs.length-1 !== index
                  ? executeProcess(
                      crumb.activeModel.processName,
                      crumb.detailedObjects[0],
                      kagamiBreadcrumbsModel.breadcrumbs,
                      true
                    )
                  : null;
              }}
            >
              {crumb.headerName}
            </span>
          ) : (
            <span
              key={crumb.activeModel.processName}
              className={`${
                kagamiBreadcrumbsModel.breadcrumbs.length-1 === index
                  ? classes.crumbBold
                  : classes.breadcrumb
              } ${classes.crumbHover}`}
              onClick={() => {
                kagamiBreadcrumbsModel.breadcrumbs.length-1 !== index
                  ? executeProcess(
                      crumb.activeModel.processName,
                      crumb.detailedObjects,
                      kagamiBreadcrumbsModel.breadcrumbs,
                      true
                    )
                  : null;
              }}
            >
              {crumb.headerName}
            </span>
          );
        })}
      </Breadcrumbs>
    </div>
  ) : (
    <></>
  );
};