import { KagamiControlModel } from "../../model/kagamiControlModel";


export class KagamiDateModel extends KagamiControlModel {
  createState: any;
  enableTo:any;
  enableFrom:any;
  uiSettings: any;
  weekendsSelected: any[];
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any,
    onChangeRequired: boolean,
    validation: any,
    pickAttribute:boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    enableTo:any,
    enableFrom:any,
    policyMap:any,
    presentationPolicy : any,
    businessPolicy : any,
    initiallyReadOnly: boolean,
    initiallyVisible : boolean,
    uiSettings: any,
    formDataIdentifier : string,
    isGridField : boolean,
    isEmbddedField:boolean,
    weekendsSelected:any[]
  ) {
    
    super(
      controlIdentifier,
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField
    );
    this.uiSettings = uiSettings;
    this.enableTo=enableTo;
    this.enableFrom=enableFrom;
    this.weekendsSelected = weekendsSelected
  }
}
