import { KagamiFormModel } from '../builder/form/model/KagamiFormModel';
import {
  getDefaultListView,
  getMappedEntityIdForPresentation,
  isLoadRequiredForPresentation,
  retrievePresentation,
  retrievePresentations
} from '../service/PresentationService';
import { reBuildList } from '../builder/list/controller/KagamiListController';
import { KagamiListModel } from '../builder/list/model/KagamiListModel';
import { getActiveModel, loadProcessContent, onStartCall } from '../controller/KagamiProcessController';
import { KagamiProcessResponseModel } from '../model/KagamiProcessResponseModel';
import { RuntimeInput } from '../model/RuntimeInput';
import { prepareForLoadCall } from './SubProcessService';
import { getPickItemFormModel, handleFormDataChange } from '../builder/form/controller/KagamiFormController';
import { KagamiGridModel } from '../builder/list/builder/grid/model/KagamiGridModel';
import { getEndRecord, getListTotalRecords, getStartRecord } from '../builder/list/service/kagamiListService';
import { reBuildListFilterComponent } from '../builder/list/builder/presentationFilters/controller/KagamiListFilterController';
import { getPickItemListModel } from '../builder/form/builder/NonFieldPresentationBuilder';
import { CommonUtils } from '../../../../../../../utils/CommonUtils';
import { loadGroupContent } from '../builder/group/controller/KagamiGroupController';
import { KagamiProcessPageModel } from '../model/KagamiProcessModel';
import { KagamiProcessRequestModel } from '../model/KagamiProcessRequestModel';
import { showServerErrorPopUp } from '../../../../../../../controller/KagamiApplicationController';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { getEmbedInfo } from './ProcessService';
import { isFormTemplate } from '../builder/form/service/FormService';
import { reBuildGridView } from '../builder/list/builder/grid/controller/KagamiGridController';
import { getBreadcrumbsModel, reBuildBreadcrumb, setBreadcrumbsModel } from '../builder/breadcrumbs/controller/kagamiBreadcrumbsController';
import { KagamiBreadcrumbsModel } from '../builder/breadcrumbs/model/KagamiBreadcrumbsModel';


export async function getPageOnloadContent(processResponseModel: KagamiProcessResponseModel) {
  if (isLoadRequiredForPresentation(processResponseModel.onStartData)) {
    let presentations: any[] = retrievePresentations(processResponseModel.onStartData);
    let entityId: string = getMappedEntityIdForPresentation(presentations);
    let detailedObjects: any = new Map();
    let changeFor: any = new Map();
    let embeddedInfo : any = {}
    if (processResponseModel.detailedObjects != null && CommonUtils.isNotEmpty(processResponseModel.detailedObjects[entityId])) {
      detailedObjects[entityId] = processResponseModel.detailedObjects[entityId];
      let entityMap: any = new Map();
      for (var key in detailedObjects[entityId][0]) {
        entityMap[key] = detailedObjects[entityId][0][key];
      }
      changeFor[entityId] = entityMap;
    } 
    // else {
    //   changeFor[entityId] = {};
    //   detailedObjects = processResponseModel.onStartData.constructOutputData.detailedObjects;
    // }
    let entityPresentation: any = retrievePresentation(processResponseModel.onStartData);
    if(isFormTemplate(processResponseModel.templateType)){
       embeddedInfo  = getEmbedInfo(processResponseModel.onStartData)
    }
    let viewType: string = getDefaultListView(processResponseModel.onStartData);
    if (viewType === 'calendar') {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var runtimeInput = new RuntimeInput();
      runtimeInput.calendarStart = firstDay.toISOString();
      runtimeInput.calendarEnd = lastDay.toISOString();
      let input = {
        RuntimeInput: [runtimeInput]
      };
      detailedObjects = input;
      prepareForLoadCall(
        processResponseModel.processName,
        entityPresentation,
        changeFor,
        detailedObjects,
        runtimeInput,
        processResponseModel.isPickItemCall,
        false,
        processResponseModel.templateType
      );
    } else {
      prepareForLoadCall(processResponseModel.processName, entityPresentation, changeFor, detailedObjects, '',processResponseModel.isPickItemCall,false,processResponseModel.templateType,undefined,undefined,embeddedInfo);
    }
  }
}

export function updateOnloadDataContent(response: any,isPickItemCall : boolean = false,updateRecord : boolean = false,templateType : string = '',mergeOnloadData : boolean = false, embeddedId:string = "") {
  let activeModel : any;
  if(isPickItemCall){
    if(templateType === 'list'){
      activeModel = getPickItemListModel();
    }
    else{
      activeModel = getPickItemFormModel()
    }
  }
  else{
    activeModel = getActiveModel();
  }
  if (activeModel instanceof KagamiListModel) {
    if(activeModel instanceof KagamiGridModel && activeModel.paginationModel){
      activeModel.paginationModel.totalRecords = getListTotalRecords(response.data)
      activeModel.paginationModel.startRecord = getStartRecord(response.data)
      activeModel.paginationModel.endRecord = getEndRecord(response.data)
    }
    
   reBuildList(response.data,updateRecord,templateType,mergeOnloadData);
   reBuildListFilterComponent(response.data)
  }else if (activeModel instanceof KagamiFormModel && CommonUtils.isNotEmpty(embeddedId)) {
    let gridData:any = response.data['constructOutputData']['detailedObjects'][activeModel.mainEntityId][0][embeddedId];
    let gridModel : KagamiGridModel = activeModel.formControls[embeddedId].kagamiGridModels.get(embeddedId)
    reBuildGridView(gridModel,gridData,"",false,true);
  }else if (activeModel instanceof KagamiFormModel) {
    let recordId : string = activeModel.inFocusFormDataIdentifiers[activeModel.mainEntityId]
    if((CommonUtils.isEmpty(recordId) ||recordId.includes('temp') ) && response.data.constructOutputData && CommonUtils.isNotEmpty(response.data.constructOutputData.detailedObjects[activeModel.mainEntityId])){
      let formDataId = response.data.constructOutputData.detailedObjects[activeModel.mainEntityId][0].id
      if(formDataId !== recordId){
        activeModel.inFocusFormDataIdentifiers[activeModel.mainEntityId] = formDataId;
        activeModel.formData.formDataId = formDataId;
        activeModel.formData.formData['id'] = formDataId;
      }
      let mainObject: any =
      response.data['constructOutputData']['detailedObjects'][activeModel.mainEntityId][0];
      activeModel.formData.data = response.data['constructOutputData']['detailedObjects'];
      activeModel.formData.detailedObjects =  cloneDeep(
        response.data['constructOutputData']['detailedObjects'])
        let mainObjectMap: any = new Map();
        if (mainObject && mainObject != null) {
          for (const id in mainObject) {
            mainObjectMap[id] = mainObject[id];
          }
        } else {
          let tempId: string = 'temp_' + uuidv4().toString().replace('-', '');
          mainObjectMap['id'] = tempId;
        }
        activeModel.onLoadData = CommonUtils.isNotEmpty(mainObject) ?  cloneDeep(mainObject) : {};  
        activeModel.formData.formData = mainObjectMap;
    }
    activeModel.kagamiProcessHeaderModel?.state?.setheaderVisible(true)
    handleFormDataChange(activeModel.mainEntityId,response.data,undefined,isPickItemCall);

    // let BreadcrumbsModel: KagamiBreadcrumbsModel = getBreadcrumbsModel();
    // BreadcrumbsModel.activeModel['onStartData']['constructOutputData']['detailedObjects'][activeModel.mainEntityId] = response.data['constructOutputData']['detailedObjects'][activeModel.mainEntityId];
    // setBreadcrumbsModel(BreadcrumbsModel);
    reBuildBreadcrumb()
  }
}

export function updateErrorContent(eror: any) {
  const activeModel = getActiveModel();
  if (activeModel instanceof KagamiListModel) {
  } else if (activeModel instanceof KagamiFormModel) {
    //rebuid form
  }
}

export function makeInitialProcessContentCall(kagamiProcessModel:KagamiProcessPageModel){
  const processRequestModel: KagamiProcessRequestModel = new KagamiProcessRequestModel(
    kagamiProcessModel.processName,
    kagamiProcessModel.isGroupProcess ? loadGroupContent : loadProcessContent,
    showServerErrorPopUp
  );
  if(kagamiProcessModel.detailsObjects !=null){
    if(CommonUtils.isNotEmpty(kagamiProcessModel.detailsObjects.entityType)){
      let detailedObjects:any={};
      detailedObjects[kagamiProcessModel.detailsObjects.entityType] =[kagamiProcessModel.detailsObjects];
      processRequestModel.data = detailedObjects;
    }
    else{
      processRequestModel.data = kagamiProcessModel.detailsObjects
    }
  }
  processRequestModel.fromDashboard = kagamiProcessModel.fromDashboard;
  onStartCall(processRequestModel);
}