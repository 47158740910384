import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiHierarchicalViewStyle } from '../styles/KagamiHierarchyView';
import { Tree, TreeNode } from 'react-organizational-chart';
import map from 'lodash/map';
import { createState } from '@hookstate/core';
import { NodeModel, useHierarchyNode } from './hierarchy-node/model/KagamiHierarchyViewNodeModel';
import { buildNode } from './hierarchy-node/Controller/KagamiHierarchyViewNodeController';
import { KagamiHierarchyViewProps } from '../model/KagamiHierarchyViewModel';
import { retrieveListColumns } from '../../ListBuilder';

const useStyles = makeStyles(KagamiHierarchicalViewStyle);

const ViewLine = (props: KagamiHierarchyViewProps) => {
  let hierarchyViewModel = props.kagamiHierarchyViewModel;
  let visibleAttributes = retrieveListColumns(hierarchyViewModel);
  let presentation = props.kagamiHierarchyViewModel.presentation;
  const nodeModel = new NodeModel(hierarchyViewModel, presentation, visibleAttributes);
  nodeModel.parent = hierarchyViewModel.parent;
  const nodeState = useHierarchyNode(createState(nodeModel));
  nodeModel.state = nodeState;
  nodeModel.collapsed = nodeModel.state.getCollapsed();
  const ConnectingNodes = nodeModel.parent
    ? TreeNode
    : (props: any) => (
      <Tree {...props} lineWidth={'2px'} lineColor={'#006e7f'} lineBorderRadius={'20px'}lineHeight={'45px'}>
        {props.children}
      </Tree>
    );
  return nodeModel.collapsed ? (
    <ConnectingNodes label={buildNode(nodeModel)} />
  ) : (
    <ConnectingNodes label={buildNode(nodeModel)}>
      {map(hierarchyViewModel.node.children, (child: any, index: any) => {
        let kgmHVModel = Object.assign({}, hierarchyViewModel);
        kgmHVModel.node = child;
        kgmHVModel.parent = nodeModel;
        return <ViewLine key={index} kagamiHierarchyViewModel={kgmHVModel} />;
      })}
    </ConnectingNodes>
  );
};

export const HierarchyViewLine = (props: KagamiHierarchyViewProps) => {
  const classes = useStyles();
  return (
    <Box  padding={4} className={classes.container}>
      {props.kagamiHierarchyViewModel.treeData?.map((node: any, index: any) => {
        let kagamiHierarchyViewModel = Object.assign({}, props.kagamiHierarchyViewModel);
        kagamiHierarchyViewModel.node = node;
        kagamiHierarchyViewModel.parent = undefined;
        return <ViewLine key={index} kagamiHierarchyViewModel={kagamiHierarchyViewModel} />;
      })}
    </Box>
  );
};
