export class MapReportsViewModel{
    name:string;
    options: any[];
    selectedData:any[];
    roleIdAndMappedReportData:any[];
    allSelectedData:any[];
    constructor(){
        this.name = '';
        this.options = [];
        this.selectedData = [];
        this.roleIdAndMappedReportData=[];
        this.allSelectedData=[]
    }
}
export interface MapReportsViewModelProps{
    mapReportsViewModel:MapReportsViewModel;
    translations?:any;
}