import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { getKagamiListModel, reBuildTriggers } from '../../../controller/KagamiListController';
import { KagamiCardViewModel } from '../model/KagamiCardViewModel';
import { buildCardViews } from '../ui/KagamiCardView';

export function buildCard(kagamiCardViewModel: KagamiCardViewModel) {
  return buildCardViews(kagamiCardViewModel);
}

export function reBuildCardView(response: any) {
  getKagamiListModel().detailedObjects = response;
  let cardViewState = KagamiStateManager.getStateToHolder(SystemConstants.CARD_VIEW_STATE);
  cardViewState.setListLoaded(true);
}

export function buildEditForm(event: any, selectedObject: any) {
  console.log(event.target.value);
  if (selectedObject && selectedObject.id === event.target.value) {
    getKagamiListModel().selectedObjects = [selectedObject];
    reBuildTriggers();
  }
}
