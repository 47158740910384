import { getEntityAssociatedAttribute } from '../../../../service/PresentationService';
import { KagamiControlModel } from '../../model/kagamiControlModel';

export class KagamiRadioModel extends KagamiControlModel {
  attributePresentation: any;
  prefixEntity:any;
  uiSettings: any;
  entityAssociatedAttribute : string;
  entityConsumed : string;
  associatedObject : any;
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlDisplayName: string,
    showDisplayName: boolean,
    controlValue: any,
    onChangeRequired: boolean,
    validation: any,
    pickAttribute: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    attributePresentation: any,
    policyMap: any,
    prefixEntity:any,
    presentationPolicy : any,
    businessPolicy : any,
    initiallyReadOnly: boolean,
    initiallyVisible : boolean,
    uiSettings: any,
    formDataIdentifier : string,
    isGridField : boolean,
    isEmbddedField:boolean,
  ) {
    super(
      controlIdentifier,
  
      attributeName,
      mandatory,
      visible,
      entityId,
      readonly,
      controlType,
      controlValue,
      controlDisplayName,
      showDisplayName,
      onChangeRequired,
      formDataChange,
      validation,
      pickAttribute,
      policyMap,
      presentationPolicy,
      businessPolicy,
      initiallyReadOnly,
      initiallyVisible,
      formDataIdentifier,
      isGridField,
      isEmbddedField
    );
    this.uiSettings = uiSettings;
    this.attributePresentation = attributePresentation;
    this.prefixEntity=prefixEntity;
    this.entityAssociatedAttribute = getEntityAssociatedAttribute(attributePresentation);
    this.associatedObject = {}
    this.entityConsumed = '';
  }
}

export interface RadioFieldProps {
  kagamiRadioModel: KagamiRadioModel;
}
