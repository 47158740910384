
import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiStepStyle = (theme: Theme) =>
    createStyles({
        stepRoot: {
            background: theme.palette.common.white,
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            margin:theme.spacing(2),
            marginTop: '0',
            border: '1px solid #ebebeb',
            // minHeight: theme.spacing(41.25),
            // transition : '0.2s all',
           
            // marginTop: theme.spacing(2),
            '& .MuiStepper-root ': {
                padding: ` ${theme.spacing(2)}`,
                paddingTop:'0',
            },
            '& .MuiStepLabel-root .MuiSvgIcon-root': {
                fontSize: '1.5rem',
                padding:'2px', 
                boxSizing: 'content-box',
                color : '#fff',
                border:'1px solid',
                borderColor: 'gray',
                borderRadius: '50%',

                '&.Mui-active':{
                    padding:'2px',  
                    border:'1px solid',
                    borderColor: theme.palette.primary.main,
                    borderRadius: '50%',
                    fill : theme.palette.primary.main,
                    '& .MuiStepIcon-text':{
                        fill: '#fff',
                },
            },
                '&.Mui-completed ':{
                    color: theme.palette.success.main,
                    borderColor: '#fff',
                }
            },
            '& .MuiStepLabel-label': {
                // fontSize: theme.typography.subtitle1.fontSize,
                marginTop:theme.spacing(0.5),
                '&.Mui-active': {
                    color: theme.palette.primary.main,
                },
            },
            '& .MuiStepIcon-text':{
                // fill:'#eee',
                fill: 'gray',
            },
            '& .css-3f5018-MuiStepConnector-root.MuiStepConnector-alternativeLabel': {
                top : theme.spacing(1.75),
                
            },

            '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
                borderColor: '#29947f' ,
                 
            },
             
        },
        stepFormBody: {
            // background: theme.palette.common.white,
            // borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            // margin:theme.spacing(2),
            // marginTop: '0',
            // border: '1px solid #ebebeb',
            minHeight: theme.spacing(41.25),
            // transition : '0.2s all',
             display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        buttonsRoot: {
            display: 'flex',
            width: '100%',
        },
        leftButton: {
            marginRight: 'auto',
            fontSize: '3.5rem',
            cursor: 'pointer',
            transition : '0.2s all',
            '&:hover': {
                color: theme.palette.success.main
            }
        },
        rightButton: {
            marginLeft: 'auto',
            fontSize: '3.5rem',
            cursor: 'pointer',
            transition : '0.2s all',
            '&:hover': {
                color: theme.palette.success.main
            }
        },
    })