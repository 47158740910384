import { State, useState } from '@hookstate/core';
import { KagamiTextModel } from '../textbox/model/kagamiTextModel';

export class FontStylingJSON {
  controlFontColor: string;
  controlFontBold: string;
  controlFontItalic: string;
  controlFontUnderline: string;
  controlFontSize: string;
  controlBackgroundColor: string;
  labelFontColor: string;
  labelFontBold: string;
  labelFontItalic: string;
  labelFontUnderline: string;
  labelFontSize: string;

  constructor() {
    this.controlFontColor = '';
    this.controlFontBold = 'normal';
    this.controlFontItalic = 'normal';
    this.controlFontUnderline = 'none';
    this.controlFontSize = '';
    this.controlBackgroundColor = 'transparent';
    this.labelFontColor = '';
    this.labelFontBold = 'normal';
    this.labelFontItalic = 'normal';
    this.labelFontUnderline = 'none';
    this.labelFontSize = '';
  }
}
export class KagamiControlModel {
  controlIdentifier: string;
  attributeName: string;
  mandatory: boolean;
  visible: boolean;
  entityId: string;
  readonly: boolean;
  controlType: string;
  previouseControlValue: any;
  controlValue: any;
  controlDisplayName: string;
  showDisplayName: boolean;
  onChangeRequired: boolean;
  formDataChange: (control: KagamiControlModel, value: any) => void;
  createState: any;
  state: any;
  errorMessage: any;
  validation: any;
  pickAttribute: boolean;
  displayValue: any;
  policyMap: any;
  selectValues: any;
  businessPolicy: any;
  presentationPolicy: any;
  initiallyReadOnly: boolean;
  initiallyVisible: boolean;
  formDataIdentifier: string;
  isGridField: boolean;
  isEmbddedField: boolean;
  isOpen: boolean;
  pageNumber: Number;
  attributePresentation?: any;
  $$attrSettings?: any;
  internationalCurreny: boolean;
  currencyFormat: string;
  multiSelectCheckBox: boolean;

  // getDisplayDirection(uiSettings:any) {
  //   let displayRow:boolean;
  // if(CommonUtils.isNotEmpty(uiSettings) && uiSettings.hasOwnProperty('displayDirection')) {
  //     if(uiSettings.displayDirection.value.lowercase() === 'sidebyside') {
  //       displayRow = true
  //     } else{
  //       displayRow = false
  //     }
  // } else {
  //   displayRow = false
  // }
  //   return displayRow
  // }
  getControlWidth(uiSettings: any) {
    let fieldGap = uiSettings && uiSettings.fieldGap?.value ? 'fieldGapTrue' : '';
    const fieldWidth = uiSettings ? uiSettings.fieldWidth?.value : null;
    if (fieldWidth) {
      if (fieldWidth === 1 || fieldWidth === 7 || fieldWidth === 9 || fieldWidth === 10 || fieldWidth === 11) {
        let uiSettingsClassName = `col` + ' ' + 'fieldPresentation';
        return uiSettingsClassName;
      } else if (fieldWidth === 4) {
        let uiSettingsClassName = `col_3` + ' ' + 'fieldPresentation';
        return uiSettingsClassName;
      } else {
        let uiSettingsClassName = `col_${fieldWidth}` + ' ' + 'fieldPresentation';
        return uiSettingsClassName;
      }
    } else {
      let uiSettingsClassName = `col_4` + ' ' + 'fieldPresentation';
      return uiSettingsClassName + '    ' + `${fieldGap}`;
    }
  }
  getFontStyling(uiSettings: any) {
    const fontStyling: FontStylingJSON = new FontStylingJSON();
    const fontProperties = uiSettings ? uiSettings.fontStyling : null;

    fontStyling.controlFontColor = fontProperties?.fontColor ? fontProperties.fontColor : '#33475b';
    fontStyling.controlFontBold = fontProperties?.fontBold ? 'bold' : 'normal';
    fontStyling.controlFontItalic = fontProperties?.fontItalic ? 'italic' : 'normal';
    fontStyling.controlFontUnderline = fontProperties?.fontUnderline ? 'underline' : 'none';
    fontStyling.controlFontSize = fontProperties?.fontSize;

    fontStyling.labelFontColor = fontProperties?.label?.fontColor ? fontProperties.label.fontColor : '#29947F';
    fontStyling.labelFontBold = fontProperties?.label?.fontBold ? 'bold' : 'normal';
    fontStyling.labelFontItalic = fontProperties?.label?.fontItalic ? 'italic' : 'normal';
    fontStyling.labelFontUnderline = fontProperties?.label?.fontUnderline ? 'underline' : 'none';
    fontStyling.labelFontSize = fontProperties?.label?.fontSize;

    return fontStyling;
  }
  constructor(
    controlIdentifier: string,
    attributeName: string,
    mandatory: boolean,
    visible: boolean,
    entityId: string,
    readonly: boolean,
    controlType: string,
    controlValue: any,
    controlDisplayName: string,
    showDisplayName: boolean,
    onChangeRequired: boolean,
    formDataChange: (control: KagamiControlModel, value: any) => void,
    validation: any,
    pickAttribute: boolean,
    policyMap: any,
    presentationPolicy: any,
    businessPolicy: any,
    initiallyReadOnly: any,
    initiallyVisible: boolean,
    formDataIdentifier: string,
    isGridField: boolean,
    isEmbddedField: boolean,
    $$attrSettings?: any
  ) {
    this.controlIdentifier = controlIdentifier;
    this.attributeName = attributeName;
    this.mandatory = mandatory;
    this.visible = visible;
    this.entityId = entityId;
    this.readonly = readonly;
    this.controlType = controlType;
    this.controlValue = controlType === 'number' ? controlValue : controlValue || '';
    this.controlDisplayName = controlDisplayName;
    this.showDisplayName = showDisplayName;
    this.onChangeRequired = onChangeRequired;
    this.formDataChange = formDataChange;
    this.pickAttribute = pickAttribute;
    this.validation = validation;
    this.displayValue = controlValue;
    this.policyMap = policyMap;
    this.presentationPolicy = presentationPolicy;
    this.businessPolicy = businessPolicy;
    this.initiallyReadOnly = initiallyReadOnly;
    this.initiallyVisible = initiallyVisible;
    this.formDataIdentifier = formDataIdentifier;
    this.isGridField = isGridField;
    this.isOpen = false;
    this.isEmbddedField = isEmbddedField;
    this.pageNumber = 1;
    this.$$attrSettings = $$attrSettings;
    this.internationalCurreny = false;
    this.currencyFormat = '';
    this.multiSelectCheckBox = false;
  }
}

export const wrapKagamiControlModelState = (state: State<KagamiControlModel> | State<KagamiTextModel>) => {
  let controlState = state;

  return {
    get isMandatory() {
      return controlState.mandatory.get();
    },

    setMandatory(isMandatory: boolean) {
      controlState.mandatory.set(isMandatory);
    },

    get isVisible() {
      return controlState.visible.get();
    },

    setVisible(isVisible: boolean) {
      controlState.visible.set(isVisible);
    },

    get isReadOnly() {
      return controlState.readonly.get();
    },

    setReadonly(isReadonly: boolean) {
      controlState.readonly.set(isReadonly);
    },

    get errorMessage() {
      return controlState.errorMessage.get();
    },

    setErrorMessage(value: string) {
      controlState.errorMessage.set(value);
    },

    get controlValue() {
      return controlState.controlValue.get();
    },

    setControlValue(value: any) {
      controlState.controlValue.set(value);
    },
    get controlType() {
      return controlState.controlType.get();
    },

    setControlType(value: any) {
      controlState.controlType.set(value);
    },
    get displayValue() {
      return controlState.displayValue.get();
    },

    setDisplayValue(value: any) {
      controlState.displayValue.set(value);
    },
    get selectValues() {
      return controlState.selectValues.get();
    },

    setSelectValues(value: any) {
      controlState.selectValues.set(value);
    },
    get isOpen() {
      return controlState.isOpen.get();
    },

    setIsOpen(value: boolean) {
      controlState.isOpen.set(value);
    },

    get atttributeSettings() {
      return controlState.$$attrSettings.get();
    },

    setAtttributeSettings(value: boolean) {
      controlState.$$attrSettings.set(value);
    }
  };
};

export const useKagamiControlModelState = (state: State<KagamiControlModel>) =>
  wrapKagamiControlModelState(useState(state));

export const accessKagamiControlModelState = (state: State<KagamiControlModel> | State<KagamiTextModel>) =>
  wrapKagamiControlModelState(state);
