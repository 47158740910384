import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
 
export const KagamiAttachmentsModalStyle = (theme: Theme) =>
  createStyles({
    AttachmentsModalroot: {
        
    },
    AttachmentsModalTitle: {
        background:'#f5f8fa',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0 15px'
    },
    AttachmentsModalContent: {
        // padding:'5px 10px'
    },
    AttachmentsModalActions: {
        background:'#f5f8fa',
        display:'flex',
        justifyContent:'space-between',
        padding:'0 25px'
    },
  })