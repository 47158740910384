import { CommonUtils } from "../../../utils/CommonUtils"
import { getAppAndUserContext, getDashboardDefaultView, getLogoData, getSummaryDashBoard } from "../../login/service/LoginService"
import { assignAppAndUserContextData } from "../builder/body/builder/process/service/PolicyExecutionService"
import { KagamiHomeModel } from "../model/KagamiHomeModel"

export const getLogoAppAndUserContextData =  async (homeModel: KagamiHomeModel) => {
    
    
    return await Promise.all([getLogoData(), getAppAndUserContext()]).then((response:any)=>{
        homeModel.logodata = response[0].data['logodata'] ?? null;
        if (response[1].status === 200) {
            if (response[1].data) {
              localStorage.setItem("userContextDataForKcountry",CommonUtils.isNotEmpty(response[1].data.applicationContext.context.AppSetting[0]['Kcountry']) ? JSON.stringify(response[1].data.applicationContext.context.AppSetting[0]['Kcountry'].countryCode) : 'null');
              localStorage.setItem("currencyCammaSeprated" , CommonUtils.isNotEmpty(response[1].data.applicationContext.context.AppSetting[0]['SystemLanguage']?.code) ? JSON.stringify(response[1].data.applicationContext.context.AppSetting[0]['SystemLanguage'].code): 'null');
              homeModel.bodyModel.dashboardModel.isSummaryDashBoard = getSummaryDashBoard(response[1].data);
              homeModel.bodyModel.dashboardModel.dashboardType = getDashboardDefaultView(response[1].data);
              assignAppAndUserContextData(response[1].data?.applicationContext?.context, response[1].data?.userContext?.context);
            }
          }
        homeModel.state.setLogoVisible(true);
    }).catch((err:any)=>{
        console.log('err',err);
        homeModel.state.setLogoVisible(true);
    })
}