import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { activityLogStyle } from "../styles/ActivityLogStyle";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { buildActivitySection } from "../builder/activityLogBuilder";
import {
  KagamiActivityLogControlModel,
  KagamiActivityLogModel,
  KagamiActivityLogProps,
  useKagamiActivityLogState,
} from "../model/KagamiActivityLogModel";
import { createState } from "@hookstate/core";
import {
  handleActivityLogModalOnClose,
  submitNewComent,
} from "../controller/KagamiActivityLogModalController";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import {
  KagamiActivityModel,
  useKagamiActivityState,
} from "../builder/activity/model/KagamiActivityModel";
import Avatar from "@mui/material/Avatar";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import SendIcon from "@mui/icons-material/Send";
import { getUserProfileData } from "../../../application/builder/home/builder/header/controller/KagamiPageHeaderController";
import comment from "../../../assets/images/filetypes/comment.svg";
import { handleActivityLogOnClick } from "../../../application/builder/home/builder/body/builder/process/builder/list/builder/ListBuilder";
import { CommonUtils } from "../../../application/utils/CommonUtils";
import clone from "lodash/clone";
import { getFormModel } from "../../../application/builder/home/builder/body/builder/process/builder/form/controller/KagamiFormController";

export const buildActivityControlForForm = (
  activityModel: KagamiActivityLogControlModel
) => {
  if (activityModel.visible === false) {
    return <></>;
  } else {
    return (
      <ActivityLogModalControl
        key={"activityLog_control"}
        ActivityLogModel={activityModel}
      />
    );
  }
};

export const buildActivityControlForKanban = (
  activityModel: KagamiActivityLogModel
) => {
  return (
    <>
      <ActivityLogModalControlKanban ActivityLogModel={activityModel} />
    </>
  );
};
function ActivityLogModalControlKanban(props: any) {
  const { ActivityLogModel } = props;
  let referenceId: any = clone(ActivityLogModel.controlValue);

  return (
    <IconButton
      size="small"
      onClick={() =>
        handleActivityLogOnClick(
          referenceId,
          ActivityLogModel.entityId,
          ActivityLogModel
        )
      }
    >
      <img
        alt={ActivityLogModel.attributeLabel}
        style={{ height: "18px", width: "18px" }}
        src={comment}
      />
    </IconButton>
  );
}

function ActivityLogModalControl(props: any) {
  const { ActivityLogModel } = props;
  const classes = makeStyles(activityLogStyle)();
  const uiSettingsClassName = ActivityLogModel.getControlWidth(
    ActivityLogModel.uiSettings
  );
  let formModel = getFormModel();
  let disableIcon: any;
  if (CommonUtils.isNotEmpty(formModel) && formModel.readOnly) {
    disableIcon =
      ActivityLogModel.htmlControl === "comments" &&
      CommonUtils.isEmpty(ActivityLogModel.controlValue);
  }
  return (
    <>
      {buildActivityLog(ActivityLogModel)}
      <div className={`${uiSettingsClassName}  `}>
        <FormControl fullWidth style={{ paddingTop: "3px" }}>
          <FormLabel component="span" className={classes.controlLabel}>
            {ActivityLogModel.controlDisplayName}
          </FormLabel>
          <div
            style={{
              cursor: disableIcon ? "not-allowed" : "pointer",
              width: "fit-content",
            }}
          >
            <IconButton
              size="small"
              onClick={() =>
                handleActivityLogOnClick(
                  ActivityLogModel.controlValue,
                  ActivityLogModel.entityId,
                  ActivityLogModel
                )
              }
              disabled={disableIcon}
            >
              <img
                alt={ActivityLogModel.controlDisplayName}
                style={{ height: "25px", width: "25px" }}
                src={comment}
              />
            </IconButton>
          </div>
        </FormControl>
      </div>
    </>
  );
}

export const buildActivityLog = (
  KagamiActivityLogModel: KagamiActivityLogModel
) => {
  return (
    <ActivityLogModal
      key={"activity_modal"}
      KagamiActivityLogModel={KagamiActivityLogModel}
    />
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      timeout={{ appear: 2000, enter: 5000, exit: 7000 }}
      direction="left"
      ref={ref}
      {...props}
    />
  );
});

export const ActivityLogModal = (props: KagamiActivityLogProps) => {
  const commentRef: any = React.useRef(null);
  const activityLogModel = props.KagamiActivityLogModel;
  const classes = makeStyles(activityLogStyle)();
  activityLogModel.state = useKagamiActivityLogState(
    createState(activityLogModel)
  );

  const currentUser = getUserProfileData();
  let createdBy;
  if (currentUser) {
    if (currentUser.userId === "admin") {
      createdBy = "ADMIN";
    } else {
      createdBy = currentUser.userId;
    }
  } else {
    createdBy = "Current User";
  }

  const newActivityComent: KagamiActivityModel = new KagamiActivityModel(
    `${moment().format()}`,
    createdBy,
    "",
    false,
    `${moment().format()}`,
    createdBy
  );

  return (
    <>
      <Dialog
        sx={{
          "& .MuiBackdrop-root ": {
            backgroundColor: "transparent",
          },
        }}
        PaperProps={{
          style: {
            position: "absolute",
            right: "10px",
            top: "45px",
            bottom: "0",
            width: "350px",
            margin: "0",
            height: "100%",
            borderRadius: "15px",
          },
        }}
        TransitionComponent={Transition}
        open={activityLogModel.state.isOpen}
        onClose={() => handleActivityLogModalOnClose(activityLogModel)}
      >
        <DialogTitle className={classes.activity_title}>
          <div>
            <Avatar
              sx={{
                width: 30,
                height: 30,
                marginLeft: "5px",
                background: "#fff",
              }}
            >
              <img alt="comment" style={{ height: "20px" }} src={comment} />
            </Avatar>
            <Typography variant="subtitle1">{"Activity Log"}</Typography>
          </div>
          <div>
            <IconButton
              edge="start"
              color="primary"
              onClick={() => handleActivityLogModalOnClose(activityLogModel)}
              aria-label="close"
              sx={{ width: 24, height: 24 }}
              size="small"
              style={{ background: "#fff" }}
            >
              <CloseIcon color="primary" fontSize="small" />
            </IconButton>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          {activityLogModel.state.isDataLoaded ? (
            buildActivitySection(activityLogModel)
          ) : (
            <div className={classes.loading}>
              <CircularProgress
                style={{ height: "15px", width: "15px", marginRight: "5px" }}
              />{" "}
              Loading Activities...!
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.footer_form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (newActivityComent.message) {
                submitNewComent(newActivityComent, activityLogModel);
                commentRef.current.value = "";
              }
            }}
          >
            <TextField
              inputRef={commentRef}
              id="comment"
              label="Write a comment"
              fullWidth
              size="small"
              autoFocus
              className={classes.activityLog_input}
              onChange={(e) => {
                newActivityComent.message = e.target.value;
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Avatar
                      variant="rounded"
                      className={classes.send_btn}
                      onClick={() => {
                        if (newActivityComent.message) {
                          submitNewComent(newActivityComent, activityLogModel);
                          commentRef.current.value = "";
                        }
                      }}
                    >
                      <SendIcon />
                    </Avatar>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivityLogModal;
