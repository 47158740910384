import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class BarReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
  }
}
export interface BarReportModelProps {
  barReportModel: BarReportModel;
}
export const useBarReportModelState = (state: State<BarReportModel>) => {
  let barReportState = useState(state);
  return {
    reportConfig() {
      barReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      barReportState.reportData.set(reportContent);
    },

    get isDataLoaded(){
      return barReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      barReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
