import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { KagamiEmbeddedFormPresentationModel } from '../../../model/KagamiEmbeddedFormPresentationModel';

export class KagamiEmbeddedPresentationModel {
  processName?: string;
  processStepName?: string;
  embeddedFormModels: KagamiEmbeddedFormPresentationModel[];
  embeddedView: string;
  key : string;
  formComponentObj: any;
  isVisible : boolean;
  state : any;
  embedViewId : string;

  constructor(embeddedFormModels: KagamiEmbeddedFormPresentationModel[], embeddedView: string) {
    this.embeddedFormModels = embeddedFormModels;
    this.embeddedView = embeddedView;
    this.key = Math.random().toString()
    this.isVisible = embeddedFormModels.filter((model : any) => model.visible === true).length > 0;
    this.embedViewId = '';
  }
}

export interface KagamiEmbeddedPresentationProps {
  embeddedPresentationModel: KagamiEmbeddedPresentationModel;
}

const wrapKagamiEmbedState = (state : State<KagamiEmbeddedPresentationModel>)=>{
  let embedState = state;
  return {
  get isVisible(){
   return embedState.isVisible.get()
  },
  setVisible(visible : boolean){
    embedState.isVisible.set(visible);
  },
  get embedFormModels(){
    return embedState.embeddedFormModels.get();
  },
}
}

export const useKagamiEmbedState = (state: State<KagamiEmbeddedPresentationModel>,embedViewId : string ) => {
   const embedState = wrapKagamiEmbedState(useState(state));
   KagamiStateManager.addStateToHolder(embedViewId,embedState);
   return embedState;
}
