import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiRadioFieldStyle = (theme: Theme) =>
  createStyles({
    
    controlLabel : {
      '&.MuiFormLabel-root ': {
        fontSize: theme.spacing(1.5), 
        color: theme.palette.info.dark,
       
      },

    },
    radio_group: {
      // border: '1px solid #ebebeb',
      // marginLeft: theme.spacing(-1.875),
      // padding:`0 ${theme.spacing(0.5)}`,
      padding: '0',
      '& .MuiFormControlLabel-root': {
        marginLeft: '0 !important',
        paddingLeft: '0 !important',
        gap: '5px'
      },
      '& .MuiRadio-root ' : {
        padding:0,
        color: theme.palette.primary.dark,
        // width:'fit-content'
      },
      '& .MuiTypography-root': {
        fontSize: theme.typography.caption.fontSize,
        // fontSize: '13px',

      }

    },
    readOnlyField_cont: {
      // paddingLeft: theme.spacing(1.6),
      // marginTop: theme.spacing(-1.375)
    },
    radio_field: {
      width:''
    },
    readOnlyField: {
      margin: '0',
      fontSize: '14px',
      color: theme.palette.primary.dark,
    }
  });