import { AddRounded } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getPresenationIdFromPresentation } from '../../../../../../service/PresentationService';
import { KagamiEmbedTriggerModel } from '../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/model/KagamiEmbedTriggerModel';
import { buildEmbeddedTrigger } from '../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/builder/Embed-Triggers/ui/KagamiEmbeddedTriggers';
import { addRecord } from '../../../../../form/builder/embedded-presentation/builder/embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController';
import { getFormModel } from '../../../../../form/controller/KagamiFormController';
import { KagamiFormModel } from '../../../../../form/model/KagamiFormModel';
import { updateInFocusMap } from '../../../../../form/service/FormService';
import { KagamiGridModel } from '../../model/KagamiGridModel';

function AddButtonRenderer(props: any) {
  const { gridModel } = props;
  let embed_kagamiGridModel = gridModel instanceof KagamiGridModel? gridModel.kagamiModalModel.embeddedFormPresentationModel :gridModel?.actions?.get("add")?.props?.embeddedTriggerModel?.embeddedFormPresentationModel;
 // let embed_kagamiGridModel = gridModel?.actions?.get("add")?.props?.embeddedTriggerModel?.embeddedFormPresentationModel
  let embedTriggerModel : KagamiEmbedTriggerModel = gridModel?.actions?.get("add")?.props?.embeddedTriggerModel

  embedTriggerModel.triggerCallback = () => {
    gridModel?.gridApi?.stopEditing();
    if (embed_kagamiGridModel.isNested && CommonUtils.isNotEmpty(gridModel.parentEmbedIdForNestedEmbed)) {
      let formModel : KagamiFormModel = getFormModel();
      let entityHierarchy = formModel.formData.entityHierarchies.get(getPresenationIdFromPresentation(gridModel.presentation))
      let parentEntityId : string = entityHierarchy.parentEntity
      updateInFocusMap(parentEntityId,gridModel.parentEmbedIdForNestedEmbed,formModel)
      // embed_kagamiGridModel = formModel.formControls[gridModel.mainEntityId + '.' + gridModel.parentEmbedIdForNestedEmbed]
    }
    addRecord(embed_kagamiGridModel || gridModel?.actions?.get("add")?.embeddedFormPresentationModel)
  }

  return buildEmbeddedTrigger(embedTriggerModel);
}

export default AddButtonRenderer