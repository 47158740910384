import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class AgeingReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
  }
}
export interface AgeingReportModelProps {
  ageingReportModel: AgeingReportModel;
}
export const useAgeingReportModelState = (state: State<AgeingReportModel>) => {
  let ageingReportState = useState(state);
  return {
    reportConfig() {
      ageingReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      ageingReportState.reportData.set(reportContent);
    }
  };
};
