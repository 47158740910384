import KagamiTextBoxValidator from '../../validations/KagamiControlValidator';
import { notifyChangeToForm } from '../../../form/controller/KagamiFormController';
import { KagamiNumberModel } from '../model/KagamiNumberModel';
import {BulidNumberField} from '../ui/KagamiNumber_CustomField1';

export function buildNumberBox(numberModel: KagamiNumberModel) {
  return  BulidNumberField(numberModel);
}

export function validateNumberBox(kagamiNumberModel: KagamiNumberModel, value: any) {
    kagamiNumberModel.controlValue = value;
  let errorMsg = KagamiTextBoxValidator.validate(kagamiNumberModel.controlType, value.toString().replace(/,/g,""), kagamiNumberModel.validation);

  if (errorMsg === null) {
    kagamiNumberModel.state.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiNumberModel, value);
  } else {
    kagamiNumberModel.state.setErrorMessage(errorMsg);
    notifyChangeToForm(kagamiNumberModel, value);
  }
}
