import _ from 'lodash';
import AgGridGeneralToolPanel from '../ui/AgGridGeneralToolPanel';
import { getDisplayRulesForColumn } from '../../../../service/ReportUtilService';
export function  makeHierarchyReportData(listReportController: any) {
  let defaultConfig ={
    autoGroupColumnDef: {  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
    columnDefs: [],
    defaultColDef: { flex :1,sortable: true ,minWidth: 125,filter: 'agMultiColumnFilter',resizable: true,editable: false, menuTabs: ['filterMenuTab']},
    groupDefaultExpanded: -1,
    // groupHideOpenParents:true,
    // groupIncludeTotalFooter: false,
    treeColumn: listReportController['treeColumn'],
    getRowHeight :(params: any) => 30,
    icons: {
      'menu': '<span class="ag-icon ag-icon-filter"/>',
      // groupContracted: '<div class="kgm-plus-icon radius radius-25"></div>',
      // groupExpanded: '<div class="kgm-minus-icon radius radius-25"></div>',
      'general-stats': '<span class="ag-icon ag-icon-custom-stats"></span>',
      'icon-menu': '<span class="ag-icon ag-icon-menu"></span>'
    },
    pinnedBottomRowData:[],
    rowData:[],
    sideBar : {
      toolPanels: [
        {
          id: 'general',
          labelDefault: 'General',
          labelKey: 'general',
          iconKey: 'icon-menu',
          toolPanel: AgGridGeneralToolPanel,
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
      ],
      position: 'right',
      defaultToolPanel: ''
    },
  };
  
  
  let userConfig = prapareUserConfigForReport(listReportController,defaultConfig);  
  let dbChartData = JSON.parse(listReportController.reportData);
  let disptabelData = getReportData(dbChartData,listReportController);
  userConfig['rowData'] = disptabelData;
  let reportConfig = _.extend(true, {}, defaultConfig, userConfig);
  return reportConfig;
}

  function prapareUserConfigForReport (listReportController: any,defaultConfig:any) {
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    userConfig['columnDefs'] = getReportColumnDefs(listReportController,defaultConfig);
    return userConfig;
  }
  const  getReportColumnDefs = (listReportController:any,defaultConfig:any) => {
    let columnDefs: any = [];
    let dbChartData  = JSON.parse(listReportController.reportData);
    if(dbChartData?.table?.cols) {
      dbChartData.table.cols.forEach((field: any) => {
        if(field.label!==listReportController['treeColumn']){
            let temp:any = {
              field: field.label,
              headerName: field.label,
              valueFormatter: field.type == "number"? (params: any) => {
                return params.value?.toFixed(params.colDef?.precision ?? 2);
              } : ""
            }
            // ---if extra thing needed then keep it in a function----
            let definedField = _.cloneDeep(field);
            if (listReportController?.reportConfig?.criteria?.columns) {
              let definedColArr = _.filter(listReportController.reportConfig.criteria.columns, { name: field.label });
              if (definedColArr && definedColArr.length > 0) {
                definedField = definedColArr[0];
              }
            }
            let dispRules = getDisplayRulesForColumn(definedField);
            if (dispRules['aggregate']) {
              temp['aggFunc'] = dispRules['aggregate'].toLowerCase();
            }
            // -------------up to here--------------------------------
            columnDefs.push(temp);
        }
      });
    }
    return columnDefs;
  }

   const getReportData = (dbChartData:any,listReportController:any) => {
    let reportJsonData:any = [];
    if( dbChartData.table){
        let keys=_.map( dbChartData.table.cols,"label");
        dbChartData.table.rows.map(function (row:any, index:number) {
            let tempData :any = {};
            _.map(keys, function (key, i) {
                if(key==listReportController['treeColumn']){
                    tempData[key] = row.c[i].v.split(' -> ')
                }else{
                    tempData[key] = row.c[i].v;
                }
            });
            reportJsonData.push(tempData);
        });
   }
    return reportJsonData;
}       
