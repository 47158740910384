import { Box, InputAdornment, List, ListItem, ListItemButton, ListItemText, Modal, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { KagamiGlobalSearchStyle } from '../styles/globalSearchstyles';
import { KagamiDashboardModel } from '../../dashboard/model/KagamiDashboardModel';
import { Typography } from '@mui/material';
import { executeProcess, setCurrentProcessName } from '../../../../navigator/builder/Menu/controller/KagamiMenuController';
import { useState, createRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { getDashboardModel } from '../../dashboard/ui/KagamiDashboard';
import { usePanelState } from '../../../../navigator/builder/Menu/model/KagamiPanelState';
import CloseIcon from '@mui/icons-material/Close';
import { translateToLocalistaion } from '../../process/service/PresentationService';

const style = {
  position: 'absolute' as 'absolute',
  width: 700,
  boxShadow: 1,
  bgcolor: 'background.paper',
  p: 2.5
};

const useStyles = makeStyles(KagamiGlobalSearchStyle);

export const GlobalSearchComponent = (props: any) => {
  // let regex = /^[^ !"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+$/;
  let regex = /^[a-z -]+$/i;
  const [cursor, setCursor] = useState<null | number>(null);
  const [showList, setShowList] = useState(false);
  const [allprocess, setAllProcess] = useState('');
  const panelState = usePanelState();
  let refList: any = {};
  // const debounceCallForGlobalSearch = React.useCallback(CommonUtils._debounceFtn(searchGlobalProcess, 150), []);
  function searchGlobalProcess(event: any) {
    if (event.target.value != '') {
      setShowList(true);
    } else {
      setShowList(false);
    }
    setAllProcess(event.target.value);
  }

  const excuteProcesCall = (processName: any, parents: string[]) => {
    panelState.SetActiveItem(processName);
    panelState.SetActiveItemParents(parents);
    props.bodyState.setGlobalMenuSearchLoaded(false);
    setCurrentProcessName(processName);
    executeProcess(processName);
  };
  let dashboardModel: KagamiDashboardModel = getDashboardModel();
  let translations = dashboardModel.containerData?.otherProperties?.translation;
  let globalSearchName: any = [];
  const classes = useStyles();
  if (dashboardModel.containerData && dashboardModel.containerData.container) {
    dashboardModel.containerData.container.forEach((ele: any) => {
      if (ele.hasOwnProperty('menuPanel')) {
        if (ele.menuPanel.hasOwnProperty('menuItems') && ele.menuPanel.menuItems.length > 0) {
          ele.menuPanel.menuItems.forEach((obj: any) => {
            if (obj.hasOwnProperty('subMenus') && obj.subMenus.length > 0) {
              obj.subMenus.forEach((data: any) => {
                if (data.hasOwnProperty('subMenus') && data.subMenus.length > 0) {
                  data.subMenus.forEach((sunMenu: any) => {
                    const parents: string[] = [];
                    parents.push(obj.name);
                    parents.push(data.name);
                    if (sunMenu.hasOwnProperty('subMenus') && sunMenu.subMenus.length > 0) {
                      parents.push(sunMenu.name);
                      sunMenu.subMenus.forEach((submenu: any) => {
                        globalSearchName.push({
                          label: translateToLocalistaion(translations, submenu.groupName + '-' + submenu.name),
                          processName: submenu?.event?.process,
                          parents: translateToLocalistaion(translations, parents)
                        });
                      });
                    } else {
                      globalSearchName.push({
                        label: translateToLocalistaion(translations, sunMenu.groupName + '-' + sunMenu.name),
                        processName: sunMenu.event.process,
                        parents: translateToLocalistaion(translations, parents)
                      });
                    }
                  });
                } else if (data.event) {
                  globalSearchName.push({ label: translateToLocalistaion(translations, data.groupName + '-' + data.name), processName: data?.event?.process, parents: translateToLocalistaion(translations, []) });
                }
              });
            } else {
              globalSearchName.push({ label: translateToLocalistaion(translations, obj.name), processName: obj?.event?.process, parents: translateToLocalistaion(translations, [] )});
            }
          });
        }
      }
      return globalSearchName;
    });
  } else {
    return globalSearchName;
  }

  const globalSearchOptionsFilterData =
    globalSearchName.length > 0
      ? globalSearchName.filter((globalsearch: any) => {
          if (!allprocess) return true;
          if (regex.test(allprocess)) {
            if (
              globalsearch.label.toLowerCase().match(allprocess.toLowerCase()) ||
              globalsearch.label.toUpperCase().match(allprocess.toUpperCase())
            ) {
              return true;
            }
          } else {
            return false;
          }
          return false;
        })
      : [];

  const keyboardEventHandler = (e: any) => {
    if (cursor === null) {
      if (e.key === 'ArrowDown') {
        setCursor(0);
        refList[0]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      } else if (e.key === 'ArrowUp') {
        setCursor(globalSearchOptionsFilterData.length - 1);
        refList[globalSearchOptionsFilterData.length - 1]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    } else if (typeof cursor === 'number') {
      if (e.key === 'Enter') {
        let processLabel: any = globalSearchOptionsFilterData[cursor];
        excuteProcesCall(processLabel.processName, processLabel.parents);
      } else {
        if (e.key === 'ArrowDown' && cursor === globalSearchOptionsFilterData?.length - 1) {
          setCursor(0);
          refList[0]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        } else if (e.key === 'ArrowUp' && cursor === 0) {
          setCursor(globalSearchOptionsFilterData.length - 1);
          refList[globalSearchOptionsFilterData.length - 1]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        } else if (e.key === 'ArrowDown') {
          setCursor((prev: any) => {
            refList[prev + 1]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            return prev + 1;
          });
        } else if (e.key === 'ArrowUp') {
          setCursor((prev: any) => {
            refList[prev - 1]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            return prev - 1;
          });
        }
      }
    }
  };

  return (
    <>
      <Modal
        style={{}}
        disableAutoFocus={true}
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.globalbreakpoint}>
        <CloseIcon className={classes.globalSearchClose} onClick={() => { props.bodyState.setGlobalMenuSearchLoaded(false) }} />
          <TextField
            autoFocus={true}
            autoComplete="off"
            color="primary"
            variant="standard"
            placeholder="Where Do You Want To Go..?"
            fullWidth
            onKeyDown={keyboardEventHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              setCursor(null);
              searchGlobalProcess(e);
            }}
          />
          {showList ? (
            <div className={classes.globalSearchOptionsFilterData}>
              <List>
                {globalSearchOptionsFilterData.map((processLabel: any, index: any) => {
                  refList[index] = createRef();
                  return (
                    <ListItem
                      ref={refList[index]}
                      onClick={() => excuteProcesCall(processLabel.processName, processLabel.parents)}
                      className={classes.globalList}
                      style={{
                        backgroundColor: cursor === index ? '#007ecc' : 'white',
                        color: cursor === index ? 'white' : '#007ecc'
                      }}
                    >
                      <ListItemButton>
                        <ListItemText
                          className={classes.filterOptions}
                          primary={
                            <>
                              <Typography variant="caption" color="primary" className={classes.filterOptionsSecondTag}>
                                {processLabel.label.toLowerCase().includes(allprocess.toLowerCase()) ? (
                                  <span>
                                    <GetHighlightedString
                                      propcessLabel={translateToLocalistaion(translations, processLabel.label)}
                                      allprocess={allprocess}
                                      // highlightedItemClass={classes.highlightedEnterValue}
                                      active={cursor === index ? true : false}
                                    />
                                  </span>
                                ) : (
                                  <span>{translateToLocalistaion(translations, processLabel.label)}</span>
                                )}
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          ) : null}
        </Box>
      </Modal>
    </>
  );
};


export const GetHighlightedString  = (props:any) =>{
  const {propcessLabel , allprocess , highlightedItemClass, active} = props;
  const getSearchKeys = propcessLabel.split(new RegExp(`(${allprocess})`, "gi"));
  let dashboardModel: KagamiDashboardModel = getDashboardModel();
  let translations = dashboardModel.containerData?.otherProperties?.translation;
  return (
    <>
      {" "}
      {getSearchKeys.map((searchkey:any, index:any) => {
        const highlightStyle = allprocess.length > 1 && searchkey.toLowerCase() === allprocess.toLowerCase() ? highlightedItemClass : "";
        return (
          <span key={index} className={highlightStyle}
            style={{
              color: active ? '#ffffff' : '#000000'
            }}
          >
            {/* {searchkey} */}
            {translateToLocalistaion(translations, searchkey)}
          </span>
        );
      })}{" "}
    </>
  );
}