import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";
import { getFormModel } from "../../../form/controller/KagamiFormController";
import { KagamiFormModel } from "../../../form/model/KagamiFormModel";

 

export class FileValidator {
    static  errorText:any;
  
    static  validate( validations:any, file:any, allowedFiles:any,  fileModel: any) {
      // let file : any;
      // for(file of files){
        let formModel : KagamiFormModel = getFormModel();
        let controlValue : any = formModel.mainEntityId !== fileModel.entityId ? file : fileModel.controlValue
        let fileSize = file?.size;
       let  fileSizeInKb = fileSize/1000;
       if(CommonUtils.isNotEmpty(fileSize && fileSizeInKb)) {
       if (Object.keys(validations).length !== 0) {
        FileValidator.errorText = null
        for (var k in validations) {
         switch(k){
           case 'notNull':
             FileValidator.notNullValidation(file, fileModel);
             break;
  
           case 'fileFormat' :
             FileValidator.formatValidation(allowedFiles,file,fileModel);
             break;
  
           case 'fileSize' :
            FileValidator.fileSizeValidation(fileSize,validations[k]);
             break;
  
           default :
              break;
         }
        }
        // if(CommonUtils.isNotEmpty(FileValidator.errorText)){
        //   break;
        // }
      }
    } else if(fileModel.mandatory && CommonUtils.isEmpty(controlValue)) {
      FileValidator.errorText = 'This field is mandatory';
    }
    // }
    return FileValidator.errorText;

    }
  
    static notNullValidation(value:any, fileModel:any) {
      value ? FileValidator.errorText = null : FileValidator.errorText = fileModel.controlDisplayName + 'field is mandatory';
    }
    static  formatValidation( allowedFiles:any, file:any, fileModel: any){
      let extension:string;
        if(allowedFiles.formats !=""){
          extension = file.name.substring(file.name.lastIndexOf('.') + 1);
          // let fileMismatch = !allowedFiles.contains(extension);
          // console.log(fileMismatch);
          if (fileModel.isEmbddedField) {
            let fileFormats = allowedFiles.formats.split(',');
            let fileTypeFound = fileFormats.find((item: any) => item === extension);
            if (fileTypeFound) {
              FileValidator.errorText = null;
            } else {
              FileValidator.errorText = 'File Upload failed... ' + file?.name + ' file type is not supported'
              // .supports only ' + allowedFiles.formats;
            }
          } else {
            allowedFiles.formats.includes(extension.toLocaleLowerCase())
              ? (FileValidator.errorText = null)
              : (FileValidator.errorText =
                  file?.name + ' file type is not supported. supports only ' + allowedFiles.formats);
          }
        } else {
          allowedFiles.formats.includes('')
            ? (FileValidator.errorText = null)
            : (FileValidator.errorText =
                file?.name + ' file type is not supported. supports only ' + allowedFiles.formats);
        }
      }
      static  fileSizeValidation(fileSize:any,sizeMap:any)  {
        if(fileSize != null){
          for(var size in sizeMap){
            switch(size){
              case 'min' :
              fileSize < sizeMap[size] ? FileValidator.errorText = 'Please upload file with sizes between '+ sizeMap['min'] + ' Kb and '+ sizeMap['max'] +' Kb' : FileValidator.errorText = null;
                break;
    
              case 'max' :
                fileSize > sizeMap[size] ? FileValidator.errorText = ' Please upload file less than ' + sizeMap[size] : FileValidator.errorText = null;
                break;
            }
            if(FileValidator.errorText != null){
              break;
            }
          }
        }
      }
  }
  