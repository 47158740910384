export const BuildServerError = (error: any) => {
  let errorMessage: string = "Unexpected error";
  if (error["error"] != null) {
    errorMessage = error["error"]["DEFAULT_ERROR_MESSAGE"];
  } else if (error["BUSINESS_VALIDATION_FAILED"] != null) {
    errorMessage = error["BUSINESS_VALIDATION_FAILED"];
  } else if (error["INVALIDATE"] !== null) {
    errorMessage = error["INVALIDATE"];
  } else if (error["stackTrace"] !== null) {
    errorMessage = error["stackTrace"];
  }
  return <div>{errorMessage} </div>;
};

export const BuildClientError = (error: any) => {
  let errorMessage: string = "Unexpected error";
  if (error["stackTrace"] !== null) {
    errorMessage = error["stackTrace"];
  }
  return <>{errorMessage} </>;
};
