import { getKagamiListModel } from "../../../../../controller/KagamiListController";
import { KagamiListFilterModel } from "../../../model/KagamiListFilterModel";
import { KagamiPresentationFilterModel } from "../../kagamiPresentationFilter/model/KagamiPresentationFilterModel";
import { buildListFilter } from "../ui/ListFilterWidget";



export const buildListFilterWidget = (kagamiListFilterModel: KagamiListFilterModel) => {
 return buildListFilter(kagamiListFilterModel)
}
 
export function buildPresentationObjForWidget(kagamiPresentationFilterModel:KagamiPresentationFilterModel, kagamiListFilterModel :KagamiListFilterModel){
 
    let label =kagamiPresentationFilterModel.dataRecordsForPresentationAttribute.label
 
   const filtersData: any = kagamiPresentationFilterModel.filterDataForSearchableAttributes;
     
    return {[label] : filtersData }
}