import { TriggerAlertModalModel } from "../model/TriggerAlertModalModel"
import { buildTriggerAlertModalComponent } from "../ui/TriggerAlertModal"


let triggerAlertModal: TriggerAlertModalModel;
export const buildTriggerAlertModal = (triggerAlertModalModel: TriggerAlertModalModel) => {
    triggerAlertModal = triggerAlertModalModel;
    return buildTriggerAlertModalComponent(triggerAlertModalModel)
}

export function setTriggetModel(triggerAlert: TriggerAlertModalModel){
    triggerAlertModal = triggerAlert;
}

export function confirmBox(alertName:string, message:string,callback:any){
    triggerAlertModal.alertName = alertName;
    triggerAlertModal.alertMessage = message;
    triggerAlertModal.callback = callback;
    triggerAlertModal.state.setOpen(true);
}
export function handleTrigger(check:boolean){
    triggerAlertModal.callback(check)
    triggerAlertModal.state.setOpen(false);
}