import { State, useState } from '@hookstate/core';

export class KagamiAccordionModel {
  active: boolean;
  disabled: boolean;
  title: JSX.Element;
  content: JSX.Element;
  transactionType: string;
  state: any;

  constructor(title: JSX.Element, content: JSX.Element, _active: boolean) {
    this.disabled = false;
    this.title = title;
    this.content = content;
    this.state = null;
    this.active = _active;
    this.transactionType = '';
  }
}

export interface AccordionProps {
  accordionModel: KagamiAccordionModel;
}

export const wrapKagamiaccordionState = (state: State<KagamiAccordionModel>) => {
  let accordionState = state;

  return {
    get active() {
      return accordionState.active.get();
    },
    setActive(expanded: boolean) {
      accordionState.active.set(expanded);
    }
  };
};

export const useKagamiAccordionState = (state: State<KagamiAccordionModel>) =>
  wrapKagamiaccordionState(useState(state));
