import { createState } from '@hookstate/core';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
// import TreeItem, { Autocomplete, Button, Chip, CircularProgress, Popover, Typography }from '@mui/lab/TreeItem';
// import TreeView from '@mui/lab/TreeView';
import { Autocomplete, Button, Chip, CircularProgress, Popover, Typography } from '@mui/material';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
// import treeItemClasses from '@mui/x-tree-view'
// -import { TreeItem, treeItemClasses, TreeItemProps, TreeView } from '@mui/lab';
import { TreeView, TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useState } from 'react';

import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { translateToLocalistaion } from '../../../../../../../process/service/PresentationService';
import { buildReportModal } from '../../../../../../view/ReportModal/controller/ReportModalController';
import { ReportModalCustomButton } from '../../../../../../view/ReportModal/model/ReportModalModel';
import { handleReportTileClick, mappingFolderWithParentFolder, mappingReportsWithParentFolder } from '../../../../../controller/ReportsLandingPageController';
import { ParentId } from '../../../../../model/ReportsLandingPageModel';
import { updateFolderDetailsForReport } from '../../../../../service/ReportsLandingPageService';
import { handleClick, handleClose } from '../controller/ReportIconPopoverController';
import { ReportIconPopoverModel, ReportIconPopoverModelProps, useReportIconPopoverState } from '../model/ReportIconPopoverModel';
import { createFolder, flatteningFoldersValueToArrayReportIcon, getReportsAndFolderDataForReportIconPopover } from '../service/ReportIconPopoverService';
import { reportsIconPopoverStyle } from '../style/ReportIconPopoverStyle';

const useStyles = makeStyles(reportsIconPopoverStyle);
export const buildReportIconPopoverComponent = (reportIconPopoverModel: ReportIconPopoverModel, translations?: any) => {
  return <ReportIconPopover key={reportIconPopoverModel.key} reportIconPopoverModel={reportIconPopoverModel} translations={translations} />;
};

export const ReportIconPopover = (props: ReportIconPopoverModelProps) => {
  const classes = useStyles();
  const reportIconPopoverModel = props.reportIconPopoverModel;
  let translateWords: any = props?.translations;
  const reportIconState = useReportIconPopoverState(createState(reportIconPopoverModel));
  reportIconPopoverModel.state = reportIconState;

  return (
    <>
      {buildReportModal(reportIconPopoverModel.reportModalModel)}
      <IconButton className={classes.ReportDropdownHeader} size='small' onClick={handleClick}>
        <Typography className={classes.ReportTitleheader} > {translateToLocalistaion(translateWords, "Reports")}</Typography>
        <ExpandMoreIcon fontSize='small' />
      </IconButton>
      <Popover
        open={reportIconState.isOpen}
        anchorEl={reportIconPopoverModel.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left'
        }}
      >
        {/* {buildReportPopoverComponent(reportIconPopoverModel)} */}
        <ReportPopoverPage key = {'report-icon-popover'} reportIconPopoverModel={reportIconPopoverModel} translations={props?.translations} />
      </Popover>
    </>
  );
};

const ReportPopoverPage = (props: ReportIconPopoverModelProps) => {
  const classes = useStyles();
  const reportPopoverPageModel = props.reportIconPopoverModel;
  useEffect(()=>{
    getReportsAndFolderDataForReportIconPopover(reportPopoverPageModel);
    // reportPopoverPageModel.state.setDataLoaded(true);
  },[])
  if (!reportPopoverPageModel.state.isDataLoaded) {
    return (
      <div className={classes.ReportDropdownLoader}>
        <CircularProgress color="secondary" />
      </div>
    );
  } else {
    mappingFolderWithParentFolder(reportPopoverPageModel.folderList, reportPopoverPageModel.folderTiles);
    mappingReportsWithParentFolder(
      reportPopoverPageModel.reportList,
      reportPopoverPageModel.folderTiles,
      reportPopoverPageModel.reportTiles
    );
    reportPopoverPageModel.currentTiles = Object.assign({}, reportPopoverPageModel?.reportTiles)
    flatteningFoldersValueToArrayReportIcon(reportPopoverPageModel);
    return (
      <div className={classes.ReportDropdownContent}>
        <PopoverHeader key={'popover-header'} reportIconPopoverModel={reportPopoverPageModel} translations={props.translations} />
        <PopoverBody key={'popover-body'} reportIconPopoverModel={reportPopoverPageModel} translations={props.translations} />
      </div>
    );
  }
};

const PopoverHeader = (props: ReportIconPopoverModelProps) => {
  const classes = useStyles();
  const handleOnSearchReport = (event: any) => {
    event.preventDefault();
    const currentReportTiles: any = {};
    for (const [key, values] of Object.entries(props.reportIconPopoverModel.reportTiles)) {
      const eachFolderReportConfigs: any[] = [];
      Array.isArray(values) &&
        values.forEach((reportConfigSummary: any) => {
          if (reportConfigSummary['name'].toLowerCase().search(event.target.value.toLowerCase()) > -1) {
            eachFolderReportConfigs.push(reportConfigSummary);
          }
        });
      currentReportTiles[key] = eachFolderReportConfigs;
    }
    props.reportIconPopoverModel.currentTiles = currentReportTiles;
    props.reportIconPopoverModel.state.setDataLoaded(true);
  };

  const handleMoveReportsToFolder = () => {
    handleClose();
    props.reportIconPopoverModel.reportModalModel.title = 'Move Reports To Folder';
    props.reportIconPopoverModel.reportModalModel.maxWidth = 'lg';
    // props.reportIconPopoverModel.reportModalModel.height = ReportModalHeight.medium;
    props.reportIconPopoverModel.reportModalModel.content = buildMoveReportsToFolder(props.reportIconPopoverModel, props?.translations);
    props.reportIconPopoverModel.reportModalModel.defaultCancelButton = false;
    let callbackAddFunction = () => {
      updateFolderDetailsForReport(props.reportIconPopoverModel.listOfReportConfigs); // hitting api
      props.reportIconPopoverModel.listOfReportConfigs = [];
      props.reportIconPopoverModel.reportModalModel.content = buildMoveReportsToFolder(props.reportIconPopoverModel, props?.translations);
      props.reportIconPopoverModel.reportModalModel.state.setOpen(true);
    };
    let addButton: ReportModalCustomButton = new ReportModalCustomButton('ADD', true, callbackAddFunction);
    addButton.buttonClassName = classes.AddButtonPopupModel;
    props.reportIconPopoverModel.reportModalModel.listOfButton.length = 0;
    props.reportIconPopoverModel.reportModalModel.listOfButton.push(addButton);
    props.reportIconPopoverModel.reportModalModel.state.setOpen(true);
  };
  return (
    <div style={{ display: 'flex' }}>
      <TextField fullWidth variant="standard" placeholder="Search Report" onChange={handleOnSearchReport} />
      <AddIcon className={classes.CreateFolderPopup} onClick={handleMoveReportsToFolder} />
    </div>
  );
};

const StyledTreeItem:any = styled((props: TreeItemProps) => <TreeItem {...props} style={{color: '#007ECC'}}/>)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  }
}));

const PopoverBody = (props: ReportIconPopoverModelProps) => {
  const classes = useStyles();
  return (
      <TreeView
        aria-label="customized"
        defaultCollapseIcon={<FolderOpenIcon />}
        defaultExpandIcon={<FolderIcon />}
        defaultEndIcon={<DescriptionIcon />}
        sx={{ height: '60vh', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        className={classes.ListOfReportAndFolder}
      >
        {props.reportIconPopoverModel.state.isDataLoaded && folderAndReport(
          props.reportIconPopoverModel.folderList,
          props.reportIconPopoverModel.currentTiles,
          true,
          props.translations
        )}
      </TreeView>
  );
};

const folderAndReport = (newFolderList: any, reportTiles: any, flag: boolean,translations?:any) => {
  const foldersData: any[] = [];
  newFolderList.forEach((folderConfig: any) => {
    if (folderConfig['strucutureList'].length) {
      foldersData.push(
        <StyledTreeItem key={`reportIconFolder_${folderConfig['id']}`} nodeId={`reportIconFolder_${folderConfig['id']}`} label={folderConfig['name']}>
          {folderAndReport(folderConfig['strucutureList'], reportTiles, false,translations)}
          {returnReportData(reportTiles[folderConfig['id']] ?? [],translations)}
        </StyledTreeItem>
      );
    } else {
      foldersData.push(
        <StyledTreeItem
          key={`reportIconFolder_${folderConfig['id']}`}
          style={{ color: '#6ebe44' }}
          nodeId={`reportIconFolder_${folderConfig['id']}`}
          label={translateToLocalistaion(translations,folderConfig['name'])}
        >
          <>{returnReportData(reportTiles[folderConfig['id']] ?? [], translations)}</>
        </StyledTreeItem>
      );
    }
  });
  if (flag) foldersData.push(...returnReportData(reportTiles[ParentId.id] ?? [], translations));
  return foldersData;
};

const returnReportData = (listOfReport: any, translations?: any) => {
  return listOfReport?.map((reportConfigSummary: any) => {
    return (
      <StyledTreeItem
        key={`reportIconReports_${reportConfigSummary['id']}`}
        nodeId={`reportIconReports_${reportConfigSummary['id']}`}
        label={translateToLocalistaion(translations, reportConfigSummary['name'])}
        className='report-list'
        onClick={() => {
          handleClose();
          handleReportTileClick(reportConfigSummary);
        }}
      />
    );
  }) ?? [];
};

const buildMoveReportsToFolder = (reportIconPopoverModel: ReportIconPopoverModel, translations?: any) => {
  return <MoveReportsToFolder key={'moving-report-to-folder'} reportIconPopoverModel={reportIconPopoverModel} translations={translations} />;
};

const MoveReportsToFolder = (props: ReportIconPopoverModelProps) => {
  const reportIconPopoverModel = props.reportIconPopoverModel;
  const classes = useStyles();
  const [render, setRenderer] = useState(Math.random());
  reportIconPopoverModel.setRenderer = setRenderer;

  return (
    <div className={classes.root}>
      <div className="createFolderComponent">
        <CreateFolderComponent reportIconPopoverModel={reportIconPopoverModel} translations={props.translations} />
      </div>

      {render && CommonUtils.isNotEmpty(reportIconPopoverModel.setOfFolderConfig) && <div className='MoveReportToFolderTable' key={Math.random()}>
        <table>
          <thead>
            <tr>
              <td>{translateToLocalistaion(props?.translations, 'Folder')}</td>
              <td>{translateToLocalistaion(props?.translations, 'Reports')}</td>
              <td>{translateToLocalistaion(props?.translations, 'Existing Reports')}</td>
            </tr>
          </thead>
          <tbody>
            {reportIconPopoverModel.setOfFolderConfig.map((folderConfig: any) => {
              let eachReportIconPopoverModel = Object.assign({}, reportIconPopoverModel);
              eachReportIconPopoverModel.folderConfig = folderConfig;
              return (
                <EachRowOfMoveReportsToFolder
                  key={`each-row-${Math.random()}`}
                  reportIconPopoverModel={eachReportIconPopoverModel}
                />
              );
            })}
          </tbody>
        </table>
      </div>}
    </div>
  );
};

const CreateFolderComponent = (props: ReportIconPopoverModelProps) => {
  const reportIconPopoverModel = props.reportIconPopoverModel;
  let translateWords = props?.translations;
  const [createFolderFlag, setCreateFolderFlag] = React.useState(true);
  let parentFolder: any = null;
  let folderName: string = '';
  return createFolderFlag ? (
    <>
      <Button
        className='create-folder-before'
        variant="contained"
        onClick={() => {
          setCreateFolderFlag(false);
        }}
      >
        {translateToLocalistaion(translateWords, 'Create Folder')}
      </Button>
    </>
  ) : (
    <div className='create-folder-after'>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={reportIconPopoverModel.setOfFolderConfig}
        getOptionLabel={(option) => option['name'] ?? ''}
        sx={{ width: 300 }}
        onChange={(event: any, child: any) => {
          parentFolder = child;
        }}
        renderInput={(params) => <TextField {...params} label="Parent Folder" size="small" />}
      />
      <TextField
        id="standard-basic"
        label="Folder Name"
        variant="outlined"
        size="small"
        sx={{ width: 300 }}
        onChange={(event: any) => {
          folderName = event.target.value;
        }}
      />
      <Button
        className='createBtn'
        variant="contained"
        onClick={() => {
          if (CommonUtils.isNotEmpty(folderName)) {
            if (CommonUtils.isNotEmpty(parentFolder)) {
              createFolder({ parentId: parentFolder.id, name: folderName });
            } else {
              createFolder({ parentId: null, name: folderName });
            }
          }
        }}
      > 
        {translateToLocalistaion(translateWords, 'Create')}
      </Button>
    </div>
  );
};

const EachRowOfMoveReportsToFolder = (props: ReportIconPopoverModelProps) => {
  const [selectedReports, setSelectedReports] = React.useState(
    props.reportIconPopoverModel.reportTiles[props.reportIconPopoverModel.folderConfig['id']] ?? []
  );
  const [value, setValue] = React.useState(null);

  const handleOnAdd = () => {
    let flag: boolean = false;
    if (value !== null) {
      setSelectedReports((prev: any) => {
        if (Array.isArray(prev)) {
          for (let reportConfigSummary of prev) {
            if (JSON.stringify(reportConfigSummary) == JSON.stringify(value)) {
              flag = true;
              break;
            }
          }
        }
        if (flag) {
          return [...prev];
        } else {
          props.reportIconPopoverModel.listOfReportConfigs.push({
            folderId: props.reportIconPopoverModel.folderConfig['id'],
            folderName: props.reportIconPopoverModel.folderConfig['name'],
            opCode: '+',
            reportId: value['id']
          });
          let customButtonModel = props.reportIconPopoverModel.reportModalModel.listOfButton.find(
            (customButton: any) => {
              return customButton['buttonName'] === 'ADD';
            }
          );
          customButtonModel?.state?.setDisabledButton(false);
          return [...prev, value];
        }
      });
      setValue(null);
    }
  };

  const handleOnDelete = (drcs: any) => {
    setSelectedReports((prev:any)=>prev.filter((rcs:any)=>rcs.id!=drcs.id));
  };

  return (
    <tr key={`moveReports_${uniqueId()}`}>
      <td className='folders'>{props.reportIconPopoverModel.folderConfig['name']}</td>
      <td className='reports'>
        <div className='container'>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            options={props.reportIconPopoverModel.reportList}
            getOptionLabel={(option) => option['name']??''}
            sx={{ width: 250 }}
            value={value}
            onChange={(event: any, child: any) => {
              setValue(child);
            }}
            renderInput={(params: any) => <TextField {...params} label="" />}
          />
          <AddIcon sx={{color: '#565656'}} onClick={handleOnAdd} />
        </div>
      </td>
      <td className='existingReports'>
        {selectedReports.map((rcs: any) => {
          return (
            <Chip
              deleteIcon={<CloseIcon />}
              size="small"
              sx={{ borderRadius: '1px', border: '1px solid #6ebe44', marginLeft: '10px' }}
              variant={'outlined'}
              key={rcs['id']}
              label={rcs['name']}
              onDelete={()=>handleOnDelete(rcs)}
            />
          );
        })}
      </td>
    </tr>
  );
};
