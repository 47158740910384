import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { showLoader } from '../../../../../../../../../../components/loader/controller/KagamiLoaderController';
import { GroupHeader } from '../builder/GroupBuilder';
import { GroupModelProps, KagamiGroupModel, useKagamiGroupState } from '../model/KagamiGroupModel';
import { KagamiGroupStyle } from '../style/KagamiGroupStyle';

export const buildGroupComponent = (groupModel: KagamiGroupModel) => {
  return <KagamiGroup groupModel={groupModel} />;
};

let useStyles = makeStyles(KagamiGroupStyle);

const KagamiGroup = (props: GroupModelProps) => {
  let classes = useStyles();
  let groupState = useKagamiGroupState(createState(props.groupModel));
  props.groupModel.state = groupState;

  return (
    <div className={classes.mainRoot}>
      <GroupHeader groupModel={props.groupModel} />
      {props.groupModel.state.groupContent == null ? showLoader() : props.groupModel.groupContent}
    </div>
  );
};
