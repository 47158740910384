import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiHeaderStyle = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      display: 'flex',
      height: '50px',
      padding: 0,
      marginBottom: '4px',
      position: 'relative'
    },
    backgroundPaper: {
      backgroundColor: theme.palette.common.white,

    },
    icons_on_headers: {
      display: 'flex',

    },
    poppers: {
      position: 'fixed',
      minHeight: '40px',
      width: '200px',
      right: '10px',
      top: '55px',
      left: 'unset',
      bottom: '0px'
    },

    hamburger_icon: {
      height: theme.spacing(3.5),
      width: theme.spacing(3.5),
      backgroundSize: theme.spacing(3.5)

    },
    kagami_image: {
      float: 'left',
      padding: '3px 0px',
      marginLeft: '-5px',
      height: '45px',
      overflow: 'hidden',
      cursor:'pointer'

    },
    userImage: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: theme.spacing(6.2),
      marginRight: 0,
      marginTop: theme.spacing(-0.25),
      padding: theme.spacing(0.25),
      border: '1px solid #2196f3 !important'

    },
    notificationImage: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: 0,
      marginTop: theme.spacing(-0.25),
      padding: theme.spacing(0.25),
    },
    notification_userprofile_grid: {
      marginRight: 0,
      marginLeft: 'auto'
    },
    
  });
