import cloneDeep from 'lodash/cloneDeep';
const PDFLedgerDownload = (configInfo: any) => {
    let initConfig: any = {
      pageOrientation: 'landscape',
      pageSize: 'A4',
      
      styles: {
        tableStyle: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: 'black'
        }
      },
      defaultStyle: {
        fontSize: 10
      }
    };
    // let config = _.assignIn(initConfig, configInfo);
    const downLoad = () => {
        initDownload();
        let pdfObj = makePdfObj();
        import('pdfmake/build/pdfmake').then(({default : pdfMake}) => {
          import('pdfmake/build/vfs_fonts').then(({default : pdfFonts}) => {
            (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
            pdfMake.createPdf(pdfObj).print();
          })
        })
    }
    const initDownload = () =>{
        initConfig['pageOrientation'] = configInfo.pageOrientation || 'landscape';
    }
    const makePdfObj = ()=> {
        let userConfig = cloneDeep(initConfig);
        userConfig['content'] = getPdfContent();
        return userConfig;
    }
    const getLedgerHeader = ()=> {
        let pdfHeaderInfo = configInfo.pdfHeaderInfo || {};
        return [  {
            text:  pdfHeaderInfo['subOrganization'] || '',
            alignment: 'center',
            fontSize: 14
          },
          {
            text: '',
            alignment: 'center',
            fontSize: 9
          },
          {
            text: pdfHeaderInfo['selectedValue'] || '',
            alignment: 'center',
            fontSize: 13,
            margin: [0, 5, 0, 0]
          },
          {
            text: pdfHeaderInfo['title'] || 'Ledger Account',
            alignment: 'center',
            fontSize: 10
          },
          {
            text: 'Date : '+ (pdfHeaderInfo['exportedDate'] || ''),
            alignment: 'right',
            fontSize: 9
          },
          {
            text:  pdfHeaderInfo['date'] || '',
            fontSize: 9,
            alignment: 'center'
          },
        ]
    }
    const getPdfContent = ()=> {
      let tableBody:any = configInfo.pdfTableRows || [];
      tableBody.unshift(configInfo.pdfTableColumns); 
        let content =  [
          getLedgerHeader() || [],
             [ {
                style: 'tableStyle',
                table: {
                  "headerRows": 1,
                  "widths":  configInfo.pdfTableColumnWidths || [],
                  body: tableBody
                },
                "layout": {
                    hLineColor: function (i:any, node:any) {
                        return (i === 0 || i == 1) ? 'black' : 'white';
                    },
                    vLineColor: function (i:any, node:any) {
                        return 'white';
                    },
                    hLineStyle: function (i:any, node:any) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return { dash: { length: 10, space: 4 } };
                    },
                    vLineStyle: function (i:any, node:any) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return { dash: { length: 4 } };
                    },
                }
              }]
          ];
          return content;
    }

    return {
        downLoad
    }
};
export default PDFLedgerDownload;



