import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { KagamiDashboardModel } from '../builder/dashboard/model/KagamiDashboardModel';

export class KagamiBodyModel {
  processName: string | null;
  dashboardModel: KagamiDashboardModel;
  bodyContent: JSX.Element | null;
  isGlobalMenuSearchActive : boolean;

  constructor() {
    this.processName = null;
    this.dashboardModel = new KagamiDashboardModel();
    this.bodyContent = null;
    this.isGlobalMenuSearchActive = false;
  }
}

export interface BodyModelProps {
  kagamiBodyModel: KagamiBodyModel;
}
export const wrapBodyState = (state: State<KagamiBodyModel>) => {
  const bodyState = state;

  return {
    get bodyContent() {
      return bodyState.bodyContent.get();
    },

    loadBodyContent(bodyContent: JSX.Element) {
      bodyState.bodyContent.set(bodyContent);
    },
    get isGlobalMenuSearchLoaded() {
      return bodyState.isGlobalMenuSearchActive.get();
    },
    setGlobalMenuSearchLoaded(isGlobalMenuSearchactive: boolean) {
      bodyState.isGlobalMenuSearchActive.set(isGlobalMenuSearchactive);
    },
  };
};

export const useBodyState = (state: State<KagamiBodyModel>) => {
  const bodyState = wrapBodyState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.BODY_STATE, bodyState);
  return bodyState;
};
