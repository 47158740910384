import { createState } from '@hookstate/core';
import { CircularProgress, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import _ from 'lodash';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { TileViewReportModel, TileViewReportModelProps, useTileViewReportModelState } from '../model/TileViewReportModel';
import { makeTile } from '../service/TileViewReportService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '85vh',
      width: '100%'
    },
    main: {
      margin: '4px'
    },
    each_tile: {
      borderRadius: '5px',
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      textAlign: 'center',
      marginBottom: '10px',
      color: '#fff',
      padding: '15px',
      boxShadow: '0 0 2px #55bf1e',
        '& .tile_header': {
          fontWeight: '600',
          fontSize: '14px',
          margin: '5px 0',
          color: '#666',
          wordBreak: 'break-all',
          textAlign: 'center',
          cursor:'pointer',
          textDecorationLine:'underline'
        },
        '& .tile_data': {
          fontSize: '20px',
          marginBottom: '0',
          fontWeight: '400',
          color: '#0066b3',
          wordBreak: 'break-all',
          lineHeight: 'initial',
          paddingTop: '8px',
          textAlign: 'center'
        }
    }
  })
);

export const BuildTileViewReport = (tileViewReportModel: TileViewReportModel) => {
  return <KagamiTileViewReport key={'tile-view'} tileViewReportModel={tileViewReportModel}></KagamiTileViewReport>;
};

const KagamiTileViewReport = (props: TileViewReportModelProps) => {
  let classes = useStyles();
  let tileViewReportModel = props.tileViewReportModel;
  let tileViewChartState = useTileViewReportModelState(createState(tileViewReportModel));
  tileViewReportModel.state = tileViewChartState;

  React.useEffect(() => {
    getReportDataForDynamicReports(tileViewReportModel);
  }, []);

  if (!tileViewChartState.isDataLoaded) {
    return <CircularProgress sx={{ marginTop: '4%', marginLeft: '48%' }} color="secondary" />;
  } else {
    if(tileViewReportModel.reportData){
    const tileViewInfo: any = makeTile(tileViewReportModel);
    const tiles: any[] = [];

    _.forEach(_.keys(tileViewInfo), (tileInfoKey: any) => {
      tiles.push(
        <Grid  item xs={6}>
          <div className={classes.each_tile}>
            {
              _.keys(tileViewInfo[tileInfoKey]).map((key:any,index:number)=>{
                  if(index===0){
                    return <p className='tile_header'>{tileViewInfo[tileInfoKey][key]}</p>
                  }else{
                    return <p className='tile_data'>{tileViewInfo[tileInfoKey][key]}</p>
                  }
              })
            }
          </div>
        </Grid>
      );
    });
    
    return (
      <>
        <Grid className='custom-dashboard-tile-report' container spacing={2} >
          {tiles}
        </Grid>
      </>
    );
  }else{
    return <p className='NoDataFound'>No Data Found</p>
  }
  }
};

      // tileViewInfo.forEach((tileInfo:any)=>{
      //   tiles.push(
      //     <Grid  item xs={6}>
      //       <div className={classes.each_tile}>
      //         {
      //           // _.keys(tileInfo).map((key: any,index:number) => {
      //           //   if(index===0){
      //           //     return <p className='tile_header'>{tileInfo[key]}</p>
      //           //   }else{
      //           //     return <p className='tile_data'>{tileInfo[key]}</p>
      //           //   }
      //           // })
      //         }
      //       </div>
      //     </Grid>
      //   );
      // })