import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiHeaderStyle = (theme: Theme) =>
  createStyles({
    'root': {
      backgroundColor: '#f5f8fa',
      position: 'static',
      display: 'block',
      padding: 0,
      marginBottom: '0px'
      // [theme.breakpoints.down('sm')]: {
      //   backgroundColor: theme.palette.background.paper,
      //   position: 'static',
      //   display:'block',
      //   padding:0,

      //   marginBottom:'0px',
      // boxShadow:' 0 8px 6px -6px black',

      // },
      // [theme.breakpoints.up('sm')]: {
      //   backgroundColor: theme.palette.background.paper,
      //   position: 'static',
      //   display:'block',
      //   padding:0,

      //   marginBottom:'0px',
      // boxShadow:' 0 8px 6px -6px black',

      // },
      // [theme.breakpoints.up('md')]: {
      //   backgroundColor: theme.palette.background.paper,
      //   position: 'static',
      //   display:'block',
      //   padding:0,

      //   marginBottom:'0px',
      // boxShadow:' 0 8px 6px -6px black',

      // },
      // [theme.breakpoints.up('lg')]: {
      //   backgroundColor: theme.palette.background.paper,
      //   position: 'static',
      //   display:'block',
      //   padding:0,

      //   marginBottom:'0px',
      // },
      // [theme.breakpoints.only('xl')]: {
      //   backgroundColor: theme.palette.background.paper,
      //   position: 'static',
      //   display:'block',
      //   padding:0,
      //   marginBottom:'0px',

      // }
    },
    'backgroundPaper': {
      backgroundColor: theme.palette.common.white
    },
    'toolbar_navigation': {
      'justifyContent': 'space-between',
      '&.MuiToolbar-regular': {
        padding: 0,
        minHeight: theme.spacing(5),
        height: theme.spacing(5)
      }
    },
    'logoutIcon': {
      color: theme.palette.primary.main
    },
    'closeImpersonateIcon': {
      color: theme.palette.primary.main,
      height: '20px',
      width: '20px'
    },
    'toggleIcon': {
      color: theme.palette.primary.main,
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block'
      }
    },
    'headerRight': {
      '& .MuiButtonBase-root': {
        'padding': theme.spacing(0.625),
        'background': theme.palette.background.default,
        // background: theme.palette.background.defaultBlue,

        'marginRight': theme.spacing(1),
        '&:last-child': {
          padding: theme.spacing(1)
        }
      }
    },
    'collapseIcon': {
      '& .MuiButtonBase-root': {
        padding: theme.spacing(0.625),
        // background: theme.palette.background.default,
        background: theme.palette.background.defaultBlue,

        marginRight: theme.spacing(1)
      }
    },
    'icons_on_headers': {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      'padding': '0 9px',
      '& .MuiButtonBase-root ': {
        // padding: `5px  ${theme.spacing(1)}`,
        // marginLeft: theme.spacing(0.5)
      },
      '& .MuiCardMedia-root ': {
        width: 'auto'
      }
    },
    'hamburger_icon': {
      height: theme.spacing(3.5),
      width: theme.spacing(3.5)
    },
    'kagami_image': {
      height: theme.spacing(5)
    },
    'userImage': {
      'width': theme.spacing(3.25),
      'height': theme.spacing(3.25),
      'borderRadius': theme.spacing(2),
      'marginRight': 0,
      'padding': '4px',
      '& .MuiAvatar-img': {
        'width': '100%',
        '&:hover': {
          animation: '$shake 1s',
          transform: 'translate3d(0, 0, 0)'
        }
      }

      // aboutusImage: {
      //   width: theme.spacing(3.25),
      //   height: theme.spacing(3.25),
      //   borderRadius: theme.spacing(2),
      //   marginRight: 0,
      //   padding: '4px',
      //   '& .MuiAvatar-img':{
      //     width: '100%',
      //     '&:hover': {
      //       animation: '$shake 1s',
      //       transform: 'translate3d(0, 0, 0)',
      //     },
      //   }
      // },
    },
    'userImage2': {
      marginRight: 0,
      border: '1px solid',
      borderRadius: '50%',
      borderColor: theme.palette.primary.main,
      objectFit: 'fill'
    },
    'notificationImage': {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: 0,
      marginTop: theme.spacing(-0.25),
      padding: theme.spacing(0.25)
    },
    'userprofile_popover': {
      position: 'fixed',
      width: theme.spacing(37.5),
      right: theme.spacing(1.2),
      top: theme.spacing(0.8),
      bootom: 0
    },
    'notification_userprofile_grid': {
      margin: 0,
      boxSizing: 'border-box',
      marginRight: 'auto'
    },
    'popup_position': {
      right: '10px !important',
      top: '50px !important',
      left: 'unset !important',
      width: '300px',
      zIndex: 2
    },
    'globalSearch': {
      'marginLeft': theme.spacing(2),
      '& .MuiOutlinedInput-root': {
        'background': theme.palette.common.white,
        'fontSize': `${theme.typography.caption.fontSize} !important`,
        '& .MuiOutlinedInput-input': {
          height: '1em'
        },
        '& .MuiSelect-select ': {
          lineHeight: '1em',
          // height: '1em',
          minHeight: '0.5em'
        }
      }
    },
    'kgmGridSearchOptions': {
      '& li': {
        fontSize: `${theme.typography.caption.fontSize} !important`
      }
    },
    '@keyframes shake': {
      '10%, 90%': {
        transform: 'translate3d(-1px, 0.2, 0.5px) rotate(-2deg)'
      },
      '20%, 80%': {
        transform: 'translate3d(2px, 0, 0.5px) rotate(2deg)'
      },
      '30%, 50%, 70%': {
        transform: 'translate3d(-2px, 0, 0.5px) rotate(-2deg)'
      },
      '40%, 60%': {
        transform: 'translate3d(1px, -0.2px, 0px) rotate(2deg)'
      },
      '100%': {
        transform: 'translate3d(0)'
      }
    }
  });
