import { State, useState } from "@hookstate/core";
import { KagamiStateManager } from "../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../constants/SystemConstants";
import { KagamiListModel } from "../../../model/KagamiListModel";

export class KagamiHierarchyViewModel extends KagamiListModel{
  node:any;
  parent:any;
  treeData : any;
  isTreeDataLoaded : boolean;
  isDataLoading:boolean;
  hierarchyData: any;
  topHierarchy: any;
  hierarchyKey: string;
  constructor(processName: string, processStepName: string, mainEntityId: string, presentation: any) {
    super(processName, processStepName, null, mainEntityId);
    this.state = null;
    this.presentation = presentation;
    this.treeData = [];
    this.hierarchyData = [];
    this.isTreeDataLoaded = false;
    this.isDataLoading=false;
    this.topHierarchy = {};
    this.hierarchyKey = ''
  }
} 

export interface KagamiHierarchyViewProps {
  kagamiHierarchyViewModel: KagamiHierarchyViewModel;
}

export const wrapKagamiHierarchyViewModelState = (state: State<KagamiHierarchyViewModel>) => {
  let hierarchyState = state;

  return {
    getHierarchyKey() {
      return hierarchyState.hierarchyKey.get();
    },

    setHierarchyKey(value: string) {
      hierarchyState.hierarchyKey.set(value);
    },

    getListLoaded() {
      return hierarchyState.isListLoaded.get();
    },

    setListLoaded(value: boolean) {
      hierarchyState.isListLoaded.set(value);
    },

    getTreeDataLoaded() {
      return hierarchyState.isTreeDataLoaded.get();
    },

    setTreeDataLoaded(value: boolean) {
      hierarchyState.isTreeDataLoaded.set(value);
    },

    getIsDataLoading() {
      return hierarchyState.isDataLoading.get();
    },
    
    setIsDataLoading(value: boolean) {
      hierarchyState.isDataLoading.set(value);
    },
  };
};

export const useHierarchyViewState = (state: State<KagamiHierarchyViewModel>) => {
const hierarchyViewState = wrapKagamiHierarchyViewModelState(useState(state));
KagamiStateManager.addStateToHolder(SystemConstants.HIERARCHY_VIEW_STATE, hierarchyViewState);
return hierarchyViewState
}


