export class KagamiProcessModel {
  processName: string;
  fromDashboard: boolean;
  processExecuting: boolean = false;
  data: any;
  submitCall: boolean;
  onError: Function;
  templateType: string;
  processStepName: string;

  constructor(processName: string, fromDashboard: boolean, processStepName: string, onError: Function) {
    this.processName = processName;
    this.fromDashboard = fromDashboard;
    this.processExecuting = false;
    this.submitCall = false;
    this.onError = onError;
    this.templateType = '';
    this.processStepName = processStepName;
    this.data = new Map();
  }
}
