import React from 'react';
import { getFavoritesPanel, getLeftPanel } from '../service/NavigatorService';
import { Menu, MenuItem } from 'react-pro-sidebar';

import dashboard from '../../../../../../assets/images/navbaricons/dashboardIcon.svg';
import reports from '../../../../../../assets/images/navbaricons/reportsIcon.svg';
import masterdata from '../../../../../../assets/images/navbaricons/masterData.svg';
import { showBody } from '../../../controller/KagamiHomeController';
import { renderReportPage } from '../../body/controller/KagamiBodyController';
import { buildMenuFolder } from './Menu/controller/KagamiMenuFolderController';

import { KagamiFolderModel } from './Menu/model/KagamiFolderModel';
import { getUserProfileData } from '../../header/controller/KagamiPageHeaderController';
import { CustomImageComponent } from './Menu/ui/KagamiMenuFolder';
import { translateToLocalistaion } from '../../body/builder/process/service/PresentationService';
import { Dashboard } from '@mui/icons-material';

export const buildNavigatorSection = (navigationData: any, MasterDatapanel: any) => {
  const components: any[] = [];
  let dashboard = <DashboardMenu key={'way-to-dashboard'} navigationData={navigationData} />;
  let report = <ReportMenu key={'way-to-reports'} navigationData={navigationData} />;
  // let dataMigration = <DataMigrationMenu key={'way-to-data_migration'}/>
  let mainMenus: KagamiFolderModel[] = getLeftPanel(navigationData);
  let masterData: KagamiFolderModel[] = [MasterDataMenu(MasterDatapanel)];
  let favourites = getFavoritesPanel(navigationData);
  let individualMenuFolders = (
    <BuildMenuFolder
      key="way-to-mainmenus"
      mainmenus={mainMenus}
      masterdata={masterData}
      favouriteData={favourites}
      navigationData={navigationData}
    />
  );

  console.log('navvabar re rendererd');
  components.push(dashboard);
  components.push(individualMenuFolders);
  // components.push(dataMigration)
  components.push(report);

  return components;
};

const BuildMenuFolder = React.memo((props: any) => {
  const { mainmenus, masterdata, favouriteData, navigationData } = props;
  let consolidatedMenuItems: any;
  const currentUser = getUserProfileData();
  if (currentUser && currentUser.userId != 'admin') {
    consolidatedMenuItems = mainmenus;
  } else {
    consolidatedMenuItems = [...mainmenus, ...masterdata];
  }
  return buildMenuFolder(consolidatedMenuItems, favouriteData, navigationData);
});
const MasterDataMenu = (MasterDatapanel: any) => {
  // const MasterDatapanel: any[] = getMasterDataPanel(navigationModel.navigationData);
  const masterDatamenu = new KagamiFolderModel(
    true,
    false,
    masterdata,
    'Master Data',
    MasterDatapanel,
    'group',
    undefined
  );
  return masterDatamenu;
};

const DashboardMenu = (navigationData: any) => {
  let translations = navigationData?.navigationData?.otherProperties?.translation;
  return (
    <Menu>
      <MenuItem icon={<CustomImageComponent image={dashboard} title={translateToLocalistaion(translations, 'Dashboard')} />} onClick={showBody}>
        {translateToLocalistaion(translations, 'Dashboard')}
      </MenuItem>
    </Menu>
  );
};

// const DashboardMenu = (navigationData: any) => (
//   <Menu>
//     <MenuItem icon={<CustomImageComponent image={dashboard} title={'Dashboard'} />} onClick={showBody}>
//       Dashboard
//     </MenuItem>
//   </Menu>
// );

const ReportMenu = (navigationData: any) => {
  let translations = navigationData?.navigationData?.otherProperties?.translation;
  return (
    <Menu>
      <MenuItem icon={<CustomImageComponent image={reports} title={translateToLocalistaion(translations, 'Reports')} />} onClick={renderReportPage}>
        {translateToLocalistaion(translations, 'Reports')}
      </MenuItem>
    </Menu>
  );
};

// const ReportMenu = (navigationData: any) => (
//   <Menu>
//     <MenuItem icon={<CustomImageComponent image={reports} title={'Reports'} />} onClick={renderReportPage}>
//       Reports
//     </MenuItem>
//   </Menu>
// );

// const DataMigrationMenu = () =><Menu><MenuItem icon ={<CustomImageComponent image={dataMigration}  title={'dataMigration' }/>} onClick={renderDataMigrationPage}>Data-Migration</MenuItem></Menu>
