import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class PieReportModel extends KagamiReportModel {
  reportData: any;
  state: any;
  isDataLoaded:boolean;
  contentReport:any;
  isContentReportLoaded:boolean;
  additionalReport:any;
  additionalReportContent:any;
  isAdditionalReportLoaded:boolean;
  constructor(reportContent: any) {
    super()
    this.reportData = reportContent;
    this.isDataLoaded = false;
    this.isContentReportLoaded = false;
    this.isAdditionalReportLoaded = false;
  }
}
export interface PieReportModelProps {
  pieReportModel: PieReportModel;
}
export const usePieReportModelState = (state: State<PieReportModel>) => {
  let pieReportState = useState(state);
  return {
    reportConfig() {
      pieReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      pieReportState.reportData.set(reportContent);
    },

    get isContentReportLoaded(){
      return pieReportState.isContentReportLoaded.get()
    },
    setContentReport(isContentReportLoaded:boolean){
      pieReportState.isContentReportLoaded.set(isContentReportLoaded);
    },
     get isAdditionalReportLoaded(){
      return pieReportState.isAdditionalReportLoaded.get()
    },
    setAdditionalReportContent(isAdditionalReportLoaded:boolean){
      pieReportState.isAdditionalReportLoaded.set(isAdditionalReportLoaded);
    },

    get isDataLoaded(){
      return pieReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      pieReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
