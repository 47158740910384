import { KagamiHomeController } from '../../pages/static/home/controller/KagamiHomeController';
import { KagamiHomeModel } from '../../pages/static/home/model/KagamiHomeModel'; 

export const RenderLogin = () => {

};

export const RenderPage = (kagamiPageModel: KagamiHomeModel) => {
  return KagamiHomeController.buildHome(kagamiPageModel);
};
