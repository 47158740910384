import { createState } from "@hookstate/core";
import { ListHeaderWithTotal} from "../../../../ProcessPageBuildler";
import { getKagamiListModel } from "../../../controller/KagamiListController";
import { kagamiListHeaderModel, kagamiListHeaderModelProps, useListHeaderState } from "../model/KagamiListHeaderModel";

export function buildListHeaderComponent(kagamiListHeaderModel: kagamiListHeaderModel) {
    return <KagamiListHeader key = 'List Header' kagamiListHeaderModel = {kagamiListHeaderModel}/>
}

const KagamiListHeader = (props: kagamiListHeaderModelProps) => {
    props.kagamiListHeaderModel.state = useListHeaderState(createState(props.kagamiListHeaderModel))
    return (
       <div  className = 'listHeader_name' >
        {props.kagamiListHeaderModel.state.headerVisible && <ListHeaderWithTotal kagamiListHeaderModel={props.kagamiListHeaderModel} />}  
       </div>
    )
}

