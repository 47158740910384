export class KagamiProcessResponseModel {
  processName: string;
  templateType: string;
  onStartData: any;
  onErrorData: any;
  processStepName: string;
  detailedObjects: any;
  onLoadCounter: any;
  isModelDialog: boolean;
  activeTemplateModel: any;
  isGroupTemplateNetworkCall : boolean;
  isPickItemCall : boolean;
  isModalResponse:boolean;
  onKanbannClose : any;
  constructor() {
    this.processName = '';
    this.templateType = '';
    this.onStartData = new Map();
    this.onErrorData = new Map();
    this.processStepName = '';
    this.detailedObjects = {};
    this.onLoadCounter = 0;
    this.isModelDialog = false;
    this.activeTemplateModel = null;
    this.isGroupTemplateNetworkCall = false;
    this.isPickItemCall = false;
    this.isModalResponse = false;
  }
}

export interface KagamiProcessResponseModelProps {
  dynamicPageModel: KagamiProcessResponseModel;
}
