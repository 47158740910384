import { KagamiListFilterModel } from '../../../model/KagamiListFilterModel';
import { KagamiPresentationFilterModel } from '../model/KagamiPresentationFilterModel';
import { presentationFilter } from "../ui/KagamiPresentationFilter";

export function buildpresentationfilter(kagamiPresentationFilterModel:KagamiPresentationFilterModel, kagamiListFilterModel :KagamiListFilterModel){
   return  presentationFilter(kagamiPresentationFilterModel, kagamiListFilterModel);
}



// export function showClearFilterButton( kagamiListFilterModel :KagamiListFilterModel){
//    kagamiListFilterModel.state.SetIsListFiltersApplied(true)
// }