import { createState } from "@hookstate/core";
import { getActionButtons } from "../builder/EmbeddedTriggersBuilder";
import { EmbedTriggerModelProps, KagamiEmbedTriggerModel } from "../model/KagamiEmbedTriggerModel";
import {useKagamiEmbedTriggerState} from "../model/KagamiEmbedTriggerModel"


export function buildEmbeddedTrigger(embeddedTriggerModel: KagamiEmbedTriggerModel) {
  return <KagamiEmbeddedTrigger key={"trigger"+embeddedTriggerModel.embeddedFormPresentationModel.formEntityId+'_'+embeddedTriggerModel.triggerName} embeddedTriggerModel={embeddedTriggerModel} />;
}

export const KagamiEmbeddedTrigger = (props: EmbedTriggerModelProps) => {
  let triggerIdentifier = props.embeddedTriggerModel.embeddedFormPresentationModel.formEntityId+'_'+props.embeddedTriggerModel.triggerName
  let embedTriggerState = useKagamiEmbedTriggerState(createState(props.embeddedTriggerModel),triggerIdentifier)
  props.embeddedTriggerModel.state = embedTriggerState
  return (
    <>
      <>{getActionButtons(props.embeddedTriggerModel)}</>
      {/* <div>{props.embeddedTriggerModel.embeddedModal}</div> */}
      {/* { props.embeddedTriggerModel.embeddedModal ? <div>{buildEmbeddedModal(props.embeddedTriggerModel.embeddedModal)}</div> : null} */}
    </>
  );
};
