import { State, useState } from '@hookstate/core';

export class KagamiHeaderNotificationModel {
  open: boolean;
  constructor(title: any) {
    this.open = false;
  }
}

export const wrapKagamiHeaderNotificationModelState = (state: State<KagamiHeaderNotificationModel>) => {
  let notificationState = state;

  return {
    get isOpen() {
      return notificationState.open.get();
    },

    setOpen(isOpen: boolean) {
      notificationState.open.set(isOpen);
    },

    get content() {
      return null;
    },

    setContent(content: any) {},
    get selectTab() {
      return null;
    },

    setselectTab(selectTabName: any) {}
  };
};
export const useKagamiHeaderNotificationModelState = (state: State<KagamiHeaderNotificationModel>) =>
  wrapKagamiHeaderNotificationModelState(useState(state));
