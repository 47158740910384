 
import { KagamiSelectModel, SelectFieldProps, useKagamiSelectModelState } from '../model/kagamiSelectModel';  
import { getFormModel } from '../../../form/controller/KagamiFormController';  
import SearchField from './SearchField';
import MultiselectField from './MultiselectField';
import SelectField from './SelectField';

export const buildSelectBoxComponent = (kagamiSelectModel: KagamiSelectModel) => {
  return <KagamiSelectField key={"KagamiSelectField"+kagamiSelectModel.controlIdentifier} kagamiSelectModel={kagamiSelectModel} />;
};

 
const KagamiSelectField = (props: SelectFieldProps) => {
  let fieldState = useKagamiSelectModelState(props.kagamiSelectModel.createState);
  props.kagamiSelectModel.state = fieldState;
  getFormModel().formControlStates[props.kagamiSelectModel.controlIdentifier] = fieldState;
   
 
  if (fieldState.isVisible === false) {
    return <></>;
  } 

  else {

  
    switch (props.kagamiSelectModel.controlType){


      case 'search': 

      return (
        <>
         <SearchField key={props.kagamiSelectModel.controlIdentifier} kagamiSelectModel={props.kagamiSelectModel} />
         </>
      ) 
      case 'multiselect': 
      return (
        <>
        <MultiselectField key={props.kagamiSelectModel.controlIdentifier} kagamiSelectModel={props.kagamiSelectModel} />
        </>
      )
      case 'select': 
      return (
        <>
        <SelectField key={props.kagamiSelectModel.controlIdentifier} kagamiSelectModel={props.kagamiSelectModel} />
        </>
      )
      default:
        return <div>{props.kagamiSelectModel.controlType}: this type not implemented </div>;
    }
 
  }
  
}