import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ReportTextFilterModel, ReportTextFilterModelProps, useReportTextFilterState } from '../model/TextFilter';
import { createState } from '@hookstate/core';
import { onTextBoxTap } from '../../../controller/KagamiReportFilterController';
import { handlePopupButtonVisibility } from '../../../service/ReportFilterUtilService';
import { setTextFieldData } from '../controller/TextFilterController';
import uniqueId from 'lodash/uniqueId';
import compact from 'lodash/compact';
import { ReportConstant } from '../../../../view/ReportConstant/ReportConstant';
import { ListBox } from '../../../../../process/builder/controls/select/ui/SearchField';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { translateToLocalistaion } from '../../../../../process/service/PresentationService';


export const buildTextFilterComponent = (reportTextFilterModel: ReportTextFilterModel, translationWords?:any) => {
  return <ReportTextFilter key={`text-Filter-${reportTextFilterModel.filterName}`} reportTextFilterModel={reportTextFilterModel} translationWords={translationWords} />;
};

export const ReportTextFilter = (props: ReportTextFilterModelProps) => {
  const reportTextFilterModel = props.reportTextFilterModel;
  let transalte: any = props?.translationWords;
  const textState = useReportTextFilterState(createState(reportTextFilterModel));
  reportTextFilterModel.state = textState;
  let filterRelationName = reportTextFilterModel.filterRelationName;
  let multipleSelect: boolean = false;

  const debounceOnChange = React.useCallback(CommonUtils._debounceFtn(onChange, 700), []);
  function onChange(searchString:string) {
    onTextBoxTap(reportTextFilterModel.filterConfig, reportTextFilterModel, searchString);
  }

  function handleAsyncSearch(){
    reportTextFilterModel.pageNo+=1;
    onTextBoxTap(reportTextFilterModel.filterConfig, reportTextFilterModel, '');
  }

  if (filterRelationName === ReportConstant.In || filterRelationName === ReportConstant.Not_In) {
    multipleSelect = true;
  }

  let oldValue = compact(
    reportTextFilterModel.reportFilterModel.selectedValue
      .get(reportTextFilterModel.filterName)
      ?.split(',') ?? []
  );

  let newValue:any = oldValue.filter((val: any) => {
    return val !== 'NOPOP' && val !== 'undefined';
  });
  if(!multipleSelect){
    newValue = newValue[0]
  }

  let options = [...textState.filterValues];
  // options.sort();

  return (
    <>
      <tr key={`report-text-${reportTextFilterModel.filterName}`}>
        <td style={{ color: '#007ecc' }}>
          {/* {reportTextFilterModel.filterName} */}
          {translateToLocalistaion(transalte, reportTextFilterModel.filterName)}
          {!reportTextFilterModel.filterConfig.optional ? (
            <span style={{ color: 'red', marginLeft: '8px', fontWeight: '200' }}>*</span>
          ) : (
            <></>
          )}
        </td>
        <td style={{ color: '#007ecc' }}>{translateToLocalistaion(transalte, filterRelationName)}</td>
        {/* <td style={{ color: '#007ecc' }}>{filterRelationName}</td> */}
        <td>
          <Autocomplete
            size="small"
            disablePortal={false}
            multiple={multipleSelect}
            id={reportTextFilterModel.filterName}
            options={options}
            sx={{ width: 300 }}
            defaultValue={newValue}
            onOpen={(event: React.SyntheticEvent) => {
              reportTextFilterModel.pageNo = 0;
              onTextBoxTap(reportTextFilterModel.filterConfig, reportTextFilterModel, '');
            }}
            onChange={(event: React.SyntheticEvent, child:any) => {
                setTextFieldData(child, reportTextFilterModel);
                if(!reportTextFilterModel.reportFilterModel.fromDashboard){
                  handlePopupButtonVisibility(reportTextFilterModel);
                }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select or Search"
                onChange={(event:any)=>debounceOnChange(event.target.value)}
              />
            )}
            // ListboxProps={{
            //   onScroll: (event: React.SyntheticEvent) => {
            //     const listboxNode = event.currentTarget;
            //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            //      handleAsyncSearch();
            //     console.log('hello')
            //     }
            //   }
            // }}
            // ListboxComponent={ListBox} // handle scroll position
          />
        </td>
      </tr>
    </>
  );
};
