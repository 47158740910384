import { Breakpoint } from '@mui/material';
import {
  getFormModel,
  getPreviousFormModel,
  setFormModel
} from '../../../application/builder/home/builder/body/builder/process/builder/form/controller/KagamiFormController';
import { KagamiFormModel } from '../../../application/builder/home/builder/body/builder/process/builder/form/model/KagamiFormModel';
import { logout } from '../../../application/builder/login/controller/KagamiLoginController';
import { CommonUtils } from '../../../application/utils/CommonUtils';
import { KagamiModalModel } from '../model/KagamiModalModel';
import { builModal } from '../ui/KagamiModal';
import {
  getAttributePresentationType,
  getAttributePresentations
} from '../../../application/builder/home/builder/body/builder/process/service/PresentationService';
import { KagamiStateManager } from '../../../state/KagamiStateManager';
import { SystemConstants } from '../../../application/constants/SystemConstants';

export function buildModal(modalModel: KagamiModalModel) {
  return builModal(modalModel);
}

export function handleModalOnOk(modalModel: KagamiModalModel) {
  let updatePasswordState = KagamiStateManager.getStateToHolder(SystemConstants.UPDATEPASSWORD_STATE);
  let updatePasswordErrors = updatePasswordState?.errors;
  if (modalModel.isTriggerModal && modalModel.embeddedFormPresentationModel !== null) {
    modalModel.handleOnOk(modalModel.embeddedFormPresentationModel);
  } else if (modalModel.handleOnOk) {
    if (CommonUtils.isNotEmpty(updatePasswordErrors)) {
      modalModel.state.setOpen(true);
    } else {
      modalModel.state.setOpen(false);
    }
    modalModel.handleOnOk();
  } else {
    modalModel.state.setOpen(false);
    if (modalModel.isSessionInvalid) logout();
  }
}

export function handleModalOnClose(modalModel: KagamiModalModel) {
  modalModel.state.setOpen(false);
  if (modalModel.isTriggerModal && modalModel.embeddedFormPresentationModel !== null) {
    modalModel.handleOnClose(modalModel.embeddedFormPresentationModel);
  } else if (modalModel.handleOnClose) {
    modalModel.handleOnClose();
  } else if (modalModel.isPickItem) {
    let formModel: KagamiFormModel = getFormModel();
    if (formModel.isSingleListView) {
      let mainFormModel: KagamiFormModel | undefined = getPreviousFormModel();
      mainFormModel && setFormModel(mainFormModel);
    }
    setIsPickItem(false, modalModel);
  }
}

function setIsPickItem(isPickItem: boolean, modalModel: KagamiModalModel) {
  modalModel.isPickItem = isPickItem;
}

export const getDynamicModalWidth = (formModel: any, modalContent?: any) => {
  let formComps: any[] = [];
  let width: Breakpoint = 'sm';

  let allPresentations = getAttributePresentations(formModel.presentation);
  let nestedEmbeddedModels = formModel.nestedEmbeddedModels;

  if (CommonUtils.isNotEmpty(allPresentations)) {
    for (const presentation in allPresentations) {
      let attributePresentation = allPresentations[presentation];

      if (getAttributePresentationType(attributePresentation) === 'FieldPresentation') {
        attributePresentation['visible'] === true && formComps.push(attributePresentation);
      }
    }
  }

  console.log('dynamicWidths', formComps);

  if (CommonUtils.isNotEmpty(formComps)) {
    if (formComps.length > 8 || CommonUtils.isNotEmpty(nestedEmbeddedModels)) {
      width = 'lg';
    } else if (formComps.length >= 4) {
      width = 'md';
    } else if (formComps.length === 3) {
      width = 'lg';
    } else if (formComps.length <= 3) {
      width = 'sm';
    } else {
      width = 'xs';
    }
  } else if (CommonUtils.isNotEmpty(modalContent) && modalContent.length <= 2) {
    width = 'sm';
  } else {
    width = 'lg';
  }
  return width;
};
