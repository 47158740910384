import { KagamiModalModel } from '../../../../components/modal/model/KagamiModalModel';
import { APIService } from '../../../../service/ApiService';
import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';
import { DOMAIN, getCloseImpersonateURL } from '../../../service/URLService';
import { CommonUtils } from '../../../utils/CommonUtils';
import { KagamiImpersonateModel } from '../model/impersonateModel';
import { buildImpasinate } from '../ui/impersonate';

let kagamiImpersonateModel: KagamiImpersonateModel;

export const buildImpasinateBuilder = (
  impersonateModel: KagamiImpersonateModel,
  impersonateModelPopupModel: KagamiModalModel
) => {
  kagamiImpersonateModel = impersonateModel;
  return buildImpasinate(impersonateModel, impersonateModelPopupModel);
};

export function getKagamiImpersonateModel() {
  return kagamiImpersonateModel;
}

export function closeImpersonateHandler(profileState: any) {
  let loginObject: any;
  const loginData = localStorage.getItem(SystemConstants.LOGIN_DATA);
  if (loginData !== null) {
    loginObject = JSON.parse(loginData);
  }
  const ApiloginData = {
    userdomain: DOMAIN,
    userId: loginObject.userId
    // password: loginObject.password
  };
  APIService.getData(SystemConstants.POST, getCloseImpersonateURL(), ApiloginData)
    .then((impersonateResponse: any) => {
      const response = impersonateResponse.data.SUCCESS;
      let responseToken: any;
      if (CommonUtils.isNotEmpty(response)) {
        responseToken = JSON.parse(response);
        closeTokenForImpersonate(responseToken.token);
        profileState.setImpersonate(false);
        localStorage.setItem('isImpersonation', 'false');
        CommonUtils._needWindowReload(100);
      }
    })
    .catch((err: any) => {
      console.log('err', err);
    });
}

export const setTokenForImpersonate = (token: any) => {
  localStorage.setItem(SystemConstants.TOKEN, token);
  KagamiStateManager.addStateToHolder(SystemConstants.TOKEN, token);
};

export const closeTokenForImpersonate = (token: any) => {
  localStorage.setItem(SystemConstants.TOKEN, token);
  KagamiStateManager.addStateToHolder(SystemConstants.TOKEN, token);
};

export const setUserIdForImpersonate = (impersonateId: any) => {
  // localStorage.setItem(SystemConstants.LOGIN_DATA, JSON.stringify(loginData));
  // KagamiStateManager.addStateToHolder(SystemConstants.LOGIN_DATA, JSON.stringify(loginData));
  localStorage.setItem('isImpersonation', 'true');
  localStorage.setItem('impersonateUserId', impersonateId);
};

