import { KagamiGridModel } from "../../list/builder/grid/model/KagamiGridModel";
import { EmbeddedRowCountModel } from "../builder/embedded-presentation/builder/embedded-accordion-presentation/embedded-rowcount/model/EmbeddedRowCountModel";
import { KagamiFormPresentationModel } from "../builder/form-presenation/model/KagamiFormPresentationModel";


export class KagamiEmbeddedFormPresentationModel extends KagamiFormPresentationModel {
  onCancelRequired: boolean;
  kagamiGridModels: Map<string, KagamiGridModel>;
  embeddedView : string;
  isNewRecord : boolean;
  isActiveForm : boolean;
  oldEditRecord : any;
  newEditRecord : any;
  embedTriggers : any;
  isNested : boolean;
  parentEmbedIdForNestedEmbed : string;
  parentEmbedEntityId : string;
  isFirstOnloadCall : boolean;
  rowCountModel : EmbeddedRowCountModel ;
  embedViewId : string;
  
  constructor(
    onStartData: any,
    mainEntityId: any,
    formEntityId: string,
    formHeader: string,
    processName: string,
    processStepName: string,
    uiTemplate: string,
    initiallyVisible : boolean,
    initiallyReadOnly : boolean,
    isDynamicForm : boolean,
  ) {
    super(onStartData, mainEntityId, formEntityId, formHeader, processName, processStepName, uiTemplate,initiallyVisible,initiallyReadOnly,isDynamicForm);
    this.onCancelRequired = false;
        this.kagamiGridModels = new Map();
        this.embeddedView = 'accordion';
        this.isNewRecord = false;
        this.isActiveForm = false;
        this.embedTriggers = {};
        this.isNested = false;
        this.parentEmbedIdForNestedEmbed = '';
        this.parentEmbedEntityId = '';
        this.isFirstOnloadCall = true;
        this.rowCountModel = new EmbeddedRowCountModel();
        this.embedViewId = '';
  }
}

export interface KagamiEmbedddedPresentationProps {
  embeddedPresentationModel: KagamiEmbeddedFormPresentationModel;
}

export interface KagamiEmbedAccordionProps {
  embeddedPresentationModel: KagamiEmbeddedFormPresentationModel;
  classes: any;
}
