import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiKanbanSectionStyle = (theme: Theme) =>
  createStyles({
    // root: {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   flexWrap: 'wrap'
    // },
    // paper: {
    //   minWidth: theme.spacing(25),
    //   maxWidth: theme.spacing(40),
    //   minHeight: theme.spacing(37.5),
    //   backgroundColor: '#ebecf0',
    //   borderRadius: theme.spacing(0.5)
    // },
    // alert_Card_Count: {
    //   borderRadius: '50%',
    //   width: theme.spacing(3),
    //   height: theme.spacing(3),
    //   marginTop: theme.spacing(0.5),
    //   color: '#FFF',
    //   fontWeight: 600,
    //   marginRight: theme.spacing(0.5),
    //   border: '2px solid #FFF',
    //   lineHeight: '1px',
    //   fontSize: theme.spacing(2)
    // },
    // kanban_card: {
    //   overflow: 'auto',
    //   width: theme.spacing(25),
    //   margin: theme.spacing(0.5),
    //   borderRadius: theme.spacing(0.5)
    // },
    // kanban_card_visbleAttributes: {
    //   width: '100%',
    //   float: 'left',
    //   textAlign: 'left',
    //   fontSize: theme.spacing(1.5)
    // },
    // kanban_section_paper:{
    //   minWidth:theme.spacing(40),
    //   maxWidth: theme.spacing(40),
    //   maxHeight: theme.spacing(30),
    //   backgroundColor: '#ebecf0',
    //   borderRadius:theme.spacing(0.5),
    // },
    // kanban_section_eachCard_vertIcon:{
    //   color:'#007ecc',
    //   fontSize: theme.spacing(1.25),
    //    marginTop: theme.spacing(-2.25),
    //    marginRight: theme.spacing(-3)
    // },
    // retunrdiv1:{
    //   display: 'flex',
    //     justifyContent: 'space-between',
    //     flexDirection: 'row',
    //     flexWrap: 'wrap',
    //     width: '100%'
    // },
    // returndiv2:{
    //   display:'block' , 
    //   width:'33.33%' , 
    //   padding:'5px' , 
    //   marginTop:'20px'
    // },
    // gutterbtn:{
    //   fontSize: '10px !important',
    //   //color: `${color}`,
    //   lineHeight: '22px',
    //   whiteSpace: 'nowrap',
    //   width: '50%',
    //   textTransform: 'capitalize'
    // },
    // gutterbtn2:{
    //   color: '#666666',
    //   fontSize: '8px !important',
    //   lineHeight: '12px',
    //   whiteSpace: 'nowrap',
    //   width: '50%',
    //   textTransform: 'capitalize'
    // },
    // kanbanpeper:{
    //   minWidth: 200, maxWidth: 350, height: '98%', backgroundColor: '#ebecf0', borderRadius: '4px' 
    // },
    // kanbandatarecord:{
    //   borderRadius: '50%',
    //   width: '23px',
    //   height: '23px',
    //   marginTop: '4px',
    //   color: '#FFF',
    //   fontWeight: 600,
    //   marginRight: '5px',
    //   border: '2px solid #FFF',
    //   lineHeight: '1px',
    //   fontSize: '20px'
    // },
    // carddiv:{
    //   display: 'flex',
    //   color: '#007ecc',
    //   fontSize: '10px',
    //   marginTop: '-18px',
    //   marginRight: '-14px'
    // },
    // cardgbtn:{
    //   width: '100%', float: 'left', textAlign: 'left', fontSize: '12px' 
    // },
    PopoverMenuItem: {
      fontSize:'12px', color: theme.palette.secondary.main,
      borderBottom:'1px solid #ebebeb',
      '&:last-child': {
        border:'none'
      },
      '&:hover': {
        background:'#006e7f10'
      }
    },
    kanbanColumn: {
      width:'200px',
      borderTopLeftRadius:"5px",
      borderTopRightRadius:"5px",
      minHeight: 500,
      maxHeight:'calc(100% - 40px)',
      overflowY:'auto',
      overflowX:'hidden',
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        height: theme.spacing(1)
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.4)'
      },
      '&::-webkit-scrollbar-track': {
        background: 'hsla(0,0,0,.2)'
      }
    },
    cardItemKanban: {
      display:'flex', 
      flexDirection:'row',
      '&:hover': {
        "& $editIconKanban": {
          display: 'block'
        }
      }
    }
    
  });



