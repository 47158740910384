import { State, useState } from '@hookstate/core';
import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { KagamiStateManager } from '../../../../../../../../../../../../state/KagamiStateManager';
import { KagamiListModel } from '../../../model/KagamiListModel';
import { TriggerAlertModalModel } from '../../../../Triggers/builder/TriggerAlertModal/model/TriggerAlertModalModel';

export class KagamiCalendarViewModel extends KagamiListModel {
  isHeaderLoaded: boolean;
  header : any;
  isHeaderLoadedFromOnStartData: boolean;
  triggerAlertModalModel: TriggerAlertModalModel;
  constructor(processName: string, processStepName: string, mainEntityId: string, presentation: any) {
    super(processName, processStepName, null, mainEntityId);
    this.state = null;
    this.presentation = presentation;
    this.isHeaderLoaded = false;
    this.isHeaderLoadedFromOnStartData = false;
    this.triggerAlertModalModel = new TriggerAlertModalModel('');
  }
}

export interface KagamiCalendarViewModelProps {
  kagamiCalendarViewModel: KagamiCalendarViewModel;
}
export const wrapKagamiCalendarViewModelState = (state: State<KagamiCalendarViewModel>) => {
  let calendarState = state;

  return {
    get isListLoaded() {
      return calendarState.isListLoaded.get();
    },
    setListLoaded(value: boolean) {
      calendarState.isListLoaded.set(value);
    },  
    get isHeaderLoaded() {
      return calendarState.isHeaderLoaded.get();
    },
    setHeaderLoaded(value: boolean) {
      calendarState.isHeaderLoaded.set(value);
    },
    get isHeaderLoadedFromOnStartData() {
      return calendarState.isHeaderLoadedFromOnStartData.get();
    },
    setIsHeaderLoadedFromOnStartData(value: boolean) {
      calendarState.isHeaderLoadedFromOnStartData.set(value);
    },
  };
};

export const useCalendarListState = (state: State<KagamiCalendarViewModel>) => {
  const calendarState = wrapKagamiCalendarViewModelState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.CALENDAR_STATE, calendarState);
  return calendarState;
};
