import { createState } from '@hookstate/core';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  MenuItem,
  Grid,
  CircularProgress
} from '@mui/material';
import React, { useState } from 'react';
import { SchedulerFormModel, SchedulerFormModelProps, useScheduleFormState } from '../model/SchedulerFormModel';
import { getUserAndRolesAtOnce, makeSchedulerForm } from '../service/SchedulerFormService';
import ScheduleReportStyle from '../../../styles/ScheduleReportStyle';
import { makeStyles } from '@mui/styles';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { translateToLocalistaion } from '../../../../../../../../process/service/PresentationService';

const useStyles = makeStyles(ScheduleReportStyle);

export const buildReportSchedulerFormContent = (schedulerFormModel: SchedulerFormModel, translations?: any) => {
  return (
    <ReportSchedulerFormContent
      key={Math.random().toString()}
      schedulerFormModel={schedulerFormModel}
      translations={translations}
    />
  );
};

const UserAndRoleData = (props: SchedulerFormModelProps) => {
  let schedulerFormModel = props.schedulerFormModel;
  const [selectUsersOrRoles, setUsersOrRoles] = useState('');
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Typography>
            {' '}
            {translateToLocalistaion(props.translations, 'Email Subject')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            sx={{ ml: 5, height: 0 }}
            onChange={(event: any) => {
              schedulerFormModel.initialFormData.metaData.emailSub = event.target.value;
              console.log('emailSub', schedulerFormModel.initialFormData.metaData.emailSub);
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {' '}
            {translateToLocalistaion(props.translations, 'Email Body')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            autoComplete="off"
            sx={{ ml: 5, height: 0 }}
            onChange={(event: any) => {
              schedulerFormModel.initialFormData.metaData.emailBody = event.target.value;
              // console.log('emailBody', schedulerFormModel.initialFormData.metaData.emailBody);
            }}
            variant="standard"
            rows={7}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(event: any) => {
              if (event.target.value === 'Select User') {
                delete schedulerFormModel.initialFormData.metaData['role'];
              } else if (event.target.value === 'Select Roles') {
                schedulerFormModel.initialFormData.metaData.emailUser = {};
              }
              setUsersOrRoles(event.target.value);
            }}
          >
            <FormControlLabel
              value="Select Users"
              control={<Radio />}
              label={translateToLocalistaion(props.translations, 'Select Users')}
            />
            <FormControlLabel
              value="Select Roles"
              control={<Radio />}
              label={translateToLocalistaion(props.translations, 'Select Roles')}
            />
          </RadioGroup>
          {selectUsersOrRoles === 'Select Users' && (
            <div style={{ marginLeft: '20px' }}>
              <Typography>{translateToLocalistaion(props.translations, 'Select Users')} : </Typography>
              <Autocomplete
                disablePortal
                limitTags={2}
                id="selectuser"
                options={schedulerFormModel.userList}
                multiple={true}
                size="small"
                sx={{ marginLeft: '0px', width: 414, maxHeight: 200, overflow: 'auto' }}
                onChange={(event: any, child: any) => {
                  schedulerFormModel.initialFormData.metaData.emailUser = child;
                  console.log('emailUser : ', schedulerFormModel.initialFormData.metaData.emailUser);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={translateToLocalistaion(props.translations, '')} />
                )}
              />
            </div>
          )}
          {selectUsersOrRoles === 'Select Roles' && (
            <div style={{ marginLeft: '20px' }}>
              <Typography>{translateToLocalistaion(props.translations, 'Select Roles')} : </Typography>
              <Autocomplete
                disablePortal
                multiple
                id="selectroles"
                limitTags={2}
                options={schedulerFormModel.allROles}
                size="small"
                sx={{ marginLeft: '0px', width: 414, maxHeight: 200, overflow: 'auto' }}
                onChange={(event: any, child: any) => {
                  schedulerFormModel.initialFormData.metaData.role = child;
                  console.log('role : ', schedulerFormModel.initialFormData.metaData.role);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={translateToLocalistaion(props.translations, '')} />
                )}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const ServerDetails = (props: SchedulerFormModelProps) => {
  let schedulerFormModel = props.schedulerFormModel;
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Typography>{translateToLocalistaion(props.translations, 'FTP Server')} </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            sx={{ ml: 5, height: 0 }}
            onChange={(event: any) => {
              schedulerFormModel.initialFormData.metaData.serverDetails.server = event.target.value;
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{translateToLocalistaion(props.translations, 'FTP Port')} </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            sx={{ ml: 5, height: 0 }}
            onChange={(event: any) => {
              schedulerFormModel.initialFormData.metaData.serverDetails.port = event.target.value;
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{translateToLocalistaion(props.translations, 'FTP UserName')} </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            sx={{ ml: 5, height: 0 }}
            onChange={(event: any) => {
              schedulerFormModel.initialFormData.metaData.serverDetails.userName = event.target.value;
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{translateToLocalistaion(props.translations, 'FTP Password')} </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            sx={{ ml: 5, height: 0 }}
            onChange={(event: any) => {
              schedulerFormModel.initialFormData.metaData.serverDetails.password = event.target.value;
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>{translateToLocalistaion(props.translations, 'FTP Folder')} </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            required
            sx={{ ml: 5, height: 0 }}
            onChange={(event: any) => {
              schedulerFormModel.initialFormData.metaData.serverDetails.path = event.target.value;
            }}
            variant="standard"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ReportSchedulerFormContent = (props: SchedulerFormModelProps) => {
  const schedulerFormModel = props.schedulerFormModel;
  const schedulerFormState = useScheduleFormState(createState(schedulerFormModel));
  schedulerFormModel.state = schedulerFormState;
  let style = { display: 'none' };
  const [showAllFields, setAllFields] = React.useState(style);

  const [radio, setRadio] = useState('hourly');

  React.useEffect(() => {
    getUserAndRolesAtOnce()
      .then((response: any) => {
        console.log('fetching userList and allRoles', response);
        const userList: any = new Set(response[0].data);
        schedulerFormModel.userList = [...userList];
        schedulerFormModel.allROles = response[1]['data']['respObject'] ?? [];
        schedulerFormModel.state.setDataLoaded(true);
      })
      .catch((err: any) => {
        console.log('getting error while fetching useList And allRoles ', err);
      });
  }, []);
  if (!schedulerFormState.isDataLoaded) {
    return (
      <CircularProgress sx={{ display: 'block', margin: '30px auto 30px auto', width: '40px' }} color="secondary" />
    );
  } else {
    const formOptionData = makeSchedulerForm(schedulerFormModel);
    return (
      <Box
        sx={{
          color: '#0000008a',
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          m: 4
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography>{translateToLocalistaion(props.translations, 'Schedule Report')}</Typography>
          <Box sx={{ display: 'flex', padding: '10px' }}>
            <Typography>{translateToLocalistaion(props.translations, 'Select a Report to schedule')}</Typography>
            <Autocomplete
              id="schedule-report"
              disablePortal={false}
              multiple={true}
              limitTags={2}
              size="small"
              options={formOptionData.reportList}
              getOptionLabel={(option: any) => option.name ?? ''}
              sx={{ marginLeft: '20px', width: 300, maxHeight: 200, overflow: 'auto' }}
              onChange={(event: any, reportConfigSummary: any) => {
                setAllFields({ display: 'block' });
                schedulerFormModel.initialFormData.reportsList = reportConfigSummary.map((rcs: any) => {
                  return rcs['id'];
                });
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>
        <Box style={showAllFields}>
          <Box sx={{ display: 'flex' }}>
            <Typography>{translateToLocalistaion(props.translations, 'Scheduler Name')} : </Typography>
            <TextField
              variant="standard"
              autoComplete="off"
              onChange={(event: any) => {
                schedulerFormModel.initialFormData.schedule.schedulerName = event.target.value ?? '';
              }}
            />
          </Box>
          {/* -------------------radio button start-------------------- */}
          <Box>
            <RadioGroup
              row={true}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={radio}
              onChange={(event: any) => {
                schedulerFormModel.initialFormData.metaData.type = event.target.value;
                schedulerFormModel.initialFormData.metaData.repeatEvery = {};
                if (event.target.value === 'daily') {
                  schedulerFormModel.initialFormData.metaData.repeatEvery['type'] = 'day';
                } else if (event.target.value === 'weekly') {
                  schedulerFormModel.initialFormData.metaData.repeatEvery['weekDays'] = {};
                } else if (event.target.value === 'monthly') {
                  schedulerFormModel.initialFormData.metaData.repeatEvery['type'] = 'dayOfMonth';
                } else if (event.target.value === 'yearly') {
                  schedulerFormModel.initialFormData.metaData.repeatEvery['type'] = 'dayOfMonthEveryYear';
                }
                setRadio(event.target.value);
              }}
            >
              {formOptionData.timeTriggerTypes.map((types: string) => {
                return (
                  <FormControlLabel
                    key={Math.random()}
                    value={types.toLowerCase()}
                    control={<Radio />}
                    label={translateToLocalistaion(props.translations, types)}
                  />
                );
              })}
            </RadioGroup>
            <Box sx={{ ml: 2 }}>
              {radio === 'hourly' && (
                <Box sx={{ display: 'flex', margin: '8px' }}>
                  <Typography>
                    {translateToLocalistaion(props.translations, 'Every')}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="10"
                    id="standard-number-hourly"
                    type="number"
                    variant="standard"
                    onChange={(event: any) => {
                      schedulerFormModel.initialFormData.metaData.repeatEvery['Hours'] = +event.target.value;
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Typography>{translateToLocalistaion(props.translations, 'Hours')} </Typography>
                </Box>
              )}

              {radio === 'daily' && (
                <Box sx={{ margin: '8px' }}>
                  <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={schedulerFormModel.initialFormData.metaData.repeatEvery['type'] ?? 'day'}
                    onChange={(event: any) => {
                      schedulerFormModel.initialFormData.metaData.repeatEvery['type'] = event.target.value;
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="day"
                        control={<Radio />}
                        label={translateToLocalistaion(props.translations, 'Every')}
                      />
                      <TextField
                        placeholder="10"
                        id="standard-number-daily"
                        type="number"
                        sx={{ width: '150px', marginLeft: '10px' }}
                        variant="standard"
                        onChange={(event: any) => {
                          if (schedulerFormModel.initialFormData.metaData.repeatEvery.type == 'day') {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['Days'] = +event.target.value;
                          }
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      <Typography>{translateToLocalistaion(props.translations, 'Days')} </Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="week"
                        control={<Radio />}
                        label={translateToLocalistaion(props.translations, 'Recur every weekday')}
                      />
                    </Box>
                  </RadioGroup>
                </Box>
              )}

              {radio === 'weekly' && (
                <Box sx={{ display: 'flex', margin: '8px' }}>
                  <Typography>
                    {translateToLocalistaion(props.translations, 'Every')}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  {formOptionData.daysOfWeek &&
                    formOptionData.daysOfWeek.map((weekDay: any, index: number) => {
                      return (
                        <Checkbox
                          size="small"
                          key={`weekday-checkbox-${index}`}
                          onChange={(event: any, child: any) => {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['weekDays'][weekDay.value] = child;
                          }}
                        />
                      );
                    })}
                </Box>
              )}

              {radio === 'monthly' && (
                <Box sx={{ margin: '8px' }}>
                  <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={schedulerFormModel.initialFormData.metaData.repeatEvery['type'] ?? 'dayOfMonth'}
                    onChange={(event: any) => {
                      schedulerFormModel.initialFormData.metaData.repeatEvery = {};
                      schedulerFormModel.initialFormData.metaData.repeatEvery['type'] = event.target.value;
                    }}
                  >
                    <Box style={{ display: 'flex' }}>
                      <FormControlLabel
                        value="dayOfMonth"
                        control={<Radio />}
                        label={translateToLocalistaion(props.translations, 'Every')}
                      />
                      <span style={{ color: 'red' }}>*</span>
                      <TextField
                        placeholder="10"
                        id="standard-number-monthly1"
                        type="number"
                        variant="standard"
                        sx={{ width: '150px', ml: 2, mr: 2 }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(event: any) => {
                          if (schedulerFormModel.initialFormData.metaData.repeatEvery['type'] === 'dayOfMonth') {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['day'] = event.target.value;
                          }
                        }}
                      />
                      <Typography>
                        {translateToLocalistaion(props.translations, 'day of every')}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <TextField
                        placeholder="10"
                        id="standard-number-monthly2"
                        type="number"
                        variant="standard"
                        sx={{ width: '150px', ml: 2, mr: 5 }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(event: any) => {
                          if (schedulerFormModel.initialFormData.metaData.repeatEvery['type'] === 'dayOfMonth') {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['months'] = event.target.value;
                          }
                          console.log(event.target.value);
                        }}
                      />
                      <Typography>{translateToLocalistaion(props.translations, 'months')}</Typography>
                    </Box>
                    <Box style={{ display: 'flex' }}>
                      <FormControlLabel
                        value="dayOfWeekOfMonth"
                        control={<Radio />}
                        label={translateToLocalistaion(props.translations, 'On the')}
                      />
                      <Select
                        id="monthly-order"
                        defaultValue=""
                        sx={{ width: '150px', ml: 2 }}
                        variant="standard"
                        onChange={(event: any) => {
                          if (schedulerFormModel.initialFormData.metaData.repeatEvery['type'] === 'dayOfWeekOfMonth') {
                            if (event.target.value == 'last') {
                              schedulerFormModel.initialFormData.metaData.repeatEvery['order'] = event.target.value;
                            } else {
                              schedulerFormModel.initialFormData.metaData.repeatEvery['order'] = +event.target.value;
                            }
                          }
                        }}
                      >
                        {formOptionData['orderOptions'] &&
                          formOptionData['orderOptions'].map((option: any) => (
                            <MenuItem key={`monthly-order-${option.label}-${option.id}`} value={option.id}>
                              {/* {option.label} */}
                              {translateToLocalistaion(props.translations, option.label)}
                            </MenuItem>
                          ))}
                      </Select>

                      <Select
                        id="monthly-daysOfWeek"
                        sx={{ width: '150px', ml: 2 }}
                        variant="standard"
                        defaultValue={''}
                        onChange={(event: any) => {
                          if (schedulerFormModel.initialFormData.metaData.repeatEvery['type'] === 'dayOfWeekOfMonth') {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['dayOfWeek'] = event.target.value;
                          }
                        }}
                      >
                        {formOptionData['daysOfWeek'].map((option: any) => (
                          <MenuItem key={`monthly-daysOfWeek-${option.label}-${option.id}`} value={option.id}>
                            {/* {option.label} */}
                            {translateToLocalistaion(props.translations, option.label)}
                          </MenuItem>
                        ))}
                      </Select>

                      <Typography>{translateToLocalistaion(props.translations, 'of every')}</Typography>
                      <TextField
                        placeholder="10"
                        id="standard-number-monthly3"
                        type="number"
                        variant="standard"
                        sx={{ width: '150px', ml: 2 }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(event: any) => {
                          if (schedulerFormModel.initialFormData.metaData.repeatEvery['type'] === 'dayOfWeekOfMonth') {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['months'] = event.target.value;
                          }
                        }}
                      />

                      <Typography>{translateToLocalistaion(props.translations, 'Month')}</Typography>
                    </Box>
                  </RadioGroup>
                </Box>
              )}

              {radio === 'yearly' && (
                <Box sx={{ margin: '8px' }}>
                  <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={
                      schedulerFormModel.initialFormData.metaData.repeatEvery['type'] ?? 'dayOfMonthEveryYear'
                    }
                    onChange={(event: any) => {
                      schedulerFormModel.initialFormData.metaData.repeatEvery = {};
                      schedulerFormModel.initialFormData.metaData.repeatEvery['type'] = event.target.value;
                    }}
                  >
                    <Box style={{ display: 'flex' }}>
                      <FormControlLabel
                        value="dayOfMonthEveryYear"
                        control={<Radio />}
                        label={translateToLocalistaion(props.translations, 'Every')}
                      />
                      <span style={{ color: 'red' }}>*</span>
                      <Select
                        id="yearly-monthsOfYear"
                        sx={{ width: '120px', marginLeft: '10px' }}
                        variant="standard"
                        defaultValue={''}
                        onChange={(event: any) => {
                          if (
                            schedulerFormModel.initialFormData.metaData.repeatEvery['type'] === 'dayOfMonthEveryYear'
                          ) {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['month'] = +event.target.value;
                          }
                        }}
                      >
                        {formOptionData['monthsOfYear'].map((option: any) => (
                          <MenuItem key={`yearly-monthsOfYear-${option.label}-${option.id}`} value={option.id}>
                            {/* {option.label} */}
                            {translateToLocalistaion(props.translations, option.label)}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        id="standard-number-yearly1"
                        type="number"
                        variant="standard"
                        sx={{ width: '120px', marginLeft: '10px' }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(event: any) => {
                          if (
                            schedulerFormModel.initialFormData.metaData.repeatEvery['type'] === 'dayOfMonthEveryYear'
                          ) {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['day'] = +event.target.value;
                          }
                        }}
                      />
                    </Box>

                    <Box style={{ display: 'flex' }}>
                      <FormControlLabel
                        value="dayOfWeekOfMonthEveryYear"
                        control={<Radio />}
                        label={translateToLocalistaion(props.translations, 'On the')}
                      />
                      <Select
                        id="yearly-orderOptions"
                        variant="standard"
                        sx={{ width: '120px', marginLeft: '10px' }}
                        defaultValue={''}
                        onChange={(event: any) => {
                          if (
                            schedulerFormModel.initialFormData.metaData.repeatEvery['type'] ===
                            'dayOfWeekOfMonthEveryYear'
                          ) {
                            if (event.target.value == 'last') {
                              schedulerFormModel.initialFormData.metaData.repeatEvery['order'] = event.target.value;
                            } else {
                              schedulerFormModel.initialFormData.metaData.repeatEvery['order'] = +event.target.value;
                            }
                          }
                        }}
                      >
                        {formOptionData['orderOptions'].map((option: any) => (
                          <MenuItem key={`yearly-orderOptions-${option.label}-${option.id}`} value={option.id}>
                            {/* {option.label} */}
                            {translateToLocalistaion(props.translations, option.label)}
                          </MenuItem>
                        ))}
                      </Select>

                      <Select
                        id="yearly-daysOfWeek"
                        variant="standard"
                        sx={{ width: '80px', marginLeft: '10px' }}
                        defaultValue={''}
                        onChange={(event: any) => {
                          if (
                            schedulerFormModel.initialFormData.metaData.repeatEvery['type'] ===
                            'dayOfWeekOfMonthEveryYear'
                          ) {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['dayOfWeek'] = +event.target.value;
                          }
                        }}
                      >
                        {formOptionData['daysOfWeek'].map((option: any) => (
                          <MenuItem key={`yearly-daysOfWeek-${option.label}-${option.id}`} value={option.id}>
                            {/* {option.label} */}
                            {translateToLocalistaion(props.translations, option.label)}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>{translateToLocalistaion(props.translations, 'of')}</Typography>
                      <Select
                        id="yearly-monthsOfYear"
                        defaultValue={''}
                        variant="standard"
                        sx={{ width: '80px', marginLeft: '10px' }}
                        onChange={(event: any) => {
                          if (
                            schedulerFormModel.initialFormData.metaData.repeatEvery['type'] ===
                            'dayOfWeekOfMonthEveryYear'
                          ) {
                            schedulerFormModel.initialFormData.metaData.repeatEvery['yearMonth'] = +event.target.value;
                          }
                        }}
                      >
                        {formOptionData['monthsOfYear'] &&
                          formOptionData['monthsOfYear'].map((option: any) => (
                            <MenuItem key={`yearly-monthsOfYear-${option.label}-${option.id}`} value={option.id}>
                              {/* {option.label} */}
                              {translateToLocalistaion(props.translations, option.label)}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                  </RadioGroup>
                </Box>
              )}
            </Box>
            {/* -------------------radio button end-------------------- */}
          </Box>
          <StartTime schedulerFormModel={schedulerFormModel} />
          <Box sx={{ display: 'flex' }}>
            <Typography mr={3}>{translateToLocalistaion(props.translations, 'Repeat')}</Typography>
            <TextField
              placeholder="10"
              id="repete-count"
              type="number"
              variant="standard"
              onChange={(event: any) => {
                schedulerFormModel.initialFormData.repeteCount = +event.target.value;
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Typography>{translateToLocalistaion(props.translations, 'times')}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography>{translateToLocalistaion(props.translations, 'File Type')}</Typography>
            <Select
              id="custom-file-type"
              sx={{ width: '150px', marginLeft: '10px' }}
              defaultValue={''}
              variant="standard"
              onChange={(event: any) => {
                schedulerFormModel.initialFormData.metaData.fileType = event.target.value;
              }}
            >
              {formOptionData['fileType'].map((option: any) => (
                <MenuItem key={`${option.label}-${option.id}`} value={option.label.toLowerCase()}>
                  {/* {option.label} */}
                  {translateToLocalistaion(props.translations, option.label)}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <FormControlLabel
              value="Enable Email"
              control={<Checkbox />}
              label={translateToLocalistaion(props.translations, 'Enable Email')}
              labelPlacement="start"
              onChange={(event, child) => {
                schedulerFormModel.initialFormData.enableEmail = child;
              }}
            />
            <FormControlLabel
              value="Enable FTP"
              control={<Checkbox />}
              label={translateToLocalistaion(props.translations, 'Enable FTP')}
              labelPlacement={translateToLocalistaion(props.translations, 'start')}
              onChange={(event, child) => {
                schedulerFormModel.initialFormData.enableFTP = child;
              }}
            />
          </Box>
          <hr />
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '48%' }}>
              <UserAndRoleData schedulerFormModel={schedulerFormModel} />
            </Box>
            <hr />
            <Box sx={{ width: '48%' }}>
              <ServerDetails schedulerFormModel={schedulerFormModel} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

const StartTime = (props: SchedulerFormModelProps) => {
  let schedulerFormModel = props.schedulerFormModel;
  const [hour, setHour] = useState<string | number | undefined>();
  const [minute, setMinute] = useState<string | number | undefined>();
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography mr={3}>
        {translateToLocalistaion(props.translations, 'Start time')}
        <span style={{ color: 'red' }}>*</span>
      </Typography>
      <TextField
        id="start-time-hour"
        error={hour !== undefined && typeof hour !== 'string' && (hour < 0 || hour > 23)}
        variant="standard"
        placeholder="hh"
        type="number"
        value={hour}
        inputProps={{ min: 0, max: 23 }}
        onChange={(event: any) => {
          if (event.target.value.length < 3) {
            if (event.target.value !== '') {
              schedulerFormModel.initialFormData.metaData.startTime.hours = +event.target.value;
              setHour(+event.target.value);
            } else {
              setHour(event.target.value);
            }
          }
        }}
        onBlur={() => {
          if (hour !== undefined && +hour > 23) {
            let $hour = `0${(+hour - (+hour % 10)) / 10}`;
            schedulerFormModel.initialFormData.metaData.startTime.hours = +$hour;
            if (CommonUtils.isEmpty(schedulerFormModel.initialFormData.metaData.startTime.minutes)) {
              schedulerFormModel.initialFormData.metaData.startTime.minutes = 0;
              setMinute('00');
            }
            setHour($hour);
          } else if (hour !== undefined && +hour < 10) {
            let $hour = `0${+hour}`;
            setHour($hour);
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
      <span style={{ marginLeft: '5px', marginRight: '5px', marginTop: '5px' }}>:</span>
      <TextField
        id="start-time-minute"
        error={minute !== undefined && typeof minute !== 'string' && (minute < 0 || minute > 59)}
        variant="standard"
        placeholder="mm"
        type="number"
        value={minute}
        inputProps={{ min: 0, max: 59 }}
        onChange={(event: any) => {
          if (event.target.value.length < 3) {
            if (event.target.value !== '') {
              schedulerFormModel.initialFormData.metaData.startTime.minutes = +event.target.value;
              setMinute(+event.target.value);
            } else {
              setMinute(event.target.value);
            }
          }
        }}
        onBlur={() => {
          if (minute !== undefined && +minute > 59) {
            let $minute = `0${(+minute - (+minute % 10)) / 10}`;
            schedulerFormModel.initialFormData.metaData.startTime.minutes = +$minute;
            if (CommonUtils.isEmpty(schedulerFormModel.initialFormData.metaData.startTime.hours)) {
              schedulerFormModel.initialFormData.metaData.startTime.hours = 0;
              setHour('00');
            }
            setMinute($minute);
          } else if (minute !== undefined && +minute < 10) {
            let $minute = `0${+minute}`;
            setMinute($minute);
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Box>
  );
};
