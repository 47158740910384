import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import { getKagamiListModel, reBuildTriggers } from '../../../controller/KagamiListController';
import { retrieveListRows } from '../../../service/kagamiListService';
import extend from 'lodash/extend';
import filter from 'lodash/filter';
import each from 'lodash/each';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import keys from 'lodash/keys';
import {remove} from 'lodash';
import get from 'lodash/get';



export function makePivot(kagamiCrossTabViewModel: any) {
  let defaultConfig = {
    columnDefs: [],
    defaultColDef: { flex: 1, sortable: true, minWidth: 150, filter: true, resizable: true, editable: false },
    groupDefaultExpanded: -1,//expand everything by default
    groupHideOpenParents:true,
    getRowHeight: (params: any) => 30,
    pivotMode: true,
    rowGroupPanelShow: 'always',
    pivotPanelShow: 'always',
    rowData: [],
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          width: 225,
          maxWidth: 225
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250
        }
      ],
      // position: 'left',
      defaultToolPanel: ''
    },
    suppressExpandablePivotGroups: false,
    suppressAggFuncInHeader: true
  };

  let userConfig = prapareUserConfigForPivot(kagamiCrossTabViewModel);
  let pivotConfig =  extend(true, {}, defaultConfig, userConfig);

  return pivotConfig;
}

function prapareUserConfigForPivot(kagamiCrossTabViewModel: any) {
  let userConfig = {
    columnDefs: [],
    rowData:[]
  };

  kagamiCrossTabViewModel.crossTabInfo['clickedActions'] = [];

  let rows: any[] = retrieveListRows(kagamiCrossTabViewModel);
  let columnDefs: any = [];
  //let presentation = props.kagamiCrossTabViewModel.presentation;
  let presentation = kagamiCrossTabViewModel.presentation;

  // const rowData = props.kagamiCrossTabViewModel.detailedObjects[presentation.entityId];
  const rowData = CommonUtils.isNotEmpty(kagamiCrossTabViewModel.detailedObjects) ?kagamiCrossTabViewModel.detailedObjects[presentation.entityId] :[];
  let xAxis =  filter(presentation.presentationRules, function (pRule) {
    return pRule.matrixAxis == 'x';
  });
  let yAxis =  filter(presentation.presentationRules, function (pRule) {
    return pRule.matrixAxis == 'y';
  });
  let cell =  filter(presentation.presentationRules, function (pRule) {
    return pRule.matrixAxis == 'cell';
  });

  // props.kagamiCrossTabViewModel.crossTabInfo['xAxis'] = xAxis;
  // props.kagamiCrossTabViewModel.crossTabInfo['yAxis'] = yAxis;
  // props.kagamiCrossTabViewModel.crossTabInfo['cell'] = cell;
  kagamiCrossTabViewModel.crossTabInfo['xAxis'] = xAxis;
  kagamiCrossTabViewModel.crossTabInfo['yAxis'] = yAxis;
  kagamiCrossTabViewModel.crossTabInfo['cell'] = cell;

   each(xAxis, function (xAxisObj) {
    columnDefs.push({ field: xAxisObj.attrName, headerName: xAxisObj.label, pivot: true });
  });
   each(yAxis, function (yAxisObj) {
    columnDefs.push({
      field: yAxisObj.attrName,
      headerName: yAxisObj.label,
      rowGroup: true,
      enableRowGroup: true,
      enablePivot: true,
    });
  });
   each(cell, function (cellObj) {
    //columnDefs.push({ field: cellObj.attrName , headerName: cellObj.label, aggFunc: 'sum', enableValue: true });
    columnDefs.push({
      field: cellObj.attrName,
      headerName: cellObj.label,
      aggFunc: 'sum',
      onCellClicked: (params: any) => {
        if(params.value !=null && params.value != undefined ){
          // onPivotCellClicked(kagamiCrossTabViewModel, params,userConfig);
          manageClickedActions(kagamiCrossTabViewModel, params,userConfig);
        }
      },
      cellClass: (params: any) => {
        let colpath =
          params.colDef && params.colDef.pivotKeys && params.colDef.pivotKeys[0]
            ? params.colDef.pivotKeys[0].toString().split(' ').join('-')
            : '';
        let rowpath = params.node && params.node.key ? params.node.key.split(' ').join('_') : '';
        colpath = colpath.replace(/[^a-zA-Z0-9]/g, '_');
        rowpath = rowpath.replace(/[^a-zA-Z0-9]/g, '_');
        let identifier = 'selector-' + rowpath + '-' + colpath + '' + params.node.rowIndex;
        // highLightSelectedRecord(kagamiCrossTabViewModel);
        let isSelected  = false;
        if (kagamiCrossTabViewModel?.crossTabInfo?.clickedActions) {
          let identfieierList =  filter(kagamiCrossTabViewModel?.crossTabInfo?.clickedActions,{identifier:identifier})
          if(identfieierList?.length > 0) {
            isSelected = true;
          }
        }            
        return isSelected ? [identifier, 'kgm-pivot-cell','kgm-pivot-cell-select'] : [identifier, 'kgm-pivot-cell'];
      }
    });
  });
  userConfig['columnDefs'] = columnDefs;
  userConfig['rowData'] = rowData;
  return userConfig;
}

function getColumnName(field: any, pivotReportController: any) {
  if (pivotReportController?.reportConfig?.criteria?.columns) {
    let column =  filter(pivotReportController.reportConfig.criteria.columns, { key: field });
    if (column && column.length) {
      return column[0]['name'];
    }
  }
  return field || '';
}

const onPivotCellClicked = (kagamiCrossTabViewModel: any, params: any,userConfig:any) => {
  let kgmPivotsCells = document.getElementsByClassName('kgm-pivot-cell');
   forEach(kgmPivotsCells, function (eachCell: any) {
    eachCell.classList.remove('kgm-pivot-cell-select');
  });
  let colpath =
    params.colDef && params.colDef.pivotKeys && params.colDef.pivotKeys[0]
      ? params.colDef.pivotKeys[0].toString().split(' ').join('-')
      : '';
  let rowpath = params.node && params.node.key ? params.node.key.split(' ').join('_') : '';
  colpath = colpath.replace(/[^a-zA-Z0-9]/g, '_');
  rowpath = rowpath.replace(/[^a-zA-Z0-9]/g, '_');
  let identifier = 'selector-' + rowpath + '-' + colpath + '' + params.node.rowIndex;
  let pivotsCells = document.getElementsByClassName(identifier);
   forEach(pivotsCells, function (eachCell: any) {
    eachCell.classList.add('kgm-pivot-cell-select');
  });
  foundRecordFromSource(kagamiCrossTabViewModel, params,identifier);
};

const foundRecordFromSource = function (kagamiCrossTabViewModel: any, params: any,identifier:any) {
  if (kagamiCrossTabViewModel && kagamiCrossTabViewModel.crossTabInfo) {
    let tempObj: any = {};
    if (kagamiCrossTabViewModel.crossTabInfo['xAxis']) {
       forEach(kagamiCrossTabViewModel.crossTabInfo['xAxis'], function (val, index) {
        if( params.colDef.pivotKeys[index]){
          tempObj[val['attrName']] = params.colDef.pivotKeys[index];
        }
      });
    }
    if (kagamiCrossTabViewModel.crossTabInfo['yAxis']) {
       forEach(kagamiCrossTabViewModel.crossTabInfo['yAxis'], function (val, index) {
        if (params.node.field == val['attrName']) {
          tempObj[val['attrName']] = params.node.key;
        }
      });
    }
    if (kagamiCrossTabViewModel.crossTabInfo['cell']) {
       forEach(kagamiCrossTabViewModel.crossTabInfo['cell'], function (val, index) {
        if (params.colDef.pivotValueColumn.userProvidedColDef.field == val['attrName']) {
          tempObj[val['attrName']] = params.value;
        }
      });
    }
    let detailedObjData = kagamiCrossTabViewModel.detailedObjects[kagamiCrossTabViewModel.mainEntityId];
    let tempKeys =  keys(tempObj);
    let foundIndex = -1;
    let foundObj = '';
     forEach(detailedObjData, function (obj, detailedIndex: number) {
      let isExists = true;
      for (let index = 0; index < tempKeys.length; index++) {
        let key = tempKeys[index];
        if (isExists) {
          if (!( get(obj, key) == tempObj[key])) {
            isExists = false;
          }
        }
      }
      if (isExists) {
        foundIndex = detailedIndex;
      }
    });

    /* if (foundIndex != -1) {
      foundObj = detailedObjData[foundIndex];
      getKagamiListModel().selectedObjects = [foundObj];
      reBuildTriggers();
    } 
    */

    return foundIndex;
  }
};


const manageClickedActions = function (kagamiCrossTabViewModel: any, params: any, userConfig: any) {
  let colpath =
    params.colDef && params.colDef.pivotKeys && params.colDef.pivotKeys[0]
      ? params.colDef.pivotKeys[0].toString().split(' ').join('-')
      : '';
  let rowpath = params.node && params.node.key ? params.node.key.split(' ').join('_') : '';
  colpath = colpath.replace(/[^a-zA-Z0-9]/g, '_');
  rowpath = rowpath.replace(/[^a-zA-Z0-9]/g, '_');
  let identifier = 'selector-' + rowpath + '-' + colpath + '' + params.node.rowIndex;
  let foundIndex = foundRecordFromSource(kagamiCrossTabViewModel, params, identifier);
  let rowIndex = params.node.rowIndex;


  let existsArr =  filter(kagamiCrossTabViewModel.crossTabInfo.clickedActions, { 'foundIndex': foundIndex, 'rowIndex': rowIndex });
  let existsRowIndexes =  filter(kagamiCrossTabViewModel.crossTabInfo.clickedActions, { 'rowIndex': rowIndex })
  if (existsArr && existsArr.length > 0) {
     remove(kagamiCrossTabViewModel.crossTabInfo.clickedActions, { 'foundIndex': foundIndex, 'rowIndex': rowIndex });

  } else if (existsRowIndexes && existsRowIndexes.length > 0) {
    //now remove existing foundIndex and add newFoundex for it 
    let removedRowRec: any =  remove(kagamiCrossTabViewModel.crossTabInfo.clickedActions, { 'rowIndex': rowIndex });
    if (removedRowRec && removedRowRec.length > 0) {
      manipulateClickAction(kagamiCrossTabViewModel, removedRowRec[0]['foundIndex'], removedRowRec[0]['identifier']); //removed existing same row selected one 
    }
    kagamiCrossTabViewModel.crossTabInfo.clickedActions.push({
      'foundIndex': foundIndex,
      'rowIndex': rowIndex,
      'identifier': identifier
    });
  } else {
    kagamiCrossTabViewModel.crossTabInfo.clickedActions.push({
      'foundIndex': foundIndex,
      'rowIndex': rowIndex,
      'identifier': identifier
    });
  }
  manipulateClickAction(kagamiCrossTabViewModel, foundIndex, identifier);

}

/**  Selectable only one record for a click , and in a row only one record should be selectable,
*  If already selected record then it should unselectable after click 
*  **/
const manipulateClickAction = function (kagamiCrossTabViewModel: any, foundIndex: any, identifier: any) {
  let detailedObjData = kagamiCrossTabViewModel.detailedObjects[kagamiCrossTabViewModel.mainEntityId];

  let foundRow = detailedObjData[foundIndex];
  foundRow.$$checked = !foundRow.$$checked;
  let cellcls = identifier || '';
  if (foundRow.$$checked) {
    // $scope.selectedRow=foundRow;
    let kgmPivotsCells = document.getElementsByClassName(cellcls);
     forEach(kgmPivotsCells, function (eachCell: any) {
      eachCell.classList.add('kgm-pivot-cell-select');
    });
  } else {
    let kgmPivotsCells = document.getElementsByClassName(cellcls);
     forEach(kgmPivotsCells, function (eachCell: any) {
      eachCell.classList.remove('kgm-pivot-cell-select');
    });
  }
  // $scope.$parent.vm.onGridCheckClick(undefined,foundRow,true)

    if (foundIndex != -1) {
      let selectedRecords = getCrossTabRecords(kagamiCrossTabViewModel,detailedObjData) || [];
      console.log("selectedRecords-------> ",selectedRecords);
      getKagamiListModel().selectedObjects = selectedRecords;
      reBuildTriggers();
    } 
}
const getCrossTabRecords = (kagamiCrossTabViewModel:any,detailedObjData:any) => {
  let crossTabRecords = [];
  if(kagamiCrossTabViewModel?.crossTabInfo?.clickedActions){
    let selectedIndexs =  map(kagamiCrossTabViewModel?.crossTabInfo?.clickedActions,'foundIndex');
    crossTabRecords=  filter(detailedObjData,function(item,index) { return selectedIndexs.indexOf(index) !=-1  })
  }
  return crossTabRecords;
}

const highLightSelectedRecord = (kagamiCrossTabViewModel: any) => {
  if (kagamiCrossTabViewModel?.crossTabInfo?.clickedActions) {
     forEach(kagamiCrossTabViewModel.crossTabInfo.clickedActions, (action) => {
      if (action?.identifier) {
        let identifierArrays = document.getElementsByClassName(action.identifier);
        if (identifierArrays && identifierArrays.length) {
           forEach(identifierArrays, (item: any) => {
            if (item.classList) {
               item.classList.add('kgm-pivot-cell-select')
            }
          })
        }
      }
    })
  }
}