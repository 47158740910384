import { createState } from "@hookstate/core"
import makeStyles from '@mui/styles/makeStyles';
import { KagamiListFilterModel } from "../../../model/KagamiListFilterModel"
import { KagamiClearButtonProps, KagamiClearFiltersButtonModel, useClearFilterButtonModelState } from "../model/ClearFiltersModel"
import { handleClearFilters } from "../controller/KagamiClearFiltersController"
import Typography from "@mui/material/Typography"
import { kagamiClearFilterButtonstyle } from "../styles/ClearButtonStyle"
import ClearIcon from '@mui/icons-material/Clear';

export function buildClearButton(clearFilterButtonModel: KagamiClearFiltersButtonModel, kagamiListFilterModel: KagamiListFilterModel ){ 
    return <KagamiClearFilterButton key={"way-to-clearfilter"} clearFilterButtonModel={clearFilterButtonModel} kagamiListFilterModel={kagamiListFilterModel} />
  }
  
  
  
  function KagamiClearFilterButton(props: KagamiClearButtonProps ){
  
    props.clearFilterButtonModel.state  = useClearFilterButtonModelState(createState(props.clearFilterButtonModel));
    const classes = makeStyles(kagamiClearFilterButtonstyle)();
  
// props.clearFilterButtonModel.state = listFilterAppliedState
  
    return( 
    <>
     {props.clearFilterButtonModel.state.isEnabled
      ? 
        <div 
        className={classes.root}
        onClick= {() => handleClearFilters(props.kagamiListFilterModel)}
      >
        <ClearIcon  fontSize="small" color="error" />
        <Typography variant="caption" color="error">
        Clear Filters
        </Typography>
      </div> 
    : <></>}
         
    </>
    )
  } 
 