import { useState, forwardRef, useImperativeHandle } from "react";
import { extractDropdownOption,getEntityConsumedInAttribute } from "../../../../../../service/PresentationService";
import { CommonUtils } from "../../../../../../../../../../../../utils/CommonUtils";
import { getValueForGridRadio, setActiveRowNode } from "../../service/GridService";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { getLovRecords } from "../../../ListBuilder";
import { KagamiStateManager } from "../../../../../../../../../../../../../state/KagamiStateManager";
 

export default forwardRef((props: any, ref: any) => {
  // props.setnewHeight(60)
  setActiveRowNode(props,props.gridModel)
  let selectedValue = getValueForGridRadio(props.node, props.attributePresentation, props.gridModel)
  const [value, setValue] = useState<any>(CommonUtils.isNotEmpty(selectedValue) ? selectedValue['id'] : '');
  const {rowData} = props;
  // console.log('came to radio editor first')

  const loveRecordsData: any = getLovRecords(getEntityConsumedInAttribute(props.attributePresentation), props.gridModel);
  const [options, setOptions] = useState<any>(loveRecordsData)
  KagamiStateManager.addStateToHolder(props.gridModel.mainEntityId + '.' + props.gridRadioModel.attributeName, setOptions);
  function onChangeHandler(e: any, value: any) {
    if(CommonUtils.isEmpty(props.gridModel.activeRowNode)){
      props.gridModel.activeRowNode = props.node;
    }
    props.node.data[props.column.colId] = value
    props.onCellValueChange(props.gridModel, props.node, props.attributePresentation.onChangeRequired, props.column.colId)
    setValue(value);
  }
 
  useImperativeHandle(ref, () => {
    console.log('imperative handler')
      return {
          getValue: () => {
              console.log('imperative handler in value')
              return value;
          },
          afterGuiAttached: () => {
              console.log('imperative handler in gui')
              let radioValue : any = getValueForGridRadio(props.node, props.attributePresentation, props.gridModel)
              if(radioValue){
                setValue(radioValue.id)
              }
          },
          refreshEditor:()=>{
            let radioValue : any = getValueForGridRadio(props.node, props.attributePresentation, props.gridModel)
            setValue(CommonUtils.isNotEmpty(radioValue) ? radioValue.id : '');
          }
      };
  });
  const classes = props.classes
  console.log('options', options)
  return ( 
    <>
    <RadioGroup
      row
      value={value} 
      onChange={onChangeHandler}
    >
        {CommonUtils.isNotEmpty(options) ? (
          options.map((option: any, index: any) => (
            <FormControlLabel className="radioInGrid" style={{fontSize: '14px'}}
              key={option['id']} 
              value={option['id']} 
              control={<Radio size="small" />} 
              label={extractDropdownOption(props.attributePresentation,option)}  
              />
            ))
          ) : (
            <></>
          )}
        </RadioGroup>  
        </>
    );
})
