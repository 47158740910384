import { State, useState } from "@hookstate/core";
import { KagamiStateManager } from "../../../../../../../../../../../../../../state/KagamiStateManager";

export class KagamiGridPaginationModel{
    isForwardEnable : boolean;
    isBackwardEnable : boolean;
    totalRecords : any;
    startRecord : any;
    endRecord : any;
    state : any;
    activePageIndex : any;
    mainEntityId : string;
    processName : string;
    entityPresentation : any;

    constructor(isForwardEnable : boolean,isBackwardEnable : boolean,totalRecords : any,startRecord : any,endRecord : any,
        mainEntityId : string,processName : string,entityPresentation : any){
        this.isForwardEnable = isForwardEnable;
        this.isBackwardEnable = isBackwardEnable;
        this.totalRecords = totalRecords;
        this.startRecord = startRecord;
        this.endRecord = endRecord;
        this.state = null;
        this.activePageIndex = 1;
        this.mainEntityId = mainEntityId;
        this.processName = processName;
        this.entityPresentation = entityPresentation
    }
}

export interface KagamiGridPaginationProps{
    paginationModel : KagamiGridPaginationModel
}

export const wrapKagamiGridPaginationState = (state: State<KagamiGridPaginationModel>) => {
    let paginationState = state;
  
    return {
    //   getListLoaded() {
    //     return listState.isListLoaded.get();
    //   },
    //   setListLoaded(value: boolean) {
    //     listState.isListLoaded.set(value);
    //   }
    getForwardEnabled(){
        return paginationState.isForwardEnable.get();
    },
    setForwardEnabled(value : boolean){
        paginationState.isForwardEnable.set(value);
    },
    getBackwardEnabled(){
        return paginationState.isBackwardEnable.get();
    },
    setBackwardEnabled(value : boolean){
        paginationState.isBackwardEnable.set(value);
    }
    };
  };
  
  export const useKagamiGridPaginationState = (state: State<KagamiGridPaginationModel>,kagamiPaginationIdentifier : string) => {
    const kagamiGridPaginationState = wrapKagamiGridPaginationState(useState(state));
    KagamiStateManager.addStateToHolder(kagamiPaginationIdentifier, kagamiGridPaginationState);
    return kagamiGridPaginationState;
  };