import { hideLoader, showLoader } from '../../../../../../components/loader/controller/KagamiLoaderController';
import { SystemConstants } from '../../../../../constants/SystemConstants';
import { showServerErrorPopUp } from '../../../../../controller/KagamiApplicationController';
import { APIService } from '../../../../../service/ApiService';
import { getDashBoardURL, getUserUrl } from '../../../../../service/URLService';
import { CommonUtils } from '../../../../../utils/CommonUtils';
import { setNavigationTransalationData, setUserProfileData } from '../../header/controller/KagamiPageHeaderController';
import { rebuildNavigator } from '../controller/KagamiNavigatorController';
import { KagamiNavigatorModel } from '../model/KagamiNavigatorModel';

export function getNavigationData(navModel: KagamiNavigatorModel) {
  showLoader();
  APIService.getData(SystemConstants.POST, getDashBoardURL(), {})
    .then((navData: any) => {
      hideLoader();
      navModel.navigationData = navData.data;
      if(CommonUtils.isNotEmpty(navData.data.error)){
       showServerErrorPopUp(JSON.parse(navData.data.error)['constructError'])
      }
      else{
        setNavigationTransalationData(navModel);
        rebuildNavigator(navModel);
      }
    })
    .catch(() => {
      hideLoader();
      // navModel.failedMessage = VisibleConstants.UNEXPECTED_ERROR;
      // navModel.state.setDashboardLoaded(true);
    });
}

export function getUserData(navModel: KagamiNavigatorModel) {
  APIService.getData(SystemConstants.GET, getUserUrl(), {}).then(
    (response: any) => {
      navModel.userData = response.data
      setUserProfileData(response.data)
      }
  ).catch((error:any) =>console.log(`${error.message}`));
}

 
export function getLeftPanel(dashboard: any[]): any[] {
  const panel = getPanel(SystemConstants.LEFT_PANEL, dashboard);
  return panel?.menuPanel?.menuItems || [];
}

export function getFavoritesPanel(dashboard: any):any[]{
  const favouritespanel = dashboard?.favourites;
  return favouritespanel;
}

export function getMasterDataPanel(dashboard: any[]): any[] {
  const panel = getPanel(SystemConstants.MASTER_DATA, dashboard);
  if (panel) {
    return panel.menuPanel.menuItems;
  }
  return panel;
}

export function getUserDataPanel(dashboard: any[]): any[] {
  const panel = getPanel('userRole', dashboard);
  if (panel) {
    return panel.menuPanel.menuItems;
  }
  return panel;
}
export function getPanel(panelType: string, dashboard: any) {

  const panels: any[] = dashboard[SystemConstants.CONTAINER];
  if(panels){
  for (const panel of panels) {
    if (panel[SystemConstants.PANEL_TYPE] === panelType) {
      return panel;
    } 
  }
}
}
