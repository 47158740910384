import { State, useState } from '@hookstate/core';

export class PinnedReportsModel {
  state: any;
  dbReportList: any[];
  dbRolesList: any[];
  dbRoleReportMapping: any[];
  isDataLoaded: boolean;
  allSelectedData: any[];
  setDisableSave:any;
  constructor() {
    this.dbReportList = [];
    this.dbRolesList = [];
    this.dbRoleReportMapping = [];
    this.isDataLoaded = false;
    this.allSelectedData = [];
  }
}

export const usePinnedReportState = (state: State<PinnedReportsModel>) => {
  const pinnedReportState = useState(state);
  return {
    get isDataLoaded() {
      return pinnedReportState.isDataLoaded.get();
    },

    setDataLoaded(isDataLoaded: boolean) {
      pinnedReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};

export interface PinnedReportsModelProps {
  pinnedReportsModel: PinnedReportsModel;
  translations?: any;
}
