export class KagamiProcessRequestModel {
  processName: string;
  processStepName: string | null;
  fromDashboard: boolean;
  submitCall: boolean;
  data: any;
  bulkUploadProperties: any;
  onSuccess: Function;
  onError: Function;
  templateType: string;
  isGroupTemplateNetworkCall : boolean;
  changeFor : any;
  isToastMessageHide: boolean;
  isUiInput : boolean;
  runTimeInput?: any;
  isPickItemCall : boolean;
  isModalResponse: boolean;
  previousProcessName : string;
  onKanbannClose : any;
  linkId : string;
  embeddedInfo : any;

  constructor(processName: string, onSuccess: Function, onError: Function) {
    this.processName = processName;
    this.processStepName = null;
    this.fromDashboard = false;
    this.submitCall = false;
    this.onSuccess = onSuccess;
    this.onError = onError;
    this.templateType = '';
    this.data = new Map();
    this.bulkUploadProperties = {}
    this.isGroupTemplateNetworkCall = false;
    this.changeFor = {}
    this.isToastMessageHide = true;
    this.isUiInput = false;
    this.runTimeInput = {};
    this.isPickItemCall = false;
    this.isModalResponse =false;
    this.previousProcessName = ''
    this.onKanbannClose = null;
    this.linkId = '';
  }
}
