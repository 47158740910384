import { State, useState } from '@hookstate/core';
import { KagamiProfileModel } from '../../home/builder/header/builder/profile/model/KagamiProfileModel';
import { KagamiModalModel } from '../../../../components/modal/model/KagamiModalModel';
import { KagamiStateManager } from '../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../constants/SystemConstants';

export class KagamiImpersonateModel {
  userId: string;
  errorMsg: string;
  state: any;
  kagamiProfileModel: KagamiProfileModel;
  kagamiModalModel: KagamiModalModel;
  content: any;
  userDataForProfile: any;

  constructor() {
    this.userId = '';
    this.errorMsg = "";
    this.state = '';
    this.kagamiProfileModel = new KagamiProfileModel(this.content, this.userDataForProfile);
    this.kagamiModalModel = new KagamiModalModel();
  }
}

export interface KagamiImpersonateModelProps {
  impersonateModel: KagamiImpersonateModel;
  impersonateModelPopupModel: KagamiModalModel;
}

export const wrapKagamiInpasinateState = (state: State<KagamiImpersonateModel>) => {
  let impersonateModelState = state;

  return {
    get userId() {
      return impersonateModelState.userId.get();
    },

    setUserId(userId: any) {
      impersonateModelState.userId.set(userId);
    },

    get error() {
      return impersonateModelState.errorMsg.get();
    },

    setError(errorMsg: any) {
      impersonateModelState.errorMsg.set(errorMsg);
    }
  };
};

export const useKagamiInpasinateState = (state: State<KagamiImpersonateModel>) => {
  let impersonateState = wrapKagamiInpasinateState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.IMPERSONATE_STATE, impersonateState);
  return impersonateState;
};
