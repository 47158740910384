import { State, useState } from '@hookstate/core';
import { KagamiReportModel } from '../../../../model/KagamiReportModel';

export class AreaReportModel extends KagamiReportModel {
  reportData: any;
  isDataLoaded:boolean;
  state: any;
  constructor(reportContent: any) {
    super()
    this.isDataLoaded = false;
    this.reportData = reportContent;
  }
}
export interface AreaReportModelProps {
  areaReportModel: AreaReportModel;
}
export const useAreaReportModelState = (state: State<AreaReportModel>) => {
  let areaReportState = useState(state);
  return {
    reportConfig() {
      areaReportState.reportData.get();
    },
    setReportConfig(reportContent: any) {
      areaReportState.reportData.set(reportContent);
    },

    get isDataLoaded(){
      return areaReportState.isDataLoaded.get()
    },

    setDataLoaded(isDataLoaded:boolean){
      areaReportState.isDataLoaded.set(isDataLoaded);
    }
  };
};
