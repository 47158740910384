import { State, useState } from "@hookstate/core";

export class KagamiMenuModel {
  build: boolean;
  favourite: boolean;
  event: any;
  groupName: string;
  name: string;
  type: string;

  state: any;
  isActiveMenu: boolean;
  isFavourite: boolean;
  parents: string[]=[];
  constructor(build: boolean, favourite: boolean, event: any, groupName: string, name: string, type: string,parents:string[]=[]) {
    this.build = build;
    this.favourite = favourite;
    this.event = event;
    this.groupName = groupName;
    this.name = name;
    this.type = type;
    this.isFavourite = favourite;

    this.isActiveMenu = false;
    this.parents= parents;

  }
}
export interface KagamiMenuModelProps {
  kagamiMenuModel: KagamiMenuModel;
  translations: any
}

export const useMenuState = (state: State<KagamiMenuModel>) => {
  let menustate = useState(state);

  return {
    get isActiveMenu() {
      return menustate.isActiveMenu.get();
    },

    SetIsActiveMenu(value: boolean) {
      menustate.isActiveMenu.set(value);
    },

    get isFavourite() {
      return menustate.isFavourite.get();
    },
    SetIsFavourite(value: boolean) {
      menustate.isFavourite.set(value);
    }
  };
};
