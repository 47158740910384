import React, { useEffect } from 'react';
import { Button, FormLabel, IconButton, TextField, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ToWords } from 'to-words';
import { SystemConstants } from '../../../../../../../../../../constants/SystemConstants';
import { CommonUtils } from '../../../../../../../../../../utils/CommonUtils';
import { getFormModel } from '../../../form/controller/KagamiFormController';
import { commaSeparatedValue } from '../../../form/service/FormService';
import { validateNumberBox } from '../controller/KagamiNumberController';
import { KagamiNumberFieldProps, KagamiNumberModel, useKagamiNumberModelState } from '../model/KagamiNumberModel';
import { KagamiNumberStyle } from '../style/KagamiNumberStyle';
import { NumericFormat } from 'react-number-format';
import { bulidIconsForCurrencySymbols, FeedBackControl, getCurrencyIcon } from '../builder/KagamiNumberBulider';
import { executeDisplayCondition } from '../../../../service/PolicyExecutionService';
import { APIService } from '../../../../../../../../../../../service/ApiService';
import { VisibleConstants } from '../../../../../../../../../../constants/VisibeConstants';
import SyncIcon from '@mui/icons-material/Sync';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { decimalScale, decimalsSeparator, getLocaleCodeCurrency, thousandsSeparator, translateToLocalistaion } from '../../../../service/PresentationService';
import { getActiveModel } from '../../../../controller/KagamiProcessController';
import { delay } from 'lodash';

export const BulidNumberField = (kagamiNumberModel: KagamiNumberModel) => {
  return <KagamiNumberField_Custom key={kagamiNumberModel.controlIdentifier} kagamiNumberModel={kagamiNumberModel} />;
};

const useStyles: any = makeStyles(KagamiNumberStyle);
export const KagamiNumberField_Custom = (props: KagamiNumberFieldProps) => {
  const classes = useStyles();
  let weighBridgeDestroyedCall : boolean = false;
  let fieldState = useKagamiNumberModelState(props.kagamiNumberModel.createState);
  props.kagamiNumberModel.state = fieldState;
  getFormModel().formControlStates[props.kagamiNumberModel.controlIdentifier] = fieldState;
  const uiSettingsClassName = props.kagamiNumberModel.getControlWidth(props.kagamiNumberModel.uiSettings);
  const fontProperties = props.kagamiNumberModel.getFontStyling(props.kagamiNumberModel.uiSettings);
  const fieldAlignment = CommonUtils.isNotEmpty(props?.kagamiNumberModel?.uiSettings?.fieldAlign)
    ? props?.kagamiNumberModel?.uiSettings?.fieldAlign
    : 'left';
  let activeModel = getActiveModel();
  let translations = activeModel?.onStartData?.constructOutputData?.uiResource?.otherProperties?.translation;

  const handleOnOkForWeighBridge = () => {
    if (props.kagamiNumberModel.extDataEnabled) {
      clearInterval(props.kagamiNumberModel.intervalId);
      let destroyApiDetails: any = props.kagamiNumberModel.uiSettings['externalData']['destroyApiDetails'];
      let destroyUrl: string = destroyApiDetails['endPoint'];
      APIService.getData(SystemConstants.GET, destroyUrl, {}).then((response: any) => {
        if (CommonUtils.isNotEmpty(response.data['status']) && response.data['status'] === 'success') {
          weighBridgeDestroyedCall = true;
          props.kagamiNumberModel.state.setWeighBridgeValue(props.kagamiNumberModel.controlValue);
          validateNumberBox(props.kagamiNumberModel, props.kagamiNumberModel.controlValue);
        }
      });
    } else {
      props.kagamiNumberModel.state.setWeighBridgeValue(props.kagamiNumberModel.controlValue);
      validateNumberBox(props.kagamiNumberModel, props.kagamiNumberModel.controlValue);
    }
  };

  async function getExternalData(openUrl: string) {
    APIService.getData(SystemConstants.GET, openUrl, {})
      .then((response: any) => {
        if (response.data['status'] === 'success') {
          props.kagamiNumberModel.extDataEnabled = true;
          let apiDetails: any = props.kagamiNumberModel.uiSettings['externalData']['apiDetails'];
          props.kagamiNumberModel.intervalId = setInterval(() => {
            let readUrl: string = apiDetails['endPoint'];
            APIService.getData(SystemConstants.GET, readUrl, {}).then((apiResponse: any) => {
              if (CommonUtils.isNotEmpty(apiResponse.data['value'])) {
                props.kagamiNumberModel.state.setControlValue(apiResponse.data['value']);
              }
            });
          }, 1000);
        } else {
          props.kagamiNumberModel.extDataEnabled = false;
          props.kagamiNumberModel.state.setErrorMessage('Source is not reachable..');
        }
      })
      .catch((error: any) => {
        props.kagamiNumberModel.extDataEnabled = false;
        props.kagamiNumberModel.state.setErrorMessage('Source is not reachable..');
      });
  }

  useEffect(() => {
    delay(() => {
      if (props.kagamiNumberModel.hasExternalData && !props.kagamiNumberModel.readonly) {
        let externalData = props.kagamiNumberModel.uiSettings['externalData'];
        let initApiDetails: any = externalData['initApiDetails'];
        getExternalData(initApiDetails['endPoint']);
      }
    }, 1000);
    return () =>{
      if(props.kagamiNumberModel.hasExternalData && !props.kagamiNumberModel.readonly && !weighBridgeDestroyedCall){
        clearInterval(props.kagamiNumberModel.intervalId);
        let destroyApiDetails: any = props.kagamiNumberModel.uiSettings['externalData']['destroyApiDetails'];
        let destroyUrl: string = destroyApiDetails['endPoint'];
        APIService.getData(SystemConstants.GET, destroyUrl, {}).then((response: any) => {
          if (CommonUtils.isNotEmpty(response.data['status']) && response.data['status'] === 'success') {
            console.log('weigh bridge reading connection is closed')
          }
        });
      } 
    }
  }, props.kagamiNumberModel.hasExternalData ? [fieldState.isReadOnly]:[]);

  let dispConditions: any = {};
  if (props.kagamiNumberModel?.policyMap?.presentation) {
    dispConditions = executeDisplayCondition(fieldState, props.kagamiNumberModel);
  }

  let dispCurrency = dispConditions['currency-attribute'];
  let valueFormat = props.kagamiNumberModel?.currencyFormat;

  let newCurrencyLocalCode = getLocaleCodeCurrency(dispCurrency);
  const toWords = new ToWords({
    localeCode: newCurrencyLocalCode,
    // localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });


  let currencySymbolArr = [
    'Indian Rupee',
    'Rupee',
    'rupee',
    'Rupees',
    'rupees',
    'INR',
    'GHC',
    "<i class='fa fas fa-rupee-sign' aria-hidden='true'></i>"
  ];
  let isCurrencySymbol = false;
  if (dispCurrency) {
    isCurrencySymbol = true; //(_.indexOf(currencySymbolArr,dispCurrency )!=-1) ? true:false;
    dispCurrency = dispCurrency == "<i class='fa fas fa-rupee-sign' aria-hidden='true'></i>" ? 'Rupee' : dispCurrency;
  } else if (fieldState.atttributeSettings?.['currency-attribute']) {
    isCurrencySymbol = true;
    dispCurrency = props.kagamiNumberModel.$$attrSettings['currency-attribute'];
  }

  /** here some time selected is coming as string and some time its come as an array */
  if (
    !dispCurrency &&
    props?.kagamiNumberModel?.uiSettings?.prefixType?.value == 'Currency' &&
    typeof props?.kagamiNumberModel.uiSettings.prefixType.selected != 'object'
  ) {
    dispCurrency =
      props?.kagamiNumberModel.uiSettings.prefixType.selected ===
      "<i class='fa fas fa-rupee-sign' aria-hidden='true'></i>"
        ? 'Rupee'
        : props?.kagamiNumberModel.uiSettings.prefixType.selected;
  }
  const debounceCallForOnSearch = React.useCallback(
    CommonUtils._debounceFtn(onSearchCallForSearchFeild, props.kagamiNumberModel.isCommaSeparated ? 850 : 400),
    []
  );

  const userOnKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let formattedCode = props.kagamiNumberModel?.currencyFormat;
    if (
      formattedCode === "#,##,###" ||
      formattedCode === "#,###" ||
      formattedCode === "#.###" ||
      formattedCode === "# ###"
    ) {
      if (e.key === ".") {
        e.preventDefault();
      }
    }
  };

  function onSearchCallForSearchFeild(controlvalueData: string) {
    validateNumberBox(props.kagamiNumberModel, controlvalueData);
  }
  if (fieldState.isVisible === false) {
    return <></>;
  } else {
    return (
      <div className={`${uiSettingsClassName} ${classes.root}`}>
        {fieldState.isReadOnly === false ? (
          props.kagamiNumberModel.hasExternalData ? (
            <>
              <div style={{ position: 'relative' }}>
                <FormControl fullWidth>
                  <FormLabel
                    component="span"
                    required={fieldState.isMandatory}
                    style={{
                      color: fontProperties.labelFontColor,
                      fontWeight: fontProperties.labelFontBold,
                      fontStyle: fontProperties.labelFontItalic,
                      textDecoration: fontProperties.labelFontUnderline
                    }}
                  >
                    {props.kagamiNumberModel.controlDisplayName}
                  </FormLabel>

                  <NumericFormat
                    fullWidth
                    sx={{
                      '& .MuiInputLabel-root ': {
                        color: fontProperties.labelFontColor,
                        fontWeight: fontProperties.labelFontBold,
                        fontStyle: fontProperties.labelFontItalic,
                        textDecoration: fontProperties.labelFontUnderline
                      },
                      '& .MuiInputLabel-root.Mui-focused ': {
                        color: fontProperties?.labelFontColor,
                        fontWeight: fontProperties?.labelFontBold,
                        fontStyle: fontProperties?.labelFontItalic,
                        textDecoration: fontProperties?.labelFontUnderline
                      },
                      '& .MuiOutlinedInput-root': {
                        // backgroundColor: fontProperties.controlBackgroundColor ,
                        'backgroundColor': props?.kagamiNumberModel?.$$attrSettings?.color
                          ? props?.kagamiNumberModel?.$$attrSettings?.color
                          : fontProperties.controlBackgroundColor,
                        'color': fontProperties.controlFontColor + ' ' + '!important',
                        'fontWeight': fontProperties.controlFontBold,
                        'fontStyle': fontProperties.controlFontItalic,
                        'textDecoration': fontProperties.controlFontUnderline,
                        '& .MuiOutlinedInput-input': {
                          textAlign: fieldAlignment
                        }
                      }
                    }}
                    customInput={TextField}
                    error={CommonUtils.isNotEmpty(fieldState.errorMessage)}
                    allowNegative
                    inputProps={{ readOnly: CommonUtils.isNotEmpty(fieldState.errorMessage) }}
                    required={fieldState.isMandatory}
                    size="small"
                    name={props.kagamiNumberModel.controlIdentifier}
                    // allowLeadingZeros
                    // fixedDecimalScale
                    onKeyDown={userOnKeyHandler}
                    thousandSeparator={props.kagamiNumberModel.isCommaSeparated ? thousandsSeparator(valueFormat) : false}
                    decimalSeparator={props.kagamiNumberModel.isCommaSeparated ? decimalsSeparator(valueFormat) : '.'}
                    decimalScale={props.kagamiNumberModel.isCommaSeparated ? decimalScale(valueFormat) : 20}
                    // decimalScale={9}
                    autoSave="off"
                    autoComplete="off"
                    disabled={true}
                    onChange={(event: any) => {
                      fieldState.setControlValue(event.target.value);
                    }}
                    value={fieldState.controlValue}
                    id={props.kagamiNumberModel.controlIdentifier}
                    // label={props.kagamiNumberModel.controlDisplayName}
                    variant="outlined"
                    InputProps={{
                      readOnly: fieldState.isReadOnly,
                      startAdornment: isCurrencySymbol ? (
                        bulidIconsForCurrencySymbols({ prefixType: { code: dispCurrency } })
                      ) : props.kagamiNumberModel.iscurrencySymbol ? (
                        bulidIconsForCurrencySymbols(props.kagamiNumberModel.uiSettings)
                      ) : (
                        <></>
                      )
                    }}
                  />
                </FormControl>
                <IconButton
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '21px',
                    right: '2px',
                    backgroundColor: '#05263d',
                    color: '#fff',
                    borderRadius: '5px'
                  }}
                  onClick={() => getExternalData('')}
                >
                  <SyncIcon fontSize="small" />
                </IconButton>
              </div>
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={(event: any) => handleOnOkForWeighBridge()}
                  startIcon={<CheckOutlinedIcon fontSize="small" />}
                  className={classes.model_button}
                >
                  {/* {VisibleConstants.okLabel} */}
                  {translateToLocalistaion(translations, VisibleConstants.okLabel)}
                </Button>
                <br />
                <TextField
                  fullWidth
                  size="small"
                  inputProps={{ readOnly: CommonUtils.isNotEmpty(fieldState.errorMessage) }}
                  autoSave="off"
                  autoComplete="off"
                  id={props.kagamiNumberModel.controlIdentifier}
                  value={fieldState.weighBridgeValue}
                  error={CommonUtils.isNotEmpty(fieldState.errorMessage)}
                  helperText={fieldState.errorMessage}
                  variant="outlined"
                />
              </div>
            </>
          ) : props.kagamiNumberModel.isFeedBackControl ? (
            FeedBackControl(props.kagamiNumberModel, classes, fontProperties)
          ) : (
            <>
              <FormControl fullWidth>
                <FormLabel
                  component="span"
                  required={fieldState.isMandatory}
                  style={{ color: fontProperties.labelFontColor }}
                >
                  {props.kagamiNumberModel.controlDisplayName}
                </FormLabel>

                <NumericFormat
                  fullWidth
                  sx={{
                    '& .MuiInputLabel-root ': {
                      color: fontProperties.labelFontColor,
                      fontWeight: fontProperties.labelFontBold,
                      fontStyle: fontProperties.labelFontItalic,
                      textDecoration: fontProperties.labelFontUnderline
                    },
                    '& .MuiInputLabel-root.Mui-focused ': {
                      color: fontProperties?.labelFontColor,
                      fontWeight: fontProperties?.labelFontBold,
                      fontStyle: fontProperties?.labelFontItalic,
                      textDecoration: fontProperties?.labelFontUnderline
                    },
                    '& .MuiOutlinedInput-root': {
                      // backgroundColor: fontProperties.controlBackgroundColor ,
                      'backgroundColor': props?.kagamiNumberModel?.$$attrSettings?.color
                        ? props?.kagamiNumberModel?.$$attrSettings?.color
                        : fontProperties.controlBackgroundColor,
                      'color': fontProperties.controlFontColor + ' ' + '!important',
                      'fontWeight': fontProperties.controlFontBold,
                      'fontStyle': fontProperties.controlFontItalic,
                      'textDecoration': fontProperties.controlFontUnderline,
                      '& .MuiOutlinedInput-input': {
                        textAlign: fieldAlignment
                      }
                    }
                  }}
                  customInput={TextField}
                  allowNegative
                  disabled={fieldState.isReadOnly}
                  required={fieldState.isMandatory}
                  size="small"
                  error={fieldState.errorMessage ? true : false}
                  name={props.kagamiNumberModel.controlIdentifier}
                  // allowLeadingZeros
                  // decimalScale={9}
                  onKeyDown={userOnKeyHandler}
                  // fixedDecimalScale
                  thousandSeparator={props.kagamiNumberModel.isCommaSeparated ? thousandsSeparator(valueFormat) : false}
                  decimalSeparator={props.kagamiNumberModel.isCommaSeparated ? decimalsSeparator(valueFormat) : '.'}
                  decimalScale={props.kagamiNumberModel.isCommaSeparated ? decimalScale(valueFormat) : 20}
                  autoSave="off"
                  autoComplete="off"
                  value={
                    CommonUtils.isEmpty(fieldState.controlValue) && props.kagamiNumberModel.isHavedefaultValue
                      ? CommonUtils.isEmpty(fieldState.controlValue) &&
                        fieldState.setControlValue(props.kagamiNumberModel.attributePresentation?.defaultValue)
                      : fieldState.controlValue
                  }
                  id={props.kagamiNumberModel.controlIdentifier}
                  // label={props.kagamiNumberModel.controlDisplayName}
                  helperText={fieldState.errorMessage}
                  variant="outlined"
                  onValueChange={(values: any) => {
                    if(values.formattedValue == '-0') {
                      return;
                    }
                    if (values.formattedValue !== '-' && values.formattedValue !== '.') {
                      if (values.formattedValue === SystemConstants.EMPTY) {
                        // fieldState.setControlValue(0);
                        debounceCallForOnSearch('0');
                      } else if (values.formattedValue === '0') {
                        fieldState.setControlValue(values.formattedValue);
                        debounceCallForOnSearch(fieldState.controlValue);
                      } else if (values.formattedValue.charAt(0) === '-' && values.formattedValue.length === 1) {
                        fieldState.setControlValue(values.formattedValue);
                      } else if (values.formattedValue.includes('.')) {
                        let fieldStateValue = values.formattedValue.split('.');
                        if (fieldStateValue[1] !== '') {
                          debounceCallForOnSearch(values.formattedValue);
                        }
                      } else {
                        if (values.value.length < 16) {
                          fieldState.setControlValue(values.formattedValue);
                          debounceCallForOnSearch(fieldState.controlValue);
                        } else {
                          fieldState.setErrorMessage('More than 16 digits not allowed');
                        }
                      }
                    }
                  }}
                  onFocus={(e: any) => {
                    let value: string = e.target.value;
                    if (value === '0') {
                      fieldState.setControlValue('');
                    }
                  }}
                  InputProps={{
                    readOnly: fieldState.isReadOnly,
                    startAdornment: isCurrencySymbol ? (
                      bulidIconsForCurrencySymbols({ prefixType: { code: dispCurrency } })
                    ) : props.kagamiNumberModel.iscurrencySymbol ? (
                      bulidIconsForCurrencySymbols(props.kagamiNumberModel.uiSettings)
                    ) : (
                      <></>
                    )
                  }}
                />
              </FormControl>
            </>
          )
        ) : (
          <div className={classes.readOnlyField_cont}>
            <FormLabel
              component="span"
              className={classes.controlLabel}
              style={{ color: fontProperties.labelFontColor }}
              required={fieldState.isMandatory}
            >
              {' '}
              {props.kagamiNumberModel.controlDisplayName}
            </FormLabel>
            {fieldState.controlValue === null ? (
              <p className={classes.readOnlyField}>
                {' '}
                <span>{getCurrencyIcon(dispCurrency)}</span> 0{' '}
              </p>
            ) : fieldState.controlValue === undefined || fieldState.controlValue === '' ? (
              <div>
                <p className={classes.readOnlyField} style={{ textAlign: fieldAlignment }}>
                  ----
                </p>
              </div>
            ) : (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {getCurrencyIcon(dispCurrency)}
                  <span
                    className={classes.readOnlyField}
                    style={{ color: fontProperties.controlFontColor, marginLeft: '5px' }}
                  >
                    {props.kagamiNumberModel.isCommaSeparated &&
                    !props.kagamiNumberModel.isconversion &&
                    fieldState.controlValue.toString() !== '0'
                      ? commaSeparatedValue(fieldState.controlValue, props)
                      : fieldState.controlValue}
                  </span>
                </div>

                {CommonUtils.isNotEmpty(props.kagamiNumberModel.uiSettings) &&
                props.kagamiNumberModel.uiSettings.hasOwnProperty('inWords') && props.kagamiNumberModel.uiSettings.inWords ? (
                  <p className={classes.toWordsStyle} style={{ color: fontProperties.controlFontColor }}>
                   {'('+ toWords.convert(fieldState.controlValue).replaceAll(" Dollars" , '') +')'}
                  </p>
                ) : (
                  <></>
                )}

                {fieldState.errorMessage ? (
                  <span style={{ color: '#f05252', fontSize: '12px' }}>{fieldState.errorMessage}</span>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
};
