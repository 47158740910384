import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const KagamiProcessPageStyle = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      // boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
    },
    mainAppRoot: {
      color:theme.palette.info.main,
      height:'100%',
      '& .MuiFormLabel-root ': {
        fontSize: '13px', 
        fontWeight : 'normal',
        // color : '#000', 
       }, 
      '& .MuiOutlinedInput-root': {   
        backgroundColor:'#f5f8fa',
        '&:hover fieldset': {
          borderColor:theme.palette.secondary.main
        },
        '&.Mui-error.Mui-focused  fieldset':{
          // boxShadow: '0 0 3px 2px rgb(198 40 40 / 25%) !important',
          borderColor:`${theme.palette.error.main} !important`,

         },  
         '&.Mui-focused fieldset': {  
           borderWidth:'2px !important', 
           // boxShadow: '0 0 3px 2px rgb(0 51 102 / 25%)',
          //  boxShadow:' rgba(0,145,174, 0.15) 0px 5px 15px 0px !important',
          // borderColor: 'rgba(0,208,228,.5)',
          // boxShadow: '0 0 4px 1px rgb(0 208 228 / 30%), 0 0 0 1px #00d0e4',
          borderColor:theme.palette.secondary.main
         },
      },
      '& textarea' : {
        backgroundColor:'#f5f8fa',
        fontFamily:"'Lexend deca',sans-serif",
        borderColor:'rgba(0, 0, 0, 0.23)',
        borderRadius:'4px',
        padding:'5px 10px',
        maxWidth:'100%',
        minWidth: '100%',
        '&:hover': {
          borderColor:theme.palette.secondary.main
        },
      },
      '& textarea:focus':{ 
        minHeight:' 61px',
        outlineColor:theme.palette.secondary.main,
        outline:'1px !important',
        // borderColor:' red',
        // boxShadow:' 0 0 10px #719ECE',
        borderWidth:'1px !important', 
        borderColor:theme.palette.secondary.main,
        

    }
    },
    process_headername_and_searchfilter: {
      display: 'flex',
      background: theme.palette.background.defaultBlue,
      // width:'100%',
      alignItems: 'center', 
      margin: theme.spacing(1)
    },
    process_headername: {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(2.25),
      fontWeight: 600, 
      marginLeft: '4px !important',
      display: 'flex', 
      gap:theme.spacing(1),
      alignItems: 'center',

      '& h6': {
        marginBottom  :'0'
      },
      
    },
    process_searchfilter:{
      display:'flex',
      marginLeft: 'auto'
      // justifyContent:'space-between',   
    },
    processContent: {
      width: '100%',
      height: '100%',
      // backgroundColor: theme.palette.background.default
      background: theme.palette.background.defaultBlue,

    }
  });
