import * as React from 'react';
import { createState } from '@hookstate/core';
import {
  HierarchyListReportModel,
  KagamiHierarchyListReportModelProps,
  useHierarchyListReportModelState
} from '../model/HierarchyListModel';
import { AgGridReact } from 'ag-grid-react';
import { Button, ButtonGroup, Menu, MenuItem, Grid, CircularProgress } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { buildReportModal } from '../../../../../ReportModal/controller/ReportModalController';
import { ReportModalModel } from '../../../../../ReportModal/model/ReportModalModel';
import { makeHierarchyReportData } from '../service/HierarchyListService';
import { downloadExcel, downloadPDF } from '../service/HierarchyListReportExportService';

export const buildHierarchyListView = (listReportModel: HierarchyListReportModel) => {
  return <HierarchyListView key={'tree-data-list-report-view'} listReportModel={listReportModel}></HierarchyListView>;
};
export const HierarchyListView = (props: KagamiHierarchyListReportModelProps) => {
    // const [printPdfAnchorEl, setPrintPdfAnchorEl] = React.useState<null | HTMLElement>(null);
    // const printPotraitOpen = Boolean(printPdfAnchorEl);
  const gridRef: any = React.useRef(null);
    // const printPdfHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //   setPrintPdfAnchorEl(event.currentTarget);
    // };
    // const printPdfHandleClose = () => {
    //   setPrintPdfAnchorEl(null);
    // };

  let listReportModel = props.listReportModel;
  let listState = useHierarchyListReportModelState(createState(listReportModel));
  listReportModel.state = listState;
  const reportModalModel = new ReportModalModel();

  React.useEffect(() => {
    getReportDataForDynamicReports(props.listReportModel);
  }, []);

  const autoGroupColumnDef = React.useMemo(() => {
    console.log(gridRef.current)
    return {
      headerName: `${listReportModel['treeColumn']}`,
      cellRendererParams: {
        suppressCount: true
      },
    };
  }, []);

  // const getMainMenuItems = (params: any) => {
  //   const defaultItems = params.defaultItems.slice(); // Copy the default menu items
  //   console.log('defaultItems : ', defaultItems);
  //   const customItems = defaultItems.filter(
  //     (item: any) =>
  //       item !== 'pinSubMenu' &&
  //       item !== 'resetColumns' &&
  //       item !== 'autoSizeThis' &&
  //       item !== 'autoSizeAll' &&
  //       item !== 'separator'
  //   );

  //   return customItems;
  // };
  
  const getDataPath = React.useMemo(() => {
    return (data: any) => {
      return data[listReportModel['treeColumn']];
    };
  }, []);

  if (!listState.isDataLoaded) {
    return <CircularProgress sx={{ marginTop: '4%', marginLeft: '48%' }} color="secondary" />;
  } else {
    if (listReportModel.reportData) {
        const onDownloadExcel = () => {
          // const options = {
          //   fileName: getReportName(listReportModel) || 'Report',
          // }
          // gridRef.current!.api.exportDataAsExcel(options);
          downloadExcel(reportConfig,props.listReportModel,gridRef);
        };
        // const onDownloadPDF = (type: any) => {
        //   setPrintPdfAnchorEl(null);
        //   downloadPDF(type,reportConfig,props.listReportModel);
        // };

      const reportConfig: any = makeHierarchyReportData(listReportModel);
      return (
        <div className="ag-theme-alpine kgm-report-aggrid" style={{height:'inherit'}}>
          {buildReportModal(reportModalModel)}
          <Grid
            className="hide-buttons-from-desktop-report-container"
            container
            style={{ margin: '10px', textAlign: 'right' }}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
        <ButtonGroup className="report-rtl-btngroup" variant="text" aria-label="text button group">
          <Button  variant="contained" onClick={() => { onDownloadExcel() }}>
            Download Excel
          </Button>
            {/* <div>
              <Button
                id="printPotrait-button"
                variant="contained"
                onClick={printPdfHandleClick}
                >
                Download PDF
                <ArrowDropDownIcon />
              </Button>
              <Menu
                  id="printPotrait-menu"
                  anchorEl={printPdfAnchorEl}
                  open={printPotraitOpen}
                  onClose={printPdfHandleClose}
                >
                <MenuItem onClick={() => onDownloadPDF('potrait')}>Potrait </MenuItem>
                <MenuItem onClick={() => onDownloadPDF('landscape')}>Land Scape</MenuItem>
              </Menu>
            </div> */}
          </ButtonGroup>
        </Grid>
          </Grid>
          {/* TABLE DISPLAY */}
          <Grid className='kgm-report-hw dashboard-report'>
          <AgGridReact
            ref={gridRef}
            suppressDragLeaveHidesColumns={true}
            columnDefs={reportConfig.columnDefs}
            defaultColDef={reportConfig.defaultColDef}
            getRowHeight={reportConfig.getRowHeight}
            suppressFieldDotNotation={reportConfig.suppressFieldDotNotation}
            rowData={reportConfig.rowData}
            autoGroupColumnDef={autoGroupColumnDef}
            sideBar={reportConfig.sideBar}
            icons={reportConfig.icons}
            treeData={true}
            getDataPath={getDataPath}
            // getMainMenuItems={getMainMenuItems}
            groupDefaultExpanded={reportConfig.groupDefaultExpanded}
          ></AgGridReact>
          </Grid>
          <div style={{ position: 'absolute', opacity: '0.0' }}>
            <div id="printDiv" style={{ display: 'none' }}></div>
          </div>
        </div>
      );
    } else {
      return <p className="NoDataFound">No Data Found</p>;
    }
  }
};
