import { State, useState } from "@hookstate/core";
import { KagamiReportModel } from "../../../../model/KagamiReportModel";

export class FunnelReportModel extends KagamiReportModel {
    reportData:any;
    isDataLoaded:boolean;
    state:any;
    constructor(reportContent: any) {
        super()
        this.reportData = reportContent;
        this.isDataLoaded = false;
      }
}

export interface FunnelReportModelProps{
    funnelReportModel:FunnelReportModel
}

export const useFunnelReportModelState = (state:State<FunnelReportModel>) => {

    let funnelReportState = useState(state);

    return {
        reportConfig() {
          funnelReportState.reportData.get();
        },
        setReportConfig(reportContent: any) {
          funnelReportState.reportData.set(reportContent);
        },
    
        get isDataLoaded(){
          return funnelReportState.isDataLoaded.get()
        },
    
        setDataLoaded(isDataLoaded:boolean){
          funnelReportState.isDataLoaded.set(isDataLoaded);
        }
      };
}