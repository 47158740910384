import { KagamiDateModel } from '../model/kagamiDateModel'; 
import TimeField from './TimeField';
import DateField from './DateField'
import DateTimeField from './DateTimeField';



interface KagamiDateFieldProps {
  kagamiDateModel: KagamiDateModel;
} 

const KagamiDateField = (props: KagamiDateFieldProps) => {
  

  // if (fieldState.isVisible === false) {
  //   return <></>;
  // } 

  // else { 
    
    switch (props.kagamiDateModel.controlType){
 
      case 'date': 

      return (
        <>
        <DateField kagamiDateModel = {props.kagamiDateModel}/>
        </>
      ) 
      case 'time': 
      return (
        <>
        <TimeField kagamiDateModel = {props.kagamiDateModel}/>
        </>
      )
      case 'dateTime': 
      return (
        <>
        <DateTimeField kagamiDateModel = {props.kagamiDateModel}/>
        </>
      )
      default:
        return <div>{props.kagamiDateModel.controlType}: this type not implemented </div>;
    }
 
  // } 
};

export default KagamiDateField;
