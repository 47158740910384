import { createState } from '@hookstate/core';
import React from 'react';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { CircularProgress } from '@mui/material';
import { FunnelReportModel, FunnelReportModelProps, useFunnelReportModelState } from '../model/funnelReportModel';
import { makeFunnel } from '../service/funnelReportService';

import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
// import Highcharts3d from "highcharts/highcharts-3d.js";
// import Cylinder from "highcharts/modules/cylinder.js";
// import Funnel from "highcharts/modules/funnel3d";
// import ReactHighcharts from 'react-highcharts';
import Funnel from 'highcharts/modules/funnel';
Funnel(Highcharts);


export const BuildFunnelReportView = (funnelReportModel: FunnelReportModel) => {
  return (
    <KagamiFunnelChartReportView key={'funnel-chart-report'} funnelReportModel={funnelReportModel}></KagamiFunnelChartReportView>
  );
};


const KagamiFunnelChartReportView = (props: FunnelReportModelProps) => {
  let funnelReportModel = props.funnelReportModel;
  const chartComponent:any = React.useRef(null);
  let funnelChartState = useFunnelReportModelState(createState(funnelReportModel));
  funnelReportModel.state = funnelChartState;
  
  React.useEffect(() => {
    getReportDataForDynamicReports(funnelReportModel)
  }, [])

  // Highcharts3d(Highcharts);
  // Cylinder(Highcharts);
  // Funnel(Highcharts);

  const containerRef = React.useCallback((node:any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      chartComponent.current?.chart.reflow();
    });
    resizeObserver.observe(node);
  }, []);

  if(!funnelChartState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" /> 
  }else{
    if(funnelReportModel.reportData){
  const funnelConfig :any = makeFunnel(funnelReportModel);

  return (
    <div ref={containerRef} className="ag-theme-alpine dashboard-highchart kgm-report-hw">
      <Chart ref={chartComponent} highcharts={Highcharts} options={funnelConfig} />
    </div>
  );
}else{
  return <p className='NoDataFound'>No Data Found</p>
}
}
};
