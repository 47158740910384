import { KagamiDashboardModel } from '../model/KagamiDashboardModel';
import { buildDashboardComponent } from '../ui/KagamiDashboard';

let kagamiDashboardModel:KagamiDashboardModel;
export function buildDashboard(dashboardModel: KagamiDashboardModel) {
  kagamiDashboardModel = dashboardModel;
  return buildDashboardComponent(dashboardModel);
}
export function rebuildDashboard(dashboardModel: KagamiDashboardModel){
  dashboardModel.state.setDataLoaded(true);
}

export function getIdForLayoutPage(pageName:string){
  let cobj = kagamiDashboardModel.layoutContainer?.find((data:any)=>data.pageName === pageName);
  return cobj?.id ?? null;
}