import { KagamiAttachmentsModalModel } from "../model/KagamiAttachmentsModalModel";
import { buildAttachmentsModal } from "../ui/AttachmentsModal";


 

export function buildFileAttachmentsModal(attachmentsModalModel: KagamiAttachmentsModalModel) {
    return buildAttachmentsModal(attachmentsModalModel);
  }

 export function  handleOnOk(attachmentsModalModel : KagamiAttachmentsModalModel) {
    
    attachmentsModalModel.state.setOpen(false);
      
  }

  export function  handleOnCancel(attachmentsModalModel : KagamiAttachmentsModalModel) {
    attachmentsModalModel.state.setOpen(false);
    
  }
