
import { State, useState } from "@hookstate/core";
import { KagamiMenuModel } from "./KagamiMenuModel";

export class KagamiFolderModel {
    build: boolean;
    favourite: boolean;
    img: any;
    name: string;
    type:  string ;
    subMenus:any[];
    currentMenuModel: KagamiMenuModel | null;
    state: any;
    isActiveFolder :boolean;
    isParentFolder: boolean;
    searchProcessName: string;
    parents: string[] = [];
    activeSubFolderLevel:any
    constructor(build: boolean,favourite: boolean, image:any,folderName: string, subFolders: any,type :string,isActiveFolder:boolean=false,parents:string[]=[]) {
        this.build = build;
        this.favourite = favourite;
        this.img = image
        this.name = folderName;
        this.type  =type;
        this.subMenus = subFolders;

        this.isActiveFolder = isActiveFolder;
        this.isParentFolder = false;
        this.searchProcessName = ''
        this.currentMenuModel = null;
        this.parents= parents;
        this.activeSubFolderLevel = 0
    }
  }
  export interface KagamiFolderModelProps {
    kagamiFolderModel: KagamiFolderModel; 
    favouriteData:any;
    isActiveMenu: boolean;
    translations: any
  }
  
  export const useFolderState = (state: State<KagamiFolderModel>) => {
    let folderstate = useState(state);
  
    return {
     get isActiveFolder() {
        return folderstate.isActiveFolder.get();
     },
     SetIsActiveFolder(value :boolean) {
        folderstate.isActiveFolder.set(value)
     },

    //  get isParentFolder() {
    //     return folderstate.isParentFolder.get();
    //   },

    //   SetParentFolder(value: boolean) {
    //     folderstate.isParentFolder.set(value);
    //   },
      get searchProcessName(){
        return folderstate.searchProcessName.get()
      },

      SetSearchProcessName(value:string){
        folderstate.searchProcessName.set(value)

      },

      // get currentMenuModel(){
      //   return folderstate.currentMenuModel.get()
      // },
      // SetCurrentMenuModel(value: KagamiMenuModel){
      //   return folderstate.currentMenuModel.set(value)
      // },
     
    };
  };
  
  