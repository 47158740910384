import { buildTriggerModel, onTriggerClick } from '../../Triggers/service/KagamiTriggersService';
import { handleServerError, loadGroupContent } from '../controller/KagamiGroupController';
import { KagamiGroupModel } from '../model/KagamiGroupModel';

let kagamiGroupModel: KagamiGroupModel;

export const getGroupModel = () => {
  return kagamiGroupModel;
};

export const getActiveTabContent = (activeTabNumber: number, groupModel: KagamiGroupModel) => {
  kagamiGroupModel = groupModel;
  let defaultTrigger = groupModel.triggers[activeTabNumber];
  if (defaultTrigger !== undefined) {
    let triggerModel = buildTriggerModel(groupModel.mainEntityId, defaultTrigger, loadGroupContent, false);
    groupModel.kagamiTriggerModel = triggerModel;
    onTriggerClick([], triggerModel, handleServerError, loadGroupContent, true);
    groupModel.previousProcessName = triggerModel.processName;
  }
};

export const lookForDefualtTab = (groupModel: KagamiGroupModel) => {
  kagamiGroupModel = groupModel;
  let defaultTrigger = groupModel.triggers.find((e: any) => e.defaultProcessOfGroup === true);
  if(defaultTrigger === undefined) defaultTrigger = groupModel.triggers[0]
    let triggerModel = buildTriggerModel(groupModel.mainEntityId, defaultTrigger, loadGroupContent, false);
    groupModel.kagamiTriggerModel = triggerModel;
    onTriggerClick([], triggerModel, handleServerError, loadGroupContent, true);
    groupModel.previousProcessName = triggerModel.processName;
};
