export class MapRolesViewModel{
    name:string;
    options: any[];
    selectedData:any[];
    roleMappedWithReportId:any[];
    reportId : string;
    allSelectedData:any;
    constructor(){
        this.name = '';
        this.options = [];
        this.selectedData = [];
        this.roleMappedWithReportId=[];
        this.reportId = '';
    }
}




export interface MapRolesViewModelProps{
    mapRolesViewModel:MapRolesViewModel;
    translations?:any;
}