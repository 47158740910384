import { getDmsViewUrl } from '../../../../../../../../../../../../service/URLService';
import { buildDms } from '../../../../../../../process/builder/dms/controller/kagamiDMSController';
import { KagamiDMSModel } from '../../../../../../../process/builder/dms/model/kagamiDMSModel';
import { buildLedgerDetails } from '../../ledgerDetails/controller/LedgerDetailsController';
import { LedgerDetailsModel } from '../../ledgerDetails/model/LedgerDetailsModel';
import { DayBookReportModel } from '../model/DayBookReportModel';
import { BuildDayBookReportView } from '../ui/DayBookReport';

let dayBookReport: DayBookReportModel;

export function buildDayBookReport(dayBookReportModel: DayBookReportModel) {
    dayBookReport = dayBookReportModel
    return BuildDayBookReportView(dayBookReportModel);
  }

export const handleOnLedgerClick = (ledgerInputs:any,row:any) => {
  if(row.data !== undefined){
    if(row.data['otherDeatils']!==undefined && row.data['otherDeatils']['file']!==undefined){
      let kagamiDMSModel:KagamiDMSModel = new KagamiDMSModel();
      kagamiDMSModel.dmsResponseUrl = getDmsViewUrl(row.data['otherDeatils']['file']);
      dayBookReport.modelPopup.title = 'preview file';
      dayBookReport.modelPopup.maxWidth = 'xl';
      dayBookReport.modelPopup.content = buildDms(kagamiDMSModel);
      dayBookReport.modelPopup.pdfIconVisible = false;
      dayBookReport.modelPopup.state.setOpen(true);
    }else{
      const ledgerDetailsModel = new LedgerDetailsModel();
      ledgerDetailsModel.ledgerInputs = ledgerInputs;
      dayBookReport.modelPopup.title = dayBookReport.reportConfig.reportConfigSummary.name;
      dayBookReport.modelPopup.content = buildLedgerDetails(ledgerDetailsModel);
      dayBookReport.modelPopup.pdfIconVisible = true;
      dayBookReport.modelPopup.state.setOpen(true);
    }
  }
}