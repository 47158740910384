import { KagamiEmbeddedTabsPresentationModel } from "../model/KagamiEmbeddedTabsPresentationModel";
import { BuildEmbeddedTabsPresentation } from "../ui/KagamiEmbeddedTabsPresentation";


let embeddedTabsPresentationModel : KagamiEmbeddedTabsPresentationModel ;

export function  getEmbeddedTabsPresentationModel(){
    return embeddedTabsPresentationModel;
}

export function  setEmbeddedTabsPresentationModel(tabsModel:KagamiEmbeddedTabsPresentationModel){
     embeddedTabsPresentationModel=tabsModel;
}




export function buildEmbeddedTabsPresentation(embeddedTabsPresentationModel : KagamiEmbeddedTabsPresentationModel){
    setEmbeddedTabsPresentationModel(embeddedTabsPresentationModel)
    return BuildEmbeddedTabsPresentation(embeddedTabsPresentationModel);
}

export function getActiveTabIndex(embedFormModels : any[]){
    for(let i=0;i<embedFormModels.length;i++){
        if(embedFormModels[i].visible){
            return i;
        }
    }
}