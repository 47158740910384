import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
export const KagamiTriggersStyle = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            backgroundColor: 'transparent',
            maxHeight: '35px',
            marginLeft:'4px',
          }
    })