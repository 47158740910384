import makeStyles from '@mui/styles/makeStyles';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { ContextType } from '../builder/trigger/model/KagamiTriggerModel';
import { KagamiTriggersModel, TriggersModelProps } from '../model/KagamiTriggersModel';
import { KagamiTriggersStyle } from '../style/KagamiTriggersStyle';

const useStyles = makeStyles(KagamiTriggersStyle);
export function buildTriggers(triggersModel: KagamiTriggersModel) {
  return <KagamiTriggers key={'triggers'} kagamiTriggersModel={triggersModel} />;
}
export const KagamiTriggers = (props: TriggersModelProps) => {
  let classes = useStyles(); 
  const sortedArr = props.kagamiTriggersModel.triggerComponents.reduce((acc, element) => {
    if (SystemConstants.PRIMARY_TRIGGERS.includes(element.props.kagamiTriggerModel.triggerName)) {
      return [element, ...acc];
    }
    return [...acc, element];
  }, []); 
  return (
    <div key={'trigers'} className={classes.root} style={{paddingLeft: "4px"}}>
      {sortedArr.map((element:any, index:any) => {
        if(props.kagamiTriggersModel.viewType === 'kanban'){
          if(element.props.kagamiTriggerModel.contextType === ContextType.None){
            return <div key={index}>{element}</div>;
          }
          else{
            return <div></div>;
          }
        }
        else{
          return <div key={index}>{element}</div>;
        }
      })}
    </div>
  );
};
