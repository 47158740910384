import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { KagamiAttachmentsModalModel, KagamiAttachmentsModalModelProps, useKagamiModalModelState } from '../model/KagamiAttachmentsModalModel';
import { handleOnCancel } from '../controller/AttachmentsModalController';
import { KagamiAttachmentsModalStyle } from '../style/AttachmentsModalStyle';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import ClearIcon from '@mui/icons-material/Clear'; 
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getFileData, deleteSingleFile , deleteDocument , handleImageChange, deleteAllFile} from '../../../service/KagamiFileService';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Upload_Doc from '../../../../../../../../../../../../../assets/images/custom-icons/multi_doc_upload.svg';
import { KagamiFileModel } from '../../../model/KagamiFileModel';



export function buildAttachmentsModal(attachmentsModalModel:KagamiAttachmentsModalModel ) {
    return <AttachmentsModal  kagamiAttachmentsModalModel = {attachmentsModalModel} /> 
}
function AttachmentsModal(props:KagamiAttachmentsModalModelProps) {
  let kagamiFileModel  = props.kagamiAttachmentsModalModel.kagamiFileModel
 

    let modalState: any = useKagamiModalModelState(createState(props.kagamiAttachmentsModalModel));
    props.kagamiAttachmentsModalModel.state = modalState;
    const classes = makeStyles(KagamiAttachmentsModalStyle)()
  
    
    const handleClose = (event:any, reason:any) => {
    if (reason && reason == "backdropClick") 
        return;
        handleOnCancel(props.kagamiAttachmentsModalModel);
  }
  // let uploadedFiles = getFileData(kagamiFileModel)

  
    return (
        <Dialog 
          fullWidth={true}
          scroll={'paper'}
          maxWidth={'sm'}
          open={modalState.isOpen}
          onClose={handleClose}
          classes={{
            root: classes.AttachmentsModalroot,
            paper:  '',
            scrollPaper:  '',
            paperFullWidth:  '',
          }} 
          
        >
          {/* {
            CommonUtils.isNotEmpty(props.kagamiAttachmentsModalModel.kagamiFileModel) 
            ? <AttachmentCardsBasedOnFormModel  classes={classes} kagamiFileModel = {kagamiFileModel}   attachmentsModel ={props.kagamiAttachmentsModalModel} />
            : <AttachmentsBasedOnGridData  classes={classes} thumbnailData ={props.kagamiAttachmentsModalModel.kagamiGridAttachemntsData}  attachmentsModel ={props.kagamiAttachmentsModalModel}/>

          } */}


{
            CommonUtils.isNotEmpty(props.kagamiAttachmentsModalModel.kagamiFileModel) 
            ? <AttachmentCardsByFormModel attachmentsModel ={props.kagamiAttachmentsModalModel} />
            : <AttachmentsBasedOnGridData  classes={classes} thumbnailData ={props.kagamiAttachmentsModalModel.kagamiGridAttachemntsData}  attachmentsModel ={props.kagamiAttachmentsModalModel}/>

          }
        </Dialog>
      );
}

export default AttachmentsModal


function AttachmentCardsBasedOnFormModel (props:any) {
  const {classes, kagamiFileModel,  attachmentsModel} =props;
  const fieldState = kagamiFileModel.state;
  let popupModel = kagamiFileModel.popupModel
  let uploadedFiles = getFileData(kagamiFileModel)
  let docInfoData  = CommonUtils.isNotEmpty(kagamiFileModel.docInfo) ? kagamiFileModel.docInfo : {}; //using document data from onStartdata


  const thumbNailForFields = (thumbNailsForReadOnly: any, controlValues:any) => {
    let thumbnails = JSON.parse(JSON.stringify(thumbNailsForReadOnly));
    
    return  controlValues.map((fileString:any, i:any) => {
      let fileId  =  fileString.split(":")[0];
      let fileName=fileString.split(":")[1];
      let fileType= fileString.split(".").pop() ;
      let baseUrl = '';
      //  let uploadedFileObj =  uploadedFiles.find((file:any) => file.filename === fileString)
      let filename = fileName?.split('.')[0];;
      let image = thumbnails.find((item: any) => item?.data == fileId);
      let imageFromDocInfo = CommonUtils.isNotEmpty(docInfoData) && CommonUtils.isNotEmpty( docInfoData[fileId]) ? docInfoData[fileId] : {} ;
      let uploadedFileObj =  uploadedFiles.find((file:any) => file.filename === fileString)
 
      if(CommonUtils.isNotEmpty(imageFromDocInfo)) {
       fileName = imageFromDocInfo['fileName'];
       fileType = imageFromDocInfo['fileType'] ;
       filename = fileName?.split('.')[0];
       baseUrl = docInfoData[fileId]['thumbnail'] ;
       fileString = fileId + ':' + fileName  ;
     } else {
      //  fileName = ' ';
      //  filename = fileName?.split('.')[0];
      //  fileType = ' ' ;
       fileString = fileId    
     }

     
      
    

     return(
      <>
      
     
    <div style={{width:'calc((100% - 20px)/ 3)', position:'relative' ,  height:'190px', display:'flex', flexDirection:'column', borderRadius:'5px'}}>
   {( CommonUtils.isNotEmpty(image) && image.hasOwnProperty('thumbnail')) || (CommonUtils.isNotEmpty(imageFromDocInfo) && imageFromDocInfo.hasOwnProperty('thumbnail')) 
   
   ? 
<div style={{height:'140px',   background:'#838999', border:'1px solid #ebebeb',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >
        <img alt={fileName} title={fileName}  style={{width:'100%', height:'100%'}}
            src={CommonUtils.isNotEmpty(baseUrl) ? `data:image/png;base64,${baseUrl}` : `data:image/png;base64,${image?.thumbnail}`}

            onClick={(event: any) => {

              CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel,   popupModel, fileString);
            }}
            /> 
    </div>
  : <div style={{height:'140px',   background:'#838999',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >
  <div style={{width:'58px', height:'76px' , background:'white', borderRadius:"5px", display:'flex', alignItems:'center', justifyContent:'center'}}>
      <img alt={fileName} title={fileName}  style={{width:'58px', height:'58px' }}
       src={ CommonUtils.getFileTypeICon(fileType)}
      onClick={(event: any) => {

        CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel,  popupModel, fileString);
      }}
      />
  </div>
</div>

  }
    
    <div style={{height:"50px",  textAlign:'left', padding:'10px 0 10px 0'}}>
        <div style={{display:'flex', gap:'10px', justifyContent:'space-between'}}>
          <div style={{ width:'80%', display:'inline-flex', alignItems:'center', fontSize:'12px', fontWeight:'500'}}>

            <p style={{margin:'0',
               whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}> {filename}</p> <span>{'.' + fileType}</span>
           
          </div>
          <IconButton size='small'
           onClick={(event: any) => {
            CommonUtils.downloadFile(fileId, fileName)
            // CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel, popupModel, fileString);
          }}>
          <CloudDownloadOutlinedIcon
             color='success'
            style={{ width: '20px', height: '20px'}}
           
           
          />

          </IconButton>
        </div>
    </div>
    {!kagamiFileModel.state.isReadOnly  
     //showing delete only for non-readonly
     && <div
    title="Del image"
    style={{
      position: 'absolute',
      top: '-9px',
      right: '-7px',
      cursor: 'pointer',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid red',
      borderRadius: '50%'
    }}
  >
    <ClearIcon
      sx={{
        fontSize: '13px'
      }}
      fontSize="small"
      color="error"
      onClick={() => {
        deleteSingleFile( 
          uploadedFileObj,
          kagamiFileModel
        );
      }}
    />
    </div>
  
  
  
  }
   
    </div>
     
      </>
     )
    })
  };
  return (
    <>


<div className={classes.AttachmentsModalTitle}>
                 {! kagamiFileModel.state.isReadOnly && uploadedFiles.length > 0
                 //showing delete all for only non-readOnly
                 && <Button variant = 'outlined'
                 color='error' 
                 size="small"  startIcon = {<DeleteSweepOutlinedIcon fontSize='small' />}
                 onClick={() =>deleteAllFile( kagamiFileModel)}
                 >Delete all</Button>
 
                }  
                <DialogTitle style={{padding:'5px 100px 5px 10px'}}> 
                    {attachmentsModel.attrName}  
                </DialogTitle>
                <IconButton size='small'  onClick={() => handleOnCancel(attachmentsModel)}>
                    <ClearIcon fontSize='medium'></ClearIcon>
                    </IconButton>
            </div>
          <DialogContent dividers className={classes.AttachmentsModalContent}>
          <div style={{height:'100%', width:'100%'}}>
            <div style={{ display:'flex', gap:'10px', flexWrap:'wrap'}}>
            {CommonUtils.isNotEmpty(attachmentsModel.controlValues) 

            ? 
            <>
            {thumbNailForFields(attachmentsModel.thumbnails,
             attachmentsModel.controlValues)}


               
            </>

             :
             <div style={{minHeight:'200px', display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center', width:'100%'}}>
              <h4 style={{color:'#838999', fontWeight:'normal', marginBottom:'10px'}}>NO FILES SELECTED</h4>
              <div>
            <input
                id={kagamiFileModel.controlIdentifier+'input'}
                type="file"
                style={{ display: 'none', marginTop: '3PX' }}
                onChangeCapture={(event) => {
                  handleImageChange(event, kagamiFileModel);
                  event.currentTarget.value = ''
                }}
                onSuspend={(event) => {
                  handleImageChange(event, kagamiFileModel);
                  event.currentTarget.value = ''
                }}
                multiple
              />
          <label htmlFor={kagamiFileModel.controlIdentifier+'input'} style={{ fontSize: '12px', padding: '5px', position:'relative', display:'flex', alignItems:'center', gap:'10px' }}>
                <img style={{ width: 80, height: 80, marginTop: '3px' , cursor:'pointer'}} alt="Upload" src={Upload_Doc}  /> 
                {/* <p style={{margin:'0'}}>Upload files</p> */}
            </label>  
      </div>
               </div > }
            </div>
          </div>
          <Typography color='error' variant='caption' >{ fieldState.errorMessage}</Typography>

          </DialogContent>
          <DialogActions className={classes.AttachmentsModalActions} >
            <div>


             {!kagamiFileModel.state.isReadOnly ? 
             
             ` ${uploadedFiles.length}  Files Selected`
            :
            `Showing ${uploadedFiles.length}  Files `
            
            }
            </div>
            <div>
              
             {!kagamiFileModel.state.isReadOnly
             //showing upload only for non-readonly
             && 
            //  <div style={{width:'calc((100% - 20px)/ 3)', position:'relative' ,  height:'190px', display:'flex', flexDirection:'column', borderRadius:'5px'}}>
            //  <div style={{height:'140px',   background:'#f5f8fa', border:'1px solid #ebebeb',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >   
             <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100%'}}>
         <input
             id={kagamiFileModel.controlIdentifier+'input'}
             type="file"
             style={{ display: 'none', marginTop: '3PX' }}
             onChangeCapture={(event) => {
               handleImageChange(event, kagamiFileModel);
               event.currentTarget.value = ''
             }}
             onSuspend={(event) => {
               handleImageChange(event,kagamiFileModel);
               event.currentTarget.value = ''
             }}
             multiple
           />
       <label htmlFor={kagamiFileModel.controlIdentifier+'input'} style={{ fontSize: '12px', padding: '5px 0', position:'relative', display:'flex', alignItems:'center', gap:'10px' }}>
             <img style={{ width: 30, height: 30 , cursor:'pointer'}} alt="Upload" src={Upload_Doc}  /> <p style={{margin:'0'}}>Add files</p>
            
         </label>  
   </div>
//  </div>

// </div>
             
            
            }
            </div>

          </DialogActions>
    </>
  )
}

function AttachmentsBasedOnGridData (props:any) {
  console.log(props.thumbnailData);
  const {classes, attachmentsModel, thumbnailData} = props;
  // let attrName =  getAttributeLabel(thumbnailData.attributePresentation) 
  let popupModel = thumbnailData.popupModel
  const controlValues = CommonUtils.isNotEmpty(thumbnailData.value) ? thumbnailData.value.split(',') : [];
  const nonEmptyControlValues = CommonUtils.isNotEmpty(controlValues) ? controlValues.filter((item:any) => item) :[]
  let docInfoData  = CommonUtils.isNotEmpty(thumbnailData.gridModel.docInfo) ? thumbnailData.gridModel.docInfo: {}; //using document data from onStartdata



  const thumbNailForFields = (thumbNailsForReadOnly: any, controlValues:any) => {
    let thumbnails = JSON.parse(JSON.stringify(thumbNailsForReadOnly));
     let controlValuedocids:any[] =[] ;
      controlValues.map((item:any) => {
        if (item.includes('.')){
          controlValuedocids.push( item.split(':')[0] ) ;
        } else {
          controlValuedocids.push(item)
        }
      })
   
    return  controlValuedocids.map((fileString:any, i:any) => {
      let fileId  =  fileString.split(":")[0];
      let fileName=''
      let fileType= ''
      let baseUrl = ''; 
      let filename ='';
     
     let imageFromDocInfo = CommonUtils.isNotEmpty(docInfoData) && CommonUtils.isNotEmpty( docInfoData[fileId]) ? docInfoData[fileId] : {} ;

     if(CommonUtils.isNotEmpty(imageFromDocInfo)) {
      fileName = imageFromDocInfo['fileName'];
      fileType = imageFromDocInfo['fileType'] ;
      filename = fileName?.split('.')[0];
      baseUrl = docInfoData[fileId]['thumbnail'] ;
      fileString = fileId + ':' + fileName  ;
    } else {
       
      fileString = fileId 
    }
     return(
      <>
      
     
    <div style={{width:'calc((100% - 20px)/ 3)', position:'relative' ,  height:'190px', display:'flex', flexDirection:'column', borderRadius:'5px'}}>
   {( CommonUtils.isNotEmpty(baseUrl) )
   
   ? 
<div style={{height:'140px',   background:'#838999', border:'1px solid #ebebeb',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >
        <img alt={fileName} title={fileName}  style={{width:'100%', height:'100%'}}
            src={ `data:image/png;base64,${baseUrl}`  }

            onClick={(event: any) => {

              CommonUtils.handlePreviewOrDownloadFileInGrid( attachmentsModel.attrName,   popupModel, fileString);
            }}
            /> 
    </div>
  : <div style={{height:'140px',   background:'#838999',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >
  <div style={{width:'58px', height:'76px' , background:'white', borderRadius:"5px", display:'flex', alignItems:'center', justifyContent:'center'}}>
      <img alt={fileName} title={fileName}  style={{width:'58px', height:'58px' }}
       src={ CommonUtils.getFileTypeICon(fileType)}
      onClick={(event: any) => {

        CommonUtils.handlePreviewOrDownloadFileInGrid(attachmentsModel.attrName,   popupModel, fileString);
      }}
      />
  </div>
</div>

  }
    
    <div style={{height:"50px",  textAlign:'left', padding:'10px 0 10px 0'}}>
        <div style={{display:'flex', gap:'10px', justifyContent:'space-between'}}>
          <div style={{ width:'80%', display:'inline-flex', alignItems:'center', fontSize:'12px', fontWeight:'500'}}>

            <p style={{margin:'0',
               whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}> {filename}</p> <span>{'.' + fileType}</span>
           
          </div>
          <IconButton size='small'
           onClick={(event: any) => {
            CommonUtils.downloadFile(fileId, fileName)
            // CommonUtils.handlePreviewOrDownloadFileInGrid( attachmentsModel.attrName,   popupModel, fileString);
          }}>
          <CloudDownloadOutlinedIcon
             color='success'
            style={{ width: '20px', height: '20px'}}
           
           
          />

          </IconButton>
        </div>
    </div>
     
   
    </div>
     
      </>
     )
    })
  };


  return (
    <>


    <div className={classes.AttachmentsModalTitle}>
                     
                    <DialogTitle style={{padding:'5px 100px 5px 10px'}}> 
                        {attachmentsModel.attrName}  
                    </DialogTitle>
                    <IconButton size='small'  onClick={() => handleOnCancel(attachmentsModel)}>
                        <ClearIcon fontSize='medium'></ClearIcon>
                        </IconButton>
                </div>
              <DialogContent dividers className={classes.AttachmentsModalContent}>
              <div style={{height:'100%', width:'100%'}}>
                <div style={{ display:'flex', gap:'10px', flexWrap:'wrap'}}>
               
                {thumbNailForFields([],
                 nonEmptyControlValues)}
    
    
                 
                   
                </div>
              </div>
              {/* <Typography color='error' variant='caption' >{ fieldState.errorMessage}</Typography> */}
    
              </DialogContent>
              <DialogActions className={classes.AttachmentsModalActions} >
                 <p style={{margin:'10px 0'}}>
             {` ${nonEmptyControlValues.length}  Files Selected`}
                 </p>
    
              </DialogActions>

              {/* {buildModal(popupModel)} */}
        </>
  )
}





function AttachmentCardsByFormModel(props:any) {
  const {attachmentsModel} = props;
  let kagamiFileModel = attachmentsModel.kagamiFileModel
  
  const classes = makeStyles(KagamiAttachmentsModalStyle)();

  let controlValue = CommonUtils.isNotEmpty(kagamiFileModel.controlValue) ?  kagamiFileModel.controlValue.split(',') : []
 let nonEmptyControlValues = controlValue.filter((item:any) => item) ;
  let controlValuedocids:any[] =[] ;
  nonEmptyControlValues.map((item:any) => {
      if (item.includes('.')){
        controlValuedocids.push( item.split(':')[0] ) ;
      } else {
        controlValuedocids.push(item)
      }
    })
  function  buildThumbnails (kagamiFileModel:KagamiFileModel) {

   let popupModel = kagamiFileModel.popupModel;
  
  
  //DOCiNFO FROM ONSTARTDATA
    let docInfoData  = CommonUtils.isNotEmpty(kagamiFileModel.docInfo) ? kagamiFileModel.docInfo : {}; //using document data from onStartdata
    
    //docdata from new uploads
    let newuploadsDocInfo = CommonUtils.isNotEmpty(kagamiFileModel.newUploadsDocInfo) ? kagamiFileModel.newUploadsDocInfo : {}
  
  
  
  
    return controlValuedocids.map((item :any, i:any) => {
      let fileId = item;
      let fileName = '';
      let fileType= '';
      let baseUrl = '';
      let fileString = ''
      if(Object.keys(docInfoData).includes(item )) {
        
        fileName = docInfoData[item]['fileName'];
        fileType = docInfoData[item]['fileType'] 
        baseUrl  = docInfoData[item]['thumbnail'] 
        fileString = fileId + ":" + fileName
  
    } else if(Object.keys(newuploadsDocInfo).includes(item )){
  
      fileName  =  newuploadsDocInfo[item]['fileName'];
      fileType  =  newuploadsDocInfo[item]['fileType'] ;
      baseUrl   =   newuploadsDocInfo[item]['thumbnail'] ;
      fileString = fileId + ":" + fileName
  
    } else {
      
      
      fileString = fileId 
    }
  
  let filename = CommonUtils.isNotEmpty(fileName) ? fileName.split(".")[0] : 'filname' 
    
  
  
      return (
        <>
         

            <div  key={i + '_modelFile'}   style={{width:'calc((100% - 20px)/ 3)', position:'relative' ,  height:'190px', display:'flex', flexDirection:'column', borderRadius:'5px'}}>
   {( CommonUtils.isNotEmpty(baseUrl)) 
   
   ? 
<div style={{height:'140px',   background:'#838999', border:'1px solid #ebebeb',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >
        <img alt={fileName} title={fileName}  style={{width:'100%', height:'100%'}}
            src={`data:image/png;base64,${baseUrl}`  }

            onClick={(event: any) => {

              CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel,   popupModel, fileString);
            }}
            /> 
    </div>
  : <div style={{height:'140px',   background:'#838999',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >
  <div style={{width:'58px', height:'76px' , background:'white', borderRadius:"5px", display:'flex', alignItems:'center', justifyContent:'center'}}>
      <img alt={fileName} title={fileName}  style={{width:'58px', height:'58px' }}
       src={ CommonUtils.getFileTypeICon(fileType)}
      onClick={(event: any) => {

        CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel,  popupModel, fileString);
      }}
      />
  </div>
</div>

  }
    
    <div style={{height:"50px",  textAlign:'left', padding:'10px 0 10px 0'}}>
        <div style={{display:'flex', gap:'10px', justifyContent:'space-between'}}>
          <div style={{ width:'80%', display:'inline-flex', alignItems:'center', fontSize:'12px', fontWeight:'500'}}>

         {CommonUtils.isNotEmpty(filename) &&  <>
         
          <p style={{margin:'0',
                whiteSpace: 'nowrap',
               overflow: 'hidden',
               textOverflow: 'ellipsis'
             }}> {filename}</p>
            { CommonUtils.isNotEmpty(fileType) && <span>{'.' + fileType}</span>}
         </> 
           }
          </div>
          <IconButton size='small'
           onClick={(event: any) => {
            CommonUtils.downloadFile(fileId, fileName)
            // CommonUtils.handlePreviewOrDownloadFileNew( kagamiFileModel, popupModel, fileString);
          }}>
          <CloudDownloadOutlinedIcon
             color='success'
            style={{ width: '20px', height: '20px'}}
           
           
          />

          </IconButton>
        </div>
    </div>
    {!kagamiFileModel.state.isReadOnly  
     //showing delete only for non-readonly
     && <div
    title="Del image"
    style={{
      position: 'absolute',
      top: '-9px',
      right: '-7px',
      cursor: 'pointer',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid red',
      borderRadius: '50%'
    }}
  >
    <ClearIcon
      sx={{
        fontSize: '13px'
      }}
      fontSize="small"
      color="error"
      onClick={() => {
        deleteDocument(
          fileId, kagamiFileModel
        )
      }}
    />
    </div>
  
  
  
  }
   
    </div>
        </>
          
    ) 
  
   
  
  
  
    })
  }
  

  return (
    <>


<div className={classes.AttachmentsModalTitle}>
                 {! kagamiFileModel.state.isReadOnly && controlValuedocids.length > 0
                 //showing delete all for only non-readOnly
                 && <Button variant = 'outlined'
                 color='error' 
                 size="small"  startIcon = {<DeleteSweepOutlinedIcon fontSize='small' />}
                 onClick={() =>deleteAllFile( kagamiFileModel)}
                 >Delete all</Button>
 
                }  
                <DialogTitle style={{padding:'5px 100px 5px 10px'}}> 
                    {attachmentsModel.attrName}  
                </DialogTitle>
                <IconButton size='small'  onClick={() => handleOnCancel(attachmentsModel)}>
                    <ClearIcon fontSize='medium'></ClearIcon>
                    </IconButton>
            </div>
          <DialogContent dividers className={classes.AttachmentsModalContent}>
          <div style={{height:'100%', width:'100%'}}>
            <div style={{ display:'flex', gap:'10px', flexWrap:'wrap'}}>
            {CommonUtils.isNotEmpty(controlValuedocids) 

            ? 
            <>
           { buildThumbnails(kagamiFileModel) }
           

               
            </>

             :
             <div style={{minHeight:'200px', display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center', width:'100%'}}>
              <h4 style={{color:'#838999', fontWeight:'normal', marginBottom:'10px'}}>NO FILES SELECTED</h4>
              <div>
            <input
                id={kagamiFileModel.controlIdentifier+'input'}
                type="file"
                style={{ display: 'none', marginTop: '3PX' }}
                onChangeCapture={(event) => {
                  handleImageChange(event, kagamiFileModel);
                  event.currentTarget.value = ''
                }}
                onSuspend={(event) => {
                  handleImageChange(event, kagamiFileModel);
                  event.currentTarget.value = ''
                }}
                multiple
              />
          <label htmlFor={kagamiFileModel.controlIdentifier+'input'} style={{ fontSize: '12px', padding: '5px', position:'relative', display:'flex', alignItems:'center', gap:'10px' }}>
                <img style={{ width: 80, height: 80, marginTop: '3px' , cursor:'pointer'}} alt="Upload" src={Upload_Doc}  /> 
                {/* <p style={{margin:'0'}}>Upload files</p> */}
            </label>  
      </div>
               </div > }
            </div>
          </div>
          <Typography color='error' variant='caption' >{ kagamiFileModel.state.errorMessage}</Typography>

          </DialogContent>
          <DialogActions className={classes.AttachmentsModalActions} >
            <div>


             {!kagamiFileModel.state.isReadOnly ? 
            
             ` ${controlValuedocids.length}  Files Selected` 
            :
            `Showing ${controlValuedocids.length}  Files `
            
            
            }
            </div>
            <div>
              
             {!kagamiFileModel.state.isReadOnly
             //showing upload only for non-readonly
             && 
            //  <div style={{width:'calc((100% - 20px)/ 3)', position:'relative' ,  height:'190px', display:'flex', flexDirection:'column', borderRadius:'5px'}}>
            //  <div style={{height:'140px',   background:'#f5f8fa', border:'1px solid #ebebeb',boxShadow:'0 1px 2px #00000026', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}} >   
             <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100%'}}>
         <input
             id={kagamiFileModel.controlIdentifier+'input'}
             type="file"
             style={{ display: 'none', marginTop: '3PX' }}
             onChangeCapture={(event) => {
               handleImageChange(event, kagamiFileModel);
               event.currentTarget.value = ''
             }}
             onSuspend={(event) => {
               handleImageChange(event,kagamiFileModel);
               event.currentTarget.value = ''
             }}
             multiple
           />
       <label htmlFor={kagamiFileModel.controlIdentifier+'input'} style={{ fontSize: '12px', padding: '5px 0', position:'relative', display:'flex', alignItems:'center', gap:'10px' }}>
             <img style={{ width: 30, height: 30 , cursor:'pointer'}} alt="Upload" src={Upload_Doc}  /> <p style={{margin:'0'}}>Add files</p>
            
         </label>  
   </div>
//  </div>

// </div>
             
            
            }
            </div>

          </DialogActions>
    </>
  )
}