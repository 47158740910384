import { ImportantDevices } from "@mui/icons-material";
import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { after } from "lodash";

export const OrganizationStyle = ((theme:Theme) => createStyles({
    root: {
      // backgroundColor:'#f5f8fa',
      display: "inline-grid",
      borderRadius: 50,
      // width : '180px',
      width : '220px',
      position:'relative',
      // border: '1px solid #00aeef',
      // heigth : '175px',
      overflow: "inherit",
      marginBottom:'14px',
      boxShadow: '0px 0px 20px 0px #ccc',
      '& .MuiCardHeader-root  ': {
         padding : '12px' ,
       

     },
     '&  .MuiBadge-badge':{
      backgroundColor:'#fff',
      color:'#008069',
      minWidth: '16px',
      height:'16px',
      padding:'0 4px',
     },
        '& .MuiCardHeader-avatar' : {
          marginRight:'0'
     },

 },
   
    container: {
      maxWidth: "100vw",
      // overflow:"hidden",
      overflow: "auto",
      maxHeight: "100vh",
      padding:'0',
     
    },
   
    expand: {
      paddingRight:'10px',
      color:'#fff'
      // height: '30px',
      // width:'30px',
      // position:'absolute',
      // bottom: 0,
      // right:0
    /* margin: auto; */
    // top: "54%",
    // left: "32%",
    // bottom:"0px",
    // position: "absolute",
    // transform: "translate(-20%, 36%)"
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: "#ECECF4",
    },
    details: {
    
      textAlign: "center",
      '& .MuiList-root': {
        padding:'4px',
        boxSizing:'border-box',
        border: '1px solid #00aeef',
        '& .MuiMenuItem-root ' : {
          borderTop:'1px solid #00aeef'
        }
      }
      // whiteSpace: "nowrap",

    },
    morevrtIcon: {
      position: 'absolute',
      top:'4px',
      right:'10',
      marginLeft: '56px',

    },
    cardmedia: {
      width: "60px",
      height: "60px",
      // marginLeft: "10%",
      textAlign: "center",
      // marginTop: theme.spacing(3),
      borderRadius: "75%",
      backgroundColor: "#f1f1f1",
      border: "0px solid #F1F1F1",
      objectFit: "contain",
      margin: "auto",
   

 
    },
    mainContainer:{
      display:"inline-block",
      backgroundColor: "#f5f8fa",
      textAlign: "center",
      border: "1px solid #65a6d9",
      borderRadius: theme.spacing(1.25),
      width: theme.spacing(20),
      padding: theme.spacing(2.5),
      height: theme.spacing(30),
    },
    dataContainer:{
      textAlign:"center"
    },
    name: {
      padding : theme.spacing(0),
      margin : theme.spacing(0.6),
      
    },
    employeeId: {
      color: "#0066b3",
      padding : theme.spacing(0),
      margin : theme.spacing(0.6),
    },
    designation: {
      color: "#008069",
      padding : theme.spacing(0),
      margin : theme.spacing(0.6),
      fontSize:'10px',
    },
    actionName: {
      color: "#000000",
      padding : theme.spacing(0),
      // margin : theme.spacing(0.2),
    },
    department: {
      padding : theme.spacing(0),
      margin : theme.spacing(0.6),
    },
    buttonContainer:{
      padding : theme.spacing(0),
      margin : theme.spacing(0.6),
    },

    lazyHierarchyContainer: {
      width:'100%',
      overflowX:'auto',
     
  
    },
    paperRoot: {
      borderRadius:'12px',
      '& .MuiList-root': {
        // padding:'4px',
        paddingTop: '0',
        paddingBottom: '0',
        boxSizing:'border-box',
        // border: '2px solid #00aeef',
        '& .MuiMenuItem-root' : {
          // borderBottom:'1px solid #ebebeb',
          borderBottom:'1px solid #ebebeb',
          '&:last-child': {
            borderBottom:'none'
          },
          '&:hover': {  
            backgroundColor: '#006e7f10'
          },
          '&  .MuiMenu-list' : {
            paddingTop : '0px',
            paddingBottom : '0px',
            cursor : 'pointer',
        },
     
      },
    },
    
    },
    menuItemStyle: {
      borderBottom:'1px solid #ebebeb',
      '&:last-child': {
        borderBottom:'none'
      },
      '&:hover': {  
        backgroundColor: '#006e7f10'
      }
    },
    badgeParant: {
     border:'1px solid #006e7f',
     backgroundColor:'#006e7f',
     position:'absolute',
     bottom: "0",
       left: "0",
       right:" 0",
       width: "80px",
       height:"28px",
       margin: "-16px auto",
       borderRadius: 50,
       Zindex:"999999",
       opacity: "1",
    },
    arrowdown:{
      width:'0',
       height:'0', 
       borderLeft: '11px solid transparent',
       borderRight: '11px solid transparent',
       borderTop: '20px solid #006e7f',
       margin: "auto",
       
      },
      cardParant:{ 
       width: "80px",
       height: "80px",
       borderRadius:" 50px",
       background: "#fff",
       margin: "auto",
       paddingTop: "10px",

      }
   
  }));
  