import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  extractDropdownOption,
  getPresenationIdFromPresentation
} from '../../../../../../service/PresentationService';
import Autocomplete from '@mui/material/Autocomplete';
import { CommonUtils } from '../../../../../../../../../../../../utils/CommonUtils';
import { getLovRecodsIfAny, getValueForGridSearch, setActiveRowNode } from '../../service/GridService';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { cellRendererCustomComponentsStyle } from '../../style/CellRendererCustomComponents';
import { SystemConstants } from '../../../../../../../../../../../../constants/SystemConstants';
import { onSearchCall } from '../../../../../controls/select/controller/kagamiSelectController';
import { KagamiStateManager } from '../../../../../../../../../../../../../state/KagamiStateManager';
import { ListBox } from '../../../../../controls/select/ui/SearchField';
import { getActiveFormRecord, getActiveRecordForEmbedEditors } from '../../../../../form/service/FormService';
import { KagamiFormModel } from '../../../../../form/model/KagamiFormModel';
import { getFormModel } from '../../../../../form/controller/KagamiFormController';
import delay from 'lodash/delay';
import { KagamiEmbeddedFormPresentationModel } from '../../../../../form/model/KagamiEmbeddedFormPresentationModel';
// import { KagamiGridStyle } from "../../style/KagamiGridStyle";
const useStyles = makeStyles(cellRendererCustomComponentsStyle);
export default forwardRef((props: any, ref: any) => {
  const classes = useStyles();
  const {rowData} = props;
  let searchValue = getValueForGridSearch(props.gridModel, props.attributePresentation,rowData);
  const [value, setValue] = useState<any>(CommonUtils.isNotEmpty(searchValue) ? searchValue : '');
  const [options,setOptions] = useState<any>(props.options)
  KagamiStateManager.addStateToHolder(props.gridModel.mainEntityId+'.'+props.gridSelectModel.attributeName,setOptions);
  function onChangeHandler(e: any, value: any) {
    setValue(CommonUtils.isNotEmpty(value) ? value : '');
    if(CommonUtils.isEmpty(props.gridModel.activeRowNode)){
      props.gridModel.activeRowNode = props.node;
    }
    props.gridModel.activeRowNode.data[props.column.colId] = CommonUtils.isNotEmpty(value) ? value[props.entityAssociatedAttribute] : value;
    props.gridModel.activeRowNode.data[props.entityConsumed] = CommonUtils.isNotEmpty(value) ? value : SystemConstants.REMOVED_;
    
    props.onCellValueChange(
      props.gridModel,
      props.gridModel.activeRowNode,
      props.attributePresentation.onChangeRequired,
      props.column.colId
    );
    if(props.searchString.searchValue !== '' && value === null){
      delay(()=>{
        onChange('');
        props.searchString.searchValue = ''
      },200)
    }
    //****Note: This is closed due to sequential/concurrent Network Calls */
    // if(CommonUtils.isNotEmpty(props.searchString.searchValue)){
    //   onChange('')
    // }
  }

  useEffect(()=>{
    console.log('column id is '+props.column.colId)
    setActiveRowNode(props,props.gridModel)
  },[])

  useImperativeHandle(ref, () => {
    return {
      getValue: () => (CommonUtils.isNotEmpty(value) ? value[props.entityAssociatedAttribute] : ''),
      afterGuiAttached: () => {
        // setActiveRowNode(props,props.gridModel)
        setValue(getValueForGridSearch(props.gridModel, props.attributePresentation,rowData))},
      refreshEditor:()=>{
        /// Note : (1) assigning value to editor and updating it in form data
       ///         (2) We have written this function to make sure in nested embed case the nested embeds specific to parent embed records gets updated properly
       ///              Because infocus ID's will give us only the active nested embed data , to update the remaining neseteds in remaining parent embed records we've added this
        let activeRecord : any = getActiveRecordForEmbedEditors(rowData,props.gridModel)
       if(CommonUtils.isNotEmpty(rowData[props.entityConsumed])){
         activeRecord[props.attributePresentation.attrName] = rowData[props.entityConsumed][props.entityAssociatedAttribute]
       }
       setValue(rowData[props.entityConsumed]);
      }
    };
  });

  const debounceOnChange = React.useCallback(CommonUtils._debounceFtn(onChange, 700), []);
  function onChange(searchString: string) {
    props.gridSelectModel.pageNumber = 1;
    props.searchString.searchValue = searchString;
    let presentationId = getPresenationIdFromPresentation(props.gridModel.presentation)
    onSearchCall(
      props.gridSelectModel,
      searchString,
      false,
      presentationId
    );
  }

  function handleAsyncSearch(pagination:boolean = false) {
    console.log("handle async search");
    if(props.gridSelectModel.verbProperties === undefined && pagination){
      props.gridSelectModel.pageNumber = +props.gridSelectModel.pageNumber+1;
      let presentationId = getPresenationIdFromPresentation(props.gridModel.presentation)
      onSearchCall(
        props.gridSelectModel,
        props.searchString.searchValue,
        true,
        presentationId
      );
    }else if(options.length < props.gridSelectModel.verbProperties.totalRecords){
      props.gridSelectModel.pageNumber = +props.gridSelectModel.pageNumber+1;
      let presentationId = getPresenationIdFromPresentation(props.gridModel.presentation)
      onSearchCall(
        props.gridSelectModel,
        props.searchString.searchValue,
        true,
        presentationId
      );
    }
  }

  return (
    <Autocomplete fullWidth
    className='gridSearchField'
      classes={{
        popper: classes.kgmGridSearchOptions
      }}
      forcePopupIcon= {true}
      options={CommonUtils.isNotEmpty(options) ? options : getLovRecodsIfAny(props.entityConsumed)}
      freeSolo= {CommonUtils.isNotEmpty(options)?true:false}
      isOptionEqualToValue={(option, value) => {
        if(value === '' || ((option && value) &&  (option.id === value.id))) {
          return true;
        }
        return false;
      }}
      value = {CommonUtils.isNotEmpty(value) ? value :  CommonUtils.isNotEmpty(rowData[props.entityConsumed]) ? rowData[props.entityConsumed] : ''}
      onChange={onChangeHandler}
      getOptionLabel={(option) => extractDropdownOption(props.attributePresentation, option)}
      // onBlur = {()=>{
      //   if(props.searchString.searchValue !== ''){
      //     onChange('');
      //   }
      // }}  
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.gridSearchFont}
          style={{ padding: '5px 0' }}
          onChange={(e) => debounceOnChange(e.target.value)}
        />
      )}
      // ListboxProps={{
      //   onScroll: (event: React.SyntheticEvent) => {
      //     const listboxNode = event.currentTarget;
      //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
      //     //  handleAsyncSearch();
      //     }
      //   }
      // }}
      // ListboxComponent={ListBox} // handle scroll position
    />
  );
});
