import { CommonUtils } from "../../../../../../../../../../utils/CommonUtils";

export class DateValidator {
    static  errorText:any;
  
    static  validate( validations:any,  value:any) {
      if (CommonUtils.isNotEmpty(validations) && Object.keys(validations).length !== 0) {
        DateValidator.errorText = null;
        for (var k in validations) {
          switch (k) {
            case 'notNull':
              if (validations[k] == true) {
                DateValidator.notNullValidation(value);
                if (DateValidator.errorText != null) {
                  return DateValidator.errorText;
                }
              }
              break;
          }
        }
      }
      return null;
    }
  
    static notNullValidation(value:any) {
      value ? DateValidator.errorText = null : DateValidator.errorText = 'This field is mandatory';
    }
  }
  