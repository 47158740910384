export const convertHexToRgb = (input: string) => {
  input = input + '';
  input = input.replace('#', '');
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }
  if (input.length === 3) {
    let firstInput: string = input[0];
    let secondInput: string = input[1];
    let thirdInput: string = input[2];
    input = firstInput + firstInput + secondInput + secondInput + thirdInput + thirdInput;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return parseInt(first, 16) + ', ' + parseInt(second, 16) + ', ' + parseInt(last, 16);
};

export const drawerWidth = 260;

export const primaryColor = ['#9c27b0', '#ab47bc', '#8e24aa', '#af2cc5', '#e1bee7', '#ba68c8','#99d8ff','#007ecc'];
export const secondaryColor = ['#fafafa' , '#9de500','#3f708f','#666'];
export const warningColor = ['#ff9800', '#ffa726', '#fb8c00', '#ffa21a', '#fcf8e3', '#faf2cc', '#ffe0b2', '#ffb74d'];
export const dangerColor = ['#f44336', '#ef5350', '#e53935', '#f55a4e', '#f2dede', '#ebcccc', 'ef9a9a', '#ef5350'];
export const successColor = ['#4caf50', '#66bb6a', '#43a047', '#5cb860', '#dff0d8', '#d0e9c6', '#a5d6a7', '#66bb6a'];
export const infoColor = ['#00acc1', '#26c6da', '#00acc1', '#00d3ee', '#d9edf7', '#c4e3f3', '#b2ebf2', '#4dd0e1'];
export const roseColor = ['#e91e63', '#ec407a', '#d81b60', '#f8bbd0', '#f06292'];
export const greyColor = [
  '#999',
  '#3C4858',
  '#eee',
  '#343434',
  '#585858',
  '#232323',
  '#ddd',
  '#6c757d',
  '#333',
  '#212121',
  '#777',
  '#D2D2D2',
  '#AAA',
  '#495057',
  '#e5e5e5',
  '#555',
  '#f9f9f9',
  '#ccc',
  '#444',
  '#f2f2f2',
  '#89229b',
  '#c0c1c2',
  '#9a9a9a',
  '#f5f5f5',
  '#505050',
  '#1f1f1f'
];
export const whiteColor = '#FFF';
export const blackColor = '#000';
export const twitterColor = '#55acee';
export const facebookColor = '#3b5998';
export const googleColor = '#dd4b39';
export const linkedinColor = '#0976b4';
export const pinterestColor = '#cc2127';
export const youtubeColor = '#e52d27';
export const tumblrColor = '#35465c';
export const behanceColor = '#1769ff';
export const dribbbleColor = '#ea4c89';
export const redditColor = '#ff4500';
export const instagramColor = '#125688';

export const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
};

export const containerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%'
};
export const container = {
  ...containerFluid,
  '@media (min-width: 576px)': {
    maxWidth: '540px'
  },
  '@media (min-width: 768px)': {
    maxWidth: '720px'
  },
  '@media (min-width: 992px)': {
    maxWidth: '960px'
  },
  '@media (min-width: 1200px)': {
    maxWidth: '1140px'
  }
};

export const card = {
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  margin: '25px 0',
  boxShadow: '0 1px 4px 0 rgba(' + convertHexToRgb(blackColor) + ', 0.14)',
  borderRadius: '3px',
  color: 'rgba(' + convertHexToRgb(blackColor) + ', 0.87)',
  background: whiteColor
};

export const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em'
};

export const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.42), 0 4px 25px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 8px 10px -5px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.2)'
};
export const primaryBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(' +
    convertHexToRgb(primaryColor[0]) +
    ', 0.28), 0 4px 20px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 7px 8px -5px rgba(' +
    convertHexToRgb(primaryColor[0]) +
    ', 0.2)'
};
export const infoBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(' +
    convertHexToRgb(infoColor[0]) +
    ', 0.28), 0 4px 20px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 7px 8px -5px rgba(' +
    convertHexToRgb(infoColor[0]) +
    ', 0.2)'
};
export const successBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(' +
    convertHexToRgb(successColor[0]) +
    ', 0.28), 0 4px 20px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 7px 8px -5px rgba(' +
    convertHexToRgb(successColor[0]) +
    ', 0.2)'
};
export const warningBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(' +
    convertHexToRgb(warningColor[0]) +
    ', 0.28), 0 4px 20px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 7px 8px -5px rgba(' +
    convertHexToRgb(warningColor[0]) +
    ', 0.2)'
};
export const dangerBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(' +
    convertHexToRgb(dangerColor[0]) +
    ', 0.28), 0 4px 20px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 7px 8px -5px rgba(' +
    convertHexToRgb(dangerColor[0]) +
    ', 0.2)'
};
export const roseBoxShadow = {
  boxShadow:
    '0 4px 20px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.14), 0 7px 10px -5px rgba(' +
    convertHexToRgb(roseColor[0]) +
    ', 0.4)'
};

export const warningCardHeader = {
  color: whiteColor,
  background: 'linear-gradient(60deg, ' + warningColor[1] + ', ' + warningColor[2] + ')',
  ...warningBoxShadow
};
export const successCardHeader = {
  color: whiteColor,
  background: 'linear-gradient(60deg, ' + successColor[1] + ', ' + successColor[2] + ')',
  ...successBoxShadow
};
export const dangerCardHeader = {
  color: whiteColor,
  background: 'linear-gradient(60deg, ' + dangerColor[1] + ', ' + dangerColor[2] + ')',
  ...dangerBoxShadow
};
export const infoCardHeader = {
  color: whiteColor,
  background: 'linear-gradient(60deg, ' + infoColor[1] + ', ' + infoColor[2] + ')',
  ...infoBoxShadow
};
export const primaryCardHeader = {
  color: whiteColor,
  background: 'linear-gradient(60deg, ' + primaryColor[1] + ', ' + primaryColor[2] + ')',
  ...primaryBoxShadow
};
export const roseCardHeader = {
  color: whiteColor,
  background: 'linear-gradient(60deg, ' + roseColor[1] + ', ' + roseColor[2] + ')',
  ...roseBoxShadow
};
export const cardActions = {
  margin: '0 20px 10px',
  paddingTop: '10px',
  borderTop: '1px solid  ' + greyColor[2],
  height: 'auto',
  ...defaultFont
};

export const cardHeader = {
  margin: '-30px 15px 0',
  borderRadius: '3px',
  padding: '15px'
};

export const defaultBoxShadow = {
  border: '0',
  borderRadius: '3px',
  boxShadow:
    '0 10px 20px -12px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.42), 0 3px 20px 0px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 8px 10px -5px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.2)',
  padding: '10px 0',
  transition: 'all 150ms ease 0s'
};

export const title = {
  color: greyColor[1],
  textDecoration: 'none',
  fontWeight: '700',
  marginTop: '30px',
  marginBottom: '25px',
  minHeight: '32px',
  fontFamily: `"Roboto Slab", "Times New Roman", serif`
};

export const cardTitle = {
  '&, & a': {
    ...title,
    marginTop: '.625rem',
    marginBottom: '0.75rem',
    minHeight: 'auto'
  }
};

export const cardLink = {
  '& + $cardLink': {
    marginLeft: '1.25rem'
  }
};

export const cardSubtitle = {
  marginBottom: '0',
  marginTop: '-.375rem'
};

export const main = {
  background: whiteColor,
  position: 'relative',
  zIndex: '3'
};

export const mainRaised = {
  '@media (max-width: 576px)': {
    marginTop: '-30px'
  },
  '@media (max-width: 830px)': {
    marginLeft: '10px',
    marginRight: '10px'
  },
  'margin': '-60px 30px 0px',
  'borderRadius': '6px',
  'boxShadow':
    '0 16px 24px 2px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.14), 0 6px 30px 5px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.12), 0 8px 10px -5px rgba(' +
    convertHexToRgb(blackColor) +
    ', 0.2)'
};

export const section = {
  backgroundPosition: '50%',
  backgroundSize: 'cover'
};

export const sectionDark = {
  backgroundColor: greyColor[3],
  background: 'radial-gradient(ellipse at center,' + greyColor[4] + ' 0,' + greyColor[5] + ' 100%)'
};

export const sectionDescription = {
  marginTop: '130px'
};

export const description = {
  color: greyColor[0]
};

export const mlAuto = {
  marginLeft: 'auto'
};

export const mrAuto = {
  marginRight: 'auto'
};

export const btnLink = {
  backgroundColor: 'transparent',
  boxShdow: 'none',
  marginTop: '5px',
  marginBottom: '5px'
};
export const coloredShadow = {
  // some jss/css to make the cards look a bit better on Internet Explorer
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    display: 'none !important'
  },
  'transform': 'scale(0.94)',
  'top': '12px',
  'filter': 'blur(12px)',
  'position': 'absolute',
  'width': '100%',
  'height': '100%',
  'backgroundSize': 'cover',
  'zIndex': '-1',
  'transition': 'opacity .45s',
  'opacity': '0'
};
