import axios from 'axios';
import moment from 'moment';
import { applicationURL } from '../application/service/URLService';

axios.defaults.headers.get['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers['Cache-Control'] = 'no-cache, no-store';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['utcoffset'] = moment().utcOffset();
axios.defaults.headers.source = 'runtime';

export class APIService {
  static getData(httpMethod: string, endPoint: string, requestData: any): any {
    let response: Promise<string> = new Promise((resolve) => {});
    switch (httpMethod) {
      case 'GET':
        response = axios.get(endPoint, requestData);
        break;
      case 'POST':
        response = axios.post(endPoint, requestData);
        break;
      case 'POST_SINGLE_VALUE':
        response = axios.post(endPoint, requestData, {
          headers: {
            'Content-Type': 'application/json'
          },
          transformRequest: [
            function (data, headers) {
              return requestData;
            }
          ]
        });
        break;
    }
    return response;
  }
}

axios.interceptors.request.use(
  function (config) {
    const allowedOrigins = applicationURL;
    const token = localStorage.getItem('token');
    let hardwareId = localStorage.getItem('hardwareId');
    let serialNo = localStorage.getItem('serialNo');
    config.headers.authorization = `kreact ${token}`;
    let deviceBasedLogin = localStorage.getItem('deviceBasedLogin') === 'true' ? true : false;
    deviceBasedLogin
      ? (axios.defaults.headers['device-info'] = `hardware_id=${hardwareId}, serial_no=${serialNo}`)
      : '';

    if (config.baseURL === '') {
      config.timeout = 4000;
    } else {
      return config;
    }
    console.log(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
