import _ from 'lodash';
import * as htmlToImage from 'html-to-image';
import {
  getReportData,
  reportColumnDisplayFormatter,
  reportFilterHeader,
  getReportId,
  getReportName
} from '../../../../service/ReportUtilService';
import { getFilterSelectedHeader } from '../../../../service/KagamiReportService';
import { getDrillDownReportData, handleDrillDownReport } from '../../../../service/ReportDrillDownService';
const lineDefaultConfig = () => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'line',
      height: 450,
    },
    exporting: {
      enabled: false
    },
    title: {
      text: '',
      align: 'center',
    },
    tooltip: {
      shared: true,
      valueSuffix: ' '
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    credits: {
      enabled: false,
    },
    series: [{
      name: '',
      colorByPoint: true,
      data: [],
    }]
  }
}

export const makeLine = (lineReportController: any) => {
  let defaultConfig = lineDefaultConfig();
  let dbChartData = JSON.parse(lineReportController.reportData);
  let tablerecords = getReportData(dbChartData);
  let userConfig = prapareUserConfigForChart(tablerecords, lineReportController, false);
  let dispchartOptions = _.extend(true, {}, defaultConfig, userConfig);
  return {
      lineConfig: dispchartOptions,
      tableConfig: makeTableDetails(lineReportController.reportConfig, userConfig)
  }
}
const getYAxisLabels = (arr: any) => {
  return (arr && arr.length > 0) ? arr.join(",") : ''
}
const line_getColumn = (colId: any, chartData: any) => {
  return _.map(chartData, function (row) {
    return row[colId];
  });
}
export const makeAdditionalReport = (additionalReportId: any, lineReportController: any) => {
  getDrillDownReportData(additionalReportId, lineReportController, { isAddionalReport: true });
}

const prapareUserConfigForChart = (tablerecords: any, lineReportController: any, isDashboard: any) => {
  let reportConfig = lineReportController.reportConfig;
  let userConfig: any = {
    yAxis: {
      title: {
        text: getYAxisLabels(reportConfig.horizontalAxis)
      },
      min: 0
    },
    xAxis: {
      title: {
        text: reportConfig.verticalAxis
      },
      min: 0,
      categories: line_getColumn(reportConfig.verticalAxis, tablerecords)
    },
    series: [
      {
        data: [],
        point: {
          events: {
              click: function (event:any) {
                  let $$this:any = this;
                  let value =  $$this?.['options']?.['name'] || '';
                  handleDrillDownReport(event,lineReportController,reportConfig,value,reportConfig.verticalAxis[0]);
              }
          }
      }
      }
    ]
  };
  let seriesData: any = [];
  let xaxis = reportConfig.horizontalAxis[0];
  let yaxis = reportConfig.verticalAxis[0];
  if (xaxis && yaxis) {
    _.forEach(tablerecords, function (rec, index) {
      seriesData.push({
        name: rec[yaxis],
        y: parseFloat(rec[xaxis])
      });
    });
  }
  userConfig.series[0].data = seriesData;
  return userConfig;
}
const makeTableDetails = (reportConfig: any, lineReportConfigDetails: any) => {
  let isBesideTableDetails = false;
  let showTableDetails = false;
  let detailedTableconfig;
  let additionalReportId = false;
  if (reportConfig?.reportSettings) {
    let otherSettings = reportConfig.reportSettings;
    if (otherSettings && otherSettings.showTableDetails) {
      showTableDetails = true;
      isBesideTableDetails = otherSettings.isBesideTableDetails || false;
      detailedTableconfig = {
        columns: [
          { field: "name", headerName: reportConfig?.verticalAxis?.[0] || 'Title' },
          { field: "y", headerName: reportConfig.horizontalAxis[0] || 'Count' },
        ],
        data: lineReportConfigDetails?.series?.[0].data || []
      }
    }
    if (otherSettings && otherSettings.additionalReportId) {
      additionalReportId = otherSettings.additionalReportId;
      isBesideTableDetails = true;
    }
  }
  return {
    isBesideTableDetails,
    detailedTableconfig,
    showTableDetails,
    additionalReportId
  }
}

export const downloadLinePDF = (chartRef: any, downloadConfig: any, lineReportModel: any) => {
  let html = chartRef.current.container.current.getInnerHTML()
  if (htmlToImage) {
    htmlToImage.toPng(chartRef.current.container.current)
      .then(function (dataUrl: any) {
        var img = new Image();
        img.src = dataUrl;
        downloadPdfTable(img, '', downloadConfig, lineReportModel);
      })
      .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
      });
  }
}
const downloadPdfTable = function (pdfImage: any, mode: any, downloadConfig: any, lineReportModel: any) {
  let reportName = getReportName(lineReportModel);
  let doc: any = {
    content: [
      {
        text: reportName,
        alignment: 'center',
        fontSize: 13,
      },
      {
        text: getFilterSelectedHeader(lineReportModel),
        alignment: 'center',
        fontSize: 9,
      },
      {
        image: pdfImage.src,
        fit: [490, 490]
      },
      {
        table: {
          body: getPdfTableData(downloadConfig?.tableConfig?.detailedTableconfig) || [],
          alignment: "center"
        }
      },
    ],
  }
  let headers = [];
  if (doc.content && doc.content[1] && doc.content[1].table && doc.content[1].table.body) {
    headers = _.cloneDeep(doc.content[1].table.body[0]);
    doc.content[1].table.body[0] = [];
  }
  _.each(headers, function (val) {
    var obj = { text: val, bold: true, fontSize: 14 };
    doc.content[1].table.body[0].push(obj);
  })

  import('pdfmake/build/pdfmake').then(({ default: pdfMake }) => {
    import('pdfmake/build/vfs_fonts').then(({ default: pdfFonts }) => {
      (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
      if (mode == 'print') {
        pdfMake.createPdf(doc).print();
      } else {
        pdfMake.createPdf(doc).download(reportName || 'Report');
      }
    })
  })

}

const getPdfTableData = (detailedTableconfig: any) => {
  let tableData = [_.map(detailedTableconfig.columns, 'headerName')];
  if (detailedTableconfig?.data?.length) {
    _.forEach(detailedTableconfig.data, function (rec) {
      tableData.push(_.values(rec));
    })
    let totalRow: any = []
    _.forEach(detailedTableconfig.columns, function (col: any, idx: any, array) {
      if (idx === array.length - 1) {
        totalRow.push("Total : " + (_.sum(_.map(detailedTableconfig.data, 'y'))))
      } else {
        totalRow.push("");
      }
    })
    tableData.push(totalRow)
  }
  return tableData;
}

export const getTotalOfReportColumn = (_config: any) => {
  if (_config?.tableConfig?.detailedTableconfig?.data) {
    return _.sum(_.map(_config.tableConfig.detailedTableconfig.data, 'y'))
  }
  return 0;
}
