import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  Typography,
  IconButton
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KagamiCardModelProps, KagamiCardModel } from '../model/KagamiCardModel';
import { getCardFieldValue, retrieveFileUrl } from '../service/CardService';
import { KagamiCardStyle } from '../style/KagamiCardStyle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { buildEditForm } from '../../../controller/KagamiCardViewController';
import { getKagamiListModel, reBuildTriggers } from '../../../../../controller/KagamiListController';
// import  userImage  from 'src/assets/images/user.png';
import userImage from '../images/user.png';
import { CommonUtils } from '../../../../../../../../../../../../../utils/CommonUtils';
import { buildPopover } from '../../../../../../../../../../../../../../components/popover/controller/KagamiPopoverController';
import { buildActivityControlKanban, buildActivityLogModal, getKagamiActivityLogModel } from '../../../../../../../../../../../../../../components/activityLog/controller/KagamiActivityLogModalController';
import { KagamiActivityLogModel } from '../../../../../../../../../../../../../../components/activityLog/model/KagamiActivityLogModel';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

export const buildCardView = (kagamiCardModel: KagamiCardModel) => {
  return <KagamiCard kagamiCardModel={kagamiCardModel}></KagamiCard>;
};

const useStyles = makeStyles(KagamiCardStyle);
const KagamiCard = (props: KagamiCardModelProps) => {
  let statusColor: any;
  let activityLogModel: KagamiActivityLogModel = new KagamiActivityLogModel();
  let statusAttr: any;
  const { kagamiCardModel } = props;
  let checked = false;
  let classes = useStyles();

  let objectData = new Map();
  let object = kagamiCardModel.dataRecord;
  for (var value in object) {
    objectData.set(value, object[value]);
  }
  const onMediaFallback = (event: any) => {
    event?.target?.src ? (event.target.src = userImage) : '';
  };

  // let getStatusColorAttributeData = kagamiCardModel.visibleAttributes.find((visAttr: any) =>
  //   visAttr.attributePresentation.label === 'Status'
  //     ? visAttr.attributePresentation.label === 'Status'
  //     : visAttr.attributePresentation.label === 'status'
  // );
  // if(CommonUtils.isNotEmpty(getStatusColorAttributeData)){
  // statusAttr =getStatusColorAttributeData.attributePresentation.attrName === 'status'
  //     ? getStatusColorAttributeData.attributePresentation.entityConsumed
  //     : getStatusColorAttributeData.attributePresentation.attrName;
  // }
  // if (CommonUtils.isNotEmpty(statusAttr)) {
  //   if (statusAttr.includes('.')) {
  //     let splitAttr = statusAttr.split('.')[0];
  //     statusColor = kagamiCardModel.dataRecord[splitAttr].Color.bgCode;
  //   } else {
  //     statusColor = kagamiCardModel.dataRecord[statusAttr].Color.bgCode;
  //   }
  // } else {
  //   statusColor = '#95c221';
  // }

  return (
    <>
      <div className={`${classes.cardWrapper} ${classes.col5ths}`}>
        <Card className={classes.card}>
          {kagamiCardModel.presentation.hasOwnProperty('actions') ? (
            <CardActions>
              <input
                type="checkbox"
                value={kagamiCardModel.dataRecord.id}
                defaultChecked={checked}
                onClick={(event) => {
                  checked = !checked;
                  let activeListModel = getKagamiListModel();
                  if (checked) {
                    activeListModel.selectedObjects.push(kagamiCardModel.dataRecord);
                    reBuildTriggers();
                  } else {
                    let recordIndex = activeListModel.selectedObjects.findIndex(
                      (record) => record.id === kagamiCardModel.dataRecord.id
                    );
                    if (recordIndex !== -1) {
                      activeListModel.selectedObjects.splice(recordIndex, 1);
                      reBuildTriggers();
                    }
                  }
                }}
              />
            </CardActions>
          ) : null}
          {CommonUtils.isNotEmpty(retrieveFileUrl(props.kagamiCardModel.dataRecord, kagamiCardModel.presentation)) ? (
            <div className={classes.cardMediaWrapper}>
              <CardMedia
                className={classes.image}
                component="img"
                height="140"
                image={retrieveFileUrl(props.kagamiCardModel.dataRecord, kagamiCardModel.presentation)}
                onError={onMediaFallback}
              />
            </div>
          ) : null}

          <CardContent className={classes.cardTextBlock}>
            {kagamiCardModel.visibleAttributes.length > 5
              ? kagamiCardModel.visibleAttributes.slice(0, 3).map((column) => {
                return (
                  // <Typography gutterBottom variant="h6" component="h6">
                  <div className={classes.cardstatus}>
                    <span className={classes.column_name}>{column.keyName + ' : '}</span>
                    <span className={classes.column_value}>
                      {getCardFieldValue(objectData, column)}
                    </span>
                  </div>
                  // </Typography>
                );
              })
              : kagamiCardModel.visibleAttributes.map((column) => {
                return (
                  // <Typography gutterBottom variant="h6" component="h6">
                  <div className={classes.cardstatus}>
                    <span className={classes.column_name}>{column.keyName + ' : '}</span>
                    <span className={classes.column_value}>
                      {getCardFieldValue(objectData, column)}
                    </span>
                  </div>
                  // </Typography>
                );
              })}
          </CardContent>
          {kagamiCardModel.visibleAttributes.length > 5 ? (
            <div className={classes.arrowbox}>
              <IconButton
                style={{ color: statusColor }}
                className={classes.icon}
                onClick={(event) => {
                  kagamiCardModel.popoverModel.child = (
                    <KagamiPoperCardcontent kagamiCardModel={props.kagamiCardModel} />
                  );
                  // kagamiCardModel.popoverModel.cardId = kagamiCardModel.dataRecord.id
                  kagamiCardModel.popoverModel.target = event.currentTarget;
                  kagamiCardModel.popoverModel.state.setPopoverTarget(event.currentTarget);
                  // kagamiCardModel.popoverModel.state.setPopoverStatus(true);
                  // kagamiCardModel.popoverModel.state.setPopoverShowIcon(true);
                  // kagamiCardModel.popoverModel.state.setPopoverPosition('down', true);
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          ) : null}
          {buildPopover(kagamiCardModel.popoverModel)}
        </Card>
        {buildActivityLogModal(activityLogModel)}
      </div>
    </>
  );
};
const KagamiPoperCardcontent = (props: KagamiCardModelProps) => {
  console.log("openCard", props);
  let statusColor: any;
  const { kagamiCardModel } = props;
  let classes = useStyles();
  let objectData = new Map();
  let object = kagamiCardModel.dataRecord;
  for (var value in object) {
    objectData.set(value, object[value]);
  }
  // let getStatusColorAttributeData = kagamiCardModel.visibleAttributes.find((visAttr: any) =>
  //   visAttr.attributePresentation.label === 'Status'
  //     ? visAttr.attributePresentation.label === 'Status'
  //     : visAttr.attributePresentation.label === 'status'
  // );
  // let statusAttr =
  //   getStatusColorAttributeData.attributePresentation.attrName === 'status'
  //     ? getStatusColorAttributeData.attributePresentation.entityConsumed
  //     : getStatusColorAttributeData.attributePresentation.attrName;
  // if (CommonUtils.isNotEmpty(statusAttr)) {
  //   if (statusAttr.includes('.')) {
  //     let splitAttr = statusAttr.split('.')[0];
  //     statusColor = kagamiCardModel.dataRecord[splitAttr].Color.bgCode;
  //   } else {
  //     statusColor = kagamiCardModel.dataRecord[statusAttr].Color.bgCode;
  //   }
  // } else {
  //   statusColor = '#13344d';
  // }
  return (
    <>
      {kagamiCardModel.visibleAttributes.length > 5 ? (
        <div className={classes.popoverContent}>
          {kagamiCardModel.visibleAttributes.slice(3).map((column) => {
            return (
              <>
                <div style={{ width: '100%' }}>
                  <span className={classes.column_name}>{column.keyName + ' : '}</span>
                  {(column.attributePresentation?.htmlControl === "customactivitylog" || column.attributePresentation?.htmlControl === "comments") ? getActivityComponentForCard(props) : <span style={{ color: '#007ecc' }} className={classes.column_value}>{getCardFieldValue(objectData, column)}</span>}
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};


const getActivityComponentForCard = (props: KagamiCardModelProps) => {
  let activityModel = getKagamiActivityLogModel()
  activityModel.controlValue = props.kagamiCardModel.dataRecord.activitylog;
  activityModel.entityId = props.kagamiCardModel.dataRecord.entityType;
  return buildActivityControlKanban(activityModel)
}