import { createState } from '@hookstate/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import values from 'lodash/values';
import _ from 'lodash';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { buildReportsPageController } from '../../../../../../landingPage/builder/ReportsPage/controller/ReportsPageController';
import { buildReportModal } from '../../../../../ReportModal/controller/ReportModalController';
import { ReportModalModel } from '../../../../../ReportModal/model/ReportModalModel';
import { getReportDataForDynamicReports } from '../../../../service/KagamiReportService';
import { excelDownloadLoader, onPrintPDF } from '../controller/ListReportController';
import { KagamiListReportModelProps, ListReportModel, useListReportModelState } from '../model/ListReportModel';
import { downloadExcel, downloadPDF } from '../service/ListReportExportService';
import {getFooterRowData, makeReportData, saveReportConfig } from '../service/ListReportService';
import GridPaginationStatusBar from './GridPaginationStatusBar';

export const BuildListReportView = (listReportModel: ListReportModel) => {
  return <ListReportView key={'list-report-view'} listReportModel={listReportModel}></ListReportView>;
};
export const ListReportView = (props: KagamiListReportModelProps) => {
  let listReportModel = props.listReportModel;
  const [printPdfAnchorEl, setPrintPdfAnchorEl] = React.useState<null | HTMLElement>(null);
  const printPotraitOpen = Boolean(printPdfAnchorEl);
  const gridRef:any = useRef(null);
  const printPdfHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPrintPdfAnchorEl(event.currentTarget);
  };
  const printPdfHandleClose = () => {
    setPrintPdfAnchorEl(null);
  };
  
  let listState = useListReportModelState(createState(listReportModel));
  listReportModel.state = listState;
  

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: GridPaginationStatusBar, align: 'right' },
      ],
    };
  }, []);

  React.useEffect(() => {
    getReportDataForDynamicReports(listReportModel)
  }, [])
  
  if(!listState.isDataLoaded){
    return <CircularProgress sx={{marginTop:'4%', marginLeft: '48%'}} color="secondary" />
  }else{
  if(listReportModel.reportData){
  const onDownloadExcel = () => {
    excelDownloadLoader(true);
    setTimeout(()=>{
      makeGridFilterData(reportConfig);
      downloadExcel(reportConfig,listReportModel);
    },300);

  };
  const makeGridFilterData  = (reportConfig:any) => {
  reportConfig['filteredRowData'] = [];
  if(gridRef?.current?.api?.getModel()?.rowsToDisplay?.length){
    let filteredRowData = map(gridRef.current.api.getModel().rowsToDisplay,'data');
    if(filteredRowData?.length && filteredRowData?.length != reportConfig.rowData){
      reportConfig['filteredRowData'] = filteredRowData;
    }
  }
    let filterRecords = filter(reportConfig.filteredRowData, function (item) {
      return item
    })
    if(filterRecords?.length == 0){
      reportConfig['filteredRowData'] = [];
    }
  }
  const onDownloadPDF = (type: any) => {
    setPrintPdfAnchorEl(null);
    makeGridFilterData(reportConfig);
    downloadPDF(type,reportConfig,listReportModel);
  };
  const onCustomDownloadPDF = () => {
    makeGridFilterData(reportConfig);
    onPrintPDF(listReportModel.reportModalModel, reportConfig,listReportModel)
  };
  
 const updateFooterRowData = (listReportController: any, disptabelData: any) => {
    let dbChartData = JSON.parse(listReportController.reportData);
    let footerRowData = getFooterRowData(disptabelData, dbChartData, listReportController.reportConfig);
    if (footerRowData && footerRowData.length) {
      let footerValues = values(footerRowData[0]);
      if (footerValues && footerValues.length) {
        let footerData = filter(footerValues, function (val) {
          return val != "" || val == 0;
        })
        if (footerData?.length) {
          console.log("pinnedBottomRowData" , footerRowData);
          reportConfig['pinnedBottomRowData'] = footerRowData;
          if(gridRef?.current?.api) {
            gridRef.current.api.setPinnedBottomRowData(footerRowData);
          }
        }
      }
    }
  }
  let reportConfig: any = makeReportData(listReportModel);
  if(gridRef?.current?.api?.gridBodyCtrl?.rowModel?.rowsToDisplay) {
    let displayRows =  map(gridRef?.current?.api?.gridBodyCtrl?.rowModel?.rowsToDisplay,'data');
    let isFooterData = _.filter(displayRows,function(rec) {
      return rec != undefined 
      });
    if(isFooterData?.length) {
      updateFooterRowData(listReportModel,displayRows);
    }
  }
  return (
    <div className="ag-theme-alpine kgm-report-aggrid" style={{height:'100%'}} >
      {buildReportModal(listReportModel.reportModalModel)}
        <Grid className = {`${ listReportModel['fromDashboard'] ? 'hide-buttons-from-desktop-report-container':"" }`}   container style={{ margin: '10px',textAlign:'right'}} direction="row" justifyContent="flex-end" alignItems="flex-end" >
        <Grid item xs={12}>
        <ButtonGroup className="report-rtl-btngroup" variant="text" aria-label="text button group">
          <LoadingButton
            loadingPosition="start"
            loading={listState.isExcelDownloaded}
            onClick={onDownloadExcel}
            startIcon={<SaveIcon />}
            variant="contained"
            classes={{
              loadingIndicator: "circular-loader" 
            }}
          >
            <span className='button-text'>Download Excel</span>
          </LoadingButton>
            <div>
              <Button
                id="printPotrait-button"
                variant="contained"
                onClick={printPdfHandleClick}
                >
                Download PDF
                <ArrowDropDownIcon />
              </Button>
                <Menu
                  id="printPotrait-menu"
                  anchorEl={printPdfAnchorEl}
                  open={printPotraitOpen}
                  onClose={printPdfHandleClose}
                >
                <MenuItem onClick={() => onDownloadPDF('potrait')}>Potrait </MenuItem>
                <MenuItem onClick={() => onDownloadPDF('landscape')}>Land Scape</MenuItem>
              </Menu>
            </div>
            {/* <Button  variant="contained" onClick={() => { onPrintPDF(reportModalModel, reportConfig,listReportModel) }}>
              Print
          </Button> */}
          <Button  variant="contained" onClick={() => { onCustomDownloadPDF() }}>
              Print
          </Button>
          <Button  variant="contained" onClick={() => { saveReportConfig(gridRef,listReportModel) }}>
              Save
          </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
        {/* TABLE DISPLAY */}
        <Grid className="kgm-report-hw dashboard-report" >
        <AgGridReact 
          // aggFuncs =  {reportConfig.aggFuncs} 
          ref={gridRef}
          columnDefs={reportConfig.columnDefs}
          defaultColDef={reportConfig.defaultColDef}
          getRowHeight = {reportConfig.getRowHeight}
          groupHideOpenParents = {reportConfig.groupHideOpenParents}
          // groupIncludeTotalFooter = {reportConfig.groupIncludeTotalFooter}
          // groupIncludeFooter={reportConfig.groupIncludeFooter}
          icons = {reportConfig.icons}
          pagination = {reportConfig.pagination}
          paginationPageSize = {reportConfig.paginationPageSize}
          pinnedBottomRowData = {reportConfig.hideTotals ? [] : reportConfig.pinnedBottomRowData}
          suppressFieldDotNotation = {reportConfig.suppressFieldDotNotation}
          rowData={reportConfig.rowData}
          suppressAggFuncInHeader={reportConfig.suppressAggFuncInHeader}
          statusBar={statusBar}
          onFilterChanged={(e:any) => {
            if(e?.api?.gridBodyCtrl?.rowModel?.rowsToDisplay){
             let displayRows =  map(e.api.gridBodyCtrl.rowModel.rowsToDisplay,'data');
             if(_.compact(displayRows).length == 0) {
               displayRows  = _.map(e.api.gridBodyCtrl.rowModel.rowsToDisplay,'aggData');
             } 
             updateFooterRowData(listReportModel,displayRows);
            }
          }}
         ></AgGridReact>
         </Grid>
         
         <div style = {{ position: 'absolute', opacity: '0.0' }}>
              <div id="printDiv" style = {{ display: 'none' }} ></div> 
        </div>   

        <Grid item xs={12} style={{ marginBottom:'25px'}}>
          {
            listState.isContentReportLoaded && <div>
              {buildReportsPageController(listReportModel?.contentReport, true)}
            </div>
          }
        </Grid>
        </div>
  );
  }else{
    return <p className='NoDataFound'>No Data Found</p>
  }
}
};