import { makeOnLoadCall } from "../../../../../service/ProcessService";
import { handleFormDataChange } from "../../../controller/KagamiFormController";
import { KagamiEmbeddedFormPresentationModel } from "../../../model/KagamiEmbeddedFormPresentationModel";
import { buildMatrixFormPresentation } from "../controller/KagamiMatrixFormController";
import { KagamiMatrixFormModel } from "../model/KagamiMatrixFormmodel";

 export const handleMatrixPresentation = (embeddedFormPresentationModel:any) => {
let matrixFormmodel: KagamiMatrixFormModel = new KagamiMatrixFormModel();
let changeFor = embeddedFormPresentationModel?.onStartData?.constructOutputData?.detailedObjects?.DateRecords_513;
let data = changeFor;
makeOnLoadCall(
  embeddedFormPresentationModel.processName,
  embeddedFormPresentationModel.presentation,
  changeFor,
  data,
  null,
  {}
).then((response) => {
  matrixFormmodel.matrixFormModels = response;
  handleFormDataChange(
    embeddedFormPresentationModel.formEntityId,
    response.data,
    undefined,
    false,
    !embeddedFormPresentationModel.isListPresentation
  );
    buildMatrixFormPresentation(matrixFormmodel);
});
}

export function isMatrixFormTrue(embeddedFormPresentationModel: KagamiEmbeddedFormPresentationModel) {
    let mainEntityId = embeddedFormPresentationModel.mainEntityId;
    let presentation = embeddedFormPresentationModel?.onStartData?.constructOutputData?.uiResource?.presentations?.presentationRuleMap[mainEntityId][1];
    let presentationRulesforMatrix = presentation?.presentationRules;
    let matrix: boolean = false;
    for (const object in presentationRulesforMatrix) {
      let nonField = presentationRulesforMatrix[object];
      for (const key in nonField) {
        if (nonField[key] === 'X' || nonField[key] === 'Y' || nonField[key] === 'cell') {
          matrix = true;
        }
      }
    }
    if (matrix) {
      return false;
    } else {
      return false;
    }
  }