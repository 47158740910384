import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const KagamiCheckBoxStyle = (theme: Theme) =>
  createStyles({
    root: { 
        '& .MuiFormControlLabel-root' : {
            margin: '0',
            display:'flex',
            // width: 'fit-content',
            flexDirection: 'row',
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
              color: theme.palette.primary.main,
              paddingBottom: '2px'
            },
        },
        '& .PrivateSwitchBase-input ': {
        }
    },
    controlLabel : {
      // '&.MuiFormLabel-root ': {
      //   fontSize: theme.spacing(1.5),
      //   color: theme.palette.primary.dark,
      // }, 
    } ,
    readOnlyField_cont: {
      // paddingLeft: theme.spacing(1.6),
      // marginTop: theme.spacing(-1.375)
    }
  });
