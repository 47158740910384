import { SystemConstants } from '../../../../../../../../../../../constants/SystemConstants';
import { APIService } from '../../../../../../../../../../../service/ApiService';
import { getDmsViewUrl, getDynamicTreeURL } from '../../../../../../../../../../../service/URLService';
import {
  getAttributeName,
  getDisplayAttributes,
  getEntityConsumed,
  getHtmlControl
} from '../../../../../service/PresentationService';
import { getKagamiListModel } from '../../../controller/KagamiListController';
import { getValueForPickAttribute } from '../../../service/kagamiListService';
import userImage from '../../../../../../../../../../../../assets/images/user.png';
import { KagamiHierarchyViewModel } from '../model/KagamiHierarchyViewModel';

export class HierarchyViewData {
  static getDynamicTreeData(hierarchyViewModel: KagamiHierarchyViewModel) {
    const mainEntityId = hierarchyViewModel.mainEntityId;
    const listOfObject = getKagamiListModel().detailedObjects;
    APIService.getData(SystemConstants.POST, getDynamicTreeURL(), {
      listOfObjects: listOfObject,
      entityId: mainEntityId
    })
      .then((ele: any) => {
        console.log(ele.data.trees);
        hierarchyViewModel.treeData = ele.data.trees;
        hierarchyViewModel.state.setTreeDataLoaded(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}

export const getHierarchyFieldsSearchValue = (datarecord: any, col: any) => {
  let attributeName: string = getAttributeName(col.attributePresentation);
  let entityConsumned: string = getEntityConsumed(col.attributePresentation);
  let displayAttribute: any = getDisplayAttributes(col.attributePresentation);
  let htmlControl: string = getHtmlControl(col.attributePresentation);
  let value: any;
  if (attributeName.includes('.')) {
    value = getValueForPickAttribute(attributeName, datarecord);
  } else {
    if (htmlControl === 'search' || htmlControl === 'select' || htmlControl === 'multiselect') {
      if (datarecord[entityConsumned] !== undefined && datarecord[entityConsumned] instanceof Object) {
        value = datarecord[entityConsumned][displayAttribute];
      } else {
        value = datarecord[entityConsumned];
      }
    } else if (htmlControl === 'text') {
      value = datarecord[attributeName];
    }
  }
  return value;
};

export const retrieveFileUrlForHierarchyView = (dataRecord: any, field: any) => {
  if (dataRecord[field]) {
    if (dataRecord[field].includes(':')) {
      let fieldId = dataRecord[field].split(':');
      let noImage = isNaN(fieldId[0]);
      if (noImage) {
        return userImage;
      }
      return getDmsViewUrl(fieldId[0]);
    } else {
      return getDmsViewUrl(dataRecord[field]);
    }
  } else {
    return userImage;
  }
};
