import moment from "moment";
import {getStaticFilterData,getBalanceSheetReportData,getStaticFilterInfo} from '../../../../service/KagamiReportService';
import {getReportType,handleStaticReportDates,getSelectedCurrency,getLocaleValue} from '../../../../service/ReportUtilService';
import extend from 'lodash/extend';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import ExcelTreeDataDownload  from '../../../../service/exports/ExcelTreeDataService';

export async function getDBStaticFilterData(reportController: any) {
    let reportType =  getReportType(reportController.reportConfig);
    return await getStaticFilterData(reportType);
}
export const makeReport = (reportController: any) => {
        let defaultConfig ={
          autoGroupColumnDef: {headerName: 'Account Name',  width: 200,cellRendererParams: {suppressCount: true,checkbox: false}},    
          columnDefs: [],
          defaultColDef: { flex :1,sortable: false,minWidth: 125,filter: true,resizable: true,editable: false },
          groupDefaultExpanded: 0,//expand everything by default
          groupHideOpenParents:true,
          groupIncludeTotalFooter: false,
          getDataPath: (data:any) => {
            return data.subMenus;
          },
          getRowHeight :(params: any) => 30,
          icons: {
            groupContracted:'<div class="kgm-plus-icon no-border"></div>',
            groupExpanded:'<div class="kgm-minus-icon no-border"></div>'
          },
          pivotMode : false,
          pagination: true,
          paginationPageSize: 50,
          rowData:[],
          suppressAggFuncInHeader: true, //hide aggregate name in a row 
          treeData: true,
        };
        let userConfig = prapareUserConfigForReport(reportController,defaultConfig);  
        let reportConfig = extend(true, {}, defaultConfig, userConfig);

        return reportConfig;

}
function prapareUserConfigForReport (reportController: any,defaultConfig:any) {
    let userConfig:any = {
      columnDefs: [],
      rowData: [],
    };
    let columnDefs: any = [];
    columnDefs =[
       {headerName: 'Account Name', field: 'name',hide:true},
       {headerName: 'Value', field: 'column',type: 'rightAligned'},
       {headerName: 'Total', field: 'result',type: 'rightAligned'},
      ]
    userConfig['columnDefs'] = columnDefs;
    return userConfig;
  }
 export function makeReportData(reportModel:any,dbReportData: any) {
    let dbData = []; 
    if(dbReportData.menuItems) {
        dbData = dbReportData.menuItems;
     }
     if(dbData && dbData.length > 3) { //here to same name come from backend as total
      dbData[1].name = dbData[0].name +" "+ dbData[1].name;
      dbData[3].name = dbData[2].name +" "+ dbData[3].name;
     }
     let currency = getSelectedCurrency(reportModel.reportConfig);
    return getReportData(cloneDeep(dbData),currency) ||[];
 }
  export const getData = async (reportModel:any,inputReportConfig:any) => {
    if((!inputReportConfig.state || inputReportConfig.state.length == 0 ) && (reportModel?.reportData?.staticFilterData?.data?.state)){
      inputReportConfig['state'] = "NOPOP";
    }
    if((!inputReportConfig.projectPlan || inputReportConfig.projectPlan.length == 0) && (reportModel?.reportData?.staticFilterData?.data?.projectPlan)){
      inputReportConfig['projectPlan'] = "NOPOP";
    }
    handleStaticReportDates(inputReportConfig);
    let res =  await getBalanceSheetReportData(inputReportConfig);
    if(res?.data ){
        return res.data['leftPanel'];
    }
    return [];
  }

  const getReportData = (reportData:any,currency:any) => {
    let data:any = [];
    if(reportData && reportData.length ){
      forEach(reportData,(rec:any)=> {
        let temp = {
              name: rec.name ||'',
              column: getLocaleValue(rec.column,currency) || '',
              result: getLocaleValue(rec.result,currency) ||'',
              subMenus:[rec.name]
          }
          data.push(temp);
          if(rec?.subMenus && rec.subMenus.length>0 ){
              rec.subMenus.forEach((subRec:any) => {
                recursivelyGetSubMenusData(data,subRec,[rec.name],currency)
              });
          }
      })
    }
    return data;
  }

  const recursivelyGetSubMenusData = (data:any,subMenuRecs:any,path:any,currency:any) => {
    let temp = {
      name: subMenuRecs.name ||'',
      column: getLocaleValue(subMenuRecs.column,currency) || '',
      result: getLocaleValue(subMenuRecs.result,currency) ||'',
      subMenus:path
    }
    if(temp.subMenus && temp.subMenus.length) {
      temp.subMenus.push(subMenuRecs.name);
    }
    data.push(temp);
    if(subMenuRecs?.subMenus && subMenuRecs.subMenus.length>0 ){
      subMenuRecs.subMenus.forEach((subRec:any) => {
        recursivelyGetSubMenusData(data,subRec,cloneDeep(temp.subMenus),currency);
      });
    }
  }

  export const onChangeState = async (state:any,ledgerAccountReportController:any) => {
    let reportType =  getReportType(ledgerAccountReportController.reportConfig);
    if(reportType && state && state.length >0) {
        var QueryParam='';
        QueryParam = QueryParam.concat("ledgerType=" + reportType +'&');
        QueryParam = QueryParam.concat("dependant=" + "state|" + state.join(","));
        let staticData = await getStaticFilterInfo(QueryParam);
        if (staticData?.data) {
          return cloneDeep(staticData.data.projectPlan || []);
        }
    }
    return [];
}

export const downloadExcel = (reportModel:any) => {
  // let reportInfo  = JSON.parse(JSON.stringify(runReportConfig.reportInfo.get()));
  let gridInfo:any = {};
  if(reportModel?.reportData?.gridReportInfo) {
    gridInfo =reportModel.reportData.gridReportInfo;
  }
  let currency = getSelectedCurrency(reportModel.reportConfig);

  let configInfo:any = {
    alternateRowColor:true,
    defaultColumnWidth:20,
    fileName: 'Balance Sheet Report',
    reportDBData:gridInfo.reportDBData?.menuItems ? (gridInfo.reportDBData.menuItems) : [],
    reportColumns :gridInfo.columnDefs ||[],
    nestedKey:'subMenus',
    nestedRecordRowColor:false,
    excelHeaderInfo :getReportHeaderInfo(reportModel.reportConfig),
    excelRTLfields :['column','result']
  }


  let excelTableRows  =  cloneDeep(gridInfo.reportDBData);
  let dbData = []; 
  if(excelTableRows.menuItems) {
      dbData = excelTableRows.menuItems;
   }
   if(dbData && dbData.length > 3) {
    dbData[1].name = dbData[0].name +" "+ dbData[1].name;
    dbData[3].name = dbData[2].name +" "+ dbData[3].name;
   }
  makeExportData(dbData,currency);
  configInfo['excelTableRows'] = dbData;
  const excelLib =  ExcelTreeDataDownload(configInfo);
  excelLib.downLoad();
}
const getReportHeaderInfo = (reportConfig:any) => {
  let headerConfig:any = {};
  if(reportConfig) {
    headerConfig['reportType'] =  getReportType(reportConfig) || '';
    headerConfig['subOrganization'] =reportConfig.selectedValue || ''; 
    headerConfig['date'] = getReportSelectedDates(reportConfig);
    headerConfig['exportedDate'] = printFormatDate(new Date()) || '';
  }
  return headerConfig;
}
const getReportSelectedDates = (reportConfig:any)=>{
  if(reportConfig.leftPanel){
    return "From "+printFormatDate(reportConfig.leftPanel.startDate)+" To "+printFormatDate(reportConfig.leftPanel.endDate);
  }
  return '';
}
const printFormatDate = (date:any) => {
  if(date){
       return moment(date).format('DD-MMM-YYYY'); 
  }
  return '';
}


/** EXPORT DATA HANDLING  */
const makeExportData = (reportData:any,currency:any) => {
  if(reportData && reportData.length ){
    forEach(reportData,(rec:any)=> {
      rec['column'] = getLocaleValue(rec.column,currency) || rec.column;
      rec['result'] = getLocaleValue(rec.result,currency) ||'';
        if(rec?.subMenus && rec.subMenus.length>0 ){
            rec.subMenus.forEach((subRec:any) => {
              recursivelyGetExportSubMenusData(subRec,currency)
            });
        }
    })
  }
}
const recursivelyGetExportSubMenusData = (subMenuRecs:any,currency:any) => {
  subMenuRecs['column'] = getLocaleValue(subMenuRecs.column,currency) || subMenuRecs.column;
  subMenuRecs['result'] = getLocaleValue(subMenuRecs.result,currency) ||'';
  if(subMenuRecs?.subMenus && subMenuRecs.subMenus.length>0 ){
    subMenuRecs.subMenus.forEach((subRec:any) => {
      recursivelyGetExportSubMenusData(subRec,currency);
    });
  }
}
/**  END EXPORT DATA HANDLING  */
