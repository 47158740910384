import { KagamiStateManager } from "../../../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../../../constants/SystemConstants";
import { CommonUtils } from "../../../../../../../../../../../../../utils/CommonUtils";
import { getKagamiListModel } from "../../../../../controller/KagamiListController";
import { searchRecord } from "../../../../../service/kagamiListService";
import { KagamiListFilterModel } from "../../../model/KagamiListFilterModel";
import { KagamiClearFiltersButtonModel } from "../model/ClearFiltersModel";
import { buildClearButton } from "../ui/ClearFiltersButton";

export function buildClearFilterButton(clearFilterButtonModel: KagamiClearFiltersButtonModel, kagamiListFilterModel:KagamiListFilterModel){
return buildClearButton(clearFilterButtonModel, kagamiListFilterModel);
}


export function handleClearFilters(kagamiListFilterModel: KagamiListFilterModel, enableSearch:boolean = true){


     
    console.log("presentationFiltersApplied", kagamiListFilterModel.presentationFiltersApplied);

    let kagamiListModel = getKagamiListModel();
    kagamiListModel.filterList = {};
    kagamiListModel.pageNumber = 1;
    let listModelPresentationId = kagamiListModel.presentation.presentationId;

    if(CommonUtils.isNotEmpty( kagamiListFilterModel.presentationFiltersApplied)){
        for(let i = 0; i < kagamiListFilterModel.presentationFiltersApplied.length ; i++ ){
            kagamiListFilterModel.presentationFiltersApplied[i].state.SetCheckedList([]);
        }
    }
    
    const listFilterAppliedState = KagamiStateManager.getStateToHolder(SystemConstants.CLEAR_LIST_FILTER_STATE); 
    listFilterAppliedState.SetIsEnabled(false)
    
    
    kagamiListFilterModel.searchObj = {};

    if(enableSearch){
        searchRecord(kagamiListModel,listModelPresentationId,null, null)
    }
}