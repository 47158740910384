import { BuildGrid } from '../ui/KagamiGrid';
import { KagamiGridModel } from '../model/KagamiGridModel';
import { CommonUtils } from '../../../../../../../../../../../utils/CommonUtils';
import _, { delay } from 'lodash';
import { retrieveListRows } from '../../../service/kagamiListService';

let KgmGridModel : KagamiGridModel;

export const getKagamiGridModel = () => {
  return KgmGridModel;
}
export const setKagamiGridModel = (kagamiGridModel: KagamiGridModel ) => {
  KgmGridModel = kagamiGridModel;
}

export function buildGrid(kagamiGridModel: KagamiGridModel) {
  setKagamiGridModel(kagamiGridModel)
  return BuildGrid(kagamiGridModel);
}

export function reBuildGridView(kagamiGridModel : KagamiGridModel,dataRecords: any,listIdentifier : string,updateRecord : boolean = false,mergeOnloadData : boolean = false) {
  if(updateRecord) {
    let currentRecords : any[] = _.clone(kagamiGridModel.detailedObjects);
    if(CommonUtils.isNotEmpty(currentRecords) && dataRecords.length ===1) {
      let recordToBeChangedIndex : number = currentRecords.findIndex((record : any) => record.id === dataRecords[0].id)
      if(recordToBeChangedIndex !== -1) {
        currentRecords[recordToBeChangedIndex] = dataRecords[0]
        dataRecords = currentRecords;
      }
    }
  }
    if(mergeOnloadData && kagamiGridModel.detailedObjects instanceof Array){
      let newRecordsLength : number = dataRecords.length;
      let selectedNodes : any[] = kagamiGridModel.gridApi.getSelectedNodes()
      kagamiGridModel.detailedObjects.push(...dataRecords);
      if(CommonUtils.isEmpty(kagamiGridModel.onLoadData)) {kagamiGridModel.onLoadData = _.clone(kagamiGridModel.detailedObjects) ?? []}
      kagamiGridModel.onLoadData.push(...dataRecords)
      let rows : any[] = retrieveListRows(kagamiGridModel);
      kagamiGridModel.gridApi.setRowData(rows)
      if(kagamiGridModel.scrollEnd){
        kagamiGridModel.scrollEnd = false;
        kagamiGridModel.gridApi.ensureIndexVisible(kagamiGridModel.detailedObjects.length - newRecordsLength,'bottom');
      } 
      /// Note : While loading the new data in infinite scrolling nodes are rebuilding which is making the already existing nodes
      /// to lose its reference and unselecting the selected records. So to overcome this based on selected records id's 
      /// we are making the records selected in updated grid data
      if(selectedNodes.length > 0){
        delay(()=>{
          let nodeIds : any[] = selectedNodes.map((node) => node.data.id)
          kagamiGridModel.gridApi.forEachNode((node:any) => {
            if(nodeIds.includes(node.data.id)){
              node.setSelected(true)
            }
          })
        },1000)
      }
    }
    else{
      kagamiGridModel.detailedObjects = dataRecords;
      kagamiGridModel.onLoadData = dataRecords;
      let kagamiGridState = kagamiGridModel.state
      kagamiGridState.setListLoaded(true);
    } 

  
}

export const updateTotalRecords = (listHeaderModel:any, totalRecords: any) => { 
   let updateTotalRecordCount = listHeaderModel.kagamiListHeaderModel?.state?.settotalRecords(totalRecords);
  return updateTotalRecordCount;
}


