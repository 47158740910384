import { State, useState } from "@hookstate/core";
import { KagamiStateManager } from "../../../../../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../../../../../constants/SystemConstants";
import { KagamiListModel } from "../../../model/KagamiListModel";

export class KagamiLazyHierarchyViewModel extends KagamiListModel{
  node:any;
  parent:any;
  treeData : any;
  state: any;
  isTreeDataLoaded : boolean;
  isDataLoading:boolean;
  hierarchyData: any;
  topHierarchy: any;
  hierarchyKey: string;
  constructor(processName: string, processStepName: string, mainEntityId: string, presentation: any) {
    super(processName, processStepName, null, mainEntityId);
    this.state = null;
    this.presentation = presentation;
    this.treeData = [];
    this.hierarchyData = [];
    this.topHierarchy = {}
    this.isTreeDataLoaded = false;
    this.isDataLoading=false;
    this.hierarchyKey = ''
  }
} 

export interface KagamiLazyHierarchyViewProps {
  kagamiLazyHierarchyViewModel: KagamiLazyHierarchyViewModel;
}

export const wrapKagamiLazyHierarchyViewModelState = (state: State<KagamiLazyHierarchyViewModel>) => {
  let hierarchyState = state;

  return {

    getTreeData() {
      return hierarchyState.treeData.get();
    },

    setTreeData(value: any[]) {
      hierarchyState.treeData.set(value);
    },

    getTopHierarchy() {
      return hierarchyState.topHierarchy.get();
    },

    setTopHierarchy(value: any) {
      hierarchyState.topHierarchy.set(value);
    },

    getHierarchyData() {
      return hierarchyState.hierarchyData.get();
    },

    setHierarchyData(value: any[]) {
      hierarchyState.hierarchyData.set(value);
    },

    getListLoaded() {
      return hierarchyState.isListLoaded.get();
    },

    setListLoaded(value: boolean) {
      hierarchyState.isListLoaded.set(value);
    },

    getTreeDataLoaded() {
      return hierarchyState.isTreeDataLoaded.get();
    },

    setTreeDataLoaded(value: boolean) {
      hierarchyState.isTreeDataLoaded.set(value);
    },

    getIsDataLoading() {
      return hierarchyState.isDataLoading.get();
    },

    setIsDataLoading(value: boolean) {
      hierarchyState.isDataLoading.set(value);
    },

    getHierarchyKey() {
      return hierarchyState.hierarchyKey.get();
    },
    
    setHierarchyKey(value: string) {
      hierarchyState.hierarchyKey.set(value);
    },
  };
};

export const useLazyHierarchyViewState = (state: State<KagamiLazyHierarchyViewModel>) => {
const lazyHierarchyViewState = wrapKagamiLazyHierarchyViewModelState(useState(state));
KagamiStateManager.addStateToHolder(SystemConstants.HIERARCHY_VIEW_STATE, lazyHierarchyViewState);
return lazyHierarchyViewState
}


