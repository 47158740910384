import { KagamiStateManager } from '../../state/KagamiStateManager';
import { BuildServerError } from '../builder/home/builder/ErrorBuilder';
import { getHomeModel } from '../builder/home/controller/KagamiHomeController';
import { KagamiHomeModel } from '../builder/home/model/KagamiHomeModel';
import { validateToken } from '../builder/login/controller/KagamiLoginController';
import { SystemConstants } from '../constants/SystemConstants';
import { KagamiApplicationModel } from '../model/KagamiApplicationModel';
import { BuildApplication } from '../ui/KagamiApplication';
import { setLinkProcessId } from '../Router/Route';

let applicationModel: KagamiApplicationModel;

export function getAppplicationModel() {
  return applicationModel;
}
export function onApplicationLoginSuccess() {
  // const location = useLocation();
  // const navigate = useHistory();
  // const redirectTo  = queryString.parse(location.search) 
  // const newPath = redirectTo == null ? "/" : redirectTo
  // navigate.push(newPath);
 
  KagamiStateManager.getStateToHolder(SystemConstants.APP_STATE).setUserLoggedIn(true);


  
}

export function onApplicationLoginFailure() {
  localStorage.clear();
  deleteCookies();
  setLinkProcessId(undefined);
  KagamiStateManager.getStateToHolder(SystemConstants.APP_STATE).setUserLoggedIn(false);
}

function deleteCookies(){
  var Cookies = document.cookie.split(';');
  for (var i = 0; i < Cookies.length; i++){
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
    console.log('cookie is '+document.cookie);
  }
}

export function renderApplication(appModel: KagamiApplicationModel) {
  applicationModel = appModel;
  return BuildApplication(appModel);
}

export function handleRefresh() {
  validateToken();
}

export function showServerErrorPopUp(error: any) {
  let kagamiHomeModel: KagamiHomeModel = getHomeModel();
  // let kagamiSelectModel: KagamiSelectModel = getSelectModel();
  // if(kagamiSelectModel?.controlType === "multiselect" && !kagamiHomeModel?.errorModal?.error) {
    // null
  // } else {
  kagamiHomeModel.errorModal.content = BuildServerError(error);
  kagamiHomeModel.errorModal.title = 'Error';
  kagamiHomeModel.errorModal.isSessionInvalid = isSessionInvalid(error);
  kagamiHomeModel.errorModal.modalWidth = 'sm';
  kagamiHomeModel.errorModal.isServerError = true;
  kagamiHomeModel.errorModal.handleOnClose = undefined;
  kagamiHomeModel.errorModal.handleOnOk = undefined;
  console.log(error);
  let alertDialogState = kagamiHomeModel.errorModal.state;
  alertDialogState.setOpen(true);
  // } 
}

function isSessionInvalid(error: any) {
  return error['error'] && error['error']['INVALIDATE'] && error['error']['INVALIDATE'] !== null;
}

interface triggerNotifcationTypes {
  text: string;
  background: string;
  textColor: string;
  time: number;
}

// export const triggerNotification = ({ text, background, textColor, time }: triggerNotifcationTypes) => {
//   toast(text, {
//     // style: {
//     //   borderRadius: '15px',
//     //   background: background,
//     //   color: textColor
//     // },
//     duration:time*10000
//   });
// };

// export const showToastAlert = ( message: string,time: number) => {
//   return setTimeout(() => {
//     triggerNotification({
//       text: message,
//       background: '#8dc63f',
//       textColor: '#ffffff',
//       time: 3
//     });
//   }, time);
// } 