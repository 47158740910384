import { State, useState } from '@hookstate/core';
import { KagamiProfileModel } from '../builder/profile/model/KagamiProfileModel';

export class KagamiHeaderUserProfileModel extends KagamiProfileModel {
  open: boolean;
  constructor(content: any) {
    super(content);
    this.content = content;
    this.open = false;
  }
}

export const wrapKagamiHeaderProfileState = (state: State<KagamiHeaderUserProfileModel>) => {
  let profileState = state;

  return {
    get isOpen() {
      return profileState.open.get();
    },

    setOpen(isOpen: boolean) {
      profileState.open.set(isOpen);
    },

    get content() {
      return profileState.content.get();
    },

    setContent(content: any) {
      profileState.content.set(content);
    },
    get appAndUserContext() {
      return profileState.appAndUserContext.get();
    },

    setAppAndUserContext(appAndUserContext: any) {
      profileState.appAndUserContext.set(appAndUserContext);
    }
  };
};
export const useKagamiHeaderProfileState = (state: State<KagamiHeaderUserProfileModel>) =>
  wrapKagamiHeaderProfileState(useState(state));
