import React from 'react'
import { createState } from '@hookstate/core';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import hamburger from '../../../../../../assets/images/hamburgerRight.svg';
import {  buildNavigatorSection } from '../builder/NavigationBuilder';
import { KagamiNavigatorModel, NavigatorProps, useNavigatorState } from '../model/KagamiNavigatorModel';
import {   getFavoritesPanel, getMasterDataPanel, getNavigationData, getUserData   } from '../service/NavigatorService';

import { KagamiNavigatorStyle } from '../styles/KagamiNavigator';
import { ProSidebar, SidebarContent, SidebarHeader } from 'react-pro-sidebar';

import 'react-pro-sidebar/dist/css/styles.css'; 
import { usePanelState } from '../builder/Menu/model/KagamiPanelState';
import { getDashboardModel } from '../../body/builder/dashboard/ui/KagamiDashboard';
import { KagamiDashboardModel } from '../../body/builder/dashboard/model/KagamiDashboardModel';
import { Avatar, IconButton } from '@mui/material';
import { executeLinkProcess } from '../../body/builder/process/controller/KagamiProcessController';
import { KagamiHomeModel } from '../../../model/KagamiHomeModel';
import { getHomeModel } from '../../../controller/KagamiHomeController';







export const buildNavigatorComponent = (kagamiNavigatorModel: KagamiNavigatorModel) => {
 return <KagamiNavigator key={'kagami-navigation'} kagamiNavigatorModel={kagamiNavigatorModel} />;
};

const useStyles = makeStyles(KagamiNavigatorStyle);

export const KagamiNavigator = React.memo( (props: NavigatorProps) => {
  let navigatorModel = props.kagamiNavigatorModel;
  navigatorModel.state = useNavigatorState(createState(navigatorModel));
  const panelState = usePanelState();
  
  const classes = useStyles();
  useEffect(() => {
    getNavigationData(navigatorModel);
    getUserData(navigatorModel);
  }, []); 

  const collapseSidebar = () => {
    panelState.SetSideBarCollapsed(!panelState.sideBarCollapesed);
  };
  const handleSidebarToggle = (value:boolean) => {
    panelState.SetSideBarToggled(value)
  }
  return  (
      <>  
        <ProSidebar       
        className={classes.proSidebar}
        collapsed={panelState.sideBarCollapesed}
        // toggled={true}
        toggled={panelState.sideBarToggled}
        onToggle={handleSidebarToggle}
        breakPoint = "md"
        // rtl ={true}
        width= {200}
        // collapsedWidth = {60}
         > 
        <SidebarHeader>
            <div style={{justifyContent:'end'}}
            className={classes.hamburgerimg} >
            <IconButton
             size="small"
               onClick={() => collapseSidebar()}
             >

                <Avatar 
                sx={{width: 13, height:13 }}
                className={panelState.sideBarCollapesed ? classes.hamburgerimgnrml : classes.hamburgerimgrevers}
                src ={hamburger}
                />

            </IconButton>
            </div>
        </SidebarHeader>
      <SidebarContent> 
           
        {navigatorModel.state.navigatorDataLoaded 
        ?     <>
                {/* {CommonUtils.isNotEmpty(navigatorModel.navComponents) ? navigatorModel.navComponents : buildNavigatorSection(navigatorModel)} */}

                <KagamiNavigationMenu   navigationData={navigatorModel.navigationData }/>
                </>  
              
        :   <></>}
            </SidebarContent> 
        </ProSidebar>
      </>
  )
});

const KagamiNavigationMenu = React.memo((props:any )=> {
  
  const {masterData,navigationData  } = props
  useEffect(() => {
    let favourites = getFavoritesPanel(navigationData);
    let dashboardModel:KagamiDashboardModel =  getDashboardModel();
    dashboardModel?.state?.setFavouritesData(favourites);
    dashboardModel.state.setcontainerData(navigationData);
    let homeModel : KagamiHomeModel = getHomeModel();
  if(homeModel.executeLinkProcess){
    executeLinkProcess(homeModel.linkProcessId)
  }
  }, [])
  
  const MasterDatapanel: any[] = getMasterDataPanel(navigationData);
  console.log("KagamiNavigationMenu Comp render");
  return <> { buildNavigatorSection(navigationData, MasterDatapanel)} </>
});
