import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { Typography, InputAdornment, Stack } from "@mui/material";
import { activityStyle } from "../styles/KagamiActivityStyle";
import {
  KagamiActivityModel,
  KagamiActivityProps,
  useKagamiActivityState,
} from "../model/KagamiActivityModel";
import { createState } from "@hookstate/core";
import {
  handleEditComment,
  handleReply,
} from "../../../controller/KagamiActivityLogModalController";
import { KagamiActivityLogModel } from "../../../model/KagamiActivityLogModel";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { getUserProfileData } from "../../../../../application/builder/home/builder/header/controller/KagamiPageHeaderController";
import { CommonUtils } from "../../../../../application/utils/CommonUtils";
import { getUserInfoFromProfileModel } from "../../../../../application/builder/home/builder/header/builder/profile/controller/kagamiProfileController";

import { convertIntoRelativeTime } from "../../../../../application/utils/DateUtils";

export const buildActivityList = (
  activityList: any,
  activityLogModel: KagamiActivityLogModel
) => {
  return (
    Array.isArray(activityList) &&
    activityList.map((item: any) => {
      if (item.message) {
        let kagamiActivityModel: KagamiActivityModel = new KagamiActivityModel(
          item.createDateTime,
          item.createdBy,
          item.message,
          item.readOnly,
          item.updateDateTime,
          item.updatedBy
        );
        if (item.selfrefActivity && item.selfrefActivity.length > 0) {
          kagamiActivityModel.selfrefActivity = item.selfrefActivity;
        }
        return buildActivityComponent(kagamiActivityModel, activityLogModel);
      } else {
        return null;
      }
    })
  );
};

export function buildActivityComponent(
  kagamiActivity: KagamiActivityModel,
  activityLogModel: KagamiActivityLogModel
) {
  return (
    <KagamiActivity
      KagamiActivityModel={kagamiActivity}
      KagamiActivityLogModel={activityLogModel}
    />
  );
}

//used by avatar
function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}`,
  };
}

function ExtractStatusMessage(props: any) {
  const { message, activityLogModel } = props;
  let messageToshow;
  let bgColor;
  if (message || Object.keys(activityLogModel.statusInfo).length !== 0) {
    let statusInfo = activityLogModel.statusInfo.statusInfo;
    let colorInfo = activityLogModel.statusInfo.colorInfo;
    let stKey = activityLogModel.statusInfo.statusKey;
    let statusToConsume: any;
    if (CommonUtils.isNotEmpty(statusInfo)) {
      statusToConsume = statusInfo.filter((item: any) => item.id === message);
    }

    if (CommonUtils.isNotEmpty(statusToConsume)) {
      if (CommonUtils.isNotEmpty(colorInfo)) {
        let colorToConsume = colorInfo.filter(
          (item: any) => item.id === statusToConsume[0].colorId
        );
        bgColor = colorToConsume[0]?.bgCode;
      }
      messageToshow = statusToConsume[0][stKey];
    } else if (message) {
      messageToshow = message;
    }
  } else {
    messageToshow = message;
    bgColor = "#000";
  }
  return (
    <>
      <div>
        <Typography variant="caption" color="action">
          Status changed:{" "}
          <span style={{ color: ` ${bgColor}`, fontWeight: "bold" }}>
            {messageToshow ? messageToshow : "Null"}
          </span>
        </Typography>
      </div>
    </>
  );
}

function isCurrentUser(createdBy: any, activityLogModel: any) {
  let isCurrentUser: boolean;
  const listInfo = activityLogModel.listChatInfo;
  const userInfo = listInfo.filter((item: any) => item.chatId === createdBy);
  userInfo.length > 0 ? (isCurrentUser = false) : (isCurrentUser = true);
  return isCurrentUser;
}
export function KagamiActivity(props: KagamiActivityProps) {
  const { KagamiActivityModel, KagamiActivityLogModel } = props;
  const activity = KagamiActivityModel;
  const activityLogModel = KagamiActivityLogModel;
  const classes = makeStyles(activityStyle)();

  let activityState = useKagamiActivityState(createState(activity));

  function extractUserName(user: any, activityLogModel: any) {
    const listInfo = activityLogModel.listChatInfo;
    const userInfo = listInfo.filter(
      (item: any) => item.chatId.toString().toLowerCase() === user.toLowerCase()
    );
    let createdBy;
    if (userInfo.length > 0) {
      return (createdBy = userInfo[0]["chatHeader"]);
    } else {
      let userModel = getUserProfileData();
      if (userModel) {
        if (userModel.userId === "admin") {
          createdBy = "ADMIN";
        } else {
          createdBy = userModel.firstName;
        }
      } else if (userModel === null) {
        userModel = getUserInfoFromProfileModel();
        if (userModel) {
          createdBy = userModel.firstName;
        }
      } else {
        createdBy = "Current User";
      }
      return createdBy;
    }
  }
  let user: string = extractUserName(activity.updatedBy, activityLogModel);
  if (!user) {
    user = "CURRENT USER";
  }
  user = user.trim();
  return (
    <>
      <div className={classes.activity}>
        <Avatar
          sx={{ width: 24, height: 24 }}
          {...stringAvatar(`${user.charAt(0).toUpperCase()}`)}
        />
        <div className={classes.activitybody}>
          <div className={classes.user_info}>
            <Typography variant="caption" className={classes.activity_heading}>
              {" "}
              {user}{" "}
            </Typography>
            <div className={classes.datetime}>
              <Typography variant="caption" className={classes.lightText}>
                {convertIntoRelativeTime(activity.createDateTime)}
              </Typography>
            </div>
          </div>
          <div className={classes.user_comment}>
            {!activityState.isEditMode && activity.readOnly ? (
              <ExtractStatusMessage
                message={activity.message}
                activityLogModel={activityLogModel}
              />
            ) : activityState.isEditMode && !activity.readOnly ? (
              <EditComponent
                activityState={activityState}
                activity={activity}
                activityLogModel={activityLogModel}
              />
            ) : (
              <Typography variant="caption" color="action">
                {activity.message}
              </Typography>
            )}

            {!activity.readOnly ? (
              <div className={classes.activityActions}>
                <Stack direction="row" spacing={2}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      activityState.setIsReplyMode(true);
                    }}
                    size="small"
                  >
                    <ReplyOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#6bc03f" }}
                    />
                  </IconButton>

                  {isCurrentUser(activity.createdBy, activityLogModel) && (
                    <div style={{ marginLeft: "0" }}>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          activityState.setIsEditMode(true);
                        }}
                        size="small"
                      >
                        <ModeEditOutlineOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#29947F" }}
                        />
                      </IconButton>
                    </div>
                  )}
                </Stack>

                <div style={{ marginLeft: "30px" }}>
                  {activity.selfrefActivity.length ? (
                    <Typography variant="caption">
                      {activity.selfrefActivity.length} reply(s)
                    </Typography>
                  ) : null}
                </div>
              </div>
            ) : (
              <></>
            )}
            {activityState.isReplyMode && !activity.readOnly ? (
              buildReplyComponent(activityState, activity, activityLogModel)
            ) : (
              <></>
            )}
            <>
              {Array.isArray(activity.selfrefActivity) &&
                buildActivityList(activity.selfrefActivity, activityLogModel)}
            </>
          </div>
        </div>
      </div>
    </>
  );
}

function buildReplyComponent(
  activityState: any,
  activity: any,
  activityLogModel: any
) {
  return (
    <ReplyComponent
      activityState={activityState}
      activity={activity}
      activityLogModel={activityLogModel}
    />
  );
}

export const ReplyComponent = (props: any) => {
  const { activity, activityLogModel, activityState } = props;
  const replyRef: any = React.useRef(null);
  const classes = makeStyles(activityStyle)();
  let userModel = getUserProfileData();
  let createdBy;
  if (userModel) {
    if (userModel.userId === "admin") {
      createdBy = "admin";
    } else {
      createdBy = userModel.userId;
    }
  } else if (userModel === null) {
    userModel = getUserInfoFromProfileModel();
    if (userModel.userId === "admin") {
      createdBy = "admin";
    } else {
      createdBy = userModel.userId;
    }
  } else {
    createdBy = "CurrentUser";
  }
  const replyMessage: KagamiActivityModel = new KagamiActivityModel(
    `${moment().format()}`,
    createdBy,
    "",
    false,
    `${moment().format()}`,
    createdBy
  );

  return (
    <form
      className={classes.reply_Form}
      onSubmit={(e) => {
        e.preventDefault();
        if (replyMessage.message) {
          handleReply(replyMessage, activity, activityLogModel);
          replyRef.current.value = "";
          activityState.setIsReplyMode(false);
        }
      }}
    >
      <TextField
        inputRef={replyRef}
        id="reply"
        label="reply"
        className={classes.activityLog_input}
        size="small"
        autoFocus
        onChange={(e) => {
          replyMessage.message = e.target.value;
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Avatar
                variant="rounded"
                className={classes.reply_btn}
                onClick={() => {
                  if (replyMessage.message) {
                    handleReply(replyMessage, activity, activityLogModel);
                    replyRef.current.value = "";
                    activityState.setIsReplyMode(false);
                  }
                }}
              >
                <ReplyOutlinedIcon fontSize="small" />
              </Avatar>
            </InputAdornment>
          ),
        }}
      />

      <Avatar
        variant="rounded"
        className={classes.cancel_btn}
        onClick={() => {
          replyRef.current.value = "";
          activityState.setIsReplyMode(false);
        }}
      >
        <CloseOutlinedIcon fontSize="small" />
      </Avatar>
    </form>
  );
};

export const EditComponent = (props: any) => {
  const editRef: any = React.useRef(null);
  const { activity, activityLogModel, activityState } = props;

  const classes = makeStyles(activityStyle)();
  let editMessage = activity.message;
  return (
    <>
      <form
        className={classes.reply_Form}
        onSubmit={(e) => {
          e.preventDefault();
          if (activity.message) {
            activity.message = editMessage;
            handleEditComment(activity, activityLogModel);
            editRef.current.value = "";
            activityState.setIsEditMode(false);
          }
        }}
      >
        <TextField
          inputRef={editRef}
          id="edit"
          label="edit comment"
          className={classes.activityLog_input}
          size="small"
          autoFocus
          defaultValue={activity.message}
          onChange={(e) => {
            editMessage = e.target.value;
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Avatar
                  variant="rounded"
                  className={classes.reply_btn}
                  onClick={() => {
                    if (activity.message) {
                      activity.message = editMessage;
                      handleEditComment(activity, activityLogModel);
                      editRef.current.value = "";
                      activityState.setIsEditMode(false);
                    }
                  }}
                >
                  <ModeEditOutlineOutlinedIcon fontSize="small" />
                </Avatar>
              </InputAdornment>
            ),
          }}
        />
        <Avatar
          variant="rounded"
          className={classes.cancel_btn}
          onClick={() => {
            editRef.current.value = "";
            activityState.setIsEditMode(false);
          }}
        >
          <CloseOutlinedIcon fontSize="small" />
        </Avatar>
      </form>
    </>
  );
};
