import { KagamiStateManager } from "../../../../../../../../state/KagamiStateManager";
import { SystemConstants } from "../../../../../../../constants/SystemConstants";
import { CommonUtils } from "../../../../../../../utils/CommonUtils";
import { buildProcessPage } from "../../../../body/builder/process/controller/KagamiProcessController";
import { KagamiProcessPageModel } from "../../../../body/builder/process/model/KagamiProcessModel";
import { renderProcessPage } from "../../../../body/controller/KagamiBodyController";
 

export function executeProcess(processName: string , detailedObjects?:any , breadcrumb:any = [],back : boolean = false) {
    const processModel: KagamiProcessPageModel = new KagamiProcessPageModel(processName);
    if(CommonUtils.isNotEmpty(detailedObjects)) {
        processModel.detailsObjects = detailedObjects
    }
    if(!back){
        processModel.fromDashboard = true;
    }
    processModel.breadcrumbModel.breadcrumbs = breadcrumb;
    renderProcessPage(buildProcessPage(processModel));
    KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setNotificationIconVisible(false);
    KagamiStateManager.getStateToHolder(SystemConstants.HEADER_STATE).setReportIconVisible(false);

  }


  
  let processProcessName: string = '';

  export function getProcessName() {
    return processProcessName;
  }

  export function setCurrentProcessName (processName : string) {
    return processProcessName = processName
  }