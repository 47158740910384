import { State, useState } from '@hookstate/core';
import { KagamiStateManager } from '../../../../../../../../../../state/KagamiStateManager';
import { SystemConstants } from '../../../../../../../../../constants/SystemConstants';
import { KagamiTriggerModel } from '../../Triggers/builder/trigger/model/KagamiTriggerModel';
export class KagamiGroupModel {
  mainEntityId: string;
  activeIndex: number;
  processName: string;
  processStepName: string;
  previousProcessName: string;
  onStartData: any;
  templateType: string;
  triggers: any[];
  groupContent: any;
  state: any;
  kagamiTriggerModel: KagamiTriggerModel | null;
  processList: any[];
  showSearchBar : boolean;
  activeGroupModel : any;

  constructor(
    entityId: string,
    processName: string,
    processStepName: string,
    onstartData: any,
    templateType: string,
  ) {
    this.activeIndex = 0;
    this.mainEntityId = entityId;
    this.processName = processName;
    this.processStepName = processStepName;
    this.onStartData = onstartData;
    this.templateType = templateType;
    this.triggers = [];
    this.state = null;
    this.previousProcessName = '';
    this.groupContent = null;
    this.kagamiTriggerModel = null;
    this.processList = [];
    this.showSearchBar = false;
    this.activeGroupModel = null;


  }
}

export interface GroupModelProps {
  groupModel: KagamiGroupModel;
}

export const wrapKagamiGroupState = (state: State<KagamiGroupModel>) => {
  let groupState = state;

  return {
    get activeIndex() {
      return groupState.activeIndex.get();
    },

    setActiveIndex(activeIndex: number) {
      groupState.activeIndex.set(activeIndex);
    },

    get showSearchBar(){
      return groupState.showSearchBar.get();
    },
    setSearchBar(value : boolean){
      groupState.showSearchBar.set(value)
    },

    get groupContent() {
      return groupState.groupContent.get();
    },

    setGroupContent(groupContent: JSX.Element) {
      groupState.groupContent.set(groupContent);
    },

    setDefaultProcessList() {
      groupState.processList.set([]);
    },
    
    get DefaultProcessList() {
      return groupState.processList.get();
    },

    setProcessList(array:any){
      groupState.processList.set(array);
    }
  };
};

export const useKagamiGroupState = (state: State<KagamiGroupModel>) => {
  const processState = wrapKagamiGroupState(useState(state));
  KagamiStateManager.addStateToHolder(SystemConstants.GROUP_STATE, processState);
  return processState;
};
