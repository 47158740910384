import { KagamiModalDialogModel } from "../model/KagamiModalDialogModel";
import {  builModalDialogComponent } from "../ui/KagamiModalDialog";


export function buildModalDialog(modalModel: KagamiModalDialogModel) {
  return builModalDialogComponent(modalModel);
}

export function  handleModalOnOk(modalModel : KagamiModalDialogModel) {
  if(modalModel.handleOnOk){
    modalModel.handleOnOk();
  }
  else{
    modalModel.state.setOpen(false);
  }
 
}

export function  handleModalOnClose(modalModel : KagamiModalDialogModel) {
  
  if(modalModel.handleOnClose){
    modalModel.handleOnClose();
  }
  modalModel.state.setOpen(false);
}
