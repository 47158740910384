import { KagamiEmbeddedFormPresentationModel } from "../../../model/KagamiEmbeddedFormPresentationModel"
import { buildEmbeddedAccordionPresentation } from "./embedded-accordion-presentation/controller/KagamiEmbeddedAccordionPresentationController";
import { KagamiEmbeddedAccordionPresentationModel } from "./embedded-accordion-presentation/model/KagamiEmbeddedAccordionPresentationModel";
import { buildEmbeddedTabsPresentation } from "./embedded-tabs-presentation/controller/KagamiEmbeddedTabsPresentationController";
import { KagamiEmbeddedTabsPresentationModel } from "./embedded-tabs-presentation/model/KagamiEmbeddedTabsPresentationModel";
import { buildEmbedAccordionModel, buildEmbedTabsModel } from "../service/KagamiEmbeddedPresentationService";
import { getFormModel } from "../../../controller/KagamiFormController";
import { CommonUtils } from "../../../../../../../../../../../utils/CommonUtils";

export const EmbedAccordionPresentations = (props : any) => {
    let embedAccordionComponents : any[] = [];
    let embedFormModels : KagamiEmbeddedFormPresentationModel[] = props.embedFormModels;
    let embedAccordionModel : KagamiEmbeddedAccordionPresentationModel;
    for(var embedFormModel of embedFormModels){
        embedAccordionModel = buildEmbedAccordionModel(embedFormModel)
        embedAccordionModel.isNested = embedFormModel.isNested;
        embedAccordionModel.rowCountModel= embedFormModel.rowCountModel
        embedAccordionModel.rowCountModel.dataCount =CommonUtils.isNotEmpty(embedFormModel.onLoadData) ?  embedFormModel.onLoadData.length : 0
        embedAccordionModel.parentEmbedEntityId = embedFormModel.parentEmbedEntityId;
        getFormModel().formControls[embedAccordionModel.formEntityId] = embedAccordionModel;
        embedAccordionComponents.push(buildEmbeddedAccordionPresentation(embedAccordionModel));
    }
    return <>{embedAccordionComponents}</>;
}

export const EmbedTabPresentation = (props : any) => {
     let embedTabsModel : KagamiEmbeddedTabsPresentationModel = buildEmbedTabsModel(props.embedPresentationModel);
     return buildEmbeddedTabsPresentation(embedTabsModel);
}