import { ReportConstant } from '../../view/ReportConstant/ReportConstant';

export const haveMandatoryField = (filters: any) => {
  let count = false;
  filters.forEach((filterConfig: any) => {
    if (!filterConfig.optional) {
      if (
        filterConfig.type !== ReportConstant.DateTime &&
        filterConfig.type !== ReportConstant.Date &&
        filterConfig.value === ''
      ) {
        count = true;
      }
    }
  });
  return count;
};

export const handlePopupButtonVisibility = (filterModel: any) => {
  let check = false;
  filterModel.reportFilterModel.filters.forEach((filterConfig: any) => {
    if (!filterConfig.isuser && !filterConfig.optional) {
      if (filterModel.reportFilterModel.selectedValue.get(filterConfig.name) === 'NOPOP') {
        check = true;
      }
    }
  });
  if (check) {
    filterModel.reportFilterModel.modalModel?.state?.setButtonDisabled(true);
  } else {
    filterModel.reportFilterModel.modalModel?.state?.setButtonDisabled(false);
  }
};

